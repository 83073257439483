import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { Grid, Header, Icon, Segment, Divider, Popup } from 'semantic-ui-react';
import PrintComponent from 'shared/PrintComponent';
import EmailReplyForm from '../EmailReplyForm';
import ThreadList from '../ThreadItem';

const EmailThread = ({
    threads,
    subject,
    isCollapsed,
    isSidePanelOpen,
    emailAddress,
    onToggleCollapse,
    timezone,
    readEmails,
}) => {

    const componentRef = useRef();
    const onHandlePrint = useReactToPrint({
        content: () => {
            return componentRef.current;
        },
    });

    return (
        <React.Fragment>
            <Segment className='EmailThread' size='tiny' loading={false}>
                <Grid columns='equal'>
                    <Grid.Row>
                        <Grid.Column style={{ paddingLeft: '2.65em' }} width={12}>
                            <Header as='h3'>{subject}</Header>
                        </Grid.Column>
                        <Grid.Column className='EmailThread__Icons'>
                            {threads.length > 1 ?
                                <Popup
                                    content={`${isCollapsed ? 'Expand All' : 'Collapse All'}`}
                                    inverted
                                    trigger={
                                        <Icon
                                            size='large'
                                            className='EmailThread__Icons--arrows'
                                            name={`${isCollapsed ? 'angle double down' : 'angle double up'}`}
                                            onClick={onToggleCollapse}
                                        />
                                    }
                                />
                                : <Icon
                                    size='large'
                                    className='EmailThread__Icons--disabled'
                                    name={'angle double down'}
                                />}
                            <Popup
                                content='Print'
                                inverted
                                trigger={
                                    <Icon
                                        link
                                        className='EmailThread__Icons--print'
                                        size='large'
                                        name='print'
                                        onClick={onHandlePrint}
                                    />
                                }
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider />
                <PrintComponent ref={componentRef} content={
                    <Grid>
                        <Grid.Column>
                            <Grid.Row>
                                <ThreadList
                                    emailAddress={emailAddress}
                                    isSidePanelOpen={isSidePanelOpen}
                                    toggleIsCollapsed={onToggleCollapse}
                                    isCollapsed={isCollapsed}
                                    threads={threads}
                                    timezone={timezone}
                                />
                            </Grid.Row>
                        </Grid.Column>
                    </Grid>
                } />
            </Segment>
            <EmailReplyForm email={threads[0]} readEmails={readEmails} />
        </React.Fragment>
    );
};

const EmailThreadContainer = (props) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isReplyFormOpen, setIsReplyFormOpen] = useState(true);

    const onToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    }

    const onOpenReplyForm = () => {
        setIsReplyFormOpen(true);
    }

    return (
        <EmailThread {...props}
            isCollapsed={isCollapsed}
            isReplyFormOpen={isReplyFormOpen}
            onToggleCollapse={onToggleCollapse}
            onOpenReplyForm={onOpenReplyForm}
        />
    )

}

const mapStateToProps = state => {
    return ({
        emailAddress: state.oauth_integrations.activeIntegration.email,
        isPullingEmails: state.email.isPullingEmails,
        timezone: state.auth.timezone
    })
};


export default connect(mapStateToProps)(EmailThreadContainer);
