import { generateReadOneActionCreator, generateUpdateActionCreator, generateDeleteActionCreator } from '../crudActionCreators';
import routes from 'actions/routes';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import { Notification, STATUS_TYPES } from 'dyl-components';

export default {
    readUserRole: generateReadOneActionCreator(routes.API_ACCESS_ROLE, ACTION_NAMES.ACCESS_ROLE),
    updateUserRole: generateUpdateActionCreator(routes.API_ACCESS_ROLE, ACTION_NAMES.ACCESS_ROLE, () => {
        Notification.alert(
            `Successfully updated permission profile!`,
            STATUS_TYPES.SUCCESS,
            true
        );
    }, () => {
        Notification.alert(
            `Failed to update permission profile`,
            STATUS_TYPES.ERROR,
            true
        );
    }),
    deleteUserRole: generateDeleteActionCreator(routes.API_ACCESS_ROLE, ACTION_NAMES.ACCESS_ROLE)
}
