import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Grid } from 'semantic-ui-react';
import { CustomGroupUtils } from 'utils';
import { STATES } from 'shared/constants/STATES';
import { Controller } from 'react-hook-form';
import { DateInput } from 'dyl-components';

const BusinessStandardData = ({
    isEditing,
    control,

    textAlign = 'right'
}) => {
    const { custom_data, structure_options } = useSelector(state => {
        const structure_options = state.account.account?.custom_data?.fields?.find(field => field.field_name === 'business_structure')?.options?.data?.map(option => ({
            key: option.id,
            value: option.id,
            text: option.value
        })) || [];
        return {
            custom_data: CustomGroupUtils.groupAndFlatten(state.account.account?.custom_data || {}),
            structure_options
        }
    });

    const fields = custom_data.fields;

    const structure = structure_options.find((option) => {
        return option.value === fields?.business_structure;
    });

    const states = fields?.business_in_other_states ? (fields?.business_in_other_states.map((stateKey, idx) => {
        const stateName = STATES.find((state) => {
            return stateKey === state.key;
        }).text;
        if (idx + 1 === fields?.business_in_other_states.length) {
            return stateName
        }
        return `${stateName}, `
    })) : 'None';

    return (
        <Grid className='RecordInfo'>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Business Nickname
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        fields?.business_nickname || 'None'
                    ) : (
                        <Controller
                            name='business_nickname'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Enter business nickname'
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Legal Business Name
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        fields?.legal_business_name || 'None'
                    ) : (
                        <Controller
                            name='legal_business_name'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Enter legal business name'
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    DBA Business Name
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        fields?.dba_business_name || 'None'
                    ) : (
                        <Controller
                            name='dba_business_name'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Enter DBA business name'
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    State Org.
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        (fields?.state_of_organization && STATES.find(state => fields?.state_of_organization === state.key).text) || 'None'
                    ) : (
                        <Controller
                            name='state_of_organization'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Select state of organization'
                                    selectOnBlur={false}
                                    options={STATES.map(state => ({ ...state, value: state.key }))}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Bus. Oth. States
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        states
                    ) : (
                        <Controller
                            name='business_in_other_states'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Select business in other states'
                                    selectOnBlur={false}
                                    multiple
                                    options={STATES.map(state => ({ ...state, value: state.key }))}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Prdt./Svcs. Sold
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        fields?.product_services_sold || 'None'
                    ) : (
                        <Controller
                            name='product_services_sold'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Enter products/services sold'
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Date Founded
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        fields?.date_founded || 'None'
                    ) : (
                        <Controller
                            name='date_founded'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Field
                                    control={DateInput}
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value } })
                                    }}
                                    placeholder='Enter date founded'
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Business Structure
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        structure?.text || 'None'
                    ) : (
                        <Controller
                            name='business_structure'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value } })
                                    }}
                                    placeholder='Select business structure'
                                    selectOnBlur={false}
                                    options={structure_options}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default BusinessStandardData;

