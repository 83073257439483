import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Form, Header } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { MultipleNestedDropdown, yup } from 'dyl-components';

import teamsActions from 'actions/teams';

import { STATES } from 'shared/constants/STATES';

import './TerritoryForm.scss';
import territoryActions from 'actions/territory';

import ZipcodesField from './ZipcodesField';

const TeamLabel = ({ name, userCount }) => (
    <Header className='Teams__Label' as='h5'>
        {name}
        <Header.Subheader>{userCount} users</Header.Subheader>
    </Header>
)

const TerritoryForm = ({ control, loading, clearErrors, id, setError, defaultZipOptions, setValue, watch }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(teamsActions.getTeamOptions({ limit: 1000 }));
    }, [dispatch]);

    const teams = useSelector((state) => state.teams.teams || []);

    const checkIfDuplicate = async (value) => {
        return dispatch(territoryActions.checkIfDuplicate({ name: value, territory_id: id }));
    }

    const [typingTimeout, setTypingtimeout] = useState(null);
    const [countiesLoadingTimeout, setCountiesLoadingTimeout] = useState(null);


    const [statesWithCounties, setStatesWithCounties] = useState(STATES.slice(1).map(state => ({
        key: state.key,
        text: state.text,
        options: []
    })));

    const countiesValue = (() => {
        if (watch) {
            return watch('counties');
        }
        return [];
    })();

    const [stateBeingReadForCounties, setStateBeingReadForCounties] = useState(null);

    const [stateOptions, setStateOptions] = useState([]);
    const [disallowedZips, setDisallowedZips] = useState([]);

    const [watchedStates, watchedCounties, watchedZips] = watch(['states', 'counties', 'zips']);

    const populateStateWithCounties = async (state) => {
        return dispatch(territoryActions.readCounties({
            state: watchedStates,
            county: watchedCounties.map(county => {
                const [countyName, state] = county.split('(');
                return {
                    county: countyName.trim(),
                    state: state.trim().replace(')', '')
                }
            })
        }, { state, territory_id: id }));
    }

    useEffect(() => {
        dispatch(territoryActions.getValidOptions({
            state: watchedStates,
        }, { territory_id: id })).then(results => {
            setStateOptions(results?.states || []);
        });
    }, [watchedStates, watchedCounties, dispatch, id]);

    const allowedStates = stateOptions.filter(state => state.status === "available").map(state => state.state);
    const disallowedStatesForSelectingCounties = stateOptions.filter(state => state.status === "disabled").map(state => state.state);

    useEffect(() => {
        dispatch(territoryActions.getValidOptions({
            state: watchedStates,
            county: watchedCounties.map(county => {
                const [countyName, state] = county.split('(');
                return {
                    county: countyName.trim(),
                    state: state.trim().replace(')', '')
                }
            }),
            zip: watchedZips.filter(zip => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip))
        }, { territory_id: id })).then(results => {
            const conflictingZipcodes = results?.zips || [];
            setDisallowedZips(conflictingZipcodes);
            if (conflictingZipcodes.length) {
                setError('zips', {
                    type: 'conflicting_zips',
                    message: 'Please remove all previously used or invalid zip codes'
                })
            }
        });
    }, [watchedStates, watchedZips, dispatch, id, watchedCounties, setError])

    return (
        <Form className='TerritoryForm' loading={loading} noValidate>
            <Form.Group widths={3}>
                <Controller
                    name='name'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            required
                            value={value}
                            label='Territory Name'
                            onChange={async (_, { value }) => {
                                if (typingTimeout) {
                                    clearTimeout(typingTimeout)
                                }
                                onChange({ target: { name, value } });
                                const isValid = await yup.string().no_whitespace_only().no_excessive_whitespaces().maxlength(64).simple_alphanumeric().isValid(value);
                                if (isValid && value !== '') {
                                    setTypingtimeout(setTimeout(async () => {
                                        await clearErrors(name);
                                        const hasDuplicate = await checkIfDuplicate(value);
                                        if (hasDuplicate) {
                                            await setError("name", {
                                                type: 'unique_territory',
                                                message: 'Territory name already exists'
                                            })
                                        }
                                    }, 150));
                                }
                            }}
                            error={error?.message}
                        />
                    )}
                />
            </Form.Group>
            <Form.Field
                control={'div'}
                label='Criteria'
                required
            />
            <Form.Group widths={3}>
                <Controller
                    name='states'
                    control={control}
                    render={({ field: { name: stateName, value: stateValue, onChange: stateOnChange }, fieldState: { error: stateError } }) => (
                        <>
                            <Form.Select
                                multiple
                                options={STATES.slice(1).map(state => ({
                                    ...state, value: state.key,
                                    text: `${state.text}${state.key ? ` (${state.key})` : ''}`,
                                    disabled: !allowedStates.includes(state.key)
                                }))}
                                value={stateValue}
                                search
                                onChange={async (_, { value }) => {
                                    clearErrors(stateName);
                                    clearErrors('zips')
                                    stateOnChange({ target: { name: stateName, value } });
                                    if (setValue && value.length > stateValue.length) {
                                        const newlyAddedState = value[value.length - 1];
                                        setValue('counties', countiesValue.filter(county => !county.includes(newlyAddedState)))
                                    }
                                }}
                                label='States'
                                placeholder='Select state(s)'
                                error={stateError?.type !== 'at_least_one_criterion_required' && stateError?.message}
                            />
                            <Controller
                                name='counties'
                                control={control}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Field
                                        control={MultipleNestedDropdown}
                                        onChange={(_, { value }) => {
                                            clearErrors(name);
                                            clearErrors('zips');
                                            onChange({ target: { name, value } });
                                        }}
                                        values={value}
                                        nested_options={statesWithCounties.map(state => ({
                                            ...state,
                                            loading: stateBeingReadForCounties === state.key,
                                            disabled: stateValue.includes(state.key) || disallowedStatesForSelectingCounties.includes(state.key)
                                        }))}
                                        placeholder='Select one or more counties'
                                        label='County/counties'
                                        error={error?.type !== 'at_least_one_criterion_required' && error?.message}
                                        onClickParent={async (parentValue) => {
                                            if (countiesLoadingTimeout) {
                                                clearTimeout(countiesLoadingTimeout)
                                            }
                                            setCountiesLoadingTimeout(setTimeout(async () => {
                                                const indexOfStatesToPopulateWithCounties = statesWithCounties.findIndex(state => state.key === parentValue);
                                                if (indexOfStatesToPopulateWithCounties !== -1) {
                                                    const stateToPopulateWithCounties = statesWithCounties[indexOfStatesToPopulateWithCounties];
                                                    if (stateToPopulateWithCounties.options?.length === 0) {
                                                        setStateBeingReadForCounties(parentValue);
                                                        const county = await populateStateWithCounties(parentValue);
                                                        const copy = statesWithCounties.slice(0);
                                                        copy[indexOfStatesToPopulateWithCounties] = {
                                                            ...stateToPopulateWithCounties, options: county.map(countyItem => ({
                                                                id: countyItem.county,
                                                                value: `${countyItem.county} (${countyItem.state})`,
                                                                text: countyItem.county,
                                                                disabled: countyItem.disabled
                                                            }))
                                                        }
                                                        setStatesWithCounties(copy);
                                                    }
                                                }
                                                setStateBeingReadForCounties(null);
                                            }, 300));
                                        }}
                                    />
                                )}
                            />
                        </>
                    )}
                />
                <Controller
                    name='zips'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Field
                            control={ZipcodesField}
                            label='Zip Code(s)'
                            onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                            value={value}
                            error={error?.type !== 'at_least_one_criterion_required' && error?.message}
                            options={defaultZipOptions}
                            name={name}
                            disallowedZips={disallowedZips}
                        />
                    )}
                />
            </Form.Group>
            <Controller
                name='teams'
                control={control}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => {
                    return (
                        <Form.Select
                            className='Teams'
                            multiple
                            options={teams.map(team => {
                                return (
                                    {
                                        'key': team.id,
                                        'text': value.includes(team.id) ? <TeamLabel name={team.name} userCount={team.users.count} /> : team.name,
                                        'value': team.id
                                    }
                                )
                            })}
                            value={value}
                            search
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            label='Add teams to territory'
                            placeholder='Select team(s)'
                            error={error?.message}
                            upward
                        />
                    )
                }}
            />
        </Form>
    )
}

export default TerritoryForm;
