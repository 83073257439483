const initialState = {
    isReadingEmail: false,
    email:[],
    lead_providers: [],
    lead_providers_count: 0,
    isReadingLeadProviders: false,
    isAddingLeadProvider: false,
    isUpdatingLeadProvider: false,
    isDeletingLeadProvider: false,

    provider_meta: [],
    provider_meta_count: 0,
    isReadingProviderMeta: false,
    isAddingProviderMeta: false,
    isUpdatingProviderMeta: false,
    isDeletingProviderMeta: false,

    mappings: [],
    mappings_count: 0,
    isReadingMappings: false,
    isAddingMapping: false,
    isUpdatingMapping: false,
    isDeletingMapping: false,

    isAttemptingEmailParse: false,
    emailParseData: {},
    isSavingParser: false,
    emailParserID: '',
    isUpdatingMappingFields: false,

    isReadingProviderRecordNames: false,
    providerRecordNames: [],

    queryParameters: {
        page: 1,
        limit: 50,
    },  
    main_sources:[],
    isReadingMainSources: true,

    isReadingProductInterests: false,
    product_interests:[],
   
}

export default initialState;
