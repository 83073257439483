import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';

import './ImportSteps.scss';

const ImportSteps = ({ activeStep }) => (
    <Breadcrumb size='massive'>
        <Breadcrumb.Section className={`ImportSteps__step ${activeStep === 1 ? 'ImportSteps__step--active' : ''}`}>Step 1</Breadcrumb.Section>
        <Breadcrumb.Section className={`ImportSteps__step ${activeStep === 2 ? 'ImportSteps__step--active' : ''}`}>
            <Breadcrumb.Divider className='ImportSteps__divider' icon='chevron circle right' />
            Step 2
        </Breadcrumb.Section>
    </Breadcrumb>
);

export default ImportSteps;
