import React from 'react';
import { DateTimeInput, ButtonLink, STATUS_TYPES, Notification, StyledInputWrapper } from 'dyl-components';
import { Button, Form, Grid, Select, TextArea, Icon } from 'semantic-ui-react';

import selfReportActions from 'actions/self_report';
import { connect } from 'react-redux';
import UsersForm from 'shared/forms/UsersForm';
import { DateTimeUtils } from 'dyl-components';

const initialState = {
    date: '',
    time: '',
    assigned_user: null,
    direction: 'outbound',
    message: '',
    note: '',

    isOpen: false
}

class SelfReportedTextsTab extends React.Component {
    state = {
        ...initialState
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onAdd = () => {
        const { direction, date, time, note: notes, assigned_user: user_id, message: messages } = this.state;
        const { from, mainPhone, contact_id } = this.props;
        const occurred = DateTimeUtils.getUnixTime(`${date} ${time}`, DateTimeUtils.DATETIME_FORMAT);
        const payload = {
            contact_id,
            direction,
            notes,
            user_id,
            messages,

            source: from,
            occurred,
            destination: mainPhone
        };

        this.props.logText(payload).then(() => {
            Notification.alert('Successfully logged text!', STATUS_TYPES.SUCCESS, true);
            this.onClear();
            if (this.props.refresh) {
                this.props.refresh();
            }
        }).catch(() => {
            Notification.alert('Failed to log text', STATUS_TYPES.ERROR, true);
        });
    }

    onClear = () => {
        this.setState({ ...initialState, assigned_user: this.props.assigned_user });
    }

    render() {
        return (
            <Grid className='TextFormBody' stackable>
                <Grid.Row columns='equal' className='TextFormBody__Row'>
                    <Grid.Column width={8}>
                        <StyledInputWrapper>
                            <Select
                                options={[
                                    { key: 'outbound', value: 'outbound', text: 'Outbound' },
                                    { key: 'inbound', value: 'inbound', text: 'Inbound' }
                                ]}
                                value={this.state.direction}
                                onChange={this.onChange}
                                name='direction'
                                fluid
                                placeholder='Text'
                            />
                        </StyledInputWrapper>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <DateTimeInput dateFormat={'MM-DD-YYYY'} dateName='date' minDate="01-01-1970" date={this.state.date} timeName='time' time={this.state.time} onChange={this.onChange} isTimeInputOpen dateTimeTrigger={<ButtonLink className='TextFormBody__rescheduleButton'>Reschedule</ButtonLink>} />
                        <StyledInputWrapper>
                            <DateTimeInput dateFormat={'MM-DD-YYYY'} dateName='date' minDate="01-01-1970" date={this.state.date} timeName='time' time={this.state.time} onChange={this.onChange} style={{ left: 80 }} />
                        </StyledInputWrapper>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal' className='TextFormBody__Row'>
                    <Grid.Column width={8}>
                        <StyledInputWrapper>
                            <UsersForm
                                user={this.state.assigned_user}
                                name='assigned_user'
                                onChange={this.onChange}
                                user_id={this.props.assigned_user}
                                placeholder='Assigned To'
                            />
                        </StyledInputWrapper>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <StyledInputWrapper>
                            <Select
                                options={[
                                    { key: 'sent', value: 'sent', text: 'Sent' },
                                    { key: 'received', value: 'received', text: 'Received' }
                                ]}
                                value={this.state.status}
                                onChange={this.onChange}
                                name='status'
                                fluid
                                placeholder='Status'
                            />
                        </StyledInputWrapper>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal' className='TextFormBody__Row'>
                    <Grid.Column>
                        <StyledInputWrapper className='TextFormBody__textArea'>
                            <Form>
                                <TextArea value={this.state.message}
                                    onChange={this.onChange}
                                    name="message"
                                    placeholder="Add Message"
                                    inline
                                    rows={4}
                                    size='tiny' />
                            </Form>
                        </StyledInputWrapper>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal' className='TextFormBody__Row'>
                    <Grid.Column>
                        <StyledInputWrapper className='TextFormBody__textArea'>
                            <Form>
                                <TextArea
                                    value={this.state.note}
                                    onChange={this.onChange}
                                    name="note"
                                    placeholder="Add Note"
                                    inline
                                    size='tiny'
                                    rows={4}
                                />
                            </Form>
                        </StyledInputWrapper>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal' className='FormTab__Row'>
                    <Grid.Column textAlign='right' className='TextFormFooter'>
                        {!this.props.isLoggingText && <Button onClick={this.onClear} basic icon={<Icon className='far fa-eraser' />} />}
                        <Button loading={this.props.isLoggingText} onClick={this.onAdd} content='Save' primary />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    const { first_name = '', last_name = '' } = state.users.userProfile;
    let mainPhone = state.contact_phone.phone.find(item => item.main);

    if (!mainPhone) {
        mainPhone = state.contact_phone.phone[0] || { phone: 'No main phone' }
    }

    return ({
        isLoggingText: state.self_report.isLoggingText,
        from: `${first_name} ${last_name}`,
        assigned_user: state.auth.user_id,
        contact_id: Number(state.contact.contact_id),
        mainPhone: mainPhone.phone
    });
};

const mapDispatchToProps = dispatch => ({
    logText: (payload) => {
        return dispatch(selfReportActions.addTextActivity(payload));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(SelfReportedTextsTab);
