import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { Grid, Menu } from 'semantic-ui-react';

const ProductManagement = () => {
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Menu borderless pointing secondary color='primary' stackable>
                        <Menu.Item as={NavLink} name='Product Catalog' to={`product-catalog`} />
                        <Menu.Item as={NavLink} name='Bundle Catalog' to={`bundle-catalog`} />
                        <Menu.Item as={NavLink} to={`addons`}>Add-ons</Menu.Item>
                        <Menu.Item as={NavLink} name='Fees' to={`fees`} />
                        <Menu.Item as={NavLink} name='Taxes' to={`taxes`} />
                        <Menu.Item as={NavLink} name='Quote/Invoice Settings' to={`quote-invoice-settings`} />
                        <Menu.Item as={NavLink} name='Product Fields' to={`product-fields`} />
                    </Menu>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Outlet />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default ProductManagement;
