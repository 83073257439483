import contactActions from 'actions/contact';
import { CloseableContent } from 'dyl-components';
import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, Button, Popup, Header, Ref } from 'semantic-ui-react';

import ConvertContactForm from 'shared/forms/ConvertContactForm';
import ConvertToCustomerForm from 'shared/forms/ConvertToCustomerForm';
import ConvertToOpportunityForm from 'shared/forms/ConvertToOpportunityForm';

import sequenceTasksActions from 'actions/sequence_tasks';

import LostForm from 'shared/forms/LostForm';
import ConvertNewCustomerPopup from 'shared/ConvertNewCustomerPopup';
import { QuoteBuilderContext } from 'shared/context/QuoteBuilderProvider';
import UpsellCustomerPopup from 'shared/UpsellCustomerPopup';

const ConversionControls = () => {
    const modalRef = React.createRef();

    const dispatch = useDispatch();
    const { account_id, contact_id, contactName, canConvertToLead, canConvertToOpportunity, account_stage, master_source, secondary_source, pipeline_id, account_is_a_customer, account } = useSelector(state => {
        const account_stage = state.contact.current_stage?.account_stage || 'contact';
        const { master_source, secondary_source } = state.contact.current_stage || {};
        return ({
            account_id: Number(state.contact.account_id),
            contact_id: Number(state.contact.contact_id),

            contactName: `${state.contact.contact.first_name} ${state.contact.contact.last_name} ${state.contact.contact.suffix || ''}`,

            canConvertToLead: account_stage === 'contact',
            canConvertToOpportunity: account_stage === 'contact' || account_stage === 'lead',
            account_stage,

            master_source,
            secondary_source: secondary_source?.id || null,

            pipeline_id: state.contact.current_stage?.pipeline_id,
            account_is_a_customer: state.contact.account?.has_customer,
            account: state.contact.account
        })
    });

    const [conversionModalOpen, setConversionModalOpen] = useState('');
    const [isConversionFormModified, setIsConversionFormModified] = useState(false);

    const [lostFormOpen, setLostFormOpen] = useState(false);
    const [isLostFormModified, setIsLostFormModified] = useState(false);

    const onClose = (e) => {
        const clickedElement = e?.target?.className;
        const isDropdownItemClicked = clickedElement === "item" || clickedElement === "text";
        if (!isDropdownItemClicked) {
            setConversionModalOpen('');
            setIsConversionFormModified(false);
        }
    }

    const onCloseLostForm = () => {
        setLostFormOpen(false);
    }

    const refresh = () => {
        dispatch(contactActions.readContact(contact_id));
        dispatch(sequenceTasksActions.readContactTasks(contact_id));
    };

    const { onOpenAddProducts, onEditQuote } = useContext(QuoteBuilderContext);
    const recentQuote = useSelector(state => state.quote.recentQuote?.quote_version?.id);

    const onUpsell = () => {
        if (account_stage === "opportunity" && recentQuote) {
            onEditQuote(recentQuote)
        } else {
            onOpenAddProducts({ isUpsell: true });
        }
    }

    const getPopupContent = () => {
        switch (conversionModalOpen) {
            case 'lead':
                return (
                    <React.Fragment>
                        <Header color='primary'>Convert to Lead</Header>
                        <ConvertContactForm
                            size='small'
                            account_id={account_id}
                            contact_id={contact_id}
                            onClose={onClose}
                            refresh={refresh}
                            onModify={(isModified) => { setIsConversionFormModified(isModified) }}

                        />
                    </React.Fragment>
                )
            case 'opportunity':
                return (
                    <React.Fragment>
                        <Header color='primary'>Convert to Opportunity</Header>
                        <ConvertToOpportunityForm
                            size='small'
                            account_id={account_id}
                            contact_id={contact_id}
                            onClose={onClose}
                            refresh={refresh}
                            onModify={(isModified) => { setIsConversionFormModified(isModified) }}
                            account_stage={account_stage}
                            master_source={master_source}
                            secondary_source={secondary_source}
                        />
                    </React.Fragment>
                )
            case 'customer':
                return (
                    <React.Fragment>
                        <Header color='primary'>
                            Convert <Popup trigger={<span className='Convert__account-name'>{account?.name || contactName}</span>} content={account?.name || contactName} inverted /> to Customer
                        </Header>
                        <ConvertToCustomerForm
                            size='small'
                            account_id={account_id}
                            owner_id={contact_id}
                            onClose={onClose}
                            refresh={() => {
                                dispatch(contactActions.readContact(contact_id));
                            }}
                            onModify={(isModified) => { setIsConversionFormModified(isModified) }}
                            current_primary_contact_id={account?.primary_contact_id}
                            pipeline_id={pipeline_id}
                        />
                    </React.Fragment>
                )
            case 'new-customer':
                return (
                    <React.Fragment>
                        <Header color='primary'>
                            Create Customer-Account
                        </Header>
                        <ConvertToCustomerForm
                            size='small'
                            owner_id={contact_id}
                            onClose={onClose}
                            refresh={() => {
                                dispatch(contactActions.readContact(contact_id)).then(() => {
                                    onUpsell();
                                });
                            }}
                            onModify={(isModified) => { setIsConversionFormModified(isModified) }}
                            pipeline_id={pipeline_id}
                            current_primary_contact_id={contact_id}
                            action="convert-new-customer"
                            account_id={account_id}
                            isUpsellingFromQuote={recentQuote}
                        />
                    </React.Fragment>
                )
            default:
                return null;
        }
    }

    const isPrimaryContact = account?.primary_contact_id === contact_id;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    
    return (
        <React.Fragment>
            <Popup
                content={(
                    <CloseableContent onClose={onClose}>
                        {getPopupContent()}
                    </CloseableContent>
                )}
                open={conversionModalOpen}
                position='bottom center'
                context={modalRef}
                closeOnDocumentClick={!isConversionFormModified}
                onClose={onClose}
            />
            <Ref innerRef={modalRef}>
                <Dropdown as={Button} onClose={() => {setIsDropdownOpen(false)}} onOpen={() => {setIsDropdownOpen(true)}} size='tiny' color='blue' text='Convert' style={{ background: '#2635DE' }}>
                    <Dropdown.Menu>
                        {canConvertToLead && (
                            <Dropdown.Item onClick={() => { setConversionModalOpen('lead') }}>
                                Convert to Lead
                            </Dropdown.Item>
                        )}
                        {canConvertToOpportunity && (
                            <Dropdown.Item onClick={() => { setConversionModalOpen('opportunity') }}>
                                Convert to Opportunity
                            </Dropdown.Item>
                        )}
                        {!account_is_a_customer ? (
                            <Dropdown.Item onClick={() => { setConversionModalOpen('customer') }}>
                                Convert to Customer
                            </Dropdown.Item>
                        ) : [
                            account_stage === 'lead' ? (
                                <Dropdown.Item disabled>
                                    Upsell Existing Customer
                                </Dropdown.Item>
                            ) : (
                                <UpsellCustomerPopup 
                                    open={isDropdownOpen && recentQuote}
                                    position={"left center"}
                                    trigger={(
                                        <Dropdown.Item
                                            style={{ width: 195 }}
                                            onClick={onUpsell}
                                        >
                                            Upsell Customer
                                        </Dropdown.Item>
                                    )}
                                />
                            ),
                            <ConvertNewCustomerPopup 
                                open={isDropdownOpen && (isPrimaryContact)}
                                isPrimaryContact={isPrimaryContact}
                                account_id={account_id}
                                trigger={(
                                    <Dropdown.Item
                                        disabled={isPrimaryContact}
                                        onClick={() => {
                                            setConversionModalOpen(
                                                "new-customer"
                                            );
                                        }}
                                    >
                                        Convert New Customer
                                    </Dropdown.Item>
                                )}
                            />
                        ]}
                    </Dropdown.Menu>
                </Dropdown>
            </Ref>
            {account_stage !== 'contact' && (
                <Popup
                    trigger={(
                        <Button size='tiny' color='red'>
                            Lost
                        </Button>
                    )}
                    content={(
                        <CloseableContent onClose={onCloseLostForm}>
                            <LostForm
                                account_id={account_id}
                                account_stage={account_stage}
                                contact_id={contact_id}
                                pipeline_id={pipeline_id}
                                refresh={() => {
                                    dispatch(contactActions.readContact(contact_id));
                                }}
                                onModify={(isModified) => { setIsLostFormModified(isModified) }}
                            />
                        </CloseableContent>
                    )}
                    on='click'
                    open={lostFormOpen}
                    position='bottom center'
                    closeOnDocumentClick={!isLostFormModified}
                    onClose={() => {
                        if (!lostFormOpen) {
                            onCloseLostForm();
                        }
                    }}
                    onOpen={() => { setLostFormOpen(true) }}
                />
            )}
        </React.Fragment>
    );
};

export default ConversionControls;

