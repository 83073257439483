import React from 'react';

export const ReportsAndLogsType = ({ id, method }) => {
  return (
    <>
        { method && <div><strong>{ method }</strong></div>}
        { id && <div>ID: { id }</div>}
    </>
  )
}
