import React from 'react';
import { Grid, Button } from 'semantic-ui-react';

const CallQueuesHeader = ({ onOpenAddCallQueuesSection }) => (
    <Grid verticalAlign='middle'>
        <Grid.Column width={6}>
            Call Queues
        </Grid.Column>
        <Grid.Column width={4} textAlign='right' floated='right'>
        <Button onClick={onOpenAddCallQueuesSection} size='tiny' basic inverted color='white'>Add Call Queue</Button>
        </Grid.Column>
    </Grid>
)

export default CallQueuesHeader;
