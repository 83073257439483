import React, { useContext } from 'react';
import { Table as SemanticTable } from 'semantic-ui-react';
import { ButtonLink, ClippedContent, DateTimeUtils, Person, Table } from 'dyl-components';

import { QuoteBuilderContext } from 'shared/context/QuoteBuilderProvider';

const QuotesTableSubrow = ({ versions }) => {
    const { onViewProposal } = useContext(QuoteBuilderContext);

    return (
        <Table.CollapsibleRowContent>
            <Table.Cell width={1} />
            <Table.Cell colSpan={12}>
                <div className="QuotesTable__QuoteVersionsTableContainer">
                    <Table striped={false}>
                        <SemanticTable.Header className='Table__header--primary'>
                            <SemanticTable.Row>
                                <SemanticTable.HeaderCell width={5}>
                                    Proposal
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    Created
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    Created By
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    Sent Date
                                </SemanticTable.HeaderCell>
                            </SemanticTable.Row>
                        </SemanticTable.Header>
                        <Table.Body>
                            {versions.map(version => (
                                <Table.Row>
                                    <SemanticTable.Cell>
                                        <ButtonLink onClick={() => { onViewProposal(version.id) }}><ClippedContent>{version.name}</ClippedContent></ButtonLink>
                                    </SemanticTable.Cell>
                                    <SemanticTable.Cell>
                                        <div>
                                            <b>
                                                {DateTimeUtils.formatEpoch(
                                                    version.created,
                                                    DateTimeUtils.WORD_DATE_FORMAT
                                                )}
                                            </b>
                                        </div>
                                    </SemanticTable.Cell>
                                    <SemanticTable.Cell>
                                        <Person
                                            username={version.created_by}
                                            email={version.created_by_email}
                                        />
                                    </SemanticTable.Cell>
                                    <SemanticTable.Cell>
                                        {version.sent_date > 0 && (<div>
                                            <b>
                                                {DateTimeUtils.formatEpoch(
                                                    version.sent_date,
                                                    DateTimeUtils.WORD_DATE_FORMAT
                                                )}
                                            </b>
                                        </div>)}
                                    </SemanticTable.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </Table.Cell>
        </Table.CollapsibleRowContent>
    );
}

export default QuotesTableSubrow;
