import React from 'react';
import { Modal } from 'dyl-components';

const EntriesModal = ({ info, open, onClose }) => {
    return (
        <Modal open={open} onClose={onClose} size='mini'>
            <Modal.Header>
                {info.name}
            </Modal.Header>
            <Modal.Content>
                <div style={{ marginBottom: 10 }}>
                    <span>
                        <b>Created On: </b>
                    </span>
                    <span>
                        {info.createdOn}
                    </span>
                </div>
                <div style={{ marginBottom: 10 }}>
                    <span>
                        <b>Last Edited: </b>
                    </span>
                    <span>
                        {info.lastEdited}
                    </span>
                </div>
                <div style={{ marginBottom: 10 }}>
                    <span>
                        <b>Total Entries: </b>
                    </span>
                    <span>
                        {info.entries}
                    </span>
                </div>
                <div style={{ marginBottom: 10 }}>
                    <span>
                        <b>Total converted to opportunities: </b>
                    </span>
                    <span>
                        {info.opportunities}
                    </span>
                </div>
                <div style={{ marginBottom: 10 }}>
                    <span>
                        <b>Total converted to customers: </b>
                    </span>
                    <span>
                        {info.customers}
                    </span>
                </div>
            </Modal.Content>
        </Modal>
    )
};

export default EntriesModal;
