import { DateTimeUtils, DropdownTimeInput, NestedDropdown } from "dyl-components";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Form, Icon, Label } from "semantic-ui-react";
import RemoveUndoToggle from "./RemoveUndoToggle";

const Timeframe = ({ control, isReadingDestinations, destinationOptions, index, isDeleteVisible, provided, watch, trigger, formState, excluded_parents }) => {
    const PARENT_NAME = `timeframes[${index}]`;
    const {isBeingDeleted, end_time} = watch(`${PARENT_NAME}`);
    const [isAfterError, setIsAfterError] = useState(formState?.errors?.timeframes?.[index]?.end_time?.type === "is-after");

    useEffect(() => {
        const isAfterErrorAux = formState?.errors?.timeframes?.[index]?.end_time?.type === "is-after";
        setIsAfterError(isAfterErrorAux);
    }, [formState, index])

    return (
        <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className="CallRoutingRule__ruleContainer"
        >
            <div
                {...(!isBeingDeleted && {...provided.dragHandleProps})}
                className="CallRoutingRule___elementContainer"
            >
                    <Icon className="fa-solid fa-arrows-up-down-left-right CallRoutingRuleModal__greyIcon" size={"large"}/>
            </div>
            <Controller
                name={`${PARENT_NAME}.days`}
                control={control}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <Form.Select
                        name={name}
                        value={value}
                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                        placeholder='Select days'
                        options={DateTimeUtils.DAYS.map((day, idx) => ({key: idx, value: idx, text: day}))}
                        label="Days"
                        className="CallRoutingRules__timeframeField"
                        required
                        multiple
                        error={!isBeingDeleted && error?.message}
                        renderLabel={(item) => {
                            const { text: itemText, value: itemValue  } = item;
                            return <Label>
                                {itemText.substring(0, 3)}
                                <Icon 
                                    name="delete" 
                                    onClick={() => {
                                        onChange({target: {name, value: value.filter((day) => day !== itemValue)}})
                                    }}
                                />
                            </Label>
                        }}
                        disabled={isBeingDeleted}
                    />
                )}
            />
            <div className="CallRoutingRules__timesContainer">
                <div className="CallRoutingRules__timeControllersContainer">
                    <Controller
                        name={`${PARENT_NAME}.start_time`}
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Field
                                control={DropdownTimeInput}
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } });
                                    if (!!end_time) {
                                        trigger(`${PARENT_NAME}.end_time`)
                                    }
                                }}
                                placeholder='Select start time'
                                error={!isBeingDeleted ? !isAfterError ? error?.message : true : false}
                                value={value}
                                label="Start Time"
                                required
                                className="CallRoutingRules__timeField"
                                disabled={isBeingDeleted}
                            />
                        )}
                    />
                    <Controller
                        name={`${PARENT_NAME}.end_time`}
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Field
                                control={DropdownTimeInput}
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } });
                                }}
                                placeholder='Select end time'
                                error={!isBeingDeleted ? !isAfterError ? error?.message : true : false}
                                value={value}
                                label="End Time"
                                required
                                className="CallRoutingRules__timeField"
                                disabled={isBeingDeleted}
                            />
                        )}
                    />
                </div>
                {isAfterError && !isBeingDeleted && (
                    <Label pointing={"above"} className="CallRoutingRules__errorLabel">Start Time must be before End Time</Label>
                )}
            </div>
            <Controller
                name={`${PARENT_NAME}.destination_parent`}
                control={control}
                render={({ field: { name: parentName, value: parentValue, onChange: onParentChange } }) => (
                    <Controller 
                        name={`${PARENT_NAME}.destination`}
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                            const { name: childName, value: childValue, onChange: onChildChange } = field;
                            return (
                                <Form.Field
                                    control={NestedDropdown}
                                    child_value={childValue}
                                    parent_value={parentValue}
                                    loading={isReadingDestinations}
                                    nested_options={destinationOptions}
                                    onChange={(_, { parent_value, child_value }) => {
                                        onParentChange({ target: { name: parentName, value: parent_value } });
                                        onChildChange({ target: { name: childName, value: child_value } });
                                    }}
                                    placeholder="Select destination"
                                    display_parent
                                    selection
                                    label="Destination"
                                    pointing='top'
                                    required
                                    error={!isBeingDeleted && error?.message}
                                    className="CallRoutingRules__timeframeField CallRoutingRules__destinationField"
                                    excluded_parents={excluded_parents}
                                    disabled={isBeingDeleted}
                                />
                            )
                        }}
                    />
                )}
            />
            {(isDeleteVisible || isBeingDeleted) && (
                <Controller
                    name={`${PARENT_NAME}.isBeingDeleted`}
                    control={control}
                    render={({ field: { name, value, onChange }}) => (
                        <Form.Field
                            control={RemoveUndoToggle}
                            onChange={(_, value) => {
                                onChange({ target: { name, value } });
                            }}
                            value={value}
                        />
                    )}
                />
            )}
        </div>
    )
}

export default Timeframe;
