import React, { useContext } from 'react';
import { Grid, Icon, Dropdown, Button } from 'semantic-ui-react';

import { Table, LinkedAccount, TableLoader } from 'dyl-components';
import { StringUtils } from 'utils';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import accountActions from 'actions/account';
import InfluenceField from 'pages/Account/InfluenceField';

import { ContactContext } from 'shared/context/ContactProvider';

const CONTACT_STATUS_COLORS = {
    active: 'teal',
    convert: 'blue',
    deactivated: 'red'
}

const mapStateToProps = state => ({
    contactBeingUpdated: state.contact.contactBeingUpdated,
})

export const Contact = connect(mapStateToProps)(({
    first_name,
    last_name,
    id,
    email,
    phone,
    influence,
    module_summary,
    job_title,

    status,
    pinned,
    primary,

    onEdit,
    onCancelEdit,
    contactBeingEdited,

    contactBeingUpdated
}) => {
    const name = `${first_name} ${last_name}`;

    const dispatch = useDispatch();

    const [params] = useSearchParams();

    const { account_id } = useParams();

    const refresh = () => {
        dispatch(accountActions.readContacts(account_id, {
            ...Object.fromEntries(params),
            filter: params.get('role')
        }));
        dispatch(accountActions.readAccount(account_id));
    }

    const isUpdating = contactBeingUpdated === id;

    const { onOpenModal } = useContext(ContactContext);

    return (
        !isUpdating ? (
            <Table.Row>
                <Table.Cell>
                    {pinned && <Icon color='primary' link className='fas thumbtack' />}
                    {primary && <Icon color='primary' className='fas fa-address-book' />}
                </Table.Cell>
                <Table.Cell>
                    <Grid columns='equal' verticalAlign='middle'>
                        <Grid.Column>
                            <LinkedAccount
                                maxWidth={'9em'}
                                account={name}
                                subtitle={job_title}
                                linkToAccount={<Link target={'_blank'} to={`/contact/${id}`}>{name}</Link>}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Icon disabled={!phone?.phone} link color='primary' className='fas fa-phone' />
                            <Icon disabled={!phone?.phone} link color='primary' className='fas fa-comment' />
                            <Icon disabled={!email?.email} link color='primary' className='fas fa-envelope'
                                onClick={() => {
                                    onOpenModal('email', id);
                                }}
                            />
                            <Icon link color='primary' className='fa-sharp fa-solid fa-pen-clip'
                                onClick={() => {
                                    onOpenModal('note', { id, first_name, last_name });
                                }}
                            />
                        </Grid.Column>
                    </Grid>
                </Table.Cell>
                <Table.Cell>
                    <InfluenceField
                        account_id={account_id}
                        contactBeingEdited={contactBeingEdited}
                        id={id}
                        influence={influence}
                        onCancelEdit={onCancelEdit}
                        onEdit={onEdit}
                        refresh={refresh}
                    />
                </Table.Cell>
                <Table.Cell>{Object.keys(module_summary).length > 1 ? `${StringUtils.capitalize(module_summary.account_stage)}-${module_summary?.pipeline_stage_name}` : ''}</Table.Cell>
                <Table.Cell>
                    <Dropdown
                        options={[
                            {
                                key: 'active', value: 'active', text: 'Active'
                            },
                            {
                                key: 'convert', value: 'convert', text: 'Convert'
                            },
                            {
                                key: 'deactivated', value: 'deactivated', text: 'Deactivated'
                            }
                        ]}
                        as={Button}
                        color={CONTACT_STATUS_COLORS[status]}
                        value={status}
                        fluid
                        size='tiny' />
                </Table.Cell>
            </Table.Row>
        ) : (
            <TableLoader isLoading colspan={5} />
        )
    );
})
