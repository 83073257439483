export default class DataLogUtils {

    static errorTypes = [
        { code: 1, name: "Parsing Email", description: "The email does not have a proper to address or that it doesn't follow the proper format." },
        { code: 2, name: "No Customer", description: "The to address does not match up with any customer." },
        { code: 3, name: "No Parser", description: "The subject or from address does not match any parsers." },
        { code: 4, name: "Parsing Pairs", description: "The parser used to pull pairs for the email was not able to pull any pairs." },
        { code: 5, name: "Unknown", description: "Generic error caused by incoming data that is not compatiable with infrastructure." },
        { code: 6, name: "No Mapping", description: "While parser matched there is not record_id that maps the fields pulled out." },
        { code: 7, name: "Mismatched", description: "Fields like phone number or email are wrong." },
        { code: 8, name: "Missing Data", description: "Fields like phone number or email are missing." },
        { code: 9, name: "Read Failure", description: "Unable to read fields or save mappings." },
        { code: 10, name: "Incomplete", description: "Unable to map to record. Minimum fields for a contact are likely required." },
        { code: 11, name: "Validation", description: "Does not meet required field validation." },
        { code: 12, name: "System", description: "Unable to process request. " },
        { code: 13, name: "Rejected Record", description: "Unable to add a record to the system." },
    ]

    static getErrorType = (code) => this.errorTypes.find((errorType) => code === errorType.code) || { code: 0, name: "Unknown", description: "Generic error caused by incoming data that is not compatiable with infrastructure." };

    static getErrorTypeOptions = () => this.errorTypes.map(errorType => ({ key: errorType.code, value: errorType.code, text: `${errorType.code}: ${errorType.name}` }));

    static getAllErrorCodes = () => this.errorTypes.map(errorType => errorType.code);
}
