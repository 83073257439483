import { Form, Header } from "semantic-ui-react";
import PaymentFrequency from "./PaymentFrequency";
import { StringUtils } from "utils";
import PriceField from "./PriceField";
import { Controller, useFormContext } from "react-hook-form";

const getMonthlyPrice = (frequency, price) => {
    if (isNaN(price)) {
        return "";
    }
    const monthlyPrice = (() => {
        if (frequency === "monthly") {
            return price;
        }
        if (frequency === "quarterly") {
            return price / 3;
        }
        if (frequency === "semi-annually") {
            return price / 6;
        }
        if (frequency === "annually") {
            return price / 12;
        }
        return 0;
    })();
    return Number(monthlyPrice).toFixed(2);
};

const FixedRecurringFields = () => {
    const { control } = useFormContext();
    return ["monthly", "quarterly", "semi-annually", "annually"].map(
        (frequency) => {
            const parentName = `fixed_recurring`;
            const priceFieldName = `price_data.${parentName}_${frequency}_price`;
            return (
                <PaymentFrequency
                    parentName={parentName}
                    frequency={frequency}
                    key={frequency}
                    content={
                        <div>
                            <Header color="primary">
                                {StringUtils.capitalize(frequency)}
                            </Header>
                            <Form.Group widths={"equal"}>
                                <PriceField
                                    label={"Price Per Item"}
                                    name={priceFieldName}
                                />
                                <Controller
                                    control={control}
                                    name={priceFieldName}
                                    render={({ field: { value } }) => (
                                        <Form.Field
                                            control="div"
                                            label="Per Month"
                                        >
                                            ${getMonthlyPrice(frequency, value)}
                                        </Form.Field>
                                    )}
                                />
                            </Form.Group>
                        </div>
                    }
                />
            );
        }
    );
};

export default FixedRecurringFields;
