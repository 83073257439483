import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function leadsTabReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.LEADS_TAB, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingLeads: true, leads: [], count: 0 };
        case getNamedAction(ACTION_NAMES.LEADS_TAB, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingLeads: false, leads: (action.data.data || []).map(lead => ({ ...lead })), queryParameters: action.queryParameters, count: action.data.count };
        case getNamedAction(ACTION_NAMES.LEADS_TAB, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingLeads: false };

        default:
            return state;
    }
}


export default leadsTabReducer;
