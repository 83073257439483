import React from 'react';
import { Table, MessagingIcon, ControlledPopup } from 'dyl-components';
import { connect } from 'react-redux';

import taskTypesActions from 'actions/task_types';

const TaskTypeRow = ({ 
    taskType,  
    onDelete, 
    isDeleting
}) => (
    <Table.Row loading={isDeleting}>
        <Table.Cell>
            {taskType.name}
        </Table.Cell>
        <Table.Cell>
            <ControlledPopup 
                trigger={<MessagingIcon icon='trash'  />}
                onConfirm={() => {onDelete(taskType.id)}}
                content={'Delete'}
                inverted
            />
        </Table.Cell>
    </Table.Row>
)

const mapStateToProps = state => ({
    isDeleting: state.task_types.isDeleting
})

const mapDispatchToProps = dispatch => ({
    onDelete: (id) => {
        dispatch(taskTypesActions.deleteTaskType(id))
            .then(() => {
                dispatch(taskTypesActions.readTaskTypes());
            });
    } 
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskTypeRow);
