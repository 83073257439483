import React from 'react'
import { useSelector } from 'react-redux';
import { useSearchParams, Link } from 'react-router-dom';
import { RecordsTable } from 'dyl-components';

import TableHeader from './TableHeader';
import TableRow from './TableRow';
import { StringUtils } from 'utils';

const Table = ({ onPageChange, type })  => {
    const selectorType = type === 'tax' ? 'taxes' : 'fees';

    const { count, fees, isReadingFees } = useSelector(state => ({
        count: state.product_fees[`${selectorType}Count`],
        fees: state.product_fees[selectorType],
        isReadingFees: state.product_fees[`isReading${StringUtils.capitalize(selectorType)}`]
    }));

    const [params] = useSearchParams();

    return (
        <RecordsTable
            tableBodyClassName='FeesTable'
            isTableLoading={isReadingFees}
            isSearchResultEmpty={false}
            isTableEmpty={count === 0}
            TableHeader={<TableHeader type={type} />}
            TableRows={fees.map(fee => (
                <TableRow type={type} key={fee.id} fee={fee} />
            ))}
            recordsCount={count}
            activePage={params.get('page')}
            onPageChange={onPageChange}
            emptyListTitle={`No ${type === "tax" ? "Taxes" : "Fees"} Added`}
            emptySubtitle={(
                <span>
                    Add a {type} by clicking on the &nbsp;<Link to={`/settings/product-catalog-quotes-invoices/${type === "tax" ? "taxes" : "fees"}/create`}>+ New {StringUtils.capitalize(type)}</Link>&nbsp; button on the top-right corner.
                </span>
            )}
        />
    );
}

export default Table;
