import React from 'react';
import DestinationGroupsHeader from './DestinationGroupsHeader';
import DestinationGroupsRow from './DestinationGroupsRow';
import { useSelector } from 'react-redux';
import { EmptyListPlaceholder, Table, TableLoader } from 'dyl-components';

const DestinationGroupsTable = ({ isFiltering, onDelete, onSelect }) => {
    const { destinationGroupsCount: count, isReadingDestinationGroups, destinationGroups, isReadingDestinationGroup } = useSelector((state) => state.pbx_config);

    return <Table striped={false} className="CustomFieldsTable" fixed >
        <DestinationGroupsHeader />
        <Table.Body>
            {!count && !isReadingDestinationGroups && !isReadingDestinationGroup &&
                <Table.Row>
                    <Table.Cell colspan={6} style={{padding: "30px 60px 30px 60px"}}>
                        <EmptyListPlaceholder 
                            wrapped={false}
                            name="Destination Group"
                            subtitleHidden 
                            {...(isFiltering ? { title: "No results found" } : {})} 
                        />
                    </Table.Cell>
                </Table.Row>
            }
            {!isReadingDestinationGroups && !isReadingDestinationGroup && count && destinationGroups?.map((destinationGroup, index) => 
                <DestinationGroupsRow
                    key={index}
                    destinationGroup={destinationGroup}
                    onDelete={onDelete}
                    onSelect={onSelect}
                />
            )}
            {(isReadingDestinationGroups || isReadingDestinationGroup) && (
                <TableLoader isLoading colspan={6} />
            )}
        </Table.Body>
    </Table>
}

export default DestinationGroupsTable;