import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function supplyChainNetworkReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.SUPPLY_CHAIN_NETWORK, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingFields: true, fields: [] };
        case getNamedAction(ACTION_NAMES.SUPPLY_CHAIN_NETWORK, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingFields: false, fields: action.data };
        case getNamedAction(ACTION_NAMES.SUPPLY_CHAIN_NETWORK, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingFields: false, fields: [] };

        case getNamedAction(ACTION_NAMES.SUPPLY_CHAIN_NETWORK, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, fieldBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.SUPPLY_CHAIN_NETWORK, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, fieldBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.SUPPLY_CHAIN_NETWORK, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, fieldBeingUpdated: null };

        default:
            return state;
    }
}


export default supplyChainNetworkReducer;
