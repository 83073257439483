import React from 'react';
import { Table } from 'dyl-components';
import { connect } from 'react-redux';
import TagRow from './TagRow';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';

const TagsTable = ({ callTags, categoryOptions, isReadingTags }) => {
    if (isReadingTags) {
        return (
            <Segment>
                <Dimmer active>
                    <Loader active />
                </Dimmer>
            </Segment>
        )
    }
    if (callTags == null || callTags.length === 0) {
        return (
            <Segment textAlign='center'>
                <em>There are no tags</em>
            </Segment>
        )
    }
    return (
        <Table loading={isReadingTags}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Category</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Items</Table.HeaderCell>
                    <Table.HeaderCell>Options</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {callTags.map(callTag => (
                    <TagRow
                        key={callTag.id}
                        callTag={callTag}
                        categoryOptions={categoryOptions}
                    />
                ))}
            </Table.Body>
        </Table>
    )
};

const mapStateToProps = state => {
    return {
        callTags: state.tags.listOfTags,
        isReadingTags: state.tags.isGettingListOfTags
    }
}

export default connect(mapStateToProps, null)(TagsTable);
