import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function productInterestsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PRODUCT_INTEREST_OPTIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingProductInterestOptions: true };
        case getNamedAction(ACTION_NAMES.PRODUCT_INTEREST_OPTIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingProductInterestOptions: false, productInterestOptions: action.data.data, productInterestOptionCount: action.data.count };
        case getNamedAction(ACTION_NAMES.PRODUCT_INTEREST_OPTIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingProductInterestOptions: false, productInterestOptions: [], productInterestOptionCount: 0 };

        default:
            return state;
    }
}

export default productInterestsReducer;
