import React, { useState, useMemo } from 'react';
import { Grid, Icon, Form, Portal } from 'semantic-ui-react';
import { useForm, useFieldArray } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import Field from './subcomponents/Field';
import { Link } from 'react-router-dom';

import './index.scss';
import { SettingsFooter, Button } from 'dyl-components';
import useWidthListener from 'shared/SettingsFooter/useWidthListener';
import useWindowWidth from 'shared/SettingsFooter/useWindowWidth';
import { MathUtils } from 'utils';

const DynamicFields = ({ fields = [], values = [], name, addLabel = '', isValid: isValidSecond = true, onAdd, onCheckDuplicate }) => {
    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();

    const fieldArrayName = `new_${name}`;

    const getDefaultProp = () => {
        return {
            isChecking: false, deleted: false, hasDuplicate: false, lastLabelChecked: '', duplicateMessage: '',
            checkDuplicateTimeoutHandler: null,
            startCheckDuplicateTimeout: function (checkDuplicate) {
                this.checkDuplicateTimeoutHandler = setTimeout(() => {
                    checkDuplicate();
                }, 750)
            },
            clearCheckDuplicateTimeout: function () {
                clearTimeout(this.checkDuplicateTimeoutHandler)
            }
        }
    }

    const fieldDuplicateProp = useMemo(() => getDefaultProp(), []);

    let mappedValues = [];
    let fieldDuplicateProps = [];

    if (values.length >= 1) {
        mappedValues = [...values.map(val => ({ value: val })), { value: fields.map(({ defaultValue }) => defaultValue) }];
        fieldDuplicateProps = mappedValues.map(() => ({ ...fieldDuplicateProp, id: uuid() }));
    } else {
        mappedValues = [{ value: fields.map(({ defaultValue }) => defaultValue) }];
        fieldDuplicateProps = [{ ...fieldDuplicateProp, id: uuid() }];
    }

    const [fieldsDuplicateProps, setFieldsDuplicateProps] = useState(fieldDuplicateProps);

    const { control, formState: { isValid, isDirty }, trigger, watch, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            [fieldArrayName]: mappedValues
        }
    });

    const { fields: newItems, append, remove, update } = useFieldArray({
        control,
        name: fieldArrayName,
        keyName: 'labelId'
    });


    const getProps = (index) => {
        let props = {
            label: fields[index].label || '',
            type: fields[index].type || '',
            placeholder: fields[index].placeholder || '',
            options: fields[index].options || [],

            required: fields[index].required,
            maxLength: fields[index].maxLength || 41
        }

        return props;
    }

    const watchNewItems = watch(fieldArrayName);

    const modifyCheckForDuplicateProps = (action = '', id, value) => {
        let index = fieldsDuplicateProps.findIndex(item => item.id === id);
        switch (action) {
            case 'update_lastLabelChecked':
                setFieldsDuplicateProps(arr => {
                    if (arr && arr[index]) {
                        arr[index].lastLabelChecked = value;
                    }
                    return [...arr];
                });
                break;
            case 'update_isChecking':
                setFieldsDuplicateProps(arr => {
                    if (arr && arr[index]) {
                        arr[index].isChecking = value;
                    }
                    return [...arr];
                });
                break;
            case 'update_hasDuplicate':
                setFieldsDuplicateProps(arr => {
                    if (arr && arr[index]) {
                        arr[index].hasDuplicate = value.hasDuplicate;
                        arr[index].duplicateMessage = value.duplicateMessage;
                    }

                    return [...arr];
                });
                break;
            case 'update_checkDuplicateTimeoutHandler':
                setFieldsDuplicateProps(arr => {
                    arr[index].clearCheckDuplicateTimeout();
                    arr[index].startCheckDuplicateTimeout(value);
                    return [...arr];
                });
                break;
            case 'add':
                setFieldsDuplicateProps(arr => [...arr, { ...fieldDuplicateProp, id: uuid() }]);
                break;
            case 'remove':
                setFieldsDuplicateProps(arr => {
                    arr[index].deleted = true;
                    arr[index].isChecking = false;
                    return [...arr];
                });
                break;
            case 'clear_timeout':
                setFieldsDuplicateProps(arr => {
                    arr[index].clearCheckDuplicateTimeout();
                    return [...arr];
                });
                break;
            default:
        }
    }

    const getFieldDuplicateProps = (itemIndex, prop = '') => {
        return fieldsDuplicateProps.filter(item => !item.deleted)[itemIndex][prop]
    }

    const checkDuplicateStatus = () => {
        return fieldsDuplicateProps.some(({ hasDuplicate, isChecking, deleted }) => isChecking === true || (hasDuplicate === true && deleted === false));
    }

    const onCheckLabelDuplicate = async (value, fieldName, itemIndex, valueIndex, checkDuplicateId) => {
        let hasDuplicate = false;
        let duplicateMessage = '';

        const updateDuplicateProps = () => {
            modifyCheckForDuplicateProps('update_lastLabelChecked', checkDuplicateId, value);
            modifyCheckForDuplicateProps('update_hasDuplicate', checkDuplicateId, { hasDuplicate, duplicateMessage });
            trigger(fieldName);
        }

        if (value && value?.trim()) {
            if (onCheckDuplicate) {
                await onCheckDuplicate(value.replace(/\s+/g, ' ').trim()).then(() => {
                    hasDuplicate = true;
                    duplicateMessage = 'This field has a duplicate in the existing record';
                    updateDuplicateProps();
                }, () => { })
            }

            if (hasDuplicate) {
                return;
            }

            let newValue = value.replace(/\s+/g, ' ').toLowerCase().trim();
            let values = watchNewItems
                .map(({ value }) => value[valueIndex].replace(/\s+/g, ' ').toLowerCase().trim())
                .filter(value => value === newValue);

            hasDuplicate = values.length > 1;
            duplicateMessage = 'This field has a duplicate in the list of items being added';

            updateDuplicateProps();
        } else {
            updateDuplicateProps();
        }
    }

    const onAddLabel = () => {
        append({ value: fields.map(({ defaultValue }) => defaultValue) });
        modifyCheckForDuplicateProps('add');
    }

    const onDelete = (index) => {
        if (newItems.length > 1 && ((values.length + 1) !== newItems.length)) {
            remove(index);
            modifyCheckForDuplicateProps('remove', getFieldDuplicateProps(index, 'id'));
        } else {
            update(index, { value: fields.map(({ defaultValue }) => defaultValue) })
        }
    }

    const onSubmit = (data) => {
        if (onAdd) {
            onAdd([...data[fieldArrayName]]);
        }
    }

    const isDisabled = (index) => {
        const isExisting = values.map(value => value[0]).includes(newItems[index].value[0]);
        return isExisting && !getFieldDuplicateProps(index, 'isChecking') && !getFieldDuplicateProps(index, 'hasDuplicate');
    }

    return (
        <>
            <Grid className='DynamicFields' as={Form}>
                {newItems.map((item, itemIndex) => (
                    <Grid.Row key={item.labelId} className='DynamicFields__fieldRow'>
                        {item.value.map((value, valueIndex) => (
                            <Grid.Column width={fields[valueIndex].width} stretched>
                                <Field
                                    {...getProps(valueIndex)}

                                    itemIndex={itemIndex}
                                    valueIndex={valueIndex}
                                    value={value}
                                    name={fieldArrayName}

                                    control={control}
                                    isDirty={isDirty}

                                    shouldRenderDeleteButton={!isDisabled(itemIndex) && item.value.length === valueIndex + 1}
                                    deleteButtonAction={() => { onDelete(itemIndex); }}


                                    checkDuplicate={onCheckLabelDuplicate}
                                    getFieldDuplicateProps={getFieldDuplicateProps}
                                    modifyCheckForDuplicateProps={modifyCheckForDuplicateProps}
                                    isDisabled={isDisabled(itemIndex)}
                                />
                            </Grid.Column>
                        ))}
                    </Grid.Row>
                ))}
                <Grid.Row>
                    <Grid.Column className='DynamicFields__addButton' onClick={onAddLabel}>
                        <Icon name='plus' /> {addLabel}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Portal open>
                <SettingsFooter
                    style={{ width: MathUtils.calculatePercentage(windowWidth, windowWidth - width) }}
                    className={`Webform__menu`}
                    rightOptions={[
                        <Button as={Link} to='/settings/data-providers' status={'cancel'} basic>Cancel</Button>,
                        <Button disabled={!isValid || !isValidSecond || checkDuplicateStatus()} onClick={handleSubmit(onSubmit)}>Save</Button>
                    ]}
                />
            </Portal>
        </>
    )
}

export default DynamicFields;
