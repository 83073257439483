import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Form } from 'semantic-ui-react';
import { CustomGroupUtils } from 'utils';
import { Controller } from 'react-hook-form';

const ContactHouseholdData = ({
    isEditing,
    control,

    textAlign = 'right'
}) => {
    const { householdTypeOptions, household_details } = useSelector(state => {
        const custom_data = CustomGroupUtils.groupAndFlatten(state.contact.custom_data);
        const household_details = (custom_data.children?.household_details || [])[0];
        
        const standard_groups = (state.contact.custom_data?.children || []).filter(group => group.standard);
        return {
            householdTypeOptions: CustomGroupUtils.getFieldOptions((standard_groups.find(group => group.group_name === 'household_details')?.fields || []), 'household_type'),
            household_details: household_details?.fields
        };
    });

    return (
        <Grid className='RecordInfo'>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Name
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        household_details?.household_name || 'None'
                    ) : (
                        <Controller
                            name='household_name'
                            control={control}
                            render={({ field: { name, onChange, value } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Enter household name'
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Type
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        householdTypeOptions.find(type => type.value === household_details?.household_type)?.text || 'None'
                    ) : (
                        <Controller
                            name='household_type'
                            control={control}
                            render={({ field: { name, onChange, value } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Select household type'
                                    selectOnBlur={false}
                                    options={householdTypeOptions}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Members
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        household_details?.household_members_count || 0
                    ) : (
                        <Controller
                            name='household_members'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    type='number'
                                    step='1'
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Number of Children
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        household_details?.number_of_children || 0
                    ) : (
                        <Controller
                            name='number_of_children'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    type='number'
                                    step='1'
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Annual Income
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        household_details?.annual_income || 'None'
                    ) : (
                        <Controller
                            name='annual_household_income'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default ContactHouseholdData;

