import React from 'react';
import { Table, EllipsisDropdown, ControlledPopup } from 'dyl-components';
import { Dropdown, Icon } from 'semantic-ui-react';

import HorizontalFlexList from './HorizontalFlexList';

const TerritoryRow = ({ name, territoryLimit, teams, onEdit, onDelete }) => {
    const hasTeams = teams.length > 0;
    
    return (
        <Table.Row>
            <Table.Cell>
                <b>{name}</b>
            </Table.Cell>
            <Table.Cell>
                <HorizontalFlexList
                    limit={territoryLimit}
                    entries={teams} />
            </Table.Cell>
            <Table.Cell>
                <EllipsisDropdown>
                    <Dropdown.Item
                        onClick={onEdit}
                        text={<React.Fragment>
                            <Icon name='pencil' />
                            Edit
                        </React.Fragment>}
                        style={{ cursor: 'pointer' }} />
                    <ControlledPopup 
                        trigger={
                            <Dropdown.Item
                                disabled={hasTeams}
                                text={<React.Fragment>
                                    <Icon name='trash' />
                                    Delete
                                </React.Fragment>}
                                style={{ cursor: 'pointer' }} />
                        }
                        onConfirm={onDelete}
                        inverted
                    />
                </EllipsisDropdown>
            </Table.Cell>
        </Table.Row>
    )
}

export default TerritoryRow;
