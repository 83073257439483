const READ_LEADS_IN_LEAD_CONNECT_LIST_REQUEST = 'READ_LEADS_IN_LEAD_CONNECT_LIST_REQUEST';
const READ_LEADS_IN_LEAD_CONNECT_LIST_SUCCESS = 'READ_LEADS_IN_LEAD_CONNECT_LIST_SUCCESS';
const READ_LEADS_IN_LEAD_CONNECT_LIST_FAILURE = 'READ_LEADS_IN_LEAD_CONNECT_LIST_FAILURE';

const MARK_LEAD_IN_LEAD_CONNECT_LIST = 'MARK_LEAD_IN_LEAD_CONNECT_LIST';
const MARK_ALL_LEADS_IN_LEAD_CONNECT_LIST = 'MARK_ALL_LEADS_IN_LEAD_CONNECT_LIST';

const SET_LIMIT = 'SET_LIMIT';
const CHANGE_PAGE = 'CHANGE_PAGE';

export default {
    READ_LEADS_IN_LEAD_CONNECT_LIST_REQUEST,
    READ_LEADS_IN_LEAD_CONNECT_LIST_SUCCESS,
    READ_LEADS_IN_LEAD_CONNECT_LIST_FAILURE,
    MARK_LEAD_IN_LEAD_CONNECT_LIST,
    MARK_ALL_LEADS_IN_LEAD_CONNECT_LIST,
    SET_LIMIT,
    CHANGE_PAGE
};
