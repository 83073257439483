import { useState } from "react";
import {
    Segment,
    Header,
    Icon,
    Popup,
    Table as SemanticTable,
} from "semantic-ui-react";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { ControlledPopup, Table, TableLoader } from "dyl-components";

import ProductAdditions from "./ProductAdditions";
import { StringUtils } from "utils";

const Taxes = () => {
    const [isTaxModalOpen, setIsTaxModalOpen] = useState(false);
    const { control } = useFormContext();

    const onOpenTaxModal = () => {
        setIsTaxModalOpen(true);
    };

    const onCloseTaxModal = () => {
        setIsTaxModalOpen(false);
    };

    const { taxOptions, isReadingTaxes } = useSelector((state) => ({
        taxOptions: state.product_fees.taxes,
        isReadingTaxes: state.product_fees.isReadingTaxes,
    }));

    return (
        <Segment>
            <Header color="primary" as="h3">
                <span>
                    <Icon
                        className="fas fa-plus-circle"
                        disabled={isReadingTaxes || !taxOptions?.length}
                        onClick={onOpenTaxModal}
                        link
                    />{" "}
                    Product Taxes
                </span>
            </Header>
            <Controller
                name="taxes"
                control={control}
                render={({ field: { name, value: taxes, onChange } }) => {
                    if (!taxes?.length)
                        return <Segment.Inline>No taxes added</Segment.Inline>;
                    return (
                        <Table striped={false}>
                            <SemanticTable.Header className="Table__header--primary">
                                <Table.Row>
                                    <SemanticTable.HeaderCell width={3}>
                                        Tax Name
                                    </SemanticTable.HeaderCell>
                                    <SemanticTable.HeaderCell width={3}>
                                        Amount
                                    </SemanticTable.HeaderCell>
                                    <SemanticTable.HeaderCell width={2}>
                                        Action
                                    </SemanticTable.HeaderCell>
                                </Table.Row>
                            </SemanticTable.Header>
                            <Table.Body>
                                {isReadingTaxes ? (
                                    <TableLoader colspan={3} isLoading />
                                ) : (
                                    taxes.map((tax) => (
                                        <Tax
                                            key={tax}
                                            taxDetails={
                                                taxOptions?.find(
                                                    (option) =>
                                                        option.id === tax
                                                ) || {}
                                            }
                                            onRemove={() => {
                                                onChange({
                                                    target: {
                                                        name,
                                                        value: taxes.filter(
                                                            (selectedTax) =>
                                                                selectedTax !==
                                                                tax
                                                        ),
                                                    },
                                                });
                                            }}
                                        />
                                    ))
                                )}
                            </Table.Body>
                        </Table>
                    );
                }}
            />
            {isTaxModalOpen && (
                <Controller
                    control={control}
                    name="taxes"
                    render={({
                        field: { name, value: selectedTaxes, onChange },
                    }) => (
                        <ProductAdditions
                            open={isTaxModalOpen}
                            selectedEntries={selectedTaxes}
                            onClose={onCloseTaxModal}
                            ModalHeader={
                                <ModalHeader
                                    isEditing={selectedTaxes?.length > 0}
                                />
                            }
                            selectLabel="Tax Name"
                            isSelectLoading={isReadingTaxes}
                            selectOptions={taxOptions.map((option) => ({
                                ...option,
                                text: `${option.name} (${
                                    !option?.percent ? "$" : ""
                                }${option.amount}${
                                    option?.percent ? "%" : ""
                                })`,
                            }))}
                            selectPlaceholder="Search tax name"
                            removalConfirmationMessage={
                                "Tax(es) will be removed"
                            }
                            onSave={(updatedSelectedTaxes) => {
                                onChange({
                                    target: {
                                        name,
                                        value: updatedSelectedTaxes,
                                    },
                                });
                                onCloseTaxModal();
                            }}
                        />
                    )}
                />
            )}
        </Segment>
    );
};

const ModalHeader = ({ isEditing }) => (
    <>
        {isEditing ? "Edit Product Tax" : "Add Product Tax"}
        <Popup
            trigger={
                <Icon
                    size="small"
                    style={{
                        float: "right",
                        marginRight: "1em",
                        marginTop: "0.2em",
                    }}
                    className="fas fa-circle-info"
                    color="primary"
                />
            }
            content={"These sales taxes are applied after add-ons and fees"}
            inverted
            position="bottom right"
            size="mini"
        />
    </>
);

const Tax = ({ taxDetails, onRemove }) => {
    const { name: taxName, amount, percent, status } = taxDetails;

    return (
        <Table.Row>
            <Table.Cell>
                {status !== "active" && (
                    <Popup
                        content={StringUtils.capitalize(status)}
                        inverted
                        position="top center"
                        trigger={
                            <Icon
                                className="fas fa-info-circle"
                                color="orange"
                            />
                        }
                    />
                )}
                {taxName}
            </Table.Cell>
            <Table.Cell>{`${!percent ? "$" : ""}${amount}${
                percent ? "%" : ""
            }`}</Table.Cell>
            <Table.Cell>
                <ControlledPopup
                    trigger={<Icon className="fas fa-trash" color="red" link />}
                    onConfirm={onRemove}
                    content="Delete"
                    position="bottom center"
                />
            </Table.Cell>
        </Table.Row>
    );
};

export default Taxes;
