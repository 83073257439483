import VolumeBasedPricingTiers from "./VolumeBasedPricingTiers";
import { MODEL_SCHEDULE } from "../../schemas/products/MODEL_SCHEDULE";
import PriceField from "./PriceField";
import UsageBasedFields from "./UsageBasedFields";
import FixedRecurringFields from "./FixedRecurringFields";
import { RuleContainer } from "dyl-components";
import VolumeBasedRecurringFields from "./VolumeBasedRecurringFields";

const PricingFields = ({ model, schedule }) => {
    const model_schedule = [model, schedule].join(":");
    if (model_schedule === MODEL_SCHEDULE.fixed) {
        return <PriceField name={"price_data.price"} label="Unit Price" />;
    }
    if (model_schedule === MODEL_SCHEDULE.volume) {
        return (
            <RuleContainer
                isActive
                content={
                    <VolumeBasedPricingTiers
                        parentName={`price_data.volume_prices`}
                    />
                }
            />
        );
    }
    if (model_schedule === MODEL_SCHEDULE.volume_recurring) {
        return <VolumeBasedRecurringFields />;
    }
    if (model_schedule === MODEL_SCHEDULE.recurring) {
        return <FixedRecurringFields />;
    }
    if (model === "usage") {
        return <UsageBasedFields />;
    }
    return null;
};

export default PricingFields;
