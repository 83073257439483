import { Button } from 'dyl-components';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Form } from 'semantic-ui-react';

const ChangePipelineForm = ({
    className,
    control,
    pipelineCategories,
    isReadingCategories,

    isSubmitDisabled,
    onSubmit,
    isSaving,
    changeReasonsField
}) => {
    return (
        <Form {...(className && { className })} loading={isSaving}>
            <Form.Group widths={'equal'}>
                <Controller
                    name={'stage'}
                    control={control}
                    render={({ field: { onChange: onStageChange, name: stageName } }) => (
                        <Controller
                            name={'pipeline'}
                            control={control}
                            render={({ field: { name, value: categoryValue, onChange }, fieldState: { error } }) => [
                                <Form.Select
                                    fluid
                                    label='Pipeline'
                                    name={name}
                                    value={categoryValue}
                                    onChange={(_, { name, value }) => {
                                        onChange({ target: { name, value } });
                                        onStageChange({ target: { name: stageName, value: pipelineCategories.find(category => category.value === value)?.stages[0]?.value || null } });
                                    }}
                                    options={pipelineCategories}
                                    search
                                    selectOnBlur={false}
                                    placeholder='Select Pipeline'
                                    loading={isReadingCategories}
                                    error={error && error.message && {
                                        content: error.message,
                                        pointing: 'below'
                                    }}
                                    required
                                />,
                                <Controller
                                    name={stageName}
                                    control={control}
                                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                        <Form.Select
                                            fluid
                                            label='Stage'
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                            options={pipelineCategories.find(category => category.value === categoryValue)?.stages || []}
                                            search
                                            selectOnBlur={false}
                                            placeholder='Select Stage'
                                            error={error && error.message && {
                                                content: error.message,
                                                pointing: 'below'
                                            }}
                                            required
                                        />
                                    )}
                                />
                            ]}
                        />
                    )}
                />
            </Form.Group>
            {changeReasonsField}
            <Controller
                name='notes'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.TextArea
                        label={`Change Reason/Notes`}
                        name='notes'
                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                        value={value}
                        placeholder='Enter Note...'
                    />
                )}
            />
            <Button floated='right' disabled={isSubmitDisabled} primary onClick={onSubmit} loading={isSaving}>Save</Button>
        </Form>
    );
}

export default ChangePipelineForm;
