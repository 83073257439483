import React, { useContext, useRef } from "react";
import { Checkbox, DropdownItem, Ref } from "semantic-ui-react";
import { EllipsisDropdownPortal, Table } from "dyl-components";

import { StringUtils } from "utils";
import { BulkActionsContext } from "shared/context/BulkActionsProvider";
import Addon from "./Addon";
import { Link } from "react-router-dom";

const TableRow = ({ addon }) => {
    const [selectedAddons, setSelectedAddons] = useContext(BulkActionsContext);

    const isSelected = (addon_id) => {
        return selectedAddons.includes(addon_id);
    };

    const onToggleAddon = (addon_id) => {
        const newSelectedAddons = isSelected(addon_id)
            ? selectedAddons.filter((id) => addon_id !== id)
            : [...selectedAddons, addon_id];
        setSelectedAddons(newSelectedAddons);
    };

    const ref = useRef();

    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox
                    checked={isSelected(addon.id)}
                    onChange={() => {
                        onToggleAddon(addon.id);
                    }}
                />
            </Table.Cell>
            <Table.Cell>
                <Addon addon={addon} />
            </Table.Cell>
            <Table.Cell>{addon.description}</Table.Cell>
            <Table.Cell>${addon.min_price}</Table.Cell>
            <Table.Cell>{StringUtils.capitalize(addon.status)}</Table.Cell>
            <Table.Cell>
                <Ref innerRef={ref}>
                    <EllipsisDropdownPortal reference={ref}>
                        <DropdownItem
                            text="Edit"
                            icon="fa-solid fa-pencil"
                            as={Link}
                            to={`/settings/product-catalog-quotes-invoices/addons/${addon.id}`}
                        />
                        <DropdownItem
                            text="Duplicate"
                            icon="fas fa-copy"
                            as={Link}
                            to={`/settings/product-catalog-quotes-invoices/duplicate-addon/${addon.id}`}
                        />
                    </EllipsisDropdownPortal>
                </Ref>
            </Table.Cell>
        </Table.Row>
    );
};

export default TableRow;
