
import React, { useState } from 'react';
import '../index.scss';
import { Table, EllipsisDropdown, Person, ClippedContent, ButtonLink } from 'dyl-components';
import { Icon, Dropdown, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import EditCapPopup from './EditCapPopup';

export const DistributionUsersRow = ({ user, refresh }) => {
    const [isCapPopupOpen, setIsCapPopupOpen] = useState(false);
    const { first_name, last_name, email, cap, assignment_rules, id } = user;
    const username = `${first_name} ${last_name}`;

    const relegateInactiveRules = () => {
        const inactiveAssignmentRules = assignment_rules.filter((rule) => !rule.active);
        const activeAssignmentRules = assignment_rules.filter((rule) => rule.active);
        const result = [...activeAssignmentRules, ...inactiveAssignmentRules];
        return result;
    }

    const assignmentRulesDisplay = relegateInactiveRules();

    const ELLIPSIS_OPTIONS_SUBSECTION = [
        {
            id: 1,
            text: 'Edit',
            icon: 'fas fa-edit',
            onClick: () => { setIsCapPopupOpen(true) }
        },
    ]

    const DropdownItem = ({ text, icon, ...otherProps }) => {
        return (
            <Dropdown.Item text={<React.Fragment>
                <Icon name={icon} />
                {text}
            </React.Fragment>}
                style={{ cursor: 'pointer' }}
                {...otherProps}
            />
        );
    }

    const [showMore, setShowMore] = useState(false);

    const arrayChunk = (arr, n) => {
        const array = arr.slice();
        const chunks = [];
        while (array.length) chunks.push(array.splice(0, n));
        return chunks;
    };

    return (
        <Table.Row>
            <Table.Cell>
                <div style={{ whiteSpace: 'nowrap', maxWidth: 300 }}>
                    <Person username={username} email={email} />
                </div>
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {cap || ""}
            </Table.Cell>
            <Table.Cell>
                <>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: "3.33%", flexWrap: "wrap", margin: '18px 0px 10px 0px' }}>
                        {arrayChunk(assignmentRulesDisplay || [], 3).map((row, i) => {
                            if (i === 0 || showMore) {
                                return row.map((rule) => {
                                    const { trigger_name, name, active } = rule;
                                    return <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: "nowrap", overflow: 'hidden', width: "30%", justifyContent: 'center', margin: i === 0 ? showMore ? '0px 0px 10px 0px' : 0 : '18px 0px 10px 0px' }}>
                                        <ClippedContent>
                                            <span><strong className={`DistributionUsers__triggerName${!active && "--inactive"}`}>{name}</strong></span>
                                        </ClippedContent>
                                        <ClippedContent>
                                            <Link to=''>{trigger_name || ""}</Link>
                                        </ClippedContent>
                                        {!active && <i className='DistributionUsers__triggerName--inactive'>(Inactive)</i>}
                                    </div>
                                })
                            }
                            return null;
                        })}
                    </div>
                    {assignment_rules?.length > 3 && (
                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 8 }}>
                            <ButtonLink onClick={() => setShowMore(!showMore)}>{showMore ? "Show Less" : "Show More"}</ButtonLink>
                        </div>
                    )}
                </>
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {/**TODO: CER-3755 Get the total of contacts assignend through automation */}
                <Link to=''>-</Link>
            </Table.Cell>
            <Table.Cell>
                <Popup
                    trigger={
                        <EllipsisDropdown>
                            {ELLIPSIS_OPTIONS_SUBSECTION.map(({ text, icon, displaysPopup, popupProps, ...otherProps }, idx) => {
                                return (
                                    <DropdownItem
                                        key={idx}
                                        text={text}
                                        icon={icon}
                                        displaysPopup={displaysPopup}
                                        popupProps={popupProps}
                                        {...otherProps}
                                    />
                                );
                            })}
                        </EllipsisDropdown>
                    }
                    on='click'
                    content={
                        <EditCapPopup
                            refresh={refresh}
                            onClose={() => setIsCapPopupOpen(false)}
                            user_id={id}
                            user_cap={cap}
                        />
                    }
                    open={isCapPopupOpen}
                    onClose={() => setIsCapPopupOpen(false)}
                    position='bottom left'
                    wide={false}
                />
            </Table.Cell>
        </Table.Row>
    )
}