import ACTION_NAMES from 'actions/ACTION_NAMES';
import { generateCreateActionCreator, generateUpdateActionCreator, generateDeleteActionCreator, generateReadOneActionCreator } from 'actions/crudActionCreators';
import routes from 'actions/routes';

export default {
    addEvent: generateCreateActionCreator(routes.API_EVENT, ACTION_NAMES.EVENT),
    getEvent: generateReadOneActionCreator(routes.API_EVENT, ACTION_NAMES.EVENT),
    updateEvent: generateUpdateActionCreator(routes.API_EVENT, ACTION_NAMES.EVENT),
    deleteEvent: generateDeleteActionCreator(routes.API_EVENT, ACTION_NAMES.EVENT),
        
    updateEventLabel: generateUpdateActionCreator(routes.API_EVENT_LABEL, ACTION_NAMES.EVENT_LABEL),
    deleteEventLabel: generateDeleteActionCreator(routes.API_EVENT_LABEL, ACTION_NAMES.EVENT_LABEL),
    checkEventLabelDuplicate: generateReadOneActionCreator(routes.API_EVENT_LABEL, ACTION_NAMES.CHECK_EVENT_LABEL_DUPLICATE, 'check_duplicate')
}
