import { Modal } from "dyl-components";
import { useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import InvoicePDF from "./InvoicePDF";

const InvoicePreview = () => {
    const invoice = useSelector((state) => state.invoice.invoice);
    const pdf = <InvoicePDF invoice={invoice} />;

    return (
        <>
            <Modal.Content>
                <PDFViewer showToolbar={false} style={{ width: "100%", height: "65vh" }}>{pdf}</PDFViewer>
            </Modal.Content>
            <Modal.Actions>
                <PDFDownloadLink
                    fileName={`${invoice?.name || "invoice"}.pdf`}
                    document={pdf}
                >
                    {({ loading, error }) => (
                        <Button
                            loading={loading && !error}
                            disabled={loading || error}
                            basic
                            type="button"
                            color="primary"
                        >
                            Download PDF
                        </Button>
                    )}
                </PDFDownloadLink>
                <Button type="button" color="primary">
                    Send
                </Button>
            </Modal.Actions>
        </>
    );
};

export default InvoicePreview;
