import routes from 'actions/routes';
import { generateReadActionCreator, generateCreateActionCreator, generateUpdateActionCreator, generateDeleteActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import axiosInstance from 'actions/axiosInstance';

export default {
    getTemplates: generateReadActionCreator(routes.API_TEXT_TEMPLATES, ACTION_NAMES.TEXT_TEMPLATES),
    createTemplates: generateCreateActionCreator(routes.API_TEXT_TEMPLATES, ACTION_NAMES.TEXT_TEMPLATES),
    getTemplate: (id) => async (dispatch) => {
        dispatch({
            type: getNamedAction(ACTION_NAMES.TEXT_TEMPLATE, CRUD_ACTION_TYPES.READ_ONE_REQUEST)
        });

        try {
            const { data: textTemplate } = await axiosInstance.instance1.get(`${routes.API_TEXT_TEMPLATES}/${id}`);
            const data = {
                ...textTemplate
            }
            console.log(data);
            dispatch({
                type: getNamedAction(ACTION_NAMES.TEXT_TEMPLATE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS),
                data,
                id
            });
            return data;
        } catch (error) {
            console.log(error);
            dispatch({
                type: getNamedAction(ACTION_NAMES.TEXT_TEMPLATE, CRUD_ACTION_TYPES.READ_ONE_FAILURE)
            });
            return Promise.reject(error.response?.data);
        }
    },
    updateTemplate: generateUpdateActionCreator(routes.API_TEXT_TEMPLATES, ACTION_NAMES.TEXT_TEMPLATE),
    deleteTemplate: generateDeleteActionCreator(routes.API_TEXT_TEMPLATES, ACTION_NAMES.TEXT_TEMPLATE)
}
