import React from 'react';
import { Button, Checkbox, Form, Grid, Header, Icon, Label, Segment } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { Controller } from 'react-hook-form';

import TableDropdown from 'shared/TableDropdown';
import assignmentAction from 'actions/assignment';

import './SequenceSettings.scss';
import { ClippedContent } from 'dyl-components';

export const SelectionRender = ({ value, onChange, areSelectionsRemovable }) => {
    const onDeleteAccess = (_, id, isSelectionTerritory) => {
        onChange(_, {
            ...value, ...(isSelectionTerritory ? {
                territories: value.territories.filter(selectedAccess => selectedAccess.id !== id)
            } : {
                teams: value.teams.filter(selectedAccess => selectedAccess.id !== id)
            })
        });
    }

    return (
        <div className='SequenceSettingsTab__SequenceAccessSelection'>
            {value.territories.map(territoryAccess => (
                <div className='SelectedSequenceAccess'>
                    <Label className='SelectedSequenceAccess__Label' basic>
                        <Icon className='fas fa-globe' />{territoryAccess.name}
                        {areSelectionsRemovable && (<Label.Detail><Icon className='fa-solid fa-xmark' onClick={_ => { onDeleteAccess(_, territoryAccess.id, true) }} /></Label.Detail>)}
                    </Label>
                </div>
            ))}
            {value.teams.map(teamAccess => (
                <div className='SelectedSequenceAccess'>
                    <Label className='SelectedSequenceAccess__Label' basic>
                        <Icon className='fas fa-users' />{teamAccess.name}
                        {areSelectionsRemovable && (<Label.Detail><Icon className='fa-solid fa-xmark' onClick={_ => { onDeleteAccess(_, teamAccess.id, false) }} /></Label.Detail>)}
                    </Label>
                </div>
            ))}
        </div>
    );
}

export const SearchResultsRender = ({ results, count, value, onChange, isSmall }) => {
    const onSelect = (e, access, isTerritory) => {
        onChange(e, {
            ...value, ...(isTerritory ? {
                territories: [...value.territories, { id: access.id, name: access.name, teams: access.teams }]
            } : {
                teams: [...value.teams, { id: access.id, name: access.name }]
            })
        });
    }

    const territories = results?.territories || [];
    const teams = results?.teams || [];

    return (
        <Segment className='SequenceSettingsTab__SequenceAccessSearchResults'>
            <Grid className='ResultsGrid' container>
                {count > 0 ? <>
                    {(territories).length > 0 && (
                        <>
                            <Grid.Row className='ResultsGrid__HeaderRow'>
                                <Grid.Column width={isSmall ? 10 : 13}>
                                    Territory Name
                                </Grid.Column>
                                <Grid.Column width={1}></Grid.Column>
                            </Grid.Row>
                            {territories.map(row => {
                                const isSelected = value.territories.find(selectedTerritories => selectedTerritories.id === row.id);
                                const selectedTeams = value.teams.map(team => team.id);
                                const areAllTeamsSelected = row.teams.every(team => selectedTeams.includes(team));
                                return (
                                    <Grid.Row className='ResultsGrid__Row'>
                                        <Grid.Column width={isSmall ? 10 : 13}>
                                            <div><ClippedContent popupContent={row.name}><Icon className="fas fa-globe" /> <b>{row.name}</b> {areAllTeamsSelected && `(All teams selected)`}</ClippedContent></div>
                                            <div>{`${row.teams.length} team${row.teams.length !== 1 ? 's' : ''}`}</div>
                                        </Grid.Column>
                                        <Grid.Column width={1}>
                                            <Button primary
                                                disabled={isSelected || areAllTeamsSelected}
                                                onClick={_ => { onSelect(_, row, true); }}
                                                type="button"
                                            >Add</Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            })}
                        </>
                    )}
                    {(teams).length > 0 && (
                        <>
                            <Grid.Row className='ResultsGrid__HeaderRow'>
                                <Grid.Column width={isSmall ? 10 : 13}>
                                    Team Name
                                </Grid.Column>
                                <Grid.Column width={1}></Grid.Column>
                            </Grid.Row>
                            {teams.map(row => {
                                const team = value.teams.find(selectedTeam => selectedTeam.id === row.id);
                                return (
                                    <Grid.Row className='ResultsGrid__Row'>
                                        <Grid.Column width={isSmall ? 10 : 13}>
                                            <div><ClippedContent popupContent={row.name}><Icon className="fas fa-users" /> <b>{row.name}</b> {team && `(Part of ${team.name})`}</ClippedContent></div>
                                            <div>{`${row.users.count} user${row.users.count !== 1 ? 's' : ''}`}</div>
                                        </Grid.Column>
                                        <Grid.Column width={1}>
                                            <Button primary
                                                disabled={team}
                                                onClick={e => { onSelect(e, row, false); }}
                                                type="button"
                                            >Add</Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            })}
                        </>
                    )}
                </> : (
                    <Grid.Row className='ResultsGrid__HeaderRow'>
                        <Grid.Column width={16}>
                            No results found
                        </Grid.Column>
                    </Grid.Row>
                )}
            </Grid>
        </Segment>
    );
}

const SettingsForm = ({ control }) => {
    const dispatch = useDispatch();

    const onSearchAction = async (search) => {
        if (search?.trim()) {
            const { teams, territories } = await dispatch(assignmentAction.readTeamsTerritories({ search: search }));
            return { data: { teams: teams.data, territories: territories.data }, count: (teams?.count || 0) + (territories?.count || 0) };
        }
    }

    const onFocusAction = async () => {
        const { teams, territories } = await dispatch(assignmentAction.readTeamsTerritories({}));
        return { data: { teams: teams.data, territories: territories.data }, count: (teams?.count || 0) + (territories?.count || 0) };
    }

    return (
        <div className='SequenceSettingsTab'>
            <Header className='SequenceSettingsTab__Header' as='h3'>Sequence Access</Header>
            <Controller
                name="settings_sequence_access"
                control={control}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <Form.Field
                        width={7}
                        control={TableDropdown}
                        label='Territories and Teams'
                        isSelectionVisible={value.territories.length > 0 || value.teams.length > 0}
                        value={value}
                        onChange={(_, newValue) => { onChange({ target: { name, value: newValue } }) }}
                        SelectionRender={SelectionRender}
                        SearchResultsRender={SearchResultsRender}
                        onSearchAction={onSearchAction}
                        onFocusAction={onFocusAction}
                        searchPlaceholder='Search territories and teams'
                        errorMessage={error && error.message}
                    />
                )}
            />
            <Header className='SequenceSettingsTab__Header' as='h3'>Allow Sequences on Weekends</Header>
            <Controller
                name="saturday"
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field
                        label='Saturday'
                        control={Checkbox}
                        checked={value}
                        onChange={_ => { onChange({ target: { name, value: !value } }) }}
                    />
                )}
            />
            <Controller
                name="sunday"
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field
                        label='Sunday'
                        control={Checkbox}
                        checked={value}
                        onChange={_ => { onChange({ target: { name, value: !value } }) }}
                    />
                )}
            />
            <Header className='SequenceSettingsTab__Header' as='h3'>Allow Sequences to be Removed</Header>
            <Controller
                name="conversion_remove"
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <Checkbox
                            toggle
                            checked={value}
                            onChange={_ => { onChange({ target: { name, value: !value } }) }}
                        /> <span className='SequenceSettingsTab__ToggleLabel'>Remove after contact conversion occurs</span>
                    </Form.Field>
                )}
            />
            <Controller
                name="reassign_remove"
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <Checkbox
                            toggle
                            checked={value}
                            onChange={_ => { onChange({ target: { name, value: !value } }) }}
                        /> <span className='SequenceSettingsTab__ToggleLabel'>Remove after contact owner is changed or deleted</span>
                    </Form.Field>
                )}
            />
        </div>
    )
}

export default SettingsForm;
