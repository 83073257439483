import { Controller, useFormContext } from "react-hook-form";
import { StringUtils } from "utils";

const { Segment, Header, Form, Icon } = require("semantic-ui-react");

const ProductDiscount = () => {
    const { control } = useFormContext();
    return (
        <Segment>
            <Header color="primary" as="h3">
                Product Discount
            </Header>
            <Controller
                control={control}
                name={"max_discount"}
                render={({
                    field: { name, value, onChange },
                    fieldState: { error },
                }) => (
                    <Form.Input
                        name={name}
                        value={value}
                        width={4}
                        onChange={(_, { value }) => {
                            onChange({
                                target: {
                                    name,
                                    value: StringUtils.formatDecimal(value),
                                },
                            });
                        }}
                        error={error?.message}
                        label={"Max Discount"}
                        icon={<Icon name="percent" size="small" />}
                    />
                )}
            />
        </Segment>
    );
};

export default ProductDiscount;
