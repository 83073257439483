import React, { useEffect } from 'react';
import { EmptyListPlaceholder, Table, Notification, STATUS_TYPES } from 'dyl-components';
import { Grid, Image, Loader, Radio, Table as SemanticTable, } from 'semantic-ui-react';
import Integration from 'shared/Integration';
import { useDispatch, useSelector } from 'react-redux';
import oauthActions from 'actions/oauth';
import { Link, useParams } from 'react-router-dom';
import { GmailSVG, OutlookSVG } from 'dyl-components';

const EmailIntegrations = () => {

    const { id } = useParams();

    const root = id ? `/settings/users/${id}` : `/profile`;

    const dispatch = useDispatch();

    const { integrations = [], isReadingIntegrations, isUpdatingPrimaryIntegration } = useSelector(state => state.oauth_integrations);

    useEffect(() => {
        dispatch(oauthActions.getIntegrations({ scopes: 'email', include_disabled: false }));
    }, [dispatch]);

    const onUpdatePrimary = async (integration_id) => {
        try {
            await dispatch(oauthActions.updatePrimary(null, null, { integration_id, scope: 'email' }));
            dispatch(oauthActions.getIntegrations({ scopes: 'email', include_disabled: false }));
            Notification.alert('Successfully updated primary email', STATUS_TYPES.SUCCESS);
        } catch (e) {
            Notification.alert('Failed to update primary email', STATUS_TYPES.ERROR);
        }
    }

    const EmailIntegrationRow = ({ integration }) => (
        <Table.Row>
            <Table.Cell>
                <Integration name={integration.name} customIcon={<Image src={integration.provider === 'google' ? GmailSVG : OutlookSVG} />} email={integration.email} />
            </Table.Cell>
            <Table.Cell>
                <Radio checked={integration.scopes[0].main} label={'Primary email'} onClick={!integration.scopes[0].main ? () => {
                    onUpdatePrimary(integration.id);
                } : null} />
            </Table.Cell>
        </Table.Row>
    );

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={10}>
                    <Loader active={isReadingIntegrations || isUpdatingPrimaryIntegration} />
                    <Table unstackable className='IntegrationTable'>
                        <SemanticTable.Header>
                            <Table.Row>
                                <SemanticTable.HeaderCell width={3}>Apps</SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell width={3}>Select primary email</SemanticTable.HeaderCell>
                            </Table.Row>
                        </SemanticTable.Header>
                        <Table.Body>
                            {integrations.map((integration) => (
                                <EmailIntegrationRow integration={integration} />
                            ))}
                        </Table.Body>
                    </Table>
                    {integrations.length === 0
                        &&
                        <EmptyListPlaceholder title={'No Email Integration Exists'}
                            subtitle={<span>Add an Integration by clicking the
                                <Link to={`${root}/integration`}> Apps </Link>
                                tab on top</span>} />
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>

    )
}

export default EmailIntegrations;
