import React from 'react';
import { Segment, Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Notification, STATUS_TYPES } from 'dyl-components';

import authActions from "actions/auth/auth";
import queryString from 'query-string';
import userActions from 'actions/user';

import { useForm, Controller } from 'react-hook-form';
import { VALIDATORS, generateResolver } from 'dyl-components';
import { Navigate } from 'react-router';

const PasswordChange = ({ onResetPassword, onValueChange, arePasswordsValid, isUpdatingPassword, codeProvided }) => {
   
    const { control, formState: { isValid }, setError, getValues } = useForm({
        mode: 'onChange',
        reValidateMode: 'all',
        resolver: generateResolver({
            password: VALIDATORS.PASSWORD(true),
            new_password_confirmation: VALIDATORS.PASSWORD_CONFIRMATION()
        })
    });

    const pwMatchCheck = () => {
        const {password, new_password_confirmation} = getValues();
        const msg = (new_password_confirmation !== password) ? 'Passwords must match' : false;

        setError("new_password_confirmation", {
            message: msg,
        });
    }
    
    return (
    <div className="LoginWrapper">
        <Segment className='Login'>
            <h2 className="Login__header">Update Your Password</h2>       
            <div className="Login__content">
                <Form>
                    <div className="Login__fields">
                        {!codeProvided &&
   
                            <Controller
                                name="old" //changed to match the yup resolver
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (

                                    <Form.Input
                                        fluid
                                        placeholder='Previous password...'
                                        type={'password'}
                                        name={'old'}
                                        onChange={onChange}
                                        onBlur={onValueChange}
                                        value={value}
                                        error={error && error.message}
                                        required
                                    />

                                )}
                            />
                        }

                            <Controller
                                name="password" //changed to match the yup resolver
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (

                                    <Form.Input
                                        fluid
                                        placeholder='New password...'
                                        type={'password'}
                                        name={'new_password'}
                                        onChange={e =>{onChange(e); onValueChange(e);pwMatchCheck()}}
                                        value={value}
                                        error={error && error.message}
                                        required
                                    />

                                )}
                            />

                         <Controller
                                name="new_password_confirmation" //changed to match the yup resolver
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (

                                    <Form.Input
                                        fluid
                                        placeholder='Confirm password...'
                                        type={'password'}
                                        name={'new_password_confirmation'}
                                        onChange={e =>{onChange(e); onValueChange(e);}}
                                        value={value}
                                        error={error && error.message}
                                        required
                                    />

                                )}
                            />
                    </div>
                    <Button disabled={!isValid} loading={isUpdatingPassword} onClick={onResetPassword} color='blue' fluid>Confirm</Button>
                </Form>
            </div>
        </Segment>
    </div>
)}

class PasswordChangeContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            old: '',
            new_password: '',
            new_password_confirmation: '',
            code: '',
            codeProvided: false,
            isLegit: true,
            loggedOut: false,
            resetPassword: false
        }
    }

    componentDidMount = async () => {
        // get password recovery code from query
        let search = new URLSearchParams(window.location.search);
        let code = search.get('code') || '';
        let codeProvided = (code !== '');
        this.setState({ code, codeProvided })

        // verify code
        let isCodeValid = false;
        let isCodeExpired = true;
        if (codeProvided) {
            try {
                let validateCodeResp = await this.props.verifyCode(code);
                let { valid, expired } = validateCodeResp;
                isCodeValid = valid;
                isCodeExpired = expired;
            } catch (error) {
                console.log(error)
            }
        } else {
            isCodeValid = true;
            isCodeExpired = false;
        }


        if (isCodeValid && !isCodeExpired) {
            // get credentials
            let isAuthenticated = false;
            try {
                let getUserResp = await this.props.onGetUser() || {};
                isAuthenticated = getUserResp.isAuthenticated;
            } catch (error) {
                console.log(error)
            }

            // a password request is valid only if is requested by an authenticated user
            // or using a password recovery code sent by email and not expired yet
            let isLegit = (isAuthenticated || codeProvided);
            if (!isLegit) {
                Notification.alert('Session data not found', STATUS_TYPES.ERROR, true);
                this.setState({ isLegit: false });
            }
        } else {
            Notification.alert('The link is invalid or expired', STATUS_TYPES.ERROR, true);
            this.setState({ isLegit: false });
        }
    }

    onValueChange = (e) => {
        const { name, value} = e.currentTarget || ``;
        this.setState({ [name]:value});
    }

    onResetPassword = () => {
        const { new_password, old, codeProvided } = this.state;
        if (codeProvided) {
            //Reset Forgotten Password through Forgot Password link/code
            const { code } = queryString.parse(window.location.search);
            this.props.onResetPassword({ code, new_password }).then(() => {
                this.setState({ resetPassword: true });
                Notification.alert('Sucessfully reset password', STATUS_TYPES.SUCCESS, true);
            }).catch(error => {
                console.log(error);
                Notification.alert('Failed to reset password', STATUS_TYPES.ERROR, true);
            });
        } else {
            //Update Temporary Password
            this.props.onUpdatePassword(this.props.user_id, { new_password, old, target_user_id: this.props.user_id, temporary: false }).then(() => {
                this.props.onLogOut().then(() => {
                    this.setState({ loggedOut: true });
                    Notification.alert('Sucessfully reset password', STATUS_TYPES.SUCCESS, true);
                });
            }).catch(error => {
                console.log(error);
                Notification.alert('Failed to reset password', STATUS_TYPES.ERROR, true);
            })
        }
    }

    arePasswordsValid = () => {
        const { new_password, new_password_confirmation } = this.state;
        return new_password.trim() !== ''
            && new_password_confirmation.trim() !== ''
            && new_password === new_password_confirmation
    }

    render() {
        if (this.state.loggedOut || !this.state.isLegit) {
            return <Navigate to="/login" />
        }
        if (this.state.resetPassword) {
            return <Navigate to="/" />
        }
        return (
            <PasswordChange
                {...this.state}
                onValueChange={this.onValueChange} 
                onResetPassword={this.onResetPassword}
                arePasswordsValid={this.arePasswordsValid()}
                isUpdatingPassword={this.props.isUpdatingPassword}
            />
        );
    }
}

const mapStateToProps = state => ({
    isUpdatingPassword: state.auth.isResettingPassword,
    user_id: state.auth.user_id
})

const mapDispatchToProps = dispatch => ({
    onGetUser: () => {
        return dispatch(authActions.check());
    },
    onResetPassword: (payload) => {
        return dispatch(authActions.resetPassword(payload));
    },
    onUpdatePassword: (user_id, payload) => {
        return dispatch(userActions.updatePassword(user_id, payload));
    },
    onLogOut: () => {
        return dispatch(authActions.logout());
    },
    verifyCode: (code) => {
        return dispatch(authActions.verifyCode(code));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChangeContainer);
