import types from "actions/leads/types";
import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function leadsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.LEADS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingLeads: true, leads: [], count: 0 };
        case getNamedAction(ACTION_NAMES.LEADS, CRUD_ACTION_TYPES.READ_SUCCESS):
            console.log(action.data);
            return { ...state, isReadingLeads: false, leads: (action.data.data || []).map(lead => ({ ...lead, isMarked: state.allPagesMarked })), queryParameters: action.queryParameters, count: action.data.count, allMarked: state.allPagesMarked };
        case getNamedAction(ACTION_NAMES.LEADS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingLeads: false };

        case types.MARK_LEAD:
            {
                const leads = state.leads.slice(0);
                let leadToMark = leads.filter(lead => lead.id === action.id)[0];
                leadToMark.isMarked = !leadToMark.isMarked;
                return {
                    ...state, leads, allPagesMarked: false,
                    ...(leads.every(({ isMarked }) => !isMarked) ? { allMarked: false } : {}),
                    ...(leads.every(({ isMarked }) => isMarked) ? { allMarked: true } : {})
                }
            }
        case types.MARK_ALL_LEADS:
            {
                const leads = state.leads.slice(0);
                let allMarked = !state.allMarked;
                leads.forEach(lead => { lead.isMarked = allMarked });
                return { ...state, leads, allMarked, allPagesMarked: false }
            }
        case types.UPDATE_LEAD_ASSIGNMENT:
            {
                const leads = state.leads.slice(0);
                let indexOfLeadToUpdate = leads.findIndex(lead => lead.id === action.lead);
                if (indexOfLeadToUpdate !== -1) {
                    leads[indexOfLeadToUpdate].assigned.id = action.user;
                    return { ...state, leads }
                }
                return state;
            }
        case types.MARK_LEADS_IN_EVERY_PAGE:
            {
                const leads = state.leads.slice(0);
                let allPagesMarked = !state.allPagesMarked;
                leads.forEach(lead => { lead.isMarked = allPagesMarked });
                return { ...state, leads, allMarked: allPagesMarked, allPagesMarked }
            }

        default:
            return state;
    }
}


export default leadsReducer;
