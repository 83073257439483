import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Form, Grid } from 'semantic-ui-react';
import { CustomGroupUtils } from 'utils';

const BusinessStandardFinancialData = ({
    isEditing,
    control,

    textAlign = 'right'
}) => {
    const { custom_data, business_method_options } = useSelector(state => {
        const business_method_options = state.account.account?.custom_data?.fields?.find(field => field.field_name === 'business_method')?.options?.data?.map(option => ({
            key: option.id,
            value: option.id,
            text: option.value
        })) || [];
        return {
            business_method_options,
            custom_data: CustomGroupUtils.groupAndFlatten(state.account.account?.custom_data || {})
        }
    });

    const fields = custom_data.fields;

    return (
        <Grid className='RecordInfo'>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Acct. Fiscal Year
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        fields?.accounting_fiscal_year || 'None'
                    ) : (
                        <Controller
                            name='accounting_fiscal_year'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Enter accounting fiscal year'
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Acct. Method
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        business_method_options.find((option) => {
                            return option.value === fields?.business_method;
                        })?.text || 'None'
                    ) : (
                        <Controller
                            name='business_method'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Select accounting method'
                                    selectOnBlur={false}
                                    options={business_method_options}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Sales Volume
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        fields?.sale_volume || 'None'
                    ) : (
                        <Controller
                            name='sale_volume'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Enter sales volume'
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Annual Revenue
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        fields?.annual_revenue || 'None'
                    ) : (
                        <Controller
                            name='annual_revenue'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Enter annual revenue'
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default BusinessStandardFinancialData;

