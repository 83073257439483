import React from 'react';
import { List, Icon, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FAVORITE_TYPE } from 'shared/constants/FAVORITE_TYPE';
import { ClippedContent, DateTimeUtils } from 'dyl-components';
import { useSelector } from 'react-redux';
import { StringUtils } from 'utils';

const getDayTimestampsParameter = (userAuthTimezone) => {
    const { start, end } = DateTimeUtils.getDateRange('today', DateTimeUtils.getTimezoneCurrentDay(userAuthTimezone), DateTimeUtils.DATE_FORMAT, 'day', true, userAuthTimezone);
    return `start=${start}&end=${end}`;
}

const getDisplayName = (favorite) => {
    if (favorite.favorite_type !== FAVORITE_TYPE.REPORT && favorite.favorite_type !== FAVORITE_TYPE.SETTING) {
        return favorite.display_name;
    }
    if (favorite.display_name === "product catalog quotes invoices") {
        return "Product Catalog, Quotes & Invoices";
    }
    return StringUtils.toSentenceCase(favorite.display_name)
}

const getUrl = ({ external_id, favorite_type, setting_report_type }, timezone) => {
    switch (favorite_type) {
        case FAVORITE_TYPE.CONTACT:
            return `/contact/${external_id}`;
        case FAVORITE_TYPE.MASTER_ACCOUNT:
            return `/master_account/${external_id}`;
        case FAVORITE_TYPE.ACCOUNT:
            return `/account/${external_id}`;
        case FAVORITE_TYPE.CAMPAIGN:
            return `/campaigns/${external_id}/dashboard`;
        case FAVORITE_TYPE.GROUP:
            return `/group/${external_id !== 0 ? external_id : "hotlist"}`;
        case FAVORITE_TYPE.SETTING:
        case FAVORITE_TYPE.REPORT:
            {
                const root = setting_report_type.split(".")[1].split("_").join("-");
                const subRoute = (() => {
                    if (root === "data-customization") {
                        return "/contact";
                    }
                    if (root === "library") {
                        return "/text-templates"
                    }
                    if (root === "pipeline") {
                        return "/lead-pipelines"
                    }
                    if (root === "sequence-report") {
                        const queryParameters = getDayTimestampsParameter(timezone);
                        return `${queryParameters ? `s?${queryParameters}` : "s"}`;
                    }
                    return "";
                })();
                const correctedRoot = (() => {
                    switch (root) {
                        case "data-integration-log": return "data-integration-logs";
                        case "chat-settings": return "chat";
                        case "pipeline": return "pipelines";
                        case "permissions": return "permission-profile";
                        case "ring-groups-and-queues": return "ring-groups-management";
                        default: return root;
                    }
                })();
                return `/settings/${correctedRoot}${subRoute}`;
            }
        default:
            return '/';
    }
}

const Favorite = ({ favorite, isEditing, isFavorite, onToggleFavorite, userAuthTimezone, indented }) => {
    return (
        <Grid columns={"equal"} style={{ marginBottom: "-0.5em" }}>
            <Grid.Column>
                <div {...(indented ? { style: { marginLeft: "1em" } } : {})}>
                    <ClippedContent popup={false}>
                        <Link target='_blank' to={getUrl(favorite, userAuthTimezone)}>{getDisplayName(favorite)}</Link>
                    </ClippedContent>
                </div>
            </Grid.Column>
            {isEditing && (
                <Grid.Column width={2}>
                    <Icon style={{ marginTop: 0 }} className={`${isFavorite ? 'star' : 'star outline'}`} link color={"yellow"} onClick={onToggleFavorite} />
                </Grid.Column>
            )}
        </Grid>
    )
}

const FavoriteItem = ({ favorite, isEditing, isFavorite, onToggleFavorite }) => {
    const userAuthTimezone = useSelector((state) => state.auth.timezone);
    const { children, favorite_type } = favorite;
    const children_copy = children?.slice(0) || [];
    children_copy.sort((a, b) => {
        const aDisplayName = a.name?.toLowerCase();
        const bDisplayName = b.name?.toLowerCase();
        if (aDisplayName < bDisplayName) {
            return -1;
        }
        if (aDisplayName > bDisplayName) {
            return 1;
        }
        return 0;
    });
    return (
        <>
            <List.Item>
                <List.Content>
                    <Favorite favorite={favorite} isEditing={isEditing} isFavorite={isFavorite(favorite)} onToggleFavorite={() => {onToggleFavorite(favorite)}} userAuthTimezone={userAuthTimezone} />
                </List.Content>
            </List.Item>
            {favorite_type === FAVORITE_TYPE.GROUP && children?.length > 0 && (
                children_copy.filter(child => child.id).map(child => {
                    const favorite = { external_id: child.id, display_name: child.name, favorite_type: FAVORITE_TYPE.GROUP };
                    return (
                        <List.Item key={`${child.id}${child.name}`}>
                            <List.Content>
                                <Favorite indented favorite={favorite} isEditing={isEditing} isFavorite={isFavorite(favorite)} onToggleFavorite={() => {onToggleFavorite(favorite)}} />
                            </List.Content>
                        </List.Item>
                    );
                })
            )}
        </>
    );
};

export default FavoriteItem;
