import React, { useEffect } from 'react';
import { Grid, Header, Icon, Dropdown, Popup, List, Button, Table as SemanticTable } from 'semantic-ui-react';

import { ButtonLink, EllipsisDropdown, LinkedAccount, Table } from 'dyl-components';
import { connect } from 'react-redux';
import masterAccountActions from 'actions/master_account';
import { Link, useParams } from 'react-router-dom';
import LinkAccountDropdown from 'shared/LinkAccountDropdown';

const ACCOUNT_STATUS_COLORS = {
    active: 'teal',
    convert: 'blue',
    deactivated: 'red'
}

const ELLIPSIS_OPTIONS = [
    {
        id: 1,
        text: 'Change Master Account',
        icon: 'fa-solid fa-buildings'
    }
];

const DropdownItem = ({ text, icon, ...otherProps }) => {
    return (
        <Dropdown.Item text={<React.Fragment>
            <Icon name={icon} />
            {text}
        </React.Fragment>}
            style={{ cursor: 'pointer' }}
            {...otherProps}
        />
    );
}

const mapDispatchToProps = dispatch => ({
    readAccountsForPinning: (master_account_id, queryParameters) => {
        dispatch(masterAccountActions.readAccountsForPinning(master_account_id, queryParameters));
    }
})

const mapStateToProps = state => ({
    accountsForPinning: state.master_account.accountsForPinning.map(account => ({
        key: account.id,
        value: account.id,
        text: account.name,
        content: (
            <LinkedAccount account={account.name} subtitle={`ID: ${account.id}`} />
        )
    })),
    isReadingAccountsForPinning: state.master_account.isReadingAccountsForPinning
});

export const PinnedAccounts = connect(mapStateToProps, mapDispatchToProps)(({
    accounts,
    accountsForPinning,

    onPin,
    onUnpin,
    onReplacePinnedAccount,

    isReadingAccountsForPinning,
    readAccountsForPinning
}) => {

    const { master_account_id } = useParams();

    useEffect(() => {
        readAccountsForPinning(master_account_id, { page: 1, limit: 100 });
    }, [master_account_id, readAccountsForPinning]);

    const isPinned = (account_id) => {
        return (accounts.findIndex(account => account.id === account_id) !== -1)
    }

    const rows = [
        ...accounts.map(pinnedAccount => {
            const { contact_count, lead_count, opportunity_count, primary_contact, status, id, name } = pinnedAccount;

            return (
                <Table.Row key={id}>
                    <Table.Cell>
                        <Popup
                            trigger={<Icon onClick={() => { onUnpin(pinnedAccount.id); }} color='primary' link className='fas thumbtack' />}
                            content='Unpin'
                            inverted
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <Dropdown
                            placeholder='Pin an Account'
                            selectOnBlur={false}
                            selection
                            options={accountsForPinning.filter(({ value }) => !isPinned(value))}
                            value={id}
                            onChange={(_, { value: account_id }) => {
                                if (account_id) {
                                    if (account_id !== id) {
                                        onReplacePinnedAccount(id, account_id);
                                    }
                                } else {
                                    onUnpin(id);
                                }
                            }}
                            compact
                            text={(
                                <LinkedAccount
                                    account={name}
                                    maxWidth={'8.25em'}
                                    subtitle={id && `ID: ${id}`}
                                    popup
                                    linkToAccount={<Link onClick={e => {e.stopPropagation();}} target={"_blank"} to={`/account/${id}`}>{name}</Link>}
                                />
                            )}
                            clearable
                            loading={isReadingAccountsForPinning}
                            style={{ width: '15em' }}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        {primary_contact?.id && (
                            <Grid columns='equal' verticalAlign='middle'>
                                <Grid.Column>
                                    <LinkedAccount 
                                        maxWidth={'8.25em'} 
                                        account={`${primary_contact.first_name} ${primary_contact.last_name}`} 
                                        subtitle={primary_contact.primary_email?.email}
                                        linkToAccount={<Link target={'_blank'} to={`/contact/${primary_contact.id}`}>{primary_contact.first_name} {primary_contact.last_name}</Link>}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Icon disabled={!primary_contact.primary_phone} link color='primary' className='fas fa-phone' />
                                    <Icon disabled={!primary_contact.primary_phone} link color='primary' className='fas fa-comment' />
                                    <Icon disabled={!primary_contact.primary_email} link color='primary' className='fas fa-envelope' />
                                </Grid.Column>
                            </Grid>
                        )}
                    </Table.Cell>
                    <Table.Cell>
                        <List>
                            <List.Item>
                                <ButtonLink>{contact_count || 0} contact{contact_count > 1 && 's'}</ButtonLink>
                            </List.Item>
                            <List.Item>
                                <ButtonLink>{lead_count || 0} lead{lead_count > 1 && 's'}</ButtonLink>
                            </List.Item>
                            <List.Item>
                                <ButtonLink>{opportunity_count || 0} {opportunity_count > 1 ? 'opportunities' : 'opportunity'}</ButtonLink>
                            </List.Item>
                        </List>
                    </Table.Cell>
                    <Table.Cell>
                        <Dropdown
                            options={[
                                {
                                    key: 'active', value: 'active', text: 'Active'
                                },
                                {
                                    key: 'convert', value: 'convert', text: 'Convert'
                                },
                                {
                                    key: 'deactivated', value: 'deactivated', text: 'Deactivated'
                                }
                            ]}
                            as={Button}
                            color={ACCOUNT_STATUS_COLORS[status]}
                            value={status}
                            fluid
                            size='tiny'
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <EllipsisDropdown>
                            {ELLIPSIS_OPTIONS.map(({ text, icon, ...otherProps }, idx) => {
                                return (
                                    <DropdownItem
                                        key={idx}
                                        text={text}
                                        icon={icon}
                                        {...otherProps}
                                    />
                                );
                            })}
                        </EllipsisDropdown>
                    </Table.Cell>
                </Table.Row>
            );
        })
    ];

    while (rows.length < 3) {
        rows.push((
            <Table.Row>
                <Table.Cell>
                    <Icon color='grey' className='fas thumbtack' />
                </Table.Cell>
                <Table.Cell>
                    <Dropdown
                        placeholder='Pin an Account'
                        selectOnBlur={false}
                        selection
                        options={accountsForPinning.filter(({ value }) => (accounts.findIndex(account => account.id === value) === -1))}
                        onChange={(_, { value: account_id }) => { onPin(account_id) }}
                        compact
                        style={{ width: '15em' }}
                        value={null}
                        loading={isReadingAccountsForPinning}
                    />
                </Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
            </Table.Row>
        ))
    }

    return (
        <React.Fragment>
            <Header as='h4' color='blue'>
                <LinkAccountDropdown
                    triggerProps={{
                        trigger: <Icon size='large' className='fas fa-plus-circle' />,
                        icon: ''
                    }}
                /> Accounts
            </Header>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Table striped={false}>
                            <SemanticTable.Header className='Table__header--primary'>
                                <Table.Row>
                                    <SemanticTable.HeaderCell width={1} />
                                    <SemanticTable.HeaderCell>Account</SemanticTable.HeaderCell>
                                    <SemanticTable.HeaderCell>Primary Contact</SemanticTable.HeaderCell>
                                    <SemanticTable.HeaderCell>Modules</SemanticTable.HeaderCell>
                                    <SemanticTable.HeaderCell>Account Status</SemanticTable.HeaderCell>
                                    <SemanticTable.HeaderCell>Actions</SemanticTable.HeaderCell>
                                </Table.Row>
                            </SemanticTable.Header>
                            <Table.Body>
                                {rows}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    );
});