import React from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { Menu, Segment } from "semantic-ui-react";

import './index.scss'

const CustomerTab = () => {
    const { account_id } = useParams();

    const routeRoot = `/account/${account_id}/customer`;

    return (
        <div className='CustomerTab'>
            <Segment className='CustomerTab__Segment'>
                <Menu className='CustomerTab__Menu' color='primary' pointing secondary>
                    <Menu.Item
                        name='Pipelines'
                        as={NavLink}
                        to={`${routeRoot}/pipelines`}
                    />
                    <Menu.Item
                        name='Orders/Invoices'
                        content={<span>Orders/Invoices</span>}
                        as={NavLink}
                        to={`${routeRoot}/orders_invoices`}
                    />
                    <Menu.Item
                        name='Recurring Products'
                        as={NavLink}
                        to={`${routeRoot}/recurring_products`}
                    />
                </Menu>
                <Outlet />
            </Segment>
        </div>
    )
}

export default CustomerTab;
