import React, { useEffect, useState } from 'react';
import { TextTemplates, Notification, STATUS_TYPES } from "dyl-components";
import { useDispatch, connect } from 'react-redux';
import { Grid, Pagination } from 'semantic-ui-react';
import { useNavigate, useSearchParams } from "react-router-dom";

import templateCategoryActions from 'actions/template_category';

import TextTemplateModal from 'shared/modals/TextTemplateModal';
import textTemplateActions from 'actions/text_templates';

import PreviewTextTemplateModal from 'shared/modals/PreviewTextTemplateModal';
import textAttachmentActions from 'actions/text_attachment';
import imageActions from 'actions/image';
import { DateTimeUtils } from 'dyl-components';

const LIMIT = 25;

const TextMessageLibrary = ({
    onReadCategories,
    onReadTemplates,
    onDeleteTemplate,
    onCreateTemplate,
    onUpdateTemplate,

    templates,

    onAddAttachments,
    user_id,
    onUploadMMS,
    onReadImageUrl,
    attachments,
    onRemoveAttachment,

    isReading,
    categoryOptions,
    template_count
}) => {
    const [params] = useSearchParams();

    const templateSearchQuery = params.get('search') || '';
    const [search, setSearch] = useState(params.get('search') || '');
    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        onReadCategories();
        onReadTemplates(Object.fromEntries(params));
    }, [onReadCategories, onReadTemplates, params]);

    const [filters, setFilters] = useState([]);

    const onFilter = (_, { name, value }) => {
        const query = new URLSearchParams(params);
        query.set('page', 1);
        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }
        switch (name) {
            case 'categories':
                if (value.length <= 0) {
                    query.delete('template_category_id');
                } else {
                    query.set('template_category_id', value.join(','));
                }
                break;
            case 'filters':
                setFilters(value);
                return;
            default: return;
        }
        const query_string = query.toString();
        navigate(`/settings/library/text-templates${query_string ? `?${query_string}` : ''}`,);
    }

    const onSearchSubmit = (searchValue) => {
        const query = new URLSearchParams(params);
        if (searchValue && search.trim()) {
            query.set('search', search.trim());
        } else {
            query.delete('search');
        }
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`/settings/library/text-templates${query_string ? `?${query_string}` : ''}`);
    }

    const onSearchCancel = () => {
        setSearch('');
        onSearchSubmit();
    }

    const onFavorite = (template_id) => {
        // TODO: not yet implemented
    }

    const onDelete = async (template_id) => {
        try {
            await onDeleteTemplate(template_id);
            Notification.alert('Successfully deleted template', STATUS_TYPES.SUCCESS, true);
            const isNowEmpty = templates.length - 1 === 0;
            if (!isNowEmpty) {
                onReadTemplates(Object.fromEntries(params));
            } else {
                const query = new URLSearchParams(params);
                const page = query.get('page') || 1;
                if (Number(page) !== 1) {
                    query.set('page', page - 1);
                    const query_string = query.toString();
                    navigate(`/settings/library/text-templates${query_string ? `?${query_string}` : ''}`);
                } else {
                    onReadTemplates(Object.fromEntries(query));
                }
            }
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to delete template', STATUS_TYPES.ERROR, true);
        }
    }

    const [isAddTemplateModalOpen, setAddTemplateModalOpen] = useState(false);
    const [templateBeingCopied, setTemplateBeingCopied] = useState(null);

    const onOpenAddTemplateModal = () => {
        setAddTemplateModalOpen(true);
    }

    const onCloseAddTemplateModal = async () => {
        setAddTemplateModalOpen(false);
    }

    useEffect(() => {
        if (!isAddTemplateModalOpen) {
            setTemplateBeingCopied(null)
        }
    }, [isAddTemplateModalOpen]);

    const [saving, setSaving] = useState(false);

    const addAttachments = async (files, template_id) => {
        if (files.length > 0) {
            const toUpload = files.filter(file => file.id === undefined);
            const toCopy = files.filter(file => file.id).map(file => ({
                file_id: file.file_id,
                name: file.name
            }));
            const uploadedFiles = await Promise.all(toUpload.map(file => onUploadMMS(file)));
            return onAddAttachments([
                ...uploadedFiles.map((file, index) => ({
                    file_id: file.id,
                    name: files[index].name
                })),
                ...toCopy
            ], user_id, template_id);
        }
        return Promise.resolve();
    }

    const onCreate = async (newTemplate) => {
        try {
            await setSaving(true);
            const [template_id] = await onCreateTemplate({
                template_category_id: newTemplate.category,
                name: newTemplate.name,
                message: newTemplate.text
            });
            const files = newTemplate.attachments;
            await addAttachments(files, template_id);
            Notification.alert('Successfully created template!', STATUS_TYPES.SUCCESS);
            onReadTemplates(Object.fromEntries(params));
            return Promise.resolve();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to create template', STATUS_TYPES.ERROR);
            return Promise.reject(e);
        } finally {
            await setSaving(false);
        }
    }

    const [templateBeingEdited, setTemplateBeingEdited] = useState(null);
    const [templateBeingPreviewed, setTemplateBeingPreviewed] = useState(null);

    const onEdit = (template) => {
        setTemplateBeingEdited(template.id);
    }

    const onPreviewFromEdit = async (template) => {
        const { text, attachments } = template;
        const newAttachments = attachments.filter(attachment => attachment.id === undefined);
        const oldAttachments = attachments.filter(attachment => attachment.id);
        try {
            const urls = oldAttachments.length ? await onReadImageUrl(oldAttachments.map(attachment => attachment.file_id)) : [];
            setTemplateBeingPreviewed({
                text, attachments: [
                    ...urls.map(url => ({ type: 'photo', uri: url })),
                    ...newAttachments.map(attachment => ({ type: 'photo', uri: URL.createObjectURL(attachment) }))
                ]
            });
        } catch (e) {
            console.log(e);
        }
    }

    const onPreview = async (template) => {
        setTemplateBeingPreviewed(template.id);
        dispatch(textTemplateActions.getTemplate(template.id)).then(async ({
            attachments,
            message
        }) => {
            const urls = attachments?.data?.length ? await onReadImageUrl(attachments.data.map(attachment => attachment.file_id)) : [];
            setTemplateBeingPreviewed({ text: message, attachments: urls.map(url => ({ type: 'photo', uri: url })) })
        });
    }
    
    const onCopy = async (template) => {
        const { id } = template
        await setTemplateBeingCopied(id);
        await setAddTemplateModalOpen(true);
    }

    const onCloseEditTemplateModal = () => {
        setTemplateBeingEdited(null);
    }

    const onClosePreviewTextTemplateModal = () => {
        setTemplateBeingPreviewed(null);
    }

    const removeAttachments = (files, template_id) => {
        const toRemove = attachments.filter(attachment => files.findIndex(file => file.id === attachment.id) === -1);
        if (toRemove.length <= 0) {
            return Promise.resolve();
        }
        return Promise.all(toRemove.map(file => onRemoveAttachment(file.id, template_id, user_id)));
    }

    const onUpdate = async (template_id, updatedTemplate) => {
        try {
            await setSaving(true);
            await onUpdateTemplate(template_id, {
                template_category_id: updatedTemplate.category,
                name: updatedTemplate.name,
                message: updatedTemplate.text
            });
            const files = updatedTemplate.attachments;
            const filesToAdd = files.filter(file => file.id === undefined);
            await Promise.all([
                addAttachments(filesToAdd, template_id),
                removeAttachments(files.filter(file => file.id), template_id)
            ])
            Notification.alert('Successfully updated template!', STATUS_TYPES.SUCCESS);
            onReadTemplates(Object.fromEntries(params));
            return Promise.resolve();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update template', STATUS_TYPES.ERROR);
            return Promise.reject(e);
        } finally {
            await setSaving(false);
        }
    }

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`/settings/library/text-templates${query_string ? `?${query_string}` : ''}`);
    }

    const getTemplatePreview = () => {
        return {
            ...templateBeingPreviewed
        };
    }

    const selectedCategories = params.get('template_category_id')?.split(',').map(id => parseInt(id)) || []
    const query = new URLSearchParams(params);
    const page = query.get('page');

    return (
        <React.Fragment>
            <TextTemplates
                categoryOptions={categoryOptions || []}
                categories={selectedCategories}
                filters={filters}
                search={templateSearchQuery}
                onChangeSearch={onChangeSearch}
                onSearchSubmit={onSearchSubmit}
                onSearchCancel={onSearchCancel}
                onChangeSelectedCategories={onFilter}
                onChangeSelectedFilters={onFilter}
                templates={templates}
                onFavorite={onFavorite}
                onDelete={onDelete}
                onOpenAddTemplateModal={onOpenAddTemplateModal}
                onEdit={onEdit}
                onPreview={onPreview}
                onCopy={onCopy}

                page={page}
                count={template_count}
                isReading={isReading}
            />
            {isAddTemplateModalOpen && (
                <TextTemplateModal
                    open={isAddTemplateModalOpen}
                    onClose={onCloseAddTemplateModal}
                    onCreate={onCreate}
                    categoryOptions={categoryOptions}
                    isSaving={saving}
                    onPreview={onPreviewFromEdit}
                    duplicate_id={templateBeingCopied}
                />
            )}
            {templateBeingEdited && (
                <TextTemplateModal
                    open={templateBeingEdited}
                    onClose={onCloseEditTemplateModal}
                    onUpdate={onUpdate}
                    mode='Edit'
                    categoryOptions={categoryOptions}
                    id={templateBeingEdited}
                    isSaving={saving}
                    onPreview={onPreviewFromEdit}
                />
            )}
            {templateBeingPreviewed && (
                <PreviewTextTemplateModal
                    open={templateBeingPreviewed}
                    onClose={onClosePreviewTextTemplateModal}
                    text_template={getTemplatePreview()}
                />
            )}
            {template_count > LIMIT &&
                <Grid>
                    <Grid.Row>
                        <Grid.Column textAlign='right' style={{ paddingRight: 0 }}>
                            <Pagination
                                boundaryRange={0}
                                activePage={params.get('page') || 1}
                                ellipsisItem={null}
                                siblingRange={2}
                                totalPages={Math.ceil(template_count / LIMIT)}
                                onPageChange={onPageChange}
                                disabled={isReading}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    const isProcessingAttachments = state.upload.isUploadingFile || state.text_attachment.isCreatingAttachment;
    const isUpdatingTemplate =
        state.text_template.templateBeingUpdated ||
        state.text_attachment.attachmentBeingDeleted ||
        isProcessingAttachments;

    const isCreatingTemplate =
        state.text_templates.isCreatingTemplate || isProcessingAttachments;

    return ({
        templates: state.text_templates.templates.map(({ id, name, created, template_category_id, message, isFavorite, has_attachments }) => ({
            id,
            name,
            category: template_category_id,
            text: message || '',
            isFavorite,
            created: DateTimeUtils.formatEpoch(created, DateTimeUtils.WORD_DATETIME_FORMAT),
            has_attachments
        })),
        template_count: state.text_templates.count,
        isReading: state.text_templates.isReadingTemplates || state.template_category.isReadingCategories,
        isCreatingTemplate,

        isUpdatingTemplate,
        templateBeingDeleted: state.text_template.templateBeingDeleted,
        attachments: state.text_template.template.attachments || [],

        categoryOptions: state.template_category.categories.map(category => ({
            key: category.id,
            value: category.id,
            text: category.name
        })) || [],
        category_count: state.template_category.count,
        user_id: state.auth.user_id
    })
};

const mapDispatchToProps = dispatch => ({
    onReadCategories: () => {
        return dispatch(templateCategoryActions.read({ category: 'text' }));
    },

    onReadTemplates: (queryParameters) => {
        return dispatch(textTemplateActions.getTemplates(queryParameters))
    },
    onCreateTemplate: (payload) => {
        return dispatch(textTemplateActions.createTemplates([payload]));
    },
    onUpdateTemplate: (id, payload) => {
        return dispatch(textTemplateActions.updateTemplate(id, payload));
    },
    onDeleteTemplate: (id) => {
        return dispatch(textTemplateActions.deleteTemplate(id));
    },

    onUploadMMS: (file) => {
        return dispatch(imageActions.uploadMMSPayload(file))
    },
    onAddAttachments: (attachments, user_id, template_id) => {
        return dispatch(textAttachmentActions.createAttachments(attachments, { user_id }, template_id))
    },
    onRemoveAttachment: (attachment_id, text_template_id, user_id) => {
        return dispatch(textAttachmentActions.deleteAttachment(attachment_id, { user_id, text_template_id }));
    },
    onReadAttachments: (template_id, user_id) => {
        return dispatch(textAttachmentActions.getAttachments(template_id, { user_id }));
    },
    onReadImageUrl: (file_id) => {
        return dispatch(imageActions.getUrls(file_id, 'mms'));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(TextMessageLibrary);
