import React from 'react'; 
import { Button } from 'semantic-ui-react';
import { SearchBar } from 'dyl-components';
import '../index.scss';

const Toolbar = ({ search, onSearchSubmit, cancelFunction, onChangeSearch, isReadingUserExtensions }) => {
    return (
        <div className='UserExtensionsToolbar'>
            <div style={{flex: 1}}>
                <SearchBar
                    isSearching={isReadingUserExtensions}
                    search={search}
                    searchFcn={onSearchSubmit}
                    cancelFcn={cancelFunction}
                    placeholder='Search by name or extension'
                    addClassNames='UserExtensions__search'
                    onChange={onChangeSearch}
                />
            </div>
            <div>
                <Button className='UserExtensionsToolbar__button' disabled onClick={() => {}}>
                    Bulk Actions
                </Button>
            </div>
        </div>
    )
}

export default Toolbar;
