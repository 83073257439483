import { DateTimeUtils, yup } from "dyl-components";

export const checkoutSchema = {
    invoice_name: yup.string().nullable(true),
    payment_method: yup.string().required("This field is required"),
    custom_ach: yup.string().when("payment_method", {
        is: "custom-ach",
        then: (schema) => schema.required("This field is required"),
    }),
    term: yup.string().required("This field is required"),
    due_on: yup
        .string()
        .required("This field is required")
        .test("valid_date", "Invalid date", (value) => {
            return value
                ? DateTimeUtils.isValid(
                      value,
                      DateTimeUtils.WORD_DATE_FORMAT
                  )
                : true;
        })
        .test(
            "cannot_select_past_dates",
            "Due date should not be a past date",
            function (value) {
                if (!!!value) {
                    return true;
                }
                const currentDate = DateTimeUtils.getCurrentDate(
                    DateTimeUtils.WORD_DATE_FORMAT
                );
                return !DateTimeUtils.dateIsBeforeAnotherDate(
                    value,
                    currentDate,
                    "days",
                    DateTimeUtils.WORD_DATE_FORMAT
                );
            }
        )
        .test(
            "cannot_go_beyond_a_date",
            "Due date should be at most 6 months from now",
            function (value) {
                if (!!!value) {
                    return true;
                }
                const maxDate = DateTimeUtils.changeFormat(
                    DateTimeUtils.getNext(6, "month", false, true),
                    DateTimeUtils.DATE_FORMAT,
                    DateTimeUtils.WORD_DATE_FORMAT
                );
                return DateTimeUtils.dateIsBeforeAnotherDate(
                    value,
                    maxDate,
                    "days",
                    DateTimeUtils.WORD_DATE_FORMAT
                );
            }
        ),
}
