import { VALIDATORS, yup } from "dyl-components";
import applyCurrencySchema from "./applyCurrencySchema";

const nameValidator = VALIDATORS.SIMPLE_INPUT()
    .noemoji()
    .minlength(2)
    .maxlength(200)
    .no_whitespace_only();

export const variantsSchema = {
    name: nameValidator.required("This field is required"),
    values: yup
        .array()
        .of(
            yup.object({
                value: nameValidator.required("This field is required"),
                additional_price: yup.lazy((value) =>
                    value === ""
                        ? yup.string()
                        : applyCurrencySchema(yup.number())
                ),

                // TODO: get actual validation format for SKU
                // TODO: add validation to make SKUs unique per variant
                sku: yup.string().required("This field is required"),
            })
        )
        .min(1, "There should be at least one variant value"),
};
