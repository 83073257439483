import React from 'react';
import queryString from 'query-string';
import { Navigate } from 'react-router-dom';
import { Loader } from 'semantic-ui-react'
import { connect } from 'react-redux';
import OAuthActions from 'actions/oauth';
import authActions from 'actions/auth/auth';
import { Notification, STATUS_TYPES } from 'dyl-components';

class OAuthRedirect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			redirect: '' // path to redirect to
        }
    }

    componentDidMount() {
        this.props.authCheck().then(()=>{
            const path = `/settings/users/${this.props.user_id}/integration`;
            this.props.onProcess().then((response) => {
                if (response.message) {
                    Notification.alert(response.message, STATUS_TYPES.SUCCESS, true);
                } else if (response.error) {
                    Notification.alert(response.error, STATUS_TYPES.ERROR, true);
                }
                this.setState({redirect: path});
            }).catch((e) => {
                console.log(e);
                Notification.alert('Integration Failed', STATUS_TYPES.ERROR, true);
                this.setState({redirect: path});
            });
        });
        
    }

    render() {
		if (this.state.redirect) {
			return (<Navigate to={this.state.redirect} />)
		}
		return (
			  <Loader active={true} inline="centered" />
        );
    }
}

const mapStateToProps = state => ({
    user_id: state.auth.user_id
})

const mapDispatchToProps = dispatch => ({
	onProcess: () => {
		const query = queryString.parse(window.location.search);
		if (query.code && query.state) {
			return dispatch(OAuthActions.processOAuthCallback(query));
		} else if (query.message || query.error) {
			return Promise.resolve(query)
		} else {
			return Promise.resolve({"error": "Access not grated"})
		}
	},
    authCheck: () => dispatch(authActions.check())
})

export default connect(mapStateToProps, mapDispatchToProps)(OAuthRedirect);
