import React, { useState } from "react";
import {
    Grid,
    Header,
    Icon,
    Popup,
    Segment,
    Transition,
} from "semantic-ui-react";
import { ButtonLink } from "dyl-components";
import {
    NavLink as Link,
    useParams,
    useLocation,
    createSearchParams,
} from "react-router-dom";

import ContactInformation from "shared/ContactInformation";
import CompanyInfo from "shared/CompanyInfo";
import InterestsSection from "shared/InterestsSection";
import HouseholdInfo from "shared/HouseholdInfo";
import CustomDataOverview from "shared/CustomDataOverview";
import { useSelector } from "react-redux";
import { StringUtils } from "utils";
import LeadInfo from "shared/LeadInfo";
import ProductInterestsSection from "shared/ProductInterestsSection";
import OpportunityInfo from "shared/OpportunityInfo";
import GroupsSection from "shared/GroupsSection";
import { ClippedContent } from "dyl-components";
import "./index.scss";
import QuotesSection from "./QuotesSection";
import QuoteBuilderModal from "shared/modals/QuoteBuilderModal";
import RouteSection from "shared/RouteSection";

const ProfileDetails = () => {
    const { contact_id } = useParams();
    const location = useLocation();

    const current_stage = useSelector((state) => state.contact.current_stage);

    const pipeline_id = current_stage?.pipeline_id;
    const account_stage = current_stage?.account_stage;

    const isOpportunity = account_stage === "opportunity";
    const defaultExpandedSections = [
        "business_details",
        "household_details",
        ...(account_stage ? ["stage"] : []),
        ...(isOpportunity ? ["products"] : []),
    ];

    const [expandedSections, setExpandedSections] = useState(
        defaultExpandedSections
    );

    const isExpanded = (section) => {
        return expandedSections.includes(section);
    };

    const onToggle = (section) => {
        if (isExpanded(section)) {
            setExpandedSections(
                expandedSections.filter(
                    (expandedSection) => expandedSection !== section
                )
            );
        } else {
            setExpandedSections([...expandedSections, section]);
        }
    };

    const atLeastOneExpanded = expandedSections.length > 0;

    const collapseAllData = () => {
        setExpandedSections([]);
    };

    const expandAllData = () => {
        setExpandedSections(defaultExpandedSections);
    };

    const getLocationLabel = (label) => {
        switch (label) {
            case "Home":
            case "Other":
            case "Business":
                return label;
            default:
                return "Other";
        }
    };

    const currentStageInformation = (() => {
        switch (account_stage) {
            case "lead":
                return (
                    <div>
                        <ProductInterestsSection />
                        <LeadInfo />
                    </div>
                );
            case "opportunity":
                return (
                    <div>
                        <OpportunityInfo />
                    </div>
                );
            default:
                return null;
        }
    })();

    const renderedPipelineId = (
        <>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                    <ClippedContent popupContent={pipeline_id}>
                        <Link
                            to={{
                                pathname: location.pathname,
                                search: createSearchParams({
                                    ...Object.fromEntries(
                                        new URLSearchParams(location.search)
                                    ),
                                    module_id: pipeline_id,
                                }).toString(),
                            }}
                            state={{ isTogglingPanel: true }}
                            style={{
                                maxWidth: "4em",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                display: "inline-block",
                                marginBottom: "-0.3em",
                                marginInline: "10px",
                            }}
                        >
                            {pipeline_id}
                        </Link>
                    </ClippedContent>
                </div>
            </div>
            <Popup
                trigger={
                    <div>
                        <Icon
                            link
                            className="fas fa-copy OpportunityId__id"
                            onClick={StringUtils.copy(pipeline_id)}
                        />
                    </div>

                }
                content="Copied!"
                on="click"
                closeOnTriggerMouseLeave
                inverted
            />
        </>
    );

    const account_is_a_customer = useSelector(
        (state) => state.contact.account?.has_customer
    );
    const account_primary_contact_id = useSelector(
        (state) => state.contact.account?.primary_contact_id
    );

    const account_id = useSelector((state) =>
        state.contact.account_id ? Number(state.contact.account_id) : null
    );

    return (
        <Segment basic>
            <Grid stackable>
                <Grid.Row columns={4}>
                    <Grid.Column>
                        <Grid className="ContactInfo">
                            <Grid.Row>
                                <Grid.Column>
                                    <ButtonLink
                                        onClick={
                                            atLeastOneExpanded
                                                ? collapseAllData
                                                : expandAllData
                                        }
                                    >
                                        {atLeastOneExpanded
                                            ? "Collapse"
                                            : "Expand"}{" "}
                                        All Data{" "}
                                        <Icon
                                            className={`fas fa-circle-chevron-${atLeastOneExpanded
                                                ? "up"
                                                : "down"
                                                }`}
                                        />
                                    </ButtonLink>{" "}
                                    |{" "}
                                    <ButtonLink>
                                        <Link
                                            to={`/contact/${contact_id}/data`}
                                        >
                                            View All Data
                                        </Link>
                                    </ButtonLink>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <ContactInformation
                            getLocationLabel={getLocationLabel}
                        />
                        <RouteSection />
                        <InterestsSection />
                        <GroupsSection />
                    </Grid.Column>
                    <Grid.Column>
                        <Grid className="ContactInfo">
                            <Grid.Row>
                                <Grid.Column>
                                    <CompanyInfo
                                        businessDetailsExpanded={isExpanded(
                                            "business_details"
                                        )}
                                        toggleBusinessDetails={() => {
                                            onToggle("business_details");
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Grid className="ContactInfo">
                            <Grid.Row>
                                <Grid.Column>
                                    <HouseholdInfo
                                        toggleHouseholdDetails={() => {
                                            onToggle("household_details");
                                        }}
                                        householdDetailsExpanded={isExpanded(
                                            "household_details"
                                        )}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    {account_stage && account_stage !== "customer" && (
                        <Grid.Column>
                            <Grid className="ContactInfo">
                                <Grid.Row>
                                    <Grid.Column>
                                        <Header
                                            color="primary"
                                            style={{
                                                whiteSpace: "nowrap",
                                                display: "flex",
                                                flexDirection: "row",
                                            }}
                                        >
                                            <div>
                                                {StringUtils.capitalize(
                                                    account_stage
                                                )}{" "}
                                                <span className="OpportunityId__label">
                                                    ID:
                                                </span>{" "}
                                            </div>
                                            {renderedPipelineId}
                                        </Header>
                                        <Transition
                                            visible={isExpanded("stage")}
                                            animation={"slide down"}
                                            unmountOnHide
                                            duration={"150"}
                                        >
                                            {currentStageInformation}
                                        </Transition>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    )}
                    {account_stage === "opportunity" && (
                        <Grid.Column>
                            <Grid className="ContactInfo">
                                <Grid.Row>
                                    <Grid.Column>
                                        <QuotesSection
                                            isExpanded={isExpanded("products")}
                                            toggler={
                                                <Icon
                                                    link
                                                    size="small"
                                                    className={`fas fa-circle-chevron-${isExpanded("products")
                                                        ? "up"
                                                        : "down"
                                                        }`}
                                                    onClick={() => {
                                                        onToggle("products");
                                                    }}
                                                />
                                            }
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    )}
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <CustomDataOverview />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <QuoteBuilderModal
                account_id={account_id}
                {...(isOpportunity ? { opportunity_id: pipeline_id } : {})}
                contact_id={contact_id}
                isLinkedToCustomer={account_is_a_customer}
                account_primary_contact_id={account_primary_contact_id}
            />
        </Segment>
    );
};

export default ProfileDetails;
