import { yup } from "dyl-components";

export const FILE_CATEGORIES = {
    PROFILE: "profile",
    DOCUMENT: "document",
    IMPORT: "import",
    TEXT: "text",
    EMAIL: "email",
    EVENT: "event",
    PHONE_SYSTEM: "phone_system"
}

export default class FileUtils {
    static fileSize = {
        profile: 5000000, //5MB
        document: 10000000, //10MB
        import: 10000000, //10MB
        text: 1000000, //1MB
        email: 10000000, //10MB
        event: 10000000, //10MB
        phone_system: 10000000, //10MB
    }

    static getFilesizeValidator = (category) => {
        return yup.array().test("max_file_size_limit", `Total size exceeds ${FileUtils.getMaxFileSize(category)}MB`, (value) => {
            return FileUtils.isBelowFileSizeLimit(value, category);
        })
    }

    static getTotalFileSize = files => files.reduce((a, b) => a + b.size || 0, 0);

    static getRemainingSize = (files, type) => {
        const remainingBytes = ((this.fileSize[type] - this.getTotalFileSize(files)) / 1000000); 
        return Math.max(0, remainingBytes.toFixed(2));
    }

    static isBelowFileSizeLimit = (value, type) => { 
        return this.getTotalFileSize(value) < this.fileSize[type];
    }

    static getMaxFileSize = type => { 
        return Math.max(0, (this.fileSize[type] / 1000000).toFixed(2))
    }

    static convertBase64 = (data=[], toString=false) => {
        return !toString ? Buffer.from(data, 'base64') : data.toString('base64');
    }
    
    static readFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onabort = () => console.log('|+|file reading was aborted')
            reader.onerror = reject;
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(file);
        })
    }
}
