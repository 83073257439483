import React from 'react'
import { Grid, Tab } from 'semantic-ui-react'
import { HTMLCodePane } from './HTMLCodePane';
import { ViewportModePane } from './ViewportModePane';
import { TextEmailPane } from './TextEmailPane';
import { FormDataPane } from './FormDataPane';
import { QueryStringPane } from './QueryStringPane';

export const ParseTemplateLeftSide = ({ email, isEmail }) => {
    const { html, text } = email;

    const emailPanels = () => {
        let tabs = [
            { menuItem: 'Viewport Mode', render: () => <ViewportModePane /> },
            { menuItem: 'Text Email', render: () => <TextEmailPane /> },
            { menuItem: `Source Code`, render: () => <HTMLCodePane /> }
        ];

        if (!html) { tabs.pop() } //Remove the source tab if the email is text only
        if(!text){ tabs.splice(1,1) } //Remove text tab if email is not mulitpart

        return tabs
    }
    const postPanels = () => {
        let tabs = [
            { menuItem: 'Form Data', render: () => <FormDataPane /> },
            { menuItem: 'Query String', render: () => <QueryStringPane /> },
        ];
        return tabs
    }

    return (

        <Grid.Column width={ 8 }>
            <Tab panes={ isEmail ? emailPanels() : postPanels() } />
        </Grid.Column>
    );
}
