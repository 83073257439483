import { yup } from "dyl-components";
import { applyVolumeBasedBulkPricingTierSchema } from "./volumeBasedSchema";

const getVolumeBasedRecurringPricingFrequencySchema = (frequency) => ({
    [`volume_based_recurring_${frequency}_volume_prices`]:
        yup.array().optional().when(
            ["model", "schedule", "volume_based_recurring_frequencies"],
            {
                is: (model, schedule, frequencies) => {
                    const active = frequencies?.includes(frequency);
                    return (
                        model === "volume" && schedule === "recurring" && active
                    );
                },
                then: (schema) => applyVolumeBasedBulkPricingTierSchema(schema),
                otherwise: (schema) => schema.nullable(true),
            }
        ),
});

const volumeBasedRecurringProductSchema = {
    volume_based_recurring_frequencies: yup
        .array()
        .when(["model", "schedule"], {
            is: (model, schedule) =>
                model === "volume" && schedule === "recurring",
            then: (schema) =>
                schema
                    .of(yup.string())
                    .min(1, "At least one pricing frequency should be checked")
                    .required(
                        "At least one pricing frequency should be checked"
                    ),
            otherwise: (schema) => schema.nullable(true),
        }),
    ...getVolumeBasedRecurringPricingFrequencySchema("monthly"),
    ...getVolumeBasedRecurringPricingFrequencySchema("quarterly"),
    ...getVolumeBasedRecurringPricingFrequencySchema("semi-annually"),
    ...getVolumeBasedRecurringPricingFrequencySchema("annually"),
};

const paymentFrequencies = [
    "monthly",
    "quarterly",
    "semi-annually",
    "annually",
];

export function formatVolumeRecurringPriceData(price_data) {
    const frequencies = price_data.volume_based_recurring_frequencies;
    return {
        price: paymentFrequencies.reduce((data, frequency) => {
            const root = `volume_based_recurring_${frequency}`;
            const volumes = price_data[`${root}_volume_prices`];
            const isValid = applyVolumeBasedBulkPricingTierSchema(yup.array()).isValidSync(volumes);
            return {
                ...data,
                ...(isValid
                    ? {
                        [frequency]: {
                            active: frequencies?.includes(frequency),
                            volumes: volumes.map(
                                ({ end, ...price }) => ({
                                    ...price,
                                    ...(end ? { end: Number(end) } : {}),
                                })
                            )
                        },
                    }
                    : {}),
            };
        }, {}),
    };
}

export function transformVolumeRecurringPricingDetails(price_data) {
    return {
        volume_based_recurring_frequencies: paymentFrequencies.filter(
            (paymentFrequency) => price_data.price[paymentFrequency]?.active
        ),
        ...paymentFrequencies.reduce((data, frequency) => {
            return {
                ...data,
                [`volume_based_recurring_${frequency}_volume_prices`]:
                    price_data.price[frequency]?.volumes || [
                        {
                            start: 1,
                            end: null,
                            price: null,
                        },
                    ],
            };
        }, {}),
    };
}

export default volumeBasedRecurringProductSchema;
