import React, { useState } from 'react';
import { Header, Icon, Segment, Loader, Form } from 'semantic-ui-react';
import { ButtonLink, generateResolver, Notification, STATUS_TYPES, VALIDATORS, yup } from 'dyl-components';

import contactLocationsActions from 'actions/contact_location';
import { useDispatch, useSelector } from 'react-redux';
import { StringUtils } from 'utils';
import Locations from 'shared/Locations';

import './index.scss';
import { Link } from 'react-router-dom';
import AddLocationsSection from './AddLocationsSection';
import { useFieldArray, useForm } from 'react-hook-form';

const LocationsView = ({
    control,
    mainLocationId,
    existingLocations,
    onOpenAddLocationSection,
    isAddLocationSectionOpen,
    onCloseAddLocationSection,
    onUpdatePrimaryLocation,
    isUpdatingLocation,
    id,
    type,
    location_type,
    isLocationOnEditMode,
    onEditLocation,
    tmpLocation,
    pickedLocationId,
    onConfirmEditLocation,
    onCancelEditLocation,
    isValid = true,
    loading,
    refresh
}) => (
    <div className='LocationsView'>
        <Loader active={isUpdatingLocation} />
        <Header as='h4' className='LocationsView__Header' >
            My {StringUtils.capitalize(location_type)} Locations
        </Header>
        <Locations
            control={control}
            mainLocationId={mainLocationId}
            existingLocations={existingLocations}
            isLocationOnEditMode={isLocationOnEditMode}
            isUpdatingLocation={isUpdatingLocation}
            onCancelEditLocation={onCancelEditLocation}
            onConfirmEditLocation={onConfirmEditLocation}
            onEditLocation={onEditLocation}
            onUpdatePrimaryLocation={onUpdatePrimaryLocation}
            pickedLocationId={pickedLocationId}
            tmpLocation={tmpLocation}
            location_type={location_type}
            isValid={isValid}
        />
        <Segment className={`LocationsView__Buttons${isAddLocationSectionOpen ? '' : '--close'}`} basic>
            <Form size='tiny'>
                <div>
                    <ButtonLink className={"LocationsView__AddLocationButton"} onClick={onOpenAddLocationSection}>
                        <Icon name='plus circle' /> <span> Add additional {location_type} location </span>
                    </ButtonLink>
                </div>

                {!isAddLocationSectionOpen &&
                    <div>
                        <Link to={`/${type}/${id}/data`}>
                            <Icon name='external' /> <span>View/edit profile data tab</span>
                        </Link>
                    </div>
                }
            </Form>
        </Segment>
        {isAddLocationSectionOpen &&
            <div className='LocationsView__AddLocationSection'>
                <AddLocationsSection
                    id={id}
                    type={type}
                    loading={loading}
                    refresh={refresh}
                    onCloseAddLocationsSection={onCloseAddLocationSection} 
                    location_type={location_type} 
                />
            </div>
        }
    </div>
);

const LocationsViewContainer = ({ refresh, mainLocationId, id, loading, location_type, type }) => {
    const [isAddLocationSectionOpen, setIsAddLocationSectionOpen] = useState(false);
    const [isLocationOnEditMode, setIsLocationOnEditMode] = useState(false);
    const [pickedLocationId, setPickedLocationId] = useState(null);

    const { existingLocations } = useSelector((state) => {
        return {
            existingLocations: state[type][type]?.locations || []
        };
    })

    const dispatch = useDispatch();

    const onUpdateLocation = async (locationToUpdate) => {
        const { id: location_id, ...otherProps } = locationToUpdate;
        try {
            const response = await dispatch(contactLocationsActions.updateContactLocation(id, { ...otherProps }, null, location_id));
            Notification.alert('Successfully updated location!', STATUS_TYPES.SUCCESS);
            return response;
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update location', STATUS_TYPES.ERROR);
            return Promise.reject(e);
        }
    }

    const { control, formState: { isValid, isDirty }, reset, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            locations: existingLocations?.map(location => ({
                id: location.id || 0,
                label: location.label || '',
                street: location.street || '',
                additional_street: location.additional_street || '',
                city: location.city || '',
                state: location.state || '',
                zip: location.zip || ''
            })) || []
        },
        resolver: generateResolver({
            locations: yup.array().of(yup.object().shape({
                label: yup.string().required(),
                street: yup.string().maxlength(100),
                additional_street: yup.string().maxlength(12),
                city: yup.string().maxlength(60),
                state: yup.string().required('This field is required'),
                zip: VALIDATORS.US_POSTAL_CODE(),
            }))
        })
    })

    const { fields } = useFieldArray({
        control,
        name: 'locations',
    });

    const onEditLocation = (locationId) => {
        setIsLocationOnEditMode(true);
        setPickedLocationId(locationId);
    }

    const onConfirmEditLocation = async (data) => {
        const locationToUpdate = data.locations.find(location => location.id === pickedLocationId);
        return onUpdateLocation(locationToUpdate).then(() => {
            setIsLocationOnEditMode(false);
            refresh();
        })
    }

    const onCancelEditLocation = () => {
        reset();
        setPickedLocationId(null);
        setIsLocationOnEditMode(false);
    }

    const onOpenAddLocationSection = () => {
        setIsAddLocationSectionOpen(true);
    }

    const onCloseAddLocationSection = () => {
        setIsAddLocationSectionOpen(false);
    }

    const onUpdatePrimaryLocation = (primary_location_id) => {
        const locationToEdit = {
            ...existingLocations.find((location) => {
                return location.id === primary_location_id;
            })
        }
        return onUpdateLocation({ ...locationToEdit, main: !locationToEdit.main }).then(() => {
            refresh();
        })
    }

    return (
        <LocationsView
            control={control}
            mainLocationId={mainLocationId}
            existingLocations={existingLocations}
            isAddLocationSectionOpen={isAddLocationSectionOpen}
            onOpenAddLocationSection={onOpenAddLocationSection}
            onCloseAddLocationSection={onCloseAddLocationSection}
            isUpdatingLocation={loading}
            onUpdatePrimaryLocation={onUpdatePrimaryLocation}
            location_type={location_type}
            onEditLocation={onEditLocation}
            isLocationOnEditMode={isLocationOnEditMode}
            tmpLocation={fields}
            pickedLocationId={pickedLocationId}
            onConfirmEditLocation={handleSubmit(onConfirmEditLocation)}
            onCancelEditLocation={onCancelEditLocation}
            id={id}
            type={type}
            isValid={isValid && isDirty}

            loading={loading}
            refresh={refresh}
        />
    )
}

export default LocationsViewContainer;
