import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { DateTimeUtils, Notification, STATUS_TYPES, generateResolver } from 'dyl-components';

import addonActions from 'actions/product_addons';

import AddonForm from 'shared/forms/AddonForm';
import addonSchema, { formatAddonDetails } from 'shared/schemas/addons/addonSchema';

const AddAddon = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            description: '',
            image: null,
            status: 'active',
            active_date: DateTimeUtils.getCurrentDate(DateTimeUtils.DATE_FORMAT),
            price: '',
            products: []
        },
        resolver: generateResolver(addonSchema)
    });

    const loading = useSelector(state => state.product_addons.isCreating);

    const onSave = async (data) => {
        try {
            await dispatch(addonActions.addAddon(formatAddonDetails(data)));
            Notification.alert("Succesfully created add-on", STATUS_TYPES.SUCCESS);
            methods.reset();
            navigate("/settings/product-catalog-quotes-invoices/addons", { replace: true, state: { saved: true } });
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to create add-on", STATUS_TYPES.ERROR);
        }
    }

    return (
        <FormProvider {...methods}>
            <AddonForm onSave={onSave} loading={loading} />
        </FormProvider>
    );
}

export default AddAddon;
