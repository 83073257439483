import { generateCreateActionCreator, generateReadActionCreator, generateUpdateActionCreator, generateDeleteActionCreator, generateReadOneActionCreator } from 'actions/crudActionCreators';
import { Notification, STATUS_TYPES } from 'dyl-components';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import routes from 'actions/routes';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import axiosInstance from 'actions/axiosInstance';

import imageActions from 'actions/image';

export default {
    sendSMSText: generateCreateActionCreator(routes.API_SMS_TEXT, ACTION_NAMES.SMS, () => {
        Notification.alert('Successfully sent SMS text!', STATUS_TYPES.SUCCESS, true);
    }, () => {
        Notification.alert('Failed to send SMS text', STATUS_TYPES.ERROR, true);
    }),
    sendMMSText: generateCreateActionCreator(routes.API_MMS_TEXT, ACTION_NAMES.MMS, () => {
        Notification.alert('Successfully sent MMS text!', STATUS_TYPES.SUCCESS, true);
    }, () => {
        Notification.alert('Failed to send MMS text', STATUS_TYPES.ERROR, true);
    }),


    getPhones: generateReadActionCreator(routes.API_PHONE_TEXT, ACTION_NAMES.PHONE_TEXT),
    createPhone: generateCreateActionCreator(routes.API_PHONE_TEXT, ACTION_NAMES.PHONE_TEXT),
    updatePhone: generateUpdateActionCreator(routes.API_PHONE_TEXT, ACTION_NAMES.PHONE_TEXT, () => {
        Notification.alert('Successfully updated a phone!', STATUS_TYPES.SUCCESS, true);
    }, () => {
        Notification.alert('Failed to update a phone!', STATUS_TYPES.SUCCESS, true);
    }),
    deletePhone: generateDeleteActionCreator(routes.API_PHONE_TEXT, ACTION_NAMES.PHONE_TEXT, () => {
        Notification.alert('Successfully deleted a phone!', STATUS_TYPES.SUCCESS, true);
    }, () => {
        Notification.alert('Failed to delete a phone!', STATUS_TYPES.SUCCESS, true);
    }),

    getLimits: generateReadActionCreator(routes.API_LIMIT_TEXT, ACTION_NAMES.LIMIT_TEXT),
    createLimit: generateCreateActionCreator(routes.API_LIMIT_TEXT, ACTION_NAMES.LIMIT_TEXT, () => {
        Notification.alert('Successfully created a limit!', STATUS_TYPES.SUCCESS, true);
    }, () => {
        Notification.alert('Failed to create a limit!', STATUS_TYPES.SUCCESS, true);
    }),
    updateLimit: generateUpdateActionCreator(routes.API_LIMIT_TEXT, ACTION_NAMES.LIMIT_TEXT, () => {
        Notification.alert('Successfully updated a limit!', STATUS_TYPES.SUCCESS, true);
    }, () => {
        Notification.alert('Failed to update a limit!', STATUS_TYPES.SUCCESS, true);
    }),
    deleteLimit: generateDeleteActionCreator(routes.API_LIMIT_TEXT, ACTION_NAMES.LIMIT_TEXT, () => {
        Notification.alert('Successfully deleted a limit!', STATUS_TYPES.SUCCESS, true);
    }, () => {
        Notification.alert('Failed to delete a limit!', STATUS_TYPES.SUCCESS, true);
    }),


    getConversation: generateReadActionCreator(routes.API_CONVERSATION_TEXT, ACTION_NAMES.CONVERSATION_TEXT),
    updateConversation: generateUpdateActionCreator(routes.API_CONVERSATION_TEXT, ACTION_NAMES.CONVERSATION_TEXT, () => {
        Notification.alert('Successfully updated a conversation!', STATUS_TYPES.SUCCESS, true);
    }, () => {
        Notification.alert('Failed to update a conversation!', STATUS_TYPES.SUCCESS, true);
    }),

    getMessages: generateReadOneActionCreator(routes.API_MESSAGES_TEXT, ACTION_NAMES.MESSAGES_TEXT),
    searchMessages: generateReadActionCreator(routes.API_MESSAGES_SEARCH, ACTION_NAMES.MESSAGES_SEARCH),
    getFilteredMessages: (id, queryParameters) => {
        return dispatch => {
            dispatch({
                type: getNamedAction(ACTION_NAMES.FILTER_MESSAGES_TEXT, CRUD_ACTION_TYPES.READ_ONE_REQUEST),
                id: id
            });

            return axiosInstance.instance1.get(`${routes.API_MESSAGES_TEXT}${id ? `/${id}` : ''}`, { params: queryParameters })
                .then((response) => {
                    console.log(response);

                    const { data, count, customer_phone, contact_phone } = response.data;

                    return Promise.all(data.map(({ attachment, ...otherProps }) => {
                        if (attachment && attachment.length > 0) {
                            return dispatch(imageActions.getUrls(attachment, 'mms')).then((urls) => Promise.resolve({
                                ...otherProps,
                                attachment: urls
                            })).catch(error => {
                                console.log(error);
                                return Promise.resolve({ attachment, ...otherProps });
                            });
                        }
                        return Promise.resolve({ attachment, ...otherProps });
                    })).then(messages => {
                        dispatch({
                            type: getNamedAction(ACTION_NAMES.FILTER_MESSAGES_TEXT, CRUD_ACTION_TYPES.READ_ONE_SUCCESS),
                            data: { data: messages, count, customer_phone, contact_phone },
                            queryParameters
                        });

                        return { data: messages, count, customer_phone, contact_phone };
                    });
                })
                .catch((error) => {
                    console.log(error);

                    dispatch({
                        type: getNamedAction(ACTION_NAMES.FILTER_MESSAGES_TEXT, CRUD_ACTION_TYPES.READ_ONE_FAILURE)
                    });
                })
        }
    },
    getStatus: generateCreateActionCreator(routes.API_STATUS_TEXT, ACTION_NAMES.STATUS_TEXT)

}
