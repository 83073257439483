import { Modal, generateResolver, yup, Notification, STATUS_TYPES } from 'dyl-components';
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Form, Icon, Popup } from 'semantic-ui-react';
import notificationActions from 'actions/notifications';
import { BulkActionsContext } from "shared/context/BulkActionsProvider";
import { useSearchParams, useLocation } from 'react-router-dom';

const NotificationBulkActionsModal = ({ open, onClose, numberSelectedNotifications = 0 }) => {
    const [query] = useSearchParams();
    const { pathname } = useLocation();
    const path = pathname.split("/")[1];
    const status = path === "notifications" ? "all" : path;
    const notification_type = query.get("notification_type") || '';
    const date_start_time = query.get("start") || '';
    const date_end_time = query.get("end") || '';
    const { control, reset, formState: { isValid, isDirty }, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            type: '',
        },
        resolver: generateResolver({
            type: yup.string().required('This field is required'),
        })
    });

    const dispatch = useDispatch();

    const close = () => {
        reset();
        onClose();
    }
    
    const [selectedNotifications,setSelectedData,areNotficationsInAllPagesSelected] = useContext(BulkActionsContext);

    const allOptions = [
        {
            key: 'unread', value: 'unread', text: 'Mark All as Unread', content: (
                <>
                    <Icon className="fa-solid fa-circle" />
                    Mark All as Unread
                </>
            )
        },
        {
            key: 'read', value: 'read', text: 'Mark All as Read', content: (
                <>
                    <Icon className="fa-solid fa-circle-dashed" />
                    Mark All as Read
                </>
            )
        },
        {
            key: 'archive', value: 'archive', text: 'Archive All', content: (
                <>
                    <Icon className="fa-solid fa-box-archive" />
                    Archive All
                </>
            )
        }
    ];

    const archivedOptions = [
        {
            key: 'unarchive', value: 'unarchive', text: 'Unarchive All', content: (
                <>
                    <Icon className="fa-solid fa-box-archive" />
                    Unarchive All
                </>
            )
        },
        {
            key: 'delete', value: 'delete', text: 'Delete All', content: (
                <>
                    <Icon className="trash" />
                    Delete All
                </>
            )
        }
    ];

    const getMessages = (type) => {
        switch (type) {
            case "unread":
                return {
                    successMessage: "Successfully marked notifications as undread!",
                    errorMessage: "Failed to mark notifications as unread"
                }
            case "read":
                return {
                    successMessage: "Successfully marked notifications as read!",
                    errorMessage: "Failed to mark notifications as read"
                }
            case "archive":
                return {
                    successMessage: "Successfully archived notifications!",
                    errorMessage: "Failed to archive notifications"
                }
            case "unarchive":
                return {
                    successMessage: "Successfully unarchived notifications!",
                    errorMessage: "Failed to unarchive notifications"
                }
            case "delete":
                return {
                    successMessage: "Successfully deleted notifications!",
                    errorMessage: "Failed to delete notification"
                }
            default: {}
        }
    }

    const readNotifications = (query) => {
        const queryParameters = {
            page: 1,
            limit: 25,
            status,
            ...Object.fromEntries(query)
        }
        dispatch(notificationActions.onReadNotificationHub(queryParameters));
    }

    const onBulkAction = async ({type}) => {
        const { successMessage, errorMessage } = getMessages(type);
        try {
            await dispatch(notificationActions.bulkActionNotifications(
                areNotficationsInAllPagesSelected ? [] : selectedNotifications,
                { bulk_action: type, status, notification_type, date_start_time, date_end_time }
            ));
            await readNotifications(query);
            setSelectedData([])
            close();
            Notification.alert(successMessage, STATUS_TYPES.SUCCESS);
        } catch (error) {
            console.log(error);
            Notification.alert(errorMessage, STATUS_TYPES.ERROR);
        }
    }

    if(status === "read"){
        allOptions.splice(1,1);
    }
    if(status === "unread"){
        allOptions.splice(0,1);
    }
    if(status === "snoozed"){
        allOptions.splice(0,2);
    }

    const bulkActionOptions = status !== "archived" ? allOptions : archivedOptions;
    
    return (
        <Modal open={open} onClose={close}>
            <Modal.Header>
                Bulk Action <div style={{ float: 'right', marginRight: '1em' }}>
                    <small>{numberSelectedNotifications} selected</small>
                    <Popup
                        trigger={<Icon size='small' style={{ marginLeft: '1em' }} floated='right' className='fas fa-circle-info' color='primary' />}
                        content={`Duplicates will be ignored`}
                        inverted
                        position='left center'
                        wide
                    />
                </div>
            </Modal.Header>
            <Modal.Content>
                <Form noValidate>
                    <Controller
                        name='type'
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Select
                                {...value ? { text: bulkActionOptions.find(action => action.value === value)?.content } : {}}
                                label='Bulk Action Type'
                                placeholder='Select bulk action type'
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                value={value}
                                options={bulkActionOptions}
                                required
                                error={error?.message}
                                width={8}
                                selectOnBlur={false}
                            />
                        )}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions
                hasSaveButton
                saveDisabled={!isValid || !isDirty}
                onSave={handleSubmit(onBulkAction)}
            />
        </Modal>
    )
}

export default NotificationBulkActionsModal;
