import routes from 'actions/routes';

import { generateCreateActionCreator, generateReadActionCreator, generateUpdateActionCreator, generateDeleteActionCreator} from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import { STATUS_TYPES, Notification } from 'dyl-components';

function changeActiveIntegration(email) {
    return {
        type: 'CHANGE_ACTIVE_INTEGRATION',
        email
    }
}

const onCreateFailure = () => {
    Notification.alert('Duplicate Integration.', STATUS_TYPES.ERROR, true);
};


export default {
    updatePrimary: generateUpdateActionCreator(routes.API_OAUTH_PRIMARY_INTEGRATION, ACTION_NAMES.OAUTH_UPDATE_PRIMARY_INTEGRATION),
    getPrimary: generateReadActionCreator(routes.API_OAUTH_PRIMARY_INTEGRATION, ACTION_NAMES.OAUTH_PRIMARY_INTEGRATION),
    deleteIntegration: generateDeleteActionCreator(routes.API_DELETE_OAUTH_INTEGRATION, ACTION_NAMES.DELETE_OAUTH_INTEGRATION),
    getIntegrations: generateReadActionCreator(routes.API_OAUTH_INTEGRATIONS, ACTION_NAMES.OAUTH_INTEGRATIONS),
    enable: generateCreateActionCreator(routes.API_OAUTH_INTEGRATION_ENABLE, ACTION_NAMES.OAUTH_INTEGRATION_ENABLE, () => {}, onCreateFailure),
    reactivate: generateUpdateActionCreator(routes.API_OAUTH_INTEGRATION_REACTIVATE, ACTION_NAMES.OAUTH_INTEGRATION_REACTIVATE),
    processOAuthCallback: generateCreateActionCreator(routes.API_OAUTH_INTEGRATION_CALLBACK, ACTION_NAMES.OAUTH_SETUP),
    changeActiveIntegration
};