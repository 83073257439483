const initialState = {
    count: 0,
    contactCount: 0,
    contactGroupsCount: 0,
    hotlistCount: 0,
    groups: [],
    subgroups: [],
    contacts: [],
    contactGroups: [],
    hotlistContacts: [],
    contactsInHotlist: [],
    groupName: "",
    parent: null,
    hotlist: null,
    isReadingGroups: false,
    isReadingSubgroups: false,
    isCreatingGroups: false,
    isUpdatingGroup: false,
    isReadingContacts: false,
    isReadingContactGroups: false,
    isDeletingGroup: false,
    isAddingContact: false,
    isMovingSubgroup: false,
    isReadingGroupOptions: false,
    isRemovingHotlist: false,
    contactBeingRemovedFromGroup: null
}

export default initialState;
