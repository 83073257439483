import React, { useEffect, useState } from "react";
import {
    Feed,
    Icon,
    Container,
    Grid,
    Divider,
    Header,
    Loader,
    Button,
    Popup,
} from "semantic-ui-react";
import { Notification, STATUS_TYPES, DateTimeUtils } from "dyl-components";
import "./index.scss";
import { StringUtils, FileUtils } from "utils";
import Avatar from "react-avatar";
import DOMPurify from "dompurify";
import { useDispatch, useSelector } from "react-redux";
import emailActions from "actions/email";
import useFirstRender from "utils/useFirstRender";

const getTimestampDifference = (ts, timezone = "America/New_York") => {
    DateTimeUtils.setTimezone(timezone);
    let timeStamp = DateTimeUtils.formatEpoch(
        ts,
        DateTimeUtils.DATETIME_FORMAT
    );
    let timeStamp2 = DateTimeUtils.getTime().moment.format(
        DateTimeUtils.DATETIME_FORMAT
    );
    let days = DateTimeUtils.getDifference(timeStamp2, timeStamp);
    let hours = DateTimeUtils.getDifference(timeStamp2, timeStamp, "hours");
    let minutes = DateTimeUtils.getDifference(timeStamp2, timeStamp, "minutes");

    if (days > 0) {
        return `${days} day${days > 1 ? "s" : ""} ago`;
    }
    if (hours > 0) {
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    }
    if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    }
    return `seconds ago`;
};

const CleanHTML = ({ children, className = "", collapsed }) =>
    collapsed ? (
        StringUtils.removeHTML(children)
    ) : (
        <span
            className={className}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(children) }}
        />
    );

const ThreadItem = ({
    from,
    to,
    cc,
    bcc,
    ts,
    body,
    avatarSrc,
    attachments,
    emailAddress,
    isCollapsed,
    isFirstExpanded,
    timezone,
    id,
    localCollapsedDisabled,
}) => {
    const isFirstRender = useFirstRender();
    const dispatch = useDispatch();
    const isPullingAttachment = useSelector(
        (state) => state.email.isPullingAttachment
    );
    const [localCollapsed, setLocalCollapsed] = useState(isPullingAttachment);
    const [emailAttachment, setEmailAttachment] = useState(null);
    let date = DateTimeUtils.changeFormat(
        DateTimeUtils.formatEpoch(ts, DateTimeUtils.DATE_FORMAT),
        "",
        "ddd[,] M[/]D[/]YYYY[,] "
    );
    let time = DateTimeUtils.formatEpoch(ts);

    useEffect(() => {
        if (isFirstRender && isFirstExpanded) {
            setLocalCollapsed(false);
        } else {
            setLocalCollapsed(isCollapsed);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCollapsed, isFirstExpanded]);

    useEffect(() => {
        if (emailAttachment) {
            downloadAttachment(
                {
                    attachment_id: emailAttachment.fileId,
                    message_id: emailAttachment.message_id,
                },
                emailAttachment.type
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailAttachment]);

    const downloadAttachment = async (queryParameters, type) => {
        try {
            //TODO: remove when logger is figured out further. Currently big files will blow up the response. This put in place to remove local storage befor viewing attachments.
            //located
            //src/logger/index.js
            localStorage.clear();
            ///////////////////////////////////
            //TODO: display of .mp4 and video files and files larger than 15mb buggy

            const { data } = await dispatch(
                emailActions.getAttachment(queryParameters)
            );
            const decodedData = await FileUtils.convertBase64(data);

            const typeFallback =
                type !== undefined || type !== null ? type : "applicaiton/zip";
            const url = await window.URL.createObjectURL(
                new Blob([decodedData], { type: typeFallback })
            );

            window.open(
                url,
                "_blank",
                "location=yes,height=770,width=770,scrollbars=yes,status=yes"
            );
            //reset for next attachment
            setEmailAttachment(null);

            ////////////////////////////////////
            //TODO: remove when logger is figured out further. Currently big files will blow up the response. This put in place to remove local storage befor viewing attachments.
            //located
            //src/logger/index.js
            localStorage.clear();
        } catch (e) {
            console.log("|+|error", e);
            Notification.alert(
                `Failed to download attachment.`,
                STATUS_TYPES.ERROR
            );
        }
    };

    const emailListToString = (list) => {
        let emailList = "";
        list.forEach((c, i) => {
            emailList += c?.email === emailAddress ? "me" : c.email;
            emailList += list.length - 1 !== i ? ",\xA0" : "";
        });
        return emailList;
    };

    const { name, email } = from || {};
    return (
        <Container className="EmailThread__Item">
            <div
                className="EmailThread__Item__avatar"
                style={{ marginBottom: 20 }}
            >
                <Avatar
                    style={{ marginRight: 8 }}
                    name={name || email || "unknown name"}
                    maxInitials={2}
                    src={avatarSrc}
                    size="3em"
                    round
                />
                <div
                    className="EmailThread__Item__adresses"
                    style={{ flex: 1 }}
                >
                    <div style={{ flex: 1, display: "flex" }}>
                        <div className="EmailThread__Item__adresses EmailThread__Item--long">
                            <span>
                                {from?.name || from?.email || "no from found"}
                            </span>
                            {to && localCollapsed && to.length !== 0 && (
                                <div className="EmailThread__Item__adresses">
                                    <span className="EmailThread__Item__email--to">
                                        to:&nbsp;
                                        {to.map((t, i) => {
                                            return (
                                                <span
                                                    className="EmailThread__Item__email--to"
                                                    style={{
                                                        display: "inline",
                                                    }}
                                                >
                                                    {t?.email === emailAddress
                                                        ? "me"
                                                        : t.email}
                                                    {to.length - 1 !== i
                                                        ? ","
                                                        : ""}
                                                    &nbsp;
                                                </span>
                                            );
                                        })}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div style={{ flex: 1 }}>
                            <Feed.Date className="EmailThread__Item--date">
                                {
                                    <Header
                                        id={
                                            to &&
                                            !localCollapsed &&
                                            to.length !== 0 &&
                                            "dateHeader"
                                        }
                                        as="h6"
                                    >
                                        <b>{date}</b> {time} (
                                        {getTimestampDifference(ts, timezone)})
                                    </Header>
                                }
                            </Feed.Date>
                        </div>
                    </div>
                    {to && !localCollapsed && to.length !== 0 && (
                        <Popup
                            trigger={
                                <div className="EmailThread__Item__adresses">
                                    <span className="EmailThread__Item__email--to">
                                        to:&nbsp;
                                        {to.map((t, i) => {
                                            return (
                                                <span
                                                    className="EmailThread__Item__email--to"
                                                    style={{
                                                        display: "inline",
                                                    }}
                                                >
                                                    {t?.email === emailAddress
                                                        ? "me"
                                                        : t.email}
                                                    {to.length - 1 !== i
                                                        ? ","
                                                        : ""}
                                                    &nbsp;
                                                </span>
                                            );
                                        })}
                                    </span>
                                </div>
                            }
                            content={emailListToString(to)}
                            position="left center"
                            inverted
                        />
                    )}
                    {cc && !localCollapsed && cc.length !== 0 && (
                        <Popup
                            trigger={
                                <div className="EmailThread__Item__adresses">
                                    <span className="EmailThread__Item__email--to">
                                        cc:&nbsp;
                                        {cc.map((c, i) => {
                                            return (
                                                <span
                                                    className="EmailThread__Item__email--to"
                                                    style={{
                                                        display: "inline",
                                                    }}
                                                >
                                                    {c?.email === emailAddress
                                                        ? "me"
                                                        : c.email}
                                                    {cc.length - 1 !== i
                                                        ? ","
                                                        : ""}
                                                    &nbsp;
                                                </span>
                                            );
                                        })}
                                    </span>
                                </div>
                            }
                            content={emailListToString(cc)}
                            position="left center"
                            inverted
                        />
                    )}
                    {bcc && !localCollapsed && bcc.length !== 0 && (
                        <Popup
                            trigger={
                                <div className="EmailThread__Item__adresses">
                                    <span className="EmailThread__Item__email--to">
                                        bcc:&nbsp;
                                        {bcc.map((bc, i) => {
                                            return (
                                                <span
                                                    className="EmailThread__Item__email--to"
                                                    style={{
                                                        display: "inline",
                                                    }}
                                                >
                                                    {bc?.email === emailAddress
                                                        ? "me"
                                                        : bc.email}
                                                    {bcc.length - 1 !== i
                                                        ? ","
                                                        : ""}
                                                    &nbsp;
                                                </span>
                                            );
                                        })}
                                    </span>
                                </div>
                            }
                            content={emailListToString(bcc)}
                            position="left center"
                            inverted
                        />
                    )}
                </div>
            </div>
            <Grid columns="1">
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Feed.Content>
                            <Feed.Extra
                                text
                                className={`EmailThread__Item__content--${
                                    localCollapsed ? "trimmed" : "expanded"
                                }`}
                            >
                                <CleanHTML
                                    className="EmailThread__Body"
                                    collapsed={localCollapsed}
                                >
                                    {body}
                                </CleanHTML>
                            </Feed.Extra>
                            <Feed.Meta>
                                <Feed.Like
                                    styles={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    {attachments && !localCollapsed && (
                                        <React.Fragment>
                                            {attachments &&
                                                attachments.map((file) => (
                                                    <Button
                                                        basic
                                                        disabled={
                                                            isPullingAttachment &&
                                                            emailAttachment?.fileId ===
                                                                Number(
                                                                    file.id
                                                                ) &&
                                                            emailAttachment?.message_id ===
                                                                id
                                                        }
                                                        key={file.id}
                                                        style={{
                                                            padding: "3px 12px",
                                                        }}
                                                        className="EmailThread__Item__contentfile"
                                                        onClick={() =>
                                                            setEmailAttachment({
                                                                fileId: Number(
                                                                    file.id
                                                                ),
                                                                message_id: id,
                                                                type: file.data_type,
                                                            })
                                                        }
                                                    >
                                                        <Icon name="paperclip" />{" "}
                                                        {file.filename}{" "}
                                                        {isPullingAttachment &&
                                                            emailAttachment?.fileId ===
                                                                Number(
                                                                    file.id
                                                                ) &&
                                                            emailAttachment?.message_id ===
                                                                id && (
                                                                <Loader
                                                                    active
                                                                    inline
                                                                    size="mini"
                                                                />
                                                            )}
                                                    </Button>
                                                ))}
                                        </React.Fragment>
                                    )}
                                    {attachments && localCollapsed && (
                                        <Button
                                            className="EmailThread__Item__contentfile"
                                            basic
                                            style={{ padding: "3px 12px" }}
                                        >
                                            <Icon name="paperclip" />{" "}
                                            {attachments.length}
                                        </Button>
                                    )}
                                    {localCollapsedDisabled ? (
                                        <Icon
                                            color="blue"
                                            size="large"
                                            name={"chevron circle up"}
                                            disabled
                                        />
                                    ) : (
                                        <Icon
                                            color="blue"
                                            size="large"
                                            onClick={() =>
                                                setLocalCollapsed(!localCollapsed)
                                            }
                                            name={
                                                localCollapsed
                                                    ? "chevron circle down"
                                                    : "chevron circle up"
                                            }
                                        />
                                    )}
                                </Feed.Like>
                            </Feed.Meta>
                        </Feed.Content>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

const ThreadList = ({
    threads = [],
    emailAddress,
    isCollapsed,
    toggleIsCollapsed,
    timezone,
    printable = false,
}) => {
    const [isThreadCollapsed, setIsThreadCollapsed] = useState(true);

    useEffect(() => {
        setIsThreadCollapsed(isCollapsed);
    }, [isCollapsed]);

    if (printable)
        return threads.map((email, idx) => {
            return (
                <React.Fragment>
                    {idx !== 0 ? (
                        <Grid.Row columns={1}>
                            <Divider className="EmailThread__Divider" />
                        </Grid.Row>
                    ) : null}
                    <ThreadItem
                        key={idx}
                        isCollapsed={false}
                        toggleIsCollapsed={() => {}}
                        {...email}
                        emailAddress={emailAddress}
                        timezone={timezone}
                    />
                </React.Fragment>
            );
        });

    if (threads.length === 1)
        return (
            <ThreadItem
                isCollapsed={false}
                toggleIsCollapsed={toggleIsCollapsed}
                {...threads[0]}
                emailAddress={emailAddress}
                timezone={timezone}
                localCollapsedDisabled
            />
        );

    if (threads.length === 2)
        return (
            <React.Fragment>
                <ThreadItem
                    key={1}
                    isCollapsed={isCollapsed}
                    toggleIsCollapsed={toggleIsCollapsed}
                    {...threads[0]}
                    emailAddress={emailAddress}
                    timezone={timezone}
                />
                <Grid.Row columns={1}>
                    <Divider className="EmailThread__Divider" />
                </Grid.Row>
                <ThreadItem
                    key={2}
                    isCollapsed={isCollapsed}
                    toggleIsCollapsed={toggleIsCollapsed}
                    {...threads[threads.length - 1]}
                    emailAddress={emailAddress}
                    timezone={timezone}
                    isFirstExpanded
                />
            </React.Fragment>
        );

    return threads.map((email, idx) => {
        if (idx === 0) {
            return (
                <>
                    <ThreadItem
                        key={idx}
                        isCollapsed={isCollapsed}
                        toggleIsCollapsed={toggleIsCollapsed}
                        {...email}
                        emailAddress={emailAddress}
                        timezone={timezone}
                    />
                    {isThreadCollapsed && (
                        <Grid.Row columns={1}>
                            <Divider className="EmailThread__Divider EmailThread__Divider--collapsed">
                                <p
                                    className="EmailThread__Divider--circle"
                                    onClick={() =>
                                        setIsThreadCollapsed(!isThreadCollapsed)
                                    }
                                >
                                    {threads.length - 2}
                                </p>
                            </Divider>
                        </Grid.Row>
                    )}
                </>
            );
        }

        if (idx === threads.length - 1) {
            return (
                <React.Fragment>
                    {!isThreadCollapsed && (
                        <Grid.Row columns={1}>
                            <Divider className="EmailThread__Divider" />
                        </Grid.Row>
                    )}
                    <ThreadItem
                        key={idx}
                        isCollapsed={isCollapsed}
                        toggleIsCollapsed={toggleIsCollapsed}
                        {...email}
                        emailAddress={emailAddress}
                        timezone={timezone}
                        isFirstExpanded
                    />
                </React.Fragment>
            );
        }

        return !isThreadCollapsed ? (
            <React.Fragment>
                <Grid.Row columns={1}>
                    <Divider className="EmailThread__Divider" />
                </Grid.Row>
                <ThreadItem
                    key={idx}
                    isCollapsed={isCollapsed}
                    toggleIsCollapsed={toggleIsCollapsed}
                    {...email}
                    emailAddress={emailAddress}
                    timezone={timezone}
                />
            </React.Fragment>
        ) : null
    });
};

export default ThreadList;
