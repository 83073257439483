import React from 'react';

import { ButtonLink, Icon, Table } from 'dyl-components';

import AddGroupItemSection from './AddGroupItemSection';
import GroupRow from './GroupRow';

const CustomFieldGroup = ({
    toggleGroup,
    isOpenGroup,
    group,
    onEditField,
    parent_group,
    refresh,
    groupWhereASubgroupIsToBeCreated,
    onOpenAddGroupForm,
    onCloseAddGroupForm,
    isAddGroupFormModified,
    onModify,
    isExpanded,
    onToggleField,

    onOpenAddFieldModal,

    onCloseEditGroupForm,
    openEditGroupForm,
    groupBeingEdited,
    isEditGroupFormModified,
    onModifyEditGroupForm
}) => {
    const commonGroupRowProperties = {
        groupBeingEdited,
        isEditGroupFormModified,
        isExpanded,
        isOpenGroup,
        onCloseEditGroupForm,
        onEditField,
        onModifyEditGroupForm,
        onToggleField,
        openEditGroupForm,
        refresh,
        toggleGroup
    }

    return (
        <GroupRow
            {...commonGroupRowProperties}
            group={group}
            parent_group={parent_group}
            otherSubrowContent={[
                ...(group.children || []).map(child_group => (
                    <GroupRow
                        {...commonGroupRowProperties}
                        group={child_group}
                        parent_group={group.id}
                        otherSubrowContent={[
                            <Table.CollapsibleRowContent indented nested>
                                <Table.Cell colspan={7}>
                                    <ButtonLink disabled={child_group.deleted} onClick={() => { onOpenAddFieldModal(child_group.id); }} noPadding><Icon name='plus circle' /> New Custom Field</ButtonLink>
                                </Table.Cell>
                            </Table.CollapsibleRowContent>
                        ]}
                        isSubgroup
                        isSubrow
                        nested
                        isParentArchived={group.deleted}
                    />
                )),
                <AddGroupItemSection
                    groupWhereASubgroupIsToBeCreated={groupWhereASubgroupIsToBeCreated}
                    group_id={group.id}
                    isAddGroupFormModified={isAddGroupFormModified}
                    onCloseAddGroupForm={onCloseAddGroupForm}
                    onModify={onModify}
                    onOpenAddFieldModal={onOpenAddFieldModal}
                    onOpenAddGroupForm={onOpenAddGroupForm}
                    refresh={refresh}
                    disabled={group.deleted}
                />
            ]}
        />
    );
}

export default CustomFieldGroup;
