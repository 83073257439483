import React, { useCallback, useEffect, useState } from 'react';
import { Modal, yup, generateResolver, VALIDATORS, Notification, STATUS_TYPES } from 'dyl-components';

import PipelineForm from './PipelineForm';
import FullScreenModalPopUp from '../../shared/FullScreenModalPopUp';
import { useFieldArray, useForm } from 'react-hook-form';
import { ValidationUtils } from 'utils';

import './PipelineModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import pipelineActions from 'actions/pipeline';

const PipelineModal = ({
    open,
    onClose,

    name,
    description,
    stages,

    pipeline_id,
    stage,
    refresh,
    isDuplicated,
}) => {
    const isEditing = pipeline_id !== undefined;
    const dispatch = useDispatch();

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
    const [hasDeleted, setHasDeleted] = useState(false);


    const { sequences } = useSelector((state) => ({
        sequences: state.sequences.sequencesShort || []
    }));

    const isExistingSequence = sequence_id => {
        return sequences.find(sequence => sequence.id === sequence_id);
    }

    const { formState: { isValid, isDirty }, control, handleSubmit, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: name || '',
            description: description || '',
            stages: stages?.map((stage) => ({ 
                ...stage, 
                toBeDeleted: false,
                sequence_id: isExistingSequence(stage.sequence_id) ? stage.sequence_id : null
            })) || [
                {
                    name: '',
                    description: '',
                    days: '',
                    toBeDeleted: false,
                    sequence_id: null
                }
            ]
        },
        resolver: generateResolver({
            name: VALIDATORS.SIMPLE_INPUT().required('This field is required').noemoji().minlength(2).maxlength(32).test("no_duplicated_name", "This name already exists.", (name) => {
                const result = isDuplicated(name);
                if (result) {
                    return result.id === pipeline_id;
                }
                return true;
            }),
            stages: yup.array().of(yup.object().shape({
                toBeDeleted: yup.boolean().required(),
                name: yup.string().when('toBeDeleted', {
                    is: false,
                    then: () => {
                        return VALIDATORS.SIMPLE_INPUT().required('This field is required')
                    },
                }),
                days: yup.string().when('toBeDeleted', {
                    is: false,
                    then: () => {
                        return yup.number().typeError('Must be a number').positive('Minimum is 1').integer('Must be an integer').required('This field is required')
                    },
                }),
            })).test('no_repeating_stage', "Stage already in the list", function (array) {
                return ValidationUtils.checkForDuplicateItemsInTheList.call(this, array, 'Stage', 'name')
            }),
        })
    });

    const { fields, move, append, update } = useFieldArray({
        control,
        name: 'stages',
        keyName: 'itemId'
    });

    const onDragEnd = useCallback((result) => {
        if (result.destination) {
            move(result.source.index, result.destination.index);
        }
    }, [move]);

    const onAddStage = () => {
        append({ name: '', description: '', days: '', toBeDeleted: false });
    }

    const onUpdateStage = (index, newInfo) => {
        update(index, newInfo);
    }

    const onSave = async (data) => {
        const payload = {
            account_stage: stage,
            name: data.name,
            description: data.description,
            stages: data?.stages.filter(stage => stage.toBeDeleted !== true).map(stage => ({
                ...stage, sequence_id: stage.sequence_id || 0
            }))
        }

        if (!isEditing) {
            try {
                await dispatch(pipelineActions.createPipelineCategory([payload]));
                Notification.alert('Successfully created pipeline category!', STATUS_TYPES.SUCCESS);
                onClose();
                if (refresh) {
                    refresh();
                }
                reset({
                    name: name || '',
                    description: description || '',
                    stages: stages?.map((stage) => ({ ...stage, toBeDeleted: false })) || [
                        {
                            name: '',
                            description: '',
                            days: '',
                            toBeDeleted: false,
                            sequence_id: null
                        }
                    ]
                })
            } catch (e) {
                console.log(e);
                Notification.alert('Failed to create pipeline category', STATUS_TYPES.ERROR);
            }
        } else {
            try {
                await dispatch(pipelineActions.updatePipelineCategory(pipeline_id, payload));
                Notification.alert('Successfully updated pipeline category!', STATUS_TYPES.SUCCESS);
                onClose();
                if (refresh) {
                    refresh();
                }
                reset({
                    name: name || '',
                    description: description || '',
                    stages: stages?.map((stage) => ({ ...stage, toBeDeleted: false })) || [
                        {
                            name: '',
                            description: '',
                            days: '',
                            toBeDeleted: false,
                            sequence_id: null
                        }
                    ]
                })
            } catch (e) {
                console.log(e);
                Notification.alert('Failed to update pipeline category', STATUS_TYPES.ERROR);
            }
        }
    }

    const { isCreatingCategory, categoryBeingUpdated } = useSelector(state => state.pipeline);

    const isSaving = isCreatingCategory || categoryBeingUpdated;

    const confirmationOnclose = () => {
        onClose();
        setIsConfirmationOpen(false);
        reset({
            name: name || '',
            description: description || '',
            stages: stages?.map((stage) => ({ 
                ...stage, 
                toBeDeleted: false,
                sequence_id: isExistingSequence(stage.sequence_id) ? stage.sequence_id : null
            })) || [
                {
                    name: '',
                    description: '',
                    days: '',
                    toBeDeleted: false,
                }
            ]
        })
    }

    useEffect(() => {
        let hasDeleted = false;
        fields.forEach(item => { if (item.toBeDeleted && item.toBeDeleted === true ){
                    hasDeleted = true;
        }})
        if (hasDeleted){
            setHasDeleted(true)
        }
        else {
            setHasDeleted(false)
        }
    }, [fields] )

    return (
        <>
            <Modal open={open} onClose={() => { isDirty ? setIsConfirmationOpen(true) : confirmationOnclose() }} noValidate>
                <Modal.Header>
                    {!isEditing ? `Add Pipeline` : `Edit Pipeline`}
                </Modal.Header>
                <Modal.Content scrolling>
                    <PipelineForm
                        control={control}
                        fields={fields}
                        onAddStage={onAddStage}
                        onDragEnd={onDragEnd}
                        onUpdate={onUpdateStage}
                        isSaving={isSaving}
                    />
                </Modal.Content>
                <Modal.Actions
                    hasSaveButton
                    onSave={ hasDeleted ? () => setIsSaveConfirmationOpen(true) : handleSubmit(onSave)}
                    saveDisabled={!isValid || !isDirty}
                    saveOptions={{ loading: isSaving }}
                />
                <FullScreenModalPopUp
                    header={'Are you sure?'} 
                    subheader={'Stage will be removed'} 
                    isOpen={isSaveConfirmationOpen}
                    onConfirm={handleSubmit(onSave)}
                    onFormClose={() => setIsSaveConfirmationOpen(false)}
                    closeIcon={false}
                />
            </Modal>

            <FullScreenModalPopUp
                header={'Changes not saved'} 
                subheader={'Are you sure you want to exit?'} 
                isOpen={isConfirmationOpen}
                onConfirm={confirmationOnclose}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </>
    )
}

export default PipelineModal;
