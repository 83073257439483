import { createContext, useEffect, useState } from "react";

export const SidePanelContext = createContext();

const SidePanelProvider = ({ children }) => {
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
    const onOpenSidePanel = () => {
        setIsSidePanelOpen(true);
    };
    const onCloseSidePanel = () => {
        setIsSidePanelOpen(false);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            window.scrollTo(0, 100);
            window.scrollTo(0, 0);
        }, 100)
        return () => {
            clearTimeout(timeout);
        }
    }, [isSidePanelOpen]);

    return (
        <SidePanelContext.Provider value={{ isSidePanelOpen, onOpenSidePanel, onCloseSidePanel }}>
            {children}
        </SidePanelContext.Provider>
    );
};

export default SidePanelProvider;
