import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';

import SocialMediaForm from 'shared/forms/SocialMediaForm';

import { StringUtils } from 'utils';

const SocialNetwork = ({
    icon = 'facebook' | 'linkedin' | 'twitter',
    links,

    onChangeSocialMediaType,
    onClose,
    isActive,
    onOpen,

    activeSocial
}) => {
    const renderedIcon = (
        <Icon
            {...(links.length ? {
                rel: "noopener noreferrer",
                target: "_blank",
                href: StringUtils.formatLink(links[0].url)
            } : {
                onClick: () => { onOpen(icon); }
            })}

            className={`SocialMedia__icon fab fa-${icon}`}
            color={links.length > 0 ? 'blue' : 'grey'}
            link
        />
    );

    const { pathname } = useLocation();
    const record_type = pathname.split('/')[1];

    const selectedSocials = useSelector(state => {
        return state[record_type].social_media?.map(social => social.type);
    })

    if (activeSocial === '' && links.length === 0) {
        return (
            <Popup
                inverted trigger={renderedIcon}
                content='Add a social media URL to connect to'
                wide
                position='right center'
            />
        )
    }

    if (activeSocial) {
        return (
            <Popup
                style={{ padding: 0 }}
                trigger={renderedIcon}
                content={(
                    <div style={{ paddingLeft: "1em", paddingRight: "1em", paddingTop: "0.833em" }}>
                        <SocialMediaForm
                            type={activeSocial}
                            onChangeSocialMediaType={onChangeSocialMediaType}
                            onClose={onClose}
                            icon={icon}
                            selectedSocials={selectedSocials}
                        />
                    </div>
                )}
                open={isActive}
                onClose={onClose}
                on='click'
                onOpen={() => { onOpen(icon); }}
                position='bottom center'
                size='tiny'
            />
        )
    }

    return renderedIcon;
}

export default SocialNetwork;
