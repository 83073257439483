import routes from 'actions/routes';
import {
    generateCreateActionCreator,
    generateDeleteActionCreator,
    generateReadActionCreator,
    generateReadOneActionCreator,
    generateUpdateActionCreator
} from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

export default {
    readOne: generateReadOneActionCreator(routes.API_MASTER_ACCOUNT, ACTION_NAMES.MASTER_ACCOUNT),
    create: generateCreateActionCreator(routes.API_MASTER_ACCOUNT, ACTION_NAMES.MASTER_ACCOUNT),
    update: generateUpdateActionCreator(routes.API_MASTER_ACCOUNT, ACTION_NAMES.MASTER_ACCOUNT),
    delete: generateDeleteActionCreator(routes.API_MASTER_ACCOUNT, ACTION_NAMES.MASTER_ACCOUNT),

    readAccounts: generateReadOneActionCreator(routes.API_MASTER_ACCOUNT, ACTION_NAMES.MASTER_ACCOUNT_ACCOUNTS, 'accounts'),

    readAccountsForPinning: generateReadOneActionCreator(routes.API_MASTER_ACCOUNT, ACTION_NAMES.MASTER_ACCOUNT_ACCOUNT_PIN_OPTIONS, 'accounts'),
    linkAccounts: generateUpdateActionCreator(routes.API_MASTER_ACCOUNT, ACTION_NAMES.MASTER_ACCOUNT_ACCOUNTS, () => { }, () => { }, 'accounts'),
    unlinkAccount: generateUpdateActionCreator(routes.API_MASTER_ACCOUNT, ACTION_NAMES.MASTER_ACCOUNT_ACCOUNT, () => { }, () => { }, 'account'),

    search: generateReadActionCreator(`${routes.API_MASTER_ACCOUNT}/search`, ACTION_NAMES.MASTER_ACCOUNTS_SEARCH),
}
