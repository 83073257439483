import ACTION_NAMES from "actions/ACTION_NAMES";
import { generateCreateActionCreator, generateDeleteActionCreator, generateReadActionCreator, generateReadOneActionCreator, generateUpdateActionCreator } from "actions/crudActionCreators";
import routes from "actions/routes";

export default {
    isUnique: generateReadActionCreator(`${routes.API_SEQUENCE}/is_unique`, ACTION_NAMES.IS_UNIQUE_SEQUENCE),
    addToSequence: generateCreateActionCreator(routes.API_SEQUENCE, ACTION_NAMES.ADD_TO_SEQUENCE, () => { }, () => { }, 'contact'),

    delete: generateDeleteActionCreator(routes.API_SEQUENCE, ACTION_NAMES.SEQUENCE),
    update: generateUpdateActionCreator(routes.API_SEQUENCE, ACTION_NAMES.SEQUENCE),
    readOne: generateReadOneActionCreator(routes.API_SEQUENCE, ACTION_NAMES.SEQUENCE),

    create: generateCreateActionCreator(routes.API_SEQUENCE, ACTION_NAMES.SEQUENCE),
    read: generateReadActionCreator(routes.API_SEQUENCE, ACTION_NAMES.SEQUENCE),
    readShort: generateReadActionCreator(routes.API_SEQUENCE_SHORT, ACTION_NAMES.SEQUENCE_SHORT),
    readUsers: generateReadActionCreator(`${routes.API_SEQUENCE}/users`, ACTION_NAMES.SEQUENCE_USERS)
}
