import React, { useState } from 'react';
import { Form, Dropdown, Icon } from 'semantic-ui-react';
import { DividingHeader, RichTextEditor } from 'dyl-components';
import './index.scss';
import { Controller } from 'react-hook-form';

const SIGNATURE_OPTIONS = [
    { key: 1, value: 1, text: 'Use company signature' },
    { key: 0, value: 0, text: 'Use no signature' },
];

const MOCK_SIGNATURE_TEMPLATES = [
    {
        key: 'Default Template 1',
        value: `
            <div>
                <p><strong>[Type in your name]</strong></p>
                <p>[Type in your position] at [Type in your company]</p>
                <p>
                    <span style="color: blue;">e:</span> [Type in email] |
                    <span style="color: blue;">p:</span> [Type in phone number] |
                    <span style="color: blue;">f:</span> [Type in fax number]
                </p>
                <p>
                    <span style="color: blue;">a:</span> [Type in address]
                </p>
                <p>
                    <span style="color: blue;">w:</span> [Type in website]
                </p>
            </div>
        `,
        text: 'DYL Template 1'
    },
    {
        key: 'Default Template 2',
        value: `
            <div>
                <p><strong>[Type in your Company Name]</strong></p>
                <p>
                    <span style="color: blue;">e:</span> [Type in email] |
                    <span style="color: blue;">p:</span> [Type in phone number] |
                    <span style="color: blue;">f:</span> [Type in fax number]
                </p>
                <p>
                    <span style="color: blue;">a:</span> [Type in address]
                </p>
                <p>
                    <span style="color: blue;">w:</span> [Type in website]
                </p>
            </div>
        `,
        text: 'DYL Template 2'
    }
]

const Signature = React.forwardRef((({ control, signature_body_copy }, editorRef = React.createRef()) => {
    const [template, setTemplate] = useState(null);
    const onSelectTemplate = (_, { value }) => {
        setTemplate(value);
    }
    return (
        <div style={{ marginBottom: 40 }} className='Signature'>
            <DividingHeader content='Default Signature' compact noline />
            <Controller
                name='signature_body'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field
                        width={12}
                        control={RichTextEditor}
                        label={<b>Company Signature</b>}
                        editorRef={editorRef}
                        name={name}
                        onChange={(_, { name, value }) => {
                            onChange({ target: { name, value: value === '<p><br></p>' ? '' : value } });
                        }}
                        value={value}
                        toolbarClassName={"signature_toolbar"}
                        style={{ minHeight: 250 }}
                        otherControls={[
                            <Dropdown
                                name='template'
                                value={template}
                                options={MOCK_SIGNATURE_TEMPLATES}
                                selectOnBlur={false}
                                clearable
                                onChange={(_, { value }) => {
                                    onSelectTemplate(null, { value });
                                    onChange({ target: { name, value: value === '' ? signature_body_copy : value } });
                                }}
                                placeholder="Insert"
                                basic
                                className='Signature__templateSelector ql-custom-dropdown'
                                icon={<Icon name={template ? 'times' : 'sort'} />} />
                        ]} />
                )} />
            <Form.Group>
                <Controller
                    name='new_email'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            width={4}
                            placeholder='Select signature'
                            options={SIGNATURE_OPTIONS}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }); }}
                            name={name}
                            value={value}
                            label={<b>For New Emails</b>} />
                    )} />
                <Controller
                    name='reply_forward_email'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            width={4}
                            placeholder='Select signature'
                            options={SIGNATURE_OPTIONS}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }); }}
                            name={name}
                            value={value}
                            label={<b>For Email Reply/Forward</b>} 
                        />
                    )} />
            </Form.Group>
        </div>
    );
}));

export default Signature;
