import React from 'react';
import { generateResolver, Notification, STATUS_TYPES, VALIDATORS, yup } from 'dyl-components';
import { Form, Header, Icon } from 'semantic-ui-react';

import StageFields from './StageFields';
import pipelineActions from 'actions/pipeline';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

const StagePopup = ({ 
    onClose, 
    stage_id, 
    pipeline_id,
    refresh,

    name,
    description,
    days,
    sequence_id,
    isStageDuplicate = () => {},
}) => {
    const isEditing = stage_id !== undefined;
    const dispatch = useDispatch();

    const { sequences } = useSelector((state) => ({
        sequences: state.sequences.sequencesShort || []
    }));

    const isExistingSequence = sequence_id => {
        return sequences.find(sequence => sequence.id === sequence_id);
    }

    const { formState: { isValid, isDirty }, control, handleSubmit, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            stages: [
                {
                    name: name || '',
                    description: description || '',
                    days: days || '',
                    sequence_id: isExistingSequence(sequence_id) ? sequence_id : null
                }
            ]
        },
        resolver: generateResolver({
            stages: yup.array().of(yup.object().shape({
                name: VALIDATORS.SIMPLE_INPUT().required('This field is required').maxlength(30).minlength(2).noemoji().test("no_duplicated_stage_name", "This stage name already exists.", (name) => {
                    const result = isStageDuplicate(name);
                    if (result) {
                        return result.id === stage_id;
                    }
                    return true;
                }),
                days: yup.number().typeError('Must be a number').positive('Minimum is 1').integer('Must be an integer').required('This field is required')
            }))
        })
    });

    const onSave = async ({ stages }) => {
        const payload = {
            name: stages[0].name,
            description: stages[0].description,
            days: stages[0].days,
            sequence_id: stages[0].sequence_id || 0
        }
        if (!isEditing) {
            try {
                await dispatch(pipelineActions.createStage([payload], null, pipeline_id));
                Notification.alert('Successfully created stage!', STATUS_TYPES.SUCCESS);
                onClose();
                if (refresh) {
                    refresh();
                }
            } catch (e) {
                console.log(e);
                Notification.alert('Failed to create stage', STATUS_TYPES.ERROR);
            }
        } else {
            try {
                await dispatch(pipelineActions.updateStage(stage_id, payload));
                Notification.alert('Successfully updated stage!', STATUS_TYPES.SUCCESS);
                onClose();
                if (refresh) {
                    refresh();
                }
            } catch (e) {
                console.log(e);
                Notification.alert('Failed to update stage', STATUS_TYPES.ERROR);
            }
        }
    }

    const { isCreatingStage, stageBeingUpdated } = useSelector(state => state.pipeline);

    const isSaving = isCreatingStage || stageBeingUpdated;

    return (
        <div className='StagePopup__container'>
            <Form loading={isSaving} noValidate className='StagePopup__form'>
                <Header as={"h3"}>{isEditing ? 'Edit' : 'Add'} Stage</Header>
                <Form.Group>
                    <StageFields control={control} />
                </Form.Group>
                <div className='StagePopup__btnContainer'>
                    <Form.Button loading={isCreatingStage || isSaving} disabled={!isValid || !isDirty || isSaving} onClick={handleSubmit(onSave)} primary>Save</Form.Button>
                </div>
            </Form>
            <Icon
                className='fa-solid fa-xmark StagePopup__closeIcon'
                color='grey'
                size='large'
                link
                onClick={() => {
                    onClose();
                    reset({
                        stages: [
                            {
                                name: name || '',
                                description: description || '',
                                days: days || '',
                                sequence_id: isExistingSequence(sequence_id) ? sequence_id : null
                            }
                        ]
                    })
                }}
            />
        </div>
    )
}

export default StagePopup;
