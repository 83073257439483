import ACTION_NAMES from 'actions/ACTION_NAMES';
import { generateReadActionCreator, generateCreateActionCreator, generateUpdateActionCreator, generateDeleteActionCreator, generateReadOneActionCreator } from 'actions/crudActionCreators';
import routes from 'actions/routes';

const readImports = generateReadActionCreator(routes.API_IMPORT, ACTION_NAMES.IMPORT);
const updateImport = generateUpdateActionCreator(routes.API_IMPORT, ACTION_NAMES.IMPORT);
const deleteImport = generateDeleteActionCreator(routes.API_IMPORT, ACTION_NAMES.IMPORT);
const readImport = generateReadOneActionCreator(routes.API_IMPORT, ACTION_NAMES.IMPORT);

export default {
    readImports,
    addImport: generateCreateActionCreator(routes.API_IMPORT, ACTION_NAMES.IMPORT),
    updateImport,
    deleteImport,
    readImport
}
