import React from 'react';
import { Form } from 'semantic-ui-react';

const RefreshTextCampaignForm = ({
    replied,
    onChange
}) => (
        <Form>
            <Form.Group>
                <Form.Field>
                    <label>Filters</label>
                </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Field>
                    <label>Replied</label>
                    <Form.Group inline>
                        <Form.Radio checked={replied === 'yes'} label='Yes' value='yes' name={'replied'} onChange={onChange} />
                        <Form.Radio checked={replied === 'no'} label='No' value='no' name={'replied'} onChange={onChange} />
                    </Form.Group>
                </Form.Field>
            </Form.Group>
        </Form>
    );

export default RefreshTextCampaignForm;
