import React from 'react';
import { Grid, Pagination } from 'semantic-ui-react';
import { TableWithTools } from 'dyl-components';
import FaxHistoryTable from './subcomponents/FaxHistoryTable';
import FaxHistoryToolbar from './subcomponents/FaxHistoryToolbar';
import { MOCK_FAXES } from '__mocks__/mockData';
import FaxHistoryFilter from './subcomponents/FaxHistoryFilter';
import HeaderContainer from 'shared/HeaderContainer';

const FaxHistory = ({ faxes }) => (
    <Grid>
        <HeaderContainer 
            details={{
                type: 'reports',
                path: '/reports/fax-log',
                title: 'Fax History'
            }}
        />
        <Grid.Row>
            <Grid.Column>
                <FaxHistoryFilter />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <TableWithTools
                    pagination={
                        <Pagination
                            boundaryRange={0}
                            defaultActivePage={1}
                            ellipsisItem={null}
                            firstItem={null}
                            lastItem={null}
                            siblingRange={2}
                            totalPages={Math.ceil(faxes.length / 10)}
                        />
                    }
                    toolbar={<FaxHistoryToolbar numberOfReceived={faxes.length} />}
                    table={<FaxHistoryTable faxes={faxes} />}
                />
            </Grid.Column>
        </Grid.Row>
    </Grid>
)

class FaxHistoryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            faxes: []
        }
    }

    componentDidMount() {
        this.setState({
            faxes: MOCK_FAXES
        })
    }

    render() {
        return (
            <FaxHistory
                {...this.state}
            />
        )
    }
}

export default FaxHistoryContainer;
