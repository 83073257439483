import React from 'react';
import { Table, Form } from 'semantic-ui-react';
import { STATUS_TYPES, Notification, ButtonLink } from 'dyl-components';
import { Link } from 'react-router-dom';
import './Header.scss';

const TableSingleLine = ({
    isCustomLeadCallerIdOn,
    onCustomLeadCallerIdToggle,
    CustomLeadCallerIdSection,
    onAllowDuplicateLeadsToggle
}) => (
        <Table singleLine>
            <Table.Body>
                <Table.Row>
                    <Table.Cell><strong>Lead Email:</strong> doejxx01@dialyourleads.com</Table.Cell>
                    <Table.Cell textAlign='right'>
                        For addtional emails you can go to <Link to='/settings/sources'>Manage Lead Sources</Link>.
            </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Form>
                            <Form.Group inline className='LeadRoutingHeader' >
                                <label>Custom Lead Caller ID: </label>
                                <Form.Checkbox toggle
                                    onChange={onCustomLeadCallerIdToggle} />
                                {CustomLeadCallerIdSection}
                            </Form.Group>
                        </Form>
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Form>
                            <Form.Group inline className='LeadRoutingHeader' >
                                <label>Allow Duplicate Leads:</label>
                                <Form.Checkbox toggle
                                    onChange={onAllowDuplicateLeadsToggle} />
                            </Form.Group>
                        </Form>
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    )

const CustomLeadCallerIdViewInfo = ({ onEditClick }) => {
    return (
        <React.Fragment>
           8883101423
            <ButtonLink onClick={onEditClick}>Edit</ButtonLink>
        </React.Fragment>
    )
}

const CustomLeadCallerIdViewEdit = ({ onCancelClick, onUpdateClick }) => {
    return (
        <React.Fragment>
            <Form.Input value='8883101423' />

            <ButtonLink onClick={onUpdateClick}>Update</ButtonLink>
            |                
            <ButtonLink onClick={onCancelClick}>Cancel</ButtonLink>

        </React.Fragment>
    )
}

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCustomLeadCallerIdOn: false,
            isAllowDuplicateLeadsOn: false,
            customLeadCallerIdViewMode: 'info'
        }
    }

    onCustomLeadCallerIdToggle = (e, { checked }) => {
        this.setState({
            isCustomLeadCallerIdOn: checked
        });
    }

    onAllowDuplicateLeadsToggle = (e, { checked }) => {
        this.setState({
            isAllowDuplicateLeadsOn: checked
        });

        if (checked) {
            Notification.alert('Duplicate Leads Set', STATUS_TYPES.SUCCESS, true);
        } else {
            Notification.alert('Duplicate Leads Unset', STATUS_TYPES.SUCCESS, true);
        }
    }

    formCustomLeadCallerIdSection = () => {
        const { isCustomLeadCallerIdOn, customLeadCallerIdViewMode } = this.state;

        if (isCustomLeadCallerIdOn) {
            if (customLeadCallerIdViewMode === 'info') {
                return <CustomLeadCallerIdViewInfo
                    onEditClick={this.onEditClick} />
            } else if (customLeadCallerIdViewMode === 'edit') {
                return <CustomLeadCallerIdViewEdit
                    onCancelClick={this.onCancelClick}
                    onUpdateClick={this.onUpdateClick} />
            }
        } else {
            return <React.Fragment />
        }
    }

    onEditClick = (e) => {
        e.preventDefault();

        this.setState({
            customLeadCallerIdViewMode: 'edit'
        });
    }

    onCancelClick = (e) => {
        e.preventDefault();

        this.setState({
            customLeadCallerIdViewMode: 'info'
        });
    }

    onUpdateClick = (e) => {
        e.preventDefault();

        this.setState({
            customLeadCallerIdViewMode: 'info'
        });

        Notification.alert('Custom Lead Caller ID Number Set', STATUS_TYPES.SUCCESS, true);
    }

    render() {
        const { isCustomLeadCallerIdOn } = this.state;
        const CustomLeadCallerIdSection = this.formCustomLeadCallerIdSection();

        return (
            <React.Fragment>
                <TableSingleLine
                    isCustomLeadCallerIdOn={isCustomLeadCallerIdOn}
                    onCustomLeadCallerIdToggle={this.onCustomLeadCallerIdToggle}
                    CustomLeadCallerIdSection={CustomLeadCallerIdSection}
                    onAllowDuplicateLeadsToggle={this.onAllowDuplicateLeadsToggle} />
            </React.Fragment>
        )
    }
}

export default Header;
