import React, { useEffect } from 'react';
import { Modal } from 'dyl-components';
import { Grid, Loader } from 'semantic-ui-react';
import FirstEmail from 'shared/EmailTab/FirstEmail';

import { useDispatch, useSelector } from 'react-redux';
import sendEmailActions from 'actions/send_email';

import './index.scss';

const SendEmailModal = ({
    open,

    onClose,
    contact_id
}) => {
    const close = () => {
        onClose();
    }

    const sendEmailReducer = useSelector(state => state.send_email);
    const isSendingEmail = useSelector(state => state.email.isSendingEmail);

    const { isReadingContact, isReadingContactLocations } = sendEmailReducer;

    useEffect(()=>{
        if(isSendingEmail){
            onClose();
        }
    }, [isSendingEmail, onClose])

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(sendEmailActions.readContact(contact_id));
        dispatch(sendEmailActions.getLocations(contact_id));
    }, [contact_id, dispatch]);

    return (
        <Modal open={open} onClose={close}>
            <Modal.Header>Send Email</Modal.Header>
            <Modal.Content>
                <Grid className='EmailTab'>
                    <Grid.Column className="EmailTab__EmailForm">
                        {
                            isReadingContact || isReadingContactLocations ?
                                <Loader active /> :
                                <FirstEmail
                                    toggleSidePanel={() => { }}
                                    isSidePanelOpen={false}
                                    readEmails={() => { }}
                                    contact_id={contact_id}
                                    hideToggle
                                    hideTitleBorder
                                    isModal
                                />
                        }
                    </Grid.Column>
                </Grid>
            </Modal.Content>
        </Modal>
    );
};

export default SendEmailModal;
