const initialState = {
    events: [],
    isReadingEvents: false,
    isAddingEvents: false,
    count: 0,
    events_tab_events: [],
    events_tab_count: 0,

    event_labels: [],
    event_labels_count: 0,
    isReadingEventLabels: false,
    isAddingEventLabels: false
}

export default initialState;
