import React from 'react';
import { Table, ClearableCheckboxDropdown } from 'dyl-components';
import { Table as SemanticTable } from "semantic-ui-react";
import { useSearchParams } from 'react-router-dom';

const CampaignsTableHeader = ({ onFilter }) => {
    const [params] = useSearchParams();
    return (
        <Table.CollapsibleRow isHeader>
            <SemanticTable.HeaderCell className="CampaignsTable__MediumFixedWidth" width={2}>
                Added
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='CampaignsTable__MediumFixedWidth' width={2}>
                Start/End Date
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className="CampaignsTable__MediumFixedWidth" width={2}>Name</SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className="CampaignsTable__MediumFixedWidth" width={2}>Campaign Conversion</SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className="CampaignsTable__MediumFixedWidth" width={2}>
                <ClearableCheckboxDropdown
                    text="Campaign Status"
                    name="status"
                    options={[
                        { key: "none", value: "none", text: "None" },
                        { key: "active", value: "active", text: "Active" },
                        { key: "expired", value: "expired", text: "Expired" },
                        { key: "deleted", value: "deleted", text: "Deleted" },
                    ]}
                    onChange={(_, { value }) => {
                        onFilter(_, { name: "status", value });
                    }}
                    value={params.get("status")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className="CampaignsTable__MediumFixedWidth" width={2}>
                <ClearableCheckboxDropdown
                    text="Shared"
                    name="shared"
                    options={[
                        { key: "personal", value: "personal", text: "Personal" },
                        { key: "company", value: "company", text: "Company" }
                    ]}
                    onChange={(_, { value }) => {
                        onFilter(_, { name: "shared", value });
                    }}
                    value={params.get("shared")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className="CampaignsTable__SmallFixedWidth" width={1}>
                Actions
            </SemanticTable.HeaderCell>
        </Table.CollapsibleRow>
    );
}

export default CampaignsTableHeader;
