import React from 'react';
import { Table, EllipsisDropdown, ControlledPopup, STATUS_TYPES, Notification, TableLoader } from 'dyl-components';
import { Dropdown, Icon, Header, Select, Form } from 'semantic-ui-react';

import { DataRecordsTable } from './DataRecordsTable';
import { connect, useDispatch } from 'react-redux';
import leadProviderActions from 'actions/lead_provider';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { DATA_SOURCE_OPTIONS } from 'shared/constants/DATA_SOURCE_OPTIONS';
import { MASTER_SOURCE_OPTIONS } from 'shared/constants/MASTER_SOURCE_OPTIONS';
import { useNavigate, useSearchParams } from 'react-router-dom';

const mapStateToProps = state => ({
    dataProviders: state.lead_provider.providers,
    secondary_sources: state.lead_provider.secondary_sources.map((source) => ({
        key: source.id,
        value: source.id,
        text: source.name
    })),
    isReadingSecondarySources: state.lead_provider.isReadingSecondarySources,

    isReadingSecondarySourceOptions: state.lead_provider.isReadingSecondarySourceOptions,
    secondary_source_options: state.lead_provider.secondary_source_options.map((source) => ({
        key: source.id,
        value: source.id,
        text: source.name
    })),
});


export const DataProvidersRow = connect(mapStateToProps)(({
    dataProvider,
    isReadingSecondarySources,
    secondary_sources = [],
    dataProviders,
    isReadingSecondarySourceOptions,
    secondary_source_options,
    isEditing,   
    onEdit,
    onCancelEdit,
    onConfirmEdit,
    isUpdating,
    isDeleting,
    limit,
    control,
    setValue,
    canConfirmEdit,
    current_master_source_value
}) => {
    const dispatch = useDispatch();

    const [isReadingDataRecords, setIsReadingDataRecords] = useState(false);
    const [data_records, setDataRecords] = useState([]);

    const readSecondarySources = (master_source) => {
        dispatch(leadProviderActions.readSecondarySourceOptions({ master_source: master_source ? [master_source] : null }));
    }



    const [params] = useSearchParams();
    const navigate = useNavigate();

    const refresh = () => {
        dispatch(leadProviderActions.readProviders({
            ...Object.fromEntries(params),
            limit
        }));
    }

    const ELLIPSIS_OPTIONS = [
        {
            id: 1,
            text: 'Edit',
            icon: 'fas fa-edit',
            onClick: () => {
                onEdit();
                readSecondarySources(dataProvider.master_source);
                onToggle();
            }
        },
        {
            text: 'Delete',
            icon: 'fas fa-trash-alt',
            displaysPopup: true,
            popupProps: {
                header: 'Are you sure?',
                onConfirm: async () => {
                    try {
                        await dispatch(leadProviderActions.deleteProvider(dataProvider.id));
                        Notification.alert('Successfully deleted provider!', STATUS_TYPES.SUCCESS, true);
                        const isNowEmpty = dataProviders.length - 1 === 0;
                        if (!isNowEmpty) {
                            refresh();
                        } else {
                            const query = new URLSearchParams(params);
                            const page = query.get('page');
                            if (Number(page) !== 1) {
                                query.set('page', page - 1);
                                const query_string = query.toString();
                                navigate(`/settings/data-providers${query_string ? `?${query_string}` : ''}`);
                            } else {
                                refresh();
                            }
                        }
                    } catch (e) {
                        Notification.alert('Failed to delete provider', STATUS_TYPES.ERROR, true);
                    }
                }
            }
        }
    ];

    const refreshDataRecords = async () => {
        await setIsReadingDataRecords(true);
        try {
            const response = await dispatch(leadProviderActions.readProviderMappings(dataProvider.id));
            await setDataRecords(response.data);
        } catch (e) {
            console.warn(e);
            await setDataRecords([]);
        } finally {
            await setIsReadingDataRecords(false);
        }
    }

    const [collapsed, setCollapsed] = useState(true);

    const onToggle = () => {
        setCollapsed(!collapsed);
        if (!collapsed) {
            setDataRecords([]);
        } else {
            refreshDataRecords();
        }
    }

    const onCancelEditing = () => {
        onToggle();
        onCancelEdit();
    }

    const DropdownItem = ({ text, icon, displaysPopup, popupProps, ...otherProps }) => {
        const DropdownModel = () => {
            return (
                <Dropdown.Item text={<React.Fragment>
                    <Icon name={icon} />
                    {text}
                </React.Fragment>}
                    style={{ cursor: 'pointer' }}
                    {...otherProps}
                />
            );
        }

        if (displaysPopup === true) {
            return (
                <ControlledPopup
                    header={
                        <Header as='h4' textAlign='center'>
                            {popupProps.header}
                            <Header.Subheader style={{ marginTop: 5 }}>
                                {popupProps.subheader}
                            </Header.Subheader>
                        </Header>
                    }
                    trigger={
                        DropdownModel()
                    }
                    onConfirm={popupProps.onConfirm}
                />
            )
        } else {
            return (
                <DropdownModel />
            );
        }
    }


    return (
        !isUpdating && !isDeleting ? (
            <Table.CollapsibleRow
                onToggle={onToggle}
                collapsed={collapsed}
                subrowContent={(
                    <Table.CollapsibleRowContent>
                        <Table.Cell colSpan={10}>
                            <DataRecordsTable refresh={refreshDataRecords} provider_id={dataProvider.id} isReadingDataRecords={isReadingDataRecords} data_records={data_records} />
                        </Table.Cell>
                    </Table.CollapsibleRowContent>
                )}>
                <Table.Cell>
                    {!isEditing ? dataProvider.name : (
                        <Form>
                            <Controller
                                name={'name'}
                                control={control}
                                render={({ field: { value, name, onChange }, fieldState: { error } }) => {
                                    console.log(error)
                                    return (
                                        <Form.Input
                                            value={value}
                                            name={name}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                            placeholder='Type Provider Name'
                                            error={error && error.message}
                                            fluid
                                        />
                                    )
                                }}
                            />
                        </Form>
                    )}
                </Table.Cell>
                <Table.Cell colSpan={1}>
                    {!isEditing ? (
                        dataProvider.category || 'Uncategorized'
                    ) : (
                        <Controller
                            name={'category'}
                            control={control}
                            render={({ field: { value, name, onChange } }) => (
                                <Select
                                    value={value}
                                    name={name}
                                    onChange={(_, { value }) => { onChange({ target: { name, value: value || null } }); }}
                                    placeholder='Pick Data Source'
                                    options={DATA_SOURCE_OPTIONS}
                                    clearable
                                    selectOnBlur={false}
                                    compact
                                    style={{ width: '12em' }}
                                />
                            )}
                        />
                    )}
                </Table.Cell>
                <Table.Cell>
                    {!isEditing ? (
                        dataProvider.master_source || 'Uncategorized'
                    ) : (
                           
                        <Controller
                            name={'master_source'}
                            control={control}
                                render={ ({ field: { value, name, onChange } }) => (

                                        <Select
                                            value={ value }
                                            name={ name }
                                            onChange={(_, { value }) => {
                                                setValue('secondary_source_id', null);
                                                onChange({ target: { name, value: value || null } });
                                                readSecondarySources(value);
                                            } }
                                            placeholder='Pick Master Source'
                                            options={MASTER_SOURCE_OPTIONS}
                                            clearable
                                            selectOnBlur={ false }
                                            style={ { width: '13em' } }
                                            compact
                                        />
                                    
                            )}
                                />
                            
                        
                    )}
                </Table.Cell>
                <Table.Cell>
                    {!isEditing ? (
                        !isReadingSecondarySources && (secondary_sources.find(source => source.value === dataProvider.secondary_source_id)?.text || 'Uncategorized')
                    ) : (
                        <Controller
                            name={'secondary_source_id'}
                            control={control}
                            render={({ field: { value, name, onChange } }) => (
                                <Select
                                    value={value}
                                    name={name}
                                    onChange={(_, { value }) => { onChange({ target: { name, value: value || null } }); }}
                                    placeholder='Pick Secondary Source'
                                    clearable
                                    disabled={isReadingSecondarySourceOptions || !current_master_source_value}
                                    options={secondary_source_options}
                                    loading={isReadingSecondarySourceOptions}
                                    style={{ width: '15em' }}
                                    compact
                                />
                            )}
                        />
                    )}
                </Table.Cell>
                <Table.Cell>
                    {dataProvider.sources}
                </Table.Cell>
                <Table.Cell>
                    {dataProvider.mapping_count || 0}
                </Table.Cell>
                <Table.Cell>
                    {!isEditing ? (
                        <EllipsisDropdown>
                            {ELLIPSIS_OPTIONS.map(({ text, icon, displaysPopup, popupProps, ...otherProps }, idx) => {
                                return (
                                    <DropdownItem
                                        key={idx}
                                        text={text}
                                        icon={icon}
                                        displaysPopup={displaysPopup}
                                        popupProps={popupProps}
                                        {...otherProps}
                                    />
                                );
                            })}
                        </EllipsisDropdown>
                    ) : (
                        <React.Fragment>
                            <Icon color='red' className='fas fa-times' link onClick={onCancelEditing} />
                            <Icon disabled={!canConfirmEdit} color='blue' className='fas fa-check' link onClick={onConfirmEdit} />
                        </React.Fragment>
                    )}
                </Table.Cell>
            </Table.CollapsibleRow>
        ) : (
            <TableLoader isLoading colspan={7} />
        )
    )
})
