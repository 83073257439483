import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Breadcrumb, Segment } from "semantic-ui-react";
import "./index.scss"
import GroupContactsToolbar from "./subcomponents/GroupContactsToolbar";
import TableWithHeader from "dyl-components/templates/TableWithHeader";
import GroupContactsTable from "./subcomponents/GroupContactsTable"; 
import groupsActions from 'actions/groups';
import oauthActions from 'actions/oauth';
import { useDispatch, useSelector } from 'react-redux';
import BulkActionsProvider, { BulkActionsContext } from "shared/context/BulkActionsProvider";

const GroupContacts = () => {
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const { group_id } = useParams();
    const [filters, setFilters] = useState({
        account_type: params.get('account_type')?.split(','),
        owned_by: params.get('owned_by')?.split(','),
        module: params.get('module')?.split(',')
    });
    const page = params.get("page") || ""
    const search = params.get("search") || "";
    const order = params.get("order") || "desc"
    const start = params.get("start") || "";
    const end = params.get("end") || "";
    const account_type = params.get("account_type") || "";
    const module = params.get("module") || "";

    const { groupName, parent } = useSelector((state) => {
        return {
            groupName: state.groups.groupName,
            parent: state.groups.parent
        }
    })

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`/group/${group_id}${query_string ? `?${query_string}` : ''}`);
    }

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value
        });
        const query = new URLSearchParams(params);
        query.set('page', 1);
        switch(name) {
            case "account_type": {
                if (value?.length > 0) {
                    query.set('account_type', value.join(','));
                } else {
                    query.delete('account_type');
                }
                break;
            }
            case "owned_by": {
                if (value?.length > 0) {
                    query.set('owned_by', value.join(','));
                } else {
                    query.delete('owned_by');
                }
                break;
            }
            case "module": {
                if (value?.length > 0) {
                    query.set('module', value.join(','));
                } else {
                    query.delete('module');
                }
                break;
            }
            default: {
                break;
            }
        }
        const query_string = query.toString();
        navigate(`/group/${group_id}${query_string ? `?${query_string}` : ''}`,);
    }

    const [, setSelectedContacts, , setAreContactsInAllPagesSelected] = useContext(BulkActionsContext);

    const reloadContacts = () => {
        const query = {
            search,
            order,
            page,
            account_type,
            start_time: start,
            end_time: end,
            limit: 25
        }
        if (group_id !== "hotlist") {
            dispatch(groupsActions.readGroupContacts(group_id, query));
        } else {
            dispatch(groupsActions.readHotlist(query));
        }
        dispatch(oauthActions.getIntegrations({ scopes: 'email', include_disabled: false }));
        setSelectedContacts([]);
        setAreContactsInAllPagesSelected(false);
    }

    useEffect(() => {
        const query = {
            search,
            order,
            page,
            account_type,
            start_time: start,
            end_time: end,
            limit: 25
        }
        if (group_id !== "hotlist") {
            dispatch(groupsActions.readGroupContacts(group_id, query));
        } else {
            dispatch(groupsActions.readHotlist(query));
        }
        dispatch(oauthActions.getIntegrations({ scopes: 'email', include_disabled: false }));
        setSelectedContacts([]);
        setAreContactsInAllPagesSelected(false);
    }, [page, search, order, start, end, account_type, module, group_id, dispatch, setSelectedContacts,setAreContactsInAllPagesSelected])

    return <Segment basic className="GroupContacts">
        <div>
            <Breadcrumb>
                <Breadcrumb.Section
                    as={NavLink}
                    to="/groups"
                    link
                >
                    Contact Groups
                </Breadcrumb.Section>
                <Breadcrumb.Divider />
                {parent && group_id !== "hotlist" && (
                    <>
                        <Breadcrumb.Section
                            as={NavLink}
                            to={`/group/${parent.id}`}
                            link
                        >
                            {parent.name}
                        </Breadcrumb.Section>
                        <Breadcrumb.Divider />
                    </>
                )}
                <Breadcrumb.Section active>
                    {group_id === "hotlist" ? "Hotlist" : groupName}
                </Breadcrumb.Section>
            </Breadcrumb>
        </div>
        <TableWithHeader
                header={(
                    <GroupContactsToolbar reloadContacts={reloadContacts} groupName={groupName} parent={parent} />
                )}
                table={(
                    <GroupContactsTable
                        isColumnHidden={() => false}
                        onFilter={onFilter}
                        onPageChange={onPageChange}
                        parent={parent}
                    />
                )}
        />
    </Segment>;
}

export default function GroupContactsContainer() {
    return (
        <BulkActionsProvider>
            <GroupContacts />
        </BulkActionsProvider>
    )
};
