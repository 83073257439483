import React from 'react';
import { Form } from 'semantic-ui-react';
import { FilterComponent, DatesRangeInput, DateTimeUtils } from 'dyl-components';
import { connect } from 'react-redux';
import {  RecordUtils } from 'utils';
import usersActions from 'actions/users';
import leadsActions from 'actions/leads';
import lead_typesActions from 'actions/lead_types';
import lead_sourcesActions from 'actions/lead_sources';
import './ExportFilter.scss';

const timeframeOptions = [
    { key: 'any', value: 'any', text: 'Any' },
    { key: 'specify', value: 'specify', text: 'Specify' },
    { key: 'today', value: 'today', text: 'Today' },
    { key: 'yesterday', value: 'yesterday', text: 'Yesterday' },
    { key: 'this_week', value: 'this_week', text: 'This Week' },
    { key: 'last_week', value: 'last_week', text: 'Last Week' },
    { key: 'this_month', value: 'this_month', text: 'This Month' },
    { key: 'last_month', value: 'last_month', text: 'Last Month' },
    { key: 'year_to_date', value: 'year_to_date', text: 'Year to Date' },
    { key: 'last_year', value: 'last_year', text: 'Last Year' },
]

const ExportFilterComponent = ({
    leadSources,
    leadTypes,
    dateRange,
    users,
    type,
    source,
    user,
    timeframe,
    notes,
    customer,
    deleted,
    items,
    onInputChange,
    onDateRangeChange,
    onInputChangeTimeFrame,
    onSubmit
}) => (
        <FilterComponent>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Select name={'timeframe'} onChange={onInputChangeTimeFrame} options={timeframeOptions} fluid label='Browse by Timeframe' value={timeframe || 'any'} />
                    <Form.Select name={'campaign'} options={[{ key: 'any', value: 'any', text: 'Any' }]} label={'Campaign'} value={'any'} />
                    <Form.Select name={'folder'} options={[{ key: 'any', value: 'any', text: 'Any' }]} label={'Label'} value={'any'} />

                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field label='Browse by Date'
                        control={DatesRangeInput}
                        placeholder={"From - To"}
                        iconPosition="right"
                        value={dateRange}
                        popupPosition={'bottom center'}
                        onChange={onDateRangeChange}
                        name="dateRange"
                    />
                    <Form.Select name={'type'} onChange={onInputChange} options={[{ key: 'any', value: 'any', text: 'Any' }, ...leadTypes]} label={'Product Interest'} value={type || 'any'} />
                    <Form.Select name={'source'} onChange={onInputChange} options={[{ key: 'any', value: 'any', text: 'Any' }, ...leadSources]} label={'Lead Source'} value={source || 'any'} />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Select name={'user'} onChange={onInputChange} options={[{ key: 'any', value: 'any', text: 'Any' }, ...users]} label={'Assigned To'} value={user || 'any'} />
                    <Form.Select name={'caller_id'} options={[{ key: 'any', value: 'any', text: 'Any' }]} label={'Caller ID Routing'} value={'any'} />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field className='FilterComponent__radio-buttons' floated='left'>
                        <label>Has Notes</label>
                        <Form.Group inline>
                            <Form.Radio checked={notes === 'both'} label='Both' value='both' name={'notes'} onChange={onInputChange} />
                            <Form.Radio checked={notes === 'yes'} label='Yes' value='yes' name={'notes'} onChange={onInputChange} />
                            <Form.Radio checked={notes === 'no'} label='No' value='no' name={'notes'} onChange={onInputChange} />
                        </Form.Group>
                    </Form.Field>
                    <Form.Field className='FilterComponent__radio-buttons' floated='left'>
                        <label>Customer</label>
                        <Form.Group inline>
                            <Form.Radio checked={customer === 'both'} label='Both' value='both' name={'customer'} onChange={onInputChange} />
                            <Form.Radio checked={customer === 'yes'} label='Yes' value='yes' name={'customer'} onChange={onInputChange} />
                            <Form.Radio checked={customer === 'no'} label='No' value='no' name={'customer'} onChange={onInputChange} />
                        </Form.Group>
                    </Form.Field>
                    <Form.Field className='FilterComponent__radio-buttons' floated='left'>
                        <label>Deleted</label>
                        <Form.Group inline>
                            <Form.Radio checked={deleted === 'both'} label='Both' value='both' name={'deleted'} onChange={onInputChange} />
                            <Form.Radio checked={deleted === 'yes'} label='Yes' value='yes' name={'deleted'} onChange={onInputChange} />
                            <Form.Radio checked={deleted === 'no'} label='No' value='no' name={'deleted'} onChange={onInputChange} />
                        </Form.Group>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field>

                    </Form.Field>

                    <Form.Field className='ItemsPerPage'>
                        <Form.Input className='ItemsPerPage__input' name={'items'} label='Items Per Page' value={items} onChange={onInputChange} />
                    </Form.Field>

                    <Form.Field className='ActionButtons'>
                        <Form.Group inline>
                            <Form.Button onClick={onSubmit} primary>
                                Clear All
                            </Form.Button>
                            <Form.Button onClick={onSubmit} primary>
                                Filter
                            </Form.Button>
                        </Form.Group>
                    </Form.Field>
                </Form.Group>
            </Form>

        </FilterComponent>
    )

class ExportFilterContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRange: '',
            start: '',
            end: '',
            type: 'any',
            source: 'any',
            user: 'any',
            timeframe: 'any',
            notes: 'both',
            customer: 'both',
            deleted: 'both',
            items: 25
        }
        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.props.onReadUsers();
        this.props.onReadLeadTypes();
        this.props.onReadLeadSources();
        this.setState({
            dateRange: `${DateTimeUtils.getCurrentDate()} - ${DateTimeUtils.getCurrentDate()}`,
            start: `${DateTimeUtils.getCurrentDate()}`,
            end: `${DateTimeUtils.getCurrentDate()}`
        })
    }

    onInputChange(_, { name, value }) {

        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    }

    onDateRangeChange = (_, { value }) => {

        let dateData = value.split(' - ');

        this.setState({
            dateRange: value,
            start: dateData[0] || null,
            end: dateData[1] || null
        });
    }

    onInputChangeTimeFrame = (_, { name, value }) => {
        this.onInputChange(_, { name, value });
        let dateData = DateTimeUtils.getDateRange(value);
        let { start, end } = dateData;
        let dateRange = (value === 'any' || value === 'specify') ? this.state.dateRange : `${dateData.start} - ${dateData.end}`;

        if (value === 'specify') {
            dateData = dateRange.split(' - ');
            start = dateData[0] || null;
            end = dateData[1] || null;
        }
        this.setState({
            dateRange,
            start: start,
            end: end
        });

    }

    onSubmit() {
        if (this.props.onFilterLeads) {
            let queryParams = { ...this.props.queryParameters, ...this.state, page: 1 };

            if (queryParams.timeframe === 'any') {
                delete queryParams.start;
                delete queryParams.end;
            } else {
                queryParams.start === null ? delete queryParams.start :
                    queryParams.start = DateTimeUtils.getUnixTime(`${queryParams.start}`);

                queryParams.end === null ? delete queryParams.end :
                    queryParams.end = DateTimeUtils.getUnixTime(`${queryParams.end} 23:59:59`);
            }
            delete queryParams.timeframe;
            delete queryParams.dateRange;
            delete queryParams.lead;


            Object.keys(queryParams).forEach(key => {
                if (queryParams[key] === 'any') {
                    delete queryParams[key];
                }
            });

            this.props.onFilterLeads(queryParams);
        }
    }

    render() {
        return (
            <ExportFilterComponent
                {...this.props}
                onDateRangeChange={this.onDateRangeChange}
                onInputChangeTimeFrame={this.onInputChangeTimeFrame}
                onInputChange={this.onInputChange}
                onSubmit={this.onSubmit}
                {...this.state}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        users: RecordUtils.generateUserOptions(state.users.users),
        leadTypes: state.lead_types.leadTypes.map(leadType => ({
            key: leadType.id,
            value: leadType.id,
            text: leadType.name
        })),
        leadSources: state.lead_sources.leadSources.map(leadSource => ({
            key: leadSource.id,
            value: leadSource.id,
            text: leadSource.name
        })),
        queryParameters: state.leads.queryParameters
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onReadUsers: () => {
            dispatch(usersActions.readUsers({ all: true }))
        },

        onReadLeadTypes: () => {
            dispatch(lead_typesActions.getLeadTypes())
        },

        onReadLeadSources: () => {
            dispatch(lead_sourcesActions.getLeadSources())
        },

        onFilterLeads: (queryParams) => {
            dispatch(leadsActions.readLeads(queryParams))
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportFilterContainer);
