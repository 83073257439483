import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import './ErrorContainer.scss';

const ErrorContainer = ({ children, height }) => (
    <Grid as={Segment} {...(height ? { style: { height } } : {})} basic textAlign='center' className='ErrorContainer' verticalAlign='middle'>
        <Grid.Column>
            {children}
        </Grid.Column>
    </Grid>
)

export default ErrorContainer;
