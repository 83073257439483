import React, { useEffect } from 'react';
import { DateInput, DropdownTimeInput } from "dyl-components";
import { Button, Form, Icon, ListItem, Segment, Grid } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import { DateTimeUtils } from 'dyl-components';
import EventFormUtils from 'shared/EventForm/Utils';


const { DAYS_OPTIONS, FREQUENCY_OPTIONS } = EventFormUtils;


export default function Datefields(props) {
    const { control, trigger, minDate, isReadOnly, setValue, getValues, watch, tz } = props;
    const isAllDay = watch('all_day');
    const isRecurring = watch('recurring', false);
    const frequency = watch('frequency')

    let timeDropdownDefaults = DateTimeUtils.getTimeDropdownDefaults();
    let minimumEndTime = null; // TODO: set this one right

    const onChangeDays = (value, day, onChange, name) => {
        return () => {
            const new_value = value.slice(0);
            if (new_value.includes(day.value)) {
                new_value.splice(new_value.indexOf(day.value), 1);
            } else {
                new_value.push(day.value);
            }
            onChange({ target: { name, value: new_value } });
        };
    }

    useEffect(() => {
        const timezone = getValues('timezone');
        DateTimeUtils.setTimezone(timezone);
        return () => {
            DateTimeUtils.setTimezone(tz);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tz]);


    const renderFrequency = (frequency) => {
        switch (frequency) {
            case 'monthly':
                return "Repeat on same date every month";
            case 'yearly':
                return "Repeat on same date every year"
            case 'daily':
                return (
                    <Controller
                        name='repeat_days'
                        control={control}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <Form.Field>
                                <Form.Group>
                                    {DAYS_OPTIONS.map(day => (
                                        <Button
                                            size='mini'
                                            type="button"
                                            onClick={onChangeDays(value, day, onChange, name)}
                                            primary={value && value.includes(day.value)}
                                            active={value && value.includes(day.value)}
                                            circular
                                            basic
                                            className='Frequency__day light'
                                        >
                                            {day.text}
                                        </Button>
                                    ))}
                                </Form.Group>
                                {error && error.message && (
                                    <Form.Field
                                        error={error && error.message && {
                                            content: error.message,
                                            pointing: 'below'
                                        }}
                                        control={Segment}
                                        basic
                                    />
                                )}
                            </Form.Field>
                        )}
                    />
                )
            default:
                return null;
        }
    }
    return <>
        <ListItem
            icon={
                <Icon name='clock' color='black' />
            }
            description={(
                <Grid>
                    <Grid.Row columns='equal' {...(!isAllDay ? { className: 'Event__dates' } : {})}>
                        <Grid.Column>
                            <Controller
                                name='start_date'
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => {
                                    return (
                                        <Form.Field
                                            control={DateInput}
                                            label='Start Date/Time'
                                            onBlur={() => {
                                                if (!DateTimeUtils.isValid(value, DateTimeUtils.WORD_DATE_FORMAT)) {
                                                    onChange({ target: { name: 'start_date', value: timeDropdownDefaults.startDate } });
                                                    trigger(['end_date','start_time','end_time'])
                                                } else if (DateTimeUtils.dateIsBeforeAnotherDate(value, minDate, 'day', DateTimeUtils.WORD_DATE_FORMAT)) {
                                                    onChange({ target: { name: 'start_date', value: minDate } });
                                                    trigger(['end_date','start_time','end_time'])
                                                }
                                            }}
                                            onChange={(_, { value: new_value }) => {
                                                onChange({ target: { name: 'start_date', value: new_value } });
                                                trigger(['end_date','start_time','end_time'])
                                            }}
                                            value={value}
                                            error={error && error.message && {
                                                content: error.message,
                                                pointing: 'below'
                                            }}
                                            required
                                            icon=''
                                            dateFormat={DateTimeUtils.WORD_DATE_FORMAT}
                                            minDate={minDate}
                                            disabled={isReadOnly}
                                        />
                                    );
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Controller
                                name='end_date'
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <Form.Field
                                        control={DateInput}
                                        label='End Date/Time'
                                        onBlur={() => {
                                            if (!DateTimeUtils.isValid(value, DateTimeUtils.WORD_DATE_FORMAT)) {
                                                onChange({ target: { name: 'end_date', value: timeDropdownDefaults.endDate } });
                                                trigger('end_time');
                                            } else if (DateTimeUtils.dateIsBeforeAnotherDate(value, minDate, 'day', DateTimeUtils.WORD_DATE_FORMAT)) {
                                                onChange({ target: { name: 'end_date', value: minDate } });
                                                trigger('end_time');
                                            }
                                        }}
                                        onChange={(_, { value: new_value }) => {
                                            onChange({ target: { name: 'end_date', value: new_value } });
                                            trigger('end_time');
                                        }}
                                        value={value}
                                        error={error && error.message && {
                                            content: error.message,
                                            pointing: 'below'
                                        }}
                                        required
                                        icon=''
                                        dateFormat={DateTimeUtils.WORD_DATE_FORMAT}
                                        minDate={minDate}
                                        disabled={isReadOnly}
                                    />
                                )}
                            />
                        </Grid.Column>

                    </Grid.Row>
                    {!isAllDay && (
                        <>
                        <Grid.Row className='Event__times' columns='equal'>
                            <Grid.Column>
                                <Controller
                                    name='start_time'
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Form.Field
                                            control={DropdownTimeInput}
                                            className="Event__no-label--Error-fix"
                                            onChange={(_, { value: new_value }) => {
                                                onChange({ target: { name: 'start_time', value: new_value } });
                                                trigger('end_time');
                                            }}
                                            value={value}
                                            error={error && error.message && {
                                                content: error.message,
                                                pointing: 'below'
                                            }}
                                            disabled={isReadOnly}
                                        />
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Controller
                                    name='end_time'
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Form.Field
                                            control={DropdownTimeInput}
                                            className="Event__no-label--Error-fix"
                                            onChange={(_, { value: new_value }) => {
                                                onChange({ target: { name: 'end_time', value: new_value } })
                                            }}
                                            value={value}
                                            error={error && error.message && {
                                                content: error.message,
                                                pointing: 'below'
                                            }}
                                            minTime={minimumEndTime}
                                            disabled={isReadOnly}
                                        />
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        </>
                    )}
                </Grid>
            )}
        />
        { !isAllDay && (
        <Controller
            name='timezone'
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Form.Dropdown
                    className='Event__timezone'
                    options={DateTimeUtils.generateTimezoneOptions()}
                    icon=''
                    onChange={(_, { value }) => {
                        onChange({ target: { name: 'timezone', value } });
                        DateTimeUtils.setTimezone(value);
                    }}
                    value={value}
                    required
                    disabled={isReadOnly}
                    error={error && error.message && {
                        content: error.message,
                        pointing: 'below'
                    }}
                    selectOnBlur={false}
                />
            )}
        />)
        }
        <Form.Group className="Event__row">
            <Controller
                name='all_day'
                control={control}
                render={({ field: { onChange, value } }) => (
                    <Form.Checkbox
                        checked={value}
                        onChange={(_, { checked }) => {
                            onChange({ target: { name: 'all_day', value: checked } })
                            setValue('all_day',checked)
                        }}
                        label='All day'
                        disabled={isReadOnly}
                    />
                )}
            />
        </Form.Group>
        <Form.Group className="Event__row">
            <Controller
                name='recurring'
                control={control}
                render={({ field: { onChange, value, name } }) => (
                    <Form.Checkbox
                        checked={value}
                        onChange={(_, { checked }) => {
                            onChange({ target: { name, value: checked } })
                        }}
                        label='Recurring'
                        disabled={isReadOnly}
                    />
                )}
            />
            {isRecurring && (
                <React.Fragment>
                    <Controller
                        name='frequency'
                        control={control}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <Form.Dropdown
                                className='Event__frequency'
                                options={FREQUENCY_OPTIONS}
                                icon=''
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                value={value}
                                required
                                error={error && error.message && {
                                    content: error.message,
                                    pointing: 'below'
                                }}
                                selectOnBlur={false}
                                disabled={isReadOnly}
                            />
                        )}
                    />
                    {renderFrequency(frequency)}
                </React.Fragment>
            )}
        </Form.Group>
    </>
}
