import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function customerPipelineReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CUSTOMER_PIPELINE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, accountBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.CUSTOMER_PIPELINE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, accountBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.CUSTOMER_PIPELINE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, accountBeingUpdated: null };

        default:
            return state;
    }
}


export default customerPipelineReducer;
