import React from 'react';
import { Grid, Popup, Header } from 'semantic-ui-react';
import { Button, STATUS_TYPES, DateInput } from 'dyl-components';
import { LeftPaddedColumn } from 'dyl-components';

const PendingCallsRow = ({ list, onClear, clearLeadsDate, onChangeDate }) => (
    <Grid.Row columns='equal' verticalAlign='middle'>
        <Grid.Column as={LeftPaddedColumn}>
            {list.name}
        </Grid.Column>
        <Grid.Column>
            {list.numberOfLeads} Lead{list.numberOfLeads > 1 ? 's' : ''}
        </Grid.Column>
        <Grid.Column>
            <Popup 
                trigger={<Button status={STATUS_TYPES.PRIMARY}>Clear</Button>}
                on='click'
                content={(
                    <React.Fragment>
                        <Header textAlign='center'>
                        Clear Leads Received Before
                        </Header>
                        <DateInput 
                            inline
                            value={clearLeadsDate}
                            onChange={onChangeDate}
                        />
                        <Button onClick={() => {onClear(list.id)}} status={STATUS_TYPES.PRIMARY}>Clear Pending Calls</Button>
                    </React.Fragment>
                )}
            />
        </Grid.Column>
    </Grid.Row>
);

class PendingCallsRowContainer extends React.Component {
    state = {
        clearLeadsDate: new Date()
    }

    onChangeDate = (_, { value }) => {
        this.setState({ clearLeadsDate: value });
    }

    onClear = () => {
        this.props.onClear(this.props.list.id, this.state.clearLeadsDate);
    }

    render() {
        return (
            <PendingCallsRow 
                {...this.props}
                {...this.state}
                onChangeDate={this.onChangeDate}
                onClear={this.onClear}
            />
        )
    }
}

export default PendingCallsRowContainer;
