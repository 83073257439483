import pipelineActions from 'actions/pipeline';
import moduleInformationActions from 'actions/module_information';
import contactActions from 'actions/contact';
import { DateTimeUtils, Notification, Stages, STATUS_TYPES } from 'dyl-components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { StringUtils } from 'utils';
import sequencesActions from 'actions/sequences';
import sequenceTasksActions from 'actions/sequence_tasks';
import contactSequenceActions from 'actions/contact_sequence';

const StageControls = () => {
    const { stages, account_stage, account_id, active_stage, last_update_date, creation_date, pipeline_category_name, contact_id, pipeline_id, areSequenceTasksCompleted, selectedSequenceId } = useSelector(state => {
        const stages = state.module_information.pipeline?.stages?.map(({ id, name, sequence_id }) => {
            return {
                key: id,
                content: StringUtils.capitalize(name.toLowerCase()),
                sequence_id
            };
        }) || [];
        const { pipeline_stage_id, activity, created, pipeline_category_name, module, contact, id: pipeline_id } = state.module_information.pipeline;
        const last_update_date = activity ? DateTimeUtils.convertUnixTimeToDate(activity).fromNow() : '';
        return ({
            account_id: contact?.account?.id,
            account_stage: module,
            stages: stages,
            active_stage: stages.find(stage => stage.key === pipeline_stage_id)?.content || '',

            last_update_date,
            creation_date: created ? DateTimeUtils.convertUnixTimeToDate(created).fromNow() : '',
            pipeline_category_name,
            contact_id: contact.id,
            pipeline_id,
            areSequenceTasksCompleted:
                state.sequence_tasks.selectedSequenceTasks?.length === 0 ||
                state.sequence_tasks.selectedSequenceTasks?.every(task => task.status === 'complete' || task.status === 'canceled'),
            selectedSequenceId: state.sequence_tasks.selectedSequenceId
        });
    });

    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { contact_id: routeContactId } = useParams();

    const refresh = () => {
        const query = new URLSearchParams(params);
        query.set('module_id', pipeline_id);
        const query_string = query.toString();
        navigate(`${location.pathname}?module_id=${pipeline_id}`);
        navigate(`${location.pathname}${query_string ? `?${query_string}` : ''}`);
        dispatch(moduleInformationActions.getOne(pipeline_id));
        dispatch(sequenceTasksActions.readContactTasks(contact_id));
        if (routeContactId) {
            dispatch(contactActions.readContact(contact_id));
        }
    }

    const onTransitionPipeline = async (stage_name) => {
        const stage = stages.find(stage => stage.content === stage_name);

        try {
            await dispatch(pipelineActions.transition(pipeline_id, {
                account_id: account_id,
                pipeline_stage_id: stage.key,
                person_id: contact_id
            }, { sequence_done: areSequenceTasksCompleted }))
            if (selectedSequenceId) {
                try {
                    await dispatch(contactSequenceActions.removeFromContact(contact_id));
                } catch (e) {
                    console.log(e);
                }
            }
            if (stage.sequence_id) {
                await dispatch(sequencesActions.addToSequence({ contact_id: Number(contact_id) }, null, Number(stage.sequence_id)));
            }
            Notification.alert(`Successfully updated stage!`, STATUS_TYPES.SUCCESS);
            refresh();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update stage', STATUS_TYPES.ERROR);
        }
    }


    return (
        <React.Fragment>
            {pipeline_category_name}
            <Stages
                stages={stages.map(stage => ({ key: stage.content, content: stage.content }))}
                activeStage={active_stage}
                changeStage={onTransitionPipeline}
            >
                <div className="ProfileStages__timestamps">
                    <div><em><b>{StringUtils.capitalize(account_stage)} Stage Changed:</b> {last_update_date}</em></div>
                    <div><em><b>{`${StringUtils.capitalize(account_stage)} Created`}:</b></em> {creation_date}</div>
                </div>
            </Stages>
        </React.Fragment>
    )
}

export default StageControls;
