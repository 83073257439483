import { Notification, STATUS_TYPES } from 'dyl-components';

import routes from "actions/routes";
import { generateReadActionCreator, generateCreateActionCreator } from "actions/crudActionCreators";
import ACTION_NAMES from "actions/ACTION_NAMES";

const onCreateSuccess = () => {
    Notification.alert('Successfully added chat room!', STATUS_TYPES.SUCCESS, true);
}

const onCreateFailure = () => {
    Notification.alert('Failed to add chat room', STATUS_TYPES.ERROR, true);
}

const readChatRooms = generateReadActionCreator(routes.API_CHAT_ROOMS, ACTION_NAMES.CHAT_ROOMS);
const createChatRoom = generateCreateActionCreator(routes.API_CHAT_ROOMS, ACTION_NAMES.CHAT_ROOMS, onCreateSuccess, onCreateFailure);

export default {
    readChatRooms,
    createChatRoom
}
