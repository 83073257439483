import { DropdownTimeInput } from "dyl-components";
import { Controller } from "react-hook-form";
import { Form } from "semantic-ui-react";

import { StringUtils } from "utils";
import EmailAction from "./EmailAction";
import TextAction from "./TextAction";

import './Action.scss';

const ActionFields = ({ type, control, index, id, onFocus }) => {
    switch (type) {
        case 'call':
            return null;
        case 'text':
            return (
                <TextAction onFocus={onFocus} control={control} index={index} />
            );
        case 'email':
            return (
                <EmailAction onFocus={onFocus} id={id} control={control} index={index} />
            );
        default:
            throw new Error(`Unsupported action: ${type}`);
    }
}

const Action = ({ action, index, control, onUpdateAction, onFocus, deliveryOptions }) => {
    return (
        <Controller
            control={control}
            name={`actions[${index}].type`}
            render={({ fieldState: { error } }) => (
                <>
                    <h3>Action {index + 1}: {StringUtils.capitalize(action.type)}</h3>
                    <Form.Group>
                        <Form.Select
                            value={action.type}
                            onChange={(_, { value }) => {
                                onUpdateAction({
                                    ...action,
                                    type: value,
                                    data: (() => {
                                        switch (value) {
                                            case 'call': return {};
                                            case 'text': return {
                                                message: '',
                                                attachments: []
                                            }
                                            case 'email': return {
                                                subject: '',
                                                body: '',
                                                attachments: []
                                            }
                                            default: throw new Error(`Unsupported type: ${value}`);
                                        }
                                    })(),
                                    deliver: "",
                                    timeamount: ""
                                })
                            }}
                            options={[
                                {
                                    key: 'call', value: 'call', text: 'Call'
                                },
                                {
                                    key: 'text', value: 'text', text: 'Text'
                                },
                                {
                                    key: 'email', value: 'email', text: 'Email'
                                }
                            ]}
                            required
                            selectOnBlur={false}
                            label='Task Type'
                            placeholder="Select Task Type"
                            width={4}
                            error={error?.message}
                            onFocus={onFocus}
                        />
                        <Controller
                            control={control}
                            name={`actions[${index}].deliver`}
                            render={({ fieldState: { error } }) => (
                                <>
                                    <Form.Select
                                        width={4}
                                        value={action.deliver}
                                        onChange={(_, { value }) => {
                                            onUpdateAction({
                                                ...action,
                                                deliver: value,
                                                timeamount: 1,
                                                time: (value === 'instant' || value === 'minutes' || value === 'hours') ? '' : action.time
                                            })
                                        }}
                                        options={deliveryOptions}
                                        required
                                        selectOnBlur={false}
                                        label={`Deliver${index > 0 ? ' In' : ''}`}
                                        placeholder="Select deliver option"
                                        error={error?.message}
                                        onFocus={onFocus}
                                    />
                                    {action.deliver && action.deliver !== 'instant' && (
                                        <>
                                            <Controller
                                                control={control}
                                                name={`actions[${index}].timeamount`}
                                                render={({ fieldState: { error } }) => {
                                                    const timeAmountOpitons = Array.from({
                                                        length: (() => {
                                                            switch (action.deliver) {
                                                                case 'minutes': return 60;
                                                                case 'hours': return 24;
                                                                case 'days': return 15;
                                                                case 'weeks': return 8;
                                                                case 'months': return 12;
                                                                default: return 1;
                                                            }
                                                        })()
                                                    }, (_, i) => ({ key: i + 1, value: i + 1, text: i + 1 }));
                                                    return (
                                                        <Form.Select
                                                            label={StringUtils.capitalize(action.deliver)}
                                                            placeholder={`Enter number of ${action.deliver}`}
                                                            value={action.timeamount}
                                                            onChange={(_, { value }) => { onUpdateAction({ ...action, timeamount: value }) }}
                                                            options={timeAmountOpitons}
                                                            width={3}
                                                            error={error?.message}
                                                            required
                                                            onFocus={onFocus}
                                                        />
                                                    );
                                                }}
                                            />
                                            {action.deliver !== 'instant' && action.deliver !== 'minutes' && action.deliver !== 'hours' && (
                                                <Controller
                                                    control={control}
                                                    name={`actions[${index}].time`}
                                                    render={({ fieldState: { error } }) => (
                                                        <Form.Field
                                                            width={3}
                                                            value={action.time}
                                                            onChange={(_, { value }) => { onUpdateAction({ ...action, time: value }) }}
                                                            placeholder='Select Time'
                                                            control={DropdownTimeInput}
                                                            label='Time'
                                                            error={error?.message}
                                                            required
                                                            onFocus={onFocus}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        />
                    </Form.Group>
                    <ActionFields onFocus={onFocus} type={action.type} control={control} index={index} id={action.id} />
                </>
            )}
        />
    )
}
export default Action;
