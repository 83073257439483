
import initialState from './initialState';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';

function emailReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.EMAIL_PROVIDERS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingProviders: true }
        case getNamedAction(ACTION_NAMES.EMAIL_PROVIDERS, CRUD_ACTION_TYPES.READ_SUCCESS):
            const { data } = action;
            const activeIntegrations = data.active || [];
            const mainIntegration = activeIntegrations.find(e => e.main) || {};
            return { ...state, isReadingProviders: false, providers: data.providers, integrations: activeIntegrations, activeIntegration: mainIntegration }
        case getNamedAction(ACTION_NAMES.EMAIL_PROVIDERS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingProviders: false }

        case getNamedAction(ACTION_NAMES.EMAIL_ENABLE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isEnablingIntegration: true }
        case getNamedAction(ACTION_NAMES.EMAIL_ENABLE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isEnablingIntegration: false }
        case getNamedAction(ACTION_NAMES.EMAIL_ENABLE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isEnablingIntegration: false }

        case getNamedAction(ACTION_NAMES.EMAIL_PULL, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isPullingEmails: true }
        case getNamedAction(ACTION_NAMES.EMAIL_PULL, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isPullingEmails: false, emails: action.data.emails, count: action.data.count, queryParameters: { ...state.queryParameters, ...action.queryParameters } }
        case getNamedAction(ACTION_NAMES.EMAIL_PULL, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isPullingEmails: false }

        case getNamedAction(ACTION_NAMES.EMAIL_SEND, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isSendingEmail: true }
        case getNamedAction(ACTION_NAMES.EMAIL_SEND, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isSendingEmail: false }
        case getNamedAction(ACTION_NAMES.EMAIL_SEND, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isSendingEmail: false }

        case getNamedAction(ACTION_NAMES.DELETE_EMAIL_INTEGRATION, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, integrationBeingDeleted: action.queryParameters.integration_id }
        case getNamedAction(ACTION_NAMES.DELETE_EMAIL_INTEGRATION, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, integrationBeingDeleted: null }
        case getNamedAction(ACTION_NAMES.DELETE_EMAIL_INTEGRATION, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, integrationBeingDeleted: null }

        case getNamedAction(ACTION_NAMES.UPDATE_PRIMARY_EMAIL, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, integrationBeingUpdated: null }
        case getNamedAction(ACTION_NAMES.UPDATE_PRIMARY_EMAIL, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, integrationBeingUpdated: null }
        case getNamedAction(ACTION_NAMES.UPDATE_PRIMARY_EMAIL, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, integrationBeingUpdated: null }

        case getNamedAction(ACTION_NAMES.EMAIL_GET_ALL, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isGettingAllEmails: true }
        case getNamedAction(ACTION_NAMES.EMAIL_GET_ALL, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isGettingAllEmails: false, allEmails: action.data.truncatedEmailAndLogs, count: action.data.totalCount, queryParameters: { ...state.queryParameters, ...action.queryParameters } }
        case getNamedAction(ACTION_NAMES.EMAIL_GET_ALL, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isGettingAllEmails: false }

        case getNamedAction(ACTION_NAMES.EMAIL_GET_ATTACHMENT, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isPullingAttachment: true }
        case getNamedAction(ACTION_NAMES.EMAIL_GET_ATTACHMENT, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isPullingAttachment: false }
        case getNamedAction(ACTION_NAMES.EMAIL_GET_ATTACHMENT, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isPullingAttachment: false }

        case 'CHANGE_ACTIVE_INTEGRATION':
            {
                return { ...state, activeIntegration: action.email };
            }

        default:
            return state;
    }
}

export default emailReducer;
