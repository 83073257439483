import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function tasksReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.TASK_TYPES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingTaskTypes: true };
        case getNamedAction(ACTION_NAMES.TASK_TYPES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingTaskTypes: false, taskTypes: action.data.data, count: action.data.count };
        case getNamedAction(ACTION_NAMES.TASK_TYPES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingTaskTypes: false };
        
        case getNamedAction(ACTION_NAMES.TASK_TYPES, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreating: true };
        case getNamedAction(ACTION_NAMES.TASK_TYPES, CRUD_ACTION_TYPES.CREATE_SUCCESS):
        case getNamedAction(ACTION_NAMES.TASK_TYPES, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreating: false };
        

        case getNamedAction(ACTION_NAMES.TASK_TYPES, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeleting: true };
        case getNamedAction(ACTION_NAMES.TASK_TYPES, CRUD_ACTION_TYPES.DELETE_SUCCESS):
        case getNamedAction(ACTION_NAMES.TASK_TYPES, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeleting: false };

        default:
            return state;
    }
}

export default tasksReducer;
