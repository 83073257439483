import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function contactSocialsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CONTACT_SOCIAL_MEDIA, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingContactSocialMedia: true };
        case getNamedAction(ACTION_NAMES.CONTACT_SOCIAL_MEDIA, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingContactSocialMedia: false };
        case getNamedAction(ACTION_NAMES.CONTACT_SOCIAL_MEDIA, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingContactSocialMedia: false };

        case getNamedAction(ACTION_NAMES.CONTACT_SOCIAL_MEDIA, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingContactSocialMedia: true };
        case getNamedAction(ACTION_NAMES.CONTACT_SOCIAL_MEDIA, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingContactSocialMedia: false };
        case getNamedAction(ACTION_NAMES.CONTACT_SOCIAL_MEDIA, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingContactSocialMedia: false };

        case getNamedAction(ACTION_NAMES.CONTACT_SOCIAL_MEDIA, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, socialNetworkBeingDeleted: action.subId };
        case getNamedAction(ACTION_NAMES.CONTACT_SOCIAL_MEDIA, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, socialNetworkBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.CONTACT_SOCIAL_MEDIA, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, socialNetworkBeingDeleted: null };

        default:
            return state;
    }
}

export default contactSocialsReducer;
