import React from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';

import { Breadcrumb, Grid, Header, Menu, Segment } from 'semantic-ui-react';
import FavoriteIcon from 'shared/FavoriteIcon';

const PhoneManagement = ({ favorite_type, type }) => {
    const details = type ? {
        favorite_type: favorite_type || "settings",
        setting_report_type: type
    } : {};
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header as="h2">
                        {details && <FavoriteIcon details={details} />}
                        <Header.Content>Phone Management</Header.Content>
                    </Header>
                    <Breadcrumb style={{marginBottom: 10}}>
                        <Breadcrumb.Section as={Link} to="/settings/general">Settings</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section as={Link} to={`/settings/phone-management`}>Phone Management</Breadcrumb.Section>
                    </Breadcrumb>
                    <p>Manage your numbers, extensions, routing, IVR, prompts, and more.</p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Grid.Column style={{ paddingTop: '1em', paddingRight: 0, paddingLeft: '0.5em' }}>
                    <Menu borderless pointing secondary color='primary' stackable size='huge'>
                        <Menu.Item name='General' as={NavLink} to='general/call-recording' />
                        <Menu.Item name='Numbers' as={NavLink} to='numbers/dyl-numbers' />
                        <Menu.Item name='Extensions' as={NavLink} to='extensions/users' />
                        <Menu.Item name='Devices' as={NavLink} to='devices' />
                        <Menu.Item name='Routing' as={NavLink} to='routing/ring-group-call-queues' />
                        <Menu.Item name='Audio' as={NavLink} to='audio' />
                    </Menu>
                    <Segment basic>
                        <Outlet />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default PhoneManagement;