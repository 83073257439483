import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function opportunityTabReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.OPPORTUNITY_TAB, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingOpportunities: true, opportunities: [], count: 0 };
        case getNamedAction(ACTION_NAMES.OPPORTUNITY_TAB, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingOpportunities: false, opportunities: (action.data.data || []).map(opportunity => ({ ...opportunity })), queryParameters: action.queryParameters, count: action.data.count };
        case getNamedAction(ACTION_NAMES.OPPORTUNITY_TAB, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingOpportunities: false };

        default:
            return state;
    }
}


export default opportunityTabReducer;
