import React from 'react';
import { Modal } from 'dyl-components';
import { Grid, Transition } from 'semantic-ui-react';
import VoicePromptForm from 'shared/forms/VoicePromptForm';

import PromptOptions from './PromptOptions';

const IVRPromptEditModalContainer = ({ open, onClose, onUpdate, onChange, info }) => (
    <Modal open={open} onClose={onClose} size='large'>
        <Modal.Header>
            Edit Prompt Options
        </Modal.Header>
        <Modal.Content>
            <Grid columns='equal'>
                <Grid.Column>
                    <VoicePromptForm 
                        info={info}
                        onChange={onChange}
                    />
                </Grid.Column>
                <Transition visible={!info.isMessageOnly} animation='slide down' unmountOnHide duration='150'>
                    <Grid.Column width={10}>
                        <PromptOptions />
                    </Grid.Column>
                </Transition>
            </Grid>
        </Modal.Content>
        <Modal.Actions
            hasSaveButton
            onSave={onUpdate}
        />
    </Modal>
)

class IVRPromptEditModal extends React.Component {
    state = {
        info: this.props.info
    }

    componentDidMount() {
        this.setState({ info: this.props.info });
    }

    onChange = (_, { name, value }) => {
        const info = { ...this.state.info, [name]: value };
        this.setState({ info });
    }

    render() {
        return (
            <IVRPromptEditModalContainer
                {...this.props}                
                onChange={this.onChange}
                info={this.state.info}
                schedule={this.state.schedule} 
            />
        )
    }
}

export default IVRPromptEditModal;
