import React from 'react';
import Avatar from 'react-avatar';
import { Icon } from 'dyl-components';

import './index.scss';

const Integration = ({ name, image, customIcon, email, deleted = false }) => (
    <div className='Integration'>
        <div className={`Integration__warning${!deleted ? '--not-deleted' : ''}`}>
            {deleted &&
                <Icon className='fa-solid fa-triangle-exclamation' color='red' size='large' />
            }
        </div>
        {customIcon ?
            <div className='Integration__Avatar'>
                {customIcon}
            </div>
            :
            <div className='Integration__avatar'>
                <Avatar
                    name={name}
                    round='.5em'
                    size='3em'
                    maxInitials={2}
                    src={image}
                />
            </div>
        }
        <div className='Integration__info'>
            <span>
                <b>
                    {name}
                </b>
            </span>
            <span>
                {email}
            </span>
        </div>
    </div>
);

export default Integration
