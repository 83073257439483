import React from 'react';
import { Table, ControlledPopup, STATUS_TYPES, Notification, StatusLabel } from 'dyl-components';
import { Popup, Icon } from 'semantic-ui-react';

import RingGroupEditModal from '../RingGroupEditModal';
import { connect } from 'react-redux';

import ringGroupsActions from 'actions/ring_groups';

const RingGroupRow = ({ group, onOpenRingGroupEditModal, onDelete, ringGroupBeingDeleted }) => (
    <Table.Row>
        <Table.Cell>
            {group.label}
        </Table.Cell>
        <Table.Cell>
            {group.members && group.members.data ? group.members.data.map(({ member }) => member).join(', ') : ''}
        </Table.Cell>
        <Table.Cell>
            {group.members && group.members.data ? group.members.data.map(({ member, active }) => <StatusLabel
                text={member}
                type={active ? 'success' : 'error'}
            />) : ''}
        </Table.Cell>
        <Table.Cell>
            <Popup
                trigger={(
                    <Icon
                        name='cog'
                        size='large'
                        color='blue'
                        link
                        onClick={onOpenRingGroupEditModal}
                    />
                )}
                content={'Edit Ring Group'}
                inverted
            />
            {group.members && group.members.data && group.members.data.length > 0 ? (
                <Popup 
                    trigger={<span>
                        <Icon
                        name='trash'
                        size='large'
                        color='blue'
                        link
                        disabled
                    />
                    </span>}
                    content="Cannot delete group. It has members"
                    inverted
                />
            ) : (
                    <ControlledPopup
                        trigger={<Icon
                            name='trash'
                            size='large'
                            color='blue'
                            link
                        />}
                        onConfirm={() => { onDelete(group.id) }}
                        content={'Delete'}
                        inverted
                    />
                )}
            {ringGroupBeingDeleted === group.id && <Icon name='circle notched' loading />}
        </Table.Cell>
    </Table.Row>
)

class RingGroupRowContainer extends React.Component {
    state = {
        isRingGroupEditModalOpen: false
    }

    onOpenRingGroupEditModal = () => {
        this.setState({ isRingGroupEditModalOpen: true });
    }

    onCloseRingGroupEditModal = () => {
        this.setState({ isRingGroupEditModalOpen: false });
    }

    onDelete = (id) => {
        this.props.onDelete(this.props.domain, id).then(() => {
            Notification.alert('Successfully deleted ring group', STATUS_TYPES.SUCCESS, true);
            this.props.onReadRingGroups(this.props.domain);
        }).catch(() => {
            Notification.alert('Failed to delete ring group', STATUS_TYPES.ERROR, true);
        })
    }

    render() {
        return (
            <React.Fragment>
                <RingGroupRow
                    group={this.props.group}
                    onOpenRingGroupEditModal={this.onOpenRingGroupEditModal}
                    onDelete={this.onDelete}
                    ringGroupBeingDeleted={this.props.ringGroupBeingDeleted}
                />
                <RingGroupEditModal
                    group={{ ...this.props.group }}
                    onClose={this.onCloseRingGroupEditModal}
                    open={this.state.isRingGroupEditModalOpen}
                    isRingGroup
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    domain: state.pbx_config.domain,
    ringGroupBeingDeleted: state.ring_group.ringGroupBeingDeleted
})

const mapDispatchToProps = dispatch => ({
    onDelete: (domain, ring_group) => {
        return dispatch(ringGroupsActions.deleteRingGroup(domain, null, ring_group));
    },
    onReadRingGroups: (domain) => {
        dispatch(ringGroupsActions.readRingGroups(domain));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(RingGroupRowContainer);
