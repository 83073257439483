import React from 'react';
import { Form } from 'semantic-ui-react';
import { TopPaddedControls } from 'dyl-components';

const AddPromptOptionSection = ({ digit, digitOptions, route, routeOptions, onChange, onAddPromptOption, onClose }) => (
    <Form>
        <Form.Group widths='equal'>
            <Form.Select 
                label='Digit'
                name='digit'
                options={digitOptions}
                value={digit}
                onChange={onChange}
            />
            <Form.Select 
                label='Route to'
                name='route'
                options={routeOptions}
                value={route}
                onChange={onChange}
            />
            <TopPaddedControls>
                <Form.Button
                    floated='right'
                    content={'Cancel'}
                    onClick={onClose}
                />
                <Form.Button 
                    primary
                    floated='right'
                    content={'Add'}
                    onClick={onAddPromptOption}
                />
            </TopPaddedControls>
        </Form.Group>
    </Form>
)

class AddPromptOptionSectionContainer extends React.Component {
    state = {
        digit: `${this.props.digitOptions[0].value}`,
        route: this.props.routeOptions[0].value
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onAddPromptOption = () => {
        this.props.onAddPromptOption({ ...this.state });
    }

    render() {
        console.log(this.state.digit);
        return (
            <AddPromptOptionSection 
                {...this.props}
                {...this.state}
                onAddPromptOption={this.onAddPromptOption}
                onChange={this.onChange}
            />
        )
    }
}

export default AddPromptOptionSectionContainer;
