const initialState = {
    customers: [],
    isReadingCustomers: false,
    isUpdatingCustomer: false,
    isRemovingCustomer: false,
    count: 0,
    currentPage: 1,
    queryParameters: {
        page: 1,
        limit: 25
    },
    isCreatingCustomer: false
}

export default initialState
