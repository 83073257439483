import React from 'react';
import { Popup, Input } from 'semantic-ui-react';

import './index.scss';

const ErrorPopup = ({ isPopupOpen, message, inputName, inputValue, onInputChange, inputPlaceholder, isLoading }) => {
    return (
        <Popup
            className='ErrorPopup'
            open={isPopupOpen}
            content={message}
            position='top right'
            trigger={
                <Input
                    name={inputName}
                    value={inputValue}
                    onChange={onInputChange}
                    placeholder={inputPlaceholder}
                    size='mini'
                    compact
                    inline
                    loading={isLoading}
                    error={isPopupOpen}
                />
            }
        />
    );
}

export default ErrorPopup;
