import React from "react";
import { CategorizedCheckboxDropdown, ClearableCheckboxDropdown, Table, TimestampFilters } from "dyl-components";
import { Table as SemanticTable, Icon } from "semantic-ui-react";

import { useSearchParams, useNavigate } from "react-router-dom";
import "../index.scss";
import {
    getData,
    onToggleTimeUnit,
    useRangeCalendar,
} from "shared/CustomDateRange/customDateRange";
import {
    KEY_CREATED_ON, KEY_ACCOUNT_TYPE, KEY_OWNER,
    KEY_ACCOUNT_STATUS, KEY_ACTIONS,
    KEY_REACTIVATED_ON, KEY_CREATED_BY, KEY_REACTIVATED_BY,
    KEY_LAST_MODIFIED, KEY_STAGE,
    KEY_LAST_MODIFIED_BY, KEY_CUSTOMER_SINCE,
    KEY_CANCELED_ON, KEY_CANCELED_BY
} from './constants';

const SortActions = ({ direction = false, onToggleSort = () => { } }) => {
    return (
        <div
            onClick={onToggleSort}
            style={{ display: "inline-flex", cursor: "pointer" }}
        >
            <Icon
                name="fa-solid fa-arrow-down-long"
                style={{
                    marginRight: -7,
                    opacity: !direction ? "100%" : "50%",
                }}
            />
            <Icon
                name="fa-solid fa-arrow-up-long"
                style={{ opacity: direction ? "100%" : "50%" }}
            />
        </div>
    );
};

const OWNED_BY_CATEGORIES = [
    {
        name: "Assigned Status",
        options: [
            { key: "All", value: "All", text: "All", all: true },
            { key: "Assigned", value: "Assigned", text: "Assigned" },
            { key: "Unassigned", value: "Unassigned", text: "Unassigned" },
        ],
    },
    {
        name: "Owned By",
        options: [
            { key: "Anyone", value: "Anyone", text: "Anyone", all: true },
            { key: "Myself", value: "Myself", text: "Myself" },
            { key: "My team", value: "My team", text: "My team" },
            { key: "My territory", value: "My territory", text: "My territory" }
        ]
    },
];

export const AccountsTableHeader = ({ onFilter, isColumnHidden }) => {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        userAuthTimezone,
        dateRange,
        setDateRange,
    } = useRangeCalendar(params);

    const createdOrder = params.get("order_by") === "created" && params.get("order") === "asc";
    const lastModifiedOrder = params.get("order_by") === "activity" && params.get("order") === "asc";

    const onDateRangeChange = (_, { value }) => {
        setDateRange(value);
    };

    const createdOrderFilter = () => {
        const query = new URLSearchParams(params);
        const order = !createdOrder ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", "created");
        const query_string = query.toString();
        navigate(`/accounts${query_string ? `?${query_string}` : ""}`);
    };

    const lastModifiedOrderFilter = () => {
        const query = new URLSearchParams(params);
        const order = !lastModifiedOrder ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", "activity");
        const query_string = query.toString();
        navigate(`/accounts${query_string ? `?${query_string}` : ""}`);
    };

    return (
        <Table.CollapsibleRow isHeader>
            {!isColumnHidden(KEY_CREATED_ON) && (
                <SemanticTable.HeaderCell className="AccountsTable__MediumFixedWidth" width={2}>
                    <span style={{ marginRight: 5 }}>Created</span>

                    <TimestampFilters
                        timestamp={timestamp}
                        onToggleTimeUnit={(timestamp) =>
                            onToggleTimeUnit(
                                timestamp,
                                setTimestamp,
                                params,
                                navigate,
                                "/accounts"
                            )
                        }
                        isCustomDateRangeOpen={isCustomDateRangeOpen}
                        onOpenCustomDateRange={() => {
                            setCustomDateRangeOpen(true);
                        }}
                        onCloseCustomDateRange={() => {
                            setCustomDateRangeOpen(false);
                        }}
                        dateRange={dateRange}
                        onDateRangeChange={onDateRangeChange}
                        getData={() =>
                            getData({
                                setCustomDateRangeOpen,
                                dateRange,
                                userAuthTimezone,
                                params,
                                navigate,
                                url: "/accounts",
                            })
                        }
                    />

                    <SortActions
                        direction={createdOrder}
                        onToggleSort={() => {
                            createdOrderFilter();
                        }}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CREATED_BY) && (
                <SemanticTable.HeaderCell className='AccountsTable__MediumFixedWidth' width={2}>Created By</SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED) && (
                <SemanticTable.HeaderCell className='AccountsTable__MediumFixedWidth' width={2}>
                    Last Modified
                    <SortActions
                        direction={lastModifiedOrder}
                        onToggleSort={() => {
                            lastModifiedOrderFilter();
                        }}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED_BY) && (
                <SemanticTable.HeaderCell className='AccountsTable__MediumFixedWidth' width={2}>
                    Last Modified By
                </SemanticTable.HeaderCell>
            )}
            <SemanticTable.HeaderCell className="AccountsTable__LargeFixedWidth" width={4}>
                Account Name
            </SemanticTable.HeaderCell>
            {!isColumnHidden(KEY_ACCOUNT_TYPE) && (
                <SemanticTable.HeaderCell className="AccountsTable__MediumFixedWidth" width={2}>Type</SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_OWNER) && (
                <SemanticTable.HeaderCell className="AccountsTable__MediumFixedWidth" width={2}>
                    <CategorizedCheckboxDropdown
                        text="Owned By"
                        name="owned_by"
                        categories={OWNED_BY_CATEGORIES}
                        onChange={(_, { value }) => {
                            onFilter(_, { name: "owned_by", value });
                        }}
                        value={params.get("owned_by")?.split(",")}
                        closeOnLoad={true}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_STAGE) && (
                <SemanticTable.HeaderCell className="AccountsTable__MediumFixedWidth" width={2}>Stage</SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CUSTOMER_SINCE) && (
                <SemanticTable.HeaderCell className="AccountsTable__MediumFixedWidth" width={2}>Customer Since</SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CANCELED_ON) && (
                <SemanticTable.HeaderCell className="AccountsTable__MediumFixedWidth" width={2}>Canceled On</SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CANCELED_BY) && (
                <SemanticTable.HeaderCell className="AccountsTable__MediumFixedWidth" width={2}>Canceled By</SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_REACTIVATED_ON) && (
                <SemanticTable.HeaderCell className="AccountsTable__MediumFixedWidth" width={2}>Reactivated On</SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_REACTIVATED_BY) && (
                <SemanticTable.HeaderCell className="AccountsTable__MediumFixedWidth" width={2}>Reactivated By</SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_ACCOUNT_STATUS) && (
                <SemanticTable.HeaderCell className="AccountsTable__LargeFixedWidth" width={2} style={{ textAlign: "center" }}>
                    <ClearableCheckboxDropdown
                        text="Account Status"
                        name="status"
                        options={[
                            { key: "active", value: "active", text: "Active" },
                            { key: "paused", value: "paused", text: "Paused" },
                            { key: "canceled", value: "canceled", text: "Canceled" }
                        ]}
                        onChange={(_, { value }) => {
                            onFilter(_, { name: "status", value });
                        }}
                        value={params.get("status")?.split(",")}
                        closeOnLoad={true}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_ACTIONS) && (
                <SemanticTable.HeaderCell className="AccountsTable__SmallFixedWidth" width={1}>
                    Actions
                </SemanticTable.HeaderCell>
            )}
        </Table.CollapsibleRow>
    );
};
