const DATA = [
    {
        key: "Default Template 1",
        value: `
            <p><strong>Meeting Objective</strong></p>
            <ul>
              <li></li>
            </ul> 
            <br>
            <p><strong>Discussion Items</strong></p>
            <ul>
                <li></li>
            </ul> 
            <br>
            <p><strong>Actions Items</strong></p>
            <ul>
                <li></li>
            </ul> 
            <p><br></p>
        `,
        text: "Default Template 1",
    },
    {
        key: "Default Template 2",
        value: `
            <p><strong>Meeting Purpose</strong></p>
            <ul>
              <li></li>
            </ul> 
            <br>
            <p><strong>Before the Meeting</strong></p>
            <ul>
                <li></li>
            </ul> 
            <br>
            <p><strong>Agenda</strong></p>
            <p><br></p>
        `,
        text: "Default Template 2",
    },
];

export default DATA;
