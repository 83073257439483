import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import Header from './subcomponents/Header';
import LeadConnectCallbacks from './subcomponents/LeadConnectCallbacks';
import LeadRoute from './subcomponents/LeadRoute';
import LocationDestination from './subcomponents/LocationDestination';

import './index.scss';

class LeadRouting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { loading } = this.state;

        return (
            <React.Fragment>
                <div className='LeadRouting'>
                    <Header />
                    <LeadRoute />
                    <LocationDestination />
                    <LeadConnectCallbacks />
                </div>
                <Dimmer active={loading} inverted page={true}>
                    <Loader inverted></Loader>
                </Dimmer>
            </React.Fragment>
        )
    }
}

export default LeadRouting;
