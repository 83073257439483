import React from 'react';
import { Header, Message } from 'semantic-ui-react';
import ErrorContainer from './ErrorContainer';

const ErrorContent = ({ error, message, height }) => (
    <ErrorContainer height={height}>
        <Header as='h1' color='grey' textAlign='center'>
            ERROR {error}
        </Header>
        <Message warning header={message}>
        </Message>
    </ErrorContainer>
);

export default ErrorContent;
