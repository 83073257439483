import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function moduleReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PIPELINE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingPipeline: true, error: false };
        case getNamedAction(ACTION_NAMES.PIPELINE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingPipeline: false, pipeline: action.data };
        case getNamedAction(ACTION_NAMES.PIPELINE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingPipeline: false, error: true };

        case getNamedAction(ACTION_NAMES.PIPELINE_TRANSFER, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, pipelineBeingMoved: action.id };
        case getNamedAction(ACTION_NAMES.PIPELINE_TRANSFER, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, pipelineBeingMoved: null };
        case getNamedAction(ACTION_NAMES.PIPELINE_TRANSFER, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, pipelineBeingMoved: null };

        case getNamedAction(ACTION_NAMES.PIPELINE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, pipelineBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.PIPELINE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, pipelineBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.PIPELINE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, pipelineBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.PIPELINE, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, pipelineBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.PIPELINE, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, pipelineBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.PIPELINE, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, pipelineBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.PIPELINE_TIMELINE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingPipelineHistory: true, error: false };
        case getNamedAction(ACTION_NAMES.PIPELINE_TIMELINE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingPipelineHistory: false, pipelineHistory: action.data };
        case getNamedAction(ACTION_NAMES.PIPELINE_TIMELINE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingPipelineHistory: false, error: true };

        default:
            return state;
    }
}

export default moduleReducer;
