let requestLogName = "DYL_request";
let responseLogName = "DYL_response";

//TODO: maxSize of 50 will blow up logger when downloading multiple attachments---- localStorage.clear() is used as a hack to clear logger.
//Located
//src/shared/EmailTab/ThreadItem/index.js


class Logger {
  constructor(config) {
    this.name = config.name;
    this.maxSize = config.maxSize;
    this.keyValue = `${config.name}_log`;
  }

  get messages() {
    return this.getMessages();
  }
  // append a general purpose message
  append(message) {
    let messages = JSON.parse(localStorage.getItem(this.keyValue) || "[]");
    message.unix_time = Date.now();
    messages.push(message);
    while (messages.length > this.maxSize) {
      messages.shift();
    }
    localStorage.setItem(this.keyValue, JSON.stringify(messages));
  }

  clear() {
    localStorage.setItem();
  }

  getMessages() {
    let messages = JSON.parse(localStorage.getItem(this.keyValue) || "[]");
    return messages;
  }
}

let reqLog = new Logger({ name: requestLogName, maxSize: 50 });
let resLog = new Logger({ name: responseLogName, maxSize: 50 });
let log = new Logger({ name: "DYL_errors", maxSize: 100 });

window.getReqLog = () => {
  return reqLog.messages;
};

window.getResLog = () => {
  return resLog.messages;
};

window.getLog = () => {
  return log.messages;
};

export { reqLog, resLog, log };
