import { Header } from "semantic-ui-react";
import PaymentFrequency from "./PaymentFrequency";
import { StringUtils } from "utils";
import VolumeBasedPricingTiers from "./VolumeBasedPricingTiers";

const VolumeBasedRecurringFields = () => {
    return ["monthly", "quarterly", "semi-annually", "annually"].map(
        (frequency) => {
            const parentName = `volume_based_recurring`;
            return (
                <PaymentFrequency
                    parentName={parentName}
                    frequency={frequency}
                    key={frequency}
                    content={
                        <div>
                            <Header color="primary">
                                {StringUtils.capitalize(frequency)}
                            </Header>
                            <VolumeBasedPricingTiers 
                                parentName={`price_data.${parentName}_${frequency}_volume_prices`}
                            />
                        </div>
                    }
                />
            );
        }
    );
};

export default VolumeBasedRecurringFields;
