import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Grid, Header, Menu, Segment } from 'semantic-ui-react';

import './index.scss';
import usersActions from 'actions/users';

const ModulesTab = ({
    defaultColumnsDisplayed,
    allowedColumns,
    readModules,
    additionalColumnParameters = [],

    moduleName,
    moduleType,

    TabFilterComponent,
    TableComponent,

    columnDisplayOptions
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const { contact_id, account_id } = useParams();

    const routeRoot = account_id ? `/account/${account_id}/${moduleType}` : `/contact/${contact_id}/${moduleType}`;

    const columnsParameter = params.get('columns')?.split(',')?.filter(column => columnDisplayOptions.includes(column)) || [];
    const displayedColumns = columnsParameter.length ? columnsParameter : defaultColumnsDisplayed;

    const location = useLocation();

    useEffect(() => {
        if (!location.state?.isTogglingPanel) {
            dispatch(usersActions.readUsers());

            const columns = [...displayedColumns.filter(column =>
                allowedColumns.includes(column)
            ), ...additionalColumnParameters].join(',');

            const queryParameters = {
                page: 1,
                limit: 25,
                ...Object.fromEntries(params),
                columns
            }

            dispatch(readModules(contact_id || account_id, queryParameters));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const isColumnHidden = (column) => {
        return !displayedColumns.includes(column);
    }

    const onToggleColumn = async (column) => {
        const updatedColumns = isColumnHidden(column) ? [...displayedColumns, column] : displayedColumns.filter(visibleColumn => visibleColumn !== column);

        const updateColumnsParameter = () => {
            const query = new URLSearchParams(params);
            if (updatedColumns.length) {
                query.set('columns', updatedColumns.join(','));
            } else {
                query.delete('columns');
            }
            const query_string = query.toString();
            navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
        }

        if (!isColumnHidden(column)) {
            switch (column) {
                case "Created":
                    if (params.get('start') || params.get('end') || params.get('order_by') === 'created') {
                        const query = new URLSearchParams(params);
                        query.delete('start');
                        query.delete('end');
                        query.delete('order_by');
                        query.delete('order');
                        const query_string = query.toString();
                        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
                    } else {
                        updateColumnsParameter();
                    }
                    break;
                case "Last Modified":
                    if (params.get('order_by') === 'activity') {
                        const query = new URLSearchParams(params);
                        query.delete('order_by');
                        query.delete('order');
                        const query_string = query.toString();
                        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
                    } else {
                        updateColumnsParameter();
                    }
                    break;
                default:
                    updateColumnsParameter();
            }
        } else {
            updateColumnsParameter();
        }
    }

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    }

    const activeItem = params.get('filter');

    return (
        <Grid className='ModulesTab'>
            <Grid.Row>
                <Grid.Column>
                    <Header>
                        {moduleName}
                    </Header>
                </Grid.Column>
                <Grid.Column width={8} floated='right' textAlign='right'>
                    <TabFilterComponent
                        isColumnHidden={isColumnHidden}
                        onToggleColumn={onToggleColumn}
                        routeRoot={routeRoot}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={'equal'}>
                <Grid.Column>
                    <Segment className='ModulesTab__segment' color='blue'>
                        <Menu className='ModulesTab__menu' pointing secondary color='primary'>
                            <Menu.Item
                                name='all'
                                active={!activeItem}
                                as={Link}
                                to={routeRoot}
                            />
                            <Menu.Item
                                name='open'
                                active={activeItem === 'open'}
                                as={Link}
                                to={`${routeRoot}?filter=open`}
                            />
                            <Menu.Item
                                name='won'
                                active={activeItem === 'won'}
                                as={Link}
                                to={`${routeRoot}?filter=won`}
                            />
                            <Menu.Item
                                name='lost'
                                active={activeItem === 'lost'}
                                as={Link}
                                to={`${routeRoot}?filter=lost`}
                            />
                        </Menu>
                        <TableComponent
                            isColumnHidden={isColumnHidden}
                            onPageChange={onPageChange}
                            routeRoot={routeRoot}
                        />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default ModulesTab;
