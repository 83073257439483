import React from 'react';
import { Popup, Grid } from 'semantic-ui-react';
import { MessagingIcon } from 'dyl-components';

export const AgentControls = ({ agent }) => (
    <Grid columns={4}>
        <Grid.Column>
            <Popup
                trigger={<span><MessagingIcon icon={'phone'} /></span>}
                content={`Call ${agent.name || agent.extension}`}
                inverted
                position='top center'
            />
        </Grid.Column>
        <Grid.Column>
            <Popup
                trigger={<span><MessagingIcon icon={'mail'} /></span>}
                content={`Email ${agent.name}`}
                disabled={!agent.name}
                inverted
                position='top center'
            />
        </Grid.Column>
        <Grid.Column>
            <Popup
                trigger={<span><MessagingIcon icon={'conversation'} /></span>}
                content={`Chat with ${agent.name}`}
                inverted
                position='top center'
                disabled={!agent.name}
            />
        </Grid.Column>
        <Grid.Column>
            <Popup
                trigger={<span><MessagingIcon icon={'assistive listening devices'} /></span>}
                content={`Listen in on ${agent.name || agent.extension}`}
                inverted
                position='top center'
            />
        </Grid.Column>
    </Grid>
)
