import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function taskReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.TASK, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingTask: true };
        case getNamedAction(ACTION_NAMES.TASK, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingTask: false };
        case getNamedAction(ACTION_NAMES.TASK, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingTask: false };

        case getNamedAction(ACTION_NAMES.TASK, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingTask: true };
        case getNamedAction(ACTION_NAMES.TASK, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingTask: false, task: action.data };
        case getNamedAction(ACTION_NAMES.TASK, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingTask: false };

        case getNamedAction(ACTION_NAMES.TASK, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingTask: true, taskBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.TASK, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingTask: false, taskBeingUpdated: 0 };
        case getNamedAction(ACTION_NAMES.TASK, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingTask: false, taskBeingUpdated: 0 };

        case getNamedAction(ACTION_NAMES.TASK_COMPLETION, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingTask: true, taskBeingCompleted: action.id };
        case getNamedAction(ACTION_NAMES.TASK_COMPLETION, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingTask: false, taskBeingCompleted: 0 };
        case getNamedAction(ACTION_NAMES.TASK_COMPLETION, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingTask: false, taskBeingCompleted: 0 };

        case getNamedAction(ACTION_NAMES.TASK_LABEL, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingTaskLabel: true }
        case getNamedAction(ACTION_NAMES.TASK_LABEL, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingTaskLabel: false }
        case getNamedAction(ACTION_NAMES.TASK_LABEL, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingTaskLabel: false }

        case getNamedAction(ACTION_NAMES.TASK_LABEL, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingTaskLabel: true }
        case getNamedAction(ACTION_NAMES.TASK_LABEL, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingTaskLabel: false }
        case getNamedAction(ACTION_NAMES.TASK_LABEL, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingTaskLabel: false }

        case getNamedAction(ACTION_NAMES.TASK_LABEL, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingTaskTypeLabels: true }
        case getNamedAction(ACTION_NAMES.TASK_LABEL, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingTaskTypeLabels: false, task_type_labels: action.data.task_labels }
        case getNamedAction(ACTION_NAMES.TASK_LABEL, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingTaskTypeLabels: false }

        case getNamedAction(ACTION_NAMES.CHECK_TASK_LABEL_DUPLICATE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isCheckingTaskLabelDuplicate: true }
        case getNamedAction(ACTION_NAMES.CHECK_TASK_LABEL_DUPLICATE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isCheckingTaskLabelDuplicate: false }
        case getNamedAction(ACTION_NAMES.CHECK_TASK_LABEL_DUPLICATE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isCheckingTaskLabelDuplicate: false }

        default:
            return state;
    }
}


export default taskReducer;
