import React from 'react';
import { Icon, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ClippedContent } from 'dyl-components';

const taskFormat = (name, taskName, contact_id) => {
    return <>
       <div style={{display: 'block'}}><Header as='h5'>{name} Task Note</Header></div>
       <Link to={`/contact/${contact_id}/tasks`} ><ClippedContent><Icon className="fa-solid fa-clipboard-check" /> {taskName} </ClippedContent></Link> 
    </>
}

const noteType = (type, taskName="N/A", contact_id) => {
    let noteType;
    switch (type){
        case "Call":
            noteType = taskFormat("Call", taskName, contact_id);
            break;
        case "Email":
            noteType = taskFormat("Email", taskName, contact_id);
            break;
        case "Text":
            noteType = taskFormat("Text", taskName, contact_id);
            break;
        case "To-Do":
            noteType = taskFormat("To Do", taskName, contact_id);
            break;
        case "log_call":
            noteType =  <><Header as='h5'><Icon className="fas fa-phone" size='small' style={{marginRight: 0}} /> Call Activity Note</Header></>;
            break;
        case "log_email":
            noteType =  <><Header as='h5'><Icon className="fas fa-envelope" size='small' style={{marginRight: 0}} /> Email Activity Note</Header></>;
            break;
        case "log_text":
            noteType =  <><Header as='h5'><Icon className="fas fa-comment" size='small' style={{marginRight: 0}} /> Text Activity Note</Header></>;
            break;
        case "general":
            noteType = <><Header as='h5'>General Note</Header></>;
            break;
        default:
            noteType = <><Header as='h5'>General Note</Header></>;
    }
    return noteType;
}

export default noteType;