import { yup } from "dyl-components";
import applyCurrencySchema from "./applyCurrencySchema";

const getFixedRecurringPricingFrequencySchema = (frequency) => ({
    [`fixed_recurring_${frequency}_price`]: yup.lazy((value) =>
        value === ""
            ? yup
                  .string()
                  .when(["model", "schedule", "fixed_recurring_frequencies"], {
                      is: (model, schedule, frequencies) => {
                          const active = frequencies?.includes(frequency);
                          return (
                              model === "fixed" &&
                              schedule === "recurring" &&
                              active
                          );
                      },
                      then: (schema) =>
                          schema.required("This field is required"),
                  })
            : yup
                  .number()
                  .typeError("Please enter a valid price")
                  .when(["model", "schedule", "fixed_recurring_frequencies"], {
                      is: (model, schedule, frequencies) => {
                          const active = frequencies?.includes(frequency);
                          return (
                              model === "fixed" &&
                              schedule === "recurring" &&
                              active
                          );
                      },
                      then: (schema) =>
                          applyCurrencySchema(schema).required(
                              "This field is required"
                          ),
                      otherwise: (schema) => schema.nullable(true),
                  })
    ),
});

const fixedRecurringProductSchema = {
    fixed_recurring_frequencies: yup.array().when(["model", "schedule"], {
        is: (model, schedule) => model === "fixed" && schedule === "recurring",
        then: (schema) =>
            schema
                .of(yup.string())
                .min(1, "At least one pricing frequency should be checked")
                .required("At least one pricing frequency should be checked"),
        otherwise: (schema) => schema.nullable(true),
    }),
    ...getFixedRecurringPricingFrequencySchema("monthly"),
    ...getFixedRecurringPricingFrequencySchema("quarterly"),
    ...getFixedRecurringPricingFrequencySchema("semi-annually"),
    ...getFixedRecurringPricingFrequencySchema("annually"),
};

const paymentFrequencies = [
    "monthly",
    "quarterly",
    "semi-annually",
    "annually",
];

export function formatRecurringPriceData(price_data) {
    const frequencies = price_data.fixed_recurring_frequencies;
    return {
        price: paymentFrequencies.reduce((data, frequency) => {
            const root = `fixed_recurring_${frequency}`;
            const price = Number(price_data[`${root}_price`]);
            return {
                ...data,
                ...(price && !isNaN(price)
                    ? {
                          [frequency]: {
                            active: frequencies?.includes(frequency),
                            price
                          },
                      }
                    : {}),
            };
        }, {}),
    };
}

export function transformRecurringPricingDetails(price_data) {
    return {
        fixed_recurring_frequencies: paymentFrequencies.filter(
            (paymentFrequency) => price_data.price[paymentFrequency]?.active
        ),
        ...paymentFrequencies.reduce((data, frequency) => {
            const price = Number(price_data.price[frequency]?.price);
            return {
                ...data,
                ...(price && !isNaN(price)
                    ? {
                          [`fixed_recurring_${frequency}_price`]: price,
                      }
                    : {}),
            };
        }, {}),
    };
}

export default fixedRecurringProductSchema;
