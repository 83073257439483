import React from 'react';
import { Table, TableLoader } from 'dyl-components';
import { Table as SemanticTable } from 'semantic-ui-react';
import { ReportsAndLogsTableHeader } from './Header';
import { ReportsAndLogsTableRow } from './Row';

export const ReportsAndLogsTable = ({ isReadingPosts, posts, count, onFilter, filters, onSort, onTimeFilter, dateSelected, providerNames, recordNames, isReadingProviderRecordNames }) => (
    <Table>
        <SemanticTable.Header>
            <ReportsAndLogsTableHeader
                dateSelected={dateSelected}
                count={count}
                onFilter={onFilter}
                isReadingPosts={isReadingPosts}
                filters={filters}
                onSort={onSort}
                onTimeFilter={onTimeFilter}
                providerNames={providerNames}
                recordNames={recordNames}
                isReadingProviderRecordNames={isReadingProviderRecordNames}
            />
        </SemanticTable.Header>
        <Table.Body>
            {
                isReadingPosts
                    ? <TableLoader isLoading colspan={6} />
                    : count > 0 && (posts.map(report => <ReportsAndLogsTableRow key={report.id} report={report} />))
            }
        </Table.Body>
    </Table>
)
