import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Segment, Select } from "semantic-ui-react";
import Navigation from "./Navigation";
import DataCustomizationParentGroup from 'pages/DataCustomizationParentGroup';
import customFieldsGroupsActions from "actions/custom_fields_groups";

import "./index.scss";

const DataManagement = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { isReadingParentGroups, parentGroups } = useSelector((state) => state.custom_fields_groups);
    const [groupName, setGroupName] = useState('')

    const isNotAnAccountGroup = (group) => {
        return group.group_name !== 'household_account' && group.group_name !== 'business_account';
    }

    const [activeAccountType, setActiveAccountType] = useState('business_account');

    useEffect(() => {
        const currentGroupName = location.pathname.split('/').slice(-1)[0];
        const correctedName = (() => {
            if (currentGroupName !== 'account') {
                return (currentGroupName === 'contact') ? 'person' : currentGroupName
            }
            return activeAccountType;
        })();
        setGroupName(correctedName);
    }, [location, activeAccountType]);

    const getAdditionalControls = () => {
        if (!groupName.includes('account')) {
            return null;
        }
        return (
            <Select 
                options={[
                    {
                        key: 'business_account', value: 'business_account', text: 'Business Account'
                    },
                    {
                        key: 'household_account', value: 'household_account', text: 'Household Account'
                    }
                ]}
                onChange={(_, { value }) => {setActiveAccountType(value)}}
                value={activeAccountType}
            />
        )
    }

    useEffect(() => {
        dispatch(customFieldsGroupsActions.readCustomFieldsParentGroups());
    }, [dispatch, activeAccountType]);

    return (
        <React.Fragment>
            <Navigation links={parentGroups.filter(isNotAnAccountGroup)} />
            
            { !isReadingParentGroups ?
                <DataCustomizationParentGroup
                    group_name={groupName}
                    additionalControls={getAdditionalControls()}
                />
                :
                <Segment loading basic />
            }
        </React.Fragment>
    );
};

export default DataManagement;
