import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function productsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PRODUCT_CATALOG, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCatalog: true, catalog: [], count: 0 };
        case getNamedAction(ACTION_NAMES.PRODUCT_CATALOG, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCatalog: false, catalog: action.data.data || [], count: action.data.count || 0 };
        case getNamedAction(ACTION_NAMES.PRODUCT_CATALOG, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCatalog: false, catalog: [], count: 0 };

        case getNamedAction(ACTION_NAMES.PRODUCT_CATALOG, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, summaries: { ...state.summaries, [action.id]: { loading: true, payload: null } } };
        case getNamedAction(ACTION_NAMES.PRODUCT_CATALOG, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, summaries: { ...state.summaries, [action.id]: { loading: false, payload: action.data } } };
        case getNamedAction(ACTION_NAMES.PRODUCT_CATALOG, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, summaries: { ...state.summaries, [action.id]: { loading: false, payload: null } } };

        default:
            return state;
    }
}


export default productsReducer;
