import { VALIDATORS, yup } from "dyl-components";
import { ValidationUtils } from "utils";

export const applyPhonesSchema = schema => {
    return schema.when("emails", {
        is: emails => {
            return emails?.some((email) => Boolean(email.value)) === true;
        },
        then: schema => schema.of(yup.object().shape({
            value: VALIDATORS.PHONE_NUMBER()
        })).test('no_repeating_phone', "Phone already in the list", ValidationUtils.checkForDuplicatePhonesInTheList),
        otherwise: schema => schema.of(yup.object().shape({
            value: VALIDATORS.PHONE_NUMBER().required('This field is required')
        })).test('no_repeating_phone', "Phone already in the list", ValidationUtils.checkForDuplicatePhonesInTheList).min(1, 'Should have at least one phone'),
    })
}

export const applyEmailsSchema = schema => {
    return schema.when("phones", {
        is: phones => {
            return phones?.some((phone) => Boolean(phone.value)) === true;
        },
        then: schema => schema.of(yup.object().shape({
            value: VALIDATORS.EMAIL_ADDRESS().maxlength(256)
        })).test('no_repeating_email', "Email already in the list", ValidationUtils.checkForDuplicateEmailsInTheList),
        otherwise: schema => schema.of(yup.object().shape({
            value: VALIDATORS.EMAIL_ADDRESS().maxlength(256).required('This field is required')
        })).test('no_repeating_email', "Email already in the list", ValidationUtils.checkForDuplicateEmailsInTheList).min(1, 'Should have at least one email')
    })
}

export const applyEmailUniquenessAcrossContacts = schema => {
    return schema.test('unique_email_to_add', "Another contact already has this email", function (email) {
        if (!email?.trim()) {
            return true;
        }
        const [, , grandGrandParent] = this.from;
        const parentPath = this.path.split('.')[0];
        const contactIndex = Number(parentPath.slice(-2, -1));
        const emailsOfOtherContacts = grandGrandParent.value.addedContacts.filter(((contact, index) => contact.new && index !== contactIndex)).map(contact => contact.emails);
        const hasDuplicates = emailsOfOtherContacts.find(emailsOfOtherContact => {
            return emailsOfOtherContact.findIndex(emailOfOtherContact => emailOfOtherContact.value?.trim() === email?.trim()) !== -1;
        });
        return !hasDuplicates || this.createError({
            path: this.path,
            message: "Another contact already has this email",
        });
    })
}

export const commsSchema = {
    phones: applyPhonesSchema(yup.array()),
    emails: applyEmailsSchema(yup.array())
}
