import React from 'react';
import { Table } from 'dyl-components';

import UserChatRoomsRow from './UserChatRoomsRow';

const UsersChatRoomsTable = ({ usersChatRooms, isEditMode, onModifyChatRooms }) => (
    <Table celled>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>User</Table.HeaderCell>
                <Table.HeaderCell>Chat Rooms</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {usersChatRooms.map(userChatRooms => (
                <UserChatRoomsRow
                    userChatRooms={userChatRooms} 
                    onModifyChatRooms={onModifyChatRooms} 
                    key={userChatRooms.user.id} 
                    isEditMode={isEditMode} 
                />
            ))}
        </Table.Body>
    </Table>
);

export default UsersChatRoomsTable;
