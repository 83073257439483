import React from 'react';
import { Table, ClearableCheckboxDropdown } from 'dyl-components';
import { Table as SemanticTable } from 'semantic-ui-react';

const UserAccountsTableHeader = ({ teams, selectedTeams, roles, selectedRoles, onChangeFilters, statuses, selectedStatuses }) => (
    <Table.Row>
        <SemanticTable.HeaderCell width={4}>Name</SemanticTable.HeaderCell>
        <SemanticTable.HeaderCell width={4}>Email</SemanticTable.HeaderCell>
        <SemanticTable.HeaderCell width={2}>
            <ClearableCheckboxDropdown
                text='Team(s)'
                name='selectedTeams'
                onChange={onChangeFilters}
                value={selectedTeams}
                options={teams}
            />
        </SemanticTable.HeaderCell>
        <SemanticTable.HeaderCell width={2}>
            <ClearableCheckboxDropdown
                text='Permission Profile'
                name='selectedRoles'
                options={roles}
                onChange={onChangeFilters}
                value={selectedRoles}
            />
        </SemanticTable.HeaderCell>
        {/* TODO: Map properties when they are ready
            <Table.HeaderCell>Line</Table.HeaderCell>
            <Table.HeaderCell>Caller ID</Table.HeaderCell> 
        */}
        <SemanticTable.HeaderCell width={2}>
            <ClearableCheckboxDropdown
                text='Status'
                name='selectedStatuses'
                options={statuses}
                onChange={onChangeFilters}
                value={selectedStatuses}
            />
        </SemanticTable.HeaderCell>
        <SemanticTable.HeaderCell width={2}>Actions</SemanticTable.HeaderCell>
    </Table.Row>
);

export default UserAccountsTableHeader;
