const initialState = {
    isEnablingIntegration: false,
    isReadingIntegrations: false,
    integrationBeingDeleted: null,
    integrationBeingUpdated: null,
    isUpdatingPrimaryIntegration: false,
    providers: [],
    integrations: [],
    activeIntegration: false,
    isReactivatingAnIntegration: false
};

export default initialState;
