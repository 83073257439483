import React from 'react';
import { Form } from 'semantic-ui-react';
import { FilterComponent, DatesRangeInput } from 'dyl-components';
import TopMarginedFilterButton from 'shared/TopMarginedFilterButton';

const timeframeOptions = [
    {key: 'any', value: 'any', text: 'Any'},
    {key: 'specify', value: 'specify', text: 'Specify'},
    {key: 'today', value: 'today', text: 'Today'},
    {key: 'yesterday', value: 'yesterday', text: 'Yesterday'},
    {key: 'this_week', value: 'this_week', text: 'This Week'},
    {key: 'last_week', value: 'last_week', text: 'Last Week'},
    {key: 'this_month', value: 'this_month', text: 'This Month'},
    {key: 'last_month', value: 'last_month', text: 'Last Month'},
    {key: 'year_to_date', value: 'year_to_date', text: 'Year to Date'},
    {key: 'last_year', value: 'last_year', text: 'Last Year'},
];

const CustomFilter = ({ name, filters, label, onChange, value }) => {
    let options = filters.map(filter => ({
        key: filter,
        value: filter,
        text: filter
    }))

    options.sort((a, b) => a.key > b.key ? 1 : -1);
    options.unshift({key: 'any', value: 'any', text: 'Any'});
    
    return (
        <Form.Select name={name} options={options} fluid label={label} onChange={onChange} value={value} />
    )
}

const eventOptions = [
    'Add Customer',
    'Update Customer',
    'Customer Lookup',
    'Add Note',
    'Add Activity',
    'Call Popup'
]

const crmOptions = [
    'Vertafore'
]

export const CRMActivityFilter = ({ 
        dateRange, 
        event,
        timeframe,
        onInputChange,
        onSubmit,
        result
    }) => (
    <FilterComponent>
        <Form>
            <Form.Group widths='equal'>
                <Form.Select name={'timeframe'} onChange={onInputChange} options={timeframeOptions} fluid label='Browse by Timeframe' value={timeframe || 'any'} />
                <CustomFilter name={'event'} onChange={onInputChange} filters={eventOptions} label={'Event'} value={event || 'any'}/>
                <CustomFilter name={'crm'} onChange={onInputChange} filters={crmOptions} label={'CRM'} value={event || 'any'}/>
            </Form.Group>
            <Form.Group widths='equal' className='FilterComponent__action-buttons'>
                <Form.Field label='Browse by Date' 
                    control={DatesRangeInput} 
                    placeholder={"From - To"}
                    iconPosition="right"
                    value={dateRange}
                    popupPosition={'bottom center'}
                    onChange={onInputChange}
                    name="dateRange"
                />
                <Form.Field className='FilterComponent__radio-buttons'>
                    <label>Result</label>
                    <Form.Group inline>
                        <Form.Radio label='Both' value='both' checked={result === 'both'} name='result' onChange={onInputChange}  />
                        <Form.Radio label='Successful' value='successful' checked={result === 'successful'} name='result' onChange={onInputChange} />
                        <Form.Radio label='Failed' value='failed' checked={result === 'failed'} name='result' onChange={onInputChange} />
                    </Form.Group>
                </Form.Field>
                <TopMarginedFilterButton onSubmit={onSubmit} />
            </Form.Group>
        </Form>
    </FilterComponent>
)

export default class CRMActivityFilterContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRange: '',
            result: null,
            event: null,
            crm: null,
            timeframe: null
        }
        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onInputChange(_, {name, value}) {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    }

    onSubmit() {
        if (this.props.onFilterLeads) {
            this.props.onFilterLeads({...this.state});
        }
    }

    render() {
        return (
            <CRMActivityFilter 
                {...this.props}
                onInputChange={this.onInputChange}
                onSubmit={this.onSubmit}

                {...this.state}
            />
        )
    }
}
