import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ModulesFilter } from 'dyl-components';

import {
    KEY_CREATED, KEY_LAST_MODIFIED, KEY_OUTCOME, KEY_CAMPAIGN_SOURCE, KEY_CREATED_BY, KEY_LOST_REASON,
    KEY_CLOSE_PROBABILITY, KEY_LEAD_TYPE, KEY_TOTAL_COST, KEY_LAST_MODIFIED_BY,
    KEY_PRODUCT_INTEREST, KEY_LEAD_PRIORITY, KEY_LEAD_ID, KEY_LEAD_SCORE,
    KEY_LEAD_RATING, KEY_MASTER_SECONDARY, KEY_EXPECTED_REVENUE, KEY_STAGE, KEY_ACQUISITION_COST, KEY_ADDITIONAL_COST, KEY_CONTACT_NAME
} from 'shared/constants/MODULE_COLUMNS';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

const LeadsTabFilter = ({ isColumnHidden, onToggleColumn, routeRoot }) => {
    const { isReadingLeads } = useSelector(state => ({
        isReadingLeads: state.leads_tab.isReadingLeads
    }));

    const [params] = useSearchParams();
    const { account_id } = useParams();
    const navigate = useNavigate();

    const COLUMN_ROWS = !account_id ? [
        [
            { value: KEY_CREATED, label: "Created" },
            { value: KEY_OUTCOME, label: "Outcome" },
            { value: KEY_MASTER_SECONDARY, label: "Master/Secondary" }
        ],
        [
            { value: KEY_CREATED_BY, label: "Created by" },
            { value: KEY_LOST_REASON, label: "Lost Reason/Type" },
            { value: KEY_CAMPAIGN_SOURCE, label: "Campaign Converted" },

        ],
        [
            { value: KEY_LAST_MODIFIED, label: "Last Modified" },
            { value: KEY_LEAD_TYPE, label: "Type" },
            { value: KEY_CLOSE_PROBABILITY, label: "Close Probability" }
        ],
        [
            { value: KEY_LAST_MODIFIED_BY, label: "Last Modified By" },
            { value: KEY_PRODUCT_INTEREST, label: "Product Interests" },
            { value: KEY_TOTAL_COST, label: "Total Cost" }
        ],
        [
            { value: KEY_LEAD_PRIORITY, label: "Priority" },
            { value: KEY_EXPECTED_REVENUE, label: "Expected Revenue" },
            { value: KEY_ACQUISITION_COST, label: "Acquisition Cost" }
        ],
        [
            { value: KEY_LEAD_SCORE, label: "Score" },
            { value: KEY_ADDITIONAL_COST, label: "Additional Cost" },
            { value: KEY_LEAD_ID, label: "ID", isForcedChecked: true },
        ],
        [
            { value: KEY_LEAD_RATING, label: "Rating" },
            { value: KEY_STAGE, label: "Pipeline/Stage" }
        ]
    ] : [
        [
            { value: KEY_CREATED, label: "Created" },
            { value: KEY_OUTCOME, label: "Outcome" }
        ],
        [
            { value: KEY_CREATED_BY, label: "Created by" },
            { value: KEY_LOST_REASON, label: "Lost Reason/Type" },
            { value: KEY_CAMPAIGN_SOURCE, label: "Campaign Source" }
        ],
        [
            { value: KEY_LAST_MODIFIED, label: "Last Modified" },
            { value: KEY_LEAD_TYPE, label: "Type" },
            { value: KEY_CLOSE_PROBABILITY, label: "Close Probability" }
        ],
        [
            { value: KEY_LAST_MODIFIED_BY, label: "Last Modified By" },
            { value: KEY_PRODUCT_INTEREST, label: "Product Interests" },
            { value: KEY_TOTAL_COST, label: "Total Cost" }
        ],
        [
            { value: KEY_CONTACT_NAME, label: "Contacts", isForcedChecked: true },
            { value: KEY_LEAD_PRIORITY, label: "Priority" },
            { value: KEY_EXPECTED_REVENUE, label: "Expected Revenue" }
        ],
        [
            { value: KEY_LEAD_ID, label: "ID", isForcedChecked: true },
            { value: KEY_LEAD_SCORE, label: "Score" },
        ],
        [
            { value: KEY_STAGE, label: "Pipeline/Stage" },
            { value: KEY_LEAD_RATING, label: "Rating" }
        ]
    ];

    const pipelineIdQuery = params.get('pipeline_id') || '';
    const [pipelineId, setPipelineId] = useState(pipelineIdQuery);

    const onChangeSearch = (_, { value }) => {
        setPipelineId(value);
    }

    const onSearchSubmit = (value) => {
        setPipelineId(value);
        const query = new URLSearchParams(params);
        query.set('pipeline_id', pipelineId.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    }

    const cancelFunction = () => {
        setPipelineId("");
        const query = new URLSearchParams(params);
        query.delete('pipeline_id');
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`,);
    }

    return (
        <ModulesFilter
            isLoading={isReadingLeads}
            hasSearchBar={account_id ? true : false}
            columnRows={COLUMN_ROWS}
            columnCount={3}
            isColumnHidden={isColumnHidden}
            onToggleColumn={onToggleColumn}
            search={pipelineIdQuery}
            onSearchSubmit={onSearchSubmit}
            cancelFunction={cancelFunction}
            onChangeSearch={onChangeSearch}
            searchBarPlaceholder={'Search by Lead ID'}
        />
    )
}

export default LeadsTabFilter;
