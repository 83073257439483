import React from 'react'; 
import { Button, Icon } from 'semantic-ui-react';
import { SearchBar } from 'dyl-components';
import '../index.scss';

const Toolbar = ({ search, onSearchSubmit, cancelFunction, onChangeSearch, onOpenModal, isReadingDestinationGroups }) => {
    return (
        <div className='DestinationGroupsToolbar'>
            <div style={{flex: 1}}>
                <SearchBar
                    isSearching={isReadingDestinationGroups}
                    search={search}
                    searchFcn={onSearchSubmit}
                    cancelFcn={cancelFunction}
                    placeholder='Search by group name'
                    addClassNames='DestinationGroups__search'
                    onChange={onChangeSearch}
                />
            </div>
            <div>
                <Button className='DestinationGroupsToolbar__button' onClick={onOpenModal}>
                    <Icon name='plus' /> Destination Group
                </Button>
            </div>
        </div>
    )
}

export default Toolbar;
