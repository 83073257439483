import React from 'react';
import { Grid, Button, Dropdown, Icon, Input } from 'semantic-ui-react';
import { MOCK_ROUTE_OPTIONS } from '__mocks__/mockData';
import { EditableContent } from 'dyl-components';

const CustomRouteHeader = ({ name, afterHours, onChangeName, onDisableEditMode, isEdittingName, onEditName, onUpdateName, onAddTimeframe, onUpdateAfterHours }) => (
    <Grid verticalAlign='middle'>
        <Grid.Column width={isEdittingName ? 5 : 2}>
            <EditableContent 
                control={Input}
                value={name}
                onChange={onChangeName}
                isEditMode={isEdittingName}
                transparent
            />
            &nbsp;
            { isEdittingName && (
                <Icon 
                    link 
                    name={'times'} 
                    inverted
                    onClick={onDisableEditMode} 
                />
            ) }
            <Icon 
                link 
                inverted
                name={isEdittingName ? 'check' : 'pencil'} 
                onClick={isEdittingName ? onUpdateName : onEditName} 
            />
        </Grid.Column>
        <Grid.Column width={6}>
            <Dropdown icon={''} trigger={<span><Icon name='list' />After Hours</span>}
                options={MOCK_ROUTE_OPTIONS}
                onChange={(_, { value }) => {onUpdateAfterHours(value)}}
                value={afterHours}
            />
            Selected: {MOCK_ROUTE_OPTIONS.filter(option => option.key === afterHours)[0].text}
        </Grid.Column>
        <Grid.Column width={4} textAlign='right' floated='right'>
        <Button onClick={() => {onAddTimeframe()}} size='tiny' basic inverted color='white'>Add Timeframe</Button>
        </Grid.Column>
    </Grid>
)

class CustomRouteHeaderContainer extends React.Component {
    state = {
        name: this.props.name,
        isEdittingName: false
    }

    onEditName = () => {
        this.setState({ isEdittingName: true });
    }

    onDisableEditMode = () => {
        this.setState({ isEdittingName: false });
    }

    onChangeName = (_, { value }) => {
        this.setState({ name: value });
    }

    onUpdateName = () => {
        this.setState({ isEdittingName: false }, () => {
            this.props.onUpdateName(this.state.name);
        });
    }

    onUpdateAfterHours = (newAfterHours) => {
        this.props.onUpdateAfterHours(newAfterHours);
    }

    render() {
        return (
            <CustomRouteHeader 
                {...this.props}
                {...this.state}
                onUpdateName={this.onUpdateName}
                onEditName={this.onEditName}
                onDisableEditMode={this.onDisableEditMode}
                onChangeName={this.onChangeName}
                onUpdateAfterHours={this.onUpdateAfterHours}
            />
        )
    }
}

export default CustomRouteHeaderContainer;
