import routes from 'actions/routes';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import { generateReadActionCreator, generateCreateActionCreator, generateUpdateActionCreator } from 'actions/crudActionCreators';

const readTasks = generateReadActionCreator(routes.API_TASKS, ACTION_NAMES.TASKS);
const createTask = generateCreateActionCreator(routes.API_TASKS, ACTION_NAMES.TASKS);
const readTaskTypes = generateReadActionCreator(routes.API_TASK_TYPES, ACTION_NAMES.TASK_TYPES);
const addTaskLabels = generateCreateActionCreator(routes.API_TASK_LABELS, ACTION_NAMES.TASK_LABELS);
const readTaskLabels = generateReadActionCreator(routes.API_TASK_LABELS, ACTION_NAMES.TASK_LABELS);
const updateTasks = generateUpdateActionCreator(routes.API_TASKS, ACTION_NAMES.UPDATE_TASKS, () => { }, () => { }, 'update_tasks');

export default {
  readTasks,
  createTask,
  readTaskTypes,
  loadTasksTab: generateReadActionCreator(routes.API_TASKS, ACTION_NAMES.TASKS_TAB),

  addTaskLabels,
  readTaskLabels,
  updateTasks
};
