import { Icon } from 'dyl-components';

const STEPS = [
    {
        icon: <Icon className='fas fa-cog' size='large' />, title: "Setup"
    },
    {
        icon: <Icon className='fas fa-diagram-project' size='large' />, title: "Data Mapping"
    },
    {
        icon: <Icon className='fas fa-user' size='large' />, title: "Assignments"
    },
    {
        icon: <Icon className='fas fa-circle-check' size='large' />, title: "Confirm"
    }
];

export default STEPS;