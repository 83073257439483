import React from 'react';

import OpportunityHeader from './OpportunityHeader';
import OpportunityRow from './OpportunityRow';

import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { EmptyListPlaceholder, RecordsTable } from 'dyl-components';
import { StringUtils } from 'utils';
import { Segment } from 'semantic-ui-react';

import './index.scss';

const OpportunityTabTable = ({ isColumnHidden, onPageChange, routeRoot }) => {
    const [params] = useSearchParams();

    const { opportunities = [], count, isReadingOpportunities } = useSelector(state => state.opportunity_tab);
    const account_type = useSelector(state => state.account.account?.account_type || '');

    const displayEmptyListTitle = () => {

        const filter = params.get('filter');
        let title = `No Opportunities `;
        switch (filter) {
            case 'open':
                return title += 'Open';
            case 'won':
                return title += "Won";
            case 'lost':
                return title += "Lost";
            default:
                return title += `Created Under ${StringUtils.capitalize(account_type)} Contact`;
        }
    }

    const queryParamsCondition = params.get('opportunity_name') || params.get('start') || params.get('end');
    const shouldRenderTable = count !== 0 || queryParamsCondition;

    return (
        shouldRenderTable ?
            (<>
                <RecordsTable
                    tableBodyClassName='ModulesTabTable'
                    isTableLoading={isReadingOpportunities}
                    isSearchResultEmpty={count === 0 && (queryParamsCondition)}
                    isTableEmpty={count === 0 && !(queryParamsCondition)}
                    TableHeader={<OpportunityHeader isColumnHidden={isColumnHidden} routeRoot={routeRoot} />}
                    TableRows={opportunities.map(opportunity => (
                        <OpportunityRow
                            key={opportunity.pipeline_id}
                            opportunity={opportunity}
                            isColumnHidden={isColumnHidden}
                        />
                    ))}
                    recordsCount={count}
                    activePage={params.get('page')}
                    onPageChange={onPageChange}
                    recordsName='Opportunity'
                    emptySubtitle=' '
                    emptyListTitle={displayEmptyListTitle()}
                />
            </>)
            :
            <React.Fragment>
                {isReadingOpportunities ? <Segment basic loading /> : <EmptyListPlaceholder title={displayEmptyListTitle()} subtitle={' '} />}
            </React.Fragment>
    )
}

export default OpportunityTabTable;
