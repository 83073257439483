import { SettingsFooter, Button } from "dyl-components";
import { Icon, Popup, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import React from "react";
import './index.scss'

export const DataMappingFooter = ({
    parseId,
    post,
    isMinimumFields,
    isReadingEmail,
    createEmailMapping,
    createPostMapping,
    isNextStepEnabled,
    width = "100%"
}) => {
    const link = `${post ? 'post' : 'email'}-parsing-template/${parseId}`;
    const error = <Popup
                        content={
                            <div>
                                <div className="MinimumErrors"><u>Errors</u></div>
                                    <List as="ol" className="MinimumErrors">
                                        {isMinimumFields.validFields.map(field=>{
                                            if(!field.isValid){
                                                return (
                                                    <List.Item as='li'>{field.text}</List.Item>
                                                )
                                            }
                                            return ''
                                        })}
                                    </List>
                            </div>
                        }
                        wide='very'
                        position='top center'
                        trigger={
                            <Icon size='large' className='fa-solid fa-triangle-exclamation WarningRed' />
                        }
                        open
                    />
    return (
        <SettingsFooter
            style={{ width: width }}
            className={`Webform__menu`}
            leftOptions={[
                ((!isMinimumFields.isValid && !isReadingEmail) && error),
                <Button primary basic as={Link} to={`/settings/${link}`}>Back</Button>
            ]}
            rightOptions={[
                <Button as={Link} to='/settings/data-integration-logs' status={'cancel'} basic>Cancel</Button>,
                <Button onClick={post ? createPostMapping : createEmailMapping} disabled={(!isNextStepEnabled())} color={(!isNextStepEnabled()) ? 'black' : 'primary'}>
                    Map
                </Button>,
            ]}
        />
    );
};
