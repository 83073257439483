import React, { useState, useEffect } from 'react';
import { Grid, Header, Icon, Loader, Popup } from 'semantic-ui-react';
import { ButtonLink, EmptyListPlaceholder } from 'dyl-components';
import IntegrationForm from './subcomponents/IntegrationForm';
import IntegrationTable from './subcomponents/IntegrationTable';
import oauthActions from 'actions/oauth';
import { useDispatch, useSelector } from 'react-redux';

import './index.scss';

const IntegratedApps = () => {

    const dispatch = useDispatch();
    const { integrations = [], isReadingIntegrations, integrationBeingDeleted, isReactivatingAnIntegration } = useSelector(state => state.oauth_integrations);

    const [isIntegrationFormOpen, setFormOpen] = useState(false);

    useEffect(() => {
        dispatch(oauthActions.getIntegrations({ scopes: `email,calendar`, include_disabled: true }));
    }, [dispatch]);

    return (
        <Grid className='IntegrationApps'>
            <Grid.Row>
                <Grid.Column width={5}>
                    <Header size='medium' color='blue' >
                        <Popup
                            trigger={
                                <Icon name='plus circle' link />
                            }
                            content={
                                <IntegrationForm onClose={() => { setFormOpen(false) }} />
                            }
                            className='IntegrationPopup'
                            basic
                            pinned
                            flowing
                            position='bottom left'
                            on='click'
                            open={isIntegrationFormOpen}
                            onOpen={() => { setFormOpen(true) }}
                            onClose={() => { setFormOpen(false) }}
                        />
                        <Header.Content>Integration</Header.Content>
                    </Header>
                </Grid.Column>
                <Grid.Column width={6} textAlign='right'>
                    <Popup
                        trigger={<Icon className='fa-solid fa-circle-info' color='blue' size='large' />}
                        content='To delete a primary email/calendar, first select a different primary'
                        position='left center'
                        inverted
                        flowing
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={11}>
                    <Loader active={isReadingIntegrations || integrationBeingDeleted || isReactivatingAnIntegration} />
                    <IntegrationTable />
                    {integrations.length === 0
                        &&
                        <EmptyListPlaceholder title={'No Integration Created Under User'}
                            subtitle={<span>Add an Integration by clicking on the
                                <ButtonLink onClick={() => { setFormOpen(true); }}>+ Integration</ButtonLink>
                                button on the top-left corner</span>} />
                    }

                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default IntegratedApps;
