import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Icon, Loader, Popup, Search, Segment, Select } from 'semantic-ui-react';

import Avatar from 'react-avatar';
import contactsActions from 'actions/contacts';

import './UserSearchField.scss';

const resultRenderer = ({ first_name, last_name, email }) => {
    const name = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : "Unknown Name";
    return (
        <div style={{ verticalAlign: 'middle' }}>
            <Avatar name={name} maxInitials={2} round size='3em' /> <div style={{ display: "inline-block", paddingLeft: "1em" }}>
                <div><b>{name}</b></div>
                <div>{email?.email}</div>
            </div>
        </div>
    )
}

const ContactSearchField = ({
    searchValue,
    isSearchingContacts,
    onContactSearchChange,
    onFocusSearch,
    onBlur,
    open,
    contacts,
    onSelectContact,
    displayed_selected_contact,

    upward,
    placeholder,

    multiple,
    onRemove,
    name,
    size = 'small',
    selected,
    value,

    selected_outside
}) => {
    const [focused, setFocused] = useState(false);
    const selectedContactsRender = multiple ? selected.map(({ id, name: username, email }) => (
        <Popup
            inverted
            content={(
                <div>
                    <p>Name: {username}</p>
                    <p>Email: {email}</p>
                </div>
            )}
            trigger={(
                <Segment size={size} className="UserSearchField__user">
                    <Avatar name={username} maxInitials={2} round size='3em' />
                    <div className="UserSearchField__information">
                        <div><span className="SelectedUser__name"><b>{username}</b></span></div>
                        <div><span className="SelectedUser__email"><b>{email}</b></span></div>
                    </div>
                    <div className={`UserSearchField__remove-user UserSearchField__remove-user`}>
                        <Icon
                            name='delete'
                            link
                            onClick={() => { onRemove(null, { name, value: selected.filter(selected_contact => selected_contact.id !== id) }); }}
                        />
                    </div>
                </Segment>
            )} />
    )) : [];

    let selectedContactRender = null;

    if (displayed_selected_contact) {
        const { username, email } = displayed_selected_contact;
        selectedContactRender = (
            <Popup
                inverted
                content={(
                    <div>
                        <p>Name: {username}</p>
                        <p>Email: {email}</p>
                    </div>
                )}
                trigger={(
                    <Segment basic style={{ padding: 0, margin: 0 }} onClick={() => { setFocused(true) }} size='tiny' className="UserSearchField__user">
                        <Avatar name={username} maxInitials={2} round size='3em' />
                        <div className="UserSearchField__information">
                            <div><span className="SelectedUser__name"><b>{username}</b></span></div>
                            <div><span className="SelectedUser__email"><b>{email}</b></span></div>
                        </div>
                    </Segment>
                )}
            />
        )
    }

    return (
        !multiple ? (
            <React.Fragment>
                <Select
                    loading={isSearchingContacts}
                    onSearchChange={onContactSearchChange}
                    options={!isSearchingContacts && contacts.map(contact => {
                        return {
                            key: contact.id,
                            value: contact.id,
                            text: `${contact.first_name} ${contact.last_name}`,
                            content: (resultRenderer({ first_name: contact.first_name, last_name: contact.last_name, email: contact.email }))
                        }
                    })}
                    onOpen={() => { setFocused(true); onContactSearchChange(null, { searchQuery: '' });}}
                    search={focused}
                    searchQuery={focused ? searchValue : ''}
                    onChange={(e, { value }) => { onSelectContact(null, { result: contacts.find(({ id }) => id === value) }); setFocused(false) }}
                    placeholder={placeholder}
                    fluid
                    onClose={() => { setFocused(false) }}
                    selection
                    selectOnBlur={false}
                    trigger={focused ? <div></div> : selectedContactRender}
                    focus={focused}
                    onFocus={onFocusSearch}
                    value={value}
                />
            </React.Fragment>
        ) : (
            <React.Fragment>
                <Segment className={`UserSearchField${upward ? " UserSearchField--upward" : ""}`} size={size}>
                    {!selected_outside && selectedContactsRender}
                    <Search
                        loading={isSearchingContacts}
                        onSearchChange={onContactSearchChange}
                        onFocus={onFocusSearch}
                        onBlur={onBlur}
                        results={contacts.filter(({ id }) => !selected.map(({ id }) => id).includes(id))}
                        resultRenderer={resultRenderer}
                        value={searchValue}
                        onResultSelect={onSelectContact}
                        placeholder={placeholder}
                        fluid
                        icon="caret down"
                        open={open} 
                    />
                </Segment>
                {selected_outside && selectedContactsRender}
            </React.Fragment>
        )
    );
};

const ContactSearchFieldContainer = (props) => {
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState([]);
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [isSearchingContacts, setIsSearchingContacts] = useState(false);
    const [displayedSelectedContact, setDisplayedSelectedContact] = useState(props.displayed_selected_contact || null);
    const [allContacts, setAllContacts] = useState([]);

    useEffect(() => {
        if (props.display_selected_contact) {
            setDisplayedSelectedContact(props.display_selected_contact);
        }
        props.onGetAllContacts().then(res => {
            setResults(res.data)
            setAllContacts(res.data)
            setIsSearchingContacts(false);
        });
        setSelected(props.selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selected])

    const onContactSearchChange = (e, data) => {
         if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        const search = e?.target?.value;
        const filterAllContacts = (search = '') => {
            const filteredData = allContacts.filter(({ first_name = '', last_name = '', email = '' }) => {
                const searchStr = search?.toString()?.toLowerCase();
                const searchObject = `${first_name}, ${last_name}, ${email.email}`.toLowerCase();

                
                return searchObject.includes(searchStr);
            });

            return filteredData;
        }

        setTypingTimeout(setTimeout(() => {    
            const filteredResults = filterAllContacts(search);

            setResults(filteredResults)
            setIsSearchingContacts(false);
            setOpen(true);         
        }, 100));
        setSearchValue(search);
        setIsSearchingContacts(true)
    }

    const onFocusSearch = () => {
        props.onReadContacts({ limit: 200, ...(searchValue ? {search: searchValue} : {}) }).then(() => {
            setResults(props.contacts);
            setOpen(true);
        })
    }

    const onBlur = () => {
        setOpen(false);
    }

    const onSelectContact = (e, { result }) => {
        const { id, first_name, last_name, email: emailObject } = result;
        const { email } = emailObject || {}
        const contactName = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : "Unknown Name";
        if (!props.multiple) {
            setSearchValue(contactName)
            props.onChange(e, { name: props.name, value: id })
            if (props.display_selected_contact) {
                setDisplayedSelectedContact({ username: contactName, email });
            }
        } else {
            const valueAux = props.value.slice(0);
            valueAux.push(id);
            const selectedAux = selected.slice(0);
            selectedAux.push({ id, name: contactName, email });
            setSelected(selectedAux)
            const firstContactName = selectedAux?.length !== 0 ? selectedAux[0].name : null;
            setSearchValue('');
            props.onChange(e, { name: props.name, value: valueAux, contactName: firstContactName })
        }
        setOpen(false);
    }

    const onRemove = (_, { value: selectedParam }) => {
        setSearchValue('')
        setSelected(selectedParam);
        props.onChange(null, { name: props.name, value: selectedParam.map(({ id }) => id) })
    }
    return (
        <ContactSearchField
            upward={props.upward}
            placeholder={props.placeholder}
            contacts={results}
            isSearchingContacts={props.isSearchingContacts || isSearchingContacts}
            onSelectContact={onSelectContact}
            onContactSearchChange={onContactSearchChange}
            onFocusSearch={onFocusSearch}
            onBlur={onBlur}
            open={open}
            searchValue={searchValue === '' ? searchValue : searchValue || props.searchValue}
            selected={selected}

            multiple={props.multiple}
            name={props.name}
            onChange={props.onChange}
            value={props.value}
            onRemove={onRemove}
            selected_outside={props.selected_outside}
            displayed_selected_contact={displayedSelectedContact}
        />
    )
}

const ContactSearchFieldLoader = (props) => {
    const isReadingContacts = useSelector(state => state.users.isReadingUser);
    if (props.display_selected_contact && isReadingContacts) {
        return <Loader />
    }
    return <ContactSearchFieldContainer {...props} />
}

const mapStateToProps = (state, { excluded_contacts }) => {
    return {
        contacts: state.contacts.contacts.filter(contact => !excluded_contacts.includes(contact.id)),
        isSearchingContacts: state.contacts.isReadingContacts
    }
}

const mapDispatchToProps = dispatch => ({
    onReadContacts: (queryParameters) => {
        return dispatch(contactsActions.readContacts(queryParameters));
    },
    onGetAllContacts: () => {
        return dispatch(contactsActions.readContacts({limit: 200}));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactSearchFieldLoader);
