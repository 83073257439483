import React from 'react';
import { useSelector } from 'react-redux';
import { Table as SemanticTable, Icon } from "semantic-ui-react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ClearableCheckboxDropdown, Table, TimestampFilters } from 'dyl-components';

import {
    getData,
    onToggleTimeUnit,
    useRangeCalendar,
} from "./CustomDateRangePrototype";

const SortActions = ({ direction = false, onToggleSort = () => { } }) => {
    return (
        <div
            onClick={onToggleSort}
            style={{ display: "inline-flex", cursor: "pointer" }}
        >
            <Icon
                name="fa-solid fa-arrow-down-long"
                style={{
                    marginRight: -7,
                    opacity: !direction ? "100%" : "50%",
                }}
            />
            <Icon
                name="fa-solid fa-arrow-up-long"
                style={{ opacity: direction ? "100%" : "50%" }}
            />
        </div>
    );
};

const Header = ({ onFilter }) => {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        userAuthTimezone,
        dateRange,
        setDateRange,
    } = useRangeCalendar(params);

    const createdOrder = params.get("order_by") === "created" && params.get("order") === "asc";

    const onDateRangeChange = (_, { value }) => {
        setDateRange(value);
    };

    const createdOrderFilter = () => {
        const query = new URLSearchParams(params);
        const order = !createdOrder ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", "created");
        const query_string = query.toString();
        navigate(`/settings/sequence-reports${query_string ? `?${query_string}` : ""}`);
    };

    const { user_id, sequenceShorts, sequence_users } = useSelector(state => ({
        user_id: state.auth.user_id,
        sequenceShorts: state.sequences.sequencesShort || [],
        sequence_users: state.sequences.sequence_users
    }));

    return (
        <Table.Row isHeader>
            <SemanticTable.HeaderCell className="Table__MediumFixedWidth" width={2}>
                <span style={{ marginRight: 5 }}>Date</span>
                <TimestampFilters
                    timestamp={timestamp}
                    onToggleTimeUnit={(timestamp) =>
                        onToggleTimeUnit(
                            timestamp,
                            setTimestamp,
                            params,
                            navigate,
                            "/settings/sequence-reports",
                            userAuthTimezone
                        )
                    }
                    isCustomDateRangeOpen={isCustomDateRangeOpen}
                    onOpenCustomDateRange={() => {
                        setCustomDateRangeOpen(true);
                    }}
                    onCloseCustomDateRange={() => {
                        setCustomDateRangeOpen(false);
                    }}
                    dateRange={dateRange}
                    onDateRangeChange={onDateRangeChange}
                    getData={() =>
                        getData({
                            setCustomDateRangeOpen,
                            dateRange,
                            userAuthTimezone,
                            params,
                            navigate,
                            url: "/settings/sequence-reports",
                        })
                    }
                />
                <SortActions
                    direction={createdOrder}
                    onToggleSort={() => {
                        createdOrderFilter();
                    }}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className="Table__MediumFixedWidth" width={2}>
                <ClearableCheckboxDropdown
                    text="User"
                    name="user"
                    options={[
                        { key: user_id, value: user_id, text: 'Me' },
                        ...sequence_users.map(sequence_user => ({
                            key: sequence_user.id,
                            value: sequence_user.id,
                            text: `${sequence_user.first_name} ${sequence_user.last_name}`
                        }))]}
                    onChange={(_, { value }) => {
                        onFilter(_, { name: "user_id", value });
                    }}
                    value={(params.get("user_id")?.split(",") || []).map(id => Number(id))}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className="Table__MediumFixedWidth" width={2}>
                <ClearableCheckboxDropdown
                    text="Status"
                    name="status"
                    options={[
                        { key: 'Upcoming', value: 'upcoming', text: 'Upcoming' },
                        { key: 'Overdue', value: 'overdue', text: 'Overdue' },
                        { key: 'Completed', value: 'completed', text: 'Completed' },
                        { key: 'Canceled', value: 'canceled', text: 'Canceled' },
                    ]}
                    onChange={(_, { value }) => {
                        onFilter(_, { name: "status", value });
                    }}
                    value={params.get("status")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className="Table__MediumFixedWidth" width={2}>
                <ClearableCheckboxDropdown
                    text="Name"
                    name="name"
                    options={sequenceShorts.map(sequenceShort => ({ key: sequenceShort.id, value: sequenceShort.id, text: sequenceShort.name }))}
                    onChange={(_, { value }) => {
                        onFilter(_, { name: "workflow_id", value });
                    }}
                    value={(params.get("workflow_id")?.split(",") || []).map(id => Number(id))}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className="Table__MediumFixedWidth" width={2}>
                <ClearableCheckboxDropdown
                    text="Task Type"
                    name="task_type"
                    options={[
                        { key: 'Call', value: 'Call', text: 'Call' },
                        { key: 'Text', value: 'Text', text: 'Text' },
                        { key: 'Email', value: 'Email', text: 'Email' },
                    ]}
                    onChange={(_, { value }) => {
                        onFilter(_, { name: "action", value });
                    }}
                    value={params.get("action")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className="Table__MediumFixedWidth" width={2}>
                Contact Name
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className="Table__MediumFixedWidth" width={2}>
                Account
            </SemanticTable.HeaderCell>
        </Table.Row>
    );
}

export default Header;
