export const getDefaultSupplyChainNetworkValuesFromPayload = (payload) => {
    return ["vendor", "carrier", "supplier", "provider", "manufacturer"].reduce((values, type) => {
        return {
            ...values,
            [type]: payload[type]?.id
        }
    }, {})
}

export const formatSupplyChainNetworkValues = (data) => {
    return ["vendor", "carrier", "supplier", "provider", "manufacturer"].reduce((values, type) => {
        return {
            ...values,
            [type]: (() => {
                const value = data[type];
                if (!value) {
                    return null;
                }
                if (typeof value === "number") {
                    return {
                        id: value
                    }
                }
                return {
                    name: value
                }
            })()
        }
    }, {});
}
