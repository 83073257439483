import ACTION_NAMES from 'actions/ACTION_NAMES';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import initialState from './initialState';

function callQueueMemberReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CALL_QUEUE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCallQueue: true };
        case getNamedAction(ACTION_NAMES.CALL_QUEUE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingCallQueue: false, call_queue: action.data };
        case getNamedAction(ACTION_NAMES.CALL_QUEUE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCallQueue: false };

        case getNamedAction(ACTION_NAMES.CALL_QUEUE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingCallQueue: true };
        case getNamedAction(ACTION_NAMES.CALL_QUEUE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingCallQueue: false, call_queue: action.data };
        case getNamedAction(ACTION_NAMES.CALL_QUEUE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingCallQueue: false };

        case getNamedAction(ACTION_NAMES.CALL_QUEUE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, groupBeingUpdated: true };
        case getNamedAction(ACTION_NAMES.CALL_QUEUE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, groupBeingUpdated: false };
        case getNamedAction(ACTION_NAMES.CALL_QUEUE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, groupBeingUpdated: false };

        case getNamedAction(ACTION_NAMES.CALL_QUEUE, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingCallQueue: true };
        case getNamedAction(ACTION_NAMES.CALL_QUEUE, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingCallQueue: false };
        case getNamedAction(ACTION_NAMES.CALL_QUEUE, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingCallQueue: false };

        case getNamedAction(ACTION_NAMES.CALL_QUEUE_MEMBERS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCallQueueMembers: true };
        case getNamedAction(ACTION_NAMES.CALL_QUEUE_MEMBERS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingCallQueueMembers: false, members: action.data.data };
        case getNamedAction(ACTION_NAMES.CALL_QUEUE_MEMBERS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCallQueueMembers: false };

        case getNamedAction(ACTION_NAMES.CALL_QUEUE_MEMBERS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingMembers: true };
        case getNamedAction(ACTION_NAMES.CALL_QUEUE_MEMBERS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAddingMembers: false };
        case getNamedAction(ACTION_NAMES.CALL_QUEUE_MEMBERS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingMembers: false };

        case getNamedAction(ACTION_NAMES.CALL_QUEUE_MEMBERS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, memberBeingUpdated: action.subId };
        case getNamedAction(ACTION_NAMES.CALL_QUEUE_MEMBERS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, memberBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.CALL_QUEUE_MEMBERS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, memberBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.CALL_QUEUE_MEMBERS, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, memberBeingDeleted: action.subId };
        case getNamedAction(ACTION_NAMES.CALL_QUEUE_MEMBERS, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, memberBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.CALL_QUEUE_MEMBERS, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, memberBeingDeleted: null };
        
        default:
            return state;
    }
}

export default callQueueMemberReducer;
