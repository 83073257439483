import React, { useRef } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Dropdown, Header, Icon, Ref } from 'semantic-ui-react'
import { DateTimeUtils, EllipsisDropdownPortal, Table } from 'dyl-components';

import {
    KEY_CREATED, KEY_OWNED_BY, KEY_LAST_MODIFIED,
    KEY_ACCOUNT, KEY_STAGE,
    KEY_CAMPAIGN_SOURCE, KEY_CONVERT, KEY_ACTIONS,
    KEY_CREATED_BY, KEY_LAST_MODIFIED_BY, KEY_OUTCOME
} from 'shared/constants/MODULE_COLUMNS';

import Contact from 'shared/Contact';
import AccountLink from 'shared/AccountLink';
import ModuleLink from 'shared/ModuleLink';
import ContactConversionControls from 'shared/ContactConversionControls';
import { useDispatch } from 'react-redux';
import opportunitiesActions from 'actions/opportunities';

const OpportunitysRow = ({
    opportunity,
    isColumnHidden
}) => {
    const { contact, account, pipeline_stage_summary, pipeline_name, outcome = '', created_by, last_modified_by
    } = opportunity;

    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const refresh = () => {
        dispatch(opportunitiesActions.readOpportunities({ ...Object.fromEntries(params), limit: 25 }));
    }

    const ref = useRef();

    return (
        <Table.Row>
            {
                !isColumnHidden(KEY_CREATED) &&
                <Table.Cell>
                    <div><b>{DateTimeUtils.formatEpoch(opportunity.pipeline_created, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{DateTimeUtils.formatEpoch(opportunity.pipeline_created, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
            }
            {
                !isColumnHidden(KEY_CREATED_BY) &&
                <Table.Cell>{created_by}</Table.Cell>
            }
            {
                !isColumnHidden(KEY_LAST_MODIFIED) &&
                <Table.Cell>
                    <div><b>{opportunity.pipeline_activity && DateTimeUtils.formatEpoch(opportunity.pipeline_activity, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{opportunity.pipeline_activity && DateTimeUtils.formatEpoch(opportunity.pipeline_activity, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
            }
            {
                !isColumnHidden(KEY_LAST_MODIFIED_BY) &&
                <Table.Cell>{last_modified_by}</Table.Cell>
            }
            <Table.Cell>
                <ModuleLink pipeline_id={opportunity.pipeline_id} />
            </Table.Cell>
            <Table.Cell>
                {pipeline_name}
            </Table.Cell>
            <Table.Cell>
                <Contact
                    contact={{
                        ...contact,
                        email: contact?.primary_email || {},
                        phone: contact?.primary_phone || {}
                    }}
                    name={`${contact.first_name} ${contact.last_name}`}
                    linkToContact={<Link to={`/contact/${contact.id}`}>{`${contact.first_name} ${contact.last_name}`}</Link>}
                />
            </Table.Cell>
            {!isColumnHidden(KEY_ACCOUNT) && (
                <Table.Cell >
                    {account?.id &&
                        <AccountLink
                            id={account.id}
                            name={account.name}
                            type={account.type}
                        />
                    }
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_STAGE) && (
                <Table.Cell>
                    <Header as='h5'>
                        {pipeline_stage_summary?.pipeline_stage_name}
                        <Header.Subheader>
                            {pipeline_stage_summary?.pipeline_category_name}
                        </Header.Subheader>
                    </Header>
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_OUTCOME) && (
                <Table.Cell>
                    {
                        outcome !== '' ?
                            <h5 className={`ModulesListTable__Outcome${outcome.split('(')[0] === 'won' ? '--won' : '--lost'}`}>
                                {outcome.charAt(0).toUpperCase() + outcome.slice(1).replace('(', ' (')}
                            </h5> : ''
                    }
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_OWNED_BY) && (
                <Table.Cell>
                    Unassigned
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_CAMPAIGN_SOURCE) && (
                <Table.Cell>

                </Table.Cell>
            )}
            {!isColumnHidden(KEY_CONVERT) && (
                <Table.Cell>
                    {outcome === '' && !(contact.id === account?.pipeline_person_id) ?
                        <ContactConversionControls
                            account_id={account?.id}
                            pipeline_id={opportunity.pipeline_id}
                            account_stage={'opportunity'}
                            contactName={`${contact.first_name} ${contact.last_name}`}
                            contact_id={contact.id}
                            linkedAccountIsACustomer={account?.customer}
                            linkedAccountName={account?.name}
                            linkedAccountPrimaryContact={account?.primary_person_id}
                            refresh={refresh}
                        />
                        : ''}
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_ACTIONS) && (
                <Table.Cell>
                    {outcome === '' && (
                        <Ref innerRef={ref}>
                            <EllipsisDropdownPortal reference={ref}>
                                <Dropdown.Item text={<React.Fragment>
                                    <Icon name={'fas fa-sitemap'} />
                                    Sequences
                                </React.Fragment>}
                                    style={{ cursor: 'pointer' }}
                                />
                            </EllipsisDropdownPortal>
                        </Ref>
                    )}
                </Table.Cell>
            )}
        </Table.Row>
    );
}

export default OpportunitysRow;
