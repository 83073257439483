import React from 'react';
import { Grid, Segment, Loader } from 'semantic-ui-react';
import { TableWithHeader } from 'dyl-components';
import { connect } from 'react-redux';

import UsersChatRoomsHeader from './UsersChatRoomsHeader';
import UsersChatRoomsTable from './UsersChatRoomsTable';

class UsersChatRooms extends React.Component {
    state = {
        isEditMode: false,
        usersChatRooms: []
    }

    onModifyChatRooms = (_, { name, value }) => {
        const usersChatRooms = [...this.state.usersChatRooms.slice(0)];
        const indexOfUserChatRoomToModify = usersChatRooms.findIndex(userChatRooms => userChatRooms.user.id === name);
        if (indexOfUserChatRoomToModify !== -1) {
            const userChatRoomsToModify = { ...usersChatRooms[indexOfUserChatRoomToModify] };
            userChatRoomsToModify.rooms = value;
            usersChatRooms[indexOfUserChatRoomToModify] = userChatRoomsToModify;
            this.setState({ usersChatRooms });
        }
    }

    onCloseEditMode = () => {
        this.setState({ isEditMode: false });
    }

    onCancel = () => {
        this.setState({
            isEditMode: false,
            usersChatRooms: this.props.usersChatRooms
        })
    }

    onEnableEditMode = () => {
        this.setState({ isEditMode: true, usersChatRooms: this.props.usersChatRooms.slice(0) });
    }

    render() {
        return (
            <TableWithHeader
                header={
                    <UsersChatRoomsHeader 
                        isEditMode={this.state.isEditMode} 
                        onCancel={this.onCancel} 
                        onCloseEditMode={this.onCloseEditMode}
                        onEnableEditMode={this.onEnableEditMode} 
                    />
                }
                table={
                    <Grid style={{margin: 0}}>
                        <Grid.Row style={{padding: 0}}>
                            <Grid.Column style={{padding: 0}}>
                                {this.props.isReading ? (
                                    <Segment>
                                        <Loader active inverted />
                                    </Segment>
                                ) : (
                                    <UsersChatRoomsTable 
                                        onModifyChatRooms={this.onModifyChatRooms} 
                                        usersChatRooms={this.state.isEditMode ? this.state.usersChatRooms : this.props.usersChatRooms} 
                                        isEditMode={this.state.isEditMode} 
                                    />
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                } />
        )
    }
}

const mapStateToProps = state => ({
    usersChatRooms: state.chatRooms.users.map(userChatRooms => ({
        ...userChatRooms,
        rooms: userChatRooms.rooms.map(room => room.id)
    })),
    isReading: state.chatRooms.isReadingChatRooms
});

export default connect(mapStateToProps)(UsersChatRooms);
