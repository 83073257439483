import Calendar from 'pages/UserProfile/Calendar';
import Email from 'pages/UserProfile/Email';
import General from 'pages/UserProfile/General';
import Teams from 'pages/UserProfile/Teams';
import Integration from 'pages/UserProfile/Integration';
import IntegratedApps from 'pages/UserProfile/IntegratedApps';
import Notifications from 'pages/UserProfile/Notification';
import React from 'react';
import { Route } from 'react-router-dom';

export const USER_PROFILE_ROUTES = [
    <Route index path='general' element={<General />} />,
    <Route path='teams' element={<Teams />} />,
    <Route path='integration' element={<Integration />} >
        <Route index element={<IntegratedApps />} />,
        <Route path='email' element={<Email />} />,
        <Route path='calendar' element={<Calendar />} />,
        <Route path='phone' element={<div />} />,
        <Route path='text' element={<div />} />,
    </Route>,
    <Route path='notification' element={<Notifications />} />,
    <Route path='chat' element={<div />} />,
    <Route path='permission' element={<div />} />
];
