const initialState = {
    contacts: [],
    recent_contacts: [],
    isReadingContacts: false,
    isReadingRecentContacts: false,
    isCreating: false,
    queryParameters: {
        page: 1,
        limit: 300
    },
    filterQueryParameters: {

    },
    allMarked: false,
    allPagesMarked: false,
    bulkAction: null
};

export default initialState;
