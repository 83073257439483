import React from 'react';
import { Table } from 'dyl-components';
import { Input } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { EditableContent } from 'dyl-components';
import chatRoomActions from 'actions/chat_room';
import chatRoomsActions from 'actions/chat_rooms';
import MessagingIconButtons from 'shared/MessagingIconButtons';

const ChatRoomRow = ({ isEditMode, chatRoom, onChange, onDelete, onUpdate, onEnableEditMode, onDisableEditMode }) => (
    <Table.Row active={isEditMode}>
        <Table.Cell>
            <EditableContent 
                control={Input}
                name='name'
                value={chatRoom.name}
                onChange={onChange}
                isEditMode={isEditMode}
            />
        </Table.Cell>
        <Table.Cell>
           <MessagingIconButtons
                isEditMode={isEditMode}
                onDisableEditMode={onDisableEditMode} 
                onUpdate={onUpdate}
                onEnableEditMode={onEnableEditMode}
                onDelete={onDelete}
                id={chatRoom.id} 
            />
        </Table.Cell>
    </Table.Row>
)

class ChatRoomRowContainer extends React.Component {
    state = {
        chatRoom: this.props.chatRoom,
        isEditMode: false
    }

    onEnableEditMode = () => {
        this.setState({ isEditMode: true });
    }

    onDisableEditMode = () => {
        this.setState({ isEditMode: false, chatRoom: this.props.chatRoom });
    }

    onChange = (_, { name, value }) => {
        let chatRoom = { ...this.state.chatRoom, [name]: value};
        this.setState({ chatRoom });
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false
        }, () => {
            this.props.onUpdate(this.state.chatRoom);
        })
    }

    render() {
        return (
            <ChatRoomRow 
                {...this.props}
                {...this.state}
                onChange={this.onChange}
                onEnableEditMode={this.onEnableEditMode}
                onDisableEditMode={this.onDisableEditMode}
                onUpdate={this.onUpdate}
            />
        )
    }
}

const mapDispatchToProps = (dispatch, { chatRoom }) => ({
    onDelete: () => {
        dispatch(chatRoomActions.deleteChatRoom(chatRoom.id))
            .then(() => {
                dispatch(chatRoomsActions.readChatRooms());
            });
    },
    onUpdate: ({ id, ...updatedChatRoom }) => {
        dispatch(chatRoomActions.updateChatRoom(id, updatedChatRoom));
    }
})

export default connect(null, mapDispatchToProps)(ChatRoomRowContainer);
