import { SettingsFooter, Button } from "dyl-components";
import { Link } from 'react-router-dom';
import React from "react";

export const WebformMenu = ({
    isSaveDisabled,
    onSave,
    onPreview,
    isSaving,
    width = "100%"
}) => {
    return (
        <SettingsFooter
            style={{ width: width }}
            className={`Webform__menu`}
            rightOptions={[
                <Button as={Link} to='/settings/web-forms' status={'cancel'} basic>Cancel</Button>,
                <Button
                    loading={isSaving}
                    onClick={onSave}
                    disabled={isSaveDisabled || isSaving}
                >
                    Save
                </Button>,
            ]}
            leftOptions={[
                ...(!isSaving
                    ? [
                        <Button onClick={onPreview} basic>
                            Preview
                        </Button>,
                    ]
                    : []),
            ]}
        />
    );
};
