import { Controller } from "react-hook-form"
import { Form } from "semantic-ui-react"
import Actions from "./Actions"
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import sequencesActions from "actions/sequences";

const SequenceForm = ({
    control,
    clearErrors,
    setError,
    nameValidator,
    id
}) => {
    const [typingTimeout, setTypingtimeout] = useState(null);
    const dispatch = useDispatch();

    const isCheckingUniqueness = useSelector(state => state.sequences.isCheckingUniqueness);

    return (
        <>
            <Form.Group style={{ marginLeft: '1.5em' }}>
                <Controller
                    control={control}
                    name='name'
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            label='Name'
                            required
                            placeholder='Enter Sequence Name'
                            name={name}
                            value={value}
                            onChange={async (_, { value }) => { 
                                if (typingTimeout) {
                                    clearTimeout(typingTimeout)
                                }
                                onChange({ target: { name, value } });
                                const isValid = await nameValidator.isValid(value);
                                if (isValid && value !== '') {
                                    setTypingtimeout(setTimeout(async () => {
                                        await clearErrors(name);
                                        const isUnique = (await dispatch(sequencesActions.isUnique({ name: value, workflow_id: id })));
                                        if (!isUnique) {
                                            await setError("name", {
                                                type: 'unique_sequence',
                                                message: 'Sequence name already exists!'
                                            })
                                        }
                                    }));
                                }
                            }}
                            width={4}
                            loading={isCheckingUniqueness}
                            error={error?.message}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name='description'
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            label='Description'
                            placeholder='Enter Description'
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            width={4}
                            error={error?.message}
                        />
                    )}
                />
            </Form.Group>
            <Actions 
                control={control}
            />
        </>
    )
}

export default SequenceForm;
