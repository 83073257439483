import React from 'react';
import {ConfirmationPrompt, Modal} from 'dyl-components';

import './FullScreenModalPopup.scss';

const FullScreenModalPopUp = ({header, subheader, isOpen, onConfirm, onFormClose, closeIcon = true}) => {
    return (
        <Modal className='ConfirmModal' size='mini' open={isOpen} onClose={onFormClose} closeIcon={closeIcon} >
            <Modal.Content>
                <ConfirmationPrompt header={header} subheader={subheader} onCancel={onFormClose} onConfirm={onConfirm} />
            </Modal.Content>
        </Modal>
    );
}

export default FullScreenModalPopUp;
