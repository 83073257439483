import { Modal, Notification, STATUS_TYPES, VALIDATORS, generateResolver, yup } from "dyl-components";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Form } from "semantic-ui-react";
import './DevicesModal.scss'
import { useDispatch, useSelector } from "react-redux";
import { PhoneUtil } from "utils";
import pbxConfigActions from "actions/pbx_config";
import provisionActions from "actions/provision";

const DEVICE_MODAL_OPTIONS = PhoneUtil.DEVICE_TYPES_OPTIONS.filter((option) => option.key !== "browser");

const Content = ({ onClose, onReload, isEditing, hardphoneBeingEdited }) => {
    const dispatch = useDispatch();
    const [extensionOptions, setExtensionOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [isMacDuplicatedError, setIsMacDuplicatedError] = useState(false);
    const [isNameDuplicatedError, setIsNameDuplicatedError] = useState(false);

    const { extensionsList, isReadingExtensionsList } = useSelector((state) => state.pbx_config);
    const { locations, isReadingLocations, softphone, hardphone } = useSelector((state) => state.provision);

    const loadingDataType = isEditing === "softphone" && !!softphone ? "softphone" : (
        isEditing === "hardphone" && !!hardphone ? "hardphone" : false
    )

    const defaultData = () => {
        return !!loadingDataType ? (
            loadingDataType === "softphone" ? ({
                type: "softphone",
                extension: softphone.extension,
                name: softphone.name,
                location_id: softphone.location?.id,
                mac_addr: ""
            }) : ({
                type: "hardphone",
                extension: hardphone.extension,
                name: hardphone.phone_name,
                location_id: hardphone.location?.id,
                mac_addr: hardphone.mac_addr
            })
        ) : ({
            type: "softphone",
            extension: null,
            name: "",
            location_id: null,
            mac_addr: ""
        })
    };

    const { control, formState: { isValid, isDirty }, handleSubmit, watch, getValues, setError } = useForm({
        mode: 'onChange',
        defaultValues: defaultData(),
        resolver: generateResolver({
            type: yup.string().required("This field is required"),
            extension: yup.string().required("This field is required"),
            name: VALIDATORS.TEAM_NAME().required("This field is required"),
            location_id: yup.number().when("type", {
                is: (type) => type === "softphone",
                then: () => yup.number().required("This field is required"),
                otherwise: () => yup.number().nullable()
            }),
            mac_addr: yup.string().when("type", {
                is: (type) => type === 'hardphone',
                then: () => VALIDATORS.MAC_ADDRESS().required("This field is required"),
                otherwise: () => yup.string().nullable()
            }),
        })
    });

    const watchedType = watch("type");

    const onSave = async (data) => {
        try {
            if (watchedType === "softphone") {
                await dispatch(provisionActions.createSoftphone(data));
            } else {
                const { extension, name: phone_name, location_id } = data;
                const { user_id } = extensionsList.find(({ extension_number }) => extension === extension_number) || {}
                if (!location_id) {
                    delete data.location_id;
                }
                await dispatch(provisionActions.createDevice({
                    ...data, 
                    user_id,
                    phone_name
                }));
            }
            Notification.alert('Device Created Successfully!', STATUS_TYPES.SUCCESS);
            onReload();
            onClose();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to create device', STATUS_TYPES.ERROR);
        }
    }

    const onEdit = async (data) => {
        try {
            if (watchedType === "softphone") {
                await dispatch(provisionActions.updateSoftphone(softphone.id, data));
            } else {
                const { name: phone_name, location_id } = data;
                if (!location_id) {
                    delete data.location_id;
                }
                await dispatch(provisionActions.updateDevice(hardphoneBeingEdited, {...data, phone_name}));
            }
            Notification.alert('Device updated Successfully!', STATUS_TYPES.SUCCESS);
            onReload();
            onClose();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update device', STATUS_TYPES.ERROR);
        }
    }

    const onCheckUniqueMac = async () => {
        const mac_addr = getValues("mac_addr");
        if (!isEditing || (loadingDataType === "hardphone" && hardphone?.mac_addr !== mac_addr)) {
            try {
                const isUnique = await dispatch(provisionActions.uniqueMac({mac_addr}));
                if (!isUnique?.unique) {
                    setError("mac_addr", { type: "unique", message: "Mac address is already in use" })
                    setIsMacDuplicatedError(true)
                } else {
                    setIsMacDuplicatedError(false)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const onCheckUniqueName = async () => {
        const name = getValues("name");
        if (
            !loadingDataType
            || (loadingDataType === "hardphone" && hardphone?.name !== name)
            || (loadingDataType === "softphone" && softphone?.name !== name)
        ) {
            try {
                const isUnique = await dispatch(provisionActions.uniqueName({name}));
                if (!isUnique?.unique) {
                    setError("name", { type: "unique", message: "Label is already in use" })
                    setIsNameDuplicatedError(true)
                } else {
                    setIsNameDuplicatedError(false)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    useEffect(() => {
        if (extensionsList) {
            const formattedOptions = extensionsList.filter((extension) => {
                const editedExtension = !!loadingDataType ? (
                    loadingDataType === "softphone" ?
                    softphone.extension || null :
                    hardphone.extension || null
                ) : null
                const hasDevice = watchedType === "softphone" ? !extension.has_softphone : ! extension.device_count; 
                return hasDevice || (!!editedExtension && extension.extension_number === editedExtension)
            }).map((extension) => ({
                key: extension.id,
                value: extension.extension_number,
                text: `${!!extension.user ? `${extension.user.first_name} ${extension.user.last_name} (Ext. ${extension.extension_number})` : `Ext. ${extension.extension_number}`}`
            }));
            setExtensionOptions(formattedOptions);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extensionsList,watchedType])

    useEffect(() => {
        if (locations) {
            const formattedOptions = locations.map((location) => ({
                key: location.id,
                value: location.id,
                text: `${location.label}: ${location.address}`
            }));
            setLocationOptions(formattedOptions);
        }
    }, [locations])

    useEffect(() => {
        dispatch(pbxConfigActions.getListOfExtensions());
        dispatch(provisionActions.getLocations());
    }, [dispatch])

    useEffect(() => {
        setIsMacDuplicatedError(false);
    }, [watchedType])

    return (
        <>
            <Modal.Header>
                {isEditing ? "Edit" : "Add"} Device
            </Modal.Header>
            <Modal.Content>
                <Form noValidate loading={false}>
                    <div className="DevicesModal__form">
                        <div className="DevicesModal__controllerContainer">
                            {isEditing ? (
                                <Form.Input
                                    value={DEVICE_MODAL_OPTIONS.find(({value}) => value === getValues("type"))?.text || ""}
                                    label="Type"
                                    required
                                    readOnly={!!isEditing}
                                />
                            ) : (
                                <Controller
                                    name='type'
                                    control={control}
                                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                        <Form.Select
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                            placeholder='Select type'
                                            options={DEVICE_MODAL_OPTIONS}
                                            label="Type"
                                            required
                                            error={error?.message}
                                        />
                                    )}
                                />
                            )}
                            {watchedType === "softphone" && (
                                !!isEditing ? (
                                    <Form.Input
                                        value={`${softphone?.user ? `${softphone.user.first_name} ${softphone.user.last_name} (` : ""}Ext. ${softphone?.extension}${softphone?.user ? ")" : ""}`}
                                        label="User/Extension"
                                        required
                                        readOnly={!!isEditing}
                                    />
                                ) : (
                                    <Controller
                                        name='extension'
                                        control={control}
                                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                            <Form.Select
                                                name={name}
                                                value={value}
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                placeholder='Select user/extension'
                                                options={extensionOptions}
                                                label="User/Extension"
                                                required
                                                loading={isReadingExtensionsList}
                                                error={error?.message}
                                                disabled={isEditing}
                                            />
                                        )}
                                    />
                                )
                            )} 
                            {watchedType === "hardphone" && (
                                <Controller
                                    name='mac_addr'
                                    control={control}
                                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                        <Form.Input
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                            label="Mac ID"
                                            placeholder="Type Mac ID"
                                            required
                                            error={error?.message}
                                            readOnly={isEditing}
                                            onBlur={() => onCheckUniqueMac()}
                                        />
                                    )}
                                />
                            )}
                        </div>
                        <div className="DevicesModal__controllerContainer">
                            {watchedType === "hardphone" && (
                                <Controller
                                    name='extension'
                                    control={control}
                                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                        <Form.Select
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                            placeholder='Select user/extension'
                                            options={extensionOptions}
                                            label="User/Extension"
                                            required
                                            loading={isReadingExtensionsList}
                                            error={error?.message}
                                        />
                                    )}
                                />
                            )}
                            <Controller
                                name='name'
                                control={control}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        label="Label"
                                        placeholder="Type label"
                                        required
                                        error={error?.message}
                                        onBlur={onCheckUniqueName}
                                    />
                                )}
                            />
                            {watchedType === "softphone" && (
                                <Controller
                                    name='location_id'
                                    control={control}
                                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                        <Form.Select
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                            placeholder='Select E911 location'
                                            options={locationOptions}
                                            label="E911 Location"
                                            required
                                            loading={isReadingLocations}
                                            error={error?.message}
                                        />
                                    )}
                                />
                            )}
                        </div>
                        {watchedType === "hardphone" && (
                            <div className="DevicesModal__controllerContainer">
                                <Controller
                                    name='location_id'
                                    control={control}
                                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                        <Form.Select
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                            placeholder='Select E911 location'
                                            options={locationOptions}
                                            label="E911 Location"
                                            loading={isReadingLocations}
                                        />
                                    )}
                                />
                                <Form.Field />
                            </div>
                        )}
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions 
                hasSaveButton
                onSave={handleSubmit(isEditing ? onEdit : onSave)}
                saveDisabled={!isValid || !isDirty || isMacDuplicatedError || isNameDuplicatedError}
                saveOptions={{ loading: false }}
            />  
        </>
    )
}

const DevicesModal = ({ open, onClose, onReload, isEditing, hardphoneBeingEdited }) => {
    return (
        <Modal open={open} onClose={onClose} size={"small"}>
            <Content onClose={onClose} onReload={onReload} isEditing={isEditing} hardphoneBeingEdited={hardphoneBeingEdited} />
        </Modal>
    )
}

export default DevicesModal;
