import { Notification, STATUS_TYPES } from 'dyl-components';

import routes from 'actions/routes';
import { generateReadActionCreator, generateCreateActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

const onCreateSuccess = () => {
    Notification.alert('Successfully added category!', STATUS_TYPES.SUCCESS, true);
}

const onCreateFailure = () => {
    Notification.alert('Failed to add category', STATUS_TYPES.ERROR, true);
}

const getListOfTagCategories = generateReadActionCreator(routes.API_TAGS_CATEGORIES, ACTION_NAMES.TAG_CATEGORIES);
const addTagCategory = generateCreateActionCreator(routes.API_TAGS_CATEGORY, ACTION_NAMES.TAG_CATEGORIES, onCreateSuccess, onCreateFailure);

export default {
    getListOfTagCategories,
    addTagCategory
};
