import React from 'react';
import { Grid, Button } from 'semantic-ui-react';

const UsersChatRoomsHeader = ({ isEditMode, onEnableEditMode, onCancel, onUpdate }) => (
    <Grid verticalAlign='middle'>
        <Grid.Column width={6}>
            Assign Users To Chat Rooms
        </Grid.Column>
        <Grid.Column width={4} textAlign='right' floated='right'>
            {!isEditMode ? <Button onClick={onEnableEditMode} size='tiny' basic inverted color='white'>Select User Chat Rooms</Button> :
            <React.Fragment>
                <Button size='tiny' inverted color='white' basic onClick={onCancel}>Cancel</Button>
                <Button size='tiny' inverted color='white' basic onClick={onUpdate}>Save Changes</Button>
            </React.Fragment>
        }
        </Grid.Column>
    </Grid>
);

export default UsersChatRoomsHeader;
