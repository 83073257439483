import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function userReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.TEAMS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingTeams: true };
        case getNamedAction(ACTION_NAMES.TEAMS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingTeams: false, teams: action.data.data, count: action.data.count, queryParameters: { ...state.queryParameters, ...action.queryParameters } };
        case getNamedAction(ACTION_NAMES.TEAMS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingTeams: false };

        case getNamedAction(ACTION_NAMES.TEAM_OPTIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingTeamOptions: true };
        case getNamedAction(ACTION_NAMES.TEAM_OPTIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingTeamOptions: false, teams: action.data.data, count: action.data.count, queryParameters: { ...state.queryParameters, ...action.queryParameters } };
        case getNamedAction(ACTION_NAMES.TEAM_OPTIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingTeamOptions: false };

        case getNamedAction(ACTION_NAMES.TEAMS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingTeam: true };
        case getNamedAction(ACTION_NAMES.TEAMS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAddingTeam: false };
        case getNamedAction(ACTION_NAMES.TEAMS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingTeam: false };

        default:
            return state;
    }
}


export default userReducer;
