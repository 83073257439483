import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function campaignsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CONTACT_CAMPAIGNS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCampaigns: true, campaigns: [], campaigns_count: 0 };
        case getNamedAction(ACTION_NAMES.CONTACT_CAMPAIGNS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return {
                ...state,
                isReadingCampaigns: false,
                campaigns: action.data.data || [],
                campaigns_count: action.data.count || 0
            };
        case getNamedAction(ACTION_NAMES.CONTACT_CAMPAIGNS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCampaigns: false, campaigns: [], campaigns_count: 0 };

        default:
            return state;
    }
}


export default campaignsReducer;

