import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function campaignsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CAMPAIGN_CONTACTS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingContacts: true, contacts: [], count: 0 };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_CONTACTS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingContacts: false, contacts: action.data?.data || [], count: action.data?.count || 0 };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_CONTACTS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingContacts: false, contacts: [], count: 0 };

        case getNamedAction(ACTION_NAMES.CAMPAIGN_CONTACTS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, contactBeingUpdated: action.subId };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_CONTACTS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, contactBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_CONTACTS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, contactBeingUpdated: null };

        default:
            return state;
    }
}


export default campaignsReducer;

