import { NestedDropdown } from "dyl-components";
import React from "react";
import { StringUtils } from "utils";

const QuoteStatusField = ({ name, value, onChange }) => {
    const [parent_value, child_value] = (value || "").split("/");
    return (
        <NestedDropdown
            parent_value={parent_value}
            child_value={child_value}
            label="Status"
            allowedToSelectParent
            allowedToSelectParentOnly
            onChange={(e, { parent_value, child_value }) => {
                onChange(e, {
                    name,
                    value: child_value
                        ? `${parent_value}/${child_value}`
                        : parent_value,
                });
            }}
            display_parent
            selection
            nested_options={[
                ...["draft", "sent", "approved"].map((status) => ({
                    key: status,
                    value: status,
                    text: StringUtils.capitalize(status),
                    options: [],
                })),
                {
                    key: "rejected",
                    value: "rejected",
                    text: "Rejected",
                    options: [
                        "quote-expired",
                        "product-changed",
                        "price-too-high",
                        "did-not-meet-needs",
                    ].map((status) => ({
                        key: status,
                        value: status,
                        text: StringUtils.capitalize(
                            status.split("-").join(" ")
                        ),
                    })),
                },
            ]}
            selectOnBlur={false}
            clearable={false}
            placeholder="Select status"
        />
    );
};

export default QuoteStatusField;
