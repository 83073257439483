import React from 'react';
import { Form, Segment } from 'semantic-ui-react';

import './ExportDownload.scss';

const ExportDownload = ({
    exportTo,
    exportOption,
    onChange

}) => (
        <Segment className="ExportDownloadSegment">
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field >
                        <label>Export To</label>

                        <Form.Radio checked={exportTo === 'excel'} label='Excel' value='excel' name={'exportTo'} onChange={onChange} />
                        <Form.Radio checked={exportTo === 'csv'} label='CSV' value='csv' name={'exportTo'} onChange={onChange} />

                    </Form.Field>
                    <Form.Field>
                        <label>Export</label>

                        <Form.Radio checked={exportOption === 'all'} label='All' value='all' name={'exportOption'} onChange={onChange} />
                        <Form.Radio checked={exportOption === 'selected'} label='Selected' value='selected' name={'exportOption'} onChange={onChange} />

                    </Form.Field>

                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Select label='Include' placeholder='Choose your option' />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Button floated='right' primary>
                        Download
                </Form.Button>
                </Form.Group>
            </Form>
        </Segment>
    );

class ExportDownloadContainer extends React.Component {
    state = {
        exportTo: 'excel',
        exportOption: 'all'
    }

    onChange = (_, { name, value }) => {
        this.setState({
            [name]: value
        })
    }

    render() {
        return (
            <ExportDownload
                {...this.state}
                onChange={this.onChange}
            />
        )
    }
}

export default ExportDownloadContainer;
