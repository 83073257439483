import React, { useState } from 'react';
import { Table, ClearableCheckboxDropdown } from 'dyl-components';
import { Table as SemanticTable } from 'semantic-ui-react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Header = ({ search }) => {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const status = params.get('active');
    const [filters, setFilters] = useState();

    const onChange = (_, { value: filters = [] }) => {
        const query = new URLSearchParams(params);
        if (search.trim()) {
            query.set('search', search);
        }
        const noFiltersInPlace = filters.length === 0 || filters.length === 2 || filters.find(filter => filter === 0);
        if (noFiltersInPlace) {
            query.delete('active');
            const query_string = query.toString();
            setFilters(filters);
            navigate(`/settings/web-forms${query_string ? `?${query_string}` : ''}`);
            
        } else {
            const active = filters.findIndex(filter => filter === 0) !== -1;
            query.set('active', active);
            const query_string = query.toString();
            setFilters(filters);
            navigate(`/settings/web-forms${query_string ? `?${query_string}` : ''}`);
        }
    }

    return (
        <Table.Row>
            <SemanticTable.HeaderCell width={3}>Internal Form Name</SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={3}>Form Display Name</SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={3}> Created By </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={1}>Entries</SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={2}>
                <ClearableCheckboxDropdown
                    text='Status'
                    name='selectedStatus'
                    onChange={onChange}
                    onRead={() => {}}
                    value={status ? (
                        status === 'true' ? [0] : [1]
                    ) : filters}
                    options={[{ key: 0, value: 0, text: 'Active' }, { key: 1, value: 1, text: 'Inactive' }]} />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={1}>Actions</SemanticTable.HeaderCell>
        </Table.Row>
    );
};

export default Header;
