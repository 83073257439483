import React from 'react';
import { DateTimeInput, ButtonLink, STATUS_TYPES, Notification, StyledInputWrapper } from 'dyl-components';
import { Button, Form, Grid, Input, TextArea, Select, Icon } from 'semantic-ui-react';

import selfReportActions from 'actions/self_report';
import { connect } from 'react-redux';
import UserSearchField from 'shared/forms/UserSearchField';
import { DateTimeUtils } from 'dyl-components';

const initialState = {
    activityType: 'call',

    assigned_user: null,
    date: '',
    time: '',
    direction: 'outbound',
    note: '',

    result: 'reached',
    numberOfHours: '',
    numberOfMinutes: '',

    message: '',

    subject: '',
    body: ''
}

class ActivityForm extends React.Component {
    state = {
        ...initialState
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onAdd = () => {
        const {
            activityType,
            assigned_user: user_id,
            date,
            time,
            direction,
            note: notes,

            result,
            numberOfHours: hh,
            numberOfMinutes: mm,

            message: messages,

            subject,
            body
        } = this.state;
        const { from, mainEmail, mainPhone, contact_id } = this.props;
        const occurred = DateTimeUtils.getUnixTime(`${date} ${time}`, DateTimeUtils.DATETIME_FORMAT);
        const payload = {
            user_id,
            occurred,
            direction,
            notes,
            contact_id,
            from,
            to: activityType === 'email' ? mainEmail : mainPhone,
            result: this.isFuture() ? 'upcoming' : result,
            length: DateTimeUtils.convertTimeToSeconds(`${hh || 0}:${mm || 0}`, 'HH:mm'),
            messages,
            subject,
            body
        };
        
        this.props.addActivity(payload, activityType).then(() => {
            Notification.alert('Successfully added activity!', STATUS_TYPES.SUCCESS, true);
            this.onClear();
            this.props.readActivityLog(contact_id);
        }).catch(() => {
            Notification.alert('Failed to add activity', STATUS_TYPES.ERROR, true);
        });
    }

    isFuture = () => {
        const { date, time } = this.state;
        return DateTimeUtils.isValid(date) && DateTimeUtils.isValid(time, DateTimeUtils.TIME_FORMAT) && !DateTimeUtils.isBeforeOrOnCurrentDate(`${date} ${time}`, DateTimeUtils.DATETIME_FORMAT)
    }

    onClear = () => {
        this.setState({ ...initialState, assigned_user: this.props.assigned_user });
    }

    renderForm = () => {
        const {
            activityType,
            date,
            time,
            direction,
            note,

            result,
            numberOfHours,
            numberOfMinutes,

            message,

            subject,
            body
        } = this.state;
        const isFuture = this.isFuture();
        return (
            <Grid className='ActivityFormBody' stackable>
                <Grid.Row columns='equal' className='ActivityFormBody__Row'>
                    <Grid.Column width={8}>
                        <StyledInputWrapper>
                            <Select
                                name='activityType'
                                value={activityType}
                                options={[
                                    { key: 'call', value: 'call', text: 'Call' },
                                    { key: 'text', value: 'text', text: 'Text' },
                                    { key: 'email', value: 'email', text: 'Email' }
                                ]}
                                placeholder='Type'
                                onChange={this.onChange}
                                selectOnBlur={false}
                                fluid
                            />
                        </StyledInputWrapper>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <DateTimeInput dateFormat={'MM-DD-YYYY'} dateName='date' minDate="01-01-1970" date={date} timeName='time' time={time} onChange={this.onChange} isTimeInputOpen dateTimeTrigger={<ButtonLink className='ActivityFormBody__rescheduleButton'>Reschedule</ButtonLink>} />
                        <StyledInputWrapper>
                            <DateTimeInput dateFormat={'MM-DD-YYYY'} dateName='date' minDate="01-01-1970" date={date} timeName='time' time={time} onChange={this.onChange} style={{ left: 80 }} />
                        </StyledInputWrapper>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal' className='ActivityFormBody__Row'>
                    <Grid.Column width={8}>
                        <StyledInputWrapper>
                            <UserSearchField
                                name='assigned_user'
                                onChange={this.onChange}
                                placeholder='Assigned To'
                                excluded_users={[]}
                                searchValue=''
                            />
                        </StyledInputWrapper>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <StyledInputWrapper>
                            <Select
                                options={[
                                    { key: 'outbound', value: 'outbound', text: 'Outbound' },
                                    { key: 'inbound', value: 'inbound', text: 'Inbound' }
                                ]}
                                value={direction}
                                onChange={this.onChange}
                                name='direction'
                                fluid
                                placeholder='Activity'
                            />
                        </StyledInputWrapper>
                    </Grid.Column>
                </Grid.Row>
                {activityType === 'call' && (
                    <Grid.Row columns='equal' className='ActivityFormBody__Row'>
                        <Grid.Column>
                            <StyledInputWrapper>
                                <Select
                                    options={[
                                        { key: 'reached', value: 'reached', text: 'Reached' },
                                        { key: 'not available', value: 'not available', text: 'Not Available' },
                                        { key: 'missed', value: 'missed', text: 'Missed' },
                                        { key: 'voicemail', value: 'voicemail', text: 'Voicemail' },
                                        { key: 'do not call', value: 'do not call', text: 'Do not Call' },
                                        ...(!isFuture ? [] : [{ key: 'upcoming', value: 'upcoming', text: 'Upcoming' }])
                                    ]}
                                    value={!isFuture ? result : 'upcoming'}
                                    onChange={this.onChange}
                                    name='result'
                                    disabled={isFuture}
                                    fluid
                                />
                            </StyledInputWrapper>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <StyledInputWrapper>
                                <Input
                                    fluid
                                    type='number'
                                    min="0"
                                    value={numberOfHours}
                                    name='numberOfHours'
                                    placeholder='Hours'
                                    step="1"
                                    onChange={this.onChange}
                                />
                            </StyledInputWrapper>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <StyledInputWrapper>
                                <Input
                                    fluid
                                    type='number'
                                    min="0"
                                    step="1"
                                    value={numberOfMinutes}
                                    name='numberOfMinutes'
                                    placeholder='Minutes'
                                    max="59"
                                    onChange={this.onChange}
                                />
                            </StyledInputWrapper>
                        </Grid.Column>
                    </Grid.Row>
                )}
                {activityType === 'text' && (
                    <Grid.Row columns='equal' className='ActivityFormBody__Row'>
                        <Grid.Column>
                            <StyledInputWrapper className='ActivityFormBody__textArea'>
                                <Form>
                                    <TextArea
                                        value={message}
                                        onChange={this.onChange}
                                        name="message"
                                        placeholder="Add Message"
                                        inline
                                        rows={4}
                                        size='tiny'
                                    />
                                </Form>
                            </StyledInputWrapper>
                        </Grid.Column>
                    </Grid.Row>
                )}
                {activityType === 'email' && (
                    <Grid.Row columns='equal' className='ActivityFormBody__Row'>
                        <Grid.Column>
                            <StyledInputWrapper>
                                <Input
                                    value={subject}
                                    onChange={this.onChange}
                                    name="subject"
                                    placeholder="Add Subject"
                                />
                            </StyledInputWrapper>
                        </Grid.Column>
                    </Grid.Row>
                )}
                {activityType === 'email' && (
                    <Grid.Row columns='equal' className='ActivityFormBody__Row'>
                        <Grid.Column>
                            <StyledInputWrapper className='ActivityFormBody__textArea'>
                                <Form>
                                    <TextArea
                                        value={body}
                                        onChange={this.onChange}
                                        name="body"
                                        placeholder="Add Body"
                                        inline
                                        rows={4}
                                        size='tiny'
                                    />
                                </Form>
                            </StyledInputWrapper>
                        </Grid.Column>
                    </Grid.Row>
                )}
                <Grid.Row columns='equal' className='ActivityFormBody__Row'>
                    <Grid.Column>
                        <StyledInputWrapper className='ActivityFormBody__textArea'>
                            <Form>
                                <TextArea
                                    value={note}
                                    onChange={this.onChange}
                                    name="note"
                                    placeholder="Add Note"
                                    inline
                                    size='tiny'
                                    rows={4}
                                />
                            </Form>
                        </StyledInputWrapper>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal' className='FormTab__Row'>
                    <Grid.Column textAlign='right' className='ActivityFormFooter'>
                        {!this.props.isCreatingActivity && <Button onClick={this.onClear} basic icon={<Icon className='far fa-eraser' />} />}
                        <Button loading={this.props.isCreatingActivity} onClick={this.onAdd} content='Save' primary />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

    render() {
        return (
            this.renderForm()
        )
    }
}

const mapStateToProps = state => {

    const { first_name = '', last_name = '' } = state.users.userProfile;
    let mainPhone = state.contact_phone.phone.find(item => item.main);

    if (!mainPhone) {
        mainPhone = state.contact_phone.phone[0] || { phone: 'No main phone' }
    }

    let mainEmail = state.contact.email.find(item => item.main);

    if (!mainEmail) {
        mainEmail = state.contact.email[0] || { email: 'No main email' }
    }

    return ({
        isCreatingActivity: state.self_report.isLoggingCall || state.self_report.isLoggingText || state.self_report.isLoggingEmail,
        isReading: state.users.isReadingUsers,
        contact_id: Number(state.contact.contact_id),
        from: `${first_name} ${last_name}`,
        assigned_user: state.auth.user_id,
        mainEmail: mainEmail.email,
        mainPhone: mainPhone.phone
    });
};

const mapDispatchToProps = dispatch => ({
    addActivity: (payload, type) => {
        const { user_id, occurred, direction, notes, contact_id, from, to } = payload;
        const commonActivityProps = { user_id, occurred, direction, notes, contact_id, source: from, destination: to };

        switch (type) {
            case 'call':
                const { result, length } = payload;
                return dispatch(selfReportActions.addCallActivity({ ...commonActivityProps, result, length }));
            case 'text':
                const { messages } = payload;
                return dispatch(selfReportActions.addTextActivity({ ...commonActivityProps, messages }));
            case 'email':
                const { subject, body } = payload;
                return dispatch(selfReportActions.addEmailActivity({ ...commonActivityProps, subject, body }));
            default:
                Notification.alert(`Failed to add activity of type ${type}`, STATUS_TYPES.ERROR, true);
                return Promise.reject();
        }
    },
    readActivityLog: (contact_id) => {
        dispatch(selfReportActions.readActivity({ contact_id, occurred: DateTimeUtils.getCurrentDate(null, true, false) }))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityForm);
