import { Table, TableLoader, PriorityIcon, TemperatureIcon } from 'dyl-components';
import { Button, Form, Icon, Table as SemanticTable } from 'semantic-ui-react';
import React from 'react';

import './MappingTable.scss';
import { Controller } from 'react-hook-form';
import { FIELD_TYPE_CONTROL } from './DYLFieldsConstants';
import { StringUtils } from 'utils';
import { useSelector } from 'react-redux';

const MappingTable = ({
    fields,
    isMerging,
    fieldOptions,
    dataMappingControl,

    watchedFields,
    watchedDifferentiator,
    updateField,
    trigger
}) => {
    const isReadingMappingFields = useSelector(state => state.custom_fields.isReadingMappingFields);
    return (
        <Form className='MappingTable'>
            <Table striped={false} unstackable>
                <SemanticTable.Header className='Table__header--primary'>
                    <Table.Row>
                        <SemanticTable.HeaderCell width={1} />
                        {watchedDifferentiator === 'field' &&
                            <SemanticTable.HeaderCell width={1}>
                                Diff.
                            </SemanticTable.HeaderCell>
                        }
                        <SemanticTable.HeaderCell width={1} />
                        <SemanticTable.HeaderCell width={2}>
                            Field name
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell width={2}>
                            Field value example
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell width={3}>
                            DYL Field
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell width={1}>
                            Type
                        </SemanticTable.HeaderCell>
                        {isMerging && (
                            <SemanticTable.HeaderCell width={4}>
                                Options
                            </SemanticTable.HeaderCell>
                        )}
                        <SemanticTable.HeaderCell width={3}>
                            Fill in blank file values
                        </SemanticTable.HeaderCell>
                    </Table.Row>
                </SemanticTable.Header>
                <Table.Body>
                {isReadingMappingFields ? (
                        <TableLoader isLoading colspan={isMerging ? 8 : 7} />
                    ) : fields.map((field, index) => {
                        return (
                        <Controller
                            control={dataMappingControl}
                            name={`fields[${index}].skipped`}
                            render={({ field: { name: skippedFieldName, value: skippedFieldValue, onChange: skippedFieldOnChange }}) => (
                                <Controller
                                    control={dataMappingControl}
                                    name={`fields[${index}].dyl_field`}
                                    render={({ field: { name: dylFieldName, value: dylFieldValue, onChange: dylFieldOnChange }, fieldState: { invalid, isTouched, isDirty, error } }) => {
                                        const dylFieldFieldType = fieldOptions.find(option => option.value === field.dyl_field)?.field_type || 'text';
                                        const defaultOptions = watchedFields[index].options || [];
                                        return (
                                            <Table.Row key={field.id}>
                                                <Table.Cell>
                                                    <Icon link onClick={() => { skippedFieldOnChange({ target: { name: skippedFieldName, value: !skippedFieldValue } }); trigger('fields');}} className={`fas fa-${skippedFieldValue ? 'undo' : 'minus-circle'}`} {...(!skippedFieldValue ? { color: 'red' } : {})} />
                                                </Table.Cell>
                                                {watchedDifferentiator === 'field' &&
                                                    <Table.Cell>
                                                        <Controller
                                                            control={dataMappingControl}
                                                            name='differentiator_field_name'
                                                            render={({ field: { name, value, onChange } }) => (
                                                                    <Form.Radio
                                                                        checked={value === field.name}
                                                                        onClick={() => { onChange({ target: { name, value: field.name } }); }}
                                                                    />
                                                            )}
                                                        />
                                                    </Table.Cell>
                                                }
                                                <Table.Cell disabled={skippedFieldValue}>
                                                    {index + 1}
                                                </Table.Cell>
                                                <Table.Cell disabled={skippedFieldValue}>
                                                    {field.name}
                                                </Table.Cell>
                                                <Table.Cell disabled={skippedFieldValue}>
                                                    {field.value}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {!skippedFieldValue && (
                                                        <Form.Dropdown
                                                            selectOnBlur={false}
                                                            clearable
                                                            error={error?.message}
                                                            name={dylFieldName}
                                                            onChange={(_, { value }) => {
                                                                const updatedField = fieldOptions.find(option => option.value === value);
                                                                dylFieldOnChange({ target: { name: dylFieldName, value } });
                                                                updateField(index, { 
                                                                    ...watchedFields[index], 
                                                                    default_value: '', 
                                                                    options: updatedField?.options ? updatedField.options : '',
                                                                    field_type: value !== '' ? 
                                                                        updatedField?.field_type || 'text'
                                                                                : value 
                                                                });
                                                                trigger('fields');
                                                            }}
                                                            value={field.dyl_field || dylFieldValue}
                                                            search={(options, query) => {
                                                                const re = new RegExp(StringUtils.escapeRegExp(query), 'i')
                                                                const initialResults = options.filter((opt) => opt.primary_header || opt.header || re.test(opt.text));
                                                                return initialResults.filter((option, index) => {
                                                                    const isASuboption = !option.header;
                                                                    const headerHasSuboptions = !(index === initialResults.length - 1 || (option.primary_header && initialResults[index + 1].primary_header) || initialResults[index + 1].header || option.text !== initialResults[index + 1].parent);
                                                                    return isASuboption || headerHasSuboptions;
                                                                })
                                                            }}
                                                            options={fieldOptions}
                                                            selection
                                                            placeholder='Select DYL Field'
                                                        />
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {!skippedFieldValue && StringUtils.capitalize(field.field_type)}
                                                </Table.Cell>
                                                {isMerging && (
                                                    <Table.Cell>
                                                        {!skippedFieldValue && (field.dyl_field || dylFieldValue) && (
                                                            <Controller
                                                                control={dataMappingControl}
                                                                name={`fields[${index}].duplicateProcess`}
                                                                render={({ field: { name, value, onChange } }) => (
                                                                    <Form.Group style={{ marginBottom: "-0.1em" }} widths={3}>
                                                                        <Form.Radio
                                                                            label='Keep'
                                                                            checked={value === 'keep'}
                                                                            onClick={() => { onChange({ target: { name, value: 'keep' } }); }} />
                                                                        <Form.Radio
                                                                            label='Replace'
                                                                            checked={value === 'replace'}
                                                                            onClick={() => { onChange({ target: { name, value: 'replace' } }); }}
                                                                        />
                                                                        <Form.Radio
                                                                            label='Add'
                                                                            checked={value === 'add'}
                                                                            onClick={() => { onChange({ target: { name, value: 'add' } }); }}
                                                                        />
                                                                    </Form.Group>
                                                                )}
                                                            />
                                                        )}
                                                    </Table.Cell>
                                                )}
                                                <Table.Cell>
                                                    {(!skippedFieldValue && (
                                                        !field.dyl_field ? (
                                                            <Button fluid disabled>Select DYL Field First</Button>
                                                        ) : (
                                                            dylFieldFieldType === "dropdown" || dylFieldFieldType === "tagsinput" || dylFieldFieldType === "picklist" || dylFieldFieldType === "rating" ?

                                                                <Controller

                                                                    name={`fields[${index}].default_value`}
                                                                    control={dataMappingControl}
                                                                    render={({ field: { onChange, value, name }, fieldState: { error } }) =>
                                                                        <Form.Dropdown
                                                                            control={FIELD_TYPE_CONTROL[dylFieldFieldType]}
                                                                            options={defaultOptions.map(option => ({
                                                                                ...option,
                                                                                content: (
                                                                                    (field.dyl_field === 'lead.custom_data.fields.priority' || field.dyl_field === 'opportunity.custom_data.fields.confidence_level' ?
                                                                                        <span><PriorityIcon type={option.text?.toLowerCase()} /> {option.text}</span> : 
                                                                                        (field.dyl_field === 'lead.custom_data.fields.rating' ? 
                                                                                            <span><TemperatureIcon type={option.text?.toLowerCase()} /> {option.text}</span> : <span>{option.text}</span>
                                                                                        ) 
                                                                                    )
                                                                                )
                                                                            }))}
                                                                            onChange={(_, { value: selected }) => { 
                                                                                onChange({ target: { name: name, value: selected } });
                                                                                updateField(index, { 
                                                                                    ...watchedFields[index], 
                                                                                    default_value: selected
                                                                                });
                                                                            }}                                                                     
                                                                            //if allowAdditions prop
                                                                            onAddItem={() => {
                                                                                updateField(index, { 
                                                                                    ...watchedFields[index], 
                                                                                    default_value: watchedFields[index].default_value
                                                                                });
                                                                            }}
                                                                            placeholder='Leave empty'
                                                                            value={value}
                                                                            error={error?.message}
                                                                        />
                                                                    }
                                                                />
                                                            : dylFieldFieldType === "dependency" ?
                                                                <Controller
                                                                    name={`fields[${index}].default_value.parent`}
                                                                    control={dataMappingControl}
                                                                    render={({ field: { name: parentName, value: parentValue, onChange: parentOnChange } }) => (
                                                                        <Controller
                                                                            name={`fields[${index}].default_value.child`}
                                                                            control={dataMappingControl}
                                                                            render={({ field: { name: childName, value: childValue, onChange: childOnChange } }) => (
                                                                                <Form.Dropdown
                                                                                    control={FIELD_TYPE_CONTROL[dylFieldFieldType]}
                                                                                    nested_options={defaultOptions}
                                                                                    onChange={(_, { parent_value: parent, child_value: child }) => {
                                                                                        parentOnChange({ target: { name: parentName, value: parent } });
                                                                                        childOnChange({ target: { name: childName, value: child } });
                                                                                        if(parent === null && child === null){
                                                                                            updateField(index, { 
                                                                                                ...watchedFields[index], 
                                                                                                default_value: ''
                                                                                            });
                                                                                        } else {
                                                                                            updateField(index, { 
                                                                                                ...watchedFields[index],
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                    child_value={childValue}
                                                                                    parent_value={parentValue}
                                                                                    placeholder='Leave empty'
                                                                                    error={error?.message}
                                                                                />
                                                                            )}
                                                                        />
                                                                    )}
                                                                />
                                                            :
                                                            <Controller
                                                                name={`fields[${index}].default_value`}
                                                                control={dataMappingControl}
                                                                render={
                                                                    ({ field: { name, value, onChange }, fieldState: { error } }) => (
                                                                        <Form.Field
                                                                            control={FIELD_TYPE_CONTROL[dylFieldFieldType]}
                                                                            onChange={(_, { value }) => { 
                                                                                onChange({ target: { name, value } });
                                                                            }}
                                                                            name={name}
                                                                            value={value}
                                                                            error={error?.message}
                                                                            placeholder='Leave empty'
                                                                        />
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    ))}
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    }} />
                            )} />
                    )})}
                </Table.Body>
            </Table>
        </Form>
    )
};

export default MappingTable;
