export default class AccountUtils {
    static roleOptions = [
        { key: 'account admin', value: 'account admin', text: 'Account Admin' },
        { key: 'champion', value: 'champion', text: 'Champion' },
        { key: 'decision maker', value: 'decision maker', text: 'Decision Maker' },
        { key: 'influencer', value: 'influencer', text: 'Influencer' },
        { key: 'owner', value: 'owner', text: 'Owner' },
        { key: 'user', value: 'user', text: 'User' }
    ]

    static getModulePipeline(module){
        switch(module){
            case 'Lead':
                return 'lead';
            case 'Opportunity':
                return 'opportunity';
            case 'Customer':
                return 'customer';
            default:
                return null;
        }
    }
}
