import { Table } from "dyl-components";
import React from "react";

const QuoteItemAddition = ({ label, description, price, sum, action, quantity, disabled, hasMargin = true }) => {
    return (
        <Table.Row>
            <Table.Cell>
                <div {...hasMargin ? { style: { marginLeft: "1em" } } : {}}>{label}</div>
            </Table.Cell>
            <Table.Cell disabled={disabled}>{description}</Table.Cell>
            <Table.Cell />
            <Table.Cell disabled={disabled}>{price}</Table.Cell>
            <Table.Cell disabled={disabled}>{quantity}</Table.Cell>
            <Table.Cell disabled={disabled}>{sum}</Table.Cell>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell disabled={disabled}>{action}</Table.Cell>
        </Table.Row>
    );
};

export default QuoteItemAddition;
