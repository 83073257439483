import productsActions from "actions/products";
import { DateTimeUtils, Notification, STATUS_TYPES, generateResolver } from "dyl-components";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ProductForm from "shared/forms/ProductForm";
import { formatProductDetails } from "shared/schemas/products/helper";
import productSchema from "shared/schemas/products/productSchema";

const AddProduct = () => {
    const location = useLocation();
    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            name: "",
            product_type: null,
            description: "",
            image: null,
            status: "active",
            active_date: DateTimeUtils.getCurrentDate(DateTimeUtils.DATE_FORMAT),
            price_data: {
                model: null,
                schedule: null,
                price: null,
                volume_prices: null
            },
            product_category: {
                name: location.state?.product_category || ""
            },
            max_discount: 0
        },
        resolver: generateResolver(productSchema)
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loading = useSelector(state => state.products.isCreating);

    const onSave = async (data) => {
        try {
            await dispatch(productsActions.addProduct(formatProductDetails(data)));
            Notification.alert("Succesfully created product", STATUS_TYPES.SUCCESS);
            navigate("/settings/product-catalog-quotes-invoices/product-catalog", { replace: true, state: { saved: true } });
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to create product", STATUS_TYPES.ERROR);
        }
    }


    return (
        <FormProvider {...methods}>
            <ProductForm onSave={onSave} loading={loading} />
        </FormProvider>
    )
}

export default AddProduct;
