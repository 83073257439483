import { useState } from 'react';
import { Table as SemanticTable } from 'semantic-ui-react';

import { Icon, Table, TimestampFilters } from 'dyl-components';

const SortActions = ({ isFilterSelected, direction = false, onToggleSort = () => { } }) => {
    return (
        <div
            onClick={onToggleSort}
            style={{ display: "inline-flex", cursor: "pointer" }}
        >
            <Icon
                name="fa-solid fa-arrow-down-long"
                style={{
                    marginRight: -7,
                    opacity: isFilterSelected && !direction ? "100%" : "50%",
                }}
            />
            <Icon
                name="fa-solid fa-arrow-up-long"
                style={{ opacity: isFilterSelected && direction ? "100%" : "50%" }}
            />
        </div>
    );
};

const Header = ({ useRangeCalendar, onToggleTimeUnit, getTimeData,sortType, sortDirection, onToggleSort }) => {
    const [areTimeUnitOptionsOpen, setTimeUnitOptionsOpen] = useState(false);

    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        dateRange,
        setDateRange,
    } = useRangeCalendar;

    return (
        <Table.CollapsibleRow isHeader>
            <SemanticTable.HeaderCell className='OrdersTable__MediumFixedWidth' width={3}>
                <span style={{ marginRight: 5 }}>Created</span>
                <TimestampFilters
                    timestamp={timestamp}
                    onToggleTimeUnit={(timestamp) => {
                        onToggleTimeUnit(timestamp, setTimestamp, setTimeUnitOptionsOpen);
                    }}
                    dateRange={dateRange}
                    onDateRangeChange={(_, { value }) => {
                        setDateRange(value);
                    }}
                    getData={() => {
                        getTimeData(setCustomDateRangeOpen, dateRange);
                    }}
                    areTimeUnitOptionsOpen={areTimeUnitOptionsOpen}
                    onOpenTimeUnitOptions={() => {
                        setTimeUnitOptionsOpen(true);
                    }}
                    onCloseTimeUnitOptions={() => {
                        setTimeUnitOptionsOpen(false);
                    }}
                    isCustomDateRangeOpen={isCustomDateRangeOpen}
                    onOpenCustomDateRange={() => {
                        setCustomDateRangeOpen(true);
                    }}
                    onCloseCustomDateRange={() => {
                        setCustomDateRangeOpen(false);
                    }}
                />
                <SortActions
                    isFilterSelected={sortType === 'created_on'}
                    direction={sortDirection}
                    onToggleSort={() => onToggleSort('created_on')}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='OrdersTable__MediumFixedWidth' width={2}>
                <span style={{ marginRight: 5 }}>Latest Invoice</span>
                <SortActions
                    isFilterSelected={sortType === 'latest_invoice'}
                    direction={sortDirection}
                    onToggleSort={() => onToggleSort('latest_invoice')}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='OrdersTable__MediumFixedWidth' width={2}>
                Created By
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='OrdersTable__MediumFixedWidth' width={2}>
                ID
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='OrdersTable__MediumFixedWidth' width={2}>
                Status
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='OrdersTable__MediumFixedWidth' width={2}>
                Subtotal
            </SemanticTable.HeaderCell>
        </Table.CollapsibleRow>
    );
}

export default Header;
