import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function historyReducer(state = initialState, action){
    switch(action.type){
        case getNamedAction(ACTION_NAMES.LEAD_HISTORY, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return {...state, isReadingHistory: true};
        case getNamedAction(ACTION_NAMES.LEAD_HISTORY, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return {...state, isReadingHistory: false, history: action.data.data, count: action.data.count};
        case getNamedAction(ACTION_NAMES.LEAD_HISTORY, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return {...state, isReadingHistory: false};

        default:
            return state;
    }
}


export default historyReducer;
