import { generateCreateActionCreator, generateDeleteActionCreator, generateReadActionCreator, generateReadOneActionCreator, generateUpdateActionCreator } from 'actions/crudActionCreators';
import routes from 'actions/routes';
import ACTION_NAMES from 'actions/ACTION_NAMES';

const getLocations = generateReadOneActionCreator(routes.API_PROVISION_LOCATIONS, ACTION_NAMES.PROVISION_LOCATIONS);
const createLocations = generateCreateActionCreator(routes.API_PROVISION_LOCATIONS, ACTION_NAMES.PROVISION_LOCATIONS, () => {}, () => {}, 'locations');
const validateLocation = generateCreateActionCreator(routes.API_PROVISION_VALIDATE_LOCATION, ACTION_NAMES.PROVISION_VALIDATE_LOCATION);
const getSoftphoneCreds = generateReadOneActionCreator(routes.API_PROVISION_SOFTPHONES, ACTION_NAMES.PROVISION_SOFTPHONE_CREDS, 'creds')

const createSoftphone = generateCreateActionCreator(routes.API_PROVISION_SOFTPHONES, ACTION_NAMES.PROVISION_SOFTPHONE)
const updateSoftphone = generateUpdateActionCreator(routes.API_PROVISION_SOFTPHONES, ACTION_NAMES.PROVISION_SOFTPHONE)
const deleteSoftphone = generateDeleteActionCreator(routes.API_PROVISION_SOFTPHONES, ACTION_NAMES.PROVISION_SOFTPHONE)
const getSoftphone = generateReadOneActionCreator(routes.API_PROVISION_SOFTPHONES, ACTION_NAMES.PROVISION_SOFTPHONE);

const getAllDevices = generateReadActionCreator(routes.API_PROVISION_ALL_DEVICES, ACTION_NAMES.PROVISION_ALL_DEVICES);
const createDevice = generateCreateActionCreator(routes.API_PROVISION_DEVICE, ACTION_NAMES.PROVISION_DEVICE);
const getDevice = generateReadOneActionCreator(routes.API_PROVISION_DEVICE, ACTION_NAMES.PROVISION_DEVICE);
const updateDevice = generateUpdateActionCreator(routes.API_PROVISION_DEVICE, ACTION_NAMES.PROVISION_DEVICE);
const deleteDevice = generateDeleteActionCreator(routes.API_PROVISION_DEVICE, ACTION_NAMES.PROVISION_DEVICE);
const uniqueMac = generateReadActionCreator(routes.API_PROVISION_UNIQUE_MAC, ACTION_NAMES.PROVISION_UNIQUE_MAC);
const uniqueName = generateReadActionCreator(routes.API_PROVISION_UNIQUE_NAME, ACTION_NAMES.PROVISION_UNIQUE_NAME);

const removeSoftphoneCreds = () => {
    return {
        type: 'REMOVE_SOFTPHONE_CREDS',
    }
}

export default {
    getLocations,
    createLocations,
    validateLocation,

    getAllDevices,
    createDevice,
    getDevice,
    updateDevice,
    deleteDevice,

    createSoftphone,
    getSoftphone,
    updateSoftphone,
    deleteSoftphone,
    getSoftphoneCreds,
    uniqueMac,
    uniqueName,
    removeSoftphoneCreds
}
