import types from './types';
import axios from 'actions/axiosInstance';

import routes from 'actions/routes';
import { generateReadActionCreator, generateCreateActionCreator, generateReadOneActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

function addLeadNote(leadId, addNoteValue) {
    return (dispatch, getState) => {
        dispatch({
            type: types.ADD_LEAD_NOTE_REQUEST
        });

        return axios.instance1.post(routes.API_ADD_LEAD_NOTE(leadId), { text: addNoteValue })
          .then(function (response) {
            console.log(response);

            dispatch({
                type: types.ADD_LEAD_NOTE_SUCCESS,
            });

            })
          .catch(function (error) {
            console.log(error);

            dispatch({
                type: types.ADD_LEAD_NOTE_FAILURE
            });
          });

    };
}

function clearAddLeadNoteMessage() {
  return {
      type: types.CLEAR_ADD_LEAD_NOTE_MESSAGE,
  }
}

export default {
    readNotes: generateReadActionCreator(routes.API_ACCOUNT_NOTES, ACTION_NAMES.ACCOUNT_NOTES),
    readStickyNotes: generateReadActionCreator(routes.API_ACCOUNT_NOTES, ACTION_NAMES.ACCOUNT_STICKY_NOTES),
    addNote: generateCreateActionCreator(routes.API_ACCOUNT_NOTES, ACTION_NAMES.ACCOUNT_NOTES),
    addLeadNote,
    clearAddLeadNoteMessage,
    notesTab: generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_NOTES, 'notes_tab'),
    notesAccountTab: generateReadOneActionCreator(routes.API_ACCOUNT, ACTION_NAMES.ACCOUNT_NOTES, 'notes_tab'),
    notesMasterAccountTab: generateReadOneActionCreator(routes.API_MASTER_ACCOUNT, ACTION_NAMES.MASTER_ACCOUNT_NOTES, 'notes_tab'),
    getAccountNotesContacts: generateReadOneActionCreator(routes.API_ACCOUNT, ACTION_NAMES.ACCOUNT_NOTE_CONTACT, 'contacts/account'),
    getMasterAccountNotesAccount: generateReadOneActionCreator(routes.API_MASTER_ACCOUNT, ACTION_NAMES.MASTER_ACCOUNT_NOTE_CONTACT, 'account/master_account'),
};
