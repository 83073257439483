const initiaState = {
    locations: [],
    locationsCount: 0,
    isReadingLocations: false,
    isValidLocation: false,
    locationSuggestion: {},

    softphone: null,
    softphoneCreds: null,
    isReadingSoftphone: null,
    isDeletingSoftphone: false,
    isReadingSoftphoneCreds: false,

    hardphone: null,
    isReadingHardphone: false,
    isUpdatingHardphone: false,
    isDeletingHardphone: false,

    devicesCount: 0,
    isReadingDevices: false,
    devices: []
};

export default initiaState;
