import feeActions from "actions/product_fee";
import feesActions from "actions/product_fees";
import { Notification, STATUS_TYPES, generateResolver } from "dyl-components";
import { Error404, Error500 } from "pages/ErrorPages";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import feeSchema, { formatFeeDetails, getDefaultValuesFromReadingAFee } from "shared/schemas/fees/feeSchema";
import FeeForm from "shared/forms/FeeForm";
import productsActions from "actions/products";
import { StringUtils } from "utils";

const DuplicateFee = ({ type }) => {
    const { fee, isCreating } = useSelector((state) => state.product_fees);

    const methods = useForm({
        mode: "onChange",
        defaultValues: getDefaultValuesFromReadingAFee(fee),
        resolver: generateResolver(feeSchema),
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (fee) {
            const value = `Duplicate of ${fee.name}`;
            methods.setValue("name", value, {
                shouldDirty: true,
            });
            dispatch(productsActions.isDuplicate({ name: value, type })).then(isDuplicate => {
                if (isDuplicate) {
                    methods.setError("name", {
                        type: 'unique_fee',
                        message: `${StringUtils.capitalize(type)} name already exists!`
                    })
                }
            })
        }
    }, [fee, methods, dispatch, type]);

    const onSave = async (data) => {
        try {
            await dispatch(
                feesActions.addFee(formatFeeDetails(data), { type })
            );
            Notification.alert(
                `Succesfully duplicated ${type}!`,
                STATUS_TYPES.SUCCESS
            );
            methods.reset();
            navigate(
                `/settings/product-catalog-quotes-invoices/${type === "tax" ? "taxes" : "fees"}`,
                { replace: true, state: { saved: true } }
            );
        } catch (e) {
            console.log(e);
            Notification.alert(
                `Failed to duplicate ${type}`,
                STATUS_TYPES.ERROR
            );
        }
    };

    return (
        <FormProvider {...({ ...methods, type })}>
            <FeeForm onSave={onSave} loading={isCreating} />
        </FormProvider>
    );
};

const DuplicateFeeContainer = ({ type }) => {
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(feeActions.readFee(id));
    }, [id, dispatch]);

    const { feeBeingRead, feeError, fee } = useSelector(
        (state) => state.product_fees
    );

    if (feeBeingRead) {
        return (
            <Dimmer active>
                <Loader active />
            </Dimmer>
        );
    }

    if (!feeBeingRead && feeError) {
        return <Error500 message={"Something went wrong"} />;
    }

    if (!feeBeingRead && !fee) {
        return <Error404 message={"Fee not found"} />;
    }

    return <DuplicateFee type={type} />;
};

export default DuplicateFeeContainer;
