import React, { useContext } from 'react';
import { Icon } from 'semantic-ui-react';

import { FavoritesContext } from 'shared/context/FavoritesProvider';

import "./index.scss";

export const ProfileFavoriteIcon = ({
    id,
    type
}) => {
    const { onToggleFavorite, isInFavorites, isAddingFavorite, isRemovingFavorite } = useContext(FavoritesContext);

    return (
        <div className='ProfileHeader__iconsBox' onClick={() => {
            onToggleFavorite({
                external_id: Number(id),
                favorite_type: type
            })
        }}>
            <div className='ProfileHeader__iconOverlay' />
                <Icon
                    link
                    name={"star"}
                    className={`ProfileFavoriteIcon ProfileFavoriteIcon--${isInFavorites({
                        external_id: Number(id),
                        favorite_type: type
                    }) ? 'yellow' : 'grey'}`}
                    loading={isAddingFavorite || isRemovingFavorite}
                    disabled={isAddingFavorite || isRemovingFavorite}
                />
        </div>
    );
};
