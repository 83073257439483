import React from 'react';
import { TableWithHeader } from 'dyl-components';
import { DragDropContext } from 'react-beautiful-dnd';

import CallPriorityTableContainer from './CallPriorityTable';
import { ArrayUtils } from 'utils';
import CallPriorityHeader from './CallPriorityHeader';

class CallPriorityArea extends React.Component {
    state = {
        items: this.props.items,
        isEditMode: false
    }

    onChangePriority = (result) => {
        if (!result) {
            return;
        }
        let items = this.state.items.slice(0);
        let reorderedItems = ArrayUtils.reorder(items, result.source.index, result.destination.index);
        this.setState({ items: reorderedItems });
    }

    onEnableEditMode = () => {
        this.setState({
            isEditMode: true
        })
    }

    onCloseEditMode = () => {
        this.setState({
            isEditMode: false,
            items: this.props.items
        })
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false,
        }, () => {
            this.props.onUpdate(this.state.items);
        });
    }

    render() {
        return (
            <TableWithHeader 
                header={(
                    <CallPriorityHeader 
                        isEditMode={this.state.isEditMode}
                        onCloseEditMode={this.onCloseEditMode}
                        onEnableEditMode={this.onEnableEditMode}
                        onUpdate={this.onUpdate}
                    />
                )}
                table={
                    <DragDropContext 
                        onDragEnd={this.onChangePriority}
                    >
                        <CallPriorityTableContainer 
                            items={this.state.items}
                            isEditMode={this.state.isEditMode}
                        />
                    </DragDropContext>
                }
            />
        )
    }
}

export default class CallPriorityAreaContainer extends React.Component {
    state = {
        items: [
            { name: 'New Lead' },
            { name: 'Call Back' },
            { name: 'Appointment' },
            { name: 'Call Me Now' }
        ]
    }

    onUpdate = (newItems) => {
        this.setState({ items: newItems });
    }

    render() {
        return (
            <CallPriorityArea 
                {...this.state}
                onUpdate={this.onUpdate}
            />
        )
    }
}
