import { ControlledPopup, generateResolver, Notification, STATUS_TYPES, VALIDATORS } from 'dyl-components';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Form, Header } from 'semantic-ui-react';

import contactSocialsActions from 'actions/contact_socials';
import { useLocation, useParams } from 'react-router-dom';
import contactActions from 'actions/contact';
import accountActions from 'actions/account';
import { StringUtils } from 'utils';

const SOCIAL_MEDIA_TYPES = [
    { icon: 'facebook', key: 'facebook', value: 'facebook', text: 'Facebook' },
    { icon: 'linkedin', key: 'linkedin', value: 'linkedin', text: 'LinkedIn' },
    { icon: 'twitter', key: 'twitter', value: 'twitter', text: 'Twitter' }
];

const SocialMediaForm = ({
    icon,
    type,
    onChangeSocialMediaType,
    onClose,

    id,
    link,
    onDisablePopupClose,
    onEnablePopupClose,
    selectedSocials
}) => {
    const { formState: { isValid, isDirty }, handleSubmit, control } = useForm({
        mode: 'onChange',
        defaultValues: {
            link: link || ''
        },
        resolver: generateResolver({
            link: VALIDATORS.WEBSITE().required('This field is required')
        })
    });

    const { pathname } = useLocation();
    const record_type = pathname.split('/')[1];
    const { account_id, contact_id } = useParams();
    const record_id = Number(contact_id || account_id);

    const refresh = () => {
        if (record_type === 'contact') {
            dispatch(contactActions.readContact(record_id));
        } else if (record_type === 'account') {
            dispatch(accountActions.readAccount(record_id));
        }
    }

    const isSaving = useSelector(state => {
        if (id) {
            return state.contact_socials.isUpdatingContactSocialMedia;
        }
        return state.contact_socials.isCreatingContactSocialMedia;
    });

    const isDeleting = useSelector(state => {
        return state.contact_socials.socialNetworkBeingDeleted === id;
    })

    const dispatch = useDispatch();

    const onSave = async (data) => {
        const { link: url } = data;
        const payload = { type, url: StringUtils.formatLink(url) };
        try {
            if (id) {
                await dispatch(contactSocialsActions.updateContactSocial(record_id, payload, null, id))
            } else {
                await dispatch(contactSocialsActions.addContactSocial([payload], null, record_id));
            }
            Notification.alert(`Successfully ${id ? 'updated' : 'added'} ${type} account!`, STATUS_TYPES.SUCCESS, true);
            onClose();
            refresh();
        } catch {
            Notification.alert(`Failed to ${id ? 'update' : 'add'} ${type} account`, STATUS_TYPES.ERROR, true);
        }
    }

    const onRemove = async () => {
        try {
            await dispatch(contactSocialsActions.deleteContactSocial(record_id, null, id));
            Notification.alert(`Successfully removed ${type} account!`, STATUS_TYPES.SUCCESS, true);
            refresh();
        } catch (e) {
            Notification.alert(`Failed to remove ${type} account`, STATUS_TYPES.ERROR, true);
        }
    }

    return (
        <Form size='tiny'>
            <Header as='h4'>{id ? 'Edit' : 'Add'} Account Type</Header>
            <Form.Select
                disabled={id}
                options={SOCIAL_MEDIA_TYPES.filter(social_type => social_type.value === type || !selectedSocials.includes(social_type.value))}
                selectOnBlur={false}
                value={type}
                onChange={onChangeSocialMediaType}
                text={<span><Icon name={icon} />{type}</span>}
            />
            <Controller
                control={control}
                name='link'
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <Form.Input
                        size='tiny'
                        value={value}
                        onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                        placeholder='Add social media URL'
                        error={error?.message}
                    />
                )}
            />
            <Form.Group widths='equal'>
                {id && (
                    <ControlledPopup
                        trigger={
                            <Form.Button
                                onClick={() => {
                                    if (onDisablePopupClose) {
                                        onDisablePopupClose();
                                    }
                                }}
                                size='tiny'
                                disabled={isSaving || isDeleting}
                                loading={isDeleting} color='red'
                            >
                                Remove
                            </Form.Button>
                        }
                        onConfirm={onRemove}
                        onCancel={() => {
                            if (onEnablePopupClose) {
                                onEnablePopupClose();
                            }
                        }}
                    />
                )}
                <Form.Button basic primary disabled={isSaving || isDeleting} size='tiny' onClick={onClose}>
                    Cancel
                </Form.Button>
                <Form.Button disabled={!type || !isValid || !isDirty || isSaving || isDeleting} loading={isSaving} size='tiny' primary onClick={handleSubmit(onSave)}>
                    {id ? 'Save' : 'Add'}
                </Form.Button>
            </Form.Group>
        </Form>
    );
};

export default SocialMediaForm;
