import React from 'react';
import { Dropdown, Grid, Header, Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Notification, STATUS_TYPES, generateResolver, yup } from 'dyl-components';
import { CustomGroupUtils } from 'utils';

import contactActions from 'actions/contact';

import './AddInterestForm.scss';

const renderLabel = (label) => ({
    content: label.text,
    style: {
        color: '#2979ff',
        border: '1px solid #9fd6ef',
        backgroundColor: '#dbf3ff',
        borderRadius: 3
    }
})

const AddInterestField = ({ control, interests, onAddInterests, onCloseForm, isValid, isDirty, isReadingContact, isUpdatingContact }) => {
    return (
        <>
            <Header content='Add/Edit Interests' />
            <Grid>
                <Grid.Row columns='equal'>
                    <Grid.Column verticalAlign='top'>
                        <Controller
                            control={control}
                            name='newInterests'
                            render={({ field: { name, value, onChange } }) => {
                                return (
                                    <Dropdown
                                        clearable
                                        search
                                        selection
                                        multiple
                                        options={interests}
                                        onChange={(_, { value }) => {
                                            onChange({ target: { name, value } })
                                        }}
                                        name='newInterests'
                                        value={value}
                                        placeholder='Select Interest'
                                        upward={false}
                                        style={{
                                            marginRight: 10,
                                            marginBottom: 10,
                                            maxWidth: '250px'
                                        }}
                                        renderLabel={renderLabel}
                                        loading={isReadingContact}
                                        scrolling
                                    />)
                            }}
                        />
                        <Icon loading={isUpdatingContact} disabled={isUpdatingContact || !isValid || !isDirty} name={isUpdatingContact ? 'circle notch' : 'check'} className='AddInterestForm__checkButton' color='blue' link circular inverted onClick={onAddInterests} />
                        <Icon disabled={isUpdatingContact} name='x' link circular onClick={onCloseForm} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}

const AddInterstForm = ({ setIsOpen }) => {
    const dispatch = useDispatch();

    const onUpdateContact = (contact_id, updatedContactDetails) => {
        return dispatch(contactActions.updateContact(contact_id, updatedContactDetails));
    }
    const onReadContactInformation = (contact_id) => {
        dispatch(contactActions.readContact(contact_id));
    }

    const { contact, contact_id, account_id, interests, isReadingContact, isUpdatingContact, custom_data } = useSelector(state => {
        const custom_data = CustomGroupUtils.groupAndFlatten(state.contact.custom_data);
        const interests_field = (state.contact.custom_data.fields || []).find(field => field.field_name === 'interests');

        return ({
            contact: state.contact.contact,
            contact_id: Number(state.contact.contact_id),
            account_id: Number(state.contact.account_id),
            interests: interests_field?.options?.data || [],
            isReadingContact: state.contact.isReadingContact,
            isUpdatingContact: state.contact.isUpdatingContact,
            custom_data
        });
    });

    const { formState: { isValid, isDirty }, control, handleSubmit, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            newInterests: (custom_data.fields.interests || []).filter(interest => typeof interest === 'number')
        },
        resolver: generateResolver({
            newInterests: yup.array().of(yup.number())
        })
    });

    const onCloseForm = () => {
        reset();
        setIsOpen(false);
    }

    const onAddInterests = ({ newInterests }) => {
        const { first_name, last_name, suffix, contact_type, relationship, date_of_birth } = contact;

        onUpdateContact(contact_id, {
            custom_data: { ...custom_data, fields: { ...custom_data.fields, interests: newInterests } },
            first_name,
            last_name,
            suffix,
            ...(contact_type === 'person' ? { date_of_birth: date_of_birth } : {}),
            contact_type,
            relationship
        }, account_id).then(() => {
            Notification.alert('Successfully updated contact!', STATUS_TYPES.SUCCESS, true);
            onReadContactInformation(contact_id, account_id);
        }).catch(e => {
            console.log(e);
            Notification.alert('Failed to update contact', STATUS_TYPES.ERROR, true)
        })
    }

    const getInterestsWhereContactCanBeAdded = () => {
        return interests.map(interest => ({ key: interest.id, text: interest.value, value: interest.id }));
    }

    return (
        <AddInterestField
            control={control}
            interests={getInterestsWhereContactCanBeAdded()}
            onAddInterests={handleSubmit(onAddInterests)}
            onCloseForm={onCloseForm}
            isValid={isValid}
            isDirty={isDirty}
            isReadingContact={isReadingContact}
            isUpdatingContact={isUpdatingContact}
        />
    );
}

export default AddInterstForm;
