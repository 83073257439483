import React from 'react';
import { Select } from 'semantic-ui-react';
import { connect } from 'react-redux';

import textTemplateActions from 'actions/text_templates';
import emailTemplateActions from 'actions/email_templates';

export const TEMPLATE_TYPES = { SMS: 'text', EMAIL: 'email' };

const ACTIONS = {
    text: textTemplateActions,
    email: emailTemplateActions
}

export const TemplateSelector = ({ header = 'Select Template', fluid = true, selectedTemplate, templates = [], onChange, isReadingTemplates }) => (
    <Select
        value={selectedTemplate}
        name='template'
        options={templates} 
        fluid={fluid}
        onChange={onChange}
        header={header}
        placeholder={header}
        loading={isReadingTemplates}
    />
)

class TemplateSelectorContainer extends React.Component {
    componentDidMount() {
        this.props.onReadTemplates(this.props.type)
    }

    render() {
        return (
            <TemplateSelector {...this.props} />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const { type } = ownProps;
    return {
        templates: state[`${type}_templates`].templates.map(template => ({
            key: template.id,
            value: template.id,
            text: template.name
        })),
        selectedTemplate: ownProps.selectedTemplate,
        type,
        isReadingTemplates: state[`${type}_templates`].isReadingTemplates
    }
}

const mapDispatchToProps = dispatch => ({
    onReadTemplates: (type) => {
        return dispatch(ACTIONS[type].getTemplates());
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(TemplateSelectorContainer);
