const { default: ACTION_NAMES } = require("actions/ACTION_NAMES");
const { getNamedAction, default: CRUD_ACTION_TYPES } = require("actions/CRUD_ACTION_TYPES");
const { default: initialState } = require("./initialState");

function phoneNumberReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PHONE_NUMBER, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingPhoneNumbers: true };
        case getNamedAction(ACTION_NAMES.PHONE_NUMBER, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingPhoneNumbers: false, phone_numbers: action.data.data || [], count: action.data.count || 0 };
        case getNamedAction(ACTION_NAMES.PHONE_NUMBER, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingPhoneNumbers: false };

        case getNamedAction(ACTION_NAMES.PHONE_NUMBER_SEARCH, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isSearchingPhoneNumbers: true };
        case getNamedAction(ACTION_NAMES.PHONE_NUMBER_SEARCH, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isSearchingPhoneNumbers: false, search_results: action.data };
        case getNamedAction(ACTION_NAMES.PHONE_NUMBER_SEARCH, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isSearchingPhoneNumbers: false, search_results: [] };

        case getNamedAction(ACTION_NAMES.PHONE_NUMBER, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingPhoneNumber: true };
        case getNamedAction(ACTION_NAMES.PHONE_NUMBER, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAddingPhoneNumber: false };
        case getNamedAction(ACTION_NAMES.PHONE_NUMBER, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingPhoneNumber: false };

        case getNamedAction(ACTION_NAMES.PHONE_NUMBER_ORDER, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingPhoneNumber: true };
        case getNamedAction(ACTION_NAMES.PHONE_NUMBER_ORDER, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAddingPhoneNumber: false };
        case getNamedAction(ACTION_NAMES.PHONE_NUMBER_ORDER, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingPhoneNumber: false };

        case getNamedAction(ACTION_NAMES.PHONE_NUMBER_DOMAIN, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingDomains: true };
        case getNamedAction(ACTION_NAMES.PHONE_NUMBER_DOMAIN, CRUD_ACTION_TYPES.READ_SUCCESS):
            {
                const domains = action.data;
                let domain;
                if (domains && domains.length > 0) {
                    domain = domains[domains.length - 1].domain;
                }
                return { ...state, domain, isReadingDomains: false };
            }
        case getNamedAction(ACTION_NAMES.PHONE_NUMBER_DOMAIN, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingDomains: false };

        case getNamedAction(ACTION_NAMES.PHONE_NUMBER, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, phoneNumberBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.PHONE_NUMBER, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, phoneNumberBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.PHONE_NUMBER, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, phoneNumberBeingUpdated: null };

        case 'PHONE_NUMBER_MARK':
            {
                const phone_numbers = state.phone_numbers.slice(0);
                const indexOfPhoneToMark = phone_numbers.findIndex(({ phone }) => phone === action.phone);
                if (indexOfPhoneToMark !== -1) {
                    const phoneToMark = { ...phone_numbers[indexOfPhoneToMark] };
                    phoneToMark.isMarked = !phoneToMark.isMarked;
                    phone_numbers[indexOfPhoneToMark] = phoneToMark;
                }
                return { ...state, phone_numbers };
            }
        case 'PHONE_NUMBER_MARK_ALL':
            {
                const allMarked = state.allMarked;
                const phone_numbers = state.phone_numbers.slice(0).map((phoneNumber) => ({
                    ...phoneNumber,
                    isMarked: !allMarked
                }));
                return { ...state, phone_numbers, allMarked: !allMarked };
            }

        case 'PHONE_NUMBER_CLEAR_SEARCH':
            return { ...state, search_results: [] };

        default:
            return state;
    }
}

export default phoneNumberReducer;
