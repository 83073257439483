import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import withBreadcrumb from 'shared/withBreadcrumb';

const TextListBreadcrumb = () => (
    <Breadcrumb className="HeaderBreadcrumb">
        <Breadcrumb.Section as={Link} to={'/labels'}>Labels</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section>Text List</Breadcrumb.Section>
    </Breadcrumb>
);

const details = {
    type: 'folders',
    path: '/sms_list',
    title: 'Text List'
}

export default withBreadcrumb(TextListBreadcrumb, details);
