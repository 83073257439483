import React from 'react';
import { Grid, Statistic } from 'semantic-ui-react';

export const MasterAccountStatistics = ({
    accounts_count = 0,
    active_customers_count = 0,
    inactive_customers_count = 0
}) => (
    <Grid verticalAlign='middle' textAlign='center'>
        <Grid.Row style={{ paddingBottom: 0, marginBottom: 0 }} columns='equal'>
            <Grid.Column>
                <Statistic size='tiny' color='primary'>
                    <Statistic.Value>{accounts_count}</Statistic.Value>
                </Statistic>
            </Grid.Column>
            <Grid.Column>
                <Statistic size='tiny' color='primary'>
                    <Statistic.Value>{active_customers_count}</Statistic.Value>
                </Statistic>
            </Grid.Column>
            <Grid.Column>
                <Statistic size='tiny' color='primary'>
                    <Statistic.Value>{inactive_customers_count}</Statistic.Value>
                </Statistic>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0, marginTop: 0 }} columns='equal'>
            <Grid.Column>
                Account
            </Grid.Column>
            <Grid.Column>
                <div>
                    Customer
                </div>
                <div>
                    <small>Active</small>
                </div>
            </Grid.Column>
            <Grid.Column>
                <div>
                    Customer
                </div>
                <div>
                    <small>Deactivated</small>
                </div>
            </Grid.Column>
        </Grid.Row>
    </Grid>
)
