import React, { useState } from "react";
import { Table, EllipsisDropdown } from "dyl-components";
import { Dropdown, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { StringUtils } from "utils";
import ChangePasswordModal from "shared/modals/ChangePasswordModal";
import DeactivateReactivateModal from "shared/modals/DeactivateReactivateModal";

const UserActions = ({
    isActive,
    isConfirmingResetAction,
    isLoggedinUserAcct,
    onOpenChangePasswordModal,
    onOpenReactivateDeactivateModal,
}) => (
    <React.Fragment>
        <EllipsisDropdown>
            <Dropdown.Item
                icon={<i className="fa-solid fa-key DropdownIcon"></i>}
                text="Reset password"
                style={{ cursor: "pointer" }}
                onClick={onOpenChangePasswordModal}
            />
            {!isConfirmingResetAction && !isLoggedinUserAcct && (
                <Dropdown.Item
                    icon={
                        isActive ? (
                            <i className="fa-solid fa-user-slash DropdownIcon"></i>
                        ) : (
                            <i className="fa-solid fa-user DropdownIcon"></i>
                        )
                    }
                    text={isActive ? "Deactivate" : "Reactivate"}
                    style={{ cursor: "pointer" }}
                    onClick={onOpenReactivateDeactivateModal}
                />
            )}
        </EllipsisDropdown>
    </React.Fragment>
);

const UserAccountTableRow = ({
    userAccount,
    userBeingDeleted,
    isViewUserDisabled,
    isConfirmingResetAction,
    loggedInUser,
    onOpenChangePasswordModal,
    onOpenReactivateDeactivateModal,
}) => {
    const isLoggedinUserAcct = userAccount.user_id === loggedInUser;
    const name = `${userAccount.first_name} ${userAccount.last_name}`;

    return (
        <Table.Row>
            <Table.Cell width={4}>
                {isViewUserDisabled ? (
                    name
                ) : (
                    <Link to={`/settings/users/${userAccount.user_id}/general`}>
                        {userAccount.first_name} {userAccount.last_name}
                    </Link>
                )}
            </Table.Cell>
            <Table.Cell width={4}>{userAccount.email}</Table.Cell>
            <Table.Cell width={2}>
                {userAccount.teams &&
                    userAccount.teams.map(({ name }) => name).join(", ")}
            </Table.Cell>
            <Table.Cell width={2} className="tempCap">
                {userAccount.access_role &&
                    StringUtils.capitalize(userAccount.access_role.name)}
            </Table.Cell>
            {/* <Table.Cell width={2}>{userAccount.teams && userAccount.teams.map(({ name }) => name).join(', ')}</Table.Cell> */}
            {/* {isAccessible && <Table.Cell width={2}>{StringUtils.capitalize(userAccount.access_role.name)}</Table.Cell>} */}
            {/* TODO: Map properties when they are ready
                <Table.Cell>{userAccount.line}</Table.Cell>
                <Table.Cell>{userAccount.callerId}</Table.Cell> 
            */}
            <Table.Cell width={2}>
                {userAccount.active ? "Active" : "Inactive"}
            </Table.Cell>
            <Table.Cell width={2}>
                <UserActions
                    userId={userAccount.user_id}
                    isSelectedEmail={userAccount.email}
                    isSelectedName={name}
                    isActive={userAccount.active}
                    isConfirmingResetAction={isConfirmingResetAction}
                    isLoggedinUserAcct={isLoggedinUserAcct}
                    onOpenChangePasswordModal={onOpenChangePasswordModal}
                    onOpenReactivateDeactivateModal={
                        onOpenReactivateDeactivateModal
                    }
                />
                {userBeingDeleted === userAccount.user_id && (
                    <Icon name="circle notched" loading />
                )}
            </Table.Cell>
        </Table.Row>
    );
};

const UserAccountTableRowContainer = (props) => {
    const [state, setState] = useState({
        isChangePasswordModalOpen: false,
        isSelectedEmail: "",
        isSelectedName: "",
        isDeactivateReactivateOpen: false,
    });

    const onOpenReactivateDeactivateModal = (isActiveUser) => {
        setState({
            ...state,
            isDeactivateReactivateOpen: true,
        });
    };

    const onCloseDeactivateReactivateModal = () => {
        setState({
            ...state,
            isDeactivateReactivateOpen: false,
        });
    };

    const onOpenChangePasswordModal = () => {
        setState({
            ...state,
            isChangePasswordModalOpen: true,
        });
    };

    const onCloseChangePasswordModal = () => {
        setState({
            ...state,
            isChangePasswordModalOpen: false,
        });
    };

    return (
        <>
            <UserAccountTableRow
                {...props}
                {...state}
                onOpenChangePasswordModal={onOpenChangePasswordModal}
                onOpenReactivateDeactivateModal={
                    onOpenReactivateDeactivateModal
                }
            />
            <ChangePasswordModal
                open={state.isChangePasswordModalOpen}
                onClose={onCloseChangePasswordModal}
                user_id={props.loggedInUser}
                selected_user_id={props.userAccount.user_id}
            />
            <DeactivateReactivateModal
                {...props}
                open={state.isDeactivateReactivateOpen}
                onClose={onCloseDeactivateReactivateModal}
                user_id={props.loggedInUser}
                selected_user_id={props.userAccount.user_id}
                your_password={true}
                owner_password={true}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        userBeingDeleted: Number(state.users.userBeingDeleted),
        queryParameters: state.assign.queryParameters,
        loggedInUser: state.auth.user_id,
    };
};

export default connect(mapStateToProps)(UserAccountTableRowContainer);
