import React, { useEffect } from 'react';
import { Notification, STATUS_TYPES } from 'dyl-components';
import { Button, Form } from 'semantic-ui-react';

import { useForm } from 'react-hook-form';

import ConvertToCustomerFields from "./ConvertToCustomerFields";
import customersActions from "actions/customers";
import customerPipelineActions from 'actions/customer_pipeline';
import sequenceTasksActions from 'actions/sequence_tasks';
import sequencesActions from 'actions/sequences';
import contactSequenceActions from 'actions/contact_sequence';
import {useDispatch, useSelector} from "react-redux";

const ReactivateCustomerForm = ({
    onClose,

    account_id,
	customer_since,

    size,
    refresh,
	

    onModify,
    primary_contact_id
}) => {
    const { formState: { isDirty, isValid }, control, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            pipeline: null,
            stage: null
        }
    });

    const dispatch = useDispatch();

    useEffect(() => {
        onModify(isDirty);
    }, [isDirty, onModify]);

    useEffect(() => {
        dispatch(sequenceTasksActions.readContactTasks(Number(primary_contact_id)));
    }, [dispatch, primary_contact_id])

    const { contactSequenceTasks, isReadingSequenceContactTasks, selectedSequenceId, pipelineCategories } = useSelector(state => {
        return {
            contactSequenceTasks: state.sequence_tasks.selectedSequenceTasks,
            isReadingSequenceContactTasks: state.sequence_tasks.isReadingSequenceContactTasks,
            selectedSequenceId: state.sequence_tasks.selectedSequenceId,

            pipelineCategories: state.pipeline.categories.map(category => ({
                key: category.id,
                value: category.id,
                text: category.name,
                stages: category.stages?.map(stage => ({
                    key: stage.id,
                    value: stage.id,
                    text: stage.name,
                    sequence_id: stage.sequence_id
                })) || []
            }))
        }
    })

    const onReactivate = async (data) => {
        try {
            await dispatch(customersActions.createCustomer({
                account_id: Number(account_id),
				customer_since
            }));
            const areSequenceTasksCompleted = contactSequenceTasks.length === 0 || contactSequenceTasks.every(task => task.status === 'complete' || task.status === 'canceled');
            if (data.stage) {
                await dispatch(customerPipelineActions.update(account_id, {
                    pipeline_stage_id: Number(data.stage),
                    person_id: primary_contact_id,
                    previous_sequence_complete: areSequenceTasksCompleted
                }, { action: 'created' }));
                if (selectedSequenceId) {
                    await dispatch(contactSequenceActions.removeFromContact(primary_contact_id));
                }
                const category = pipelineCategories.find(category => category.value === Number(data.pipeline));
                const newStage = category?.stages.find(categoryStage => categoryStage.value === Number(data.stage));
                if (newStage.sequence_id) {
                    await dispatch(sequencesActions.addToSequence({ contact_id: primary_contact_id }, null, newStage.sequence_id));
                }
            }
            Notification.alert('Successfully reactivated customer!', STATUS_TYPES.SUCCESS);
            onClose();
            if (refresh) {
                refresh();
            }
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to reactivate customer', STATUS_TYPES.ERROR);
        }
    }

    const { isCreating } = useSelector(state => ({
        isCreating: state.customers.isCreatingCustomer || state.customer_pipeline.accountBeingUpdated || state.contact_sequence.isRemovingSequence || state.sequences.isAddingContactToSequence
    }))

    return [
        <Form loading={isCreating || isReadingSequenceContactTasks} size={size} noValidate style={{ maxHeight: "17em", width: "30em" }}>
            <ConvertToCustomerFields control={control} columns={2} pipelineName={'pipeline'} stageName={'stage'} showType={false} />
        </Form>,
        <Button floated='right' disabled={!isValid || isCreating} primary onClick={handleSubmit(onReactivate)} loading={isCreating}>Save</Button>
    ]
}

export default ReactivateCustomerForm;
