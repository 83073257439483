import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function recordAssignmentReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.ASSIGN_OWNERS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isAssigningOwner: true };
        case getNamedAction(ACTION_NAMES.ASSIGN_OWNERS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isAssigningOwner: false };
        case getNamedAction(ACTION_NAMES.ASSIGN_OWNERS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isAssigningOwner: false };

        case getNamedAction(ACTION_NAMES.ASSIGN_TEAMS_TERRITORIES, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isAssigningTeamsTerritories: true };
        case getNamedAction(ACTION_NAMES.ASSIGN_TEAMS_TERRITORIES, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isAssigningTeamsTerritories: false };
        case getNamedAction(ACTION_NAMES.ASSIGN_TEAMS_TERRITORIES, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isAssigningTeamsTerritories: false };

        default:
            return state;
    }
}
export default recordAssignmentReducer;
