import React from 'react';
import { Table, EllipsisDropdown, Notification, STATUS_TYPES, ControlledPopup } from 'dyl-components';
import { Dropdown, Icon, Image } from 'semantic-ui-react';
import { StringUtils } from 'utils';
import Integration from 'shared/Integration';
import oauthActions from 'actions/oauth';
import { useDispatch } from 'react-redux';
import { GmailSVG, OutlookSVG } from 'dyl-components';

const IntegrationRow = ({ integration }) => {

    const dispatch = useDispatch();

    const onDelete = async (integration_id) => {
        try {
            await dispatch(oauthActions.deleteIntegration(null, { integration_id }));
            dispatch(oauthActions.getIntegrations({ scopes: 'email,calendar', include_disabled: true }));
            Notification.alert('Successfully deleted an integration', STATUS_TYPES.SUCCESS);
        } catch (e) {
            Notification.alert('Failed to delete integration', STATUS_TYPES.ERROR);
        }
    }

    const isMainIntegration = integration.scopes.find(scope => scope.main) || false;

    return (
        <Table.Row className='IntegrationRow'>
            <Table.Cell width={4}>
                <Integration name={integration.name} customIcon={<Image src={integration.provider === 'google' ? GmailSVG : OutlookSVG} />} email={integration.email} />
            </Table.Cell>
            <Table.Cell width={3}>
                {
                    integration.scopes.map(({ scope_type }, index) => (
                        `${StringUtils.capitalize(scope_type)}${index !== integration.scopes.length - 1 ? ', ' : ''}`
                    ))
                }
            </Table.Cell>
            <Table.Cell width={1}>
                <EllipsisDropdown>
                    <Dropdown.Item disabled={isMainIntegration} content={
                        <ControlledPopup
                            trigger={
                                <span>
                                    <Icon className='fa-solid fa-trash-can' />
                                    Delete
                                </span>
                            }
                            onConfirm={() => { onDelete(integration.id); }}
                        />
                    }
                        style={{ cursor: 'pointer' }}
                    />
                </EllipsisDropdown>
            </Table.Cell>
        </Table.Row>
    )
}

export default IntegrationRow;
