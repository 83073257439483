import React from 'react';
import { connect } from 'react-redux';
import { ButtonLink } from 'dyl-components';

import CallRecordingSettings from './subcomponents/CallRecordingSettings';
import AccountSettingsPanel from 'shared/AccountSettingsPanel';
import callRecordingsActions from 'actions/call_recordings';

const CallRecordings = () => (
    <React.Fragment>
        <AccountSettingsPanel 
            header={'Account Settings: Call Recordings'}
            content={(
                <React.Fragment>
                    In many states recording calls without consent is against the law. 
                    Please see <ButtonLink>DYL Compliance - Call Recordings</ButtonLink> for more information.
                </React.Fragment>
            )}
        />
        <CallRecordingSettings />
    </React.Fragment>
);

class CallRecordingsContainer extends React.Component {
    componentDidMount() {
        this.props.onReadCallRecordingSettings();
    }

    render() {
        return (
            <CallRecordings />
        )
    }
}

const mapDispatchToProps = dispatch => ({
    onReadCallRecordingSettings: () => {
        dispatch(callRecordingsActions.getCallRecordingsSettingsOptions());
    }
})

export default connect(null, mapDispatchToProps)(CallRecordingsContainer);
