import React from 'react';
import { TableWithHeader } from 'dyl-components';
import WaitForBeepOptionHeader from './WaitForBeepOptionHeader';
import { Grid, Select, Popup, Icon } from 'semantic-ui-react';
import { EditableContent } from 'dyl-components';
import { LeftPaddedColumn } from 'dyl-components';

const options = [
    { key: 'auto', value: 'auto', text: 'Wait for beep automatically' },
    { key: 'manual', value: 'manual', text: 'Press voicemail button after beep' }
]

class WaitForBeepOption extends React.Component {
    state = {
        isEditMode: false,
        selectedOption: this.props.selectedOption
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onEnableEditMode = () => {
        this.setState({
            isEditMode: true
        })
    }

    onCloseEditMode = () => {
        this.setState({
            isEditMode: false,
            selectedOption: this.props.selectedOption
        })
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false,
        }, () => {
            this.props.onUpdate(this.state.selectedOption);
        });
    }

    render() {
        let { isEditMode, selectedOption } = this.state;
        return (
            <TableWithHeader 
                header={
                    <WaitForBeepOptionHeader 
                        isEditMode={isEditMode}
                        onCloseEditMode={this.onCloseEditMode}
                        onEnableEditMode={this.onEnableEditMode}
                        onUpdate={this.onUpdate}
                    />
                }
                table={
                    <Grid style={{margin: 0}}>
                        <Grid.Column as={LeftPaddedColumn}>
                            <EditableContent 
                                control={Select}
                                value={isEditMode ?  
                                    selectedOption
                                    : 
                                    <span>
                                        {options.filter(option => option.value === selectedOption)[0].text} &nbsp;
                                        <Popup 
                                            trigger={<Icon name='question circle outline' />} 
                                            content={selectedOption === 'auto' ? 'Press voicemail button at any time' : 'Message plays instantly'}
                                            inverted 
                                        />
                                    </span>
                                }
                                name={'selectedOption'}
                                options={options}
                                isEditMode={isEditMode}
                                onChange={this.onChange}
                            />
                        </Grid.Column>
                    </Grid>
                }
            />
        )
    }
}

class WaitForBeepOptionContainer extends React.Component {
    state = {
        selectedOption: 'auto'
    }

    onUpdate = (selectedOption) => {
        this.setState({ selectedOption });
    }

    render() {
        return (
            <WaitForBeepOption 
                {...this.state}
                onUpdate={this.onUpdate}
            />
        )
    }
}

export default WaitForBeepOptionContainer;
