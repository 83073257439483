import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import userActions from 'actions/users';
import { FIELD_TYPE } from './DynamicField';

import './index.scss';
import CustomDataForm from './CustomDataForm';
import { useFieldArray } from 'react-hook-form';

const CustomData = ({
    control,
    isEditing,
    numberOfColumns = 2,
    onConfirmInlineEdit,
    disabled,
    padded
}) => {
    const dispatch = useDispatch();

    const { bulkUsernamesQueryParameters } = useSelector(state => ({
        bulkUsernamesQueryParameters: state.users.bulkUsernamesQueryParameters
    }));

    const [openGroups, setOpenGroups] = useState([]);
    const [closedGroupsWhileEditing, setClosedGroupsWhileEditing] = useState([]);

    const isOpen = (key) => {
        if (isEditing){
            return !closedGroupsWhileEditing.includes(key)
        }
        else {
            return openGroups.includes(key);
        }
    };

    const onToggleGroup = (key) => {
        if (isEditing) {
            setClosedGroupsWhileEditing(
                closedGroupsWhileEditing.includes(key) ? closedGroupsWhileEditing.filter((group) => group !== key) 
                : [...closedGroupsWhileEditing, key])
        }
        else{
            setOpenGroups(
                openGroups.includes(key)
                    ? openGroups.filter((group) => group !== key)
                    : [...openGroups, key]
            );
        }
    };

    const { fields: group, insert, remove } = useFieldArray({
        name: 'children',
        control
    });

    useEffect(() => {
        if (isEditing) {
            setOpenGroups([])
        }
        else {
            setClosedGroupsWhileEditing([])
        }
    }, [isEditing, group])

    const onAddInstance = (index, group) => {
        // The + 1 is necessary to insert the element NOT before the intended element
        insert(index + 1, group)
    };

    const onRemoveInstance = (index) => {
        remove(index);
    }

    const [customFieldBeingEdited, setCustomFieldBeingEdited] = useState(null);

    const onEdit = (field_name) => {
        setCustomFieldBeingEdited(field_name);
    }

    const onCancelEdit = () => {
        setCustomFieldBeingEdited(null);
    }

    useEffect(() => {
        if (isEditing && customFieldBeingEdited) {
            onCancelEdit();
        }
    }, [isEditing, customFieldBeingEdited]);

    const readBulkUsernames = (user_ids) => {
        dispatch(userActions.readBulkUsernames({ user_ids: user_ids }));
    }

    const onFieldUpdate = (field_type, value) => {
        switch (field_type) {
            case FIELD_TYPE.USER:
                const user_ids = new Set(bulkUsernamesQueryParameters?.user_ids || []);
                user_ids.add(value);
                readBulkUsernames([...user_ids])
                break;
            default:
        }
    }

    useEffect(() => {
        if (!isEditing) {
            const user_ids = [];
            const getUserFieldsValue = (fields) => {
                fields.forEach(field => {
                    if (field?.field_type === 'user' && (field?.value !== '' && Boolean(field?.value)))
                        user_ids.push(field?.value);
                });
            }

            (group || []).forEach(groupItem => {
                (groupItem?.children || []).forEach(child => {
                    getUserFieldsValue((child?.fields || []));
                })

                getUserFieldsValue((groupItem?.fields || []));
            });

            if (user_ids.length !== 0) readBulkUsernames(user_ids);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group, isEditing, dispatch]);



    return (
        <div className={`${padded ? 'CustomData--padded' : ''}`}>
            <CustomDataForm
                group={group}
                control={control}
                isOpen={isOpen}
                parent="children"
                onToggleGroup={onToggleGroup}
                onAddInstance={onAddInstance}
                onRemoveInstance={onRemoveInstance}
                isViewing={!isEditing}
                number_of_columns={numberOfColumns}
                customFieldBeingEdited={customFieldBeingEdited}
                onEdit={onEdit}
                onCancelEdit={onCancelEdit}
                onConfirmInlineEdit={onConfirmInlineEdit}
                onFieldUpdate={onFieldUpdate}
                disabled={disabled}
            />
        </div>
    );
}

export default CustomData;

