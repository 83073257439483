import initialState from './initialState';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';

function roleReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.ACCESS_ROLE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingRole: true }
        case getNamedAction(ACTION_NAMES.ACCESS_ROLE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingRole: false, userPermissions: action.data }
        case getNamedAction(ACTION_NAMES.ACCESS_ROLE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingRole: false }

        case getNamedAction(ACTION_NAMES.ACCESS_ROLE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingRole: true }
        case getNamedAction(ACTION_NAMES.ACCESS_ROLE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingRole: false }
        case getNamedAction(ACTION_NAMES.ACCESS_ROLE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingRole: false }

        case getNamedAction(ACTION_NAMES.ACCESS_ROLE, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, roleBeingDeleted: action.id }
        case getNamedAction(ACTION_NAMES.ACCESS_ROLE, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, roleBeingDeleted: null }
        case getNamedAction(ACTION_NAMES.ACCESS_ROLE, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, roleBeingDeleted: null }

        default:
            return state;
    }
}

export default roleReducer;
