import React from 'react';
import Avatar from 'react-avatar';

export default class RecordUtils {
    static getName(record) {
        return {
            firstLine: record.first_name,
            secondLine: `${record.last_name} ${record.suffix || ""}`
        }
    }

    static generateCallPayload(contact_id, destination) {
        return {
            "contact_id": contact_id,
            "destination": destination,
        }
    }

    static generateRecordPath(account_stage, contact_id, account_id) {
        return `/contact/${contact_id}`;
    }

    static fieldIsShown(field_name_to_check, fields) {
        return fields.findIndex(({ field_name, deleted }) => field_name_to_check === field_name && !deleted) !== -1;
    }

    static generateUserOptions(users) {
        return users.map(({ user_id, first_name, last_name, email }) => {
            const text = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : "Unknown Name";
            return {
                key: Number(user_id),
                value: Number(user_id),
                text,
                content: (
                    <div style={{ verticalAlign: 'middle' }}>
                        <Avatar name={text} maxInitials={2} round size='3em' /> <div style={{ display: "inline-block", paddingLeft: "1em" }}>
                            <div><b>{text}</b></div>
                            <div>{email}</div>
                        </div>
                    </div>
                )
            }
        })
    }

    static updateMain(items, update, onChange) {
        const indexOfPreviousMain = items.findIndex(item => item.main);
        if (indexOfPreviousMain !== -1) {
            update(indexOfPreviousMain, { ...items[indexOfPreviousMain], main: false });
        }
        onChange();
    }

    static removeItem(items, index, update, remove) {
        const isMain = items[index].main;
        if (isMain) {
            const indexOfItemToSetAsMain = (index + 1) % items.length;
            update(indexOfItemToSetAsMain, { ...items[indexOfItemToSetAsMain], main: true });
        }
        remove(index);
    }
}
