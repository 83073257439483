import React from 'react';
import { TableWithHeader, STATUS_TYPES, Notification } from 'dyl-components';

import CallRoutingRulesTable from './subcomponents/CallRoutingRulesTable';
import { Grid, Transition, Segment } from 'semantic-ui-react';
import AddCustomRouteSection from './subcomponents/AddCustomRouteSection';
import CallRoutingRulesHeader from './subcomponents/CallRoutingRulesHeader';

import './index.scss';

const routeOptions = [
    { id: 'vct', name: 'Vacation' },
    { id: 'nh', name: 'Normal Hours' },
    { id: 'cd', name: 'Company Directory' },
    { id: 'clms', name: 'Claims' },
    { id: 'sc', name: 'Service Center' }
].map(route => ({ key: route.id, value: route.id, text: route.name }));

const CallRoutingRules = ({ callRoutingRules, onUpdate, onDelete, isAddCustomRouteSectionOpen, 
    onAddCustomRoute, onOpenAddCustomRouteSection, onCloseAddCustomRouteSection 
}) => (
    <TableWithHeader
        header={(
            <CallRoutingRulesHeader 
                onOpenAddCustomRouteSection={onOpenAddCustomRouteSection}
            />
        )}
        table={(
            <Grid>
                <Transition unmountOnHide visible={isAddCustomRouteSectionOpen} animation='slide down' duration='100'>
                    <Grid.Row style={{paddingBottom: 0}}>
                        <Grid.Column>
                            <Segment className="AddCustomRouteSection">
                                <AddCustomRouteSection 
                                    playMessageOptions={routeOptions}
                                    onClose={onCloseAddCustomRouteSection}
                                    onAddCustomRoute={onAddCustomRoute}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Transition>
                <Grid.Row style={isAddCustomRouteSectionOpen ? {padding: 0} : {paddingBottom: 0}}>
                    <Grid.Column style={{margin: 0}}>
                        <CallRoutingRulesTable
                            callRoutingRules={callRoutingRules}
                            playMessageOptions={routeOptions}
                            onUpdate={onUpdate}
                            onDelete={onDelete}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )}
    />
);

class CallRoutingRulesContainer extends React.Component {
    state = {
        callRoutingRules: [
            { id: '28473921', name: 'Test', playMessage: 'vct' }
        ],
        isAddCustomRouteSectionOpen: false
    }

    onUpdate = (updatedRule) => {
        let callRoutingRules = this.state.callRoutingRules.slice(0);
        let indexOfRuleToUpdate = callRoutingRules.findIndex(option => option.id === updatedRule.id);
        if (indexOfRuleToUpdate !== -1) {
            callRoutingRules[indexOfRuleToUpdate] = { ...updatedRule };
            this.setState({ callRoutingRules }, () => {
                Notification.alert('Successfully updated rule!', STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    onOpenAddCustomRouteSection = () => {
        this.setState({ isAddCustomRouteSectionOpen: true });
    }

    onCloseAddCustomRouteSection = () => {
        this.setState({ isAddCustomRouteSectionOpen: false });
    }

    onAddCustomRoute = (newCustomRoute) => {
        let callRoutingRules = this.state.callRoutingRules.slice(0);
        callRoutingRules.push({ ...newCustomRoute, id: Math.random() });
        Notification.alert('Successfully added prompt option!', STATUS_TYPES.SUCCESS, true);
        this.setState({ callRoutingRules });
    }

    onDelete = (id) => {
        let callRoutingRules = this.state.callRoutingRules.slice(0);
        let indexOfCallRoutingRuleToDelete = callRoutingRules.findIndex(option => option.id === id);
        if (indexOfCallRoutingRuleToDelete !== -1) {
            callRoutingRules.splice(indexOfCallRoutingRuleToDelete, 1);
            this.setState({ callRoutingRules }, () => {
                Notification.alert('Successfully deleted rule!', STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    render() {
        return (
            <CallRoutingRules
                {...this.state}
                onUpdate={this.onUpdate}
                onDelete={this.onDelete}
                onAddCustomRoute={this.onAddCustomRoute}
                onOpenAddCustomRouteSection={this.onOpenAddCustomRouteSection}
                onCloseAddCustomRouteSection={this.onCloseAddCustomRouteSection}
            />
        )
    }
}

export default CallRoutingRulesContainer;
