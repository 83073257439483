const initialState = {
    isReadingProviders: false,
    providers: [],
    provider_count: 0,

    isCreatingProvider: false,
    providerBeingUpdated: null,
    providerBeingDeleted: null,

    isReadingProviderNames: false,
    provider_names: [],
    provider_names_count: 0,

    isReadingUnmappedProviders: false,
    unmapped_providers: [],
    unmapped_providers_count: 0,

    isReadingMappings: false,
    mappings: [],
    mappings_count: 0,

    isCreatingMappings: false,    
    isCheckingIfMappingExists: false,
    isExistingMapping: false,

    isReadingSecondarySources: false,
    secondary_sources: [],

    isReadingSecondarySourceOptions: false,
    secondary_source_options: [],

    isUpdatingSecondarySources: false
}

export default initialState;
