import React from 'react';
import { TableWithHeader, STATUS_TYPES, Notification } from 'dyl-components';
import IVRPromptOptionsTable from './PromptOptionsTable';
import PromptOptionsHeader from './PromptOptionsHeader';
import { Grid, Transition} from 'semantic-ui-react';
import AddPromptOptionSectionContainer from './AddPromptOptionSection';
import LeftPaddedContent from 'shared/LeftPaddedContent';

const routeOptions = [
    { id: 'vct', name: 'Vacation' },
    { id: 'nh', name: 'Normal Hours' },
    { id: 'cd', name: 'Company Directory' },
    { id: 'clms', name: 'Claims' },
    { id: 'sc', name: 'Service Center' }
].map(route => ({ key: route.id, value: route.id, text: route.name }));

const digitOptions = [
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '#', '*'
];

const PromptOptions = ({ 
    options, 
    onUpdate, 
    onDelete, 
    isAddOptionSectionOpen,
    onOpenAddOptionSection, 
    onCloseAddOptionSection, 
    onAddOption,
    selectedDigits
}) => (
    <TableWithHeader 
        header={
            <PromptOptionsHeader 
                onOpenAddOptionSection={onOpenAddOptionSection}
            />
        }
        table={
            <Grid style={{margin: 0}}>
                <Transition visible={isAddOptionSectionOpen} unmountOnHide duration='100' animation='slide down'>
                    <Grid.Row>
                        <Grid.Column>
                            <LeftPaddedContent>
                                <AddPromptOptionSectionContainer 
                                    onAddPromptOption={onAddOption} 
                                    onClose={onCloseAddOptionSection}
                                    routeOptions={routeOptions}
                                    digitOptions={digitOptions.map(digit => (
                                        {key: digit, value: digit, text: digit}
                                    )).filter(digit => !selectedDigits.includes(digit.key))}
                                />
                            </LeftPaddedContent>
                        </Grid.Column>
                    </Grid.Row>
                </Transition>
                <Grid.Row style={{padding: 0}}>
                    <Grid.Column style={{padding: 0}}>
                        <IVRPromptOptionsTable 
                            options={options}
                            onUpdate={onUpdate}
                            onDelete={onDelete}
                            playMessageOptions={routeOptions}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        }
    />
);

class PromptOptionsContainer extends React.Component {
    state = {
        options: [
            { digit: 1, playMessage: 'None', route: 'vct' },
            { digit: 2, playMessage: 'None', route: 'vct' },
            { digit: 3, playMessage: 'None', route: 'vct' },
            { digit: 4, playMessage: 'None', route: 'vct' }
        ],
        isAddOptionSectionOpen: false
    }

    onUpdate = (updatedOption) => {
        let options = this.state.options.slice(0);
        let indexOfOptionToUpdate = options.findIndex(option => option.digit === updatedOption.digit);
        if (indexOfOptionToUpdate !== -1) {
            options[indexOfOptionToUpdate] = {...updatedOption};
            this.setState({ options }, () => {
                Notification.alert('Successfully updated prompt option!', STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    onAddOption = (newOption) => {
        let options = this.state.options.slice(0);
        options.push({ ...newOption, playMessage: 'None' });
        Notification.alert('Successfully added prompt option!', STATUS_TYPES.SUCCESS, true);
        this.setState({ options, isAddOptionSectionOpen: false });
    }

    onOpenAddOptionSection = () => {
        this.setState({ isAddOptionSectionOpen: true });
    }

    onCloseAddOptionSection = () => {
        this.setState({ isAddOptionSectionOpen: false });
    }

    onDelete = (id) => {
        let options = this.state.options.slice(0);
        let indexOfLeadSourceToDelete = options.findIndex(option => option.digit === id);
        if (indexOfLeadSourceToDelete !== -1) {
            options.splice(indexOfLeadSourceToDelete, 1);
            this.setState({ options }, () => {
                Notification.alert('Successfully deleted prompt option!', STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    render() {
        return (
            <PromptOptions 
                options={this.state.options.sort((a, b) => a.digit > b.digit ? 1 : -1)}
                isAddOptionSectionOpen={this.state.isAddOptionSectionOpen}
                onOpenAddOptionSection={this.onOpenAddOptionSection}
                onCloseAddOptionSection={this.onCloseAddOptionSection}
                onAddOption={this.onAddOption}
                onUpdate={this.onUpdate}
                onDelete={this.onDelete}
                selectedDigits={this.state.options.map(option => option.digit.toString())}
            />
        )
    }
}

export default PromptOptionsContainer;
