import { Controller, useFormContext } from "react-hook-form";
import { Form } from "semantic-ui-react";

const ModelField = ({ schedule }) => {
    const { control, setValue, productBeingEdited } = useFormContext();
    return (
        <Controller
            control={control}
            name="price_data.model"
            render={({
                field: {
                    name: modelFieldName,
                    value: model,
                    onChange: onChangeModel,
                },
                fieldState: { error: modelError },
            }) => {
                return (
                    <Form.Select
                        disabled={productBeingEdited}
                        name={modelFieldName}
                        value={model}
                        onChange={(_, { name, value }) => {
                            onChangeModel({
                                target: { name, value },
                            });
                            if (value === "volume" && schedule === "one-time") {
                                setValue("price_data.volume_prices", [
                                    {
                                        start: 1,
                                        end: null,
                                        price: null,
                                    },
                                ]);
                            } else if (value === "volume" && schedule === "recurring") {
                                [
                                    "monthly",
                                    "quarterly",
                                    "semi-annually",
                                    "annually",
                                ].forEach(frequency => {
                                    setValue(`price_data.volume_based_recurring_${frequency}_volume_prices`, [
                                        {
                                            start: 1,
                                            end: null,
                                            price: null,
                                        },
                                    ])
                                })
                            }
                        }}
                        label="Pricing Model"
                        required
                        options={[
                            {
                                key: "fixed",
                                value: "fixed",
                                text: "Fixed",
                            },
                            {
                                key: "volume",
                                value: "volume",
                                text: "Volume-based",
                            },
                            {
                                key: "usage",
                                value: "usage",
                                text: "Usage-based",
                            },
                        ]}
                        error={modelError?.message}
                        selectOnBlur={false}
                    />
                );
            }}
        />
    );
};

export default ModelField;
