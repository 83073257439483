const initialState = {
    roles: [],
	count: 0,
    isReadingRoles: false,
    queryParameters: {
        page: 1,
        limit: 25, 
        offset: 0,
        search:''
    },
};

export default initialState;
