import ACTION_NAMES from 'actions/ACTION_NAMES';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import initialState from './initialState';

function relationshipsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.ACCOUNT_RELATIONSHIPS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReading: true };
        case getNamedAction(ACTION_NAMES.ACCOUNT_RELATIONSHIPS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReading: false, relationships: action.data.data, count: action.data.count };
        case getNamedAction(ACTION_NAMES.ACCOUNT_RELATIONSHIPS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReading: false };

        case getNamedAction(ACTION_NAMES.ACCOUNT_RELATIONSHIPS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, relationshipBeingUpdated: action.queryParameters?.contact_id };
        case getNamedAction(ACTION_NAMES.ACCOUNT_RELATIONSHIPS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, relationshipBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.ACCOUNT_RELATIONSHIPS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, relationshipBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.CONTACT_RELATIONSHIP, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            const { id } = action;
            return { ...state, isUpdating: true, relationshipBeingUpdated: id };
        case getNamedAction(ACTION_NAMES.CONTACT_RELATIONSHIP, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdating: false, relationshipBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.CONTACT_RELATIONSHIP, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdating: false, relationshipBeingUpdated: null };
        default:
            return state;
    }
};

export default relationshipsReducer;
