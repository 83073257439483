import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function officeViewReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CALL_HISTORY_CALLS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCallHistory: true };
        case getNamedAction(ACTION_NAMES.CALL_HISTORY_CALLS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCallHistory: false, call_history: action.data };
        case getNamedAction(ACTION_NAMES.CALL_HISTORY_CALLS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCallHistory: false };

        case getNamedAction(ACTION_NAMES.CALL_HISTORY_ACTIVE, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingActiveCalls: true };
        case getNamedAction(ACTION_NAMES.CALL_HISTORY_ACTIVE, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingActiveCalls: false, activeCalls: action.data };
        case getNamedAction(ACTION_NAMES.CALL_HISTORY_ACTIVE, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingActiveCalls: false };

        case 'CALL_HISTORY_MARK_CALL':
            {
                const call_history = state.call_history.slice(0);
                const callToMark = call_history.find(call => call.id === action.id);
                callToMark.isMarked = !callToMark.isMarked;
                return { ...state, call_history };
            }
        case 'CALL_HISTORY_MARK_ALL':
            {
                const call_history = state.call_history.slice(0);
                const allMarked = !state.allMarked;
                call_history.forEach(call => { call.isMarked = allMarked });
                return { ...state, call_history, allMarked };
            }
        default:
            return state;
    }
}


export default officeViewReducer;
