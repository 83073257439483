const initialState = {
    isReadingCampaign: false,
    isReadingCampaignUserIds: false,
    isReadingCampaigns: false,
    isAddingUserReport: false,

    campaign: {},
    campaignUserIds: [],
    campaigns: [],
    campaigns_count: 0,
    campaignUserIdsCount: 0,
    isCreatingCampaign: false,
    campaignBeingUpdated: null,
    campaignBeingDeleted: null,
    campaignBeingReactivated: null,
    campaignList: [],

    duplicate: null,
    isCheckingDuplicate: false,

    isReadingCampaignConverted: false,
    isReadingCampaignList: false,
    campaignConvertedOptions: [],
    isApplyingBulkAction: false,

    customCampaignConvertedBeingUpdated: null
}

export default initialState;
