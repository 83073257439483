import React from 'react';

import { ClippedContent, CloseableContent, ControlledPopup, EllipsisDropdown, Notification, STATUS_TYPES, Table } from 'dyl-components';

import { Dropdown, Header, Popup, Ref, Icon } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import customFieldsGroupActions from 'actions/custom_fields_group';
import CustomFieldGroupForm from 'shared/forms/CustomFieldGroupForm';

const DropdownItem = ({ text, icon, displaysPopup, popupProps, ...otherProps }) => {
    const DropdownModel = () => {
        return (
            <Dropdown.Item text={<React.Fragment>
                <Icon name={icon} />
                {text}
            </React.Fragment>}
                style={{ cursor: 'pointer' }}
                {...otherProps}
            />
        );
    }

    if (displaysPopup === true) {
        return (
            <ControlledPopup
                header={
                    <Header as='h4' textAlign='center'>
                        {popupProps.header}
                        <Header.Subheader style={{ marginTop: 5 }}>
                            {popupProps.subheader}
                        </Header.Subheader>
                    </Header>
                }
                trigger={
                    DropdownModel()
                }
                onConfirm={popupProps.onConfirm}
            />
        )
    } else {
        return (
            <DropdownModel />
        );
    }
}

const GroupEntries = ({
    group,
    parent_group,
    refresh,
    onCloseEditGroupForm,
    openEditGroupForm,
    groupBeingEdited,
    isEditGroupFormModified,
    onModifyEditGroupForm,
    isSubgroup,
    isParentArchived
}) => {
    const dispatch = useDispatch();

    const isArchived = group.deleted;

    const generateFieldGroupEllipsisOptions = (group_id) => {
        return [
            ...(!isArchived ? [{
                id: 1,
                text: 'Edit',
                icon: 'fas fa-pencil',
                onClick: () => { openEditGroupForm(group_id) }
            }] : !isParentArchived ? ([{
                id: 1,
                text: 'Restore',
                icon: 'fas fa-trash-can-undo',
                onClick: async () => {
                    try {
                        await dispatch(customFieldsGroupActions.reactivateGroup(group_id));
                        Notification.alert(`Successfully restored custom ${isSubgroup ? 'sub' : ''}group!`, STATUS_TYPES.SUCCESS);
                        refresh();
                    } catch (e) {
                        console.log(e);
                        Notification.alert(`Failed to restore custom ${isSubgroup ? 'sub' : ''}group`, STATUS_TYPES.ERROR);
                    }
                }
            }]) : []),
            {
                id: 2,
                text: 'Delete',
                icon: 'fas fa-trash-alt',
                displaysPopup: true,
                popupProps: {
                    header: 'Are you sure?',
                    subheader: isSubgroup ? 'Deleting a subgroup will also delete all fields within the subgroup.' : 'Deleting a group will also delete all subgroups and fields.',
                    onConfirm: async () => {
                        try {
                            await dispatch(customFieldsGroupActions.deleteCustomFieldGroup(group_id));
                            Notification.alert(`Successfully deleted custom ${isSubgroup ? 'sub' : ''}group!`, STATUS_TYPES.SUCCESS);
                            refresh();
                        } catch (e) {
                            console.log(e);
                            Notification.alert(`Failed to delete custom ${isSubgroup ? 'sub' : ''}group`, STATUS_TYPES.ERROR);
                        }
                    }
                }
            },
            ...(!isArchived ? [{
                id: 3,
                text: 'Archive',
                icon: 'fas fa-archive',
                displaysPopup: true,
                popupProps: {
                    header: 'Are you sure?',
                    onConfirm: async () => {
                        try {
                            await dispatch(customFieldsGroupActions.deleteCustomFieldGroup(group_id, { archive: true }));
                            Notification.alert(`Successfully archived custom ${isSubgroup ? 'sub' : ''}group!`, STATUS_TYPES.SUCCESS);
                            refresh();
                        } catch (e) {
                            console.log(e);
                            Notification.alert(`Failed to archive custom ${isSubgroup ? 'sub' : ''}group`, STATUS_TYPES.ERROR);
                        }
                    }
                }
            }] : [])
        ]
    }

    const editGroupFormRef = React.createRef();

    const isEditingGroup = groupBeingEdited === group.id;
    
    return [
        <Table.Cell disabled={isArchived}>
            <ClippedContent>
                {group.label}
            </ClippedContent>
        </Table.Cell>,
        <Table.Cell disabled={isArchived}>

        </Table.Cell>,
        <Table.Cell>

        </Table.Cell>,
        <Table.Cell disabled={isArchived}>
            <ClippedContent>
                {group.description}
            </ClippedContent>
        </Table.Cell>,
        <Table.Cell>

        </Table.Cell>,
        <Table.Cell>

        </Table.Cell>,
        <Table.Cell>
            <Popup
                content={(
                    <CloseableContent onClose={onCloseEditGroupForm}>
                        <CustomFieldGroupForm
                            group={group}
                            parent_group={parent_group}
                            refresh={refresh}
                            onModify={onModifyEditGroupForm}
                            onClose={onCloseEditGroupForm}
                            isSubgroup={isSubgroup}
                        />
                    </CloseableContent>
                )}
                open={isEditingGroup}
                closeOnDocumentClick={!isEditGroupFormModified}
                onClose={onCloseEditGroupForm}
                on='click'
                position='bottom left'
                context={editGroupFormRef}
            />
            <Ref innerRef={editGroupFormRef}>
                <EllipsisDropdown {...isEditingGroup ? { isDropdownOpen: false } : {}}>
                    {generateFieldGroupEllipsisOptions(group.id).map(({ text, icon, displaysPopup, popupProps, ...otherProps }, idx) => {
                        return (
                            <DropdownItem
                                key={idx}
                                text={text}
                                icon={icon}
                                displaysPopup={displaysPopup}
                                popupProps={popupProps}
                                {...otherProps} 
                            />
                        );
                    })}
                </EllipsisDropdown>
            </Ref>
        </Table.Cell>
    ];
}

export default GroupEntries;
