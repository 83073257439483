import React, { useState } from "react";
import { Form, Popup } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";
import {
    ClippedContent,
    Icon,
    Modal,
    generateResolver,
    yup,
} from "dyl-components";

import FullScreenModalPopUp from "shared/FullScreenModalPopUp";
import { StringUtils } from "utils";
import './ProductAdditions.scss';

const ProductAdditions = ({
    open,
    selectedEntries,
    onClose,

    ModalHeader,
    selectLabel,
    isSelectLoading,
    selectOptions,
    selectPlaceholder,
    onSave,

    removalConfirmationMessage,
}) => {
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isConfirmingEntriesRemoval, setIsConfirmingEntriesRemoval] =
        useState(false);

    const {
        formState: { isValid, isDirty },
        control,
        reset,
        handleSubmit,
        getValues,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            selection: selectedEntries || [],
        },
        resolver: generateResolver({
            selection: yup.array().min(1, "This field is required"),
        }),
    });

    const confirmationOnClose = () => {
        onClose();
        setIsConfirmationOpen(false);
        reset({ selection: [] });
    };

    const onConfirmingChanges = (data) => {
        const removedEntries = (selectedEntries || []).filter(
            (selectedEntry) => !data.selection.includes(selectedEntry)
        );
        if (removedEntries.length > 0) {
            setIsConfirmingEntriesRemoval(true);
        } else {
            onSave(data.selection);
        }
    };

    return (
        <>
            <Modal
                open={open}
                onClose={() =>
                    isDirty ? setIsConfirmationOpen(true) : onClose()
                }
                noValidate
                size="mini"
            >
                <Modal.Header>{ModalHeader}</Modal.Header>
                <Modal.Content>
                    <Form noValidate style={{ marginRight: "5em" }}>
                        <Controller
                            name="selection"
                            control={control}
                            render={({
                                field: { name, value, onChange },
                                fieldState: { error },
                            }) => (
                                <Form.Select
                                    search
                                    name={name}
                                    value={value}
                                    multiple
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value } });
                                    }}
                                    label={selectLabel}
                                    loading={isSelectLoading}
                                    options={selectOptions.map((option) => {
                                        const isDiscontinued =
                                            option.status === "discontinued";
                                        return {
                                            key: option.id,
                                            value: option.id,
                                            text: option.name,
                                            disabled: isDiscontinued,
                                            ...(!isDiscontinued
                                                ? {}
                                                : {
                                                      style: {
                                                          display: "none",
                                                      },
                                                  }),
                                            content: (
                                                <div
                                                    style={{ maxWidth: "12em" }}
                                                >
                                                    <ClippedContent
                                                        popup={false}
                                                    >
                                                        {option.status !== "active" && (
                                                            <Popup
                                                                content={StringUtils.capitalize(
                                                                    option.status
                                                                )}
                                                                inverted
                                                                position="top center"
                                                                trigger={
                                                                    <Icon
                                                                        className="fas fa-info-circle"
                                                                        color="orange"
                                                                    />
                                                                }
                                                            />
                                                        )}
                                                        {option.text}
                                                    </ClippedContent>
                                                </div>
                                            ),
                                        };
                                    })}
                                    error={error?.message}
                                    selectOnBlur={false}
                                    scrolling
                                    renderLabel={(label) => ({
                                        content: label.content,
                                        className: "ToggableDropdown__label",
                                    })}
                                    placeholder={selectPlaceholder}
                                    required
                                    className="ProductAddition__options"
                                />
                            )}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions
                    hasSaveButton
                    onSave={handleSubmit(onConfirmingChanges)}
                    saveDisabled={!isValid || !isDirty}
                />
            </Modal>
            <FullScreenModalPopUp
                header={"Are you sure?"}
                subheader={removalConfirmationMessage}
                isOpen={isConfirmingEntriesRemoval}
                onConfirm={() => {
                    onSave(getValues().selection);
                }}
                onFormClose={() => setIsConfirmingEntriesRemoval(false)}
            />
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={confirmationOnClose}
                onFormClose={() => setIsConfirmationOpen(false)}
            />
        </>
    );
};

export default ProductAdditions;
