import React from 'react';
import { Modal } from 'dyl-components';
import { Button, Breadcrumb, Header } from 'semantic-ui-react';

import './RefreshModal.scss';

const FilteredRefreshCampaignModal = ({
    open,
    onClose,
}) => (
        <Modal open={open} onClose={onClose} animated={false}>
            <Modal.Header>
                <div>
                    Refresh Campaign
                </div>
                <Breadcrumb size='huge' className="Breadcrumb">
                    <Breadcrumb.Section>Step 1</Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right chevron' />
                    <Breadcrumb.Section className="BreadcrumbSection">Step 2</Breadcrumb.Section>
                </Breadcrumb>
            </Modal.Header>
            <Modal.Content>
                <Header as='h3'> 8 records will be reset to Uncalled</Header>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>Cancel</Button>
                <Button primary>Refresh</Button>
            </Modal.Actions>
        </Modal>
    )



export default FilteredRefreshCampaignModal;
