import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';

import './ZipcodesField.scss';

const processZipcodes = value => {
    return value.split(value.includes(',') ? ',' : ' ').filter(zip => zip.trim().replaceAll(',', ''));
}

const renderLabel = (label) => {
    return ({
        ...(!label.invalid ? {} : { color: 'red' }),
        content: label.text
    });
};

const ZipcodesField = ({ name, value, onChange, options, disallowedZips }) => {
    const [zipCodeOptions, setZipcodeOptions] = useState(options || []);

    const onAddZipcodes = async (_, { value }) => {
        const newZipcodes = processZipcodes(value);
        const zipcodesToAdd = newZipcodes.filter((zip) => !zipCodeOptions.map(option => option.value).includes(zip));
        // TODO: validate zip code options being added here
        setZipcodeOptions([...zipCodeOptions, ...zipcodesToAdd.map(zip => ({
            key: zip,
            value: zip,
            text: zip,
            invalid: !(/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip))
        }))])
    }

    return (
        <Form.Select
            className='ZipcodesField'
            name={name}
            onChange={(e, { name, value: newValue }) => {
                if (value.length < newValue.length) {
                    const newlyAddedZipcode = newValue.pop();
                    const newZipcodes = processZipcodes(newlyAddedZipcode);
                    onChange(e, { name, value: [...newValue, ...newZipcodes.filter(zip => (!newValue.includes(zip)))] })
                } else {
                    const removedZip = value.find(zip => newValue.findIndex(otherZip => otherZip === zip) === -1);
                    onChange(e, { name, value: newValue });
                    setZipcodeOptions(zipCodeOptions.filter(zip => removedZip !== zip.value));
                }
            }}
            value={value}
            options={zipCodeOptions.map(zip => ({
                ...zip,
                invalid: zip.invalid || disallowedZips.includes(zip.value)
            }))}
            multiple
            search
            placeholder='Type or paste zip code(s)'
            allowAdditions
            onAddItem={onAddZipcodes}
            renderLabel={renderLabel}
            noResultsMessage={null}
        />
    )
}

export default ZipcodesField;
