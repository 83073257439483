import { AndOrToggle, ButtonLink, DateInput, Icon, InnerRuleContainer } from 'dyl-components';
import { ASSIGMENT_RULES_MULTIPLE_TYPES, ASSIGNMENTS_OPERATORS_OPTIONS, ASSIGNMENT_RULES_OPERATOR_SINGLE } from 'pages/DataMapping/DYLFieldsConstants';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import { STATES } from 'shared/constants/STATES';

export const CompositeCondition = ({
    compositeOptions,
    condition,
    conditions,
    control,
    displayIndex,
    isLast,
    name,
    onAddAdditionalField,
    onChangeCompositeField,
    onRemoveAdditionalField,
    parentName,
    segmentIndex,
    trigger,
    watch,
    setValue
}) => {
    const [fields, setFields] = useState([])
    const states = STATES.slice(1).map(state => ({
        ...state, value: state.key,
        text: `${state.text}${state.key ? ` (${state.key})` : ''}`,
    }))

    useEffect(() => {
        const conditionsCopy = [...conditions];
        conditionsCopy.sort((a, b) => a.index - b.index);
        setFields(conditionsCopy);
    }, [conditions])

    const alreadySelectedFields = fields.map(({field}) => field);

    return (
        <div className={`${isLast ? "AssignmentCondition__container--last": "AssignmentCondition__container"}`}>
            <div className='AssignmentCondition__numberedContainer'>
                <div className='AssignmentCondition__numberContainer'>
                    <span className='AssignmentCondition__number'>{displayIndex}</span>
                </div>
            </div>
            <div className='AssignmentCondition__conditionContainer'>
                <InnerRuleContainer isLast={isLast}>
                    <div style={{display: 'flex', alignItems: 'flex-start', gap: 10}}>
                        <span style={{marginTop: 30}}>Field: {condition.text}</span>
                        <div style={{flex: 1}}>
                            {fields.map((field, index) => {
                                const { type, options: fieldOptions, outerIndex } = field;
                                const watchedOperator = watch(`${name}[${outerIndex}].operator`)
                                return <div key={index} style={{flex: 1, display: 'flex', alignItems: 'center', gap: 10}}>
                                    <Form.Select
                                        className='CompositeCondition__select'
                                        label='Field Selector'
                                        value={field.field}
                                        onChange={(_, { value }) => {
                                            onChangeCompositeField(field.field, value);
                                            trigger();
                                        }}
                                        options={compositeOptions.map((compositeOption) => ({...compositeOption, disabled: !!alreadySelectedFields.find((selectedField) => selectedField === compositeOption.key && field.field !== selectedField)}))}
                                    />
                                    <Controller
                                        control={control}
                                        name={`${name}[${outerIndex}].operator`}
                                        render={({ field: { onChange, value, name: nameRender } }) => (
                                            <Form.Select
                                                className='CompositeCondition__select'
                                                label='Operator'
                                                value={value}
                                                name={nameRender}
                                                onChange={(_, { name: nameOnChange, value }) => {
                                                    if (ASSIGMENT_RULES_MULTIPLE_TYPES.includes(type)) {
                                                        if (ASSIGNMENT_RULES_OPERATOR_SINGLE.includes(value)) {
                                                            setValue(`${name}[${outerIndex}].value`, "");
                                                        } else {
                                                            setValue(`${name}[${outerIndex}].value`, [])
                                                        }
                                                    }
                                                    onChange({ target: { name: nameOnChange, value } })
                                                    trigger();
                                                }}
                                                options={ASSIGNMENTS_OPERATORS_OPTIONS[type]}
                                            />
                                        )}
                                    />
                                    {type !== "checkbox" && watchedOperator !== "exists" && watchedOperator !== "not_exists" && (
                                        <Controller
                                            control={control}
                                            name={`${name}[${outerIndex}].value`}
                                            render={({ field: { onChange, value, name }, fieldState: { error }  }) => {
                                                switch (type) {
                                                    case "text":
                                                    case "phone":
                                                    case "email":
                                                    case "url":
                                                        return (
                                                            <Form.Input
                                                                className='CompositeCondition__input'
                                                                label='Values'
                                                                name={name}
                                                                value={value}
                                                                onChange={(_, { value }) => {
                                                                    onChange({ target: { name, value } })
                                                                }}
                                                                error={error && error?.message && ({
                                                                    content: error.message,
                                                                    pointing: 'below'
                                                                })}
                                                            />
                                                        )
                                                    case "dropdown":
                                                    case "state":
                                                        return (
                                                            <Form.Select
                                                                className='CompositeCondition__select'
                                                                label='Values'
                                                                value={value}
                                                                name={name}
                                                                onChange={(_, { name, value }) => {
                                                                    onChange({ target: { name, value } })
                                                                }}
                                                                options={fieldOptions || states}
                                                                error={error && error?.message && ({
                                                                    content: error.message,
                                                                    pointing: 'below'
                                                                })}
                                                                multiple={!ASSIGNMENT_RULES_OPERATOR_SINGLE.includes(watchedOperator)}
                                                            />
                                                        )
                                                    case "date":
                                                        return (
                                                            <Form.Field
                                                                control={DateInput}
                                                                name={name}
                                                                value={value}
                                                                onChange={(_, { value }) => {
                                                                    onChange({ target: { name, value } })
                                                                }}
                                                                label='Values'
                                                                error={error && error?.message && ({
                                                                    content: error.message,
                                                                    pointing: 'below'
                                                                })}
                                                            />
                                                        )
                                                    default:
                                                        return (
                                                            <Form.Input
                                                                className='CompositeCondition__input'
                                                                label='Values'
                                                                name={name}
                                                                value={value}
                                                                onChange={(_, { value }) => {
                                                                    onChange({ target: { name, value } })
                                                                }}
                                                                error={error && error?.message && ({
                                                                    content: error.message,
                                                                    pointing: 'below'
                                                                })}
                                                            />
                                                        )
                                                }
                                            }}
                                        />
                                    )}
                                    {fields.length > 1 && (
                                        <ButtonLink onClick={() => {
                                            onRemoveAdditionalField(field.outerIndex, segmentIndex);
                                        }} status='error'>
                                            <Icon link className='fa-solid fa-trash' />
                                        </ButtonLink>
                                    )}
                                </div>
                            })}
                            {compositeOptions.length > fields.length && (
                                <ButtonLink style={{marginBottom: 10}} onClick={() => {onAddAdditionalField(condition.field, segmentIndex)}}><strong>+ Additional Field</strong></ButtonLink>
                            )}
                        </div>
                    </div>
                </InnerRuleContainer>
                {!isLast && (
                    <Controller
                        control={control}
                        name={`${parentName}.boolean_operator`}
                        render={({ field: { onChange, value, name } }) =>
                            <AndOrToggle
                                name={name}
                                options={[
                                    { key: 'and', text: 'And', value: 'and'},
                                    { key: 'or', text: 'Or', value: 'or' },
                                ]}
                                value={value}
                                onChange={(value) => {
                                    onChange({ target: { name, value } })
                                }}
                            />
                        } 
                    />
                    
                )}
            </div>
        </div>
    )
}
