import addonActions from "actions/product_addon";
import addonsActions from "actions/product_addons";
import { Notification, STATUS_TYPES, generateResolver } from "dyl-components";
import { Error404, Error500 } from "pages/ErrorPages";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import addonSchema, { formatAddonDetails, getDefaultValuesFromReadingAnAddon } from "shared/schemas/addons/addonSchema";
import AddonForm from "shared/forms/AddonForm";
import productsActions from "actions/products";

const DuplicateAddOn = () => {
    const { addon, isCreating } = useSelector((state) => state.product_addons);

    const methods = useForm({
        mode: "onChange",
        defaultValues: getDefaultValuesFromReadingAnAddon(addon),
        resolver: generateResolver(addonSchema),
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (addon) {
            const value = `Duplicate of ${addon.name}`;
            methods.setValue("name", value, {
                shouldDirty: true,
            });
            dispatch(productsActions.isDuplicate({ name: value, type: "addon" })).then(isDuplicate => {
                if (isDuplicate) {
                    methods.setError("name", {
                        type: 'unique_addon',
                        message: 'Add-on name already exists!'
                    })
                }
            })
        }
    }, [addon, methods, dispatch]);

    const onSave = async (data) => {
        try {
            await dispatch(
                addonsActions.addAddon(formatAddonDetails(data))
            );
            Notification.alert(
                "Succesfully duplicated add-on!",
                STATUS_TYPES.SUCCESS
            );
            methods.reset();
            navigate(
                "/settings/product-catalog-quotes-invoices/addons",
                { replace: true, state: { saved: true } }
            );
        } catch (e) {
            console.log(e);
            Notification.alert(
                "Failed to duplicate add-on",
                STATUS_TYPES.ERROR
            );
        }
    };

    return (
        <FormProvider {...methods}>
            <AddonForm onSave={onSave} loading={isCreating} />
        </FormProvider>
    );
};

const DuplicateAddOnContainer = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(addonActions.readAddon(id));
    }, [id, dispatch]);

    const { addonBeingRead, addonError, addon } = useSelector(
        (state) => state.product_addons
    );

    if (addonBeingRead) {
        return (
            <Dimmer active>
                <Loader active />
            </Dimmer>
        );
    }

    if (!addonBeingRead && addonError) {
        return <Error500 message={"Something went wrong"} />;
    }

    if (!addonBeingRead && !addon) {
        return <Error404 message={"AddOn not found"} />;
    }

    return <DuplicateAddOn />;
};

export default DuplicateAddOnContainer;
