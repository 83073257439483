import React, { useEffect, useState } from "react";
import { Form, Popup, Icon, Header } from "semantic-ui-react";
import SettingsFooter from "dyl-components/molecules/SettingsFooter";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Button, Notification, STATUS_TYPES } from "dyl-components";
import { useDispatch, useSelector } from "react-redux";
import Table from "./subcomponents/CallerIDTable";
import "./index.scss";
import { MathUtils } from "utils";
import useWidthListener from "shared/SettingsFooter/useWidthListener";
import useWindowWidth from "shared/SettingsFooter/useWindowWidth";
import { STATES } from "shared/constants/STATES";
import { DIGITS } from "shared/constants/DIGIT_OPTIONS";
import pbxConfigActions from "actions/pbx_config";
import phoneUtils from "utils/PhoneUtil";

let defaultData = {
    cid_shortcut: false,
    local_presence: false,
    state_cid_enabled: false,
    cid_shortcuts: [],
    state_cids: [],
};

const stateCallerIdHeaders = ["State", "Caller ID", "Actions"];
const callerIdShortcutHeaders = ["Digit", "Caller ID", "Actions"];

const formatOptions = (phones) => {
    return phones.map((phone) => {
        const formatted = phoneUtils.formatPhoneNumber(phone);
        return {
            key: phone,
            text: formatted,
        };
    });
};

const formatRows = (rows, state, number) => {
    if (rows && rows.length) {
        return rows.map((row) => {
            return {
                state: { name: row[state], value: row[state] },
                callerId: {
                    name: phoneUtils.formatPhoneNumber(row[number]),
                    value: row[number],
                },
                isEditing: row.isEditing,
            };
        });
    } else {
        return [];
    }

};

const CallerID = () => {
    const dispatch = useDispatch();
    const { cidOptions, cidPrefs, isReadingCidPrefs, isUpdatingCidPrefs } = useSelector(
        (state) => state.pbx_config
    );

    const windowWidth = useWindowWidth();
    const width = useWidthListener("settingsSidebar");

    const [callerIdOptions, setCallerIdOptions] = useState([]);

    const {
        control,
        formState: { isValid, isDirty },
        getValues,
        setValue,
        handleSubmit,
        reset,
        watch,
    } = useForm({
        mode: "onChange",
        defaultValues: defaultData,
    });

    const {
        fields: cid_shortcuts,
        update: updateShortcuts,
        append: appendShortcuts,
        remove: removeShortcuts,
    } = useFieldArray({ control, name: "cid_shortcuts" });
    const {
        fields: state_cids,
        update: updateStateCid,
        append: appendStateCids,
        remove: removeStateCids,
    } = useFieldArray({ control, name: "state_cids" });

    const values = watch();

    const setValues = async (formData) => {
        for (let value in formData) {
            setValue(value, formData[value]);
        }
    };

    useEffect(() => {
        dispatch(pbxConfigActions.readCallerIdPrefs());
        dispatch(pbxConfigActions.readCallerIdOptions());
    }, [dispatch]);

    useEffect(() => {
        if (cidOptions && cidOptions.length)
            setCallerIdOptions(formatOptions(cidOptions));
    }, [cidOptions]);

    useEffect(() => {
        reset({ ...cidPrefs });
    }, [cidPrefs, reset]);

    const handleSubmitData = async (data) => {
        try {
            await dispatch(pbxConfigActions.updateCallerIdPrefs(null, data));
            Notification.alert(
                "Successfully updated the caller IDs!",
                STATUS_TYPES.SUCCESS
            );
            reset({ ...values });
        } catch (e) {
            console.log(e);
            Notification.alert(
                "Failed to update",
                STATUS_TYPES.ERROR
            );
        }
    };

    const onError = (e) => {
        console.error(`|+|+|+ |There was an error validating the form`, e);
    };

    const getRowsData = (name, data) => {
        let rows = [];
        data.map((row) => {
            const rowData = row.isEditing===false ? ({
                ...row,
                ...(name === "cid_shortcuts" ? {
                    caller_id_replace: row.callerId?.value || row.caller_id_replace,
                    digit: row.state?.value || row.digit
                } : {
                    state: row.state?.value || row.state,
                    number: row.callerId?.value || row.number
                }
            )}) : {...row};
            rows.push(rowData);
            return rowData;
        });

        let existingData = { ...values };
        existingData[name] = rows;
        setValues(existingData);
    };

    const isCidShortcutsEditing = watch('cid_shortcuts', [{ isEditing: false }]);
    const isStatCcidsEditing = watch('state_cids', [{ isEditing: false }]);
    const isAnyRowEditing = isCidShortcutsEditing?.some(item => item.isEditing) || isStatCcidsEditing?.some(item => item.isEditing);

    return (
        <div className="callerID_form">
            <Form size="small" className="caller_id" loading={isReadingCidPrefs}>
                <Form.Group>
                    <Controller
                        name="state_cid_enabled"
                        control={control}
                        render={({ field: { onChange, name, value } }) => {
                            return (
                                <Form.Checkbox
                                    name={name}
                                    className="state_cid_enabled"
                                    checked={value}
                                    toggle
                                    label="Enable state caller ID"
                                    onChange={(_, { checked }) => {
                                        onChange({
                                            target: { name, value: checked },
                                        });
                                    }}
                                />
                            );
                        }}
                    />
                    <Popup
                        trigger={
                            <Icon
                                size="large"
                                className="fas fa-circle-info"
                                color="primary"
                                style={{ marginLeft: 10 }}
                            />
                        }
                        content={
                                <span>
                                    State caller ID will only be used for the
                                    campaign dialer.
                                </span>
                        }
                        inverted
                        hoverable
                    />
                </Form.Group>

                {getValues("state_cid_enabled") && (
                    <div className="expanded-section">
                        <Header as="h2" color="primary">
                            State Caller ID
                        </Header>

                        <Table
                            headers={stateCallerIdHeaders}
                            rows={
                                formatRows(state_cids, "state", "number") || []
                            }
                            name="State Caller ID"
                            append={appendStateCids}
                            update={updateStateCid}
                            control={control}
                            remove={removeStateCids}
                            statesOrDigitsOptions={STATES}
                            phoneNumbers={callerIdOptions}
                            getRowsData={getRowsData}
                            type="state_cids"
                            getValues={getValues}
                            placeHolderText="state"
                        />
                    </div>
                )}

                <Form.Group>
                    <Controller
                        name="local_presence"
                        control={control}
                        render={({ field: { onChange, name, value } }) => {
                            return (
                                <Form.Checkbox
                                    name={name}
                                    className="local_presence"
                                    checked={value}
                                    toggle
                                    label="Enable local presence"
                                    onChange={(_, { checked }) => {
                                        onChange({
                                            target: { name, value: checked },
                                        });
                                    }}
                                />
                            );
                        }}
                    />
                    <Popup
                        trigger={
                            <Icon
                                size="large"
                                className="fas fa-circle-info"
                                color="primary"
                                style={{ marginLeft: 10 }}
                            />
                        }
                        content={
                                <span>
                                    Local presence over rides state caller ID.
                                </span>
                        }
                        inverted
                        hoverable
                    />
                </Form.Group>

                <Form.Group>
                    <Controller
                        name="cid_shortcut"
                        control={control}
                        render={({ field: { onChange, name, value } }) => {
                            return (
                                <Form.Checkbox
                                    name={name}
                                    className="cid_shortcut"
                                    checked={value}
                                    width={10}
                                    toggle
                                    label="Enable caller ID shortcut"
                                    onChange={(_, { checked }) => {
                                        onChange({
                                            target: { name, value: checked },
                                        });
                                    }}
                                />
                            );
                        }}
                    />
                </Form.Group>

                {getValues("cid_shortcut") && (
                    <div className="expanded-section">
                        <Header as="h2" color="primary">
                            Caller ID Shortcut
                        </Header>
                        <Table
                            headers={callerIdShortcutHeaders}
                            rows={formatRows(
                                cid_shortcuts,
                                "digit",
                                "caller_id_replace"
                            )}
                            name="Caller ID Shortcut"
                            append={appendShortcuts}
                            update={updateShortcuts}
                            control={control}
                            remove={removeShortcuts}
                            statesOrDigitsOptions={DIGITS}
                            phoneNumbers={callerIdOptions}
                            getRowsData={getRowsData}
                            type="cid_shortcuts"
                            getValues={getValues}
                            placeHolderText="digit"
                        />
                    </div>
                )}

                <SettingsFooter
                    style={{
                        width: MathUtils.calculatePercentage(
                            windowWidth,
                            windowWidth - width
                        ),
                    }}
                    className={`Webform__menu`}
                    rightOptions={[
                        <Button
                            disabled={
                                !isValid || !isDirty || isUpdatingCidPrefs || isAnyRowEditing
                            }
                            loading={isUpdatingCidPrefs}
                            onClick={handleSubmit(handleSubmitData, onError)}
                            primary
                        >
                            Save
                        </Button>,
                    ]}
                />
            </Form>
            <br />
        </div>
    );
};

export default CallerID;
