import React from 'react';
import { Form } from 'semantic-ui-react';
import { TopPaddedControls } from 'dyl-components';

const AddCustomRouteSection = ({ name, playMessage, onChange, onAddCustomRoute, onClose, playMessageOptions }) => (
    <Form>
        <Form.Group>
            <Form.Input
                label='Route Name'
                name='name'
                value={name}
                onChange={onChange}
            />
            <Form.Select
                label='After Hours'
                name='playMessage'
                options={playMessageOptions}
                onChange={onChange}
                value={playMessage}
            />

            <TopPaddedControls>
                <Form.Button
                    floated='right'
                    content={'Cancel'}
                    onClick={onClose}
                />
                <Form.Button
                    primary
                    floated='right'
                    content={'Create'}
                    onClick={onAddCustomRoute}
                />
            </TopPaddedControls>
        </Form.Group>
    </Form>
)

class AddCustomRouteSectionContainer extends React.Component {
    state = {
        name: '',
        playMessage: 'vct'
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onAddCustomRoute = () => {
        this.props.onAddCustomRoute({ ...this.state });
        this.setState({
            name: ''
        });
    }

    render() {
        return (
            <AddCustomRouteSection
                {...this.props}
                {...this.state}
                onAddCustomRoute={this.onAddCustomRoute}
                onChange={this.onChange}
            />
        )
    }
}

export default AddCustomRouteSectionContainer;
