import React from 'react';
import { Grid, Form, TextArea } from 'semantic-ui-react';

import TemplateSelector, { TEMPLATE_TYPES } from 'shared/forms/TemplateSelector';
import ShortcodeButtons from 'shared/ShortcodeButtons';
import { connect } from 'react-redux';

const TextForm = ({ contacts, onChange, selectedTemplate, onSelectTemplate, message, name = 'body', isReadingTemplate, onAddShortcode, onFocus, currentlyFocusedElement }) => (
    <Grid>
        <Grid.Row>

            <Grid.Column computer={9} tablet={9} mobile={16} className='TextMessageModal__contact-number'>
                <span>All Contacts: {contacts}</span>
            </Grid.Column>

            <Grid.Column computer={7} tablet={7} mobile={16}>
                <TemplateSelector
                    type={TEMPLATE_TYPES.SMS}
                    onChange={onSelectTemplate}
                    selectedTemplate={selectedTemplate}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <div>Content:</div>
                <div>
                    <Form loading={isReadingTemplate}>
                        <TextArea
                            autoFocus
                            name={name}
                            value={message}
                            placeholder='Message'
                            onInput={onChange}
                            onFocus={onFocus}
                        />
                    </Form>
                </div>
                <div className='TextMessageModal__remaining-character-count-indicator'>
                    Characters Remaining: {140 - (message ? message.length % 140 : 0)}
                </div>
                <div className='TextMessageModal__remaining-character-count-indicator'>
                    Messages: {message ? Math.ceil((message.length + 1) / 140) : 0}
                </div>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <div>
                    Shortcodes:
                </div>
                <ShortcodeButtons onAddShortcode={onAddShortcode} currentlyFocusedElement={currentlyFocusedElement} />
            </Grid.Column>
        </Grid.Row>
    </Grid>
)

class TextFormContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentlyFocusedElement: null
        }
        this.onFocus = this.onFocus.bind(this);
    }

    onFocus(e) {
        this.setState({
            currentlyFocusedElement: e.target
        });
    }

    render() {
        return (
            <TextForm
                {...this.props}
                {...this.state}
                onFocus={this.onFocus}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        isReadingTemplate: state.template.isReadingTemplate
    }
}

export default connect(mapStateToProps, null)(TextFormContainer);
