const initialState = {
    groupBeingUpdated: null,
    groupBeingDeleted: null,
    group: [],
    count: 0,
    queryParameters: {
        page: 1,
        limit: 15
    },
    isReadingModalStandardFieldGroup: false,
    modal_standard_group: {
        children: [
            {fields: {data: []}}
        ],
        fields: {
            data: []
        }
    },

    isReadingContactStandardFieldGroup: false,
    contact_standard_group: {
        children: [
            {fields: {data: []}}
        ],
        fields: {
            data: []
        }
    },

    isReadingCustomerStandardFieldGroup: false,
    customer_standard_group: {
        children: [
            {fields: {data: []}}
        ],
        fields: {
            data: []
        }
    }
}

export default initialState;
