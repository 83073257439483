import { useContext } from "react";
import { ConfirmContext } from "./ConfirmContextProvider";

export const useConfirm = () => {
    const [confirm, setConfirm] = useContext(ConfirmContext);

    const isConfirmed = (header, subheader) => {
        const promise = new Promise((resolve, reject) => {
            setConfirm({
                header,
                subheader,
                isOpen: true,
                proceed: resolve,
                cancel: reject
            });
        });
        return promise.then(
            () => {
                setConfirm({ ...confirm, isOpen: false });
                return true;
            },
            () => {
                setConfirm({ ...confirm, isOpen: false });
                return false;
            }
        );
    };
    return {
        ...confirm,
        isConfirmed
    };
};
