import React from 'react';
import { Table } from 'dyl-components';
import CallQueueRow from './CallQueueRow';
import { connect } from 'react-redux';
import { Loader, Segment } from 'semantic-ui-react';

const CallQueuesTable = ({ queues, isReadingCallQueues }) => (
    isReadingCallQueues ? <Segment><Loader active /></Segment> :
    <Table>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Call Queue</Table.HeaderCell>
                <Table.HeaderCell>Extension</Table.HeaderCell>
                <Table.HeaderCell>Receive Calls</Table.HeaderCell>
                <Table.HeaderCell>Options</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {queues.map(queue => (
                <CallQueueRow 
                    key={queue.id}
                    queue={queue}
                />
            ))}
        </Table.Body>
    </Table>
);

const mapStateToProps = (state) => ({
    queues: state.call_queues.call_queues,
    isReadingCallQueues: state.call_queues.isReadingCallQueues || state.pbx_config.isReadingDomains,
    domain: state.pbx_config.domain
})

export default connect(mapStateToProps)(CallQueuesTable);
