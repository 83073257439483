import React from 'react';
import { Table } from 'dyl-components';
import FaxHistoryRow from './FaxHistoryRow';

const FaxHistoryTable = ({ faxes, otherProps }) => (
    <Table>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    TimeStamp
                </Table.HeaderCell>
                <Table.HeaderCell>
                    Contact
                </Table.HeaderCell>
                <Table.HeaderCell>
                    Pages/Review
                </Table.HeaderCell>
                <Table.HeaderCell>
                    Mark/Unmark Viewed
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {faxes.map(fax => (
                <FaxHistoryRow fax={fax} {...otherProps} />
            ))}
        </Table.Body>
    </Table>
);

export default FaxHistoryTable;
