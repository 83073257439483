import React from "react";
import { Dropdown, Icon, Popup } from "semantic-ui-react";

import "./index.scss";
import { StringUtils } from "utils";
import { ClippedContent } from "dyl-components";

const HoverableIconWithList = ({ items, icon, iconContent }) => {
    return (
        Boolean(items?.length) && (
            <Dropdown
                className="HoverableIconWithList"
                trigger={
                    <Popup
                        trigger={icon}
                        content={iconContent}
                        inverted
                        position="bottom center"
                    />
                }
                options={items.map((item) => ({
                    key: item.id,
                    value: item.id,
                    text: item.name,
                    content: <>
                        <span className="StatusIcon">
                            {(item.status === "inactive" || item.status === "discontinued") &&
                                <Popup
                                    content={StringUtils.capitalize(item.status)}
                                    inverted
                                    position="top center"
                                    trigger={<Icon className="fas fa-info-circle" color="orange" />}
                                />
                            }
                        </span> <ClippedContent>{item.name}</ClippedContent>
                    </>,
                    active: false,
                    selected: false,
                }))}
                icon={null}
                scrolling
                value={null}
                selectOnBlur={false}
            />
        )
    );
};

export default HoverableIconWithList;
