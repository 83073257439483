import ACTION_NAMES from 'actions/ACTION_NAMES';
import { generateReadOneActionCreator, generateCreateActionCreator, generateUpdateActionCreator, generateDeleteActionCreator } from 'actions/crudActionCreators';
import routes from 'actions/routes';

export default {
    addAttachments: generateCreateActionCreator(routes.API_EVENT, ACTION_NAMES.EVENT_ATTACHMENTS, () => {}, () => {}, 'event_attachment'),
    getAttachments: generateReadOneActionCreator(routes.API_EVENT, ACTION_NAMES.EVENT_ATTACHMENTS, 'event_attachment'),
    updateAttachment: generateUpdateActionCreator(routes.API_EVENT_ATTACHMENT, ACTION_NAMES.EVENT_ATTACHMENTS),
    removeAttachment: generateDeleteActionCreator(routes.API_EVENT_ATTACHMENT, ACTION_NAMES.EVENT_ATTACHMENTS)
}
