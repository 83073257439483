const initialState = {
    templates: [],
    count: 0,
    isCreatingTemplate: false,
    isReadingTemplates: false,
    queryParameters: {
        search: '',
        template_category_id: [],
        page: 1,
        limit: 60
    }
}

export default initialState;
