import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Table, DateTimeUtils, ClippedContent } from 'dyl-components';
import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';
import noteType from './NoteType';

export const NotesRow = ({ note, contactAccount, masterAccount, account }) => {
    const name = `${note?.user?.first_name} ${note?.user?.last_name}`;
    const user_id = note?.user?.id || 0;
    const contactAccountName = contactAccount === "account" ? note?.contact ? `${note.contact?.first_name} ${note.contact?.last_name}` : account?.name : contactAccount === "master_account" && note.accountName !== "master_account" ? note.accountName : masterAccount?.name;
    const noteLinked = (contactAccount !== "contact" && !note.linked); 
    return (
        <>
            <Table.Row key={note.id}>
                <Table.Cell width={2}>
                    <div className={noteLinked ? 'Note__Disabled' : ''}><strong>{DateTimeUtils.formatEpoch(note.ts, "M[/]D[/]Y")}</strong></div>
                    <div className={noteLinked ? 'Note__Disabled' : ''}>{DateTimeUtils.formatEpoch(note.ts)}</div>
                </Table.Cell>
                <Table.Cell width={2}>
                    <div style={{width:150}} className={noteLinked ? 'Note__Disabled' : ''}>{noteType(note.sub_type, note?.task_name, contactAccount === "contact" ? note?.contact_id : note?.contact?.id)}</div>
                </Table.Cell>
                {(contactAccount === "account" || contactAccount === "master_account") &&
                    <Table.Cell width={2}>
                        <Grid verticalAlign={noteLinked ? '' : 'middle'}>
                            <Grid.Column width={4} style={{paddingLeft: 0, paddingRight: 0}}>
                                <Avatar
                                    name={contactAccountName}
                                    maxInitials={2}
                                    round
                                    size='3em'
                                />  
                            </Grid.Column>
                            <Grid.Column width={8} style={{paddingLeft: 0, paddingRight: 0}}> 
                                <Grid.Row>
                                    <ClippedContent>
                                        {contactAccount === "account" ? note.contact ? <Link to={`/contact/${note.contact.id}`}>{contactAccountName}</Link> : contactAccountName : contactAccount === "master_account" && note.accountName !== "master_account" ? <Link to={`/account/${note.contact_id}`}>{contactAccountName}</Link> : contactAccountName}
                                    </ClippedContent>
                                </Grid.Row>
                                {noteLinked &&
                                    <Grid.Row>
                                        <div className='Note__Disabled'><i>(unlinked)</i></div>
                                    </Grid.Row>
                                }
                            </Grid.Column>
                        </Grid>
                    </Table.Cell>
                }
                <Table.Cell width={2}>
                    <Grid verticalAlign='middle'>
                        <Grid.Column width={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <Avatar
                                name={name}
                                maxInitials={2}
                                round
                                size='3em'
                            />
                        </Grid.Column>
                        <Grid.Column width={8} style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <ClippedContent>
                                <Link to={`/settings/users/${user_id}/general`}>{name}</Link>
                            </ClippedContent>
                        </Grid.Column>
                    </Grid>
                </Table.Cell>
                <Table.Cell width={5}>
                    <div className={noteLinked ? 'Note__Disabled' : ''}>{note.note}</div>
                </Table.Cell>
            </Table.Row>
        </>
    )
}
