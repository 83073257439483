import React from 'react';
import { Icon } from 'semantic-ui-react';
import { CALL_STATUS } from 'shared/Call';
import { GridTable, ButtonLink } from 'dyl-components';
import { DateTimeUtils } from 'dyl-components';
import officeViewActions from 'actions/office_view';
import { connect } from 'react-redux';

export const RecentCall = ({ recentCall, onClickRecentCall, onGetRecording }) => (
    <GridTable.Row>
        <GridTable.Column>
            {DateTimeUtils.formatEpoch(recentCall.start_at, DateTimeUtils.DATETIME_FORMAT)}
        </GridTable.Column>
        <GridTable.Column>
            <ButtonLink onClick={() => {onClickRecentCall(recentCall)}}>
                {recentCall.dest_cid_name}
            </ButtonLink>
        </GridTable.Column>
        <GridTable.Column>
            {recentCall.system_result}
        </GridTable.Column>
        <GridTable.Column width={4}>
            {recentCall.status && recentCall.status.toUpperCase() !== CALL_STATUS.NOT_REACHED && (
                <ButtonLink onClick={() => {onGetRecording()}}>
                    Play <Icon name='play circle' />
                </ButtonLink>
            )}
        </GridTable.Column>
    </GridTable.Row>
)

const mapDispatchToProps = dispatch => ({
    onClickRecentCall: (call) => {
        dispatch(officeViewActions.setActiveCall({
            name: call.dest_cid_name,
            contactNumber: call.dest_cid_number,
            ...call
        }));
    }
});

export default connect(null, mapDispatchToProps)(RecentCall);
