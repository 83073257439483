import React from 'react';
import { Modal } from 'dyl-components';
import { Button, Breadcrumb } from 'semantic-ui-react';
import RefreshCampaignForm from '../forms/RefreshCampaignForm';

import './RefreshModal.scss';

const RefreshCampaignModal = ({
    open,
    onClose,
    onShowFilteredRefreshCampaignModal,
    ...otherProps
}) => (
        <Modal open={open} onClose={onClose} animated={false}>
            <Modal.Header>
                <div>
                    Refresh Campaign
                </div>
                <Breadcrumb size='huge' className="Breadcrumb">
                    <Breadcrumb.Section className="BreadcrumbSection">Step 1</Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right chevron' />
                    <Breadcrumb.Section>Step 2</Breadcrumb.Section>
                </Breadcrumb>
            </Modal.Header>
            <Modal.Content>
                <RefreshCampaignForm {...otherProps} />
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={onShowFilteredRefreshCampaignModal}>Filter</Button>
            </Modal.Actions>
        </Modal>
    )

class RefreshCampaignModalContainer extends React.Component {

    render() {
        return (
            <RefreshCampaignModal
                {...this.props}
            />
        )
    }
}


export default RefreshCampaignModalContainer;
