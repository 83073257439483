import React from 'react';
import { Portal } from 'semantic-ui-react';
import { Button, SettingsFooter } from "dyl-components";
import useWidthListener from 'shared/SettingsFooter/useWidthListener';
import useWindowWidth from 'shared/SettingsFooter/useWindowWidth';
import { MathUtils } from 'utils';

const ImportFooter = ({width="100%", onParseAttempt, readyToParse, readyForMapping, parseButtonCopy, resetDynForm, saveParseGoToMapping, isEmail, postGoToMapping, returnedValues})=>{
    const isMappingButtonDisabled = isEmail ? !readyForMapping : returnedValues.length === 0;
    const leftOptions = [
        <Button onClick={ () => console.log('|+|+ Help') } primary basic disabled>Get Help</Button>,
        <Button onClick={ () => console.log('|+|+ Block') } primary basic disabled>Block email sender</Button>
    ];
    
    const rightOptions = [
        <Button  onClick={ () => { resetDynForm() } }  basic status={'reset'}>Reset</Button>,
        <Button onClick={ () => { onParseAttempt() } } primary basic disabled={ !readyToParse }>{ parseButtonCopy }</Button>,
        <Button onClick={ () => { isEmail ? saveParseGoToMapping() :  postGoToMapping()} } primary color='blue' disabled={isMappingButtonDisabled}>Keep all, go to mapping</Button>
    ];
    
    if (!isEmail) { 
        leftOptions.pop() //Remove the block tab for posts
        rightOptions.splice(0,2)  //Remove reset and parse attempt for posts
    } 
 
    return (
        <SettingsFooter
            style={{ width: width }}
            className={`ParseTemplatePanel__footer`}
            leftOptions={leftOptions}
            rightOptions={rightOptions}
        />
    );

}


export const ParseTemplateStaticFooter = ({ data }) => {

    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();

    return (
        <Portal open>

        <ImportFooter 
            width={MathUtils.calculatePercentage(windowWidth, windowWidth - width)}
            {...data}
        />
        </Portal>
    );
}
