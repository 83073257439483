import React from 'react';
import { Tab } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

export const QueryStringPane = () => {
    const { email } = useSelector(state => state.lead_ingestion);
    const query  = email?.post?.query;   

    return (
        <Tab.Pane>
            <pre>{query}</pre>
        </Tab.Pane>
    );
}
