import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Form, Icon, Image, Popup } from 'semantic-ui-react';
import { ClippedContent, GmailSVG, OutlookSVG } from 'dyl-components';

import './index.scss';

const EmailSender = () => {
    const { integrations = [], isReadingIntegrations } = useSelector(state => state.oauth_integrations);

    const { control, resetField } = useForm({
        mode: 'onChange',
        defaultValues: {
            email_sender: 'DYL',
            email_integrations: ''
        }
    });

    return (
        <Form className='EmailSenderForm'>
            <Form.Group>
                <Controller
                    name={'email_sender'}
                    control={control}
                    render={({ field: { email_sender_name, value: email_sender_value, onChange } }) => (
                        <>
                            <Form.Select
                                width={4}
                                label={
                                    <>
                                        <label style={{ display: 'inline-block' }}>Email Sender</label>
                                        <Popup
                                            content={'Email replies will be delivered to selected email'}
                                            trigger={<Icon className='fas fa-circle-info' style={{ marginLeft: '0.5em' }} color='blue' />}
                                            position='top center'
                                            inverted
                                        />
                                    </>
                                }
                                loading={isReadingIntegrations}
                                options={[
                                    { key: 'DYL', value: 'DYL', text: 'DYL' },
                                    { key: 'Integration', value: 'Integration', text: 'Integration' },
                                ]}
                                value={email_sender_value}
                                onChange={(_, { value }) => {
                                    resetField('email_integration');
                                    onChange({ target: { email_sender_name, value } });
                                }}
                                required
                            />
                            <Controller
                                control={control}
                                name={'email_integration'}
                                render={({ field: { email_integration_name, value: email_integration_value, onChange } }) => (
                                    <>
                                        {email_sender_value === 'DYL' ? (
                                            <Form.Input
                                                width={6}
                                                label='Email'
                                                name={email_integration_name}
                                                onChange={(_, { value }) => {
                                                    onChange({ target: { email_integration_name, value } });
                                                }}
                                                value={email_integration_value}
                                                required
                                            />
                                        ) : (
                                            <Form.Select
                                                className='EmailSenderForm__IntegrationDropdown'
                                                width={6}
                                                label='Email'
                                                loading={isReadingIntegrations}
                                                options={
                                                    integrations.map(({ id, email, provider }) => ({
                                                        key: id,
                                                        text: email,
                                                        value: id,
                                                        content: (
                                                            <ClippedContent popup={false}>
                                                                <Image src={provider === 'google' ? GmailSVG : OutlookSVG} size='mini' /> {email}
                                                            </ClippedContent>
                                                        )
                                                    }))
                                                }
                                                onChange={(_, { value }) => {
                                                    onChange({ target: { email_integration_name, value } });
                                                }}
                                                value={email_integration_value}
                                                required
                                            />
                                        )}
                                    </>
                                )}
                            />
                        </>
                    )}
                />
            </Form.Group>
        </Form>
    );
}

export default EmailSender;
