import productsActions from "actions/products";
import { DateInput, DateTimeUtils, FileInput } from "dyl-components";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Segment, Header, Form } from "semantic-ui-react"
import productSchema from "../../schemas/products/productSchema";

const ProductInfo = ({ setIsNameDuplicate }) => {
    const [typingTimeout, setTypingtimeout] = useState(null);

    const dispatch = useDispatch();
    const { control, productBeingEdited, clearErrors, setError } = useFormContext();
    const { product } = useSelector(state => state.products);
    const isCheckingForDuplicates = useSelector(state => state.products.isCheckingForDuplicates);

    const generateEffectiveDateMessage = (status, date) => {
        let message = null;
        if (!DateTimeUtils.isBeforeOrOnCurrentDate(date, DateTimeUtils.DATE_FORMAT)) {
            if (!productBeingEdited) {
                if (status === 'active') {
                    message = `This product is inactive until ${date}`;
                }
            } else {
                let { status: originalStatus } = product;
                if (originalStatus === 'active' && (status === 'inactive' || status === 'discontinued')) {
                    message = `This product is active until ${date}`;
                }
                if (originalStatus === 'inactive' && (status === 'active' || status === 'discontinued')) {
                    message = `This product is inactive until ${date}`;
                }
                if (originalStatus === 'discontinued' && (status === 'active' || status === 'inactive')) {
                    message = `This product is discontinued until ${date}`;
                }
            }
        }

        return (
            <p>
                <em>{message}</em>
            </p>
        );
    }

    return (
        <Segment>
            <Header as="h3" color="primary">Product Info</Header>
            <Form.Group widths={"equal"}>
                <Controller
                    control={control}
                    name="name"
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={async (_, { name, value }) => {
                                if (typingTimeout) {
                                    clearTimeout(typingTimeout)
                                }
                                onChange({ target: { name, value } });
                                const isValid = await productSchema.name.isValid(value);
                                if (isValid && value !== '') {
                                    setTypingtimeout(setTimeout(async () => {
                                        await clearErrors(name);
                                        await setIsNameDuplicate(false);
                                        const isDuplicate = (await dispatch(productsActions.isDuplicate({ name: value, type: "product", ...(productBeingEdited ? { id: productBeingEdited } : {}) })));
                                        if (isDuplicate) {
                                            await setError(name, {
                                                type: 'unique_product',
                                                message: 'Product name already exists!'
                                            })
                                            await setIsNameDuplicate(true);
                                        }
                                    }));
                                }
                            }}
                            label="Product Name"
                            required
                            error={error?.message}
                            loading={isCheckingForDuplicates}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="product_type"
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Field control='div'>
                            <Form.Select
                                name={name}
                                value={value}
                                onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                label="Type"
                                required
                                options={[
                                    {
                                        key: "good", value: "good", text: "Good"
                                    },
                                    {
                                        key: "service", value: "service", text: "Service"
                                    }
                                ]}
                                error={error?.message}
                                selectOnBlur={false}
                                disabled={productBeingEdited}
                                style={{ marginBottom: "0.5em" }}
                            />
                            {value === "good" && (
                                <Controller 
                                    control={control}
                                    name="shippable"
                                    render={({ field: { name, value, onChange } }) => (
                                        <Form.Checkbox 
                                            label="Shippable"
                                            name={name}
                                            checked={value}
                                            onChange={(_, { checked }) => {onChange({ target: { name, value: checked } })}} 
                                        />
                                    )}
                                />
                            )}
                        </Form.Field>
                    )}
                />
            </Form.Group>
            <Controller
                control={control}
                name="description"
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <Form.TextArea
                        name={name}
                        value={value}
                        onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                        error={error?.message}
                        label="Product Description"
                    />
                )}
            />
            <Controller
                control={control}
                name="image"
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <Form.Field
                        control={FileInput}
                        name={name}
                        files={value?.map(file => ({
                            ...file,
                            path: URL.createObjectURL(file)
                        }))}
                        onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                        error={error?.message}
                        label="Product Image"
                        showImage
                        accept={"image/png, image/jpeg"}
                        hasBrowse
                        icon="image"
                        showIcon
                    />
                )}
            />
            <Form.Group widths={"equal"}>
                <Controller
                    control={control}
                    name="status"
                    render={({ field: { name, value: status, onChange }, fieldState: { error } }) => (
                        <>
                            <Form.Select
                                name={name}
                                value={status}
                                onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                label="Status"
                                required
                                options={[
                                    {
                                        key: "active", value: "active", text: "Active"
                                    },
                                    {
                                        key: "inactive", value: "inactive", text: "Inactive"
                                    },
                                    ...(productBeingEdited ? [{
                                        key: "discontinued", value: "discontinued", text: "Discontinued"
                                    }] : [])
                                ]}
                                error={error?.message}
                                selectOnBlur={false}
                            />
                            <Controller
                                control={control}
                                name="active_date"
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Field as='div'>
                                        <Form.Field
                                            control={DateInput}
                                            name={name}
                                            value={value}
                                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                            label="Effective Date"
                                            error={error?.message}
                                            dateFormat={DateTimeUtils.DATE_FORMAT}
                                        />
                                        {DateTimeUtils.isValid(value, DateTimeUtils.DATE_FORMAT) && (
                                            generateEffectiveDateMessage(status, value)
                                        )}
                                    </Form.Field>
                                )}
                            />
                        </>
                    )}
                />

            </Form.Group>
        </Segment>
    )
}

export default ProductInfo;
