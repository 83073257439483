import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TableWithHeader } from 'dyl-components';

import SequencesToolbar from './subcomponents/SequencesToolbar';
import SequenceTable from './subcomponents/SequenceTable';

import sequencesActions from 'actions/sequences';

import './index.scss';

const Sequences = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(sequencesActions.read({ ...Object.fromEntries(params) }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`/settings/sequences${query_string ? `?${query_string}` : ''}`);
    }

    return (
        <div className='SequencesPage'>
            <TableWithHeader
                header={(
                    <SequencesToolbar />
                )}
                table={(
                    <SequenceTable onPageChange={onPageChange} />
                )}
            />
        </div>
    );
}

export default Sequences;
