import React from 'react';
import { Segment, Grid, Dropdown } from 'semantic-ui-react';
import Avatar from 'react-avatar';

import { StringUtils, PhoneUtil} from 'utils';
import { DateTimeUtils} from 'dyl-components';
import { connect } from 'react-redux';
import textUiActions from 'actions/text_ui';
import textActions from 'actions/text';


const MessageRecipient = ({
    recipient,
    additionalControls,
    phones,
    to,
    onSelectContactPhone,
    contact_id,
    closeButton,
    size = 46
}) => (
    <Segment style={{ paddingTop: 16, paddingBottom: 20 }}>
        <span style={{float: 'right'}}>{closeButton}</span>
        <Grid columns='equal' style={{ paddingTop: 0 }}>
            {additionalControls && (
                <Grid.Column style={{ paddingLeft: 0 }}>
                    {additionalControls}
                </Grid.Column>)
            }
            <Grid.Column width={12}>
                <Avatar maxInitials={2} name={recipient} round size={size} />
                {' '}
                <div style={{ display: 'inline', paddingLeft: 5 }}>
                    <span style={{ color: '#1c1d21', fontStyle: 'bold', fontSize: 14, fontWeight: 700 }}>
                        TO:
                    </span>
                    <span style={{ paddingLeft: 5, color: '#4d4f5c', fontStyle: 'regular', fontWeight: 400, fontSize: 14 }}>
                        {recipient} <Dropdown 
                            options={phones}
                            onChange={(_, { value }) => {onSelectContactPhone(contact_id, value)}}
                            value={to}
                        />
                    </span>
                </div>
            </Grid.Column>
        </Grid>
        
    </Segment>
);

const mapStateToProps = state => {
    const contact = state.contact.contact;
    const contact_type = contact.contact_type;
    let recipient;
    switch (contact_type) {
        case 'person':
            const { first_name, last_name } = contact;
            recipient = `${first_name || ''} ${last_name || ''}`;
            break;
        case 'business':
        case 'household':
            const custom_data = {};
            const contact_custom = state.contact.custom_data;
            contact_custom.fields.forEach(field => {
                custom_data[`fields|${field.field_name}`] = field.value;
            })
            recipient = custom_data[`fields|_${contact_type}_name`] || `${StringUtils.capitalize(contact_type)} Name`;
            break;
        default:
            recipient = '';
    }
    return {
        recipient,
        phones: state.contact_phone.phone.map(({ id, phone }) => ({
            key: id,
            value: phone,
            text: PhoneUtil.formatPhoneNumber(phone)
        })),
        to: state.text_ui.to,
        contact_id: Number(state.contact.contact_id)
    }
}

const mapDispatchToProps = dispatch => ({
    onSelectContactPhone: (contact_id, contact_phone) => {
        const queryParameters = { contact_phone, start: DateTimeUtils.getLast(3, 'years'), end: DateTimeUtils.getCurrentDate(DateTimeUtils.DATE_FORMAT, true, false) };
        dispatch(textUiActions.selectContactPhone(contact_phone));
        dispatch(textActions.getMessages(contact_id, queryParameters));
        dispatch(textActions.getFilteredMessages(contact_id, queryParameters));
    } 
})

export default connect(mapStateToProps, mapDispatchToProps)(MessageRecipient);
