import React from 'react';
import { connect } from 'react-redux';

import ChatFeature from './subcomponents/ChatFeature';
import ChatRooms from './subcomponents/ChatRooms';
import AssignUsersToChatRooms from './subcomponents/AssignUsersToChatRooms';
import chatRoomsActions from 'actions/chat_rooms';

class ManageChatRooms extends React.Component {
    state = {
        isChatFeatureEnabled: false,
    }

    componentDidMount() {
        this.props.onReadChatRooms();
    }

    onToggleChatFeature = (isChatFeatureEnabled) => {
        this.setState({ isChatFeatureEnabled });
    }

    render() {
        const { isChatFeatureEnabled } = this.state;
        return (
            <React.Fragment>
                <ChatFeature isChatFeatureEnabled={isChatFeatureEnabled} onUpdate={this.onToggleChatFeature} />
                <ChatRooms />
                <AssignUsersToChatRooms />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    onReadChatRooms: () => {
        dispatch(chatRoomsActions.readChatRooms())
    }
})

export default connect(null, mapDispatchToProps)(ManageChatRooms);
