import React from 'react';
import { Grid, Header, List, Ref, } from 'semantic-ui-react';
import { connect } from 'react-redux';

import RecentCalls from './RecentCalls';
import DialPad from './DialPad';

import './Content.scss';
import { Call } from 'shared/Call';
import ActionsView from './ActionsView';
import { Droppable } from 'react-beautiful-dnd';

const CallboxContent = ({ callerInActionsView, isReading }) => (
    <Grid columns='equal' stackable stretched>
        <Droppable droppableId={'CALLBOX'}>
            {provided => (
                <Ref innerRef={provided.innerRef}>
                    <Grid.Column width={11} {...provided.droppableProps}>
                        <div className="CallboxContentDiv">
                            {callerInActionsView && (
                                <List divided relaxed>
                                    <Call call={callerInActionsView} draggable={callerInActionsView.uuid_call !== ''} index={0} isReading={isReading} />
                                </List>
                            )}
                            <RecentCalls />
                        </div>
                        {provided.placeholder}
                    </Grid.Column>
                </Ref>
            )}
        </Droppable>
        <Grid.Column>
            <ActionsView />
            <div className="CallboxDialDiv">
                <Header as='h3'>Dial Pad</Header>
                <div style={{ paddingBottom: 30 }}>
                    <DialPad />
                </div>
            </div>
        </Grid.Column>
    </Grid>
);

const mapStateToProps = state => {
    return {
        callerInActionsView: state.officeView.callerInActionsView,
        actionsViewVisible: state.officeView.actionsViewVisible,
        isActiveCall: state.officeView.isActiveCall,
        recentCalls: state.officeView.recentCalls,
        isReading: state.pbx_config.isReadingCallQueues || state.teams.isReadingTeams
    }
}

export default connect(mapStateToProps)(CallboxContent);
