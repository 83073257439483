import React from 'react';
import EmailTask from './EmailTask';
import TextTask from './TextTask';

const TaskFields = ({
    type,
    control,
    description,
    subject,
    body,
	signature,

    template,
    setTemplate,
    onSelectTemplate,
	trigger,
    
	userSignature,
	companySignature,
	isDisabledSignature,

    editorMode,
    setEditorMode,
	setValue,
    isAllowedToModify=true
}) => {
    switch (type) {
        case 'Text':
            return (
                <TextTask
                    control={control}
                    onSelectTemplate={onSelectTemplate}
                />
            )
        case 'Email':
            return (
                <EmailTask
                    isAllowedToModify={isAllowedToModify}
                    control={control}
                    description={description}
                    subject={subject}
                    body={body}
                    signature={signature}
                    template={template}
                    setTemplate={setTemplate}
                    onSelectTemplate={onSelectTemplate}
                    editorMode={editorMode}
                    setEditorMode={setEditorMode}
					setValue={setValue}
					trigger={trigger}

                    userSignature={userSignature}
                    companySignature={companySignature}
                    isDisabledSignature={isDisabledSignature}
                />
            )
        default:
            return ""
    }
};

export default TaskFields;
