import React from 'react';
import { Form, Header, Icon, Popup } from 'semantic-ui-react';
import { STATES } from 'shared/constants/STATES';
import { Controller } from 'react-hook-form';
import { Button } from "dyl-components"; 
import './E911LocationsForm.scss'

const E911LocationsForm = ({
    control,
    onValidate,
    isValid,
    isDirty,
    pbxListPhones,
    isReadingListPhones,
    revalidating,
    onClosePopUp
}) => {
    return (
        <Form loading={isReadingListPhones}>
            <Header as='h3' color='primary' style={{display:'inline-block'}}>Add E911 Location</Header>
            <Icon size='large' onClick={() => onClosePopUp()} className='fa-solid fa-xmark' style={{cursor: 'pointer', float: 'right'}}/>
            <Form.Group widths='equal'>
                <Controller
                    name='label'
                    control={control}
                    render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                        <Form.Input
                            label="Name"
                            required
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Type name'
                            error={error?.message}
                        />
                    )}
                />
                <Controller
                    name='caller_id_number'
                    control={control}
                    render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                        <Form.Select
                            label="Number"
                            required
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Select number'
                            search
                            error={error?.message}
                            options={pbxListPhones}
                        />
                    )}
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Controller
                    name='address'
                    control={control}
                    render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                        <Form.Input
                            label="Street Address"
                            required
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Type street address'
                            error={error?.message}
                        />
                    )}
                />
                <Controller
                    name='address2'
                    control={control}
                    render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                        <Form.Input
                            label="Apt, Unit building, Floor #"
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Type Apt, Unit building, Floor #'
                            error={error?.message}
                        />
                    )}
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Controller
                    name='city'
                    control={control}
                    render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                        <Form.Input
                            label="City"
                            required
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Type city'
                            error={error?.message}
                        />
                    )}
                />
                <Controller
                    name='state'
                    control={control}
                    render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                        <Form.Select
                            label="State"
                            required
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Select state'
                            search
                            error={error?.message}
                            options={STATES.map(state => ({ ...state, value: state.key }))}
                        />
                    )}
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Controller
                    name='zip'
                    control={control}
                    render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                        <Form.Input
                            label="Zipcode"
                            required
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            error={error?.message}
                            placeholder='Type zipcode'
                        />
                    )}
                />
                <Form.Input style={{display: 'none'}} />
            </Form.Group>
            <Popup
                trigger={
                    <Button
                        onClick={onValidate}
                        disabled={!isDirty || !isValid}
                        style={{float:'right'}}
                    >
                        {revalidating ? 'Revalidate' : 'Validate'}
                    </Button>
                }
                content="Fields updated with suggestions"
                wide='very'
                position='left center'
                open={revalidating}
                className='e911-validations'
            />
        </Form>
    )
}

export default E911LocationsForm;



