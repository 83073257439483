import { generateCreateActionCreator, generateReadOneActionCreator, generateUpdateActionCreator } from '../crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import routes from 'actions/routes';

export default {
    getOpportunityRecentQuote: generateReadOneActionCreator(routes.API_OPPORTUNITY_RECENT_QUOTE, ACTION_NAMES.OPPORTUNITY_RECENT_QUOTE, 'recent_quote'),
    getQuotesByOpportunityTabView: generateReadOneActionCreator(routes.API_OPPORTUNITY_RECENT_QUOTE, ACTION_NAMES.OPPORTUNITY_TAB_VIEW_QUOTES, 'tab_view'),
    readQuote: generateReadOneActionCreator(routes.API_QUOTE, ACTION_NAMES.QUOTE),
    createQuote: generateCreateActionCreator(routes.API_QUOTE, ACTION_NAMES.QUOTE),
    updateQuote: generateUpdateActionCreator(routes.API_QUOTE, ACTION_NAMES.QUOTE),
    updateQuoteStatus: generateUpdateActionCreator(routes.API_QUOTE, ACTION_NAMES.QUOTE_STATUS, () => {}, () => {}, 'status'),

    createVersion: generateCreateActionCreator(routes.API_QUOTE, ACTION_NAMES.QUOTE_VERSION, () => {}, () => {}, 'version'),
    getVersionsSummary: generateReadOneActionCreator(routes.API_QUOTE, ACTION_NAMES.QUOTE_VERSIONS_SUMMARY, 'versions_summary'),
    getVersions: generateReadOneActionCreator(routes.API_QUOTE, ACTION_NAMES.QUOTE_VERSIONS, 'version'),
    getVersion: generateReadOneActionCreator(routes.API_QUOTE_VERSION, ACTION_NAMES.QUOTE_VERSION),
    readQuoteContactInformation: generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.QUOTE_CONTACT_INFORMATION),
    updateQuoteAccountId: generateUpdateActionCreator(routes.API_OPPORTUNITY_RECENT_QUOTE, ACTION_NAMES.OPPORTUNITY_RECENT_QUOTE),

    updateAddresses: generateUpdateActionCreator(routes.API_QUOTE, ACTION_NAMES.QUOTE_ADDRESS, () => {}, () => {}, 'location'),
    getOrderQuote: generateReadOneActionCreator(routes.API_OPPORTUNITY_RECENT_QUOTE, ACTION_NAMES.QUOTE_ORDER, 'order_quote')
}
