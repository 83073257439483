import React from 'react';
import { EditableLabels, Notification, STATUS_TYPES } from 'dyl-components';
import { connect } from 'react-redux';
import eventsActions from 'actions/events';
import eventActions from 'actions/event';

class EventLabelsContainer extends React.Component {

    state = {
        event_labels: [],
        key: 'EVENT_LABELS'
    }

    componentDidMount() {
        this.props.onReadEventLabels().then(() => {
            const { event_labels } = this.props;
            this.setState({
                event_labels,
                key: ''
            }, () => {
                this.setState({
                    key: 'EVENT_LABELS'
                })
            })
        });
    }

    onSave = async (event_labels) => {
        try {
            await Promise.all([
                this.onSaveEventLabels(event_labels.filter(({ id }) => !id).map(({ value }) => ({ name: value[0].replace(/\s+/g, ' ').trim() })))
            ])
            Notification.alert(`Successfully saved the event labels!`, STATUS_TYPES.SUCCESS);
            await this.props.onReadEventLabels();
            this.setState({
                event_labels: this.props.event_labels,
                key: ''
            }, () => {
                this.setState({
                    key: 'EVENT_LABELS'
                })
            })
        } catch (e) {
            console.log(e);
        }
    }

    onSaveEventLabels = async (event_labels) => {
        if (event_labels.length > 0) {
            try {
                await this.props.onAddEventLabels(event_labels);
            } catch (e) {
                console.log(e);
                Notification.alert(`Failed to create event label${event_labels.length > 1 ? 's' : ''}`, STATUS_TYPES.ERROR);
                return Promise.reject();
            }
        } else {
            return Promise.resolve();
        }
    }

    onUpdate = async (id, name) => {
        try {
            await this.props.onUpdateEventLabel(id, name);
            Notification.alert(`Successfully updated the event label!`, STATUS_TYPES.SUCCESS);
            return Promise.resolve();
        } catch (error) {
            if(error.Code === 409){
                Notification.alert(`Event label is already existing`, STATUS_TYPES.ERROR);
            } else {
                Notification.alert(`Failed to update event label`, STATUS_TYPES.ERROR);
            }
          
            return Promise.reject();
        }
    }

    onRemove = async (id) => {
        try {
            await this.props.onDeleteEventLabel(id);
            Notification.alert(`Successfully deleted the event label!`, STATUS_TYPES.SUCCESS);
            return Promise.resolve();
        } catch (e) {
            console.log(e);
            Notification.alert(`Failed to delete event label`, STATUS_TYPES.ERROR);
            return Promise.reject();
        }
    }

    onCheckDuplicate = async (value) => {
        try {
            let { id, name } = await this.props.onCheckEventLabelDuplicate(value);
            if (id && name) {
                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        } catch (e) {
            console.log(e);
            return Promise.reject();
        }
    }

    render() {

        const fields = [
            { header: 'Event Labels', type: 'input', placeholder: 'Type event label', defaultValue: '', editable: true }
        ];

        return (
            <EditableLabels
                key={this.state.key}
                name='event_labels'
                addLabel='Add Event Label'
                info='Edit will reflect on all events. Delete will remove the label from all future events.'
                fields={fields}
                items={this.state.event_labels}
                onSave={this.onSave}
                onUpdate={this.onUpdate}
                onRemove={this.onRemove}
                isLoading={this.props.isLoading}
                onCheckDuplicate={this.onCheckDuplicate}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        event_labels: state.events.event_labels.map(({ name, id }) => ({ id, value: [name] })),
        isLoading: state.events.isReadingEventLabels || state.events.isAddingEventLabels || state.event.isDeletingEventLabel || state.event.isUpdatingEventLabel
    }
}

const mapDispatchToProps = dispatch => ({
    onReadEventLabels: () => {
        return dispatch(eventsActions.readEventLabels());
    },
    onAddEventLabels: (event_labels) => {
        return dispatch(eventsActions.addEventLabels(event_labels));
    },
    onUpdateEventLabel: (event_label_id, name) => {
        return dispatch(eventActions.updateEventLabel(event_label_id, { name }))
    },
    onDeleteEventLabel: (event_label_id) => {
        return dispatch(eventActions.deleteEventLabel(event_label_id));
    },
    onCheckEventLabelDuplicate: (event_label) => {
        return dispatch(eventActions.checkEventLabelDuplicate(null, { event_label }))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(EventLabelsContainer);
