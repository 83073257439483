import { Table as SemanticTable } from "semantic-ui-react";

import { Table, TableLoader } from "dyl-components";
import SupplyChainNetwork from "./SupplyChainNetwork";

import "./index.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import supplyChainNetworkService from "actions/supply_chain_network";
import productsActions from "actions/products";
import ProductCategory from "./ProductCategory";

const ProductFields = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(supplyChainNetworkService.getFields());
        dispatch(productsActions.readProducts({ limit: 100 }));
    }, [dispatch]);

    const isReadingFields = useSelector(
        (state) => state.supply_chain_network.isReadingFields || state.products.isReadingProducts
    );

    return (
        <Table striped={false} className="ProductFields" fixed>
            <SemanticTable.Header className="Table__header--primary">
                <Table.Row>
                    <SemanticTable.HeaderCell width={1} />
                    <SemanticTable.HeaderCell width={2}>
                        Field Name
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Field Type
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>Values</SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell width={6}>
                        Description
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>Actions</SemanticTable.HeaderCell>
                </Table.Row>
            </SemanticTable.Header>
            <Table.Body>
                {isReadingFields ? (
                    <TableLoader isLoading colspan={6} />
                ) : (
                    <>
                        <ProductCategory />
                        <SupplyChainNetwork />
                    </>
                )}
            </Table.Body>
        </Table>
    );
};

export default ProductFields;
