const initialState = {
    import_data: [],
    import_count: 0,
    isReadingImports: false,
    isReadingImportCSV: false,
    isAddingImport: false,
    isReadingImport: false,
    importBeingUpdated: null,
    importBeingDeleted: null,

    import_info: {},
    csv: null
}

export default initialState;
