import React from 'react';
import { Form, Icon } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import { MultipleToggableDropdown } from 'dyl-components';

const ProductInterestsForm = ({ control, handleSubmit, isDirty, isValid, onSave, productInterestOptions, onCancel, search, onSearchChange, setSearch, isReadingProductInterestOptions, isReadingProductInterests }) => {
    return (
        <div className='FormFlex'>
            <Controller
                className='FormFlex__Dropdown'
                name='product_interests'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field
                        control={MultipleToggableDropdown}
                        values={value}
                        nested_options={
                            productInterestOptions.map(productInterestOption => ({
                                text: productInterestOption.value, 
                                key: productInterestOption.id, 
                                value: productInterestOption.id, 
                                hasOptions: false, 
                                options: [], 
                                parent: null
                            }))
                        }
                        onChange={(_, { value }) => {
                            setSearch("");
                            onChange({ target: { name, value } });
                        }}
                        placeholder='Search product interests'
                        onHoverParent={() => { }}
                        searchValue={search}
                        onSearchChange={onSearchChange}
                        loading={isReadingProductInterestOptions}
                        selection
                    />
                )}
            />
            <div className='ContactGroupForm__buttonContainer'>
                <Icon size='large' link onClick={onCancel} className='fa-solid fa-xmark redIcon' style={{ marginBottom: 21 }} />
                <Icon size='large' disabled={!isDirty || !isValid} color='primary' link onClick={handleSubmit(onSave)} className='fa-solid fa-check' style={{ marginBottom: 21 }} />
            </div>
        </div>
    );
}

export default ProductInterestsForm;
