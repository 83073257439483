import React from 'react';
import { Controller } from 'react-hook-form';
import { Form, Segment } from 'semantic-ui-react';

import './DataMapping.scss';

const Provider = ({
    dataMappingControl,
    post,
    providerRecordNames,
    isReadingProviderRecordNames,
	dataMappingTrigger,
    isMappingDuplicated,
    isProviderNameValid,
    setIsProviderNameValid,
    providerNameErrorMessage,
    isDataRecordNameValid,
    dataRecordErrorMessage,
}) => {

    const recordNames = providerRecordNames.length !== 0 ? 
        providerRecordNames.map((provider)=>({ 
            key: provider.id,
            value: provider.id,
            text: provider.name,
        })) : [];
    return (
        <React.Fragment>
            <Segment className='DataMapping__DataField'>
                <Form>
                    <Form.Group widths='3'>
                        <Form.Field
                                control='div'
                                required
                                label='Provider Name'
                        >   
                            <Controller
                                name='provider_name'
                                control={dataMappingControl}
                                render={({ field: { name, value, onChange } }) => (
                                    <Form.Select
                                        name={name}
                                        value={value}
                                        placeholder='Select Provider'
                                        onChange={(_, { value }) => {
                                            onChange({
                                                target: {
                                                    name, value
                                                }
                                            })
                                            if(!isProviderNameValid){
                                                setIsProviderNameValid(true); 
                                            }
                                        }}
                                        options={ !post ? recordNames : [{key: value, value: value, text: value}]}
                                        selectOnBlur={false}
                                        required
                                        disabled={post}
                                        loading={!post ? isReadingProviderRecordNames : false}
                                        search
                                        error={!isProviderNameValid && providerNameErrorMessage}
                                    />
                                )}
                            />
                        </Form.Field>
                        <Form.Field
                            control='div'
                            required
                            label='Data Record Name'
                        >                            
                        <Controller
                            name='data_record_name'
                            control={dataMappingControl}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Input
                                    name={name}
									error={!isDataRecordNameValid ? dataRecordErrorMessage : error?.message}
                                    value={value}
                                    placeholder='Type Data Record Name'
                                    onChange={(_, { value }) => {
                                        onChange({
                                            target: {
                                                name, value
                                            }
                                        })
                                    }}
                                    options={[
                                        {
                                            key: value, value: value, text:  value
                                        }
                                    ]}
                                    disabled={post}
                                    onBlur={() => isMappingDuplicated(value)}
                                />
                            )}
                        />
                        </Form.Field>
                        {!post &&
                            <Controller
                                name='differentiator'
                                control={dataMappingControl}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Select
										error={error?.message}
                                        name={name}
                                        label='Mapping differentiator'
                                        value={value}
                                        placeholder='Select'
                                        onChange={(_, { value }) => {
											dataMappingTrigger('data_record_name');
                                            onChange({
                                                target: {
                                                    name, value
                                                }
                                            })
                                        }}
                                        options={[
                                            { key: 'none', value: 'none', text: 'None' },
                                            { key: 'field', value: 'field', text: 'Field' },
                                            { key: 'subject', value: 'subject', text: 'Subject' }
                                        ]}
                                        selectOnBlur={false}
                                    />
                                )}
                            />
                        }
                        
                    </Form.Group>
                </Form>
            </Segment>
        </React.Fragment>
    )
};

export default Provider;
