import React from 'react';
import { Modal, HTMLPreview } from 'dyl-components';
import { Icon, Header, List, Segment, Loader } from 'semantic-ui-react';

import { connect } from 'react-redux';

const PreviewTemplateModal = ({ open, onClose, subject, content, attachments, isReadingTemplate }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header basic>
                <b>Subject</b> <span style={{ marginLeft: 10, fontWeight: 100 }}>{subject}</span>
            </Modal.Header>
            <Modal.Content scrolling>
                <Loader active={isReadingTemplate} />
                <HTMLPreview content={content} full padded={false} />
                <Header as='h5'>Attachments: </Header>
                <Segment basic>
                    <List horizontal>
                        {attachments && attachments.map(attachment => (
                            <List.Item>
                                <Header size="mini" color="black" icon>
                                    <Icon name="file pdf outline" />
                                    {attachment.path}
                                </Header>
                            </List.Item>
                        ))}
                    </List>
                </Segment>
            </Modal.Content>
        </Modal>
    )
}

const mapStateToProps = state => ({
    isReadingAttachments: state.email_attachment.isReadingAttachments
})

export default connect(mapStateToProps)(PreviewTemplateModal);
