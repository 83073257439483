import React from 'react';
import { TableWithHeader } from 'dyl-components';
import EmailNotificationsAttachmentsSetting from './EmailNotificationsAttachmentsSetting';
import { Grid } from 'semantic-ui-react';
import EmailNotificaitonsAttachmentsHeader from './EmailNotificationsAttachmentsHeader';
import { LeftPaddedColumn } from 'dyl-components';

class EmailNotificationsAttachmentsTable extends React.Component {
    state = {
        isEditMode: false,
        areAttachmentsEnabled: this.props.areAttachmentsEnabled
    }

    onChange = (_, { name, value }) => {
        this.setState({
            [name]: value
        });
    }

    onEnableEditMode = () => {
        this.setState({
            isEditMode: true
        })
    }

    onCloseEditMode = () => {
        this.setState({
            isEditMode: false,
            areAttachmentsEnabled: this.props.areAttachmentsEnabled,
        })
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false,
        }, () => {
            this.props.onUpdate({
                areAttachmentsEnabled: this.state.areAttachmentsEnabled
            })
        });
    }

    render() {
        return (
            <TableWithHeader 
                header={<EmailNotificaitonsAttachmentsHeader 
                    isEditMode={this.state.isEditMode}
                    onEnableEditMode={this.onEnableEditMode}
                    onCloseEditMode={this.onCloseEditMode}
                    onUpdate={this.onUpdate}
                />}

                table={
                    <Grid celled style={{margin: 0}} verticalAlign='middle'>
                        <Grid.Row as={LeftPaddedColumn}>
                            <Grid.Column>Attach call recordings, voicemails and faxes to email notifications.</Grid.Column>
                        </Grid.Row>
                        <EmailNotificationsAttachmentsSetting 
                            {...this.state}
                            onChange={this.onChange}
                        />
                    </Grid>
                }
            />
        )
    }
}

export default class EmailNotificationsAttachmentsTableContainer extends React.Component {
    state = {
        areAttachmentsEnabled: true
    }

    onUpdate = (emailNotificationsAttachmentsSetting) => {
        this.setState({
            ...emailNotificationsAttachmentsSetting
        })
    }

    render() {
        return (
            <EmailNotificationsAttachmentsTable 
                {...this.state}
                onUpdate={this.onUpdate} 
            />
        )
    }
}
