import moduleInformationActions from 'actions/module_information';
import { Error404, Error500 } from 'pages/ErrorPages';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Dimmer, Loader, Segment, Tab } from 'semantic-ui-react';
import HistorySection from './HistorySection';
import InfoSection from './InfoSection';
import QuotesSection from './QuotesSection';
import ModuleHeader from './ModuleHeader';
import NotesSection from './NotesSection';
import pipelineActions from 'actions/pipeline';
import campaignsAction from "actions/campaigns";
import quoteActions from "actions/quote";
import QuoteBuilderProvider from 'shared/context/QuoteBuilderProvider';
import contactActions from 'actions/contact';


const ModuleInformation = ({ onClose }) => {
    const [params] = useSearchParams();
    const tab = params.get('module_tab');
    const module_id = params.get('module_id');

    const dispatch = useDispatch();

    const { error, pipeline, isReadingPipeline, account_stage, active, isTransitioningPipeline } = useSelector(state => {
        return ({
            error: state.module_information.error,
            pipeline: state.module_information.pipeline,
            isReadingPipeline: state.module_information.isReadingPipeline,
            account_stage: state.module_information.pipeline?.module,
            active: state.module_information.pipeline?.active,
            isTransitioningPipeline: state.pipeline.isTransitioningPipeline || state.sequences.isAddingContactToSequence || state.contact_sequence.isRemovingSequence
        });
    });

    useEffect(() => {
        if (module_id) {
            dispatch(moduleInformationActions.getOne(module_id)).then(response => {
                const contact_id = response.contact?.id;
                dispatch(campaignsAction.readPersonCampaignConverted({ person_id: contact_id, campaign_conversion: response.module }));
            });
            dispatch(quoteActions.getOpportunityRecentQuote(module_id));
        }
    }, [module_id, dispatch]);

    useEffect(() => {
        if (active === false) {
            dispatch(pipelineActions.readCloseReasons({ account_stage }));
        }
    }, [account_stage, active, dispatch]);

    const panes = [
        {
            menuItem: 'Info',
            render: () => <Tab.Pane><InfoSection /></Tab.Pane>
        },
        {
            menuItem: 'History',
            render: () => <Tab.Pane><HistorySection /></Tab.Pane>
        },
        ...(account_stage === 'opportunity' ? [
            {
                menuItem: 'Quotes',
                render: () => <Tab.Pane><QuotesSection /></Tab.Pane>
            }
        ] : []),
        {
            menuItem: 'Notes',
            render: () => <Tab.Pane><NotesSection /></Tab.Pane>
        }
    ];

    const getActiveTab = () => {
        const index = panes.findIndex(pane => pane.menuItem?.toLowerCase() === tab?.toLowerCase());
        return index === -1 ? 0 : index;
    }

    const navigate = useNavigate();
    const location = useLocation();

    const onChangeTab = (index) => {
        const { menuItem } = panes[index];
        const query = new URLSearchParams(params);
        query.set('module_tab', menuItem.toLowerCase());
        const query_string = query.toString();
        navigate(`${location.pathname}${query_string ? `?${query_string}` : ''}`, { state: { isTogglingPanel: true } });
    }

    if (isReadingPipeline) {
        return (
            <div style={{ height: '96.25vh' }}>
                <Dimmer active>
                    <Loader active />
                </Dimmer>
            </div>
        );
    }

    if (!isReadingPipeline && error) {
        return <Error500 message={"Something went wrong"} />;
    }

    if (!isReadingPipeline && !pipeline) {
        return <Error404 message={"Module not found"} />;
    }

    return (
        <Segment loading={isTransitioningPipeline} style={{ margin: 0, padding: 0 }} basic>
            <ModuleHeader onCloseModulePanel={onClose} />
            <Tab
                menu={{ color: 'blue', secondary: true, pointing: true }}
                panes={panes}
                activeIndex={getActiveTab()}
                onTabChange={(_, { activeIndex }) => { onChangeTab(activeIndex) }}
                renderActiveOnly
            />
        </Segment>
    )
};

const ModuleInformationContainer = ({ onClose }) => {
    const dispatch = useDispatch();

    const [params] = useSearchParams();
    const pipeline_id = params.get('module_id');

    const location = useLocation();
    const navigate = useNavigate();

    const pathParams = useParams();

    const refresh = () => {
        const query = new URLSearchParams(params);
        query.set('module_id', pipeline_id);
        const query_string = query.toString();
        navigate(`${location.pathname}?module_id=${pipeline_id}`);
        navigate(`${location.pathname}${query_string ? `?${query_string}` : ''}`);
        dispatch(moduleInformationActions.getOne(pipeline_id));
        if (Boolean(pathParams?.contact_id)) {
            dispatch(contactActions.readContact(Number(pathParams?.contact_id)));
        }
    }

    return (
        <QuoteBuilderProvider refreshAccountInformation={() => {
            return refresh();
        }}>
            <ModuleInformation onClose={onClose} />
        </QuoteBuilderProvider>
    )
}

export default ModuleInformationContainer;
