import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DateTimeUtils, TableWithHeader } from 'dyl-components';

import './index.scss';
import BulkActionsProvider, { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import campaignUsersActions from 'actions/campaign_users';
import campaignActions from 'actions/campaigns';
import UsersToolbar from './subcomponents/UsersToolbar';
import UsersTable from './subcomponents/UsersTable';

const CampaignUserReports = () => {
    const { id } = useParams();

    const campaign = useSelector(state => state.campaigns.campaign);
    const endDate = DateTimeUtils.formatEpoch(campaign.is_company_campaign?.end_time, DateTimeUtils.DATE_FORMAT);
    const isExpired = !DateTimeUtils.isAfterOrOnCurrentDate(endDate, DateTimeUtils.DATE_FORMAT, false);
    const isDeleted = (campaign.status || '').toLowerCase() === 'deleted';

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const routeRoot = `/campaigns/${id}/user-reports`;

    const searchQuery = params.get('search') || '';
    const [search, setSearch] = useState(searchQuery);

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }

    const cancelFunction = () => {
        setSearch("");
        const query = new URLSearchParams(params);
        query.delete('search');
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    };

    const [, setSelectedUsers, , setAreUsersInAllPagesSelected] = useContext(BulkActionsContext);

    const readUsers = () => {
        const deletedQuery = Object.fromEntries(params)?.user?.split(',') || [];

        const queryParameters = {
            page: 1,
            limit: 25,
            ...Object.fromEntries(params),
            ...(deletedQuery.length === 1 && { deleted: deletedQuery.includes('removed') })
        }
        delete queryParameters.user;

        dispatch(campaignUsersActions.readCampaignUsers(id, queryParameters));
        setSelectedUsers([]);
        setAreUsersInAllPagesSelected(false);
    }

    useEffect(() => {
        readUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    useEffect(() => {
        dispatch(campaignActions.readCampaignUserIds(id));
    }, [dispatch, id])

    const [filters, setFilters] = useState({
        user: params.get('user')?.split(',')
    })

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value
        });
    }

    useEffect(() => {
        const query = new URLSearchParams(params);
        query.set('page', 1);
        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }
        const { user, module } = filters;
        if (user?.length > 0) {
            query.set('user', user.join(','));
        } else {
            query.delete('user');
        }
        if (module?.length > 0) {
            query.set('module', module.join(','));
        } else {
            query.delete('module');
        }
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    }

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    }

    return (
        <div className='CampaignUsersList'>
            <TableWithHeader
                header={(
                    <UsersToolbar
                        cancelFunction={cancelFunction}
                        onSearchSubmit={onSearchSubmit}
                        onChangeSearch={onChangeSearch}
                        readUsers={readUsers}
                        search={search}
                        isCampaignInactive={isExpired || isDeleted}
                    />
                )}
                table={(
                    <UsersTable
                        onPageChange={onPageChange}
                        onFilter={onFilter}
                    />
                )}
            />
        </div>
    );
}

export default function CampaignUserReportsContainer() {
    return (
        <BulkActionsProvider>
            <CampaignUserReports />
        </BulkActionsProvider>
    )
};
