import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Form, Icon, List, Popup, Portal, Tab } from 'semantic-ui-react';
import SequenceForm from './SequenceForm';
import useWidthListener from 'shared/SettingsFooter/useWidthListener';
import useWindowWidth from 'shared/SettingsFooter/useWindowWidth';
import { Button, SettingsFooter, yup } from 'dyl-components';
import { MathUtils } from 'utils';
import SettingsForm from './SettingsForm';
import TemplatesContextProvider from './TemplatesContextProvider';
import { useConfirm } from 'shared/confirmation/useConfirm';
import CustomPrompt from 'shared/confirmation/CustomPrompt';
import ConfirmModal from 'shared/confirmation/ConfirmModal';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NAME_VALIDATOR = yup.string().no_whitespace_only().no_excessive_whitespaces().minlength(2).maxlength(64).simple_alphanumeric();

const Sequence = ({ onSave, loading, id }) => {
    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();

    const [activeTab, setActiveTab] = useState('Sequence');

    const { clearErrors, control, formState: { isValid, isDirty, errors }, setError, handleSubmit, watch } = useFormContext();

    const panes = [
        {
            menuItem: 'Sequence',
            render: () => <Tab.Pane style={{ border: 'none', marginLeft: '-1.75em' }}><SequenceForm id={id} clearErrors={clearErrors} setError={setError} nameValidator={NAME_VALIDATOR} control={control} /></Tab.Pane>
        },
        {
            menuItem: 'Settings',
            render: () => <Tab.Pane><SettingsForm control={control} /></Tab.Pane>
        }
    ];

    const getActiveTab = () => {
        const index = panes.findIndex(pane => pane.menuItem?.toLowerCase() === activeTab?.toLowerCase());
        return index === -1 ? 0 : index;
    }

    const onChangeTab = (index) => {
        const { menuItem } = panes[index];
        setActiveTab(menuItem);
    }

    const { isConfirmed } = useConfirm();

    CustomPrompt(null, isDirty, isConfirmed, 'Changes not saved', 'Are you sure you want to exit?');

    const isCheckingUniqueness = useSelector(state => state.sequences.isCheckingUniqueness);

    const actions = watch("actions");

    const actionCounts = actions.reduce((acc, curr) => ({ ...acc, [curr.type]: (acc[curr.type] || 0) + 1 }), {});
    const ACTION_LIMIT = 5;
    const actionsExceedingLimit = Object.keys(actionCounts).map(key => ({ type: key, count: actionCounts[key] })).filter(stat => stat.count > ACTION_LIMIT);

    const otherErrors = [
        ...actionsExceedingLimit.map(stat => ({
            message: `There can only be at most 5 ${stat.type}s.`
        }))
    ];

    return (
        <TemplatesContextProvider>
            <ConfirmModal />
            <Form loading={loading} noValidate onSubmit={e => {
                e.preventDefault();
            }}>
                <Tab
                    menu={{ color: 'blue', secondary: true, pointing: true }}
                    panes={panes}
                    activeIndex={getActiveTab()}
                    onTabChange={(_, { activeIndex }) => { onChangeTab(activeIndex) }}
                    renderActiveOnly
                />
            </Form>
            <Portal open>
                <SettingsFooter
                    style={{ width: MathUtils.calculatePercentage(windowWidth, windowWidth - width) }}
                    className={`Webform__menu`}
                    {...(otherErrors.length ? {
                        leftOptions: [
                            <Popup
                                content={
                                    <div>
                                        <div className="MinimumErrors"><u>Errors</u></div>
                                        <List as="ol" className="MinimumErrors">
                                            {otherErrors.map(error => (
                                                <List.Item as='li'>{error.message}</List.Item>
                                            ))}
                                        </List>
                                    </div>
                                }
                                wide='very'
                                position='top center'
                                trigger={
                                    <Icon size='large' className='fa-solid fa-triangle-exclamation WarningRed' />
                                }
                                open
                            />
                        ]
                    } : {})}
                    rightOptions={[
                        <Button disabled={loading} as={Link} to='/settings/sequences' status={'cancel'} basic>Cancel</Button>,
                        <Button
                            disabled={(!isValid || !isDirty || loading || isCheckingUniqueness || Object.keys(errors)?.length || otherErrors.length)}
                            onClick={handleSubmit(onSave)}
                            loading={loading}
                        >
                            Save
                        </Button>
                    ]}
                />
            </Portal>
        </TemplatesContextProvider>
    )
}

export default Sequence;
