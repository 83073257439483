const initialState = {
    phones: [],
    phones_count: 0,
    isCreatingPhone: false,
    isReadingPhones: false,
    isUpdatingPhone: false,
    isDeletingPhone: false,

    limits: [],
    limits_count: 0,
    isCreatingLimit: false,
    isReadingLimits: false,
    isUpdatingLimit: false,
    isDeletingLimit: false,
    
    conversations: [],
    conversations_count: 0,
    isReadingConversations: false,
    isUpdatingConversation: false,
    
    messages: [],
    messages_count: 0,
    message: {},
    isReadingMessages: false,

    status: '',
    isReadingStatus: false,
    
    isSendingMMSText: false,
    isSendingSMSText: false,

    from: '',
    to: '',
    filteredMessages: [],
    filtered_messages_count: 0
}

export default initialState;
