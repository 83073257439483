import { RecordsTable } from 'dyl-components';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Header from './Header';
import Row from './Row';
import { useSelector } from 'react-redux';

const Table = ({ onFilter, onPageChange, onOpenEditSoundsModal, onDeleteSound }) => {
    const { sounds, isReadingSounds, soundsCount } = useSelector((state) => state.pbx_config);

    const [params] = useSearchParams();

    return <RecordsTable
        tableBodyClassName='PhoneAudio'
        isTableLoading={isReadingSounds}
        isSearchResultEmpty={!soundsCount && (params.get('search') || params.get('category'))}
        isTableEmpty={!soundsCount && !(params.get('search') || params.get('category'))}
        tableEmptyMessage='No Audio Added'
        TableHeader={<Header onFilter={onFilter} count={soundsCount} sounds={sounds} />}
        TableRows={sounds?.map((sound) => <Row sound={sound} onOpenEditSoundsModal={onOpenEditSoundsModal} onDeleteSound={onDeleteSound} />)}
        recordsCount={soundsCount}
        activePage={params.get('page')}
        onPageChange={onPageChange}
        recordsName='Number'
    />
}

export default Table;