import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function leadProviderReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDERS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingProviders: true };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDERS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingProviders: false, providers: action.data.data || [], providers_count: action.data.count || 0 };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDERS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingProviders: false, providers: [], providers_count: 0 };

        case getNamedAction(ACTION_NAMES.LEAD_PROVIDERS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingProvider: true };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDERS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingProvider: false };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDERS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingProvider: false };

        case getNamedAction(ACTION_NAMES.LEAD_PROVIDERS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, providerBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDERS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, providerBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDERS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, providerBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.LEAD_PROVIDERS, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, providerBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDERS, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, providerBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDERS, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, providerBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.LEAD_PROVIDER_NAMES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingProviderNames: true };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDER_NAMES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingProviderNames: false, provider_names: action.data.data || [], provider_names_count: action.data.count || 0 };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDER_NAMES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingProviderNames: false, provider_names: [], provider_names_count: 0 };

        case getNamedAction(ACTION_NAMES.UNMAPPED_LEAD_PROVIDERS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingUnmappedProviders: true };
        case getNamedAction(ACTION_NAMES.UNMAPPED_LEAD_PROVIDERS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingUnmappedProviders: false, unmapped_providers: action.data.data || [], unmapped_providers_count: action.data.count };
        case getNamedAction(ACTION_NAMES.UNMAPPED_LEAD_PROVIDERS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingUnmappedProviders: false, unmapped_providers: [], unmapped_providers_count: 0 };

        case getNamedAction(ACTION_NAMES.LEAD_PROVIDER_MAPPINGS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingMappings: true };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDER_MAPPINGS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingMappings: false, mappings: action.data.data || [], mappings_count: action.data.count || 0 };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDER_MAPPINGS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingMappings: false, mappings: [], mappings_count: 0 };

        case getNamedAction(ACTION_NAMES.LEAD_PROVIDER_MAPPINGS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingMappings: true };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDER_MAPPINGS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingMappings: false };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDER_MAPPINGS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingMappings: false };

        case getNamedAction(ACTION_NAMES.IS_EXISTING_LEAD_PROVIDER_MAPPING, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isCheckingIfMappingExists: true };
        case getNamedAction(ACTION_NAMES.IS_EXISTING_LEAD_PROVIDER_MAPPING, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isCheckingIfMappingExists: false, isExistingMapping: action.data };
        case getNamedAction(ACTION_NAMES.IS_EXISTING_LEAD_PROVIDER_MAPPING, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isCheckingIfMappingExists: false, isExistingMapping: false };

        case getNamedAction(ACTION_NAMES.LEAD_PROVIDER_SECONDARY_SOURCES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingSecondarySources: true };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDER_SECONDARY_SOURCES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingSecondarySources: false, secondary_sources: action.data.data || [] };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDER_SECONDARY_SOURCES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingSecondarySources: false, secondary_sources: [] };

        case getNamedAction(ACTION_NAMES.LEAD_PROVIDER_SECONDARY_SOURCES, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingSecondarySources: true };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDER_SECONDARY_SOURCES, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingSecondarySources: false };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDER_SECONDARY_SOURCES, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingSecondarySources: false };

        case getNamedAction(ACTION_NAMES.LEAD_PROVIDER_SECONDARY_SOURCE_OPTIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingSecondarySourceOptions: true };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDER_SECONDARY_SOURCE_OPTIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingSecondarySourceOptions: false, secondary_source_options: action.data.data || [] };
        case getNamedAction(ACTION_NAMES.LEAD_PROVIDER_SECONDARY_SOURCE_OPTIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingSecondarySourceOptions: false, secondary_source_options: [] };

        default:
            return state;
    }
}

export default leadProviderReducer;
