export const KEY_CREATED_ON = 'created_on';
export const KEY_CREATED_BY = 'created_by';
export const KEY_LAST_MODIFIED_ON = 'last_modified_on';
export const KEY_LAST_MODIFIED_BY = 'last_modified_by';
export const KEY_START_END_DATE = 'start/end_date';
export const KEY_ID = 'id';
export const KEY_NAME = 'name';
export const KEY_TYPE = 'type';
export const KEY_CATEGORY_SUBCATEGORY = 'category';
export const KEY_STATUS = 'status';
export const KEY_SHARED = 'shared';
export const KEY_TOTAL_CONTACTS = 'total_contacts';
export const KEY_REACHED = 'reached';
export const KEY_UNREACHED = 'unreached';
export const KEY_OWNER = 'owner';
export const KEY_ACTIONS = 'actions';
export const KEY_CAMPAIGN_CONVERSION = 'campaign_conversion';





