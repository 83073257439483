const initialState = {
    smsList: [],
    isDeletingLeadFromSmsList: false,
    isGettingSmsList: false,
    isUpdatingLeadFromSmsList: false,
    allMarked: false,
    requiresConsent: false
}

export default initialState;
