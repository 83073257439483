import React from 'react';
import { Controller } from 'react-hook-form';
import { Form, Grid, Icon, Popup } from 'semantic-ui-react';

export const RightHeader = ({
    control,
    onCheckUniqueAssignmentName
}) => (
    <Grid>
        <Grid.Column textAlign='left' width={16}>
            <Form.Group>
                <Controller
                    name='rule_name'
                    defaultValue=''
                    control={control}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <Form.Input
                            width={6}
                            required
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                            label='Rule Name'
                            error={error && error?.message && ({
                                pointing: 'below',
                                content: error.message
                            })} 
                            onBlur={onCheckUniqueAssignmentName}
                        />
                    )} />
                <Controller
                    name='rule_description'
                    defaultValue=''
                    control={control}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <Form.Input
                            width={6}
                            required
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                            name={name}
                            label='Rule Description'
                            error={error && error?.message && ({
                                pointing: 'below',
                                content: error.message
                            })} 
                        />
                    )} />
                    <Popup
                        trigger={<Icon style={{ marginLeft: '8em' }} name='fas fa-info-circle' color='primary' />}
                        content='Triggers, conditions, and assignments are required. Additional conditions and assignment sets can be added subsequently as a pair.'
                        inverted
                        position='left center' 
                    />
            </Form.Group>
        </Grid.Column>
    </Grid>
)
