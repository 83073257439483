import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Grid, Portal } from 'semantic-ui-react';
import { SettingsFooter, Button } from 'dyl-components';
import { useFormContext } from 'react-hook-form';

import FeeInfo from './FeeInfo';
import FeeApplication from './FeeApplication';
import PricingDetails from './PricingDetails';

import useWidthListener from 'shared/SettingsFooter/useWidthListener';
import useWindowWidth from 'shared/SettingsFooter/useWindowWidth';
import { useConfirm } from 'shared/confirmation/useConfirm';
import CustomPrompt from 'shared/confirmation/CustomPrompt';
import ConfirmModal from 'shared/confirmation/ConfirmModal';
import { MathUtils } from 'utils';
import { useSelector } from 'react-redux';

const FeeForm = ({ onSave, loading }) => {
    const [isNameDuplicate, setIsNameDuplicate] = useState(false);

    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();

    const { handleSubmit, formState: { isValid, isDirty }, type } = useFormContext();

    const { isConfirmed } = useConfirm();
    CustomPrompt(null, isDirty, isConfirmed, 'Changes not saved', 'Are you sure you want to exit?');

    const isCheckingForDuplicates = useSelector(state => state.products.isCheckingForDuplicates);

    return (
        <Form className='AddFeeForm' loading={loading} noValidate>
            <ConfirmModal />
            <Grid columns={2}>
                <Grid.Column>
                    <FeeInfo setIsNameDuplicate={setIsNameDuplicate} />
                    <FeeApplication />
                </Grid.Column>
                <Grid.Column>
                    <PricingDetails />
                </Grid.Column>
            </Grid>
            <Portal open>
                <SettingsFooter
                    className={`Webform__menu`}
                    style={{ width: MathUtils.calculatePercentage(windowWidth, windowWidth - width) }}
                    rightOptions={[
                        <Button disabled={loading} as={Link} to={`/settings/product-catalog-quotes-invoices/${type === "tax" ? "taxes" : "fees"}`} status={'cancel'} basic>Cancel</Button>,
                        <Button
                            disabled={(!isValid || !isDirty || loading || isCheckingForDuplicates || isNameDuplicate)}
                            onClick={handleSubmit(onSave)}
                            loading={loading}
                        > Save </Button>
                    ]}
                />
            </Portal>
        </Form>
    );
}

export default FeeForm;
