
import React, {useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Form, Icon, Portal } from "semantic-ui-react";
import UserSearchField from 'shared/forms/UserSearchField';

import { DividingHeader, ButtonLink, VALIDATORS,  generateResolver, yup, DateTimeUtils, Button} from "dyl-components";
import { STATES } from "shared/constants/STATES";
import useWidthListener from "shared/SettingsFooter/useWidthListener";
import useWindowWidth from "shared/SettingsFooter/useWindowWidth";
import SettingsFooter from "dyl-components/molecules/SettingsFooter";
import { MathUtils } from 'utils';
import "./index.scss";

export const ProfileForm = (props) => {
    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();

    const [isReseted, setReset] = useState(false);
    const [defaultValues, setDefaultValues] = useState(props.defaultValues)

    useEffect(() => {
        setDefaultValues(props.defaultValues);
      }, [props.defaultValues]);
    
    /* yup validation - combo of default settings and custom `yup.string()....` */
    const userProfileValidations = {
        first_name: VALIDATORS.FIRST_NAME().required('This field is required'),
        last_name: VALIDATORS.LAST_NAME().required('This field is required').maxlength(50),
        email: VALIDATORS.EMAIL_ADDRESS().maxlength(256).required('This field is required'),
        profile_type: yup.number().typeError('A role is required'),
        timezone: yup.string(),

        street: VALIDATORS.STREET_ADDRESS(),
        additional_street: yup.string().minlength(2).maxlength(12),
        city: VALIDATORS.TOWN(),
        state: VALIDATORS.STATE(),
        zip: VALIDATORS.US_POSTAL_CODE(),

        work_number: VALIDATORS.PHONE_NUMBER(),
        ext: VALIDATORS.PHONE_NUMBER_EXTENSION(),
        mobile_number: VALIDATORS.PHONE_NUMBER(),
 
        facebook: VALIDATORS.WEBSITE(),
        twitter: VALIDATORS.WEBSITE(),
        linkedin: VALIDATORS.WEBSITE(),
        job_title: yup.string().minlength(2).maxlength(60),
        reports_to: yup.number().nullable(true).transform(value => (typeof value === 'number' && isNaN(value) ) ? 0 : value) 
    };


    /* React-hook-form setup */
    const {
        handleSubmit,
        formState: { errors, isDirty, isValid },
        control,
        setValue,
        resetField,
        reset, trigger,
        setError,
        clearErrors,
        getValues
    } = useForm({
        defaultValues,
        mode: "onChange",
        resolver: generateResolver(userProfileValidations),
    });

    const LabelHeader = ({ icon = null, required = false, label }) => {
        return (
            <React.Fragment>
                {icon && <Icon name={icon} />} <b>{label}</b>{" "}
                {required && <Icon size="tiny" name="asterisk" color="red" />}
            </React.Fragment>
        );
    };

    const checkEmailAvailability = async (e) => {
        const { value } = e.target;
        const result = await props.onCheckIfDuplicateExistsV2(value);
        const message = (!!result && value !== defaultValues.email) ? "This email is already in use" : null;

        if (message) {
            clearErrors("email");
            setError("ss_email", { type: "custom", message });
        } else {
            clearErrors("ss_email");
        }
    };

    const onChangeSelect = (_, { name, value }) => {
        if (value !== defaultValues[name]) {
            setValue(name, value, { shouldDirty: true });
        } else {
            resetField(name)
        }
    };

    const handleSubmitData = (data) => {   
        let formData=getValues('reports_to');
        data.reports_to = formData?.value ? formData?.value : formData;
        props.onUpdate(data);
        setDefaultValues(data);
    };

    const onError = (e) => {
        console.error(`|+|+|+ |There was an error validating the form`, e);
    };

    const isReady = isDirty && isValid;
    const timezones = DateTimeUtils.generateTimezoneOptions();
    const states = STATES.map(({ key }) => ({ key, value: key, text: key }));
    /* The useEffect sections below just kickstarts th evalidation to show any bad data for an existing user  */
    useEffect(() => {
      reset(defaultValues);
      setReset(true);
    }, [reset, defaultValues]);

    useEffect(() => {
      isReseted && trigger();
    }, [trigger, isReseted]);
    
    return (
        <>
            <Form size="small" className="ProfileForm__container">
                <DividingHeader noline content="Profile" compact />

                <Form.Group>
                    <Controller
                        name="first_name"
                        control={control}
                        render={({ field }) => (
                            <Form.Input
                                {...field}
                                ref={null}
                                error={errors.first_name?.message}
                                width={5}
                                required
                                label={<LabelHeader icon="user" required label="First Name" />}
                            />
                        )}
                    />
                    <Controller
                        name="last_name"
                        control={control}
                        render={({ field }) => (
                            <Form.Input
                                {...field}
                                ref={null}
                                label={<LabelHeader label="Last Name" required />}
                                error={errors.last_name?.message}
                                width={5}
                            />
                        )}
                    />
                </Form.Group>

                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <Form.Input
                            {...field}
                            ref={null}
                            error={errors.email?.message || errors.ss_email?.message}
                            width={10}
                            label={ <LabelHeader icon="sign in" label="Email (username)" required />  }
                            onBlur={(e) => {  checkEmailAvailability(e) }}
                        />
                    )}
                />
            
                <Form.Group>
                    <Controller
                        name="profile_type"
                        control={control}
                        render={({ field: { name, value } }) => (
                            !props.isOwner ?
                                <Form.Select
                                    name={name}
                                    value={value}
                                    error={errors.profile_type?.message}
                                    width={5}
                                    options={props.profile_types}
                                    onChange={onChangeSelect}
                                    label={
                                        <LabelHeader icon="id card" required label="Profile Type" />
                                    }
                                />
                            :
                                <Form.Input
                                    label={<LabelHeader icon="id card" required label="Profile Type" />}
                                    readOnly
                                    transparent
                                    tabIndex={-1}
                                    width={5}
                                    value={'Primary Owner'}
                                />
                        )}
                    />
                    <Controller
                        name="timezone"
                        control={control}
                        render={({ field: { name, value } }) => (
                            <Form.Select
                                name={name}
                                value={value}
                                error={errors.timezones?.message}
                                onChange={onChangeSelect}
                                width={5}
                                options={timezones}
                                selection
                                search
                                label={<LabelHeader icon="clock outline" required label="Timezone" />}
                            />
                        )}
                    />

                    <Form.Field width={6} style={{ marginTop: "2em" }}>
                        <Icon name="lock" />{" "}
                        <ButtonLink onClick={props.onOpenChangePasswordModal}>
                            Change Password
                        </ButtonLink>
                    </Form.Field>
                </Form.Group>

            
                <DividingHeader noline compact content="Contact Info" />
                <Form.Group>
                    <Controller
                        name="street"
                        control={control}
                        render={({ field }) => (
                            <Form.Input
                                {...field}
                                ref={null}
                                error={errors.street?.message}
                                width={10}
                                label={<LabelHeader icon="map marker" label="Street Address" />}
                            />
                        )}
                    />
                    <Controller
                        name="additional_street"
                        control={control}
                        render={({ field }) => (
                            <Form.Input
                                {...field}
                                ref={null}
                                error={errors.additional_street?.message}
                                label={<LabelHeader label="Apt, Suite, Unit, Blg, Floor #" />}
                                width={5}
                            />
                        )}
                    />
                </Form.Group>
    
                <Form.Group>
                    <Controller
                        name="city"
                        control={control}
                        render={({ field }) => (
                            <Form.Input
                                {...field}
                                ref={null}
                                error={errors.city?.message}
                                label={<LabelHeader label="City" />}
                                width={8}
                            />
                        )}
                    />
                    <Controller
                        name="state"
                        control={control}
                        render={({ field: { name, value } }) => (
                            <Form.Select
                                name={name}
                                value={value}
                                error={errors.state?.message}
                                onChange={onChangeSelect}
                                width={2}
                                options={states}
                                selection
                                search
                                label={<LabelHeader label="State" />}
                            />
                        )}
                    />
                    <Controller
                        name="zip"
                        control={control}
                        render={({ field }) => (
                            <Form.Input
                                {...field}
                                ref={null}
                                error={errors.zip?.message}
                                label={<LabelHeader label="Postal Code" />}
                                width={5}
                            />
                        )}
                    />
                </Form.Group>

                <Form.Group>
                    <Controller
                        name="work_number"
                        control={control}
                        render={({ field }) => (
                            <Form.Input
                                {...field}
                                ref={null}
                                error={errors.work_number?.message}
                                label={<LabelHeader icon="text telephone" label="Work Number" />}
                                width={4}
                            />
                        )}
                    />
                    <Controller
                        name="ext"
                        control={control}
                        render={({ field }) => (
                            <Form.Input
                                {...field}
                                ref={null}
                                error={errors.ext?.message}
                                label={<LabelHeader label="Ext." />}
                                width={2}
                            />
                        )}
                    />

                    <Controller
                        name="mobile_number"
                        control={control}
                        render={({ field }) => (
                            <Form.Input
                                {...field}
                                error={errors.mobile_number?.message}
                                ref={null}
                                label={
                                    <LabelHeader icon="mobile alternative" label="Mobile Number" />
                                }
                                width={4}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group>
                    <Controller
                        name="facebook"
                        control={control}
                        render={({ field }) => (
                            <Form.Input
                                {...field}
                                ref={null}
                                error={errors.facebook?.message}
                                label={<LabelHeader icon="facebook" label="facebook" />}
                                width={5}
                            />
                        )}
                    />
                    <Controller
                        name="linkedin"
                        control={control}
                        render={({ field }) => (
                            <Form.Input
                                {...field}
                                ref={null}
                                error={errors.linkedin?.message}
                                label={<LabelHeader icon="linkedin" label="LinkedIn" />}
                                width={5}
                            />
                        )}
                    />
                    <Controller
                        name="twitter"
                        control={control}
                        render={({ field }) => (
                            <Form.Input
                                {...field}
                                ref={null}
                                error={errors.twitter?.message}
                                label={<LabelHeader icon="twitter" label="Twitter" />}
                                width={5}
                            />
                        )}
                    />
                </Form.Group>

                <DividingHeader noline compact content="Position" />
                <Form.Group>
            
                            <Form.Input
                                label={<LabelHeader icon="university" label="Company" />}
                                readOnly
                                name='company'
                                transparent
                                tabIndex={-1}
                                width={3}
                                value={props.company}
                            />
                    
                            <Form.Input
                                name="industry"
                                label={<LabelHeader icon="university" label="Industry" />}
                                readOnly
                                transparent
                                tabIndex={-1}
                                width={2}
                                value={props.industry}
                            />
                    <Controller
                        name="job_title"
                        control={control}
                        render={({ field }) => (
                            <Form.Input
                                {...field}
                                ref={null}
                                error={errors.job_title?.message}
                                width={5}
                                label={<LabelHeader icon="user" label="Job Title" />}
                            />
                        )}
                    />
                    <Controller
                        name="reports_to"
                        control={control}
                        render={({ field: { name, value } }) => (
                            <Form.Field
                                name={name}
                                value={value}
                                error={errors.reports_to?.message}
                                width={5}
                                control={UserSearchField}
                                excluded_users={[value]}
                                upward
                                onChange={onChangeSelect}
                                placeholder="Select User"
                                display_selected_user
                                displayed_selected_user={defaultValues.reports_to}
                                one_user_display
                                label={<LabelHeader icon='clipboard' label="Reports To" />}
                            />
                        )}
                    />
                </Form.Group>
            </Form>
                <Portal open>
                    <SettingsFooter
                        style={{ width: MathUtils.calculatePercentage(windowWidth, windowWidth - width) }}
                        className={`Webform__menu`}
                        rightOptions={[
                            <Button disabled={!isReady} onClick={handleSubmit(handleSubmitData, onError)} primary>Save</Button>
                        ]}
                    />
                </Portal>
        </>
    );
};
