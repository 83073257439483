import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Table, ButtonLink, Notification, STATUS_TYPES, } from 'dyl-components';
import Integration from 'shared/Integration';

import oauthActions from 'actions/oauth';
import { useDispatch } from 'react-redux';

const DeletedIntegrationRow = ({ integration }) => {

    const dispatch = useDispatch();

    const onReconnect = async () => {
        try {
            await dispatch(oauthActions.reactivate(null, null, { integration_id: integration.id }));
            dispatch(oauthActions.getIntegrations({ scopes: 'email,calendar', include_disabled: true }));
            Notification.alert('Successfully reactivated an integration', STATUS_TYPES.SUCCESS);
        } catch (e) {
            Notification.alert('Failed to reactivate an integration', STATUS_TYPES.ERROR);
        }

    }

    return (
        <Table.Row className='DeletedIntegrationRow'>
            <Table.Cell width={4}>
                <Integration name={integration.name} customIcon={<Icon className='fa-solid fa-cloud' flipped='horizontally' />} email={integration.email} deleted />
            </Table.Cell>
            <Table.Cell width={3}>
                <span className='DeletedIntegrationRow__disconnected'>
                    Disconnected
                </span>
            </Table.Cell>
            <Table.Cell width={1}>
                <ButtonLink onClick={onReconnect}>
                    Reconnect
                </ButtonLink>
            </Table.Cell>
        </Table.Row>
    );
}

export default DeletedIntegrationRow;
