import React from 'react';
import { Table } from 'dyl-components';
import { Checkbox, Dimmer, Loader } from 'semantic-ui-react';
import CallHistoryRow from './CallHistoryRow';
import {  RecordUtils } from 'utils';
import { DateTimeUtils } from 'dyl-components';

import callHistoryActions from 'actions/call_history';
import { connect } from 'react-redux';
import { EmailModal, NotesModal, TextMessageModal } from 'shared/modals';

const CallHistoryTable = ({ calls, onMarkAll, otherProps, onToggleModal }) => (
    <Table>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell width={1}>
                    <Checkbox onChange={onMarkAll} />
                </Table.HeaderCell>
                <Table.HeaderCell resizable>
                    Timestamp
                </Table.HeaderCell>
                <Table.HeaderCell resizable>
                    Contact
                </Table.HeaderCell>
                <Table.HeaderCell resizable>
                    Length
                </Table.HeaderCell>
                <Table.HeaderCell resizable>
                    Call Result
                </Table.HeaderCell>
                <Table.HeaderCell resizable>
                    User
                </Table.HeaderCell>
                <Table.HeaderCell resizable>
                    Call Note
                </Table.HeaderCell>
                <Table.HeaderCell resizable>
                    Actions
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {calls.map(call => (
                <CallHistoryRow call={call} onToggleModal={onToggleModal} {...otherProps} />
            ))}
        </Table.Body>
    </Table>
);

class CallHistoryTableContainer extends React.Component {

    state = {
        activeModal: null,
        contact: null,
        emails: [],
        phone: '',
        name: ''
    }

    componentDidMount() {
        this.props.onReadCalls({ end_at: DateTimeUtils.getLast(1, 'day') });
    }

    onToggleModal = (name, call) => {
        const { phone = [], email = { data: [] } } = call.contact;

        let mainPhone = phone.data.find(item => item.main);

        if (!mainPhone) {
            mainPhone = phone.data[0] ? phone.data[0] : { phone: '' }
        }

        this.setState({
            contact: call.contact,
            name: `${RecordUtils.getName(call.contact).firstLine} ${RecordUtils.getName(call.contact).secondLine}`,
            emails: email.data,
            phone: mainPhone.phone
        }, () => {
            this.setState({
                activeModal: name
            })
        })
    }

    isOpenModal = (name) => {
        return this.state.activeModal === name;
    }

    onClose = () => {
        this.setState({
            activeModal: ''
        })
    }

    render() {
        if (this.props.isReadingCallHistory) {
            return (
                <Dimmer active>
                    <Loader active />
                </Dimmer>
            )
        }
        return (
            <React.Fragment>
                <CallHistoryTable
                    calls={this.props.calls}
                    onMarkAll={this.props.onMarkAll}
                    onToggleModal={this.onToggleModal}
                />

                {this.isOpenModal('text') &&
                    <TextMessageModal
                        open={this.isOpenModal('text')}
                        onClose={this.onClose}
                        to_phone={this.state.phone}
                        contact_id={this.state.contact.id}
                    />
                }

                {this.isOpenModal('email') &&
                    <EmailModal
                        open={this.isOpenModal('email')}
                        onClose={this.onClose}
                        name={this.state.name}
                        emails={this.state.emails}
                    />
                }

                {
                    this.isOpenModal('add_note') && (
                        <NotesModal
                            open={this.isOpenModal('add_note')}
                            onClose={this.onClose}
                            contact_id={this.state.contact.id}
                            account_id={this.state.contact.account_id}
                        />
                    )
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        calls: state.call_history.call_history,
        isReadingCallHistory: state.call_history.isReadingCallHistory
    }
}

const mapDispatchToProps = dispatch => ({
    onReadCalls: (queryParameters) => {
        dispatch(callHistoryActions.getCalls(queryParameters));
    },
    onMarkAll: () => {
        dispatch(callHistoryActions.markAllCalls());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CallHistoryTableContainer);
