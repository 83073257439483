import React from 'react';
import { Modal } from 'dyl-components';
import { Grid, Button, Select, Input, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';

import CallTagsInputContainer from 'shared/forms/CallTagsInput';
import callActions from 'actions/call';

const CallModal = ({ 
    open, 
    onClose,
    callDetails,
    onAddNote
}) => (
    <Modal open={open} onClose={onClose}>
        <Modal.Header>
            Select Call Result
        </Modal.Header>
        <Modal.Content>
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <strong>Name</strong>
                        <div>{callDetails.name || '<name>'}</div>
                    </Grid.Column>
                    <Grid.Column>
                        <strong>Number</strong>
                        <div>{callDetails.destination || '<number>'}</div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Select placeholder='Additional' />                    
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Input 
                            placeholder='Write your note here...'
                            fluid
                            onChange={onAddNote}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <strong>Call Tags</strong>
                        <CallTagsInputContainer />
                    </Grid.Column>
                </Grid.Row>
                <strong>Select Call Result</strong>
                <Grid.Row>
                    <Grid.Column width={6}>
                        <Button color='red'>
                            <Icon name='ban' />
                            Do Not Call
                        </Button>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <Button color='violet'> 
                            <Icon name='play circle outline' />
                            Voicemail
                        </Button>
                        <Button color='blue'>
                            <Icon name='stopwatch' />
                            Reached
                        </Button>
                        <Button color='teal'>
                            <Icon name='phone' />
                            Not Available
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Modal.Content>
    </Modal>
);

const mapStateToProps = state => ({
    open: state.call.isResolvingCallResult,
    callDetails: state.call.callDetails
})

const mapDispatchToProps = dispatch => ({
    onClose: () => {
        dispatch(callActions.resolveCallResult());
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(CallModal);
