import React from 'react';
import { Icon, Button, Popup, Input, Select, Grid } from 'semantic-ui-react';
import { TableWithHeader, ControlledPopup, Table } from 'dyl-components';
import { MOCK_LOCATION_DESTINATION } from '__mocks__/mockData';
import { STATUS_TYPES, Notification } from 'dyl-components';

class LocationDestinationRowForm extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            name: 'ZIP',
            location: 'Default Zip',
            userDestination: 'All Users',
        }
    }

    onChange = (_, {name, value}) => {
        this.setState({
            [name]: value
        })
    }

    render(){
        const { isAddLocationOpen, onCancelClick, onConfirmClick } = this.props;
        const {
            name,
            location,
            userDestination,
        } = this.state;

        return(
            <Table.Row style={{display: isAddLocationOpen ? 'table-row' : 'none'}}>
                <Table.Cell>
                    <Input 
                        placeholder='Location Name'
                        onChange={this.onChange}
                        name='name'
                        value={name} />
                </Table.Cell>
                <Table.Cell>
                <Select options={[
                        { key: 'Zip', value: 'Zip', text: 'Zip' }
                    ]} 
                    value={location}
                    onChange={this.onChange}
                    name='location'/>
                </Table.Cell>
                <Table.Cell>
                <Select options={[
                        { key: 'All Users', value: 'All Users', text: 'All Users' }
                    ]} 
                    value={userDestination}
                    onChange={this.onChange}
                    name='userDestination'/>
                </Table.Cell>
                <Table.Cell>
                    <Popup content='Cancel' inverted trigger={<Icon name='close' color='red' link onClick={onCancelClick} />} />
                    <Popup content='Confirm' inverted trigger={<Icon name='checkmark' color='blue' link 
                        onClick={() => onConfirmClick(this.state)}/>} />
                </Table.Cell>
            </Table.Row>
        )
    }
}

const LocationDestinationRowViewInfo = ({
    id,
    name,
    location,
    userDestination,

    isEditMode,
    onDisableEditMode,
    onDeleteClick,
    onEditClick
}) => (
    <Table.Row>
        <Table.Cell>{name}</Table.Cell>
        <Table.Cell>{location}</Table.Cell>
        <Table.Cell>{userDestination}</Table.Cell>
        <Table.Cell>
            {/* <Popup content='Add New Route' inverted trigger={<Icon name='add circle' color='grey' link />} /> */}
            <ControlledPopup 
                trigger={<Icon name='trash alternate' color='grey'  link />}
                onConfirm={() => onDeleteClick(id)}
                onCancel={onDisableEditMode}
            />
            <Popup content='Edit' inverted trigger={<Icon name='pencil' color='grey'  link
                onClick={() => onEditClick(id)} />} />
        </Table.Cell>
    </Table.Row>
)

class LocationDestinationRowViewEdit extends React.Component{
    constructor(props){
        super(props);

        const {
            name,
            location,
            userDestination,
        } = props;

        this.state = {
            name,
            location,
            userDestination,
        }
    }

    onChange = (_, {name, value}) => {
        this.setState({
            [name]: value
        })
    }

    render(){
        const {
            name,
            location,
            userDestination,
        } = this.state;

        const { id, onEditCancelClick, onEditConfirmClick } = this.props;

        return(
            <Table.Row>
                <Table.Cell>
                    <Input 
                        placeholder='Location Name'
                        onChange={this.onChange}
                        name='name'
                        value={name} />
                </Table.Cell>
                <Table.Cell>
                <Select options={[
                        { key: 'Zip', value: 'Zip', text: 'Zip' }
                    ]} 
                    value={location}
                    onChange={this.onChange}
                    name='location'/>
                </Table.Cell>
                <Table.Cell>
                <Select options={[
                        { key: 'All Users', value: 'All Users', text: 'All Users' }
                    ]} 
                    value={userDestination}
                    onChange={this.onChange}
                    name='userDestination'/>
                </Table.Cell>
                <Table.Cell>
                    <Popup content='Cancel' inverted trigger={<Icon name='close' color='red' link 
                        onClick={() => onEditCancelClick(id)} />} />
                    <Popup content='Confirm' inverted trigger={<Icon name='checkmark' color='blue' link 
                        onClick={() => onEditConfirmClick(id, {...this.state})}/>} />
                </Table.Cell>
            </Table.Row>
        )
    }
}

class TableSingleLine extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            isEditMode: false
        }
    }

    onDisableEditMode = () => {
        this.setState({
            isEditMode: false,
        })
    }

    formLocationDestinationItems = () => {
        const { locationDestinationItems, onDeleteClick, onEditClick, onEditCancelClick, onEditConfirmClick } = this.props;
        const { isEditMode } = this.state;

        return locationDestinationItems.map(locationDestinationItem => {
            if(locationDestinationItem.edit){
                return(
                    <LocationDestinationRowViewEdit
                        id={locationDestinationItem.id}
                        key={locationDestinationItem.id} 
                        name={locationDestinationItem.name}
                        location={locationDestinationItem.location}
                        userDestination={locationDestinationItem.userDestination}
        
                        onEditCancelClick={onEditCancelClick}
                        onEditConfirmClick={onEditConfirmClick}
                    />
                )
            }else{
                return(
                    <LocationDestinationRowViewInfo
                        id={locationDestinationItem.id}
                        key={locationDestinationItem.id} 
                        name={locationDestinationItem.name}
                        location={locationDestinationItem.location}
                        userDestination={locationDestinationItem.userDestination}
        
                        isEditMode={isEditMode} 
                        onDisableEditMode={this.onDisableEditMode}
                        onDeleteClick={onDeleteClick}       
                        onEditClick={onEditClick}
                    />
                )
            }
        })
    }

    render(){
        const {isAddLocationOpen, onCancelClick, onConfirmClick} = this.props;
        const LocationDestinationItems = this.formLocationDestinationItems();

        return(
            <Table singleLine>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Location</Table.HeaderCell>
                        <Table.HeaderCell>User Destination</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            
                <Table.Body>
                    <LocationDestinationRowForm
                        isAddLocationOpen={isAddLocationOpen} 
                        onCancelClick={onCancelClick}
                        onConfirmClick={onConfirmClick} />
                    {LocationDestinationItems}
                </Table.Body>
            </Table>
        )
    }
}

class LocationDestination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAddLocationOpen: false,
            locationDestinationItems: MOCK_LOCATION_DESTINATION
        }
    }

    onAddLocationClick = () => {
        this.setState({
            isAddLocationOpen: true
        })
    }

    onCancelClick = () => {
        this.setState({
            isAddLocationOpen: false
        })
    }

    onConfirmClick = (data) => {
        const { locationDestinationItems } = this.state;

        data.id = locationDestinationItems.length + 1;

        this.setState({
            locationDestinationItems: [
                ...this.state.locationDestinationItems,
                data
            ],
            isAddLocationOpen: false
        });

        Notification.alert('Location Routing Added', STATUS_TYPES.SUCCESS, true);
    }

    onDeleteClick = (id) => {
        let locationDestinationItems = this.state.locationDestinationItems.slice(0);
        let indexOfLocationDestinationItemToDelete = locationDestinationItems.findIndex(locationDestinationItem => locationDestinationItem.id === id);
        
        if (indexOfLocationDestinationItemToDelete !== -1) {
            locationDestinationItems.splice(indexOfLocationDestinationItemToDelete, 1);
            Notification.alert('Location Routing Removed', STATUS_TYPES.SUCCESS, true);
            this.setState({ locationDestinationItems });
        }
    }

    onEditClick = (id) => {
        let locationDestinationItems = this.state.locationDestinationItems.slice(0);
        let indexOflocationDestinationItem = locationDestinationItems.findIndex(locationDestinationItem => locationDestinationItem.id === id);
        
        if (indexOflocationDestinationItem !== -1) {
            locationDestinationItems[indexOflocationDestinationItem].edit = true;
            this.setState({ locationDestinationItems });
        }
    }

    onEditCancelClick = (id) => {
        let locationDestinationItems = this.state.locationDestinationItems.slice(0);
        let indexOflocationDestinationItem = locationDestinationItems.findIndex(locationDestinationItem => locationDestinationItem.id === id);
        
        if (indexOflocationDestinationItem !== -1) {
            locationDestinationItems[indexOflocationDestinationItem].edit = false;
            this.setState({ locationDestinationItems });
        }
    }

    onEditConfirmClick = (id, data) => {
        let locationDestinationItems = this.state.locationDestinationItems.slice(0);
        let indexOflocationDestinationItem = locationDestinationItems.findIndex(locationDestinationItem => locationDestinationItem.id === id);
        
        if (indexOflocationDestinationItem !== -1) {
            locationDestinationItems[indexOflocationDestinationItem] = data;
            this.setState({ locationDestinationItems });
        }
    }

    render() {
        const { isAddLocationOpen, locationDestinationItems } = this.state;

        return (
            <React.Fragment>
                <TableWithHeader 
                    header={
                        <Grid verticalAlign='middle'>
                            <Grid.Column width={6}>
                                <strong>Location Destination</strong>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign='right' floated='right'>
                                <Button size='tiny' basic inverted color='white' onClick={this.onAddLocationClick}>Add Location</Button>
                            </Grid.Column>
                        </Grid>
                    }
                    table={
                        <TableSingleLine
                            onCancelClick={this.onCancelClick}
                            onConfirmClick={this.onConfirmClick}
                            isAddLocationOpen={isAddLocationOpen} 
                            locationDestinationItems={locationDestinationItems}
                            onDeleteClick={this.onDeleteClick}
                            onEditClick={this.onEditClick}
                            onEditCancelClick={this.onEditCancelClick}
                            onEditConfirmClick={this.onEditConfirmClick}/>
                    }
                    className='TableWithHeader'/>
            </React.Fragment>
        )
    }
}

export default LocationDestination;
