import React from 'react';
import { Header, Transition, Icon } from 'semantic-ui-react';
import ContactHouseholdData from 'shared/ContactHouseholdData';

const HouseholdInfo = ({
    toggleHouseholdDetails,
    householdDetailsExpanded
}) => {
    return (
        <div>
            <Header color='primary' style={{ whiteSpace: 'nowrap' }}>
                <span>
                    Household Details <Icon
                        onClick={toggleHouseholdDetails}
                        link
                        size='small'
                        className={`fas fa-circle-chevron-${householdDetailsExpanded ? 'up' : 'down'}`}
                    />
                </span>
            </Header>
            <Transition visible={householdDetailsExpanded} animation={'slide down'} unmountOnHide duration={'150'}>
                <div>
                    <ContactHouseholdData />
                </div>
            </Transition>
        </div>
    );
};

export default HouseholdInfo;

