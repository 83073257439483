import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { EllipsisDropdown, ControlledPopup } from 'dyl-components';

const TeamActionDropdown = ({ teamId, isTeamEmpty, onDeleteTeam, onEdit }) => {
    return (
        <EllipsisDropdown>
            <Dropdown.Item icon='eye slash' text='Hide on office view' disabled/>
            <Dropdown.Item icon='pencil' onClick={() => {onEdit(teamId)}} text='Edit' />
            {isTeamEmpty && <ControlledPopup
                trigger={<Dropdown.Item icon='trash' text='Delete' />}
                content={'Delete'}
                onConfirm={() => onDeleteTeam(teamId)}
                inverted
            />}
        </EllipsisDropdown>
    )
}

export default TeamActionDropdown;
