import React from 'react';
import { Icon, List, Transition, Input, Checkbox } from 'semantic-ui-react';
import { ButtonLink, STATUS_TYPES, Notification, ListTopHeaderBottomContentNoBorder, ListItem } from 'dyl-components';
import { connect } from 'react-redux';

const CallerName = ({ 
    caller, 
    isEdittingName, 
    onEditName, 
    onChange, 
    onCancelEditName, 
    onConfirmEditName,
    onConfirmNameThroughEnter,
}) => (
    <React.Fragment>
        {!isEdittingName && (
            <React.Fragment>
                <ButtonLink>
                    {caller.name}
                </ButtonLink>
                {' '}
                <Icon name='pencil' link onClick={onEditName} />
            </React.Fragment>
        )}
        <Transition visible={isEdittingName} animation='slide up'>
            <div>
                <Input type='text' defaultValue={caller.name} size='small' onChange={onChange} onKeyPress={onConfirmNameThroughEnter}  /> 
                <Icon size='small' name='times' link onClick={onCancelEditName} />
                <Icon size='small' name='check' link onClick={onConfirmEditName} />
            </div>
        </Transition>
    </React.Fragment>
)

class CallerNameContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isEdittingName: false,
            currentName: props.caller.name
        }
    }
    
    onChangeName(e) {
        this.setState({
            currentName: e.target.value
        })
    }

    onEditName() {
        this.setState({ isEdittingName: true });
    }

    onCancelEditName() {
        this.setState({ isEdittingName: false, currentName: this.props.name });
    }

    onConfirmEditName() {
        this.setState({ isEdittingName: false });
    }

    onConfirmNameThroughEnter(e) {
        if (e.key === 'Enter') {
            this.setState({ isEdittingName: false });
        }
    }

    render() {
        return (
            <CallerName 
                caller={this.props.caller}
                {...this.state}
                onEditName={this.onEditName.bind(this)}
                onChange={this.onChangeName.bind(this)}
                onCancelEditName={this.onCancelEditName.bind(this)}
                onConfirmEditName={this.onConfirmEditName.bind(this)}
                onConfirmNameThroughEnter={this.onConfirmNameThroughEnter.bind(this)}
            />
        )
    }
}

const InHotlistField = ({ caller, onToggleInHotlist, inHotlist }) => (
    <Checkbox toggle onChange={onToggleInHotlist} checked={inHotlist} />
)

export const CallerDetails = ({ caller, onToggleInHotlist, inHotlist }) => (
    <ListTopHeaderBottomContentNoBorder
        topLeft='Actions'
        topLine={false}
        bottom={(
            <List divided relaxed>
                <ListItem>
                    <List.Icon name='user circle outline' />
                    <List.Content>
                        <List.Header>
                            Name
                        </List.Header>
                        <List.Description>
                            <CallerNameContainer caller={caller} />
                        </List.Description>
                    </List.Content>
                </ListItem>
                <ListItem>
                    <List.Icon name='user outline' />
                    <List.Content>
                        <List.Header>Assigned to</List.Header>
                        <List.Description>{caller.assignee}</List.Description>
                    </List.Content>
                </ListItem>
                <ListItem>
                    <List.Icon name='star' />
                    <List.Content>
                        <List.Header>My Hotlist</List.Header>
                        <List.Description>
                            <InHotlistField 
                                caller={caller} 
                                onToggleInHotlist={onToggleInHotlist}
                                inHotlist={inHotlist} 
                            />
                        </List.Description>
                    </List.Content>
                </ListItem>
            </List>
        )}       
    />
)

class CallerDetailsContainer extends React.Component {
    state = {
        inHotlist: false
    }

    onToggleInHotlist() {
        let action = this.state.inHotlist ? 'Removed' : 'Added';
        let direction = this.state.inHotlist ? 'from' : 'to';
        Notification.alert(`${action} ${this.props.caller.name} ${direction} hotlist!`, STATUS_TYPES.SUCCESS, true);
        this.setState({ inHotlist: !this.state.inHotlist });
    }

    render() {
        if (this.props.caller === null) {
            return '';
        }
        return (
            <CallerDetails 
                {...this.props}
                onToggleInHotlist={this.onToggleInHotlist.bind(this)}
                {...this.state}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        caller: state.officeView.callerInActionsView
    }
}

export default connect(mapStateToProps)(CallerDetailsContainer);
