import routes from 'actions/routes';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import { generateReadActionCreator } from 'actions/crudActionCreators';

const readTeamsTerritories = generateReadActionCreator(routes.API_ASSIGNMENT_TERRITORIES_TEAMS, ACTION_NAMES.ASSIGNMENT_TERRITORIES_TEAMS);
const uniqueAssignmentName = generateReadActionCreator(routes.API_ASSIGNMENT_UNIQUE_NAME, ACTION_NAMES.ASSIGNMENT_UNIQUE_NAME);

export default {
    readTeamsTerritories,
    uniqueAssignmentName
}
