import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RecordsTable } from 'dyl-components';

import OpportunitiesHeader from './Header';
import OpportunitiesRow from './OpportunitiesRow';

const OpportunitiesTable = ({ onFilter, isColumnHidden, onPageChange }) => {
    const [params] = useSearchParams();

    const { count, opportunities = [], isReadingOpportunities } = useSelector(state => state.opportunities);

    return (
        <RecordsTable
            tableBodyClassName='ModulesListTable'
            isTableLoading={isReadingOpportunities}
            isSearchResultEmpty={count === 0 && (params.has('search') || params.has('start') || params.has('end') || params.has('account_type') || params.has('owned_by'))}
            isTableEmpty={count === 0 && !(params.has('search') || params.has('start') || params.has('end') || params.has('account_type') || params.has('owned_by'))}
            TableHeader={<OpportunitiesHeader isColumnHidden={isColumnHidden} onFilter={onFilter} />}
            TableRows={opportunities.map(opportunity => (
                <OpportunitiesRow
                    key={opportunity.pipeline_id}
                    opportunity={opportunity}
                    isColumnHidden={isColumnHidden}
                />
            ))}
            recordsCount={count}
            activePage={params.get('page')}
            onPageChange={onPageChange}
            recordsName='Opportunity'
        />
    );
}

export default OpportunitiesTable;
