import routes from "actions/routes";
import { generateReadOneActionCreator, generateUpdateActionCreator, generateCreateActionCreator, generateDeleteActionCreator } from "actions/crudActionCreators";
import ACTION_NAMES from "actions/ACTION_NAMES";

export default {
    getContactEmails: generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_EMAIL, 'email'),
    addContactEmail: generateCreateActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_EMAIL, () => { }, () => { }, 'email'),
    updateContactEmail: generateUpdateActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_EMAIL, () => { }, () => { }, 'email'),
    deleteContactEmail: generateDeleteActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_EMAIL, () => { }, () => { }, 'email'),
    readOptions: generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_EMAIL_OPTIONS, 'email')
}
