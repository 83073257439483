import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function productCategoriesReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PRODUCT_CATEGORY, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReading: true, categories: [], count: 0 };
        case getNamedAction(ACTION_NAMES.PRODUCT_CATEGORY, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReading: false, categories: action.data.data, count: action.data.count };
        case getNamedAction(ACTION_NAMES.PRODUCT_CATEGORY, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReading: false, categories: [], count: 0 };

        case getNamedAction(ACTION_NAMES.PRODUCT_CATEGORY, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreating: true };
        case getNamedAction(ACTION_NAMES.PRODUCT_CATEGORY, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreating: false };
        case getNamedAction(ACTION_NAMES.PRODUCT_CATEGORY, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreating: false };

        case getNamedAction(ACTION_NAMES.PRODUCT_CATEGORY, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, categoryBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.PRODUCT_CATEGORY, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, categoryBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.PRODUCT_CATEGORY, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, categoryBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.PRODUCT_CATEGORIES, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingProductCategories: true };
        case getNamedAction(ACTION_NAMES.PRODUCT_CATEGORIES, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingProductCategories: false };
        case getNamedAction(ACTION_NAMES.PRODUCT_CATEGORIES, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingProductCategories: false };

        case getNamedAction(ACTION_NAMES.PRODUCT_CATEGORY, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, categoryBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.PRODUCT_CATEGORY, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, categoryBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.PRODUCT_CATEGORY, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, categoryBeingDeleted: null };

        default:
            return state;
    }
}


export default productCategoriesReducer;
