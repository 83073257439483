import React from 'react';
import { Grid, Button } from 'semantic-ui-react';

const CategoriesHeader = ({ onOpenAddCategorySection }) => (
    <Grid verticalAlign='middle'>
        <Grid.Column width={6}>
            Categories
        </Grid.Column>
        <Grid.Column width={4} textAlign='right' floated='right'>
        <Button onClick={onOpenAddCategorySection} size='tiny' basic inverted color='white'>Add Category</Button>
        </Grid.Column>
    </Grid>
)

export default CategoriesHeader;
