import { Dropdown } from 'semantic-ui-react';
import React, { useEffect } from 'react';
import { Editable, Notification, STATUS_TYPES } from 'dyl-components';
import { useDispatch } from 'react-redux';
import accountActions from 'actions/account';
import { AccountUtils, StringUtils } from 'utils';
import { Controller, useForm } from 'react-hook-form';

import contactActions from 'actions/contact';

export default function InfluenceField({ id, influence, contactBeingEdited, account_id, onCancelEdit, onEdit, refresh }) {
    const { control, formState: { isValid, isDirty }, handleSubmit, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            influence
        }
    });

    const isEditing = id === contactBeingEdited;

    useEffect(() => {
        if (!isEditing) {
            reset();
        }
    }, [isEditing, reset]);

    const dispatch = useDispatch();

    const onConfirmEdit = async ({ influence }) => {
        try {
            await dispatch(contactActions.updateContact(id, { influence }));
            Notification.alert('Successfully updated role!', STATUS_TYPES.SUCCESS);
            if (refresh) {
                refresh();
            } else {
                dispatch(accountActions.readAccount(account_id));
            }
            onCancelEdit();
        } catch (e) {
            Notification.alert('Failed to update role', STATUS_TYPES.ERROR);
        }
    };

    return (
        <Editable
            value={influence ? StringUtils.toSentenceCase(influence) : 'Unknown'}
            isEditing={isEditing}
            onCancelEdit={() => {
                reset();
                onCancelEdit();
            }}
            onConfirmEdit={handleSubmit(onConfirmEdit, (errors, e) => {
                console.log(errors, e);
            })}
            submitDisabled={!isDirty || !isValid}
            onEdit={() => { onEdit(id) }}
        >
            <Controller
                name='influence'
                control={control}
                render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                    <Dropdown
                        name={name}
                        value={value}
                        onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                        error={error && error.message && {
                            content: error.message
                        }}
                        placeholder='Select Role'
                        size='tiny'
                        options={AccountUtils.roleOptions}
                        compact 
                        selectOnBlur={false}
                    />
                )} />
        </Editable>
    );
}

