const initialState = {
    hotlist: [],
    currentUserDispalyedHotlist: '',
    allMarked: false,
    allPagesMarked: false,
    isReadingHotlist: false,
    count: 0,
    isAddingToHotlist: false,
    isRemovingFromHotlist: false,
    contactBeingRemoved: null,
    queryParameters: {
        page: 1,
        limit: 25,
        all: true
    },
    isCheckingContactExistence: false,
    existing_contacts: []
}

export default initialState;
