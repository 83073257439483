import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";
import { StringUtils } from "utils";

function productFeesReducer(state = initialState, action) {

    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PRODUCT_FEES, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreating: true };
        case getNamedAction(ACTION_NAMES.PRODUCT_FEES, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreating: false };
        case getNamedAction(ACTION_NAMES.PRODUCT_FEES, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreating: false };

        case getNamedAction(ACTION_NAMES.PRODUCT_FEES, CRUD_ACTION_TYPES.READ_REQUEST): {
            const type = action.queryParameters.type === 'tax' ? 'taxes' : 'fees';
            return { ...state, [`isReading${StringUtils.capitalize(type)}`]: true, [type]: [], [`${type}Count`]: 0 };
        }
            
        case getNamedAction(ACTION_NAMES.PRODUCT_FEES, CRUD_ACTION_TYPES.READ_SUCCESS): {
            const type = action.queryParameters.type === 'tax' ? 'taxes' : 'fees';
            return { ...state, [`isReading${StringUtils.capitalize(type)}`]: false, [type]: action.data?.data || [], [`${type}Count`]: action.data?.count || 0 };
        }
            
        case getNamedAction(ACTION_NAMES.PRODUCT_FEES, CRUD_ACTION_TYPES.READ_FAILURE): {
            const type = action.queryParameters.type === 'tax' ? 'taxes' : 'fees';
            return { ...state, [`isReading${StringUtils.capitalize(type)}`]: false, [type]: [], [`${type}Count`]: 0 };
        }

        case getNamedAction(ACTION_NAMES.PRODUCT_FEES, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, feeBeingRead: action.id, fee: null, feeError: false };
        case getNamedAction(ACTION_NAMES.PRODUCT_FEES, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, feeBeingRead: null, fee: action.data, feeError: false };
        case getNamedAction(ACTION_NAMES.PRODUCT_FEES, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, feeBeingRead: null, fee: null, feeError: true };

        case getNamedAction(ACTION_NAMES.PRODUCT_FEES, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, feeBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.PRODUCT_FEES, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, feeBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.PRODUCT_FEES, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, feeBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.PRODUCT_FEES, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, feeBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.PRODUCT_FEES, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, feeBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.PRODUCT_FEES, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, feeBeingDeleted: null };

        default:
            return state;
    }
}

export default productFeesReducer;
