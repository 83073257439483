import React from 'react';
import { Button } from 'dyl-components';

import './ActionButton.scss';

const ActionButton = ({ label, color, onClick, icon }) => (
    <div className="ActionButton">
        <Button circular color={color} size='large' onClick={onClick} icon={icon} />
        <p>{label}</p>
    </div>
)

export default ActionButton;
