import React from 'react';
import { Grid, Form, Checkbox, Icon, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { ButtonLink } from 'dyl-components';

const setLimit = (onSetLimit, queryParameters) => (
    (_, { value }) => {
        onSetLimit({ ...queryParameters, page: 1, limit: value });
    }
)

const CampaignTableToolbar = ({ numberOfLeads = 0, onSort, onOpenBulkActionsModal, numberOfMarkedLeads, sortOrder, queryParameters, onSetLimit }) => (
    <Form as={Grid} stackable textAlign='left' style={{ paddingLeft: '1em' }}>
        <Form.Group as={Grid.Row} style={{ paddingBottom: 0, marginBottom: 0 }}>
            <Form.Field as={Grid.Column} width={2}>
                <Checkbox label='Select all pages' className='Checkbox--white' />
            </Form.Field>
            <Form.Field as={Grid.Column} width={2} style={{ left: '-0.5em' }}>

                <ButtonLink disabled={numberOfMarkedLeads === 0} onClick={onOpenBulkActionsModal} className='ToolbarButtonLink'>
                    <span><Icon name='cog' />Bulk Actions</span>
                </ButtonLink>

            </Form.Field>
            <Form.Field as={Grid.Column} width={1}>
                <Dropdown icon={''} trigger={<span><Icon name='sort' />Sort</span>}>
                    <Dropdown.Menu>
                        <Dropdown.Header content='Sort by' />
                        <Dropdown.Item active={sortOrder === 'received'} onClick={() => { onSort('received') }}>Received</Dropdown.Item>
                        <Dropdown.Item active={sortOrder === 'date_added'} onClick={() => { onSort('date_added') }}>Date Added</Dropdown.Item>
                        <Dropdown.Item active={sortOrder === 'activity'} onClick={() => { onSort('activity') }}>Activity</Dropdown.Item>
                        <Dropdown.Item active={sortOrder === 'callback'} onClick={() => { onSort('callback') }}>Callback</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Form.Field>
            <Form.Field as={Grid.Column} width={1}>
                <Dropdown
                    icon={''}
                    trigger={<span><Icon name='list' />Show</span>}
                    onChange={setLimit(onSetLimit, queryParameters)}
                    options={[
                        { key: 10, value: 10, text: 10 },
                        { key: 25, value: 25, text: 25 },
                        { key: 50, value: 50, text: 50 },
                        { key: 100, value: 100, text: 100 },
                        { key: 200, value: 200, text: 200 }
                    ]}
                    header={'Show'}
                    value={10}
                />
            </Form.Field>
            <Grid.Column floated='right' width={2} textAlign='right'>
                Leads: {8}
            </Grid.Column>
        </Form.Group>
    </Form>
)

class CampaignTableToolbarContainer extends React.Component {
    state = {
        isBulkActionsModalOpen: false
    }

    onOpenBulkActionsModal = () => {
        this.setState({ isBulkActionsModalOpen: true });
    }

    onCloseBulkActionsModal = () => {
        this.setState({ isBulkActionsModalOpen: false });
    }

    render() {
        return (
            <React.Fragment>
                <CampaignTableToolbar
                    {...this.props}
                    onOpenBulkActionsModal={this.onOpenBulkActionsModal}

                />
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {

}

const mapDispatchToProps = (dispatch, { id }) => {

}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignTableToolbarContainer);
