import React, { useState } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { Icon, SearchBar } from 'dyl-components';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

const ImportToolbar = () => {
    const [params] = useSearchParams();

    const navigate = useNavigate();

    const searchQuery = params.get('search') || '';
    const [search, setSearch] = useState(searchQuery);

    const onSearchSubmit = () => {
        const query = new URLSearchParams(params);
        query.set('search', search.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`/settings/import-data${query_string ? `?${query_string}` : ''}`);
    }

    const cancelFunction = () => {
        const query = new URLSearchParams(params);
        query.delete('search');
        const query_string = query.toString();
        navigate(`/settings/import-data${query_string ? `?${query_string}` : ''}`);
    }

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <div className='ImportToolbar'>
                        <SearchBar
                            search={searchQuery}
                            searchFcn={onSearchSubmit}
                            cancelFcn={cancelFunction}
                            placeholder={'Search by file name or file description'}
                            onChange={onChangeSearch}
                        />
                        <Button as={Link} to="/settings/import-data/setup" color='primary' className='AddImportButton' content={
                            <span><Icon name='plus' /> New Import</span>
                        } />
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default ImportToolbar;
