import React, { useState } from "react";
import Avatar from "react-avatar";
import { Button, List, Form, Header, Divider, Icon, Grid } from "semantic-ui-react";
import { Modal, Notification, STATUS_TYPES } from "dyl-components";
import { useDispatch, useSelector } from 'react-redux';
import { StringUtils } from 'utils';

import emailActions from 'actions/email';
import uploadActions from 'actions/upload';


const AttachmentList = ({ Attachments, onOpenAttachment }) => {
    return <>
        <Header as={'h4'} style={{ marginBottom: '20px' }} >
            Attachments:
        </Header>
        <Grid>
            {
                Attachments.map(
                    ({ name, file_id }) => {
                        return <>
                            <Grid.Column width={3}>
                                <Header
                                    as={'h6'}
                                    icon
                                    onClick={() => onOpenAttachment({ file_id })}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Icon name='pdf file outline' />
                                    {name}
                                </Header>
                            </Grid.Column>
                        </>
                    })
            }
        </Grid>
    </>
}

const TaskEmailConfirmationModal = ({ open, onClose, onConfirm, emailIntegration, recordType, email, sendSignature }) => {
    const dispatch = useDispatch();

    const { subject, body, attachments } = email

    const { contact, account, contact_email_addresses, fromName } = useSelector(state => ({
        contact: state.contact?.contact,
        account: state.account?.account,
        contact_email_addresses: state.contact_email?.email,
        fromName: state.auth.name,
    }));

    const from = {
        name: fromName,
        email: emailIntegration?.email
    }
    
    const isContact = (recordType === 'contact')
    const recipient = ( 
        isContact? contact_email_addresses : account?.emails || [] 
    ).filter((e)=>{return e.main})[0]?.email

    const recipientName = (
        isContact ? `${contact?.first_name} ${contact?.last_name}` : account?.name
    );

    let to = {
        email: recipient ? recipient : email.contactDetails?.email?.email,
        name: recipientName ? recipientName : email.contactDetails ? `${email.contactDetails?.first_name} ${email.contactDetails?.last_name}` : "",
    }

    const [isSendingEmail, setSendingEmail] = useState(false);

    const getAttachmentTypeAndCustomID = ( file_id ) => {
        // file id is in format "1/pdf/5670ff03-eb56-480b-88a8-0dab03077c00.pdf"
        // Type and CustomID is are for usage on the upload-api
        let [type, custom_id] = file_id.split('/').slice(1);
        custom_id = custom_id.split('.')[0] // remove file extension
        return { type, custom_id }
    }

    const getAttachment = async ( { file_id, name } ) => {
        // returns a promise with the attachment in the payload format for the email-api { filename, data_type, data }
        const { type, custom_id } = getAttachmentTypeAndCustomID(file_id);
        return (
            new Promise((resolve, reject) => {
                dispatch(uploadActions.download(type, { custom_id }, false))
                    .then((blob) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onload = () => {
                            const [properties, data] = reader.result.split(',');
                            const data_type = properties.match(/data:(.*?);base64/)[1];
                            resolve({ filename: name, data_type, data });
                        }
                        reader.onerror = (e) => { reject(e); }
                    })
            })
        )
    }

    const getAttachments = () => {
        const promises = attachments.map( (att) => { return getAttachment(att) })
        return Promise.all(promises)
    }

    const sendAndConfirm = () => {        
        setSendingEmail(true);
        getAttachments()        
            .then(
                (atts) => {
                    const payload = {
                        from,
                        to: [to],
                        subject: subject,
                        body: body + sendSignature?.content || "",
                        body_type: 'html',
                        attachments: atts
                    }
                    return dispatch(emailActions.send([payload]))
                })
            .then(() => {
                Notification.alert('Succesfully sent!', STATUS_TYPES.SUCCESS, true);
                setSendingEmail(false);
                onConfirm();
            })
            .catch(() => {
                Notification.alert('Problem sending the email!', STATUS_TYPES.ERROR, true);
                setSendingEmail(false)
            })
    }

    return (
        <Modal open={open} size={"tiny"} animated onClose={onClose}>
            <Modal.Header>{email.subject}</Modal.Header>
            <Modal.Content>
                <Form isLoading={true}>
                <List>
                    <List.Item>
                        <List.Icon verticalAlign="middle">
                            <Avatar
                                name={from.name}
                                maxInitials={2}
                                round
                                size="3em"
                            />
                        </List.Icon>
                        <List.Content>
                            <List.Header>
                                <span>{from.name}</span>
                            </List.Header>
                            <List.Description>
                                <span>{`to ${to?.name} ${to?.email ? `(${to?.email})` : ""} `}</span>
                            </List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content style={{ overflow: 'scroll' }}>
                            { StringUtils.CleanHTML({children: email.body}) }
                            { StringUtils.CleanHTML({children: sendSignature?.content }) }
                        </List.Content>
                    </List.Item>
                    <Divider/>
                    <List.Item>
                        <List.Content>
                            <AttachmentList
                                Attachments={email.attachments || []}
                                onOpenAttachment={({file_id})=>{
                                    const { type, custom_id } = getAttachmentTypeAndCustomID(file_id);
                                    dispatch(uploadActions.download(type, { custom_id }));
                                }}
                            />
                        </List.Content>
                    </List.Item>
                </List>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    floated={'left'}
                    negative
                    disabled={isSendingEmail}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    primary
                    basic
                    onClick={onConfirm}
                    disabled={isSendingEmail}
                >
                    Complete task only
                </Button>
                <Button
                    primary
                    onClick={sendAndConfirm}
                    loading={isSendingEmail}
                    disabled={(to.email === undefined) || (emailIntegration === null)}
                >
                    Send Email
                </Button>
                
            </Modal.Actions>
        </Modal>
    );
};

export default TaskEmailConfirmationModal;
