import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Icon } from 'semantic-ui-react';

import { BulkActionsContext } from 'shared/context/BulkActionsProvider';

const Toolbar = () => {
    const [selectedAddons, , areAddonsInAllPagesSelected] = useContext(BulkActionsContext);

    const hasSelectedAddons = selectedAddons.length || areAddonsInAllPagesSelected;

    const [, setIsBulkActionsModalOpen] = useState(false);

    const onOpenBulkActionsModal = () => {
        setIsBulkActionsModalOpen(true);
    }

    return (
        <>
            <Grid>
                <Grid.Row columns='equal' verticalAlign='middle'>
                    <Grid.Column></Grid.Column>
                    <Grid.Column width={4} floated='right'>
                        <Button as={Link} to='/settings/product-catalog-quotes-invoices/addons/create' floated='right' className='AddonsToolbar__NewAddonsButton'>
                            <Icon name='plus' /> New Add-On
                        </Button>
                        <Button floated='right' className='AddonsToolbar__NewAddonsButton' disabled={!hasSelectedAddons} onClick={onOpenBulkActionsModal}>
                            Bulk Action
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}

export default Toolbar;
