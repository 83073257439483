import React, { useState } from 'react';
import { Modal, Notification, STATUS_TYPES, Table } from 'dyl-components';
import { Form, Header, Icon, Popup, Table as SemanticTable } from 'semantic-ui-react';
import "./index.scss"
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import automationActions from 'actions/automation';
import FullScreenModalPopUp from 'shared/FullScreenModalPopUp';

const OrderAssignmentRulesModal = ({rules = {}, onClose, onGetAssignmentRules}) => {
    const [removedRules, setRemovedRules] = useState([]);
    const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
    const dispatch = useDispatch();

    const { control, handleSubmit, formState: {isDirty, isValid} } = useForm({
        mode: 'onChange',
        defaultValues: {
            rules: rules.data || []
        },
    });

    const { fields, move } = useFieldArray({
        control: control,
        name: 'rules',
        keyName: "useFieldArrayId"
    })

    const onDragEnd = (result) => {
        const {destination, source} = result;
        if (!!destination && !!source) {
            move(source.index, destination.index);
        }
    };

    const getRemovedActionIds = () => {
        return removedRules.map((removedTrigger) => {
            return rules.data.find((rule) => rule.id === removedTrigger).segments?.[0]?.actions?.[0]?.action_id;
        })
    }

    const onSave = async (data) => {
        try {
            const module = rules.name.toLowerCase();
            const body = data.rules.filter(({removed}) => !removed).map(({active, id}) => ({
                id,
                active
            }))
            const removedActions = getRemovedActionIds()
            await dispatch(automationActions.reorderTriggers(null, body, {module, removedActions}));
            Notification.alert(`Successfully reordered assignment rules!`, STATUS_TYPES.SUCCESS, true);
            onClose();
            onGetAssignmentRules();
        } catch (error) {
            Notification.alert(`Successfully reordered assignment rules!`, STATUS_TYPES.ERROR, true);
        }
    }

    return (
        <>
            <Modal.Header>
                Edit Assignment Rules Order
                <Popup
                    trigger={
                        <div className='ReorderingInfo'>
                            <Icon
                                className="fas fa-circle-info"
                                color="primary"
                            />
                        </div>
                    }
                    content={`Reordering a rule will reset the priority of assignment rules.`}
                    inverted
                    position="left center"
                    wide
                    on="hover"
                />
            </Modal.Header>
            <Modal.Content scrolling>
                    <Header>{rules.name || ""}</Header>
                    <Form>
                        <Table>
                            <SemanticTable.Header className="Table__header--primary">
                                <Table.Row>
                                    <Table.HeaderCell width={2}>#</Table.HeaderCell>
                                    <Table.HeaderCell width={5}>Rule Name</Table.HeaderCell>
                                    <Table.HeaderCell width={5}>Description</Table.HeaderCell>
                                    <Table.HeaderCell width={2}>Active</Table.HeaderCell>
                                    <Table.HeaderCell width={2} />
                                </Table.Row>
                            </SemanticTable.Header>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId='droppable'>
                                    {(provided, snapshot) => (
                                        <tbody
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            {fields.map((rule, index) => {
                                                const isDisabled = !!removedRules.find((removedId) => removedId === rule.id);
                                                return <Draggable key={index} draggableId={`draggable-${index}`} index={index} isDragDisabled={isDisabled}>
                                                    {(provided, snapshot) => (
                                                        <tr
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Table.Cell className="AssignmentRules__numberCell" disabled={isDisabled}>
                                                                <Icon className='fas fa-arrows-alt AssignmentRules__reorderIcon' disabled={isDisabled} />
                                                                {index + 1}
                                                            </Table.Cell>
                                                            <Table.Cell disabled={isDisabled}>{rule.name}</Table.Cell>
                                                            <Table.Cell disabled={isDisabled}>{rule.description}</Table.Cell>
                                                            <Table.Cell className="AssignmentRules__reorderToggleCell">
                                                                <Controller
                                                                    name={`rules[${index}].active`}
                                                                    control={control}
                                                                    render={({ field: { name, value, onChange } }) => {
                                                                        return (
                                                                            <Form.Radio
                                                                                className='AssignmentRules__activeToggle'
                                                                                label={value ? 'Active' : 'Inactive'}
                                                                                toggle
                                                                                name={name}
                                                                                checked={value}
                                                                                onChange={(_, { checked }) => {
                                                                                    onChange(checked)
                                                                                }}
                                                                                disabled={isDisabled}
                                                                            />
                                                                        )
                                                                    }}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <Controller
                                                                    name={`rules[${index}].removed`}
                                                                    control={control}
                                                                    render={({ field: { value, onChange } }) => (
                                                                        <Icon
                                                                            className={!!value ? 'fa-solid fa-rotate-left AssignmentRules__undoIcon' : 'fa-solid fa-trash AssignmentRules__removeIcon'}
                                                                            link
                                                                            onClick={() => {
                                                                                if (!value) {
                                                                                    const removedRulesAux = [...removedRules, rule.id];
                                                                                    setRemovedRules(removedRulesAux);
                                                                                } else {
                                                                                    const removedRulesAux = removedRules.filter((removedRule) => removedRule !== rule.id);
                                                                                    setRemovedRules(removedRulesAux);
                                                                                }
                                                                                onChange(!value);
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </Table.Cell>
                                                        </tr>
                                                    )}
                                                </Draggable>
                                            })}
                                        </tbody>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Table>
                    </Form>
            </Modal.Content>
            <Modal.Actions 
                hasSaveButton
                onSave={removedRules.length > 0 ? () => setIsSaveConfirmationOpen(true) : handleSubmit(onSave)}
                saveDisabled={!isDirty || !isValid}
            />
            <FullScreenModalPopUp
                header={'Are you sure?'} 
                subheader={'Rule will be removed.'} 
                isOpen={isSaveConfirmationOpen}
                onConfirm={handleSubmit(onSave)}
                onFormClose={() => setIsSaveConfirmationOpen(false)}
                closeIcon={false}
            />
        </>
    )
}

export default OrderAssignmentRulesModal;
