
import React, { useImperativeHandle, forwardRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Grid, Header, Segment } from 'semantic-ui-react';
import { Button, Icon } from 'dyl-components';

import { useForm, useFieldArray } from "react-hook-form";


const DynamicInputs = forwardRef(({ props }, ref) => {
    const { isAttemptingEmailParse } = useSelector(state => state.lead_ingestion);
    const { onAddInputValues, parsePayload } = props;

    const { parse_pairs } = parsePayload;

    const defaultInputValues = (parse_pairs) ? parse_pairs : [{ key: "", value: "" }];

    const { register, control, watch, getValues, reset } = useForm({
        defaultValues: {
            newInput: defaultInputValues
        }
    });
    const { fields, remove, append } = useFieldArray({
        name: "newInput",
        control
    });

    useEffect(() => {
        if (fields.length < 2) {
            for (let index = 1; index < 2; index++) {
                append({ key: "", value: "" });
            }
        }

    }, [fields, append]);

    useEffect(() => {
        const subscription = watch((data)=>{
            //update input values for the container to pass to the parseAttempt endpoint
            onAddInputValues(getValues());
        })
        return () => {
            subscription.unsubscribe()
        }
    }, [watch, getValues, onAddInputValues])


    useImperativeHandle(ref, () => ({
        resetForm() {
            reset();
        }
    }));

    return (
        <Form className="ParseInputFields" loading={ isAttemptingEmailParse } >
            <Grid>
                <Grid.Row >
                    <Grid.Column width={ 7 }>
                        <Header as="h4">Field name</Header>
                    </Grid.Column>
                    <Grid.Column width={ 7 }>
                        <Header as="h4">Field value</Header>
                    </Grid.Column>
                    <Grid.Column width={ 2 }> {/** check column holder**/}</Grid.Column>
                </Grid.Row>
                { fields.map((field, index) => {
                    return (
                        <Grid.Row key={ field.id } columns={ 2 }>
                            <Grid.Column width={ 7 }><Form.Field>
                                <input
                                    defaultValue={ field.key }
                                    { ...register(`newInput[${index}].key`) }
                                />
                            </Form.Field>
                            </Grid.Column>

                            <Grid.Column width={ 7 }>
                                <Form.Field>
                                    <input
                                        defaultValue={ field.value }
                                        { ...register(`newInput[${index}].value`) }
                                    />
                                </Form.Field>
                            </Grid.Column>

                            <Grid.Column width={ 2 }>

                                { fields.length > 2 &&
                                    <Icon className="fa-solid fa-xmark NeutralGray"
                                        size="big"
                                        onClick={ () => remove(index) } />
                                }
                            </Grid.Column>
                        </Grid.Row>
                    );
                }) }
                <Grid.Row columns={ 1 }>
                    <Button as={ 'span' } onClick={ () => {
                        append({
                            key: "",
                            value: ""
                        });
                    } }>
                    + Add Additional Key Value Pair
                    </Button>
                </Grid.Row>
            </Grid>
        </Form>
    );
});

export const InputValuePairsTable = ({ onAddInputValues, dynamicFieldsRef, parsePayload }) => {

    return (
        <Grid>
            <Grid.Row columns={ 1 }>
                <Grid.Column>
                    <Header as='h3'>Enter Key Value Pairs <Icon name='info circle' color='blue' size='small' /></Header>
                    <p>Use this option if the parsing above is incorrect or missing field-value pairs.</p>
                    <Segment placeholder textAlign='center'>

                        <DynamicInputs props={ { onAddInputValues, parsePayload } } ref={ dynamicFieldsRef } />

                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}