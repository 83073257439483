import React from 'react';
import { Form, Icon } from 'semantic-ui-react';

import { useDispatch, useSelector } from 'react-redux';
import { ClippedContent, generateResolver, Notification, STATUS_TYPES, yup } from 'dyl-components';
import { Controller, useForm } from 'react-hook-form';
import contactActions from 'actions/contact';

export const JobTitle = ({ isEditing, onEdit, onCancelEdit }) => {
    const job_title = useSelector(state => state.contact.contact?.job_title);

    const contact_id = useSelector(state => Number(state.contact.contact_id));
    const isUpdating = useSelector(state => !!state.contact.contactBeingUpdated);
    const dispatch = useDispatch();

    const { control, formState: { isValid, isDirty }, reset, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            job_title: job_title || ''
        },
        resolver: generateResolver({
            job_title: yup.string().noemoji().maxlength(60)
        })
    })

    const onCancel = () => {
        onCancelEdit();
        reset({
            job_title: job_title || ''
        })
    }

    const onConfirmEdit = async (data) => {
        try {
            await dispatch(contactActions.updateContact(contact_id, data));
            Notification.alert("Successfully updated job title!", STATUS_TYPES.SUCCESS);
            dispatch(contactActions.readContact(contact_id));
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to update job title", STATUS_TYPES.ERROR);
        }
    }

    return (
        !isEditing ? (
            <div className='ProfileHeader__editable' onClick={onEdit}>
                <ClippedContent popupContent={job_title}>
                    <em>{job_title || 'No job title'}</em>
                </ClippedContent>
            </div>
        ) : (
            <Form size='mini' loading={isUpdating}>
                <Form.Group>
                    <Controller
                        name='job_title'
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Input
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                error={error?.message}
                                placeholder='Job Title'
                                fluid
                                width={10}
                            />
                        )}
                    />
                    <Form.Field width={1}>
                        <Icon size='large' color='red' link onClick={onCancel} className='fa-solid fa-xmark' />
                    </Form.Field>
                    <Form.Field width={1}>
                        <Icon size='large' disabled={!isValid || !isDirty} color='primary' link onClick={handleSubmit(onConfirmEdit)} className='fa-solid fa-check' />
                    </Form.Field>
                </Form.Group>
            </Form>
        )
    );
}
