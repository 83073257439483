import React from 'react';
import { List, Divider, Header, Icon, Grid, Transition } from 'semantic-ui-react';
import FavoriteItem from '../FavoriteItem';
import { FAVORITE_TYPE } from 'shared/constants/FAVORITE_TYPE';
import { StringUtils } from 'utils';

const FAVORITES_ICONS = {
    [FAVORITE_TYPE.CONTACT]: "fas fa-user",
    [FAVORITE_TYPE.MASTER_ACCOUNT]: "fas fa-buildings",
    [FAVORITE_TYPE.ACCOUNT]: "fas fa-house-building",
    [FAVORITE_TYPE.CAMPAIGN]: "fas fa-megaphone",
    [FAVORITE_TYPE.GROUP]: "fas fa-folder",
    [FAVORITE_TYPE.REPORT]: "fas fa-chart-pie",
    [FAVORITE_TYPE.SETTING]: "fas fa-cog"
}

const FavoriteCategory = ({ category, onToggle, isExpanded, isEditing, isFavorite, onToggleFavorite }) => {
    const { group: name, data: favorites } = category;
    const favoritesCopy = favorites.slice(0);
    favoritesCopy.sort((a, b) => {
        const aDisplayName = a.display_name?.toLowerCase();
        const bDisplayName = b.display_name?.toLowerCase();
        if (aDisplayName < bDisplayName) {
            return -1;
        }
        if (aDisplayName > bDisplayName) {
            return 1;
        }
        return 0;
    });
    return (
        <div className='FavoriteCategory'>
            <Divider className='Divider' />
            <Grid>
                <Grid.Column>
                    <Header as='h4'>
                        <span>
                            <Icon disabled={favorites.length === 0} color="primary" onClick={onToggle} link className={`fas fa-circle-chevron-${isExpanded ? "up" : "down"}`} />
                            <Icon className={FAVORITES_ICONS[name]} /> {StringUtils.toSentenceCase(name)}
                        </span>
                    </Header>
                </Grid.Column>
            </Grid>
            <Transition animation="slide down" duration={150} visible={isExpanded} unmountOnHide>
                <List>
                    {favoritesCopy.map((favorite) => {
                        const { external_id, setting_report_type, favorite_type } = favorite;
                        return (
                            <FavoriteItem
                                key={`${external_id || setting_report_type}${favorite_type}`}
                                favorite={favorite}
                                isEditing={isEditing}
                                isFavorite={isFavorite}
                                onToggleFavorite={onToggleFavorite}
                            />
                        );
                    })}
                </List>
            </Transition>
        </div>
    );
};

export default FavoriteCategory;
