import React from 'react';
import SalesManagerDashboard from './SalesManagerDashboard';
import SalesRepresentativeDashboard from './SalesRepresentativeDashboard';
import { connect } from 'react-redux';

import { Icon, Grid, List, Popup, Segment } from 'semantic-ui-react';
import { RecordUtils } from 'utils';
import { ListTopHeaderBottomContent, ListItemMiddleContentRightOptions, ButtonLink, PageTitleCard } from 'dyl-components';
import { Link } from 'react-router-dom';


const renderDashboard = (dashboard) => {
    switch (dashboard) {
        case 'Sales Manager':
            return <SalesManagerDashboard />;
        case 'Sales Representative':
            return <SalesRepresentativeDashboard />;
        default:
            return;
    }
}

const getInactiveDashboard = (dashboard) => {
    switch (dashboard) {
        case 'Sales Manager':
            return 'Sales Representative';
        case 'Sales Representative':
            return 'Sales Manager';
        default:
            return '';
    }
}

const Dashboard = ({
    contacts,
    isReadingRecentContacts,
    leads,
    isReadingLeads,
    opportunities,
    isReadingOpportunities,
    activeDashboard,
    onToggleDashboard,
}) => (
    <React.Fragment>
        <PageTitleCard title={`${getInactiveDashboard(activeDashboard) !== 'Sales Representative' ? 'My' : 'Mgr'} Dashboard`}>
            <Popup
                position='top center'
                flowing
                trigger={
                    <ButtonLink onClick={onToggleDashboard} >
                        <Icon name='list alternate outline' />
                        Switch dashboards
                    </ButtonLink>
                }
                inverted
                content={`${getInactiveDashboard(activeDashboard) === 'Sales Representative' ? 'My' : 'Mgr'} Dashboard`}
            />
        </PageTitleCard>
        <Grid stackable stretched>
            <Grid.Row>
                <Grid.Column>
                    {renderDashboard(activeDashboard)}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <Segment style={{ padding: 0 }} loading={isReadingRecentContacts}>
                        <ListTopHeaderBottomContent topLeft='Recent Contacts' bottom={<React.Fragment>
                            <List divided relaxed>
                                {contacts.map(generateRecordItem('contact'))}
                            </List>
                        </React.Fragment>} />
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment style={{ padding: 0 }} loading={isReadingLeads}>
                        <ListTopHeaderBottomContent topLeft='Recent Leads' bottom={<React.Fragment>
                            <List divided relaxed>
                                {leads.map(generateRecordItem('lead'))}
                            </List>
                        </React.Fragment>} />
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment style={{ padding: 0 }} loading={isReadingOpportunities}>
                        <ListTopHeaderBottomContent topLeft='Recent Opportunities' bottom={<React.Fragment>
                            <List divided relaxed>
                                {opportunities.map(generateRecordItem('opportunity'))}
                            </List>
                        </React.Fragment>} />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </React.Fragment>
)


class DashboardContainer extends React.Component {
    state = {
        activeDashboard: 'Sales Representative'
    }

    onToggleDashboard = () => {
        this.setState({
            activeDashboard: getInactiveDashboard(this.state.activeDashboard)
        })
    }

    render() {
        return (
            <Dashboard
                activeDashboard={this.state.activeDashboard}
                isReadingRecentContacts={this.props.isReadingRecentContacts}
                isReadingLeads={this.props.isReadingLeads}
                isReadingOpportunities={this.props.isReadingOpportunities}
                contacts={this.props.contacts}
                leads={this.props.leads}
                opportunities={this.props.opportunities}
                onToggleDashboard={this.onToggleDashboard}
            />
        )
    }
}

const mapStateToProps = state => {
    return ({
        contacts: state.contacts.recent_contacts.map(formatContacts()),
        leads: state.leads.leads.map(formatRecords()),
        opportunities: state.opportunities.opportunities.map(formatRecords()),
        isReadingRecentContacts: state.contacts.isReadingRecentContacts,
        isReadingLeads: state.leads.isReadingLeads,
        isReadingOpportunities: state.opportunities.isReadingOpportunities,
    })
}

function generateRecordItem(type) {
    return record => {
        const { firstLine, secondLine } = RecordUtils.getName(record);
        return <ListItemMiddleContentRightOptions
            text={(
                <Link to={RecordUtils.generateRecordPath(type, record.contact_id, record.id)}>
                    {firstLine} {secondLine}
                </Link>
            )}
            subheader={record.account_type}
            hasIcon={false}
        />;
    };
}

function formatRecords() {
    return ({ contact, title, id, account_type }) => {
        const first_name = contact.first_name || '';
        const last_name = contact.last_name || '';
        const suffix = contact.suffix || '';
        const custom_data = contact.custom_data || { fields: {} };
        return {
            first_name,
            last_name,
            suffix,
            title,
            id,
            custom_data,
            account_type,
            contact_id: contact.id
        };
    };
}

function formatContacts() {
    return contact => {
        let first_name = contact.first_name || '';
        let last_name = contact.last_name || '';
        let suffix = contact.suffix || '';
        if (first_name === '' && last_name === '') {
            [first_name, last_name] = contact.account_name ? contact.account_name.split(' ') : ['Unknown', 'Name'];
        }
        return {
            ...contact,
            account_type: contact.contact_type,
            contact_id: contact.id,
            account_id: contact.account ? contact.account.id : 0,
            first_name,
            last_name,
            suffix,
        }
    }
}

export default connect(mapStateToProps)(DashboardContainer);

