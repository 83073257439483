import React from 'react';
import { Link } from 'react-router-dom';
import { Table, MessagingIcon } from 'dyl-components';
import { Checkbox, Popup, Icon } from 'semantic-ui-react';
import { TwoLinedContent } from 'dyl-components';

const VoicemailHistoryRow = ({ voicemail, onPlay, onCall, onTranscribe }) => (
    <Table.Row>
        <Table.Cell>
            <TwoLinedContent firstLine={voicemail.ts.split()[0]} secondLine={voicemail.ts.split()[1]} />
        </Table.Cell>
        <Table.Cell>
            <Link to={`/lead?id=${voicemail.lead_id}`}>
                <Icon name='circle' className={`Contact__status-icon`} />
                <TwoLinedContent className={'Contact__details'} firstLine={`${voicemail.first_name} ${voicemail.last_name}`} 
                secondLine={voicemail.phone} />
            </Link>
        </Table.Cell>
        <Table.Cell>
            {voicemail.mailbox}
        </Table.Cell>
        <Table.Cell>
            <Checkbox checked={voicemail.listened} />
        </Table.Cell>
        <Table.Cell>
            <Popup inverted position='top center' trigger={<span className='LeadsTable__messagingIcon'><MessagingIcon size='' icon={'play circle'} onClick={() => {onPlay(voicemail.id)}} /></span>} content='Play Recording' />
            <Popup inverted position='top center' trigger={<span className='LeadsTable__messagingIcon'><MessagingIcon size='' icon={'phone'} onClick={() => {onCall(voicemail.lead_id)}} /></span>} content='Call Lead' />
            <Popup inverted position='top center' trigger={<span className='LeadsTable__messagingIcon'><MessagingIcon size='' icon={'edit'} onClick={() => {onTranscribe(voicemail.id)}} /></span>} content='Transcribe' />
        </Table.Cell>
    </Table.Row>
);

export default VoicemailHistoryRow;
