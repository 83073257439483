import React, { useState } from 'react';
import { Dropdown, Button, Ref, Popup, Header, Portal } from 'semantic-ui-react';
import { CloseableContent } from 'dyl-components';

import { StringUtils } from "utils";
import LostForm from "shared/forms/LostForm";
import ReactivateCustomerForm from "shared/forms/ReactivateCustomerForm";

import './ConversionControls.scss';
import ConvertToCustomerForm from 'shared/forms/ConvertToCustomerForm';

export const PopupForm = ({
    account,
    statusRef,
    conversionModalOpen,
    isConversionFormModified = false,
    setIsConversionFormModified,
    refresh,
    onClose
}) => {
    const getPopupContent = (conversionModalOpen) => {
        switch (conversionModalOpen) {
            case 'customer':
                return (
                    <React.Fragment>
                        <Header color='primary'>Convert to Customer</Header>
                        <ConvertToCustomerForm
                            account_id={account.id}
                            onClose={onClose}
                            size={'small'}
                            onModify={(isModified) => { setIsConversionFormModified(isModified) }}
                            refresh={refresh}
                            owner_id={account?.primary_person_id}
                            action="convert-account"
                        />
                    </React.Fragment>
                )
            case 'pause':
                return (
                    <React.Fragment>
                        <div className={"Account__lost-form"}>
                            <Header color='primary'>Pause Customer</Header>
                            <LostForm
                                account_id={account.id}
                                onClose={onClose}
                                type={'paused'}
                                account_stage={'customer'}
                                refresh={refresh}
                            />
                        </div>
                    </React.Fragment>
                )
            case 'cancel':
                return (
                    <React.Fragment>
                        <div className={"Account__lost-form"}>
                            <Header color='primary'>Cancel Customer</Header>
                            <LostForm
                                account_id={account.id}
                                onClose={onClose}
                                type={'canceled'}
                                account_stage={'customer'}
                                refresh={refresh}
                            />
                        </div>
                    </React.Fragment>
                )
            case 'reactivate':
                return (
                    <React.Fragment>
                        <Header color='primary'>Reactivate Customer</Header>
                        <ReactivateCustomerForm
                            account_id={account.id}
                            onClose={onClose}
                            size={'small'}
                            onModify={(isModified) => { setIsConversionFormModified(isModified) }}
                            refresh={refresh}
                            customer_since={account.customer_since}
                            primary_contact_id={account?.primary_person_id}
                        />
                    </React.Fragment>
                )
            default:
                return null;
        }
    }

    return (
        <Popup
            style={{ zIndex: '5' }}
            content={(
                <CloseableContent onClose={onClose}>
                    {getPopupContent(conversionModalOpen)}
                </CloseableContent>
            )}
            open={conversionModalOpen}
            position='bottom center'
            context={statusRef}
            closeOnDocumentClick={!isConversionFormModified}
            onClose={() => {
                if (conversionModalOpen !== "pause" && conversionModalOpen !== "cancel") {
                    onClose();
                }
            }}
        />
    );
}

export const StatusDropdown = ({ account, onStatusDropdownSelect }) => {
    const statusRef = React.createRef();
    const [position, setPosition] = useState({ x: 0, y: 0, width: 0 });
    const [isOpen, setIsOpen] = useState(false);

    const getActionText = () => {
        if (account.account_status) {
            return StringUtils.capitalize(account.account_status);
        }
        return 'Convert';
    }

    function getStatusClass() {
        if (account.account_status) {
            if (account.account_status === "active") {
                return "ConversionControls__StatusDropdown--active"
            }
            return `ConversionControls__StatusDropdown--${account.account_status}`
        }
        return 'ConversionControls__StatusDropdown--convert'
    }

    return (
        <div className='ConversionControls'>
            <Ref innerRef={statusRef}>
                <Dropdown
                    className={`ConversionControls__StatusDropdown ${getStatusClass()}`}
                    as={Button}
                    text={getActionText()}
                    onClick={() => {
                        if (statusRef.current) {
                            const position = statusRef.current.getBoundingClientRect();
                            const scrollY = window.scrollY;
                            setPosition({ x: position.left, y: position.top + scrollY + 15, width: position.width });
                            setIsOpen(!isOpen)
                        }
                    }}
                    onBlur={() => setIsOpen(false)}
                >
                    <Portal open>
                        <Dropdown open={isOpen} style={{ position: 'absolute', zIndex: 999, left: position.x, top: position.y, visibility: 'hidden' }} onClose={() => { setIsOpen(false); }}>
                            <Dropdown.Menu {...(position.width ? { style: { width: position.width } } : {})} >
                                {(!account.account_status || account.account_status === '') && (
                                    <Dropdown.Item onClick={() => {
                                        onStatusDropdownSelect('customer', statusRef, account);
                                        setIsOpen(false);
                                    }}>
                                        Convert to Customer
                                    </Dropdown.Item>
                                )}
                                {(account.account_status === 'paused' || account.account_status === 'canceled') && (
                                    <Dropdown.Item onClick={() => {
                                        onStatusDropdownSelect('reactivate', statusRef, account);
                                        setIsOpen(false);
                                    }}>
                                        Reactivate
                                    </Dropdown.Item>
                                )}
                                {account.account_status === 'active' && [
                                    <Dropdown.Item onClick={() => {
                                        onStatusDropdownSelect('pause', statusRef, account);
                                        setIsOpen(false);
                                    }}>
                                        Pause Customer
                                    </Dropdown.Item>
                                ]}
                                {(account.account_status === 'active' || account.account_status === 'paused') && (
                                    <Dropdown.Item onClick={() => {
                                        onStatusDropdownSelect('cancel', statusRef, account);
                                        setIsOpen(false);
                                    }}>
                                        Cancel Customer
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Portal>
                </Dropdown>
            </Ref>
        </div>
    );
}
