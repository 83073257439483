import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { Form, Header, Segment } from 'semantic-ui-react';
import { DateInput, DateTimeUtils, FileInput } from 'dyl-components';

import addonSchema from 'shared/schemas/addons/addonSchema';

import productsActions from "actions/products";

const AddonInfo = ({ setIsNameDuplicate }) => {
    const dispatch = useDispatch();

    const [typingTimeout, setTypingtimeout] = useState(null);

    const { control, addonBeingEdited, clearErrors, setError } = useFormContext();
    const { addon } = useSelector((state) => state.product_addons);
    const isCheckingForDuplicates = useSelector(state => state.products.isCheckingForDuplicates);

    const generateEffectiveDateMessage = (status, date) => {
        let message = null;
        if (!DateTimeUtils.isBeforeOrOnCurrentDate(date, DateTimeUtils.DATE_FORMAT)) {
            if (!addonBeingEdited) {
                if (status === 'active') {
                    message = `This add-on is inactive until ${date}`;
                }
            } else {
                let { status: originalStatus } = addon;
                if (originalStatus === 'active' && (status === 'inactive' || status === 'discontinued')) {
                    message = `This add-on is active until ${date}`;
                }
                if (originalStatus === 'inactive' && (status === 'active' || status === 'discontinued')) {
                    message = `This add-on is inactive until ${date}`;
                }
                if (originalStatus === 'discontinued' && (status === 'active' || status === 'inactive')) {
                    message = `This add-on is discontinued until ${date}`;
                }
            }
        }

        return (
            <p>
                <em>{message}</em>
            </p>
        );
    }

    const statusOptions = [
        {
            key: "active", value: "active", text: "Active"
        },
        {
            key: "inactive", value: "inactive", text: "Inactive"
        },
    ];
    if (addonBeingEdited) statusOptions.push({
        key: "discontinued", value: "discontinued", text: "Discontinued"
    });

    return (
        <Segment>
            <Header as="h3" color="primary">Add-On Info</Header>
            <Controller
                control={control}
                name="name"
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <Form.Input
                        name={name}
                        value={value}
                        onChange={async (_, { name, value }) => {
                            if (typingTimeout) {
                                clearTimeout(typingTimeout)
                            }
                            onChange({ target: { name, value } });
                            const isValid = await addonSchema.name.isValid(value);
                            if (isValid && value !== '') {
                                setTypingtimeout(
                                    setTimeout(async () => {
                                        await clearErrors(name);
                                        await setIsNameDuplicate(false);
                                        const isDuplicate = (await dispatch(productsActions.isDuplicate({ name: value, type: "addon", ...(addonBeingEdited ? { id: addonBeingEdited } : {}) })));
                                        if (isDuplicate) {
                                            await setError(name, {
                                                type: 'unique_addon',
                                                message: 'Add-on name already exists!'
                                            })
                                            await setIsNameDuplicate(true);
                                        }
                                    })
                                );
                            }
                        }}
                        label="Add-On Name"
                        required
                        error={error?.message}
                        loading={isCheckingForDuplicates}
                    />
                )}
            />
            <Controller
                control={control}
                name="description"
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <Form.TextArea
                        name={name}
                        value={value}
                        onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                        error={error?.message}
                        label="Add-On Description"
                    />
                )}
            />
            <Controller
                control={control}
                name="image"
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <Form.Field
                        control={FileInput}
                        name={name}
                        files={value?.map(file => ({
                            ...file,
                            path: URL.createObjectURL(file)
                        }))}
                        onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                        error={error?.message}
                        label="Add-On Image"
                        showImage
                        accept={"image/png, image/jpeg"}
                        hasBrowse
                        icon="image"
                        showIcon
                    />
                )}
            />
            <Form.Group widths={"equal"}>
                <Controller
                    control={control}
                    name="status"
                    render={({ field: { name, value: status, onChange }, fieldState: { error } }) => (
                        <>
                            <Form.Select
                                name={name}
                                value={status}
                                onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                label="Status"
                                required
                                options={statusOptions}
                                error={error?.message}
                                selectOnBlur={false}
                            />
                            <Controller
                                control={control}
                                name="active_date"
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Field as='div'>
                                        <Form.Field
                                            control={DateInput}
                                            name={name}
                                            value={value}
                                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                            label="Effective Date"
                                            error={error?.message}
                                            dateFormat={DateTimeUtils.DATE_FORMAT}
                                        />
                                        {DateTimeUtils.isValid(value, DateTimeUtils.DATE_FORMAT) && (
                                            generateEffectiveDateMessage(status, value)
                                        )}
                                    </Form.Field>
                                )}
                            />
                        </>
                    )}
                />
            </Form.Group>
        </Segment>
    );
}

export default AddonInfo;
