const initialState = {
    isCheckingUniqueness: false,
    isAddingContactToSequence: false,

    sequenceBeingDeleted: null,
    sequenceBeingUpdated: null,

    sequence: null,
    isReadingSequence: false,
    sequenceError: false,

    isCreating: false,

    sequences: [],
    sequence_count: 0,
    isReadingSequences: false,

    sequencesShort: [],
    sequence_short_count: 0,
    isReadingSequencesShort: false,

    sequence_users: [],
    sequence_user_count: 0,
    isReadingSequenceUsers: false
}

export default initialState;
