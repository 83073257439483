import initialState from './initialState';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';

function permissionsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PERMISSIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingPermissions: true }
        case getNamedAction(ACTION_NAMES.PERMISSIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingPermissions: false, appPermissions: action.data }
        case getNamedAction(ACTION_NAMES.PERMISSIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingPermissions: false }

        default:
            return state;
    }
}

export default permissionsReducer;
