import React, { useEffect, useState } from 'react';
import {Notification, STATUS_TYPES, generateResolver, yup, Person} from 'dyl-components';
import {Button, Form} from 'semantic-ui-react';

import { CustomGroupUtils } from 'utils';

import { useForm, Controller } from 'react-hook-form';

import ConvertToCustomerFields from "./ConvertToCustomerFields";
import accountActions from 'actions/account';
import customersActions from "../../actions/customers";
import sequencesActions from 'actions/sequences';
import contactSequenceActions from 'actions/contact_sequence';
import contactActions from 'actions/contact';
import { useDispatch, useSelector } from "react-redux";
import campaignsAction from "actions/campaigns";
import quoteActions from 'actions/quote';
import sequenceTasksActions from 'actions/sequence_tasks';
import FullScreenModalPopUp from 'shared/FullScreenModalPopUp';
import UpsellCustomerPopup from 'shared/UpsellCustomerPopup';

const accountTypes = [
    { key: 'business', text: 'Business', value: 'business' },
    { key: 'household', text: 'Household', value: 'household' }
];

const ConvertToCustomerForm = ({
    onClose,

    account_id: existing_account_id,
    owner_id,

    size,
    refresh,

    onModify,
    action,
    current_primary_contact_id,
    pipeline_id,
    isUpsellingFromQuote
}) => {
    const isContactLinked = Boolean(existing_account_id);
    const isConvertingNewCsutomer = action === "convert-new-customer";
    const { formState: { isDirty, isValid }, control, handleSubmit, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            contactUnlinked: !isContactLinked || isConvertingNewCsutomer,
            name: '',
            account_type: '',
            pipeline: null,
            stage: null,
            customer_type: null,
            owner_id: owner_id || ""
        },
        resolver: generateResolver({
            account_type: yup.string().when('contactUnlinked', {
                is: true,
                then: schema => schema.required('This field is required')
            }),
            name: yup.string().when('contactUnlinked', {
                is: true,
                then: schema => schema.required('This field is required')
            }),
            owner_id: yup.string().when('contactUnlinked', {
                is: false,
                then: schema => schema.nullable(true).required('This field is required')
            })
        })
    });

    const watchedOwnerId = watch("owner_id");

    useEffect(() => {
        onModify(isDirty);
    }, [isDirty, onModify]);

    const dispatch = useDispatch();

    const linkContactsToAccount = async (account_id, owner_id) => {
        return dispatch(accountActions.linkContacts([account_id], [{
            person_id: Number(owner_id), primary_person: true, ...(isConvertingNewCsutomer && existing_account_id ? { old_account_id: existing_account_id } : {})
        }]))
    }

    const { isCreating, primaryContactOptions, isReadingPrimaryContactOptions, contactSequenceTasks, pipelineCategories, isReadingSequenceContactTasks, selectedSequenceId } = useSelector(state => ({
        isCreating: state.account.isCreatingAccount ||
            state.account.isLinkingContactsToAccount ||
            state.customers.isCreatingCustomer ||
            state.account.isUpdatingAccount ||
            state.contact.contactBeingUpdated ||
            state.quote.opportunityBeingUpdated ||
            state.contact_sequence.isHandlingConvert || 
            state.sequences.isAddingContactToSequence,
        primaryContactOptions: state.account.contactsForPinning.map(contact => {
            const name = `${contact.first_name} ${contact.last_name}`;

            return ({
                key: contact.id,
                value: contact.id,
                text: name,
                content: (
                    <Person username={name} subtitleLabel='Job Title' email={contact?.job_title || ''} />
                ),
                pinned: contact.pinned
            });
        }),
        isReadingPrimaryContactOptions: state.account.isReadingContactsForPinning,

        contactSequenceTasks: state.sequence_tasks.selectedSequenceTasks,
        isReadingSequenceContactTasks: state.sequence_tasks.isReadingSequenceContactTasks,
        selectedSequenceId: state.sequence_tasks.selectedSequenceId,

        pipelineCategories: state.pipeline.categories.map(category => ({
            key: category.id,
            value: category.id,
            text: category.name,
            stages: category.stages?.map(stage => ({
                key: stage.id,
                value: stage.id,
                text: stage.name,
                sequence_id: stage.sequence_id
            })) || []
        }))
    }));

    useEffect(() => {
        dispatch(accountActions.readContactsForPinning(existing_account_id, { page: 1, limit: 100 }));
    }, [existing_account_id, dispatch, owner_id]);

    useEffect(() => {
        if (watchedOwnerId) {
            dispatch(campaignsAction.readPersonCampaignConverted({ person_id: watchedOwnerId, campaign_conversion: "customer" }));
            dispatch(sequenceTasksActions.readContactTasks(watchedOwnerId));
        }
    }, [watchedOwnerId, dispatch]);

    const isConvertingAccount = action === "convert-account";

    const onCreate = async (data) => {
        try {
            const account_id = await (async () => {
                if (!isContactLinked || isConvertingNewCsutomer) {
                    const accounts = await dispatch(accountActions.createAccount([
                        {
                            account_type: data.account_type,
                            name: data.name
                        }
                    ]));
                    return Number(accounts[0].id)
                }
                return Number(existing_account_id);
            })();

            if (!isConvertingAccount) {
                if (isConvertingNewCsutomer && pipeline_id) {
                    try {
                        await dispatch(quoteActions.updateQuoteAccountId(pipeline_id, { account_id }));
                    } catch (e) {
                        console.log(e);
                    }
                }
                if (account_id !== existing_account_id) {
                    await linkContactsToAccount(account_id, data.owner_id);
                }
            }

            if (!isContactLinked && !isConvertingNewCsutomer) {
                await dispatch(accountActions.updateAccount(account_id, {
                    primary_person_id: Number(data.owner_id)
                }))
            }

            const primaryContactWillBeReplaced = owner_id !== data.owner_id;
            if (primaryContactWillBeReplaced || (!isConvertingAccount && !current_primary_contact_id)) {
                await dispatch(accountActions.updateAccount(account_id, {
                    pinned: primaryContactOptions.filter(option => option.pinned && option.value !== Number(data.owner_id)).map(option => option.value),
                    primary_person_id: Number(data.owner_id)
                }))
            }

            const areSequenceTasksCompleted = contactSequenceTasks.length === 0 || contactSequenceTasks.every(task => task.status === 'complete' || task.status === 'canceled');
            const canApplyNewSequence = selectedSequenceId && data.stage ? await dispatch(contactSequenceActions.handleConvert(Number(data.owner_id))) : true;

            await dispatch(customersActions.createCustomer({
                account_id: account_id,
                owner_id: data.owner_id ? Number(data.owner_id) : null,
                campaign_id: data.campaign_converted,
                custom_data: {
                    fields: CustomGroupUtils.extractCustomerDetails(data)
                },
                ...(data.stage ? { pipeline_stage_id: data.stage, previous_sequence_complete: areSequenceTasksCompleted, sequence_conversion_removed: canApplyNewSequence } : {})
            }, pipeline_id ? { pipeline_id } : {}));
            if (data.owner_id && data.stage) {
                const category = pipelineCategories.find(category => category.value === Number(data.pipeline));
                const newStage = category?.stages.find(categoryStage => categoryStage.value === Number(data.stage));
                if (canApplyNewSequence && newStage?.sequence_id) {
                    await dispatch(sequencesActions.addToSequence({ contact_id: Number(data.owner_id) }, null, newStage?.sequence_id));
                }
            }
            if (data.owner_id) {
                await dispatch(contactActions.updateContact(Number(data.owner_id), {influence: 'decision maker'}));
            }
            Notification.alert('Successfully converted to customer!', STATUS_TYPES.SUCCESS);
            onClose();
            if (refresh) {
                return refresh(account_id);
            }
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to convert to customer', STATUS_TYPES.ERROR);
        }
    }

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const trigger = <Button floated='right' disabled={!isValid || isCreating || isReadingPrimaryContactOptions} primary onClick={isConvertingNewCsutomer ? () => { setIsConfirmationOpen(true); } : handleSubmit(onCreate)} loading={isCreating || isReadingPrimaryContactOptions}>Save</Button>;
    return [
        <Form loading={isCreating || isReadingSequenceContactTasks} disabled={isCreating} size={size} noValidate style={{ maxHeight: "17em", width: "30em" }}>
            {(!isContactLinked || isConvertingNewCsutomer) ? (
                <Form.Group widths={2}>
                    <Controller
                        name='account_type'
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Select
                                fluid
                                label='Account Type'
                                name={name}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                options={accountTypes}
                                selectOnBlur={false}
                                placeholder='Select Type'
                                required
                                error={error && error.message && {
                                    content: error.message,
                                    pointing: 'below'
                                }}
                            />
                        )}
                    />
                    <Controller
                        name='name'
                        control={control}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <Form.Input
                                fluid
                                label='Account Name'
                                onChange={onChange}
                                name={name}
                                value={value}
                                placeholder="Type Account Name"
                                required
                                error={error && error.message && {
                                    content: error.message,
                                    pointing: 'below'
                                }}
                            />
                        )}
                    />
                </Form.Group>
            ) : (
                isConvertingAccount && <Form.Group widths={'equal'}>
                    <Controller
                        name={'owner_id'}
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Dropdown
                                label={'Primary Contact'}
                                placeholder='Select Primary Contact'
                                selectOnBlur={false}
                                selection
                                options={primaryContactOptions}
                                value={value}
                                onChange={(_, { value: contact_id }) => {
                                    onChange({ target: { name, value: contact_id } });
                                }}
                                compact
                                clearable
                                loading={isReadingPrimaryContactOptions}
                                required
                                error={error?.message}
                            />
                        )}
                    />
                </Form.Group>
            )}
            <ConvertToCustomerFields control={control} columns={2} pipelineName={'pipeline'} stageName={'stage'} typeName={'customer_type'} isEmpty={watchedOwnerId === ''} />
            {isConvertingNewCsutomer && (
                <FullScreenModalPopUp
                    header={'Are you sure?'} 
                    subheader={'Account type cannot be changed'} 
                    isOpen={isConfirmationOpen}
                    onConfirm={handleSubmit(onCreate)}
                    onFormClose={() => setIsConfirmationOpen(false)}
                    closeIcon={false}
                />
            )}
        </Form>,
        isUpsellingFromQuote ? (
            <UpsellCustomerPopup 
                open={isUpsellingFromQuote}
                position={"bottom-center"}
                trigger={trigger}
            />
        ) : (
            trigger
        )
    ]
}

export default ConvertToCustomerForm;
