import React, { useState } from 'react';
import { Icon, Table as SemanticTable } from 'semantic-ui-react';
import { ClearableCheckboxDropdown, Table, TimestampFilters } from 'dyl-components';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { getData, onToggleTimeUnit, useRangeCalendar } from "shared/CustomDateRange/customDateRange";

const SortActions = ({ direction = false, onToggleSort = () => { } }) => {
    return (
        <div
            onClick={onToggleSort}
            style={{ display: "inline-flex", cursor: "pointer" }}
        >
            <Icon
                name="fa-solid fa-arrow-down-long"
                style={{
                    marginRight: -7,
                    opacity: !direction ? "100%" : "50%",
                }}
            />
            <Icon
                name="fa-solid fa-arrow-up-long"
                style={{ opacity: direction ? "100%" : "50%" }}
            />
        </div>
    );
};

const CustomerPipelinesHeader = ({ routeRoot }) => {

    const [params] = useSearchParams();
    const navigate = useNavigate();

    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        userAuthTimezone,
        dateRange,
        setDateRange,
    } = useRangeCalendar(params);

    const [createdOrder, setCreatedOrder] = useState(
        params.get("order_by") === "created" && params.get("order") === "asc"
    );

    const onDateRangeChange = (_, { value }) => {
        setDateRange(value);
    };

    const createdOrderFilter = () => {
        setCreatedOrder(!createdOrder);
        const query = new URLSearchParams(params);
        const order = !createdOrder ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", "created");
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    };

    const onStatusFilter = (_, { value }) => {
        const query = new URLSearchParams(params);
        if (!(value.includes('open') || value.includes('closed'))) {
            query.delete("status");
        } else {
            query.set("status", value.join(','));
        }
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    }

    return (
        <Table.CollapsibleRow isHeader>
            <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={3}>
                <span style={{ marginRight: 5 }}>Created</span>
                <TimestampFilters
                    timestamp={timestamp}
                    onToggleTimeUnit={(timestamp) =>
                        onToggleTimeUnit(
                            timestamp,
                            setTimestamp,
                            params,
                            navigate,
                            routeRoot
                        )
                    }
                    isCustomDateRangeOpen={isCustomDateRangeOpen}
                    onOpenCustomDateRange={() => {
                        setCustomDateRangeOpen(true);
                    }}
                    onCloseCustomDateRange={() => {
                        setCustomDateRangeOpen(false);
                    }}
                    dateRange={dateRange}
                    onDateRangeChange={onDateRangeChange}
                    getData={() =>
                        getData({
                            setCustomDateRangeOpen,
                            dateRange,
                            userAuthTimezone,
                            params,
                            navigate,
                            url: routeRoot,
                        })
                    }
                />

                <SortActions
                    direction={createdOrder}
                    onToggleSort={() => {
                        createdOrderFilter();
                    }}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='ModulesListTable__LargeFixedWidth' width={4}>
                Contact
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={3}>
                <ClearableCheckboxDropdown
                    text="Status"
                    name="status"
                    options={[
                        { key: "open", value: "open", text: "Open" },
                        { key: "closed", value: "closed", text: "Closed" }
                    ]}
                    onChange={(_, { value }) => {
                        onStatusFilter(_, { value });
                    }}
                    value={params.get("status")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={3}>
                Pipeline/Stage
            </SemanticTable.HeaderCell>
        </Table.CollapsibleRow>
    )
}

export default CustomerPipelinesHeader;
