const onSelectTemplateChange = (message, selectedTemplate) => {
    return {
        message,
        selectedTemplate,
        type: 'MESSAGE_EDITOR_TEMPLATE_CHANGE'
    }
}

const onSelectFile = (file) => {
    return {
        file,
        type: 'MESSAGE_EDITOR_SELECT_FILE'
    }
}

const onChangeMessage = (message) => {
    return {
        message,
        type: 'MESSAGE_EDITOR_CHANGE_MESSAGE'
    }
}

const onClearMessage = () => {
    return {
        type: 'MESSAGE_EDITOR_CLEAR_MESSAGE'
    }
}

export default {
    onSelectTemplateChange,
    onSelectFile,
    onChangeMessage,
    onClearMessage
}
