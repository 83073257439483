import React from 'react';
import { Table, Segment, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { UserAvatarList } from 'dyl-components';

import TeamActionDropdown from './TeamActionDropdown';

const memberLimit = 10;

const TeamsTable = ({
    isReadingTeams,
    userTeams,
    toggleShowMembers,
    toggleTeamDelete,
    onDeleteTeam,
    onEdit,
    expandedTeams,
    teamToDelete
}) => {
    if (isReadingTeams) {
        return (
            <Segment>
                <Loader active />
            </Segment>
        )
    }

    return (
        <Table className='TeamsTable' striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell style={{ width: '18%' }}>Team Name</Table.HeaderCell>
                    <Table.HeaderCell style={{ width: '72%' }}>Users</Table.HeaderCell>
                    <Table.HeaderCell style={{ width: '10%' }}>Actions</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {userTeams.map((userTeam, index) => {
                    const isShown = expandedTeams.includes(userTeam.id);
                    return (
                        <Table.Row key={userTeam.id}>
                            <Table.Cell><b>{userTeam.name}</b></Table.Cell>
                            <Table.Cell className='TeamsTable__TeamMembersCell'>
                                <UserAvatarList
                                    user_id={userTeam.id}
                                    usersLimit={memberLimit}
                                    usersList={userTeam.users}
                                    showAllUsersList={isShown}
                                    toggleShowAll={toggleShowMembers}
                                />
                            </Table.Cell>
                            <Table.Cell className='ActionCell'>
                                <TeamActionDropdown
                                    teamId={userTeam.id}
                                    teamIndex={index}
                                    isDeleteOpen={userTeam.id === teamToDelete}
                                    isTeamEmpty={userTeam.users.length === 0}
                                    toggleTeamDelete={toggleTeamDelete}
                                    onDeleteTeam={onDeleteTeam}
                                    onEdit={onEdit}
                                />
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
}

const mapStateToProps = state => ({
    isReadingTeams: state.teams.isReadingTeams
});

export default connect(mapStateToProps, null)(TeamsTable);
