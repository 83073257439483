import React, { useState, useEffect, useReducer, useRef, useCallback } from 'react';
import { Search, Form } from 'semantic-ui-react';

import Selections from './Selections';
import accountOptionsReducers from 'reducers/account_options';
import accountOptionsInitialState from 'reducers/account_options/state';

import './index.scss'
import accountOptionsActions from 'actions/account_options';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import { useDispatch } from 'react-redux';
import { LinkedAccount } from 'dyl-components';

const resultRenderer = (data) => (
    <LinkedAccount
        account={data.name}
        maxWidth={'9em'}
        subtitle={data._id && `ID: ${data._id}`}
        popup
    />
)

const SearchableMultiSelection = ({ selections, onSelect, onRemove }) => {
    const [state, dispatch] = useReducer(accountOptionsReducers, { ...accountOptionsInitialState });

    const { isReadingOptions, options: results } = state;

    const timeoutRef = useRef();

    const [searchValue, setSearchValue] = useState('');

    const apiDispatch = useDispatch();

    const handleSearchChange = useCallback((_, { value }) => {
        clearTimeout(timeoutRef.current);
        setSearchValue(value);

        dispatch({ type: getNamedAction(ACTION_NAMES.ACCOUNT_OPTIONS, CRUD_ACTION_TYPES.READ_REQUEST) });

        timeoutRef.current = setTimeout(async () => {
            try {
                const response = await apiDispatch(accountOptionsActions.read({ search: value, hasMaster: false, account_type: "business" }));
                dispatch({ type: getNamedAction(ACTION_NAMES.ACCOUNT_OPTIONS, CRUD_ACTION_TYPES.READ_SUCCESS), data: response });
            } catch {
                dispatch({ type: getNamedAction(ACTION_NAMES.ACCOUNT_OPTIONS, CRUD_ACTION_TYPES.READ_FAILURE) });
            }
        }, 300);
    }, [apiDispatch]);

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
        }
    }, []);

    return (
        <div className='SearchableMultiSelection'>
            <Form.Group>
                <Form.Field width={8}>
                    <label>Nest Existing Business Account(s)</label>
                    <Search
                        className='SearchableMultiSelection__SearchBar'
                        placeholder='Search for Account Name or ID'
                        onSearchChange={handleSearchChange}
                        onResultSelect={(_, { result }) => {
                            setSearchValue('');
                            onSelect(result);
                        }}
                        resultRenderer={resultRenderer}
                        value={searchValue}
                        results={results.map(result => ({
                            name: result.name,
                            _id: result.id
                        })).filter(({ _id }) => (
                            selections.findIndex(selection => selection._id === _id) === -1
                        ))}
                        fluid
                        loading={isReadingOptions}
                    />
                </Form.Field>
            </Form.Group>
            <Selections selections={selections} onSelectionDelete={onRemove} />
        </div>
    );
}

export default SearchableMultiSelection;
