import React from "react";
import { Icon, Table } from "dyl-components";
import { Table as SemanticTable } from "semantic-ui-react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useSelector } from "react-redux";
import SelectAllCheckbox from "shared/SelectAllCheckbox";
import { KEY_ACCOUNT_OWNER, KEY_CREATED, KEY_CREATED_BY, KEY_DUE_ON, KEY_INVOICE_STATUS, KEY_NUMBER_PRODUCTS, KEY_PAYMENT_TYPE, KEY_PRODUCTS, KEY_SUBTOTAL, KEY_TERM } from "shared/constants/INVOICE_REPORT_COLUMNS";

const SortActions = ({ direction = false, onToggleSort = () => {} }) => {
    return (
        <div
            onClick={onToggleSort}
            style={{ display: "inline-flex", cursor: "pointer" }}
        >
            <Icon
                name="fa-solid fa-arrow-down-long"
                style={{
                    marginRight: -7,
                    opacity: !direction ? "100%" : "50%",
                }}
            />
            <Icon
                name="fa-solid fa-arrow-up-long"
                style={{ opacity: direction ? "100%" : "50%" }}
            />
        </div>
    );
};

export const InvoicesHeader = ({ isColumnHidden }) => {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const createdOrder =
        params.get("order_by") === "created" && params.get("order") === "asc";
    const dueOnOrder =
        params.get("order_by") === "due_on" &&
        params.get("order") === "asc";

    const createdOrderFilter = () => {
        const query = new URLSearchParams(params);
        const order = !createdOrder ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", "created");
        const query_string = query.toString();
        navigate(`/settings/invoices${query_string ? `?${query_string}` : ""}`);
    };

    const dueOnOrderFilter = () => {
        const query = new URLSearchParams(params);
        const order = !dueOnOrder ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", "due_on");
        const query_string = query.toString();
        navigate(`/settings/invoices${query_string ? `?${query_string}` : ""}`);
    };

    const { count, invoices = [] } = useSelector((state) => {
        return {
            count: 0,
            invoices: [],
        };
    });

    return (
        <Table.Row>
            <SemanticTable.HeaderCell
                width={1}
                className="InvoicesTable__SmallFixedWidth"
            >
                <SelectAllCheckbox
                    count={count}
                    data={invoices}
                    type={"quotes and orders"}
                    idProperty={"invoice_id"}
                />
            </SemanticTable.HeaderCell>
            {!isColumnHidden(KEY_CREATED) && (
                <SemanticTable.HeaderCell
                    className="InvoicesTable__MediumFixedWidth"
                    width={2}
                >
                    Created
                    <SortActions
                        direction={createdOrder}
                        onToggleSort={() => {
                            createdOrderFilter();
                        }}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_DUE_ON) && (
                <SemanticTable.HeaderCell
                    className="InvoicesTable__MediumFixedWidth"
                    width={2}
                >
                    Due On
                    <SortActions
                        direction={dueOnOrder}
                        onToggleSort={() => {
                            dueOnOrderFilter();
                        }}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_TERM) && (
                <SemanticTable.HeaderCell
                    className="InvoicesTable__MediumFixedWidth"
                    width={2}
                >
                    Term
                </SemanticTable.HeaderCell>
            )}
            <SemanticTable.HeaderCell
                className="InvoicesTable__LargeFixedWidth"
                width={3}
            >
                Invoice ID
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                className="InvoicesTable__LargeFixedWidth"
                width={3}
            >
                Order ID
            </SemanticTable.HeaderCell>
            {!isColumnHidden(KEY_ACCOUNT_OWNER) && (
                <SemanticTable.HeaderCell
                    className="InvoicesTable__MediumFixedWidth"
                    width={2}
                >
                    Account Owner
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CREATED_BY) && (
                <SemanticTable.HeaderCell
                    className="InvoicesTable__MediumFixedWidth"
                    width={2}
                >
                    Created By
                </SemanticTable.HeaderCell>
            )}
            <SemanticTable.HeaderCell
                className="InvoicesTable__LargeFixedWidth"
                width={4}
            >
                Account Name
            </SemanticTable.HeaderCell>
            {!isColumnHidden(KEY_PRODUCTS) && (
                <SemanticTable.HeaderCell
                    className="InvoicesTable__MediumFixedWidth"
                    width={2}
                >
                    Products
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_NUMBER_PRODUCTS) && (
                <SemanticTable.HeaderCell
                    className="InvoicesTable__MediumFixedWidth"
                    width={2}
                >
                    # Products
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_SUBTOTAL) && (
                <SemanticTable.HeaderCell
                    className="InvoicesTable__MediumFixedWidth"
                    width={2}
                >
                    Subtotal
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_PAYMENT_TYPE) && (
                <SemanticTable.HeaderCell
                    className="InvoicesTable__MediumFixedWidth"
                    width={2}
                >
                    Payment Type
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_INVOICE_STATUS) && (
                <SemanticTable.HeaderCell
                    className="InvoicesTable__MediumFixedWidth"
                    width={3}
                >
                    Invoice Status
                </SemanticTable.HeaderCell>
            )}
        </Table.Row>
    );
};
