import React from 'react';
import { Icon } from 'semantic-ui-react';

const SortActions = ({ direction = false, onToggleSort = () => {} }) => {
    return (
        <div
            onClick={onToggleSort}
            style={{ display: "inline-flex", cursor: "pointer" }}
        >
            <Icon
                name="fa-solid fa-arrow-down-long"
                style={{marginRight: -7, opacity: !direction ? "100%" : "50%"}}
            />
            <Icon
                name="fa-solid fa-arrow-up-long"
                style={{ opacity: direction ? "100%" : "50%" }}
            />
        </div>
    );
};

export default SortActions;