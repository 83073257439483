import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { Modal, ModulesFilter } from 'dyl-components';

import AddLeadModal from "shared/modals/AddLeadModal";

import {
    KEY_CREATED, KEY_CREATED_BY, KEY_OWNED_BY,
    KEY_LAST_MODIFIED, KEY_PRODUCT_INTEREST,
    KEY_LAST_MODIFIED_BY, KEY_LEAD_PRIORITY,
    KEY_LOST_REASON,
    KEY_OUTCOME, KEY_LEAD_ID, KEY_LEAD_SCORE, KEY_CONVERT,
    KEY_CONTACT_NAME, KEY_LEAD_RATING, KEY_ACTIONS, KEY_ACCOUNT,
    KEY_MASTER_SECONDARY, KEY_STAGE, KEY_CAMPAIGN_SOURCE
} from 'shared/constants/MODULE_COLUMNS';

const COLUMN_ROWS = [
    [
        { value: KEY_CREATED, label: "Created" },
        { value: KEY_LOST_REASON, label: "Lost Reason/Type" },
        { value: KEY_CREATED_BY, label: "Created by" }
    ],
    [
        { value: KEY_OWNED_BY, label: "Owner" },
        { value: KEY_LAST_MODIFIED, label: "Last Modified" },
        { value: KEY_PRODUCT_INTEREST, label: "Product Interests" }
    ],
    [
        { value: KEY_LAST_MODIFIED_BY, label: "Last Modified By" },
        { value: KEY_LEAD_PRIORITY, label: "Priority" },
        { value: KEY_OUTCOME, label: "Outcome" }
    ],
    [
        { value: KEY_LEAD_ID, label: "Lead ID", isForcedChecked: true },
        { value: KEY_LEAD_SCORE, label: "Score" },
        { value: KEY_CONVERT, label: "Convert" }
    ],
    [
        { value: KEY_CONTACT_NAME, label: "Contact Name", isForcedChecked: true },
        { value: KEY_LEAD_RATING, label: "Rating" },
        { value: KEY_ACTIONS, label: "Actions" }
    ],
    [
        { value: KEY_ACCOUNT, label: "Account" },
        { value: KEY_MASTER_SECONDARY, label: "Master/Secondary Source" },
        { value: KEY_STAGE, label: "Stage" },
    ],
    [
        { value: KEY_CAMPAIGN_SOURCE, label: "Campaign Converted" }
    ],
];

const LeadsToolbar = ({ search, onSearchSubmit, cancelFunction, onChangeSearch, isColumnHidden, onToggleColumn }) => {
    const { isReadingLeads, isReadingDisplay } = useSelector(state => ({
        isReadingLeads: state.leads.isReadingLeads
    }));

    const [isAddLeadModalOpen, setIsAddLeadModalOpen] = useState(false);

    const onOpenAddLeadModal = () => {
        setIsAddLeadModalOpen(true);
    }

    const onCloseAddLeadModal = () => {
        setIsAddLeadModalOpen(false);
    }

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row columns='equal' verticalAlign='middle'>
                    <Grid.Column width={5}>
                        <ModulesFilter
                            isLoading={isReadingLeads || isReadingDisplay}
                            search={search}
                            onSearchSubmit={onSearchSubmit}
                            cancelFunction={cancelFunction}
                            onChangeSearch={onChangeSearch}
                            columnRows={COLUMN_ROWS}
                            columnCount={3}
                            isColumnHidden={isColumnHidden}
                            onToggleColumn={onToggleColumn}
                        />
                    </Grid.Column>
                    <Grid.Column width={3} floated='right'>
                        <Button floated='right' className='ModulesListToolbar__NewModulesListButton' onClick={onOpenAddLeadModal}>
                            <Icon className='plus' /> New Lead
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Modal open={isAddLeadModalOpen} onClose={onCloseAddLeadModal}>
                <AddLeadModal onOpen={() => { return Promise.resolve(); }} onClose={onCloseAddLeadModal} />
            </Modal>
        </React.Fragment>
    );
}

export default LeadsToolbar;
