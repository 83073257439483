import { DateTimeUtils, VALIDATORS, yup } from "dyl-components";
import applyCurrencySchema from "../products/applyCurrencySchema";

const feeSchema = {
    name: VALIDATORS.SIMPLE_INPUT()
        .noemoji()
        .minlength(2)
        .maxlength(60)
        .no_whitespace_only()
        .required("This field is required"),
    description: VALIDATORS.DESCRIPTION(),
    active_date: yup
        .string()
        .nullable(true)
        .test("valid_date", "Invalid date", (value) => {
            return value
                ? DateTimeUtils.isValid(value, DateTimeUtils.DATE_FORMAT)
                : true;
        }),
    percent: yup.boolean(),
    amount: yup.number().required("This field is required").when('percent', {
        is: false,
        then: schema => applyCurrencySchema(schema),
        otherwise: schema => schema
            .typeError("Please enter a valid percentage")
            .min(0).max(100),
    })
};

export const getDefaultValuesFromReadingAFee = (fee) => {
    return {
        name: fee?.name || "",
        description: fee?.description || "",
        status: fee?.status || null,
        active_date: fee?.active_date || null,
        amount: fee.amount || '',
        percent: fee.percent || false,
        products: fee.products.map(product => ({ value: product.id + '/C', name: product.name }))
    };
};

export const formatFeeDetails = ({ products, ...data }) => {
    return {
        ...data,
        products: products.map(product => Number(product.value.split('/')[0]))
    }
}

export default feeSchema;
