import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function contactsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CONTACT_LOCATION, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingLocations: true, location: [], count: 0 };
        case getNamedAction(ACTION_NAMES.CONTACT_LOCATION, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingLocations: false, location: action.data.data || [], count: action.data.count || 0 };
        case getNamedAction(ACTION_NAMES.CONTACT_LOCATION, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingLocations: false, location: [], count: 0 };

        case getNamedAction(ACTION_NAMES.CONTACT_LOCATION, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingContactLocation: true };
        case getNamedAction(ACTION_NAMES.CONTACT_LOCATION, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingContactLocation: false };
        case getNamedAction(ACTION_NAMES.CONTACT_LOCATION, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingContactLocation: false };

        case getNamedAction(ACTION_NAMES.CONTACT_LOCATION, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, contactLocationBeingUpdated: action.subId };
        case getNamedAction(ACTION_NAMES.CONTACT_LOCATION, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, contactLocationBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.CONTACT_LOCATION, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, contactLocationBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.CONTACT_LOCATION, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, contactLocationBeingDeleted: action.subId };
        case getNamedAction(ACTION_NAMES.CONTACT_LOCATION, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, contactLocationBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.CONTACT_LOCATION, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, contactLocationBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.CONTACT_LOCATION_OPTIONS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, locationOptions: { ...state.locationOptions, [action.id]: { isReading: true, options: [] } } };
        case getNamedAction(ACTION_NAMES.CONTACT_LOCATION_OPTIONS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, locationOptions: { ...state.locationOptions, [action.id]: { isReading: false, options: action.data.data || [] } } };
        case getNamedAction(ACTION_NAMES.CONTACT_LOCATION_OPTIONS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, locationOptions: { ...state.locationOptions, [action.id]: { isReading: false, options: [] } } };

        default:
            return state;
    }
}

export default contactsReducer;
