import React, { useContext } from "react";
import { Button } from "semantic-ui-react";

import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";

const AddQuote = ({ isOpportunity }) => {
    const { onOpenAddProducts } = useContext(QuoteBuilderContext);

    return (
        <div>
            <Button
                type="button"
                onClick={() => {
                    onOpenAddProducts();
                }}
                color="blue"
                fluid
                style={{ background: "#2635DE" }}
            >
                {isOpportunity ? "Add Quote" : "Product Catalog"}
            </Button>
        </div>
    );
};

export default AddQuote;
