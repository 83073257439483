import { createContext, useState } from 'react';

export const FormsContext = createContext();

const FormsProvider = ({ children }) => {
    const [statusRef, setStatusRef] = useState(null);
    const [accountConversion, setAccountConversion] = useState(null);
    const [conversionModalOpen, setConversionModalOpen] = useState('');
    const [isConversionFormModified, setIsConversionFormModified] = useState(false);
    const [isLostFormModified, setIsLostFormModified] = useState(false);

    const onStatusDropdownSelect = (conversionModalOpen, statusRef, account) => {
        setAccountConversion(account);
        setStatusRef(statusRef);
        setConversionModalOpen(conversionModalOpen);
    }

    const onCloseConversion = () => {
        setConversionModalOpen('');
        setIsConversionFormModified(false);
        setIsLostFormModified(false);
        setAccountConversion(null);
        setStatusRef(null);
    }

    const [actionPopupRef, setActionPopupRef] = useState(null);
    const [deleteMasterAccount, setDeleteMasterAccount] = useState({});
    const [isDeleteMasterAccountPopupOpen, setDeleteMasterAccountPopupOpen] = useState(false);

    const [unlinkAccount, setUnlinkAccount] = useState({});
    const [isUnlinkAccountPopupOpen, setUnlinkAccountPopupOpen] = useState(false);

    const [linkAccount, setLinkAccount] = useState({});
    const [isLinkMasterAccountModalOpen, setIsLinkMasterAccountModalOpen] = useState(false);

    const onLinkSelect = (account_id, master_account) => {
        setLinkAccount({ account_id, master_account });
        setIsLinkMasterAccountModalOpen(true);
    }

    const onLinkFormClose = () => {
        setLinkAccount({});
        setIsLinkMasterAccountModalOpen(false);
    }

    const closeLinkMasterAccountModal = () => {
        setIsLinkMasterAccountModalOpen(false);
    }

    const onDeleteMasterAccountSelect = (accountPopupRef, deleteMasterAccount) => {
        setActionPopupRef(accountPopupRef);
        setDeleteMasterAccountPopupOpen(true);
        setDeleteMasterAccount(deleteMasterAccount);
    }

    const onDeleteMasterAccountFormSubmit = () => {
        setActionPopupRef(null);
        setDeleteMasterAccountPopupOpen(false);
    }

    const onDeleteMasterAccountFormClose = () => {
        setDeleteMasterAccountPopupOpen(false);
        setActionPopupRef(null);
        setDeleteMasterAccount({});
    }

    const onUnlinkSelect = (accountPopupRef, unlinkAccount) => {
        setUnlinkAccountPopupOpen(true);
        setActionPopupRef(accountPopupRef);
        setUnlinkAccount(unlinkAccount);
    }

    const onUnlinkFormSubmit = () => {
        setActionPopupRef(null);
        setUnlinkAccountPopupOpen(false);
    }

    const onUnlinkFormClose = () => {
        setActionPopupRef(null);
        setUnlinkAccount({});
    }

    return (
        <FormsContext.Provider value={{
            statusRef,
            accountConversion,
            conversionModalOpen,
            isConversionFormModified,
            setIsConversionFormModified,
            isLostFormModified,
            setIsLostFormModified,
            onStatusDropdownSelect,
            onCloseConversion,

            actionPopupRef,
            setActionPopupRef,
            deleteMasterAccount,
            isDeleteMasterAccountPopupOpen,
            unlinkAccount,
            isUnlinkAccountPopupOpen,
            setUnlinkAccountPopupOpen,
            linkAccount,
            isLinkMasterAccountModalOpen,
            onLinkSelect,
            onLinkFormClose,
            closeLinkMasterAccountModal,
            onDeleteMasterAccountSelect,
            onDeleteMasterAccountFormSubmit,
            onDeleteMasterAccountFormClose,
            onUnlinkSelect,
            onUnlinkFormSubmit,
            onUnlinkFormClose
        }}>
            {children}
        </FormsContext.Provider>
    );
}

export default FormsProvider;
