import React from 'react';

import { Table } from 'dyl-components';

import GroupEntries from './GroupEntries';
import GroupFields from './GroupFields';

const GroupRow = ({
    toggleGroup,
    isOpenGroup,
    group,
    onEditField,
    parent_group,
    refresh,

    onCloseEditGroupForm,
    openEditGroupForm,
    groupBeingEdited,
    isEditGroupFormModified,
    onModifyEditGroupForm,

    isExpanded,
    onToggleField,
    otherSubrowContent = [],

    isSubrow,
    isSubgroup,
    nested,
    isParentArchived
}) => (
    <Table.CollapsibleRow
        isSubrow={isSubrow}
        onToggle={() => { toggleGroup(group.id) }}
        collapsed={!isOpenGroup(group.id)}
        subrowContent={[
            <GroupFields
                isParentArchived={group.deleted}
                fields={group.fields?.data || []}
                isExpanded={isExpanded}
                onEditField={onEditField}
                onToggleField={onToggleField}
                parent_group={group.id}
                refresh={refresh}
                nested={nested}
            />,
            ...otherSubrowContent
        ]}
    >
        <GroupEntries
            group={group}
            groupBeingEdited={groupBeingEdited}
            isEditGroupFormModified={isEditGroupFormModified}
            onCloseEditGroupForm={onCloseEditGroupForm}
            onModifyEditGroupForm={onModifyEditGroupForm}
            openEditGroupForm={openEditGroupForm}
            parent_group={parent_group}
            refresh={refresh}
            isSubgroup={isSubgroup}
            isParentArchived={isParentArchived}
        />
    </Table.CollapsibleRow>
)

export default GroupRow;
