const initialState = {
    leads: [],
    count: 0,
    allMarked: false,
    queryParameters: {
        page: 1,
        limit: 25
    },
    allPagesMarked: false
}

export default initialState;
