import { DateTimeUtils, Notification, STATUS_TYPES, generateResolver } from "dyl-components";
import Sequence from "shared/Sequence";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import sequencesActions from 'actions/sequences';
import { StringUtils } from "utils";
import { sequenceValidationSchema } from "shared/schemas/sequenceValidationSchema";

const SequenceAdd = () => {
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            description: '',
            actions: [
                {
                    type: 'call', deliver: '', data: {}
                }
            ],
            settings_sequence_access: { territories: [], teams: [] },
            saturday: false,
            sunday: false,
            conversion_remove: false,
            reassign_remove: false
        },
        resolver: generateResolver(sequenceValidationSchema)
    })

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const onCreate = async (data) => {
        const { name, description, saturday, sunday, conversion_remove, reassign_remove, settings_sequence_access, actions } = data;
        const { teams, territories } = settings_sequence_access;
        try {
            await dispatch(sequencesActions.create({
                name,
                description,
                saturday,
                sunday,
                conversion_remove,
                reassign_remove,
                tasks: actions.map(action => ({
                    data: action.data,
                    interval: {
                        amount: action.timeamount,
                        type: action.deliver
                    },
                    task_type: StringUtils.toSentenceCase(action.type),
                    time: action.time ? DateTimeUtils.convertTimeToSeconds(action.time, DateTimeUtils.TIME_FORMAT) : 0
                })),
                teams: teams.map(team => team.id),
                territories: territories.map(territory => territory.id)
            }))
            Notification.alert('Successfully created sequence!', STATUS_TYPES.SUCCESS);
            methods.reset();
            navigate('/settings/sequences', { replace: true, state: { saved: true } });
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to create sequence', STATUS_TYPES.ERROR);
        }
    }

    const { isCreating } = useSelector(state => state.sequences);

    return (
        <FormProvider {...methods}>
            <Sequence loading={isCreating} onSave={onCreate} />
        </FormProvider>
    )
}

export default SequenceAdd;
