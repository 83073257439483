import React from 'react';
import { Modal, Button, STATUS_TYPES, DatesRangeInput } from 'dyl-components';
import { DateTimeUtils } from 'dyl-components';

const CustomRangeDateModal = ({ open, onClose, onDateRangeChange, dateRange, getData, applyDisabled=false, cancelDisabled=false }) => (
    <Modal open={open} onClose={onClose}>
        <Modal.Header>
            Custom Range
        </Modal.Header>
        <Modal.Content>

            <DatesRangeInput
                name="dateRange"
                inline
                initialDate={DateTimeUtils.getCurrentDate()}
                minDate={'01-01-1970'}
                onChange={onDateRangeChange}
                value={dateRange}
            />

        </Modal.Content>
        <Modal.Actions>
            <Button
                onClick={onClose}
                disabled={cancelDisabled}
                status={STATUS_TYPES.DANGER}
            >
                Cancel
            </Button>
            <Button
                onClick={getData}
                disabled={applyDisabled}
                status={STATUS_TYPES.PRIMARY}
            >
                Apply
            </Button>
        </Modal.Actions>
    </Modal>
)

export default CustomRangeDateModal;
