import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Form, Grid } from 'semantic-ui-react';
import { CustomGroupUtils } from 'utils';

const HouseholdStandardData = ({
    isEditing,
    control,

    textAlign = 'right'
}) => {
    const { custom_data, type_options } = useSelector(state => {
        const type_options = state.account.account?.custom_data?.fields?.find(field => field.field_name === 'account_household_type')?.options?.data?.map(option => ({
            key: option.id,
            value: option.id,
            text: option.value
        })) || [];
        return ({
            custom_data: CustomGroupUtils.groupAndFlatten(state.account.account?.custom_data || {}),
            type_options: type_options
        });
    });
    const fields = custom_data.fields;

    const type = type_options.find(option => option.value === fields?.account_household_type)?.text || ''

    return (
        <Grid className='RecordInfo'>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Type
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        type || 'None'
                    ) : (
                        <Controller
                            name='account_household_type'
                            control={control}
                            render={({ field: { name, onChange, value } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Select household type'
                                    selectOnBlur={false}
                                    options={type_options}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Members
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        fields?.members || 0
                    ) : (
                        <Controller
                            name={`members`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Children
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        fields?.children || 0
                    ) : (
                        <Controller
                            name={`number_of_children`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Monthly Income
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        fields?.monthly_income || 0
                    ) : (
                        <Controller
                            name={`monthly_income`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Annual Income
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        fields?.annual_income || 0
                    ) : (
                        <Controller
                        name={`annual_income`}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                            <Form.Input
                                name={name}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            />
                        )}
                    />
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default HouseholdStandardData;

