import React from 'react';
import { Image } from 'semantic-ui-react';
import './index.scss';

const DisplayPicture = ({ src, circular = true }) => (
    <center>
        <Image
            src={src}
            circular={circular}
            className='DisplayPicture'
        />
    </center>
)

export default DisplayPicture;
