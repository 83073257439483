import React from 'react';
import { GridTimeline, CollapsibleContent, DateTimeUtils } from 'dyl-components';
import { Checkbox, Grid } from 'semantic-ui-react';
import {  StringUtils } from 'utils';

const STATUS = {
    'Upcoming': 'upcoming',
    'Received': 'received',
    'Sent': 'sent',
    'Reached': 'sent',
    'Not available': 'missed',
    'Do not call': 'missed',
    'Voicemail': 'upcoming',
    'Missed': 'missed',
    'Completed': 'completed'
}

const getStatus = (completed, status, direction, occurred) => {

    if (completed) {
        return 'Completed';
    }

    if (status) {
        return StringUtils.capitalize(status);
    }

    if (!DateTimeUtils.isBeforeOrOnCurrentDate(DateTimeUtils.formatEpoch(occurred, DateTimeUtils.DATETIME_FORMAT), DateTimeUtils.DATETIME_FORMAT)) {
        return 'Upcoming';
    }

    return direction === 'inbound' ? 'Received' : 'Sent';
}

const Timeline = ({ items, expanded, onToggleActivity }) => (
    <div className='ActivitiesTab__Timeline'>
        <GridTimeline
            expanded={expanded}
            items={items.map(item => {
                const date = `${DateTimeUtils.formatEpoch(item.occurred, 'ddd M/D')}`.toUpperCase();
                const time = DateTimeUtils.formatEpoch(item.occurred);
                return ({
                    date,
                    time,
                    otherControls: <Checkbox checked={item.completed} onClick={(e, { checked }) => onToggleActivity(e, { checked, type: item.type, id: item.id })} className='ActivitiesTab__otherControls' />,
                    itemComponent: <Grid className='ActivitiesTab__Info'>
                        <Grid.Row columns='equal' style={{ padding: 0 }}>
                            <Grid.Column verticalAlign='middle'>
                                <span className={`Info__name Info__name--${item.completed ? 'selected' : ''}`}>
                                    {StringUtils.capitalize(item.type)}
                                </span>
                                <div className={`Info__description Info__description--${item.completed ? 'selected' : ''}`}>
                                    <CollapsibleContent width={'80%'} content={item.messages || item.note} />
                                </div>

                            </Grid.Column>
                            <Grid.Column width={2} verticalAlign='middle'>
                                <span className={`Info__status Info__status--${STATUS[getStatus(item.completed, item.status, item.direction, item.occurred)]}`}>
                                    {getStatus(item.completed, item.status, item.direction, item.occurred)}
                                </span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>,
                    markerColor: '#af5fe6',
                    selected: item.completed
                });
            })} />
    </div>
);

export default Timeline;
