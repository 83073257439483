import React from 'react';
import { EditableLabels, Notification, STATUS_TYPES } from 'dyl-components';
import tasksActions from 'actions/tasks';
import taskActions from 'actions/task';
import { connect } from 'react-redux';
import task_typesActions from 'actions/task_types';

class TaskLabels extends React.Component {

    state = {
        task_labels: [],
        key: 'TASK_LABELS'
    }

    componentDidMount() {
        Promise.all([
            this.props.onReadTaskLabels(),
            this.props.onReadTaskTypes(),
        ]).then(() => {
            const { task_labels } = this.props;
            this.setState({
                task_labels,
                key: ''
            }, () => {
                this.setState({
                    key: 'TASK_LABELS'
                })
            })
        })
    }

    onSave = async (task_labels) => {
        try {
            await Promise.all([
                this.onSaveTaskLabels(task_labels.filter(({ id }) => !id).map(({ value }) =>
                ({
                    name: value[0].replace(/\s+/g, ' ').trim(),
                    task_type_ids: value[1].map(task_type_id => ({ task_type_id }))
                })))
            ])
            Notification.alert(`Successfully saved the task labels!`, STATUS_TYPES.SUCCESS);
            await this.props.onReadTaskLabels();
            this.setState({
                task_labels: this.props.task_labels,
                key: ''
            }, () => {
                this.setState({
                    key: 'TASK_LABELS'
                })
            })
        } catch (e) {
            console.log(e);
        }
    }

    onSaveTaskLabels = async (task_labels) => {
        if (task_labels.length > 0) {
            try {
                await this.props.onAddTaskLabels(task_labels);
            } catch (e) {
                console.log(e);
                Notification.alert(`Failed to create task label${task_labels.length > 1 ? 's' : ''}`, STATUS_TYPES.ERROR);
                return Promise.reject();
            }
        } else {
            return Promise.resolve();
        }
    }

    onUpdate = async (id, name) => {
        try {
            const { id: new_id } = await this.props.onUpdateTaskLabel(id, name);
            Notification.alert(`Successfully updated the task label!`, STATUS_TYPES.SUCCESS);
            return Promise.resolve(new_id);
        } catch (error) {
            if (error.Code === 409) {
                Notification.alert(`Task label is already existing`, STATUS_TYPES.ERROR);
            } else {
                Notification.alert(`Failed to update task label`, STATUS_TYPES.ERROR);
            }
            return Promise.reject();
        }
    }

    onRemove = async (id) => {
        try {
            await this.props.onDeleteTaskLabel(id);
            Notification.alert(`Successfully deleted the task label!`, STATUS_TYPES.SUCCESS);
            return Promise.resolve();
        } catch (e) {
            console.log(e);
            Notification.alert(`Failed to delete task label`, STATUS_TYPES.ERROR);
            return Promise.reject();
        }
    }


    onCheckDuplicate = async (value) => {
        try {
            let { id, name } = await this.props.onCheckTaskLabelDuplicate(value);
            if (id && name) {
                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        } catch (e) {
            console.log(e);
            return Promise.reject();
        }
    }

    getCorrectedTaskTypeName = (task_type_name) => {
        if (task_type_name?.includes('Task')) {
            const processed_name = task_type_name.split(' ');
            processed_name.pop();
            task_type_name = processed_name.join(' ');
        }
        if (task_type_name === 'To Do') {
            task_type_name = 'To-Do'
        }
        return task_type_name;
    }

    getTaskTypeId = (task_type_name) => {
        // TODO: remove this once all tasks are deleted and 
        // unneeded task types are deleted as well
        task_type_name = this.getCorrectedTaskTypeName(task_type_name);

        return this.props.taskTypes.find(({ name }) => name === task_type_name)?.id;
    }

    generateTaskTypeOptions = () => {
        let taskTypes = ['To Do', 'Call', 'Text', 'Email'];
        let options = [];
        taskTypes.forEach(taskType => {
            let value = this.getTaskTypeId(taskType);
            options.push({ key: value, value, text: taskType })
        })
        return options;
    }

    render() {
        const fields = [
            { header: 'Task Labels', type: 'input', placeholder: 'Type task label', defaultValue: '', editable: true },
            {
                header: 'Task Types', type: 'checkbox_dropdown', placeholder: 'Select task type(s)', defaultValue: [],
                options: this.generateTaskTypeOptions(), editable: false
            }
        ];

        return (
            <EditableLabels
                key={this.state.key}
                name='task_labels'
                addLabel='Add Task Label'
                info='Edit will reflect on all tasks. Delete will remove the label from all future tasks.'
                fields={fields}
                items={this.state.task_labels}
                onSave={this.onSave}
                onUpdate={this.onUpdate}
                onRemove={this.onRemove}
                isLoading={this.props.isLoading}
                onCheckDuplicate={this.onCheckDuplicate}
            />
        )
    }
}


const mapStateToProps = state => {
    const { task_labels } = state.tasks; 

    return {
        task_labels: (task_labels) ? task_labels.map(({ name, id, task_types }) =>
        ({
            id, value: [name, task_types.map(({ id }) => id).valueOf()]
        })) : null,
        taskTypes: state.task_types.taskTypes,
        isLoading: state.tasks.isReadingTaskLabels || state.tasks.isAddingTaskLabels || state.task.isDeletingTaskLabel || state.task.isUpdatingTaskLabel
    }
}

const mapDispatchToProps = dispatch => ({
    onReadTaskLabels: () => {
        return dispatch(tasksActions.readTaskLabels());
    },
    onAddTaskLabels: (task_labels) => {
        return dispatch(tasksActions.addTaskLabels(task_labels));
    },
    onUpdateTaskLabel: (task_label_id, name) => {
        return dispatch(taskActions.updateTaskLabel(task_label_id, { name }));
    },
    onDeleteTaskLabel: (task_label_id) => {
        return dispatch(taskActions.deleteTaskLabel(task_label_id));
    },
    onCheckTaskLabelDuplicate: (label) => {
        return dispatch(taskActions.checkTaskLabelDuplicate(null, { label }))
    },
    onReadTaskTypes: () => {
        return dispatch(task_typesActions.readTaskTypes());
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskLabels);
