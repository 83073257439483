import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TableWithHeader } from 'dyl-components';

import feeActions from 'actions/product_fees';

import Toolbar from './subcomponents/Toolbar';
import Table from './subcomponents/Table';

import BulkActionsProvider, { BulkActionsContext } from 'shared/context/BulkActionsProvider';

import './index.scss';

const FeesContainer = ({ type }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const [, setSelectedFees, , setAreFeesInAllPagesSelected] = useContext(BulkActionsContext);

    useEffect(() => {
        const queryParameters = {
            page: 1,
            limit: 25,
            ...Object.fromEntries(params)
        }

        dispatch(feeActions.readFee({ ...queryParameters, type }));
        setSelectedFees([]);
        setAreFeesInAllPagesSelected(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, type]);

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`/settings/product-catalog-quotes-invoices/${type === "tax" ? "taxes" : "fees"}${query_string ? `?${query_string}` : ''}`);
    }

    return (
        <div className='FeesPage'>
            <TableWithHeader
                header={(
                    <Toolbar type={type} />
                )}
                table={(
                    <Table type={type} onPageChange={onPageChange} />
                )}
            />
        </div>
    );
}

const Fees = ({ type }) => {
    return (
        <BulkActionsProvider>
            <FeesContainer type={type} />
        </BulkActionsProvider>
    );
}

export default Fees;
