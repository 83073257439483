import React from 'react';
import { Table, ClearableCheckboxDropdown } from 'dyl-components';
import { Table as SemanticTable } from 'semantic-ui-react';
import { useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    secondary_sources: [
        {
            key: 0, value: 0, text: 'Uncategorized'
        },
        ...state.lead_provider.secondary_sources.map((source) => ({
            key: source.id,
            value: source.id,
            text: source.name
        }))
    ],
    isReading: state.lead_provider.isReadingProviders,
    isReadingSecondarySources: state.lead_provider.isReadingSecondarySources,
});

export const DataProvidersTableHeader = connect(mapStateToProps)(({
    onFilter,
    isReading,
    isReadingSecondarySources,
    secondary_sources = [],
}) => {
    const [params] = useSearchParams();

    return (
        <Table.CollapsibleRow isHeader>
            <SemanticTable.HeaderCell width={3}>Provider Name</SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell>
                <ClearableCheckboxDropdown
                    text='Data Source Category'
                    name='category'
                    onChange={(_, { value }) => { onFilter(_, { name: 'category', value }) }}
                    value={params.get('category')?.split(',')}
                    options={[
                        {
                            key: 'uncategorized', value: 'uncategorized', text: 'Uncategorized'
                        },
                        {
                            key: 'lead_vendor', value: 'Lead Vendor', text: 'Lead Vendor'
                        },
                        {
                            key: 'data_broker', value: 'Data Broker', text: 'Data Broker'
                        }
                    ]}
                    disabled={isReading}
                    loading={isReading}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell>
                <ClearableCheckboxDropdown
                    text='Master Source'
                    name='master_source'
                    onChange={(_, { value }) => {
                        onFilter(_, { name: 'master_source', value })
                    }
                    }
                    value={params.get('master_source')?.split(',')}
                    options={[
                        {
                            key: 'uncategorized', value: 'uncategorized', text: 'Uncategorized'
                        },
                        {
                            key: 'events', value: 'Events', text: 'Events'
                        },
                        {
                            key: 'social', value: 'Social', text: 'Social'
                        },
                        {
                            key: 'advertising', value: 'Advertising', text: 'Advertising'
                        },
                        {
                            key: 'direct', value: 'Direct', text: 'Direct'
                        },
                        {
                            key: 'media', value: 'Media', text: 'Media'
                        },
                        {
                            key: 'outbound', value: 'Outbound', text: 'Outbound'
                        },
                    ]}
                    disabled={isReading}
                    loading={isReading}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell>
                <ClearableCheckboxDropdown
                    text='Secondary Source'
                    name='secondary_source_id'
                    onChange={(_, { value }) => { onFilter(_, { name: 'secondary_source_id', value }) }}
                    value={params.get('secondary_source_id')?.split(',').map(id => parseInt(id))}
                    options={secondary_sources}
                    disabled={isReadingSecondarySources}
                    loading={isReadingSecondarySources}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell></SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={1}>Sources</SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={1}>Actions</SemanticTable.HeaderCell>
        </Table.CollapsibleRow>
    );
})
