import { LinkedAccount } from "dyl-components";
import { useContext, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Form, Grid, Input, Popup } from "semantic-ui-react";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import OrderAddress from "./OrderAddress";
import Location from "shared/Location";
import OrderStatusField from "../OrderStatusField";
import accountActions from "actions/account";

const LocationDisplay = ({ location }) => {
    if (location?.state) {
        return <Location location={location} />;
    }
    return (
        <div className="Location">
            <div className="Location__line">No Default Location</div>
        </div>
    );
};

const OrderInformation = ({ account_id, disabled }) => {
    const { control } = useFormContext();
    const { quoteBuilderConfig } = useContext(QuoteBuilderContext);
    const order = useSelector((state) => state.order.order);
    const { field: nameField } = useController({
        name: "order_name",
        control,
    });
    const { field: orderStatusField } = useController({
        name: "order_status",
        control,
    });
    const { field: billingAddressField } = useController({
        name: "billing_address",
        control,
    });
    const { field: shippingAddressField } = useController({
        name: "shipping_address",
        control,
    });
    const [addressBeingModified, setAddressBeingModified] = useState(null);
    const onModifyBillingAddress = () => {
        setAddressBeingModified("billing");
    };
    const onModifyShippingAddress = () => {
        setAddressBeingModified("shipping");
    };
    const onCloseAdressForm = () => {
        setAddressBeingModified(null);
    };

    const hasBillingAddress = () => {
        const { address, phone, email } = billingAddressField.value;
        const { street, city, state, zip } = address || {};
        return phone && email && street && city && state && zip;
    }

    const dispatch = useDispatch()

    return (
        <Grid>
            <Grid.Row columns={6}>
                <Grid.Column>
                    <Form.Input
                        label="Order Name"
                        name={nameField.name}
                        value={nameField.value}
                        onChange={(_, { name, value }) => {
                            nameField.onChange({ target: { name, value } });
                        }}
                        disabled={disabled}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Form.Field control="div" label="Order ID">
                        <Input disabled value={quoteBuilderConfig?.id} />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column>
                    <Form.Field
                        control={OrderStatusField}
                        label="Status"
                        required
                        value={orderStatusField.value}
                        onChange={(_, { value }) => {
                            orderStatusField.onChange({ target: { name: orderStatusField.name, value } });
                        }}
                        disabled={disabled || (order?.order_status && order?.order_status !== "draft")}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={6}>
                <Grid.Column>
                    <Form.Field label="Customer Name" required control="div">
                        <Dropdown
                            placeholder="Select Contact"
                            selectOnBlur={false}
                            selection
                            options={[]}
                            value={null}
                            fluid
                            required
                            disabled
                            {...(order?.account
                                ? {
                                      text: (
                                          <LinkedAccount
                                              account={order?.account?.name}
                                              maxWidth={"8.25em"}
                                          />
                                      ),
                                  }
                                : {})}
                        />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column>
                    <Form.Field control="div" required label="Billing Address">
                        <Popup
                            on="click"
                            onOpen={onModifyBillingAddress}
                            disabled={disabled}
                            trigger={
                                <div>
                                    <LocationDisplay
                                        location={
                                            billingAddressField.value?.address
                                        }
                                    />
                                </div>
                            }
                            content={
                                <OrderAddress
                                    type="billing"
                                    account_id={account_id}
                                    name={billingAddressField.name}
                                    defaultValues={billingAddressField.value}
                                    onSave={(value) => {
                                        billingAddressField.onChange({
                                            target: {
                                                name: billingAddressField.name,
                                                value,
                                            },
                                        });
                                        onCloseAdressForm();
                                    }}
                                />
                            }
                            position="bottom center"
                            open={addressBeingModified === "billing"}
                            onClose={onCloseAdressForm}
                        />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column stretched>
                    <Form.Field control="div" required label="Shipping Address" disabled={!hasBillingAddress()}>
                        <Popup
                            on="click"
                            disabled={disabled || !hasBillingAddress()}
                            onOpen={onModifyShippingAddress}
                            trigger={
                                <div>
                                    <LocationDisplay
                                        location={shippingAddressField.value
                                            ?.address}
                                    />
                                </div>
                            }
                            content={
                                <OrderAddress
                                    type="shipping"
                                    name={shippingAddressField.name}
                                    account_id={account_id}
                                    defaultValues={shippingAddressField.value}
                                    onSave={(value) => {
                                        shippingAddressField.onChange({
                                            target: {
                                                name: shippingAddressField.name,
                                                value,
                                            },
                                        });
                                        dispatch(accountActions.readContactsForPinning(account_id));
                                        onCloseAdressForm();
                                    }}
                                    valueToCopy={billingAddressField.value}
                                />
                            }
                            position="bottom center"
                            open={addressBeingModified === "shipping"}
                            onClose={onCloseAdressForm}
                        />
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default OrderInformation;
