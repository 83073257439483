import React from 'react';
import { Icon } from 'semantic-ui-react';

const SelectionTag = ({ selection, onSelectionDelete }) => {
    return (
        <div className='Selections__SelectionTag'>
            {selection.name}
            <Icon
                className='fa-solid fa-xmark SelectionTag__DeleteIcon'
                size='small'
                onClick={() => {onSelectionDelete(selection._id)}}
            />
        </div>
    );
}

export default SelectionTag;
