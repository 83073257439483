import moduleInformationActions from 'actions/module_information';
import { DateTimeUtils, generateResolver, NestedDropdown, Notification, STATUS_TYPES, yup } from 'dyl-components';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Form, Grid, Header, Icon, Menu, Segment, Transition } from 'semantic-ui-react';
import { CustomGroupUtils, StringUtils } from 'utils';
import LeadFields from './LeadFields';
import OpportunityFields from './OpportunityFields';

import customFieldsGroupActions from "actions/custom_fields_group";
import productInterestActions from "actions/product_interest";

import './InfoSection.scss';
import CustomData from 'shared/CustomData';
import contactActions from 'actions/contact';

const Controls = ({ disabled, onSave, loading }) => {
    return (
        <Menu fixed='bottom' floated='right'>
            <Menu.Item>
                <Button loading={loading} floated='right' onClick={onSave} color='primary' disabled={disabled || loading}>Save</Button>
            </Menu.Item>
        </Menu>
    )
}

const CustomDataSection = ({ module, control, isUpdating, disabled }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const toggleCollapsed = () => {
        setIsCollapsed(!isCollapsed);
    }

    const hasCustomGroups = useSelector(state => state.module_information.pipeline?.custom_data.children?.filter(child => !child.standard)?.length);

    return (
        <Segment loading={isUpdating} color='blue' className='CustomData'>
            <Grid stackable>
                <Grid.Row style={{ paddingBottom: `${isCollapsed ? '1' : '0'}em` }}>
                    <Grid.Column>
                        <Header as='h5' color='blue'>{StringUtils.capitalize(module)} Custom Data <span style={{ float: 'right' }}>
                            <Icon
                                className='CustomData__toggler'
                                size='small'
                                link
                                color='blue'
                                inverted
                                onClick={toggleCollapsed}
                                circular
                                name={`chevron ${isCollapsed ? 'right' : 'down'}`}
                            />
                        </span> </Header>
                    </Grid.Column>
                </Grid.Row>
                <Transition visible={!isCollapsed} unmountOnHide animation='slide down' duration='150' style={{ paddingTop: 0 }}>
                    <Grid.Row>
                        <Grid.Column>
                            <Grid>
                                <Grid.Row style={{ marginTop: '0.5em' }}>
                                    <Grid.Column>
                                        {hasCustomGroups ? (
                                            <CustomData
                                                control={control}
                                                isEditing={!disabled}
                                                numberOfColumns={3}
                                                disabled={disabled}
                                            />) :
                                            <i>No Custom Data Created. Add a Custom Data by going to <Link to={'/settings/data-customization/' + module} state= {{activeTab: 'Custom'}}>Data Customization</Link>.</i>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Transition>
            </Grid>
        </Segment>
    )
}

const LeadInfoSection = ({ ownerField, disabled, refresh, isUpdating, lost_reason_fields }) => {
    const { pipeline } = useSelector(state => ({
        pipeline: state.module_information.pipeline
    }));

    const { master_source, secondary_source, custom_data, id, campaign_converted, product_interest } = pipeline;

    const field_values = CustomGroupUtils.groupAndFlatten(custom_data).fields;
    const custom_groups = custom_data.children?.filter(child => !child.standard);


    const { formState: { isDirty, isValid }, control, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            type: field_values?.type || '',
            product_interests: product_interest.map(element => element.id),
            expected_revenue: field_values?.expected_revenue || '',
            close_probability: field_values?.close_probability || '',
            master_source: master_source || '',
            secondary_source: secondary_source?.id || '',
            priority: field_values?.priority || null,
            rating: field_values?.rating || null,
            score: field_values?.score || '',
            acquisition_cost: field_values?.acquisition_cost || '',
            additional_cost: field_values?.additional_cost || '',
            children: custom_groups,
            campaign_converted: campaign_converted?.id || null
        },
        resolver: generateResolver({
            children: CustomGroupUtils.generateValidationSchema(custom_groups)
        })
    });

    const dispatch = useDispatch();

    const onUpdate = async (data) => {
        const custom_data = {
            fields: CustomGroupUtils.extractLeadDetails(data),
            children: CustomGroupUtils.groupAndFlatten({ children: data.children }).children
        };

        const { master_source, secondary_source: secondary_source_id, campaign_converted, product_interests } = data;

        try {
            await dispatch(moduleInformationActions.update(id, {
                custom_data,
                master_source: master_source ? master_source : '',
                secondary_source_id: secondary_source_id ? secondary_source_id : 0,
                campaign_id: campaign_converted || 0,
                product_interest_ids: product_interests
            }));
            Notification.alert(`Successfully updated lead`, STATUS_TYPES.SUCCESS);
            if (refresh) {
                refresh();
            }
        } catch (e) {
            console.log(e);
            Notification.alert(`Failed to update lead`, STATUS_TYPES.ERROR);
        }
    }

    return (
        <React.Fragment>
            <Form loading={isUpdating} noValidate className='InfoSection'>
                <LeadFields lost_reason_fields={lost_reason_fields} control={control} disabled={disabled} ownerField={ownerField} />
                <CustomDataSection
                    control={control}
                    isUpdating={isUpdating}
                    disabled={disabled}
                    module={'lead'}
                />
            </Form>
            <Controls loading={isUpdating} disabled={!isDirty || !isValid || disabled} onSave={handleSubmit(onUpdate)} />
        </React.Fragment>
    )
}

const OpportunityInfoSection = ({ ownerField, disabled, refresh, isUpdating, lost_reason_fields }) => {
    const { pipeline } = useSelector(state => ({
        pipeline: state.module_information.pipeline
    }));

    const { master_source, secondary_source, custom_data, id, name, campaign_converted } = pipeline;

    const field_values = CustomGroupUtils.groupAndFlatten(custom_data).fields;
    const custom_groups = custom_data.children?.filter(child => !child.standard);

    const { formState: { isDirty, isValid }, control, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            opportunity_name: name || '',

            type: field_values?.type || '',
            description: field_values?.description || '',
            pain_need_notes: field_values?.pain_need_notes || '',

            sales_strategy_notes: field_values?.sales_strategy_notes || '',
            confidence_level: field_values?.confidence_level || null,
            close_probability: field_values?.close_probability || '',

            forecast_amount: field_values?.forecast_amount || null,
            expected_close_date: field_values?.expected_close_date || '',
            master_source: master_source || '',
            secondary_source: secondary_source?.id || '',

            next_step_date: field_values?.next_step_date || '',
            next_step: field_values?.next_step || '',

            children: custom_groups,

            campaign_converted: campaign_converted?.id || null
        },
        resolver: generateResolver({
            opportunity_name: yup.string().no_whitespace_only().required('This field is required'),
            expected_close_date: yup.string().test("valid_date", "Invalid date", (value => {
                return value ? DateTimeUtils.isValid(value, DateTimeUtils.DATE_FORMAT) : true;
            })),
            next_step_date: yup.string().test("valid_date", "Invalid date", (value => {
                return value ? DateTimeUtils.isValid(value, DateTimeUtils.DATE_FORMAT) : true;
            })),
            children: CustomGroupUtils.generateValidationSchema(custom_groups)
        })
    });

    const dispatch = useDispatch();

    const onUpdate = async (data) => {
        const custom_data = {
            fields: CustomGroupUtils.extractOpportunityDetails(data),
            children: CustomGroupUtils.groupAndFlatten({ children: data.children }).children
        };

        const { master_source, secondary_source: secondary_source_id, opportunity_name: name, campaign_converted } = data;

        try {
            await dispatch(moduleInformationActions.update(id, {
                custom_data,
                master_source: master_source ? master_source : '',
                secondary_source_id: secondary_source_id ? secondary_source_id : 0,
                name,
                campaign_id: campaign_converted || 0
            }));
            Notification.alert(`Successfully updated opportunity`, STATUS_TYPES.SUCCESS);
            if (refresh) {
                refresh();
            }
        } catch (e) {
            console.log(e);
            Notification.alert(`Failed to update opportunity`, STATUS_TYPES.ERROR);
        }
    }

    return (
        <React.Fragment>
            <Form loading={isUpdating} noValidate className='InfoSection'>
                <OpportunityFields lost_reason_fields={lost_reason_fields} ownerField={ownerField} control={control} disabled={disabled} />
                <CustomDataSection
                    control={control}
                    isUpdating={isUpdating}
                    disabled={disabled}
                    module={'opportunity'}
                />
            </Form>
            <Controls loading={isUpdating} disabled={!isDirty || !isValid || disabled} onSave={handleSubmit(onUpdate)} />
        </React.Fragment>
    )
}

const InfoSection = () => {
    const { module: account_stage, active, id: pipeline_id, isUpdating, close_reason, close_type, close_reason_notes, isMovingModule } = useSelector(state => ({
        module: state.module_information.pipeline?.module,
        active: state.module_information.pipeline?.active,
        id: state.module_information.pipeline?.id,
        isUpdating: state.module_information.pipelineBeingUpdated,
        close_reason: state.module_information.pipeline?.close_reason_id,
        close_type: state.module_information.pipeline?.close_type_id,
        close_reason_notes: state.module_information.pipeline?.lost_reason_note,
        isMovingModule: state.module_information.pipelineBeingMoved || state.contacts.isCreating || state.account.isLinkingContactsToAccount
    }));

    const disabled = !active || isMovingModule;

    const ownerField = (
        <Form.Field>
            <label>Owner</label>
            <Form.Select
                placeholder='Select Owner'
                disabled={disabled}
            />
        </Form.Field>
    )

    const navigate = useNavigate();
    const [params] = useSearchParams();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(customFieldsGroupActions.getModalStandardGroup({ group_name: account_stage }));
        dispatch(productInterestActions.readProductInterestOptions());
    }, [account_stage, dispatch]);

    const { contact_id } = useParams();

    const refresh = () => {
        const query = new URLSearchParams(params);
        query.set('module_id', pipeline_id);
        const query_string = query.toString();
        navigate(`${location.pathname}?module_id=${pipeline_id}`);
        navigate(`${location.pathname}${query_string ? `?${query_string}` : ''}`);
        dispatch(moduleInformationActions.getOne(pipeline_id));
        if (contact_id) {
            dispatch(contactActions.readContact(contact_id));
        }
    }

    const { isReadingCloseReasons } = useSelector(state => state.pipeline);

    const lostReasons = useSelector(state => state.pipeline.close_reasons.map(reason => ({
        key: reason.id,
        value: reason.id,
        text: reason.name,
        options: (
            reason.close_types.map(type => ({
                key: type.id,
                value: type.id,
                text: type.name
            })) || []
        )
    })) || [])

    const lost_reason_fields = active ? [] : [
        <Form.Field>
            <label>Lost Reason/Type</label>
            <Form.Field
                control={NestedDropdown}
                allowedToSelectParentOnly
                child_value={close_type}
                parent_value={close_reason}
                loading={isReadingCloseReasons}
                nested_options={lostReasons}
                disabled
                placeholder='Select Lost Reason/Type'
                display_parent
                selection
                required
            />
        </Form.Field>,
        <Form.Field>
            <label>Lost Reason Notes</label>
            <Form.Input
                placeholder='Notes'
                value={close_reason_notes}
                disabled
            />
        </Form.Field>
    ]

    if (account_stage === 'lead') {
        return (
            <LeadInfoSection lost_reason_fields={lost_reason_fields} refresh={refresh} disabled={disabled} ownerField={ownerField} isUpdating={isUpdating} />
        )
    }

    if (account_stage === 'opportunity') {
        return (
            <OpportunityInfoSection lost_reason_fields={lost_reason_fields} refresh={refresh} disabled={disabled} ownerField={ownerField} isUpdating={isUpdating} />
        )
    }

    return null;
}

export default InfoSection;
