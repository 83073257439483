import ACTION_NAMES from 'actions/ACTION_NAMES';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import initialState from './initialState';

function callQueuesReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CALL_QUEUES, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCallQueues: true };
        case getNamedAction(ACTION_NAMES.CALL_QUEUES, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingCallQueues: false, call_queues: action.data.data || [], count: action.data.count || 0 };
        case getNamedAction(ACTION_NAMES.CALL_QUEUES, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCallQueues: false };

        case getNamedAction(ACTION_NAMES.CALL_QUEUES, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingCallQueues: true };
        case getNamedAction(ACTION_NAMES.CALL_QUEUES, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingCallQueues: false };
        case getNamedAction(ACTION_NAMES.CALL_QUEUES, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingCallQueues: false };
        default:
            return state;
    }
}

export default callQueuesReducer;
