import React from 'react';
import { Header } from 'semantic-ui-react';
import { Table } from 'dyl-components';
import { StringUtils } from 'utils';
import Avatar from 'react-avatar';

const HistoryColumns = ({ activity }) => (
    <React.Fragment>
        <Table.Cell>
            <Header as='h4'>
                {StringUtils.capitalize(activity.activity_type)}
                <Header.Subheader>
                    <span
                    >
                        {StringUtils.capitalize(activity.activity_event)}
                    </span>
                </Header.Subheader>
            </Header>
        </Table.Cell>
        <Table.Cell>
            {activity.activity_description}
        </Table.Cell>
        <Table.Cell>

        </Table.Cell>
        <Table.Cell>
            {activity.user ?
                <Avatar
                    name={activity.user}
                    round
                    size='3em'
                    maxInitials={2} /> : ''}
        </Table.Cell>
        <Table.Cell>

        </Table.Cell>
    </React.Fragment>
);

export default HistoryColumns;
