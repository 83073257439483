export default class ArrayUtils {
    static reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    static getLastModifiedElement = (firstArray, secondArray) => {
        if (firstArray.length < secondArray.length) {
            let temp = firstArray.slice(0);
            firstArray = secondArray.slice(0);
            secondArray = temp;
        } else {
            let temp = secondArray.slice(0);
            secondArray = firstArray.slice(0);
            secondArray = temp;
        }
        return firstArray.filter(elementOfFirstArray => secondArray.indexOf(elementOfFirstArray) < 0)[0];
    }

    static getUniqueElements = (array = []) => {
        return array.filter((value, index, self) => {
            return self.indexOf(value) === index
        })
    }

    static groupBy = (array = [], property, return_object = false) => {
        const groupedByProperty = array.reduce((groups, item) => {
            const group = (groups[item[property]] || []);
            group.push(item);
            groups[item[property]] = group;
            return groups;
        }, {});
        if (return_object) {
            return groupedByProperty;
        }
        return Object.keys(groupedByProperty).map(group => ({
            group,
            data: groupedByProperty[group]
        }));
    }
}
