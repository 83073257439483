import React, { Component } from 'react';
import usersActions from 'actions/users';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { RecordUtils } from 'utils';

class UsersField extends Component {
    componentDidMount() {
        this.props.onReadUsers();
    }

    render() {
        const DropdownComponent = this.props.styled ? Form.Select : Form.Dropdown;
        return (
            <DropdownComponent 
                onChange={this.props.onChange}
                options={this.props.users}
                name={this.props.name}
                loading={this.props.isReadingUsers}
                value={this.props.value}
                multiple={this.props.multiple}
                scrolling
                label={this.props.label}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    users: RecordUtils.generateUserOptions(state.users.users),
    isReadingUsers: state.users.isReadingUsers
})

const mapDispatchToProps = dispatch => ({
    onReadUsers: () => {
        dispatch(usersActions.readUsers());
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersField);

