import React from "react";
import { Icon, Input, Button } from 'semantic-ui-react';

const ShareOptionFields = ({ label, icon = '', isFormActive, value }) => {

    const disableInputCopy = e => !isFormActive && e.preventDefault();

    return (
        <div className='WebForm__ShareOptionField'>
            <div className='ShareOptionField__Label'><Icon circular className={`ShareOptionField__Icon ${icon}`} /> {label}</div>
            <Input
                disabled
                className='ShareOptionField__Input'
                value={value || ''}
                onCopy={ disableInputCopy }
            /> 
            <Button 
                disabled={!isFormActive} 
                color={isFormActive ? 'blue' : 'gray'}
                onClick={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(value);
                }}
            >
                Copy
            </Button>
        </div>
    );
}

export default ShareOptionFields;
