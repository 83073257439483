import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import { Select } from 'semantic-ui-react';

import usersActions from 'actions/users';
import { useDispatch } from 'react-redux';
import { LinkedAccount } from 'dyl-components';
import usersReducers from "reducers/users";
import usersInitialState from "reducers/users/initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

const UserField = ({
    name,
    value,
    onChange,
    error
}) => {
    const dispatch = useDispatch();

    const [state, usersSearchDispatch] = useReducer(usersReducers, { ...usersInitialState });

    const { isReadingUsers, userOptions: results } = state;

    const timeoutRef = useRef();

    const handleSearchChange = useCallback((_, { searchQuery: value }) => {
        clearTimeout(timeoutRef.current);

        usersSearchDispatch({ type: getNamedAction(ACTION_NAMES.USER_OPTIONS, CRUD_ACTION_TYPES.READ_REQUEST) });

        timeoutRef.current = setTimeout(async () => {
            try {
                const response = await dispatch(usersActions.readUserOptions({ search: value?.toLowerCase()?.trim() }));
                usersSearchDispatch({ type: getNamedAction(ACTION_NAMES.USER_OPTIONS, CRUD_ACTION_TYPES.READ_SUCCESS), data: response });
            } catch {
                usersSearchDispatch({ type: getNamedAction(ACTION_NAMES.USER_OPTIONS, CRUD_ACTION_TYPES.READ_FAILURE) });
            }
        }, 1000);
    }, [dispatch]);

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
        }
    }, []);

    useEffect(() => {
        usersSearchDispatch({ type: getNamedAction(ACTION_NAMES.USER_OPTIONS, CRUD_ACTION_TYPES.READ_REQUEST) });
        dispatch(usersActions.readUserOptions({ user_id: Number(value) })).then(response => {
            usersSearchDispatch({ type: getNamedAction(ACTION_NAMES.USER_OPTIONS, CRUD_ACTION_TYPES.READ_SUCCESS), data: response });
        }).catch(() => {
            usersSearchDispatch({ type: getNamedAction(ACTION_NAMES.USER_OPTIONS, CRUD_ACTION_TYPES.READ_FAILURE) });
        });
    }, [value, dispatch])

    const existingUserOptions = results.map(option => {
        const { first_name, last_name, user_id, email } = option;
        const name = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : "Unknown Name";
        return ({
            key: user_id,
            value: user_id,
            text: name,
            content: (
                <LinkedAccount
                    account={name}
                    maxWidth={'15em'}
                    subtitle={email}
                    popup />
            )
        });
    });

    const selectedUser = existingUserOptions.find(option => Number(option.value) === Number(value));

    return (
        <Select
            placeholder='Search Users'
            name={name}
            value={value}
            error={error}
            onChange={onChange}
            options={existingUserOptions}
            {...(value && selectedUser) ? {
                text: selectedUser.content
            } : {}}
            search={!selectedUser}
            selectOnBlur={false}
            clearable
            onSearchChange={handleSearchChange}
            loading={isReadingUsers}
        />
    );
}

export default UserField;
