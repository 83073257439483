import React, { useEffect, useState } from 'react';
import { TableWithHeader } from 'dyl-components';
import { Header, Pagination, Segment } from 'semantic-ui-react';
import { ReportsAndLogsTable } from './subcomponents/ReportsAndLogsTable';
import { ReportsAndLogsToolbar } from './subcomponents/ReportsAndLogsToolbar';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';

import leadIngestionActions from 'actions/lead_ingestion';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DataLogUtils } from 'utils';

const LIMIT = 25;

export const ReportsAndLogs = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {posts={}, isReadingPosts=false, isReadingProviderRecordNames, providerRecordNames=[]} = useSelector(state => state.lead_ingestion);
    const { data=[], count, rejected_count, partial_count } = posts;
    const [params] = useSearchParams();

    const providerNames = (providers) => {
        return providers.map((provider)=>({ 
            key: provider.id,
            value: provider.id,
            text: provider.name,
        }))
    }

    const recordNames = (providers) => {
        let recordNames = [];
        providers.forEach((provider)=>{
            const mappings = provider?.mappings;
            if(mappings.length !== 0){
                mappings.forEach((mapping)=>{
                    const mappedNames = {
                        key: mapping.id,
                        value: mapping.id,
                        text: mapping.name
                    }
                    if(mapping.length !== 0){
                        recordNames.push(mappedNames);
                    }
                })
            }
        })
        return recordNames;
    }

    const getIds = (names) => {
        return names.map((name) => name.key);
    }
        
    const [filters, setFilters] = useState({
        status: ['Accepted','Rejected','Partial','Duplicate'],
        type: ['email', 'post'],
        error_type: DataLogUtils.getAllErrorCodes(),
        recordName: getIds(recordNames(providerRecordNames)) || [],
        providerName: getIds(providerNames(providerRecordNames)) || []
    });

    const onFilter = (_, { name, value }, paramName) => {
        setFilters({
            ...filters,
            [name]: value
        });
        const queryParams = new URLSearchParams(params);
        if(value.length !==0){
            queryParams.set(paramName, value.join(','));
        } else {
            queryParams.delete(paramName);
        }
        const queryString = queryParams.toString();
        navigate(`/settings/data-integration-logs${queryString ? `?${queryString}` : ''}`);
    }

    const onPageChange = (_, { activePage }) => {
        const queryParams = new URLSearchParams(params);
        queryParams.set('page', activePage);
        const queryString = queryParams.toString();
        navigate(`/settings/data-integration-logs${queryString ? `?${queryString}` : ''}`);
    }

    const onSort = (order) => {
        const queryParams = new URLSearchParams(params);
        queryParams.set('order', order);
        const queryString = queryParams.toString();
        navigate(`/settings/data-integration-logs${queryString ? `?${queryString}` : ''}`);
    }
    useEffect(() => {
        dispatch(leadIngestionActions.readAllLeadIngestion(Object.fromEntries(params)));
        if(providerRecordNames.length === 0){
            dispatch(leadIngestionActions.readAllProviderRecordNames({limit:100}));        
        }
    }, [dispatch, params, providerRecordNames]);

    useEffect(()=>{
        setFilters({
            status: ['Accepted','Rejected','Partial', 'Duplicate'],
            type: ['email', 'post'],
            error_type: DataLogUtils.getAllErrorCodes(),
            recordName: getIds(recordNames(providerRecordNames)),
            providerName: getIds(providerNames(providerRecordNames)),
        })
    }, [ providerRecordNames ])
    
    return (
        <div className='ReportsAndLogsPanel'>
            <TableWithHeader
                header={(
                    <ReportsAndLogsToolbar rejected={rejected_count} partial={partial_count} onFilter={onFilter}/>
                )}
                table={(
                    <Segment.Group>
                        <Segment style={{ padding: 0 }}>
                            <ReportsAndLogsTable
                                posts={[...data]}
                                isReadingPosts={isReadingPosts}
                                onFilter={onFilter}
                                filters={filters}
                                count={count}
                                onSort={onSort}
                                providerNames={providerNames(providerRecordNames)}
                                recordNames={recordNames(providerRecordNames)}
                                isReadingProviderRecordNames={isReadingProviderRecordNames}
                            />
                        </Segment>
                        {!isReadingPosts && count === 0 &&
                            <Header as='h3' textAlign='center' style={{ marginTop: 30 }}>
                                No results found
                            </Header>
                        }
                        {!isReadingPosts && count !== 0 &&
                            <Segment textAlign='right'>
                                <Pagination
                                    boundaryRange={0}
                                    activePage={params.get('page') || 1}
                                    ellipsisItem={null}
                                    siblingRange={2}
                                    totalPages={Math.ceil(count / LIMIT)}
                                    onPageChange={onPageChange}
                                    disabled={isReadingPosts}
                                />
                            </Segment>
                        }
                    </Segment.Group>
                )}
            />
        </div>
    )
}
