import React from 'react';
import { Input, Button, Grid, Divider } from 'semantic-ui-react';

import './MessageList.scss';

class ListExampleDivided extends React.Component{
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    formClassName = () => {
        const { selectedItem, id } = this.props;

        if(selectedItem === id){
            return 'MessageList__item MessageList__item--active';
        }else{
            return 'MessageList__item';
        }
    }

    render(){
        const { onItemClick, id } = this.props;

        return(
            <React.Fragment>
                <Grid onClick={() => onItemClick(id)} className={this.formClassName()}>
                    <Grid.Row columns={2}>
                        <Grid.Column textAlign='left'>
                            <div>
                                <strong>march 27 test</strong>
                            </div>
                            <div>
                                Hello
                            </div>
                        </Grid.Column>
                        <Grid.Column textAlign='right'>
                            Apr 22, 2019 6:35 PM
                        </Grid.Column>
                    </Grid.Row>  
                </Grid>
                <Divider />
            </React.Fragment>
        )
    }
}

export default class MessageList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null
        }
    }

    onItemClick = (id) => {
        this.setState({
            selectedItem: id
        });
    }

    render() {
        const { selectedItem } = this.state;
        
        return (
            <Grid className='MessageList'>
                <Grid.Row>
                    <Grid.Column width={14}>
                        <Input icon='search' iconPosition='left' placeholder='Search' fluid />
                    </Grid.Column>
                    <Grid.Column width={2} textAlign='right'>
                        <Button basic icon='search'></Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className='MessageList__items'>
                    <Grid.Column>
                        <ListExampleDivided onItemClick={this.onItemClick} id={1} selectedItem={selectedItem} />
                        <ListExampleDivided onItemClick={this.onItemClick} id={2} selectedItem={selectedItem} />
                        <ListExampleDivided onItemClick={this.onItemClick} id={3} selectedItem={selectedItem} />
                        <ListExampleDivided onItemClick={this.onItemClick} id={4} selectedItem={selectedItem} />
                        <ListExampleDivided onItemClick={this.onItemClick} id={5} selectedItem={selectedItem} />
                        <ListExampleDivided onItemClick={this.onItemClick} id={6} selectedItem={selectedItem} />
                        <ListExampleDivided onItemClick={this.onItemClick} id={7} selectedItem={selectedItem} />
                        <ListExampleDivided onItemClick={this.onItemClick} id={8} selectedItem={selectedItem} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}
