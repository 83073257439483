import { DateTimeUtils } from 'dyl-components';
import React, { useEffect } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import TaskForm from 'shared/NewTasksTab/subcomponents/TaskForm';
import useTaskForm from 'utils/useTaskForm';

const EmailTaskBody = ({ activeMessage }) => {
    const { watch, control, trigger, loadTask } = useTaskForm({ isContact: false, isModal: false });
    
    const {
        id = '',
        activity = '',
        completed = true,
        task_type,
        ts,
        assigned_to = {},
        assigned_by = {},
        attachments = {},
        task_label = {},
    } = activeMessage;

    const completion_time = completed ? DateTimeUtils.formatEpoch(activity, DateTimeUtils.WORD_DATE_FORMAT) : '';
    const date = DateTimeUtils.formatEpoch(ts, DateTimeUtils.WORD_DATE_FORMAT);
    const time = DateTimeUtils.formatEpoch(ts, DateTimeUtils.TIME_FORMAT);
    const { first_name = '', last_name = '', email: assigner_email, user_id: assigner_id } = assigned_by || { email: '' }
    const { content: description, content_type, direction, subject } = activeMessage.task_meta
    const taskData = {
        ...activeMessage,
        completion_time,
        content_type,
        description,
        direction,
        subject,
        attachments: (attachments?.data || []),
        id,
        label: task_label?.id || null,
        label_name: task_label?.name || '',
        date,
        time,
        task_type: task_type?.name || 'raw email',
        assigner: `${first_name} ${last_name}`,
        assigner_id,
        assigner_email,
        organizer: assigned_by?.username || `none`,
        organizer_id: assigned_by?.user_id || 'none',
        organizer_email: assigned_by?.email || 0,
        conference_line: 'none given',
        pin: 'no PIN',
        phone_number: 'no Number',
        assignee: assigned_to?.username || 'none',
        assignee_email: assigned_to?.email || 'none',
        assignee_id: assigned_to?.user_id || 'none',
        searchValue: [],
        watch,
        control,
        trigger
    };

    useEffect(() => {
        loadTask(taskData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Grid.Column className="EmailTab__TaskForm__Existing">
        <TaskForm
            {...taskData}
            isAllowedToModify={false}
            task_labels={[]}
            refresh={() => { console.log(`|+|+| Refresh on task heppened`) }}
            taskTypes={[
                {
                    key: 'Email', value: 'Email', text: (
                        <div>
                            <Icon className='fas fa-circle-envelope' color='blue' /> Email
                        </div>
                    )
                }
            ]}

        />
    </Grid.Column>;
};

export default EmailTaskBody;
