import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Header, Segment, Pagination } from 'semantic-ui-react';
import GroupsToolbar from './subcomponents/GroupsToolbar'
import GroupsHeader from './subcomponents/GroupsHeader'
import GroupsRow from './subcomponents/GroupsRow'
import './index.scss';
import { EmptyListPlaceholder, Notification, STATUS_TYPES, Table, TableLoader } from 'dyl-components';
import { useDispatch, useSelector } from 'react-redux';
import groupsActions from 'actions/groups';
import { useNavigate, useSearchParams } from 'react-router-dom';
import HotlistRow from './subcomponents/HotlistRow';
import BulkActionsProvider from 'shared/context/BulkActionsProvider';
import { BulkActionsContext } from "shared/context/BulkActionsProvider";

const Groups = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [query] = useSearchParams();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedShared, setSelectedShared] = useState([]);
    const [isHeaderChecked, setIsHeaderChecked] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const search = query.get("search") || "";
    const start = query.get("start") || "";
    const end = query.get("end") || "";
    const order = query.get("order") || ""
    const order_by = query.get("order_by") || ""
    const page = query.get("page") || "";
    
    const { groups, count, isReadingGroups, isReadingSubgroups, current_user, hotlist, subgroups } = useSelector((state) => {
        return {
            current_user: state.auth.user_id,
            groups: state.groups.groups,
            subgroups: state.groups.subgroups,
            count: state.groups.count,
            hotlist: state.groups.hotlist,
            isReadingGroups: state.groups.isReadingGroups,
            isReadingSubgroups: state.groups.isReadingSubgroups,
        };
    })
    const LIMIT = 25;

    const isFiltering =  search !== "" || start !== "" || end !== "";
    
    const [,setSelectedGroups] = useContext(BulkActionsContext);

    const onChangePage = (_, { activePage }) => {
        changePage(activePage);
    };

    const changePage = (page) => {
        const queryParams = new URLSearchParams(query);
        queryParams.set("page", page);
        const queryString = queryParams.toString();
        navigate(`/groups${queryString ? `?${queryString}` : ""}`) 
    }

    const getGroups = useCallback((params) => {
        return dispatch(groupsActions.readGroups(params));
    }, [dispatch]);

    const getSubgroups = (params) => {
        return dispatch(groupsActions.readSubgroups(params));
    }

    const onSave = async (data) => {
        const {name, shared_type} = data;
        const user_id = shared_type === "personal" ? current_user : 0;
        try {
            await dispatch(groupsActions.createGroups([{name, user_id}]))
            Notification.alert(`Successfully added group!`, STATUS_TYPES.SUCCESS);
            getGroups({search, start, end, order});
            setIsPopupOpen(false);
        } catch (error) {
            console.log(error)
            Notification.alert('Failed to add group', STATUS_TYPES.ERROR);
        }
    }

    const isDuplicated = (name) => {
        const result = dispatch(groupsActions.isGroupUnique({name}));
        return result;
    }

    const onChangeFilter = async (_, { value }) => {
        setSelectedShared(value);
        const queryParams = new URLSearchParams(query);
        if (value && value?.length < 2) {
            queryParams.set("shared", value.join(","));
        } else {
            queryParams.delete("shared");
        }
        queryParams.set("page", 1);
        const queryString = queryParams.toString();
        navigate(`/groups${queryString ? `?${queryString}` : ""}`);
    };

    const onDeleteGroup = async (group_id) => {
        try {
            await dispatch(groupsActions.deleteGroup(group_id));
            Notification.alert(`Successfully deleted group!`, STATUS_TYPES.SUCCESS);
            getGroups({search, start, end, order, page, order_by});
        } catch (error) {
            console.log(error)
            Notification.alert('Failed to delete group', STATUS_TYPES.ERROR);
        }
    }

    useEffect(() => {
        setSelectedShared(["company", "personal"])
    }, [setSelectedShared])

    useEffect(() => {
        if (isHeaderChecked) {
            const selectedSubIds = [];
            for (const subgroupIdx in subgroups) {
                subgroups[subgroupIdx].forEach((subgroup) => {
                    if (groups.find((group) => group.id === parseInt(subgroupIdx))) {
                        selectedSubIds.push({group_id: subgroup.id, parent_label_id: subgroup.parent_label_id});
                    }
                });
            }
            const selectedIds = selectedSubIds.concat(groups.map(({id, parent_label_id}) => ({group_id: id, parent_label_id})));
            setSelectedGroups(selectedIds);
        }
        setLoadingState(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHeaderChecked])

    const reload = () => {
        const sharedMapping = {
            company: "shared",
            personal: "private"
        }

        getGroups({
            search,
            order,
            order_by,
            page,
            start_time: start,
            end_time: end,
            limit: LIMIT,
            type: selectedShared.length !== 1 ? "all" : sharedMapping[selectedShared[0]]
        });
    }

    useEffect(() => {
        const sharedMapping = {
            company: "shared",
            personal: "private"
        }

        getGroups({
            search,
            order,
            order_by,
            page,
            start_time: start,
            end_time: end,
            limit: LIMIT,
            type: selectedShared.length !== 1 ? "all" : sharedMapping[selectedShared[0]]
        });
    }, [search, start, end, order, order_by, page, selectedShared, dispatch, getGroups]);

    return (
        <div className='GroupsPage'>
            <Header className='GroupsPage__PageHeader'>
                <Header.Content>Groups</Header.Content>
            </Header>
            <GroupsToolbar reload={reload} onSave={onSave} isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} isDuplicated={isDuplicated} setIsHeaderChecked={setIsHeaderChecked} getSubgroups={getSubgroups} />
            <Table striped={false} className="CustomFieldsTable" fixed >
                <GroupsHeader setLoadingState={setLoadingState} getSubgroups={getSubgroups} setIsHeaderChecked={setIsHeaderChecked} onChangeFilter={onChangeFilter} selectedShared={selectedShared}/>
                <Table.Body>
                    {count === 0 && !isReadingGroups && isFiltering &&
                        <Table.Row>
                            <Table.Cell colspan={8} style={{padding: "30px 60px 30px 60px"}}>
                                <Header as='h3' textAlign='left' colspan={7} >
                                    No results found
                                </Header>
                            </Table.Cell>
                        </Table.Row>
                    }
                    {count === 0 && !isReadingGroups && !isFiltering &&
                        <Table.Row>
                            <Table.Cell colspan={8} style={{padding: "30px 60px 30px 60px"}}>
                                <EmptyListPlaceholder wrapped={false} name="Group"/>
                            </Table.Cell>
                        </Table.Row>
                    }
                    {hotlist && !loadingState && !isReadingGroups && count > 0 && (<HotlistRow hotlist={hotlist}/>)}
                    {!isReadingGroups && !loadingState && groups.map((group, index) => <GroupsRow isHeaderChecked={isHeaderChecked} getSubgroups={getSubgroups} key={index} group={group} getGroups={() => getGroups({search, start, end, order})} onDeleteGroup={onDeleteGroup} isDuplicated={isDuplicated} isReadingSubgroups={isReadingSubgroups}/>)}
                    {(isReadingGroups || loadingState) && <TableLoader isLoading colSpan={9} />}
                </Table.Body>
            </Table>
            {count > 0 && (
                <Segment basic textAlign="right">
                    <Pagination
                        boundaryRange={0}
                        activePage={page}
                        ellipsisItem={null}
                        siblingRange={2}
                        totalPages={Math.ceil(count / LIMIT)}
                        onPageChange={onChangePage}
                        disabled={isReadingGroups || isReadingSubgroups}
                    />
                </Segment>
            )}
        </div>
    )
}

export default function GroupsContainer() {
    return (
        <BulkActionsProvider>
            <Groups />
        </BulkActionsProvider>
    )
};
