import initialState from './state';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';

function templatesReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.TEXT_TEMPLATES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingTemplates: true };
        case getNamedAction(ACTION_NAMES.TEXT_TEMPLATES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingTemplates: false, templates: action.data.data, count: action.data.count }
        case getNamedAction(ACTION_NAMES.TEXT_TEMPLATES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingTemplates: false, count: 0, templates: [] };

        case getNamedAction(ACTION_NAMES.TEXT_TEMPLATES, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingTemplate: true };
        case getNamedAction(ACTION_NAMES.TEXT_TEMPLATES, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingTemplate: false }
        case getNamedAction(ACTION_NAMES.TEXT_TEMPLATES, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingTemplate: false };

        default:
            return state;
    }
}

export default templatesReducer;
