import React from 'react';
import { Form, Grid, Icon } from 'semantic-ui-react';
import { DividingHeader, DateTimeUtils } from 'dyl-components';

import './index.scss';
import { Controller } from 'react-hook-form';
import { PhoneUtil } from 'utils';
import { MONTHS } from 'shared/constants/MONTHS';
import { STATES } from 'shared/constants/STATES';

const TIMEZONE_OPTIONS = DateTimeUtils.generateTimezoneOptions();


const ProfileContactInfo = ({
    control,
    industry_options,

    isFocused,
    addFocus,
    removeFocus
}) => (
    <React.Fragment>
        <div className='CompanyProfile'>
            <Form.Group>
                <Controller
                    name='name'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            className='CompanyProfile__field CompanyProfile__field--input_padded'
                            width={4}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            error={error?.message}
                            name={name}
                            value={value}
                            label={<React.Fragment><Icon name='building' /> <b>Company Name</b> <Icon size='tiny' name='asterisk' color='red' /></React.Fragment>}
                        />
                    )}
                />
                <Controller
                    name='website'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            className='CompanyProfile__field--input_padded'
                            width={4}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            value={value}
                            error={error?.message}
                            name={name}
                            label={<React.Fragment><Icon name='linkify' /> <b>Website</b></React.Fragment>}
                        />
                    )}
                />
                <Controller
                    name='industry'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Select
                            className='CompanyProfile__field--input_padded'
                            width={4}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            name={name}
                            value={value}
                            error={error?.message}
                            options={industry_options}
                            label={<React.Fragment><Icon name='industry' /> <b>Industry</b></React.Fragment>}
                        />
                    )}
                />
                <Controller
                    name='timezone'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Select
                            className='CompanyProfile__field CompanyProfile__field--input_padded'
                            width={4}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            error={error?.message}
                            name={name}
                            value={value}
                            options={TIMEZONE_OPTIONS}
                            label={<React.Fragment><Icon name='world' /> <b>Timezone</b> <Icon size='tiny' name='asterisk' color='red' /></React.Fragment>}
                        />
                    )}
                />
            </Form.Group>
            <Form.Group>
                <Controller
                    name='email'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            className='CompanyProfile__field--input_padded'
                            width={4}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            name={name}
                            value={value}
                            error={error?.message}
                            label={<React.Fragment><Icon name='mail' /> <b>Email</b></React.Fragment>}
                        />
                    )}
                />
                <Controller
                    name='fiscal_year_start'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            className='CompanyProfile__field--input_padded'
                            width={4}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            options={MONTHS.map(({ key }) => ({ key, value: key, text: key }))}
                            name={name}
                            value={value}
                            label={<React.Fragment><Icon name='address card' /> <b>Fiscal Year Start Month</b></React.Fragment>}
                        />
                    )}
                />
                <Controller
                    name='ein'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Input
                            className='CompanyProfile__field--input_padded'
                            width={4}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            name={name}
                            value={value}
                            label={<React.Fragment><Icon name='briefcase' /> <b>EIN</b></React.Fragment>}
                        />
                    )}
                />
            </Form.Group>
        </div>
        <div className='CompanyContactInfo'>
            <DividingHeader content='Contact Info' compact noline />
            <Form.Group>
                <Controller
                    name='street'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error }  }) => (
                        <Form.Input
                            className='CompanyContactInfo__field--input_padded'
                            width={4}
                            onChange={(_, { name, value }) => {onChange({ target: { name, value } })}}
                            name={name}
                            value={value}
                            error={error?.message}
                            label={<React.Fragment><Icon name='map marker' /> <b>Street Address</b></React.Fragment>}
                        />
                    )}
                />
                <Controller
                    name='additional_street'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            className='CompanyContactInfo__field--padded'
                            width={4}
                            onChange={(_, { name, value }) => {onChange({ target: { name, value } })}}
                            name={name}
                            value={value}
                            error={error?.message}
                            label={<React.Fragment><b>Apt, suite, unit building, floor #</b></React.Fragment>}
                        />
                    )}
                />
                <Controller
                    name='city'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            className='CompanyContactInfo__field--padded'
                            width={4}
                            onChange={(_, { name, value }) => {onChange({ target: { name, value } })}}
                            name={name}
                            value={value}
                            label={<React.Fragment><b>City</b></React.Fragment>}
                            error={error?.message}
                        />
                    )}
                />
                <Controller
                    name='state'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Select
                            className='CompanyContactInfo__field--padded'
                            width={2}
                            options={STATES.map(({ key }) => ({ key, value: key, text: key }))}
                            search
                            onChange={(_, { name, value }) => {onChange({ target: { name, value } })}}
                            name={name}
                            value={value}
                            error={error?.message}
                            label={<React.Fragment><b>State</b></React.Fragment>}
                        />
                    )}
                />
                <Controller
                    name='zip'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            className='CompanyContactInfo__field--padded'
                            width={2}
                            minLength={5}
                            maxLength={5}
                            onChange={(_, { name, value }) => {onChange({ target: { name, value } })}}
                            name={name}
                            value={value}
                            error={error?.message}
                            label={<React.Fragment><b>Zip Code</b></React.Fragment>}
                        />
                    )}
                />
            </Form.Group>
            <Form.Group as={Grid} className='CompanyContactInfo__numbers'>
                <Grid.Row columns='equal'>
                    <Grid.Column width={4} className='CompanyContactInfo__primaryColumn'>
                        <Controller
                            name='primary_number'
                            control={control}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Input
                                    className='CompanyContactInfo__field--input_padded Primary__number'
                                    onChange={(_, { name, value }) => {onChange({ target: { name, value } })}}
                                    name={name}
                                    value={!isFocused('primary_phone') ? PhoneUtil.formatPhoneNumber(value) : value}
                                    error={error?.message}
                                    onFocus={() => {addFocus('primary_phone')}}
                                    onBlur={() => {removeFocus('primary_phone')}}
                                    label={<React.Fragment><Icon name='text telephone' /> <b>Primary Phone Number</b></React.Fragment>}
                                />
                            )}
                        />
                        <Controller
                            name='ext'
                            control={control}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Input
                                    onChange={(_, { name, value }) => {onChange({ target: { name, value } })}}
                                    name={name}
                                    value={value}
                                    className='Primary__extension'
                                    error={error?.message}
                                    fluid
                                />
                            )}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Controller
                            name='mobile_number'
                            control={control}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Input
                                    className='CompanyContactInfo__field--input_padded'
                                    onChange={(_, { name, value }) => {onChange({ target: { name, value } })}}
                                    name={name}
                                    value={!isFocused('mobile_number') ? PhoneUtil.formatPhoneNumber(value) : value}
                                    error={error?.message}
                                    onFocus={() => {addFocus('mobile_number')}}
                                    onBlur={() => {removeFocus('mobile_number')}}
                                    label={<React.Fragment><Icon name='mobile alternate' /> <b>Mobile Number</b></React.Fragment>}
                                    fluid
                                />
                            )}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Controller
                            name='fax_number'
                            control={control}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Input
                                    className='CompanyContactInfo__field--input_padded'
                                    onChange={(_, { name, value }) => {onChange({ target: { name, value } })}}
                                    name={name}
                                    value={!isFocused('fax_number') ? PhoneUtil.formatPhoneNumber(value) : value}
                                    error={error?.message}
                                    onFocus={() => {addFocus('fax_number')}}
                                    onBlur={() => {removeFocus('fax_number')}}
                                    label={<React.Fragment><Icon name='fax alternate' /> <b>Fax Number</b></React.Fragment>}
                                    fluid
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Form.Group>
            <Form.Group widths='equal'>
                <Controller
                    name='facebook'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input 
                            className='CompanyContactInfo__field--input_padded' 
                            onChange={(_, { name, value }) => {onChange({ target: { name, value } })}} 
                            name={name} 
                            value={value}
                            error={error?.message}
                            label={<React.Fragment><Icon name='facebook' /> <b>Facebook</b></React.Fragment>} 
                        />
                    )}
                />
                <Controller
                    name='linkedin'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input 
                            className='CompanyContactInfo__field--input_padded' 
                            onChange={(_, { name, value }) => {onChange({ target: { name, value } })}} 
                            name={name} 
                            value={value}
                            error={error?.message}
                            label={<React.Fragment><Icon name='linkedin' /> <b>LinkedIn</b></React.Fragment>}
                        />
                    )}
                />
                <Controller
                    name='twitter'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input 
                            className='CompanyContactInfo__field--input_padded' 
                            onChange={(_, { name, value }) => {onChange({ target: { name, value } })}} 
                            name={name} 
                            value={value}
                            error={error?.message}
                            label={<React.Fragment><Icon name='twitter' /> <b>Twitter</b></React.Fragment>}
                        />
                    )}
                />
                <Controller
                    name='google'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input 
                            className='CompanyContactInfo__field--input_padded' 
                            onChange={(_, { name, value }) => {onChange({ target: { name, value } })}} 
                            name={name} 
                            value={value}
                            error={error?.message}
                            label={<React.Fragment><Icon name='google plus square' /> <b>Google+</b></React.Fragment>}
                        />
                    )}
                />
            </Form.Group>
        </div>
    </React.Fragment>
);

export default ProfileContactInfo;
