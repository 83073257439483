const MARK_LEAD_IN_TEXT_LIST = 'MARK_LEAD_IN_TEXT_LIST';
const MARK_ALL_LEADS_IN_TEXT_LIST = 'MARK_ALL_LEADS_IN_TEXT_LIST';

const TEXT_LIST_LEAD_CONSENT = 'TEXT_LIST_LEAD_CONSENT';

export default {
    MARK_LEAD_IN_TEXT_LIST,
    MARK_ALL_LEADS_IN_TEXT_LIST,

    TEXT_LIST_LEAD_CONSENT
};
