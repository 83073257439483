import initialState from './initialState';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';

function rolesReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.ACCESS_ROLES, CRUD_ACTION_TYPES.READ_REQUEST):
            return {...state, isReadingRoles: true}
        case getNamedAction(ACTION_NAMES.ACCESS_ROLES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {...state, isReadingRoles: false, roles: action.data.data || action.data , queryParameters: { ...state.queryParameters, ...action.queryParameters }, count: action.data.count || 0} //PERMISSION_DISABLED --action.data is hack until we convert full permissions 
        case getNamedAction(ACTION_NAMES.ACCESS_ROLES, CRUD_ACTION_TYPES.READ_FAILURE):
            return {...state, isReadingRoles: false}
        case getNamedAction(ACTION_NAMES.ACCESS_ROLES, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return {...state, isCreatingRole: true};
        case getNamedAction(ACTION_NAMES.ACCESS_ROLES, CRUD_ACTION_TYPES.CREATE_SUCCESS):
        case getNamedAction(ACTION_NAMES.ACCESS_ROLES, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return {...state, isCreatingRole: false};
            
        default: 
            return state;
    }
}

export default rolesReducer;
