import { Modal, NestedDropdown, Notification, STATUS_TYPES, VALIDATORS, generateResolver, yup } from "dyl-components";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Form, Icon, Popup } from "semantic-ui-react";
import './EditDylNumberModal.scss'
import { useDispatch, useSelector } from "react-redux";
import { PhoneUtil, StringUtils } from "utils";
import pbxConfigActions from "actions/pbx_config";

const Content = ({ onClose, onReload }) => {
    const dispatch = useDispatch();

    const [destinationOptions, setDestinationOptions] = useState([]);

    const { dylNumber, isReadingDylNumber, destinations, isReadingDestinations, isUpdatingDylNumber, soundOptions } = useSelector((state) => state.pbx_config);

    const { control, formState: { isValid, isDirty }, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            number: dylNumber?.number,
            cnam: dylNumber?.cnam || "",
            cid_name_replace: dylNumber?.cid_name_replace || "",
            destination_parent: dylNumber.destination?.type || null,
            destination_id: dylNumber.destination?.destination_id || null,
            type: StringUtils.capitalize(dylNumber.type || ""),
            label: dylNumber.label || "",
            plan_type: null,
            inbound_sound_id: dylNumber?.inbound_sound?.sound_id || null,
            the_campaign_registry: null
        },
        resolver: generateResolver({
            number: yup.string().required("This field is required"),
            type: yup.string().required("This field is required"),
            destination_id: yup.number().required("This field is required"),
            cid_name_replace: VALIDATORS.CALLER_ID_DISPLAY(),
            cnam: VALIDATORS.CNAM(),
            label: VALIDATORS.NUMBER_LABEL().required("This field is required")
        })
    });

    const onUpdateDylNumber = async (data) => {
        try {
            const { number, cid_name_replace, destination_id, label, cnam, inbound_sound_id } = data;
            await dispatch(pbxConfigActions.updateDylNumber(number, {cid_name_replace, destination_id, cnam, label: label ? label : PhoneUtil.formatPhoneNumber(number), inbound_sound_id}));
            Notification.alert('DYL Number Updated Successfully!', STATUS_TYPES.SUCCESS);
            onReload();
            onClose();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update DYL number', STATUS_TYPES.ERROR);
        }
    }

    useEffect(() => {
        if (destinations) {
            const destinationTypesAux = [...PhoneUtil.DESTINATION_TYPES_OPTIONS];
            const destinationOptionsAux = destinationTypesAux.map((destinationType) => {
                const { key } = destinationType; 
                const options = destinations[key]?.map(({destination_id, label}) => (
                    {key: destination_id, value: destination_id, text: label}
                )) || []
                return {...destinationType, options};
            })
            setDestinationOptions(destinationOptionsAux);
        }
    }, [destinations])

    useEffect(() => {
        dispatch(pbxConfigActions.getDestinations());
        dispatch(pbxConfigActions.getSoundOptions({ category: "general" }));
    }, [dispatch])

    return (
        <>
            <Modal.Header>
                Edit DYL Number
            </Modal.Header>
            <Modal.Content>
                <Form noValidate loading={isReadingDylNumber || isUpdatingDylNumber}>
                    <div className="EditDylNumberModal__form">
                        <div className="EditDylNumberModal__controllerContainer">
                            <Controller
                                name='number'
                                control={control}
                                style={{flex: 1}}
                                render={({ field: { name, value, onChange } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        label="Number"
                                        readOnly
                                    />
                                )}
                            />
                            <Controller
                                name='type'
                                control={control}
                                render={({ field: { name, value, onChange } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        label="Type"
                                        readOnly
                                    />
                                )}
                            />
                        </div>
                        <div className="EditDylNumberModal__controllerContainer">
                            <Controller
                                name='plan_type'
                                control={control}
                                render={({ field: { name, value, onChange } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        label="Plan Type"
                                        placeholder="Select plan type"
                                        readOnly
                                    />
                                )}
                            />
                            <Controller
                                name='destination_parent'
                                control={control}
                                render={({ field: { name: parentName, value: parentValue, onChange: onParentChange } }) => (
                                    <Controller 
                                        name='destination_id'
                                        control={control}
                                        render={({ field: { name: childName, value: childValue, onChange: onChildChange }, fieldState: { error } }) => (
                                            <Form.Field
                                                control={NestedDropdown}
                                                child_value={childValue}
                                                parent_value={parentValue}
                                                loading={isReadingDestinations}
                                                nested_options={destinationOptions}
                                                onChange={(_, { parent_value, child_value }) => {
                                                    onParentChange({ target: { name: parentName, value: parent_value } });
                                                    onChildChange({ target: { name: childName, value: child_value } });
                                                }}
                                                placeholder="Select Destination"
                                                display_parent
                                                selection
                                                label="Destination"
                                                pointing='top'
                                                required
                                                error={error?.message}
                                                excluded_parents={["conference_room", "parking_lot", "destination_group"]}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </div>
                        <div className="EditDylNumberModal__controllerContainer">
                            <Controller
                                name='label'
                                control={control}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        label="Label"
                                        required
                                        error={error?.message}
                                    />
                                )}
                            />
                            <Controller
                                name='cid_name_replace'
                                control={control}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        label={{
                                            children: (
                                                <span>
                                                    Caller ID Display
                                                    <Popup
                                                        trigger={<Icon style={{ float: 'right', marginTop: '0.25em' }} name='fas fa-info-circle' color='primary' />}
                                                        inverted
                                                        content="Inbound display when the number is called. If no caller display is added, then it populate with the caller ID."
                                                    />
                                                </span>
                                            )
                                        }}
                                        error={error?.message}
                                    />
                                )}
                            />
                        </div>
                        <div className="EditDylNumberModal__controllerContainer">
                            <Controller
                                name='cnam'
                                control={control}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        label={{
                                            children: (
                                                <span>
                                                    CNAM
                                                    <Popup
                                                        trigger={<Icon style={{ float: 'right', marginTop: '0.25em' }} name='fas fa-info-circle' color='primary' />}
                                                        inverted
                                                        content="Outbound display when the number is calling out."
                                                    />
                                                </span>
                                            )
                                        }}
                                        error={error?.message}
                                    />
                                )}
                            />
                            <Controller
                                name='inbound_sound_id'
                                control={control}
                                render={({
                                    field: { name, value, onChange },
                                    fieldState: { error },
                                }) => (
                                    <Form.Select
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => {
                                            onChange({
                                                target: { name, value },
                                            });
                                        }}
                                        placeholder="Select existing audio file"
                                        options={soundOptions?.general?.map(option => ({ key: option.id, value: option.id, text: option.name}))}
                                        label="Inbound Audio Message"
                                        error={error?.message}
                                    />
                                )}
                            />
                        </div>
                        <div className="EditDylNumberModal__controllerContainer">
                            <Controller
                                name='the_campaign_registry'
                                control={control}
                                render={({ field: { name, value, onChange } }) => (
                                    <Form.Select
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        placeholder='Select'
                                        options={[]}
                                        label="The Campaign Registry"
                                        readOnly
                                    />
                                )}
                            />
                            <div className="field" />
                        </div>
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions 
                hasSaveButton
                onSave={handleSubmit(onUpdateDylNumber)}
                saveDisabled={!isValid || !isDirty}
                saveOptions={{ loading: isReadingDylNumber || isUpdatingDylNumber }}
            />  
        </>
    )
}

const EditDylNumberModal = ({ open, onClose, onReload }) => {
    return (
        <Modal open={open} onClose={onClose} >
            <Content onClose={onClose} onReload={onReload} />
        </Modal>
    )
}

export default EditDylNumberModal;
