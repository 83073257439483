import React from 'react';
import { Grid, Pagination } from 'semantic-ui-react';
import { TableWithTools } from 'dyl-components';
import CallHistoryTable from './subcomponents/CallHistoryTable';
import { MOCK_CALLS } from '__mocks__/mockData';
import CallHistoryToolbar from './subcomponents/CallHistoryToolbar';
import CallHistoryFilter from './subcomponents/CallHistoryFilter';
import HeaderContainer from 'shared/HeaderContainer';

const CallHistory = ({ calls }) => (
    <Grid>
        <HeaderContainer 
            details={{
                type: 'reports',
                path: '/reports/pbx-log',
                title: 'Call History'
            }}
        />
        <Grid.Row>
            <Grid.Column>
                <CallHistoryFilter />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <TableWithTools
                    pagination={
                        <Pagination
                            boundaryRange={0}
                            defaultActivePage={1}
                            ellipsisItem={null}
                            firstItem={null}
                            lastItem={null}
                            siblingRange={2}
                            totalPages={Math.ceil(calls.length / 10)}
                        />
                    }
                    toolbar={
                        <CallHistoryToolbar numberOfCalls={calls.length} />
                    }
                    table={<CallHistoryTable calls={calls} />}
                />
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

class CallHistoryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            calls: []
        }
    }

    componentDidMount() {
        this.setState({
            calls: MOCK_CALLS
        })
    }
    
    render() {
        return (
            <CallHistory
                {...this.state}
            />
        )
    }
}

export default CallHistoryContainer;
