import React from 'react';
import { TableWithHeader, STATUS_TYPES, Notification } from 'dyl-components';
import CustomRouteHeaderContainer from './subcomponents/CustomRouteHeader';
import TimeframesTable from './subcomponents/TimeframesTable';

const CustomRoute = ({
    customRoute,
    timeframes,
    onUpdateName,
    onUpdateAfterHours,
    onAddTimeframe,
    onDeleteTimeframe,
    onUpdateTimeframe,
    addTimeFrameIndex,
    onConfirmAdd,
    onCancel
}) => (
    <TableWithHeader 
        header={(
            <CustomRouteHeaderContainer 
                name={customRoute.name}
                afterHours={customRoute.afterHours}
                onUpdateName={onUpdateName}
                onUpdateAfterHours={onUpdateAfterHours}
                onAddTimeframe={onAddTimeframe}
            />
        )}
        table={(
            <TimeframesTable 
                timeframes={timeframes}
                onAdd={onAddTimeframe}
                onDelete={onDeleteTimeframe}
                onUpdate={onUpdateTimeframe}
                addTimeFrameIndex={addTimeFrameIndex}
                onCancel={onCancel}
                onConfirmAdd={onConfirmAdd}
            />
        )}
    />
);

class CustomRouteContainer extends React.Component {
    state = {
        addTimeFrameIndex: -1,
        customRoute: {
            name: 'Test',
            afterHours: 'vct'
        },
        timeframes: [
            {
                id: '1mwf12d',
                days: ['Mon', 'Wed', 'Fri'],
                start: '1:30 PM',
                end: '2:30 PM',
                destinations: ['Default']
            },
            {
                id: '2mtwthf121d',
                days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
                start: '12:00 PM',
                end: '1:00 PM',
                destinations: ['Default']
            },
            {
                id: '3mtwthf121d',
                days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
                start: '9:00 AM',
                end: '12:00 PM',
                destinations: ['Default']
            }
        ]
    }

    onUpdateName = (newName) => {
        let customRoute = { ...this.state.customRoute, name: newName };
        this.setState({ customRoute });
    }

    onUpdateAfterHours = (newAfterHours) => {
        let customRoute = { ...this.state.customRoute, afterHours: newAfterHours };
        this.setState({ customRoute });
    }

    onAddTimeframe = (index = 0) => {
        this.setState({ addTimeFrameIndex: index });
    }

    onConfirmAdd = (timeframe) => {
        let timeframes = this.state.timeframes.slice(0);
        timeframes.splice(this.state.addTimeFrameIndex, 0, timeframe);
        this.setState({ timeframes, addTimeFrameIndex: -1 }, () => {
            Notification.alert('Successfully added timeframe!', STATUS_TYPES.SUCCESS, true);
        });
    }

    onDeleteTimeframe = (id) => {
        let timeframes = this.state.timeframes.slice(0);
        let indexOfTimeframeToDelete = timeframes.findIndex(timeframe => timeframe.id === id);
        if (indexOfTimeframeToDelete !== -1) {
            timeframes.splice(indexOfTimeframeToDelete, 1);
            this.setState({ timeframes }, () => {
                Notification.alert('Successfully deleted timeframe!', STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    onCancel = () => {
        this.setState({
            addTimeFrameIndex: -1
        })
    }

    onUpdateTimeframe = (updatedTimeframe) => {
        let timeframes = this.state.timeframes.slice(0);
        let indexOfTimeframeToUpdate = timeframes.findIndex(timeframe => timeframe.id === updatedTimeframe.id);
        if (indexOfTimeframeToUpdate !== -1) {
            timeframes[indexOfTimeframeToUpdate] = { ...updatedTimeframe };
            this.setState({ timeframes }, () => {
                Notification.alert('Successfully updated timeframe!', STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    render() {
        return (
            <CustomRoute 
                {...this.state}
                onUpdateName={this.onUpdateName}
                onUpdateAfterHours={this.onUpdateAfterHours}
                onAddTimeframe={this.onAddTimeframe}
                onDeleteTimeframe={this.onDeleteTimeframe}
                onUpdateTimeframe={this.onUpdateTimeframe}
                onConfirmAdd={this.onConfirmAdd}
                onCancel={this.onCancel}
            />
        )
    }
}

export default CustomRouteContainer;
