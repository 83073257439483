import React, { useState } from "react"; 
import { useSelector } from "react-redux";
import { Table, ConfirmationPrompt } from "dyl-components";
import { Icon, Header, Popup,  Loader, Modal } from "semantic-ui-react";
import E911LocationsForm from "shared/forms/E911LocationsForm";
import { PhoneUtil } from "utils";
import E911Rows from './E911Rows';

const E911Table = ({
    control,
    clearErrors,
    setError,
    trigger,
    onValidate,
    isDirty,
    isValid,
    revalidating,
    isPopupOpen,
    setPopupOpen,
    locations,
    onClosePopUp,
    isReadingLocations,
    onDeleteLocation,
    getPhones,
    setIsDeletePromptOpen,
    isDeletePromptOpen,
}) => {
    const { pbxListPhones, isReadingListPhones } = useSelector(state => {
        return {
            pbxListPhones: state.pbx_config.pbxListPhones.map(phone => ({ key: phone, value: phone, text: PhoneUtil.formatPhoneNumber(phone)})),
            isReadingListPhones: state.pbx_config.isReadingListPhones,
        }
    });

    const [deleteIndex, setDeleteIndex] = useState(null);
 
    return (
        <>
            <Header
                as="h2"
                color="primary"
            >
                <Popup
                    trigger={
                        <Icon
                            as='h2'
                            style={{marginRight: 10, cursor: 'pointer'}}
                            className={"fas fa-plus-circle"}
                        />
                    }
                    on='click'
                    content={
                        <E911LocationsForm 
                            control={control}
                            clearErrors={clearErrors}
                            setError={setError}
                            trigger={trigger}
                            onValidate={onValidate}
                            isDirty={isDirty}
                            isValid={isValid}
                            pbxListPhones={pbxListPhones}
                            isReadingListPhones={isReadingListPhones}
                            revalidating={revalidating}
                            onClosePopUp={onClosePopUp}
                        />
                    }
                    wide='very'
                    style={{ width: '600px' }}
                    position='bottom left'
                    open={isPopupOpen}
                    onOpen={() => {
                        setPopupOpen(true);
                        getPhones();
                        }
                    }
                />
                E911 Locations
            </Header>
            <Table >
                <Table.Header className="Table__header--primary">
                    <Table.Row>
                        <Table.HeaderCell width={1}>
                            Name
                        </Table.HeaderCell>
                        <Table.HeaderCell width={1}>
                            Address
                        </Table.HeaderCell>
                        <Table.HeaderCell width={1}>
                            Registered Phones
                        </Table.HeaderCell>
                        <Table.HeaderCell width={1}>
                            Actions
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {isReadingLocations ?
                    <Table.Toolbar colSpan="16">
                        <Loader active inline='centered' />
                    </Table.Toolbar>
                :
                    locations.length !== 0 ?
                        <Table.Body>
                            {locations.map((location) => {
                                return (
                                    <E911Rows 
                                        location={location} 
                                        setDeleteIndex={setDeleteIndex}
                                        setIsDeletePromptOpen={setIsDeletePromptOpen}
                                    />
                                )
                            })}
                        </Table.Body>
                        :
                        <Table.Toolbar colSpan="16">
                            <Header as='h2'>No E911 Location Added</Header>
                        </Table.Toolbar>
                    }
            </Table>
            <Modal
                open={isDeletePromptOpen}
                style={{ padding: 30, width: 350 }}
            >
                <ConfirmationPrompt
                    header={
                        <div style={{ paddingTop: "1em" }}>
                            Are you sure?
                        </div>
                    }
                    subheader={<div>You cannot undo delete actions.</div>}
                    onConfirm={() =>
                        onDeleteLocation(deleteIndex)
                    }
                    onCancel={() => setIsDeletePromptOpen(false)}
                />
            </Modal> 
        </>
    );
};

export default E911Table;
