import React, { useState } from 'react';
import { Icon, Table as SemanticTable } from 'semantic-ui-react';
import { Table, TimestampFilters } from 'dyl-components';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getData, onToggleTimeUnit, useRangeCalendar } from "shared/CustomDateRange/customDateRange";

import {
    KEY_CREATED, KEY_NEXT_STEP,
    KEY_CREATED_BY, KEY_OPPORTUNITY_AGE, KEY_LAST_MODIFIED,
    KEY_LAST_MODIFIED_BY,
    KEY_CONFIDENCE_LEVEL, KEY_STAGE, KEY_CLOSE_PROBABILITY,
    KEY_OUTCOME, KEY_EXPECTED_CLOSE_DATE, KEY_LOST_REASON,
    KEY_NEXT_STEP_DATE
} from 'shared/constants/MODULE_COLUMNS';

const SortActions = ({ direction = false, onToggleSort = () => { } }) => {
    return (
        <div
            onClick={onToggleSort}
            style={{ display: "inline-flex", cursor: "pointer" }}
        >
            <Icon
                name="fa-solid fa-arrow-down-long"
                style={{
                    marginRight: -7,
                    opacity: !direction ? "100%" : "50%",
                }}
            />
            <Icon
                name="fa-solid fa-arrow-up-long"
                style={{ opacity: direction ? "100%" : "50%" }}
            />
        </div>
    );
};

const OpportunityHeader = ({ isColumnHidden, routeRoot }) => {

    const [params] = useSearchParams();
    const navigate = useNavigate();

    const { account_id } = useParams();

    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        userAuthTimezone,
        dateRange,
        setDateRange,
    } = useRangeCalendar(params);

    const [createdOrder, setCreatedOrder] = useState(
        params.get("order_by") === "created" && params.get("order") === "asc"
    );

    const onDateRangeChange = (_, { value }) => {
        setDateRange(value);
    };

    const createdOrderFilter = () => {
        setCreatedOrder(!createdOrder);
        const query = new URLSearchParams(params);
        const order = !createdOrder ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", "created");
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    };


    return (
        <Table.CollapsibleRow isHeader>
            {!isColumnHidden(KEY_CREATED) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={3}>
                    <span style={{ marginRight: 5 }}>Created</span>
                    <TimestampFilters
                        timestamp={timestamp}
                        onToggleTimeUnit={(timestamp) =>
                            onToggleTimeUnit(
                                timestamp,
                                setTimestamp,
                                params,
                                navigate,
                                routeRoot
                            )
                        }
                        isCustomDateRangeOpen={isCustomDateRangeOpen}
                        onOpenCustomDateRange={() => {
                            setCustomDateRangeOpen(true);
                        }}
                        onCloseCustomDateRange={() => {
                            setCustomDateRangeOpen(false);
                        }}
                        dateRange={dateRange}
                        onDateRangeChange={onDateRangeChange}
                        getData={() =>
                            getData({
                                setCustomDateRangeOpen,
                                dateRange,
                                userAuthTimezone,
                                params,
                                navigate,
                                url: routeRoot,
                            })
                        }
                    />

                    <SortActions
                        direction={createdOrder}
                        onToggleSort={() => {
                            createdOrderFilter();
                        }}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CREATED_BY) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Created By
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Last Modified
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED_BY) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Last Modified By
                </SemanticTable.HeaderCell>
            )}
            <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={3}>
                ID
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={3}>
                Name
            </SemanticTable.HeaderCell>
            {account_id && (
                <SemanticTable.HeaderCell className='ModulesListTable__LargeFixedWidth' width={4}>
                    Contact
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_OUTCOME) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={3}>
                    Outcome
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_STAGE) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={3}>
                    Pipeline/Stage
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_LOST_REASON) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Lost Reason/Type
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_EXPECTED_CLOSE_DATE) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Expected Close Date
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CONFIDENCE_LEVEL) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Confidence Level
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_OPPORTUNITY_AGE) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Opportunity Age
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_NEXT_STEP_DATE) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Next Step Date
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_NEXT_STEP) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Next Step
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CLOSE_PROBABILITY) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Close Probability
                </SemanticTable.HeaderCell>
            )}
        </Table.CollapsibleRow>
    )
}

export default OpportunityHeader;
