import React from 'react';
import { TableWithHeader } from 'dyl-components';
import { Grid, Button } from 'semantic-ui-react';
import CommunicationNotificationsForm from './CommunicationNotificationsForm';
import { LeftPaddedColumn } from 'dyl-components';

function getOptions(options) {
    return options.map(option => ({ key: option, value: option, text: option }));
}

const CommunicationNotifications = ({ 
    info,
    isEditMode,
    onEnableEditMode,
    onCloseEditMode,
    onUpdate,
    onChange,
    onAddItem,
    companyVoicemailsOptions,
    inboundFaxesOptions,
    textingMessagesOptions
}) => (
    <TableWithHeader 
        header={(
            <Grid verticalAlign='middle'>
                <Grid.Column width={6}>
                    Forward Voicemail, Fax &amp; Texting Notifications
                </Grid.Column>
                <Grid.Column width={4} textAlign='right' floated='right'>
                    {!isEditMode ? <Button onClick={onEnableEditMode} size='tiny' basic inverted color='white'>Edit</Button> :
                    <React.Fragment>
                        <Button size='tiny' inverted color='white' basic onClick={onCloseEditMode}>Cancel</Button>
                        <Button size='tiny' inverted color='white' basic onClick={onUpdate}>Update</Button>
                    </React.Fragment>
                }
                </Grid.Column>
            </Grid>
        )}
        table={(
            <Grid celled style={{margin: 0}} verticalAlign='middle'>
                <Grid.Row as={LeftPaddedColumn}>
                    <Grid.Column>Notifications will not be sent to the main user if emails are specified.</Grid.Column>
                </Grid.Row>
                <CommunicationNotificationsForm 
                    info={info}
                    isEditMode={isEditMode}
                    onChange={onChange}
                    onAddItem={onAddItem}
                    companyVoicemailsOptions={companyVoicemailsOptions}
                    inboundFaxesOptions={inboundFaxesOptions}
                    textingMessagesOptions={textingMessagesOptions}
                />
            </Grid>
        )}
    />
);

class CommunicationNotificationsContainer extends React.Component {
    state = {
        isEditMode: false,
        info: this.props.info,
        companyVoicemailsOptions: getOptions(this.props.info.company_voicemails),
        inboundFaxesOptions: getOptions(this.props.info.inbound_faxes),
        textingMessagesOptions: getOptions(this.props.info.texting_messages)
    }

    onEnableEditMode = () => {
        this.setState({
            isEditMode: true
        })
    }

    onCloseEditMode = () => {
        this.setState({
            isEditMode: false,
            info: this.props.info,
            companyVoicemailsOptions: getOptions(this.props.info.company_voicemails),
            inboundFaxesOptions: getOptions(this.props.info.inbound_faxes),
            textingMessagesOptions: getOptions(this.props.info.texting_messages)
        })
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false,
        }, () => {
            this.props.onUpdate({
                ...this.state.info
            })
        });
    }

    onChange = (_, {name, value}) => {
        let info = Object.assign({}, this.state.info, {[name]: value});
        this.setState({
            info
        });
    }

    onAddItem = (name, value) => {
        this.setState(prevState => ({
            [name]: [{ text: value, value, key: value }, ...prevState[name]]
        }));
    }

    render() {
        return (
            <CommunicationNotifications 
                {...this.state}
                onEnableEditMode={this.onEnableEditMode}
                onCloseEditMode={this.onCloseEditMode}
                onUpdate={this.onUpdate}
                onChange={this.onChange}
                onAddItem={this.onAddItem}
            />
        )
    }
}

export default CommunicationNotificationsContainer;
