import React, { createContext, useState } from 'react';

const ConditionsContext = createContext();

const ConditionsProvider = ({ children }) => {
  const [callbacks, setCallbacks] = useState([]);

  const addCallbacks = (segment) => setCallbacks((prev) => [...prev, segment])
  const deleteCallbacks = (id) => setCallbacks((prev) => [...prev].filter((callback) => callback.segmentId !== id))

  return (
    <ConditionsContext.Provider value={{ 
      addCallbacks,
      deleteCallbacks,
      callbacks
    }}>
      {children}
    </ConditionsContext.Provider>
  );
};

export { ConditionsProvider, ConditionsContext };