import SearchBar from "dyl-components/molecules/SearchBar";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button, Header } from "semantic-ui-react";
import FavoriteIcon from "shared/FavoriteIcon";
import { FAVORITE_TYPE } from "shared/constants/FAVORITE_TYPE";
import { BulkActionsContext } from "shared/context/BulkActionsProvider";
import GroupContactBulkModal from "shared/modals/GroupContactBulkModal";

const GroupContactsToolbar = ({ reloadContacts, groupName, parent }) => {
    const navigate = useNavigate();
    const [query] = useSearchParams();
    const search = query.get("search") || '';
    const { group_id } = useParams();
    const { isReadingContacts, count } = useSelector((state) => ({
        isReadingContacts: state.groups.isReadingContacts,
        count: state.groups.contactCount
    }));

    const [selectedContacts, , areContactsInAllPagesSelected] = useContext(BulkActionsContext);

    const [isBulkActionsModalOpen, setIsBulkActionsModalOpen] = useState(false);

    const hasSelectedContacts = selectedContacts.length || areContactsInAllPagesSelected;
    const numberOfSelectedContacts = areContactsInAllPagesSelected ? count : selectedContacts.length;

    const searchFunction = (value) => navigate(`/group/${group_id}?search=${value}`);
    const cancelFunction = () => navigate(`/group/${group_id}`);

    const onCloseBulkActionsModal = () => {
        setIsBulkActionsModalOpen(false);
    }

    return <div className='GroupContacts__toolbar'>
        <Header as="h1" className="GroupContacts__title">
            <FavoriteIcon details={{
                external_id: group_id !== "hotlist" ? Number(group_id) : 0,
                favorite_type: FAVORITE_TYPE.GROUP
            }} />{group_id !== "hotlist" ? groupName : "Hotlist"}
        </Header>
        <Button disabled={!hasSelectedContacts} onClick={() => setIsBulkActionsModalOpen(true)} primary>Bulk Action</Button>
        <SearchBar
            placeholder={'Search by contact name, email phone number'}
            isSearching={isReadingContacts}
            searchFcn={searchFunction}
            cancelFcn={cancelFunction}
            search={search}
        />
        <GroupContactBulkModal
            numberSelectedContacts={numberOfSelectedContacts}
            areContactsInAllPagesSelected={areContactsInAllPagesSelected}
            open={isBulkActionsModalOpen}
            onClose={onCloseBulkActionsModal}
            selectedContacts={selectedContacts}
            reloadContacts={reloadContacts}
            parent={parent}
        />
    </div>
}

export default GroupContactsToolbar;
