import React from 'react';
import { Card } from 'semantic-ui-react';
import './index.scss';

const CardDataItem = ({ children }) => {
  return (
    <div className='CardDataItem'>
      {children}
    </div>
  )
}

const CardData = ({ children }) => {
  return (
    <Card className='CardData'>
      {children}
    </Card>
  )
}

export { CardData, CardDataItem };
