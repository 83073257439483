import React from 'react';
import { Form, Ref } from 'semantic-ui-react'
import { connect } from 'react-redux';

import usersActions from 'actions/users';
import { RecordUtils } from 'utils';

const UsersForm = React.forwardRef((({ users, label, user, onChange, isReadingUsers, name, as, placeholder, disabled }, ref) => (
    <Ref innerRef={ref}>
        <Form.Select
            options={users}
            value={user}
            onChange={onChange}
            name={name}
            clearable
            loading={isReadingUsers}
            fluid
            width={16}
            selectOnBlur={false}
            placeholder={placeholder}
            {...(as ? { as } : {})}
            search
            label={label}
            disabled={disabled}
        />
    </Ref>
)));

class UsersFormContainer extends React.Component {
    state = {
        page: 1,
        limit: 15,
        users: []
    }

    ref = React.createRef();

    componentDidMount() {
        // const scrollBox = this.ref.current.getElementsByClassName("menu")[0];
        // scrollBox.addEventListener("scroll", () => {
        //     if(scrollBox.scrollTop + scrollBox.clientHeight >= scrollBox.scrollHeight) {
        //         this.setState(prevState => ({
        //             page: Math.min(prevState.page + 1, Math.ceil(this.props.userOptionsCount / prevState.limit))
        //         }), () => {
        //             const { limit, page } = this.state;
        //             this.props.onReadUsers({ limit, page }).then(() => {
        //                 this.setState(prevState => ({
        //                     users: [ ...prevState.users, ...this.props.users ]
        //                 }))
        //             });
        //         })
        //     }
        // });
        this.onReadUsers();
    }
    
    onReadUsers = () => {
        this.setState({ page: 1 }, () => {
            const { limit, page } = this.state;
            this.props.onReadUsers({ limit, page }).then(() => {
                this.setState(prevState => ({
                    users: [ ...prevState.users, ...this.props.users ]
                }));
            });
        });
    }

    render() {
        return (
            <UsersForm
                name={this.props.name}
                onChange={this.props.onChange}
                user={this.props.user}
                users={this.state.users}
                placeholder={this.props.placeholder}
                ref={this.ref}
                isReadingUsers={this.props.isReadingUsers}
                onReadUsers={this.onReadUsers}
                {...(this.props.as ? { as: this.props.as } : {})}
                label={this.props.label}
                disabled={this.props.disabled}
            />
        )
    }
}

const mapStateToProps = (state, { excluded_users = [] }) => ({
    users: RecordUtils.generateUserOptions(state.users.userOptions).filter(option => !excluded_users.includes(option.value)),
    queryParameters: state.users.userOptionsQueryParameters,
    isReadingUsers: state.users.isReadingUsers,
    userOptionsCount: state.users.userOptionsCount
});

const mapDispatchToProps = (dispatch, { user_id }) => ({
    onReadUsers: (queryParameters) => {
        return dispatch(usersActions.readUserOptions({limit:500, page:1, user_id }));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersFormContainer);
