const initialState = {
    ring_group: {},
    isReadingRingGroup: false,
    isUpdatingRingGroup: false,
    ringGroupBeingDeleted: false,

    isReadingMembers: false,
    isAddingMembers: false,
    memberBeingUpdated: null,
    memberBeingDeleted: null,
    members: [],
    group:{},
    ringGroupsData:[],
    ringGroupsCount:0,
}

export default initialState;
