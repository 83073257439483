const initialState = {
    isReadingLocations: false,
    location: [],
    count: 0,
    isCreatingLocation: false,
    contactLocationBeingUpdated: null,
    contactLocationBeingDeleted: null,
    locationOptions: {}
};

export default initialState;
