const initialState = {
    fees: [],
    feesCount: 0,
    taxes: [],
    taxesCount: 0,

    isReadingFees: false,
    isReadingTaxes: false,
    isCreating: false,

    fee: null,

    feeBeingRead: null,
    feeBeingUpdated: false,
    feeBeingDeleted: false,
    feeError: false,
}

export default initialState;
