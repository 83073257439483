import React from 'react';
import { useSelector } from 'react-redux';

export const GetTimezone = () => {
    const userAuthTimezone = useSelector((state) => state.auth.timezone);
    const {timezone=userAuthTimezone} = useSelector(state => state.user.userProfile);
    return timezone;
}

const TZDisplay = () => { 
    return (
        <span> {GetTimezone()} </span>
    )
}

export default TZDisplay;
