import React from 'react';
import Events from './Events';
import Tasks from './Tasks';

import './index.scss';

class Activities extends React.Component {
    render() {
        return (
            <div className='Activities'>
                <Events />
                <Tasks />
            </div>
        );
    }
}

export default Activities;
