const CHECK_REQUEST = 'CHECK_REQUEST';
const CHECK_SUCCESS = 'CHECK_SUCCESS';
const CHECK_FAILURE = 'CHECK_FAILURE';

const GET_IP_REQUEST = 'GET_IP_REQUEST';
const GET_IP_SUCCESS = 'GET_IP_SUCCESS';
const GET_IP_FAILURE = 'GET_IP_FAILURE';

export default {
    CHECK_REQUEST,
    CHECK_SUCCESS,
    CHECK_FAILURE,

    GET_IP_REQUEST,
    GET_IP_SUCCESS,
    GET_IP_FAILURE
};
