import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
    Table,
    ClearableCheckboxDropdown,
    Icon,
    TimestampFilters,
    CategorizedCheckboxDropdown
} from "dyl-components";
import { Table as SemanticTable } from "semantic-ui-react";
import {
    getData,
    onToggleTimeUnit,
    useRangeCalendar,
} from "shared/CustomDateRange/customDateRange";

const typeOptions = [
    {
        name: "General Notes",
        options: [
            { key: "general", value: "general", text: "All",},
        ],
    },
    {
        name: "Call Notes",
        options: [
            { key: "All", value: "All", text: "All", all: true },
            { key: "inbound", value: "inbound", text: "Inbound" },
            { key: "outbound", value: "outbound", text: "Outbound" },
        ]
    },
    {
        name: "Task Notes",
        options: [
            { key: "All", value: "All", text: "All", all: true },
            { key: "Call", value: "Call", text: "Call" },
            { key: "Text", value: "Text", text: "Text" },
            { key: "Email", value: "Email", text: "Email" },
            { key: "To-Do", value: "To-Do", text: "To Do" },
        ]
    },
    {
        name: "Activity Log Notes",
        options: [
            { key: "All", value: "All", text: "All", all: true },
            { key: "log_call", value: "log_call", text: "Call" },
            { key: "log_text", value: "log_text", text: "Text" },
            { key: "log_email", value: "log_email", text: "Email" },
        ]
    },
];

export const NotesHeader = ({
    isReadingNotes,
    filters,
    onFilter,
    onSort,
    record_id,
    userAccounts,
    isReadingUserAccounts,
    contactAccount, 
    account,
    masterAccount,
    isReadingAccountRelatedNotes,
    contactAccountOptions
}) => {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    if(contactAccount === "account" || contactAccount === "master_account"){
        if(contactAccountOptions[0].options.length === 0){
            contactAccountOptions[0].options.push({ key: contactAccount === "account" ? account.id : masterAccount.id, value: contactAccount === "account" ? account.id : masterAccount.id, text: contactAccount === "account" ? account.name : masterAccount.name });
        }
    }

    const { 
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        isTimeRangeOpen,
        setTimeRangeOpen,
        dateRange,
        setDateRange,
    } = useRangeCalendar(params);

    const [newestFirst, setNewestFirst] = useState(false);
    const handleSort = () => {
        setNewestFirst(!newestFirst);
        onSort(newestFirst ? "desc" : "asc");
    };

    const onDateRangeChange = (_, { value }) => {
        setDateRange(value);
    };
    return (
        <Table.Row>
            <SemanticTable.HeaderCell width={2}>
                <span style={{ marginRight: 5 }}>Created</span>
                <TimestampFilters
                    timestamp={timestamp}
                    onToggleTimeUnit={(timestamp) =>
                        onToggleTimeUnit(
                            timestamp,
                            setTimestamp,
                            params,
                            navigate,
                            `/${contactAccount}/${record_id}/notes`
                        )
                    }
                    isCustomDateRangeOpen={isCustomDateRangeOpen}
                    onOpenCustomDateRange={() => {
                        setCustomDateRangeOpen(true);
                    }}
                    onCloseCustomDateRange={() => {
                        setCustomDateRangeOpen(false);
                    }}
                    onOpenTimeUnitOptions={() => {
                        setTimeRangeOpen(true);
                    }}
                    onCloseTimeUnitOptions={() => {
                        setTimeRangeOpen(false);
                    }}
                    areTimeUnitOptionsOpen={isTimeRangeOpen}
                    dateRange={dateRange}
                    onDateRangeChange={onDateRangeChange}
                    getData={() =>
                        getData({
                            setCustomDateRangeOpen,
                            dateRange,
                            params,
                            navigate,
                            url: `/${contactAccount}/${record_id}/notes`,
                        })
                    }
                />
                <div
                    onClick={handleSort}
                    style={{ display: "inline-flex", cursor: "pointer" }}
                >
                    <Icon
                        name="fa-solid fa-arrow-down-long"
                        style={{
                            marginRight: -7,
                            opacity: !newestFirst ? "100%" : "50%",
                        }}
                    />
                    <Icon
                        name="fa-solid fa-arrow-up-long"
                        style={{ opacity: newestFirst ? "100%" : "50%" }}
                    />
                </div>
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={2}>
                {contactAccount !== "master_account" ? (
                    <CategorizedCheckboxDropdown
                        text="Type"
                        name="type"
                        categories={typeOptions}
                        onChange={(_, { value }) => {
                            onFilter(_, { name: "type", value });
                        }}
                        value={filters.type}
                        closeOnLoad={true}
                        disabled={isReadingNotes}
                        loading={isReadingNotes}
                    />
                ): "Type"}
            </SemanticTable.HeaderCell>
            {(contactAccount === "account" || contactAccount === "master_account") &&
                <SemanticTable.HeaderCell width={2}>
                    <CategorizedCheckboxDropdown
                        text={`Account${contactAccount === "account" ? '/Contact' : ''}`}
                        name="contact_id"
                        onChange={(_, { value }) => {
                            onFilter(_,{ name: "contact_id", value });
                        }}
                        value={filters.contact_id}
                        categories={contactAccountOptions}
                        closeOnLoad={true}
                        disabled={isReadingAccountRelatedNotes}
                        loading={isReadingAccountRelatedNotes}
                    />
                </SemanticTable.HeaderCell>
            }
            <SemanticTable.HeaderCell width={2}>
                <ClearableCheckboxDropdown
                    text="User"
                    name="user_id"
                    onChange={(_, { value }) => {
                        onFilter(_,{ name: "user_id", value });
                    }}
                    value={filters.user_id}
                    options={userAccounts.map(user=>({ key: user.user_id, value: user.user_id, text: `${user.first_name} ${user.last_name}` }))}
                    disabled={isReadingUserAccounts}
                    loading={isReadingUserAccounts}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={5}>
                Notes
            </SemanticTable.HeaderCell>
        </Table.Row>
    );
};
