import React from 'react';
import CommunicationNotificationsContainer from './CommunicationNotifications';

export default class CommunicationNotificationsTable extends React.Component {
    state = {
        info: {
            company_voicemails: ['cgarris@dyl.com'],
            inbound_faxes: ['cgarris@dyl.com', 'MCuevas@dyl.com', 'dgreene@dyl.com'],
            texting_messages: []
        }
    }

    onUpdate = (newInfo) => {
        this.setState({
            info: newInfo
        })
    }

    render() {
        return (
            <CommunicationNotificationsContainer
                info={this.state.info}
                onUpdate={this.onUpdate}
            />
        )
    }
}
