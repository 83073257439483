import routes from 'actions/routes';
import { generateReadActionCreator, generateCreateActionCreator, generateUpdateActionCreator, generateDeleteActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

export default {
    readCategorizedTemplates: generateReadActionCreator(routes.API_CATEGORIZED_TEMPLATES, ACTION_NAMES.CATEGORIZED_TEMPLATES),
    read: generateReadActionCreator(routes.API_TEMPLATE_CATEGORY, ACTION_NAMES.TEMPLATE_CATEGORY),
    create: generateCreateActionCreator(routes.API_TEMPLATE_CATEGORY, ACTION_NAMES.TEMPLATE_CATEGORY),
    update: generateUpdateActionCreator(routes.API_TEMPLATE_CATEGORY, ACTION_NAMES.TEMPLATE_CATEGORY),
    delete: generateDeleteActionCreator(routes.API_TEMPLATE_CATEGORY, ACTION_NAMES.TEMPLATE_CATEGORY)
}
