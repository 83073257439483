import React, { useState } from "react";
import {
    Table,
    TimestampFilters,
} from "dyl-components";
import { Table as SemanticTable } from "semantic-ui-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SelectAllCheckbox from "shared/SelectAllCheckbox";
import SortActions from "./SortActions";
import { getData, onToggleTimeUnit, useRangeCalendar } from "shared/CustomDateRange/customDateRange";
import { useSelector } from "react-redux";

const LIST_VIEW_URL = "/settings/phone-management/routing/destination-groups"

const DestinationGroupsHeader = () => {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const [sortDirection, setSortDirection] = useState(true);
    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        userAuthTimezone,
        isTimeRangeOpen,
        setTimeRangeOpen,
        dateRange,
        setDateRange
    } = useRangeCalendar(params);
    const { destinationGroups, destinationGroupsCount } = useSelector((state) => state.pbx_config);

    const onDateRangeChange = (_, { value }) => {
        setDateRange(value);
    };

    const sortFilter = () => {
        setSortDirection(!sortDirection);
        const query = new URLSearchParams(params);
        const order = !sortDirection ? "asc" : "desc";
        query.set("order", order);
        const query_string = query.toString();
        navigate(`${LIST_VIEW_URL}${query_string ? `?${query_string}` : ""}`);
    };

    return (
        <SemanticTable.Header className='Table__header--primary Table__header--checkbox'>
            <Table.Row>
                <SemanticTable.HeaderCell
                    width={1}
                >
                    <SelectAllCheckbox 
                        count={destinationGroupsCount}
                        data={destinationGroups}
                        type={"Destination Groups"}
                        idProperty={"id"}
                    />
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell width={1} />
                <SemanticTable.HeaderCell width={3}>
                    <span>Created</span>
                    <TimestampFilters
                        timestamp={timestamp}
                        onToggleTimeUnit={(timestamp) =>
                            onToggleTimeUnit(
                                timestamp,
                                setTimestamp,
                                params,
                                navigate,
                                LIST_VIEW_URL
                            )
                        }
                        isCustomDateRangeOpen={isCustomDateRangeOpen}
                        onOpenCustomDateRange={() => {
                            setCustomDateRangeOpen(true);
                        }}
                        onCloseCustomDateRange={() => {
                            setCustomDateRangeOpen(false);
                        }}
                        onOpenTimeUnitOptions={() => {
                            setTimeRangeOpen(true);
                        }}
                        onCloseTimeUnitOptions={() => {
                            setTimeRangeOpen(false);
                        }}
                        areTimeUnitOptionsOpen={isTimeRangeOpen}
                        dateRange={dateRange}
                        onDateRangeChange={onDateRangeChange}
                        getData={() =>
                            getData({
                                setCustomDateRangeOpen,
                                dateRange,
                                userAuthTimezone,
                                params,
                                navigate,
                                url: LIST_VIEW_URL,
                            })
                        }
                    />
                    <SortActions
                        direction={sortDirection}
                        onToggleSort={() => {
                            sortFilter();
                        }}
                    />
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell
                    width={3}
                >
                    Created By
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell
                    width={3}
                >
                    Group Name
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell 
                    width={3}
                >
                    Actions
                </SemanticTable.HeaderCell>
            </Table.Row>
        </SemanticTable.Header>
    );
};

export default DestinationGroupsHeader;
