import React from 'react';
import { Schedule, UserAccounts, UserPermissionSettings, UserTeams, AddTeam, AddEditPermissionSettings, Territories, CreateTerritory } from "pages";

const PARENT_NAME = "general_settings";

export const GENERAL_SETTINGS = [
    {
        path: `users`,
        icon: 'fas fa-user',
        header: 'Users',
        description: 'Add, edit, and assign profile types to users. Also manage passwords.',
        page: UserAccounts,
        type: `${PARENT_NAME}.users`
    },
    {
        path: `teams`,
        icon: 'fas fa-users',
        header: 'Teams',
        description: 'Assign users to specific teams. For example: By project or department.',
        page: UserTeams,
        type: `${PARENT_NAME}.teams`
    },
    {
        path: `add-team`,
        icon: 'fas fa-users',
        header: 'Add Team',
        description: 'Add users to a team',
        page: AddTeam,
        exclude: true,
        parent: {
            path: 'teams',
            name: 'Teams'
        }
    },
    {
        path: `permission-profile`,
        icon: 'fas fa-id-badge',
        header: 'Permissions',
        description: 'View or modify permissions for all users based on scope or role.',
        page: UserPermissionSettings,
        type: `${PARENT_NAME}.permissions`
    },
    {
        path: `view-permission-profile/:roleId`,
        icon: 'fas fa-id-badge',
        header: 'View Permission Profile',
        page: AddEditPermissionSettings,
        exclude: true,
        parent: {
            path: 'permission-profile',
            name: 'Permissions'
        }
    },
    {
        path: `edit-permission-profile/:roleId`,
        icon: 'fas fa-id-badge',
        header: 'Edit Permission Profile',
        page: AddEditPermissionSettings,
        exclude: true,
        parent: {
            path: 'permission-profile',
            name: 'Permissions'
        }
    },
    {
        path: `add-permission-profile`,
        icon: 'fas fa-id-badge',
        header: 'Add Permission Profile',
        page: AddEditPermissionSettings,
        exclude: true,
        parent: {
            path: 'permission-profile',
            name: 'Permissions'
        }
    },
    {
        path: `schedule`,
        icon: 'fas fa-calendar',
        header: 'Schedule',
        description: `Set your company's business hours, days and times.`,
        page: Schedule,
        type: `${PARENT_NAME}.schedule`
    },
    {
        path: `territories`,
        icon: 'fas fa-globe',
        header: 'Territories',
        description: 'Set up your service areas.',
        page: Territories,
        type: `${PARENT_NAME}.territories`
    },
    {
        path: `territories/create`,
        icon: 'fas fa-globe',
        header: 'Add New Territory',
        page: CreateTerritory,
        exclude: true,
        parent: {
            path: 'territories',
            name: 'Territories'
        }
    },
    {
        path: `goals`,
        icon: 'fas fa-bullseye-arrow',
        header: 'Goals',
        description: 'Specify your goals.',
        page: React.Fragment,
        type: `${PARENT_NAME}.goals`
    },
    {
        path: `chat`,
        icon: 'fas fa-comments-alt',
        header: 'Chat Settings',
        description: 'Manage your chat rooms and settings.',
        page: React.Fragment,
        type: `${PARENT_NAME}.chat_settings`
    }
];
