import React from 'react';
import { Table } from 'dyl-components';
import { Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { MOCK_LEADS } from '__mocks__/mockData';
import ExportTableRowContainer from './ExportTableRow';

const ExportTable = ({ leads, onMarkAll, allMarked, id }) => (
    <Table striped stackable>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell width={5}>
                    <Checkbox onChange={onMarkAll} checked={allMarked} />
                </Table.HeaderCell>
                <Table.HeaderCell resizable>
                    Received
                </Table.HeaderCell>
                <Table.HeaderCell resizable>
                    Source
                </Table.HeaderCell>
                <Table.HeaderCell resizable width={200}>
                    Contact
                </Table.HeaderCell>
                <Table.HeaderCell resizable>
                    Assigned
                </Table.HeaderCell>
                <Table.HeaderCell resizable>
                    Latest Note
                </Table.HeaderCell>
                <Table.HeaderCell resizable>
                    Actions
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {MOCK_LEADS.map(lead => (
                <ExportTableRowContainer
                    lead={lead}
                    key={lead.id}
                    id={id}
                />
            ))}
        </Table.Body>
    </Table>
)

class ExportTableContainer extends React.Component {

    render() {
        return (
            <ExportTable
                {...this.props}
            />
        )
    }
}

const mapStateToProps = state => {

}

const mapDispatchToProps = (dispatch, ownProps) => ({

})


export default connect(mapStateToProps, mapDispatchToProps)(ExportTableContainer);
