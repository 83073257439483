import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Divider, Form, Grid, Modal, Portal, Segment, Tab } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOMServer from "react-dom/server";

import './index.scss';

import { WebformMenu } from './WebformMenu';
import { FieldToggles } from './FieldToggles';
import { WebformDetails } from './WebformDetails';
import { WebformOptions } from './WebformOptions';
import SuccessMessageForm from './SuccessMessageForm';
import FormSettings from './FormSettings';
import { ActiveFields, Notification, STATUS_TYPES } from 'dyl-components';
import { STATES } from 'shared/constants/STATES';

import WebformTheme from './WebformTheme';
import WebformPreview from './WebformPreview';
import WebformSuccessMessage from './WebformSuccessMessage';

import CustomPrompt from 'shared/confirmation/CustomPrompt';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import webformsActions from 'actions/webforms';
import ConfirmModal from 'shared/confirmation/ConfirmModal';
import useWidthListener from 'shared/SettingsFooter/useWidthListener';
import useWindowWidth from 'shared/SettingsFooter/useWindowWidth';
import { useConfirm } from 'shared/confirmation/useConfirm';
import { MathUtils } from 'utils';

export const WebformCreate = ({ states = STATES }) => {
    const location = useLocation();

    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();

    const { control, formState: { isValid, isDirty }, getValues, setValue, setError, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            activeFields: [],
            internal_form_name: null,
            form_display_name: null,
            success_message: '<p><br></p><h2><strong>Thank you for filling out your information!</strong></h2><p>We will be in touch with you shortly.</p>',
            form_background_color: "#FCFCFC",
            page_background_color: "#EDEDED",
            use_logo: true,
            active: false
        }
    });

    const { isConfirmed } = useConfirm();

    CustomPrompt(null, isDirty, isConfirmed, 'Changes not saved', 'Are you sure you want to exit?');

    const dispatch = useDispatch();

    useEffect(() => {
        if (location.state?.form_id) {
            dispatch(webformsActions.readForm(location.state?.form_id)).then(({
                active,
                cdn_id,
                company_logo,
                display_name,
                fields,
                form_bkg_color,
                page_bkg_color,
                success_msg,
                internal_name
            }) => {
                setValue('active', active);
                setValue('cdn_id', cdn_id);
                setValue('use_logo', company_logo);
                setValue('form_display_name', display_name);
                setValue('activeFields', fields);
                setValue('form_background_color', form_bkg_color);
                setValue('page_background_color', page_bkg_color);
                setValue('success_message', success_msg);
                setValue('internal_form_name', `Duplicate of ${internal_name}`, { shouldDirty: true, shouldValidate: true });
            });
        }
    }, [location.state?.form_id, dispatch, setValue]);

    const [activeTab, setActiveTab] = useState(location.state?.activeTab || 0);
    const [activeFormOption, setActiveFormOption] = useState(location.state?.activeFormOption || 'form_settings');
    const [fieldBeingModified, setFieldBeingModified] = useState(null);
    const [isBeingPreviewed, setIsBeingPreviewed] = useState(false);

    const onChangeActiveTab = (index) => {
        setActiveTab(index);
    }

    const renderFormOption = (control, otherProps = {}) => {
        return (
            <React.Fragment>
                <div {...(activeFormOption !== 'form_settings' ? { style: { display: 'none' } } : {})}>
                    <FormSettings embed={`<iframe src="${otherProps.url}" title="${otherProps.title || ""}" />
                    `} url={otherProps.url} control={control} isFormActive={getValues().active} />
                </div>
                <div {...(activeFormOption !== 'success_message' ? { style: { display: 'none' } } : {})}>
                    <SuccessMessageForm control={control} />
                </div>
                <div {...(activeFormOption !== 'form_theme' ? { style: { display: 'none' } } : {})}>
                    <WebformTheme control={control} />
                </div>
            </React.Fragment>
        )
    }

    const { form_id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (form_id) {
            dispatch(webformsActions.readForm(form_id)).then(({
                active,
                cdn_id,
                company_logo,
                display_name,
                fields,
                form_bkg_color,
                internal_name,
                page_bkg_color,
                success_msg
            }) => {
                reset({
                    active,
                    cdn_id,
                    use_logo: company_logo,
                    form_display_name: display_name,
                    activeFields: fields,
                    form_background_color: form_bkg_color,
                    internal_form_name: internal_name,
                    page_background_color: page_bkg_color,
                    success_message: success_msg
                })
            });
        }
    }, [dispatch, reset, form_id]);

    const [saving, setSaving] = useState(false);

    const isReading = useSelector(state => state.webforms.webformBeingRead);

    const onSave = async () => {
        const {
            activeFields,
            internal_form_name,
            success_message,
            form_background_color,
            page_background_color,
            use_logo,
            form_display_name,
            active,
            cdn_id
        } = getValues();

        const form_html = `
        <html>
            <head>
                <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css" />
                <script
  src="https://code.jquery.com/jquery-3.1.1.min.js"
  integrity="sha256-hVVnYaiADRTO2PzUGmuLJr8BLUSjGIZsDYGmIJLv2b8="
  crossorigin="anonymous"></script>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.js"></script>
            </head>
            <body>
            ${ReactDOMServer.renderToStaticMarkup((
            <div style={{ height: '100vh', backgroundColor: page_background_color }}>
                <WebformPreview
                    activeFields={activeFields}
                    form_background_color={form_background_color}
                    form_display_name={form_display_name}
                    logo={logo}
                    use_logo={use_logo}
                />
            </div>
        ))}
            </body>
            </html>
        `

        const payload = {
            active,
            cdn_id,
            company_logo: use_logo,
            display_name: form_display_name || '',
            fields: activeFields,
            form_bkg_color: form_background_color,
            internal_name: internal_form_name.replace(/\s+/g, ' ').trim(),
            page_bkg_color: page_background_color,
            success_msg: success_message
        }

        await setSaving(true);
        try {
            const { id } = active ? await dispatch(webformsActions.publishForm(null, {
                name: internal_form_name.replace(/\s+/g, ' ').trim(),
                form_html
            })) : { id: cdn_id || "" };

            if (form_id) {
                delete payload.active;
                await dispatch(webformsActions.updateForm(form_id, { ...payload, cdn_id: active ? id : cdn_id }));
                await dispatch(webformsActions.updateStatus(form_id, { active }));
                Notification.alert('Successfully updated webform!', STATUS_TYPES.SUCCESS);
                navigate('/settings/web-forms', { replace: true, state: { saved: true } });
                navigate(`/settings/web-forms/edit/${form_id}`, { replace: true, state: { activeTab, activeFormOption } });
            } else {
                await dispatch(webformsActions.createForms({
                    ...payload,
                    cdn_id: id
                }));
                Notification.alert('Successfully created webform!', STATUS_TYPES.SUCCESS);
                navigate('/settings/web-forms', { replace: true, state: { saved: true } });
            }


        } catch (e) {
            if (e?.Code !== 409) {
                Notification.alert(`Failed to ${form_id ? 'update' : 'create'} webform`, STATUS_TYPES.ERROR);
            } else {
                Notification.alert(`Internal form name already exists`, STATUS_TYPES.ERROR);
                setError("internal_form_name", { type: "unique", message: "Name already exists!" });
            }
        } finally {
            await setSaving(false);
        }
    }

    const onPreview = () => {
        setIsBeingPreviewed(true);
    }

    const logo = useSelector(state => state.company.logo);

    return (
        <React.Fragment>
            <ConfirmModal />
            <Controller
                name='activeFields'
                control={control}
                rules={{
                    validate: {
                        not_empty: (value) => {
                            return (value && value.length > 0) || "This field is requred"
                        }
                    }
                }}
                render={({ field: { onChange, value: activeFields, name } }) => {
                    const onToggleField = (field) => {
                        const isActive = !!activeFields.find(activeField => activeField.name === field.name);
                        if (!isActive) {
                            onChange({
                                target: {
                                    name,
                                    value: [...activeFields, {
                                        ...field,
                                        label: field.label,
                                        required: false,
                                        hidden: false,
                                        original_label: field.label
                                    }]
                                }
                            });
                            setFieldBeingModified(field.name);
                        } else {
                            onChange({
                                target: {
                                    name,
                                    value: activeFields.filter(activeField => activeField.name !== field.name)
                                }
                            });
                            setFieldBeingModified(null);
                        }
                    };
                    return (
                        <Grid className='Webform' columns='equal'>
                            <Grid.Row>
                                <Grid.Column width={4}>
                                    <Segment loading={saving || isReading} textAlign='center' className='Webform__controls'>
                                        <Tab
                                            onTabChange={(_, { activeIndex }) => { onChangeActiveTab(activeIndex); }}
                                            menu={{ size: 'small', className: 'centered Webform__controls-menu', secondary: true, pointing: true, color: 'primary' }}
                                            panes={[
                                                {
                                                    menuItem: 'Add Fields',
                                                    render: () => (
                                                        <React.Fragment>
                                                            <Divider hidden />
                                                            <FieldToggles
                                                                activeFields={activeFields}
                                                                onToggleField={onToggleField}
                                                            />
                                                        </React.Fragment>
                                                    )
                                                },
                                                {
                                                    menuItem: 'Form Options',
                                                    render: () => (
                                                        <WebformOptions activeFormOption={activeFormOption} setActiveFormOption={setActiveFormOption} />
                                                    )
                                                }
                                            ]}
                                            activeIndex={activeTab}
                                        />
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form loading={saving || isReading}>
                                        <div {...(activeTab !== 0 ? { style: { display: 'none' } } : {})}>
                                            <WebformDetails
                                                control={control}
                                            />
                                            <ActiveFields
                                                onChange={(value) => {
                                                    onChange({
                                                        target: {
                                                            name,
                                                            value
                                                        }
                                                    })
                                                }}
                                                activeFields={activeFields}
                                                onToggle={onToggleField}
                                                states={states}
                                                fieldBeingModified={fieldBeingModified}
                                                setFieldBeingModified={(field_name) => {
                                                    setFieldBeingModified(field_name);
                                                }}
                                            />
                                        </div>
                                        <div {...(activeTab !== 1 ? { style: { display: 'none' } } : { style: { paddingLeft: '3em', paddingTop: '1em' } })}>
                                            {renderFormOption(control, { title: getValues().form_display_name, url: getValues().cdn_id ? `https://static.dev.getdyl.com/${getValues().cdn_id}` : '', embed: '' })}
                                        </div>
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    );
                }}
            />
            <Divider hidden />
            <Divider hidden />
            <Modal closeOnEscape size='fullscreen' closeIcon onClose={() => { setIsBeingPreviewed(false); }} style={{ backgroundColor: getValues().page_background_color, width: '100%', height: '100%', top: 0, left: 0, margin: 0 }} open={isBeingPreviewed}>
                <Modal.Content scrolling style={{ maxHeight: '96vh', backgroundColor: getValues().page_background_color }}>
                    <Grid columns='equal'>
                        <Grid.Column />
                        <Grid.Column width={8}>
                            <WebformPreview
                                activeFields={getValues().activeFields}
                                form_background_color={getValues().form_background_color}
                                form_display_name={getValues().form_display_name}
                                logo={logo}
                                use_logo={getValues().use_logo}
                            />
                            <WebformSuccessMessage
                                form_background_color={getValues().form_background_color}
                                success_message={getValues().success_message}
                                logo={logo}
                                use_logo={getValues().use_logo}
                            />
                        </Grid.Column>
                        <Grid.Column />
                    </Grid>
                </Modal.Content>
            </Modal>
            <Portal open>
                <WebformMenu
                    isSaveDisabled={!isDirty || !isValid}
                    onSave={onSave}
                    onPreview={onPreview}
                    isSaving={saving}
                    width={MathUtils.calculatePercentage(windowWidth, windowWidth - width)}
                />
            </Portal>
        </React.Fragment>
    )
}
