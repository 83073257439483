const initialState = {
    isReadingEmails: false,
    email: [],
    count: 0,

    isCreatingEmail: false,
    isUpdatingEmail: false,
    emailBeingDeleted: null,

    emailOptions: {}
};

export default initialState;
