import React from 'react';
import { Header, Icon } from 'semantic-ui-react';
import ErrorContainer from './ErrorContainer';

const WentWrong = () => (
    <ErrorContainer>
        <Header as='h1' size='huge' color='grey' textAlign='center' icon>
            <Icon link name='bug' size='massive' />
            Something Went Wrong
        </Header>
    </ErrorContainer>
);

export default WentWrong;
