import types from './authTypes';
import axios from 'actions/axiosInstance';
import { Notification, STATUS_TYPES } from 'dyl-components';
import routes from 'actions/routes';
import { generateReadActionCreator, generateCreateActionCreator, generateUpdateActionCreator, generateReadOneActionCreator, generateDeleteActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

const login = generateCreateActionCreator(routes.AUTH_LOGIN, ACTION_NAMES.LOGIN, () => { }, () => {
  Notification.alert('Problem logging in!', STATUS_TYPES.ERROR, true);
});

const createUser = generateCreateActionCreator(routes.AUTH_USER, ACTION_NAMES.AUTH_USER);
const logout = generateReadActionCreator(routes.AUTH_LOGOUT, ACTION_NAMES.LOGOUT);

const check = () => {
  return dispatch => {

    dispatch({
      type: types.CHECK_REQUEST
    });

    return axios.instance1.get(routes.AUTH_CHECK)
      .then(function (response) {
        if (response.data.isAuthenticated) {
          dispatch({
            type: types.CHECK_SUCCESS,
            data: response.data
          });

          return Promise.resolve(response.data);
        } else {
          dispatch({
            type: types.CHECK_FAILURE
          });
        }

      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.CHECK_FAILURE
        });
      });

  };
}
const deactivateUser = generateDeleteActionCreator(routes.AUTH_DEACTIVATE_USER, ACTION_NAMES.AUTH_DEACTIVATE_USER);
const reactivateUser = generateUpdateActionCreator(routes.AUTH_REACTIVATE_USER, ACTION_NAMES.AUTH_REACTIVATE_USER, () => {}, () => {}, '', true);
const resetPassword = generateCreateActionCreator(routes.AUTH_RESET_PASSWORD, ACTION_NAMES.AUTH_RESET_PASSWORD);
const sendPasswordRecoveryLink = generateReadActionCreator(routes.AUTH_SEND_PASSWORD_RECOVERY_LINK, ACTION_NAMES.AUTH_SEND_PASSWORD_RECOVERY_LINK);
const verifyCode = generateReadOneActionCreator(routes.AUTH_VERIFY_CODE, ACTION_NAMES.AUTH_VERIFY_CODE);

const getIP = () => {
  const publicIp = require('public-ip');

  return dispatch => {
    dispatch({
      type: types.GET_IP_SUCCESS
    });

    return publicIp.v4()
  }
}

export default {
  login,
  check,
  logout,
  getIP,
  createUser,
  verifyCode,
  resetPassword,
  deactivateUser,
  reactivateUser,
  sendPasswordRecoveryLink,
  updateAccessRole: generateUpdateActionCreator(routes.AUTH_ROLE, ACTION_NAMES.AUTH_ROLE)
};
