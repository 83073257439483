import { Activities, AddProduct, EditProduct, DuplicateProduct, AddAddon, DuplicateAddOn, EditAddon } from 'pages';
import AddFee from 'shared/forms/AddFee';
import DuplicateFee from 'shared/forms/DuplicateFee';
import EditFee from 'shared/forms/EditFee';

const PARENT_NAME = "crm_customization";

export const CRM_CUSTOMIZATIONS_SETTINGS = [
    {
        path: `activities`,
        icon: 'fas fa-calendar-check',
        header: 'Activities',
        description: 'Customize your tasks and events.',
        page: Activities,
        type: `${PARENT_NAME}.activities`
    },
    {
        path: `library/text-templates`,
        icon: 'fas fa-book',
        header: 'Library',
        description: 'Store and edit email and text templates and dialer scripts.',
        page: null
    },
    {
        path: `product-catalog-quotes-invoices/product-catalog`,
        icon: 'fas fa-box-open',
        header: 'Product Catalog, Quotes & Invoices',
        description: 'Organize and edit your product details.',
        page: null,
    },
    {
        path: `product-catalog-quotes-invoices/create`,
        icon: 'fas fa-box-open',
        header: 'Create Product',
        description: 'Organize and edit your product details.',
        page: AddProduct,
        exclude: true,
        parent: {
            path: 'product-catalog-quotes-invoices/product-catalog',
            name: 'Product Catalog, Quotes & Invoices'
        }
    },
    {
        path: `product-catalog-quotes-invoices/product/:id`,
        icon: 'fas fa-box-open',
        header: 'Edit Product',
        description: 'Organize and edit your product details.',
        page: EditProduct,
        exclude: true,
        parent: {
            path: 'product-catalog-quotes-invoices/product-catalog',
            name: 'Product Catalog, Quotes & Invoices'
        }
    },
    {
        path: `product-catalog-quotes-invoices/duplicate-product/:id`,
        icon: 'fas fa-box-open',
        header: 'Duplicate Product',
        description: 'Organize and edit your product details.',
        page: DuplicateProduct,
        exclude: true,
        parent: {
            path: 'product-catalog-quotes-invoices/product-catalog',
            name: 'Product Catalog, Quotes & Invoices'
        }
    },
    {
        path: `pipelines/lead-pipelines`,
        icon: 'fas fa-funnel-dollar',
        header: 'Pipeline',
        description: 'Edit and manage your pipeline stages.',
        page: null
    },
    {
        path: `data-customization/contact`,
        icon: 'fas fa-pencil-ruler',
        header: 'Data customization',
        description: 'Create custom data for contacts, leads, opportunities and customers.',
        page: null
    },
    {
        path: `product-catalog-quotes-invoices/addons/create`,
        icon: 'fas fa-box-open',
        header: 'Create Add-On',
        description: '',
        page: AddAddon,
        exclude: true,
        parent: {
            path: 'product-catalog-quotes-invoices/addons',
            name: 'Product Catalog, Quotes & Invoices'
        },
        subroute: {
            path: 'product-catalog-quotes-invoices/addons',
            name: 'Add-Ons'
        }
    },
    {
        path: `product-catalog-quotes-invoices/addons/:id`,
        icon: 'fas fa-box-open',
        header: 'Edit Addon',
        description: 'Organize and edit your addon details.',
        page: EditAddon,
        exclude: true,
        parent: {
            path: 'product-catalog-quotes-invoices/product-catalog',
            name: 'Product Catalog, Quotes & Invoices'
        },
        subroute: {
            path: 'product-catalog-quotes-invoices/addons',
            name: 'Add-Ons'
        }
    },
    {
        path: `product-catalog-quotes-invoices/duplicate-addon/:id`,
        icon: 'fas fa-box-open',
        header: 'Duplicate Addon',
        description: 'Organize and edit your addon details.',
        page: DuplicateAddOn,
        exclude: true,
        parent: {
            path: 'product-catalog-quotes-invoices/product-catalog',
            name: 'Product Catalog, Quotes & Invoices'
        },
        subroute: {
            path: 'product-catalog-quotes-invoices/addons',
            name: 'Add-Ons'
        }
    },
    {
        path: `product-catalog-quotes-invoices/fees/create`,
        icon: 'fas fa-box-open',
        header: 'Create Fee',
        description: '',
        page: () => <AddFee type={"fee"} />,
        exclude: true,
        parent: {
            path: 'product-catalog-quotes-invoices/product-catalog',
            name: 'Product Catalog, Quotes & Invoices'
        },
        subroute: {
            path: 'product-catalog-quotes-invoices/fees',
            name: 'Fees'
        }
    },
    {
        path: `product-catalog-quotes-invoices/taxes/create`,
        icon: 'fas fa-box-open',
        header: 'Create Tax',
        description: '',
        page: () => <AddFee type={"tax"} />,
        exclude: true,
        parent: {
            path: 'product-catalog-quotes-invoices/product-catalog',
            name: 'Product Catalog, Quotes & Invoices'
        },
        subroute: {
            path: 'product-catalog-quotes-invoices/taxes',
            name: 'Taxes'
        }
    },
    {
        path: `product-catalog-quotes-invoices/duplicate-fee/:id`,
        icon: 'fas fa-box-open',
        header: 'Duplicate Fee',
        description: 'Organize and edit your fee details.',
        page: () => <DuplicateFee type="fee" />,
        exclude: true,
        parent: {
            path: 'product-catalog-quotes-invoices/product-catalog',
            name: 'Product Catalog, Quotes & Invoices'
        },
        subroute: {
            path: 'product-catalog-quotes-invoices/fees',
            name: 'Fees'
        }
    },
    {
        path: `product-catalog-quotes-invoices/duplicate-tax/:id`,
        icon: 'fas fa-box-open',
        header: 'Duplicate Tax',
        description: 'Organize and edit your tax details.',
        page: () => <DuplicateFee type="tax" />,
        exclude: true,
        parent: {
            path: 'product-catalog-quotes-invoices/product-catalog',
            name: 'Product Catalog, Quotes & Invoices'
        },
        subroute: {
            path: 'product-catalog-quotes-invoices/taxes',
            name: 'Taxes'
        }
    },
    {
        path: `product-catalog-quotes-invoices/fees/:id`,
        icon: 'fas fa-box-open',
        header: 'Edit Fee',
        description: 'Organize and edit your fee details.',
        page: () => <EditFee type="fee" />,
        exclude: true,
        parent: {
            path: 'product-catalog-quotes-invoices/product-catalog',
            name: 'Product Catalog, Quotes & Invoices'
        },
        subroute: {
            path: 'product-catalog-quotes-invoices/fees',
            name: 'Fees'
        }
    },
    {
        path: `product-catalog-quotes-invoices/taxes/:id`,
        icon: 'fas fa-box-open',
        header: 'Edit Tax',
        description: 'Organize and edit your tax details.',
        page: () => <EditFee type="tax" />,
        exclude: true,
        parent: {
            path: 'product-catalog-quotes-invoices/product-catalog',
            name: 'Product Catalog, Quotes & Invoices'
        },
        subroute: {
            path: 'product-catalog-quotes-invoices/taxes',
            name: 'Taxes'
        }
    },
];
