import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Button } from 'dyl-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const UserAccountsToolbar = () => {
    const canAddTerritories = useSelector((state) => (state.territory.territories || []).length < 25);

    return (
        <div className='TerritoryOptions'>
            <Button disabled={!canAddTerritories} as={Link} to='/settings/territories/create' className='TerritoryOptions__NewTerritory'>
                <Icon name='plus' /> New Territory
            </Button>
        </div>
    );
}

export default UserAccountsToolbar;
