import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import initialState from "./initialState";
import ACTION_NAMES from "actions/ACTION_NAMES";

function sequencesReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.IS_UNIQUE_SEQUENCE, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isCheckingUniqueness: true };
        case getNamedAction(ACTION_NAMES.IS_UNIQUE_SEQUENCE, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isCheckingUniqueness: false };
        case getNamedAction(ACTION_NAMES.IS_UNIQUE_SEQUENCE, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isCheckingUniqueness: false };

        case getNamedAction(ACTION_NAMES.SEQUENCE, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingSequences: true, sequences: [], sequence_count: 0 };
        case getNamedAction(ACTION_NAMES.SEQUENCE, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingSequences: false, sequences: action.data?.data || [], sequence_count: action.data.count };
        case getNamedAction(ACTION_NAMES.SEQUENCE, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingSequences: false, sequences: [], sequence_count: 0 };

        case getNamedAction(ACTION_NAMES.SEQUENCE_SHORT, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingSequencesShort: true, sequencesShort: [], sequence_short_count: 0 };
        case getNamedAction(ACTION_NAMES.SEQUENCE_SHORT, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingSequencesShort: false, sequencesShort: action.data?.data || [], sequence_short_count: action.data.count };
        case getNamedAction(ACTION_NAMES.SEQUENCE_SHORT, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingSequencesShort: false, sequencesShort: [], sequence_short_count: 0 };

        case getNamedAction(ACTION_NAMES.SEQUENCE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreating: true };
        case getNamedAction(ACTION_NAMES.SEQUENCE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreating: false };
        case getNamedAction(ACTION_NAMES.SEQUENCE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreating: false };

        case getNamedAction(ACTION_NAMES.ADD_TO_SEQUENCE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingContactToSequence: true };
        case getNamedAction(ACTION_NAMES.ADD_TO_SEQUENCE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAddingContactToSequence: false };
        case getNamedAction(ACTION_NAMES.ADD_TO_SEQUENCE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingContactToSequence: false };

        case getNamedAction(ACTION_NAMES.SEQUENCE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, sequence: null, isReadingSequence: true, sequenceError: false };
        case getNamedAction(ACTION_NAMES.SEQUENCE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, sequence: action.data, isReadingSequence: false };
        case getNamedAction(ACTION_NAMES.SEQUENCE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, sequence: null, isReadingSequence: false, sequenceError: true };

        case getNamedAction(ACTION_NAMES.SEQUENCE, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, sequenceBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.SEQUENCE, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, sequenceBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.SEQUENCE, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, sequenceBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.SEQUENCE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, sequenceBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.SEQUENCE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, sequenceBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.SEQUENCE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, sequenceBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.SEQUENCE_USERS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingSequenceUsers: true, sequence_users: [], sequence_user_count: 0 };
        case getNamedAction(ACTION_NAMES.SEQUENCE_USERS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingSequenceUsers: false, sequence_users: action.data, sequence_user_count: action.data.length };
        case getNamedAction(ACTION_NAMES.SEQUENCE_USERS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingSequenceUsers: false, sequence_users: [], sequence_user_count: 0 };

        default:
            return state;
    }
}

export default sequencesReducer;
