import {
    Document,
    Page,
    Text,
    View,
} from "@react-pdf/renderer";
import { DateTimeUtils } from "dyl-components";
import Logo from "shared/PDFViewer/Logo";
import PersonInformation from "shared/PDFViewer/PersonInformation";
import { styles } from "shared/PDFViewer/styles";
import { MathUtils, PhoneUtil, StringUtils } from "utils";

const Addons = ({ addons, index, quantity }) =>
    addons.map((addon) => (
        <View key={`item-${index}-${addon.addon_id}`} style={styles.tableRow}>
            <View style={styles.tableCol}>
                <Text
                    style={{
                        ...styles.tableCell,
                        fontWeight: 750,
                        marginLeft: "10pt",
                    }}
                >
                    {addon.name}
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{addon.description}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                    ${StringUtils.formatDecimal(`${addon.price}`)}
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{quantity}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                    ${StringUtils.formatDecimal(`${addon.price * quantity}`)}
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
            </View>
        </View>
    ));

const Fees = ({ fees, type = "fee", grossTotal, index, quantity }) =>
    fees.map((fee, feeIndex) => (
        <View key={`item-${index}-${type}-${feeIndex}`} style={styles.tableRow}>
            <View style={styles.tableCol}>
                <Text
                    style={{
                        ...styles.tableCell,
                        fontWeight: 750,
                        marginLeft: "10pt",
                    }}
                >
                    *{fee.name}
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{fee.description}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                    {!fee.percent ? "$" : ""}
                    {fee.amount}
                    {fee.percent ? "%" : ""}
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{quantity}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                    $
                    {StringUtils.formatDecimal(
                        `${MathUtils.roundDecimals(
                            fee.amount *
                                (fee.percent ? 0.01 * grossTotal : quantity)
                        )}`
                    )}
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
            </View>
        </View>
    ));

const Summary = ({ cart }) => {
    const { grossTotal, totalDiscount, totalTax, totalFee, subtotal } =
        cart.reduce(
            (a, item) => {
                const price = item.item.price;
                const {
                    grossTotal,
                    totalTax,
                    totalFee,
                    subtotal,
                    discountAmount,
                } = MathUtils.calculateItemTotals({
                    price,
                    additional_price: item.item.variant?.additional_price || 0,
                    quantity: item.item.quantity,
                    discount: item.item.discount,
                    addons: item.addons,
                    taxes: item.taxes,
                    fees: item.fees,
                });

                return {
                    grossTotal: a.grossTotal + grossTotal,
                    totalDiscount: a.totalDiscount + discountAmount,
                    totalTax: a.totalTax + totalTax,
                    totalFee: a.totalFee + totalFee,
                    subtotal: a.subtotal + subtotal,
                };
            },
            {
                grossTotal: 0,
                totalDiscount: 0,
                totalTax: 0,
                totalFee: 0,
                subtotal: 0,
            }
        );

    return (
        <View
            style={{
                border: "1pt solid rgba(191, 191, 191)",
                padding: 20,
                width: "40%",
            }}
        >
            <View style={{ ...styles.grid, ...styles.divider }}>
                <View>
                    <Text style={styles.header}>Today's Quote</Text>
                </View>
            </View>
            <View
                style={{
                    ...styles.grid,
                    ...styles.divider,
                    justifyContent: "space-between",
                }}
            >
                <View>
                    <Text style={{ ...styles.textMedium }}>Total</Text>
                </View>
                <View>
                    <Text style={{ ...styles.textMedium, color: "#2b78ff" }}>
                        $
                        {`${StringUtils.formatDecimal(
                            `${MathUtils.roundDecimals(grossTotal)}`,
                            true
                        )}`}
                    </Text>
                </View>
            </View>
            <View style={{ ...styles.grid, justifyContent: "space-between" }}>
                <View>
                    <Text style={{ ...styles.textMedium }}>Discount</Text>
                </View>
                <View>
                    <Text style={{ ...styles.textMedium, color: "#2b78ff" }}>
                        -$
                        {StringUtils.formatDecimal(
                            `${MathUtils.roundDecimals(totalDiscount)}`,
                            true
                        )}
                    </Text>
                </View>
            </View>
            <View style={{ ...styles.grid, justifyContent: "space-between" }}>
                <View>
                    <Text style={{ ...styles.textMedium }}>Total Fee</Text>
                </View>
                <View>
                    <Text style={{ ...styles.textMedium, color: "#2b78ff" }}>
                        $
                        {StringUtils.formatDecimal(
                            `${MathUtils.roundDecimals(totalFee)}`,
                            true
                        )}
                    </Text>
                </View>
            </View>
            <View
                style={{
                    ...styles.grid,
                    ...styles.divider,
                    justifyContent: "space-between",
                }}
            >
                <View>
                    <Text style={{ ...styles.textMedium }}>Total Tax</Text>
                </View>
                <View>
                    <Text style={{ ...styles.textMedium, color: "#2b78ff" }}>
                        $
                        {StringUtils.formatDecimal(
                            `${MathUtils.roundDecimals(totalTax)}`,
                            true
                        )}
                    </Text>
                </View>
            </View>
            <View style={{ ...styles.grid, justifyContent: "space-between" }}>
                <View>
                    <Text style={{ ...styles.textMedium }}>Subtotal</Text>
                </View>
                <View>
                    <Text style={{ ...styles.textMedium, color: "#2b78ff" }}>
                        $
                        {StringUtils.formatDecimal(
                            `${MathUtils.roundDecimals(subtotal)}`,
                            true
                        )}
                    </Text>
                </View>
            </View>
        </View>
    );
};

const QuotePDF = ({ quoteVersion }) => {
    const quote_information = quoteVersion.quote_information;
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View
                    style={{ ...styles.grid, justifyContent: "space-between" }}
                >
                    <View>
                        <Logo />
                    </View>
                    <View>
                        <Text style={styles.text}>
                            Date:{" "}
                            {DateTimeUtils.changeFormat(
                                DateTimeUtils.convertUnixTimeToDate(
                                    quote_information.saved_time
                                ),
                                "",
                                DateTimeUtils.DATE_FORMAT
                            )}
                        </Text>
                        <Text style={styles.text}>
                            Expires On:{" "}
                            {quote_information.expires
                                ? DateTimeUtils.changeFormat(
                                      DateTimeUtils.convertUnixTimeToDate(
                                          quote_information.expires,
                                          "UTC"
                                      ),
                                      "",
                                      DateTimeUtils.DATE_FORMAT,
                                      "UTC"
                                  )
                                : "NA"}
                        </Text>
                    </View>
                </View>
                <View style={styles.grid}>
                    <PersonInformation
                        info={{
                            heading: `From: ${quote_information.from_information.user_name}`,
                            phone: PhoneUtil.formatPhoneNumber(
                                quote_information.from_information.phone
                            ),
                            email: quote_information.from_information.email,
                            location:
                                quote_information.from_information?.location,
                        }}
                    />
                    <PersonInformation
                        info={{
                            heading: `To: ${[quote_information?.first_name, quote_information?.last_name, quote_information?.suffix].filter(part => part).join(" ")}`,
                            phone: PhoneUtil.formatPhoneNumber(
                                quote_information.contact_info?.phone
                            ),
                            email: quote_information.contact_info?.email,
                            location: quote_information.contact_info?.address,
                        }}
                    />
                </View>
                <View>
                    <Text style={{ ...styles.header, color: "#2b78ff" }}>
                        Summary
                    </Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Products</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>
                                Description
                            </Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>
                                Pricing Schedule
                            </Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Price</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Quantity</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Sum</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Discount</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Subtotal</Text>
                        </View>
                    </View>
                    {quoteVersion.cart_item_summary.map((item, index) => {
                        const price = item.item.price;
                        const { grossTotal, subtotal, sum } =
                            MathUtils.calculateItemTotals({
                                price,
                                additional_price:
                                    item.item.variant?.additional_price || 0,
                                quantity: item.item.quantity,
                                discount: item.item.discount,
                                addons: item.addons,
                                taxes: item.taxes,
                                fees: item.fees,
                            });
                        const variant = item.item?.variant || {};
                        return (
                            <>
                                <View
                                    key={`item-${index}`}
                                    style={styles.tableRow}
                                >
                                    <View style={styles.tableCol}>
                                        <Text
                                            style={{
                                                ...styles.tableCell,
                                                fontWeight: 750,
                                            }}
                                        >
                                            <Text>
                                                {index + 1}. {item.item.name}{" "}
                                            </Text>
                                            <Text
                                                style={{
                                                    fontSize: 5,
                                                    marginLeft: "8pt",
                                                }}
                                            >
                                                {Boolean(
                                                    Object.keys(variant).length
                                                ) &&
                                                    `(${variant.name}/${variant.value})`}
                                            </Text>
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            {item.item.description}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            {StringUtils.capitalize(
                                                item.item.price_schedule
                                            )}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            $
                                            {StringUtils.formatDecimal(
                                                `${item.item.price}`
                                            )}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            {item.item.quantity}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            ${sum}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            {item.item.discount
                                                ? `${item.item.discount}%`
                                                : ""}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            ${MathUtils.roundDecimals(subtotal)}
                                        </Text>
                                    </View>
                                </View>
                                <Addons
                                    addons={item.addons}
                                    index={index}
                                    quantity={item.item.quantity}
                                />
                                <Fees
                                    fees={item.fees}
                                    grossTotal={grossTotal}
                                    index={index}
                                    quantity={item.item.quantity}
                                />
                                <Fees
                                    fees={item.taxes}
                                    grossTotal={grossTotal}
                                    index={index}
                                    quantity={item.item.quantity}
                                    type="tax"
                                />
                            </>
                        );
                    })}
                </View>
                <View
                    style={{
                        ...styles.grid,
                        justifyContent: "flex-end",
                        marginTop: 10,
                    }}
                >
                    <Summary cart={quoteVersion.cart_item_summary} />
                </View>
            </Page>
        </Document>
    );
};

export default QuotePDF;
