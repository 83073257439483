import React from 'react';
import { STATUS_TYPES, Notification } from 'dyl-components';

import RingGroupMembersContainer from 'shared/RingGroupMembers';
import RingOptions from 'shared/RingOptions';

class RingOptionsContainer extends React.Component {
    state = {
        info: {
            name: 'Ring First-IVR',
            ringMode: 'Ring All',
            ringTime: 30,
            fallbackExtension: 'Hangup',
            internalExtension: ''
        }
    }

    onUpdate = (newInfo) => {
        this.setState({
            info: newInfo
        }, () => {
            Notification.alert('Succefully updated ring group!', STATUS_TYPES.SUCCESS, true);
        });
    }

    render() {
        return (
            <RingOptions 
                {...this.state}
                onUpdate={this.onUpdate}
            />
        )
    }
}


const RingGroup = () => (
    <React.Fragment>
        <RingOptionsContainer />
        <RingGroupMembersContainer />
    </React.Fragment>
);

export default RingGroup;
