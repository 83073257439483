import routes from 'actions/routes';
import { generateUpdateActionCreator, generateDeleteActionCreator, generateCreateActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

export default {
    updateField: generateUpdateActionCreator(routes.API_CUSTOM_FIELDS_GROUPS, ACTION_NAMES.CUSTOM_FIELD, () => {}, () => {}, 'field'),
    deleteField: generateDeleteActionCreator(routes.API_CUSTOM_FIELDS_GROUPS, ACTION_NAMES.CUSTOM_FIELD, () => {}, () => {}, 'field'),
    createFieldOptions: generateCreateActionCreator(routes.API_CUSTOM_FIELD, ACTION_NAMES.CUSTOM_FIELD, () => {}, () => {}, 'options'),
    removeFieldOption: generateDeleteActionCreator(routes.API_CUSTOM_FIELD_OPTIONS, ACTION_NAMES.CUSTOM_FIELD_OPTION),
    reactivateField: generateUpdateActionCreator(routes.API_CUSTOM_FIELD, ACTION_NAMES.CUSTOM_FIELD_REACTIVATE)
};
