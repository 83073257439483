import { Controller, useFormContext } from "react-hook-form";
import { Form } from "semantic-ui-react";

const ScheduleField = ({ model }) => {
    const { control, setValue, trigger } = useFormContext();
    if (!model || model === "usage") {
        return null;
    }
    return (
        <Controller
            name="price_data.schedule"
            control={control}
            render={({
                field: { name, value: schedule, onChange: onChangeSchedule },
            }) => (
                <Form.Select
                    name={name}
                    value={schedule}
                    onChange={(_, { name, value }) => {
                        onChangeSchedule({
                            target: { name, value },
                        });
                        if (model === "volume" && value === "one-time") {
                            setValue("price_data.volume_prices", [
                                {
                                    start: 1,
                                    end: null,
                                    price: null,
                                },
                            ]);
                        } else if (model === "volume" && value === "recurring") {
                            [
                                "monthly",
                                "quarterly",
                                "semi-annually",
                                "annually",
                            ].forEach(frequency => {
                                setValue(`price_data.volume_based_recurring_${frequency}_volume_prices`, [
                                    {
                                        start: 1,
                                        end: null,
                                        price: null,
                                    },
                                ])
                            })
                        } else if (model === "fixed" && value === "recurring") {
                            trigger("price_data.fixed_recurring_frequencies");
                        }
                    }}
                    label="Pricing Schedule"
                    required
                    options={[
                        {
                            key: "one-time",
                            value: "one-time",
                            text: "One-time",
                        },
                        {
                            key: "recurring",
                            value: "recurring",
                            text: "Recurring",
                        },
                    ]}
                    selectOnBlur={false}
                />
            )}
        />
    );
};

export default ScheduleField;
