import React from 'react';
import { TableWithHeader } from 'dyl-components';
import TaskTypeHeader from './subcomponents/TaskTypeHeader';
import TaskTypesTable from './subcomponents/TaskTypesTable';

const TaskTypes = ({ 
    onOpenAddTaskTypeSection,
    onCloseAddTaskTypeSection,
    isAddTaskTypeSectionOpen
}) => (
    <TableWithHeader 
        header={
            <TaskTypeHeader 
                onOpenAddTaskTypeSection={onOpenAddTaskTypeSection}
                onCloseAddTaskTypeSection={onCloseAddTaskTypeSection}
                isAddTaskTypeSectionOpen={isAddTaskTypeSectionOpen}
            />
        }
        table={
            <TaskTypesTable />
        }
    />
)

class TaskTypeContainer extends React.Component {
    state = {
        isAddTaskTypeSectionOpen: false
    }

    onOpenAddTaskTypeSection = () => {
        this.setState({ isAddTaskTypeSectionOpen: true });
    }

    onCloseAddTaskTypeSection = () => {
        this.setState({ isAddTaskTypeSectionOpen: false });
    }

    render() {
        return (
            <TaskTypes 
                isAddTaskTypeSectionOpen={this.state.isAddTaskTypeSectionOpen}
                onOpenAddTaskTypeSection={this.onOpenAddTaskTypeSection}
                onCloseAddTaskTypeSection={this.onCloseAddTaskTypeSection}
            />
        )
    }
}

export default TaskTypeContainer;
