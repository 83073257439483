import React from 'react';
import { Grid, Input, Icon } from 'semantic-ui-react';
import { Button, STATUS_TYPES } from 'dyl-components';

import { EditableContent } from 'dyl-components';

const LoginInfo = ({ 
    info, 
    isEditMode, 
    isPasswordVisible, 
    onTogglePasswordVisibility, 
    onUpdate, 
    onEnableEditMode, 
    onDisableEditMode,
    onChange 
}) => (
    <Grid>
        <Grid.Row columns={2}>
            <Grid.Column>
                ID
            </Grid.Column>
            <Grid.Column>
                {info.id}
            </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
            <Grid.Column>
                Username
            </Grid.Column>
            <Grid.Column>
                <EditableContent
                    control={Input}
                    isEditMode={isEditMode}
                    name='username'
                    value={info.username}
                    onChange={onChange}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
            <Grid.Column>
                Password
            </Grid.Column>
            <Grid.Column>
                <EditableContent
                    control={Input}
                    isEditMode={isEditMode}
                    name='password'
                    type={isPasswordVisible ? 'text' : 'password'}
                    value={!isPasswordVisible && !isEditMode ? '***********' : info.password}
                    onChange={onChange}
                />&nbsp;
                <Icon
                    name={isPasswordVisible ? 'eye slash' : 'eye'}
                    link
                    onClick={onTogglePasswordVisibility}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
            <Grid.Column>
                URL
            </Grid.Column>
            <Grid.Column>
                <EditableContent
                    control={Input}
                    isEditMode={isEditMode}
                    value={info.url}
                    name='url'
                    onChange={onChange}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
            <Grid.Column>
                Verified: <Icon name='check' color='green' />
            </Grid.Column>
            <Grid.Column textAlign='right'>
                {isEditMode && (
                    <Button status={STATUS_TYPES.ERROR} onClick={onDisableEditMode}>
                        Cancel
                    </Button>
                )}
                <Button status={STATUS_TYPES.PRIMARY} onClick={isEditMode ? onUpdate : onEnableEditMode}>
                    {isEditMode ? 'Update' : 'Edit'}
                </Button>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

class LoginInfoContainer extends React.Component {
    state = {
        isEditMode: false,
        info: this.props.info,
        isPasswordVisible: false
    }

    onEnableEditMode = () => {
        this.setState({ isEditMode: true });
    }

    onDisableEditMode = () => {
        this.setState({ isEditMode: false, info: this.props.info});
    }

    onChange = (_, { name, value }) => {
        let info = { ...this.state.info, [name]: value};
        this.setState({ info });
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false
        }, () => {
            this.props.onUpdate(this.state.info);
        })
    }

    onTogglePasswordVisibility = () => {
        this.setState({
            isPasswordVisible: !this.state.isPasswordVisible
        })
    }

    render() {
        return (
            <LoginInfo 
                {...this.state}
                onChange={this.onChange}
                onEnableEditMode={this.onEnableEditMode}
                onDisableEditMode={this.onDisableEditMode}
                onTogglePasswordVisibility={this.onTogglePasswordVisibility}
                onUpdate={this.onUpdate}
            />
        )
    }
}

class LoginInfoInstance extends React.Component {
    state = {
        info: {
            username: 'dyl400',
            password: 'Vertafore',
            id: '8999188-24',
            url: 'http://example.com'
        }
    }

    onUpdate = (updatedInfo) => {
        let info = {...this.state.info, ...updatedInfo};
        this.setState({ info });
    }

    render() {
        return (
            <LoginInfoContainer 
                {...this.state}
                onUpdate={this.onUpdate}
            />
        )
    }
}

export default LoginInfoInstance;
