import React from 'react';
import { Form, Icon } from 'semantic-ui-react';

import './SearchBar.scss';

const SearchBar = ({ primary, isValidNumber, onInputNumber, phoneNumber, disabled }) => (
    <Form>
        <Form.Input
            disabled={disabled}
            className={`SearchBar ${isValidNumber ? 'SearchBar--valid' : 'SearchBar--invalid'}`}
            placeholder={'Enter Number or Name'}
            fluid
            icon={<Icon name='phone square' color={isValidNumber ? primary ? 'green' : 'primary' : ''} size='large' link={isValidNumber} />}
            onChange={(_, { value }) => {onInputNumber(value)}}
            value={phoneNumber}
        />
    </Form>
);

export default SearchBar;
