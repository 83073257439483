import React from 'react';
import { Grid, Button } from 'semantic-ui-react';

const RingGroupsHeader = ({ onOpenAddRingGroupsSection }) => (
    <Grid verticalAlign='middle'>
        <Grid.Column width={6}>
            Ring Groups
        </Grid.Column>
        <Grid.Column width={4} textAlign='right' floated='right'>
        <Button onClick={onOpenAddRingGroupsSection} size='tiny' basic inverted color='white'>Add Ring Group</Button>
        </Grid.Column>
    </Grid>
)

export default RingGroupsHeader;
