import React, { useState } from 'react';
import { Table, EllipsisDropdown, ControlledPopup } from 'dyl-components';
import { Icon, Dropdown, Header, Popup } from 'semantic-ui-react';
import StagePopup from '../StagePopup';

const PipelineStageRow = ({
    pipelineStage,
    disabledDeleteBtn,
    onDeleteStage,
    pipeline_id,
    isStageDuplicate,
    refresh
}) => {
    const [isStagePopupOpen, setIsStagePopupOpen] = useState(false);

    const ELLIPSIS_OPTIONS = [
        {
            id: 1,
            text: 'Edit',
            icon: 'fas fa-edit',
            onClick: () => { setIsStagePopupOpen(true) }
        },
        {
            id: 2,
            text: 'Delete',
            icon: 'fas fa-trash-alt',
            onClick: () => {onDeleteStage(pipeline_id, pipelineStage.id)},
            disabled: disabledDeleteBtn
        },
    ];

    const DropdownItem = ({ text, icon, displaysPopup, popupProps, ...otherProps }) => {
        const DropdownModel = () => {
            return (
                <Dropdown.Item text={<React.Fragment>
                    <Icon name={icon} />
                    {text}
                </React.Fragment>}
                    style={{ cursor: 'pointer' }}
                    {...otherProps}
                />
            );
        }

        if (displaysPopup === true) {
            return (
                <ControlledPopup
                    header={
                        <Header as='h4' textAlign='center'>
                            {popupProps.header}
                            <Header.Subheader style={{ marginTop: 5 }}>
                                {popupProps.subheader}
                            </Header.Subheader>
                        </Header>
                    }
                    trigger={
                        DropdownModel()
                    }
                    onConfirm={popupProps.onConfirm}
                />
            )
        } else {
            return (
                <DropdownModel />
            );
        }
    }

    return (
        <Table.Row>
            <Table.Cell width={1}>
            </Table.Cell>
            <Table.Cell width={4}>
                <div className='LeadStageRow__NameContainer'>
                    <p className='LeadStageRow__NameElement'>{pipelineStage.position}</p>
                    <p className='LeadStageRow__NameElement'>{pipelineStage.name}</p>
                </div>
            </Table.Cell>
            <Table.Cell width={4}>
                <div className="Pipeline__description">
                    {pipelineStage.description}
                </div>
            </Table.Cell>
            <Table.Cell width={4}>
                {pipelineStage.days}
            </Table.Cell>
            <Table.Cell width={3}>
                <Popup
                    trigger={
                        <EllipsisDropdown {...isStagePopupOpen ? { isDropdownOpen: false } : {}}>
                            {ELLIPSIS_OPTIONS.map(({ text, icon, displaysPopup, popupProps, disabled, onClick, ...otherProps }, idx) => {
                                if (idx === 1) {
                                    return (
                                        <ControlledPopup 
                                            trigger={<Dropdown.Item icon={icon} text={text} disabled={disabled} />}
                                            content={text}
                                            onConfirm={onClick}
                                            inverted
                                        />
                                    )
                                }
                                return (
                                    <DropdownItem
                                        key={idx}
                                        text={text}
                                        icon={icon}
                                        displaysPopup={displaysPopup}
                                        popupProps={popupProps}
                                        disabled={disabled}
                                        onClick={onClick}
                                        {...otherProps} />
                                );
                            })}
                        </EllipsisDropdown>
                    }
                    on='click'
                    content={
                        <StagePopup
                            refresh={refresh}
                            onClose={() => setIsStagePopupOpen(false)}
                            stage_id={pipelineStage.id}
                            isStageDuplicate={isStageDuplicate}
                            days={pipelineStage.days}
                            description={pipelineStage.description}
                            name={pipelineStage.name}
                            sequence_id={pipelineStage.sequence_id}
                        />
                    }
                    open={isStagePopupOpen}
                    onClose={() => setIsStagePopupOpen(false)}
                    wide='very'
                    position='bottom left'
                />

            </Table.Cell>
        </Table.Row>
    );
}

export default PipelineStageRow;
