import React from "react";
import { Form } from "semantic-ui-react";
import { FileInput, Notification, STATUS_TYPES } from "dyl-components";

const AddAudioFileSection = ({
    name,
    files,
    isVolumeReduced,
    onChange,
    onAddAudioFile,
    onClose,
}) => (
    <React.Fragment>
        <Form>
            <Form.Input
                label="Prompt Name"
                name="name"
                value={name}
                onChange={onChange}
            />
            <Form.Checkbox
                label="Reduce Volume"
                name="isVolumeReduced"
                checked={isVolumeReduced}
                onChange={(_, { name, checked }) => {
                    onChange(null, { name, value: checked });
                }}
            />

            <Form.Field label="Attachment" />
            <Form.Field>
                <FileInput
                    onChange={onChange}
                    name="files"
                    files={files}
                    accept="audio/*"
                    icon="file audio outline"
                    onReject={(rejected) => {
                        if (rejected.length > 0) {
                            Notification.alert(
                                "File must be audio.",
                                STATUS_TYPES.ERROR,
                                true
                            );
                        }
                    }}
                />
            </Form.Field>
            <Form.Group>
                <Form.Button
                    floated="right"
                    content={"Cancel"}
                    onClick={onClose}
                />
                <Form.Button
                    primary
                    floated="right"
                    content={"Upload"}
                    onClick={onAddAudioFile}
                />
            </Form.Group>
        </Form>
        <p>
            Upload .mp3 or .wav files. It may take a few minutes to process your
            upload.
        </p>
    </React.Fragment>
);

class AddAudioFileSectionContainer extends React.Component {
    state = {
        name: "",
        isVolumeReduced: true,
        files: null,
    };

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    };

    onAddAudioFile = () => {
        this.props.onAddAudioFile({ ...this.state });
        this.setState({
            name: "",
            file: null,
        });
    };

    render() {
        return (
            <AddAudioFileSection
                {...this.props}
                {...this.state}
                onAddAudioFile={this.onAddAudioFile}
                onChange={this.onChange}
            />
        );
    }
}

export default AddAudioFileSectionContainer;
