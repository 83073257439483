import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function contactsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.ACCOUNT_CONTACT, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, ...initialState, isReadingContact: true, contact_id: action.id, contactError: false };
        case getNamedAction(ACTION_NAMES.ACCOUNT_CONTACT, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            const { contact, account, current_stage } = action.data;
            if (!contact) {
                return { ...state, contact: null, lead: null, opportunity: null, isReadingContact: false };
            }
            const { email, primary_contact, custom_data, phone, social_media, date_of_birth, ...otherProps } = contact;
            return {
                ...state,
                account_id: account?.id || 0,
                contact: { ...otherProps, contact_type: 'person', date_of_birth: date_of_birth === '01-01-0001' ? '' : date_of_birth },
                email: (email || { data: [] }).data,
                phone: (phone || { data: [] }).data,
                custom_data: custom_data ? Object.keys(custom_data).length > 0 ? custom_data : state.custom_data : state.custom_data,
                isReadingContact: false,
                social_media: (social_media || { data: [] }).data,
                queryParameters: action.queryParameters,
                primary_contact,
                current_stage,

                account
            };
        case getNamedAction(ACTION_NAMES.ACCOUNT_CONTACT, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingContact: false, contactError: true };

        case 'INLINE_UPDATE_CONTACT_CUSTOM_DATA':
            return { ...state, custom_data: { ...state.custom_data, children: action.groups } }

        case getNamedAction(ACTION_NAMES.ACCOUNT_CONTACT, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, contactBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.ACCOUNT_CONTACT, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, contactBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.ACCOUNT_CONTACT, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, contactBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.CONTACT_CONTACT_LABEL, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingContactLabels: true };
        case getNamedAction(ACTION_NAMES.CONTACT_CONTACT_LABEL, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingContactLabels: false, contact_labels: action.data.data };
        case getNamedAction(ACTION_NAMES.CONTACT_CONTACT_LABEL, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingContactLabels: false };


        case getNamedAction(ACTION_NAMES.CONTACT_CONTACT_LABEL, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingContactLabel: true };
        case getNamedAction(ACTION_NAMES.CONTACT_CONTACT_LABEL, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingContactLabel: false };
        case getNamedAction(ACTION_NAMES.CONTACT_CONTACT_LABEL, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingContactLabel: false };

        case getNamedAction(ACTION_NAMES.CONTACTS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingContactInfo: true };
        case getNamedAction(ACTION_NAMES.CONTACTS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingContactInfo: false, contact_info: action.data.data };
        case getNamedAction(ACTION_NAMES.CONTACTS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingContactInfo: false };

        case getNamedAction(ACTION_NAMES.CONTACTS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingContactInfo: true };
        case getNamedAction(ACTION_NAMES.CONTACTS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingContactInfo: false };
        case getNamedAction(ACTION_NAMES.CONTACTS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingContactInfo: false };

        case getNamedAction(ACTION_NAMES.CONTACT_OPTIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingContactOptions: true };
        case getNamedAction(ACTION_NAMES.CONTACT_OPTIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingContactOptions: false, contact_options: action.data };
        case getNamedAction(ACTION_NAMES.CONTACT_OPTIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingContactOptions: false };

        case getNamedAction(ACTION_NAMES.CONTACT_HISTORY, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingHistory: true };
        case getNamedAction(ACTION_NAMES.CONTACT_HISTORY, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, history: action.data.data, history_count: action.data.count, isReadingHistory: false };
        case getNamedAction(ACTION_NAMES.CONTACT_HISTORY, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingHistory: false };

        case getNamedAction(ACTION_NAMES.CONTACT_UPCOMING_HISTORY, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingUpcomingHistory: true };
        case getNamedAction(ACTION_NAMES.CONTACT_UPCOMING_HISTORY, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, upcoming_history: action.data.data, upcoming_history_count: action.data.count, isReadingUpcomingHistory: false };
        case getNamedAction(ACTION_NAMES.CONTACT_UPCOMING_HISTORY, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingUpcomingHistory: false };

        case getNamedAction(ACTION_NAMES.CONTACT_LATEST_HISTORY, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingLatestHistory: true };
        case getNamedAction(ACTION_NAMES.CONTACT_LATEST_HISTORY, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, latest_history: action.data.data, latest_history_count: action.data.count, isReadingLatestHistory: false };
        case getNamedAction(ACTION_NAMES.CONTACT_LATEST_HISTORY, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingLatestHistory: false };

        case getNamedAction(ACTION_NAMES.ACCOUNT_CONTACT, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, contactBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.ACCOUNT_CONTACT, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, contactBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.ACCOUNT_CONTACT, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, contactBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.CONTACT_ROUTING, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, contactBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.CONTACT_ROUTING, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, contactBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.CONTACT_ROUTING, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, contactBeingUpdated: null };

        default:
            return state;
    }
}

export default contactsReducer;
