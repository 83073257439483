import { Modal } from "dyl-components";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import QuotePDF from "./QuotePDF";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useContext, useEffect } from "react";
import quoteActions from "actions/quote";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";

const ProposalPreview = ({ opportunity_id }) => {
    const quoteVersion = useSelector((state) => state.quote.quoteVersion);
    const pdf = <QuotePDF quoteVersion={quoteVersion} />;

    const dispatch = useDispatch();
    const { onEditQuote } = useContext(QuoteBuilderContext);

    useEffect(() => {
        dispatch(quoteActions.getVersions(quoteVersion.quote_information.quote_id, { opportunity_id }));    
    }, [dispatch, quoteVersion, opportunity_id])

    return (
        <>
            <Modal.Content>
                <PDFViewer showToolbar={false} style={{ width: "100%", height: "65vh" }}>{pdf}</PDFViewer>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => {
                        onEditQuote(quoteVersion.quote_information.quote_id);
                    }}
                    basic
                    type="button"
                    color="primary"
                >
                    Back to Quote Builder
                </Button>
                <PDFDownloadLink
                    fileName={`${quoteVersion.quote_information.name}.pdf`}
                    document={pdf}
                >
                    {({ loading, error }) => (
                        <Button
                            loading={loading && !error}
                            disabled={loading || error}
                            basic
                            type="button"
                            color="primary"
                        >
                            Download PDF
                        </Button>
                    )}
                </PDFDownloadLink>
                <Button type="button" color="primary">
                    Send
                </Button>
            </Modal.Actions>
        </>
    );
};

export default ProposalPreview;
