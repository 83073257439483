import React from 'react';
import { TableWithHeader } from 'dyl-components';
import AudioFilesTable from './AudioFilesTable';
import AudioFilesHeader from './AudioFilesHeader';
import { Grid, Transition } from 'semantic-ui-react';
import AddAudioFileSectionContainer from './AddAudioFileSection';
import LeftPaddedContent from 'shared/LeftPaddedContent';

const AudioFiles = ({ 
    audioFiles, 
    onUpdate, 
    onDelete, 
    isAddAudioFileSectionOpen,
    onOpenAddAudioFileSection, 
    onCloseAddAudioFileSection, 
    onAddAudioFile
}) => (
    <TableWithHeader 
        header={
            <AudioFilesHeader 
                onOpenAddAudioFileSection={onOpenAddAudioFileSection}
            />
        }
        table={
            <Grid style={{margin: 0}}>
                <Transition visible={isAddAudioFileSectionOpen} unmountOnHide duration='100' animation='slide down'>
                    <Grid.Row>
                        <Grid.Column>
                            <LeftPaddedContent>
                                <AddAudioFileSectionContainer 
                                    onAddAudioFile={onAddAudioFile} 
                                    onClose={onCloseAddAudioFileSection}
                                />
                            </LeftPaddedContent>
                        </Grid.Column>
                    </Grid.Row>
                </Transition>
                <Grid.Row style={{padding: 0}}>
                    <Grid.Column style={{padding: 0}}>
                        <AudioFilesTable 
                            audioFiles={audioFiles}
                            onUpdate={onUpdate}
                            onDelete={onDelete}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        }
    />
);

class AudioFilesContainer extends React.Component {
    state = {
        isAddAudioFileSectionOpen: false
    }

    onOpenAddAudioFileSection = () => {
        this.setState({ isAddAudioFileSectionOpen: true });
    }

    onCloseAddAudioFileSection = () => {
        this.setState({ isAddAudioFileSectionOpen: false });
    }

    render() {
        return (
            <AudioFiles 
                {...this.state}
                {...this.props}
                isAddAudioFileSectionOpen={this.state.isAddAudioFileSectionOpen}
                onOpenAddAudioFileSection={this.onOpenAddAudioFileSection}
                onCloseAddAudioFileSection={this.onCloseAddAudioFileSection}
            />
        )
    }
}

export default AudioFilesContainer;
