import { DateTimeUtils } from 'dyl-components';

const initialState = {
    conversion_data: [],
    isReadingConversionData: false,
    funnel_data: [],
    isReadingFunnelData: false,

    conversionQueryParameters: {
        time_interval: "month",
        team_ids: []
    },
    funnelQueryParameters: {
        start: DateTimeUtils.getLast(2, "month"),
        end: DateTimeUtils.getCurrentDate(DateTimeUtils.DATE_FORMAT, true, false),
        account_stage: "lead",
        team_ids: []
    }
}

export default initialState;
