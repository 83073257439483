import React from 'react';
import { Table, Notification, STATUS_TYPES } from 'dyl-components';
import { EditableContent } from 'dyl-components';
import { Select, Input } from 'semantic-ui-react';
import { STATES } from 'shared/constants/STATES';
import MessagingIconButtons from 'shared/MessagingIconButtons';

const CallOverrideRow = ({ 
    callOverride, 
    isEditMode, 
    onChange, 
    onDelete,
    onEnableEditMode,
    onDisableEditMode,
    onUpdate
}) => (
    <Table.Row>
        <Table.Cell>
            <EditableContent 
                control={Select}
                value={isEditMode ? callOverride.state : STATES.filter(state => state.key === callOverride.state)[0].text }
                search
                name='state'
                options={STATES.map(state => ({ ...state, value: state.key }))}
                onChange={onChange}
                isEditMode={isEditMode}
            />
        </Table.Cell>
        <Table.Cell>
            <EditableContent 
                control={Input}
                value={callOverride.callerId}
                name='callerId'
                onChange={onChange}
                isEditMode={isEditMode}
            />
        </Table.Cell>
        <Table.Cell>
           <MessagingIconButtons
                isEditMode={isEditMode}
                onDisableEditMode={onDisableEditMode} 
                onUpdate={onUpdate}
                onEnableEditMode={onEnableEditMode}
                onDelete={onDelete}
                id={callOverride.callerId} 
            />
        </Table.Cell>
    </Table.Row>
)

class CallOverrideRowContainer extends React.Component {
    state = {
        callOverride: this.props.callOverride,
        isEditMode: false
    }

    onEnableEditMode = () => {
        this.setState({ isEditMode: true });
    }

    onDisableEditMode = () => {
        this.setState({ isEditMode: false, callOverride: this.props.callOverride });
    }

    onChange = (_, { name, value }) => {
        let callOverride = { ...this.state.callOverride, [name]: value};
        this.setState({ callOverride });
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false
        }, () => {
            this.props.onUpdate(this.state.callOverride);
            Notification.alert('Successfully updated call override!', STATUS_TYPES.SUCCESS, true);
        })
    }

    render() {
        return (
            <CallOverrideRow 
                {...this.props}
                {...this.state}
                onChange={this.onChange}
                onEnableEditMode={this.onEnableEditMode}
                onDisableEditMode={this.onDisableEditMode}
                onUpdate={this.onUpdate}
            />
        )
    }
}

export default CallOverrideRowContainer;
