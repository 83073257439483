import types from "actions/opportunities/types";
import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function opportunitiesReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.OPPORTUNITIES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingOpportunities: true, opportunities: [], count: 0 };
        case getNamedAction(ACTION_NAMES.OPPORTUNITIES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingOpportunities: false, opportunities: (action.data.data || []).map(opportunitiy => ({ ...opportunitiy, isMarked: state.allPagesMarked })), queryParameters: action.queryParameters, count: action.data.count, stages: action.data.pipeline_stages ? action.data.pipeline_stages.stages : [], allMarked: state.allPagesMarked };
        case getNamedAction(ACTION_NAMES.OPPORTUNITIES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingOpportunities: false };

        case types.MARK_OPPORTUNITY:
            {
                const opportunities = state.opportunities.slice(0);
                let opportunityToMark = opportunities.filter(opportunity => opportunity.id === action.id)[0];
                opportunityToMark.isMarked = !opportunityToMark.isMarked;
                return {
                    ...state, opportunities, allPagesMarked: false,
                    ...(opportunities.every(({ isMarked }) => !isMarked) ? { allMarked: false } : {}),
                    ...(opportunities.every(({ isMarked }) => isMarked) ? { allMarked: true } : {})
                }
            }
        case types.MARK_ALL_OPPORTUNITIES:
            {
                const opportunities = state.opportunities.slice(0);
                let allMarked = !state.allMarked;
                opportunities.forEach(opportunity => { opportunity.isMarked = allMarked });
                return { ...state, opportunities, allMarked, allPagesMarked: false }
            }
        case types.MARK_OPPORTUNITIES_IN_EVERY_PAGE:
            {
                const opportunities = state.opportunities.slice(0);
                let allPagesMarked = !state.allPagesMarked;
                opportunities.forEach(opportunity => { opportunity.isMarked = allPagesMarked });
                return { ...state, opportunities, allMarked: allPagesMarked, allPagesMarked }
            }
        case types.UPDATE_OPPORTUNITY_ASSIGNMENT:
            {
                const opportunities = state.opportunities.slice(0);
                let indexOfOpportunityToUpdate = opportunities.findIndex(opportunity => opportunity.id === action.opportunity);
                if (indexOfOpportunityToUpdate !== -1) {
                    opportunities[indexOfOpportunityToUpdate].assigned.id = action.user;
                    return { ...state, opportunities }
                }
                return state;
            }

        default:
            return state;
    }
}


export default opportunitiesReducer;
