import routes from "actions/routes";
import ACTION_NAMES from "actions/ACTION_NAMES";
import { generateDeleteActionCreator, generateUpdateActionCreator, generateCreateActionCreator } from "actions/crudActionCreators";

const deleteTeam = generateDeleteActionCreator(routes.API_TEAMS, ACTION_NAMES.TEAM);
const updateTeam = generateUpdateActionCreator(routes.API_TEAMS, ACTION_NAMES.TEAM);
const addUsers = generateCreateActionCreator(routes.API_TEAMS, ACTION_NAMES.TEAM, () => {}, () => {}, 'team_user');
const deleteUser = generateDeleteActionCreator(routes.API_TEAMS, ACTION_NAMES.TEAM_USER, () => {

}, () => {

}, 'team_user')

export default {
    updateTeam,
    deleteTeam,
    addUsers,
    deleteUser
}
