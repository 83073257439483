import React, { useState } from 'react';
import { Grid, Header, Icon, Popup } from 'semantic-ui-react';

import {
    ClippedContent,
    DateTimeUtils,
    Editable,
    generateResolver,
    Notification,
    STATUS_TYPES,
    VALIDATORS
} from 'dyl-components';
import Avatar from 'react-avatar';

import { Controller, useForm } from 'react-hook-form';

import accountActions from 'actions/account';
import sequenceTasksActions from 'actions/sequence_tasks';
import contactSequenceActions from 'actions/contact_sequence';
import sequencesActions from 'actions/sequences';
import customerPipelineActions from 'actions/customer_pipeline';
import { useDispatch, useSelector } from 'react-redux';
import ErrorPopup from 'shared/ErrorPopup';
import SocialMedia from 'shared/SocialMedia';
import ConversionControls from "./ConversionControls";
import Controls from "./Controls";
import ProfileStages from 'shared/ProfileStages';
import { FAVORITE_TYPE } from 'shared/constants/FAVORITE_TYPE';
import { ProfileFavoriteIcon } from 'shared/ProfileFavoriteIcon';

function copy(text) {
    return (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(text);
    };
}

export const ProfileHeader = ({
    name,
    id,
    breadcrumbs,
    isSidePanelOpen
}) => {
    const [isEditing, setEditing] = useState(false);

    const { control, formState: { isValid, isDirty }, handleSubmit, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: name || ''
        },
        resolver: generateResolver({
            name: VALIDATORS.BUSINESS_NAME().required('This field is required')
        })
    });

    const onEdit = () => {
        reset();
        setEditing(true);
    }

    const onCancelEdit = () => {
        setEditing(false);
    }

    const { account, isUpdating, customer_information, contactSequenceTasks, selectedSequenceId } = useSelector(state => ({
        account: state.account.account,
        isUpdating: state.account.isUpdatingAccount,
        customer_information: state.account.account?.customer || {},
        contactSequenceTasks: state.sequence_tasks.selectedSequenceTasks,
        selectedSequenceId: state.sequence_tasks.selectedSequenceId
    }))

    const onConfirmEdit = async (data) => {
        try {
            await dispatch(accountActions.updateAccount(id, { ...data, pinned: account.pinned.map(contact => contact.id), primary_contact_id: account.primary_contact.id }));
            Notification.alert('Successfully updated account!', STATUS_TYPES.SUCCESS);
            dispatch(accountActions.readAccount(id));
        } catch {
            Notification.alert('Failed to update account', STATUS_TYPES.ERROR);
        }
    }

    const account_type = account?.account_type;

    const { customer_status, customer_status_activity, customer_pipeline } = customer_information;

    const dispatch = useDispatch();

    const onTransitionPipeline = async (stage_name) => {
        const newStage = (customer_pipeline?.stages.map(({ id, name, sequence_id }) => {
            return {
                key: id,
                content: name,
                sequence_id
            };
        }) || [])?.find(stage => stage.content === stage_name);
        const stage_id = newStage.key;
        if (Number(customer_pipeline.pipeline_stage_id) === Number(stage_id)) {
            return;
        }

        const areSequenceTasksCompleted = contactSequenceTasks.length === 0 || contactSequenceTasks.every(task => task.status === 'complete' || task.status === 'canceled');
        const associatedContact = customer_pipeline?.assocciated_contact?.id;
        try {
            
            await dispatch(customerPipelineActions.update(id, {
                pipeline_stage_id: Number(stage_id),
                person_id: associatedContact,
                pipeline_id: customer_pipeline?.pipeline_id,
                previous_sequence_complete: areSequenceTasksCompleted
            }, { action: 'transition' }));
            if (selectedSequenceId) {
                await dispatch(contactSequenceActions.removeFromContact(associatedContact));
            }
            if (newStage.sequence_id) {
                await dispatch(sequencesActions.addToSequence({ contact_id: associatedContact }, null, newStage.sequence_id));
            }
            Notification.alert(`Successfully updated stage!`, STATUS_TYPES.SUCCESS);
            dispatch(accountActions.readAccount(id));
            dispatch(sequenceTasksActions.readContactTasks(associatedContact));
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update stage', STATUS_TYPES.ERROR);
        }
    }

    const isTransitioningPipeline = useSelector(state => state.customer_pipeline.accountBeingUpdated || state.contact_sequence.isRemovingSequence || state.sequences.isAddingContactToSequence);

    return (
        <React.Fragment>
            <div style={{ paddingBottom: "0.5em" }}>
                {breadcrumbs}
            </div>
            <Grid>
                <Grid.Row columns='equal' verticalAlign='middle'>
                    <Grid.Column width={6}>
                        <Grid columns='equal' verticalAlign='middle'>
                            <Grid.Column className='ProfileHeader__avatarContainer' width={isSidePanelOpen ? 3 : 2} textAlign='center' style={{ padding: '1em 0 1em 0' }}>
                                <Avatar
                                    size='5em'
                                    round
                                    maxInitials={2}
                                    name={name}
                                />
                                <SocialMedia />
                            </Grid.Column>
                            <Grid.Column>
                                <Header as='h3' style={{ marginBottom: 5 }}>
                                    {!isEditing ?
                                        <div onClick={onEdit}>
                                            <ClippedContent>
                                                {name}
                                            </ClippedContent>
                                        </div> : (
                                            <Editable
                                                value={name}
                                                isEditing={isEditing}
                                                onCancelEdit={onCancelEdit}
                                                onEdit={onEdit}
                                                size='small'
                                                underline
                                                onConfirmEdit={handleSubmit(onConfirmEdit, (errors, e) => {
                                                    console.log(errors, e);
                                                })}
                                                submitDisabled={!isDirty || !isValid || isUpdating}
                                            >
                                                <Controller
                                                    name='name'
                                                    control={control}
                                                    render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                                                        <ErrorPopup
                                                            isPopupOpen={typeof (error?.message) != "undefined"}
                                                            message={error?.message}
                                                            inputName={name}
                                                            inputValue={value}
                                                            onInputChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                                            inputPlaceholder='Enter new name'
                                                            isLoading={isUpdating}
                                                        />
                                                    )}
                                                />
                                            </Editable>
                                        )}
                                </Header>
                                <div className='ProfileHeader__leftContainer'>
                                    <span><Icon className={`fas${account_type === 'business' ? ' fa-building' : ''}${account_type === 'household' ? ' fa-house' : ''}`} />Account</span>
                                    <div className='ProfileHeader__iconsContainer'>
                                        <ProfileFavoriteIcon id={id} type={FAVORITE_TYPE.ACCOUNT} />
                                    </div>
                                </div>
                                <div>
                                    ID: <Popup
                                        trigger={<a href='#?' role="button" tabIndex="0" onClick={copy(id)}>{id} <Icon className='fas fa-copy' /></a>}
                                        content='Copied!'
                                        on='click'
                                        closeOnTriggerMouseLeave
                                        position='bottom center'
                                        inverted />
                                </div>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    {customer_status && (
                        <Grid.Column width={6}>
                            <div className='ProfileHeader__PipelineConversion'>
                                <ConversionControls />
                            </div>
                            <ProfileStages
                                otherInfo={customer_status === 'canceled' ? <span><b>Customer Canceled</b>: {DateTimeUtils.formatEpoch(customer_status_activity, "MM/DD/YYYY")}</span> : null}
                                onTransitionPipeline={onTransitionPipeline}
                                current_stage={{
                                    account_stage: 'customer',
                                    ...((customer_pipeline?.stages || []).length > 0 ? customer_pipeline : {})
                                }}
                                isTransitioningPipeline={isTransitioningPipeline}
                            />
                        </Grid.Column>
                    )}
                    <Grid.Column textAlign='right'>
                        {!customer_status && <ConversionControls />}
                        <Controls />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    );
};
