import React from 'react';

import opportunityTabActions from 'actions/opportunity_tab';

import {
    KEY_CREATED, KEY_NEXT_STEP,
    KEY_CREATED_BY, KEY_OPPORTUNITY_AGE, KEY_LAST_MODIFIED,
    KEY_LAST_MODIFIED_BY, KEY_OPPORTUNITY_ID,
    KEY_OPPORTUNITY_NAME,
    KEY_CONFIDENCE_LEVEL, KEY_STAGE, KEY_CLOSE_PROBABILITY,
    KEY_OUTCOME, KEY_EXPECTED_CLOSE_DATE, KEY_LOST_REASON,
    KEY_NEXT_STEP_DATE, KEY_ACCOUNT, KEY_MASTER_SECONDARY
} from 'shared/constants/MODULE_COLUMNS';

import ModulesTab from 'shared/ModulesTab';
import OpportunityTabTable from './OpportunityTabTable';
import OpportunityTabFilter from './OpportunityTabFilter';
import { useParams, useSearchParams } from 'react-router-dom';
import QuoteBuilderProvider from 'shared/context/QuoteBuilderProvider';
import { useDispatch } from 'react-redux';

const OpportunityTab = () => {

    const { account_id } = useParams();

    const moduleType = account_id ? 'opportunities' : 'opportunity';
    const Container = account_id ? QuoteBuilderProvider : React.Fragment;

    const dispatch = useDispatch();
    const [params] = useSearchParams();

    return (
        <Container
            {...(account_id ? {
                refreshAccountInformation: () => {
                    const queryParameters = {
                        page: 1,
                        limit: 25,
                        ...Object.fromEntries(params),
                    }
                    dispatch(opportunityTabActions.readOpportunities(account_id, queryParameters));
                }
            } : {})}
        >
            <ModulesTab
                TableComponent={OpportunityTabTable}
                TabFilterComponent={OpportunityTabFilter}
                allowedColumns={[KEY_CREATED, KEY_ACCOUNT, KEY_STAGE, KEY_MASTER_SECONDARY, KEY_LOST_REASON, KEY_OPPORTUNITY_ID, "Name", KEY_OUTCOME, KEY_LAST_MODIFIED]}
                defaultColumnsDisplayed={[
                    KEY_CREATED,
                    KEY_OPPORTUNITY_ID,
                    KEY_ACCOUNT,
                    KEY_STAGE,
                    KEY_OUTCOME,
                    KEY_EXPECTED_CLOSE_DATE
                ]}
                moduleName='Opportunities'
                readModules={opportunityTabActions.readOpportunities}
                additionalColumnParameters={['pipeline_custom_data']}
                routeRoot={''}
                columnDisplayOptions={[
                    KEY_CREATED, KEY_NEXT_STEP,
                    KEY_CREATED_BY, KEY_OPPORTUNITY_AGE, KEY_LAST_MODIFIED,
                    KEY_LAST_MODIFIED_BY, KEY_OPPORTUNITY_ID,
                    KEY_OPPORTUNITY_NAME,
                    KEY_CONFIDENCE_LEVEL, KEY_STAGE, KEY_CLOSE_PROBABILITY,
                    KEY_OUTCOME, KEY_EXPECTED_CLOSE_DATE, KEY_LOST_REASON,
                    KEY_NEXT_STEP_DATE
                ]}
                moduleType={moduleType}
            />
        </Container>
    )
}

export default OpportunityTab;
