import { ClippedContent } from 'dyl-components';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import './index.scss';

const AccountLink = ({ type, name, id }) => (
    <div className='AccountLink'>
        <ClippedContent popupContent={name}>
            <Icon className={`AccountLink__icon ${type !== 'household' ? 'fa-solid fa-building' : 'fa-solid fa-house-chimney-blank'}`} />
            <Link to={`/account/${id}`}>{name}</Link>
        </ClippedContent>
    </div>
);

export default AccountLink;
