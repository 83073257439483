import React from 'react';
import { Controller } from 'react-hook-form';
import { Form, Segment } from 'semantic-ui-react';

import './DataMapping.scss';

const DataMapping = ({
    importSetupFields,
    dataMappingControl,
    mappingTable,
    module,

    productInterestsSelectedAsDylField,
    interestsSelectedAsDylField
}) => {
    return (
        <React.Fragment>
            <Segment className='DataMapping__DataField'>
                <Form noValidate>
                    <Form.Group widths='equal'>
                        {importSetupFields}
                        {module === 'Contact' ? (
                            <Controller
                                name='interests'
                                control={dataMappingControl}
                                render={({ field: { name, value, onChange } }) => (
                                    <Form.Select
                                        name={name}
                                        label='Interest(s)'
                                        value={value}
                                        placeholder='Select'
                                        onChange={(_, { value }) => {
                                            onChange({
                                                target: {
                                                    name, value
                                                }
                                            })
                                        }}
                                        multiple
                                        selectOnBlur={false}
                                        {...(!interestsSelectedAsDylField ? {} : {
                                            disabled: true,
                                            text: 'Selected as a DYL Field'
                                        } )}
                                    />
                                )}
                            />
                        ) : (
                            <Controller
                                name='product_interests'
                                control={dataMappingControl}
                                render={({ field: { name, value, onChange } }) => (
                                    <Form.Select
                                        name={name}
                                        label='Product Interest(s)'
                                        value={value}
                                        placeholder='Select'
                                        onChange={(_, { value }) => {
                                            onChange({
                                                target: {
                                                    name, value
                                                }
                                            })
                                        }}
                                        multiple
                                        selectOnBlur={false}
                                        {...(!productInterestsSelectedAsDylField ? {} : {
                                            disabled: true,
                                            text: 'Selected as a DYL Field'
                                        })}
                                    />
                                )}
                            />
                        )}
                        <Controller
                            name='group'
                            control={dataMappingControl}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    name={name}
                                    label='Add to group'
                                    value={value}
                                    placeholder='Select'
                                    onChange={(_, { value }) => {
                                        onChange({
                                            target: {
                                                name, value
                                            }
                                        })
                                    }}
                                    options={[
                                        {
                                            key: 'none', value: 'none', text: 'None'
                                        }
                                    ]}
                                    selectOnBlur={false}
                                />
                            )}
                        />
                        <Controller
                            name='campaign'
                            control={dataMappingControl}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    name={name}
                                    label='Add to campaign'
                                    value={value}
                                    placeholder='Select'
                                    onChange={(_, { value }) => {
                                        onChange({
                                            target: {
                                                name, value
                                            }
                                        })
                                    }}
                                    options={[
                                        {
                                            key: 'none', value: 'none', text: 'None'
                                        }
                                    ]}
                                    selectOnBlur={false}
                                />
                            )}
                        />
                    </Form.Group>
                </Form>
            </Segment>
            {mappingTable}
        </React.Fragment>
    )
};

export default DataMapping;