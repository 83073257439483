import React from 'react'
import { NavLink } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import './index.scss';

const placeHolders = [
    { label: "Products/Inventory", group_name: "product-inventory", disabled: true, id: "tempIDProd1" }
];

const Navigation = ({ links }) => {

    const navItems = [...links, ...placeHolders]; // placeholders till we work out what's happening with Campaigns & Invenroty

    return (
        <Menu size='small' borderless pointing secondary stackable>
            {navItems.map(item => {
                const name = (item.group_name === 'person') ? 'contact' : item.group_name
                return (
                    <Menu.Item as={NavLink}
                        name={`${item.label}`}
                        to={`/settings/data-customization/${name}`}
                        id={item.id}
                        disabled={item?.disabled} 
                    />
                )
            })}
        </Menu>
    )
};

export default Navigation;


