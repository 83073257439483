import React from 'react';
import { Breadcrumb, Grid, Header, Button, Menu } from 'semantic-ui-react';
import { Link, NavLink } from 'react-router-dom';
import { ClippedContent } from 'dyl-components';

import FavoriteIcon from 'shared/FavoriteIcon';
import { FAVORITE_TYPE } from 'shared/constants/FAVORITE_TYPE';

const CampaignTabsHeader = ({ id, campaign }) => {
    const isDeleted = (campaign.status || '').toLowerCase() === 'deleted';
    return (
        <Grid columns='equal'>
            <Grid.Row>
                <Grid.Column>
                    <Breadcrumb>
                        <Breadcrumb.Section as={Link} to='/campaigns'>
                            Campaigns
                        </Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section>
                            {campaign.name}
                        </Breadcrumb.Section>
                    </Breadcrumb>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Header as='h2'>
                        <FavoriteIcon
                            details={{
                                external_id: Number(id),
                                favorite_type: FAVORITE_TYPE.CAMPAIGN
                            }}
                            doNotRecordRecent
                        />
                        <Header.Content>{campaign.name}</Header.Content>
                        <Header.Subheader><ClippedContent>{campaign.description}</ClippedContent></Header.Subheader>
                    </Header>
                </Grid.Column>
                <Grid.Column>
                    <Button floated='right' primary disabled={isDeleted}>
                        Set Up Dialer
                    </Button>
                    <Button floated='right' primary disabled={isDeleted}>
                        Send Text
                    </Button>
                    <Button floated='right' primary disabled={isDeleted}>
                        Send Email
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Menu borderless pointing secondary color='primary' stackable>
                        <Menu.Item as={NavLink} name='Dashboard' to={`/campaigns/${id}/dashboard`} />
                        <Menu.Item as={NavLink} name='Contact List' to={`/campaigns/${id}/contacts`} />
                        {
                            Object.keys(campaign?.is_company_campaign || []).length > 0 &&
                            <Menu.Item as={NavLink} name='User Reports' to={`/campaigns/${id}/user-reports`} />
                        }
                        <Menu.Item as={NavLink} name='Settings' to={`/campaigns/${id}/settings`} />
                        <Menu.Item as={NavLink} name='History' to={`/campaigns/${id}/history`} />
                    </Menu>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default CampaignTabsHeader;
