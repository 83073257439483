export default class LocationUtils {
    static getAddressLabel = (addressLabel) => {
        switch (addressLabel) {
            case "Home":
            case "Business":
            case "Other":
                return addressLabel
            default:
                return "Other"
        }
    }

    static getLocationModel = (location_type) => {
        return {
            city: '',
            state: '',
            additional_street: '',
            zip: '',
            label: location_type,
            street: '',
            main: false,
            id: 1
        }
    }

    static getAddressLabelOptions = () => {
        return [
            { key: 0, text: 'Business', value: 'Business' },
            { key: 1, text: 'Home', value: 'Home' },
            { key: 2, text: 'Other', value: 'Other' },
        ]
    }

    static formatAddress = (address, address2, city, state, zip) => {
        return (
            <>
                <div>{address} {address2}</div>
                <div>{city} {state}, {zip}</div>
            </>
        )
    }
}
