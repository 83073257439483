const initialState = {
    categorized_templates: [],
    isReadingCategorizedTemplates: false,

    categories: [],
    count: 0,
    isReadingCategories: false,
    isCreatingCategory: false,

    categoryBeingUpdated: null,
    categoryBeingDeleted: null
}

export default initialState;
