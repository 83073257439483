import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function productManufacturersReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PRODUCT_MANUFACTURER, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReading: true };
        case getNamedAction(ACTION_NAMES.PRODUCT_MANUFACTURER, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReading: false, manufacturers: action.data.data || [], count: action.data.count };
        case getNamedAction(ACTION_NAMES.PRODUCT_MANUFACTURER, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReading: false, manufacturers: [], count: 0 };

        case getNamedAction(ACTION_NAMES.PRODUCT_MANUFACTURER, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreating: true };
        case getNamedAction(ACTION_NAMES.PRODUCT_MANUFACTURER, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreating: false };
        case getNamedAction(ACTION_NAMES.PRODUCT_MANUFACTURER, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreating: false };

        case getNamedAction(ACTION_NAMES.PRODUCT_MANUFACTURER, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, manufacturerBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.PRODUCT_MANUFACTURER, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, manufacturerBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.PRODUCT_MANUFACTURER, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, manufacturerBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.PRODUCT_MANUFACTURER, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, manufacturerBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.PRODUCT_MANUFACTURER, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, manufacturerBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.PRODUCT_MANUFACTURER, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, manufacturerBeingDeleted: null };

        default:
            return state;
    }
}


export default productManufacturersReducer;
