import React, { useEffect } from 'react';
import { Segment, Menu, Grid } from 'semantic-ui-react';

import './index.scss';
import { NavLink, Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import sequencesActions from 'actions/sequences';

const Pipeline = () => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(sequencesActions.readShort({ limit: 1000 }));
    }, [dispatch]);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Menu borderless pointing secondary color='primary' stackable size='huge'>
                        <Menu.Item name='Lead' as={NavLink} to='lead-pipelines' />
                        <Menu.Item name='Opportunity' as={NavLink} to='opportunity-pipelines' />
                        <Menu.Item name='Customer' as={NavLink} to='customer-pipelines' />
                    </Menu>
                    <Segment basic>
                        <Outlet />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default Pipeline;
