import React from 'react';
import Avatar from 'react-avatar';
import { Icon, Popup, Segment, Select } from 'semantic-ui-react';

import { Person } from 'dyl-components';
import { useParams } from "react-router-dom";

const ContactOptions = ({
    name,
    options,
    value,
    onChange,
    label,
    placeholder
}) => {
    const { contact_id } = useParams();

    return (
        <React.Fragment>
            <Select
                label={label}
                value={value}
                name={name}
                onChange={onChange}
                placeholder={placeholder}
                options={options.map(option => ({
                    ...option,
                    content: (
                        <Person
                            username={option.text}
                            email={option.email || 'No primary email'}
                            popup={false}
                        />
                    )
                }))}
                search
                multiple
                renderLabel={() => null}
                className='Event__contacts'
            />
            {value?.map((id) => {
                const { text, email } = options.find(option => option.key === id) || {
                    text: '', email: '',
                }
                return (
                    <Popup
                        content={
                            <div>
                                <p>Name: {text}</p>
                                <p>Email: {email || 'No primary email'}</p>
                            </div>
                        }
                        inverted
                        trigger={(
                            <Segment size='small' className="UserSearchField__user">
                                <Avatar name={text} maxInitials={2} round size='3em' />
                                <div className="UserSearchField__information">
                                    <div><span className="SelectedUser__name"><b>{text}</b></span></div>
                                    <div><span className="SelectedUser__email"><b>{email || 'No primary email'}</b></span></div>
                                </div>
                                {contact_id !== id && (
                                    <div className={`UserSearchField__remove-user UserSearchField__remove-user`}>
                                        <Icon
                                            name='delete'
                                            link
                                            onClick={() => { onChange(null, { name, value: value.filter(contact => contact !== id) }); }} />
                                    </div>
                                )}
                            </Segment>
                        )}
                    />
                );
            })}
        </React.Fragment>
    )
};
export default ContactOptions;
