import { DateInput, PriorityIcon } from 'dyl-components';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import MasterSecondarySourceOptions from 'shared/forms/MasterSecondarySourceOptions';
import { CustomGroupUtils } from 'utils';

const OpportunityFields = ({
    control,
    disabled,
    ownerField,
    lost_reason_fields
}) => {
    const { isReadingCustomFields, group, campaignConvertedOptions, isReadingCampaignConverted } = useSelector(state => ({
        isReadingCustomFields: state.custom_fields_groups.isReadingParentGroups || state.custom_fields_group.isReadingModalStandardFieldGroup,
        group: state.custom_fields_group.modal_standard_group,

        campaignConvertedOptions: state.campaigns.campaignConvertedOptions.map((campaign) => ({ key: campaign.id, value: campaign.id, text: campaign.name })),
        isReadingCampaignConverted: state.campaigns.isReadingCampaignConverted
    }));

    const fields = group?.fields?.data || [];

    return [
        <Form.Group widths='equal'>
            <Controller
                name='opportunity_name'
                control={control}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <Form.Field>
                        <label>Opportunity Name</label>
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Opportunity Name'
                            required
                            error={error?.message}
                            disabled={disabled}
                        />
                    </Form.Field>
                )}
            />
            <Controller
                name='type'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Opportunity Type</label>
                        <Form.Select
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            options={CustomGroupUtils.getFieldOptions(fields, name)}
                            search
                            selectOnBlur={false}
                            placeholder='Select Type'
                            loading={isReadingCustomFields}
                            disabled={disabled}
                        />
                    </Form.Field>
                )}
            />
            <Controller
                name='description'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Description</label>
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Description'
                            disabled={disabled}
                        />
                    </Form.Field>
                )}
            />
        </Form.Group>,
        <Form.Group widths={'equal'}>
            <Controller
                name='master_source'
                control={control}
                render={({ field: { name: masterSourceFieldName, value: masterSourceFieldValue, onChange: masterSourceOnChange } }) => (
                    <Controller
                        name='secondary_source'
                        control={control}
                        render={({ field: { name: secondarySourceFieldName, value: secondarySourceValue, onChange: secondarySourceOnChange } }) => (
                            <Form.Field>
                                <label>Master/Secondary Source</label>
                                <Form.Field
                                    control={MasterSecondarySourceOptions}
                                    onChange={(_, { parent_value: master_source, child_value: secondary_source }) => {
                                        masterSourceOnChange({ target: { name: masterSourceFieldName, value: master_source } });
                                        secondarySourceOnChange({ target: { name: secondarySourceFieldName, value: secondary_source } });
                                    }}
                                    value={secondarySourceValue}
                                    parentValue={masterSourceFieldValue}
                                    disabled={disabled}
                                />
                            </Form.Field>
                        )}
                    />
                )}
            />
            <Controller
                name='pain_need_notes'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Pain/Need Notes</label>
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Pain/Need Notes'
                            disabled={disabled}
                        />
                    </Form.Field>
                )}
            />
            <Controller
                name='sales_strategy_notes'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Sales Strategy Notes</label>
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Sales Strategy'
                            disabled={disabled}
                        />
                    </Form.Field>
                )}
            />
        </Form.Group>,
        <Form.Group widths={'equal'}>
            <Controller
                name='confidence_level'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Confidence Level</label>
                        <Form.Select
                            search
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            options={CustomGroupUtils.getFieldOptions(fields, name).map(option => ({
                                ...option,
                                content: (
                                    <span><PriorityIcon type={option.text?.toLowerCase()} /> {option.text}</span>
                                )
                            }))}
                            selectOnBlur={false}
                            placeholder='Select Confidence Level'
                            disabled={disabled}
                        />
                    </Form.Field>
                )}
            />
            <Controller
                name='close_probability'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Close Probability</label>
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Close Probability'
                            disabled={disabled}
                        />
                    </Form.Field>
                )}
            />
            <Controller
                name='forecast_amount'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Forecast Amount</label>
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Forecast Amount'
                            disabled={disabled}
                        />
                    </Form.Field>
                )}
            />
        </Form.Group>,
        <Form.Group widths={'equal'}>
            <Controller
                name='next_step_date'
                control={control}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <Form.Field>
                        <label>Next Step Date</label>
                        <Form.Field
                            name={name}
                            value={value}
                            control={DateInput}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Select Next Step Date'
                            error={error?.message}
                            disabled={disabled}
                        />
                    </Form.Field>
                )}
            />
            <Controller
                name='next_step'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Next Step</label>
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Next Step'
                            disabled={disabled}
                        />
                    </Form.Field>
                )}
            />
            <Controller
                name='expected_close_date'
                control={control}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <Form.Field>
                        <label>Expected Close Date</label>
                        <Form.Field
                            name={name}
                            value={value}
                            control={DateInput}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Select Expected Close Date'
                            error={error?.message}
                            disabled={disabled}
                        />
                    </Form.Field>
                )}
            />
            
        </Form.Group>,
        <Form.Group widths={3}>
            <Controller
                name='campaign_converted'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Campaign Converted</label>
                        <Form.Select
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            options={campaignConvertedOptions}
                            search
                            selectOnBlur={false}
                            placeholder='Select Campaign Converted'
                            clearable
                            disabled={disabled}
                            loading={isReadingCampaignConverted}
                        />
                    </Form.Field>
                )}
            />
            {lost_reason_fields.length ? lost_reason_fields : ownerField}
        </Form.Group>,
        ...(lost_reason_fields.length ? [
            <Form.Group widths={3}>
                {ownerField}
            </Form.Group>
        ] : []),
    ]
}

export default OpportunityFields;
