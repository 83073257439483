import React from 'react';
import { TimelineTabDisplay, GridList } from 'dyl-components';
import { Popup, Segment } from 'semantic-ui-react';
import { DateTimeUtils, AvatarGroup, UserAvatar } from 'dyl-components';
import Avatar from 'react-avatar';

const STATUS = {
    'Upcoming': 'upcoming',
    'Completed': 'completed',
    'Previous': 'previous'
}

const titles = [
    {title: 'Name', width: 4},
    {title: 'Organizer', width: 2},
    {title: 'Guest(s)', width: 3},
    {title: 'Status'},
]

const getStatus = (completed, start_time, end_time) => {

    if (completed) {
        return 'Completed';
    }

    if (!DateTimeUtils.isBeforeOrOnCurrentDate(DateTimeUtils.formatEpoch(start_time, DateTimeUtils.DATETIME_FORMAT), DateTimeUtils.DATETIME_FORMAT)) {
        return 'Upcoming';
    }

    if (!DateTimeUtils.isBeforeOrOnCurrentDate(DateTimeUtils.formatEpoch(end_time, DateTimeUtils.DATETIME_FORMAT), DateTimeUtils.DATETIME_FORMAT)) {
      return 'Ongoing'
    }

    return 'Previous';
}

const Timeline = ({ items, expanded, isReadingEvents, onEditEvent }) => (
    items.length <= 0 && !isReadingEvents ? (
        <Segment basic>
            <i>No events to display</i>
        </Segment>
    ) :
        <TimelineTabDisplay
            titles={titles}
            expanded={expanded}
            selectedRow={(email) => onEditEvent(email.id)}
            itemComponent={items.map(item => {
                const start_time = item.all_day? `${DateTimeUtils.getUnixTime(item.start.date,'YYYY-MM-DD')}` : item.start.date_time
                const end_time = item.all_day? `${DateTimeUtils.getUnixTime(item.end.date,'YYYY-MM-DD')}` : item.end.date_time
                const date = `${DateTimeUtils.formatEpoch(start_time, 'ddd M/D')}`.toUpperCase();
                const time = item.all_day? '' : DateTimeUtils.formatEpoch(start_time); // hide time for all_day events
                const guests = item.attendees.map(attendee => { return { ...attendee, active: attendee.accepted === 'yes'  }});
                return ({
                    id: item.id,
                    date,
                    time,
                    itemComponent: 
                        <React.Fragment>
                            <GridList.Cell width={4} className='TimelineTabDisplay__Info'>
                                <Popup
                                    trigger={<span><b>{item.name}</b><br/>{item.event_type?.name}</span>}
                                    content={item.name}
                                    inverted
                                />
                            </GridList.Cell>
                            <GridList.Cell width={2}>
                                {
                                    (item.created_by?.user_id) ?
                                        <Popup
                                            on="hover"
                                            basic
                                            position="bottom"
                                            trigger={
                                                <div>
                                                    <Avatar
                                                        name={`${item.created_by.first_name} ${item.created_by.last_name}`}
                                                        round
                                                        size='2em'
                                                        maxInitials={5}
                                                        title=" "
                                                    />
                                                </div>
                                            }
                                            content={
                                                <UserAvatar
                                                    usersList={[{ ...item.created_by, active: true }]}
                                                    showAllUsersList={true}
                                                />
                                            }
                                        /> : <Avatar
                                            name={'Unassigned'}
                                            round
                                            size='2em'
                                            maxInitials={5}
                                        />
                                }
                            </GridList.Cell>
                            <GridList.Cell width={3} >
                                <div style={{ display: 'flex'}}> {/* center the component */}
                                    <AvatarGroup
                                        persons={guests}
                                        limit={2}
                                        size='2em'
                                    />
                                </div>
                            </GridList.Cell>
                            <GridList.Cell>
                                <span className={`Info__status__TimelineDisplay Info__status__TimelineDisplay--${STATUS[getStatus(item.completed, item.status, item.direction, item.start_date)]}`}>
                                    {getStatus(item.complete, start_time, end_time)}
                                </span>
                            </GridList.Cell>
                        </React.Fragment>,
                    markerColor: '#e5e5e5',
                    selected: item.completed
                });
            })} 
        />
);

export default Timeline;
