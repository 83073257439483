import types from "actions/notes/types";
import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function notesReducer(state = initialState, action) {
    

    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CONTACT_NOTES, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingNotes: true, notes: [], count: 0 };
        case getNamedAction(ACTION_NAMES.CONTACT_NOTES, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingNotes: false, notes: action.data.data || [], count: action.data.count, queryParameters: { ...state.queryParameters, ...action.queryParameters }, token: action.data.token };
        case getNamedAction(ACTION_NAMES.CONTACT_NOTES, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingNotes: false };

        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTES, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingNotes: true, notes: [], count: 0 };
        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTES, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingNotes: false, notes: action.data.data || [], count: action.data.count, queryParameters: { ...state.queryParameters, ...action.queryParameters }, token: action.data.token };
        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTES, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingNotes: false };

        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT_NOTES, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingNotes: true, notes: [], count: 0 };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT_NOTES, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingNotes: false, notes: action.data.data || [], count: action.data.count, queryParameters: { ...state.queryParameters, ...action.queryParameters }, token: action.data.token };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT_NOTES, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingNotes: false };

        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTE_CONTACT, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingAccountRelatedNotes: true, accountRelatedNotes: [] };
        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTE_CONTACT, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingAccountRelatedNotes: false, accountRelatedNotes: action.data || [], queryParameters: { ...state.queryParameters, ...action.queryParameters }};
        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTE_CONTACT, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingAccountRelatedNotes: false };

        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT_NOTE_CONTACT, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingAccountRelatedNotes: true, accountRelatedNotes: [], count: 0 };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT_NOTE_CONTACT, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingAccountRelatedNotes: false, accountRelatedNotes: action.data || [], queryParameters: { ...state.queryParameters, ...action.queryParameters }};
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT_NOTE_CONTACT, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingAccountRelatedNotes: false };

        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingNotes: true, notes: [], count: 0 };
        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingNotes: false, notes: action.data.data || [], count: action.data.count, queryParameters: { ...state.queryParameters, ...action.queryParameters } };
        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingNotes: false };

        case getNamedAction(ACTION_NAMES.ACCOUNT_STICKY_NOTES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingStickyNotes: true, sticky_notes: [], sticky_notes_count: 0 };
        case getNamedAction(ACTION_NAMES.ACCOUNT_STICKY_NOTES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingStickyNotes: false, sticky_notes: action.data.data || [], sticky_notes_count: action.data.count, queryParameters: { ...state.queryParameters, ...action.queryParameters } };
        case getNamedAction(ACTION_NAMES.ACCOUNT_STICKY_NOTES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingStickyNotes: false };

        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTES, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingNote: true };
        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTES, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingNote: false };
        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTES, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingNote: false };

        case types.ADD_LEAD_NOTE_REQUEST:
            return { ...state, isAddingLeadNote: true };
        case types.ADD_LEAD_NOTE_SUCCESS:
            return { ...state, isAddingLeadNote: false, addLeadNoteMessage: 'success' };
        case types.ADD_LEAD_NOTE_FAILURE:
            return { ...state, isAddingLeadNote: false, addLeadNoteMessage: 'error' };

        case types.CLEAR_ADD_LEAD_NOTE_MESSAGE:
            return { ...state, addLeadNoteMessage: null };
        
        default:
            return state;
    }
}


export default notesReducer;
