import { useState } from "react";
import { STATUS_TYPES, Notification, DateTimeUtils } from "dyl-components";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useLocation } from 'react-router-dom';

import taskActions from "actions/task";
import eventActions from "actions/event";
import eventAttendeeActions from "actions/event_attendee";
import eventAttachmentActions from "actions/event_attachment";
import uploadActions from "actions/upload";
import notificationActions from "actions/notifications";

import useTaskForm from "utils/useTaskForm";
import useEventForm from "utils/useEventForm";
import Utils from "shared/EventForm/Utils";
import { FILE_CATEGORIES } from "utils/FileUtils";

const NotificationPopupModals = () => {
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const { pathname } = useLocation();
    const tab = pathname.split("/")[1];
    const tab2 = pathname.split("/")[2];

    const { task_labels, organizer_id, organizer, organizer_email, allContacts, current_user, event_labels, centerNotifications } = useSelector((state) => {        
        const { first_name = "", last_name = "", email: organizer_email } = state.users.userProfile;
        return {
            task_labels: state.task.task_type_labels.map(({ name, id }) => ({
                key: id,
                value: id,
                text: name,
            })),
            organizer_id: state.auth.user_id,
            organizer: `${first_name} ${last_name}`,
            organizer_email,
            allContacts: state.contact.contact_info || [],
            current_user: state.auth, 
            event_labels: state.events.event_labels.map(({ id, name }) => ({
                key: id,
                value: id,
                text: name,
            })),
            centerNotifications: state.notifications.centerNotifications,
        }
    });

    const [taskModalOpen, setTaskModalOpen] = useState(false);
    const [eventModalOpen, setEventModalOpen] = useState(false);
    const [emailModalOpen, setEmailModalOpen] = useState(false);

    const [emailData, setEmailData] = useState({body:"", subject:""});

    const [person_id, setPerson_id] = useState(0);
    const [notificationContactName, setNotificationContactName] = useState("");
    
    const onToggleModal = (id, type, notification_id) => {
        if(type === "task"){
            onReadTask(id)
                .then((task) => {
                    const taskBeingEdited = formatTaskBeingEdited(
                        id,
                        task
                    );
                    let contactDetails = allContacts.find(contact => contact.id === taskBeingEdited.contact_id);
                    let email = {
                        subject: taskBeingEdited?.subject,
                        body: taskBeingEdited?.body || "",
                        contactDetails,
                        attachments:taskBeingEdited?.attachments
                    }
                    setEmailData(email);
                    loadTask(taskBeingEdited);
                    setTaskModalOpen(prevState => (!prevState));
                    onClearNotification(notification_id);   
            });
        }
        if(type === "event"){
            onReadEvent(id).then((event) => {
                if (event) {
                    event.id = id; // TODO: return id from the backend
                    loadEvent(event);
                    onClearNotification(notification_id);   
                }
            });
            setEventModalOpen(prevState => (!prevState));
        }
        if(type === "email"){
            setEmailModalOpen(prevState => (!prevState)); 
            onClearNotification(notification_id);
        }
        setPersonId(id);
    };

    const onOpenNotification = (id, type, notification_id) => {
        switch(type){
            case "task":
                onToggleModal(id, type, notification_id);
                break;
            case "event":
                onToggleModal(id, type, notification_id);
                break;
            case "email":
                onToggleModal(id, type, notification_id);
                break;
            case "voicemail":
            case "text":
            case "fax":
            default:
                console.log("Failed: Open Notification");
        }
    }

    const setPersonId = (id) => {
        setPerson_id(id);
    };

    const onClearAllNotifications = async () => {
        const notification_ids = centerNotifications.map(notifcation => notifcation.notification_id)
        try {
            await dispatch(notificationActions.onClearNotifications('', {}, '', {}, notification_ids));
            await onReadNotificationCenter();
        } catch(e) {
            console.log("Failed: Clear Notifications", e);
        }
    }
    
    const onClearNotification = async (id) => {
        try {
            await dispatch(notificationActions.markReadNotification(id, {}, { read: true }));
            await onReadNotificationCenter();
        } catch(e) {
            console.log("Failed: Mark Read Notification", e);
        }
    }

    const onReadNotificationCenter = () => {
        return dispatch(notificationActions.onReadNotificationCenter());
    }

    const onReadNotificationHub = (params) => {
        const queryParameters = {
            page: 1,
            limit: 25,
            status: tab === 'notifications' ? 'all' : tab,
            ...Object.fromEntries(params)
        }
        dispatch(notificationActions.onReadNotificationHub(queryParameters));
    }
    
    const onReadTask = (id) => {
        return dispatch(taskActions.readTask(id));
    }

    const onDeleteEvent = (id) => {
        return dispatch(eventActions.deleteEvent(id));
    }

    const onUpload = (file, file_type) => {
        return dispatch(uploadActions.uploadFiles(file, file_type));
    }
    const onReadEvent = (id) => {
        return dispatch(eventActions.getEvent(id));
    }

    const onUpdateEvent = (event_id, body) => {
        dispatch(eventActions.updateEvent(event_id, body));
    }

    const onDelete = async () => {
        try {
            await onDeleteEvent(eventBeingEdited.id);
            Notification.alert(
                "Successfully deleted event!",
                STATUS_TYPES.SUCCESS
            );
            setEventModalOpen(false);
            (tab === "notifications" || tab2 ==="notifications") && onReadNotificationHub(params);
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to delete event", STATUS_TYPES.ERROR);
        }
    }

    const onUpdate = async () => {
        const event = getValues();
        const {
            all_day,
            start_date,
            start_time,
            end_date,
            end_time,
            attachments,
            timezone,
            contacts,
            users,
        } = event;
        const { id } = eventBeingEdited;
        const start = DateTimeUtils.getUnixTime(
            `${start_date}${all_day ? "" : ` ${start_time}`}`,
            all_day
                ? DateTimeUtils.WORD_DATE_FORMAT
                : DateTimeUtils.WORD_DATETIME_FORMAT,
            timezone
        );
    
        let event_label = event.label ? event.label : 0;

        if (
            event_label !== 0 &&
            event_labels.findIndex(({ value }) => value === event_label) ===
                -1 &&
            !DateTimeUtils.isBeforeOrOnCurrentDate(
                DateTimeUtils.formatEpoch(start, DateTimeUtils.DATETIME_FORMAT),
                DateTimeUtils.DATETIME_FORMAT
            )
        ) {
            event_label = 0;
        }

        const attendees = [
            ...contacts.map((contact_id) => ({
                accepted: "no",
                contact_id,
                emailed: false,
            })),
            ...users.map((user_id) => ({
                accepted: "no",
                user_id,
                emailed: false,
            })),
        ];

        // TODO: add recurring event properties
        const payload = {
            complete: false,
            conference_phone: event.phone_number,
            conference_pin: event.pin,
            conference_url: event.conference_line,
            content: event.content,
            content_type: "text/html",
            event_label_id: event_label,
            location: event.location,
            name: event.name,
            all_day,
            start_date,
            start_time,
            end_date,
            end_time,
            // "recurring_event_id": 0,
            organizer_id,
            organizer,
            organizer_email,
            users: users,
            contact_id: contacts,
            contact_name: notificationContactName,
            timezone,
            event_id: id,
            // "recurring_event_id": 0,
        }

        try {
            await onUpdateEvent(id, Utils.toPayload(payload));
            const files = attachments;
            const filesToAdd = files.filter((file) => file.id === undefined);
            await Promise.all([
                addAttachments(filesToAdd, id, "pdf"),
                removeAttachments(
                    files.filter((file) => file.id),
                    id
                ),
                updateAttendees(attendees, id),
            ]);
            Notification.alert(
                "Successfully updated the event!",
                STATUS_TYPES.SUCCESS
            );
            setEventModalOpen(false);
            (tab === "notifications" || tab2 === "notifications") && onReadNotificationHub(params);

        } catch (e) {
            console.log(e);
            Notification.alert(
                "Failed to update the event",
                STATUS_TYPES.ERROR
            );
        }
    };

    const addAttendees = (event_id, usersToAdd) => {
        let params = {
            payload: usersToAdd,
            integration_id: null,
            access_token: null,
        };
        if (usersToAdd.length > 0) {
            return onAddAttendees(event_id, params);
        }
        return Promise.resolve();
    }

    const removeAttendees = (event_id, usersToRemove) => {
        let params = { event_id, integration_id: null, access_token: null };
        if (usersToRemove.length > 0) {
            return Promise.all(
                usersToRemove.map((user) => onRemoveAttendee(user, params))
            );
        }
        return Promise.resolve();
    }

    const updateAttendees = async (updatedAttendees, event_id) => {
        const attending_users = eventBeingEdited?.attendees?.users || [];
        const attending_contacts = eventBeingEdited?.attendees?.contacts || [];

        const updatedUsers = updatedAttendees.filter(
            (attendee) => attendee.user_id
        );

        const updatedContacts = updatedAttendees.filter(
            (attendee) => attendee.contact_id
        );
        
        let usersToAdd = updatedUsers.filter(({user_id}) => {
            return !attending_users.includes(user_id)
        })

        const usersToRemove = attending_users.filter((user) => (
            usersToAdd.findIndex(({ user_id }) => user_id === user) === -1 &&
            updatedUsers.findIndex(({ user_id }) => user_id === user) === -1
        ));

        let contactsToAdd = updatedContacts.filter(({contact_id}) => {
            return !attending_contacts.includes(contact_id)
        })

        const contactsToRemove = attending_contacts.filter((contact) => (
            contactsToAdd.findIndex(({ contact_id }) => contact_id === contact) === -1 &&
            updatedContacts.findIndex(({ contact_id }) => contact_id === contact) === -1
        ));

        await Promise.all([
            addAttendees(event_id, [...usersToAdd, ...contactsToAdd]),
            removeAttendees(event_id, [...usersToRemove, ...contactsToRemove]),
        ]);
    }

    const onAddAttachments = (attachments, user_id, event_id) => {
        return dispatch(
            eventAttachmentActions.addAttachments(
                attachments,
                { user_id },
                event_id
            )
        );
    }

    const onRemoveAttachment = (attachment_id, event_id, user_id) => {
        return dispatch(
            eventAttachmentActions.removeAttachment(attachment_id, {
                user_id,
                event_id,
            })
        );
    }

    const onAddAttendees = (event_id, params) => {
        return dispatch(
            eventAttendeeActions.addAttendees(params, null, event_id)
        );
    }

    const onRemoveAttendee = (attendee_id, params) => {
        return dispatch(
            eventAttendeeActions.removeAttendee(attendee_id, params)
        );
    }

    const addAttachments = async (files, event_id) => {
        if (files && files.length > 0) {
            const toUpload = files.filter((file) => file.id === undefined);
            const toCopy = files.filter(file => file.id).map(file => ({
                file_id: file.file_id,
                name: file.name,
                size: file.size
            }));
            const uploadedFiles = await onUpload(toUpload, FILE_CATEGORIES.EVENT);
            const newFiles = toUpload.map((_, idx) => {    
                return {
                    name: files[idx].name,
                    file_id: uploadedFiles[idx],
                    size: files[idx].size
                }})
            return onAddAttachments(
                [
                    ...newFiles,
                    ...toCopy,
                ],
                current_user.user_id,
                event_id
            );
        }
        return Promise.resolve();
    }

    const removeAttachments = (files, event_id) => {
        if (!files) {
            files = [];
        }
        const toRemove = eventBeingEdited.attachments.filter(
            (attachment) =>
                files.findIndex((file) => file.id === attachment.id) === -1
        );
        if (toRemove.length <= 0) {
            return Promise.resolve();
        }
        return Promise.all(
            toRemove.map((file) =>
                onRemoveAttachment(file.id, event_id, current_user.user_id)
            )
        );
    }

    const {
        control: taskControl,
        watch: taskWatch,
        isValid: taskIsValid,
        isDirty: taskIsDirty,
        trigger: taskTrigger,
        setValue: taskSetValue,
        getValues: taskGetValues,
        clearErrors: taskClearErrors,
        setError: taskSetError,
        reset: taskReset,
        taskBeingEdited,
        loadTask,
        addTask,
        state,
        setState,
        onDelete: onDeleteTask,
        onUpdate: onUpdateTask,
        handleSubmit,
        formatTaskBeingEdited,
    } = useTaskForm({
        isContact: false,
        isModal: true,
        open: taskModalOpen,
    });

    const {
        control,
        watch,
        isValid,
        isDirty,
        trigger,
        setValue,
        getValues,
        reset,
        isAllowedToModify,
        eventBeingEdited,
        loadEvent,
        handleSubmit: handleSubmitEvent,
        resetField,
    } = useEventForm({});

    return {
        onToggleModal,
        taskModalOpen,
        setTaskModalOpen,
        control,
        watch,
        isValid,
        isDirty,
        trigger,
        setValue,
        getValues,
        reset,
        isAllowedToModify,
        eventBeingEdited,
        handleSubmitEvent,
        resetField,
        taskControl,
        taskWatch,
        taskIsValid,
        taskIsDirty,
        taskTrigger,
        taskSetValue,
        taskGetValues,
        taskClearErrors,
        taskSetError,
        taskReset,
        taskBeingEdited,
        loadTask,
        addTask,
        state,
        setState,
        onDeleteTask,
        onUpdateTask,
        handleSubmit,
        task_labels,
        organizer_id,
        organizer,
        organizer_email,
        eventModalOpen,
        emailModalOpen,
        emailData,
        person_id,
        onDelete,
        onUpdate,
        onClearAllNotifications,
        onClearNotification,
        setEventModalOpen,
        onOpenNotification,
        current_user,
        setNotificationContactName,
    }      
};

export default NotificationPopupModals;

