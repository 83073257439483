import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Statistic } from 'semantic-ui-react';

export const AccountStatistics = ({
    account_id,
    contacts_count = 0,
    leads_count = 0,
    opportunities_count = 0
}) => {
    return (
        <Grid verticalAlign='middle' textAlign='center'>
            <Grid.Row style={{ paddingBottom: 0, marginBottom: 0 }} columns='equal'>
                <Grid.Column>
                    <Statistic as={Link} size='tiny' color='primary' to={`/account/${account_id}/contacts`} >
                        <Statistic.Value>{contacts_count}</Statistic.Value>
                    </Statistic>
                </Grid.Column>
                <Grid.Column>
                    <Statistic as={Link} size='tiny' color='primary' to={`/account/${account_id}/leads?filter=open`}>
                        <Statistic.Value>{leads_count}</Statistic.Value>
                    </Statistic>
                </Grid.Column>
                <Grid.Column>
                    <Statistic as={Link} size='tiny' color='primary' to={`/account/${account_id}/opportunities?filter=open`}>
                        <Statistic.Value>{opportunities_count}</Statistic.Value>
                    </Statistic>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: 0, marginTop: 0 }} columns='equal'>
                <Grid.Column>
                    Contacts
                </Grid.Column>
                <Grid.Column>
                    Leads
                </Grid.Column>
                <Grid.Column>
                    Opportunities
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
