import ACTION_NAMES from 'actions/ACTION_NAMES';
import { generateReadActionCreator, generateCreateActionCreator } from 'actions/crudActionCreators';
import routes from 'actions/routes';

export default {
    readEvents: generateReadActionCreator(routes.API_EVENTS, ACTION_NAMES.EVENTS),
    addEventLabels: generateCreateActionCreator(routes.API_EVENT_LABELS, ACTION_NAMES.EVENT_LABELS),
    readEventLabels: generateReadActionCreator(routes.API_EVENT_LABELS, ACTION_NAMES.EVENT_LABELS),
    loadEventsTab: generateReadActionCreator(routes.API_EVENTS, ACTION_NAMES.EVENTS_TAB)
}
