import React from 'react';
import { Grid } from 'semantic-ui-react';
import CalendarIntegrations from './CalendarIntegrations';
import CalendarPreferences from './CalendarPreferences';

const Calendar = () => (
    <Grid>
        <Grid.Row>
            <Grid.Column>
                <CalendarIntegrations />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <CalendarPreferences />
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

export default Calendar;
