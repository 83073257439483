import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Segment, Header, Popup, List, Ref } from 'semantic-ui-react';

import ActiveCall from './ActiveCall';

import './TransferDestination.scss';

const TransferDestination = ({ id, type, icon, activeCallersViewDirection = 'left', children, areActiveCallersVisible, isDragging, activeCallers = [], ...otherProps }) => (
    <Droppable droppableId={`TRANSFER DESTINATIONS-${id}-${children}-${type}`}>
        {({ droppableProps, innerRef }, snapshot) => (
            <Ref innerRef={innerRef}>
                <Segment {...droppableProps} textAlign='center' className={`TransferDestination ${isDragging ? 'TransferDestination--dragging' : ''} ${snapshot.isDraggingOver ? 'TransferDestination--dragging-over' : ''}`} {...otherProps}>
                    <Popup
                        trigger={(
                            <Header icon>
                                <div className='TransferDestination__icon'>{icon}</div>
                                <div className='TransferDestination__name'>{children}</div>
                            </Header>
                        )}
                        open={areActiveCallersVisible && activeCallers.length > 0}
                        content={<List className={`ActiveCallers ${activeCallers.length > 4 ? 'ActiveCallers--compact' : ''} `}>
                            {activeCallers.map(activeCall => (
                                <List.Item>
                                    <ActiveCall
                                        caller={activeCall.caller}
                                        duration={activeCall.duration}
                                    />
                                </List.Item>
                            ))}
                        </List>}
                        position={`${activeCallersViewDirection} center`}
                    />
                </Segment>
            </Ref>
        )}
    </Droppable>
);

export default TransferDestination;
