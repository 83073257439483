import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Header, Image } from 'semantic-ui-react';
import { SelectedElement, ColorTile, ColorPicker } from 'dyl-components';
import { LogoTextBlue } from 'dyl-components'; 

import companyActions from 'actions/company';


import './index.scss';
import { Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';

const FORM_BG_COLORS = ["#FCFCFC", "#F8EDED", "#F8F7FF", "#FCF4EA"];
const PAGE_BG_COLORS = ["#EDEDED", "#FFD8D8", "#A9A0FF", "#FFD69D"];

const WebformTheme = ({
    logo,
    onReadCompanyLogo,
    hasCompanyLogo,
    control
}) => {

    useEffect(() => {
        onReadCompanyLogo()
        // eslint-disable-next-line
    }, []);

    return (
        <Grid className='WebformTheme'>
            <Grid.Row className='WebformTheme__logo'>
                <Grid.Column>
                    <Controller
                        name='use_logo'
                        control={control}
                        render={({ field: { name, onChange, value: useLogo } }) => (
                            <React.Fragment>
                                <Header as='h3'>
                                    Use Logo
                                    <Header.Subheader>
                                        Use your business logo in the form to make the experience more personalized. <Link to='/settings/company-profile'> Change company logo in Company Profile. </Link>
                                    </Header.Subheader>
                                </Header>
                                <SelectedElement
                                    selected={useLogo}
                                    unselectable
                                    borderRadius={15}
                                    onClick={useLogo ? () => { } : () => { onChange({ target: { name, value: true } }) }}
                                    onUnselect={() => { onChange({ target: { name, value: false } }) }}
                                >
                                    <Image className={`WebformTheme__image WebformTheme__image${hasCompanyLogo ? "--company" : "--default"}`} src={logo} size='small' />
                                </SelectedElement>
                            </React.Fragment>
                        )}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className='WebformTheme__formBackground'>
                <Grid.Column>
                    <Header as='h3'>
                        Select Form Background Color
                        <Header.Subheader>
                            Add a form background color to make it look more cleaner.
                        </Header.Subheader>
                    </Header>
                    <Controller
                        name='form_background_color'
                        control={control}
                        render={({ field: { onChange, name, value: formBackgroundColor } }) => (
                            <React.Fragment>
                                <div className='WebformTheme__colorTiles'>
                                    {FORM_BG_COLORS.map(color => (
                                        <SelectedElement selected={formBackgroundColor === color} onClick={() => { onChange({ target: { name, value: color } }) }}>
                                            <ColorTile color={color} />
                                        </SelectedElement>
                                    ))}
                                    <ColorPicker onAdd={(value) => { onChange({ target: { name, value: value?.toUpperCase() } }) }} />
                                </div>
                                {formBackgroundColor && !FORM_BG_COLORS.includes(formBackgroundColor) &&
                                    <div className='WebformTheme__customColorTile'>
                                        <SelectedElement selected unselectable onUnselect={() => { onChange({ target: { name, value: FORM_BG_COLORS[0] } }); }}>
                                            <ColorTile color={formBackgroundColor} />
                                        </SelectedElement>
                                    </div>
                                }
                            </React.Fragment>
                        )}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className='WebformTheme__pageBackground'>
                <Grid.Column>
                    <Header as='h3'>
                        Select Page Background Color
                        <Header.Subheader>
                            Add a page background color for your form to make it look more professional.
                        </Header.Subheader>
                    </Header>
                    <Controller
                        name='page_background_color'
                        control={control}
                        render={({ field: { onChange, name, value: pageBackgroundColor } }) => (
                            <React.Fragment>
                                <div className='WebformTheme__colorTiles'>
                                    {PAGE_BG_COLORS.map(color => (
                                        <SelectedElement selected={pageBackgroundColor === color} onClick={() => { onChange({ target: { name, value: color } }) }}>
                                            <ColorTile color={color} />
                                        </SelectedElement>
                                    ))}
                                    <ColorPicker onAdd={(value) => { onChange({ target: { name, value: value?.toUpperCase() } }) }} />
                                </div>
                                {pageBackgroundColor && !PAGE_BG_COLORS.includes(pageBackgroundColor) &&
                                    <div className='WebformTheme__customColorTile'>
                                        <SelectedElement selected unselectable onUnselect={() => { onChange({ target: { name, value: PAGE_BG_COLORS[0] } }); }} >
                                            <ColorTile color={pageBackgroundColor} />
                                        </SelectedElement>
                                    </div>
                                }
                            </React.Fragment>
                        )}
                    />
                </Grid.Column>

            </Grid.Row>
        </Grid>
    );
}

const mapStateToProps = state => {

    const { logo, isReadingCompanyLogo } = state.company;

    return {
        isReadingCompanyLogo,
        logo: logo ? `data:image/png;base64, ${logo}` : LogoTextBlue,
        hasCompanyLogo: logo
    }
};

const mapDispatchToProps = dispatch => ({
    onReadCompanyLogo: () => {
        return dispatch(companyActions.readCompanyLogo({ isThumbnail: false }));
    },
});


export default connect(mapStateToProps, mapDispatchToProps)(WebformTheme);
