import ACTION_NAMES from 'actions/ACTION_NAMES';
import { generateReadOneActionCreator, generateCreateActionCreator, generateUpdateActionCreator, generateDeleteActionCreator } from 'actions/crudActionCreators';
import routes from 'actions/routes';

export default {
    addAttendees: generateCreateActionCreator(routes.API_EVENT, ACTION_NAMES.EVENT_ATTENDEES, () => {}, () => {}, 'attendee'),
    getAttendees: generateReadOneActionCreator(routes.API_EVENT, ACTION_NAMES.EVENT_ATTENDEES, 'attendee'),
    updateAttendee: generateUpdateActionCreator(routes.API_EVENT_ATTENDEE, ACTION_NAMES.EVENT_ATTENDEES),
    removeAttendee: generateDeleteActionCreator(routes.API_EVENT_ATTENDEE, ACTION_NAMES.EVENT_ATTENDEES)
}
