import React from 'react';
import { Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Notification, STATUS_TYPES } from 'dyl-components';

import taskTypesActions from 'actions/task_types';

const AddTaskTypeSection = ({ name, onChange, onAddTaskType, onClose, isCreating }) => (
    <Form loading={isCreating}>
        <Form.Input
            label='Add Task Type'
            name='name'
            value={name}
            onChange={onChange}
            placeholder='Enter name of task type'
        />
        <Form.Group widths='equal'>
            <Form.Button
                fluid
                content={'Cancel'}
                onClick={onClose}
                basic
                primary
            />
            <Form.Button
                primary
                fluid
                content={'Add'}
                onClick={onAddTaskType}
            />
        </Form.Group>
    </Form>
)

class AddTaskTypeSectionContainer extends React.Component {
    state = {
        name: ''
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onAddTaskType = () => {
        this.props.onCreate({ ...this.state }).then(() => {
            Notification.alert('Successfully added task type', STATUS_TYPES.SUCCESS, true);
            this.props.onReadTaskTypes();
            this.onClose();
        }).catch((error) => {
            if (error.error === 'unique_violation') {
                Notification.alert('Duplicate name', STATUS_TYPES.ERROR, true);
            } else {
                Notification.alert('Failed to add task type', STATUS_TYPES.ERROR, true);
            }
        });
        
    }

    onClose = () => {
        this.setState({ name: '' });
        this.props.onClose();
    }

    render() {
        return (
            <AddTaskTypeSection
                name={this.state.name}
                isCreating={this.props.isCreating}
                onClose={this.onClose}
                onAddTaskType={this.onAddTaskType}
                onChange={this.onChange}
            />
        )
    }
}

const mapStateToProps = state => ({
    isCreating: state.task_types.isCreating
});

const mapDispatchToProps = dispatch => ({
    onCreate: ({ name }) => {
        return dispatch(taskTypesActions.addTaskType([{ name }]))
    },
    onReadTaskTypes: () => {
        dispatch(taskTypesActions.readTaskTypes());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTaskTypeSectionContainer);
