import React from 'react';
import { Grid } from 'semantic-ui-react';
import { DragDropContext } from 'react-beautiful-dnd';
import TransferDestinations from './subcomponents/TransferDestinations';
import Callbox from './subcomponents/Callbox';
import Departments from './subcomponents/Department';

import officeViewActions from 'actions/office_view';
import { connect } from 'react-redux';
import './index.scss';
import callActions from 'actions/call';

const OfficeView = ({ isActive }) => (
    <Grid stackable className='OfficeView'>
        <Grid.Row>
            <Grid.Column>
                <Callbox />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <TransferDestinations isActive={isActive} />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <Departments />
            </Grid.Column>
        </Grid.Row>
    </Grid>
)

class OfficeViewContainer extends React.Component {
    onFinishDraggingCall(result) {
        console.log(result)

        const { destination, draggableId } = result;

        if (!destination) {
            return;
        }

        let channel_uuid = draggableId;
        let transferData = destination.droppableId.split('-');

        if (transferData[0] === 'EXTENSION') {
            this.props.onTransferCall({
                channel_uuid,
                transfer_to: transferData[1],
                caller_id_name: transferData[2],
                transfer_type: 'extension'
            });
        } else if (transferData[0] === 'TRANSFER DESTINATIONS') {
            this.props.onTransferCall({
                channel_uuid,
                transfer_to: transferData[1],
                caller_id_name: transferData[2],
                transfer_type: transferData[3]
            });
            return;
        } else {
            return;
        }

        this.props.onCloseActionsView();
    }

    onCall(caller) {
        this.props.onCloseActionsView();
        setTimeout(() => {
            this.props.onCall(caller);
        }, 1000)
    }

    render() {
        if (!this.props.isActive) {
            return "";
        }
        return (
            <DragDropContext
                onDragEnd={this.onFinishDraggingCall.bind(this)}
            >
                <OfficeView
                    {...this.props}
                    onCall={this.onCall.bind(this)}
                />
            </DragDropContext>
        )
    }
}

const mapStateToProps = state => {
    return {
        callerInActionsView: state.officeView.callerInActionsView,
        actionsViewVisible: state.officeView.actionsViewVisible,
        isActiveCall: state.officeView.isActiveCall
    }
}

const mapDispatchToProps = dispatch => ({
    onTransferCall: (transfer) => {
        dispatch(callActions.transfer(transfer))
    },
    onTransferCallFromQueueToDepartment: (callId, sourceCallQueueId, departmentAgentId) => {
        dispatch(officeViewActions.transferCallFromQueue(callId, sourceCallQueueId, departmentAgentId));
    },
    onReorderCallQueues: (sourceIndex, destinationIndex) => {
        dispatch(officeViewActions.reorderCallQueues(sourceIndex, destinationIndex));
    },
    onClickActiveCallerName: (caller) => {
        dispatch(officeViewActions.clickActiveCallerName(caller));
    },
    onCloseActionsView: () => {
        dispatch(officeViewActions.closeActionsView());
    },
    onCall: (contact) => {
        dispatch(officeViewActions.call(contact));
    },
    onTransferCallToQueue: (callId, callQueueId) => {
        dispatch(officeViewActions.transferCallToQueue(callId, callQueueId));
    },
    onTransferCallToDepartment: (callId, departmentAgentId) => {
        dispatch(officeViewActions.transferIncomingCallToDepartment(callId, departmentAgentId));
    },
    onReadOfficeView: () => {
        dispatch(officeViewActions.getOfficeView());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(OfficeViewContainer);
