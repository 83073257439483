const initialState = {
    centerNotifications: [],
    enableBell: false,
    isReadingNotificationCenter: false,
    
    hasNotifications: false,

    hubNotifications: [],
    isReadingNotificationHub: false,
    isUpdatingNotificationHub: false,
    isReadingNotificationBulkAction: false,
    
    isUpdatingNotification: false,
    isClearingNotification: false,
    isDeletingNotification: false,
    notificationSettings: {},
    isUpdatingNotificationSettings:false
}

export default initialState;
