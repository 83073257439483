import React from 'react';
import { DateInput } from 'dyl-components';
import { Accordion, Form, Segment } from 'semantic-ui-react';

import { CustomGroupUtils } from 'utils';
import { STATES } from 'shared/constants/STATES';
import { Controller } from 'react-hook-form';
import {useSelector} from "react-redux";

import './BusinessAdditionalInfo.scss';
const BusinessAdditionalInfo = ({
    control
}) => {
    const group = useSelector(state => state.custom_fields_group.modal_standard_group[0] || []);
    const fields = group?.fields?.data || [];
    
    const accordionIcon = {
        className: `fas fa-circle-chevron-right`,
        color: 'primary'
    };

    return (
        <Accordion
            fluid
            styled
            exclusive={false}
            defaultActiveIndex={[]}
            className='AdditionalBusinessData'
            panels={[
                {
                    key: 'panel-0',
                    title: {
                        content: 'Business Details',
                        icon: accordionIcon
                    },
                    content: {
                        content: (
                            <Segment basic>
                                <Form.Group widths='equal'>
                                    <Controller
                                        name='business_nickname'
                                        control={control}
                                        render={({ field: { name, value, onChange } }) => (
                                            <Form.Input
                                                name={name}
                                                value={value}
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                placeholder='Enter business nickname'
                                                label='Business Nickname'
                                            />
                                        )}
                                    />
                                    <Controller
                                        name='legal_business_name'
                                        control={control}
                                        render={({ field: { name, value, onChange } }) => (
                                            <Form.Input
                                                name={name}
                                                value={value}
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                placeholder='Enter legal business name'
                                                label='Legal Business Name'
                                            />
                                        )}
                                    />
                                    <Controller
                                        name='dba_business_name'
                                        control={control}
                                        render={({ field: { name, value, onChange } }) => (
                                            <Form.Input
                                                name={name}
                                                value={value}
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                placeholder='Enter DBA business name'
                                                label='DBA Business Name'
                                            />
                                        )}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Controller
                                        name='state_of_organization'
                                        control={control}
                                        render={({ field: { name, value, onChange } }) => (
                                            <Form.Select
                                                name={name}
                                                value={value}
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                placeholder='Select state of organization'
                                                label='State Org.'
                                                selectOnBlur={false}
                                                options={STATES.map(state => ({ ...state, value: state.key }))}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name='business_in_other_states'
                                        control={control}
                                        render={({ field: { name, value, onChange } }) => (
                                            <Form.Select
                                                name={name}
                                                value={value}
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                placeholder='Select business in other states'
                                                label='Bus. Oth. States'
                                                selectOnBlur={false}
                                                multiple
                                                options={STATES.map(state => ({ ...state, value: state.key }))}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name='product_services_sold'
                                        control={control}
                                        render={({ field: { name, value, onChange } }) => (
                                            <Form.Input
                                                name={name}
                                                value={value}
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                placeholder='Enter products/services sold'
                                                label='Prdt./Svcs. Sold'
                                            />
                                        )}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Controller
                                        name='date_founded'
                                        control={control}
                                        render={({ field: { name, value, onChange } }) => (
                                            <Form.Field
                                                control={DateInput}
                                                name={name}
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    onChange({ target: { name, value } })
                                                }}
                                                label='Date Founded'
                                                placeholder='Enter date founded'
                                            />
                                        )}
                                    />
                                    <Controller
                                        name='business_structure'
                                        control={control}
                                        render={({ field: { name, value, onChange } }) => (
                                            <Form.Select
                                                name={name}
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    onChange({ target: { name, value } })
                                                }}
                                                placeholder='Select business structure'
                                                label='Business Structure'
                                                selectOnBlur={false}
                                                options={CustomGroupUtils.getFieldOptions(fields, name)}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name='business_tags'
                                        control={control}
                                        render={({ field: { name, value, onChange } }) => (
                                            <Form.Select
                                                name={name}
                                                value={value}
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                placeholder='Select business tags'
                                                label='Business Tags'
                                                selectOnBlur={false}
                                                options={[]}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </Segment>
                        )
                    }
                },
                {
                    key: 'panel-1',
                    title: {
                        content: 'Business Registration',
                        icon: accordionIcon
                    },
                    content: {
                        content: (
                            (
                                <Segment basic>
                                    <Form.Group widths='equal'>
                                        <Controller
                                            name='state_employer_account_number'
                                            control={control}
                                            render={({ field: { name, value, onChange } }) => (
                                                <Form.Input
                                                    name={name}
                                                    value={value}
                                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                    label='State EAN'
                                                    placeholder='Enter state EAN'
                                                />
                                            )}
                                        />
                                        <Controller
                                            name='federal_employer_identification_number'
                                            control={control}
                                            render={({ field: { name, value, onChange } }) => (
                                                <Form.Input
                                                    name={name}
                                                    value={value}
                                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                    label='FEIN'
                                                    placeholder='Enter FEIN'
                                                />
                                            )}
                                        />
                                        <Controller
                                            name='sic'
                                            control={control}
                                            render={({ field: { name, value, onChange } }) => (
                                                <Form.Input
                                                    name={name}
                                                    value={value}
                                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                    label='SIC'
                                                    placeholder='Enter SIC'
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Controller
                                            name='primary_sic'
                                            control={control}
                                            render={({ field: { name, value, onChange } }) => (
                                                <Form.Input
                                                    name={name}
                                                    value={value}
                                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                    label='Primary SIC'
                                                    placeholder='Enter primary SIC'
                                                />
                                            )}
                                        />
                                        <Controller
                                            name='naics_code'
                                            control={control}
                                            render={({ field: { name, value, onChange } }) => (
                                                <Form.Input
                                                    name={name}
                                                    value={value}
                                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                    label='NAICS Code(s)'
                                                    placeholder='Enter NAICS code(s)'
                                                />
                                            )}
                                        />
                                        <Controller
                                            name='stock_ticker'
                                            control={control}
                                            render={({ field: { name, value, onChange } }) => (
                                                <Form.Input
                                                    name={name}
                                                    value={value}
                                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                    label='Stock Ticker'
                                                    placeholder='Enter stock ticker'
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Controller
                                            name='duns'
                                            control={control}
                                            render={({ field: { name, value, onChange } }) => (
                                                <Form.Input
                                                    name={name}
                                                    value={value}
                                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                    label='Duns #'
                                                    placeholder='Enter duns #'
                                                />
                                            )}
                                        />
                                        <Controller
                                            name='employee_size'
                                            control={control}
                                            render={({ field: { name, value, onChange } }) => (
                                                <Form.Input
                                                    name={name}
                                                    value={value}
                                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                    label='Employee Size'
                                                    type='number'
                                                    placeholder='Enter employee size'
                                                />
                                            )}
                                        />
                                        <Controller
                                            name='professional_corporation'
                                            control={control}
                                            render={({ field: { name, value, onChange } }) => (
                                                <Form.Input
                                                    name={name}
                                                    value={value}
                                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                    label='PCs'
                                                    placeholder='Enter PCs'
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                    <Controller
                                        name='out_of_state_employees'
                                        control={control}
                                        render={({ field: { name, value, onChange } }) => (
                                            <Form.Field
                                                control={Form.Field}
                                                value={value}
                                                label='Out State Emp.'
                                            >
                                                <Form.Group>
                                                    <Form.Checkbox
                                                        name={name}
                                                        toggle
                                                        onChange={(_, { checked: value }) => { onChange({ target: { name, value } }) }}
                                                    />
                                                    <span>No</span>
                                                </Form.Group>
                                            </Form.Field>
                                        )}
                                    />
                                </Segment>
                            )
                        )
                    }
                },
                {
                    key: 'panel-2',
                    title: {
                        content: 'Financial Info',
                        icon: accordionIcon
                    },
                    content: {
                        content: (
                            (
                                <Segment basic>
                                    <Form.Group widths='equal'>
                                        <Controller
                                            name='accounting_fiscal_year'
                                            control={control}
                                            render={({ field: { name, value, onChange } }) => (
                                                <Form.Input
                                                    name={name}
                                                    value={value}
                                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                    label='Acct. Fiscal Year'
                                                    placeholder='Enter accounting fiscal year'
                                                />
                                            )}
                                        />
                                        <Controller
                                            name='business_method'
                                            control={control}
                                            render={({ field: { name, value, onChange } }) => (
                                                <Form.Select
                                                    name={name}
                                                    value={value}
                                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                    placeholder='Select accounting method'
                                                    label='Acct. Method'
                                                    selectOnBlur={false}
                                                    options={CustomGroupUtils.getFieldOptions(fields, name)}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name='sale_volume'
                                            control={control}
                                            render={({ field: { name, value, onChange } }) => (
                                                <Form.Input
                                                    name={name}
                                                    value={value}
                                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                    label='Sales Volume'
                                                    placeholder='Enter sales volume'
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Controller
                                            name='annual_revenue'
                                            control={control}
                                            render={({ field: { name, value, onChange } }) => (
                                                <Form.Input
                                                    name={name}
                                                    value={value}
                                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                    label='Annual Revenue'
                                                    placeholder='Enter annual revenue'
                                                />
                                            )}
                                        />
                                        <Form.Field />
                                        <Form.Field />
                                    </Form.Group>
                                </Segment>
                            )
                        )
                    }
                }
            ]}
        />
    )
}
export default BusinessAdditionalInfo;
