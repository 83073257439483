export default class MathUtils {
    static roundDecimals(number) {
        return Math.round((number + Number.EPSILON) * 100)/100
    }

    static calculateItemTotals = (item) => {
        const price = item.price;
        const sum =
            MathUtils.roundDecimals((price +
                (item.additional_price || 0)) *
            item.quantity);
        const discount = (item.discount || 0)/100;
        const totalAddonPrice =
            MathUtils.roundDecimals(item.addons.reduce((a, b) => a + b.price || 0, 0) *
            item.quantity);
        const grossTotal = sum + totalAddonPrice;
        const discountAmount = MathUtils.roundDecimals((grossTotal * discount));
        const netTotal = grossTotal - discountAmount;
        const totalTax = MathUtils.roundDecimals(item.taxes.reduce(
            (a, b) =>
                a +
                b.amount *
                    (b.percent
                        ? 0.01 * netTotal
                        : item.quantity),
            0
        ));
        const totalFee = MathUtils.roundDecimals(item.fees.reduce(
            (a, b) =>
                a +
                b.amount *
                    (b.percent
                        ? 0.01 * netTotal
                        : item.quantity),
            0
        ));
        
        const subtotal =
            netTotal +
            totalTax +
            totalFee
        return {
            sum, grossTotal, netTotal, totalTax, totalFee, subtotal, discountAmount
        }
    }

    static calculatePercentage = (total, number) => `${(number * 100) / total}%`;
}
