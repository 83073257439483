import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { DateTimeUtils, generateResolver, Notification, STATUS_TYPES } from 'dyl-components';

import feeActions from 'actions/product_fees';

import feeSchema, { formatFeeDetails } from 'shared/schemas/fees/feeSchema';
import FeeForm from 'shared/forms/FeeForm';

const AddFee = ({ type }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            description: '',
            status: 'active',
            active_date: DateTimeUtils.getCurrentDate(DateTimeUtils.DATE_FORMAT),
            products: [],
            amount: '',
            percent: false
        },
        resolver: generateResolver(feeSchema)
    });

    const loading = useSelector(state => state.product_fees.isCreating);

    const onSave = async (data) => {
        try {            
            await dispatch(feeActions.addFee(formatFeeDetails(data), { type }));
            Notification.alert(`Succesfully created ${type}`, STATUS_TYPES.SUCCESS);
            methods.reset();
            navigate(`/settings/product-catalog-quotes-invoices/${type === "tax" ? "taxes" : "fees"}`, { replace: true, state: { saved: true } });
        } catch (e) {
            console.log(e);
            Notification.alert(`Failed to create ${type}`, STATUS_TYPES.ERROR);
        }
    }

    return (
        <FormProvider {...({ ...methods, type })}>
            <FeeForm onSave={onSave} loading={loading} />
        </FormProvider>
    );
}

export default AddFee;
