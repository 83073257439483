import React, { useContext, useState } from 'react';
import { Table, EllipsisDropdown, ControlledPopup, DateTimeUtils, VoicemailPlayback, Icon } from 'dyl-components';
import { Checkbox, Dropdown, DropdownItem, Header, Popup } from 'semantic-ui-react';
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';

import '../index.scss';

const Row = ({ IVR = {}, onRead, onDelete }) => {
    const { id, last_modified, label, alias, sound: { url, sound_id, label: sound_label }, in_use } = IVR;
    const [selectedIVRs, setSelectedIVRs] = useContext(BulkActionsContext);
    const [isVoicemailPlaybackOpen, setIsVoicemailPlaybackOpen] = useState(false);

    const isSelected = (ivr_id) => {
        return selectedIVRs.includes(ivr_id);
    }

    const onToggle = (ivr_id) => {
        const newSelectedIVRs = isSelected(ivr_id) ? selectedIVRs.filter(id => ivr_id !== id) : [...selectedIVRs, ivr_id];
        setSelectedIVRs(newSelectedIVRs);
    }

    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox checked={isSelected(id)} onChange={() => { onToggle(id) }} />
            </Table.Cell>
            <Table.Cell>
                <div><b>{DateTimeUtils.formatEpoch(last_modified, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                <div>{DateTimeUtils.formatEpoch(last_modified, DateTimeUtils.TIME_FORMAT)}</div>
            </Table.Cell>
            <Table.Cell>
                {label}
            </Table.Cell>
            <Table.Cell>
                {alias || ""}
            </Table.Cell>
            <Table.Cell>
                <Popup
                    style={{minWidth:400}}
                    trigger={
                        <Icon className="fa-solid fa-music" size="large" disabled={!!!url} color="primary" />
                    }
                    on="click"
                    content={
                        <VoicemailPlayback
                            onClose={() => setIsVoicemailPlaybackOpen(false)}
                            className={`audio-playback-${sound_id}`}
                            fileName={sound_label}
                            link={url}
                        />
                    }
                    position="bottom center"
                    open={isVoicemailPlaybackOpen}
                    onOpen={() => setIsVoicemailPlaybackOpen(true)}
                />
            </Table.Cell>
            <Table.Cell>
                <EllipsisDropdown>
                    <DropdownItem
                        text={"Edit"}
                        icon={"fa-solid fa-pencil"}
                        onClick={() => onRead(id)}
                    />
                    <ControlledPopup 
                        header={
                            <Header as='h4' textAlign='center'>
                                Are you sure?
                                <Header.Subheader style={{ marginTop: 5 }}>
                                    You cannot undo delete actions.
                                </Header.Subheader>
                            </Header>
                        }
                        trigger={<Dropdown.Item icon={'fa-solid fa-trash'} text={"Delete"} disabled={in_use} />}
                        onConfirm={() => onDelete(id)}
                        inverted
                    />
                </EllipsisDropdown>      
            </Table.Cell>
        </Table.Row>
    );
}

export default Row;


