import initialState from "./initialState";

import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function selfReportReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.SELF_REPORT, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingActivityLog: true, activityLog: [] };
        case getNamedAction(ACTION_NAMES.SELF_REPORT, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingActivityLog: false, activityLog: action.data };
        case getNamedAction(ACTION_NAMES.SELF_REPORT, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingActivityLog: false, activityLog: [] };

        case getNamedAction(ACTION_NAMES.SELF_REPORT_CALL, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isLoggingCall: true };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_CALL, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isLoggingCall: false };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_CALL, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isLoggingCall: false };

        case getNamedAction(ACTION_NAMES.SELF_REPORT_CALL, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, callLogBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_CALL, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, callLogBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_CALL, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, callLogBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.SELF_REPORT_CALL, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, callLogBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_CALL, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, callLogBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_CALL, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, callLogBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.SELF_REPORT_CALL, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, callLogBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_CALL, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, callLogBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_CALL, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, callLogBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.USER_CALL_ACTIVITY_LOG, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingUserCallLogs: true, userCallLogs: [] };
        case getNamedAction(ACTION_NAMES.USER_CALL_ACTIVITY_LOG, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingUserCallLogs: false, userCallLogs: action.data };
        case getNamedAction(ACTION_NAMES.USER_CALL_ACTIVITY_LOG, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingUserCallLogs: false, userCallLogs: [] };

        case getNamedAction(ACTION_NAMES.CUSTOMER_CALL_ACTIVITY_LOG, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCustomerCallLogs: true, customerCallLogs: [] };
        case getNamedAction(ACTION_NAMES.CUSTOMER_CALL_ACTIVITY_LOG, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCustomerCallLogs: false, customerCallLogs: action.data };
        case getNamedAction(ACTION_NAMES.CUSTOMER_CALL_ACTIVITY_LOG, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCustomerCallLogs: false, customerCallLogs: [] };

        case getNamedAction(ACTION_NAMES.SELF_REPORT_TEXT, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isLoggingText: true };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_TEXT, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isLoggingText: false };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_TEXT, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isLoggingText: false };

        case getNamedAction(ACTION_NAMES.SELF_REPORT_TEXT, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, textLogBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_TEXT, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, textLogBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_TEXT, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, textLogBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.SELF_REPORT_TEXT, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, textLogBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_TEXT, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, textLogBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_TEXT, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, textLogBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.SELF_REPORT_TEXT, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, textLogBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_TEXT, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, textLogBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_TEXT, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, textLogBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.USER_TEXT_ACTIVITY_LOG, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingUserTextLogs: true, userTextLogs: [] };
        case getNamedAction(ACTION_NAMES.USER_TEXT_ACTIVITY_LOG, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingUserTextLogs: false, userTextLogs: action.data };
        case getNamedAction(ACTION_NAMES.USER_TEXT_ACTIVITY_LOG, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingUserTextLogs: false, userTextLogs: [] };

        case getNamedAction(ACTION_NAMES.CUSTOMER_TEXT_ACTIVITY_LOG, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCustomerTextLogs: true, customerTextLogs: [] };
        case getNamedAction(ACTION_NAMES.CUSTOMER_TEXT_ACTIVITY_LOG, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCustomerTextLogs: false, customerTextLogs: action.data };
        case getNamedAction(ACTION_NAMES.CUSTOMER_TEXT_ACTIVITY_LOG, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCustomerTextLogs: false, customerTextLogs: [] };


        case getNamedAction(ACTION_NAMES.SELF_REPORT_EMAIL, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isLoggingEmail: true };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_EMAIL, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isLoggingEmail: false };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_EMAIL, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isLoggingEmail: false };

        case getNamedAction(ACTION_NAMES.SELF_REPORT_EMAIL, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, emailLogBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_EMAIL, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, emailLogBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_EMAIL, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, emailLogBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.SELF_REPORT_EMAIL, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, emailLogBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_EMAIL, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, emailLogBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_EMAIL, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, emailLogBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.SELF_REPORT_EMAIL, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, emailLogBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_EMAIL, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, emailLogBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.SELF_REPORT_EMAIL, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, emailLogBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.USER_EMAIL_ACTIVITY_LOG, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingUserEmailLogs: true, userEmailLogs: [] };
        case getNamedAction(ACTION_NAMES.USER_EMAIL_ACTIVITY_LOG, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingUserEmailLogs: false, userEmailLogs: action.data };
        case getNamedAction(ACTION_NAMES.USER_EMAIL_ACTIVITY_LOG, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingUserEmailLogs: false, userEmailLogs: [] };

        case getNamedAction(ACTION_NAMES.CUSTOMER_EMAIL_ACTIVITY_LOG, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCustomerEmailLogs: true, customerEmailLogs: [] };
        case getNamedAction(ACTION_NAMES.CUSTOMER_EMAIL_ACTIVITY_LOG, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCustomerEmailLogs: false, customerEmailLogs: action.data };
        case getNamedAction(ACTION_NAMES.CUSTOMER_EMAIL_ACTIVITY_LOG, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCustomerEmailLogs: false, customerEmailLogs: [] };

        default:
            return state;
    }
}

export default selfReportReducer;
