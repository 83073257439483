import React from 'react';
import { Header, Transition, Icon } from 'semantic-ui-react';
import ContactBusinessData from 'shared/ContactBusinessData';

const CompanyInfo = ({
    businessDetailsExpanded,
    toggleBusinessDetails
}) => {
    return (
        <div>
            <Header color='primary'>
                <span>Business Details <Icon
                    onClick={toggleBusinessDetails}
                    link
                    size='small'
                    className={`fas fa-circle-chevron-${businessDetailsExpanded ? 'up' : 'down'}`}
                />
                </span>
            </Header>
            <Transition visible={businessDetailsExpanded} animation={'slide down'} unmountOnHide duration={'150'}>
                <div>
                    <ContactBusinessData />
                </div>
            </Transition>
        </div>
    );
};

export default CompanyInfo;

