import React from 'react';
import { Button, Form, Header, Input, Popup } from 'semantic-ui-react';
import { DateInput, STATUS_TYPES, TimeInput, Notification } from 'dyl-components';
import { connect } from 'react-redux';

import UsersForm from 'shared/forms/UsersForm';
import { DateTimeUtils } from 'dyl-components';

import './ActivityForm.scss';

import selfReportActions from 'actions/self_report';

const TextActivityForm = ({
    onChangeDate,
    date,
    onChangeTime,
    time,

    onChange,
    assigned_user,
    direction,
    message,
    note,
    onCancel,
    onAdd,
    size,
    user_id,
    isLoggingText,
    isCalendarOpen,
    onOpenCalendar,
    onCloseCalendar,
    isTimeInputOpen,
    onOpenTimeInput,
    onCloseTimeInput
}) => (
    <Form size={size} className='ActivityForm'>
        <Header as='h4' textAlign='center'>
            Text Activity
        </Header>
        <Form.Group widths='equal'>
            <Form.Field
                label='Assigned User'
                size={size}
            />
            <Form.Field
                control={UsersForm}
                user={assigned_user}
                name='assigned_user'
                onChange={onChange}
                size={size}
                user_id={user_id}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Field
                label='Date'
                size={size}
                width={4}
            />
            <Popup
                trigger={<Form.Field
                    control={Input}
                    placeholder={`Set Date (${DateTimeUtils.DATE_FORMAT})`}
                    size={size}
                    onChange={onChangeDate}
                    value={date}
                    icon='calendar outline'
                />}
                content={(
                    <DateInput
                        onChange={onChangeDate}
                        minDate="01-01-1970"
                        value={date}
                        name='date'
                        inline
                    />
                )}
                on='click'
                open={isCalendarOpen}
                onOpen={onOpenCalendar}
                onClose={onCloseCalendar}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Field
                label='Time'
                size={size}
                width={4}
            />
            <Popup
                trigger={<Form.Field
                    control={Input}
                    placeholder={`Set Time (${DateTimeUtils.TIME_FORMAT})`}
                    size={size}
                    onChange={onChangeTime}
                    value={time}
                    icon='clock'
                />}
                content={(
                    <TimeInput
                        onChange={onChangeTime}
                        value={time}
                        name='time'
                        inline
                    />
                )}
                on='click'
                open={isTimeInputOpen}
                onOpen={onOpenTimeInput}
                onClose={onCloseTimeInput}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Field
                label='Text'
                size={size}
                width={4}
            />
            <Form.Select
                options={[
                    { key: 'outbound', value: 'outbound', text: 'Outbound' },
                    { key: 'inbound', value: 'inbound', text: 'Inbound' }
                ]}
                value={direction}
                onChange={onChange}
                name='direction'
                size={size}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Field
                label="Text Message"
                size={size}
                width={4}
            />
            <Form.TextArea
                value={message}
                onChange={onChange}
                name="message"
                placeholder="Add Message"
                inline
                size={size}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Field
                label="Note"
                size={size}
                width={4}
            />
            <Form.TextArea
                value={note}
                onChange={onChange}
                name="note"
                placeholder="Add Note"
                inline
                size={size}
            />
        </Form.Group>

        <div style={{ textAlign: 'right' }}>
            {!isLoggingText && <Button size={size} onClick={onCancel}>Cancel</Button>}
            <Button loading={isLoggingText} size={size} primary onClick={onAdd}>Add</Button>
        </div>
    </Form>
);

const initialState = {
    date: '',
    time: '',
    assigned_user: '',
    direction: 'outbound',
    message: '',
    note: '',
    isCalendarOpen: false,
    isTimeInputOpen: false,

    isOpen: false
}

class TextActivityFormContainer extends React.Component {
    state = {
        ...initialState,
        assigned_user: this.props.assigned_user
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onChangeDate = (_, { value }) => {
        this.setState({ date: value, isCalendarOpen: false });
    }

    onChangeTime = (_, { value }) => {
        this.setState({ time: value, isTimeInputOpen: false });
    }

    handleOpen = () => {
        this.setState({ isOpen: true });
    }

    onOpenCalendar = () => {
        this.setState({ isCalendarOpen: true });
    }

    onCloseCalendar = () => {
        this.setState({ isCalendarOpen: false });
    }

    onOpenTimeInput = () => {
        this.setState({ isTimeInputOpen: true });
    }

    onCloseTimeInput = () => {
        this.setState({ isTimeInputOpen: false });
    }

    handleClose = () => {
        const { isCalendarOpen, isTimeInputOpen } = this.state;
        if (isCalendarOpen || isTimeInputOpen) {
            return;
        }
        this.setState({ ...initialState, assigned_user: this.props.assigned_user });
    }

    onAdd = () => {
        const { direction, date, time, note: notes, assigned_user: user_id, message: messages } = this.state;
        const { from, to, contact_id } = this.props;
        const occurred = DateTimeUtils.getUnixTime(`${date} ${time}`, DateTimeUtils.DATETIME_FORMAT);
        const payload = {
            contact_id,
            direction,
            notes,
            user_id,
            messages,

            source: from,
            occurred,
            destination: to
        };

        this.props.logText(payload).then(() => {
            Notification.alert('Successfully logged text!', STATUS_TYPES.SUCCESS, true);
            this.handleClose();
            if (this.props.refresh) {
                this.props.refresh();
            }
        }).catch(() => {
            Notification.alert('Failed to log text', STATUS_TYPES.ERROR, true);
        });
    }


    render() {
        return (
            <Popup 
                trigger={this.props.trigger}
                content={(
                    <TextActivityForm 
                        assigned_user={this.state.assigned_user}
                        onChange={this.onChange}
                        onChangeDate={this.onChangeDate}
                        onChangeTime={this.onChangeTime}
                        direction={this.state.direction}
                        note={this.state.note}
                        date={this.state.date}
                        time={this.state.time}
                        message={this.state.message}
                        size='tiny'
                        onCancel={this.handleClose}
                        onAdd={this.onAdd}
                        user_id={this.props.assigned_user}
                        isLoggingText={this.props.isLoggingText}
                        isCalendarOpen={this.state.isCalendarOpen}
                        onCloseCalendar={this.onCloseCalendar}
                        onOpenCalendar={this.onOpenCalendar}
                        isTimeInputOpen={this.state.isTimeInputOpen}
                        onOpenTimeInput={this.onOpenTimeInput}
                        onCloseTimeInput={this.onCloseTimeInput}
                    />
                )}
                open={this.state.isOpen}
                on='click'
                onOpen={this.handleOpen}
                onClose={this.handleClose}
            />
        )
    }
}

const mapStateToProps = state => {
    const { first_name = '', last_name = '' } = state.users.userProfile;
    return ({
        isLoggingText: state.self_report.isLoggingText,
        from: `${first_name} ${last_name}`,
        assigned_user: state.auth.user_id
    });
};

const mapDispatchToProps = dispatch => ({
    logText: (payload) => {
        return dispatch(selfReportActions.addTextActivity(payload));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(TextActivityFormContainer);
