import routes from '../routes';

import {
    generateReadActionCreator,
    generateUpdateActionCreator,
    generateDeleteActionCreator, generateCreateActionCreator
} from "actions/crudActionCreators";
import ACTION_NAMES from "actions/ACTION_NAMES";

export default {
    readCustomers: generateReadActionCreator(routes.API_CUSTOMERS, ACTION_NAMES.CUSTOMERS),
    createCustomer: generateCreateActionCreator(routes.API_CUSTOMER, ACTION_NAMES.CUSTOMERS),
    updateCustomer: generateUpdateActionCreator(routes.API_CUSTOMERS, ACTION_NAMES.CUSTOMERS),
    deleteCustomer: generateDeleteActionCreator(routes.API_CUSTOMERS, ACTION_NAMES.CUSTOMERS)
};
