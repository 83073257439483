import { yup } from "dyl-components";
import bulkPricingTierSchema from "./bulkPricingTierSchema";

// yup.array().min() does not work here since it will not display errors for the other object validations
export const applyVolumeBasedBulkPricingTierSchema = (schema) => {
    return schema
        .of(yup.object(bulkPricingTierSchema))
        .test(
            "should_have_at_least_two",
            "There should be at least two ranges",
            function (tiers) {
                if (!tiers) {
                    return true;
                }
                if (tiers.length === 1) {
                    const isValid = yup.object(bulkPricingTierSchema).isValidSync(tiers[0]);
                    return !isValid;
                }
                return tiers.length >= 2;
            }
        )
        .test(
            "end_is_required_for_all_ranges_except_the_last",
            "This field is required",
            function (tiers) {
                if (!tiers) {
                    return true;
                }
                for (let i = 0; i < tiers.length - 1; i++) {
                    if (!tiers[i].end) {
                        return this.createError({
                            message: "This field is required",
                            path: `${this.path}[${i}].end`,
                        });
                    }
                }
                return true;
            }
        );
};

const volumeBasedSchema = {
    volume_prices: yup
        .array()
        .optional()
};

export default volumeBasedSchema;
