import { generateCreateActionCreator, generateReadActionCreator } from 'actions/crudActionCreators';
import routes from 'actions/routes';
import ACTION_NAMES from 'actions/ACTION_NAMES';

 
export default {
    pull: generateReadActionCreator(routes.API_EMAIL_PULL, ACTION_NAMES.EMAIL_PULL),
    send: generateCreateActionCreator(routes.API_EMAIL_SEND, ACTION_NAMES.EMAIL_SEND, () => {}, () => {}),
    getAll: generateReadActionCreator(routes.API_EMAIL_GET_ALL, ACTION_NAMES.EMAIL_GET_ALL),
    getAttachment: generateReadActionCreator(routes.API_EMAIL_GET_ATTACHMENT, ACTION_NAMES.EMAIL_GET_ATTACHMENT),
}
