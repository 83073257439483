import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function provisionReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PROVISION_ALL_DEVICES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingDevices: true, devices: [], devicesCount: 0 };
        case getNamedAction(ACTION_NAMES.PROVISION_ALL_DEVICES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingDevices: false, devices: action.data.data, devicesCount: action.data.count };
        case getNamedAction(ACTION_NAMES.PROVISION_ALL_DEVICES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingDevices: true, devices: [], devicesCount: 0 };

        case getNamedAction(ACTION_NAMES.PROVISION_LOCATIONS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingLocations: true };
        case getNamedAction(ACTION_NAMES.PROVISION_LOCATIONS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingLocations: false, locations: action.data.data, locationsCount: action.data.count };
        case getNamedAction(ACTION_NAMES.PROVISION_LOCATIONS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingLocations: false };

        case getNamedAction(ACTION_NAMES.PROVISION_LOCATIONS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingLocations: true };
        case getNamedAction(ACTION_NAMES.PROVISION_LOCATIONS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingLocations: false };
        case getNamedAction(ACTION_NAMES.PROVISION_LOCATIONS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingLocations: false };

        case getNamedAction(ACTION_NAMES.PROVISION_VALIDATE_LOCATION, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isValidatingLocation: true };
        case getNamedAction(ACTION_NAMES.PROVISION_VALIDATE_LOCATION, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isValidatingLocation: false, isValidLocation: action.data.valid, locationSuggestion: action.data.location };
        case getNamedAction(ACTION_NAMES.PROVISION_VALIDATE_LOCATION, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isValidatingLocation: false };

        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingSoftphone: true, softphone: null};
        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingSoftphone: false, softphone: action.data };
        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingSoftphone: false, softphone: null };

        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, softphoneBeingUpdated: action.subId };
        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, softphoneBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, softphoneBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingSoftphone: true };
        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingSoftphone: false };
        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingSoftphone: false };
            
        case getNamedAction(ACTION_NAMES.PROVISION_DEVICE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingHardphone: true, hardphone: null};
        case getNamedAction(ACTION_NAMES.PROVISION_DEVICE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingHardphone: false, hardphone: action.data };
        case getNamedAction(ACTION_NAMES.PROVISION_DEVICE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingHardphone: false, hardphone: null };
            
        case getNamedAction(ACTION_NAMES.PROVISION_DEVICE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingHardphone: true };
        case getNamedAction(ACTION_NAMES.PROVISION_DEVICE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingHardphone: false };
        case getNamedAction(ACTION_NAMES.PROVISION_DEVICE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingHardphone: false };
            
        case getNamedAction(ACTION_NAMES.PROVISION_DEVICE, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingHardphone: true };
        case getNamedAction(ACTION_NAMES.PROVISION_DEVICE, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingHardphone: false };
        case getNamedAction(ACTION_NAMES.PROVISION_DEVICE, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingHardphone: false };
            
        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE_CREDS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingSoftphoneCreds: true, softphoneCreds: null };
        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE_CREDS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingSoftphoneCreds: false, softphoneCreds: action.data };
        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE_CREDS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingSoftphoneCreds: false, softphoneCreds: null };

        case 'REMOVE_SOFTPHONE_CREDS':
            return { ...state, softphoneCreds: null };   

        default:
            return state;
    }
}


export default provisionReducer;
