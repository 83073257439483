import React from 'react';
import { Grid, Button } from 'semantic-ui-react';

const AddTemplateHeader = ({ onOpenAddTemplateSection }) => (
    <Grid verticalAlign='middle'>
        <Grid.Column width={6}>
            Script Management
        </Grid.Column>
        <Grid.Column width={4} textAlign='right' floated='right'>
        <Button onClick={onOpenAddTemplateSection} size='tiny' basic inverted color='white'>Add Script</Button>
        </Grid.Column>
    </Grid>
)

export default AddTemplateHeader;
