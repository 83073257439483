import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function contactAutocompleteReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CONTACT_AUTOCOMPLETE, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingAutocompleteResults: true };
        case getNamedAction(ACTION_NAMES.CONTACT_AUTOCOMPLETE, CRUD_ACTION_TYPES.READ_SUCCESS):
            const results = (action.data['email_addresses-suggest'] && action.data['email_addresses-suggest'][0]) || {
                options: [
                    ...action.data['names-suggest'][0].options,
                    ...action.data['account_title-suggest'][0].options
                ]
            }
            return { ...state, isReadingAutocompleteResults: false, results: results.options };
        case getNamedAction(ACTION_NAMES.CONTACT_AUTOCOMPLETE, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingAutocompleteResults: false };

        default:
            return state;
    }
}


export default contactAutocompleteReducer;
