import React from 'react';
import { Loader } from 'semantic-ui-react';
import { Modal, ChatMessage } from 'dyl-components';
import { useSelector } from 'react-redux';

const PreviewTextTemplateModal = ({ text_template, open, onClose }) => {

    const messages = [
        ...(text_template.attachments ? text_template.attachments.map(({ type, uri }) => ({ type, data: { uri } })) : []),
        {
            type: 'text',
            text: text_template.text
        }
    ];

    const isReading = useSelector(state => state.text_template.isReadingTemplate);

    return (
        <Modal open={open} onClose={onClose} size='mini'>
            <Modal.Content>
                {isReading && <Loader active />}
                {messages.map(({ position, type, text, data }) => (
                    <ChatMessage position={position} type={type} text={text} data={data} />
                ))}
            </Modal.Content>
        </Modal>
    )
}

export default PreviewTextTemplateModal;
