import React, { useEffect, useState } from 'react';
import { TableWithHeader, Table, EmptyListPlaceholder, TableLoader, STATUS_TYPES, Notification } from 'dyl-components';
import { Table as SemanticTable, Segment } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import TerritoriesToolbar from './subcomponents/Toolbar';
import TerritoryRow from './subcomponents/TerritoryRow';

import './index.scss';

import territoryActions from 'actions/territory';
import { Link } from 'react-router-dom';
import EditTerritoryModal from 'shared/modals/EditTerritoryModal';

const territoryLimit = 14;

const Territories = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(territoryActions.readTerritories());
    }, [dispatch]);

    const [territoryBeingEdited, setTerritoryBeingEdited] = useState(null);

    const onEdit = (id) => {
        setTerritoryBeingEdited(id);
    }

    const refresh = () => {
        dispatch(territoryActions.readTerritories())
    }

    const onDelete = async (id) => {
        try {
            await dispatch(territoryActions.deleteTerritory(id))
            Notification.alert('Successfully deleted territory!', STATUS_TYPES.SUCCESS);
            refresh();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to delete territory', STATUS_TYPES.ERROR);
        }
    }

    const territories = useSelector((state) => state.territory.territories || []);
    const { isReadingTerritories, isDeletingTerritory } = useSelector((state) => {
        const {isReadingTerritories, isDeletingTerritory} = state.territory;
        return {
            isReadingTerritories,
            isDeletingTerritory
        }
    });

    const canAddTerritories = territories?.length < 25;

    return (
        <div className='TerritoriesSettingsPage'>
            <TableWithHeader
                header={
                    <TerritoriesToolbar />
                }
                table={
                    <Segment.Group vertical loading={false}>
                        <Segment style={{ padding: 0 }}>
                            <Table>
                                <SemanticTable.Header className="Table__header--primary">
                                    <Table.Row>
                                        <SemanticTable.HeaderCell width={3}>Territory Name</SemanticTable.HeaderCell>
                                        <SemanticTable.HeaderCell width={11}>Teams</SemanticTable.HeaderCell>
                                        <SemanticTable.HeaderCell width={1}>Actions</SemanticTable.HeaderCell>
                                    </Table.Row>
                                </SemanticTable.Header>
                                <Table.Body>
                                    {isReadingTerritories || isDeletingTerritory ? <TableLoader isLoading colspan={3} /> : (
                                        territories.map(territory => {
                                            return (
                                                <TerritoryRow
                                                    name={territory.name}
                                                    territoryLimit={territoryLimit}
                                                    teams={territory.teams}
                                                    onEdit={() => {onEdit(territory.id)}}
                                                    onDelete={() => {onDelete(territory.id)}}
                                                />
                                            );
                                        })
                                    )}
                                </Table.Body>
                            </Table>
                            {territories.length === 0 && !isReadingTerritories && <EmptyListPlaceholder
                                name='Territory'
                                subtitle={(
                                    <span>
                                        Add a Territory by clicking on the <Link disabled={!canAddTerritories} to='/settings/territories/create'>+ New Territory </Link> button on the top-right corner.
                                    </span>
                                )}
                            />}
                        </Segment>
                    </Segment.Group>
                }
            />
            <EditTerritoryModal 
                id={territoryBeingEdited}
                open={territoryBeingEdited}
                refresh={refresh}
                onClose={() => {setTerritoryBeingEdited(null)}}
            />
        </div>
    );
}

export default Territories;
