import React from 'react';
import { ButtonLink, EllipsisDropdown, Table, Notification, STATUS_TYPES, ControlledPopup } from 'dyl-components';
import { DateTimeUtils } from 'dyl-components';
import { Dropdown, Header, Icon } from 'semantic-ui-react';
import importDataActions from 'actions/import_data';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

export const ImportTableRow = ({ importItem, onPreview = () => { }, limit = 25 }) => {
    const dispatch = useDispatch();

    const [params] = useSearchParams();

    const onDelete = async () => {
        try {
            await dispatch(importDataActions.deleteImport(importItem.id));
            Notification.alert('Successfully deleted import data!', STATUS_TYPES.SUCCESS, true);
            dispatch(importDataActions.readImports({
                ...Object.fromEntries(params),
                limit
            }));
        } catch (e) {
            Notification.alert('Failed to delete import data', STATUS_TYPES.ERROR, true);
        }
    }

    const ELLIPSIS_OPTIONS = [
        {
            text: 'Delete',
            icon: 'fas fa-trash-alt',
            displaysPopup: true,
            popupProps: {
                header: 'Are you sure?',
                onConfirm: onDelete
            }
        }
    ];

    const DropdownItem = ({ text, icon, displaysPopup, popupProps, ...otherProps }) => {
        const DropdownModel = () => {
            return (
                <Dropdown.Item text={<React.Fragment>
                    <Icon name={icon} />
                    {text}
                </React.Fragment>}
                    style={{ cursor: 'pointer' }}
                    {...otherProps}
                />
            );
        }

        if (displaysPopup === true) {
            return (
                <ControlledPopup
                    header={
                        <Header as='h4' textAlign='center'>
                            {popupProps.header}
                            <Header.Subheader style={{ marginTop: 5 }}>
                                {popupProps.subheader}
                            </Header.Subheader>
                        </Header>
                    }
                    trigger={
                        DropdownModel()
                    }
                    onConfirm={popupProps.onConfirm}
                />
            )
        } else {
            return (
                <DropdownModel />
            );
        }
    }

    return (
        <React.Fragment>
            <Table.Row>
                <Table.Cell>
                    <div><b>{DateTimeUtils.formatEpoch(importItem.created, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{DateTimeUtils.formatEpoch(importItem.created, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
                <Table.Cell>
                    <ButtonLink className='ImportItem__previewLink' onClick={() => { onPreview(importItem.file_id); }} role="button">
                        {importItem.file_name}
                    </ButtonLink>
                </Table.Cell>
                <Table.Cell>
                    {importItem.file_description}
                </Table.Cell>
                <Table.Cell>
                    {importItem.product_interests}
                </Table.Cell>
                <Table.Cell>
                    {importItem.module}
                </Table.Cell>
                <Table.Cell>
                    <ButtonLink>
                        {importItem.successful_records}
                    </ButtonLink>
                </Table.Cell>
                <Table.Cell>
                    <EllipsisDropdown>
                        {ELLIPSIS_OPTIONS.map(({ text, icon, displaysPopup, popupProps, ...otherProps }, idx) => {
                            return (
                                <DropdownItem
                                    key={idx}
                                    text={text}
                                    icon={icon}
                                    displaysPopup={displaysPopup}
                                    popupProps={popupProps}
                                    {...otherProps}
                                />
                            );
                        })}
                    </EllipsisDropdown>
                </Table.Cell>
            </Table.Row>
        </React.Fragment>
    )
}
