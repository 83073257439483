import ACTION_NAMES from 'actions/ACTION_NAMES';
import { generateReadActionCreator, generateUpdateActionCreator, generateDeleteActionCreator, generateCreateActionCreator } from 'actions/crudActionCreators'; 
import routes from 'actions/routes';

const onReadNotificationCenter = generateReadActionCreator(routes.API_NOTIFICATION_CENTER, ACTION_NAMES.NOTIFICATION_CENTER);
const onReadNotificationHub = generateReadActionCreator(routes.API_NOTIFICATION_HUB, ACTION_NAMES.NOTIFICATION_HUB);
const onArchiveNotification = generateUpdateActionCreator(routes.API_NOTIFICATION_ARCHIVE, ACTION_NAMES.NOTIFICATION_ARCHIVE);
const markReadNotification = generateUpdateActionCreator(routes.API_NOTIFICATION_MARK_READ, ACTION_NAMES.NOTIFICATION_MARK_READ);
const snoozeNotification = generateUpdateActionCreator(routes.API_NOTIFICATION_SNOOZE, ACTION_NAMES.NOTIFICATION_SNOOZE);
const onClearNotifications = generateDeleteActionCreator(routes.API_NOTIFICATION_CLEAR, ACTION_NAMES.NOTIFICATION_CLEAR);
const onDeleteNotification = generateDeleteActionCreator(routes.API_NOTIFICATION_DELETE, ACTION_NAMES.NOTIFICATION_DELETE);
const getNotificationSettings = generateReadActionCreator(routes.API_NOTIFICATION_SETTINGS, ACTION_NAMES.NOTIFICATION_SETTINGS);
const updateNotificationSettings = generateUpdateActionCreator(routes.API_UPDATE_NOTIFICATION_SETTINGS, ACTION_NAMES.UPDATE_NOTIFICATION_SETTINGS,()=>{},()=>{},undefined,true);
const bulkActionNotifications = generateCreateActionCreator(routes.API_NOTIFICATION_BULK_ACTION, ACTION_NAMES.NOTIFICATION_BULK_ACTION);

const hasReadNotifications = () => {
    return {
        type: 'HAS_READ_NOTIFICATIONS',
    }
}

const hasUnReadNotifications = () => {
    return {
        type: 'HAS_UNREAD_NOTIFICATIONS',
    }
}

export default {
    onReadNotificationCenter,
    onReadNotificationHub,
    hasReadNotifications,
    hasUnReadNotifications,
    onArchiveNotification,
    markReadNotification,
    snoozeNotification,
    onClearNotifications,
    onDeleteNotification,
    getNotificationSettings,
    updateNotificationSettings,
    bulkActionNotifications
}
