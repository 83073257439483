import React from 'react';
import { STATUS_TYPES, Notification, DateTimeUtils } from 'dyl-components';
import { connect } from 'react-redux';

import ScheduleTable from './ScheduleTable';
import scheduleActions from 'actions/schedule';
import userActions from 'actions/user';
import companyActions from 'actions/company';

import { StringUtils,  ObjectUtils } from 'utils';
import { Header, Select } from 'semantic-ui-react';

class ScheduleArea extends React.Component {
    state = {
        schedule: [],
        isSaving: false,
        saved: false,
        timezone: DateTimeUtils.DEFAULT_TIMEZONE_OPTION
    }

    componentDidMount() {
        this.props.onReadCompany(this.props.user_id)
            .then(() => {
                if (this.props.company.timezone) this.setState({ timezone: this.props.company.timezone });
            });
        this.props.onReadSchedule()
            .then(() => {
                const schedule = this.props.schedule.slice(0);
                if (schedule.length === 0) {
                    for (let i = 1; true; i++) {
                        if (i === 7) { i = -1; continue; }
                        schedule.push({
                            open: i > 0 && i < 6,
                            day: StringUtils.capitalize(DateTimeUtils.DAYS[i]),
                            start: "08:00 AM",
                            end: "05:00 PM"
                        })
                        if (i === 0) break;
                    }
                }
                this.setState({ schedule });
            });
    }

    onChange = (day, { name, value }) => {
        let schedule = this.state.schedule.slice(0);
        let indexOfDayScheduleToChange = schedule.findIndex(daySchedule => daySchedule.day === day);
        if (indexOfDayScheduleToChange !== -1) {
            schedule[indexOfDayScheduleToChange] = { ...schedule[indexOfDayScheduleToChange], [name]: value };
            this.setState({ schedule, saved: false });
        }
    }

    onChangeTimezone = (_, { value: timezone }) => {
        this.setState({ timezone, saved: false });
    }

    onUpdate = () => {
        this.setState({ isSaving: true }, async () => {
            const updateResults = await Promise.all([
                new Promise(async (resolve, reject) => {
                    try {
                        await this.props.onUpdateCompany(this.props.user_id, {
                            "ein": this.props.company.ein,
                            "email": this.props.company.email,
                            "fiscal_year_start": this.props.company.fiscal_year_start,
                            "industry": this.props.company.industry.id,
                            "large_logo": this.props.company.large_logo,
                            "name": this.props.company.name,
                            "small_logo": this.props.company.small_logo,
                            "timezone": this.state.timezone,
                            "trial": this.props.company.trial,
                            "website": this.props.company.website
                        });
                        if (!this.props.user_timezone) {
                            DateTimeUtils.setTimezone(this.state.timezone);
                        }
                        await this.props.onReadCompany(this.props.user_id);
                        this.setState({ timezone: this.props.company.timezone }, resolve(true));
                    } catch (e) {
                        console.log(e);
                        resolve(false);
                    }
                }),
                new Promise(async (resolve, reject) => {
                    try {
                        await this.props.onUpdateSchedule([...this.state.schedule]);
                        await this.props.onReadSchedule();
                        this.setState({ schedule: this.props.schedule }, resolve(true));
                    } catch (e) {
                        console.log(e);
                        resolve(false);
                    }
                })
            ]);

            if (updateResults[0] && updateResults[1]) {
                this.setState({ schedule: this.props.schedule, isSaving: false, saved: true }, () => {
                    Notification.alert('Successfully updated schedule!', STATUS_TYPES.SUCCESS, true);
                });
            } else {
                this.setState({ isSaving: false }, () => {
                    Notification.alert(`Failed to update: ${!updateResults[0] ? 'Timezone' : ''} ${!updateResults[0] && !updateResults[1] ? ' and ' : ''} ${!updateResults[1] ? 'Schedule' : ''}`, STATUS_TYPES.ERROR, true);
                });
            }
        })
    }

    hasChanges = () => {
        return !ObjectUtils.deepEqual(this.props.schedule, this.state.schedule) || this.props.company.timezone !== this.state.timezone;
    }

    isValid = () => {
        return this.state.schedule.filter(day => day.open).every(day => DateTimeUtils.dateIsBeforeAnotherDate(day.start, day.end));
    }

    render() {
        return (
            <React.Fragment>
                <Header as='h3' color='blue'>Time Zone</Header>
                <Select
                    disabled={this.props.isReading}
                    loading={this.props.isReading}
                    options={DateTimeUtils.generateTimezoneOptions()}
                    name="timezone"
                    onChange={this.onChangeTimezone}
                    value={this.state.timezone}
                />
                <Header as='h3' color='blue'>Business Hours</Header>
                <ScheduleTable
                    schedule={this.state.schedule}
                    onChange={this.onChange}
                    hasChanges={this.hasChanges()}
                    onUpdate={this.onUpdate}
                    isValid={this.isValid()}
                    isUpdating={this.state.isSaving}
                    saved={this.state.saved}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    let schedules = [];
    for (let x = 1; state.schedule.days && state.schedule.days.length !== 0; x++) {
        if (x === 7) { x = -1; continue; }
        const day = state.schedule.days[x];
        schedules.push({
            ...day,
            open: day.active,
            day: StringUtils.capitalize(DateTimeUtils.DAYS[day.day]),
            start: DateTimeUtils.convertSecondsToTime(day.seconds_start),
            end: DateTimeUtils.convertSecondsToTime(day.seconds_end)
        });
        if (x === 0) break;
    }

    return {
        user_id: state.auth.user_id,
        company: state.company.company,
        schedule: schedules,
        isReading: state.company.isReadingCompany || state.schedule.isReadingSchedule,
        user_timezone: state.auth.timezone
    }
}


const mapDispatchToProps = dispatch => ({
    onReadCompany: () => {
        return dispatch(companyActions.readCompany())
    },
    onReadSchedule: () => {
        return dispatch(scheduleActions.readSchedule());
    },
    onUpdateSchedule: (updatedSchedule) => {
        return dispatch(scheduleActions.updateSchedule(null,
            updatedSchedule.map((day, index) => ({
                day: index !== 6 ? ++index : 0,
                seconds_start: DateTimeUtils.convertTimeToSeconds(day.start, DateTimeUtils.TIME_FORMAT),
                seconds_end: DateTimeUtils.convertTimeToSeconds(day.end, DateTimeUtils.TIME_FORMAT),
                active: day.open
            }))
        ));
    },
    onUpdateCompany: (user_id, updatedCompany) => {
        return dispatch(userActions.updateCompany(user_id, updatedCompany));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleArea);

