import { Form, Grid, Portal } from "semantic-ui-react";
import ProductInfo from "./ProductInfo";
import ProductCategoryDetails from "./ProductCategoryDetails";
import ProductDetails from "./ProductDetails";
import PricingDetails from "./PricingDetails";
import Variants from "./Variants";
import AddOns from "./Addons";
import Fees from "./Fees";
import Taxes from "./Taxes";
import ProductDiscount from "./ProductDiscount";
import { Button, SettingsFooter } from "dyl-components";
import { MathUtils } from "utils";
import useWidthListener from "shared/SettingsFooter/useWidthListener";
import useWindowWidth from "shared/SettingsFooter/useWindowWidth";
import { useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useConfirm } from "shared/confirmation/useConfirm";
import CustomPrompt from "shared/confirmation/CustomPrompt";
import ConfirmModal from "shared/confirmation/ConfirmModal";
import { useEffect, useState } from "react";
import productAddonsActions from "actions/product_addons";
import feesActions from 'actions/product_fees';

const ProductForm = ({ onSave, loading, id }) => {
    const [isNameDuplicate, setIsNameDuplicate] = useState(false);

    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();

    const { handleSubmit, formState: { isValid, isDirty } } = useFormContext();
    const isCheckingForDuplicates = useSelector(state => state.products.isCheckingForDuplicates);

    const { isConfirmed } = useConfirm();

    CustomPrompt(null, isDirty, isConfirmed, 'Changes not saved', 'Are you sure you want to exit?');

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(productAddonsActions.readAddons({ limit: 500 }));
        dispatch(feesActions.readFee({ limit: 500, type: 'fee', active: false }));
        dispatch(feesActions.readFee({ limit: 500, type: 'tax', active: false }));
    }, [dispatch]);

    return (
        <Form style={{ paddingBottom: "5em" }} loading={loading} noValidate>
            <ConfirmModal />
            <Grid columns={2}>
                <Grid.Column>
                    <ProductInfo id={id} setIsNameDuplicate={setIsNameDuplicate} />
                    <ProductCategoryDetails />
                    <ProductDetails />
                </Grid.Column>
                <Grid.Column>
                    <PricingDetails />
                    <Variants />
                    <AddOns />
                    <Fees />
                    <Taxes />
                    <ProductDiscount />
                </Grid.Column>
            </Grid>
            <Portal open>
                <SettingsFooter
                    style={{ width: MathUtils.calculatePercentage(windowWidth, windowWidth - width) }}
                    className={`Webform__menu`}
                    rightOptions={[
                        <Button disabled={loading} as={Link} to='/settings/product-catalog-quotes-invoices/product-catalog' status={'cancel'} basic>Cancel</Button>,
                        <Button
                            disabled={(!isValid || !isDirty || loading || isCheckingForDuplicates || isNameDuplicate)}
                            onClick={handleSubmit(onSave)}
                            loading={loading}
                        >
                            Save
                        </Button>
                    ]}
                />
            </Portal>
        </Form>
    )
}

export default ProductForm;
