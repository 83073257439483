import React, { useState } from 'react';
import { Grid, Button, Segment, Header, Icon, Form, Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
    ClippedContent,
    DateTimeUtils,
    generateResolver,
    yup,
    VALIDATORS,
    Notification,
    STATUS_TYPES,
    DividingHeader,
    ButtonLink,
} from 'dyl-components';

import CustomData from '../CustomData';
import { Link, useParams } from 'react-router-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import RecordEmailsForm from 'shared/forms/RecordEmailsForm';
import RecordPhonesForm from 'shared/forms/RecordPhonesForm';
import contactPhoneActions from 'actions/contact_phone';
import contactEmailActions from 'actions/contact_email';

import './index.scss';
import { STATES } from 'shared/constants/STATES';
import RecordLocations from './RecordLocations';
import { CustomGroupUtils, ObjectUtils, PhoneUtil, RecordUtils, StringUtils, ValidationUtils } from 'utils';
import contactLocationsActions from 'actions/contact_location';
import contactDuplicatesActions from 'actions/contact_duplicates';
import LocationUtils from 'utils/LocationUtils';
import BusinessStandardRegistrationData from 'pages/Account/BusinessStandardRegistrationData';
import BusinessStandardFinancialData from 'pages/Account/BusinessStandardFinancialData';
import HouseholdStandardData from 'pages/Account/HouseholdStandardData';
import accountActions from 'actions/account';
import BusinessStandardData from 'pages/Account/BusinessStandardData';
import EmailUtil from 'utils/EmailUtil';

function copy(text) {
    return (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(text);
    };
}


const AccountDataTab = ({
    account_type,
    website,
    phones,
    emails,
    locations,
    fields,

    isReading,

    onSave,
    onReadAccount,

    checkDuplicates,
    isCheckingDuplicates,

    custom_data,
    standard_groups,
    isReadingAccount,
    isUpdating,

    onUpdateCustomData,
    onInlineUpdateCustomData,
    created,
    last_modified
}) => {
    const { account_id } = useParams();

    const [saving, setSaving] = useState(false);

    function getDefaultValues() {
        return {
            ...(account_type === 'business' ? {
                business_nickname: fields?.business_nickname,
                legal_business_name: fields?.legal_business_name,
                dba_business_name: fields?.dba_business_name,
                state_of_organization: fields?.state_of_organization,
                business_in_other_states: fields?.business_in_other_states,
                product_services_sold: fields?.product_services_sold,
                date_founded: fields?.date_founded,
                business_structure: fields?.business_structure,

                state_employer_account_number: fields?.state_employer_account_number,
                federal_employer_identification_number: fields?.federal_employer_identification_number,
                sic: fields?.sic,
                primary_sic: fields?.primary_sic,
                naics_code: fields?.naics_code,
                stock_ticker: fields?.stock_ticker,
                duns: fields?.duns,
                employee_size: fields?.employee_size,
                professional_corporation: fields?.professional_corporation,

                accounting_fiscal_year: fields?.accounting_fiscal_year,
                business_method: fields?.business_method,
                sale_volume: fields?.sale_volume,
                annual_revenue: fields?.annual_revenue
            } : {}),
            ...(account_type === 'household' ? {
                account_household_type: fields?.account_household_type,
                members: fields?.members,
                number_of_children: fields?.children,
                monthly_income: fields?.monthly_income,
                annual_income: fields?.annual_income
            } : {}),
            phones: phones.map(phone => ({
                value: phone.phone,
                main: phone.main,
                id: phone.id,
                type: phone.phone_type
            })),
            emails: emails.map(email => ({
                value: email.email,
                main: email.main,
                id: email.id,
                type: email.email_type
            })),
            locations: locations.map(location => ({
                label: location.label,
                street: location.street,
                apartmentUnitOrFloor: location.additional_street,
                state: location.state,
                city: location.city,
                zip: location.zip,
                main: location.main,
                id: location.id
            })),
            website: website || '',

            children: custom_data
        };
    }

    const { formState: { isDirty, isValid, errors }, control, watch, handleSubmit, reset, setError, clearErrors, getValues } = useForm({
        mode: 'onChange',
        defaultValues: getDefaultValues(),
        resolver: generateResolver({
            phones: yup.array().of(yup.object().shape({
                value: VALIDATORS.PHONE_NUMBER()
            })).test('no_repeating_phone', "Phone already in the list", ValidationUtils.checkForDuplicatePhonesInTheList),
            emails: yup.array().of(yup.object().shape({
                value: VALIDATORS.EMAIL_ADDRESS().maxlength(256)
            })).test('no_repeating_email', "Email already in the list", ValidationUtils.checkForDuplicateEmailsInTheList),
            website: VALIDATORS.WEBSITE().maxlength(256),
            socials: yup.array().of(yup.object().shape({
                type: yup.string().required('Social type is required'),
                value: VALIDATORS.WEBSITE()
            })),
            locations: yup.array().of(yup.object().shape({
                STATE: yup.string().oneOf(STATES.map(({ key }) => key)),
                street: yup.string().maxlength(100),
                city: yup.string().maxlength(60),
                apartmentUnitOrFloor: yup.string().maxlength(12),
                zip: VALIDATORS.US_POSTAL_CODE(),
            })),
            children: CustomGroupUtils.generateValidationSchema(custom_data)
        })
    });

    const cancelEdit = () => {
        onCancelEdit();
        reset(getDefaultValues())
    }

    const { fields: phonesToEdit, append: addPhone, remove: removePhone, update: updatePhone } = useFieldArray({
        control,
        name: 'phones'
    });

    const { fields: emailsToEdit, append: addEmail, remove: removeEmail, update: updateEmail } = useFieldArray({
        control,
        name: 'emails'
    });

    const { fields: locationsToEdit, append: addLocation, remove: removeLocation, update: updateLocation } = useFieldArray({
        control,
        name: 'locations'
    })

    const [watchedEmails, watchedPhones, watchedLocations] = watch(['emails', 'phones', 'locations']);

    const save = async (data) => {
        try {
            setSaving(true);
            const custom_data = {
                children: CustomGroupUtils.groupAndFlatten({ children: data.children }).children,
                fields: account_type === 'business' ? CustomGroupUtils.extractBusinessDetails(data) : CustomGroupUtils.extractHouseholdDetails(data)
            }
            await onSave({
                account_id,
                phones,
                phoneValues: data.phones,
                emails,
                emailValues: data.emails,
                locations,
                locationValues: data.locations,
                website: data.website,

                custom_data
            });
            setSaving(false);
            Notification.alert('Successfully updated account!', STATUS_TYPES.SUCCESS);
            await onReadAccount(account_id);
            cancelEdit();
        } catch (e) {
            console.log(e);
            setSaving(false);
            Notification.alert('Failed to update account', STATUS_TYPES.ERROR);
        }
    }

    const hasErrors = Object.keys(errors).length > 0;

    const [isEditing, setIsEditing] = useState(false);

    const onEdit = () => {
        setIsEditing(true);
    }

    const onCancelEdit = () => {
        setIsEditing(false);
    }

    const onConfirmInlineEdit = async () => {
        try {
            setSaving(true);
            const data = getValues();
            await onUpdateCustomData(account_id,
                {
                    children: CustomGroupUtils.groupAndFlatten({ children: data.children }).children,
                    fields: account_type === 'business' ? CustomGroupUtils.extractBusinessDetails(data) : CustomGroupUtils.extractHouseholdDetails(data)
                });
            onInlineUpdateCustomData([
                ...standard_groups,
                ...data.children
            ]);
            setSaving(false);
            Notification.alert('Successfully updated account!', STATUS_TYPES.SUCCESS);
            return;
        } catch (e) {
            console.log(e);
            setSaving(false);
            Notification.alert('Failed to update account', STATUS_TYPES.ERROR);
        }
    }

    return (
        <Form loading={isReading || saving} size='mini'>
            <Grid stackable>
                <Grid.Row centered>
                    <Grid.Column textAlign='center'>
                        {!isEditing ? (
                            <Button disabled={isReading} basic color='blue' onClick={onEdit}>
                                Edit
                            </Button>
                        ) : (
                            [
                                !saving && <Button basic color='blue' onClick={cancelEdit}>Cancel</Button>,
                                <Button onClick={handleSubmit(save)} disabled={!isDirty || !isValid || hasErrors || isCheckingDuplicates} loading={saving} primary>Save</Button>
                            ]
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                    <Grid.Column>
                        <Grid
                            stackable
                            as={Segment}
                            size='tiny'
                            columns='equal'
                        >
                            <Grid.Column>
                                <Header color='primary'>Contact Info</Header>
                                <Header size='small' color='primary'>
                                    <span>
                                        {isEditing && (
                                            <Icon link onClick={() => { PhoneUtil.onAddPhone({ value: '', type: 'Cell', new: true }, phonesToEdit, addPhone) }} className='fas fa-plus-circle' color='primary' />
                                        )}Phones
                                    </span>
                                </Header>
                                {!isEditing ? (
                                    <Grid className='RecordInfo' columns='equal'>
                                        {phones.map((phone) => (
                                            <Grid.Row columns='equal'>
                                                <Grid.Column>
                                                    <b>{phone.phone_type}</b>
                                                </Grid.Column>
                                                <Grid.Column width={10}>
                                                    <ClippedContent>
                                                        {PhoneUtil.formatPhoneNumber(phone.phone)}
                                                    </ClippedContent>
                                                </Grid.Column>
                                                <Grid.Column width={1}>
                                                    {phone.main && <Icon className='fas fa-star' color='primary' />}
                                                </Grid.Column>
                                            </Grid.Row>
                                        ))}

                                    </Grid>
                                ) : (
                                    <RecordPhonesForm
                                        control={control}
                                        phones={phonesToEdit}
                                        onRemove={(index) => {
                                            RecordUtils.removeItem(watchedPhones, index, updatePhone, removePhone);
                                        }}
                                        updateMain={(onChange) => {
                                            RecordUtils.updateMain(watchedPhones, updatePhone, onChange);
                                        }}
                                        checkDuplicates={checkDuplicates}
                                        clearErrors={clearErrors}
                                        account_id={account_id}
                                        setError={setError}
                                    />
                                )}
                                <Header size='small' color='primary'>
                                    <span>
                                        {isEditing && (
                                            <Icon link onClick={() => { EmailUtil.onAddEmail({ value: '', new: true, type: 'Work' }, emailsToEdit, addEmail) }} className='fas fa-plus-circle' color='primary' />
                                        )}Emails
                                    </span>
                                </Header>
                                {!isEditing ? (
                                    <Grid className='RecordInfo' columns='equal'>
                                        {emails.map((email) =>
                                            <Grid.Row columns='equal'>
                                                <Grid.Column>
                                                    <b>{email.email_type || 'No Type'}</b>
                                                </Grid.Column>
                                                <Grid.Column width={10}>
                                                    <ClippedContent>
                                                        {email.email}
                                                    </ClippedContent>
                                                </Grid.Column>
                                                <Grid.Column width={1}>
                                                    {email.main && <Icon className='fas fa-star' color='primary' />}
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                    </Grid>
                                ) : (
                                    <RecordEmailsForm
                                        control={control}
                                        emails={emailsToEdit}
                                        onRemove={(index) => {
                                            RecordUtils.removeItem(watchedEmails, index, updateEmail, removeEmail);
                                        }}
                                        updateMain={(onChange) => {
                                            RecordUtils.updateMain(watchedEmails, updateEmail, onChange);
                                        }}
                                        checkDuplicates={checkDuplicates}
                                        clearErrors={clearErrors}
                                        account_id={account_id}
                                        setError={setError}
                                    />
                                )}

                                <Header size='small' color='primary'>
                                    <span>
                                        {isEditing && (
                                            <Icon link onClick={() => { addLocation({ value: '', main: false, new: true, label: 'Business' }) }} className='fas fa-plus-circle' color='primary' />
                                        )}Locations
                                    </span>
                                </Header>
                                <RecordLocations
                                    control={control}
                                    locations={!isEditing ? locations : locationsToEdit}
                                    isEditing={isEditing}
                                    addressLabelOptions={LocationUtils.getAddressLabelOptions()}
                                    onRemove={(index) => {
                                        RecordUtils.removeItem(watchedLocations, index, updateLocation, removeLocation);
                                    }}
                                    updateMain={(onChange) => {
                                        RecordUtils.updateMain(watchedLocations, updateLocation, onChange);
                                    }}
                                />

                                <Header size='small' color='primary'>
                                    <span>
                                        Website
                                    </span>
                                </Header>

                                <Grid className='RecordInfo' columns='equal'>
                                    <Grid.Row columns='equal'>
                                        <Grid.Column width={10}>
                                            {!isEditing ? website ? <a href={StringUtils.formatLink(website)} target="_blank" rel="noreferrer">{website}</a> : "No Website" : (
                                                <Controller
                                                    control={control}
                                                    name="website"
                                                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                                        <Form.Input
                                                            name={name}
                                                            value={value}
                                                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                            error={error?.message}
                                                            placeholder="Enter website"
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                                <Grid className='RecordInfo'>
                                    <Grid.Row columns='equal'>
                                        <Grid.Column>Account ID</Grid.Column>
                                        <Grid.Column>
                                            {account_id ? (
                                                <Popup
                                                    trigger={<ButtonLink role="button" tabIndex="0" onClick={copy(account_id)}>{account_id} <Icon className='fas fa-copy' /></ButtonLink>}
                                                    content='Copied!'
                                                    on='click'
                                                    closeOnTriggerMouseLeave
                                                    position='bottom center'
                                                    inverted
                                                />
                                            ) : 'None'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns='equal'>
                                        <Grid.Column>Record Source</Grid.Column>
                                        <Grid.Column></Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns='equal'>
                                        <Grid.Column>Created On</Grid.Column>
                                        <Grid.Column>{created.date}</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns='equal'>
                                        <Grid.Column>Created By</Grid.Column>
                                        <Grid.Column>{created.user && <ClippedContent><Link target={"_blank"} to={`/settings/users/${created.id}/general`}>{created.user}</Link></ClippedContent>}</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns='equal'>
                                        <Grid.Column>Last Modified On</Grid.Column>
                                        <Grid.Column>{last_modified.date}</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns='equal'>
                                        <Grid.Column>Last Modified By</Grid.Column>
                                        <Grid.Column>{last_modified.user && <ClippedContent><Link target={"_blank"} to={`/settings/users/${last_modified.id}/general`}>{last_modified.user}</Link></ClippedContent>}</Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                {account_type === 'business' && (
                                    <>
                                        <Header color='primary'>Business Details</Header>
                                        <BusinessStandardData isEditing={isEditing} control={control} />
                                    </>
                                )}
                            </Grid.Column>
                            <Grid.Column style={{ paddingTop: '2em', paddingBottom: '2em' }}>
                                {account_type === 'business' && (
                                    <>
                                        <Header color='primary'>Business Registration</Header>
                                        <BusinessStandardRegistrationData isEditing={isEditing} control={control} />
                                        <Header color='primary'>Financial Info</Header>
                                        <BusinessStandardFinancialData isEditing={isEditing} control={control} />
                                    </>
                                )}
                                {account_type === 'household' && (
                                    <>
                                        <Header color='primary'>Household Details</Header>
                                        <HouseholdStandardData isEditing={isEditing} control={control} />
                                    </>
                                )}
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid as={Segment} loading={isReadingAccount || isUpdating} size='mini' columns='equal' className={`CustomDataBox CustomDataBox--${isEditing ? 'isEditing' : ''}`}>
                            <Grid.Row>
                                <Grid.Column>
                                    <DividingHeader compact content='Custom Data' backgroundColor='#f4f6ff' />
                                    <div className='CustomDataBox__form'>
                                        <CustomData
                                            control={control}
                                            isEditing={isEditing}
                                            onConfirmInlineEdit={onConfirmInlineEdit}
                                        />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

const mapStateToProps = state => {
    const { created_by, created, last_modified_by, activity } = state.account.account || {};
    const { phone: phones } = state.contact_phone;

    const custom_data = CustomGroupUtils.groupAndFlatten(state.account.account?.custom_data || {});

    return {
        created: {
            ...created_by,
            date: DateTimeUtils.formatEpoch(created, DateTimeUtils.DATE_FORMAT)
        },
        last_modified: {
            ...last_modified_by,
            date: DateTimeUtils.formatEpoch(activity, DateTimeUtils.DATE_FORMAT)
        },

        website: state.account.account?.website,
        locations: state.account.account?.locations,
        phones,
        emails: state.contact_email.email,
        fields: custom_data.fields,

        custom_data: state.account.account?.custom_data?.children?.filter(child => !child.standard),
        standard_groups: state.account.account?.custom_data?.children?.filter(child => child.standard),

        isReading: state.account.isReadingAccount,
        isUpdating: state.account.accountBeingUpdated,
        isCheckingDuplicates: state.contact_duplicates.isCheckingDuplicates,
        account_type: state.account.account?.account_type
    }
};

const mapDispatchToProps = (dispatch) => ({
    onReadAccount: (account_id) => {
        return Promise.all([
            dispatch(accountActions.readAccount(account_id)),
            dispatch(contactPhoneActions.getContactPhones(account_id)),
            dispatch(contactEmailActions.getContactEmails(account_id)),
        ])
    },

    checkDuplicates: (queryParameters) => {
        return dispatch(contactDuplicatesActions.checkDuplicates(queryParameters));
    },

    onUpdateCustomData: (account_id, custom_data) => {
        return dispatch(accountActions.updateAccount(account_id, { custom_data }));
    },

    onInlineUpdateCustomData: groups => {
        return dispatch(accountActions.inlineUpdateCustomData(groups));
    },

    onSave: async ({ account_id, phones, phoneValues, emails, emailValues, locations, locationValues, website, custom_data }) => {
        const phonesToDelete = phones.filter(phone => phoneValues.findIndex(phoneValue => phoneValue.id === phone.id) === -1);
        const phonesToAdd = phoneValues.filter(phone => phone.new);
        const phonesToUpdate = phoneValues.filter(phoneValue => !phoneValue.new && phones.findIndex(phone => (
            phone.id === phoneValue.id &&
            phone.main === phoneValue.main &&
            phone.phone === phoneValue.value &&
            phone.phone_type === phoneValue.type
        )) === -1);

        const emailsToDelete = emails.filter(email => emailValues.findIndex(emailValue => emailValue.id === email.id) === -1);
        const emailsToAdd = emailValues.filter(email => email.new);
        const emailsToUpdate = emailValues.filter(emailValue => !emailValue.new && emails.findIndex(email => (
            email.id === emailValue.id &&
            email.main === emailValue.main &&
            email.email === emailValue.value &&
            email.email_type === emailValue.type
        )) === - 1);

        const locationsToDelete = locations.filter(location => locationValues.findIndex(locationValue => locationValue.id === location.id) === -1);
        const locationsToAdd = locationValues.filter(location => location.new);
        const locationsToUpdate = locationValues.filter(({ new: isNew, main: isLocationValueMain, ...locationValue }) =>
            !isNew &&
            locations.findIndex(({ main: isMainLocation, ...location }) => ObjectUtils.deepEqual(location, locationValue)) === -1
        );

        const idsOfNewLocations = await processItems(locationsToAdd, () => {
            return dispatch(contactLocationsActions.addContactLocation(locationsToAdd.map(location => ({
                label: location.label,
                street: location.street,
                additional_street: location.apartmentUnitOrFloor,
                city: location.city,
                state: location.state,
                zip: location.zip,
            })), null, account_id));
        });

        await processItems(locationsToUpdate, () => {
            return Promise.all(locationsToUpdate.map(location => dispatch(contactLocationsActions.updateContactLocation(account_id, {
                label: location.label,
                street: location.street,
                additional_street: location.apartmentUnitOrFloor,
                city: location.city,
                state: location.state,
                zip: location.zip
            }, null, location.id))));
        });

        const primary_location_id = (() => {
            const primaryLocation = locationValues.find(location => location.main);
            if (primaryLocation === undefined) {
                return null;
            }
            if (primaryLocation.new) {
                const indexOfAddedPrimaryLocation = locationsToAdd.findIndex(location => location.main);
                return idsOfNewLocations[indexOfAddedPrimaryLocation];
            }
            return primaryLocation.id;
        })();
        if (primary_location_id) {
            const primaryLocation = locationValues.find((location) => {
                return location.main;
            });
            await dispatch(contactLocationsActions.updateContactLocation(account_id, {
                label: primaryLocation.label,
                street: primaryLocation.street,
                additional_street: primaryLocation.apartmentUnitOrFloor,
                city: primaryLocation.city,
                state: primaryLocation.state,
                main: primaryLocation.main,
                zip: primaryLocation.zip
            }, null, primaryLocation.id))
        }
        await dispatch(accountActions.updateAccount(account_id,
            {
                custom_data,
                website
            }));

        await processItems(locationsToDelete, () => {
            return Promise.all(locationsToDelete.map(location => dispatch(contactLocationsActions.deleteContactLocation(account_id, null, location.id))))
        });

        await Promise.all([
            processItems(phonesToUpdate, () => {
                return Promise.all(phonesToUpdate.map(phone => dispatch(contactPhoneActions.updateContactPhone(account_id, {
                    main: phone.main,
                    phone: phone.value,
                    phone_type: phone.type
                }, null, phone.id))));
            }),
            processItems(phonesToDelete, () => {
                return Promise.all(phonesToDelete.map(phone => dispatch(contactPhoneActions.deleteContactPhone(account_id, null, phone.id))));
            }),
            processItems(emailsToUpdate, () => {
                return Promise.all(emailsToUpdate.map(email => dispatch(contactEmailActions.updateContactEmail(account_id, {
                    main: email.main,
                    email: email.value,
                    email_type: email.type
                }, null, email.id))));
            }),
            processItems(emailsToDelete, () => {
                return Promise.all(emailsToDelete.map(email => dispatch(contactEmailActions.deleteContactEmail(account_id, null, email.id))));
            })
        ])

        return await Promise.all([
            processItems(phonesToAdd, () => {
                return dispatch(contactPhoneActions.addContactPhone(phonesToAdd.map(phone => ({
                    main: phone.main,
                    phone: phone.value,
                    phone_type: phone.type
                })), null, account_id));
            }),
            processItems(emailsToAdd, () => {
                return dispatch(contactEmailActions.addContactEmail(emailsToAdd.map(email => ({
                    main: email.main,
                    email: email.value,
                    email_type: email.type
                })), null, account_id));
            })
        ]);
    }
})

const processItems = (items, callback) => {
    if (items.length) {
        return callback();
    }
    return Promise.resolve();
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDataTab);

