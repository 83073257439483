import React from 'react';
import { Grid, List, Icon, ListItem, Form } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { VALIDATORS, generateResolver } from 'dyl-components';

const ChangePasswordForm = ({
    old,
    new_password,
    onValueChange,
    onKeyDown,
    isOldPasswordValid,
    confirm_password,
    your_password,
    owner_password
}) => {
    const { control, setError, getValues } = useForm({
        mode: 'onChange',
        reValidateMode: 'all',
        resolver: generateResolver({
            password: VALIDATORS.PASSWORD(true),
            confirm_password: VALIDATORS.PASSWORD_CONFIRMATION(true)

        })
    });

    const onValueCheck = (e) =>{
        const {name, value } = e.currentTarget || "";
        onValueChange(e, {name, value});
    }

    const checkConfirmPassword = () => {
        const { confirm_password, password } = getValues();
        const msg = (confirm_password !== password) ? 'Passwords must match' : false;

        setError("confirm_password", {
            message: msg,
        });
    }
    
    
    return (
        <Grid>
            <Grid.Column as={Form} autoComplete='off'>

                
                <List relaxed size='small'>
                    <ListItem
                        icon={
                            <Icon name='key' color='black' />
                        }
                        header={your_password ? 'Your Password' : 'Old Password'}
                        description={
                            <Form.Input 
                                type='password' 
                                value={old} 
                                fluid 
                                name='old'
                                onChange={onValueChange} 
                                onKeyDown={onKeyDown}  
                                error={(isOldPasswordValid?.error) && isOldPasswordValid?.message}/>
                        }
                    />
                    {!owner_password &&
                        <React.Fragment>
                            <ListItem
                                icon={
                                    <Icon name='key' color='black' />
                                }
                                header='New Password'
                                description={
                                    <Controller
                                    name="password" //changed to match the yup resolver
                                    control={control}
                                    render={({ field: { onChange,  value }, fieldState: { error } }) => (
                                        
                                        <Form.Input
                                            fluid
                                            type={'password'}
                                            name={'new_password'}
                                            onChange={(e) => {
                                                onChange(e)
                                                onValueCheck(e);
                                                checkConfirmPassword(e)
                                            }}
                                            value={value}
                                            error={error && error.message}
                                            required
                                        />
                                        
                                    )}
                                />
                                }
                            />
                            <ListItem
                                icon={
                                    <Icon name='key' color='black' />
                                }
                                header='Confirm Password'
                                description={
                                    <Controller
                                    name="confirm_password"
                                    control={control}
                              
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Form.Input
                                            fluid
                                            type={'password'}
                                            name={'confirm_password'}
                                            onChange={e=>{onChange(e);onValueCheck(e)}}
                                            onBlur={checkConfirmPassword}
                                            value={value}
                                            error={error && error.message}
                                            required
                                        />
                                    )}
                                />
                                }
                            />
                        </React.Fragment>
                    }
                </List>
            </Grid.Column>
        </Grid>
    )
}

export default ChangePasswordForm;
