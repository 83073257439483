import React, { useEffect, useRef, useState, useMemo } from "react";
import { Table, TableWithHeader, TableLoader } from "dyl-components";
import UserAccountsTableHeader from "./Header";
import UserAccountTableRow from "./Row";
import {
    Segment,
    Pagination,
    Table as SemanticTable,
    Header,
} from "semantic-ui-react";
import UserAccountsToolbar from "./Toolbar";
import AddUserModalContainer from "./AddUserModal";
import EditUserModalContainer from "./EditUserModal";

import "./index.scss";
import { useSelector, useDispatch } from "react-redux";
import teamsActions from "actions/teams";
import rolesActions from "actions/roles";
import assignActions from "actions/assign";

import { StringUtils } from "utils";
import { useNavigate, useSearchParams } from "react-router-dom";

const UserAccountsTable = ({
    teams,
    selectedTeams,
    roles,
    selectedRoles,
    onChangeFilters,
    userAccounts = [],
    onOpenEditUser,
    isReadingUserAccounts,
    statuses,
    selectedStatuses,
}) => (
    <Table>
        <SemanticTable.Header className="Table__header--primary">
            <UserAccountsTableHeader
                teams={teams}
                selectedTeams={selectedTeams}
                roles={roles}
                selectedRoles={selectedRoles}
                onChangeFilters={onChangeFilters}
                statuses={statuses}
                selectedStatuses={selectedStatuses}
            />
        </SemanticTable.Header>
        <Table.Body>
            {isReadingUserAccounts ? (
                <TableLoader isLoading colSpan={6} />
            ) : (
                userAccounts.map((userAccount, index) => (
                    <UserAccountTableRow
                        key={userAccount.user_id}
                        userAccount={userAccount}
                        onOpenEditUser={() => onOpenEditUser(index)}
                    />
                ))
            )}
        </Table.Body>
    </Table>
);

const UserAccountsTableContainer = () => {
    const firstLoad = useRef(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
    const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
    const [search, setSearch] = useState(params.get("search") || "");
    const [userAccountIndex, setUserAccountIndex] = useState(-1);
    const [currentPage, setCurrentPage] = useState(null);
    const [filters, setFilters] = useState({
        selectedTeams: params.get("team_id")?.split(","),
        selectedRoles: params.get("role")?.split(","),
        selectedStatuses: params.get("active")?.split(","),
    });

    const {
        user_accounts: userAccounts,
        count: userCount,
        isReadingUserAccounts,
    } = useSelector((state) => state.assign);
    const { teams = [], count: teamsCount } = useSelector(
        (state) => state.teams
    );
    const { roles = [], count: rolesCount } = useSelector(
        (state) => state.roles
    );
    const teamsList = teams.map(({ id, name }) => ({
        key: id,
        value: id,
        text: name,
    }));
    const teamsOptions = useMemo(
        () => [{ key: 0, value: 0, text: "Unassigned" }, ...teamsList],
        [teamsList]
    );
    const rolesOption = useMemo(
        () =>
            rolesCount !== 0
                ? roles.map(({ id, name }) => ({
                    key: id,
                    value: id,
                    text: StringUtils.capitalize(name),
                }))
                : [],
        [roles, rolesCount]
    );
    const page = params.get("page") || 1;
    const limit = 25;

    useEffect(() => {
        dispatch(teamsActions.getTeams({ limit: 1000 }));
        dispatch(rolesActions.readRoles({ limit: 1000 }));
    }, [dispatch]);

    useEffect(() => {
        let queryParameters = {
            page: 1,
            limit: 25,
            ...Object.fromEntries(params),
        };
        dispatch(assignActions.readUserAccounts(queryParameters));
    }, [dispatch, params]);

    const onOpenAddUser = () => {
        setIsAddUserModalOpen(true);
    };

    const onHideAddUserModal = () => {
        setIsAddUserModalOpen(false);
    };

    const onOpenEditUser = (index) => {
        setIsEditUserModalOpen(true);
        setUserAccountIndex(index);
    };

    const onHideEditUserModal = () => {
        setIsEditUserModalOpen(false);
    };

    const onChangePage = (_, { activePage }) => {
        const queryParams = new URLSearchParams(params);
        queryParams.set("page", activePage);
        const queryString = queryParams.toString();
        setCurrentPage(activePage,
            navigate(`/settings/users${queryString ? `?${queryString}` : ""}`) 
        );
    };

    const onChangeFilters = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value,
        });
        setCurrentPage(1);
    };

    useEffect(() => {
        if (firstLoad.current && rolesOption.length && teamsOptions.length) {
            const initialRolesSelected = rolesOption.map((role) => role.value);
            const initialTeamsSelected = teamsOptions.map((team) => team.value);

            setFilters({
                ...filters,
                selectedTeams: initialTeamsSelected,
                selectedRoles: initialRolesSelected,
                selectedStatuses: [0, 1],
            });
            firstLoad.current = false;
        }
    }, [filters, rolesOption, teamsOptions]);

    useEffect(() => {
        const query = new URLSearchParams(params);
        const queryPage = (!currentPage) ? query.get('page') : 1;
     
        if(query.get('page') !== null) {
            query.set("page", queryPage);
        } else {
            query.delete('page')
        }
        if (search.trim()) {
            query.set("search", search);
        } else {
            query.delete("search");
        }
        const { selectedRoles, selectedStatuses, selectedTeams } = filters;
        if (selectedRoles && selectedRoles?.length < rolesCount) {
            query.set("role", selectedRoles.join(","));
        } else {
            query.delete("role");
        }
        console.log(teamsCount + 1)
        console.log(selectedTeams?.length)
        if (selectedTeams && selectedTeams?.length < teamsCount + 1) {
            query.set("team_id", selectedTeams.join(","));
        } else {
            query.delete("team_id");
        }
        if (selectedStatuses && selectedStatuses?.length < 2) {
            query.set("active", selectedStatuses.join(","));
        } else {
            query.delete("active");
        }
        const queryString = query.toString();
        navigate(`/settings/users${queryString ? `?${queryString}` : ""}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, navigate, rolesCount, search, teamsCount]);

    return (
        <div className="UserAccountsPanel">
            <TableWithHeader
                header={
                    <UserAccountsToolbar
                        onOpenAddUser={onOpenAddUser}
                        value={search}
                        setSearch={setSearch}
                    />
                }
                table={
                    <Segment.Group vertical loading={isReadingUserAccounts}>
                        <Segment style={{ padding: 0 }}>
                            <UserAccountsTable
                                teams={teamsOptions}
                                selectedTeams={filters.selectedTeams}
                                roles={rolesOption}
                                selectedRoles={filters.selectedRoles}
                                onChangeFilters={onChangeFilters}
                                userAccounts={userAccounts}
                                onOpenEditUser={onOpenEditUser}
                                isReadingUserAccounts={isReadingUserAccounts}
                                statuses={[
                                    { key: "active", text: "Active", value: 1 },
                                    {
                                        key: "inactive",
                                        text: "Inactive",
                                        value: 0,
                                    },
                                ]}
                                selectedStatuses={filters.selectedStatuses}
                            />
                        </Segment>
                        {userCount !== 0 && (
                            <Segment textAlign="right">
                                <Pagination
                                    boundaryRange={0}
                                    activePage={page}
                                    ellipsisItem={null}
                                    siblingRange={2}
                                    totalPages={Math.ceil(userCount / limit)}
                                    onPageChange={onChangePage}
                                    disabled={isReadingUserAccounts}
                                />
                            </Segment>
                        )}
                        {!isReadingUserAccounts && userCount === 0 && (
                            <Segment>
                                <Header as="h3">No Results Found</Header>
                            </Segment>
                        )}
                    </Segment.Group>
                }
            />

            {isAddUserModalOpen && (
                <AddUserModalContainer
                    open={isAddUserModalOpen}
                    onClose={onHideAddUserModal}
                />
            )}

            {isEditUserModalOpen && (
                <EditUserModalContainer
                    userAccount={userAccounts[userAccountIndex]}
                    open={isEditUserModalOpen}
                    onClose={onHideEditUserModal}
                // onUpdateDetails={onUpdateDetails}
                />
            )}
        </div>
    );
};

export default UserAccountsTableContainer;
