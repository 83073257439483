const initialState = {
    isUpdatingEvent: false,
    isDeletingEvent: false,
    isReadingEvent: false,
    event: null,

    isUpdatingEventLabel: false,
    isDeletingEventLabel: false,
    isCheckingEventLabelDuplicate: false
}

export default initialState;
