import React from "react";
import { Table } from "dyl-components";
import { Loader, Segment, Pagination, Header } from "semantic-ui-react";
import PermissionsRow from "./PermissionsRow";
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from "react-router-dom";

const PermissionsTable = ({ permissionProfile, ...rowProps }) => {

    const [ query ] = useSearchParams();
    const search = query.get('search') || '';
    const page = query.get('page') || 1;
    const limit = 25;

    const navigate = useNavigate();

    const onChangePage = (_, { activePage }) => {
        query.set('page', activePage);
        let searchParams = new URLSearchParams({ page: activePage, ...(search? { search } : {}) }).toString();
        rowProps.readRoles({ page: activePage, limit, search });
        navigate(`/settings/permission-profile?${searchParams}`);
    }
    
    return (
        <React.Fragment>
            <Table>
                <Table.Header primary>
                    <Table.Row>
                        <Table.HeaderCell width='1' style={{padding: 15}}>Permission Profile</Table.HeaderCell>
                        <Table.HeaderCell style={{padding: 15}}>Assigned Users</Table.HeaderCell>
                        <Table.HeaderCell width='1' style={{padding: 15}}>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {permissionProfile.map(permission => (
                        <PermissionsRow
                            key={permission.id}
                            permission={permission}
                            {...rowProps}
                        />
                    ))}
                </Table.Body>
            </Table>
            {permissionProfile.length === 0 ?
                <Header as='h3'>No Results Found</Header>
            :
                <div className="Pagination">
                    <Pagination
                        boundaryRange={0}
                        activePage={ page }
                        ellipsisItem={ null }
                        siblingRange={2}
                        totalPages={ Math.ceil(rowProps.count / limit) }
                        onPageChange={ onChangePage }
                    />
                </div>
            }
        </React.Fragment>
    );
}
class PermissionsTableContainer extends React.Component {
    render() {
        if (this.props.isReadingRoles) {
            return (
                <Segment>
                    <Loader active />
                </Segment>
            );
        }
        return (
            <PermissionsTable 
                {...this.props} 
            />
        )
    }
}

const mapStateToProps = state => ({
    isSaving: state.roles.isReadingRoles,
    count: state.roles.count
});

export default connect(mapStateToProps)(PermissionsTableContainer);
