import React, { useContext } from 'react';
import { InvoicesHeader } from './Header';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RecordsTable } from 'dyl-components';
import InvoicesRow from './Row';
import { QuoteBuilderContext } from 'shared/context/QuoteBuilderProvider';
import QuoteBuilderModal from 'shared/modals/QuoteBuilderModal';

const InvoicesTable = ({ isColumnHidden, onPageChange }) => {
    const { count, invoices, isReadingInvoices } = useSelector(state => ({
        count: state.invoice_report.count,
        invoices: state.invoice_report.entries,
        isReadingInvoices: state.invoice_report.isReadingInvoices
    }));

    const [params] = useSearchParams();

    const { quoteBuilderConfig: { id: entryBeingViewed } } = useContext(QuoteBuilderContext);

    const { account, contact } = invoices.find(entry => entry.invoice_id === entryBeingViewed) || { account: {}, contact: {}, opportunity: {} };

    return (
        <>
            <RecordsTable
                tableBodyClassName='InvoicesTable'
                isTableLoading={isReadingInvoices}
                isSearchResultEmpty={count === 0 && (params.get('start') || params.get('end'))}
                isTableEmpty={count === 0 && !(params.get('start') || params.get('end'))}
                TableHeader={<InvoicesHeader isColumnHidden={isColumnHidden} />}
                TableRows={invoices.map(log => (
                    <InvoicesRow key={log.id} entry={log} isColumnHidden={isColumnHidden} />
                ))}
                recordsCount={count}
                activePage={params.get('page')}
                onPageChange={onPageChange}
                recordsName='Invoices'
                emptySubtitle=' '
                emptyListTitle='No Invoices Created'
            />
            <QuoteBuilderModal
                account_id={account?.id}
                contact_id={contact?.id}
                account_primary_contact_id={account?.primary_contact_id}
                isLinkedToCustomer={account?.customer}
            />
        </>
    )
}

export default InvoicesTable;
