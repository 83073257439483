import { DateTimeUtils } from "dyl-components";
import { useSelector } from "react-redux";
import { Grid, Header, Icon, Segment, Statistic } from "semantic-ui-react";

import "./index.scss";
import { StringUtils } from "utils";
import { Link, useParams } from "react-router-dom";

const Metric = ({ value, label, color,  }) => {
    return (
        <Statistic className="Metric" {...(color ? { color } : {})}>
            <Statistic.Value className="Metric__value">{value}</Statistic.Value>
            <Statistic.Label className="Metric__label">{label}</Statistic.Label>
        </Statistic>
    );
};

const ConversionMetric = ({
    total_converted,
    should_display_number,
    label,
}) => {
    return (
        <Metric
            color={should_display_number ? "primary" : "grey"}
            value={should_display_number ? total_converted : "-"}
            label={label}
        />
    );
};

const Section = ({ title, children }) => {
    return (
        <Segment.Group>
            <Segment size="mini" color="grey" inverted>
                <Header>{title}</Header>
            </Segment>
            <Segment>{children}</Segment>
        </Segment.Group>
    );
};

const CampaignDashboard = () => {
    const {
        status,
        period,
        campaign_conversion,

        expected_responses,
        expected_revenue,
        budgeted_cost,
        isCompanyCampaign,
        total_converted,
        total_active,
        total_inactive,
        total_contact,
        conversion_rate
    } = useSelector((state) => {
        const {
            name,
            description,
            is_company_campaign: companyCampaignDetails,
            campaign_conversion,
            ...otherDetails
        } = state.campaigns.campaign || {
            name: "",
            description: "",
            campaign_conversion: null,
            is_company_campaign: null,
        };
        const isCompanyCampaign =
            companyCampaignDetails &&
            Object.keys(companyCampaignDetails).length > 0;
        const start_date = isCompanyCampaign
            ? DateTimeUtils.changeFormat(
                  DateTimeUtils.convertUnixTimeToDate(
                      companyCampaignDetails.start_time
                  ),
                  "",
                  DateTimeUtils.DATE_FORMAT
              )
            : "";
        const end_date = isCompanyCampaign
            ? DateTimeUtils.changeFormat(
                  DateTimeUtils.convertUnixTimeToDate(
                      companyCampaignDetails.end_time
                  ),
                  "",
                  DateTimeUtils.DATE_FORMAT
              )
            : "";
        const period = isCompanyCampaign ? `${start_date} - ${end_date}` : "";
        return {
            ...otherDetails,
            isReadingCampaign: state.campaigns.isReadingCampaign,

            shared_type: isCompanyCampaign ? "company" : "personal",
            name: name || "",
            description: description || "",

            period,
            category:
                companyCampaignDetails?.campaign_category
                    ?.campaign_category_id || null,
            subcategory:
                companyCampaignDetails?.campaign_category
                    ?.campaign_subcategory_id || null,

            expected_responses:
                companyCampaignDetails?.expected_responses || null,
            expected_revenue: companyCampaignDetails?.expected_revenue || null,
            budgeted_cost: companyCampaignDetails?.budgeted_cost || null,
            campaign_conversion: campaign_conversion || null,
            isCompanyCampaign,
        };
    });

    const shouldDisplayConvertedMetric = (type) => {
        return type === campaign_conversion;
    };

    const id = Number(useParams().id);

    return (
        <Grid stretched>
            <Grid.Row>
                <Grid.Column width={7}>
                    <Section title={"Overview"}>
                        <Statistic.Group size="tiny">
                            <Metric
                                color={(() => {
                                    if (status === "Active") {
                                        return "green"
                                    }
                                    if (status === "Inactive") {
                                        return "red"
                                    }
                                    return "black";
                                })()}
                                value={status}
                                label={period}
                            />
                            <Metric
                                color={"primary"}
                                value={<Link to={`/campaigns/${id}/contacts`}>{total_contact || 0}</Link>}
                                label={<Link to={`/campaigns/${id}/contacts`}>Total Contacts</Link>}
                            />
                            <Metric
                                color={"primary"}
                                value={0}
                                label={"Attempted"}
                            />
                            <Metric
                                color={"primary"}
                                value={0}
                                label={"Pending"}
                            />
                        </Statistic.Group>
                    </Section>
                </Grid.Column>
                <Grid.Column width={3}>
                    <Section title={"Status"}>
                        <Statistic.Group widths={2} size="tiny">
                            <Metric
                                color={"green"}
                                value={<Link to={`/campaigns/${id}/contacts?status=active`}>{total_active || 0}</Link>}
                                label={<Link to={`/campaigns/${id}/contacts?status=active`}>Active</Link>}
                            />
                            <Metric
                                color={"red"}
                                value={<Link to={`/campaigns/${id}/contacts?status=inactive`}>{total_inactive || 0}</Link>}
                                label={<Link to={`/campaigns/${id}/contacts?status=inactive`}>Inactive</Link>}
                            />
                        </Statistic.Group>
                    </Section>
                </Grid.Column>
                <Grid.Column width={2}>
                    <Section title={"Revenue"}>
                        <Statistic.Group size="tiny">
                            <Metric
                                color={"grey"}
                                value={"-"}
                                label={"Attributed Revenue"}
                            />
                        </Statistic.Group>
                    </Section>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={10}>
                    <Section title={"Conversion"}>
                        <Statistic.Group size="tiny">
                            <Metric
                                color={"primary"}
                                value={`${conversion_rate ? StringUtils.formatDecimal(`${conversion_rate * 100}`) : 0}%`}
                                label={"Conversion Rate"}
                            />
                            <ConversionMetric
                                should_display_number={shouldDisplayConvertedMetric(
                                    "lead"
                                )}
                                total_converted={total_converted}
                                label={"Converted Leads"}
                            />
                            <ConversionMetric
                                should_display_number={shouldDisplayConvertedMetric(
                                    "opportunity"
                                )}
                                total_converted={total_converted}
                                label={"Converted Opportunities"}
                            />
                            <ConversionMetric
                                should_display_number={shouldDisplayConvertedMetric(
                                    "customer"
                                )}
                                total_converted={total_converted}
                                label={"Converted Customers"}
                            />
                            <ConversionMetric
                                should_display_number={shouldDisplayConvertedMetric(
                                    "custom"
                                )}
                                total_converted={total_converted}
                                label={"Converted Custom"}
                            />
                        </Statistic.Group>
                    </Section>
                </Grid.Column>
                {isCompanyCampaign && (
                    <Grid.Column width={6}>
                        <Section title={"Campaign Expected Success"}>
                            <Statistic.Group size="tiny">
                                <Metric
                                    color={"grey"}
                                    value={`${expected_responses || 0}%`}
                                    label={"Expected Responses"}
                                />
                                <Metric
                                    color={"grey"}
                                    value={`$${expected_revenue || 0}`}
                                    label={"Expected Revenue"}
                                />
                                <Metric
                                    color={"grey"}
                                    value={`$${budgeted_cost || 0}`}
                                    label={"Budgeted Cost"}
                                />
                            </Statistic.Group>
                        </Section>
                    </Grid.Column>
                )}
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={4}>
                    <Section
                        title={
                            <span>
                                <Icon className="fas fa-comment" /> Text
                                Campaign
                            </span>
                        }
                    ></Section>
                </Grid.Column>
                <Grid.Column width={7}>
                    <Section
                        title={
                            <span>
                                <Icon className="fas fa-phone" /> Call Campaign
                            </span>
                        }
                    ></Section>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default CampaignDashboard;
