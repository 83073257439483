const MARK_LEAD = 'MARK_LEAD';
const MARK_ALL_LEADS = 'MARK_ALL_LEADS';

const SET_LIMIT = 'SET_LIMIT';
const CHANGE_PAGE = 'CHANGE_PAGE';
const UPDATE_LEAD_ASSIGNMENT = 'UPDATE_LEAD_ASSIGNMENT'

export default {
    MARK_LEAD,
    MARK_ALL_LEADS,
    SET_LIMIT,
    CHANGE_PAGE,
    UPDATE_LEAD_ASSIGNMENT,
    MARK_LEADS_IN_EVERY_PAGE: 'MARK_LEADS_IN_EVERY_PAGE'
};
