import React from 'react';
import { Table } from 'dyl-components';
import { Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { PhoneUtil } from 'utils';
import { TwoLinedContent } from 'dyl-components';

const LeadConnectTableRow = ({ lead, onCall, onCancel }) => (
    <Table.Row>
        <Table.Cell>
            <TwoLinedContent firstLine={lead.received.split()[0]} secondLine={lead.received.split()[1]} />
        </Table.Cell>
        <Table.Cell>
            <Link to={`/lead?id=${lead.lead_id}`}>
                <Icon name='circle' className={`Contact__status-icon`} />
                <TwoLinedContent className={'Contact__details'} firstLine={lead.name} secondLine={PhoneUtil.formatPhoneNumber(lead.phone)} />
            </Link>
        </Table.Cell>
        <Table.Cell>
            <TwoLinedContent firstLine={lead.source} secondLine={lead.type} />
        </Table.Cell>
        <Table.Cell>
            {lead.numberOfMissedCalls}
        </Table.Cell>
        <Table.Cell>
            <TwoLinedContent firstLine={lead.lastCallDate} secondLine={lead.lastCallTime} />
        </Table.Cell>
        <Table.Cell>
            <TwoLinedContent firstLine={lead.nextCallDate} secondLine={lead.nextCallTime} />
        </Table.Cell>
        <Table.Cell>
            <Button icon={<Icon name='call' color='blue' />} onClick={() => {onCall(lead.id)}} />
            <Button icon={<Icon name='times' color='red' />} onClick={() => {onCancel(lead.id)}} />
        </Table.Cell>
    </Table.Row>
);

export default LeadConnectTableRow;
