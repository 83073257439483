import React from 'react';
import { Table, Button, STATUS_TYPES } from 'dyl-components';
import { Grid } from 'semantic-ui-react';

import UserRow from './UserRow';

const UsersTable = ({ users, header, features, onUpdate, onToggleFeature, onToggleIsActive }) => (
    <Grid>
        <Grid.Row>
            <Grid.Column>
                <Table>
                    <Table.Header>
                        {header}
                    </Table.Header>
                    <Table.Body>
                        {users.map(user => (
                            <UserRow
                                user={user}
                                key={user.id}
                                features={features}
                                onToggleFeature={onToggleFeature}
                                onToggleIsActive={onToggleIsActive}
                            />
                        ))}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell textAlign='right' colSpan={features.length + 2}>
                                <Button onClick={onUpdate} status={STATUS_TYPES.PRIMARY}>Update</Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

class UsersTableContainer extends React.Component {
    state = {
        users: this.props.users
    }

    onToggleFeature = (id, { name, value }) => {
        let users = this.state.users.slice(0);
        let indexOfUserToUpdate = users.findIndex(user => user.id === id);
        if (indexOfUserToUpdate !== -1) {
            let userToUpdate = users[indexOfUserToUpdate];
            let indexOfFeatureToUpdate = this.props.features.findIndex(feature => feature === name);
            if (indexOfFeatureToUpdate !== -1) {
                userToUpdate.isEnabledList[indexOfFeatureToUpdate] = value;
                this.setState({ users });
            }
        }
    }

    onToggleIsActive = (id, { checked }) => {
        let users = this.state.users.slice(0);
        let indexOfUserToUpdate = users.findIndex(user => user.id === id);
        if (indexOfUserToUpdate !== -1) {
            let userToUpdate = users[indexOfUserToUpdate];
            userToUpdate.isActive = checked;
            this.setState({ users });
        }
    }

    onUpdate = () => {
        this.props.onUpdate([...this.state.users]);
    }

    render() {
        return (
            <UsersTable 
                {...this.props}
                {...this.state}
                onToggleFeature={this.onToggleFeature}
                onUpdate={this.onUpdate}
                onToggleIsActive={this.onToggleIsActive}
            />
        )
    }
}

export default UsersTableContainer;
