import React from 'react';
import { Table } from 'dyl-components';
import { Table as SemanticTable, } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import IntegrationHeader from './IntegrationHeader';
import IntegrationRow from './IntegrationRow';
import DeletedIntegrationRow from './DeletedIntegrationRow';

import './IntegrationTable.scss';

const IntegrationTable = () => {

    const { integrations = [] } = useSelector(state => state.oauth_integrations);

    return (
        <Table unstackable className='IntegrationTable'>
            <SemanticTable.Header>
                <IntegrationHeader />
            </SemanticTable.Header>
            <Table.Body>
                {integrations.map((integration) => (
                    integration.active ? <IntegrationRow integration={integration} count={integrations.length} /> :
                        <DeletedIntegrationRow integration={integration} />

                ))}
            </Table.Body>
        </Table>
    );
}

export default IntegrationTable;
