import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { Grid, Menu, Segment } from 'semantic-ui-react';
import './index.scss'

const General = () => {
    return <Grid>
        <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Grid.Column style={{ paddingTop: '1em', paddingRight: 0, paddingLeft: '0.5em' }}>
                <Menu secondary className='PhoneManagement_generalTabs'>
                    <Menu.Item name='Call Recording' as={NavLink} to='call-recording' />
                    <Menu.Item name='Caller ID' as={NavLink} to='caller-id' />
                    <Menu.Item name='E911 Location' as={NavLink} to='e911-location' />
                    <Menu.Item name='TCR' as={NavLink} to='tcr' />
                    <Menu.Item name='Preferences' as={NavLink} to='preferences' />
                </Menu>
                <Segment basic>
                    <Outlet />
                </Segment>
            </Grid.Column>
        </Grid.Row>
    </Grid>;
}

export default General;