import React from 'react';
import {Grid} from "semantic-ui-react";

const RevenueInfo = ({ textAlign = 'right' }) => {
    return (
        <Grid className='RecordInfo'>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Revenue
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    None
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Cost
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    None
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Profit
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    None
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default RevenueInfo;