import React from 'react';
import { Table } from 'dyl-components';
import { Table as SemanticTable } from 'semantic-ui-react';

const PipelinesTableHeader = () => (
    <Table.Row>
        <SemanticTable.HeaderCell width={1}></SemanticTable.HeaderCell>
        <SemanticTable.HeaderCell width={4}>Pipeline/Stage Name</SemanticTable.HeaderCell>
        <SemanticTable.HeaderCell width={4}>Description</SemanticTable.HeaderCell>
        <SemanticTable.HeaderCell width={4}># of Days</SemanticTable.HeaderCell>
        <SemanticTable.HeaderCell width={3}>Actions</SemanticTable.HeaderCell>
    </Table.Row>
);

export default PipelinesTableHeader;
