import React from "react";
import { Header, Popup } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
    CloseableContent,
    Notification,
    STATUS_TYPES,
    VALIDATORS,
    generateResolver,
} from "dyl-components";

import { FormProvider, useForm } from "react-hook-form";
import ProductCategoryForm from "shared/forms/ProductCategoryForm";
import productCategoryActions from "actions/product_category";

const EditProductCategoryPopup = ({
    isOpen,
    actionPopupRef,
    id,
    onFormClose,
    refresh,

    name,
    description,
}) => {
    const dispatch = useDispatch();

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            name: name || "",
            description: description || "",
        },
        resolver: generateResolver({
            name: VALIDATORS.SIMPLE_INPUT()
                .noemoji()
                .minlength(2)
                .maxlength(100)
                .no_whitespace_only()
                .required("This field is required"),
            description: VALIDATORS.DESCRIPTION(),
        }),
    });

    const onSave = async (data) => {
        try {
            await dispatch(
                productCategoryActions.updateProductCategory(id, data)
            );
            Notification.alert(
                "Succesfully updated product category!",
                STATUS_TYPES.SUCCESS
            );
            if (refresh) {
                refresh();
            }
        } catch (e) {
            console.log(e);
            Notification.alert(
                "Failed to update product category",
                STATUS_TYPES.ERROR
            );
        }
    };

    const isSaving = useSelector(
        (state) => state.product_category.categoryBeingUpdated === id
    );

    const onClose = () => {
        onFormClose();
        methods.reset();
    };

    return (
        <Popup
            open={isOpen}
            position="bottom left"
            context={actionPopupRef}
            onClose={onClose}
            content={
                <FormProvider {...methods}>
                    <CloseableContent onClose={onClose}>
                        <div style={{ padding: "1em" }}>
                            <Header color="primary">Edit Category</Header>
                            <ProductCategoryForm
                                onSave={onSave}
                                isSaving={isSaving}
                            />
                        </div>
                    </CloseableContent>
                </FormProvider>
            }
        />
    );
};

export default EditProductCategoryPopup;
