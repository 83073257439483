import React from 'react';
import { Table } from 'dyl-components';
import TaskTypeRow from './TaskTypeRow';
import { Loader, Segment } from 'semantic-ui-react';
import task_types from 'actions/task_types';
import { connect } from 'react-redux';

const TaskTypesTable = ({ 
    taskTypes, 
    onUpdate,
    onDelete 
}) => (
    <Table>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Command</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {taskTypes.map(taskType => (
                <TaskTypeRow 
                    key={taskType.id}
                    taskType={taskType}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                />
            ))}
        </Table.Body>
    </Table>
);

class TaskTypesTableContainer extends React.Component {
    componentDidMount() {
        this.props.onReadTaskTypes();
    }

    render() {
        if (this.props.isReadingTaskTypes) {
            return (
                <Segment>
                    <Loader active />
                </Segment>
            )
        }
        if (this.props.taskTypes.length === 0) {
            return (
                <Segment textAlign='center'>
                    <em>There are no task types</em>
                </Segment>
            )
        }
        return (
            <TaskTypesTable 
                {...this.props}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        taskTypes: state.task_types.taskTypes,
        isReadingTaskTypes: state.task_types.isReadingTaskTypes
    }
}

const mapDistpatchToProps = (dispatch) => ({
    onReadTaskTypes: () => {
        dispatch(task_types.readTaskTypes());
    }
})

export default connect(mapStateToProps, mapDistpatchToProps)(TaskTypesTableContainer);
