import React from 'react';
import { Form, Header } from 'semantic-ui-react';

export const RecordInfo = ({
    created,
    last_modified,
    source
}) => (
    <Form size='small'>
        <Header as='h5' color='primary'>Assignment</Header>
        <Form.Select
            placeholder='Select User'
            label='Owned By' />
        <Form.Select
            placeholder='Select User'
            label='Co-Owned By' />
        <Header as='h5' color='primary'>Account Info</Header>
        <Form.Field
            label='Created'
            control='span'
        >
            {created}
        </Form.Field>
        <Form.Field
            control='span'
            label='Last Modified'
        >
            {last_modified}
        </Form.Field>
        <Form.Field
            control='span'
            label='Source'
        >
            {source}
        </Form.Field>
    </Form>
)
