import ACTION_NAMES from "actions/ACTION_NAMES";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import initialState from "./initialState";

function dataRecordReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.DATA_RECORD, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, dataRecordBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.DATA_RECORD, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, dataRecordBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.DATA_RECORD, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, dataRecordBeingUpdated: null };

        default:
            return state;
    }
}

export default dataRecordReducer;
