import React, { useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import pipelineActions from "actions/pipeline";

const TriggerForm = ({ control, converts_from, module, pipeline, reset, getValues, disabledModules, isEditing }) => {
    const dispatch = useDispatch();

    const RECORDS = [
        {
            value: 'contact', text: 'Contact', key: 'contact'
        },
        {
            value: 'lead', text: 'Lead', key: 'lead',
        },
        {
            value: 'opportunity', text: 'Opportunity', key: 'opportunity',
        },
        {
            value: 'customer', text: 'Customer', key: 'customer',
        }
    ];

    const convertsFromSlicing = {
        contact: 1,
        lead: 2,
        opportunity: 3,
        customer: 3,
    }

    const { pipelineCategories, isReadingCategories } = useSelector(state => ({
        pipelineCategories: state.pipeline.categories.map(category => ({
            key: category.id,
            value: category.id,
            text: category.name,
            stages: category.stages?.map(stage => ({
                key: stage.id,
                value: stage.id,
                text: stage.name
            })) || []
        })),
        isReadingCategories: state.pipeline.isReadingCategories,
    }));

    const onChangeConvertsFrom = () => {
        const triggerObject = getValues();
        reset({
            ...triggerObject,
            converts_to: null,
            pipeline: null,
            stage: null
        })
    }

    const onChangeModule = () => {
        const triggerObject = getValues();
        reset({
            ...triggerObject,
            pipeline: null,
            stage: null
        })
    }

    useEffect(() => {
        dispatch(pipelineActions.getPipelineCategories({ account_stage: module === null ? null : module }));
    }, [dispatch, module]);

    return (
        <>
            <Form.Group widths={'equal'}>
                <Controller
                    name='converts_from'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Select
                            fluid
                            label='Converts From'
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { 
                                onChange({ target: { name, value } }) 
                                onChangeConvertsFrom();
                            }}
                            options={RECORDS.slice(0, 3).map((option) => {
                                return {
                                    ...option,
                                    disabled: disabledModules[option.value]
                                }
                            })}
                            search
                            placeholder='Select record'
                            error={error && error.message && {
                                content: error.message,
                                pointing: 'below'
                            }}
                            required
                            disabled={isEditing}
                        />
                    )}
                />
                <Controller
                    name='converts_to'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Select
                            fluid
                            label='Converts To'
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { 
                                onChange({ target: { name, value } })
                                onChangeModule();
                            }}
                            options={convertsFromSlicing[converts_from] ? RECORDS.slice(convertsFromSlicing[converts_from]) : RECORDS}
                            search
                            placeholder='Select record'
                            error={error && error.message && {
                                content: error.message,
                                pointing: 'below'
                            }}
                            required
                            disabled={!converts_from || isEditing}
                        />
                    )}
                />
                    <Controller
                        name={'stage'}
                        control={control}
                        render={({ field: { onChange: onStageChange, name: stageName } }) => (
                            <Controller
                                name={'pipeline'}
                                control={control}
                                render={({ field: { name, value: categoryValue, onChange }, fieldState: { error } }) => [
                                    <Form.Select
                                        fluid
                                        label='Pipeline'
                                        name={name}
                                        value={categoryValue}
                                        onChange={(_, { name, value }) => {
                                            onChange({ target: { name, value } });
                                            onStageChange({ target: { name: stageName, value: pipelineCategories.find(category => category.value === value)?.stages[0]?.value || null } });
                                        }}
                                        options={pipelineCategories}
                                        search
                                        placeholder='Select Pipeline'
                                        loading={isReadingCategories}
                                        error={error && error.message && {
                                            content: error.message,
                                            pointing: 'below'
                                        }}
                                        clearable
                                        required
                                        disabled={!module}
                                    />,
                                    <Controller
                                        name={stageName}
                                        control={control}
                                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                            <Form.Select
                                                fluid
                                                label='Stage'
                                                name={name}
                                                value={value}
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                options={pipelineCategories.find(category => category.value === categoryValue)?.stages || []}
                                                search
                                                placeholder='Select Stage'
                                                error={error && error.message && {
                                                    content: error.message,
                                                    pointing: 'below'
                                                }}
                                                required
                                                disabled={!pipeline}
                                            />
                                        )}
                                    />
                                ]}
                            />
                        )}
                    />
            </Form.Group>
        </>
    );
}

export default TriggerForm;
