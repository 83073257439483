import React from 'react';
import { Modal, FileInput, Notification, STATUS_TYPES } from 'dyl-components';
import { Form } from 'semantic-ui-react';
import DisplayPicture from 'shared/DisplayPicture';

const UploadPhotoModal = React.forwardRef((({ open, onClose, fileChange, newImage, oldImage, onUpload, isUploading, altImage, circular = true }, fileInputRef) => (
    <Modal open={open} onClose={onClose} size='small'>
        <Modal.Header>
            Upload Photo
        </Modal.Header>
        <Modal.Content>
            {(altImage || newImage || oldImage) &&
                <React.Fragment>
                    {newImage || oldImage ? <DisplayPicture src={newImage || oldImage} circular={circular} /> :
                        <center style={{ marginBottom: 20 }}>
                            {altImage}
                        </center>
                    }
                </React.Fragment>
            }
            <Form>
                <Form.Field
                    control={FileInput}
                    onChange={fileChange}
                    onReject={(rejected) => {
                        if (rejected.length > 0) {
                            Notification.alert("Only accepts png and jpeg files.", STATUS_TYPES.ERROR, true);
                        }
                    }}
                    accept="image/png, image/jpeg"
                    name='file'
                    ref={fileInputRef}
                    hasBrowse
                    showIcon
                    icon='image'
                />
            </Form>
        </Modal.Content>
        <Modal.Actions
            hasSaveButton
            onSave={onUpload}
            saveDisabled={newImage === null || newImage === ''}
            saveOptions={{loading: isUploading}}
        />
    </Modal>
)));

export default UploadPhotoModal;
