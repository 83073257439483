import { RecordsTable } from 'dyl-components';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Header from './Header';
import Row from './Row';
import { useSelector } from 'react-redux';

const Table = ({ onFilter, onPageChange, onOpenModal, onEditDevice, onDeleteDevice }) => {
    const { devicesCount, isReadingDevices, devices } = useSelector((state) => state.provision);
    const [params] = useSearchParams();

    return <RecordsTable
        tableBodyClassName='DevicesTable'
        isTableLoading={isReadingDevices}
        isSearchResultEmpty={!devicesCount && (params.get('search') || params.get('type') || params.get('destination_type') || params.get('status'))}
        isTableEmpty={!devicesCount && !(params.get('search') || params.get('type') || params.get('destination_type') || params.get('status'))}
        tableEmptyMessage='No Devices Added'
        TableHeader={<Header onFilter={onFilter} count={devicesCount} devices={devices} />}
        TableRows={devices.map((device) => <Row device={device} onOpenModal={onOpenModal} onEditDevice={onEditDevice} onDeleteDevice={onDeleteDevice} />)}
        recordsCount={devicesCount}
        activePage={params.get('page')}
        onPageChange={onPageChange}
        recordsName='Device'
    />
}

export default Table;