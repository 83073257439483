import React from 'react';
import { Modal, generateResolver, yup, Notification, STATUS_TYPES } from 'dyl-components';
import { Button, Form, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { Controller, useForm } from 'react-hook-form';

import contactsActions from 'actions/contacts';
import AccountOptions from 'shared/forms/AccountOptions';
import accountActions from 'actions/account';
import tasksActions from 'actions/tasks';

const LinkExistingAccountForm = ({
    isLinking,
    onClose,

    onLinkContactsToAccount,
    contactIdToLink,
    refreshFromLinking,
    updateTasks
}) => {
    const { formState: { isDirty, isValid }, control, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            account: null
        },
        resolver: generateResolver({
            account: yup.mixed().required('This field is required')
        })
    });

    const onLink = async (data) => {
        try {
            await onLinkContactsToAccount([
                { person_id: contactIdToLink, primary_person: false }
            ], data.account?.id);
            await updateTasks(contactIdToLink, data.account?.id);
            Notification.alert('Successfully linked contact to account!', STATUS_TYPES.SUCCESS);
            onClose();
            refreshFromLinking();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to link contact to account', STATUS_TYPES.ERROR);
        }
    }

    return (
        [
            <Modal.Header>
                Link Existing Account
            </Modal.Header>,
            <Modal.Content>
                <Form noValidate>
                    <Header as='h3' color='primary'>Account</Header>
                    <Controller
                        name='account'
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                            <Form.Field
                                name={name}
                                value={value}
                                control={AccountOptions}
                                label='Link to Existing Account'
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } });
                                }}
                            />
                        )}
                    />
                </Form>
            </Modal.Content>,
            <Modal.Actions>
                {!isLinking && <Button primary basic onClick={onClose}>Cancel</Button>}
                <Button disabled={!isDirty || !isValid} primary onClick={handleSubmit(onLink)} loading={isLinking}>Save</Button>
            </Modal.Actions>
        ]
    )
}

const mapStateToProps = state => {
    return {
        isLinking: state.account.isLinkingContactsToAccount
    };
}

const mapDispatchToProps = dispatch => ({
    onCreateContacts: (contacts, account_id) => {
        return dispatch(contactsActions.createContact(contacts, { account_id }));
    },
    onLinkContactsToAccount: (contacts, account_id) => {
        return dispatch(accountActions.linkContacts(account_id, contacts))
    },
    updateTasks: (contact_id, account_id) => {
        return dispatch(tasksActions.updateTasks(-1, { contact_id, account_id }));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(LinkExistingAccountForm);
