import { useContext, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Header, Popup, Ref } from "semantic-ui-react";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import ConvertToCustomerForm from "shared/forms/ConvertToCustomerForm";

import orderActions from "actions/order";
import { Notification, STATUS_TYPES } from "dyl-components";
import ConvertNewCustomerPopup from "shared/ConvertNewCustomerPopup";
import { formatOrder } from "shared/schemas/cart/cartSchema";
import quoteActions from 'actions/quote';

const ConvertToCustomerOrder = ({
    account_id,
    contact_id,
    account_primary_contact_id,
    isLinkedToCustomer,
    disabled,
    isAllowedToUpsell
}) => {
    const {
        formState: { isValid },
        handleSubmit,
    } = useFormContext();

    const isSaving = useSelector(
        (state) => state.quote.isCreatingVersion || state.order.isCreating || state.quote.opportunityBeingUpdated
    );

    const quote = useSelector(state => state.quote.quote);
    const opportunity_id = quote?.opportunity_id;

    const [conversionModalOpen, setConversionModalOpen] = useState(false);
    const [isConversionFormModified, setIsConversionFormModified] = useState(false);

    const onCloseConversionForm = () => {
        setConversionModalOpen("");
    };

    const { onRefreshAccountInformation, onViewOrder, quoteBuilderConfig } =
        useContext(QuoteBuilderContext);
    const { id: quote_id } = quoteBuilderConfig;

    const dispatch = useDispatch();

    const onCreateOrder = async (data) => {
        try {
            const order_id = await dispatch(
                orderActions.create(
                    {
                        items: formatOrder(data.cart),
                    },
                    {
                        quote_id,
                        account_id,
                        opportunity_id
                    }
                )
            );
            Notification.alert(
                "Successfully created order",
                STATUS_TYPES.SUCCESS
            );
            onViewOrder(order_id, account_id);
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to create order", STATUS_TYPES.ERROR);
        }
    };

    const onCreateOrderForUpdatedAccount = (account_id, shouldMoveQuotes) => {
        return handleSubmit(async (data) => {
            try {
                if (shouldMoveQuotes) {
                    await dispatch(quoteActions.updateQuoteAccountId(opportunity_id, { account_id }));
                }
                const order_id = await dispatch(
                    orderActions.create(
                        {
                            items: formatOrder(data.cart),
                        },
                        {
                            quote_id,
                            account_id,
                            opportunity_id
                        }
                    )
                );
                Notification.alert(
                    "Successfully created order",
                    STATUS_TYPES.SUCCESS
                );
                onViewOrder(order_id, account_id);
            } catch (e) {
                console.log(e);
                Notification.alert(
                    "Failed to create order",
                    STATUS_TYPES.ERROR
                );
            }
        })();
    };

    const modalRef = useRef();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const isPrimaryContact =
        account_primary_contact_id && Number(account_primary_contact_id) === Number(contact_id);

    const createOrderAfterRefreshingAccountInfo = async (account_id, shouldMoveQuotes) => {
        await onRefreshAccountInformation();
        return onCreateOrderForUpdatedAccount(account_id, shouldMoveQuotes);
    };

    if (isLinkedToCustomer) {
        return (
            <>
                <Popup
                    content={
                        <div>
                            <Header color="primary">
                                Create Customer-Account
                            </Header>
                            <ConvertToCustomerForm
                                size="small"
                                owner_id={contact_id}
                                onClose={onCloseConversionForm}
                                refresh={createOrderAfterRefreshingAccountInfo}
                                onModify={(isModified) => { setIsConversionFormModified(isModified) }}
                                pipeline_id={opportunity_id}
                                current_primary_contact_id={contact_id}
                                action="convert-new-customer"
                                account_id={account_id}
                            />
                        </div>
                    }
                    open={conversionModalOpen === "new-customer"}
                    position="bottom center"
                    context={modalRef}
                    onClose={onCloseConversionForm}
                    closeOnDocumentClick={!isConversionFormModified}
                />
                <Ref innerRef={modalRef}>
                    <Dropdown
                        as={Button}
                        onClose={() => {
                            setIsDropdownOpen(false);
                        }}
                        onOpen={() => {
                            setIsDropdownOpen(true);
                        }}
                        color="blue"
                        text="Create Order"
                        basic
                        disabled={!isValid || isSaving || disabled}
                        icon={null}
                        pointing="bottom"
                    >
                        <Dropdown.Menu>
                            <Popup
                                open={isDropdownOpen && !isAllowedToUpsell}
                                disabled={
                                    isAllowedToUpsell
                                }
                                position={'left center'}
                                content={"Current products do not match existing subscription"}
                                inverted
                                trigger={(
                                    <Dropdown.Item
                                        onClick={handleSubmit(onCreateOrder)}
                                        disabled={!isAllowedToUpsell}
                                    >
                                        Upsell Existing Customer
                                    </Dropdown.Item>
                                )}
                            />
                            <ConvertNewCustomerPopup
                                position="left center"
                                open={
                                    isDropdownOpen &&
                                    (isPrimaryContact)
                                }
                                isPrimaryContact={isPrimaryContact}
                                account_id={account_id}
                                trigger={
                                    <Dropdown.Item
                                        disabled={
                                            isPrimaryContact
                                        }
                                        onClick={() => {
                                            setConversionModalOpen(
                                                "new-customer"
                                            );
                                        }}
                                    >
                                        Convert New Customer
                                    </Dropdown.Item>
                                }
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </Ref>
            </>
        );
    }

    return (
        <Popup
            trigger={
                <Button
                    disabled={!isValid || isSaving || disabled}
                    basic
                    type="button"
                    color="primary"
                >
                    Create Order
                </Button>
            }
            open={conversionModalOpen === "customer"}
            content={
                <>
                    <Header color="primary">Convert to Customer</Header>
                    <ConvertToCustomerForm
                        size="small"
                        account_id={account_id}
                        owner_id={contact_id}
                        refresh={(account_id) => {
                            return createOrderAfterRefreshingAccountInfo(account_id, true)
                        }}
                        current_primary_contact_id={account_primary_contact_id}
                        pipeline_id={opportunity_id}
                        onModify={(isModified) => { setIsConversionFormModified(isModified) }}
                        onClose={onCloseConversionForm}
                    />
                </>
            }
            onOpen={() => {
                setConversionModalOpen("customer");
            }}
            on="click"
            onClose={onCloseConversionForm}
            position="top center"
            closeOnDocumentClick={!isConversionFormModified}
        />
    );
};

export default ConvertToCustomerOrder;
