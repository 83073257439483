const initialState = {
    isReadingCategories: false,
    isCreatingCategory: false,
    category: {},
    categories: [],
    isReadingCategory: false,
    categoryBeingUpdated: null,
    categoryBeingDeleted: null,
    
    isCreatingStage: false,
    stageBeingUpdated: null,
    stageBeingDeleted: null,
    stage: {},

    isConvertingContact: false,
    isTransitioningPipeline: false,
    isConvertingToCustomer: false,

    isCreatingTrialAccount: false,
    isCreatingAuthTrialAccount: false,
    isUpdatingPassword: false,
    isEndingTrial: false,

    isSettingAccountAsLost: false,
    isReadingCloseReasons: false,
    close_reasons: [],
    isReactivatingTrial: false,

    categoryBeingReordered: null,
    isUpdatingCloseReasons: false,

    isClosingCustomerPipeline: false
};

export default initialState;
