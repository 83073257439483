import { useState } from "react";
import {
    Segment,
    Header,
    Icon,
    Popup,
    Table as SemanticTable,
} from "semantic-ui-react";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { ControlledPopup, Table, TableLoader } from "dyl-components";

import ProductAdditions from "./ProductAdditions";
import { StringUtils } from "utils";

const Fees = () => {
    const [isFeeModalOpen, setIsFeeModalOpen] = useState(false);
    const { control } = useFormContext();

    const onOpenAddonModal = () => {
        setIsFeeModalOpen(true);
    };

    const onCloseFeeModal = () => {
        setIsFeeModalOpen(false);
    };

    const { feesOptions, isReadingFees } = useSelector((state) => ({
        feesOptions: state.product_fees.fees,
        isReadingFees: state.product_fees.isReadingFees,
    }));

    return (
        <Segment>
            <Header color="primary" as="h3">
                <span>
                    <Icon
                        className="fas fa-plus-circle"
                        disabled={isReadingFees || !feesOptions?.length}
                        onClick={onOpenAddonModal}
                        link
                    />{" "}
                    Product Fees
                </span>
            </Header>
            <Controller
                name="fees"
                control={control}
                render={({ field: { name, value: fees, onChange } }) => {
                    if (!fees?.length) {
                        return (
                            <Segment.Inline>No fees added</Segment.Inline>
                        );
                    }
                    return (
                        <Table striped={false}>
                            <SemanticTable.Header className="Table__header--primary">
                                <Table.Row>
                                    <SemanticTable.HeaderCell width={3}>
                                        Fee Name
                                    </SemanticTable.HeaderCell>
                                    <SemanticTable.HeaderCell width={3}>
                                        Amount
                                    </SemanticTable.HeaderCell>
                                    <SemanticTable.HeaderCell width={2}>
                                        Action
                                    </SemanticTable.HeaderCell>
                                </Table.Row>
                            </SemanticTable.Header>
                            <Table.Body>
                                {isReadingFees ? (
                                    <TableLoader colspan={3} isLoading />
                                ) : (
                                    fees.map((fee) => (
                                        <Fee
                                            key={fee}
                                            feeDetails={
                                                feesOptions?.find(
                                                    (option) =>
                                                        option.id === fee
                                                ) || {}
                                            }
                                            onRemove={() => {
                                                onChange({
                                                    target: {
                                                        name,
                                                        value: fees.filter(
                                                            (selectedFee) =>
                                                                selectedFee !==
                                                                fee
                                                        ),
                                                    },
                                                });
                                            }}
                                        />
                                    ))
                                )}
                            </Table.Body>
                        </Table>
                    );
                }}
            />
            {isFeeModalOpen && (
                <Controller
                    control={control}
                    name="fees"
                    render={({
                        field: { name, value: selectedFees, onChange },
                    }) => (
                        <ProductAdditions
                            open={isFeeModalOpen}
                            selectedEntries={selectedFees}
                            onClose={onCloseFeeModal}
                            ModalHeader={
                                <ModalHeader
                                    isEditing={selectedFees?.length > 0}
                                />
                            }
                            selectLabel="Fee Name"
                            isSelectLoading={isReadingFees}
                            selectOptions={feesOptions.map((option) => ({
                                ...option,
                                text: `${option.name} (${
                                    !option?.percent ? "$" : ""
                                }${option.amount}${
                                    option?.percent ? "%" : ""
                                })`,
                            }))}
                            selectPlaceholder="Search fee name"
                            removalConfirmationMessage={
                                "Fee(s) will be removed"
                            }
                            onSave={(updatedSelectedFees) => {
                                onChange({
                                    target: {
                                        name,
                                        value: updatedSelectedFees,
                                    },
                                });
                                onCloseFeeModal();
                            }}
                        />
                    )}
                />
            )}
        </Segment>
    );
};

const ModalHeader = ({ isEditing }) => (
    <>
        {isEditing ? "Edit Product Fee" : "Add Product Fee"}
        <Popup
            trigger={
                <Icon
                    size="small"
                    style={{
                        float: "right",
                        marginRight: "1em",
                        marginTop: "0.2em",
                    }}
                    className="fas fa-circle-info"
                    color="primary"
                />
            }
            content={"These fees are applied prior to sales tax."}
            inverted
            position="bottom right"
            size="mini"
        />
    </>
);

const Fee = ({ feeDetails, onRemove }) => {
    const { name: feeName, amount, percent, status } = feeDetails;

    return (
        <Table.Row>
            <Table.Cell>
                {status !== "active" && (
                    <Popup
                        content={StringUtils.capitalize(status)}
                        inverted
                        position="top center"
                        trigger={
                            <Icon
                                className="fas fa-info-circle"
                                color="orange"
                            />
                        }
                    />
                )}
                {feeName}
            </Table.Cell>
            <Table.Cell>{`${!percent ? "$" : ""}${amount}${
                percent ? "%" : ""
            }`}</Table.Cell>
            <Table.Cell>
                <ControlledPopup
                    trigger={<Icon className="fas fa-trash" color="red" link />}
                    onConfirm={onRemove}
                    content="Delete"
                    position="bottom center"
                />
            </Table.Cell>
        </Table.Row>
    );
};

export default Fees;
