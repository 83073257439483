import React, { useEffect, useState } from 'react';
import { DividingHeader, generateResolver, STATUS_TYPES, Notification, Button, yup } from 'dyl-components';
import { Header, Form, Portal, Grid } from 'semantic-ui-react'
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import SettingsFooter from "dyl-components/molecules/SettingsFooter";
import useWindowWidth from "shared/SettingsFooter/useWindowWidth";
import useWidthListener from "shared/SettingsFooter/useWidthListener";
import notificationActions from "actions/notifications";
import { MathUtils } from 'utils';
import './index.scss';

const upcomingRemainderTimeOptionsList = [
    { key: 0, value: 0, text: '0 minutes prior' },
    { key: 600, value: 600, text: '10 minutes prior' },
    { key: 900, value: 900, text: '15 minutes prior' },
    { key: 1800, value: 1800, text: '30 minutes prior' },
    { key: 86400, value: 86400, text: '1 Business day prior' },
    { key: 172800, value: 172800, text: '2 Business day prior' },
];

const General = () => {

    const dispatch = useDispatch();
    const { notificationSettings, isUpdatingNotificationSettings } = useSelector((state) => state.notifications);
    const [isReseted, setReset] = useState(false);

    const windowWidth = useWindowWidth();
    const width = useWidthListener("settingsSidebar");

    const toggleOnOffValues = (status) => {
        let formValues = {
            enable_system_notification: !status,
            receive_notifications_outside_of_work_hours: status,
            do_not_disturb_during_calls: status,
            enable_event_reminders: status,
            enable_email_notifications_for_voicemails: status,
            enable_email_notifications_for_text_messages: status,
            incoming_texts: status,
            incoming_emails: status,
            incoming_fax: status,
            upcoming_meeting_reminder: notificationSettingsData.upcoming_meeting_reminder ?? 0,
            enable_task_reminders: status,
            reminder_to_complete: notificationSettingsData.reminder_to_complete ?? 0,
            missed_call: status,
            voicemail: status
        };
        return formValues;
    }

    const [notificationSettingsData, setNotificationSettingsData] = useState({});
    const [enableTaskReminders, setEnableTaskReminders] = useState(null);
    const [enableEventReminders, setEnableEventReminders] = useState(null);

    const userProfileValidations = {
        reminder_to_complete: yup.number().oneOf(upcomingRemainderTimeOptionsList.map(option => option.value)),
        upcoming_meeting_reminder: yup.number().oneOf(upcomingRemainderTimeOptionsList.map(option => option.value)),
    };

    const {
        handleSubmit,
        formState: { errors, isValid, isDirty },
        control,
        getValues,
        watch,
        setValue,
        reset,
        trigger
    } = useForm({
        mode: "onChange",
        resolver: generateResolver(userProfileValidations),
        defaultValues: notificationSettingsData
    });

    const setValues = async (object) => {
        for (let key in object) {
            setValue(key, object[key], { shouldDirty: true });
        }
    }

    useEffect(() => {
        if (notificationSettings) {
            let deStructuredNotificationSettings = {
                ...notificationSettings,
                upcoming_meeting_reminder: notificationSettings.enable_event_reminders?.remind,
                enable_event_reminders: notificationSettings.enable_event_reminders?.enable,
                reminder_to_complete: notificationSettings.enable_task_reminders?.remind,
                enable_task_reminders: notificationSettings.enable_task_reminders?.enable
            };
            setNotificationSettingsData(deStructuredNotificationSettings);
            setValues(deStructuredNotificationSettings);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationSettings]);

    useEffect(() => {
        dispatch(notificationActions.getNotificationSettings());
    }, [dispatch])

    useEffect(() => {
        !isUpdatingNotificationSettings && dispatch(notificationActions.getNotificationSettings());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdatingNotificationSettings]);

    useEffect(() => {
        const subscription = watch((data, { name }) => {
            if (name !== 'enable_system_notification') {
                let allDisabled = true;
                let notifiactions = data;
                for (let key in notifiactions) {
                    if (notifiactions.hasOwnProperty(key) && notifiactions[key] === true && key !== 'enable_system_notification') {
                        allDisabled = false;
                    }
                }

                setEnableTaskReminders(notifiactions?.enable_task_reminders);
                setEnableEventReminders(notifiactions?.enable_event_reminders)

                if (allDisabled) setValue('enable_system_notification', false);
                if (!allDisabled && !getValues('enable_system_notification')) setValue('enable_system_notification', true);
            }
        })
        return () => {
            subscription.unsubscribe()
        }
    }, [watch, getValues, setValue]);

    useEffect(() => {
        reset(notificationSettingsData);
        setReset(true);
    }, [reset, notificationSettingsData]);
  
    useEffect(() => {
        isReseted && trigger();
    }, [trigger, isReseted]);

    const handleSubmitData = async (data) => {
        try {
            data.enable_task_reminders = {
                enable: data.enable_task_reminders,
                remind: data.reminder_to_complete
            };
            data.enable_event_reminders = {
                enable: data.enable_event_reminders,
                remind: data.upcoming_meeting_reminder
            };
            await dispatch(notificationActions.updateNotificationSettings(null, data));
            Notification.alert(
                "Successfully updated!",
                STATUS_TYPES.SUCCESS
            );
            reset(getValues());
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to update!", STATUS_TYPES.ERROR);
        }
    };

    const onError = (e) => {
        console.error(`|+|+|+ |There was an error validating the form`, e);
    };

    const toggleAllNotifications = () => {
        let system_notifications = getValues("enable_system_notification");
        system_notifications ? setValues(toggleOnOffValues(true)) : setValues(toggleOnOffValues(false));
    }

    return (
        <>
            <Form size="small" className="system_notifications_general">
                <Header as='h1'>System Notifications</Header>
                <Form.Group>
                    <Controller
                        name="enable_system_notification"
                        control={control}
                        render={({ field: { onChange, name, value } }) => {
                            return <Form.Checkbox
                                name={name}
                                className='Notification_Settings'
                                checked={value}
                                ref={null}
                                width={5}
                                toggle
                                label='Enable system notifications'
                                onChange={(_, { checked }) => { 
                                    onChange({ target: { name, value: checked } }); 
                                    toggleAllNotifications(); 
                                }}
                            />
                        }}
                    />
                </Form.Group>

                <DividingHeader noline content={<h2 className='notification_headers'>Schedule</h2>} compact />
                <Form.Group>
                    <Controller
                        name="receive_notifications_outside_of_work_hours"
                        control={control}
                        render={({ field: { onChange, name, value } }) => {
                            return <Form.Checkbox
                                name={name}
                                checked={value}
                                ref={null}
                                width={10}
                                toggle
                                className='label-text Notification_Settings'
                                label='Receive notifications outside of work hours'
                                onChange={(_, { checked }) => { onChange({ target: { name, value: checked } }) }}
                            />
                        }}
                    />
                </Form.Group>

                <Form.Group>
                    <Controller
                        name="do_not_disturb_during_calls"
                        control={control}
                        render={({ field: { onChange, name, value } }) => {
                            return <Form.Checkbox
                                name={name}
                                className='Notification_Settings'
                                checked={value}
                                ref={null}
                                width={10}
                                toggle
                                label='Do not disturb during calls'
                                onChange={(_, { checked }) => { onChange({ target: { name, value: checked } }) }}
                            />
                        }}
                    />
                </Form.Group>

                <Grid>
                    <Grid.Row columns='equal'>
                        <Grid.Column width={7}>
                            <DividingHeader noline content={<h2 className='notification_headers'>Reminders</h2>} compact />
                            <Form.Group>
                                <Controller
                                    name="enable_event_reminders"
                                    control={control}
                                    render={({ field: { onChange, name, value } }) => {
                                        return <Form.Checkbox
                                            name={name}
                                            className='Notification_Settings'
                                            checked={value}
                                            ref={null}
                                            width={12}
                                            toggle
                                            label='Enable event reminders'
                                            onChange={(_, { checked }) => { onChange({ target: { name, value: checked } }) }}
                                        />
                                    }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Controller
                                    name="upcoming_meeting_reminder"
                                    control={control}
                                    render={({ field: { onChange, name, value } }) => {
                                        return <Form.Select
                                            name={name}
                                            ref={null}
                                            value={value}
                                            options={upcomingRemainderTimeOptionsList}
                                            width={10}
                                            error={errors?.upcoming_meeting_reminder?.message}
                                            required
                                            disabled={!getValues('enable_system_notification') || !enableEventReminders}
                                            label='Upcoming meeting reminder'
                                            placeholder='None'
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                        />
                                    }}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Controller
                                    name="enable_task_reminders"
                                    control={control}
                                    render={({ field: { onChange, name, value } }) => {
                                        return <Form.Checkbox
                                            name={name}
                                            className='Notification_Settings'
                                            checked={value}
                                            ref={null}
                                            width={12}
                                            toggle
                                            label='Enable task reminders'
                                            onChange={(_, { checked }) => { onChange({ target: { name, value: checked } }) }}
                                        />
                                    }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Controller
                                    name="reminder_to_complete"
                                    control={control}
                                    render={({ field: { onChange, name, value } }) => {
                                        return <Form.Select
                                            name={name}
                                            ref={null}
                                            value={value}
                                            options={upcomingRemainderTimeOptionsList}
                                            width={10}
                                            error={errors.reminder_to_complete?.message}
                                            label='Reminder to complete'
                                            placeholder='None'
                                            required
                                            disabled={!getValues('enable_system_notification') || !enableTaskReminders}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                        />
                                    }}
                                />
                            </Form.Group>
                        </Grid.Column>

                        <Grid.Column width={7}>
                            <DividingHeader noline content={<h2 className='notification_headers'>Alerts</h2>} compact />
                            <Form.Group>
                                <Controller
                                    name="enable_email_notifications_for_voicemails"
                                    control={control}
                                    render={({ field: { onChange, name, value } }) => {
                                        return <Form.Checkbox
                                            name={name}
                                            className='Notification_Settings'
                                            checked={value}
                                            ref={null}
                                            width={16}
                                            toggle
                                            label='Enable email notifications for voicemails'
                                            onChange={(_, { checked }) => { onChange({ target: { name, value: checked } }) }}
                                        />
                                    }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Controller
                                    name="enable_email_notifications_for_text_messages"
                                    control={control}
                                    render={({ field: { onChange, name, value } }) => {
                                        return <Form.Checkbox
                                            name={name}
                                            className='Notification_Settings'
                                            checked={value}
                                            ref={null}
                                            width={16}
                                            toggle
                                            label='Enable email notifications for text messages'
                                            onChange={(_, { checked }) => { onChange({ target: { name, value: checked } }) }}
                                        />
                                    }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Controller
                                    name="incoming_texts"
                                    control={control}
                                    render={({ field: { onChange, name, value } }) => {
                                        return <Form.Checkbox
                                            name={name}
                                            checked={value}
                                            className='Notification_Settings'
                                            ref={null}
                                            width={10}
                                            toggle
                                            label='Incoming Texts'
                                            onChange={(_, { checked }) => { onChange({ target: { name, value: checked } }) }}
                                        />
                                    }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Controller
                                    name="incoming_emails"
                                    control={control}
                                    render={({ field: { onChange, name, value } }) => {
                                        return <Form.Checkbox
                                            name={name}
                                            className='Notification_Settings'
                                            checked={value}
                                            ref={null}
                                            width={10}
                                            toggle
                                            label='Incoming Emails'
                                            onChange={(_, { checked }) => { onChange({ target: { name, value: checked } }) }}
                                        />
                                    }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Controller
                                    name="incoming_fax"
                                    control={control}
                                    render={({ field: { onChange, name, value } }) => {
                                        return <Form.Checkbox
                                            name={name}
                                            className='Notification_Settings'
                                            checked={value}
                                            ref={null}
                                            width={10}
                                            toggle
                                            label='Incoming Faxes'
                                            onChange={(_, { checked }) => { onChange({ target: { name, value: checked } }) }}
                                        />
                                    }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Controller
                                    name="missed_call"
                                    control={control}
                                    render={({ field: { onChange, name, value } }) => {
                                        return <Form.Checkbox
                                            name={name}
                                            className='Notification_Settings'
                                            checked={value}
                                            ref={null}
                                            width={10}
                                            toggle
                                            label='Missed Calls'
                                            onChange={(_, { checked }) => { onChange({ target: { name, value: checked } }) }}
                                        />
                                    }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Controller
                                    name="voicemail"
                                    control={control}
                                    render={({ field: { onChange, name, value } }) => {
                                        return <Form.Checkbox
                                            name={name}
                                            className='Notification_Settings'
                                            checked={value}
                                            ref={null}
                                            width={10}
                                            toggle
                                            label='Voicemails'
                                            onChange={(_, { checked }) => { onChange({ target: { name, value: checked } }) }}
                                        />
                                    }}
                                />
                            </Form.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>

            <Portal open>
                <SettingsFooter
                    style={{ width: MathUtils.calculatePercentage(windowWidth, windowWidth - width) }}
                    className={`Webform__menu`}
                    rightOptions={[
                        <Button
                            disabled={!isValid || !isDirty || isUpdatingNotificationSettings}
                            loading={isUpdatingNotificationSettings}
                            onClick={handleSubmit(handleSubmitData, onError)} primary>Save</Button>
                    ]}
                />
            </Portal>
        </>
    )
}

export default General;