import React from 'react';
import { Popup } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { ConfirmationPrompt, Notification, STATUS_TYPES } from 'dyl-components';

import campaignsActions from 'actions/campaigns';

const DeleteCampaignPopup = ({ isOpen, actionPopupRef, campaignID, onFormClose, refresh }) => {
    const dispatch = useDispatch();

    return (
        <Popup
            open={isOpen}
            position='bottom center'
            context={actionPopupRef}
            onClose={() => onFormClose()}
            content={
                <ConfirmationPrompt
                    subheader='You cannot undo delete actions.'
                    onConfirm={async () => {
                        try {
                            onFormClose();
                            await dispatch(campaignsActions.deleteCampaign(campaignID));
                            Notification.alert('Successfully deleted campaign!', STATUS_TYPES.SUCCESS);
                            refresh();
                        } catch (e) {
                            console.log(e);
                            Notification.alert('Failed to delete campaign', STATUS_TYPES.ERROR)
                        }
                    }}
                    onCancel={() => { onFormClose() }}
                />
            }
            hoverable
        />
    );
}

export default DeleteCampaignPopup;
