
import React from 'react';
import { Grid, Input, Form, Icon, ListItem, List } from 'semantic-ui-react';
import { DateInput, BlueCheckBox } from 'dyl-components';
import { DateTimeUtils } from 'dyl-components';
import '../index.scss';

const EmailViewBody = ({ time, date, body, notes, onChange, destination, source, id, completed, onToggleEmailComplete}) => {

    let isAllowedToModify = false;
  

    return(
        <Grid className='EmailFormBody' stackable>
             <Grid.Row columns='equal' className='EmailFormBody__Row'>
                <Grid.Column>
                <Form as={List} >
                    <ListItem
                        icon={
                            <Icon name='clock' color='black' />
                        }
                        description={(
                            <Grid>
                                <Grid.Row columns='equal'>
                                    <Grid.Column>
                                        <Form.Field
                                            name='date'
                                            control={DateInput}
                                            label='Date &amp; Time'
                                            onChange={onChange}
                                            value={date}
                                            required
                                            icon='calendar alternate'
                                            dateFormat={DateTimeUtils.WORD_DATE_FORMAT}
                                            iconPosition='left'
                                            readOnly={!isAllowedToModify}
                                        /> 
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field
                                            name='time'
                                            readOnly={!isAllowedToModify}
                                            control={DateInput}
                                            className="Task__no-label--Error-fix"
                                            onChange={onChange}
                                            value={time}
                                            iconPosition='left'
                                            icon='clock'
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        )}
                    />
                    <ListItem
                        icon={
                            <Icon name='user' color='black' />
                        }
                        description={(
                            <Grid>
                                <Grid.Row columns='equal'>
                                    <Grid.Column>
                                        <Form.Field
                                            control={Input}
                                            label='To'
                                            name='destination'
                                            onChange={onChange}
                                            value={destination}
                                            readOnly={!isAllowedToModify}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field
                                            label='From'
                                            name='source'
                                            control={Input}
                                            onChange={onChange}
                                            value={source}
                                            readOnly={!isAllowedToModify}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        )}
                    />
                    <ListItem
                        icon={
                            <Icon name='envelope' color='black' />
                        }
                        description={<Form.TextArea
                            label='Body' 
                            rows={4} 
                            value={body} 
                            name='body' 
                            onChange={onChange}
                            readOnly={!isAllowedToModify}
                        />}
                    />
                    <ListItem
                        icon={
                            <Icon name='pen alternate' color='black' />
                        }
                        description={<Form.Input
                            label='Note' 
                            value={notes} 
                            name='notes' 
                            onChange={onChange}
                            readOnly={!isAllowedToModify}
                        />}
                    />
                    <ListItem
                        description={
                            <BlueCheckBox
                                label='Marked Complete'
                                onClick={() => onToggleEmailComplete(id, completed)}
                                checked={completed}
                                readOnly={!isAllowedToModify}
                            />
                        }
                    />
                </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};



export default EmailViewBody;