import React from 'react';
import { useForm } from 'react-hook-form';
import ContactGroupForm from './ContactGroupForm';
import { Button, Icon } from 'semantic-ui-react';
import { generateResolver, yup } from 'dyl-components';

const ContactGroupPopup = ({handlePopupClose, onSave, group, isDuplicated, isSubgroup}) => {
    const defaultValues = {
        name: group ? (group.name ? group.name : "") : "",
        shared_type: group ? (group.shared_type ? group.shared_type : "") : "",
    }
    const { control, formState: { isDirty, isValid }, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: generateResolver({
            name: yup.string().minlength(2).maxlength(41).required('This field is required').test("no_duplicated_name", "This name already exists.", async (name) => {
                if (name !== '' && name.length > 1 && name.length < 42) {
                    const result = await isDuplicated(name);
                    return !result;
                }
                return true;
            }),
            shared_type: isSubgroup ? null : yup.string().required('This field is required'),
        })
    });

    return (
        <div className='GroupForm__container'>
            <Icon
                className='fa-solid fa-xmark GroupForm__xIcon'
                size='large'
                onClick={handlePopupClose}
                link
            />
            <div className='GroupForm__formContainer'>
                <ContactGroupForm control={control} group={group} isSubgroup={isSubgroup} />
            </div>
            <Button
                className='GroupFrom__saveBtn'
                disabled={!isDirty || !isValid}
                onClick={handleSubmit(onSave)}
                primary
            >
                Save
            </Button>
        </div>
    ); 
}

export default ContactGroupPopup;