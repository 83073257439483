import React from "react";
import { useSelector } from "react-redux";
import { Table as SemanticTable } from "semantic-ui-react";
import { Table } from "dyl-components";

import SelectAllCheckbox from "shared/SelectAllCheckbox";

const TableHeader = () => {
    const { count, addons = [] } = useSelector((state) => ({
        count: state.product_addons.count,
        addons: state.product_addons.addons,
    }));

    return (
        <Table.Row>
            <SemanticTable.HeaderCell
                width={1}
                className="AddonsTable__SmallFixedWidth"
            >
                <SelectAllCheckbox
                    count={count}
                    data={addons}
                    type={"addons"}
                    idProperty={"id"}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                className="AddonsTable__LargeFixedWidth"
                width={4}
            >
                Add-On Name
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                className="AddonsTable__LargeFixedWidth"
                width={4}
            >
                Description
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={1}
                className="AddonsTable__SmallFixedWidth"
            >
                Price
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={1}
                className="AddonsTable__SmallFixedWidth"
            >
                Status
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={1}
                className="AddonsTable__SmallFixedWidth"
            >
                Actions
            </SemanticTable.HeaderCell>
        </Table.Row>
    );
};

export default TableHeader;
