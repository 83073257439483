import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { RecordsTable } from 'dyl-components';

import CampaignsTableHeader from './Header';
import CampaignsRow from './Row';
import DeleteCampaignPopup from "./DeleteCampaignPopup";
import { CampaignsContext } from './CampaignsContextProvider';
import ReactivateCampaignPopup from './ReactivateCampaignPopup';

const CampaignsTable = ({ isColumnHidden, onFilter, onPageChange, readCampaigns }) => {
    const [params] = useSearchParams();

    const {
        state,
        onClearSelected,
    } = useContext(CampaignsContext);
    const { campaignsReducer, user_id } = useSelector(state => ({
        campaignsReducer: state.campaigns,
        user_id: state.auth.user_id,
    }));
    const { campaigns = [], isReadingCampaigns, campaignBeingDeleted, campaigns_count } = campaignsReducer;

    const isTableLoading = isReadingCampaigns || campaignBeingDeleted;

    const { ref, selectedCampaign, action } = state;

    return (
        <>
            <RecordsTable
                tableBodyClassName='CampaignsTable'
                isTableLoading={isTableLoading}
                isSearchResultEmpty={campaigns_count === 0 && (params.get('search') || params.get('start') || params.get('end') || params.get('type') || params.get('status') || params.get('shared'))}
                isTableEmpty={campaigns_count === 0 && !(params.get('search') || params.get('start') || params.get('end') || params.get('type') || params.get('status') || params.get('shared'))}
                TableHeader={<CampaignsTableHeader isColumnHidden={isColumnHidden} onFilter={onFilter} />}
                TableRows={campaigns.map(campaign => (
                    <CampaignsRow
                        key={campaign.id}
                        user_id={user_id}
                        campaign={campaign}
                        isColumnHidden={isColumnHidden}
                    />
                ))}
                recordsCount={campaigns_count}
                activePage={params.get('page')}
                onPageChange={onPageChange}
                recordsName='Campaign'
                emptySubtitleLink={'/campaigns/create'}
            />
            <DeleteCampaignPopup
                isOpen={action === 'delete' && selectedCampaign}
                actionPopupRef={ref}
                campaignID={selectedCampaign}
                onFormClose={onClearSelected}
                refresh={readCampaigns}
            />
            <ReactivateCampaignPopup
                isOpen={action === 'reactivate' && selectedCampaign}
                actionPopupRef={ref}
                campaignID={selectedCampaign}
                onFormClose={onClearSelected}
                refresh={readCampaigns}
            />
        </>
    );
}

export default CampaignsTable;
