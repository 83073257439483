import React from 'react';
import { Tab } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { StringUtils } from 'utils';

export const FormDataPane = () => {
    const CleanHTML = StringUtils.CleanHTML;
    const { email } = useSelector(state => state.lead_ingestion);
    const post = email?.post;
    const { content, content_type } = post;

    return (
        
        <Tab.Pane>
            {
                content_type === 'application/json' ?
                    <CleanHTML>{content}</CleanHTML>
                        : content_type === 'application/xml' || 'multipart/form-data' ?
                    <pre>{content}</pre>
                        : <pre>{content}</pre>
            }
        </Tab.Pane>
    );
}
