import { generateCreateActionCreator, generateDeleteActionCreator, generateReadOneActionCreator, generateUpdateActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import routes from 'actions/routes';

export default {    
    readCallQueues: generateReadOneActionCreator(routes.API_PBX_CONFIG_DOMAIN, ACTION_NAMES.CALL_QUEUES, 'call_queues'),
    createCallQueues: generateCreateActionCreator(routes.API_PBX_CONFIG_DOMAIN, ACTION_NAMES.CALL_QUEUES, () => {}, () => {}, 'call_queues'),
    readCallQueue: generateReadOneActionCreator(routes.API_PBX_CONFIG_DOMAIN, ACTION_NAMES.CALL_QUEUE, 'call_queues'),
    updateCallQueue: generateUpdateActionCreator(routes.API_PBX_CONFIG_DOMAIN, ACTION_NAMES.CALL_QUEUE, () => {}, () => {}, 'call_queues'),
    deleteCallQueue: generateDeleteActionCreator(routes.API_PBX_CONFIG_DOMAIN, ACTION_NAMES.CALL_QUEUE, () => {}, () => {}, 'call_queues'),

    readCallQueueMembers: generateReadOneActionCreator(routes.API_PBX_CONFIG_CALL_QUEUE, ACTION_NAMES.CALL_QUEUE_MEMBERS, 'members'),
    addCallQueueMembers: generateCreateActionCreator(routes.API_PBX_CONFIG_CALL_QUEUE, ACTION_NAMES.CALL_QUEUE_MEMBERS, () => {}, () => {}, 'members'),
    updateCallQueueMember: generateUpdateActionCreator(routes.API_PBX_CONFIG_CALL_QUEUE, ACTION_NAMES.CALL_QUEUE_MEMBERS, () => {}, () => {}, 'members'),
    deleteCallQueueMember: generateDeleteActionCreator(routes.API_PBX_CONFIG_CALL_QUEUE, ACTION_NAMES.CALL_QUEUE_MEMBERS, () => {}, () => {}, 'members'),

    deleteCallQueueItem: generateDeleteActionCreator(routes.API_PBX_CONFIG_CALL_QUEUE, ACTION_NAMES.CALL_QUEUE, () => {}, () => {}),
    addCallQueue: generateCreateActionCreator(routes.API_PBX_CONFIG_CALL_QUEUE, ACTION_NAMES.CALL_QUEUE, () => {}, () => {}),
    updateCallQue: generateUpdateActionCreator(routes.API_PBX_CONFIG_CALL_QUEUE, ACTION_NAMES.CALL_QUEUE, () => {}, () => {}),
    getCallQueue: generateReadOneActionCreator(routes.API_PBX_CONFIG_CALL_QUEUE, ACTION_NAMES.CALL_QUEUE),


}
