import React from 'react';
import { ClippedContent, CollapsibleList, EllipsisDropdown, Icon, Notification, STATUS_TYPES, Table } from 'dyl-components';
import { StringUtils } from 'utils';
import { Dropdown, Header } from 'semantic-ui-react';
import ControlledPopup from 'dyl-components/molecules/ControlledPopup';
import { useDispatch } from 'react-redux';
import customFieldActions from 'actions/custom_field';

const DropdownItem = ({ text, icon, displaysPopup, popupProps, ...otherProps }) => {
    const DropdownModel = () => {
        return (
            <Dropdown.Item text={<React.Fragment>
                <Icon name={icon} />
                {text}
            </React.Fragment>}
                style={{ cursor: 'pointer' }}
                {...otherProps}
            />
        );
    }

    if (displaysPopup === true) {
        return (
            <ControlledPopup
                header={
                    <Header as='h4' textAlign='center'>
                        {popupProps.header}
                        <Header.Subheader style={{ marginTop: 5 }}>
                            {popupProps.subheader}
                        </Header.Subheader>
                    </Header>
                }
                trigger={
                    DropdownModel()
                }
                onConfirm={popupProps.onConfirm}
            />
        )
    } else {
        return (
            <DropdownModel />
        );
    }
}


const FieldEntries = ({ field, editable, onEditField, standard, parent_group, hasDependency, refresh, isParentArchived }) => {
    const dispatch = useDispatch();

    const isArchived = isParentArchived || field.deleted;

    const generateFieldEllipsisOptions = (field_id) => {
        return [
            ...(!isArchived ? [{
                id: 1,
                text: 'Edit',
                icon: 'fas fa-edit',
                onClick: () => {
                    onEditField({
                        ...field,
                        standard,
                        parent_group,
                        field_type: hasDependency ? 'dependency' : field.field_type
                    })
                }
            }] : !isParentArchived ? [{
                id: 1,
                text: 'Restore',
                icon: 'fas fa-trash-can-undo',
                onClick: async () => {
                    try {
                        await dispatch(customFieldActions.reactivateField(field_id));
                        Notification.alert('Successfully restored custom field!', STATUS_TYPES.SUCCESS);
                        refresh();
                    } catch (e) {
                        console.log(e);
                        Notification.alert('Failed to restore custom field', STATUS_TYPES.ERROR);
                    }
                }
            }] : []),
            ...(standard ? [] : [
                {
                    id: 2,
                    text: 'Delete',
                    icon: 'fas fa-trash-alt',
                    displaysPopup: true,
                    popupProps: {
                        header: 'Are you sure?',
                        onConfirm: async () => {
                            try {
                                await dispatch(customFieldActions.deleteField(parent_group, null, field_id));
                                Notification.alert('Successfully deleted custom field!', STATUS_TYPES.SUCCESS);
                                refresh();
                            } catch (e) {
                                console.log(e);
                                Notification.alert('Failed to delete custom field', STATUS_TYPES.ERROR);
                            }
                        }
                    }
                },
                ...(!isArchived ? [{
                    id: 3,
                    text: 'Archive',
                    icon: 'fas fa-archive',
                    displaysPopup: true,
                    popupProps: {
                        header: 'Are you sure?',
                        onConfirm: async () => {
                            try {
                                await dispatch(customFieldActions.deleteField(parent_group, { archive: true }, field_id));
                                Notification.alert('Successfully archived custom field!', STATUS_TYPES.SUCCESS);
                                refresh();
                            } catch (e) {
                                console.log(e);
                                Notification.alert('Failed to archive custom field', STATUS_TYPES.ERROR);
                            }
                        }
                    }
                }] : [])
            ])
        ]
    }

    const field_type = (() => {
        if (field.field_type === 'url') {
            return field.field_type?.toUpperCase();
        }
        if (field.field_name === 'total_spent') {
            return 'Roll-up'
        }
        return StringUtils.capitalize((
            field.field_type === 'dependency' ? 'dropdown (dependency)' : field.field_type
        ));
    })()

    return [
        <Table.Cell disabled={isArchived}>
            <ClippedContent>
                {field.label} {field.required && <Icon className='fas fa-asterisk' color='red' size='small' />}
            </ClippedContent>
        </Table.Cell>,
        <Table.Cell disabled={isArchived}>
            <ClippedContent>
                {field_type}
            </ClippedContent>
        </Table.Cell>,
        <Table.Cell disabled={isArchived}>
            <CollapsibleList
                disabled={isArchived}
                minimumNumberOfItemsToShow={3}
                items={field.options?.data?.map(({ value }) => value) || []}
            />
        </Table.Cell>,
        <Table.Cell disabled={isArchived}>
            <ClippedContent>
                {field.description}
            </ClippedContent>
        </Table.Cell>,
        <Table.Cell>

        </Table.Cell>,
        <Table.Cell>

        </Table.Cell>,
        <Table.Cell>
            {editable && (
                <EllipsisDropdown>
                    {generateFieldEllipsisOptions(field.id).map(({ text, icon, displaysPopup, popupProps, ...otherProps }, idx) => {
                        return (
                            <DropdownItem
                                key={idx}
                                text={text}
                                icon={icon}
                                displaysPopup={displaysPopup}
                                popupProps={popupProps}
                                {...otherProps}
                            />
                        );
                    })}
                </EllipsisDropdown>
            )}
        </Table.Cell>
    ];
};

export default FieldEntries;
