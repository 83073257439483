import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { PhoneUtil } from 'utils';

const UserDestination = ({ label, user_id, username, icon, type }) => {
    const hasUser = !!user_id && !!username
    return (
        <div>
            <Icon className={icon} style={{marginRight: 5}}/>
            {hasUser ? (
                <>
                    <Link to={`/settings/users/${user_id}/${type === "direct_extension" ? "general" : "integration/phone"}`}>{username}</Link>
                    <div>
                        {label}
                    </div>
                </>
            ) : label}
        </div>
    )
}

const OtherDestination = ({ label, icon, url }) => {
    return (
        <>
            {!!icon && <Icon className={icon} style={{marginRight: 5}}/>}
            {!!url && <Link to={url}>{label}</Link>}
        </>
    )
}

const Destinations = ({ destination = {} }) => {
    const { label, type, user_id, username } = destination;

    if (type === "direct_extension" || type === "personal_voicemail") {
        return <UserDestination 
            label={label} 
            user_id={user_id} 
            username={username} 
            icon={PhoneUtil.DESTINATION_TYPES_ICONS[type]}
            type={type}
        />
    }

    return <OtherDestination label={label} icon={PhoneUtil.DESTINATION_TYPES_ICONS[type]} url={PhoneUtil.DESTINATION_TYPES_URLS[type]} />;
}

export default Destinations;