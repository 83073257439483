import routes from "actions/routes";
import { generateReadOneActionCreator, generateUpdateActionCreator, generateCreateActionCreator, generateDeleteActionCreator } from "actions/crudActionCreators";
import ACTION_NAMES from "actions/ACTION_NAMES";

export default {
    getContactSocials: generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_SOCIAL_MEDIA, 'social_media'),
    addContactSocial: generateCreateActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_SOCIAL_MEDIA, () => { }, () => { }, 'social_media'),
    updateContactSocial: generateUpdateActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_SOCIAL_MEDIA, () => { }, () => { }, 'social_media'),
    deleteContactSocial: generateDeleteActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_SOCIAL_MEDIA, () => { }, () => { }, 'social_media')
}
