import React, { useState, useContext } from 'react';
import { Table as SemanticTable, Checkbox, Popup } from 'semantic-ui-react';
import { Table, ClearableCheckboxDropdown, TimestampFilters } from 'dyl-components';
import SortActions from './SortActions'
import { getData, onToggleTimeUnit, useRangeCalendar } from 'shared/CustomDateRange/customDateRange';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BulkActionsContext } from "shared/context/BulkActionsProvider";
import { useSelector } from 'react-redux';

const GroupsHeader = ({onChangeFilter, selectedShared, setIsHeaderChecked, getSubgroups, setLoadingState }) => {
    const [sortDirection, setSortDirection] = useState(true);
    const [selectedGroups, setSelectedGroups] = useContext(BulkActionsContext);
    const [params] = useSearchParams();
    const search = params.get("search") || "";
    const navigate = useNavigate();
    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        userAuthTimezone,
        isTimeRangeOpen,
        setTimeRangeOpen,
        dateRange,
        setDateRange
    } = useRangeCalendar(params);

    const { groups } = useSelector((state) => ({
        groups: state.groups.groups,
    }));

    const isSelected = (group_id) => {
        return selectedGroups.some((group) => group.group_id === group_id);
    }

    const onToggleAllGroups = async () => {
        if (selectedGroups.length > 0) {
            setSelectedGroups([]);
            setIsHeaderChecked(false);
        } else {
            if (!search) {
                setLoadingState(true);
                const promises = []
                groups.forEach(async (group) => {
                    if (group.sub_labels) {
                        promises.push(getSubgroups({parent_label_id: group.id}));
                    }
                });
                await Promise.all(promises)
                setIsHeaderChecked(true);
            } else {
                const newSelectedGroups = groups.some(group => isSelected(group.id)) ? [] : groups.map(group => ({group_id: group.id, parent_label_id: group.parent_label_id}));
                setSelectedGroups(newSelectedGroups);
            }
        }
    }
    
    const areAllGroupsSelected = selectedGroups.length > 0 && groups.every(group => isSelected(group.id));
    const areGroupsPartiallySelected = selectedGroups.length > 0 && !areAllGroupsSelected;
    const numberOfSelectedGroups = selectedGroups.length;

    const onDateRangeChange = (_, { value }) => {
        setDateRange(value);
    };

    const sortFilter = (order_by) => {
        setSortDirection(!sortDirection);
        const query = new URLSearchParams(params);
        const order = !sortDirection ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", order_by);
        const query_string = query.toString();
        navigate(`/groups${query_string ? `?${query_string}` : ""}`);
    };

    return (
        <SemanticTable.Header className='Table__header--primary Table__header--checkbox'>
            <Table.Row>
                <SemanticTable.HeaderCell width={1}>
                    <Popup
                        {...areAllGroupsSelected ? { style: { zIndex: 9 } } : {}}
                        position="bottom right"
                        open={areAllGroupsSelected}
                        trigger={<Checkbox checked={areAllGroupsSelected} onClick={onToggleAllGroups} indeterminate={areGroupsPartiallySelected} />}
                        content={<div> All {`${numberOfSelectedGroups} groups on this page`} selected. </div>}
                    />
                </SemanticTable.HeaderCell>
                {search === "" ? (<SemanticTable.HeaderCell width={1} />) : (null)}
                <SemanticTable.HeaderCell>
                    Group Name
                    <SortActions
                        direction={sortDirection}
                        onToggleSort={() => {
                            sortFilter("name");
                        }}
                    />
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell>
                    Contains
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell>
                    <ClearableCheckboxDropdown
                        text="Shared"
                        name="shared_type"
                        options={[
                            {
                                key: "personal",
                                value: "personal",
                                text: "Personal",
                            },
                            {
                                key: "company",
                                value: "company",
                                text: "Company",
                            },
                        ]}
                        value={selectedShared}
                        onChange={onChangeFilter}
                        closeOnLoad={true}
                    />
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell>
                    Created By
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell>
                    <span>Created </span>
                    <TimestampFilters
                        timestamp={timestamp}
                        onToggleTimeUnit={(timestamp) =>
                            onToggleTimeUnit(
                                timestamp,
                                setTimestamp,
                                params,
                                navigate,
                                "/groups"
                            )
                        }
                        isCustomDateRangeOpen={isCustomDateRangeOpen}
                        onOpenCustomDateRange={() => {
                            setCustomDateRangeOpen(true);
                        }}
                        onCloseCustomDateRange={() => {
                            setCustomDateRangeOpen(false);
                        }}
                        onOpenTimeUnitOptions={() => {
                            setTimeRangeOpen(true);
                        }}
                        onCloseTimeUnitOptions={() => {
                            setTimeRangeOpen(false);
                        }}
                        areTimeUnitOptionsOpen={isTimeRangeOpen}
                        dateRange={dateRange}
                        onDateRangeChange={onDateRangeChange}
                        getData={() =>
                            getData({
                                setCustomDateRangeOpen,
                                dateRange,
                                userAuthTimezone,
                                params,
                                navigate,
                                url: "/groups",
                            })
                        }
                    />
                    <SortActions
                        direction={sortDirection}
                        onToggleSort={() => {
                            sortFilter("created");
                        }}
                    />
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell>
                    Last Modified
                    <SortActions
                        direction={sortDirection}
                        onToggleSort={() => {
                            sortFilter("activity");
                        }}
                    />
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell>
                    Actions
                </SemanticTable.HeaderCell>
            </Table.Row>
        </SemanticTable.Header>
    )
}

export default GroupsHeader;