import React, { useEffect } from "react";
import { useConfirm } from "./useConfirm";
import { Modal, ConfirmationPrompt } from "dyl-components";
import { Modal as SemanticModal } from "semantic-ui-react"

import './index.scss';

const ConfirmModal = () => {
    const {
        header = '',
        subheader = '',
        isOpen = false,
        proceed,
        cancel
    } = useConfirm();

    useEffect(() => {
        const handleKeydown = (e) => {
            if (proceed && isOpen && e.key === "Enter") {
                proceed();
            }
        };

        window.addEventListener("keydown", handleKeydown);
        return () => window.removeEventListener("keydown", handleKeydown);
    }, [proceed, isOpen]);

    return (
        <SemanticModal className='ConfirmModal' size='mini' open={isOpen} onClose={cancel}>
            <Modal.Content>
                <ConfirmationPrompt header={header} subheader={subheader} onCancel={cancel} onConfirm={proceed} />
            </Modal.Content>
        </SemanticModal>
    );
};

export default ConfirmModal;
