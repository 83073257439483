const MARK_OPPORTUNITY = 'MARK_OPPORTUNITY';
const MARK_ALL_OPPORTUNITIES = 'MARK_ALL_OPPORTUNITIES';

const UPDATE_OPPORTUNITY_ASSIGNMENT = 'UPDATE_OPPORTUNITY_ASSIGNMENT'

export default {
    MARK_OPPORTUNITY,
    MARK_ALL_OPPORTUNITIES,
    UPDATE_OPPORTUNITY_ASSIGNMENT,
    MARK_OPPORTUNITIES_IN_EVERY_PAGE: 'MARK_OPPORTUNITIES_IN_EVERY_PAGE'
};
