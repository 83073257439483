import React, { useEffect, useState } from 'react';
import { Segment, Header, Pagination } from 'semantic-ui-react';
import { TableWithHeader } from 'dyl-components';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { DataProvidersTable } from './subcomponents/DataProvidersTable';
import { DataProvidersToolbar } from './subcomponents/DataProvidersToolbar';

import leadProviderActions from 'actions/lead_provider';

import { QueryParamsUtils } from 'utils';

import './index.scss';

const LIMIT = 25;

const DataProviders = ({ isReading, providers_count, onReadProviders, onReadSecondarySources }) => {
    const [params] = useSearchParams();

    const searchQuery = params.get('search') || '';
    const [search, setSearch] = useState(searchQuery);

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }

    const navigate = useNavigate();

    useEffect(() => {
        onReadProviders(Object.fromEntries(params));
        const master_source_filter = params.get('master_source') || '';
        onReadSecondarySources({ master_source: master_source_filter.split(',').filter(master_source => master_source !== 'uncategorized') })
    }, [onReadProviders, params, onReadSecondarySources]);

    const [filters, setFilters] = useState({
        category: params.get('category')?.split(','),
        master_source: params.get('master_source')?.split(','),
        secondary_source_id: params.get('secondary_source_id')?.split(',')
    })

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value,
            ...(name === 'master_source' ? { secondary_source_id: [] } : {})
        });
    }

    useEffect(() => {
        const query = new URLSearchParams(params);
        query.set('page', 1);
        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }
        const { category, master_source, secondary_source_id } = filters;
        if (category?.length > 0) {
            query.set('category', category.join(','));
        } else {
            query.delete('category');
        }
        if (master_source?.length > 0) {
            query.set('master_source', master_source.join(','));
        } else {
            query.delete('master_source');
        }
        if (secondary_source_id?.length > 0) {
            query.set('secondary_source_id', secondary_source_id.join(','));
        } else {
            query.delete('secondary_source_id');
        }
        const query_string = query.toString();
        navigate(`/settings/data-providers${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`/settings/data-providers${query_string ? `?${query_string}` : ''}`);
    }

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`/settings/data-providers${query_string ? `?${query_string}` : ''}`);
    }

    return (
        <div className='DataProvidersPanel'>
            <TableWithHeader
                header={(
                    <DataProvidersToolbar
                        search={searchQuery}
                        isSearching={isReading}
                        onSearch={onSearchSubmit}
                        setSearch={setSearch}
                        onChangeSearch={onChangeSearch}
                    />
                )}
                table={(
                    <Segment.Group>
                        <Segment style={{ padding: 0 }}>
                            <DataProvidersTable onFilter={onFilter} isReadingDataProviders={isReading} search={search} limit={LIMIT} />
                        </Segment>

                        {!isReading && providers_count === 0 &&
                            <Segment>
                                {!QueryParamsUtils.isUsingFilters({ params, isUsingPage: true }) ? (
                                    <Header as='h3' textAlign='center' style={{ marginTop: 30 }}>
                                        No Data Providers Created
                                        <Header.Subheader>
                                            Add a Provider by clicking on the <Link to='/settings/data-providers/create'>
                                                + New Provider
                                            </Link> button on the top-right corner.
                                        </Header.Subheader>
                                    </Header>
                                ) :
                                    <Header as='h3'>
                                        No Results Found.
                                    </Header>
                                }
                            </Segment>
                        }
                        {providers_count !== 0 &&
                            <Segment textAlign='right'>
                                <Pagination
                                    boundaryRange={0}
                                    activePage={params.get('page') || 1}
                                    ellipsisItem={null}
                                    siblingRange={2}
                                    totalPages={Math.ceil(providers_count / LIMIT)}
                                    onPageChange={onPageChange}
                                    disabled={isReading}
                                />
                            </Segment>
                        }
                    </Segment.Group>
                )}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    isReading: state.lead_provider.isReadingProviders,
    providers_count: state.lead_provider.providers_count
});

const mapDispatchToProps = dispatch => ({
    onReadProviders: (queryParameters) => {
        dispatch(leadProviderActions.readProviders(queryParameters));
    },
    onReadSecondarySources: (queryParameters) => {
        dispatch(leadProviderActions.readSecondarySources(queryParameters));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(DataProviders);
