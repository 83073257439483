import DOMPurify from 'dompurify';
export default class StringUtils {
    static formatLink(link, secure = true) {
        if (link) {
            if (!/(http(s?)):\/\//i.test(link)) {
                return `http${secure ? 's' : ''}://${link}`
            }
            return link;
        }
        return "";
    }

    static capitalize(string) {
        if (string) {
            return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
        }
        return '';
    }

    static toUpperCase(string) {
        return string.toUpperCase();
    }

    static toSentenceCase(string) {
        return (string || '').split(' ').map(word => StringUtils.capitalize(word.toLowerCase())).join(' ');
    }

    static generateRandomString(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result = `${result}${characters.charAt(Math.floor(Math.random() * charactersLength))}`;
        }
        return result;
    }

    static isValidCurrency(value) {
        return `${value}`.match(/^\$?([0-9]{1,3},?([0-9]{3},?)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/);
    }

    static generateRandomId = (numberOfGroups = 1) => {
        const id = [];
        for (let i = 0; i < numberOfGroups; i++) {
            id.push(Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1));
        }
        return id.join('-');
    }

    static isEmpty = (string) => {
        return (!string || /^\s*$/.test(string));
    }

    static removeHTML = (html) => {
        return html.replace(/<(.|\n)*?>/g, ' ');
    }

    static copy = (text) => {
        return (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(text);
        };
    }

    static escapeRegExp = query => {
        return query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    static sanitizeAppendedValue = value => {
        return value.replace(/^\d-/,"");
    }

    static sanitizeString = value => {
        return value.replace(/["]/g, "").replace(/[:]/g, "");
        //.replace(/.\d.*$/,""), remove all numbers
    }

    static formatDecimal = (value, displayTrailingZeroes = false) => {
        const decimalPointPosition = value?.indexOf(".");
        const isNotValidNumber = value === "" || isNaN(Number(value));
        const result = isNotValidNumber ? value : decimalPointPosition >= 0 ? (value.split('.')[1] ? Number(value.slice(0, decimalPointPosition + 3)) : value.slice(0, decimalPointPosition + 3)) : Number(value);
        return isNotValidNumber ? result : displayTrailingZeroes ? result.toFixed(2) : result; 
    }

    static sanitizeCurrency = value => {
        return value.replace(/[$]/g, "").replace(/[,]/g, "");
    }

    static sanitizeTerminology = value => {
        if(Array.isArray(value)){
            return value.map(term => this.replaceTerminology(term));
        }
        return this.replaceTerminology(value);
    }

    static replaceTerminology = value => {
        return value.replace("birth", "date of birth").replace("birthdate", "date of birth").replace("zip", "zip code").replace("full name", "first last name").replace("cell", "mobile").replace("telephone number", "mobile")
            .replace("name", "first last name").replace("address", "city, state zip");
    }

    static formatSearch = value => {
        const splitValueUnderscore = value.split('_');
        const splitValueSpace = value.split(' ');
        if(splitValueUnderscore.length > 1 ){
            splitValueUnderscore.push(value.replace(/[_]/g, " "))
            return splitValueUnderscore;
        }
        if(splitValueSpace.length > 1 ){
            splitValueSpace.push(value)
            return splitValueSpace;
        }
        return value;
    }

    static CleanHTML = ({children, className = "", collapsed}) => collapsed
        ? StringUtils.removeHTML(children)
        : <span className={className} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(children) }} />;

    
}
