import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Pagination, Header } from 'semantic-ui-react';
import { generateResolver, Modal, Notification, STATUS_TYPES, VALIDATORS, Button } from 'dyl-components';
import { useNavigate, useSearchParams } from "react-router-dom";

import teamActions from 'actions/team';
import teamsActions from 'actions/teams';

import TeamOptions from './subcomponents/TeamOptions';
import TeamsTable from './subcomponents/TeamsTable';

import TeamForm from 'shared/forms/TeamForm';

import './index.scss';
import { useForm } from 'react-hook-form';

const UserTeams = ({
    onReadTeams,
    onDelete,

    userTeams = [],
    count,
    isSaving,

    onUpdate,
    onAddUsersToTeam,
    onRemoveUserFromTeam
}) => {
    const [query] = useSearchParams();

    const team_name = query.get('search') || '';
    const page = query.get('page');
    const limit = 25;

    useEffect(() => {
        onReadTeams({ team_name, page, limit });
    }, [onReadTeams, team_name, page, limit]);

    const totalPages = Math.ceil(count/(limit));

    const navigate = useNavigate();

    const [expandedTeams, setExpandedTeams] = useState([]);
    const [teamToRemove, setTeamToRemove] = useState(null);
    const [teamToEdit, setTeamToEdit] = useState(null);

    const teamBeingEdited = userTeams.find(({ id }) => id === teamToEdit) || [];

    const { control, formState: { isValid, isDirty }, getValues, reset, setError } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: teamBeingEdited.name || ''
        },
        resolver: generateResolver({
            name: VALIDATORS.TEAM_NAME(true).required('This field is required').trim()
        })
    });


    const onSetError = (name, { type, message }) => {
        setError(name, { type, message });
    }

    const onReset = (payload) => {
        reset(payload);
    }

    const onToggleMembers = (id) => {
        if (expandedTeams.includes(id)) {
            setExpandedTeams(expandedTeams.filter(team => team !== id));
        } else {
            setExpandedTeams([...expandedTeams, id]);
        }
    }

    const onAttemptToDelete = (id) => {
        const toRemove = id === teamToRemove ? null : id;
        setTeamToRemove(toRemove);
    }

    const onDeleteTeam = async (id) => {
        try {
            setTeamToRemove(null);
            await onDelete(id);
            Notification.alert('Successfully deleted team!', STATUS_TYPES.SUCCESS);
            const isNowEmpty = userTeams.length === 0;
            if (!isNowEmpty) {
                onReadTeams({ page, limit, team_name });
            } else {
                const query = new URLSearchParams();
                if (page > 1) {
                    query.append('page', page - 1);
                    query.append('search', team_name);
                }
                const query_string = query.toString();
                navigate(`/settings/teams${query_string ? `?${query_string}` : ''}`);
            }
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to delete team', STATUS_TYPES.ERROR);
        }
    }

    const onEditTeam = (id) => {
        setTeamToEdit(id);
        reset({
            name: teamBeingEdited.name
        });
    }

    const onCancelEdit = () => {
        setTeamToEdit(null);
    }

    const onSaveEdit = async (payload, _, keepEditing, setError) => {
        const { users } = teamBeingEdited;
        const team_id = teamToEdit;
        const { name, show_in_office_view } = payload;
        const newLlistOfUsers = payload.users.slice(0);
        const currentUsersInTheTeam = users.map(({ id }) => id);
        const usersToRemove = currentUsersInTheTeam.filter(id => !newLlistOfUsers.includes(id));
        const usersToAdd = newLlistOfUsers.filter(id => !currentUsersInTheTeam.includes(id));

        try {
            await onUpdate(team_id, {
                name: name.trim(),
                hide_view: !show_in_office_view
            });
            await onAddUsersToTeam(team_id, usersToAdd.map(user_id => ({ user_id })));
            await Promise.all(usersToRemove.map(user_id => onRemoveUserFromTeam(team_id, user_id)))
            Notification.alert('Successfully updated team!', STATUS_TYPES.SUCCESS);
            if (!keepEditing) {
                setTeamToEdit(null);
            }
            if (team_name === name.trim()) {
                onReadTeams({ team_name });
            } else {
                navigate(`/settings/teams?search=${name.trim()}`);
            }
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.error === "unique_violation") {
                if (setError) {
                    setError("name", { type: "unique", message: "Team name already exists!" });
                }
            }
            Notification.alert('Failed to update team', STATUS_TYPES.ERROR);
        }
    }

    return (
        <div className='UserTeamsPanel'>
            <TeamOptions />
            <TeamsTable
                userTeams={userTeams}
                toggleShowMembers={onToggleMembers}
                toggleTeamDelete={onAttemptToDelete}
                onDeleteTeam={onDeleteTeam}
                onEdit={onEditTeam}
                teamToDelete={teamToRemove}
                expandedTeams={expandedTeams}
            />
            {totalPages !== 0 &&
                <div className="Pagination">
                    <Pagination
                        boundaryRange={0}
                        activePage={page || 1}
                        ellipsisItem={null}
                        siblingRange={2}
                        totalPages={totalPages}
                        onPageChange={(_, { activePage }) => {
                            navigate(`/settings/teams?${new URLSearchParams({
                                page: activePage,
                                ...(team_name? { search: team_name } : {})
                            }).toString()}`);
                        }}
                    />
                </div>
            }
            {totalPages === 0 && !isSaving &&
                <Header as='h3'>No Results Found</Header>
            }
            {teamToEdit && (
                <Modal
                    size='large'
                    onClose={onCancelEdit}
                    open={teamToEdit}
                >
                    <Modal.Header>
                        Edit Team: {teamBeingEdited.name}
                    </Modal.Header>
                    <Modal.Content>
                        <TeamForm
                            control={control}
                            isSaving={isSaving}
                            name={teamBeingEdited.name}
                            show_in_office_view={!teamBeingEdited.hide_view}
                            users={teamBeingEdited.users.map(({ id }) => id)}
                            selected_users_details={teamBeingEdited.users.map(({ first_name, last_name, id, email }) => {
                                const name = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : "Unknown Name"
                                return ({
                                    user_id: id,
                                    name,
                                    email
                                });
                            })}
                        />
                    </Modal.Content>
                    <Modal.Actions
                        hasSaveButton
                        disabled={!isValid || !isDirty}
                        onSave={() => { onSaveEdit(getValues(), onReset, false, onSetError); }}
                        primaryChildren={[
                            <Button disabled={!isValid || !isDirty} onClick={() => { onSaveEdit(getValues(), onReset, true, onSetError); }} primary>
                                Save and Keep Editing
                            </Button>,
                        ]}
                    />
                </Modal>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    userTeams: state.teams.teams.map(team => ({ ...team, users: team.users.data || [] })),
    isSaving: state.team.teamBeingUpdated || state.team.teamWhereUsersAreBeingAdded || state.teams.isReadingTeams,
    count: state.teams.count
});

const mapDispatchToProps = dispatch => ({
    onDelete: (id) => {
        return dispatch(teamActions.deleteTeam(id));
    },
    onReadTeams: (queryParameters) => {
        return dispatch(teamsActions.getTeams(queryParameters));
    },

    onUpdate: (id, payload) => {
        return dispatch(teamActions.updateTeam(id, payload));
    },
    onRemoveUserFromTeam: (team_id, user_id) => {
        return dispatch(teamActions.deleteUser(team_id, { user_id }));
    },
    onAddUsersToTeam: (team_id, users) => {
        return dispatch(teamActions.addUsers(users, null, team_id));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserTeams);
