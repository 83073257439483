import React from "react";
import {
    Table,
} from "dyl-components";
import { Table as SemanticTable } from "semantic-ui-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SelectAllCheckbox from "shared/SelectAllCheckbox";
import SortActions from "./SortActions";
import { useSelector } from "react-redux";

const LIST_VIEW_URL = "/settings/phone-management/extensions/users"

const UserExtensionsHeader = () => {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const { userExtensions, userExtensionsCount } = useSelector((state) => state.pbx_config);

    const extensionOrder = params.get("order_by") === "extension" && params.get("order") === "asc";
    const userOrder = params.get("order_by") === "user" && params.get("order") === "asc";

    const extensionFilter = () => {
        const query = new URLSearchParams(params);
        const order = !extensionOrder ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", "extension");
        const query_string = query.toString();
        navigate(`${LIST_VIEW_URL}${query_string ? `?${query_string}` : ""}`);
    };

    const userFilter = () => {
        const query = new URLSearchParams(params);
        const order = !userOrder ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", "user");
        const query_string = query.toString();
        navigate(`${LIST_VIEW_URL}${query_string ? `?${query_string}` : ""}`);
    };

    return (
        <SemanticTable.Header className='Table__header--primary Table__header--checkbox'>
            <Table.Row>
                <SemanticTable.HeaderCell
                    width={1}
                >
                    <SelectAllCheckbox 
                        count={userExtensionsCount || 0}
                        data={userExtensions || []}
                        type={"User Extensions"}
                        idProperty={"id"}
                    />
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell width={1} />
                <SemanticTable.HeaderCell width={2}>
                    <span>Extension</span>
                    <SortActions
                        direction={extensionOrder}
                        onToggleSort={extensionFilter}
                    />
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell
                    width={3}
                >
                    <span>User</span>
                    <SortActions
                        direction={userOrder}
                        onToggleSort={userFilter}
                    />
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell
                    width={3}
                >
                    Number
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell
                    width={3}
                >
                    Teams
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell
                    width={2}
                >
                    Voicemail Box
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell
                    width={2}
                >
                    Voicemail
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell
                    width={2}
                >
                    PIN
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell 
                    width={3}
                >
                    Actions
                </SemanticTable.HeaderCell>
            </Table.Row>
        </SemanticTable.Header>
    );
};

export default UserExtensionsHeader;
