import { Notification, STATUS_TYPES } from 'dyl-components';

import routes from 'actions/routes';
import { generateReadActionCreator, generateCreateActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

const onCreateSuccess = () => {
  Notification.alert('Successfully added tag!', STATUS_TYPES.SUCCESS, true);
}

const onCreateFailure = () => {
  Notification.alert('Failed to add tag', STATUS_TYPES.ERROR, true);
}

const getListOfTags = generateReadActionCreator(routes.API_TAGS, ACTION_NAMES.TAGS);
const addTag = generateCreateActionCreator(routes.API_TAGS, ACTION_NAMES.TAGS, onCreateSuccess, onCreateFailure);

export default {
  getListOfTags,
  addTag
};
