import React, { useEffect } from 'react';
import { Notification, STATUS_TYPES, generateResolver, yup, DateInput, DateTimeUtils } from 'dyl-components';
import { Button, Form, Segment } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomGroupUtils } from 'utils';

import { Controller, useForm } from 'react-hook-form';

import customFieldsGroupActions from "actions/custom_fields_group";
import pipelineActions from 'actions/pipeline';
import MasterSecondarySourceOptions from './MasterSecondarySourceOptions';
import campaignsAction from "actions/campaigns";

import sequenceTasksActions from 'actions/sequence_tasks';
import contactSequenceActions from 'actions/contact_sequence';
import sequencesActions from 'actions/sequences';

const ConvertToOpportunityForm = ({
    onClose,

    account_id,
    contact_id,
    account_stage,

    size,
    refresh,

    onModify,

    master_source,
    secondary_source
}) => {
    const { close_reason_id, group, isCreating, isReadingCategories, isReadingCustomFields, pipelineCategories, campaignConvertedOptions, isReadingSequenceContactTasks, contactSequenceTasks, selectedSequenceId } = useSelector(state => {
        return {
            isCreating: state.pipeline.isConvertingContact || state.contact_sequence.isHandlingConvert || state.sequences.isAddingContactToSequence,
            isReadingCustomFields: state.custom_fields_group.isReadingModalStandardFieldGroup,
            group: state.custom_fields_group.modal_standard_group,
    
            pipelineCategories: state.pipeline.categories.map(category => ({
                key: category.id,
                value: category.id,
                text: category.name,
                stages: category.stages?.map(stage => ({
                    key: stage.id,
                    value: stage.id,
                    text: stage.name,
                    sequence_id: stage.sequence_id
                })) || []
            })),
            isReadingCategories: state.pipeline.isReadingCategories,
    
            close_reason_id: state.contact.opportunity?.close_reason?.id,
            campaignConvertedOptions: state.campaigns.campaignConvertedOptions,

            contactSequenceTasks: state.sequence_tasks.selectedSequenceTasks,
            isReadingSequenceContactTasks: state.sequence_tasks.isReadingSequenceContactTasks,
            selectedSequenceId: state.sequence_tasks.selectedSequenceId
        };
    })

    const fields = group?.fields?.data || [];

    const { formState: { isDirty, isValid }, control, setValue, handleSubmit, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            opportunity_name: '',
            pipeline: null,
            stage: null,

            type: null,
            description: '',
            pain_need_notes: '',

            sales_strategy_notes: '',
            confidence_level: null,
            close_probability: '',

            forecast_amount: null,
            expected_close_date: '',
            master_source: master_source || null,
            secondary_source: secondary_source || null,

            opportunity_custom_data: [],
        },
        resolver: generateResolver({
            opportunity_name: yup.string().minlength(2).no_whitespace_only().required('This field is required'),
            pipeline: yup.string().required('This field is required'),
            stage: yup.string().required('This field is required'),
            expected_close_date: yup.string().test("valid_date", "Invalid date", (value => {
                return value ? DateTimeUtils.isValid(value, DateTimeUtils.DATE_FORMAT) : true;
            }))
        })
    });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(customFieldsGroupActions.getModalStandardGroup({ group_name: 'opportunity' }));
        dispatch(pipelineActions.getPipelineCategories({ account_stage: "opportunity" }));
        dispatch(campaignsAction.readPersonCampaignConverted({ person_id: contact_id, campaign_conversion: "opportunity" }));
        dispatch(sequenceTasksActions.readContactTasks(contact_id));
    }, [dispatch, contact_id]);

    useEffect(() => {
        onModify(isDirty);
    }, [isDirty, onModify]);

    const [watchedPipeline] = watch(['pipeline']);

    const onCreate = async (data) => {
        const custom_data = {
            fields: CustomGroupUtils.extractOpportunityDetails(data),
            children: {}
        };

        const { stage, opportunity_name, master_source, secondary_source, campaign_converted, pipeline } = data;

        const areSequenceTasksCompleted = contactSequenceTasks.length === 0 || contactSequenceTasks.every(task => task.status === 'complete' || task.status === 'canceled');
        const category = pipelineCategories.find(category => category.value === Number(pipeline));
        const newStage = category?.stages.find(categoryStage => categoryStage.value === Number(stage));

        try {
            const canApplyNewSequence = selectedSequenceId ? await dispatch(contactSequenceActions.handleConvert(contact_id)) : true;
            const { id } = await dispatch(pipelineActions.convertContact({
                account_id,
                contact_id,

                custom_data,
                pipeline_stage_id: Number(stage),
                close_reason_id,

                name: opportunity_name,
                master_source,
                campaign_id: campaign_converted,
                secondary_source_id: secondary_source,

                previous_sequence_complete: areSequenceTasksCompleted,
                sequence_conversion_removed: canApplyNewSequence
            }));
            
            if (canApplyNewSequence && newStage?.sequence_id) {
                await dispatch(sequencesActions.addToSequence({ contact_id }, null, newStage?.sequence_id));
            }
            Notification.alert('Successfully converted to opportunity!', STATUS_TYPES.SUCCESS);
            onClose();
            if (refresh) {
                refresh(id);
            }
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to convert to opportunity', STATUS_TYPES.ERROR);
        }
    }

    const hasLeadInformation = Boolean(master_source || secondary_source);

    const confidenceLevelField = <Controller
        name='confidence_level'
        control={control}
        render={({ field: { name, value, onChange } }) => (
            <Form.Select
                label='Confidence Level'
                search
                name={name}
                value={value}
                onChange={(_, { name, value }) => { onChange({ target: { name, value } }); }}
                options={CustomGroupUtils.getFieldOptions(fields, name)}
                selectOnBlur={false}
                placeholder='Select Confidence Level'
                width={8}
            />
        )} />;

    const expectedCloseDateField = <Controller
        name='expected_close_date'
        control={control}
        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <Form.Field
                label='Expected Close Date'
                name={name}
                value={value}
                control={DateInput}
                onChange={(_, { name, value }) => { onChange({ target: { name, value } }); }}
                placeholder='Select Expected Close Date'
                error={error?.message}
            />
        )} />;

    return [
        <Form loading={isCreating || isReadingSequenceContactTasks} size={size} noValidate style={{ maxHeight: "17em", width: "30em", overflowY: "scroll", overflowX: "hidden", paddingRight: "1em", marginBottom: "1em" }}>
            <Form.Group widths={'equal'}>
                <Controller
                    name='opportunity_name'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            label='Opportunity Name'
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Opportunity Name'
                            required
                            error={error?.message}
                        />
                    )}
                />
                <Controller
                    name='pipeline'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            label='Pipeline'
                            required
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => {
                                onChange({ target: { name, value } });
                                setValue('stage', pipelineCategories.find(category => category.value === value)?.stages[0]?.value || null, { shouldValidate: true });
                            }}
                            options={pipelineCategories}
                            search
                            selectOnBlur={false}
                            placeholder='Select Pipeline'
                            loading={isReadingCategories}
                        />
                    )}
                />
            </Form.Group>
            <Form.Group widths={'equal'}>
                <Controller
                    name='stage'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            label='Stage'
                            required
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            options={pipelineCategories.find(category => category.value === watchedPipeline)?.stages || []}
                            search
                            selectOnBlur={false}
                            placeholder='Select Stage'
                        />
                    )}
                />
                <Controller
                    name='type'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            label='Type'
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            options={CustomGroupUtils.getFieldOptions(fields, name)}
                            search
                            selectOnBlur={false}
                            placeholder='Select Type'
                        />
                    )}
                />
            </Form.Group>
            <Form.Group>
                {!hasLeadInformation && (
                    <Controller
                        name='master_source'
                        control={control}
                        render={({ field: { name: masterSourceFieldName, value: masterSourceFieldValue, onChange: masterSourceOnChange } }) => (
                            <Controller
                                name='secondary_source'
                                control={control}
                                render={({ field: { name: secondarySourceFieldName, value: secondarySourceValue, onChange: secondarySourceOnChange } }) => (
                                    <Form.Field
                                        control={MasterSecondarySourceOptions}
                                        onChange={(_, { parent_value: master_source, child_value: secondary_source }) => {
                                            masterSourceOnChange({ target: { name: masterSourceFieldName, value: master_source } });
                                            secondarySourceOnChange({ target: { name: secondarySourceFieldName, value: secondary_source } });
                                        }}
                                        value={secondarySourceValue}
                                        parentValue={masterSourceFieldValue}
                                        label='Master/Secondary Source'
                                        placeholder='Select Source'
                                        width={8}
                                    />
                                )}
                            />
                        )}
                    />
                )}
                <Controller
                    name='sales_strategy_notes'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Input
                            label='Sales Strategy'
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Sales Strategy'
                            width={8}
                        />
                    )}
                />
                {hasLeadInformation && (
                    confidenceLevelField
                )}
            </Form.Group>
            <Form.Group widths={'equal'}>
                {!hasLeadInformation && (
                    confidenceLevelField
                )}
                <Controller
                    name='close_probability'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Input
                            label='Close Probability'
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Close Probability'
                        />
                    )}
                />
                {hasLeadInformation && (
                    expectedCloseDateField
                )}
            </Form.Group>
            <Form.Group widths={2}>
                {!hasLeadInformation && (
                    expectedCloseDateField
                )}
                <Controller
                    name='forecast_amount'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Input
                            label='Forecast Amount'
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Forecast Amount'
                        />
                    )}
                />
            </Form.Group>
            <Form.Group widths={2}>
                <Controller
                    name={'campaign_converted'}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            fluid
                            label='Campaign Converted'
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            options={campaignConvertedOptions.map((campaign) => ({key: campaign.id, value: campaign.id, text: campaign.name}))}
                            search
                            selectOnBlur={false}
                            placeholder='Select Campaign'
                        />
                    )}
                />
            </Form.Group>
            <Controller
                name='description'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.TextArea
                        label='Description'
                        name={name}
                        value={value}
                        onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                        placeholder='Enter Description'
                    />
                )}
            />
            <Controller
                name='pain_need_notes'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.TextArea
                        label='Pain/Need Notes'
                        name={name}
                        value={value}
                        onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                        placeholder='Enter Pain/Need Notes'
                    />
                )}
            />
            <Segment basic style={{ padding: 0 }} loading={isReadingCustomFields}>

            </Segment>
        </Form>,
        <Button floated='right' disabled={!isDirty || !isValid || isCreating} primary onClick={handleSubmit(onCreate)} loading={isCreating}>Save</Button>
    ]
}

export default ConvertToOpportunityForm;
