import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function productAddonsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PRODUCT_ADDONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingAddons: true, addons: [], count: 0 };
        case getNamedAction(ACTION_NAMES.PRODUCT_ADDONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingAddons: false, addons: action.data?.data || [], count: action.data?.count || 0 };
        case getNamedAction(ACTION_NAMES.PRODUCT_ADDONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingAddons: false, addons: [], count: 0 };

        case getNamedAction(ACTION_NAMES.PRODUCT_ADDONS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreating: true };
        case getNamedAction(ACTION_NAMES.PRODUCT_ADDONS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreating: false };
        case getNamedAction(ACTION_NAMES.PRODUCT_ADDONS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreating: false };

        case getNamedAction(ACTION_NAMES.PRODUCT_ADDONS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, addonBeingRead: action.id, addon: null, addonError: false };
        case getNamedAction(ACTION_NAMES.PRODUCT_ADDONS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, addonBeingRead: null, addon: action.data, addonError: false };
        case getNamedAction(ACTION_NAMES.PRODUCT_ADDONS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, addonBeingRead: null, addon: null, addonError: true };

        case getNamedAction(ACTION_NAMES.PRODUCT_ADDONS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, addonBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.PRODUCT_ADDONS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, addonBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.PRODUCT_ADDONS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, addonBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.PRODUCT_ADDONS, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, addonBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.PRODUCT_ADDONS, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, addonBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.PRODUCT_ADDONS, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, addonBeingDeleted: null };
            
        default:
            return state;
    }
}

export default productAddonsReducer;
