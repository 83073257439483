import React from 'react';
import DateModificationToolbarContainer from 'shared/DateModificationToolbar';
import { Segment, Grid, Dropdown } from 'semantic-ui-react';
import LeadConnectTableContainer from './subcomponents/LeadConnectTable';

const LeadConnectToolbar = ({ onNumberOfEntriesChange }) => (
    <Segment basic style={{paddingTop: 0, paddingBottom: 0}}>
        <Grid stackable verticalAlign='middle'>
            <Grid.Row>
                <Grid.Column width={3}>
                    Show <Dropdown 
                        onChange={onNumberOfEntriesChange} 
                        options={[
                            {key: 10, text: 10, value: 10},
                            {key: 25, text: 25, value: 25},
                            {key: 50, text: 50, value: 50},
                            {key: 100, text: 100, value: 100}
                        ]}
                        defaultValue={10}
                    /> entries
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>
)

const LeadConnect = () => (
    <Segment basic>
        <DateModificationToolbarContainer />
        <Segment vertical>
            <LeadConnectToolbar />
            <LeadConnectTableContainer />
        </Segment>
    </Segment>
)

export default LeadConnect;
