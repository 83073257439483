import React from 'react';
import {Grid} from 'semantic-ui-react';

const CustomerMetrics = ({
     textAlign = 'right'
 }) => {
    return (
        <Grid className='RecordInfo'>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    NPS
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    None
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    CSAT
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    None
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    CES
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    None
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    CHS
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    None
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default CustomerMetrics;

