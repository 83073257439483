import React from 'react'; 
import { Button, Icon } from 'semantic-ui-react';
import { SearchBar } from 'dyl-components';
import '../index.scss';

const Toolbar = ({ search, onSearchSubmit, cancelFunction, onChangeSearch, onOpenModal }) => {
    return (
        <div style={{display: 'flex'}}>
            <div style={{flex: 1}}>
                <SearchBar
                    isSearching={false}
                    search={search}
                    searchFcn={onSearchSubmit}
                    cancelFcn={cancelFunction}
                    placeholder='Search by prompt name or extension'
                    addClassNames='IvrPrompts__search'
                    onChange={onChangeSearch}
                />
            </div>
            <div>
                <Button className='IvrPromptsToolbar__button' onClick={onOpenModal}>
                    <Icon name="plus" size='small' /> IVR Audio
                </Button>
            </div>
        </div>

    )
}

export default Toolbar;
