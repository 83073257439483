import { StyleSheet } from "@react-pdf/renderer";

const columnWidth = "12.5%";

export const styles = StyleSheet.create({
    page: { padding: 20 },
    header: {
        fontSize: 12,
        paddingBottom: 12,
        paddingTop: 12,
    },
    subheader: {
        fontSize: 8,
        paddingBottom: 3,
    },
    grid: {
        display: "flex",
        flexDirection: "row",
    },
    personInfo: {
        paddingRight: 100,
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
    },
    tableColHeader: {
        width: columnWidth,
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCol: {
        width: columnWidth,
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCellHeader: {
        fontSize: 8,
        fontWeight: 500,
        padding: 4,
    },
    tableCell: {
        fontSize: 6,
        padding: 4,
    },
    text: {
        fontSize: 8,
        marginBottom: 4,
    },
    textMedium: {
        fontSize: 10,
        paddingTop: 8,
        paddingBottom: 8,
    },
    divider: {
        borderBottom: "1pt solid #bfbfbf",
    },
});
