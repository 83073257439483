import ProductField from "./ProductField";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import productsActions from "actions/products";
import EditProductCategoriesModal from "./EditProductCategoriesModal";
import productCategoryService from "actions/product_category";

const ProductCategory = () => {
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.products.categories);

    const [isEditingProductCategories, setIsEditingProductCategories] =
        useState(false);

    const productCategoryField = {
        name: "Product Category",
        type: "dropdown",
        values: categories.map(({ name }) => ({ name })),
        description: "Categories for products",
    };

    const onUpdateProductCategories = async (data) => {
        const { options } = data;
        return dispatch(
            productCategoryService.updateProductCatgories(
                null,
                options.map(({ id, name, description }) => {
                    return {
                        ...(Number.isInteger(id) ? { id } : {}),
                        name,
                        description
                    };
                }),
                null,
                null
            )
        );
    }

    const refresh = () => {
        dispatch(
            productsActions.readProducts({ limit: 100 })
        );
    };

    return (
        <>
            <ProductField
                field={productCategoryField}
                onEdit={() => {
                    setIsEditingProductCategories(true);
                }}
            />
            {isEditingProductCategories && (
                <EditProductCategoriesModal
                    field={{
                        field_name: productCategoryField.name,
                        field_type: productCategoryField.type,
                        label: productCategoryField.name,
                        options: {
                            data: categories.map((category) => ({
                                id: category.id,
                                description: category.description,
                                value: category.name,
                                removable: !category.has_products
                            })),
                        },
                        description: productCategoryField.description,
                    }}
                    onClose={() => {
                        setIsEditingProductCategories(false);
                    }}
                    open={isEditingProductCategories}
                    refresh={refresh}
                    onUpdate={onUpdateProductCategories}
                />
            )}
        </>
    );
};

export default ProductCategory;
