import React from 'react';
import { Grid, Radio, Button, Segment, Select } from 'semantic-ui-react';
import { Notification, STATUS_TYPES } from 'dyl-components';

const Options = ({ onUpdate, options, onChange }) => (
    <Segment basic textAlign='center'>
        <Grid>
            <Grid.Row columns={2}>
                <Grid.Column>
                    Multiple Call Popup
                </Grid.Column>
                <Grid.Column>
                    <Select
                        onChange={onChange}
                        value={options.multipleCallPopup}
                        name={'multipleCallPopup'}
                        options={[
                            {key: 'Search Page', value: 'Search Page', text: 'Search Page'}
                        ]}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column>
                    Default Customer Type
                </Grid.Column>
                <Grid.Column>
                    <Select
                        onChange={onChange}
                        value={options.defaultCustomerType}
                        name={'defaultCustomerType'}
                        options={[
                            {key: 'Commercial', value: 'Commercial', text: 'Commercial'}
                        ]}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column>
                    Auto Prospect
                </Grid.Column>
                <Grid.Column>
                    <Radio
                        toggle
                        onChange={(_, { name, checked }) => {
                            onChange(null, { name, value: checked });
                        }}
                        checked={options.isAutoProspectEnabled}
                        name={'isAutoProspectEnabled'}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column>
                    Important Notes
                </Grid.Column>
                <Grid.Column>
                    <Radio
                        toggle
                        onChange={(_, { name, checked }) => {
                            onChange(null, { name, value: checked });
                        }}
                        checked={options.isImportantNotesEnabled}
                        name={'isImportantNotesEnabled'}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column>
                    Important Sticky Notes
                </Grid.Column>
                <Grid.Column>
                    <Radio
                        toggle
                        onChange={(_, { name, checked }) => {
                            onChange(null, { name, value: checked });
                        }}
                        checked={options.isImportantStickyNotesEnabled}
                        name={'isImportantStickyNotesEnabled'}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column textAlign='right'>
                    <Button
                        primary
                        onClick={onUpdate}
                    >
                        Update
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>
);

class OptionsContainer extends React.Component {
    state = {
        multipleCallPopup: 'Search Page',
        defaultCustomerType: 'Commercial',
        isAutoProspectEnabled: false,
        isImportantNotesEnabled: true,
        isImportantStickyNotesEnabled: true
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onUpdate = () => {
        Notification.alert('Update successful!', STATUS_TYPES.SUCCESS, true);
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        return (
            <Options
                options={this.state}
                onUpdate={this.onUpdate}
                onChange={this.onChange}
            />
        )
    }
}

export default OptionsContainer;
