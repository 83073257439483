import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function tagReducer(state = initialState, action){
    switch(action.type){
        case getNamedAction(ACTION_NAMES.TAG, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return {...state, isDeletingTag: true};
        case getNamedAction(ACTION_NAMES.TAG, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return {...state, isDeletingTag: false};
        case getNamedAction(ACTION_NAMES.TAG, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return {...state, isDeletingTag: false};

        case getNamedAction(ACTION_NAMES.TAG, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return {...state, isUpdatingTag: true};
        case getNamedAction(ACTION_NAMES.TAG, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return {...state, isUpdatingTag: false};
        case getNamedAction(ACTION_NAMES.TAG, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return {...state, isUpdatingTag: false};

        default:
            return state;
    }
}


export default tagReducer;
