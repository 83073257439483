import { Notification, STATUS_TYPES } from 'dyl-components';

import { generateDeleteActionCreator, generateUpdateActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

const onDeleteSuccess = () => {
    Notification.alert('Successfully deleted category!', STATUS_TYPES.SUCCESS, true);
}

const onDeleteFailure = () => {
    Notification.alert('Failed to delete category', STATUS_TYPES.ERROR, true);
}

const onUpdateSuccess = () => {
    Notification.alert('Successfully updated category!', STATUS_TYPES.SUCCESS, true);
}

const onUpdateFailure = () => {
    Notification.alert('Failed to update category', STATUS_TYPES.ERROR, true);
}

const deleteTagCategory = generateDeleteActionCreator('/v1/tags/category', ACTION_NAMES.TAG_CATEGORY, onDeleteSuccess, onDeleteFailure);
const updateTagCategory = generateUpdateActionCreator('/v1/tags/category', ACTION_NAMES.TAG_CATEGORY, onUpdateSuccess, onUpdateFailure);

export default {
    deleteTagCategory,
    updateTagCategory,
};
