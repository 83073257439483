import React, { useContext, useEffect } from 'react';
import { Icon, Popup } from 'semantic-ui-react';

import './index.scss';
import { FavoritesContext } from 'shared/context/FavoritesProvider';
import { useDispatch } from 'react-redux';
import recentsActions from 'actions/recents';

const FavoriteIcon = ({ details, doNotRecordRecent }) => {
    const { onToggleFavorite, isInFavorites, isAddingFavorite, isRemovingFavorite } = useContext(FavoritesContext);
    const isFavorite = isInFavorites(details);

    const generateFavoriteIcon = () => {
        return isFavorite ? 'star' : 'star outline';
    }

    const dispatch = useDispatch();

    useEffect(() => {
        if (!doNotRecordRecent) {
            dispatch(recentsActions.addRecent({
                page_type: details.favorite_type,
                external_id: details.external_id,
                setting_report_type: details.setting_report_type
            }))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Popup
            trigger={<Icon 
                className={`FavoriteIcon ${generateFavoriteIcon()}`} 
                link 
                color={isFavorite ? 'yellow' : 'black'} 
                loading={isAddingFavorite || isRemovingFavorite} 
                onClick={() => {onToggleFavorite(details)}} 
            />}
            content={isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
            position='right center'
            inverted
        />
    )
}

export default FavoriteIcon;
