import React from 'react';

import './index.scss';
import UserAccountsTable from './subcomponents/UserAccountsTable';

const UserAccounts = () => (
    <React.Fragment>
        <UserAccountsTable />
    </React.Fragment>
);

export default UserAccounts;
