import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Form, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { FIELD_TYPE, FIELD_TYPE_CONTROL } from './DynamicField';

import './CustomDataForm.scss';
import { ButtonLink, ClippedContent, Editable, STATUS_TYPES, generateResolver } from "dyl-components";
import { DYNAMIC_FIELD_VALUE_VALIDATION_SCHEMA } from 'utils/CustomGroupUtils';

const clearValues = (group) => {
    const fields = group.fields.slice(0).map((field) => {
        return { ...field, value: '' }
    });
    const children = group.children.slice(0).map(child => {
        return clearValues(child);
    });
    return {
        ...group,
        fields,
        children
    }
}

const EditableCustomField = ({
    field_name,
    label,
    value,
    field_type,
    renderValue,
    isEditing,

    onCancelEdit,
    onEdit,
    options,
    required,
    onConfirmInlineEdit,

    disabled
}) => {
    const { isReadingBulkUsernames } = useSelector(state => ({
        isReadingBulkUsernames: state.users.isReadingBulkUsernames
    }));

    const { control, formState: { isValid, isDirty }, reset, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            field_type,
            value
        },
        resolver: generateResolver(DYNAMIC_FIELD_VALUE_VALIDATION_SCHEMA)
    });

    const onConfirmEdit = async ({ value }) => {
        return onConfirmInlineEdit(value);
    }

    useEffect(() => {
        if (!isEditing) {
            reset({ value });
        }
    }, [isEditing, reset, value])

    return (
        <Controller
            name={'value'}
            control={control}
            render={({ field: { value: newValue, name, onChange }, fieldState: { error } }) => {
                const FieldComponent = FIELD_TYPE_CONTROL[field_type];
                return (
                    <Form.Field
                        control={disabled ? 'div' : Editable}
                        error={error?.message}
                        label={
                            <label><ClippedContent>{label}</ClippedContent></label>
                        }
                        value={renderValue(value)}
                        isReadLoading={
                            !disabled && field_type === FIELD_TYPE.USER && isReadingBulkUsernames
                        }
                        isEditing={isEditing}
                        onCancelEdit={() => {
                            onCancelEdit();
                        }}
                        onConfirmEdit={handleSubmit(onConfirmEdit, (errors, e) => {
                            console.log(errors, e);
                        })}
                        submitDisabled={!isValid || !isDirty}
                        onEdit={() => { onEdit(field_name) }}
                        disabled={disabled}
                    >
                        {!disabled ? (
                            <FieldComponent
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } });
                                }}
                                name={name}
                                value={newValue}
                                options={(options?.data || []).map(({ value, id, sub_options }) => ({ key: id, value: id, text: value, options: sub_options?.map(sub_option => ({ key: sub_option.id, value: sub_option.id, text: sub_option.value })) || [] }))}
                                required={required}
                                {...field_type === FIELD_TYPE.ADDRESS ? ({
                                    fieldControl: control
                                }) : {}}
                            />
                        ) : renderValue(value)}
                    </Form.Field>
                );
            }}
        />

    )
}

const CustomGroup = ({
    group,
    groupKey,
    number_of_columns = 2,
    isOpen,
    onToggleGroup,
    childIndex,

    onAddInstance,
    onRemoveInstance,

    control,
    instanceCount,

    isViewing,

    customFieldBeingEdited,
    onEdit,
    onCancelEdit,
    onConfirmInlineEdit,
    onFieldUpdate,

    disabled
}) => {
    const { usernames } = useSelector(state => ({
        usernames: state.users.bulkUsernames
    }));

    const { fields } = useFieldArray({
        name: `${groupKey}.fields`,
        control
    });

    const { fields: children, insert, remove } = useFieldArray({
        name: `${groupKey}.children`,
        control
    })
    function renderValue(field, value) {
        const { field_type, options } = field;
        if (field_type === FIELD_TYPE.CHECKBOX) {
            return value ? 'Yes' : 'No'
        }
        const hasOptions = field_type === FIELD_TYPE.TAGSINPUT || field_type === FIELD_TYPE.PICKLIST || field_type === FIELD_TYPE.RADIO || field_type === FIELD_TYPE.DROPDOWN;
        if (hasOptions) {
            if (typeof value === 'number' || typeof value === 'string') {
                return (options.data || []).find(option => option.id === value)?.value || "None";
            }
            return value.map(individualValue => (options.data || []).find(option => option.id === individualValue)?.value || '').filter(value => value).join(', ')
        }
        if (field_type === FIELD_TYPE.ADDRESS) {
            const { street, additional_street, city, state, zip } = value;
            return [[street, additional_street, city].filter(part => part?.trim()).join(' '), state, zip].filter(part => part?.trim()).join(', ').trim() || "None";
        }
        if (field_type === FIELD_TYPE.TIMERANGE) {
            const { start_time, end_time } = value;
            return [start_time, end_time].filter(part => part?.trim()).join(' - ') || "None";
        }
        if (field_type === FIELD_TYPE.DEPENDENCY) {
            const [parentValue, childValue] = value.split(',').map(fieldValue => Number(fieldValue));
            if (parentValue) {
                const parent = (options?.data || []).find(option => option.id === parentValue);
                const parentValueName = parent?.value || '';
                const childValueName = parent?.sub_options?.find(sub_option => sub_option.id === childValue)?.value || '';
                return [parentValueName, childValueName].filter(fieldValue => fieldValue?.trim()).join('/');
            }
            return "None"
        }
        if (field_type === FIELD_TYPE.USER) {
            const user = usernames.find(({ id }) => id === value) || {};
            return `${user?.first_name || ''} ${user?.last_name || ''}`;
        }

        return value || "None";
    }

    function renderLabel(field) {
        const { field_type, label } = field;
        if (field_type !== FIELD_TYPE.DEPENDENCY) {
            return label;
        }
        return `${label} / ${field.data?.dependency_label}`;
    }

    return (
        <Grid className={`CustomDataForm${group.archived ? ` CustomDataForm--archived` : ''}`}  >
            <Grid.Row columns='equal' verticalAlign='middle' style={{ marginTop: 0, paddingTop: 0, marginBottom: "0.5em", paddingBottom: 0 }}>
                <Grid.Column className='CustomDataForm__header'>
                    <Header color='primary'>
                        <span>
                            <Icon name={`chevron circle ${isOpen(group.id) ? 'down' : 'right'}`} onClick={() => { onToggleGroup(group.id) }} link color='primary' />
                            {group.label} {group.multiple && (instanceCount)} {group.archived && <small>(Archived)</small>}
                            {!isViewing && !group.archived && group.multiple && <Icon
                                onClick={() => { onAddInstance(childIndex, clearValues(group)) }}
                                name={'plus circle'}
                                link
                                color='primary'
                            />
                            } {!isViewing && !group.archived && group.multiple && instanceCount !== 1 && (
                                <ButtonLink status={STATUS_TYPES.ERROR} onClick={() => { onRemoveInstance(childIndex) }}>
                                    <Icon
                                        className={'fas fa-trash'}
                                        link
                                    /> Delete
                                </ButtonLink>
                            )}
                        </span>
                    </Header>
                </Grid.Column>
            </Grid.Row>
            {/* Transition is not used here since it messes up the validation for custom data */}
            <Grid.Row {...(!isOpen(group.id) ? { style: { display: 'none' } } : {})}>
                <Grid.Column>
                    <Segment size='mini' className='CustomDataForm__fields'>
                        <Grid>
                            <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }} columns={fields.length ? number_of_columns : 1}>
                                {fields.map((field, fieldIndex) => {
                                    const field_name = `${groupKey}.fields[${fieldIndex}].value`;
                                    const { field_type, options, required } = field;
                                    return (
                                        <Grid.Column verticalAlign='top' style={{ paddingBottom: "1em", paddingLeft: "2.25em", paddingRight: "2.25em" }} key={field_name}>
                                            <Controller
                                                name={field_name}
                                                control={control}
                                                render={({ field: { name, value, onChange }, fieldState: { error } }) => {
                                                    if (isViewing) {
                                                        return (
                                                            <EditableCustomField
                                                                field_type={field_type}
                                                                label={renderLabel(field)}
                                                                field_name={name}

                                                                options={options}
                                                                renderValue={(value) => renderValue(field, value)}
                                                                value={value}
                                                                required={required}
                                                                isEditing={customFieldBeingEdited === name}
                                                                onCancelEdit={onCancelEdit}
                                                                onEdit={onEdit}
                                                                onConfirmInlineEdit={async (newValue) => {
                                                                    await onChange({ target: { name, value: newValue } });
                                                                    await onConfirmInlineEdit();
                                                                    onFieldUpdate(field_type, newValue);
                                                                    onCancelEdit();
                                                                }}
                                                                disabled={disabled}
                                                            />
                                                        )
                                                    }
                                                    return (
                                                        <Form.Field
                                                            readOnly={group.archived}
                                                            control={isViewing ? 'div' : FIELD_TYPE_CONTROL[field_type]}
                                                            onChange={(_, { value }) => {
                                                                onChange({ target: { name, value } });
                                                            }}
                                                            name={name}
                                                            value={value}
                                                            error={error?.message}
                                                            options={(options?.data || []).map(({ value, id, sub_options }) => ({ key: id, value: id, text: value, options: sub_options?.map(sub_option => ({ key: sub_option.id, value: sub_option.id, text: sub_option.value })) || [] }))}
                                                            required={required}
                                                            label={
                                                                <label><ClippedContent>{renderLabel(field)}</ClippedContent></label>
                                                            }
                                                            {...isViewing ? (
                                                                { children: renderValue(field, value) }
                                                            ) : {}}
                                                            fluid
                                                            disabled={group.archived || disabled}
                                                            {...field_type === FIELD_TYPE.ADDRESS ? ({
                                                                fieldControl: control
                                                            }) : {}}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Grid.Column>
                                    );
                                })}
                            </Grid.Row>
                            {children?.length > 0 && (
                                <Grid.Row style={{ marginLeft: "1.25em" }}>
                                    <Grid.Column>
                                        <CustomDataForm
                                            parent={`${groupKey}.children`}
                                            group={children}
                                            number_of_columns={number_of_columns}
                                            isOpen={isOpen}
                                            onAddInstance={(index, group) => { insert(index + 1, clearValues(group)) }}
                                            onRemoveInstance={(index) => { remove(index) }}
                                            control={control}
                                            onToggleGroup={onToggleGroup}
                                            isViewing={isViewing}
                                            customFieldBeingEdited={customFieldBeingEdited}
                                            onCancelEdit={onCancelEdit}
                                            onEdit={onEdit}
                                            onConfirmInlineEdit={onConfirmInlineEdit}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                            {!children.length && !fields.length && (
                                <Grid.Row>
                                    <Grid.Column style={{ marginLeft: "1.25em" }}>
                                        <em>No groups/fields listed under this group</em>
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                        </Grid>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

const CustomDataForm = ({
    group,
    parent,
    number_of_columns = 2,
    isOpen,
    onToggleGroup,

    onAddInstance,
    onRemoveInstance,

    control,
    isViewing,

    customFieldBeingEdited,
    onEdit,
    onCancelEdit,
    onConfirmInlineEdit,
    onFieldUpdate,

    disabled
}) => {
    if (group === null || group === undefined || group.length <= 0) {
        return ""
    }
    let instanceCount = 0;
    let prevChildName = ''
    return (
        group.map((child, childIndex) => {
            const currentChildName = child.group_name;
            if (currentChildName !== prevChildName) {
                instanceCount = 0;
            }
            prevChildName = currentChildName;
            instanceCount++;
            const groupKey = `${parent}[${childIndex}]`;

            return (
                <CustomGroup
                    control={control}
                    groupKey={groupKey}
                    isOpen={isOpen}
                    onAddInstance={onAddInstance}
                    onRemoveInstance={onRemoveInstance}
                    onToggleGroup={onToggleGroup}
                    number_of_columns={number_of_columns}
                    group={child}
                    childIndex={childIndex}
                    instanceCount={instanceCount}
                    key={`${child.id}${groupKey}`}
                    isViewing={isViewing}
                    customFieldBeingEdited={customFieldBeingEdited}
                    onEdit={onEdit}
                    onCancelEdit={onCancelEdit}
                    onConfirmInlineEdit={onConfirmInlineEdit}
                    onFieldUpdate={onFieldUpdate}
                    disabled={disabled}
                />
            );
        })
    )
}

export default CustomDataForm;
