import React from "react";
import {
    ClearableCheckboxDropdown,
    Table,
} from "dyl-components";
import { Table as SemanticTable } from "semantic-ui-react";
import { useSearchParams, useNavigate } from "react-router-dom";
import SelectAllCheckbox from "shared/SelectAllCheckbox";
import {
    getData,
    onToggleTimeUnit,
    useRangeCalendar,
} from "shared/CustomDateRange/customDateRange";
import { TimestampFilters } from 'dyl-components';
import { PhoneUtil } from "utils";
import SortActions from "./SortActions";

const Header = ({ onFilter, count, sounds }) => {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const lastModifiedOrder = params.get("order") === "asc";

    const lastModifiedFilter = () => {
        const query = new URLSearchParams(params);
        const order = !lastModifiedOrder ? "asc" : "desc";
        query.set("order", order);
        const query_string = query.toString();
        navigate(`/settings/phone-management/audio${query_string ? `?${query_string}` : ""}`);
    };

    const onDateRangeChange = (_, { value }) => {
        setDateRange(value);
    };

    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        userAuthTimezone,
        dateRange,
        setDateRange,
    } = useRangeCalendar(params);

    return (
        <Table.Row>
            <SemanticTable.HeaderCell
                width={1}
            >
            <SelectAllCheckbox 
                count={count}
                data={sounds || []}
                type={"Audio Files"}
                idProperty={"sound_id"}
            />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
            <span style={{ marginRight: 5 }}>Last Modified</span>
            <TimestampFilters
                timestamp={timestamp}
                onToggleTimeUnit={(timestamp) =>
                    onToggleTimeUnit(
                        timestamp,
                        setTimestamp,
                        params,
                        navigate,
                        "/settings/phone-management/audio"
                    )
                }
                isCustomDateRangeOpen={isCustomDateRangeOpen}
                onOpenCustomDateRange={() => {
                    setCustomDateRangeOpen(true);
                }}
                onCloseCustomDateRange={() => {
                    setCustomDateRangeOpen(false);
                }}
                dateRange={dateRange}
                onDateRangeChange={onDateRangeChange}
                getData={() =>
                    getData({
                        setCustomDateRangeOpen,
                        dateRange,
                        userAuthTimezone,
                        params,
                        navigate,
                        url: "/settings/phone-management/audio",
                    })
                }
            />   
            <SortActions
                direction={lastModifiedOrder}
                onToggleSort={() => {
                    lastModifiedFilter();
                }}
            />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                <ClearableCheckboxDropdown
                    text="Type"
                    name="type"
                    options={PhoneUtil.SOUND_TYPES}
                    onChange={(_, { name, value }) => {
                        onFilter(_, { name, value });
                    }}
                    value={params.get("type")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={3}
            >
                Apply To
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={1}
            >
                Audio
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                Label
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell 
                width={1}
            >
                Actions
            </SemanticTable.HeaderCell>
        </Table.Row>
    );
};

export default Header;
