const initialState = {
    attachments: [],
    count: 0,
    isCreatingAttachment: false,
    isReadingAttachments: false,
    attachmentBeingDeleted: null,
    attachmentBeingUpdated: null,
    queryParameters: {}
}

export default initialState;
