import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { ClippedContent } from "dyl-components";
import HoverableIconWithList from "shared/HoverableIconWithList";

const Fee = ({ fee, type }) => {
    return (
        <div className="FeesTable__Fee">
            <div className="Fee__Name">
                <ClippedContent>
                    <Link
                        to={`/settings/product-catalog-quotes-invoices/${
                            type === "tax" ? "taxes" : "fees"
                        }/${fee.id}`}
                    >
                        {fee.name}
                    </Link>
                </ClippedContent>
            </div>
            <div className="Fee__Actions">
                <HoverableIconWithList
                    icon={<Icon className="fa-solid fa-box" color="primary" />}
                    iconContent={"Products"}
                    items={fee?.products}
                />
                <HoverableIconWithList
                    icon={<Icon className="fa-solid fa-boxes-stacked" color="primary" />}
                    iconContent={"Bundles"}
                    items={fee?.bundles}
                />
            </div>
        </div>
    );
};

export default Fee;
