
import initialState from './initialState';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';

function image(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.USER_PROFILE_IMAGE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isUploadingUserProfileImage: true };
        case getNamedAction(ACTION_NAMES.USER_PROFILE_IMAGE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
        case getNamedAction(ACTION_NAMES.USER_PROFILE_IMAGE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isUploadingUserProfileImage: false };

        case getNamedAction(ACTION_NAMES.USER_PROFILE_IMAGE, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingUserProfileImage: true };
        case getNamedAction(ACTION_NAMES.USER_PROFILE_IMAGE, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingUserProfileImage: false, image: action.data.image };
        case getNamedAction(ACTION_NAMES.USER_PROFILE_IMAGE, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingUserProfileImage: false, image: null };

        case getNamedAction(ACTION_NAMES.MMS_PAYLOAD, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isUploadingMMSPayload: true };
        case getNamedAction(ACTION_NAMES.MMS_PAYLOAD, CRUD_ACTION_TYPES.CREATE_SUCCESS):
        case getNamedAction(ACTION_NAMES.MMS_PAYLOAD, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isUploadingMMSPayload: false };

        case getNamedAction(ACTION_NAMES.IMAGE_URLS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingURLs: true };
        case getNamedAction(ACTION_NAMES.IMAGE_URLS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingURLs: false };
        case getNamedAction(ACTION_NAMES.IMAGE_URLS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingURLs: false };

        default:
            return state;
    }
}


export default image;
