
import React from 'react'; //{ useState }
import '../index.scss';
import { Table, EllipsisDropdown, DateTimeUtils, Person, ControlledPopup } from 'dyl-components';
import { Icon, Dropdown, Header, } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
    
export const AssignmentRulesRow = 
    ({id, index, name, description, usersAssigned,
    dateCreated, createdBy, isActive, onDelete}) => {

    const navigate = useNavigate();

    const ELLIPSIS_OPTIONS_SUBSECTION = [
        {
            id: 1,
            text: 'Edit',
            icon: 'fas fa-edit',
            onClick: () => {
                navigate(`/settings/assignment-rules/edit/${id}`);
            }
        },
        {
            text: 'Delete',
            icon: 'fas fa-trash-alt',
            displaysPopup: true,
            popupProps: {
                header: 'Are you sure?',
                onConfirm: (onDelete) => {
                    onDelete();
                }
            }
        }
    ]

    const DropdownItem = ({ text, icon, ...otherProps }) => {
        return (
        <Dropdown.Item text={<React.Fragment>
            <Icon name={icon} />
            {text}
        </React.Fragment>}
            style={{ cursor: 'pointer' }}
            {...otherProps}
        />
        );
    }
        
    return (
        <Table.CollapsibleRowContent>
            <Table.Cell colSpan={1}>
            </Table.Cell>
            <Table.Cell colSpan={1} >
                <span className="ARTable__latoBlack">{index + 1} </span>
                <Link to={`/settings/assignment-rules/edit/${id}`} style={{marginLeft: 10}}>
                    {name}
                </Link>
            </Table.Cell>
            <Table.Cell colSpan={1}>
                {description}
            </Table.Cell>
            <Table.Cell colSpan={1}>
                Auto-assign : {usersAssigned}
            </Table.Cell>
            <Table.Cell colSpan={1}>
                {usersAssigned} users
            </Table.Cell>
            <Table.Cell colSpan={1}>
                <div><b>{DateTimeUtils.formatEpoch(dateCreated, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                <div>{DateTimeUtils.formatEpoch(dateCreated, DateTimeUtils.TIME_FORMAT)}</div>
            </Table.Cell>
            <Table.Cell colSpan={1}>
                {/* TODO: Replace contact once data is passed into component */}
                <Person username={createdBy.username} isUsernameOnly />
            </Table.Cell>
            <Table.Cell colSpan={1}>
                {isActive ? "Active" : "Inactive"}
            </Table.Cell>
            <Table.Cell colspan={1}>
                <EllipsisDropdown>
                    {ELLIPSIS_OPTIONS_SUBSECTION.map(({ text, icon, displaysPopup, popupProps, ...otherProps }, idx) => {
                        if (idx === 1) {
                            return (
                                <ControlledPopup
                                    header={
                                        <Header as='h4' textAlign='center'>
                                            Are you sure?
                                        </Header>
                                    }
                                    trigger={<Dropdown.Item icon={icon} text={text} />}
                                    onConfirm={onDelete}
                                    inverted
                                />
                            )
                        }
                        return (
                            <DropdownItem
                                key={idx}
                                text={text}
                                icon={icon}
                                displaysPopup={displaysPopup}
                                popupProps={popupProps}
                                {...otherProps}
                            />
                        );
                    })}
                </EllipsisDropdown>        
            </Table.Cell>
            </Table.CollapsibleRowContent>
    )
}