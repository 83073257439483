import React from 'react';
import { TableWithHeader, STATUS_TYPES, Notification } from 'dyl-components';
import VoicemailDropMessagesTable from './VoicemailDropMessagesTable';
import DefaultMessagesTableContainer from './DefaultMessagesTable';

const VoicemailDropMessages = ({ messages, onUpdate, onDelete }) => (
    <TableWithHeader 
        header={'Voicemail Drop Messages'}
        table={
            <VoicemailDropMessagesTable 
                messages={messages}
                onUpdate={onUpdate}
                onDelete={onDelete}
            />
        }
    />
);

class VoicemailDropMessagesContainer extends React.Component {
    state = {
        messages: [
            { id: 'nl', name: 'New Lead', lastUpdated: 'Thu, Oct 30, 2014', assigned: 'All Users' },
            { id: 'qfu', name: 'Quote Follow Up', lastUpdated: 'Thu, Oct 30, 2014', assigned: 'All Users' },
            { id: 'v2', name: 'Voicemail 2', lastUpdated: 'Thu, Mar 21, 2019', assigned: 'All Users' },
            { id: 'v3', name: 'Voicemail 3', lastUpdated: 'Fri, Mar 22, 2019', assigned: 'All Users' },
            { id: 'v4', name: 'Voicemail 4', lastUpdated: 'Tue, Apr 23, 2019', assigned: 'All Users' }
        ]
    }

    onUpdate = (updatedMessage) => {
        let messages = this.state.messages.slice(0);
        let indexOfMessageToUpdate = messages.findIndex(message => message.id === updatedMessage.id);
        if (indexOfMessageToUpdate !== -1) {
            messages[indexOfMessageToUpdate] = {...updatedMessage};
            this.setState({ messages }, () => {
                Notification.alert('Successfully updated voicemail drop message!', STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    onDelete = (id) => {
        let messages = this.state.messages.slice(0);
        let indexOfLeadSourceToDelete = messages.findIndex(message => message.id === id);
        if (indexOfLeadSourceToDelete !== -1) {
            messages.splice(indexOfLeadSourceToDelete, 1);
            this.setState({ messages }, () => {
                Notification.alert('Successfully deleted voicemail drop message!', STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <VoicemailDropMessages 
                    messages={this.state.messages}
                    onUpdate={this.onUpdate}
                    onDelete={this.onDelete}
                />
                <DefaultMessagesTableContainer 
                    messageOptions={this.state.messages.map(message => ({
                        key: message.id,
                        value: message.id,
                        text: message.name
                    }))}
                />
            </React.Fragment>
        )
    }
}

export default VoicemailDropMessagesContainer;
