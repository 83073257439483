import React, {useCallback, useEffect, useReducer, useRef, useState} from 'react';
import {Form} from 'semantic-ui-react';

import masterAccountActions from 'actions/master_account';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {Notification, STATUS_TYPES, generateResolver, Modal, yup, VALIDATORS, LinkedAccount} from 'dyl-components';
import masterAccountReducers from "reducers/master_account";
import masterAccountInitialState from "reducers/master_account/initialState";
import CRUD_ACTION_TYPES, {getNamedAction} from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

const LinkToMasterAccountForm = ({
    close,
    
    refreshFromLinking,
    account_id,
    
    current_master_account
}) => {
    const { isCreatingMasterAccount, isLinkingAccounts } = useSelector(state => ({
        isCreatingMasterAccount: state.master_account.isCreatingMasterAccount,
        isLinkingAccounts: state.master_account.isLinkingAccounts
    }));
    
    const dispatch = useDispatch();
    
    const {control, formState: {isValid, isDirty}, handleSubmit, setError} = useForm({
        mode: 'onChange',
        defaultValues: {
            master_account: null
        },
        resolver: generateResolver({
            master_account: yup.mixed().required('This field is required').test("invalid_name", "Invalid name", async function(value) {
                if (!value || typeof value === 'number') {
                    return true;
                }
                const isValid = await VALIDATORS.MASTER_NAME().isValid(value);
                return isValid
            })
        })
    });

    const linkAccountsToMasterAccount = async (data) => {
        const {master_account} = data;
        try {
            if (typeof master_account === 'string') {
                const { id: master_account_id } = await dispatch(masterAccountActions.create({ name: master_account }));
                await dispatch(masterAccountActions.linkAccounts(master_account_id, [Number(account_id)], { old_master_account_id: current_master_account }));
            } else {
                await dispatch(masterAccountActions.linkAccounts(master_account, [Number(account_id)], { old_master_account_id: current_master_account }));
            }
            Notification.alert(`Successfully ${current_master_account ? 'changed master account' : 'linked account to master account'}!`, STATUS_TYPES.SUCCESS);
            close();
            refreshFromLinking();
        } catch (e) {
            if (e?.code !== 409) {
                Notification.alert(`Failed to ${current_master_account ? 'change master account' : 'link account to master account'}`, STATUS_TYPES.ERROR);
            } else {
                Notification.alert(`Master account already exists`, STATUS_TYPES.ERROR);
                setError("master_account", { type: "unique", message: "Name already exists!" });
            }
        }
    }
    
    const [newMasterAccountOptions, setNewMasterAccountOptions] = useState([]);

    const [state, masterAccountDispatch] = useReducer(masterAccountReducers, { ...masterAccountInitialState });

    const { isReadingMasterAccounts, masterAccounts: results } = state;

    const timeoutRef = useRef();

    const handleSearchChange = useCallback((_, { searchQuery: value }) => {
        clearTimeout(timeoutRef.current);

        masterAccountDispatch({ type: getNamedAction(ACTION_NAMES.MASTER_ACCOUNTS_SEARCH, CRUD_ACTION_TYPES.READ_REQUEST) });

        timeoutRef.current = setTimeout(async () => {
            try {
                const response = await dispatch(masterAccountActions.search({ search: value?.toLowerCase()?.trim() }));
                masterAccountDispatch({ type: getNamedAction(ACTION_NAMES.MASTER_ACCOUNTS_SEARCH, CRUD_ACTION_TYPES.READ_SUCCESS), data: response });
            } catch {
                masterAccountDispatch({ type: getNamedAction(ACTION_NAMES.MASTER_ACCOUNTS_SEARCH, CRUD_ACTION_TYPES.READ_FAILURE) });
            }
        }, 1000);
    }, [dispatch]);

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
        }
    }, []);

    return (
        <React.Fragment>
            <Modal.Header>
                {current_master_account ? 'Change' : 'Link'} Master Account
            </Modal.Header>
            <Modal.Content>
                <Form loading={isCreatingMasterAccount || isLinkingAccounts}>
                    <Form.Group>
                        <Controller
                            name='master_account'
                            control={control}
                            render={({field: {onChange, value, name}, fieldState: {error}}) => {
                                const existingMasterAccountsOptions = results.map(option => ({
                                    key: option.id,
                                    value: option.id,
                                    text: option.name,
                                    disabled: option.id === current_master_account,
                                    content: (
                                        <LinkedAccount
                                            account={option.name}
                                            maxWidth={'15em'}
                                            subtitle={option.id && `ID: ${option.id}${option.id === current_master_account ? ' (current)' : ''}`}
                                            popup
                                        />
                                    )
                                }));
                                const selectedExistingMasterAccount = existingMasterAccountsOptions.find(option => option.value === value);
                                return (
                                    <Form.Select
                                        label='Master Account Name'
                                        width={8}
                                        required
                                        placeholder='Search for master account or create new'
                                        name={name}
                                        value={value}
                                        error={error && error.message}
                                        onChange={(_, {value: newValue}) => {
                                            if (typeof value === 'string') {
                                                setNewMasterAccountOptions(newMasterAccountOptions.filter(option => value !== option.value))
                                            }
                                            onChange({target: {name, value: newValue || null}});
                                            if (!newValue) {
                                                masterAccountDispatch({ type: getNamedAction(ACTION_NAMES.MASTER_ACCOUNTS_SEARCH, CRUD_ACTION_TYPES.READ_SUCCESS), data: { data: [] } });
                                            }
                                        }}
                                        options={[
                                            ...newMasterAccountOptions,
                                            ...existingMasterAccountsOptions
                                        ]}
                                        {...(value && selectedExistingMasterAccount) ? {
                                            text: selectedExistingMasterAccount.content
                                        } : {}}
                                        allowAdditions={!isReadingMasterAccounts}
                                        search={!selectedExistingMasterAccount}
                                        selectOnBlur={false}
                                        clearable
                                        onAddItem={async (_, {value}) => {
                                            const isExisting = newMasterAccountOptions.find(option => option.value?.toLowerCase() === value?.toLowerCase()) !== undefined;
                                            if (!isExisting) {
                                                setNewMasterAccountOptions([
                                                    ...newMasterAccountOptions,
                                                    {
                                                        key: value,
                                                        value: value,
                                                        text: value,
                                                        new: true
                                                    }
                                                ])
                                            }
                                        }}
                                        onSearchChange={handleSearchChange}
                                        loading={isReadingMasterAccounts}
                                    />
                                );
                            }}
                        />
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions
                hasSaveButton
                onSave={handleSubmit(linkAccountsToMasterAccount)}
                saveDisabled={!isValid || !isDirty || isCreatingMasterAccount || isLinkingAccounts}
                saveOptions={{loading: isCreatingMasterAccount || isLinkingAccounts}}
            />
        </React.Fragment>
    );
}

export default LinkToMasterAccountForm;
