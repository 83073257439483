const initialState = {
    isEnablingIntegration: false,
    isReadingProviders: false,
    isPullingEmails: false,
    isSendingEmail: false,
    integrationBeingDeleted: null,
    integrationBeingUpdated: null,
    providers: [],
    integrations: [],
    emails: [],
    count: 0,
    activeIntegration: {},
    queryParameters: {},
    isGettingAllEmails: false,
    allEmails: [],
    isPullingAttachment: false,
};

export default initialState;
