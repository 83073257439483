import routes from 'actions/routes';
import {
    generateCreateActionCreator, generateDeleteActionCreator, generateReadActionCreator, generateReadOneActionCreator, generateUpdateActionCreator,
} from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

export default {
    createTrigger: generateCreateActionCreator(routes.API_AUTOMATION_TRIGGER, ACTION_NAMES.AUTOMATION_TRIGGER),
    reorderTriggers: generateUpdateActionCreator(routes.API_AUTOMATION_TRIGGER_ORDER, ACTION_NAMES.AUTOMATION_TRIGGER_ORDER),
    deleteTrigger: generateDeleteActionCreator(routes.API_AUTOMATION_TRIGGER, ACTION_NAMES.AUTOMATION_TRIGGER),
    getTriggers: generateReadActionCreator(routes.API_AUTOMATION_TRIGGER, ACTION_NAMES.AUTOMATION_TRIGGER),
    getDistributionUsers: generateReadActionCreator(routes.API_AUTOMATION_USERS, ACTION_NAMES.AUTOMATION_USERS),
    setUserCap: generateUpdateActionCreator(routes.API_AUTOMATION_USER_CAP, ACTION_NAMES.AUTOMATION_USER_CAP),
    uniqueRuleName: generateReadActionCreator(routes.API_AUTOMATION_TRIGGER_UNIQUE, ACTION_NAMES.AUTOMATION_TRIGGER_UNIQUE),
    getTrigger: generateReadOneActionCreator(routes.API_AUTOMATION_TRIGGER, ACTION_NAMES.AUTOMATION_TRIGGER),
    updateTrigger: generateUpdateActionCreator(routes.API_AUTOMATION_TRIGGER, ACTION_NAMES.AUTOMATION_TRIGGER),
};
