import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Header, Popup, Segment } from 'semantic-ui-react';
import { ButtonLink } from 'dyl-components';

import { CustomGroupUtils } from 'utils';

import AddInterestForm from 'shared/forms/AddInterestForm';

const InterestsSection = () => {
    const [isOpen , setIsOpen] = useState(false);

    const contact_interest_count = useSelector(state => {
        const custom_data = CustomGroupUtils.groupAndFlatten(state.contact.custom_data);
        return (custom_data.fields.interests || []).filter(interest => typeof interest === 'number').length;
    });

    return (
        <Popup
            trigger={
                <Segment>
                    <div style={{ display: 'flex' }}>
                        <Header style={{ margin: 0, flex: 1 }} as='h4'>Interests</Header>
                        <ButtonLink>{`${contact_interest_count} interest${contact_interest_count !== 1 ? 's' : ''}`}</ButtonLink>
                    </div>
                </Segment>
            }
            on="click"
            content={<AddInterestForm setIsOpen={setIsOpen} />}
            position="bottom center"
            flowing
            basic
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
        />
    );
};

export default InterestsSection;
