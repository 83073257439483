import React from 'react';
import { Segment } from 'semantic-ui-react'

import SelectionTag from './SelectionTag';

const Selections = ({ selections = [], onSelectionDelete }) => {
    return (
        <Segment className='SearchableMultiSelection__Selections'>
            {selections.length > 0 ?
                selections.map((selection, index) => (
                    <SelectionTag key={index} selection={selection} onSelectionDelete={onSelectionDelete} />
                )) :
                <span className='Selections__EmptySelectionPrompt'>No Nested Account(s)</span>
            }
        </Segment>
    );
}

export default Selections;
