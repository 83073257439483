import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'dyl-components';
import { Popup, Icon } from 'semantic-ui-react';
import { TwoLinedContent } from 'dyl-components';

const CRMActivityRow = ({ activity }) => (
    <Table.Row>
        <Table.Cell>
            <TwoLinedContent firstLine={activity.ts.split()[0]}
            secondLine={activity.ts.split()[1]} />
        </Table.Cell>
        <Table.Cell>
            <Link to={`/lead?id=${activity.lead_id}`}>
                <Icon name='circle' /> {activity.first_name} {activity.last_name}
            </Link>
        </Table.Cell>
        <Table.Cell>
            {activity.crm}
        </Table.Cell>
        <Table.Cell>
            {activity.event}
        </Table.Cell>
        <Table.Cell>
            N/A
        </Table.Cell>
        <Table.Cell>
            <Popup inverted position='top center' trigger={<Icon name='warning sign' color='red' inverted />} content='Missing Phone Number, cannot look up AMS customer' />
        </Table.Cell>
        <Table.Cell>
            
        </Table.Cell>
    </Table.Row>
);

export default CRMActivityRow;
