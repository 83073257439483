
import { DateTimeUtils } from 'dyl-components';

import eventsActions from 'actions/events';
import contactActions from 'actions/contact';
import selfReportActions from 'actions/self_report';
import contactPhoneActions from 'actions/contact_phone';
import contactEmailActions from 'actions/contact_email';

const onReadContactInformationDispatch = (dispatch) => (contact_id) => {
    const start = DateTimeUtils.getLast(2, 'month');
    const end = DateTimeUtils.getNext(1, 'year');
    dispatch(contactActions.readContact(contact_id));
    dispatch(contactActions.readLabelsContainingContact(contact_id));
    dispatch(contactPhoneActions.getContactPhones(contact_id));
    dispatch(contactEmailActions.getContactEmails(contact_id));
    dispatch(eventsActions.readEvents({ contact_id, start_time: start, end_time: end, limit: 100 }));
  
    dispatch(selfReportActions.readActivity({ contact_id, occurred: start, limit: 100 }));
};

export default onReadContactInformationDispatch;
