const initialState = {
    attendees: [],
    isReading: false,
    isAdding: false,
    attendeeBeingUpdated: null,
    attendeeBeingRemoved: null,
    count: 0
}

export default initialState;
