import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';


import { MASTER_SOURCE_OPTIONS } from 'shared/constants/MASTER_SOURCE_OPTIONS';
import leadProviderActions from 'actions/lead_provider';
import { NestedDropdown } from 'dyl-components';

const MasterSecondarySourceOptions = ({
    value,
    parentValue,

    onChange,

    placeholder = 'Select Master/Secondary Source'
}) => {
    const dispatch = useDispatch();

    const [sourceOptions, setSourceOptions] = useState([]);
    const [isReading, setIsReading] = useState(false);

    useEffect(() => {
        const getSourceOptions = async () => {
            const { data: secondary_sources } = await (async () => {
                try {
                    return await dispatch(leadProviderActions.readSecondarySourceOptions({ limit: 100 }))
                } catch (e) {
                    return { data: [] };
                }
            })();
            return MASTER_SOURCE_OPTIONS.map((master_source => ({
                key: master_source.key,
                value: master_source.value,
                text: master_source.text,
                options: (
                    secondary_sources.filter(secondary_source => secondary_source.master_source === master_source.value).map(secondary_source => ({
                        key: secondary_source.id,
                        value: secondary_source.id,
                        text: secondary_source.name
                    }))
                )
            })))
        }

        setIsReading(true);
        getSourceOptions().then(options => {
            setSourceOptions(options);
            setIsReading(false);
        }).catch(e => {
            console.log(e);
            setSourceOptions([]);
            setIsReading(false);
        })
    }, [dispatch])
    return (
        <NestedDropdown
            child_value={value}
            parent_value={parentValue}
            loading={isReading}
            nested_options={sourceOptions}
            onChange={(e, { parent_value, child_value }) => {
                onChange(e, { parent_value, child_value });
            }}
            placeholder={placeholder}
            display_parent
            allowedToSelectParentOnly
        />
    )
}

export default MasterSecondarySourceOptions;
