import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';

import './ActiveCall.scss';

const ActiveCall = ({ caller, duration = '0:00' }) => (
    <Segment className='ActiveCall'>
        <Grid columns='equal'>
            <Grid.Column floated='left' width={11}>
                {caller}
            </Grid.Column>
            <Grid.Column floated='right'>
                {duration}
            </Grid.Column>
        </Grid>
    </Segment>
);

export default ActiveCall;
