import { RecordsTable } from 'dyl-components';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Header from './Header';
import Row from './Row';
import { useSelector } from 'react-redux';

const Table = ({ onFilter, onPageChange, onOpenEditDylNumberModal }) => {
    const { dylNumbersCount, isReadingDylNumbers, dylNumbers } = useSelector((state) => state.pbx_config);
    const [params] = useSearchParams();

    return <RecordsTable
        tableBodyClassName='NumbersTable'
        isTableLoading={isReadingDylNumbers}
        isSearchResultEmpty={!dylNumbersCount && (params.get('search') || params.get('type') || params.get('destination_type'))}
        isTableEmpty={!dylNumbersCount && !(params.get('search') || params.get('type') || params.get('destination_type'))}
        tableEmptyMessage='No Numbers Added'
        TableHeader={<Header onFilter={onFilter} count={dylNumbersCount} numbers={dylNumbers} />}
        TableRows={dylNumbers.map((number) => <Row number={number} onOpenEditDylNumberModal={onOpenEditDylNumberModal} />)}
        recordsCount={dylNumbersCount}
        activePage={params.get('page')}
        onPageChange={onPageChange}
        recordsName='Number'
    />
}

export default Table;