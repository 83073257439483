import React from 'react';
import { Controller } from 'react-hook-form';
import { Form, Grid, Icon, Popup } from 'semantic-ui-react';

export const WebformDetails = ({
    control
}) => (
    <Grid>
        <Grid.Column width={1} />
        <Grid.Column textAlign='left' width={15}>
            <Form.Group>
                <Controller
                    name='internal_form_name'
                    defaultValue=''
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'This field is required'
                        },
                        maxLength: {
                            value: 100,
                            message: 'Max 100 Characters'
                        },
                        pattern: {
                            value: /^[A-Za-z0-9:\-\s]+$/,
                            message: 'This field should contain only alphanumeric characters'
                        },
                        validate: {
                            no_excessive_whitespaces: (value) => {
                                return !!value?.trim() || 'This field is required';
                            }
                        }
                    }}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <Form.Input
                            width={6}
                            required
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                            label={{
                                children: (
                                    <span>
                                        Internal Form Name
                                        <Popup
                                            trigger={<Icon style={{ float: 'right', marginTop: '0.25em' }} name='fas fa-info-circle' color='primary' />}
                                            content='This will only appear within your internal system to quickly differentiate between forms.'
                                            inverted
                                            position='top right' />
                                    </span>
                                )
                            }}
                            error={error?.message && ({
                                content: error.message
                            })} />
                    )} />
                <Controller
                    name='form_display_name'
                    defaultValue=''
                    control={control}
                    rules={{
                        maxLength: {
                            value: 100,
                            message: 'Max 100 Characters'
                        }
                    }}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <Form.Input
                            width={6}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                            name={name}
                            label={'Form Display Name'}
                            error={error?.message && ({
                                pointing: 'below',
                                content: error.message
                            })} 
                        />
                    )} />
            </Form.Group>
        </Grid.Column>
    </Grid>
)
