import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function companyReducer(state = initialState, action) {
    switch (action.type) {

        case getNamedAction(ACTION_NAMES.COMPANY, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCompany: true }
        case getNamedAction(ACTION_NAMES.COMPANY, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {
                ...state, isReadingCompany: false, company: {
                    ...action.data,
                    email: action.data.email || '',
                    website: action.data.website || '',
                    ein: action.data.ein || '',
                    locations: action.data.locations.data,
                    phones: action.data.phones.data,
                    social_medias: action.data.social_medias.data,
                }
            }
        case getNamedAction(ACTION_NAMES.COMPANY, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCompany: false, company: {} }

        case getNamedAction(ACTION_NAMES.COMPANY, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingCompany: true }
        case getNamedAction(ACTION_NAMES.COMPANY, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingCompany: false }
        case getNamedAction(ACTION_NAMES.COMPANY, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingCompany: false }

        case getNamedAction(ACTION_NAMES.COMPANY, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingCompany: true }
        case getNamedAction(ACTION_NAMES.COMPANY, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingCompany: false }
        case getNamedAction(ACTION_NAMES.COMPANY, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingCompany: false }

        case getNamedAction(ACTION_NAMES.COMPANY_LOCATIONS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCompanyLocations: true }
        case getNamedAction(ACTION_NAMES.COMPANY_LOCATIONS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingCompanyLocations: false, locations: action.data.data }
        case getNamedAction(ACTION_NAMES.COMPANY_LOCATIONS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCompanyLocations: false, locations: [] }

        case getNamedAction(ACTION_NAMES.COMPANY_LOCATIONS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingCompanyLocations: true }
        case getNamedAction(ACTION_NAMES.COMPANY_LOCATIONS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingCompanyLocations: false }
        case getNamedAction(ACTION_NAMES.COMPANY_LOCATIONS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingCompanyLocations: false }

        case getNamedAction(ACTION_NAMES.COMPANY_LOCATIONS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingCompanyLocation: true }
        case getNamedAction(ACTION_NAMES.COMPANY_LOCATIONS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingCompanyLocation: false }
        case getNamedAction(ACTION_NAMES.COMPANY_LOCATIONS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingCompanyLocation: false }

        case getNamedAction(ACTION_NAMES.COMPANY_LOCATIONS, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingCompanyLocation: true }
        case getNamedAction(ACTION_NAMES.COMPANY_LOCATIONS, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingCompanyLocation: false }
        case getNamedAction(ACTION_NAMES.COMPANY_LOCATIONS, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingCompanyLocation: false }


        case getNamedAction(ACTION_NAMES.COMPANY_PHONES, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCompanyPhones: true }
        case getNamedAction(ACTION_NAMES.COMPANY_PHONES, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingCompanyPhones: false, phones: action.data.data }
        case getNamedAction(ACTION_NAMES.COMPANY_PHONES, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCompanyPhones: false, phones: [] }

        case getNamedAction(ACTION_NAMES.COMPANY_PHONES, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingCompanyPhones: true }
        case getNamedAction(ACTION_NAMES.COMPANY_PHONES, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingCompanyPhones: false }
        case getNamedAction(ACTION_NAMES.COMPANY_PHONES, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingCompanyPhones: false }

        case getNamedAction(ACTION_NAMES.COMPANY_PHONES, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingCompanyPhone: true }
        case getNamedAction(ACTION_NAMES.COMPANY_PHONES, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingCompanyPhone: false }
        case getNamedAction(ACTION_NAMES.COMPANY_PHONES, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingCompanyPhone: false }

        case getNamedAction(ACTION_NAMES.COMPANY_PHONES, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingCompanyPhone: true }
        case getNamedAction(ACTION_NAMES.COMPANY_PHONES, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingCompanyPhone: false }
        case getNamedAction(ACTION_NAMES.COMPANY_PHONES, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingCompanyPhone: false }


        case getNamedAction(ACTION_NAMES.COMPANY_SOCIAL_MEDIAS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCompanySocialMedias: true }
        case getNamedAction(ACTION_NAMES.COMPANY_SOCIAL_MEDIAS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingCompanySocialMedias: false, social_medias: action.data.data }
        case getNamedAction(ACTION_NAMES.COMPANY_SOCIAL_MEDIAS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCompanySocialMedias: false, social_medias: [] }

        case getNamedAction(ACTION_NAMES.COMPANY_SOCIAL_MEDIAS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingCompanySocialMedias: true }
        case getNamedAction(ACTION_NAMES.COMPANY_SOCIAL_MEDIAS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingCompanySocialMedias: false }
        case getNamedAction(ACTION_NAMES.COMPANY_SOCIAL_MEDIAS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingCompanySocialMedias: false }

        case getNamedAction(ACTION_NAMES.COMPANY_SOCIAL_MEDIAS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingCompanySocialMedia: true }
        case getNamedAction(ACTION_NAMES.COMPANY_SOCIAL_MEDIAS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingCompanySocialMedia: false }
        case getNamedAction(ACTION_NAMES.COMPANY_SOCIAL_MEDIAS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingCompanySocialMedia: false }

        case getNamedAction(ACTION_NAMES.COMPANY_SOCIAL_MEDIAS, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingCompanySocialMedia: true }
        case getNamedAction(ACTION_NAMES.COMPANY_SOCIAL_MEDIAS, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingCompanySocialMedia: false }
        case getNamedAction(ACTION_NAMES.COMPANY_SOCIAL_MEDIAS, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingCompanySocialMedia: false }

        case getNamedAction(ACTION_NAMES.COMPANY_INDUSTRY, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCompanyIndustry: true }
        case getNamedAction(ACTION_NAMES.COMPANY_INDUSTRY, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingCompanyIndustry: false, industry: action.data.data }
        case getNamedAction(ACTION_NAMES.COMPANY_INDUSTRY, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCompanyIndustry: false, industry: null }

        case getNamedAction(ACTION_NAMES.COMPANY_SIGNATURE, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingSignatures: true, signatures: [], signature_count: 0 };
        case getNamedAction(ACTION_NAMES.COMPANY_SIGNATURE, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, signatures: (action.data.data || []), signature_count: (action.data.count || 0), isReadingSignatures: false };
        case getNamedAction(ACTION_NAMES.COMPANY_SIGNATURE, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingSignatures: false, signatures: [], signature_count: 0 };

        case getNamedAction(ACTION_NAMES.COMPANY_SIGNATURE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingSignature: true };
        case getNamedAction(ACTION_NAMES.COMPANY_SIGNATURE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingSignature: false };
        case getNamedAction(ACTION_NAMES.COMPANY_SIGNATURE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingSignature: false };

        case getNamedAction(ACTION_NAMES.COMPANY_SIGNATURE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, signatureBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.COMPANY_SIGNATURE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, signatureBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.COMPANY_SIGNATURE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, signatureBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.COMPANY_SIGNATURE, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, signatureBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.COMPANY_SIGNATURE, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, signatureBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.COMPANY_SIGNATURE, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, signatureBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.COMPANY_SIGNATURE_PREFERENCES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingSignaturePreferences: true };
        case getNamedAction(ACTION_NAMES.COMPANY_SIGNATURE_PREFERENCES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {
                ...state, isReadingSignaturePreferences: false,
                signature_preferences: {
                    reply_forward_email: action.data.signature_id_reply_forward_email,
                    new_email: action.data.signature_id_new_email
                }
            };
        case getNamedAction(ACTION_NAMES.COMPANY_SIGNATURE_PREFERENCES, CRUD_ACTION_TYPES.READ_FAILURE):
            return {
                ...state, isReadingSignaturePreferences: false, signature_preferences: {
                    new_email: 0,
                    reply_forward_email: 0
                }
            };

        case getNamedAction(ACTION_NAMES.COMPANY_SIGNATURE_PREFERENCES, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingSignaturePreferences: true };
        case getNamedAction(ACTION_NAMES.COMPANY_SIGNATURE_PREFERENCES, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingSignaturePreferences: false };
        case getNamedAction(ACTION_NAMES.COMPANY_SIGNATURE_PREFERENCES, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingSignaturePreferences: false };

        case getNamedAction(ACTION_NAMES.COMPANY_LOGO, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCompanyLogo: true }
        case getNamedAction(ACTION_NAMES.COMPANY_LOGO, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCompanyLogo: false, logo: action.data.image }
        case getNamedAction(ACTION_NAMES.COMPANY_LOGO, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCompanyLogo: false, logo: null }

        case getNamedAction(ACTION_NAMES.COMPANY_LOGO, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isUploadingCompanyLogo: true }
        case getNamedAction(ACTION_NAMES.COMPANY_LOGO, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isUploadingCompanyLogo: false }
        case getNamedAction(ACTION_NAMES.COMPANY_LOGO, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isUploadingCompanyLogo: false }

        case getNamedAction(ACTION_NAMES.COMPANY_LOGO, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingCompanyLogo: true }
        case getNamedAction(ACTION_NAMES.COMPANY_LOGO, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingCompanyLogo: false }
        case getNamedAction(ACTION_NAMES.COMPANY_LOGO, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingCompanyLogo: false }

        default:
            return state;
    }
}

export default companyReducer;
