import React from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { EmptyListPlaceholder, RecordsTable } from "dyl-components";

import LeadsHeader from "./LeadsHeader";
import LeadsRow from "./LeadsRow";
import { Segment } from "semantic-ui-react";
import { StringUtils } from "utils";

const LeadsTabTable = ({ isColumnHidden, onPageChange, routeRoot }) => {
    const [params] = useSearchParams();

    const {
        count,
        leads = [],
        isReadingLeads,
    } = useSelector((state) => state.leads_tab);
    const account_type = useSelector(
        (state) => state.account.account?.account_type || ""
    );

    const displayEmptyListTitle = () => {
        const filter = params.get("filter");
        let title = `No Leads `;
        switch (filter) {
            case "open":
                return (title += "Open");
            case "won":
                return (title += "Won");
            case "lost":
                return (title += "Lost");
            default:
                return (title += `Created Under ${StringUtils.capitalize(
                    account_type
                )} Contact`);
        }
    };

    const queryParamsCondition =
        params.get("pipeline_id") || params.get("start") || params.get("end");
    const shouldRenderTable = count !== 0 || queryParamsCondition;

    return shouldRenderTable ? (
        <RecordsTable
            tableBodyClassName="ModulesTabTable"
            isTableLoading={isReadingLeads}
            isSearchResultEmpty={count === 0 && queryParamsCondition}
            isTableEmpty={count === 0 && !queryParamsCondition}
            TableHeader={
                <LeadsHeader
                    isColumnHidden={isColumnHidden}
                    routeRoot={routeRoot}
                />
            }
            TableRows={leads.map((lead) => (
                <LeadsRow
                    key={lead.pipeline_id}
                    lead={lead}
                    isColumnHidden={isColumnHidden}
                />
            ))}
            recordsCount={count}
            activePage={params.get("page")}
            onPageChange={onPageChange}
            recordsName="Lead"
            emptySubtitle=" "
            emptyListTitle={displayEmptyListTitle()}
        />
    ) : (
        <React.Fragment>
            {isReadingLeads ? (
                <Segment basic loading />
            ) : (
                <EmptyListPlaceholder
                    title={displayEmptyListTitle()}
                    subtitle={" "}
                />
            )}
        </React.Fragment>
    );
};

export default LeadsTabTable;
