import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Table, DateTimeUtils, Person, TableLoader } from 'dyl-components';

import quoteActions from "actions/quote";

import { StringUtils } from "utils";

import QuoteID from 'shared/QuoteID';
import { QuoteBuilderContext } from 'shared/context/QuoteBuilderProvider';

import QuotesTableSubrow from './QuotesTableSubrow';

const QuotesTableRow = ({ quote }) => {
    const { onEditQuote } = useContext(QuoteBuilderContext);

    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const module_id = params.get('module_id');

    const { isReading: isReadingVersions, versions } = useSelector(
        (state) => {
            return ({
                contact_id: state.module_information.pipeline.contact.id,
                account_id: state.module_information.pipeline.contact?.account?.id,
                ...(state.quote.quoteVersionsSummary[quote.quote_id] || {
                    isReading: false,
                    versions: [],
                })
            })
        }
    );

    const [collapsed, setCollapsed] = useState(true);

    const refreshVersions = async () => {
        dispatch(quoteActions.getVersionsSummary(quote.quote_id, { opportunity_id: module_id }));
    };

    const onToggle = () => {
        setCollapsed(!collapsed);
        if (collapsed) {
            refreshVersions();
        }
    };

    const formatStatus = (status) => {
        const statusSplit = status.split('/');

        if (statusSplit[0] !== 'rejected') {
            return StringUtils.capitalize(statusSplit[0]);
        } else {
            const rejectReason = statusSplit[1].split('-');
            return `${StringUtils.capitalize(statusSplit[0])}/${rejectReason[0] === 'price' ? 'Priced' : StringUtils.capitalize(rejectReason[0])} ${rejectReason.slice(1, rejectReason.length).join(' ')}`;
        }
    }

    return (
        <>
            <Table.CollapsibleRow
                onToggle={onToggle}
                collapsed={collapsed}
                toggleDisabled={!quote?.pdf_id}
                subrowContent={
                    isReadingVersions ? (
                        <TableLoader colspan={10} isLoading />
                    ) : (
                        <QuotesTableSubrow
                            versions={versions}
                        />
                    )
                }
            >
                <Table.Cell>
                    <div>
                        <b>
                            {DateTimeUtils.formatEpoch(
                                quote.created,
                                DateTimeUtils.WORD_DATE_FORMAT
                            )}
                        </b>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div>
                        <b>
                            {DateTimeUtils.formatEpoch(
                                quote.last_modified,
                                DateTimeUtils.WORD_DATE_FORMAT
                            )}
                        </b>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div>
                        <b>
                            {quote.expires && (DateTimeUtils.formatEpoch(
                                quote.expires,
                                DateTimeUtils.WORD_DATE_FORMAT
                            ))}
                        </b>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <Person
                        username={quote.created_by}
                        email={quote.created_by_email}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Person
                        username={quote.last_modified_by}
                        email={quote.last_modified_by_email}
                    />
                </Table.Cell>
                <Table.Cell>
                    <QuoteID
                        hasPdf={false}
                        onEditQuote={() => {
                            onEditQuote(quote.quote_id);
                        }}
                        id={quote.quote_id}
                    />
                </Table.Cell>
                <Table.Cell>
                    {formatStatus(
                        quote.quote_status
                    )}
                </Table.Cell>
                <Table.Cell>
                    {quote.product_count}
                </Table.Cell>
                <Table.Cell>
                    {quote.one_time_amount &&
                        `$${StringUtils.formatDecimal(String(quote.subtotal), true)}`}
                </Table.Cell>
                <Table.Cell>
                    {quote.one_time_amount &&
                        `$${StringUtils.formatDecimal(String(quote.one_time_amount), true)}`}
                </Table.Cell>
                <Table.Cell>
                    {quote.recurring_amount &&
                        `$${StringUtils.formatDecimal(String(quote.recurring_amount), true)}`}
                </Table.Cell>
            </Table.CollapsibleRow>
        </>
    );
}

export default QuotesTableRow;
