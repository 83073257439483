import React from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { BulkTextMessageModal, RefreshTextCampaignModal } from 'shared/modals';
import './CampaignText.scss';

const CampaignText = ({ onOpenBulkTextMessageModal, onOpenTextCampaignFilterModal, leadId = 'test' }) => (
    <Button.Group basic style={{ backgroundColor: 'white' }}>
        <Dropdown
            text='Refresh Campaign'
            icon='list'
            button
            className='CampaignText__Dropdown'
        >
            <Dropdown.Menu>
                <Dropdown.Item>Mark as Complete</Dropdown.Item>
                <Dropdown.Item onClick={onOpenTextCampaignFilterModal}>Filter</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        <Button onClick={onOpenBulkTextMessageModal}>Text Campaign</Button>
    </Button.Group>
)


class CampaignTextContainer extends React.Component {
    state = {
        isBulkTextMessageModalOpen: false,
        isTextCampaignFilterModalOpen: false,
        replied: null
    }

    onChange = (_, { name, value }) => {
        this.setState({
            [name]: value
        });
    }

    onOpenBulkTextMessageModal = () => {
        this.setState({ isBulkTextMessageModalOpen: true });
    }

    onCloseBulkTextMessageModal = () => {
        this.setState({
            isBulkTextMessageModalOpen: false,
        });
    }

    onOpenTextCampaignFilterModal = () => {
        this.setState({ isTextCampaignFilterModalOpen: true });
    }

    onCloseTextCampaignFilterModal = () => {
        this.setState({
            isTextCampaignFilterModalOpen: false,
        });
    }

    render() {
        return (
            <React.Fragment>
                <CampaignText
                    onOpenBulkTextMessageModal={this.onOpenBulkTextMessageModal}
                    onOpenTextCampaignFilterModal={this.onOpenTextCampaignFilterModal} />
                <BulkTextMessageModal
                    open={this.state.isBulkTextMessageModalOpen}
                    onClose={this.onCloseBulkTextMessageModal}
                    contacts={8}
                />
                <RefreshTextCampaignModal
                    open={this.state.isTextCampaignFilterModalOpen}
                    onClose={this.onCloseTextCampaignFilterModal}
                    onChange={this.onChange}
                    {...this.state}
                />
            </React.Fragment>
        );
    }
}

export default CampaignTextContainer;
