import React, { useEffect } from 'react';
import { Divider, Grid, GridColumn, Header } from 'semantic-ui-react';
import { Modal, STATUS_TYPES, Notification, GeneralNotes, VALIDATORS, generateResolver, TableLoader, DateTimeUtils } from 'dyl-components';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux'; 
import noteType from 'shared/Notes/NoteType';


import notesActions from 'actions/notes';

const NotesModal = ({
    open,
    onClose,
    contact,
    account
}) => {
    const dispatch = useDispatch();
    const { notes, isReadingNotes }  = useSelector(state => state.notes);
    const { control, formState: { isValid, isDirty }, getValues, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            note: '',
        },
        resolver: generateResolver({
            note: VALIDATORS.NOTE().required('This field is required'),
        })
    });

    useEffect(() => {
        if(contact){
            dispatch(notesActions.notesTab(contact.id, { limit: 3 }));
        }
        if(account && account.account_type !== 'Master account'){
            dispatch(notesActions.notesAccountTab(account.id, { limit: 3 }));
        }
        if(account && account.account_type === 'Master account'){
            dispatch(notesActions.notesMasterAccountTab(account.id, { limit: 3 }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const onAddNote = async () => {
        const { note } = getValues();
        try {
            await dispatch(notesActions.addNote({ note, sticky: false }, { contact_id: contact?.id || account.id }));
            Notification.alert('Successfully added a note!', STATUS_TYPES.SUCCESS, true);
            reset();
            if(contact){
                dispatch(notesActions.notesTab(contact.id, { limit: 3 }));
            }
            if(account && account.account_type !== 'Master account'){
                dispatch(notesActions.notesAccountTab(account.id, { limit: 3 }));
            }
            if(account && account.account_type === 'Master account'){
                dispatch(notesActions.notesMasterAccountTab(account.id, { limit: 3 }));
            }
        } catch (e) {
            Notification.alert('Failed to add a note', STATUS_TYPES.ERROR, true);
            console.log(e);
        }
    }

    const NoteView = ({ note }) => {
        const name = `${note?.user?.first_name} ${note?.user?.last_name}`;
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={12}>
                        <Header as='h5' style={{marginBottom: 0}}>{name}</Header>
                        <div>{DateTimeUtils.formatEpoch(note.ts, DateTimeUtils.WORD_DATETIME_FORMAT)}</div>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        {noteType(note.sub_type, note?.task_name, note?.contact_id)}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <GridColumn>
                        {note.note}
                    </GridColumn>
                </Grid.Row>
            </Grid>
        )
    }
    const name = contact ? contact.contact_name ? contact.contact_name : `${contact?.first_name} ${contact?.last_name}` : account ? account.name : 'N/A';
    return (
        <Modal open={open} animated onClose={onClose} size='small'>
            <Modal.Header basic>
                Add General Note for '{name}'
            </Modal.Header>
            <Modal.Content scrolling>
                <GeneralNotes
                    style={{padding: 0}}
                    control={control}
                    onAddNote={onAddNote}
                    disabled={!isValid || !isDirty}
                />
                <Divider />
                {
                    isReadingNotes
                        ? <TableLoader isLoading colspan={6} />
                        : notes.map((note, key)=> {
                            return(
                                <>
                                    <NoteView key={note.id} note={note} />
                                    {key !== (notes.length - 1) && <Divider /> }
                                </>
                            )}
                        )
                }
            </Modal.Content>
        </Modal>
    )
}

export default NotesModal;
