import productCategoryActions from "actions/product_category";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Icon, Menu, Tab } from "semantic-ui-react";
import Products from "./Products";

import "./index.scss";

const CatalogWizard = ({ opportunity_id }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(productCategoryActions.readProductCategories());
    }, [dispatch]);

    return (
        <Tab 
            panes={[
                {
                    menuItem: <Menu.Item><Icon className="fas fa-box" /> Products</Menu.Item>,
                    render: () => (
                        <Products opportunity_id={opportunity_id} />
                    )
                },
                {
                    menuItem: <Menu.Item><Icon className="fas fa-boxes-stacked" /> Bundles</Menu.Item>,
                    render: () => (
                        <Tab.Pane>

                        </Tab.Pane>
                    )
                }
            ]}
            menu={{ color: 'blue', secondary: true, pointing: true }}
            defaultActiveIndex={0}
        />
    )
}

export default CatalogWizard;
