import React from 'react';

import CustomerPipelinesHeader from './CustomerPipelinesHeader';
import CustomerPipelinesRow from './CustomerPipelinesRow';

import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { EmptyListPlaceholder, RecordsTable } from 'dyl-components';
import { Segment } from 'semantic-ui-react';

import { StringUtils } from 'utils';

const CustomerTabTable = ({ onPageChange, routeRoot }) => {
    const [params] = useSearchParams();

    const { pipelines = [], count = 0, isReadingCustomerPipelines } = useSelector(state => state.customer_tab);

    const displayEmptyListTitle = () => {
        const filter = StringUtils.capitalize(params.get('filter') || '');
        return `No` + (filter ? ` ${filter} ` : ' ') + `Customer Pipelines`;
    }

    const queryParamsCondition = params.get('start') || params.get('end');
    const shouldRenderTable = count !== 0 || queryParamsCondition;

    return (
        shouldRenderTable ?
            (<>
                <RecordsTable
                    tableBodyClassName='CustomerPipeline__timeline'
                    isTableLoading={isReadingCustomerPipelines}
                    isSearchResultEmpty={count === 0 && (queryParamsCondition)}
                    isTableEmpty={count === 0 && !(queryParamsCondition)}
                    TableHeader={<CustomerPipelinesHeader routeRoot={routeRoot} />}
                    TableRows={pipelines.map(pipeline => (
                        <CustomerPipelinesRow
                            key={pipeline.pipeline_id}
                            pipeline={pipeline}
                        />
                    ))}
                    recordsCount={count}
                    activePage={params.get('page')}
                    onPageChange={onPageChange}
                    recordsName='Customer Pipeline'
                    emptySubtitle=' '
                    emptyListTitle={displayEmptyListTitle()}
                />
            </>)
            :
            <React.Fragment>
                {isReadingCustomerPipelines ? <Segment basic loading /> : <EmptyListPlaceholder title={displayEmptyListTitle()} subtitle={' '} />}
            </React.Fragment>
    )
}

export default CustomerTabTable;
