const applyCurrencySchema = (schema, message = "Please enter a valid price", min = 0.01) =>
    schema
        .typeError(message)
        .min(min, message)
        .test(
            "maxDigitsAfterDecimal",
            "Please enter a valid price",
            (number) => /^\d+(\.\d{1,2})?$/.test(number)
        );

export default applyCurrencySchema;
