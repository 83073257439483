import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { SearchBar } from 'dyl-components';

const WebFormsToolbar = ({ onChange, value, onReadFilters, isReadingWebForms, onClearSearch }) => {
    return (
        <div className='WebFormsToolbar'>
            <SearchBar
                onChange={onChange}
                isSearching={isReadingWebForms}
                search={value}
                searchFcn={onReadFilters}
                cancelFcn={onClearSearch}
                placeholder={'Search by Internal Form Name'}
            />

            <Button as={Link} to="/settings/web-forms/create" className='WebFormsToolbar__NewWebFormButton' ><Icon name='plus' /> New Web Form</Button>
        </div>
    )
}

export default WebFormsToolbar;
