import React from 'react';
import { Grid, Icon, Popup } from 'semantic-ui-react';
import { ClippedContent, ButtonLink } from 'dyl-components';
import { StringUtils } from 'utils';

const QuoteID = ({ id, onViewProposal, hasPdf, onEditQuote }) => {
    return (
        <Grid columns={'equal'}>
            <Grid.Column width={6}>
                <ClippedContent popupContent={id}>
                    <ButtonLink
                        noPadding
                        onClick={onEditQuote}
                    >
                        {id}
                    </ButtonLink>
                </ClippedContent>
            </Grid.Column>
            <Grid.Column>
                <Popup
                    trigger={(
                        <Icon link className='fas fa-copy' color="blue" onClick={StringUtils.copy(id)} />
                    )}
                    content='Copied!'
                    on='click'
                    closeOnTriggerMouseLeave
                    inverted
                />
                {hasPdf && (
                    <Icon onClick={onViewProposal} link className="fa-solid fa-file-pdf" color="blue" />
                )}
            </Grid.Column>
        </Grid>
    );
}

export default QuoteID;
