const initialState = {
    company: {},
    isReadingCompany: false,
    isCreatingCompany: false,
    isUpdatingCompany: false,

    locations: [],
    isReadingCompanyLocations: false,
    isCreatingCompanyLocations: false,
    isUpdatingCompanyLocation: false,
    isDeletingCompanyLocation: false,

    phones: [],
    isReadingCompanyPhones: false,
    isCreatingCompanyPhones: false,
    isUpdatingCompanyPhone: false,
    isDeletingCompanyPhone: false,

    social_medias: [],
    isReadingCompanySocialMedias: false,
    isCreatingCompanySocialMedias: false,
    isUpdatingCompanySocialMedia: false,
    isDeletingCompanySocialMedia: false,

    industry: null,
    isReadingCompanyIndustry: false,

    signatures: [],
    signature_count: 0,
    isCreatingSignature: false,
    signatureBeingUpdated: null,
    signatureBeingDeleted: null,

    signature_preferences: {
        new_email: 0,
        reply_forward_email: 0
    },
    isReadingSignaturePreferences: false,
    isUpdatingSignaturePreferences: false,

    logo: null,
    isReadingCompanyLogo: false,
    isUploadingCompanyLogo: false,
    isDeletingCompanyLogo: false,
}

export default initialState;
