import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function leadTypesReducer(state = initialState, action){
    
    switch(action.type){
        case getNamedAction(ACTION_NAMES.LEAD_TYPES, CRUD_ACTION_TYPES.READ_REQUEST):
            return {...state, isReadingLeadTypes: true}
        case getNamedAction(ACTION_NAMES.LEAD_TYPES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {...state, isReadingLeadTypes: false, leadTypes: action.data}
        case getNamedAction(ACTION_NAMES.LEAD_TYPES, CRUD_ACTION_TYPES.READ_FAILURE):
            return {...state, isReadingLeadTypes: false}
        default:
            return state
    }

}

export default leadTypesReducer;
