import React from 'react';
import { CheckboxDropdown } from 'dyl-components';
import { Controller } from 'react-hook-form';
import { Form, Icon } from 'semantic-ui-react';

const renderDeleteButton = (deleteButtonAction) => (
    <Icon name='x' className='Field__deleteButton' link onClick={deleteButtonAction} />
);

const Field = ({ type, name, label, itemIndex, valueIndex, control, required, maxLength, value, placeholder, options = [], shouldRenderDeleteButton, deleteButtonAction, isDirty, checkDuplicate, modifyCheckForDuplicateProps, getFieldDuplicateProps, isDisabled = false }) => {
    switch (type) {
        case 'input':
            return <Controller
                name={`${name}.${itemIndex}.value.${valueIndex}`}
                control={control}
                defaultValue={value}
                rules={{
                    required: {
                        value: required,
                        message: 'This field should not be empty'
                    },
                    maxLength: {
                        value: maxLength,
                        message: `This field should contain at most ${maxLength} characters`
                    },
                    pattern: {
                        value: /^[A-Za-z0-9:\-\s]+$/,
                        message: 'This field should contain only alphanumeric characters'
                    },
                    validate: {
                        no_excessive_whitespaces: (value) => {
                            return ((value === undefined || value?.length === 0)) ||
                                (value?.length !== 0 && !!value?.trim()) ||
                                'This field cannot only contain white spaces'
                        },
                        no_duplicate: (value) => {
                            if (getFieldDuplicateProps(itemIndex, 'lastLabelChecked') !== value) {
                                modifyCheckForDuplicateProps('update_checkDuplicateTimeoutHandler', getFieldDuplicateProps(itemIndex, 'id'),
                                    () => { checkDuplicate(value, `${name}.${itemIndex}.value.${valueIndex}`, itemIndex, valueIndex, getFieldDuplicateProps(itemIndex, 'id')) }
                                );
                            }

                            if (value === '' || getFieldDuplicateProps(itemIndex, 'lastLabelChecked') === value) {
                                modifyCheckForDuplicateProps('update_isChecking', getFieldDuplicateProps(itemIndex, 'id'), false);
                            }

                            return !getFieldDuplicateProps(itemIndex, 'hasDuplicate') ||
                                getFieldDuplicateProps(itemIndex, 'duplicateMessage');
                        }
                    },

                }}
                render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                    <React.Fragment>
                        <Form.Field>
                            <label>
                                {label}
                                {required &&
                                    <div style={{ display: 'inline-flex', verticalAlign: 'text-top', marginLeft: 2 }}>
                                        <Icon name='asterisk' color='red' size='mini' />
                                    </div>
                                }
                            </label>
                            <Form.Input
                                required={required}
                                name={name}
                                value={value}
                                onChange={(_, { name, value }) => {
                                    modifyCheckForDuplicateProps('update_isChecking', getFieldDuplicateProps(itemIndex, 'id'),
                                        value.replace(/\s+/g, ' ').trim()
                                    );
                                    onChange({ target: { name, value } });
                                }}
                                placeholder={placeholder}
                                error={isDirty && error && error.message && {
                                    content: error.message
                                }}
                                loading={getFieldDuplicateProps(itemIndex, 'isChecking')}
                                disabled={isDisabled}
                            />
                        </Form.Field>
                        {shouldRenderDeleteButton && renderDeleteButton(deleteButtonAction)}
                    </React.Fragment>
                )}
            />
        case 'checkbox_dropdown':
            return <Controller
                name={`${name}.${itemIndex}.value.${valueIndex}`}
                control={control}
                defaultValue={value}
                rules={{
                    required: {
                        value: required,
                        message: 'This field should not be empty'
                    }
                }}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <React.Fragment>
                        <Form.Field>
                            <label>
                                {label}
                                {required &&
                                    <div style={{ display: 'inline-flex', verticalAlign: 'text-top', marginLeft: 2 }}>
                                        <Icon name='asterisk' color='red' size='mini' />
                                    </div>
                                }
                            </label>
                            <Form.Field
                                required={required}
                                control={CheckboxDropdown}
                                fluid
                                onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                placeholder={placeholder}
                                name={name}
                                value={value}
                                selection
                                options={options}
                                text={value && value.length > 0 ? options.filter(option => value.includes(option.value)).map(option => option.text).join(', ') : ''}
                                error={isDirty && error && error.message && {
                                    content: error.message
                                }}
                                disabled={isDisabled}
                            />
                        </Form.Field>
                        {shouldRenderDeleteButton && renderDeleteButton(deleteButtonAction)}
                    </React.Fragment>
                )}
            />
        case 'select':
            return <Controller
                name={`${name}.${itemIndex}.value.${valueIndex}`}
                control={control}
                defaultValue={value}
                rules={{
                    required: {
                        value: required,
                        message: 'This field should not be empty'
                    }
                }}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (

                    <React.Fragment>
                        <Form.Field>
                            <label>
                                {label}
                                {required &&
                                    <div style={{ display: 'inline-flex', verticalAlign: 'text-top', marginLeft: 2 }}>
                                        <Icon name='asterisk' color='red' size='mini' />
                                    </div>
                                }
                            </label>
                            <Form.Select
                                required={required}
                                onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                placeholder={placeholder}
                                name={name}
                                value={value}
                                options={options}
                                error={isDirty && error && error.message && {
                                    content: error.message
                                }}
                                disabled={isDisabled}
                            />
                        </Form.Field>
                        {shouldRenderDeleteButton && renderDeleteButton(deleteButtonAction)}
                    </React.Fragment>


                )}
            />
        default:
            return <React.Fragment />
    }
}

export default Field;

