import {
    Text,
    View,
} from "@react-pdf/renderer";
import { styles } from "./styles";

const PersonInformation = ({ info }) => {
    const { location } = info;
    return (
        <View style={styles.personInfo}>
            <Text style={styles.header}>{info.heading}</Text>
            {info.subheading && <Text style={styles.subheader}>{info.subheading}</Text>}
            {info.others && <Text style={styles.subheader}>{info.others}</Text>}
            <Text style={styles.text}>
                {location?.street ? `${location.street} ` : ""}
                {location?.additional_street
                    ? `${location.additional_street} `
                    : ""}
            </Text>
            <Text style={styles.text}>
                {location?.city ? `${location.city}, ` : ""}
                {location?.state ? `${location.state} ` : ""}
                {location?.zip || ""}
            </Text>
            <Text style={styles.text}>Phone: {info.phone}</Text>
            <Text style={styles.text}>Email: {info.email || "N/A"}</Text>
        </View>
    );
};

export default PersonInformation;
