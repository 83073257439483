import { useState } from "react";
import {
    Segment,
    Header,
    Icon,
    Popup,
    Table as SemanticTable,
} from "semantic-ui-react";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { ControlledPopup, Table, TableLoader } from "dyl-components";

import ProductAdditions from "./ProductAdditions";
import { StringUtils } from "utils";

const Addons = () => {
    const [isAddonModalOpen, setIsAddonModalOpen] = useState(false);
    const { control } = useFormContext();

    const onOpenAddonModal = () => {
        setIsAddonModalOpen(true);
    };

    const onCloseAddonModal = () => {
        setIsAddonModalOpen(false);
    };

    const { addonOptions, isReadingAddons } = useSelector((state) => ({
        addonOptions: state.product_addons.addons,
        isReadingAddons: state.product_addons.isReadingAddons,
    }));

    return (
        <Segment>
            <Header color="primary" as="h3">
                <span>
                    <Icon
                        className="fas fa-plus-circle"
                        disabled={isReadingAddons || !addonOptions?.length}
                        onClick={onOpenAddonModal}
                        link
                    />{" "}
                    Add-ons
                </span>
            </Header>
            <Controller
                name="addons"
                control={control}
                render={({ field: { name, value: addons, onChange } }) => {
                    if (!addons?.length) {
                        return (
                            <Segment.Inline>No add-ons added</Segment.Inline>
                        );
                    }
                    return (
                        <Table striped={false}>
                            <SemanticTable.Header className="Table__header--primary">
                                <Table.Row>
                                    <SemanticTable.HeaderCell width={3}>
                                        Add-on Name
                                    </SemanticTable.HeaderCell>
                                    <SemanticTable.HeaderCell width={3}>
                                        Amount
                                    </SemanticTable.HeaderCell>
                                    <SemanticTable.HeaderCell width={2}>
                                        Action
                                    </SemanticTable.HeaderCell>
                                </Table.Row>
                            </SemanticTable.Header>
                            <Table.Body>
                                {isReadingAddons ? (
                                    <TableLoader colspan={3} isLoading />
                                ) : (
                                    addons.map((addon) => (
                                        <Addon
                                            key={addon}
                                            addonDetails={
                                                addonOptions?.find(
                                                    (option) =>
                                                        option.id === addon
                                                ) || {}
                                            }
                                            onRemove={() => {
                                                onChange({
                                                    target: {
                                                        name,
                                                        value: addons.filter(
                                                            (selectedAddon) =>
                                                                selectedAddon !==
                                                                addon
                                                        ),
                                                    },
                                                });
                                            }}
                                        />
                                    ))
                                )}
                            </Table.Body>
                        </Table>
                    );
                }}
            />
            {isAddonModalOpen && (
                <Controller
                    control={control}
                    name="addons"
                    render={({
                        field: { name, value: selectedAddons, onChange },
                    }) => (
                        <ProductAdditions
                            open={isAddonModalOpen}
                            selectedEntries={selectedAddons}
                            onClose={onCloseAddonModal}
                            ModalHeader={
                                <ModalHeader
                                    isEditing={selectedAddons?.length > 0}
                                />
                            }
                            selectLabel="Addon Name"
                            isSelectLoading={isReadingAddons}
                            selectOptions={addonOptions.map((option) => ({
                                ...option,
                                text: `${option.name} ($${option.min_price})`
                            }))}
                            selectPlaceholder="Search addon name"
                            removalConfirmationMessage={
                                "Add-on(s) will be removed"
                            }
                            onSave={(updatedSelectedAddons) => {
                                onChange({
                                    target: {
                                        name,
                                        value: updatedSelectedAddons,
                                    },
                                });
                                onCloseAddonModal();
                            }}
                        />
                    )}
                />
            )}
        </Segment>
    );
};

const ModalHeader = ({ isEditing }) => (
    <>
        {isEditing ? "Edit Product Add-on" : "Add Product Add-on"}
        <Popup
            trigger={
                <Icon
                    size="small"
                    style={{
                        float: "right",
                        marginRight: "1em",
                        marginTop: "0.2em",
                    }}
                    className="fas fa-circle-info"
                    color="primary"
                />
            }
            content={"These add-ons are applied prior to sales tax."}
            inverted
            position="bottom right"
            size="mini"
        />
    </>
);

const Addon = ({ addonDetails, onRemove }) => {
    const { name: addonName, min_price, status } = addonDetails;

    return (
        <Table.Row>
            <Table.Cell>
                {status !== "active" && (
                    <Popup
                        content={StringUtils.capitalize(status)}
                        inverted
                        position="top center"
                        trigger={
                            <Icon
                                className="fas fa-info-circle"
                                color="orange"
                            />
                        }
                    />
                )}
                {addonName}
            </Table.Cell>
            <Table.Cell>${min_price}</Table.Cell>
            <Table.Cell>
                <ControlledPopup
                    trigger={<Icon className="fas fa-trash" color="red" link />}
                    onConfirm={onRemove}
                    content="Delete"
                    position="bottom center"
                />
            </Table.Cell>
        </Table.Row>
    );
};

export default Addons;
