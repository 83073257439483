import { useFieldArray, useFormContext } from "react-hook-form";
import PricingTier from "./PricingTier";
import { ButtonLink, Icon } from "dyl-components";
import bulkPricingTierSchema from "shared/schemas/products/bulkPricingTierSchema";

const VolumeBasedPricingTiers = ({ parentName }) => {
    const { control } = useFormContext();
    const {
        fields: tiers,
        append: addRange,
        update: updateRange,
        remove: removeRange,
    } = useFieldArray({
        name: parentName,
        control,
    });
    const addTier = async () => {
        const previousTier = tiers[tiers.length - 1];
        const start = (() => {
            const endOfPreviousTier = previousTier.end;
            if (endOfPreviousTier === "" || endOfPreviousTier === null) {
                return null
            };
            const isEndOfPreviousTierValid = bulkPricingTierSchema.end.isValid(previousTier.end);
            if (isEndOfPreviousTierValid) {
                return Number(endOfPreviousTier) + 1;
            }
            return null;
        })();
        addRange({
            start,
            end: null,
        });
    };
    return (
        <>
            {tiers.map((tier, index) => (
                <PricingTier
                    removeRange={removeRange}
                    currentTiers={tiers}
                    updateRange={updateRange}
                    addRange={addRange}
                    fieldName={parentName}
                    key={tier.id}
                    tier={tier}
                    index={index}
                />
            ))}
            <ButtonLink onClick={addTier}>
                <Icon className="fa-light fa-plus" color="grey" /> Add
                additional tier
            </ButtonLink>
        </>
    );
};

export default VolumeBasedPricingTiers;
