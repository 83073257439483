import routes from 'actions/routes';
import { generateCreateActionCreator, generateReadOneActionCreator, generateUpdateActionCreator, generateDeleteActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

export default {
    getAttachments: generateReadOneActionCreator(routes.API_EMAIL_TEMPLATES, ACTION_NAMES.EMAIL_ATTACHMENTS, 'email_attachment'),
    createAttachments: generateCreateActionCreator(routes.API_EMAIL_TEMPLATES, ACTION_NAMES.EMAIL_ATTACHMENTS, () => {}, () => {}, 'email_attachment'),
    updateAttachment: generateUpdateActionCreator(routes.API_EMAIL_ATTACHMENTS, ACTION_NAMES.EMAIL_ATTACHMENTS),
    deleteAttachment: generateDeleteActionCreator(routes.API_EMAIL_ATTACHMENTS, ACTION_NAMES.EMAIL_ATTACHMENTS)
}
