export function getNamedAction(name, type) {
    return `${name}|${type}`;
}

export default {
    CREATE_REQUEST: 'CREATE_REQUEST',
    CREATE_SUCCESS: 'CREATE_SUCCESS',
    CREATE_FAILURE: 'CREATE_FAILURE',

    READ_REQUEST: 'READ_REQUEST',
    READ_SUCCESS: 'READ_SUCCESS',
    READ_FAILURE: 'READ_FAILURE',

    READ_ONE_REQUEST: 'READ_ONE_REQUEST',
    READ_ONE_SUCCESS: 'READ_ONE_SUCCESS',
    READ_ONE_FAILURE: 'READ_ONE_FAILURE',

    UPDATE_REQUEST: 'UPDATE_REQUEST',
    UPDATE_SUCCESS: 'UPDATE_SUCCESS',
    UPDATE_FAILURE: 'UPDATE_FAILURE',
    
    DELETE_REQUEST: 'DELETE_REQUEST',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_FAILURE: 'DELETE_FAILURE'
}

