import React from 'react';
import RingGroupMemberRow from './RingGroupMemberRow';
import { Table } from 'dyl-components';

const RingGroupMembersTable = ({ members, onDelete, onToggleCalls, ring_group, isRingGroup }) => (
    <Table>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Extension</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Options</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {members.map(member => (
                <RingGroupMemberRow 
                    member={member}
                    key={member.id}
                    onDelete={onDelete}
                    onToggleCalls={onToggleCalls}
                    ring_group={ring_group}
                    isRingGroup={isRingGroup}
                />
            ))}
        </Table.Body>
    </Table>
);

export default RingGroupMembersTable;
