import React, { useContext, useState } from 'react';
import { Dropdown, Header, Icon, Popup, Ref } from 'semantic-ui-react';

import { useDispatch, useSelector } from 'react-redux';
import callActions from 'actions/call';
import { CustomGroupUtils, RecordUtils } from 'utils';
import {
    CloseableContent,
    DateTimeUtils,
    Modal,
    SelectionsModal
} from 'dyl-components';
import CallActivityForm from 'shared/forms/CallActivityForm';
import TextActivityForm from 'shared/forms/TextActivityForm';

import contactActions from 'actions/contact';
import selfReportActions from 'actions/self_report';
import sequenceTasksActions from 'actions/sequence_tasks';
import pipelineActions from "actions/pipeline";
import { NavLink, useLocation, useParams } from 'react-router-dom';

import 'shared/ProfileControls.scss';
import LinkExistingAccountForm from './LinkExistingAccountForm';
import AddAccountModal from "shared/modals/AddAccountModal";
import ChangeModulePipelineForm from './ChangeModulePipelineForm';
import UnlinkPopupForm from 'shared/UnlinkPopupForm';
import QuoteBuilderModal from 'shared/modals/QuoteBuilderModal';
import { QuoteBuilderContext } from 'shared/context/QuoteBuilderProvider';

const modalCategories = [
    {
        selections: [
            { key: 'BusinessAccount', text: 'Business Account', icon: 'fa-solid fa-building' },
            { key: 'HouseholdAccount', text: 'Household Account', icon: 'fa-solid fa-house-chimney-blank' }
        ]
    }
];
const AddModuleRecordsModalContent = ({ activeContent, onClose, contact_id, refreshFromLinking }) => {
    if (activeContent === 'BusinessAccount' || activeContent === 'HouseholdAccount') {
        return (
            <AddAccountModal
                account_type={activeContent === 'BusinessAccount' ? 'business' : 'household'}
                refreshFromLinking={refreshFromLinking}
                contactIdToLink={contact_id}
                onClose={onClose}
            />
        );
    }
    return null;
}

const generateIconProperties = (name, active, onChangeActive) => ({
    ...(active === name ? { color: 'blue' } : {}),
    onClick: () => { onChangeActive(name) },
    link: true
})

const Controls = () => {
    const { pathname } = useLocation();
    const activeTab = pathname.split('/')[3];

    const {
        parent_account,
        phone,
        name,
        canCall,
        canText,
        integration_id,
        from,
        mainPhone,
        current_pipeline_category,
        account_stage,

        master_source,
        secondary_source,
        module_custom_data,
        campaign_converted,
        isReadingCategories,
        canChangePipeline,
        account_id,
        primary_contact_id,
        isCustomerAccount
    } = useSelector(state => {
        const { first_name, last_name, suffix } = state.contact.contact || {};
        const custom_data = {};
        const contact_custom = state.contact.custom_data;
        contact_custom.fields.forEach(field => {
            custom_data[`fields|${field.field_name}`] = field.value;
        })
        const name = `${first_name || ''} ${last_name || ''}${suffix ? ` ${suffix}` : ''}`;
        const contactEmails = state.contact.email ? state.contact.email.slice(0).map(({ email }) => email) : [];

        let mainPhone = state.contact_phone.phone.find(item => item.main);

        if (!mainPhone) {
            mainPhone = state.contact_phone.phone[0] || { phone: 'No main phone' }
        }

        const { master_source, secondary_source, campaign_converted } = state.contact.current_stage || {};
   
        return {
            name,
            phone: state.contact_phone.phone[0] ? `${state.contact_phone.phone[0].phone}` : "",
            canCall: state.users.userProfile.cell_phone,
            canText: state.users.userProfile.cell_phone,
            integration_id: state.oauth_integrations?.activeIntegration?.id,
            from: contactEmails.length > 0 ? contactEmails.join() : null,
            mainPhone: mainPhone.phone,
            parent_account: state.contact.account,
            current_pipeline_category: state.contact.current_stage?.pipeline_category_id,
            account_stage: state.contact.current_stage?.account_stage || "contact",
            master_source,
            secondary_source: secondary_source?.id || null,

            module_custom_data: CustomGroupUtils.groupAndFlatten(state.contact.current_stage?.custom_data || {}),

            campaign_converted: campaign_converted && Object.keys(campaign_converted).length > 1 && (Boolean(campaign_converted.id) && Boolean(campaign_converted.name)) ? campaign_converted.id : null,
            isReadingCategories: state.pipeline.isReadingCategories,
            canChangePipeline: state.pipeline.categories?.length > 1,
            account_id: state.contact.account_id,
            primary_contact_id: state.contact.account.primary_contact_id,
            isCustomerAccount: state.contact.account.has_customer
        }
    });

    const dispatch = useDispatch();

    const [activeForm, setActiveForm] = useState('');

    const onChangeActiveForm = (activeForm) => {
        setActiveForm(activeForm);
    }

    const [isModulesModalOpen, setIsModulesModalOpen] = useState(false);

    const closeModal = () => {
        setIsModulesModalOpen(false);
    }

    const [isLinkAccountModalOpen, setIsLinkAccountModalOpen] = useState(false);

    const closeLinkAccountModal = () => {
        setIsLinkAccountModalOpen(false);
    }

    const { contact_id } = useParams();
    const { id: parent_account_id } = parent_account || {};

    const refresh = () => {
        dispatch(selfReportActions.readActivity({ contact_id, occurred: DateTimeUtils.getCurrentDate(null, true, false) }));
        if (activeTab === 'timeline') {
            const end = DateTimeUtils.getTimeUnitsFromToday(new Date(), 1, 'years', 'addition');
            dispatch(contactActions.readContactHistory(contact_id, { account_id: parent_account_id, start: DateTimeUtils.getLast(2, 'week'), end: DateTimeUtils.getUnixTime(`${end} 23:59:59`), integration_id, from }));
        }
    }

    const refreshFromLinking = () => {
        return dispatch(contactActions.readContact(contact_id));
    };

    const locationParam = () => `contact/${contact_id}`;

    const popupRef = React.createRef();

    const [openForm, setOpenForm] = useState(null);
    const [isPipelineFormModified, setIsPipelineFormModified] = useState(false);
    const onCloseForm = () => {
        setOpenForm(null);
        setIsPipelineFormModified(false);
    }

    const [isUnlinkAccountPopupOpen, setUnlinkAccountPopupOpen] = useState(false);
    const isPrimaryContact = isCustomerAccount && (primary_contact_id === Number(contact_id)); 

    const { onOpenAddProducts } = useContext(QuoteBuilderContext);

    return (
        <React.Fragment>
            {canCall ? (
                <Icon disabled={!phone} onClick={() => {
                    dispatch(callActions.call(RecordUtils.generateCallPayload(
                        contact_id,
                        phone
                    ), { name }));
                }} className='Profile__controls fas fa-phone' link rotated='counterclockwise' />
            ) : (
                <CallActivityForm
                    trigger={<Icon as={NavLink} to={`/contact/${contact_id}`} className={`Profile__controls ${activeForm === 'calls' ? 'Profile__controls--active' : ''} fas fa-phone`} {...generateIconProperties('calls', activeForm, onChangeActiveForm)} />}
                    to={mainPhone}
                    contact_id={contact_id}
                    refresh={refresh}
                />
            )}
            {canText ? (
                <Icon
                    as={NavLink}
                    to={`/${locationParam()}/texts`}
                    link
                    className='Profile__controls fas fa-comment'
                />
            ) : (
                <TextActivityForm
                    trigger={<Icon as={NavLink} to={`/contact/${contact_id}`} className={`Profile__controls ${activeForm === 'texts' ? 'Profile__controls--active' : ''} fas fa-comment`} {...generateIconProperties('texts', activeForm, onChangeActiveForm)} />}
                    to={mainPhone}
                    contact_id={contact_id}
                    refresh={refresh}
                />
            )}
            <Icon
                as={NavLink}
                to={`/${locationParam()}/emails`}
                link
                className='Profile__controls fas fa-envelope'
                color='grey'
            />
            <Icon
                as={NavLink}
                to={`/${locationParam()}/notes`}
                link
                className='Profile__controls fa-sharp fa-solid fa-pen-clip'
                color='grey'
            />
            <Popup
                open={openForm === 'change-pipeline'}
                position='bottom right'
                context={popupRef}
                onClose={onCloseForm}
                content={(
                    <>
                        <Header color='primary'>
                            Change Pipeline
                        </Header>
                        <CloseableContent onClose={onCloseForm}>
                            <ChangeModulePipelineForm
                                contact_id={contact_id}
                                onClose={onCloseForm}
                                refresh={() => { dispatch(contactActions.readContact(contact_id)); dispatch(sequenceTasksActions.readContactTasks(contact_id)); }}
                                current_pipeline_category={current_pipeline_category}
                                account_stage={account_stage}
                                account_id={parent_account_id}
                                campaign_converted={campaign_converted}
                                custom_data={module_custom_data}
                                master_source={master_source}
                                secondary_source={secondary_source}
                            />
                        </CloseableContent>
                    </>
                )}
                closeOnDocumentClick={!isPipelineFormModified}
            />
            <Ref innerRef={popupRef}>
                <Dropdown
                    icon={<Icon name='ellipsis vertical' className='Profile__controls' link />}
                    pointing={'top right'}
                    onOpen={() => {
                        if (account_stage === "lead") {
                            dispatch(pipelineActions.getPipelineCategories({ account_stage }));
                        }
                    }}
                >
                    <Dropdown.Menu>
                        {[
                            ...(parent_account_id ? (
                                [<Dropdown.Item onClick={() => { setUnlinkAccountPopupOpen(true) }} icon={<Icon className='fas fa-diagram-nested' />} content={'Unlink Account'} disabled={isPrimaryContact || account_stage !== "contact"} />]
                            ) : [
                                <Dropdown.Item onClick={() => { setIsModulesModalOpen(true) }} icon={<Icon className='fas fa-diagram-nested' />} content={'Link New Account'} />,
                                <Dropdown.Item onClick={() => { setIsLinkAccountModalOpen(true) }} icon={<Icon className='fas fa-diagram-nested' />} content={'Link Existing Account'} />
                            ]),
                            <Dropdown.Item icon={<Icon className='fas fa-sitemap' />} content={'Sequences'} />,
                            ...(account_stage === "lead" ? [<Dropdown.Item disabled={isReadingCategories || !canChangePipeline} icon={<Icon className={`fa-solid ${isReadingCategories ? "fa-circle-notch" : "fa-filters"}`} loading={isReadingCategories} />} content={'Change Pipeline'} onClick={() => { setOpenForm("change-pipeline") }} />] : []),
                            ...(account_stage !== "opportunity" ? [
                                <Dropdown.Item onClick={() => {onOpenAddProducts()}} icon={<Icon className='fas fa-box' />} content="Product Catalog" />
                            ] : [])
                        ]}
                    </Dropdown.Menu>
                </Dropdown>
            </Ref>
            <SelectionsModal
                selectionHeader='Add New'
                categories={modalCategories}
                ToRender={AddModuleRecordsModalContent}
                contentModalSize={activeContent => {
                    if (activeContent === 'selection') {
                        return 'tiny'
                    }
                    if (activeContent === 'BusinessAccount') {
                        return 'big';
                    }
                    return null;
                }}
                open={isModulesModalOpen}
                close={closeModal}
                toRenderProps={{
                    contact_id: Number(contact_id),
                    refreshFromLinking
                }}
            />
            <Modal open={isLinkAccountModalOpen} onClose={closeLinkAccountModal}>
                <LinkExistingAccountForm
                    contactIdToLink={Number(contact_id)}
                    refreshFromLinking={refreshFromLinking}
                    onClose={closeLinkAccountModal}
                />
            </Modal>
            <UnlinkPopupForm
                isUnlinkAccountPopupOpen={isUnlinkAccountPopupOpen}
                unlinkAccountPopupRef={popupRef}
                setUnlinkAccountPopupOpen={setUnlinkAccountPopupOpen}
                account_id={account_id}
                contact_id={Number(contact_id)}
                unlinkContact
                refresh={() => {
                    refreshFromLinking();
                    setUnlinkAccountPopupOpen(false);
                }}
            />
            <QuoteBuilderModal 
                account_id={account_id}
            />
        </React.Fragment>
    );
};

export default Controls;

