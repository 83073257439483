import React from 'react';
import { Grid, Transition, Icon } from 'semantic-ui-react';
import { AgentControls } from './AgentControls';
import { CALL_CONTROL_NAMES, DroppableCallButton } from './CallControls';
import { Contact } from './Contact';
import { ButtonLink, DataVisibilityTogglerContent, MessagingIcon } from 'dyl-components';
import { TwoLinedContent } from 'dyl-components';

import './Department.scss';
import { StringUtils } from 'utils';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

export const Team = ({ team, activeCalls, user_id, onToggleDataVisibility, isMoreDataVisible }) => (
    <React.Fragment>
        <Grid className='Department__header' verticalAlign='middle'>
            <Grid.Row>
                <Grid.Column width={10} floated='left'>
                    <h5 className="DepartmentHeading5"><i>{StringUtils.capitalize(team.name)}</i></h5>
                </Grid.Column>
                <Grid.Column width={3} floated='right' textAlign='right'>
                    <ButtonLink className="DepartmentButtonLink" onClick={onToggleDataVisibility}>
                        <DataVisibilityTogglerContent isMoreDataVisible={isMoreDataVisible} />
                    </ButtonLink>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        <Transition visible={isMoreDataVisible} animation={'slide down'} duration={150} unmountOnHide>
            <Grid celled className='Department__extensions'>
                {team.users.data.filter(user => user.user_id !== user_id).map((user, index) => (
                    <Grid.Row columns='equal' key={user.user_id}>
                        <Grid.Column verticalAlign='middle'>
                            <div className="DepartmentDiv">
                                <Link to={`/profile?id=${user.user_id}`}>
                                    <TwoLinedContent
                                        firstLine={user.name}
                                        secondLine={`Ext. ${user.extension}`}
                                    />
                                </Link>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={4} verticalAlign='middle'>
                            <Contact agent={{ ...user, contact: activeCalls.find(activeCall => activeCall.extension === user.extension) }} teamName={team.name} index={index} />
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <DroppableCallButton
                                teamName={team.name}
                                name={CALL_CONTROL_NAMES.VOICEMAIL}
                                agentId={user.user}
                                popupContent={'Drop to Voicemail'}
                            >
                                <React.Fragment>
                                    <Icon.Group style={{ margin: 0 }}>
                                        <MessagingIcon icon={'mobile'} />
                                        <MessagingIcon icon={'volume up'} corner />
                                    </Icon.Group> Drop to Voicemail
                                </React.Fragment>
                            </DroppableCallButton>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <DroppableCallButton
                                teamName={team.name}
                                name={CALL_CONTROL_NAMES.CELL}
                                agentId={user.user}
                                popupContent={'Drop to Cell'}
                            >
                                <React.Fragment>
                                    <Icon.Group>
                                        <MessagingIcon icon={'mobile alternate'} />
                                    </Icon.Group> Drop to Cell
                                </React.Fragment>
                            </DroppableCallButton>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <AgentControls agent={user} />
                        </Grid.Column>
                    </Grid.Row>
                ))}
            </Grid>
        </Transition>
    </React.Fragment>
)

class TeamContainer extends React.Component {
    state = {
        isMoreDataVisible: true
    }

    toggleDataVisibility = () => {
        this.setState({ isMoreDataVisible: !this.state.isMoreDataVisible })
    }

    render() {
        return (
            this.props.team.users.count > 0 && (
                <Team
                    isMoreDataVisible={this.state.isMoreDataVisible}
                    onToggleDataVisibility={this.toggleDataVisibility}
                    team={this.props.team}
                    activeCalls={this.props.activeCalls}
                    user_id={this.props.user_id}
                />
            )
        )
    }
}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id,
        activeCalls: state.call_history.activeCalls
    }
}

export default connect(mapStateToProps)(TeamContainer);
