import React, { useEffect, useState } from 'react';
import { Segment, Header, Pagination } from 'semantic-ui-react';
import { TableWithHeader } from 'dyl-components';
import WebFormsToolbar from './subcomponents/WebFormsToolbar';
import WebFormsTable from './subcomponents/WebFormsTable';

import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import webformsActions from 'actions/webforms';

import { QueryParamsUtils } from 'utils';

const LIMIT = 25;

const WebForms = () => {
    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(webformsActions.readForms({
            ...Object.fromEntries(params),
            limit: LIMIT
        }));
    }, [dispatch, params]);

    const searchQuery = params.get('search') || '';
    const [search, setSearch] = useState(searchQuery);

    const onSearchSubmit = () => {
        const query = new URLSearchParams(params);
        if (search.trim()) {
            query.set('search', search.trim());
        } else {
            query.delete('search');
        }
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`/settings/web-forms${query_string ? `?${query_string}` : ''}`);
    }

    const onClearSearch = () => {
        const query = new URLSearchParams(params);
        query.delete('search');
        query.set('page', 1);
        const query_string = query.toString();
        setSearch('');
        navigate(`/settings/web-forms${query_string ? `?${query_string}` : ''}`);
    }

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`/settings/web-forms${query_string ? `?${query_string}` : ''}`);
    }

    const isReading = useSelector(state => state.webforms.isReading);
    const count = useSelector(state => state.webforms.count);

    return (
        <div className='WebFormsPanel'>
            <TableWithHeader
                header={(
                    <WebFormsToolbar
                        value={searchQuery}
                        isReadingWebForms={isReading}
                        onChange={(_, { value }) => { setSearch(value) }}
                        onClearSearch={onClearSearch}
                        onReadFilters={onSearchSubmit}
                    />
                )}
                table={(
                    <Segment.Group vertical>
                        <Segment style={{ padding: 0 }}>
                            <WebFormsTable limit={LIMIT} search={search} isReadingWebForms={isReading} />
                        </Segment>

                        {!isReading && count === 0 &&
                            <Segment>
                                {!QueryParamsUtils.isUsingFilters({ params, isUsingPage: true }) ? (
                                    <Header as='h3' textAlign='center' style={{ marginTop: 30 }}>
                                        No Forms Created
                                        <Header.Subheader style={{ marginTop: 20 }}>
                                            Create a Form by clicking on the <Link to='/settings/web-forms/create'>
                                                + New Web Form
                                            </Link> icon on the top-right corner.
                                        </Header.Subheader>
                                    </Header>
                                ) :
                                    <Header as='h3'>
                                        No Results Found.
                                    </Header>
                                }
                            </Segment>
                        }

                        {count !== 0 &&
                            <Segment textAlign='right'>
                                <Pagination
                                    boundaryRange={0}
                                    activePage={params.get('page') || 1}
                                    ellipsisItem={null}
                                    siblingRange={2}
                                    totalPages={Math.ceil(count / LIMIT)}
                                    onPageChange={onPageChange}
                                    disabled={isReading}
                                />
                            </Segment>
                        }
                    </Segment.Group>
                )}
            />
        </div>
    );
}

export default WebForms;
