import React, { useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import pipelineActions from "actions/pipeline";
import { Button, Notification, Person, STATUS_TYPES, generateResolver, yup } from 'dyl-components';
import customerPipelineActions from 'actions/customer_pipeline';

const CustomerPipelineForm = ({ onClose, account_id, refresh, primary_contact_id }) => {
    const dispatch = useDispatch();

    const { formState: { isDirty, isValid }, control, handleSubmit, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            pipeline: null,
            stage: null,
            person_id: primary_contact_id,
        },
        resolver: generateResolver({
            pipeline: yup.string().required('This field is required'),
            stage: yup.string().required('This field is required'),
            person_id: yup.number().typeError("This field is required").required('This field is required')
        })
    })

    useEffect(() => {
        dispatch(pipelineActions.getPipelineCategories({ account_stage: 'customer' }));
    }, [dispatch]);

    const { pipelineCategories, isReadingCategories, isCreating, associateContactOptions, isReadingAssociateContactOptions } = useSelector(state => ({
        pipelineCategories: state.pipeline.categories.map(category => ({
            key: category.id,
            value: category.id,
            text: category.name,
            stages: category.stages?.map(stage => ({
                key: stage.id,
                value: stage.id,
                text: stage.name
            })) || []
        })),
        isReadingCategories: state.pipeline.isReadingCategories,
        isCreating: state.customer_pipeline.accountBeingUpdated,
        associateContactOptions: state.account.contactsForPinning.map(contact => {
            const name = `${contact.first_name} ${contact.last_name}`;

            return ({
                key: contact.id,
                value: contact.id,
                text: name,
                content: (
                    <Person username={name} email={contact.email.email} />
                ),
                pinned: contact.pinned
            });
        }),
        isReadingAssociateContactOptions: state.account.isReadingContactsForPinning,
    }));

    const onCreate = async (data) => {
        try {
            await dispatch(customerPipelineActions.update(account_id, {
                pipeline_stage_id: Number(data.stage),
                person_id: data.person_id
            }, { action: 'created' }));
            Notification.alert('Successfully added pipeline!', STATUS_TYPES.SUCCESS);
            if (onClose) {
                onClose();
            }
            if (refresh) {
                refresh();
            }
            reset();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to add pipeline', STATUS_TYPES.ERROR);
        }
    }

    return (
        <>
            <Form.Group widths={'equal'}>
                <Controller
                    name={'stage'}
                    control={control}
                    render={({ field: { onChange: onStageChange, name: stageName } }) => (
                        <Controller
                            name={'pipeline'}
                            control={control}
                            render={({ field: { name, value: categoryValue, onChange }, fieldState: { error } }) => [
                                <Form.Select
                                    fluid
                                    label='Pipeline'
                                    name={name}
                                    value={categoryValue}
                                    onChange={(_, { name, value }) => {
                                        onChange({ target: { name, value } });
                                        onStageChange({ target: { name: stageName, value: pipelineCategories.find(category => category.value === value)?.stages[0]?.value || null } });
                                    }}
                                    options={pipelineCategories}
                                    search
                                    selectOnBlur={false}
                                    placeholder='Select Pipeline'
                                    loading={isReadingCategories}
                                    error={error && error.message && {
                                        content: error.message,
                                        pointing: 'below'
                                    }}
                                    required
                                />,
                                <Controller
                                    name={stageName}
                                    control={control}
                                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                        <Form.Select
                                            fluid
                                            label='Stage'
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                            options={pipelineCategories.find(category => category.value === categoryValue)?.stages || []}
                                            search
                                            selectOnBlur={false}
                                            placeholder='Select Stage'
                                            error={error && error.message && {
                                                content: error.message,
                                                pointing: 'below'
                                            }}
                                            required
                                        />
                                    )}
                                />
                            ]}
                        />
                    )}
                />
            </Form.Group>
            <Controller
                name={'person_id'}
                control={control}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <Form.Field
                        control='div'
                        required
                        label={`Associate Contact`}
                    >
                        <Form.Dropdown
                            selectOnBlur={false}
                            selection
                            options={associateContactOptions}
                            value={value}
                            onChange={(_, { value: contact_id }) => {
                                onChange({ target: { name, value: contact_id } });
                            }}
                            compact
                            loading={isReadingAssociateContactOptions}
                            error={error?.message}
                            disabled={associateContactOptions?.length <= 1}
                            {...value ? { text: associateContactOptions.find(option => option.value === value)?.content } : {}}
                        />
                    </Form.Field>
                )}
            />
            <Button floated='right' disabled={!isDirty || !isValid || isCreating} primary onClick={handleSubmit(onCreate)} loading={isCreating}>Save</Button>
        </>
    );
}

export default CustomerPipelineForm;
