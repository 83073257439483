import React from "react";
import { Icon } from "semantic-ui-react";
import { ClippedContent } from "dyl-components";
import { Link } from "react-router-dom";
import HoverableIconWithList from "shared/HoverableIconWithList";

const Addon = ({ addon }) => {
    return (
        <div className="AddonsTable__Addon">
            <div className="Addon__Name">
                <ClippedContent>
                    <Link
                        to={`/settings/product-catalog-quotes-invoices/addons/${addon.id}`}
                    >
                        {addon.name}
                    </Link>
                </ClippedContent>
            </div>
            <div className="Addon__Actions">
                <HoverableIconWithList
                    icon={<Icon className="fa-solid fa-box" color="primary" />}
                    iconContent={"Products"}
                    items={addon?.products}
                />
                <HoverableIconWithList
                    icon={<Icon className="fa-solid fa-boxes-stacked" color="primary" />}
                    iconContent={"Bundles"}
                    items={addon?.bundles}
                />
            </div>
        </div>
    );
};

export default Addon;
