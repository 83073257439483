import React, { useEffect } from "react";
import { RuleContainer, RoundRobin, ButtonLink, Icon } from "dyl-components";
import { Header } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import assignActions from "actions/assign";
import assignmentActions from 'actions/assignment';

export const RoutingsSection = ({
    routing,
    control,
    trigger,
    watch,
    errors,
    getValues,
    setError,
    routingIndex,
    removeRouting,
    onChangeActiveInnerTab,
    updateRoutings,
    segments,
    totalPercent,
    setTotalPercent,
    isEditing,
    editedTeamName,
}) => {
    const dispatch = useDispatch();
    const watchedRoutings = watch("routings")
    const watchedWeighted = watchedRoutings?.[0]?.weighted || null;
    const isDistributionTeamMessageOn = !!errors.routings?.[0]?.name;

    const user_accounts = useSelector((state) => state.assign.user_accounts);

    const onUpdateRoutingField = (value, fieldName) => {
        const routing = !!watchedRoutings[0] ? {...watchedRoutings[0], [fieldName]: value} : null;
        updateRoutings(0, routing);
    }

    const onUniqueRoutingCheck = async () => {
        const distributionTeamName = getValues("routings[0].name");
        try {
            if (!isEditing || editedTeamName !== distributionTeamName) {
                const isUnique = await dispatch(assignmentActions.uniqueAssignmentName({ name: distributionTeamName }));
                if (!isUnique) {
                    setError("routings[0].name", { type: "unique", message: "Name already exists!" });
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onRemoveRouting = () => {
        removeRouting(0);
        onChangeActiveInnerTab(segments.length > 0 ? 1 : 0);
    }

    useEffect(() => {
        dispatch(assignActions.readUserAccounts({limit: 500, active: true}));
    }, [dispatch])

    return (
        <>
            <RuleContainer 
                isActive={false}
                content={
                    <>
                        <Header>Routing</Header>
                        {routing.routingActiveFields.length > 0 ? (
                            <RoundRobin
                                control={control}
                                trigger={trigger}
                                parentName={`routings[${routingIndex}]`}
                                searchContent={user_accounts}
                                onChangeField={onUpdateRoutingField}
                                onUniqueRoutingCheck={onUniqueRoutingCheck}
                                watchedWeighted={watchedWeighted}
                                isDistributionTeamMessageOn={isDistributionTeamMessageOn}
                                totalPercent={totalPercent}
                                setTotalPercent={setTotalPercent}
                            />
                        ) : (
                            <Header textAlign='center'>
                                <Header.Subheader textAlign='center' style={{ marginTop: 20 }}>
                                    <strong>No Assignments Added</strong>
                                    <div>Add an Assignment by clicking on the + icon on the panel</div>
                                </Header.Subheader>
                            </Header>
                        )}
                    </>
                }
            />
            <div>
                <ButtonLink onClick={() => {}} status='primary' disabled>
                    <Icon link size='large' className='fa-solid fa-clone' /> Duplicate
                </ButtonLink>
                <ButtonLink onClick={() => onRemoveRouting()} status='error'>
                    <Icon link size='large'  className='fas fa-circle-minus' /> Remove
                </ButtonLink>
            </div>
        </>
    );
};
