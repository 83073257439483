import React from 'react';
import { TableWithHeader, Notification, STATUS_TYPES } from 'dyl-components';
import TemplateHeader from './TemplatesHeader';
import { Grid, Transition, Segment } from 'semantic-ui-react';
import AddTemplateSectionContainer from './AddTemplateSection';
import ScriptsTable from './TemplatesTable';

import './index.scss';

const Scripts = ({ 
    templates,
    isAddTemplateSectionOpen, 
    onOpenAddTemplateSection,
    onCloseAddTemplateSection,
    onAddTemplate,
    onUpdateTemplate,
    onDelete
}) => (
    <TableWithHeader 
        header={
            <TemplateHeader 
                onOpenAddTemplateSection={onOpenAddTemplateSection} 
            />
        }
        table={
            <React.Fragment>
                <Transition unmountOnHide visible={isAddTemplateSectionOpen} animation='slide down' duration='100'>
                    <Grid>
                        <Grid.Row style={{paddingBottom: 0}}>
                            <Grid.Column className="ScriptsGrid">
                                <Segment basic>
                                    <AddTemplateSectionContainer 
                                        onAddTemplate={onAddTemplate}
                                        onClose={onCloseAddTemplateSection}
                                    />
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Transition>
                {templates.length <= 0 && (
                    <Segment textAlign='center'>
                        <em>There are no templates</em>
                    </Segment>
                )}
                {templates.length > 0 && (
                    <ScriptsTable 
                        templates={templates} 
                        onUpdate={onUpdateTemplate}
                        onDelete={onDelete}
                    />
                )}
            </React.Fragment>
        }
    />
)

class TemplateContainer extends React.Component {
    state = {
        templates: [
            { 
                id: 'c1n', 
                name: 'Cancel 1 Notice', 
                isEnabled: false,
                text: '%%first%% NOTICE your policy is about to expire by_______, Need to pay ASAP. Do you have time to talk now or tonight.', 
            },
            { 
                id: 'flwu', 
                name: 'Following Up',
                isEnabled: true, 
                text: 'i %%NAME%% I am following up on the auto insurance quote we discussed a few days ago.', 
            },
            { 
                id: 'ghf', 
                name: 'Greeting',
                isEnabled: true, 
                text: 'Hi %%first%%, Call me back at 626-408-2662. James Angel', 
            },
            { 
                id: 'hnuh', 
                name: 'Happy Holidays',
                isEnabled: false, 
                text: '%%NAME%%, Us here at your INS company wishes a Happy Holidays.', 
            },
            { 
                id: 'htik', 
                name: 'Hello', 
                isEnabled: true,
                text: '	Hello %%FIRST%%, this is Kris at your company. My phone number is %%AGENT_PHONE%%.', 
            }
        ],
        isAddTemplateSectionOpen: false
    }

    onOpenAddTemplateSelection = () => {
        this.setState({ isAddTemplateSectionOpen: true });
    }

    onCloseAddTemplateSection = () => {
        this.setState({ isAddTemplateSectionOpen: false });
    }

    onUpdateTemplate = (updatedTemplate) => {
        const templates = this.state.templates.slice(0);
        let indexOfTemplateToUpdate = templates.findIndex(template => template.id === updatedTemplate.id);
        if (indexOfTemplateToUpdate !== -1) {
            templates[indexOfTemplateToUpdate] = {...updatedTemplate};
            this.setState({ templates });
        }
    }

    onDelete = (id) => {
        const templates = this.state.templates.slice(0);
        const indexOfTemplateToDelete = templates.findIndex(template => template.id === id);
        if (indexOfTemplateToDelete !== -1) {
            console.log(indexOfTemplateToDelete);
            templates.splice(indexOfTemplateToDelete, 1);
            this.setState({ templates }, () => {
                Notification.alert('Successfully deleted template!', STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    onAddTemplate = (newTemplate) => {
        const templates = this.state.templates.slice(0);
        templates.push(newTemplate);
        this.setState({ templates }, () => {
            Notification.alert('Successfully added template!', STATUS_TYPES.SUCCESS, true);
        });
    }

    render() {
        return (
            <Scripts 
                {...this.state}
                onUpdateTemplate={this.onUpdateTemplate}
                onOpenAddTemplateSection={this.onOpenAddTemplateSelection}
                onCloseAddTemplateSection={this.onCloseAddTemplateSection}
                onAddTemplate={this.onAddTemplate}
                onDelete={this.onDelete}
            />
        )
    }
}

export default TemplateContainer;
