import React from 'react';
import { useSelector } from 'react-redux';
import { Label, Checkbox, Button } from 'semantic-ui-react';

import LocationsForm from 'shared/forms/LocationsForm';
import LocationUtils from 'utils/LocationUtils';

const Locations = ({
    control,
    mainLocationId,
    existingLocations,
    onUpdatePrimaryLocation,
    isUpdatingLocation,
    isLocationOnEditMode,
    onEditLocation,
    tmpLocation,
    pickedLocationId,
    onConfirmEditLocation,
    onCancelEditLocation,
    location_type,
    isValid = true
}) => {
    const onLocationDoubleClick = (id) => {
        !isLocationOnEditMode && onEditLocation(id)
    }

    const contactLocationBeingUpdated = useSelector(state => state.contact_location.contactLocationBeingUpdated);

    return (
        <div className='LocationsView__Locations'>
            {existingLocations.map((existingLocation, index) => {
                return (
                    <div key={index} className='Location' onDoubleClick={() => onLocationDoubleClick(existingLocation.id)} style={{ overflowWrap: 'break-word' }}>
                        {!(pickedLocationId === existingLocation.id) ?
                            <>
                                <Label className='ContactInfo__locationLabel'>{LocationUtils.getAddressLabel(existingLocation.label)}</Label>
                                <div className='Location__address'>
                                    <div>
                                        {existingLocation.street ? `${existingLocation.street} ` : ''}
                                        {existingLocation.additional_street ? `${existingLocation.additional_street} ` : ''}
                                    </div>
                                    <div>
                                        {existingLocation.city ? `${existingLocation.city}, ` : ''}
                                        {existingLocation.state ? `${existingLocation.state} ` : ''}
                                        {existingLocation.zip || ''}
                                    </div>
                                </div>
                                <div className='Location__checkbox'>
                                    <Checkbox
                                        onClick={
                                            () => {
                                                if (existingLocation.id === mainLocationId) {
                                                    return;
                                                }
                                                return onUpdatePrimaryLocation(existingLocation.id);
                                            }
                                        }
                                        label={`Make this my default location`}
                                        checked={existingLocation.id === mainLocationId}
                                    />
                                </div>
                            </>
                            : (
                                <>
                                    <LocationsForm
                                        control={control}
                                        location_type={location_type}
                                        locations={tmpLocation}
                                        onInlineEditPrimary
                                        pickedLocationIndex={existingLocations.findIndex(existingLocation => existingLocation.id === pickedLocationId)}
                                    />
                                    <Button disabled={!isValid} primary size='tiny' floated='right' loading={isUpdatingLocation || contactLocationBeingUpdated} onClick={onConfirmEditLocation}>
                                        Confirm
                                    </Button>
                                    <Button disabled={isUpdatingLocation || contactLocationBeingUpdated} basic size='tiny' floated='right' color='red' onClick={onCancelEditLocation}>
                                        Cancel
                                    </Button>
                                </>
                            )
                        }
                    </div>
                )
            })}
        </div>
    )
}

export default Locations;
