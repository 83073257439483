import { Modal, generateResolver, yup, Notification, STATUS_TYPES, ConfirmationPrompt } from 'dyl-components';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Form, Icon, Popup, Modal as SemanticModal } from 'semantic-ui-react';
import { BulkActionsContext } from "shared/context/BulkActionsProvider";
import { useParams, useSearchParams } from 'react-router-dom';
import campaignsActions from 'actions/campaigns';

const CampaignContactsBulkActionsModal = ({ open, onClose, numberSelectedContacts = 0, readContacts }) => {
    const { id: campaign_id } = useParams();
    const [isPromptOpen, setIsPromptOpen] = useState(false);
    const [query] = useSearchParams();
    const search = query.get("search") || '';
    const module = query.get("module") || '';
    const status = query.get("campaign_converted") || '';

    const { control, reset, formState: { isValid, isDirty }, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            type: '',
        },
        resolver: generateResolver({
            type: yup.string().required('This field is required'),
        })
    });

    const dispatch = useDispatch();

    const close = () => {
        reset();
        onClose();
    }
    
    const [selectedContacts,,areContactsInAllPagesSelected] = useContext(BulkActionsContext);

    const bulkActionOptions = [
        {
            key: 'remove_from_campaign', value: 'remove_from_campaign', text: 'Remove From Campaign', content: (
                <>
                    Remove From Campaign
                </>
            )
        },
        {
            key: 'set_to_active', value: 'set_to_active', text: 'Set to Active', content: (
                <>
                    Set to Active
                </>
            )
        },
        {
            key: 'set_to_inactive', value: 'set_to_inactive', text: 'Set to Inactive', content: (
                <>
                    Set to Inactive
                </>
            )
        },
    ];

    const getMessages = (type) => {
        switch (type) {
            case "remove_from_campaign":
                return {
                    successMessage: "Successfully removed from campaign!",
                    errorMessage: "Failed to remove from campaign"
                }
            case "set_to_active":
                return {
                    successMessage: "Successfully added to group!",
                    errorMessage: "Failed to add to group"
                }
            case "set_to_inactive":
                return {
                    successMessage: "Successfully added to campaign!",
                    errorMessage: "Failed to add to campaign"
                }
            default: {}
        }
    }

    const onBulkAction = async ({type}) => {
        const { successMessage, errorMessage } = getMessages(type);
        try {
            await dispatch(campaignsActions.campaignContactsBulkAction("", {payload: !areContactsInAllPagesSelected ? selectedContacts : []}, {action: type, search, module, status, campaign_id}));
            Notification.alert(successMessage, STATUS_TYPES.SUCCESS);
            close();
            setIsPromptOpen(false);
            readContacts();
        } catch (error) {
            console.log(error);
            Notification.alert(errorMessage, STATUS_TYPES.ERROR);
        }
    }

    return (
        <>
            <Modal open={open} onClose={close}>
                <Modal.Header>
                    Bulk Action <div style={{ float: 'right', marginRight: '1em' }}>
                        <small>{numberSelectedContacts} selected</small>
                        <Popup
                            trigger={<Icon size='small' style={{ marginLeft: '1em' }} floated='right' className='fas fa-circle-info' color='primary' />}
                            content={`Duplicates will be ignored`}
                            inverted
                            position='left center'
                            wide
                        />
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <Form noValidate>
                        <Controller
                            name='type'
                            control={control}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Select
                                    {...value ? { text: bulkActionOptions.find(action => action.value === value)?.content } : {}}
                                    label='Bulk Action Type'
                                    placeholder='Select bulk action type'
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    value={value}
                                    options={bulkActionOptions}
                                    required
                                    error={error?.message}
                                    width={8}
                                    selectOnBlur={false}
                                />
                            )}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions
                    hasSaveButton
                    saveDisabled={!isValid || !isDirty}
                    onSave={() => setIsPromptOpen(true)}
                />
            </Modal>
            <SemanticModal open={isPromptOpen} style={{ padding: 30, width: 350 }}>
                <ConfirmationPrompt 
                    header={<div style={{ paddingTop: '1em' }}>Are you sure?</div>}
                    // subheader={<div>Selected groups will be permanently deleted.</div>}
                    onConfirm={handleSubmit(onBulkAction)}
                    onCancel={() => setIsPromptOpen(false)}
                />
            </SemanticModal>
        </>
    )
}

export default CampaignContactsBulkActionsModal;
