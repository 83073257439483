import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Header, Icon, Transition, Table as SemanticTable } from 'semantic-ui-react';
import FieldGroup from './FieldGroup';

import { BlueToggleSwitch, Table } from 'dyl-components';


import EditFieldModal from 'shared/modals/EditFieldModal';

import customFieldActions from 'actions/custom_field';

const StandardFields = ({
    group,
    subgroups,
    toggleSubgroup,
    isOpenSubgroup,
    refresh,
    isReading
}) => {
    const [hiddenGroup, setHiddenGroup] = useState({});

    const toggleGroup = (id) => {
        setHiddenGroup(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }))
    }

    const [fieldBeingEdited, setFieldBeingEdited] = useState(null);

    const onEditField = (field) => {
        setFieldBeingEdited(field);
    }

    const onCancelEdit = () => {
        setFieldBeingEdited(null);
    }

    const dispatch = useDispatch();

    const onUpdate = async (data) => {
        if (fieldBeingEdited.field_type === 'dependency') {
            const { categories } = data;
            const id = fieldBeingEdited.id;
            return dispatch(customFieldActions.updateField(fieldBeingEdited.parent_group, {
                option: categories.map(category => ({
                    id: category.id,
                    value: category.name,
                    name: category.name,
                    sub_options: category.options.map(option => ({
                        ...(!option.new ? { id: option.id } : {}),
                        value: option.name,
                        name: option.name,
                    }))
                }))
            }, null, id));
        }
        const { options, field_type } = data;
        const originalOptions = fieldBeingEdited?.options?.data?.filter(option => !option.deleted);
        const originalOptionsIds = originalOptions?.map(option => option.id) || [];
        if ((field_type === "picklist" && fieldBeingEdited.field_name === "product_interest")) {
            return dispatch(customFieldActions.updateField(fieldBeingEdited.parent_group, {
                option: options.map(option => ({
                    ...(originalOptionsIds.includes(option.id) ? { id: option.id } : {}),
                    value: option.name,
                    name: option.name
                }))
            }, null, fieldBeingEdited.id));
        }
        const hasOptions = field_type === 'tagsinput';
        
        if (hasOptions) {
            const toAdd = options ? options.filter(option => !originalOptionsIds.includes(option.id)) : [];
            const toRemove = fieldBeingEdited?.options?.data?.length ? fieldBeingEdited?.options?.data?.filter(originalOption => options.findIndex(option => option.name === originalOption.value) === -1) : [];
            return Promise.all([
                (() => {
                    if (toAdd.length > 0) {
                        return dispatch(customFieldActions.createFieldOptions(toAdd.map(option => ({ value: option.name })), null, fieldBeingEdited.id));
                    }
                    return Promise.resolve();
                })(),
                ...toRemove.map(({ id }) => dispatch(customFieldActions.removeFieldOption(id)))
            ])
        }
        return Promise.resolve();
    }

    return (
        <React.Fragment>
            {fieldBeingEdited && (
                <EditFieldModal
                    open={fieldBeingEdited !== null}
                    field={fieldBeingEdited}
                    onClose={onCancelEdit}
                    refresh={refresh}
                    onUpdate={onUpdate}
                    willAffectExistingValues={fieldBeingEdited.data?.dependency_label}
                />
            )}
            <Table striped={false} className="CustomFieldsTable" fixed>
                <SemanticTable.Header className='Table__header--primary'>
                    <Table.Row>
                        <SemanticTable.HeaderCell width={1} />
                        <SemanticTable.HeaderCell>
                            Field Name
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell>
                            Field Type
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell>
                            Values
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell>
                            Description
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell>
                            {/* Display */}
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell>
                            {/* Linked Display */}
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell>
                            Actions
                        </SemanticTable.HeaderCell>
                    </Table.Row>
                </SemanticTable.Header>
                <Table.Body>
                    <FieldGroup
                        fields={group?.fields?.data || []}
                        onEditField={onEditField}
                        standard
                        isReading={isReading}
                        parent_group={group?.id}
                        group_name={group.group_name}
                    />
                    {subgroups.map(subgroup => {
                        const isOpen = isOpenSubgroup(subgroup.id);
                        return [
                            <Table.Row className='CustomFieldsTable__subgroup-header'>
                                <Table.Cell colSpan={8}>
                                    <Header color='primary'>
                                        <span>
                                            <Icon
                                                onClick={() => { toggleSubgroup(subgroup.id) }}
                                                link
                                                size='small'
                                                className={`fas fa-circle-chevron-${isOpen ? 'up' : 'down'}`}
                                            />
                                            {subgroup.label}
                                        </span><BlueToggleSwitch toggle label={(hiddenGroup[subgroup.id]) ? 'Show All' : 'Hide All'} onClick={() => toggleGroup(subgroup.id)} />
                                    </Header>
                                </Table.Cell>
                            </Table.Row>,
                            <Transition visible={isOpen} animation={'slide down'} unmountOnHide duration={'150'}>
                                <FieldGroup
                                    fields={subgroup?.fields?.data || []}
                                    onEditField={onEditField}
                                    standard
                                    isReading={isReading}
                                    parent_group={subgroup?.id}
                                />
                            </Transition>
                        ]
                    })}
                </Table.Body>
            </Table>
        </React.Fragment>
    )
}

export default StandardFields;
