import React from 'react';
import { Table, EllipsisDropdown, ControlledPopup, DateTimeUtils } from 'dyl-components';
import { Dropdown, Icon, Header, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ReportsAndLogsType } from './ReportsAndLogsType';
import { ReportsAndLogsDataDetails } from './ReportsAndLogsDataDetails';
import { StringUtils } from 'utils';
import ErrorPopupContainer from './ErrorPopupContent';

const ACCEPTED_OPTIONS_EMAIL = [
    { 
        id: 1,
        text: 'Edit parse rule',
        icon: 'fas fa-table-columns',
        as: Link,
        to: '/settings/email-parsing-template',
        removeId: false
    },
    {
        id: 2,
        text: 'Edit mapping',
        icon: 'fas fa-sitemap',
        as: Link,
        to: '/settings/data-mapping/email/edit',
        removeId: false
    },
    {
        id: 3,
        text: 'Manage data record',
        icon: 'fas fa-pencil',
        as: Link,
        to: '/settings/data-providers',
        removeId: true
    },
    {
        id: 4,
        text: 'View record',
        icon: 'fas fa-square-user',
        as: Link,
        to: '/contact',
        removeId: false
    },
];
const ACCEPTED_OPTIONS_POST = [
    {
        id: 1,
        text: 'View post',
        icon: 'fas fa-eye',
        as: Link,
        to: '/settings/post-parsing-template',
        removeId: false
    },
    {
        id: 2,
        text: 'Edit mapping',
        icon: 'fas fa-sitemap',
        as: Link,
        to: '/settings/data-mapping/post/edit',
        removeId: false
    },
    {
        id: 3,
        text: 'View record',
        icon: 'fas fa-square-user',
        as: Link,
        to: '/contact',
        removeId: false
    },
];
const REJECTED_OPTIONS_POST = [
    {
        id: 1,
        text: 'View post',
        icon: 'fas fa-eye',
        as: Link,
        to: '/settings/post-parsing-template',
        removeId: false
    },
    {
        id: 2,
        text: 'Manage data record',
        icon: 'fas fa-pencil',
        as: Link,
        to: '/settings/data-providers',
        removeId: true
    },
];
const REJECTED_OPTIONS_EMAIL = [
    {
        id: 1,
        text: 'Parse/map',
        icon: 'fas fa-table-columns',
        as: Link,
        to: '/settings/email-parsing-template',
        removeId: false
    },
    {
        id: 2,
        text: 'Block',
        icon: 'fas fa-times-circle',
        as: Link,
        to: '/settings/data-integration-logs',
        removeId: true
    },
];

export const ReportsAndLogsTableRow = ({ report }) => {
    const DropdownItem = ({ text, icon, displaysPopup, popupProps, to, report, removeId, ...otherProps }) => {
        const DropdownModal = () => {
            const routeId = to === '/contact' ? report?.contact_id : report?.id;
            return (
                <Dropdown.Item
                    children={
                        <React.Fragment>
                            <i className={icon} style={{ marginRight: '3px' }} /> {text}
                        </React.Fragment>
                    }
                    style={{ cursor: 'pointer' }}
                    to={removeId ? to : `${to}/${routeId}`}
                    {...otherProps}
                />
            );
        }

        if (displaysPopup === true) {
            return (
                <ControlledPopup
                    header={
                        <Header as='h4' textAlign='center'>
                            {popupProps.header}
                            <Header.Subheader style={{ marginTop: 5 }}>
                                {popupProps.subheader}
                            </Header.Subheader>
                        </Header>
                    }
                    trigger={
                        DropdownModal()
                    }
                    onConfirm={popupProps.onConfirm}
                />
            )
        } else {
            return (
                <DropdownModal />
            );
        }
    }

    const isPost = report.method === 'post' ? true : false;

    let ellipsisOptions = ['Accepted', 'Partial'].includes(report.status)
        ? !isPost ? ACCEPTED_OPTIONS_EMAIL: ACCEPTED_OPTIONS_POST
        : isPost ? REJECTED_OPTIONS_POST : REJECTED_OPTIONS_EMAIL

    return (
        <>
            <Table.Row key={report.id}>
                <Table.Cell width={2}>
                    <div><strong>{DateTimeUtils.formatEpoch(report.ts, "M[/]D[/]Y")}</strong></div>
                    <div>{DateTimeUtils.formatEpoch(report.ts)}</div>
                </Table.Cell>
                <Table.Cell width={2}>
                    <ReportsAndLogsType id={report.id} method={StringUtils.capitalize(report.method)} />
                </Table.Cell>
                <Table.Cell width={2}>
                    <Link to='/settings/data-integration-logs'>{report?.provider?.name}</Link>
                </Table.Cell>
                <Table.Cell width={2}>
                    <Link to='/settings/data-integration-logs'>{report?.provider?.record_name}</Link>
                </Table.Cell>
                <Table.Cell width={3}>
                    <ReportsAndLogsDataDetails {...report} />
                </Table.Cell>
                <Table.Cell width={2}>
                    <Popup 
                        wide='very'
                        position='bottom left'
                        content={
                            <ErrorPopupContainer isPost={isPost} error_code={report.error_code}/>
                        }
                        trigger={
                            <Link to='/settings/data-integration-logs'>{report.error_code}</Link>
                        } 
                    />
                </Table.Cell>
                <Table.Cell width={2} className='RowStatus'>
                    <Icon name='circle' size='large' className={`RowStatus__${report.status}`} />{report.status}
                </Table.Cell>
                <Table.Cell width={1}>
                    <EllipsisDropdown>
                        {ellipsisOptions.map(({ text, icon, displaysPopup, popupProps, ...otherProps }, idx) => {
                            return (
                                <DropdownItem
                                    key={idx}
                                    text={text}
                                    icon={icon}
                                    displaysPopup={displaysPopup}
                                    popupProps={popupProps}
                                    report={report}
                                    {...otherProps}
                                />
                            );
                        })}
                    </EllipsisDropdown>
                </Table.Cell>
            </Table.Row>
        </>
    )
}
