import React, { useEffect, useState } from "react";
import { Table, ConfirmationPrompt } from "dyl-components";
import { Icon, Header, Modal as SemanticModal } from "semantic-ui-react";
import { DropdownMenu, DropdownItem, Dropdown } from "semantic-ui-react";
import { STATES } from "shared/constants/STATES";
import DataInputRow from "./DataInputRow";

const showStateName = (states, value) => {
    let name;
    states.map((state) => {
        if (state.key === value) {
            name = state.text;
        }
        return name;
    });
    return name ? name : "*" + value;
};

const CallerIDTable = ({
    headers,
    rows,
    name,
    statesOrDigitsOptions,
    phoneNumbers,
    getRowsData,
    type,
    append,
    update,
    remove,
    control,
    getValues,
    placeHolderText,
}) => {
    const [isAllOptionsSelected, setIsAllOptionsSelected] = useState(false);
    const [mode, setMode] = useState("");

    const checkIsAllSelected = (allOptions, alreadyExistingOptions, param) => {
        let options = allOptions
            .filter((item) => item.key !== "")
            .map((item) => item.key);

        let existingOptions = alreadyExistingOptions
            .filter((item) => item[param]?.value !== "")
            .map((item) => item[param]?.value);

        let allSelected = options.every((element) =>
            existingOptions.includes(element)
        );
        return allSelected;
    };

    useEffect(() => {
        const isAllCallerIdsSelected =
            type === "state_cids"
                ? false
                : checkIsAllSelected(phoneNumbers, rows, "callerId");
        const isAllStatesOrDigitsSelected = checkIsAllSelected(
            statesOrDigitsOptions,
            rows,
            "state"
        );
        const isAllSelected =
            isAllCallerIdsSelected || isAllStatesOrDigitsSelected;
        setIsAllOptionsSelected(isAllSelected);
    }, [rows, phoneNumbers, statesOrDigitsOptions, type]);

    useEffect(() => {
        let isEditOrDelete;
        for (const item of getValues(type) || []) {
            if (item.isEditing) {
                if (item.state.name) {
                    isEditOrDelete = 'edit';
                } else {
                    isEditOrDelete = 'add';
                }
            }
        }
        setMode(isEditOrDelete);
    }, [rows, type, getValues]);

    const onAddCallerId = () => {
        append({
            isEditing: true,
            state: "",
            callerId: "",
        });
    };

    const editEnable = (index) => {
        const updatedData = { ...rows[index], isEditing: true };
        update(index, updatedData);
    };

    const appendData = (data, index) => {
        let existingData = [...getValues(type)];
        data.isEditing = false;
        existingData[index] = data;
        append(existingData);
        getRowsData(type, existingData);
    };

    const ExistingData = () => {
        const [isDeletePromptOpen, setIsDeletePromptOpen] = useState(false);
        const [selectedIndexToDelete, setSelectedIndexToDelete] =
            useState(false);

        const handleDeleteSubmit = (index) => {
            setIsDeletePromptOpen(false);
            remove(index);
        };

        return (
            <>
                {rows.map((row, index) => (
                    <>
                        {row.isEditing ? (
                            <DataInputRow
                                index={index}
                                appendData={appendData}
                                getValues={getValues}
                                remove={remove}
                                type={type}
                                control={control}
                                placeHolderText={placeHolderText}
                                phoneNumbers={phoneNumbers}
                                statesOrDigitsOptions={statesOrDigitsOptions}
                            />
                        ) : (
                            <Table.Row>
                                <Table.Cell width={4}>
                                    {showStateName(STATES, row.state.name)}
                                </Table.Cell>
                                <Table.Cell width={4}>
                                    {row.callerId.name}
                                </Table.Cell>
                                <Table.Cell width={4}>
                                    <Dropdown
                                        icon="ellipsis horizontal"
                                        floating
                                        labeled
                                        className="icon"
                                        disabled={mode === "add"}
                                    >
                                        <DropdownMenu>
                                            <DropdownItem
                                                onClick={() => {
                                                    editEnable(index);
                                                }}
                                            >
                                                <Icon
                                                    name="pencil alternate"
                                                    size="large"
                                                />
                                                Edit
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() => {
                                                    setIsDeletePromptOpen(true);
                                                    setSelectedIndexToDelete(
                                                        index
                                                    );
                                                }}
                                            >
                                                <Icon
                                                    name="trash"
                                                    size="large"
                                                />
                                                Delete
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </>
                ))}
                <SemanticModal
                    open={isDeletePromptOpen}
                    style={{ padding: 30, width: 350 }}
                >
                    <ConfirmationPrompt
                        header={
                            <div style={{ paddingTop: "1em" }}>
                                Are you sure?
                            </div>
                        }
                        subheader={<div>You cannot undo delete actions.</div>}
                        onConfirm={() =>
                            handleDeleteSubmit(selectedIndexToDelete)
                        }
                        onCancel={() => setIsDeletePromptOpen(false)}
                    />
                </SemanticModal>
            </>
        );
    };

    return (
        <>
            <Table>
                <Table.Header className="Table__header--primary">
                    <Table.Row>
                        {headers.map((header) => {
                            return (
                                <Table.HeaderCell width={1}>
                                    {header}
                                </Table.HeaderCell>
                            );
                        })}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    <ExistingData />
                    <div className="add-state-caller-id-btn">
                        <Header
                            as="h4"
                            color="primary"
                            disabled={mode || isAllOptionsSelected}
                        >
                            <Icon
                                size="large"
                                className={
                                    "fas fa-plus-circle callerID-action-icon"
                                }
                                onClick={onAddCallerId}
                                disabled={mode || isAllOptionsSelected}
                            />
                            {name}
                        </Header>
                    </div>
                </Table.Body>
            </Table>
            <br />
        </>
    );
};

export default CallerIDTable;
