import React from 'react';
import { Grid, Form, Icon, Dropdown } from 'semantic-ui-react';

const FaxHistoryToolbar = ({ numberOfReceived = 0 }) => (
    <Grid textAlign='left' stackable>
        <Grid.Row>
            <Grid.Column width={10}>
                <Form style={{padding: 0}}>
                    <Form.Field>
                        <Dropdown icon={''} trigger={<span><Icon name='list' />Show</span>}>
                            <Dropdown.Menu>
                                <Dropdown.Header content='Show' />
                                <Dropdown.Item>10</Dropdown.Item>
                                <Dropdown.Item>25</Dropdown.Item>
                                <Dropdown.Item>50</Dropdown.Item>
                                <Dropdown.Item>100</Dropdown.Item>
                                <Dropdown.Item>200</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Form.Field>
                </Form>
            </Grid.Column>
            <Grid.Column floated='right' width={2} textAlign='right'>
                Received: {numberOfReceived}
            </Grid.Column>
        </Grid.Row>
    </Grid>
)

export default FaxHistoryToolbar;
