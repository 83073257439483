
import React from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react';


import ReturnedKeyValuePairsTable from './ReturnedKeyValuePairsTable';
import { InputValuePairsTable } from './InputValuePairsTable';

export const ParseTemplateRightSide = ({ data }) => {
    const { onAddInputValues, dynamicFieldsRef , parsePayload, iconChoice, returnedValues, isEmail} = data;

    const addInsetClass = (returnedValues?.length > 0) ? 'shadow-container' : '';

    return (
        <Grid.Column width={ 8 } rows={ 2 } className={ addInsetClass }>
            <Grid className="InputFields" >
                {!isEmail && 
                    <Grid.Row columns={ 1 }>
                        <Grid.Column>
                            <Header as='h3'>View Parsing </Header>
                            <p>Review that your post has been parsed correctly.</p>
                        </Grid.Column>
                    </Grid.Row> 
                }
                { (returnedValues.length < 1 && isEmail) ?
                    <Grid.Row columns={ 1 }>
                        <Grid.Column>
                            <Header as='h3'>View Parsing </Header>
                            <p>Review that your email has been parsed correctly.</p>
                                <Segment placeholder textAlign='center'>
                                    <Header as='h4'>No fields or values parsed</Header>
                                    <p>Enter at least 2 sets of key value pairs below</p>
                                </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    :
                    <ReturnedKeyValuePairsTable iconChoice={iconChoice} returnedValues={returnedValues} isEmail={isEmail}/>              
                }
                {isEmail &&
                    <InputValuePairsTable
                        onAddInputValues={ onAddInputValues }
                        dynamicFieldsRef={ dynamicFieldsRef }
                        parsePayload={parsePayload}
                    />
                }
            </Grid>
        </Grid.Column>
    );
}
