import React from 'react';
import { Table } from 'dyl-components';
import TemplateRowContainer from './TemplateRow';

const TemplatesTable = ({ 
    templates, 
    onUpdate,
    onDelete 
}) => (
    <Table>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Script Name</Table.HeaderCell>
                <Table.HeaderCell>Script Status</Table.HeaderCell>
                <Table.HeaderCell>Script Text</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {templates.map(template => (
                <TemplateRowContainer 
                    key={template.id}
                    template={template}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                />
            ))}
        </Table.Body>
    </Table>
);

export default TemplatesTable;
