import React, { useEffect } from 'react';

import {
    KEY_CREATED, KEY_LAST_MODIFIED, KEY_ACCOUNT,
    KEY_OUTCOME, KEY_CAMPAIGN_SOURCE, KEY_CREATED_BY, KEY_LOST_REASON,
    KEY_CLOSE_PROBABILITY, KEY_TOTAL_COST, KEY_LAST_MODIFIED_BY,
    KEY_PRODUCT_INTEREST, KEY_LEAD_PRIORITY, KEY_LEAD_ID, KEY_LEAD_SCORE,
    KEY_LEAD_RATING, KEY_MASTER_SECONDARY, KEY_EXPECTED_REVENUE, KEY_STAGE,
    KEY_ACQUISITION_COST, KEY_ADDITIONAL_COST, KEY_CONTACT_NAME,  KEY_LEAD_TYPE
} from 'shared/constants/MODULE_COLUMNS';

import leadsTabActions from 'actions/leads_tab';
import productInterestActions from "actions/product_interest";
import LeadsTabTable from './LeadsTabTable';
import LeadsTabFilter from './LeadsTabFilter';
import ModulesTab from 'shared/ModulesTab';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const LeadTab = () => {
    const { account_id, contact_id } = useParams();

    const moduleType = account_id ? 'leads' : 'lead';

    const addedDefaultColumns = account_id ? [KEY_LOST_REASON, KEY_MASTER_SECONDARY, KEY_CAMPAIGN_SOURCE] : [];
    const addedAllowedColumns = contact_id ? [KEY_ACCOUNT] : [];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(productInterestActions.readProductInterestOptions());
    }, [dispatch]);

    return (
        <ModulesTab
            TableComponent={LeadsTabTable}
            TabFilterComponent={LeadsTabFilter}
            allowedColumns={[KEY_CREATED, KEY_STAGE, KEY_MASTER_SECONDARY, KEY_LOST_REASON, KEY_LEAD_ID, KEY_OUTCOME, KEY_CONTACT_NAME, KEY_LAST_MODIFIED, KEY_PRODUCT_INTEREST, ...addedAllowedColumns]}
            defaultColumnsDisplayed={[
                KEY_CREATED,
                KEY_LEAD_ID,
                KEY_OUTCOME,
                KEY_STAGE,
                KEY_PRODUCT_INTEREST,
                KEY_LEAD_PRIORITY,
                KEY_LEAD_RATING,
                KEY_LEAD_SCORE,
                ...addedDefaultColumns
            ]}
            moduleName='Leads'
            readModules={leadsTabActions.readLeads}
            additionalColumnParameters={['pipeline_custom_data']}
            columnDisplayOptions={[
                KEY_CREATED, KEY_TOTAL_COST, KEY_CREATED_BY,
                KEY_ACQUISITION_COST, KEY_LAST_MODIFIED, KEY_PRODUCT_INTEREST,
                KEY_ADDITIONAL_COST, KEY_LAST_MODIFIED_BY, KEY_LEAD_PRIORITY,
                KEY_EXPECTED_REVENUE, KEY_LEAD_ID, KEY_LEAD_SCORE,
                KEY_CONTACT_NAME, KEY_LEAD_RATING, KEY_MASTER_SECONDARY, 
                KEY_STAGE, KEY_CAMPAIGN_SOURCE, KEY_LOST_REASON,
                KEY_CLOSE_PROBABILITY, KEY_OUTCOME, KEY_LEAD_TYPE,
                ...addedAllowedColumns
            ]}
            moduleType={moduleType}
        />
    )
}

export default LeadTab;
