import ACTION_NAMES from "actions/ACTION_NAMES";
import { generateDeleteActionCreator, generateReadOneActionCreator, generateUpdateActionCreator } from "actions/crudActionCreators";
import routes from "actions/routes";

export default {
    removeFromContact: generateDeleteActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_SEQUENCE, () => {}, () => {}, 'remove_sequence'),
    handleConvert: generateUpdateActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_SEQUENCE_CONVERT, () => {}, () => {}, 'sequence_convert'),
    handleReassign: generateUpdateActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_SEQUENCE_REASSIGN, () => {}, () => {}, 'reassign_sequence'),
    readTasks: generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_SEQUENCE, 'sequence_tasks')
}
