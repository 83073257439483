import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function tagsReducer(state = initialState, action){
    switch(action.type){
        case getNamedAction(ACTION_NAMES.TAGS, CRUD_ACTION_TYPES.READ_REQUEST):
            return {...state, isGettingListOfTags: true};
        case getNamedAction(ACTION_NAMES.TAGS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {...state, isGettingListOfTags: false, listOfTags: action.data};
        case getNamedAction(ACTION_NAMES.TAGS, CRUD_ACTION_TYPES.READ_FAILURE):
            return {...state, isGettingListOfTags: false};

        case getNamedAction(ACTION_NAMES.TAGS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return {...state, isAddingTag: true};
        case getNamedAction(ACTION_NAMES.TAGS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return {...state, isAddingTag: false};
        case getNamedAction(ACTION_NAMES.TAGS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return {...state, isAddingTag: false};

        default:
            return state;
    }
}


export default tagsReducer;
