import React from 'react';
import { Table } from 'dyl-components';
import CRMActivityRow from './CRMActivityRow';

const CRMActivityTable = ({ activities, ...otherProps }) => (
    <Table>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    Timestamp
                </Table.HeaderCell>
                <Table.HeaderCell>
                    Contact
                </Table.HeaderCell>
                <Table.HeaderCell>
                    CRM
                </Table.HeaderCell>
                <Table.HeaderCell>
                    Event
                </Table.HeaderCell>
                <Table.HeaderCell>
                    External URL
                </Table.HeaderCell>
                <Table.HeaderCell>
                    Result
                </Table.HeaderCell>
                <Table.HeaderCell>
                    Actions
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {activities.map(activity => (
                <CRMActivityRow activity={activity} {...otherProps} />
            ))}
        </Table.Body>
    </Table>
);

export default CRMActivityTable;
