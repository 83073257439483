import { Grid, Menu, Segment } from 'semantic-ui-react';
import { NavLink, Outlet } from 'react-router-dom';

const AssignmentRulesContainer = () => {
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Menu borderless pointing secondary color='primary' stackable size='huge'>
                        <Menu.Item name='Assignment Rules' as={NavLink} to='assignment-rules' />
                        <Menu.Item name='Distribution Users' as={NavLink} to='distribution-users' />
                    </Menu>
                    <Segment basic>
                        <Outlet />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
export default AssignmentRulesContainer;