import {
    Table as SemanticTable,
    Segment,
    Header,
    Icon,
    Dropdown,
    TableBody,
    Ref,
    Popup,
} from "semantic-ui-react";
import { useState } from "react";
import VariantsModal from "../../modals/VariantsModal";
import { ConfirmationPrompt, EllipsisDropdown, Table } from "dyl-components";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useRef } from "react";

const Variant = ({ variant, onEditVariant, onRemoveVariant }) => {
    const ref = useRef();
    const [isRemoving, setIsRemoving] = useState(false);
    const onCancelRemoval = () => {
        setIsRemoving(false);
    };
    return (
        <Table.Row>
            <Table.Cell width={3}>{variant.name}</Table.Cell>
            <Table.Cell width={3}>
                {variant.values.map((item) => {
                    const additionalPrice = item.additional_price;
                    return (
                        <div>{item.value}{additionalPrice ? ` (+$${additionalPrice})` : ""}</div>
                    );
                })}
            </Table.Cell>
            <Table.Cell width={2}>
                {variant.values.map((item) => (
                    <div>{item.sku}</div>
                ))}
            </Table.Cell>
            <Table.Cell width={2}>
                <Ref innerRef={ref}>
                    <EllipsisDropdown reference={ref}>
                        {/* TODO: Pass ID or name to Edit and Delete Functions */}
                        <Dropdown.Item onClick={onEditVariant}>
                            <Icon name="icon pencil alternate" />
                            Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                setIsRemoving(true);
                            }}
                        >
                            <Icon name="trash" />
                            Delete
                        </Dropdown.Item>
                    </EllipsisDropdown>
                </Ref>
                <Popup
                    context={ref}
                    content={
                        <ConfirmationPrompt
                            header={
                                <div style={{ paddingTop: "0.5em", marginBottom: '-1em' }}>
                                    Are you sure?
                                </div>
                            }
                            onConfirm={onRemoveVariant}
                            onCancel={onCancelRemoval}
                        />
                    }
                    onClose={onCancelRemoval}
                    open={isRemoving}
                    position="bottom right"
                    size="small"
                />
            </Table.Cell>
        </Table.Row>
    );
};

const Variants = () => {
    const [idOfVariantBeingModified, setVariantBeingModified] = useState(null);
    const { control } = useFormContext();

    const openModalForAdding = () => {
        setVariantBeingModified("new");
    };

    const onCloseVariantModal = () => {
        setVariantBeingModified(null);
    };

    const {
        fields: variants,
        append,
        remove,
        update,
    } = useFieldArray({
        control,
        name: "variants",
    });

    const deleteVariant = (index) => {
        remove(index);
    };

    const editVariant = (variantId) => {
        setVariantBeingModified(variantId);
    };

    const saveVariant = ({ id, ...modifiedVariant }) => {
        if (idOfVariantBeingModified === "new") {
            append(modifiedVariant);
        } else {
            update(
                variants.findIndex(
                    (variant) => variant.id === idOfVariantBeingModified
                ),
                modifiedVariant
            );
        }
        onCloseVariantModal();
    };

    const getVariantBeingModified = () => {
        return {
            name: "",
            values: [],
            ...(idOfVariantBeingModified !== "new"
                ? variants.find(
                      (variant) => variant.id === idOfVariantBeingModified
                  )
                : {}),
        };
    };

    return (
        <Segment>
            <Header color="primary" as="h3">
                <span>
                    <Icon
                        link
                        onClick={openModalForAdding}
                        className="fas fa-plus-circle"
                    />{" "}
                    Variants
                </span>
            </Header>
            {variants.length > 0 ? (
                <Table className="VariantsTable">
                    <SemanticTable.Header>
                        <Table.Row>
                            <SemanticTable.HeaderCell width={3}>
                                Variant Name
                            </SemanticTable.HeaderCell>
                            <SemanticTable.HeaderCell width={3}>
                                Values
                            </SemanticTable.HeaderCell>
                            <SemanticTable.HeaderCell width={2}>
                                Variant SKU
                            </SemanticTable.HeaderCell>
                            <SemanticTable.HeaderCell width={2}>
                                Action
                            </SemanticTable.HeaderCell>
                        </Table.Row>
                    </SemanticTable.Header>
                    <TableBody>
                        {variants.map((variant, index) => (
                            <Variant
                                variant={variant}
                                key={variant.id}
                                onEditVariant={() => {
                                    editVariant(variant.id);
                                }}
                                onRemoveVariant={() => {
                                    deleteVariant(index);
                                }}
                            />
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <Segment.Inline>No variants added</Segment.Inline>
            )}
            {idOfVariantBeingModified && (
                <VariantsModal
                    open={idOfVariantBeingModified}
                    onClose={onCloseVariantModal}
                    variantBeingModified={getVariantBeingModified()}
                    onSave={saveVariant}
                />
            )}
        </Segment>
    );
};

export default Variants;
