import { createContext, useState } from "react";
import { useDispatch } from "react-redux";
import quoteActions from "actions/quote";
import orderActions from "actions/order";
import invoiceActions from "actions/invoice";

export const CURRENT_STEPS = {
    BUILD_QUOTE: "build-quote",
    ADD_PRODUCTS: "add-products",
    PROPOSAL_PREVIEW: "proposal-preview",
    ORDER: "order",
    EDIT_ORDER: "edit-order",
    CHECKOUT: "checkout",
    INVOICE: "invoice"
}

export const QuoteBuilderContext = createContext();

const QuoteBuilderProvider = ({ children, refreshAccountInformation }) => {
    const dispatch = useDispatch();

    const [quoteBuilderConfig, setQuoteBuilderConfig] = useState({
        currentStep: null,
        id: null,
    });

    const onOpenAddProducts = (config) => {
        const { quote_id = null, isUpsell = false, order_id = null } = config || {};
        setQuoteBuilderConfig({
            currentStep: CURRENT_STEPS.ADD_PRODUCTS,
            id: quote_id || order_id || null,
            isUpsell,
        });
    };

    const onRefreshAccountInformation = () => {
        if (refreshAccountInformation) {
            return refreshAccountInformation();
        }
        return null;
    };

    const onEditQuote = (quote_id) => {
        if (quote_id) {
            dispatch(quoteActions.readQuote(quote_id));
        }
        setQuoteBuilderConfig({
            currentStep: quote_id ? CURRENT_STEPS.BUILD_QUOTE : CURRENT_STEPS.ADD_PRODUCTS,
            id: quote_id || null,
        });
    };

    const onViewProposal = (version_id) => {
        dispatch(quoteActions.getVersion(version_id));
        setQuoteBuilderConfig({
            currentStep: CURRENT_STEPS.PROPOSAL_PREVIEW,
            id: version_id,
        });
    };

    const onViewOrder = (order_id, account_id) => {
        if (order_id) {
            dispatch(orderActions.readOne(account_id, null, order_id));
        }
        setQuoteBuilderConfig({
            currentStep: CURRENT_STEPS.ORDER,
            id: order_id,
        });
    };

    const onEditOrder = (order_id, account_id) => {
        dispatch(orderActions.readOne(account_id, null, order_id));
        setQuoteBuilderConfig({
            currentStep: CURRENT_STEPS.EDIT_ORDER,
            id: order_id,
        });
    };

    const onCheckout = ({ checkout }) => {
        setQuoteBuilderConfig({
            ...quoteBuilderConfig,
            checkout,
            currentStep: CURRENT_STEPS.CHECKOUT,
        });
    };

    const onViewInvoice = (invoice_id) => {
        dispatch(invoiceActions.readOne(invoice_id));
        setQuoteBuilderConfig({
            currentStep: CURRENT_STEPS.INVOICE,
            id: invoice_id
        })
    }

    const onCloseModal = () => {
        setQuoteBuilderConfig({ currentStep: null, id: null });
    };

    return (
        <QuoteBuilderContext.Provider
            value={{
                quoteBuilderConfig,
                onOpenAddProducts,
                onEditQuote,
                onViewProposal,
                onCloseModal,
                onViewOrder,
                onCheckout,
                onRefreshAccountInformation,
                onEditOrder,
                onViewInvoice
            }}
        >
            {children}
        </QuoteBuilderContext.Provider>
    );
};

export default QuoteBuilderProvider;
