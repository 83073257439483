import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function campaignCategoryReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CAMPAIGN_CATEGORY_OPTIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCategories: true, categories: [] };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_CATEGORY_OPTIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, categories: action.data, isReadingCategories: false };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_CATEGORY_OPTIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, categories: [], isReadingCategories: false };

        default:
            return state;
    }
}

export default campaignCategoryReducer;
