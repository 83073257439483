import ACTION_NAMES from "actions/ACTION_NAMES";
import { generateReadActionCreator, generateReadOneActionCreator, generateUpdateActionCreator } from "actions/crudActionCreators";
import routes from "actions/routes";

export default {
    update: generateUpdateActionCreator(routes.API_SEQUENCE_TASK, ACTION_NAMES.SEQUENCE_TASK),
    readOne: generateReadOneActionCreator(routes.API_SEQUENCE_TASK, ACTION_NAMES.SEQUENCE_TASK),
    readRescheduleStats: generateReadOneActionCreator(routes.API_SEQUENCE_TASK, ACTION_NAMES.RESCHEDULE_SEQUENCE_TASK_STATS, 'reschedule_stats'),
    reschedule: generateUpdateActionCreator(routes.API_SEQUENCE_TASK, ACTION_NAMES.RESCHEDULE_SEQUENCE_TASK, () => {}, () => {}, 'reschedule'),
    complete: generateUpdateActionCreator(routes.API_SEQUENCE_TASK, ACTION_NAMES.COMPLETE_SEQUENCE_TASK, () => {}, () => {}, 'complete'),
    readContactTasks: generateReadOneActionCreator(routes.API_SEQUENCE_TASK, ACTION_NAMES.SEQUENCE_CONTACT_TASKS, 'contact'),

    read: generateReadActionCreator(routes.API_SEQUENCE_TASK, ACTION_NAMES.SEQUENCE_TASK)
}
