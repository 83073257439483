const initialState = {
    tasks: [],
    isReadingTasks: false,
    isReadingTasksTab: false,
    isCreatingTask: false,
    queryParameters: {},
    all: 0,
    complete: 0,
    missed: 0,
    upcoming: 0,

    tasks_tab_tasks: [],

    task_labels: [],
    task_labels_count: 0,
    isReadingTaskLabels: false,
    isAddingTaskLabels: false
}

export default initialState;
