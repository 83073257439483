import { ClippedContent, CollapsibleList, EllipsisDropdownPortal, Table } from "dyl-components";
import { useRef } from "react";
import { DropdownItem, Ref } from "semantic-ui-react";
import { StringUtils } from "utils";

const ProductField = ({ field, onEdit, isSubrow }) => {
    const ref = useRef();
    const RowComponent = isSubrow ? Table.CollapsibleRowContent : Table.Row
    return (
        <RowComponent isSubrow={isSubrow}>
            <Table.Cell />
            <Table.Cell>{StringUtils.capitalize(field.name)}</Table.Cell>
            <Table.Cell>{StringUtils.capitalize(field.type)}</Table.Cell>
            <Table.Cell>
                <CollapsibleList
                    minimumNumberOfItemsToShow={3}
                    items={field.values.map(({ name }) => <ClippedContent key={name}>{name}</ClippedContent>)}
                />
            </Table.Cell>
            <Table.Cell><span style={{ wordBreak: "break-word" }}>{field.description}</span></Table.Cell>
            <Table.Cell>
                <Ref innerRef={ref}>
                    <EllipsisDropdownPortal reference={ref}>
                        <DropdownItem
                            text="Edit"
                            icon="fa-solid fa-pencil"
                            onClick={onEdit}
                        />
                    </EllipsisDropdownPortal>
                </Ref>
            </Table.Cell>
        </RowComponent>
    );
};

export default ProductField;
