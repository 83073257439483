import { MultipleToggableDropdown } from 'dyl-components';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Form, Icon } from 'semantic-ui-react';

import './ContactGroupForm.scss';

const ContactGroupForm = ({groups, onHoverParent, onSave, onCancel, control, handleSubmit, isDirty, isValid, search, onSearchChange, isReadingGroups, setSearch}) => {
        return (
            <div style={{display: 'flex', paddingBottom: 150}}>
                <Controller
                    name='groups'
                    control={control}
                    style={{margin: 0}}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Field
                            control={MultipleToggableDropdown}
                            values={value}
                            nested_options={groups}
                            onChange={( _, { value }) => {
                                setSearch("");
                                onChange({ target: { name, value }});
                            }}
                            placeholder='Search groups'
                            onHoverParent={onHoverParent}
                            searchValue={search}
                            onSearchChange={onSearchChange}
                            loading={isReadingGroups}
                            selection
                        />
                    )}
                />
                <div className='ContactGroupForm__buttonContainer'>
                    <Icon size='large' link onClick={onCancel} className='fa-solid fa-xmark redIcon' style={{marginBottom: 21}} />
                    <Icon size='large' disabled={!isDirty || !isValid} color='primary' link onClick={handleSubmit(onSave)} className='fa-solid fa-check' style={{marginBottom: 21}} />
                </div>
            </div>
        )
}

export default ContactGroupForm;
