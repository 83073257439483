import React from 'react';
import { Icon, Dropdown } from 'semantic-ui-react';

const DropdownItem = ({ text, icon, ...otherProps }) => {
    return (
        <Dropdown.Item 
            text={
                <React.Fragment>
                    <Icon name={icon} />
                    {text}
                </React.Fragment>
            }
            style={{ cursor: 'pointer' }}
            {...otherProps}
        />
    );
}

export default DropdownItem;