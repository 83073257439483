import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Form } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { CustomGroupUtils, StringUtils } from 'utils';
import { Controller } from 'react-hook-form';
import { ClippedContent, PriorityIcon, TemperatureIcon } from 'dyl-components';
import MasterSecondarySourceOptions from 'shared/forms/MasterSecondarySourceOptions';

const LeadInfo = ({
    isEditing,
    control,

    textAlign = 'right'
}) => {
    const current_stage = useSelector(state => state.contact.current_stage);
    const custom_data = current_stage?.custom_data || {};
    const fields = CustomGroupUtils.groupAndFlatten(custom_data).fields;
    const rating_options = custom_data.fields.find(field => field.field_name === 'rating')?.options?.data?.map(option => ({
        key: option.id,
        value: option.id,
        text: option.value,
        content: (
            <TemperatureIcon type={option.value} />
        )
    })) || [];
    const priority_options = custom_data.fields.find(field => field.field_name === 'priority')?.options?.data?.map(option => ({
        key: option.id,
        value: option.id,
        text: option.value,
        content: (
            <PriorityIcon type={option.value} />
        )
    })) || [];
    const type_options = custom_data.fields.find(field => field.field_name === 'type')?.options?.data?.map(option => ({
        key: option.id,
        value: option.id,
        text: option.value
    })) || [];

    const type = type_options.find(option => option.value === fields?.type)?.text || ''
    const priority = priority_options.find(option => option.value === fields?.priority)?.text || '';
    const rating = rating_options.find(option => option.value === fields?.rating)?.text || '';
    const master_source = current_stage?.master_source;
    const secondary_source = current_stage?.secondary_source;
    const campaign_converted = Object.keys(current_stage.campaign_converted).length > 1 && (Boolean(current_stage.campaign_converted.id) && Boolean(current_stage.campaign_converted.name)) ?
        <Link to={`/campaigns/${current_stage.campaign_converted.id}/dashboard`}><ClippedContent>{current_stage.campaign_converted.name}</ClippedContent></Link> : 'None';

    return (
        <Grid className='RecordInfo'>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Type
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        type || 'None'
                    ) : (
                        <Controller
                            name='type'
                            control={control}
                            render={({ field: { name, onChange, value } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Select Type'
                                    options={type_options}
                                    selectOnBlur={false}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Priority
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        fields?.priority ? <span><PriorityIcon type={priority.toLowerCase()} /> {StringUtils.capitalize(priority)}</span> : 'None'
                    ) : (
                        <Controller
                            name='priority'
                            control={control}
                            render={({ field: { name, onChange, value } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Select Priority'
                                    options={priority_options}
                                    selectOnBlur={false}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Rating
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        fields?.rating ? <span><TemperatureIcon type={rating.toLowerCase()} /> {StringUtils.capitalize(rating)}</span> : 'None'
                    ) : (
                        <Controller
                            name='rating'
                            control={control}
                            render={({ field: { name, onChange, value } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Select Rating'
                                    options={priority_options}
                                    selectOnBlur={false}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Score
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {fields?.score}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Close Probability
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {fields?.close_probability}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Total Cost
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {fields?.total_cost}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Expected Revenue
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {fields?.expected_revenue}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <ClippedContent>
                        Master/Secondary Source
                    </ClippedContent>
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        master_source ? `${master_source}/${secondary_source?.name}` : 'None'
                    ) : (
                        <Controller
                            name='master_source'
                            control={control}
                            render={({ field: { name: masterSourceFieldName, value: masterSourceFieldValue, onChange: masterSourceOnChange } }) => (
                                <Controller
                                    name='secondary_source'
                                    control={control}
                                    render={({ field: { name: secondarySourceFieldName, value: secondarySourceValue, onChange: secondarySourceOnChange } }) => (
                                        <Form.Field
                                            control={MasterSecondarySourceOptions}
                                            onChange={(_, { parent_value: master_source, child_value: secondary_source }) => {
                                                masterSourceOnChange({ target: { name: masterSourceFieldName, value: master_source } });
                                                secondarySourceOnChange({ target: { name: secondarySourceFieldName, value: secondary_source } });
                                            }}
                                            value={secondarySourceValue}
                                            parentValue={masterSourceFieldValue}
                                            label='Master/Secondary Source'
                                            placeholder='Select Source'
                                        />
                                    )}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <ClippedContent>
                        Campaign Converted
                    </ClippedContent>
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {campaign_converted}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default LeadInfo;

