import React from 'react';
import { Icon, Popup, Dropdown } from 'semantic-ui-react';
import { Button } from 'dyl-components';
import { connect } from 'react-redux';

import TransferForm from 'shared/forms/TransferForm';
import callActions from 'actions/call';
import './Controls.scss';

const Controls = ({ onTransfer, onHangup, isHangingUp, channel_uuid }) => (
    <React.Fragment>
        <Popup 
            trigger={(
                <Button 
                    color='hippie-blue' 
                    onClick={onTransfer}> 
                    <Icon name='exchange' />
                    Transfer
                </Button>
            )}
            content={(
                <TransferForm />
            )}
            on='click'
        />
        <Dropdown
            button
            trigger={
                <span>
                    <Icon name='play circle' /> Voicemail
                </span>
            }
            floating
            className='Controls__vm-drop'
            options={[
                { key: 'Voicemail', text: 'Voicemail', value: 'Voicemail' },
                { key: 'Cell', text: 'Cell', value: 'Cell' },
            ]}
        />
        <Button 
            color='new-york-pink' 
            onClick={() => {onHangup(channel_uuid)}}
            loading={isHangingUp}
        >
            <Icon name='phone' />
            Hangup
        </Button>
        <Button 
            floated='right'
            basic
        >
            <Popup 
                trigger={<Icon name='cancel' color='blue' />}
                inverted
                content='Call Release'
            />
        </Button>
    </React.Fragment>
);

const mapStateToProps = state => ({
    isHangingUp: state.call.isHangingUp,
    channel_uuid: state.call.channel_uuid
})

const mapDispatchToProps = dispatch => ({
    onHangup: (channel_uuid) => {
        dispatch(callActions.hangup({
            channel_uuid
        }));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
