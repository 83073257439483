import React from 'react';
import { ContactsHeader } from './Header';
import { ContactsRow } from './Row';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RecordsTable } from 'dyl-components';

const ContactsTable = ({ onFilter, onPageChange, readContacts }) => {
    const { count, contacts = [], isReadingContacts } = useSelector(state => state.campaign_contacts);

    const { campaign } = useSelector(state => ({
        campaign: state.campaigns.campaign
    }));

    const isCampaignDeleted = (campaign.status || '').toLowerCase() === 'deleted';

    const contactsData = contacts.map(campaign_contact => {
        const { contact, active, account_id, unlinked } = campaign_contact;
        const mainLocation = contact.location?.data.filter(location => location.main).length > 0 ? contact.location.data.filter(location => location.main)[0] : {}
        const { city = "", street = "", additional_street = "", state = "", zip = "", label = "" } = mainLocation;
        const first_name = contact.first_name || '';
        const last_name = contact.last_name || '';
        const suffix = contact.suffix || '';

        return {
            id: contact.id,
            active,
            unlinked,
            first_name,
            last_name,
            suffix,
            email: contact.email,
            phone: [contact.phone],
            location: Object.keys(mainLocation).length === 0 ? [] : mainLocation,
            address: `${label ? `${label}:` : ''} ${street ? `${street},` : ''} ${additional_street ? `${additional_street},` : ''} ${city ? `${city},` : ''} ${state || ''} ${zip || ''} `,
            campaign_converted: campaign_contact.campaign_converted,
            account_id,
            ...((campaign_contact.campaign_converted && campaign_contact.pipeline_id) ? { pipeline_id: campaign_contact.pipeline_id } : {})
        }
    });

    const [params] = useSearchParams();

    return (
        <RecordsTable
            tableBodyClassName='CampaignContactsTable'
            isTableLoading={isReadingContacts}
            isSearchResultEmpty={count === 0 && (params.get('search') || params.get('start') || params.get('end') || params.get('account_type') || params.get('owned_by'))}
            isTableEmpty={count === 0 && !(params.get('search') || params.get('start') || params.get('end') || params.get('account_type') || params.get('owned_by'))}
            TableHeader={<ContactsHeader onFilter={onFilter} />}
            TableRows={contactsData.map(contact => (
                <ContactsRow isCampaignDeleted={isCampaignDeleted} key={contact.id} contact={contact} readContacts={readContacts} />
            ))}
            recordsCount={count}
            activePage={params.get('page')}
            onPageChange={onPageChange}
            recordsName='Contact'
            emptySubtitle=' '
            emptyListTitle={'No Contacts Added'}
        />
    )
}

export default ContactsTable;
