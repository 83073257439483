import { generateCreateActionCreator, generateReadActionCreator } from '../crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import routes from 'actions/routes';

export default {
    readProducts: generateReadActionCreator(routes.API_PRODUCT, ACTION_NAMES.PRODUCT),
    readCategoryProducts: generateReadActionCreator(routes.API_PRODUCT, ACTION_NAMES.CATEGORY_PRODUCT),
    addProduct: generateCreateActionCreator(routes.API_PRODUCT, ACTION_NAMES.PRODUCT),
    isDuplicate: generateReadActionCreator(`${routes.API_PRODUCT}/check_duplicate`, ACTION_NAMES.CHECK_PRODUCT_DUPLICATE)
}
