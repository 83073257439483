export const callResultOptions = [
    {
        key: 'reached', value: 'reached', text: 'Reached'
    },
    {
        key: 'not available', value: 'not available', text: 'Not Available'
    },
    {
        key: 'voicemail', value: 'voicemail', text: 'Voicemail'
    },
    {
        key: 'do not call', value: 'do not call', text: 'Do not Call'
    },
]