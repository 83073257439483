import React from 'react';
import { Tab } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

export const TextEmailPane = () => {
    const { email } = useSelector(state => state.lead_ingestion);
    const { text } = email?.email;   

    return (
        <Tab.Pane>
            <pre>{text}</pre>
        </Tab.Pane>
    );
}
