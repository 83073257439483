const initialState = {
    sequenceTaskBeingUpdated: false,

    sequenceTask: null,
    isReadingSequenceTask: false,
    sequenceTaskError: false,

    rescheduleSequenceTaskStats: { call: 0, email: 0, text: 0 },
    isReadingRescheduleSequenceTaskStats: false,
    rescheduleSequenceTaskStatsError: false,
    selectedSequenceTasks: [],
    selectedSequenceId: null,
    selectedSequenceName: '',

    sequenceTasks: [],
    sequence_task_count: 0,
    isReadingSequenceTasks: false,

    isReadingSequenceContactTasks: false
}

export default initialState;
