import React from 'react';
import { Grid, Header, Transition } from 'semantic-ui-react';
import { ButtonLink, GridTable, DataVisibilityTogglerContent } from 'dyl-components';
import { connect } from 'react-redux';

import RecentCallContainer from './RecentCall';
import officeViewActions from 'actions/office_view';
import { DateTimeUtils } from 'dyl-components';

const RecentCalls = ({ recentCalls, isMoreDataVisible }) => (
    <GridTable>
        <GridTable.Body>
            {recentCalls.length === 0 && (
                <GridTable.Row>
                    <GridTable.Column width={16}>
                        {/* <em>There are no recent calls</em> */}
                    </GridTable.Column>
                </GridTable.Row>
            )}
            {recentCalls.length > 0 && (
                <RecentCallContainer recentCall={recentCalls[0] || {}} key={recentCalls[0] && recentCalls[0].id} />
            )}
        </GridTable.Body>
        <Transition visible={isMoreDataVisible} animation='slide down' duration='100' unmountOnHide>
            <GridTable.Body>
                {recentCalls.slice(1).map(recentCall => (
                    <RecentCallContainer recentCall={recentCall} key={recentCall.call_uuid} />
                ))}
            </GridTable.Body>
        </Transition>
    </GridTable>
)

class RecentCallsContainer extends React.Component {
    state = {
        isMoreDataVisible: false
    }

    componentDidMount() {
        this.props.onReadRecentCalls({ end_at: DateTimeUtils.getLast(1, 'day') });
    }

    toggleDataVisibility = () => {
        this.setState({ isMoreDataVisible: !this.state.isMoreDataVisible });
    }

    render() {
        return (
            <React.Fragment>
                <Grid columns={3}>
                    <Grid.Column>
                        <Header as='h3'>Recent Calls</Header>
                    </Grid.Column>
                    <Grid.Column>
                        <ButtonLink onClick={this.toggleDataVisibility}>
                            <DataVisibilityTogglerContent isMoreDataVisible={this.state.isMoreDataVisible} />
                        </ButtonLink>
                    </Grid.Column>
                </Grid>
                <RecentCalls
                    recentCalls={this.props.recentCalls}
                    isMoreDataVisible={this.state.isMoreDataVisible}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        recentCalls: state.officeView.recentCalls
    }
}

const mapDispatchToProps = dispatch => ({
    onReadRecentCalls: (queryParameters) => {
        dispatch(officeViewActions.getRecentCalls(queryParameters));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(RecentCallsContainer)

