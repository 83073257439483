import React from 'react';
import { Table } from 'dyl-components';
import { Table as SemanticTable, } from 'semantic-ui-react';

const IntegrationHeader = () => (
    <Table.Row>
        <SemanticTable.HeaderCell width={4}>Apps</SemanticTable.HeaderCell>
        <SemanticTable.HeaderCell width={3}>Enabled</SemanticTable.HeaderCell>
        <SemanticTable.HeaderCell width={2}>Actions</SemanticTable.HeaderCell>
    </Table.Row>
)


export default IntegrationHeader;
