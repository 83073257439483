import {CloseableContent} from 'dyl-components';
import React, {useState} from 'react';
import {Dropdown, Button, Popup, Header, Ref} from 'semantic-ui-react';

import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import './ConversionControls.scss';
import ConvertToCustomerForm from "../../shared/forms/ConvertToCustomerForm";
import accountActions from "../../actions/account";
import LostForm from "shared/forms/LostForm";
import {CustomGroupUtils, StringUtils} from "utils";
import ReactivateCustomerForm from "../../shared/forms/ReactivateCustomerForm";

const ConversionControls = () => {
    const { account_id } = useParams();
    const { customer_information, primary_contact_id, account_name } = useSelector(state => ({
        customer_information: state.account.account?.customer,
        primary_contact_id: state.account.account?.primary_contact?.id,
        account_name: state.account.account?.name
    }));
    
    const modalRef = React.createRef();

    const [conversionModalOpen, setConversionModalOpen] = useState('');
    const [isConversionFormModified, setIsConversionFormModified] = useState(false);

    const { customer_status, customer_since, custom_data } = customer_information;

	const fields = CustomGroupUtils.groupAndFlatten(custom_data || {})?.fields;

    const onClose = () => {
        setConversionModalOpen('');
        setIsConversionFormModified(false);
    }
    
    const dispatch = useDispatch();
    
    const refresh = () => {
        dispatch(accountActions.readAccount(account_id));
    }
    
    const getPopupContent = () => {
        switch (conversionModalOpen) {
            case 'customer':
                return (
                    <React.Fragment>
                        <Header color='primary'>
                            Convert <Popup trigger={<span className='Convert__account-name'>{account_name}</span>} content={account_name} inverted /> to Customer
                        </Header>
                        <ConvertToCustomerForm 
                            account_id={account_id}
                            onClose={onClose}
                            size={'small'}
                            onModify={(isModified) => {setIsConversionFormModified(isModified)}}
                            refresh={refresh}
                            owner_id={primary_contact_id}
                            action="convert-account"
                        />
                    </React.Fragment>
                )
            case 'pause':
                return (
                    <React.Fragment>
                        <div className={"Account__lost-form"}>
                            <Header color='primary'>Pause Customer</Header>
                            <LostForm
                                account_id={account_id}
                                onClose={onClose}
                                type={'paused'}
                                account_stage={'customer'}
                                refresh={refresh}
                            />
                        </div>
                    </React.Fragment>
                )
            case 'cancel':
                return (
                    <React.Fragment>
                        <div className={"Account__lost-form"}>
                            <Header color='primary'>Cancel Customer</Header>
                            <LostForm
                                account_id={account_id}
                                onClose={onClose}
                                type={'canceled'}
                                account_stage={'customer'}
                                refresh={refresh}
                            />
                        </div>
                    </React.Fragment>
                )
			case 'reactivate':
				return (
					<React.Fragment>
						<Header color='primary'>Reactivate Customer</Header>
						<ReactivateCustomerForm
							account_id={account_id}
							onClose={onClose}
							size={'small'}
							onModify={(isModified) => {setIsConversionFormModified(isModified)}}
							refresh={refresh}
							customer_since={customer_since}
							customer_type={fields?.customer_type}
                            primary_contact_id={primary_contact_id}
						/>
					</React.Fragment>
				)
            default:
                return null;
        }
    }

    function getActionText() {
        if (customer_status) {
            return StringUtils.capitalize(customer_status);
        }
        return 'Convert';
    }
    
    function getStatusClass() {
        if (customer_status) {
            if (customer_status === "active") {
                return "CustomerConversion--active"
            }
            return `CustomerConversion--${customer_status}`
        }
        return 'CustomerConversion--convert'
    }

    return (
        <React.Fragment>
            <Popup
                content={(
                    <CloseableContent onClose={onClose}>
                        {getPopupContent()}
                    </CloseableContent>
                )}
                open={conversionModalOpen}
                position='bottom center'
                context={modalRef}
                closeOnDocumentClick={!isConversionFormModified}
                onClose={() => {
                    if (conversionModalOpen !== "pause" && conversionModalOpen !== "cancel") {
                        onClose();
                    }
                }}
            />
            <Ref innerRef={modalRef}>
                <Dropdown as={Button} size='tiny' text={getActionText()} className={`CustomerConversion ${getStatusClass()}`}>
                    <Dropdown.Menu>
                        {customer_status === undefined && (
                            <Dropdown.Item onClick={() => {
                                setConversionModalOpen('customer')
                            }}>
                                Convert to Customer
                            </Dropdown.Item>
                        )}
                        {(customer_status === 'paused' || customer_status === 'canceled') && (
                            <Dropdown.Item onClick={() => {
                                setConversionModalOpen('reactivate')
                            }}>
                                Reactivate
                            </Dropdown.Item>
                        )}
                        {customer_status === 'active' && [
                            <Dropdown.Item onClick={() => {
                                setConversionModalOpen('pause')
                            }}>
                                Pause Customer
                            </Dropdown.Item>
                        ]}
                        {(customer_status === 'active' || customer_status === 'paused') && (
                            <Dropdown.Item onClick={() => {
                                setConversionModalOpen('cancel')
                            }}>
                                Cancel Customer
                            </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </Ref>
        </React.Fragment>
    );
};

export default ConversionControls;

