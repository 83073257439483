import { useEffect, useState } from 'react';

const useWidthListener = (id) => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const resize_ob = new ResizeObserver(function (entries) {
            let rect = entries[0].contentRect;

            let width = rect.width;

            setWidth(width);
        });

        const elementRef = document.getElementById(id);

        if (elementRef) {
            resize_ob.observe(elementRef);
        }

        return () => {
            if (elementRef) {
                resize_ob.unobserve(elementRef);
            }
            resize_ob.disconnect();
        }
    }, [id])

    return width;
}

export default useWidthListener;