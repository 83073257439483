import React from 'react';
import { Segment, Form, Input, } from 'semantic-ui-react';
import { Button, Notification, STATUS_TYPES } from 'dyl-components';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import * as EmailValidator from 'email-validator';


import authActions from 'actions/auth/auth';

import './index.scss';

const ForgottenPassword = ({ onSendPasswordRecoveryLink, isEmailValid, onChange }) => (
    <div className="ForgottenPasswordWrapper">
        <Segment className='ForgottenPassword'>
            <h2 className="ForgottenPassword__header">Password recover</h2>
            <div className="ForgottenPassword__content">
                <Form onSubmit={onSendPasswordRecoveryLink}>
                    <div className="ForgottenPassword__fields">
                        <Form.Field>
                            <Input icon='mail' iconPosition='left' placeholder='Email' onChange={onChange} name='email' fluid />
                        </Form.Field>
                    </div>
                    <Button disabled={!isEmailValid} type="submit" color='green' fluid>SEND PASSWORD RECOVERY LINK</Button>
                </Form>
            </div>
        </Segment>
    </div>
)

class ForgottenPasswordContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                email: ''
            },
            isEmailValid: false,
            redirect: null
        }
    }

    onSendPasswordRecoveryLink(e) {
        this.props.onSendPasswordRecoveryLink(this.state.form.email)
            .then((response) => {
                Notification.alert(response.message, STATUS_TYPES.SUCCESS, true);
                this.setState({ redirect: '/login' })
            })
    }

    onChange(e) {
        let form = { ...this.state.form };
        form[e.target.name] = e.target.value;
		this.setState({ form, isEmailValid: EmailValidator.validate(form.email) });
    }

    componentDidMount() {
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <ForgottenPassword 
	    onSendPasswordRecoveryLink={this.onSendPasswordRecoveryLink.bind(this)}
	    isEmailValid={this.state.isEmailValid}
	    onChange={this.onChange.bind(this)}
	    />
        );
    }
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        onSendPasswordRecoveryLink: (email) => {
            return dispatch(authActions.sendPasswordRecoveryLink({ email }))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgottenPasswordContainer);
