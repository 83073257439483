const initialState = {
    task: {},
    isDeletingTask: false,
    isReadingTask: false,
    isUpdatingTask: false,

    isUpdatingTaskLabel: false,
    isDeletingTaskLabel: false,
    isCheckingTaskLabelDuplicate: false,

    task_type_labels: [],
    isReadingTaskTypeLabels: false,

    taskBeingUpdated: null,
    taskBeingCompleted: null
}

export default initialState;
