import {
    DateInput,
    DatesRangeInput,
    DateTimeInput, DropdownTimeInput, NestedDropdown,
    PriorityIcon,
    TemperatureIcon
} from 'dyl-components';
import { Checkbox, Form, Icon, Input, Radio, Select, TextArea } from 'semantic-ui-react';
import React from 'react';
import { STATES } from "shared/constants/STATES";
import { Controller } from 'react-hook-form';
import UserField from 'shared/forms/UserField';

export const FIELD_TYPE = {
    TEXT: "text",
    ACCOUNT_LOOKUP: "account",
    USER_LOOKUP: "user",
    NUMBER: "number",
    PICKLIST: "picklist",
    DATETIME: "datetime",
    CAMPAIGN_LOOKUP: "campaign",
    CONTACT_LOOKUP: "contact",
    PERCENT: "percent",
    CURRENCY: "currency",
    CHECKBOX: "checkbox",
    URL: "url",
    DATE: "date",
    EMAIL: "email",
    PHONE: "phone",
    STATE: "state",

    PRIORITY: "priority",
    RATING: "rating",
    DATERANGE: "daterange",
    TIME: "time",
    TIMERANGE: "timerange",
    RADIO: "radio",
    TAGSINPUT: "tagsinput",
    DROPDOWN: "dropdown",
    USER: "user",
    TEXTAREA: "textarea",
    ADDRESS: "address",
    DEPENDENCY: "dependency"
};

export const FIELD_TYPE_CONTROL = {
    [FIELD_TYPE.TEXT]: (props) => (
        <Input
            type='text'
            {...props}
        />
    ),
    [FIELD_TYPE.TAGSINPUT]: (props) => (
        <Select
            {...props}
            multiple
            selectOnBlur={false}
        />
    ),
    [FIELD_TYPE.NUMBER]: (props) => (
        <Input
            {...props}
            type='number'
            min='0'
            step='1'
            onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
        />
    ),
    [FIELD_TYPE.DROPDOWN]: (props) => (
        <Select
            {...props}
            multiple={false}
            selectOnBlur={false}
        />
    ),
    [FIELD_TYPE.PICKLIST]: (props) => (
        <Select
            {...props}
            multiple
            selectOnBlur={false}
        />
    ),
    [FIELD_TYPE.DATETIME]: (props) => (
        <DateTimeInput
            {...props}
        />
    ),
    [FIELD_TYPE.PERCENT]: (props) => (
        <Input
            {...props}
            type='number'
            icon={<Icon className='fas fa-percent' />}
            onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
        />
    ),
    [FIELD_TYPE.CURRENCY]: (props) => (
        <Input
            {...props}
            type='number'
            icon={<Icon className='fas fa-dollar-sign' />}
            iconPosition='left'
            onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
        />
    ),
    [FIELD_TYPE.CHECKBOX]: ({ onChange, name, ...props }) => (
        <Checkbox
            {...props}
            checked={props.value}
            onChange={(e, { checked }) => { onChange(e, { name, value: checked }) }}
            toggle
        />
    ),
    [FIELD_TYPE.URL]: (props) => (
        <Input
            {...props}
        />
    ),
    [FIELD_TYPE.DATE]: (props) => (
        <DateInput
            {...props}
        />
    ),
    [FIELD_TYPE.EMAIL]: (props) => (
        <Input
            {...props}
            icon={<Icon className='fas fa-envelope' />}
            iconPosition='left'
            type='email'
        />
    ),
    [FIELD_TYPE.PHONE]: (props) => (
        <Input
            {...props}
            icon={<Icon className='fas fa-phone' />}
            iconPosition='left'
        />
    ),
    [FIELD_TYPE.STATE]: (props) => (
        <Select
            {...props}
            multiple={false}
            selectOnBlur={false}
            options={[]}
            search
        />
    ),
    [FIELD_TYPE.RATING]: (props) => (
        <Select
            {...props}
            multiple={false}
            selectOnBlur={false}
            options={[
                { key: 'hot', value: 'hot', text: <div><TemperatureIcon type='hot' /> <span> Hot </span> </div> },
                { key: 'warm', value: 'warm', text: <div><TemperatureIcon type='warm' /> <span> Warm </span> </div> },
                { key: 'cold', value: 'cold', text: <div><TemperatureIcon type='cold' /> <span> Cold </span> </div> }
            ]}
        />
    ),
    [FIELD_TYPE.PRIORITY]: (props) => (
        <Select
            {...props}
            multiple={false}
            options={[
                { key: 1, value: 'High', text: <div><PriorityIcon type='high' /> <span> High </span> </div> },
                { key: 2, value: 'Medium', text: <div><PriorityIcon type='medium' /> <span> Medium </span> </div> },
                { key: 3, value: 'Low', text: <div><PriorityIcon type='low' /> <span> Low </span> </div> }
            ]}
        />
    ),
    [FIELD_TYPE.DATERANGE]: (props) => (
        <DatesRangeInput
            {...props}
        />
    ),
    [FIELD_TYPE.TIME]: (props) => (
        <DropdownTimeInput
            {...props}
        />
    ),
    [FIELD_TYPE.RADIO]: (props) => {
        const { options, value, onChange } = props;
        return options.filter(option => !option.deleted).map(option => (
            <Radio
                onClick={(e) => { onChange(e, { value: value === option.value ? null : option.value }) }}
                checked={value === option.value}
                label={option.text}
            />
        ))
    },
    [FIELD_TYPE.USER]: (props) => (
        <UserField
            {...props}
        />
    ),
    [FIELD_TYPE.TEXTAREA]: (props) => (
        <TextArea
            {...props}
        />
    ),
    [FIELD_TYPE.ADDRESS]: ({ name, fieldControl }) => {
        return (
            <div>
                <Controller
                    name={`${name}.street`}
                    control={fieldControl}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            placeholder={'Street'}
                            onChange={(e, { value }) => { onChange({ target: { name, value } }) }}
                            value={value}
                            fluid
                            error={error?.message}
                        />
                    )}
                />
                <Controller
                    name={`${name}.additional_street`}
                    control={fieldControl}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            placeholder={'Additional Street'}
                            onChange={(e, { value }) => { onChange({ target: { name, value } }) }}
                            value={value}
                            fluid
                            error={error?.message}
                        />
                    )}
                />
                <Controller
                    name={`${name}.city`}
                    control={fieldControl}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            placeholder={'City'}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            value={value}
                            fluid
                            error={error?.message}
                        />
                    )}
                />
                <Controller
                    name={`${name}.state`}
                    control={fieldControl}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Dropdown
                            selection
                            placeholder={'State'}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            options={STATES.slice(1).map(state => ({ ...state, value: state.key }))}
                            selectOnBlur={false}
                            value={value}
                            fluid
                            error={error?.message}
                        />
                    )}
                />
                <Controller
                    name={`${name}.zip`}
                    control={fieldControl}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            placeholder={'Zip'}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            value={value}
                            fluid
                            error={error?.message}
                        />
                    )}
                />
            </div>
        )
    },
    [FIELD_TYPE.DEPENDENCY]: ({ value, onChange, options }) => {
        const [parentValue, childValue] = value.split(',').map(fieldValue => Number(fieldValue));
        return (
            <NestedDropdown
                child_value={childValue}
                parent_value={parentValue}
                nested_options={options}
                onChange={(e, { parent_value, child_value }) => {
                    const newValue = [parent_value, child_value].filter(value => value).join(',');
                    onChange(e, { value: newValue });
                }}
                display_parent
                selection
                allowedToSelectParentOnly
            />
        )
    },
    [FIELD_TYPE.TIMERANGE]: ({ value, onChange }) => {
        const { start_time, end_time } = value;
        return (
            <div>
                <DropdownTimeInput
                    name='start_time'
                    value={start_time}
                    onChange={(e, { name, value: newValue }) => { onChange(e, { value: { ...value, [name]: newValue, end_time: null } }) }}
                    placeholder='Start Time'
                />
                <DropdownTimeInput
                    name='end_time'
                    value={end_time}
                    onChange={(e, { name, value: newValue }) => { onChange(e, { value: { ...value, [name]: newValue } }) }}
                    placeholder='End Time'
                    {...start_time ? { minTime: start_time } : {}}
                />
            </div>
        )
    }
};
