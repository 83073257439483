import { useRef, useEffect, useState } from 'react';
import { useBlocker } from './UseBlocker';

const CustomPrompt = (messageObj, shouldPrompt, isConfirmed, header, subheader) => {
    const [confirmedNavigation, 
        setConfirmedNavigation
    ] = useState(false);

    const retryFn = useRef(() => { });

    useEffect(() => {
        if (confirmedNavigation) {
            retryFn.current()
        }
    }, [confirmedNavigation])

    const HandleBlockNavigation = async ({ retry, saved }) => {
        const shouldDisplayPrompt = typeof shouldPrompt === "boolean" ? shouldPrompt && !saved : shouldPrompt();
        if (shouldDisplayPrompt) {
            const test = await isConfirmed(header, subheader);
            if (test) {
                setConfirmedNavigation(true);
                retryFn.current = retry
            }
        } else {
            retry();
        }
    }

    return useBlocker(HandleBlockNavigation, !confirmedNavigation);
}

export default CustomPrompt;
