import { generateCreateActionCreator, generateDeleteActionCreator, generateReadActionCreator, generateReadOneActionCreator, generateUpdateActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import routes from 'actions/routes';

export default {
    readCampaigns: generateReadActionCreator(routes.API_CAMPAIGN, ACTION_NAMES.CAMPAIGNS),
    readPersonCampaignConverted: generateReadActionCreator(routes.API_CAMPAIGN_CONVERTED, ACTION_NAMES.CAMPAIGN_CONVERTED),
    createCampaign: generateCreateActionCreator(routes.API_CAMPAIGN, ACTION_NAMES.CAMPAIGNS),
    checkDuplicate: generateReadActionCreator(`${routes.API_CAMPAIGN}/check_duplicate`, ACTION_NAMES.CHECK_CAMPAIGN_DUPLICATE),
    readCampaign: generateReadOneActionCreator(routes.API_CAMPAIGN, ACTION_NAMES.CAMPAIGNS),
    updateCampaign: generateUpdateActionCreator(routes.API_CAMPAIGN, ACTION_NAMES.CAMPAIGNS),
    deleteCampaign: generateDeleteActionCreator(routes.API_CAMPAIGN, ACTION_NAMES.CAMPAIGNS),
    reactivateCampaign: generateUpdateActionCreator(routes.API_CAMPAIGN, ACTION_NAMES.REACTIVATE_CAMPAIGN, () => {}, () => {}, 'reactivate'),
    createCampaignUserReport: generateCreateActionCreator(routes.API_CAMPAIGN, ACTION_NAMES.CAMPAIGN_USER_REPORTS, () => { }, () => { }, 'user_reports'),
    readCampaignUserIds: generateReadOneActionCreator(routes.API_CAMPAIGN, ACTION_NAMES.CAMPAIGN_USER_REPORTS, 'user_ids'),
    campaignContactsBulkAction: generateUpdateActionCreator(routes.API_CAMPAIGN_BULK_ACTION, ACTION_NAMES.CAMPAIGN_BULK_ACTION, () => {}, () => {}, '', true),
    updatePersonCustomCampaignConverted: generateUpdateActionCreator(routes.API_CUSTOM_CAMPAIGN_CONVERTED, ACTION_NAMES.CAMPAIGN_CONVERTED),
    readCampaignsList: generateReadActionCreator(routes.API_CAMPAIGN_LIST, ACTION_NAMES.CAMPAIGNS_LIST),
}
