import emailTemplatesActions from 'actions/email_templates';
import PhoneUtil from './PhoneUtil';
import RecordUtils from './RecordUtils';
import { Notification, STATUS_TYPES } from 'dyl-components';
import { FileUtils } from 'utils';
import { Form }  from 'semantic-ui-react';

export const SIGNATURE_OPTIONS = [
    {
        key: 'my_signature',
        value: 'my_signature',
        text: 'My Signature'
    },
    {
        key: 'company_signature',
        value: 'company_signature',
        text: 'Company Signature'
    },
    {
        key: 'none',
        value: 'none',
        text: 'None'
    }
]

export const personalizedVariables = (variable, editorRef, body, setValue) => {
    const element = editorRef.current;
    const toInsert = variable;
    if (element.getEditorSelection) {
        const selection = element.getEditorSelection();
        const getEditorSelection = element.getEditor()?.selection?.savedRange;
        const position = selection ? selection.index : getEditorSelection?.index;
        element.getEditor().insertText(position, toInsert);
    } else {
        const textarea = element.ref.current;
        textarea.focus();
        const selectionStart = textarea.selectionStart;
        const beginning = body.substr(0, selectionStart);
        const end = body.substr(selectionStart);
        setValue('body', `${beginning}${toInsert}${end}`);
    }
}

export const handleChangeTemplate = (templateId, setValue, dispatch, trigger, userSignature, companySignature) => {
    if (templateId) {
        dispatch(emailTemplatesActions.getTemplate(templateId)).then(({ subject, content, attachments, signature_choice }) => {
            setValue('subject', subject);
            trigger('subject');
            setValue('body', content);
            setValue('attachments', (attachments?.data || []).map(attachment => ({
                path: attachment.name,
                id: attachment.id,
                file_id: attachment.file_id,
                name: attachment.name,
                size: attachment.size
            })));
            if(signature_choice === 'my_signature' && userSignature !== null){
                setValue('signature', signature_choice)
            }
            if(signature_choice === 'company_signature' && companySignature !== null){
                setValue('signature', signature_choice)
            }
            if(signature_choice === 'none' && (userSignature !== null || companySignature !== null)){
                setValue('signature', signature_choice)
            }
        });
    } else {
        setValue('subject', '');
        setValue('body', '');
        setValue('attachments', []);
    }
}

export const getEmailPreview = (formValues, userSignature, companySignature, contact, userProfile, contactLocation, account) => {
    const { body, signature } = formValues;
    let convertedBody = account?.account?.id ?
        getConvertedAccountBody(body, userProfile, account)
        : 
        getConvertedContactBody(body, userProfile, contact, contactLocation);
    
    let convertedSignature = signature === 'my_signature' ? 
        userSignature 
        : signature === 'company_signature' 
            ? companySignature 
            : '';
    return `${convertedBody} <br> ${convertedSignature}`;
}

export const determineSignature = (userSignature, companySignature) => {
    if (!userSignature && !companySignature) {
        return 'none';
    }
    if (!userSignature && companySignature) {
        return 'company_signature';
    }
    if (userSignature && !companySignature) {
        return 'my_signature';
    }
    if (userSignature && companySignature) {
        //inculdes template scenario
        return 'my_signature';
    }
}

export const disabledSignature = (userSignature, companySignature) => {
    //returns disabled scenario for each
    //[my_signature, company_signature, none]
    if (!userSignature && !companySignature) {
        return [true, true, false];
    }
    if (!userSignature && companySignature) {
        return [true, false, false];
    }
    if (userSignature && !companySignature) {
        return [false, true, false];
    }
    if (userSignature && companySignature) {
        //inculdes template scenario
        return [false, false, false];
    }
}

const getConvertedContactBody = (body, userProfile, contact, contactLocation) => {
    const {
        first_name: myFirstName = '',
        last_name: myLastName = '',
        work_phone: myPhone = '',
        cell_phone: myCell = '',
        company: { company_name: myCompany = '' }
    } = userProfile;
    const contactInfo = {
        ...contact,
        ...contact.contact,
    };
    const myFullName = `${myFirstName} ${myLastName}`;
    const formattedPhone = myPhone ? PhoneUtil.formatPhoneNumber(myPhone) :
        myCell ? PhoneUtil.formatPhoneNumber(myCell) : '';

    const fullName = `${RecordUtils.getName(contactInfo).firstLine || ''} ${RecordUtils.getName(contactInfo).secondLine || ''}`;
    const firstName = RecordUtils.getName(contactInfo).firstLine || '';
    const lastName = RecordUtils.getName(contactInfo).secondLine || '';

    let { address = '', phone = '' } = contact;
    let location = contactLocation || [];
    const defaultCity = location.find(loc => loc.id === contactInfo.primary_location_id) || location[0] || {};
    const { street = '', additional_street = '', city = '', state = '', zip = '' } = defaultCity;
    address = `${street} ${additional_street} ${city}, ${state} ${zip}`;
    phone = contact.phone.find(p => p.main === true) || phone[0] || '';
    const phoneFormatted = PhoneUtil.formatPhoneNumber(phone.phone)
    
    return body.replace(/%%My Full Name%%/g, myFullName).replace(/%%My First Name%%/g, myFirstName)
        .replace(/%%My Phone%%/g, formattedPhone).replace(/%%My Company%%/g, myCompany).replace(/%%Full Name%%/g, fullName)
        .replace(/%%First Name%%/g, firstName).replace(/%%Last Name%%/g, lastName).replace(/%%Phone%%/g, phoneFormatted)
        .replace(/%%Address%%/g, address).replace(/%%City%%/g, city).replace(/%%State%%/g, state)
        .replace(/%%Zip%%/g, zip)
}

const getConvertedAccountBody = (body, userProfile, account) => {
    const {
        first_name: myFirstName = '',
        last_name: myLastName = '',
        work_phone: myPhone = '',
        cell_phone: myCell = '',
        company: { company_name: myCompany = '' }
    } = userProfile;
    const { name: fullName='', phones, locations: location=[] } = account.account;

    const myFullName = `${myFirstName} ${myLastName}`;
    const formattedPhone = myPhone ? PhoneUtil.formatPhoneNumber(myPhone) :
        myCell ? PhoneUtil.formatPhoneNumber(myCell) : '';
    const defaultCity = location.find(loc => loc.main === true) || location[0] || {};
    const { street = '', city = '', state = '', zip = '' } = defaultCity;
    const address = `${street} ${city}, ${state} ${zip}`;
    let phone = phones ? phones.find(p => p.main === true) || phones[0] : '';
    const phoneFormatted = PhoneUtil.formatPhoneNumber(phone.phone)

    return body.replace(/%%My Full Name%%/g, myFullName)
        .replace(/%%My First Name%%/g, myFirstName).replace(/%%My Phone%%/g, formattedPhone).replace(/%%My Company%%/g, myCompany)
        .replace(/%%Full Name%%/g, fullName).replace(/%%Phone%%/g, phoneFormatted)
        .replace(/%%Address%%/g, address).replace(/%%City%%/g, city).replace(/%%State%%/g, state).replace(/%%Zip%%/g, zip)
}

export const personalizeButtons = (account, personalize) => {
    return(
        <Form className="Template__variables">
            <h5>Sender Variables</h5>
            <Form.Group widths='equal'>
                <Form.Button onClick={() => { personalize('%%My Full Name%%') }} fluid size='mini' basic primary>My Full Name</Form.Button>
                <Form.Button onClick={() => { personalize('%%My First Name%%') }} fluid size='mini' basic primary>My First Name</Form.Button>
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Button onClick={() => { personalize('%%My Phone%%') }} fluid size='mini' basic primary>My Phone</Form.Button>
                <Form.Button onClick={() => { personalize('%%My Company%%') }} fluid size='mini' basic primary>My Company</Form.Button>
            </Form.Group>
            <h5>Recipient Variables</h5>
            <Form.Group widths='equal'>
                <Form.Button onClick={() => { personalize('%%Full Name%%') }} fluid size='mini' basic primary>Full Name</Form.Button>
                {!account &&
                    <Form.Button onClick={() => { personalize('%%First Name%%') }} fluid size='mini' basic primary>First Name</Form.Button>
                }
                {account &&
                    <Form.Button onClick={() => { personalize('%%Phone%%') }} fluid size='mini' basic primary>Phone</Form.Button>
                }
            </Form.Group>
            {!account &&
                <Form.Group widths='equal'>
                    <Form.Button onClick={() => { personalize('%%Last Name%%') }} fluid size='mini' basic primary>Last Name</Form.Button>
                    <Form.Button onClick={() => { personalize('%%Phone%%') }} fluid size='mini' basic primary>Phone</Form.Button>
                </Form.Group>
            }
            <Form.Group widths='equal'>
                <Form.Button onClick={() => { personalize('%%Address%%') }} fluid size='mini' basic primary>Address</Form.Button>
                <Form.Button onClick={() => { personalize('%%City%%') }} fluid size='mini' basic primary>City</Form.Button>
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Button onClick={() => { personalize('%%State%%') }} fluid size='mini' basic primary>State</Form.Button>
                <Form.Button onClick={() => { personalize('%%Zip%%') }} fluid size='mini' basic primary>Zip</Form.Button>
            </Form.Group>
        </Form>
    )
}

export const attachmentsExist = (email) => {
    const emailAttachment = (email?.type === 'email-gmail' && email?.attachments !== null);
    const emailThreadAttachment = email?.type === 'email-gmail' && Object.keys(email?.threads).some(key => email.threads[key].attachments !== null);
    if (emailAttachment || emailThreadAttachment) {
        return true;
    }
    return false;
};

export const sendEmailAttachment = async (attachments, emailToSend) => {
    try {
        let base64Attachments = [];

        for (const attachment of attachments) {
            //convert base64 and remove extra headers
            let data = await FileUtils.readFileToBase64(attachment);
            let dataReplace = data.replace(`data:${attachment.type};base64,`, '');
            //add to form data key pair value
            let formData = new FormData();
            formData.append("data", dataReplace);

            base64Attachments.push({
                data: formData.get("data"),
                data_type: attachment.type,
                filename: attachment.name,
                size: attachment.size,
            });
        }
        //add attachments to email body
        emailToSend.attachments = base64Attachments;

    } catch (err) {
        Notification.alert('Failed to add attachment.', STATUS_TYPES.ERROR, true);
    }
}
