import React from "react";
import { Modal, FileInput, Notification, STATUS_TYPES } from "dyl-components";
import { Grid, Checkbox, Button } from "semantic-ui-react";
import { connect } from "react-redux";

import TextForm from "shared/forms/TextForm";
import textActions from "actions/text";
import imageActions from "actions/image";

import { PhoneUtil } from "utils";

import "./TextMessageModal.scss";
import text_templatesActions from "actions/text_templates";

export const TEXT_MESSAGE_MODAL_ID = "TEXT_MESSAGE";

const TextMessageModal = ({
    open,
    onClose,
    to_phone,
    onChange,
    onSelectTemplate,
    body,
    selectedTemplate,
    sendSMSText,
    onAddShortcode,
    isReadingUser,
    isSendingSMSText,
    isSendingMMSText,
    attachment,
    isConsentingToTextMessaging
}) => (
        <Modal
            open={open}
            className="TextMessageModal"
            onClose={onClose}
            animated={false}
        >
            <Modal.Header>Text Message</Modal.Header>
            <Modal.Content>
                <TextForm
                    phone={PhoneUtil.formatPhoneNumber(to_phone)}
                    message={body}
                    onChange={onChange}
                    onSelectTemplate={onSelectTemplate}
                    selectedTemplate={selectedTemplate}
                    name="body"
                    onAddShortcode={onAddShortcode}
                />
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <div>
                                <Checkbox
                                    label="Consent to Text Messaging"
                                    onChange={
                                        (e, { name, checked }) => {
                                            onChange(e, { name, value: checked })
                                        }
                                    }
                                    name='isConsentingToTextMessaging'
                                    checked={isConsentingToTextMessaging}
                                />
                            </div>
                            <div className="TextMessageModal__consent">
                                <p>
                                    I agree that I have consent allowing me to send
                                    this message and that the intended recipient has
                                    not previously opted out.
                            </p>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <div>
                                <FileInput onChange={onChange} name='attachment' files={attachment} icon='file outline' />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                {!isSendingSMSText && !isSendingMMSText && (
                    <Button basic onClick={onClose}>
                        Cancel
                    </Button>
                )}
                <Button
                    primary
                    onClick={sendSMSText}
                    loading={
                        isReadingUser || isSendingSMSText || isSendingMMSText
                    }
                    disabled={!isConsentingToTextMessaging}
                >
                    Send
                </Button>
            </Modal.Actions>
        </Modal>
    );

class TextMessageModalContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            body: this.props.body || "",
            attachment: null,
            selectedTemplate: this.props.template || null,
            isConsentingToTextMessaging: false
        };
        this.onChange = this.onChange.bind(this);
        this.onSelectTemplate = this.onSelectTemplate.bind(this);
        this.onAddShortcode = this.onAddShortcode.bind(this);
    }

    onChange(_, { name, value }) {
        this.setState({ [name]: value });
    }

    onSelectTemplate(_, { value }) {
        this.props.onReadTemplate(value).then(({ message }) => {
            this.setState({ body: message, selectedTemplate: value });
        });
    }

    onAddShortcode(currentlyFocusedElement, value) {
        currentlyFocusedElement.focus();
        let fieldWithShortCode = `${this.state[
            currentlyFocusedElement.name
        ].substr(
            0,
            currentlyFocusedElement.selectionStart
        )}${value}${this.state[currentlyFocusedElement.name].substr(
            currentlyFocusedElement.selectionStart
        )}`;
        this.setState({
            [currentlyFocusedElement.name]: fieldWithShortCode
        });
    }

    sendSMSText = () => {
        let to = "NA";
        if (this.props.to_phone) {
            to = `${this.props.to_phone}`;
        }
        const from = `${this.props.from_phone}`;
        return (() => {
            if (!this.props.textPhones.some(textPhone => textPhone && `${textPhone.phone}` === from)) {
                return this.props.onAddPhone([
                    { carrier: 'bandwidth', messaging_type: 'p2p', phone: from }
                ]).then(() => {
                    return this.props.onReadTextPhones();
                });
            }
            return Promise.resolve();
        })().then(() => {
            const { file } = this.props;
            const textBody = {
                from,
                to,
                message: this.state.body,
                contact_id: this.props.contact_id
            }
            if (file) {
                return this.props.onSendMMSText(file, textBody)
            }
            return this.props.onSendSMSText(textBody).then(() => {
                this.props.onClose();
            });
        }).catch(error => {
            console.log(error);
            Notification.alert('Failed to send message', STATUS_TYPES.ERROR, true);
        })
    };

    render() {
        return (
            <TextMessageModal
                {...this.props}
                {...this.state}
                onChange={this.onChange}
                onSelectTemplate={this.onSelectTemplate}
                onAddShortcode={this.onAddShortcode}
                sendSMSText={this.sendSMSText}
            />
        );
    }
}

const mapDispatchToProps = dispatch => ({
    onReadTemplate: (templateId) => {
        return dispatch(text_templatesActions.getTemplate(templateId));
    },
    onSendSMSText: smspayload => {
        return dispatch(textActions.sendSMSText(smspayload));
    },
    onSendMMSText: (attachment, textBody) => {
        return dispatch(imageActions.uploadMMSPayload(attachment))
            .then(({ url }) => {
                return dispatch(textActions.sendMMSText({
                    ...textBody, attachments: [
                        { url, id: url }
                    ]
                }));
            })
    },
    onReadTextPhones: () => {
        return dispatch(textActions.getPhones());
    },
    onAddPhone: (phones) => {
        return dispatch(textActions.createPhone(phones));
    }
});

const mapStateToProps = state => {
    return {
        isSendingSMSText: state.text.isSendingSMSText,
        isSendingMMSText: state.text.isSendingMMSText,
        user_phones: state.user.phones.map(({ phone }) => ({
            key: phone,
            value: phone,
            text: PhoneUtil.formatPhoneNumber(phone)
        })),
        isReadingUser: state.user.isReadingUser,
        from_phone: state.message_editor.from_phone,
        textPhones: state.text.phones
    };
};

const ConnectedTextMessageModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(TextMessageModalContainer);
ConnectedTextMessageModal.ID = "TEXT_MESSAGE";

export default ConnectedTextMessageModal;
