import React from 'react';
import { Form, Checkbox, Dropdown, Popup } from 'semantic-ui-react';
import { Table, Button, STATUS_TYPES } from 'dyl-components';
import './ImportActionsAndMappings.scss';

const MOCK_ADDTO = [
    { key: 'None', value: 'None', text: 'None' },
    { key: 'Folders', value: 'Folders', text: 'Labels' },
    { key: 'Campaigns', value: 'Campaigns', text: 'Campaigns' },
];

const campaignTypeOptions = [
    { key: 'dial', value: 'dial', text: 'Dial' },
    { key: 'text', value: 'text', text: 'Text' },
    { key: 'email', value: 'email', text: 'Email' }
];

const MOCK_CAMPAIGNS = [
    { key: 'c1', value: 'c1', text: 'Campaign 1' },
    { key: 'c2', value: 'c2', text: 'Campaign 2' },
    { key: 'c3', value: 'c3', text: 'Campaign 3' },
];

const MOCK_LABELS = [
    { key: 'f1', value: 'f1', text: 'Label 1' },
    { key: 'f2', value: 'f2', text: 'Label 2' },
    { key: 'f3', value: 'f3', text: 'Label 3' },
];

const ImportToFolder = ({ importToFolder, name, onChange }) => (
    <React.Fragment>
        <Form.Group widths='equal'>
            <Form.Field>
                <Form.Group widths='equal'>
                    <Checkbox radio name={name} value='newFolder' checked={importToFolder === 'newFolder'} onChange={onChange} />
                    <Form.Input value='New Label' />
                </Form.Group>
            </Form.Field>
            <Form.Field>
                <Form.Group widths='equal'>
                    <Checkbox radio name={name} value='existingFolder' checked={importToFolder === 'existingFolder'} onChange={onChange} />
                    <Form.Select multiple options={MOCK_LABELS} placeholder='Existing Label' />
                </Form.Group>
            </Form.Field>
        </Form.Group>
        {importToFolder === 'newFolder'
            &&
            <Form.Group widths='equal'>
                <Form.Input placeholder='Label Name' />
                <Form.Select placeholder='Add as Subfolder' />
            </Form.Group>
        }
    </React.Fragment>
);


const ImportToCampaign = ({ importToCampaign, name, onChange }) => (
    <React.Fragment>
        <Form.Group widths='equal'>
            <Form.Field>
                <Form.Group widths='equal'>
                    <Checkbox radio name={name} value='newCampaign' checked={importToCampaign === 'newCampaign'} onChange={onChange} />
                    <Form.Input value='New Campaign' />
                </Form.Group>
            </Form.Field>
            <Form.Field>
                <Form.Group widths='equal'>
                    <Checkbox radio name={name} value='existingCampaign' checked={importToCampaign === 'existingCampaign'} onChange={onChange} />
                    <Form.Select multiple options={MOCK_CAMPAIGNS} placeholder='Existing Campaign' />
                </Form.Group>
            </Form.Field>
        </Form.Group>
        {importToCampaign === 'newCampaign' &&
            <React.Fragment>
                <Form.Group widths='equal'>
                    <Form.Input placeholder='Campaign Name' />
                    <Form.Select placeholder='Category' />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Select options={campaignTypeOptions} placeholder='Type' />
                    <Form.Select placeholder='Assign Script/Template' />
                </Form.Group>
            </React.Fragment>
        }
    </React.Fragment>
);

const ImportActionsAndMapping = ({ addTo, addToSecond, importToFolder, importToCampaign, users, possibleFields, importToSecondFolder, importToSecondCampaign, leadAssignment, onChange }) => (
    <Form>
        <Form.Group className='PlusAddList'>
            <Form.Field>
                <label>Add List As</label>
            </Form.Field>
        </Form.Group>
        <Form.Group className='PlusAddList'>
            <Dropdown
                value={addTo}
                selection
                text={addTo}
                className='PlusAddList__dropdown'>
                <Dropdown.Menu>
                    <Popup
                        style={{ position: 'relative', left: '16em', top: '-2em' }}
                        position='left center'
                        trigger={<Dropdown.Item text='None' value="None" name='addTo' onClick={onChange} />}
                        content={
                            "This selection will simply upload the contacts without grouping / organizing them together."
                        } />
                    <Dropdown.Item text='Label' value="Folders" name='addTo' onClick={onChange} />
                    <Dropdown.Item text='Campaigns' value="Campaigns" name='addTo' onClick={onChange} />
                </Dropdown.Menu>
            </Dropdown>
        </Form.Group>

        {addTo === 'Folders' && <ImportToFolder importToFolder={importToFolder} name={'importToFolder'} onChange={onChange} />}
        {addTo === 'Campaigns' && <ImportToCampaign importToCampaign={importToCampaign} name={'importToCampaign'} onChange={onChange} />}

        <Form.Group className='PlusAddList'>
            <Button.Group color='blue'>
                <Dropdown
                    text='+Add List As'
                    button
                    className='icon PlusAddList__dropdownbutton'
                >
                    <Dropdown.Menu>
                        {MOCK_ADDTO.map(item => (
                            <Dropdown.Item onClick={onChange} name='addToSecond' value={item.value}> {item.text}</Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </Button.Group>

        </Form.Group>

        {addToSecond === 'Folders' && <ImportToFolder importToFolder={importToSecondFolder} name={'importToSecondFolder'} onChange={onChange} />}
        {addToSecond === 'Campaigns' && <ImportToCampaign importToCampaign={importToSecondCampaign} name={'importToSecondCampaign'} onChange={onChange} />}

        <Form.Group>
            <Form.Select
                label='Duplicates'
                defaultValue={'skip'}
                options={[
                    { key: 'skip', value: 'skip', text: 'Skip Duplicates' },
                    { key: 'merge', value: 'merge', text: 'Merge Duplicates' },
                    { key: 'allow', value: 'allow', text: 'Allow Duplicates' }
                ]}
            />
        </Form.Group>
        <Form.Field
            label={'Lead Assignment'}
            control={() => (
                <Form.Group>
                    <Form.Radio checked={leadAssignment === 'unassigned'} name='leadAssignment' value='unassigned' onChange={onChange} label='Unassigned' />
                    <Form.Radio checked={leadAssignment === 'assignFromField'} name='leadAssignment' value='assignFromField' onChange={onChange} label='Assign from Field' />
                    <Form.Radio checked={leadAssignment === 'autoAssign'} name='leadAssignment' value='autoAssign' onChange={onChange} label='Auto Assign' />

                    {leadAssignment === 'autoAssign' &&
                        (<Form.Select
                            options={[
                                { key: 'auto', value: 'auto', text: 'Auto-assign' },
                                ...users.map(user => ({ key: user.id, value: user.id, text: user.name }))
                            ]}
                            defaultValue={['auto']}
                            search
                            multiple
                        />)
                    }

                </Form.Group>
            )}
        />
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Remap Field</Table.HeaderCell>
                    <Table.HeaderCell>Data</Table.HeaderCell>
                    <Table.HeaderCell>Map to</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>insert field</Table.Cell>
                    <Table.Cell>insert data</Table.Cell>
                    <Table.Cell>
                        <Form.Dropdown
                            options={possibleFields}
                        />
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>

        <div className="ImportActionsAndMappingDiv">
            <Form.Button as={Button} status={STATUS_TYPES.PRIMARY} floated='right'>
                Import
            </Form.Button>
            <Form.Button as={Button} status={STATUS_TYPES.ERROR} floated='right'>
                Cancel
            </Form.Button>
        </div>
    </Form>
);

export default ImportActionsAndMapping;
