import { generateDeleteActionCreator, generateReadOneActionCreator, generateUpdateActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import routes from 'actions/routes';

const deleteTask = generateDeleteActionCreator(routes.API_TASK, ACTION_NAMES.TASK);
const readTask = generateReadOneActionCreator(routes.API_TASK, ACTION_NAMES.TASK);
const updateTask = generateUpdateActionCreator(routes.API_TASK, ACTION_NAMES.TASK);
const completeTask = generateUpdateActionCreator(routes.API_TASK, ACTION_NAMES.TASK_COMPLETION, () => { }, () => { }, 'complete');

const updateTaskLabel = generateUpdateActionCreator(routes.API_TASK_LABEL, ACTION_NAMES.TASK_LABEL);
const deleteTaskLabel = generateDeleteActionCreator(routes.API_TASK_LABEL, ACTION_NAMES.TASK_LABEL);
const readTaskTypeLabels = generateReadOneActionCreator(routes.API_TASK_TYPE_LABEL, ACTION_NAMES.TASK_LABEL);
const checkTaskLabelDuplicate = generateReadOneActionCreator(routes.API_TASK_LABEL, ACTION_NAMES.CHECK_TASK_LABEL_DUPLICATE, 'check_duplicate');

export default {
    deleteTask,
    readTask,
    updateTask,

    updateTaskLabel,
    deleteTaskLabel,
    readTaskTypeLabels,
    checkTaskLabelDuplicate,
    completeTask
};
