import React from 'react';
import { Table } from 'dyl-components';
import { Header } from 'semantic-ui-react';
import { StringUtils } from 'utils';

const CustomerPipelinesSubrowColumns = ({ activityHeader, activitySubheader, messageNotes, user }) => (
    <>
        <Table.Cell>
            <Header as='h4'>
                {activityHeader}
                <Header.Subheader>
                    <span
                        className={`HistorySection__ActivitySubheader HistorySection__ActivitySubheader--${activitySubheader.toLowerCase()}`}
                    >
                        {StringUtils.toSentenceCase((activitySubheader || '').split("_").join(" "))}
                    </span>
                </Header.Subheader>
                
            </Header>
        </Table.Cell>
        <Table.Cell>
            {messageNotes}
        </Table.Cell>
        <Table.Cell>
            {user}
        </Table.Cell>
    </>
);

export default CustomerPipelinesSubrowColumns;
