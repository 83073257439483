import { ClippedContent, LinkedAccount, Person, SearchableOptions, VALIDATION_PATTERNS } from 'dyl-components';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, Icon, Segment, Form, Button } from 'semantic-ui-react';
import { PhoneUtil } from 'utils';

import './AccountOptions.scss';
import accountOptionsActions from 'actions/account_options';

const mapDispatchToProps = dispatch => ({
    onReadAccounts: (queryParameters) => {
        return dispatch(accountOptionsActions.read(queryParameters));
    }
});

const LIMIT = 15;

const AccountSearchResults = ({ loading, account_options, onChange, selectedId }) => (
    <Segment loading={loading}>
        <Grid>
            {account_options.map(data => {
                const selected = selectedId === data.id;
                return (
                    <Grid.Row columns={'equal'} verticalAlign='middle'>
                        <Grid.Column>
                            <LinkedAccount
                                account={data.name}
                                maxWidth={'9em'}
                                subtitle={data.id && `ID: ${data.id}`}
                                popup
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <ClippedContent>{data.email}</ClippedContent>
                        </Grid.Column>
                        <Grid.Column>
                            <ClippedContent>{PhoneUtil.formatPhoneNumber(data.phone)}</ClippedContent>
                        </Grid.Column>
                        <Grid.Column>
                            {data.master_account?.name && (
                                <ClippedContent>
                                    <Icon className={`${data.account_type === 'business' ? 'fa-solid fa-building' : 'fa-solid fa-house-chimney-blank'}`} />{data.master_account?.name}
                                </ClippedContent>
                            )}
                        </Grid.Column>
                        <Grid.Column width={1}>
                            <Link onClick={e => { e.stopPropagation(); }} target={"_blank"} to={`/account/${data.id}`}>{<Icon className='fas fa-external-link' />}</Link>
                        </Grid.Column>
                        <Grid.Column>
                            <Button fluid color='primary' onClick={() => { onChange(null, { value: data }); }} disabled={selected}>
                                {selected ? 'Selected' : 'Select'}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                );
            })}
        </Grid>
    </Segment>
)

const AccountOptions = ({
    onChange,
    onReadAccounts,
    value
}) => {
    const [state, setState] = useState({
        account_options: [],
        count: 0,
        page: 1,
        search: '',
        open: false
    });

    const [loading, setLoading] = useState(false);

    const searchFunction = async () => {
        try {
            setLoading(true);
            const { data, count } = await onReadAccounts({ page: 1, search: state.search, limit: LIMIT });
            setState({ ...state, account_options: data, count, open: true, page: 1, search: state.search });
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }

    const cancelFunction = async () => {
        setState({ ...state, account_options: [], count: 0, open: false, page: 1, search: '' });
    }

    const onChangeSearch = (_, { value }) => {
        setState({ ...state, search: value, open: false });
    }

    if (value) {
        const { name, id, isAlreadyLinked } = value;
        return (
            <Form.Dropdown
                className={`AccountOptions__dropdown${isAlreadyLinked ? '--unclearable' : ''} `}
                text={(
                    <Person
                        username={name}
                        email={`ID: ${id}`}
                    />
                )}
                value={id}
                clearable={!isAlreadyLinked}
                open={false}
                selection
                width={3}
                onChange={(e) => {
                    onChange(e, { value: null });
                    cancelFunction();
                }}
            />
        )
    }

    return (
        <SearchableOptions
            loading={loading}
            search={state.search}
            searchFunction={searchFunction}
            cancelSearchFunction={cancelFunction}
            searchPlaceholder={'Search for an account'}
            onChangeSearch={onChangeSearch}
            isSearchResultsVisible={state.open}
            renderSearchResults={<AccountSearchResults
                account_options={state.account_options}
                loading={loading}
                onChange={onChange}
                search={state.search}
                selectedId={value?.id}
            />}
            isPaginationVisible={state.account_options.length > 0}
            paginationProps={{
                boundaryRange: 0,
                activePage: state.page,
                ellipsisItem: null,
                siblingRange: 2,
                totalPages: Math.ceil(state.count / LIMIT),
                onPageChange: async (_, { activePage }) => {
                    setLoading(true);
                    setState({ ...state, open: true, page: activePage });
                    const { data, count } = await onReadAccounts({ page: activePage, search: state.search, limit: LIMIT });
                    setState({ ...state, page: activePage, account_options: data, count, open: true });
                    setLoading(false);
                }
            }}
            validationPattern={VALIDATION_PATTERNS.alphanumeric_w_hyphen_and_or_ampersand_search}
        />
    )
}

export default connect(null, mapDispatchToProps)(AccountOptions);
