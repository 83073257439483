import {
    EmailLibrary,
    TaskTypes,
    ScriptManagement,
    TextMessageLibrary
} from "pages";

import React from 'react';
import { Route } from 'react-router-dom';

export const LIBRARY_ROUTES = [
    <Route index path='text-templates' element={<TextMessageLibrary />} />,
    <Route path='email-templates' element={<EmailLibrary />} />,
    <Route path='dialer-scripts' element={<ScriptManagement />} />,
    <Route path='task-types' element={<TaskTypes />} />
];
