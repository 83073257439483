import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { GroupedTimeLine, DateTimeUtils, Icon } from 'dyl-components';

import HistoryTableHeader from './HistoryTableHeader';
import HistoryColumns from './HistoryColumns';

import {
    getData,
    onToggleTimeUnit,
    useRangeCalendar
} from "shared/CustomDateRange/customDateRange";

import campaignHistoryActions from 'actions/campaign_history';
import { useEffect } from 'react';

const HistorySection = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const sortDirection = params.get("order") === "asc";

    const createdOrderFilter = () => {
        const query = new URLSearchParams(params);
        if (sortDirection) {
            query.delete("order");
        } else {
            query.set("order", 'asc');
        }
        const query_string = query.toString();
        navigate(`/campaigns/${id}/history${query_string ? `?${query_string}` : ""}`);
    };

    const { history, history_count, isReadingHistory } = useSelector(state => ({
        history: DateTimeUtils.groupDates((state.campaign_history.history || [])
            .map(item => ({
                ts: item.created,
                icon: <Icon className='clipboard' />,
                columns: <HistoryColumns
                    activity={item}
                />
            })),
            sortDirection
        ),
        history_count: state.campaign_history.history_count,
        isReadingHistory: state.campaign_history.isReadingHistory
    }));

    useEffect(() => {
        const { start, end, order, page } = Object.fromEntries(params);
        dispatch(campaignHistoryActions.readCampaignHistory(id, {
            ...start ? { start_time: start } : {},
            ...end ? { end_time: end } : {},
            order, page
        }));
    }, [dispatch, id, params]);

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`/campaigns/${id}/history${query_string ? `?${query_string}` : ''}`);
    }

    return (
        <div className='HistorySection'>
            <GroupedTimeLine
                headers={<HistoryTableHeader />}
                isLoading={isReadingHistory}
                additionalColumnNumber={5}
                groups={history}
                useRangeCalendar={useRangeCalendar(params)}
                onToggleTimeUnit={(timestamp, setTimestamp) =>
                    onToggleTimeUnit(
                        timestamp,
                        setTimestamp,
                        params,
                        navigate,
                        `/campaigns/${id}/history`
                    )
                }
                getTimeData={(setCustomDateRangeOpen, dateRange) => {
                    getData({
                        setCustomDateRangeOpen,
                        dateRange,
                        params,
                        navigate,
                        url: `/campaigns/${id}/history`,
                    })
                }}
                sortDirection={sortDirection}
                onToggleSort={() => {
                    createdOrderFilter();
                }}
                usePagination
                recordsCount={history_count}
                activePage={params.get('page')}
                onPageChange={onPageChange}
            />
        </div>
    )
}

export default HistorySection;
