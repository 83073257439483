import callQueuesActions from 'actions/call_queues';
import { STATUS_TYPES, Notification } from 'dyl-components';
import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { TopPaddedControls } from 'dyl-components';

const AddCallQueueSection = ({ label, ring_strategy, onChange, onAddCallQueue, isCreatingCallQueues, onClose }) => (
    <Form>
        <Form.Group widths='equal'>
            <Form.Input
                label='Name'
                name='label'
                value={label}
                onChange={onChange}
            />
            <Form.Select
                label='Ring Strategy'
                name='ring_strategy'
                value={ring_strategy}
                onChange={onChange}
                options={[
                    { key: 'ringall', value: 'ringall', text: 'Ring All' },
                    { key: 'roundrobin', value: 'roundrobin', text: 'Round Robin' },
                ]}
            />
            <TopPaddedControls>
                {!isCreatingCallQueues && (
                    <Form.Button
                        floated='right'
                        content={'Cancel'}
                        onClick={onClose}
                    />
                )}
                <Form.Button
                    primary
                    floated='right'
                    content={'Add'}
                    onClick={onAddCallQueue}
                    loading={isCreatingCallQueues}
                />
            </TopPaddedControls>
        </Form.Group>
    </Form>
)

class AddCallQueueSectionContainer extends React.Component {
    state = {
        label: '',
        ring_strategy: 'roundrobin',
        announce_mode: 'none',
        exit_key: "1"
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onAddCallQueue = () => {
        const { label, ring_strategy, announce_mode, exit_key } = this.state;
        this.props.onAdd(this.props.domain, [{
            label,
            ring_strategy,
            announce_mode,
            exit_key
        }]).then(() => {
            Notification.alert('Successfully added call queue!', STATUS_TYPES.SUCCESS, true);
            this.props.onReadCallQueues(this.props.domain);
            this.onClose();
        }).catch(() => {
            Notification.alert('Failed to add call queue', STATUS_TYPES.ERROR, true);
        })
    }

    onClose = () => {
        this.props.onClose();
        this.setState({ label: '', ring_strategy: 'roundrobin' });
    }

    render() {
        return (
            <AddCallQueueSection
                isCreatingCallQueues={this.props.isCreatingCallQueues}
                label={this.state.label}
                ring_strategy={this.state.ring_strategy}
                onClose={this.onClose}
                onAddCallQueue={this.onAddCallQueue}
                onChange={this.onChange}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    domain: state.pbx_config.domain,
    isCreatingCallQueues: state.call_queues.isCreatingCallQueues
})

const mapDispatchToProps = dispatch => ({
    onAdd: (domain, payload) => {
        return dispatch(callQueuesActions.createCallQueues(payload, null, domain));
    },
    onReadCallQueues: (domain) => {
        dispatch(callQueuesActions.readCallQueues(domain));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(AddCallQueueSectionContainer);
