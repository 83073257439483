import React from 'react';
import { TableWithHeader } from 'dyl-components';
import RingGroupMembersTable from './RingGroupMembersTable';
import RingGroupMembersHeader from './RingGroupMembersHeader';
import { Grid, Segment, Loader, Dimmer } from 'semantic-ui-react';
import AddMemberSectionContainer from './AddMemberSection';
import LeftPaddedContent from 'shared/LeftPaddedContent';
import ringGroupsActions from 'actions/ring_groups';
import { connect } from 'react-redux';
import callQueuesActions from 'actions/call_queues';
import { RecordUtils } from 'utils';

const RingGroupMembers = ({
    members,
    isAddMemberSectionOpen,
    onOpenAddMemberSection,
    onCloseAddMemberSection,
    isReadingMembers,
    ring_group,
    isRingGroup
}) => (
    <TableWithHeader
        header={
            <RingGroupMembersHeader
                onOpenAddMemberSection={onOpenAddMemberSection}
            />
        }
        table={
            <Grid style={{ margin: 0 }}>
                {isAddMemberSectionOpen && (
                    <Grid.Row>
                        <Grid.Column>
                            <LeftPaddedContent>
                                <AddMemberSectionContainer
                                    onClose={onCloseAddMemberSection}
                                    members={members}
                                    ring_group={ring_group}
                                    isRingGroup={isRingGroup}
                                />
                            </LeftPaddedContent>
                        </Grid.Column>
                    </Grid.Row>
                )}
                <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column style={{ padding: 0 }}>
                        <Dimmer active={isReadingMembers}>
                            <Loader active={isReadingMembers} />
                        </Dimmer>
                        {members.length <= 0 && !isReadingMembers && (
                            <Segment textAlign='center'>
                                <em>There are no members</em>
                            </Segment>
                        )}
                        {members.length > 0 && (
                            <RingGroupMembersTable
                                members={members}
                                ring_group={ring_group}
                                isRingGroup={isRingGroup}
                            />
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        }
    />
);

class RingGroupMembersContainer extends React.Component {
    state = {
        isAddMemberSectionOpen: false
    }

    componentDidMount() {
        this.props.onReadGroupMembers(this.props.ring_group);
    }

    onOpenAddMemberSection = () => {
        this.setState({ isAddMemberSectionOpen: true });
    }

    onCloseAddMemberSection = () => {
        this.setState({ isAddMemberSectionOpen: false });
    }

    render() {
        return (
            <RingGroupMembers
                members={this.props.members.sort((a, b) => a.member > b.member ? 1 : -1)}
                isAddMemberSectionOpen={this.state.isAddMemberSectionOpen}
                onOpenAddMemberSection={this.onOpenAddMemberSection}
                onCloseAddMemberSection={this.onCloseAddMemberSection}
                isReadingMembers={this.props.isReadingMembers}
                ring_group={this.props.ring_group}
                isRingGroup={this.props.isRingGroup}
            />
        )
    }
}

const mapStateToProps = (state, { isRingGroup }) => ({
    members: state[isRingGroup ? 'ring_group' : 'call_queue'].members.map(({ user_id, ...memberProps }) => {
        const users = RecordUtils.generateUserOptions(state.users.users);
        const user = (users.find(({ value }) => value === user_id) || { text: '(No assigned user)' }).text;
        return {
            ...memberProps,
            user
        }
    }) || [],
    isReadingMembers: state[isRingGroup ? 'ring_group' : 'call_queue'].isReadingMembers
})

const mapDispatchToProps = (dispatch, { isRingGroup }) => ({
    onReadGroupMembers: (group_id) => {
        if (isRingGroup) {
            return dispatch(ringGroupsActions.readRingGroupMembers(group_id));
        }
        return dispatch(callQueuesActions.readCallQueueMembers(group_id));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(RingGroupMembersContainer);
