
import initialState from './state';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';

function templateReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.TEXT_TEMPLATE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingTemplate: true }
        case getNamedAction(ACTION_NAMES.TEXT_TEMPLATE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingTemplate: false, template: { ...action.data, attachments: action.data.attachments.data } }
        case getNamedAction(ACTION_NAMES.TEXT_TEMPLATE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingTemplate: false, template: {} }

        case getNamedAction(ACTION_NAMES.TEXT_TEMPLATE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingTemplate: true }
        case getNamedAction(ACTION_NAMES.TEXT_TEMPLATE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingTemplate: false }
        case getNamedAction(ACTION_NAMES.TEXT_TEMPLATES, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingTemplate: false }

        case getNamedAction(ACTION_NAMES.TEXT_TEMPLATE, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingTemplate: true }
        case getNamedAction(ACTION_NAMES.TEXT_TEMPLATE, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingTemplate: false }
        case getNamedAction(ACTION_NAMES.TEXT_TEMPLATE, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingTemplate: false }

        default:
            return state;
    }
}

export default templateReducer;
