const initialState = {
    count: 0,
    plans: [],
    queryParameters: {
        page: 1,
        offset: 0
    },

    isReading: false,
    isCreating: false,
    planBeingUpdated: false,
    planBeingDeleted: false
}

export default initialState;
