import React from 'react';
import { Grid, Icon, List, ListItem, Dropdown, Select, Form } from 'semantic-ui-react';
import './index.scss';
import { connect } from 'react-redux';
import UserSearchField from 'shared/forms/UserSearchField';
import { StringUtils } from 'utils';
import { DateTimeUtils } from 'dyl-components';
import { STATES } from 'shared/constants/STATES';

const getDefaultUserType = (roles) => {
    if (roles) {
        const userRole = roles.find((role) => role.text === "User");
        if (userRole) {
            return userRole.key;
        }
    }
    return null;
}

const AddUserForm = ({
    onInputChange,
    teams,
    isReadingTeams,
    roles,
    isReadingRoles,
    timezones,
    emailErrorMessage,
    isNameValid,
    isFindingUserByEmail,
    onCheckEmailValidity,
    isEmailValid,
    isPhoneNumberValid,
    isWorkNumberValid,
    isExtensionValid,
    errorMessages,
}) => {

    //TODO:  there is an issue with using YUP on optional fields, the revalidation method of react-hook-forms doesn't work properly with the yup strings. Should investigate workarounds and use YUP when this form gets refactored from a modal to a page.
    return (
        <React.Fragment>
            <Grid columns={2}>
                <Grid.Column>
                    <List relaxed size='small' as={Form}>
                        <ListItem
                            icon={
                                <Icon name='user' color='black' />
                            }
                            header={<b>First Name <Icon size='tiny' name='asterisk' color='red' /></b>}
                            description={
                                <Form.Input onChange={onInputChange} name='first_name' onBlur={() => { isNameValid('first_name', true, 2, 50) }} error={errorMessages.first_name && errorMessages.first_name} fluid required />
                            }
                        />
                        <ListItem
                            icon={
                                <Icon />
                            }
                            header={<b>Last Name <Icon size='tiny' name='asterisk' color='red' /></b>}
                            description={
                                <Form.Input onChange={onInputChange} name='last_name' onBlur={() => { isNameValid('last_name', true, 2, 50) }} error={errorMessages.last_name && errorMessages.last_name} fluid required />
                            }
                        />
                        <ListItem
                            icon={
                                <Icon name='sign in' color='black' />
                            }
                            header={<b>Email <Icon size='tiny' name='asterisk' color='red' /></b>}
                            description={
                                <Form.Input
                                    loading={isFindingUserByEmail} required onChange={onInputChange} fluid onBlur={onCheckEmailValidity} name='email'
                                    error={!isEmailValid && emailErrorMessage}
                                />
                            }
                        />
                        <ListItem
                            icon={
                                <Icon name='map marker' color='black' />
                            }
                            header='Street Address'
                            description={
                                <Form.Input onChange={onInputChange} name='street' onBlur={(e) => { isNameValid('street', false, 2, 100) }} error={errorMessages.street && errorMessages.street} placeholder='Street address or P.O. box' fluid required />

                            }
                        />
                        <ListItem
                            icon={
                                <Icon />
                            }
                            header='Apt. suite, unit building, floor, etc.'
                            description={
                                <Form.Input required onChange={onInputChange} onBlur={(e) => { isNameValid('suite', false, 2, 12) }} error={errorMessages.suite && errorMessages.suite} name='suite' fluid />
                            }
                        />
                        <ListItem
                            icon={
                                <Icon />
                            }
                            header='City'
                            description={
                                <Form.Input onChange={onInputChange} name='city' onBlur={() => { isNameValid('city', false, 2, 60) }} error={errorMessages.city && errorMessages.city} placeholder='Street address or P.O. box' fluid required />
                            }
                        />
                        <ListItem
                            icon={
                                <Icon />
                            }
                            header={<b>State <Icon size='tiny' name='asterisk' color='red' /></b>}
                            description={
                                <Grid>
                                    <Grid.Column width='8'>
                                        <Dropdown selection search fluid
                                            onChange={onInputChange}
                                            name='state'
                                            required
                                            options={STATES.map(state => ({ ...state, value: state.key }))}
                                            placeholder='Select a state'
                                        />
                                    </Grid.Column>
                                    <Grid.Column width='8' style={{ paddingTop: 0 }}>
                                        <ListItem
                                            header='Zip Code'
                                            description={

                                                <Form.Input onChange={onInputChange} name='zipcode' onBlur={() => { isNameValid('zipcode', false, 2, 9) }} error={errorMessages.zipcode && errorMessages.zipcode} placeholder='Postal Code' fluid required />
                                            } />
                                    </Grid.Column>
                                </Grid>
                            }
                        />
                    </List>
                </Grid.Column>
                <Grid.Column>
                    <List relaxed size='small' as={Form}>
                        <ListItem
                            icon={
                                <Icon name='text telephone' color='black' />
                            }
                            header='Work Number'
                            description={
                                <Grid>
                                    <Grid.Column width='10'>
                                        <Form.Input
                                            required onChange={onInputChange} name='work_number' fluid
                                            error={!isWorkNumberValid && 'Valid phone format required.'} placeholder="xxxxxxxxxx" />
                                    </Grid.Column>

                                    <Grid.Column width='6'>
                                        <span class="tempHeader">Ext.</span>
                                        <Form.Input onChange={onInputChange} name='extension' fluid
                                            error={!isExtensionValid && 'This field should contain only numbers ( 11 max)'} />
                                    </Grid.Column>
                                </Grid>
                            }
                        />
                        <ListItem
                            icon={
                                <Icon name='mobile alternate' color='black' />
                            }
                            header='Cell Number'
                            description={
                                <Form.Input
                                    required onChange={onInputChange} fluid name='cell_number'
                                    error={!isPhoneNumberValid && 'Valid phone format required.'} placeholder="xxxxxxxxxx"
                                />
                            }
                        />
                        <ListItem
                            icon={
                                <Icon name='clock outline' color='black' />
                            }
                            header={<b>Timezone <Icon size='tiny' name='asterisk' color='red' /></b>}
                            description={
                                <Select search defaultValue={'America/New_York'} options={timezones} required onChange={onInputChange} name='timezone' fluid placeholder='Select Time Zone' />
                            }
                        />
                        <ListItem
                            icon={
                                <Icon name='address book' color='black' />
                            }
                            header={<b>Job Title</b>}
                            description={
                                <Form.Input onChange={onInputChange} name='job_title' fluid 
                                error={errorMessages.job_title && errorMessages.job_title} />
                            }
                        />
                        <ListItem
                            icon={
                                <Icon name='clipboard' color='black' />
                            }
                            header='Reports to'
                            description={
                                <Form.Field
                                    control={UserSearchField}
                                    excluded_users={[]}
                                    onChange={onInputChange}
                                    placeholder="Select User"
                                    display_selected_user
                                />
                            }
                        />
                        <ListItem
                            icon={
                                <Icon name='fas fa-users' color='black' />
                            }
                            header='Team(s)'
                            description={
                                <Dropdown multiple selection fluid
                                    onChange={onInputChange}
                                    name='teams'
                                    options={teams}
                                    loading={isReadingTeams}
                                    placeholder='Select Team(s)'
                                />
                            }
                        />
                        <ListItem
                            icon={
                                <Icon name='id card' color='black' />
                            }
                            header={<b>Profile Type <Icon size='tiny' name='asterisk' color='red' /></b>}
                            description={
                                <Select
                                    onChange={onInputChange}
                                    name='profile_type'
                                    options={roles}
                                    defaultValue={getDefaultUserType(roles)}
                                    fluid
                                    selection
                                    loading={isReadingRoles}
                                    required
                                    placeholder='Select Profile Type'
                                />
                            } />
                    </List>
                </Grid.Column>
            </Grid>
        </React.Fragment>
    )
}


const mapStateToProps = state => ({
    timezones: DateTimeUtils.generateTimezoneOptions(),
    teams: state.teams.teams.filter(({ deleted }) => !deleted).map(({ id, name }) => ({
        key: id,
        value: id,
        text: name
    })),
    isReadingTeams: state.teams.isReadingTeams,
    roles: state.roles.roles.filter(({ owner }) => { return !owner }).map(({ id, name }) => ({
        key: id,
        value: id,
        text: StringUtils.capitalize(name)
    })),
    isReadingRoles: state.roles.isReadingRoles,
    integrations: (state.oauth_integrations.integrations || []).map(({ id, email }) => ({
        key: id,
        value: id,
        text: email
    })),
    isReadingProviders: state.oauth_integrations.isReadingProviders,
    isFindingUserByEmail: state.user.isFindingUserByEmail
});

export default connect(mapStateToProps)(AddUserForm);
