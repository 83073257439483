import { useFieldArray } from "react-hook-form";
import { Button, Divider, Grid, Icon, Popup, Ref } from "semantic-ui-react";

import { useState } from "react";
import Action from "./Action";
import { CloseableContent, RuleContainer } from "dyl-components";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ArrayUtils } from "utils";

const Actions = ({ control }) => {
    const { fields: actions, append, replace, update } = useFieldArray({
        control,
        name: 'actions'
    });

    const [activeAction, setActiveAction] = useState(0);

    const addAction = (type) => {
        append({
            type,
            deliver: '',
            data: (() => {
                switch (type) {
                    case 'call': return {};
                    case 'text': return {
                        message: '',
                        attachments: [],
                        template_category: null,
                        template: null
                    }
                    case 'email': return {
                        subject: '',
                        body: '<p></p>',
                        attachments: [],
                        template_category: null,
                        template: null
                    }
                    default: throw new Error(`Unsupported type: ${type}`);
                }
            })()
        })
        setActiveAction(actions.length);
    }

    const isSameDay = (action, index, modifiedActions = actions) => {
        if (action.type === "call" || index === 0) {
            return false;
        }
        let count = 0;
        for (let forIndex = index - 1; forIndex >= 0; forIndex--) {
            if (modifiedActions[forIndex].type === action.type) count++;
            if (modifiedActions[forIndex].deliver === "days" || modifiedActions[forIndex].deliver === "weeks" || modifiedActions[forIndex].deliver === "months") break;
        }
        return count >= 2;
    }

    const isFollowedByMessagingActionOfSameTypeAndDay = (action, index, modifiedActions = actions) => {
        if (action.type === "call") {
            return false;
        }
        const previousAction = index > 0 ? modifiedActions[index - 1] : null;
        return previousAction && previousAction.type === action.type;
    }

    const isFollowedByTwoMessagingActionsOfSameTypeAndDay = (action, index, modifiedActions = actions) => {
        if (action.type === "call") {
            return false;
        }
        if (index <= 1) {
            return false;
        }
        const actionsToCheck = [...modifiedActions.slice(index - 2, index), action];
        const areAllTexts = actionsToCheck.every(action => action.type === "text");
        const areAllEmails = actionsToCheck.every(action => action.type === "email");
        const previousActionDeliveryTime = modifiedActions[index - 1].deliver;
        const previousActionHappensADayOrMore = previousActionDeliveryTime === "days" || previousActionDeliveryTime === "weeks" || previousActionDeliveryTime === "months";
        return !previousActionHappensADayOrMore && (areAllTexts || areAllEmails);
    }

    const clearDeliveryOfInvalidMessagingActions = (actions) => {
        replace(actions.map((action, index) => {
            const deliver = action.deliver;
            if (index !== 0 && deliver === "instant") {
                return { ...action, deliver: "" };
            }
            if ((deliver === "hours" || deliver === "minutes") && isSameDay(action, index, actions) ) {
                return { ...action, deliver: "" };
            }
            if (deliver === "minutes" && (isFollowedByMessagingActionOfSameTypeAndDay(action, index, actions))) {
                return { ...action, deliver: "" };
            }
            if ((deliver === "hours" || deliver === "minutes") && isFollowedByTwoMessagingActionsOfSameTypeAndDay(action, index, actions) ) {
                return { ...action, deliver: "" };
            }
            return action;
        }))
    }

    const removeAction = (index) => {
        const actionsCopy = actions.slice(0);
        actionsCopy.splice(index, 1);
        clearDeliveryOfInvalidMessagingActions(actionsCopy);
        if (index === activeAction) {
            setActiveAction(0);
        }
    }

    const reorder = async (result) => {
        if (!result.destination) {
            return;
        }
        const { source, destination } = result;
        const reorderedActions = ArrayUtils.reorder(actions, source.index, destination.index);
        clearDeliveryOfInvalidMessagingActions(reorderedActions);
        setActiveAction(result.destination.index);
    }
    
    const updateAction = async (updatedAction, index) => {
        const actionsCopy = actions.slice(0);
        actionsCopy[index] = updatedAction;
        update(index, updatedAction);
        // the replace function is not used here since it will cause "jumping" to the next action
        actionsCopy.forEach((action, indexOfAction) => {
            if (index === indexOfAction) {
                return;
            }
            const deliver = action.deliver;
            if (indexOfAction !== 0 && deliver === "instant") {
                return update(indexOfAction, { ...action, deliver: "" });
            }
            if ((deliver === "hours" || deliver === "minutes") && isSameDay(action, indexOfAction, actions) ) {
                return update(indexOfAction, { ...action, deliver: "" });
            }
            if (deliver === "minutes" && (isFollowedByMessagingActionOfSameTypeAndDay(action, indexOfAction, actions))) {
                return update(indexOfAction, { ...action, deliver: "" });
            }
            if ((deliver === "hours" || deliver === "minutes") && isFollowedByTwoMessagingActionsOfSameTypeAndDay(action, indexOfAction, actions) ) {
                return update(indexOfAction, { ...action, deliver: "" });
            }  
        })
    }
    
    return (
        <>
            <DragDropContext onBeforeDragStart={(start) => { setActiveAction(start.source.index) }} onDragEnd={reorder}>
                <Droppable droppableId="actions" type="ACTIONS">
                    {(provided, droppableSnapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {actions.map((action, index) => {
                                const deliveryOptions = [
                                    {
                                        key: 'instant', value: 'instant', text: 'Instant'
                                    },
                                    {
                                        key: 'minutes', value: 'minutes', text: 'Minutes', disabled: isFollowedByMessagingActionOfSameTypeAndDay(action, index) || isSameDay(action, index)
                                    },
                                    {
                                        key: 'hours', value: 'hours', text: 'Hours', disabled: isFollowedByTwoMessagingActionsOfSameTypeAndDay(action, index) || isSameDay(action, index)
                                    },
                                    {
                                        key: 'days', value: 'days', text: 'Days'
                                    },
                                    {
                                        key: 'weeks', value: 'weeks', text: 'Weeks'
                                    },
                                    {
                                        key: 'months', value: 'months', text: 'Months'
                                    },
                                ].slice(index === 0 ? 0 : 1);
                                
                                const toRender = (
                                    <Action
                                        action={action}
                                        index={index}
                                        control={control}
                                        onUpdateAction={(updatedAction) => { updateAction(updatedAction, index) }}
                                        onFocus={() => { setActiveAction(index) }}
                                        deliveryOptions={deliveryOptions}
                                    />
                                )
                                const isActive = activeAction === index;
                                return (
                                    <Draggable disableInteractiveElementBlocking={false} draggableId={action.id} key={action.id} index={index} isDragDisabled={actions.length === 1}>
                                        {(provided) => (
                                            <Ref innerRef={provided.innerRef}>
                                                <Grid {...provided.draggableProps} columns={'equal'}>
                                                    <Grid.Column width={1} className="Action__drag-handle">
                                                        {isActive && <Icon {...provided.dragHandleProps} color="grey" className="fas fa-arrows" />}
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <div {...(!isActive ? { onClick: () => { setActiveAction(index) } } : {})}>
                                                            <RuleContainer
                                                                isActive={isActive}
                                                                content={actions.length > 1 && !droppableSnapshot.isDraggingOver ? (
                                                                    <CloseableContent onClose={() => { removeAction(index) }}>
                                                                        {toRender}
                                                                    </CloseableContent>
                                                                ) : toRender}
                                                            />
                                                        </div>
                                                    </Grid.Column>
                                                </Grid>
                                            </Ref>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Divider horizontal style={{ paddingBottom: '5em' }}>
                <Popup
                    style={{ padding: 0 }}
                    trigger={<Icon size="big" color='primary' className="fas fa-plus-circle" link />}
                    content={(
                        <Button.Group icon basic>
                            <Button onClick={() => { addAction('call') }}>
                                <Icon className="fas fa-phone" />
                            </Button>
                            <Button onClick={() => { addAction('text') }}>
                                <Icon className="fas fa-message" />
                            </Button>
                            <Button onClick={() => { addAction('email') }}>
                                <Icon className="fas fa-envelope" />
                            </Button>
                        </Button.Group>
                    )}
                    position="top center"
                    hoverable
                />
            </Divider>
        </>
    )
}

export default Actions;
