const initialState = {
    leads: [],
    count: 0,
    isReadingLeads: false,
    limit: 25,
    currentPage: 1,
    allMarked: false
}

export default initialState;
