import React from 'react';
import { Segment } from 'semantic-ui-react';

const StatusBox = ({ status = 'Idle' }) => (
    <Segment textAlign='center'>
        <h3>Status</h3>
        {status}
    </Segment>
)

export default StatusBox;
