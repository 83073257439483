import React, { useState } from 'react';

import { ItemCard, Timeline, CollapsibleContent, DateTimeUtils } from 'dyl-components';
import { Menu, Form, Icon, Checkbox } from 'semantic-ui-react';

import { StringUtils } from 'utils';

import './index.scss';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

const SequenceTimeline = ({ control, sequenceOptions, onSave, onRemove, onComplete, onCancel, selectedSequenceTasks, selectedSequenceId, selectedSequenceName, clearSequence, canApplySequence, onSelectSequenceTaskForRescheduling }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [activeTab, setActiveTab] = useState('Upcoming');

    const { isAddingContactToSequence } = useSelector(state => ({
        isAddingContactToSequence: state.sequences.isAddingContactToSequence
    }))

    const getIconClassName = (action) => {
        const actionText = action.split(" ")[0];
        switch (actionText) {
            case "Call":
                return "fa-solid fa-phone-flip"
            case "Email":
                return "fa-solid fa-envelope"
            case "Text":
                return "fa-solid fa-message"
            default:
                return "";
        }
    }

    const handleCheckboxChange = (id) => {
        onComplete(id);
    }

    const handleOnCancel = (id) => {
        onCancel(id);
    }

    const mapTasks = (task) => {
        const isComplete = task.status === "completed";
        return ({
            date: (
                <div className='TimelineItem__date'>
                    <span className='TimelineItem__day'>
                        {DateTimeUtils.formatEpoch(task.ts, "ddd").toUpperCase()}
                    </span>
                    <span className='TimelineItem__day-of-month'>
                        {DateTimeUtils.formatEpoch(task.ts, "M[/]D")}
                    </span>
                </div>
            ),
            time: (
                <span className='TimelineItem__time'>
                    {DateTimeUtils.formatEpoch(task.ts)}
                </span>
            ),
            itemComponent: (
                <ItemCard
                    title={<div>
                        <span className='EventItem__name'>
                            {StringUtils.capitalize(selectedSequenceName)}
                        </span>
                    </div>}
                    description={<div className='EventItem__note'>
                        <CollapsibleContent
                            content={task.action || ''} />
                    </div>}
                    actions={<div style={{ display: "flex", gap: 10 }}>
                        <Checkbox className="SequenceTime__taskCheckbox" style={{ marginRight: 6 }} checked={isComplete} disabled={isComplete} onChange={() => handleCheckboxChange(task.id)} />
                        <Icon size='large' link className={`${getIconClassName(task.action)}`} color='primary' style={{ marginTop: 2 }} />
                        <Icon size='large' link className='fa-solid fa-calendar' onClick={() => { onSelectSequenceTaskForRescheduling(task.id); } } color='primary' style={{ marginTop: 2 }} />
                        <Icon size='big' link className='fa-solid fa-xmark redIcon' disabled={isComplete} onClick={() => handleOnCancel(task.id)} />
                    </div>}
                    size='mini' />
            ),
            markerColor: '#e5e5e5'
        });
    }

    const handleTabClick = (e, { name }) => {
        setActiveTab(name);
    }

    const handleCancel = () => {
        clearSequence();
        setIsEditing(false)
    }

    const numberOfCompletedTasks = selectedSequenceTasks?.filter((task) => task.status === 'completed')?.length || 0;

    return (
        <div>
            <Form size='small'>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                    <Controller
                        name='sequence'
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                            <Form.Select
                                placeholder="Select Sequence Name"
                                label="Sequence Name"
                                name={name}
                                value={value}
                                options={sequenceOptions}
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } });
                                }}
                                required
                                search
                                width={13}
                                disabled={selectedSequenceId || sequenceOptions.length === 0 || !isEditing || isAddingContactToSequence}
                            />
                        )}
                    />
                    {sequenceOptions.length > 0 && !isEditing && !selectedSequenceId && <Icon name='pencil' color='primary' link onClick={() => setIsEditing(true)} style={{ marginLeft: 10, marginTop: 10 }} />}
                    {!isAddingContactToSequence && isEditing && !selectedSequenceId && <>
                        <Icon size='large' link className='fa-solid fa-xmark redIcon' style={{ marginLeft: 10, marginTop: 10 }} onClick={handleCancel} />
                        <Icon disabled={!canApplySequence} size='large' color='primary' link className='fa-solid fa-check' style={{ marginTop: 10 }} onClick={onSave} />
                    </>}
                    {isAddingContactToSequence && <Icon name='circle notched' loading />}
                    {selectedSequenceId && <Icon color='primary' link className='fa-solid fa-trash redIcon' style={{ marginLeft: 10, marginTop: 10 }} onClick={onRemove} />}
                </div>
            </Form>
            <span>{selectedSequenceId ? `${numberOfCompletedTasks}/${selectedSequenceTasks.length} Tasks Completed` : 'No sequence tasks to display'}</span>
            {sequenceOptions.length > 0 && selectedSequenceId && (
                <>
                    <Menu size='small' borderless pointing secondary color={"primary"} fluid widths={"2"} className="ContactSequence__Menu">
                        <Menu.Item link active={activeTab === "Upcoming"} name='Upcoming' onClick={handleTabClick} />
                        <Menu.Item link active={activeTab === "Overdue"} name='Overdue' onClick={handleTabClick} />
                    </Menu>
                    <Timeline
                        className="SequenceTimeline__divider"
                        items={selectedSequenceTasks?.filter(task => task?.status?.toLowerCase() === activeTab?.toLowerCase()).map(mapTasks) || []}
                        icon={
                            <Icon className='fa-solid fa-sitemap' style={{ margin: 0 }} />
                        }
                    />
                </>
            )}
        </div>
    )
}

export default SequenceTimeline;
