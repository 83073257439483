const initialState = {
    isUpdatingCustomField: false,
    isDeletingCustomField: false,
    isAddingCustomFieldOption: false,
    isDeletingCustomFieldOption: false,
    group: null,
    fieldBeingDeleted: null,
    fieldBeingUpdated: null
}

export default initialState;
