import React from 'react';
import { Button, TimeUnitToggler, DateTimeUtils } from 'dyl-components';
import { Segment, Grid, Header, Popup } from 'semantic-ui-react';
import { connect, useSelector } from 'react-redux';

import CustomRangeDateModal from 'shared/modals/CustomRangeDateModal';

const DateModificationToolbar = ({
    onClickPrevious,
    onClickNext,
    onClickPresent,
    dateOnDisplay,
    onToggleDay,
    onToggleMonth,
    onToggleWeek,
    isPresentButtonDisabled,
    onClickPreviousFromPresent,
    onClickYearToDate,
    areNextAndPreviousButtonsDisabled,
    onOpenCustomDateRange,
    isCustomDateRangeOpen,
    onCloseCustomDateRange,
    onDateRangeChange,
    dateRange,
    getWorkflowsByRange
}) => {
    const tz = useSelector(state => state.auth.timezone);
    DateTimeUtils.setTimezone(tz);
    return (
        <Segment attached color='blue' inverted>
            <Grid verticalAlign='middle' columns='equal' textAlign='center' stackable>
                <Grid.Column width={6} floated='left' textAlign='center'>
                    <Button.Group size='large' basic floated='left' inverted>
                        <Button icon='chevron left' onClick={onClickPrevious} disabled={areNextAndPreviousButtonsDisabled} />
                        <Button icon='chevron right' onClick={onClickNext} disabled={areNextAndPreviousButtonsDisabled} />
                    </Button.Group>
                    <Button.Group basic size='large' floated='left' inverted>
                        <Button onClick={() => { onClickPresent('day') }} disabled={isPresentButtonDisabled}>
                            Today
                    </Button>
                    </Button.Group>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Header as='h1' inverted>
                        {dateOnDisplay}
                    </Header>
                </Grid.Column>
                <Grid.Column width={6} floated='right'>
                    <Button.Group basic size='large' floated='right' inverted>
                        <Button onClick={onToggleWeek}>
                            Week
                    </Button>
                        <Button onClick={onToggleMonth}>
                            Month
                    </Button>
                        <Button onClick={onToggleDay}>
                            Day
                    </Button>
                        <Popup
                            flowing
                            trigger={<Button icon='calendar outline plus' />}
                            content={(
                                <Grid columns={'equal'}>
                                    <Grid.Column>
                                        <TimeUnitToggler content='Today' onClick={() => { onClickPresent('day') }} />
                                        <TimeUnitToggler content='Yesterday' onClick={() => { onClickPreviousFromPresent('day') }} />
                                        <TimeUnitToggler content='This Week' onClick={() => { onClickPresent('week') }} />
                                        <TimeUnitToggler content='Last Week' onClick={() => { onClickPreviousFromPresent('week') }} />
                                        <TimeUnitToggler content='This Month' onClick={() => { onClickPresent('month') }} />
                                        <TimeUnitToggler content='Last Month' onClick={() => { onClickPreviousFromPresent('month') }} />
                                        <TimeUnitToggler content='Year to Date' onClick={onClickYearToDate} />
                                        <TimeUnitToggler content='Custom Range' onClick={onOpenCustomDateRange} />
                                    </Grid.Column>
                                </Grid>
                            )}
                            on='click'
                        />
                    </Button.Group>
                </Grid.Column>
            </Grid>
            <CustomRangeDateModal
                open={isCustomDateRangeOpen}
                onClose={onCloseCustomDateRange}
                onDateRangeChange={onDateRangeChange}
                dateRange={dateRange}
                getData={getWorkflowsByRange} />
        </Segment>
    )
 }

class DateModificationToolbarContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateOnDisplay: DateTimeUtils.getTime().moment.format('LL'),
            currentDate: DateTimeUtils.getTime().moment,
            activeTimeUnit: 'day',
            isCustomDateRangeOpen: false,
            dateRange: ''
        }
        this.onToggleDay = this.onToggleDay.bind(this);
        this.onToggleWeek = this.onToggleWeek.bind(this);
        this.onToggleMonth = this.onToggleMonth.bind(this);
        this.goForward = this.goForward.bind(this);
        this.goBack = this.goBack.bind(this);
        this.goPresent = this.goPresent.bind(this);
        this.goBackFromPresent = this.goBackFromPresent.bind(this);
        this.goYearToDate = this.goYearToDate.bind(this);
        this.openCustomDateRange = this.openCustomDateRange.bind(this);
        this.closeCustomDateRange = this.closeCustomDateRange.bind(this);
        this.onDateRangeChange = this.onDateRangeChange.bind(this);
        this.getWorkflowsByRange = this.getWorkflowsByRange.bind(this);
    }

    onToggleDay() {
        this.setState({
            activeTimeUnit: 'day'
        });
        this.getUpcomingWorkflows('today', this.state.currentDate);
    }

    onToggleWeek() {
        this.setState({
            activeTimeUnit: 'week'
        });
        this.getUpcomingWorkflows('this_week', this.state.currentDate);
    }

    onToggleMonth() {
        this.setState({
            activeTimeUnit: 'month'
        });
        this.getUpcomingWorkflows('this_month', this.state.currentDate);
    }

    getDateToBeDisplayed() {
        let activeTimeUnit = this.state.activeTimeUnit;
        switch (activeTimeUnit) {
            case 'month':
                return this.state.currentDate.format('MMMM YYYY');
            case 'week':
                let start = DateTimeUtils.getTime(this.state.currentDate).moment.startOf('week');
                let end = DateTimeUtils.getTime(this.state.currentDate).moment.endOf('week');
                let endString = start.get('month') === end.get('month') ? end.format('DD YYYY') : end.format('LL');
                return `${start.format('MMMM DD')} - ${endString}`;
            case 'year_to_date':
                let startOfYear = DateTimeUtils.getTime().moment.startOf('year').format('MMMM DD')
                return `${startOfYear} - ${DateTimeUtils.getTime().moment.format('LL')}`;
            default:
                return this.state.currentDate.format('LL');
        }

    }

    getUpcomingWorkflows(timeUnit, date) {
        let dateData = DateTimeUtils.getDateRange(timeUnit, date);
        let queryParameters = {
            ...this.props.queryParameters,
            start: DateTimeUtils.getUnixTime(`${dateData.start} 0:00:00`),
            end: DateTimeUtils.getUnixTime(`${dateData.end} 23:59:59`),
        }
        this.props.onReadUpcomingWorkflows(queryParameters);
    }



    goBack() {
        let currentDate = DateTimeUtils.getTime(this.state.currentDate).moment;
        let activeTimeUnit = this.state.activeTimeUnit;
        currentDate.subtract(1, activeTimeUnit);
        this.setState({ currentDate },
            () => {
                activeTimeUnit === 'day' ? activeTimeUnit = 'today' : activeTimeUnit = `this_${activeTimeUnit}`;
                this.getUpcomingWorkflows(activeTimeUnit, this.state.currentDate);
            });
    }

    goForward() {
        let currentDate = DateTimeUtils.getTime(this.state.currentDate).moment;
        let activeTimeUnit = this.state.activeTimeUnit;
        currentDate.add(1, activeTimeUnit);
        this.setState({ currentDate },
            () => {
                activeTimeUnit === 'day' ? activeTimeUnit = 'today' : activeTimeUnit = `this_${activeTimeUnit}`;
                console.log(this.state.currentDate);
                this.getUpcomingWorkflows(activeTimeUnit, this.state.currentDate);
            })
    }

    goPresent(timeUnit) {
        let currentDate = DateTimeUtils.getTime().moment;
        this.setState({ currentDate, activeTimeUnit: timeUnit },
            () => {
                timeUnit === 'day' ? this.getUpcomingWorkflows('today') : this.getUpcomingWorkflows(`this_${timeUnit}`);
            });
    }

    goBackFromPresent(timeUnit) {
        let currentDate = DateTimeUtils.getTime().moment;
        currentDate.subtract(1, timeUnit);
        this.setState({ currentDate, activeTimeUnit: timeUnit },
            () => {
                timeUnit === 'day' ? this.getUpcomingWorkflows('yesterday') : this.getUpcomingWorkflows(`last_${timeUnit}`);
            });
    }

    goYearToDate() {
        this.setState({ activeTimeUnit: 'year_to_date' });
        this.getUpcomingWorkflows('year_to_date')
    }

    openCustomDateRange() {
        this.setState({ isCustomDateRangeOpen: true });
    }

    closeCustomDateRange() {
        this.setState({ isCustomDateRangeOpen: false });
    }

    onDateRangeChange(_, { name, value }) {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    }

    getWorkflowsByRange() {
        let dateData = this.state.dateRange.split(' - ');

        let queryParameters = {
            start: '',
            end: '',
            page: 1,
            limit: 10,
        }

        dateData[0] === "" ? delete queryParameters.start :
            queryParameters.start = DateTimeUtils.getUnixTime(`${dateData[0]} 0:00:00`);

        dateData[1] === "" ? delete queryParameters.end :
            queryParameters.end = DateTimeUtils.getUnixTime(`${dateData[1]} 23:59:59`);

        this.closeCustomDateRange();
        if (dateData.length > 1) {
            this.props.onReadUpcomingWorkflows(queryParameters);
        }

    }

    render() {
        return (
            <DateModificationToolbar
                {...this.state}
                onToggleDay={this.onToggleDay}
                onToggleMonth={this.onToggleMonth}
                onToggleWeek={this.onToggleWeek}
                dateOnDisplay={this.getDateToBeDisplayed()}
                onClickNext={this.goForward}
                onClickPrevious={this.goBack}
                onClickPresent={this.goPresent}
                isPresentButtonDisabled={this.state.currentDate.format('MMMM YY DD') === DateTimeUtils.getTime().moment.format('MMMM YY DD')}
                onClickPreviousFromPresent={this.goBackFromPresent}
                onClickYearToDate={this.goYearToDate}
                areNextAndPreviousButtonsDisabled={this.state.activeTimeUnit === 'year_to_date'}
                isCustomDateRangeOpen={this.state.isCustomDateRangeOpen}
                onOpenCustomDateRange={this.openCustomDateRange}
                onCloseCustomDateRange={this.closeCustomDateRange}
                getUpcomingWorkflows={this.getUpcomingWorkflows}
                onDateRangeChange={this.onDateRangeChange}
                dateRange={this.state.dateRange}
                getWorkflowsByRange={this.getWorkflowsByRange}
            />
        )
    }
}

const mapStateToProps = state => {
    return ({
        queryParameters: state.workflows.queryParameters,
        count: state.workflows.count
    })
}

const mapDispatchToProps = dispatch => ({
    onReadUpcomingWorkflows: (queryParameters) => {

    },
})


export default connect(mapStateToProps, mapDispatchToProps)(DateModificationToolbarContainer);
