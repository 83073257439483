import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CustomerPipelinesTable from "./CustomerPipelinesTable";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect } from "react";
import customerTabActions from "actions/customer_tab";

import oauthActions from 'actions/oauth';
import ContactProvider, { ContactContext } from "shared/context/ContactProvider";
import SendEmailModal from "shared/modals/SendEmailModal";
import { NotesModal } from "shared/modals";

import './index.scss';
import { Dimmer, Loader } from "semantic-ui-react";

const CustomerPipelines = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const { account_id } = useParams();

    const routeRoot = `/account/${account_id}/customer/pipelines`;

    const { config: { modalType, contact }, onCloseModal } = useContext(ContactContext);

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    }

    useEffect(() => {
        dispatch(oauthActions.getIntegrations({ scopes: 'email' }));
    }, [dispatch]);

    useEffect(() => {
        const queryParameters = {
            page: 1,
            limit: 10,
            ...Object.fromEntries(params)
        }

        dispatch(customerTabActions.readPipelines(Number(account_id), queryParameters));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const { isTransferringPipeline } = useSelector(state => ({
        isTransferringPipeline: state.customer_pipeline.accountBeingUpdated || state.contact_sequence.isRemovingSequence || state.sequences.isAddingContactToSequence
    }));

    return (
        <>
            <Dimmer active={isTransferringPipeline}>
                <Loader active={isTransferringPipeline} />
            </Dimmer>
            <CustomerPipelinesTable
                routeRoot={routeRoot}
                onPageChange={onPageChange}
            />
            <SendEmailModal
                open={modalType === "email"}
                onClose={() => { onCloseModal() }}
                contact_id={contact}
            />
            <NotesModal
                open={modalType === "note"}
                onClose={() => { onCloseModal() }}
                contact={contact}
            />
        </>
    )
}

const CustomerPipelinesContainer = () => {
    return (
        <ContactProvider>
            <CustomerPipelines />
        </ContactProvider>
    );
}

export default CustomerPipelinesContainer;
