import React from 'react';
import { Grid, Form, Checkbox, Icon, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';

const TextListTableToolbar = ({ numberOfLeads = 0, onSort, sortOrder }) => (
    <Form as={Grid} stackable textAlign='left' style={{ paddingLeft: '1em' }}>
        <Form.Group as={Grid.Row} style={{ paddingBottom: 0, marginBottom: 0 }}>
            <Form.Field as={Grid.Column} width={2}>
                <Checkbox label='Select all pages' className='Checkbox--white' />
            </Form.Field>
            <Form.Field as={Grid.Column} width={2}>
                <Dropdown icon={''} trigger={<span><Icon name='cog' />Bulk Actions</span>}>
                    <Dropdown.Menu>
                        <Dropdown.Header icon='folder' content='Add to label:' />
                        <Dropdown.Item>
                            Remove from Texting List
                                    </Dropdown.Item>
                        <Dropdown.Item>
                            Mark as Called
                                    </Dropdown.Item>
                        <Dropdown.Item>
                            Mark as Uncalled
                                    </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Form.Field>
            <Form.Field as={Grid.Column} width={1}>
                <Dropdown icon={''} trigger={<span><Icon name='sort' />Sort</span>}>
                    <Dropdown.Menu>
                        <Dropdown.Header content='Sort by' />
                        <Dropdown.Item active={sortOrder === 'received'} onClick={() => { onSort('received') }}>Received</Dropdown.Item>
                        <Dropdown.Item active={sortOrder === 'activity'} onClick={() => { onSort('activity') }}>Activity</Dropdown.Item>
                        <Dropdown.Item active={sortOrder === 'consent'} onClick={() => { onSort('consent') }}>Consent</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Form.Field>
            <Form.Field as={Grid.Column} width={1}>
                <Dropdown icon={''} trigger={<span><Icon name='list' />Show</span>}>
                    <Dropdown.Menu>
                        <Dropdown.Header content='Show' />
                        <Dropdown.Item>10</Dropdown.Item>
                        <Dropdown.Item>25</Dropdown.Item>
                        <Dropdown.Item>50</Dropdown.Item>
                        <Dropdown.Item>100</Dropdown.Item>
                        <Dropdown.Item>200</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Form.Field>

            <Grid.Column floated='right' width={2} textAlign='right'>
                Leads: {numberOfLeads}
            </Grid.Column>
        </Form.Group>
    </Form>
)

const mapStateToProps = state => {
    return {
        numberOfLeads: state.smsList.smsList.length
    }
}

export default connect(mapStateToProps, null)(TextListTableToolbar);
