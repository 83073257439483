import routes from 'actions/routes';
import {
    generateCreateActionCreator,
    generateReadActionCreator,
    generateReadOneActionCreator,
    generateUpdateActionCreator
} from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

export default {
    createAccount: generateCreateActionCreator(routes.API_ACCOUNT, ACTION_NAMES.ACCOUNT, () => {
    }, () => {
    }),
    readAccounts: generateReadActionCreator(routes.API_ACCOUNT, ACTION_NAMES.ACCOUNT),
    readAccount: generateReadOneActionCreator(routes.API_ACCOUNT, ACTION_NAMES.ACCOUNT),
    readContacts: generateReadOneActionCreator(routes.API_ACCOUNT, ACTION_NAMES.ACCOUNT_CONTACTS, 'contacts'),
    readContactsForPinning: generateReadOneActionCreator(routes.API_ACCOUNT, ACTION_NAMES.ACCOUNT_CONTACTS_PIN_OPTIONS, 'contacts'),
    linkContacts: generateUpdateActionCreator(routes.API_ACCOUNT, ACTION_NAMES.LINK_CONTACTS_TO_ACCOUNT, () => {
    }, () => {
    }, 'link_contact'),
    unlinkContact: generateUpdateActionCreator(routes.API_ACCOUNT, ACTION_NAMES.UNLINK_CONTACTS_TO_ACCOUNT, () => {
    }, () => {
    }, 'unlink_contact'),
    readContactIds: generateReadOneActionCreator(routes.API_ACCOUNT, ACTION_NAMES.ACCOUNT_CONTACT_IDS, 'contact_ids'),
    createLocation: generateCreateActionCreator(routes.API_ACCOUNT_LOCATION, ACTION_NAMES.ACCOUNT_LOCATION),
    readLocations: generateReadActionCreator(routes.API_ACCOUNT_LOCATION, ACTION_NAMES.ACCOUNT_LOCATION),
    updateLocation: generateUpdateActionCreator(routes.API_ACCOUNT_LOCATION, ACTION_NAMES.ACCOUNT_LOCATION),
    updateAccount: generateUpdateActionCreator(routes.API_ACCOUNT, ACTION_NAMES.ACCOUNT),

    pauseCustomer: generateCreateActionCreator(routes.API_ACCOUNT, ACTION_NAMES.PAUSE_CUSTOMER, () => {}, () => {}, 'pause'),
    cancelCustomer: generateCreateActionCreator(routes.API_ACCOUNT, ACTION_NAMES.CANCEL_CUSTOMER, () => {}, () => {}, 'cancel'),
    inlineUpdateCustomData: (groups) => {
        return {
            type: 'INLINE_UPDATE_ACCOUNT_CUSTOM_DATA',
            groups
        }
    }
};
