import React, { useEffect } from 'react';
import { Modal, Notification, STATUS_TYPES, generateResolver, VALIDATORS, yup } from 'dyl-components';
import { Button, Form } from 'semantic-ui-react';
import { connect, useSelector } from 'react-redux';

import accountActions from 'actions/account';
import { useForm } from 'react-hook-form';

import contactsActions from 'actions/contacts';
import contactActions from 'actions/contact';
import tasksActions from 'actions/tasks';
import customFieldsGroupActions from "actions/custom_fields_group";
import { CustomGroupUtils, StringUtils, ValidationUtils } from 'utils';
import ContactsToLink from 'shared/ContactsToLink';
import { applyEmailUniquenessAcrossContacts, applyPhonesSchema } from 'shared/schemas/contact/commsSchema';

const LinkContactsForm = ({
    isCreating,
    onClose,

    onCreateContacts,
    onLinkContactsToAccount,
    onUpdateContact,
    updateTasks,

    account_id,
    readAccount,
    readContactStandardGroup
}) => {
    const account = useSelector(state => state.account.account);
    const account_type = StringUtils.capitalize(account?.account_type || '');

    const { formState: { isDirty, isValid }, control, trigger, handleSubmit, watch, clearErrors, setError } = useForm({
        mode: 'onChange',
        defaultValues: {
            addedContacts: [{
                main: false,
                first_name: '',
                last_name: '',
                suffix: '',
                emails: [
                    { value: '', type: "Work", main: true }
                ],
                phones: [
                    { value: '', type: "Cell", main: true }
                ],
                role: '',
                job_title: '',

                address_label: account_type === 'Household' ? 'Home' : account_type,
                street: '',
                apartmentUnitOrFloor: '',
                city: '',
                state: '',
                zip: '',

                website: '',

                business_name: '',
                industry_sector: null,
                sub_industry: null,
                employee_size: 0,
                annual_revenue: '',
                products_sold: '',

                household_name: '',
                household_type: null,
                household_members: 0,
                number_of_children: 0,
                annual_household_income: ''
            }]
        },
        resolver: generateResolver({
            addedContacts: yup.array().of(yup.object().shape({
                new: yup.boolean(),
                first_name: VALIDATORS.FIRST_NAME().no_whitespace_only().required('This field is required'),
                last_name: VALIDATORS.LAST_NAME().no_whitespace_only().required('This field is required'),

                phones: yup.array().when("new", {
                    is: true,
                    then: schema => applyPhonesSchema(schema)
                }),
                emails: yup.array().when("new", {
                    is: true,
                    then: schema => schema.when("phones", {
                        is: phones => {
                            return phones?.some((phone) => phone.value !== '') === true;
                        },
                        then: schema => schema.of(yup.object().shape({
                            value: applyEmailUniquenessAcrossContacts(VALIDATORS.EMAIL_ADDRESS().maxlength(256))
                        })).test('no_repeating_email', "Email already in the list", ValidationUtils.checkForDuplicateEmailsInTheList),
                        otherwise: schema => schema.of(yup.object().shape({
                            value: applyEmailUniquenessAcrossContacts(VALIDATORS.EMAIL_ADDRESS().maxlength(256).required('This field is required'))
                        })).test('no_repeating_email', "Email already in the list", ValidationUtils.checkForDuplicateEmailsInTheList)
                    })
                }),
                suffix: VALIDATORS.SUFFIX(),
                department: yup.string().maxlength(64),
                job_title: yup.string().maxlength(60),
                street: yup.string().maxlength(100),
                apartmentUnitOrFloor: yup.string().maxlength(12),
                city: yup.string().maxlength(60),
                state: yup.string().when("new", {
                    is: true,
                    then: schema => schema.required('This field is required'),
                }),
                zip: VALIDATORS.US_POSTAL_CODE(),

                website: VALIDATORS.WEBSITE(),
                role: yup.string().when("new", {
                    is: false,
                    then: schema => schema.required('This field is required')
                })
            }, ['emails', 'phones']))
        })
    });

    useEffect(() => {
        readContactStandardGroup();
    }, [readContactStandardGroup]);

    const contacts = watch('addedContacts');

    const linkContactsToAccount = async () => {
        const newContactsToLink = contacts.filter(contact => !contact.contact_id).map(contact => ({
            primary: contact.main || false,
            contact_type: 'person',
            custom_data: {
                fields: CustomGroupUtils.extractPersonFields(contact),
                children: {
                    ...CustomGroupUtils.extractBusinessAndHouseholdDetails(contact)
                }
            },
            email: contact.emails.filter(email => Boolean(email.value)).map(email => ({
                main: email.main,
                email: email.value,
                email_type: email.type
            })),
            first_name: contact.first_name,
            last_name: contact.last_name,
            locations: [
                {
                    street: contact.street,
                    additional_street: contact.apartmentUnitOrFloor,
                    city: contact.city,
                    state: contact.state,
                    zip: contact.zip,
                    label: contact.address_label
                }
            ],
            phone: contact.phones.filter(phone => Boolean(phone.value)).map(phone => ({
                main: phone.main,
                phone: phone.value,
                phone_type: phone.type
            })),
            influence: contact.role,
            suffix: contact.suffix || '',
            social_media: [],

            job_title: contact.job_title,
            website: contact.website
        }));

        const contactsToLink = [];

        if (newContactsToLink.length) {
            const newContacts = await onCreateContacts(newContactsToLink);
            contactsToLink.push(...newContacts.map((id, index) => ({
                person_id: id,
                primary_person: newContactsToLink[index].primary,
            })));
        }

        const existingContactsToLink = contacts.filter(contact => contact.contact_id).map(contact => ({
            person_id: contact.contact_id,
            primary_person: contact.main || false,
            influence: contact.role || "unknown"
        }));

        contactsToLink.push(...existingContactsToLink);

        if (contactsToLink.length) {
            contactsToLink.forEach(async contact => {
                await onUpdateContact(contact.person_id, { influence: contact.influence });
            });
            await onLinkContactsToAccount(contactsToLink, account_id);
            await updateTasks(contactsToLink.map((contact_id) => contact_id.person_id ), Number(account_id));
        }

        return Promise.resolve();
    }

    const onLink = async () => {
        try {
            await linkContactsToAccount(account_id);
            Notification.alert('Successfully linked contacts to account!', STATUS_TYPES.SUCCESS);
            onClose();
            readAccount(account_id);
        } catch {
            Notification.alert('Failed to link contacts to account', STATUS_TYPES.ERROR);
        }
    }

    return (
        [
            <Modal.Header>
                Add {account_type} Contact
            </Modal.Header>,
            <Modal.Content scrolling>
                <Form noValidate>
                    <ContactsToLink trigger={trigger} account_type={account_type} clearErrors={clearErrors} control={control} setError={setError} watch={watch} />
                </Form>
            </Modal.Content>,
            <Modal.Actions>
                {!isCreating && <Button primary basic onClick={onClose}>Cancel</Button>}
                <Button disabled={!isDirty || !isValid} primary onClick={handleSubmit(onLink)} loading={isCreating}>Save</Button>
            </Modal.Actions>
        ]
    )
}

const mapStateToProps = state => {
    const { isLinkingContactsToAccount } = state.account;
    const { isCreating: isCreatingContacts } = state.contacts;
    return {
        isCreating: isCreatingContacts || isLinkingContactsToAccount
    };
}

const mapDispatchToProps = dispatch => ({
    onCreateContacts: (contacts) => {
        return dispatch(contactsActions.createContact(contacts));
    },
    onLinkContactsToAccount: (contacts, account_id) => {
        return dispatch(accountActions.linkContacts(account_id, contacts))
    },
    onUpdateContact: (contact_id, updatedContactDetails) => {
        return dispatch(contactActions.updateContact(contact_id, updatedContactDetails));
    },
    readAccount: (id) => {
        dispatch(accountActions.readAccount(id));
        dispatch(accountActions.readContactsForPinning(id, { page: 1, limit: 100 }));
    },
    readContactStandardGroup: () => {
        dispatch(customFieldsGroupActions.getContactStandardGroup({ group_name: 'person' }));
    },
    updateTasks: (contact_id, account_id) => {
        return dispatch(tasksActions.updateTasks(-1, { contact_id, account_id }));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(LinkContactsForm);
