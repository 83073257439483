import ProductCodes from "../ProductCodes";
import SupplyChainNetwork from "../SupplyChainNetwork";

const { Segment, Header } = require("semantic-ui-react")

const ProductDetails = () => {
    return (
        <Segment>
            <Header color="primary" as="h3">Product Details</Header>
            <ProductCodes />
            <SupplyChainNetwork />
        </Segment>
    )
}

export default ProductDetails;
