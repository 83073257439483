import React from 'react';
import {
    getData,
    onToggleTimeUnit,
    useRangeCalendar,
} from "shared/CustomDateRange/customDateRange";
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Table as SemanticTable } from 'semantic-ui-react';
import { CategorizedCheckboxDropdown, ClearableCheckboxDropdown, Icon, Table, TimestampFilters } from 'dyl-components';

import {
    KEY_ACCOUNT, KEY_ACTIONS, KEY_CONVERT, KEY_CREATED,
    KEY_LAST_MODIFIED, KEY_OWNED_BY, KEY_CAMPAIGN_SOURCE,
    KEY_STAGE, KEY_CREATED_BY, KEY_LAST_MODIFIED_BY, KEY_OUTCOME
} from 'shared/constants/MODULE_COLUMNS';

const SortActions = ({ direction = false, onToggleSort = () => { } }) => {
    return (
        <div
            onClick={onToggleSort}
            style={{ display: "inline-flex", cursor: "pointer" }}
        >
            <Icon
                name="fa-solid fa-arrow-down-long"
                style={{
                    marginRight: -7,
                    opacity: !direction ? "100%" : "50%",
                }}
            />
            <Icon
                name="fa-solid fa-arrow-up-long"
                style={{ opacity: direction ? "100%" : "50%" }}
            />
        </div>
    );
};

const OWNED_BY_CATEGORIES = [
    {
        name: 'Assigned Status', options: [
            { key: 'All', value: 'All', text: 'All', all: true },
            { key: 'Assigned', value: 'Assigned', text: 'Assigned' },
            { key: 'Unassigned', value: 'Unassigned', text: 'Unassigned' }
        ]
    },
    {
        name: 'Owned By', options: [
            { key: 'Anyone', value: 'Anyone', text: 'Anyone', all: true },
            { key: 'Myself', value: 'Myself', text: 'Myself' },
            { key: 'My team', value: 'My team', text: 'My team' },
            { key: 'My territory', value: 'My territory', text: 'My territory' }
        ]
    }
]

const OpportunitiesHeader = ({ onFilter, isColumnHidden }) => {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        userAuthTimezone,
        dateRange,
        setDateRange,
    } = useRangeCalendar(params);

    const createdOrder = params.get("order_by") === "created" && params.get("order") === "asc";

    const lastModifiedOrder = params.get("order_by") === "activity" && params.get("order") === "asc";

    const onDateRangeChange = (_, { value }) => {
        setDateRange(value);
    };

    const createdOrderFilter = () => {
        const query = new URLSearchParams(params);
        const order = !createdOrder ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", "created");
        const query_string = query.toString();
        navigate(`/opportunities${query_string ? `?${query_string}` : ""}`);
    };

    const lastModifiedOrderFilter = () => {
        const query = new URLSearchParams(params);
        const order = !lastModifiedOrder ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", "activity");
        const query_string = query.toString();
        navigate(`/opportunities${query_string ? `?${query_string}` : ""}`);
    };

    return (
        <Table.Row>
            {!isColumnHidden(KEY_CREATED) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    <span style={{ marginRight: 5 }}>Created</span>
                    <TimestampFilters
                        timestamp={timestamp}
                        onToggleTimeUnit={(timestamp) =>
                            onToggleTimeUnit(
                                timestamp,
                                setTimestamp,
                                params,
                                navigate,
                                "/opportunities"
                            )
                        }
                        isCustomDateRangeOpen={isCustomDateRangeOpen}
                        onOpenCustomDateRange={() => {
                            setCustomDateRangeOpen(true);
                        }}
                        onCloseCustomDateRange={() => {
                            setCustomDateRangeOpen(false);
                        }}
                        dateRange={dateRange}
                        onDateRangeChange={onDateRangeChange}
                        getData={() =>
                            getData({
                                setCustomDateRangeOpen,
                                dateRange,
                                userAuthTimezone,
                                params,
                                navigate,
                                url: "/opportunities",
                            })
                        }
                    />

                    <SortActions
                        direction={createdOrder}
                        onToggleSort={() => {
                            createdOrderFilter();
                        }}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CREATED_BY) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>Created By</SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Last Modified
                    <SortActions
                        direction={lastModifiedOrder}
                        onToggleSort={() => {
                            lastModifiedOrderFilter();
                        }}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED_BY) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Last Modified By
                </SemanticTable.HeaderCell>
            )}
            <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>Opportunity ID</SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                Opportunity Name
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='ModulesListTable__LargeFixedWidth' width={4}>
                Contact Name
            </SemanticTable.HeaderCell>
            {!isColumnHidden(KEY_ACCOUNT) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    <ClearableCheckboxDropdown
                        text='Account'
                        name='account_type'
                        options={[
                            { key: 'household', value: 'household', text: 'Household' },
                            { key: 'business', value: 'business', text: 'Business' },
                        ]}
                        onChange={(_, { value }) => { onFilter(_, { name: 'account_type', value }) }}
                        value={params.get('account_type')?.split(',')}
                        closeOnLoad={true}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_STAGE) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>Stage</SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_OUTCOME) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>Outcome</SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_OWNED_BY) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    <CategorizedCheckboxDropdown
                        text="Owner"
                        name="owned_by"
                        categories={OWNED_BY_CATEGORIES}
                        onChange={(_, { value }) => {
                            onFilter(_, { name: "owned_by", value });
                        }}
                        value={params.get("owned_by")?.split(",")}
                        closeOnLoad={true}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CAMPAIGN_SOURCE) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Campaign Converted
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CONVERT) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Convert
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_ACTIONS) && (
                <SemanticTable.HeaderCell className='ModulesListTable__SmallFixedWidth' width={1}>Actions</SemanticTable.HeaderCell>
            )}
        </Table.Row>
    );
}

export default OpportunitiesHeader;
