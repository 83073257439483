import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid } from 'semantic-ui-react';
import { SearchBar } from 'dyl-components';
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import CampaignContactsBulkActionsModal from 'shared/modals/CampaignContactsBulkActionsModal';


const ContactsToolbar = ({ search, onSearchSubmit, cancelFunction, onChangeSearch, readContacts }) => {
    const [selectedContacts, , areContactsInAllPagesSelected] = useContext(BulkActionsContext);

    const { count, isReadingContacts } = useSelector(state => ({
        isReadingContacts: state.campaign_contacts.isReadingContacts,
        count: state.campaign_contacts.count
    }));

    const hasSelectedContacts = selectedContacts.length || areContactsInAllPagesSelected;
    const numberOfSelectedContacts = areContactsInAllPagesSelected ? count : selectedContacts.length;

    const [isBulkActionsModalOpen, setIsBulkActionsModalOpen] = useState(false);

    const onOpenBulkActionsModal = () => {
        setIsBulkActionsModalOpen(true);
    }

    const onCloseBulkActionsModal = () => {
        setIsBulkActionsModalOpen(false);
    }

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row columns='equal' verticalAlign='middle'>
                    <Grid.Column width={5}>
                        <SearchBar
                            isSearching={isReadingContacts}
                            search={search}
                            searchFcn={onSearchSubmit}
                            cancelFcn={cancelFunction}
                            placeholder={'Search by Contact Name'}
                            onChange={onChangeSearch}
                        />
                    </Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column width={3} floated='right'>
                        <Button floated='right' className='ContactsToolbar__NewContactsButton' disabled={!hasSelectedContacts} onClick={onOpenBulkActionsModal}>
                            Bulk Action
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <CampaignContactsBulkActionsModal
                open={isBulkActionsModalOpen}
                onClose={onCloseBulkActionsModal}
                numberSelectedContacts={numberOfSelectedContacts}
                readContacts={readContacts}
            />
        </React.Fragment>

    )
}

export default ContactsToolbar;
