import React, { useState } from 'react';
import { Grid, Header, Icon, Popup } from 'semantic-ui-react';

import { ClippedContent, Editable, generateResolver, Notification, STATUS_TYPES, VALIDATORS } from 'dyl-components';
import Avatar from 'react-avatar';

import './index.scss'
import { Controller, useForm } from 'react-hook-form';
import masterAccountActions from 'actions/master_account';
import { connect } from 'react-redux';

import ErrorPopup from 'shared/ErrorPopup';
import { FAVORITE_TYPE } from 'shared/constants/FAVORITE_TYPE';
import { ProfileFavoriteIcon } from 'shared/ProfileFavoriteIcon';

function copy(text) {
    return (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(text);
    };
}

const mapStateToProps = state => ({
    isUpdating: state.master_account.isUpdatingMasterAccount
});

const mapDispatchToProps = dispatch => ({
    onUpdateAccount: (account_id, payload) => {
        return dispatch(masterAccountActions.update(account_id, payload));
    },
    onReadAccount: (account_id) => {
        dispatch(masterAccountActions.readOne(account_id));
    }
})

export const ProfileHeader = connect(mapStateToProps, mapDispatchToProps)(({
    name,
    id,
    type,
    breadcrumbs,

    isSidePanelOpen,
    onReadAccount,
    isUpdating,
    onUpdateAccount,

    pinnedAccounts
}) => {
    const [isEditing, setEditing] = useState(false);

    const { control, formState: { isValid, isDirty }, handleSubmit, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            name
        },
        resolver: generateResolver({
            name: VALIDATORS.MASTER_NAME().no_whitespace_only().required('This field is required')
        })
    });

    const onEdit = () => {
        setEditing(true);
    }

    const onCancelEdit = () => {
        setEditing(false);
        reset({
            name: name || ''
        })
    }


    const onConfirmEdit = async (data) => {
        try {
            await onUpdateAccount(id, { ...data, pins: pinnedAccounts.map(account => account.id) });
            Notification.alert('Successfully updated account!', STATUS_TYPES.SUCCESS);
            onReadAccount(id);
        } catch {
            Notification.alert('Failed to update account', STATUS_TYPES.ERROR);
        }
    }

    return (
        <React.Fragment>
            <div style={{ paddingBottom: "0.5em" }}>
                {breadcrumbs}
            </div>
            <Grid>
                <Grid.Row columns='equal' verticalAlign='middle'>
                    <Grid.Column width={6}>
                        <Grid columns='equal' verticalAlign='middle'>
                            <Grid.Column width={isSidePanelOpen ? 4 : 3}>
                                <Avatar
                                    size='5em'
                                    round
                                    maxInitials={2}
                                    name={name} />
                            </Grid.Column>
                            <Grid.Column>
                                <Header as='h3'>
                                    {!isEditing ?
                                        <div onClick={onEdit}>
                                            <ClippedContent>
                                                {name}
                                            </ClippedContent>
                                        </div> : (
                                            <Editable
                                                value={name}
                                                isEditing={isEditing}
                                                onCancelEdit={onCancelEdit}
                                                onEdit={onEdit}
                                                size='small'
                                                underline
                                                onConfirmEdit={handleSubmit(onConfirmEdit, (errors, e) => {
                                                    console.log(errors, e);
                                                })}
                                                submitDisabled={!isDirty || !isValid || isUpdating}
                                            >
                                                <Controller
                                                    name='name'
                                                    control={control}
                                                    render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                                                        <ErrorPopup
                                                            isPopupOpen={typeof (error?.message) != "undefined"}
                                                            message={error?.message}
                                                            inputName={name}
                                                            inputValue={value}
                                                            onInputChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                                            inputPlaceholder='Enter new name'
                                                            isLoading={isUpdating}
                                                        />
                                                    )}
                                                />
                                            </Editable>
                                        )}
                                    <div className='ProfileHeader__icons'>
                                        <ProfileFavoriteIcon id={id} type={FAVORITE_TYPE.MASTER_ACCOUNT} />
                                    </div>
                                </Header>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column textAlign='left'>
                        <Header as='h3' color='primary' style={{ marginTop: 0, marginBottom: 0 }}>
                            <Header.Content>
                                <Icon.Group>
                                    <Icon color='primary' size='big' name='circle outline' />
                                    <Icon color='primary' className='fas fa-buildings' />
                                </Icon.Group> {type?.toUpperCase()}
                            </Header.Content>
                        </Header>
                        <span style={{ marginLeft: '0.75em' }}>
                            ID: <Popup
                                trigger={<a href="#?" role="button" tabIndex="0" onClick={copy(id)}>{id} <Icon className='fas fa-copy' /></a>}
                                content='Copied!'
                                on='click'
                                closeOnTriggerMouseLeave
                                position='bottom center'
                                inverted />
                        </span>
                    </Grid.Column>
                    <Grid.Column textAlign='right'>
                        <Icon name='fas fa-ellipsis-vertical' />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    );
});
