import React from 'react';
import { useSelector } from "react-redux";
import { Table as SemanticTable } from "semantic-ui-react";
import { Table } from "dyl-components";

import SelectAllCheckbox from 'shared/SelectAllCheckbox';
import { StringUtils } from 'utils';

const TableHeader = ({ type }) => {
    const { count, fees = [] } = useSelector((state) => ({
        count: state.product_fees.count,
        fees: state.product_fees.fees,
    }));

    return (
        <Table.Row>
            <SemanticTable.HeaderCell
                width={1}
                className="FeesTable__SmallFixedWidth"
            >
                <SelectAllCheckbox
                    count={count}
                    data={fees}
                    type={type === "tax" ? "taxes" : "fees"}
                    idProperty={"id"}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                className="FeesTable__LargeFixedWidth"
                width={4}
            >
                {StringUtils.capitalize(type)} Name
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                className="FeesTable__LargeFixedWidth"
                width={4}
            >
                Description
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={1}
                className="FeesTable__SmallFixedWidth"
            >
                Price
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={1}
                className="FeesTable__SmallFixedWidth"
            >
                Status
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={1}
                className="FeesTable__SmallFixedWidth"
            >
                Actions
            </SemanticTable.HeaderCell>
        </Table.Row>
    );
}

export default TableHeader;
