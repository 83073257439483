import routes from 'actions/routes';
import { generateUpdateActionCreator, generateDeleteActionCreator, generateReadOneActionCreator, generateReadActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

export default {
    updateCustomFieldGroup: generateUpdateActionCreator(routes.API_CUSTOM_FIELDS_GROUPS, ACTION_NAMES.CUSTOM_FIELDS_GROUP),
    deleteCustomFieldGroup: generateDeleteActionCreator(routes.API_CUSTOM_FIELDS_GROUPS, ACTION_NAMES.CUSTOM_FIELDS_GROUP),
    getCustomFieldGroup: generateReadOneActionCreator(routes.API_CUSTOM_FIELDS_GROUPS, ACTION_NAMES.CUSTOM_FIELDS_GROUP),
    getModalStandardGroup: generateReadActionCreator(routes.API_CUSTOM_FIELDS_GROUPS, ACTION_NAMES.MODAL_CUSTOM_FIELDS_STANDARD_GROUP),
    getContactStandardGroup: generateReadActionCreator(routes.API_CUSTOM_FIELDS_GROUPS, ACTION_NAMES.CONTACT_STANDARD_GROUP),
    getCustomerStandardGroup: generateReadActionCreator(routes.API_CUSTOM_FIELDS_GROUPS, ACTION_NAMES.CUSTOMER_STANDARD_GROUP),
    reactivateGroup: generateUpdateActionCreator(routes.API_CUSTOM_FIELDS_GROUPS, ACTION_NAMES.CUSTOM_FIELD_GROUP_REACTIVATE, () => {}, () => {}, 'active')
};
