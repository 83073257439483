import React from 'react';
import { Grid, Header, Icon } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const IntegrationBody = () => {
    const  { contact_id, account_id }  = useParams();
    const user_id = useSelector(state => state.auth.user_id);
    return(
        <Grid className='Integration'>
        
            <Grid.Row className='IntegrationHeader__Row' columns='equal'>
                <Grid.Column>
                    <Header as='h5'>
                        Go to <Link to={`/settings/users/${user_id}/integration`}><Icon name='cog' style={{color: 'primary', fontSize: 15, margin: 0, verticalAlign:'center'}}/>Settings</Link> to add an email integration
                    </Header>
                </Grid.Column>
            </Grid.Row>  
        
        
            <Grid.Row className='IntegrationBody__Row'>
                <Grid.Column>
                    {!account_id &&
                        <Header as='h5'>
                            You can also
                            
                            <Link to={`/contact/${contact_id}/timeline`} state={{ fromEmail: true }}><Icon name='history' className='IntegrationIcon' style={{color: 'primary', fontSize: 15, margin: 0, verticalAlign:'center'}}/><b>log previous emails </b></Link>

                            or <Link to={`/contact/${contact_id}/tasks`}><Icon name='fal fa-clipboard-check' style={{color: 'primary', fontSize: 15, margin: 0, verticalAlign:'center'}}/>create an email task</Link>
                        </Header>
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

export default IntegrationBody

