import { Controller, useController, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import CategoryProductAddition from "./CategoryProductAddition";

const CategoryProductSummary = ({
    product_id,
    volumeInformation,
    price_data,
    disabled,
}) => {
    const summary = useSelector(
        (state) => state.product_catalog.summaries[product_id]
    );

    const {
        addons,
        fee: fees,
        tax: taxes,
        variants = {},
    } = summary?.payload || {
        addons: [],
        fee: [],
        tax: [],
        variants: {},
    };

    const { control } = useFormContext();

    const isRecurring = price_data?.model?.includes("recurring");
    const isVolumeBased = price_data?.model?.includes("volume");

    const { field: masterFilterField } = useController({
        control,
        name: "pricing_schedule"
    });

    return (
        <>
            {volumeInformation}
            <Controller
                control={control}
                name={"cart"}
                render={({ field: { name, value: cart, onChange } }) => {
                    const selectedVariants = cart.filter(
                        (item) => item.id === product_id
                    );
                    const addonsIncluded = [
                        ...new Set(
                            selectedVariants
                                .map((item) => item.addons || [])
                                .flat()
                        ),
                    ];
                    return (
                        <>
                            {Object.keys(variants)
                                .map((variantName) => {
                                    const variantValues = variants[variantName];
                                    return variantValues.map((variantValue) => {
                                        const {
                                            additional_price,
                                            id: variation_id,
                                            value,
                                        } = variantValue;
                                        const isInCart = Boolean(
                                            cart.find(
                                                (item) =>
                                                    item.id === product_id &&
                                                    item.variation_id ===
                                                        variation_id
                                            )
                                        );

                                        const activeFrequencies = Object.keys(
                                            price_data.price
                                        ).filter(
                                            (frequency) =>
                                                price_data.price[frequency]
                                                    ?.active
                                        );

                                        const selectedPricingSchedule =
                                            isRecurring
                                                ? masterFilterField?.value || [
                                                      ...new Set(
                                                          selectedVariants.map(
                                                              (item) =>
                                                                  item.pricing_schedule
                                                          )
                                                      ),
                                                  ][0] || activeFrequencies[0]
                                                : null;
                                        const quantity = !isVolumeBased
                                            ? 1
                                            : !isRecurring
                                            ? price_data.price[0].start
                                            : price_data.price[
                                                  selectedPricingSchedule
                                              ]?.volumes[0].start || 1;

                                        const onUpdateCart = () => {
                                            onChange({
                                                target: {
                                                    name,
                                                    value: isInCart
                                                        ? cart.filter(
                                                              (item) =>
                                                                  !(
                                                                      item.id ===
                                                                          product_id &&
                                                                      item.variation_id ===
                                                                          variation_id
                                                                  )
                                                          )
                                                        : [
                                                              ...cart,
                                                              {
                                                                  id: product_id,
                                                                  variation_id:
                                                                      variation_id,
                                                                  addons: addonsIncluded,
                                                                  quantity:
                                                                      quantity,
                                                                  ...(isRecurring
                                                                      ? {
                                                                            pricing_schedule:
                                                                                activeFrequencies[0],
                                                                            schedules: activeFrequencies
                                                                        }
                                                                      : {}),
                                                              },
                                                          ],
                                                },
                                            });
                                        };

                                        return (
                                            <CategoryProductAddition
                                                key={`variant-${variantName}-${variation_id}`}
                                                labelColspan={1}
                                                label={
                                                    <span
                                                        style={{
                                                            paddingLeft: "3em",
                                                        }}
                                                    >
                                                        {variantName}/{value}
                                                        {additional_price
                                                            ? `(+$${additional_price})`
                                                            : ""}{" "}
                                                    </span>
                                                }
                                                popupProps={{
                                                    popupContent: `${variantName}/${value}
                                                    ${
                                                        additional_price
                                                            ? `(+$${additional_price})`
                                                            : ""
                                                    }${" "}`,
                                                    position: 'top center'
                                                }}
                                                action={
                                                    <Icon
                                                        className={`fas fa-circle-${
                                                            isInCart
                                                                ? "minus"
                                                                : "plus"
                                                        }`}
                                                        color={
                                                            isInCart
                                                                ? "red"
                                                                : "primary"
                                                        }
                                                        link
                                                        onClick={onUpdateCart}
                                                        disabled={disabled}
                                                    />
                                                }
                                            />
                                        );
                                    });
                                })
                                .flat()}
                            {addons.map((addon) => {
                                const allProductVariationsInCart = cart.filter(
                                    (item) => item.id === product_id
                                );
                                const isAddonIncluded =
                                    allProductVariationsInCart.some(
                                        (variation) =>
                                            variation.addons?.includes(addon.id)
                                    );
                                const onToggleAddon = () => {
                                    onChange({
                                        target: {
                                            name,
                                            value: cart.map((item) => {
                                                if (item.id !== product_id) {
                                                    return item;
                                                }
                                                const itemAddons =
                                                    item?.addons || [];
                                                return {
                                                    ...item,
                                                    addons: isAddonIncluded
                                                        ? itemAddons.filter(
                                                              (includedAddon) =>
                                                                  includedAddon !==
                                                                  addon.id
                                                          )
                                                        : [
                                                              ...itemAddons,
                                                              addon.id,
                                                          ],
                                                };
                                            }),
                                        },
                                    });
                                };
                                return (
                                    <CategoryProductAddition
                                        key={`addon-${addon.id}`}
                                        label={
                                            <span>
                                                <b>Add-on:</b> {addon.name}
                                            </span>
                                        }
                                        value={`$${addon.price}`}
                                        action={
                                            <Icon
                                                className={`fas fa-circle-${
                                                    isAddonIncluded
                                                        ? "minus"
                                                        : "plus"
                                                }`}
                                                color={
                                                    isAddonIncluded
                                                        ? "red"
                                                        : "primary"
                                                }
                                                link
                                                onClick={onToggleAddon}
                                                disabled={
                                                    allProductVariationsInCart.length ===
                                                    0
                                                }
                                            />
                                        }
                                    />
                                );
                            })}
                        </>
                    );
                }}
            />
            {fees.map((fee) => (
                <CategoryProductAddition
                    key={`fee-${fee.id}`}
                    label={
                        <span>
                            <b>Fee:</b> {fee.name}
                        </span>
                    }
                    value={`${
                        fee.percent ? `${fee.amount}%` : `$${fee.amount}`
                    }`}
                />
            ))}
            {taxes.map((tax) => (
                <CategoryProductAddition
                    key={`tax-${fees.id}`}
                    label={
                        <span>
                            <b>Tax:</b> {tax.name}
                        </span>
                    }
                    value={`${
                        tax.percent ? `${tax.amount}%` : `$${tax.amount}`
                    }`}
                />
            ))}
        </>
    );
};

export default CategoryProductSummary;
