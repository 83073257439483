import React from 'react';
import { TableWithHeader, Notification, STATUS_TYPES } from 'dyl-components';
import CallOverrideHeader from './subcomponents/CallOverrideHeader';
import { Grid, Transition, Segment } from 'semantic-ui-react';
import AddCallOverrideSectionContainer from './subcomponents/AddCallOverrideSection';
import CallOverrideTable from './subcomponents/CallOverrideTable';
import { LeftPaddedColumn } from 'dyl-components';

const CallOveride = ({ 
    callOverrides,
    isAddCallOverrideSectionOpen, 
    onOpenAddCallOverrideSection,
    onCloseAddCallOverrideSection,
    onAddCallOverride,
    onUpdateCallOverride,
    onDelete
}) => (
    <TableWithHeader 
        header={
            <CallOverrideHeader 
                onOpenAddCallOverrideSection={onOpenAddCallOverrideSection} 
            />
        }
        table={
            <React.Fragment>
                <Transition unmountOnHide visible={isAddCallOverrideSectionOpen} animation='slide down' duration='100'>
                    <Grid>
                        <Grid.Row style={{paddingBottom: 0}}>
                            <Grid.Column as={LeftPaddedColumn}>
                                <Segment basic>
                                    <AddCallOverrideSectionContainer 
                                        onAddCallOverride={onAddCallOverride}
                                        onClose={onCloseAddCallOverrideSection}
                                    />
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Transition>
                {callOverrides.length <= 0 && (
                    <Segment textAlign='center'>
                        <em>There are no call overrides</em>
                    </Segment>
                )}
                {callOverrides.length > 0 && (
                    <CallOverrideTable 
                        callOverrides={callOverrides} 
                        onUpdate={onUpdateCallOverride}
                        onDelete={onDelete}
                    />
                )}
            </React.Fragment>
        }
    />
)

class CallOverrideContainer extends React.Component {
    state = {
        callOverrides: [
            { state: 'AL', callerId: '(555) 555-5555' }
        ],
        isAddCallOverrideSectionOpen: false
    }

    onOpenAddCallOverrideSection = () => {
        this.setState({ isAddCallOverrideSectionOpen: true });
    }

    onCloseAddCallOverrideSection = () => {
        this.setState({ isAddCallOverrideSectionOpen: false });
    }

    onUpdateCallOverride = (updatedCallOverride) => {
        let callOverrides = this.state.callOverrides.slice(0);
        let indexOfCallOverrideToUpdate = callOverrides.findIndex(callOverride => callOverride.callerId === updatedCallOverride.callerId);
        if (indexOfCallOverrideToUpdate !== -1) {
            callOverrides[indexOfCallOverrideToUpdate] = {...updatedCallOverride};
            this.setState({ callOverrides });
        }
    }

    onDelete = (callerId) => {
        let callOverrides = this.state.callOverrides.slice(0);
        let indexOfCallOverrideToDelete = callOverrides.findIndex(callOverride => callOverride.callerId === callerId);
        if (indexOfCallOverrideToDelete !== -1) {
            callOverrides.splice(indexOfCallOverrideToDelete, 1);
            this.setState({ callOverrides }, () => {
                Notification.alert('Successfully deleted call override!', STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    onAddCallOverride = (newCallOverride) => {
        let callOverrides = this.state.callOverrides.slice(0);
        callOverrides.push(newCallOverride);
        this.setState({ callOverrides }, () => {
            Notification.alert('Successfully added call override!', STATUS_TYPES.SUCCESS, true);
        });
    }

    render() {
        return (
            <CallOveride 
                {...this.state}
                onUpdateCallOverride={this.onUpdateCallOverride}
                onOpenAddCallOverrideSection={this.onOpenAddCallOverrideSection}
                onCloseAddCallOverrideSection={this.onCloseAddCallOverrideSection}
                onAddCallOverride={this.onAddCallOverride}
                onDelete={this.onDelete}
            />
        )
    }
}

export default CallOverrideContainer;
