import React, { useState, useContext } from 'react';
import { Table, Person, DateTimeUtils, EllipsisDropdown, ControlledPopup } from 'dyl-components';
import DropdownItem from './DropdownItem';
import ContactGroupPopup from './ContactGroupPopup';
import { Dropdown, Header, Popup, Checkbox } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import MoveSubgroupModal from 'shared/modals/MoveSubgroupModal';
import { BulkActionsContext } from "shared/context/BulkActionsProvider";

const GroupsSubrow = ({subgroup, parent, onUpdateSubgroup, isSubgroupDuplicated, onDeleteGroup, reloadSubgroups}) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);

    const ELLIPSIS_SUBOPTIONS = [
        {
            id: 1,
            text: 'Edit',
            icon: 'fa-solid fa-pencil',
            onClick: () => setIsPopupOpen(true)
        },
        {
            id: 2,
            text: 'Move subgroup',
            icon: 'fa-solid fa-arrow-right-from-line',
            onClick: () => setIsMoveModalOpen(true)
        },
        {
            id: 3,
            text: 'Delete',
            icon: 'fa-solid fa-trash',
            onClick: () => onDeleteGroup(subgroup.id)
        },
    ];

    const [selectedGroups, setSelectedGroups] = useContext(BulkActionsContext);
    
    const isSelected = (group_id) => {
        return selectedGroups.some((group) => group.group_id === group_id);
    }
    
    const onToggleGroup = (group) => {
        const {id: group_id, parent_label_id} = group;
        if (isSelected(group_id)) {
            if (isSelected(parent.id)) {
                const newSelectedGroups = selectedGroups.filter((group) => group.group_id !== group_id && group.group_id !== parent.id);
                setSelectedGroups(newSelectedGroups);
            } else {
                const newSelectedGroups = selectedGroups.filter((group) => group.group_id !== group_id);
                setSelectedGroups(newSelectedGroups);
            }
        } else {
            const newSelectedGroups = [...selectedGroups, {group_id, parent_label_id}];
            setSelectedGroups(newSelectedGroups);
        }
    }

    return (
        <>
            <Table.CollapsibleRowContent indented nested secondCol>
                <Table.Cell>
                    <div style={{position: 'relative'}}>
                        <Checkbox style={{position: 'absolute', left: -30}} checked={isSelected(subgroup.id)} onChange={() => { onToggleGroup(subgroup) }} />
                        <Link to={`/group/${subgroup.id}`}>{subgroup.name}</Link>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    {subgroup.contact_count}
                </Table.Cell>
                <Table.Cell>
                    {subgroup.user_id ? "Personal" : "Company"}
                </Table.Cell>
                <Table.Cell>
                    <Person username={`${subgroup.created_by.first_name} ${subgroup.created_by.last_name}`} email={subgroup.created_by.email} />
                </Table.Cell>
                <Table.Cell>
                    <div><b>{DateTimeUtils.formatEpoch(subgroup.created, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{DateTimeUtils.formatEpoch(subgroup.created, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
                <Table.Cell>
                    <div><b>{DateTimeUtils.formatEpoch(subgroup.activity, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{DateTimeUtils.formatEpoch(subgroup.activity, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
                <Table.Cell>
                    <Popup 
                        trigger={
                            <EllipsisDropdown {...isPopupOpen ? { isDropdownOpen: false } : {}}>
                                {ELLIPSIS_SUBOPTIONS.map(({ text, icon, disabled, onClick, ...otherProps }, idx) => {
                                    return idx === 2 ? (
                                        <ControlledPopup 
                                            header={
                                                <Header as='h4' textAlign='center'>
                                                    Are you sure?
                                                    <Header.Subheader style={{ marginTop: 5 }}>
                                                        You cannot undo delete actions.
                                                    </Header.Subheader>
                                                </Header>
                                            }
                                            trigger={<Dropdown.Item icon={icon} text={text} disabled={disabled} />}
                                            onConfirm={onClick}
                                            inverted
                                        />
                                    ) : (
                                        <DropdownItem
                                            key={idx}
                                            text={text}
                                            icon={icon}
                                            disabled={disabled}
                                            onClick={onClick}
                                            {...otherProps}
                                        />
                                    );
                                })}
                            </EllipsisDropdown>
                        }
                        content={
                            <ContactGroupPopup
                                handlePopupClose={() => setIsPopupOpen(false)}
                                onSave={(data) => {onUpdateSubgroup(data, subgroup.id, setIsPopupOpen)}}
                                isDuplicated={isSubgroupDuplicated}
                                group={subgroup}
                                isSubgroup
                            />
                        }
                        on='click'
                        position='bottom left'
                        open={isPopupOpen}
                        onClose={() => setIsPopupOpen(false)}
                    />
                </Table.Cell>
            </Table.CollapsibleRowContent>
            <MoveSubgroupModal open={isMoveModalOpen} onClose={() => setIsMoveModalOpen(false)} subgroup={subgroup} parent={parent} reloadSubgroups={reloadSubgroups} />
        </>
    )
}

export default GroupsSubrow