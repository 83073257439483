import React, { useEffect } from "react";
import PermissionsTable from "./subcomponents/PermissionsTable";
import { connect } from "react-redux";

import usersActions from "actions/users";
import assignActions from "actions/assign";
import rolesActions from "actions/roles";

import './index.scss';
import { useSearchParams } from "react-router-dom";
import { PermissionsHeader } from "./subcomponents/PermissionsHeader";

const UserPermissionSettings = ({
    onReadRoles,
    onReadUsers,
    onReadAccessRoleAssignments,
    permissionProfile,
    isReadingRoles
}) => {

    const [ query ] = useSearchParams();
    const permission_name = query.get('search') || '';
    const page = query.get('page')  || 1;
    const limit = 25;

    useEffect(() => {
        onReadRoles({ search:permission_name, page, limit });
        onReadUsers();
        onReadAccessRoleAssignments();
    }, [ onReadRoles, onReadAccessRoleAssignments, permission_name, page, limit, onReadUsers ]);

    return (
        <div className='Permissions__wrapper'>
            <PermissionsHeader
                className='Permissions__header'
                isReadingRoles={ isReadingRoles }
            />
            <PermissionsTable 
                className='Permissions__table'
                permissionProfile={ permissionProfile }
                isReadingRoles={ isReadingRoles }
                readRoles={ params => onReadRoles({ ...params })}
            />
        </div>
    )
};

const mapStateToProps = state => {
    return {
        permissionProfile: state.roles.roles.count !== 0 ? state.roles.roles.map(role => ({ ...role })) : [],
        isReadingRoles: state.roles.isReadingRoles || state.users.isReadingUsers || state.assign.isReadingAccessRoleAssignments,
    };
};

const mapDispatchToProps = dispatch => ({
    onReadRoles: (queryParameters) => {
        dispatch(rolesActions.readRoles(queryParameters));
    },
    onReadUsers: () => {
        dispatch(usersActions.readUsers());
    },
    onReadAccessRoleAssignments: () => {
        dispatch(assignActions.readAssignments());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPermissionSettings)
