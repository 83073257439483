const initialState = {
    current_stage: {},
    contact: null,
    contact_id: null,
    account_id: null,
    contact_labels: [],
    contact_info: [],
    contact_options: [],
    contactError: false,
    isReadingContactInfo: false,
    isUpdatingContactInfo: false,
    isReadingContactLabel: false,
    isDeletingContactLabel: false,
    isReadingContactOptions: false,
    isReadingContact: false,
    contactBeingUpdated: null,
    email: [],
    social_media: [],
    custom_data: {
        fields: [],
        children: []
    },
    queryParameters: {},
    history: [],
    history_count: 0,
    isReadingHistory: false,

    upcoming_history: [],
    upcoming_history_count: 0,
    latest_history: [],
    latest_history_count: 0,
    isReadingUpcomingHistory: false,
    isReadingLatestHistory: false,
    primary_contact: {},
    contactBeingDeleted: null
};

export default initialState;
