import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid } from 'semantic-ui-react';
import { SearchBar } from 'dyl-components';
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import AddUserReportModal from './AddUserReportModal';
import { useParams } from 'react-router-dom';
import CampaignOwnerDropdown from './CampaignOwnerDropdown';

const UsersToolbar = ({ search, onSearchSubmit, cancelFunction, onChangeSearch, isCampaignInactive }) => {
    const [selectedUsers, , areUsersInAllPagesSelected] = useContext(BulkActionsContext);
    const { id } = useParams();

    const { isReadingUsers } = useSelector(state => ({
        isReadingUsers: state.campaign_users.isReadingUsers,
        count: state.campaign_users.count
    }));

    const hasSelectedUsers = selectedUsers.length || areUsersInAllPagesSelected;

    const [, setIsBulkActionsModalOpen] = useState(false);
    const [isAddUserReportModalOpen, setAddUserReportModalOpen] = useState(false);

    const onOpenBulkActionsModal = () => {
        setIsBulkActionsModalOpen(true);
    }

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <CampaignOwnerDropdown />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal' verticalAlign='middle'>
                    <Grid.Column width={5}>
                        <div className='ModulesToolbar'>
                            <SearchBar
                                isSearching={isReadingUsers}
                                search={search}
                                searchFcn={onSearchSubmit}
                                cancelFcn={cancelFunction}
                                placeholder={'Search by User Name'}
                                onChange={onChangeSearch}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column floated='right'>
                        <Button floated='right' primary disabled={isCampaignInactive} onClick={() => { setAddUserReportModalOpen(true) }}>
                            + User
                        </Button>
                        <Button floated='right' primary disabled={!hasSelectedUsers || isCampaignInactive} onClick={onOpenBulkActionsModal}>
                            Bulk Action
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <AddUserReportModal
                campaignId={id}
                isModalOpen={isAddUserReportModalOpen}
                onModalClose={() => { setAddUserReportModalOpen(false) }}
            />
        </React.Fragment>

    )
}

export default UsersToolbar;
