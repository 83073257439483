// SHARED CONSTANTS
export const KEY_CREATED = "Created";
export const KEY_CREATED_BY = "Created By";
export const KEY_OWNER  = 'Owner';
export const KEY_OWNED_BY = "Owned By";
export const KEY_LAST_MODIFIED = "Last Modified";
export const KEY_LAST_MODIFIED_BY = 'Last Modified by';
export const KEY_CONVERT = 'Convert';
export const KEY_CONTACT_NAME = 'Contact Name';
export const KEY_ACTIONS = 'Actions';
export const KEY_ACCOUNT = "Account";
export const KEY_MASTER_SECONDARY = "Master/Secondary";
export const KEY_STAGE = "Stage";
export const KEY_CAMPAIGN_SOURCE = 'Campaign Converted';
export const KEY_LOST_REASON = "Lost Reason/Type";
export const KEY_CLOSE_PROBABILITY = 'Close Probability';
export const KEY_OUTCOME = "Outcome";

// LEAD EXCLUSIVE CONSTANTS
export const KEY_LEAD_TYPE = "Lead Type";
export const KEY_TOTAL_COST = "Total Cost";
export const KEY_ACQUISITION_COST = "Acquisition Cost";
export const KEY_PRODUCT_INTEREST = "Product Interests";
export const KEY_ADDITIONAL_COST = "Additional Costs";
export const KEY_LEAD_PRIORITY = "Lead Priority";
export const KEY_EXPECTED_REVENUE = "Expected Revenue";
export const KEY_LEAD_ID = "ID";
export const KEY_LEAD_SCORE = "Lead Score";
export const KEY_LEAD_RATING = "Lead Rating";

// OPPORTUNITY EXCLUSIVE CONSTANTS
export const KEY_OPPORTUNITY_ID = 'ID';
export const KEY_OPPORTUNITY_NAME = 'Opportunity Name';
export const KEY_SOURCE = 'Source';
export const KEY_DESCRIPTION = 'Description';
export const KEY_PAIN_NEED_NOTES = 'Pain/Need Notes';
export const KEY_SALES_STRATEGY_NOTES = 'Sales Strategy Notes';
export const KEY_CONFIDENCE_LEVEL = 'Confidence Level';
export const KEY_EXPECTED_CLOSE_DATE = 'Expected Close Date';
export const KEY_NEXT_STEP_DATE = 'Next Step Date';
export const KEY_NEXT_STEP = 'Next Step';
export const KEY_OPPORTUNITY_AGE = 'Opportunity Age';
export const KEY_SYNCED_QUOTE = 'Synced Quote';
export const KEY_ONE_TIME_AMOUNT = 'One-Time Amount';
export const KEY_REOCCURRING_AMOUNT = 'Reoccurring Amount';
export const KEY_TOTAL_AMOUNT = 'Total Amount';
export const KEY_DISCOUNT = 'Discount';
export const KEY_QUOTES = 'Quotes';
