import React from 'react';
import { Header } from 'semantic-ui-react';
import FavoriteIcon from 'shared/FavoriteIcon';

import './index.scss';

const HeaderComp = ({ details, title }) => (
    <Header className="HeaderCompHeader">
        {details && <FavoriteIcon details={details} />}
        <Header.Content>{title}</Header.Content>
    </Header>
);

export default HeaderComp;
