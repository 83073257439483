import React, { useState } from "react";
import {
    ButtonLink,
    generateResolver,
    Modal,
    Notification,
    STATUS_TYPES,
    VALIDATORS,
    yup,
} from "dyl-components";

import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Form, Icon, Popup } from "semantic-ui-react";

import FIELD_TYPE_OPTIONS from "shared/constants/FIELD_TYPE_OPTIONS";

import "./EditProductCategoriesModal.scss";

const EditProductCategoriesModal = ({
    open,
    onClose,

    field,
    refresh,

    onUpdate
}) => {
    const {
        formState: { isValid, isDirty },
        control,
        handleSubmit,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            field_type: field?.field_type || "",
            field_name: field?.field_name || "",
            label: field?.label || "",
            description: field?.description || "",
            options:
                field?.options?.data
                    ?.filter((option) => !option.deleted)
                    ?.map((option) => ({
                        id: option.id,
                        name: option.value,
                        description: option.description,
                        removable: option.removable
                    })) || [],
        },
        resolver: generateResolver({
            field_type: yup
                .string()
                .oneOf(FIELD_TYPE_OPTIONS.map(({ key }) => key)),
            label: yup.string().no_excessive_whitespaces().no_whitespace_only(),
            description: VALIDATORS.DESCRIPTION(),
            options: yup.array(
                yup.object({
                    name: VALIDATORS.SIMPLE_INPUT()
                        .noemoji()
                        .minlength(2)
                        .maxlength(100)
                        .no_whitespace_only()
                        .required("This field is required"),
                    description: VALIDATORS.DESCRIPTION(),
                })
            ),
        }),
    });

    const [isUpdating, setIsUpdating] = useState(false);

    const onConfirmEdit = async (data) => {
        try {
            setIsUpdating(true);
            await onUpdate(data);
            Notification.alert(
                "Successfully updated field!",
                STATUS_TYPES.SUCCESS
            );
            onClose();
            setIsUpdating(false);
            if (refresh) {
                refresh();
            }
        } catch (e) {
            setIsUpdating(false);
            console.log(e);
            Notification.alert("Failed to update field", STATUS_TYPES.ERROR);
        }
    };

    const {
        fields: options,
        remove,
        append,
    } = useFieldArray({
        control,
        name: "options",
    });

    console.log(options)

    return (
        <Modal open={open} onClose={onClose} className="EditFieldModal">
            <Controller
                name="field_type"
                control={control}
                render={({ field: { value: fieldTypeValue } }) => [
                    <Modal.Header>
                        Edit Field
                        <Popup
                            trigger={
                                <Icon
                                    className="fas fa-circle-info EditFieldModal__infoMessage"
                                    color="primary"
                                />
                            }
                            content={`Please note that saved changes will affect all existing values`}
                            inverted
                            position="left center"
                            wide
                        />
                    </Modal.Header>,
                    <Modal.Content scrolling>
                        <Form loading={isUpdating} noValidate>
                            <Form.Group widths={"equal"}>
                                <Form.Select
                                    options={FIELD_TYPE_OPTIONS}
                                    value={fieldTypeValue}
                                    label={"Field Type"}
                                    disabled
                                    required
                                />
                                <Controller
                                    name="label"
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Form.Input
                                            value={value}
                                            label={"Field Name"}
                                            disabled
                                            required
                                        />
                                    )}
                                />
                            </Form.Group>
                            <Controller
                                name="description"
                                control={control}
                                render={({
                                    field: { name, value, onChange },
                                }) => (
                                    <Form.Input
                                        label={"Description"}
                                        value={value}
                                        onChange={(_, { value }) => {
                                            onChange({
                                                target: { name, value },
                                            });
                                        }}
                                        disabled={field.standard}
                                    />
                                )}
                            />
                            {options.map((option, index) => (
                                <Form.Group key={option.id}>
                                    <Controller
                                        control={control}
                                        name={`options[${index}].name`}
                                        render={({
                                            field: { name, value, onChange },
                                            fieldState: { error },
                                        }) => (
                                            <Form.Input
                                                label="Category Name"
                                                required
                                                error={error?.message}
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    onChange({
                                                        target: { name, value },
                                                    });
                                                }}
                                                placeholder={
                                                    "Enter category name"
                                                }
                                                width={8}
                                            />
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name={`options[${index}].description`}
                                        render={({
                                            field: { name, value, onChange },
                                            fieldState: { error },
                                        }) => (
                                            <Form.Input
                                                label="Category Description"
                                                error={error?.message}
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    onChange({
                                                        target: { name, value },
                                                    });
                                                }}
                                                placeholder={
                                                    "Enter category description"
                                                }
                                                width={8}
                                            />
                                        )}
                                    />
                                    <Form.Field
                                        style={{ marginTop: "1em" }}
                                        control="div"
                                        width={1}
                                        label="Remove"
                                        className="Field--hidden-label"
                                    >
                                        <Icon
                                            link
                                            className="fas fa-trash"
                                            color="red"
                                            onClick={() => {
                                                remove(index);
                                            }}
                                            disabled={!option.removable}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            ))}
                            <ButtonLink
                                noPadding
                                onClick={() => {
                                    append({ name: "", description: "", removable: true });
                                }}
                            >
                                + Add additional category
                            </ButtonLink>
                        </Form>
                    </Modal.Content>,
                ]}
            />
            <Modal.Actions
                hasSaveButton
                onSave={handleSubmit(onConfirmEdit)}
                saveDisabled={
                    !isValid || !isDirty || isUpdating || options.length <= 0
                }
                saveOptions={{ loading: isUpdating }}
            ></Modal.Actions>
        </Modal>
    );
};

export default EditProductCategoriesModal;
