import React from 'react';
import { Button, STATUS_TYPES } from 'dyl-components';
import { Grid, Card } from 'semantic-ui-react';

const CredentialsArea = ({ user, onLogin }) => (
    <Grid columns={2} celled>
        <Grid.Row>
            <Grid.Column>
                Login Credentials for {user.name}
            </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} verticalAlign='middle'>
            <Grid.Column>
                <Card 
                    header='Credentials for DYL'
                    description={(
                        <React.Fragment>
                            <div><strong>Login:</strong> {user.login}</div>
                            <div><strong>Organization ID:</strong> {user.orgID}</div>
                        </React.Fragment>
                    )}
                />
            </Grid.Column>
            <Grid.Column>
                <Button status={STATUS_TYPES.PRIMARY} onClick={onLogin}>
                    Login
                </Button>
            </Grid.Column>
        </Grid.Row>
    </Grid>
)

class CredentialsAreaContainer extends React.Component {
    state = {
        user: {
            name: 'Tom Luck',
            login: 'dyl400',
            orgID: '8999188-24'
        }
    }

    render() {
        return (
            <CredentialsArea 
                {...this.props}
                {...this.state}
            />
        )
    }
}

export default CredentialsAreaContainer;
