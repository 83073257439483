import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function tagsReducer(state = initialState, action){
    switch(action.type){
        case getNamedAction(ACTION_NAMES.TAG_CATEGORY, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return {...state, isDeletingTagCategory: true};
        case getNamedAction(ACTION_NAMES.TAG_CATEGORY, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return {...state, isDeletingTagCategory: false};
        case getNamedAction(ACTION_NAMES.TAG_CATEGORY, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return {...state, isDeletingTagCategory: false};

        case getNamedAction(ACTION_NAMES.TAG_CATEGORY, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return {...state, updatingCategory: action.updatingCategory};
        case getNamedAction(ACTION_NAMES.TAG_CATEGORY, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return {...state, updatingCategory: null};
        case getNamedAction(ACTION_NAMES.TAG_CATEGORY, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return {...state, updatingCategory: null};

        default:
            return state;
    }
}


export default tagsReducer;
