import React, { useContext } from "react";
import { ButtonLink } from "dyl-components";
import { Checkbox, Popup } from "semantic-ui-react";
import { BulkActionsContext } from "shared/context/BulkActionsProvider";

export default function SelectAllCheckbox({
    count,
    data,
    type,
    idProperty
}) {
    const [
        selectedData,
        setSelectedData,
        areDataInAllPagesSelected,
        setAreDataInAllPagesSelected,
    ] = useContext(BulkActionsContext);

    const onToggleAreDataInAllPagesSelected = () => {
        setAreDataInAllPagesSelected(!areDataInAllPagesSelected);
    };

    const isSelected = (id) => {
        return selectedData.includes(id);
    };

    const onToggleAll = () => {
        const newSelectedData = data.some((record) =>
            isSelected(record[idProperty])
        )
            ? []
            : data.map((record) => record[idProperty]);
        setSelectedData(newSelectedData);
        setAreDataInAllPagesSelected(false);
    };

    const areAllSelected =
        selectedData.length > 0 && data.length > 0 &&
        data.every((record) => isSelected(record[idProperty]));
    const areDataPartiallySelected =
        selectedData.length > 0 && !areAllSelected;
    return (
        <Popup
            {...(areAllSelected ? { style: { zIndex: 9 } } : {})}
            position={"top left"}
            open={areAllSelected}
            trigger={
                <Checkbox
                    checked={areAllSelected}
                    onClick={onToggleAll}
                    indeterminate={areDataPartiallySelected}
                />
            }
            content={
                <div
                    style={{
                        width: areDataInAllPagesSelected ? "24em" : "34em",
                    }}
                >
                    All{" "}
                    {areDataInAllPagesSelected
                        ? `${count} in System selected.`
                        : `${data.length} ${type} on this page selected.`}{" "}
                    <ButtonLink
                        onClick={onToggleAreDataInAllPagesSelected}
                        noPadding
                    >
                        {areDataInAllPagesSelected
                            ? "Undo Selection."
                            : `Select ${count} ${type} on all Pages.`}
                    </ButtonLink>
                </div>
            }
        />
    );
}
