import React from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';

import './ActiveCallControls.scss';
import { Button } from 'dyl-components';

import callActions from 'actions/call';
import { connect } from 'react-redux';

const ActiveCallControls = ({ isHangingUp, channel_uuid, onHangup }) => (
    <React.Fragment>
        <Dropdown
            button
            trigger={
                <span>
                    <Icon name='play circle' /> VM Drop
                </span>
            }
            floating
            className='ActiveCallControls__vm-drop'
            options={[
                { key: 'Voicemail', text: 'Voicemail', value: 'Voicemail' },
                { key: 'Cell', text: 'Cell', value: 'Cell' },
            ]}
        />
        <Button
            color={'new-york-pink'}
            content={'Hangup'}
            icon='phone'
            loading={isHangingUp}
            onClick={() => {onHangup(channel_uuid)}}
        />
    </React.Fragment>
);

const mapStateToProps = state => ({
    isHangingUp: state.call.isHangingUp,
    channel_uuid: state.call.channel_uuid
})

const mapDispatchToProps = dispatch => ({
    onHangup: (channel_uuid) => {
        dispatch(callActions.hangup({
            channel_uuid
        }));
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(ActiveCallControls);
