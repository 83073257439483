import { FileInput, Notification, RichTextEditor, STATUS_TYPES, TEXT_EDITOR_MODES } from "dyl-components";
import { Dimmer, Form, Icon, Loader } from "semantic-ui-react";

import { useContext, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { TemplatesContext } from "./TemplatesContextProvider";
import TemplateSelector from "./TemplateSelector";

const EmailAction = ({
    index,
    id,
    control,
    onFocus
}) => {
    const editorRef = useRef();
    const dropzoneRef = useRef();
    const [mode, setMode] = useState(TEXT_EDITOR_MODES.RICH_TEXT);

    const { emailTemplateOptions, onSelectEmailTemplate } = useContext(TemplatesContext);
    const [isReading, setIsReading] = useState(false);

    return (
        <Controller
            control={control}
            name={`actions[${index}].data.template_category`}
            render={({ field: { name: templateCategoryName, value: templateCategoryValue, onChange: templateCategoryOnChange } }) => (
                <Controller
                    control={control}
                    name={`actions[${index}].data.template`}
                    render={({ field: { name: templateName, value: templateValue, onChange: templateOnChange } }) => (
                        <>
                            <Dimmer active={isReading}>
                                <Loader active={isReading} />
                            </Dimmer>
                            <Form.Group widths='equal'>
                                <Form.Field control={'div'}>
                                    <Controller
                                        control={control}
                                        name={`actions[${index}].data.body`}
                                        render={({ field: { name, onChange } }) => (
                                            <Controller
                                                control={control}
                                                name={`actions[${index}].data.subject`}
                                                render={({ field: { name: subjectName, onChange: subjectOnChange } }) => (
                                                    <Controller
                                                        control={control}
                                                        name={`actions[${index}].data.attachments`}
                                                        render={({ field: { name: attachmentsName, onChange: attachmentsOnChange } }) => (
                                                            <Form.Field
                                                                control={TemplateSelector}
                                                                label='Template'
                                                                categorized_templates={emailTemplateOptions}
                                                                parentValue={templateCategoryValue}
                                                                value={templateValue}
                                                                onChange={async (_, { parent_value, child_value: templateId }) => {
                                                                    templateCategoryOnChange({ target: { name: templateCategoryName, value: parent_value } })
                                                                    templateOnChange({ target: { name: templateName, value: templateId } });
                                                                    if (templateId) {
                                                                        try {
                                                                            setIsReading(true);
                                                                            const { attachments, content, subject } = await onSelectEmailTemplate(templateId);
                                                                            subjectOnChange({ target: { name: subjectName, value: subject } })
                                                                            onChange({ target: { name, value: content } });
                                                                            attachmentsOnChange({
                                                                                target: {
                                                                                    name: attachmentsName, value: (attachments?.data || []).map(attachment => ({
                                                                                        path: attachment.name,
                                                                                        id: attachment.id,
                                                                                        file_id: attachment.file_id,
                                                                                        name: attachment.name
                                                                                    }))
                                                                                }
                                                                            });
                                                                        } catch (e) {
                                                                            console.log(e);
                                                                        } finally {
                                                                            setIsReading(false);
                                                                        }
                                                                    } else {
                                                                        subjectOnChange({ target: { name: subjectName, value: '' } })
                                                                        onChange({ target: { name, value: '' } });
                                                                        attachmentsOnChange({
                                                                            target: {
                                                                                name: attachmentsName, value: []
                                                                            }
                                                                        });
                                                                    }
                                                                }}
                                                                parentName={templateCategoryName}
                                                                onFocus={onFocus}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name={`actions[${index}].data.subject`}
                                        render={({ field: { name, value, onChange } }) => (
                                            <Form.Input
                                                label='Subject and Body'
                                                placeholder='Type email subject'
                                                value={value}
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                style={{ marginBottom: '1em' }}
                                                disabled={templateValue}
                                            />
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name={`actions[${index}].data.body`}
                                        render={({ field: { name, value, onChange } }) => (
                                            <Form.Field
                                                control={RichTextEditor}
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                value={value}
                                                toolbarClassName={`email-action-body-toolbar-${id}`}
                                                allowSwitchToHTML
                                                onSwitchToHTML={() => { setMode(TEXT_EDITOR_MODES.HTML) }}
                                                onSwitchToRichText={() => { setMode(TEXT_EDITOR_MODES.RICH_TEXT) }}
                                                mode={mode}
                                                editorRef={editorRef}
                                                size='small'
                                                loading={isReading}
                                                disabled={templateValue}
                                            />
                                        )}
                                    />
                                </Form.Field>
                                <Controller
                                    control={control}
                                    name={`actions[${index}].data.attachments`}
                                    render={({ field: { name, value, onChange } }) => (
                                        <Form.Field
                                            control={'div'}
                                            disabled
                                        >
                                            <Form.Field
                                                label={<div>
                                                    <Icon onClick={() => { dropzoneRef.current.open(); }} color='blue' name='plus circle' link /> <b>Attach {value?.length > 0 && 'more '}files</b>
                                                </div>}
                                            />
                                            <div className='Task__attachments'>
                                                <FileInput
                                                    onChange={(_, { value: new_value }) => {
                                                        onChange({
                                                            target: {
                                                                name, value: [
                                                                    ...value.attachments,
                                                                    ...new_value.filter(new_file => (
                                                                        value.findIndex(file => file.path === new_file.path) === -1
                                                                    ))
                                                                ]
                                                            }
                                                        })
                                                    }}
                                                    onReject={(rejected) => {
                                                        if (rejected.length > 0) {
                                                            Notification.alert("File type must be .pdf.", STATUS_TYPES.ERROR, true);
                                                        }
                                                    }}
                                                    onRemove={(_, { value }) => {
                                                        onChange({ target: { name, value } })
                                                    }}
                                                    files={value}
                                                    name="files"
                                                    accept="application/pdf"
                                                    icon="file pdf outline"
                                                    size="mini"
                                                    dropzoneRef={dropzoneRef}
                                                    showIcon
                                                />
                                            </div>
                                        </Form.Field>
                                    )}
                                />
                            </Form.Group>
                        </>
                    )}
                />
            )}
        />
    )
}

export default EmailAction;
