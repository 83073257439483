import React from 'react';
import { Table, Notification, STATUS_TYPES } from 'dyl-components';
import { EditableContent } from 'dyl-components';
import { Input } from 'semantic-ui-react';
import MessagingIconButtons from 'shared/MessagingIconButtons';

const LeadSourceRow = ({ 
    leadSource, 
    isEditMode, 
    onChange, 
    onDelete,
    onEnableEditMode,
    onDisableEditMode,
    onUpdate
}) => (
    <Table.Row>
        <Table.Cell>
            {leadSource.name}
        </Table.Cell>
        <Table.Cell>
            {leadSource.leadsDestination === 'Automatic' ? <em>{leadSource.leadsDestination}</em> : leadSource.leadsDestination}
        </Table.Cell>
        <Table.Cell>
            <EditableContent 
                control={Input}
                value={isEditMode ? leadSource.cost : `$${Number(leadSource.cost).toFixed(2)}`}
                name='cost'
                onChange={onChange}
                isEditMode={isEditMode}
                label='$'
                type='number'
            />
        </Table.Cell>
        <Table.Cell>
           <MessagingIconButtons
                isEditMode={isEditMode}
                onDisableEditMode={onDisableEditMode} 
                onUpdate={onUpdate}
                onEnableEditMode={onEnableEditMode}
                onDelete={onDelete}
                id={leadSource.id} 
            />
        </Table.Cell>
    </Table.Row>
)

class LeadSourceRowContainer extends React.Component {
    state = {
        leadSource: this.props.leadSource,
        isEditMode: false
    }

    onEnableEditMode = () => {
        this.setState({ isEditMode: true });
    }

    onDisableEditMode = () => {
        this.setState({ isEditMode: false, leadSource: this.props.leadSource });
    }

    onChange = (_, { name, value }) => {
        let leadSource = { ...this.state.leadSource, [name]: value};
        this.setState({ leadSource });
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false
        }, () => {
            this.props.onUpdate(this.state.leadSource);
            Notification.alert('Successfully updated lead source!', STATUS_TYPES.SUCCESS, true);
        })
    }

    render() {
        return (
            <LeadSourceRow 
                {...this.props}
                {...this.state}
                onChange={this.onChange}
                onEnableEditMode={this.onEnableEditMode}
                onDisableEditMode={this.onDisableEditMode}
                onUpdate={this.onUpdate}
            />
        )
    }
}

export default LeadSourceRowContainer;
