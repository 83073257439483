import { DateInput, DateTimeUtils, LinkedAccount } from "dyl-components";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { Dropdown, Form } from "semantic-ui-react";
import TermField from "./TermField";

const PaymentInformation = () => {
    const { control } = useFormContext();
    const order = useSelector((state) => state.order.order);
    return (
        <>
            <Form.Group widths={3}>
                <Controller
                    control={control}
                    name="invoice_name"
                    render={({
                        field: { name, value, onChange },
                        fieldState: { error },
                    }) => (
                        <Form.Input
                            label="Invoice Name"
                            value={value}
                            name={name}
                            onChange={(_, { value }) => {
                                onChange({ target: { name, value } });
                            }}
                            error={error?.message}
                        />
                    )}
                />
            </Form.Group>
            <Form.Group widths={"equal"}>
                <Form.Field label="Customer Name" required control="div">
                    <Dropdown
                        placeholder="Select Customer"
                        selectOnBlur={false}
                        selection
                        options={[]}
                        value={null}
                        fluid
                        required
                        disabled
                        {...(order?.account
                            ? {
                                  text: (
                                      <LinkedAccount
                                          account={order?.account?.name}
                                          maxWidth={"8.25em"}
                                      />
                                  ),
                              }
                            : {})}
                    />
                </Form.Field>
                <Form.Field label="Date" control="div">
                    <DateInput
                        dateFormat={DateTimeUtils.WORD_DATE_FORMAT}
                        value={DateTimeUtils.getCurrentDate(
                            DateTimeUtils.WORD_DATE_FORMAT
                        )}
                        disabled
                    />
                </Form.Field>
                <Controller
                    control={control}
                    name="due_on"
                    render={({
                        field: { name, value, onChange },
                        fieldState: { error },
                    }) => (
                        <Form.Field
                            control={DateInput}
                            dateFormat={DateTimeUtils.WORD_DATE_FORMAT}
                            value={value}
                            label="Due On"
                            required
                            onChange={(_, { value }) => {
                                onChange({ target: { name, value } });
                            }}
                            error={error?.message}
                        />
                    )}
                />
            </Form.Group>
            <Form.Group widths={3}>
                <Controller
                    control={control}
                    name="term"
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Field
                            control={TermField}
                            label="Term"
                            required
                            value={value}
                            onChange={(_, { value }) => {
                                onChange({ target: { name, value } });
                            }}
                        />
                    )}
                />
            </Form.Group>
        </>
    );
};

export default PaymentInformation;
