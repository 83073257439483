import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
    Table,
    ClearableCheckboxDropdown,
    Icon,
    TimestampFilters,
} from "dyl-components";
import { Table as SemanticTable } from "semantic-ui-react";
import {
    getData,
    onToggleTimeUnit,
    useRangeCalendar,
} from "shared/CustomDateRange/customDateRange";
import { DataLogUtils } from 'utils';

const statusOptions = [
    { key: 0, value: "Accepted", text: "Accepted" },
    { key: 1, value: "Duplicate", text: "Duplicate" },
    { key: 2, value: "Partial", text: "Partially accepted" },
    { key: 3, value: "Rejected", text: "Rejected" },
];

const typeOptions = [
    { key: 0, value: "email", text: "Email" },
    { key: 1, value: "post", text: "Post" },
];

const errorOptions = DataLogUtils.getErrorTypeOptions();

export const ReportsAndLogsTableHeader = ({
    isReadingPosts,
    filters,
    onFilter,
    onSort,
    providerNames,
    recordNames,
    isReadingProviderRecordNames
}) => {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        isTimeRangeOpen,
        setTimeRangeOpen,
        userAuthTimezone,
        dateRange,
        setDateRange,
    } = useRangeCalendar(params);

    const [newestFirst, setNewestFirst] = useState(false);
    const handleSort = () => {
        setNewestFirst(!newestFirst);
        onSort(newestFirst ? "desc" : "asc");
    };

    const onDateRangeChange = (_, { value }) => {
        setDateRange(value);
    };

    return (
        <Table.Row>
            <SemanticTable.HeaderCell width={3}>
                <span style={{ marginRight: 5 }}>Timestamp</span>
                <TimestampFilters
                    timestamp={timestamp}
                    onToggleTimeUnit={(timestamp) =>
                        onToggleTimeUnit(
                            timestamp,
                            setTimestamp,
                            params,
                            navigate,
                            "/settings/data-integration-logs"
                        )
                    }
                    isCustomDateRangeOpen={isCustomDateRangeOpen}
                    onOpenCustomDateRange={() => {
                        setCustomDateRangeOpen(true);
                    }}
                    onCloseCustomDateRange={() => {
                        setCustomDateRangeOpen(false);
                    }}
                    onOpenTimeUnitOptions={() => {
                        setTimeRangeOpen(true);
                    }}
                    onCloseTimeUnitOptions={() => {
                        setTimeRangeOpen(false);
                    }}
                    areTimeUnitOptionsOpen={isTimeRangeOpen}
                    dateRange={dateRange}
                    onDateRangeChange={onDateRangeChange}
                    getData={() =>
                        getData({
                            setCustomDateRangeOpen,
                            dateRange,
                            userAuthTimezone,
                            params,
                            navigate,
                            url: "/settings/data-integration-logs",
                        })
                    }
                />
                <div
                    onClick={handleSort}
                    style={{ display: "inline-flex", cursor: "pointer" }}
                >
                    <Icon
                        name="fa-solid fa-arrow-down-long"
                        style={{
                            marginRight: -7,
                            opacity: !newestFirst ? "100%" : "50%",
                        }}
                    />
                    <Icon
                        name="fa-solid fa-arrow-up-long"
                        style={{ opacity: newestFirst ? "100%" : "50%" }}
                    />
                </div>
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={2}>
                <ClearableCheckboxDropdown
                    text="Type"
                    name="type"
                    onChange={(_, { value }) => {
                        onFilter(_, { name: "type", value }, "method");
                    }}
                    value={filters.type}
                    options={typeOptions}
                    disabled={isReadingPosts}
                    loading={isReadingPosts}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={2}>
                <ClearableCheckboxDropdown
                    text="Provider Name"
                    name="providerName"
                    onChange={(_, { value }) => {
                        onFilter(_,{ name: "providerName", value },"provider_id");
                    }}
                    value={filters.providerName}
                    options={providerNames}
                    disabled={isReadingProviderRecordNames}
                    loading={isReadingProviderRecordNames}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={2}>
                <ClearableCheckboxDropdown
                    text="Record Name"
                    name="recordName"
                    onChange={(_, { value }) => {
                        onFilter(_, { name: "recordName", value }, "record_id");
                    }}
                    value={filters.recordName}
                    options={recordNames}
                    disabled={isReadingProviderRecordNames}
                    loading={isReadingProviderRecordNames}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={3}>
                Data Details
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={2}>
                <ClearableCheckboxDropdown
                    text="Error Type"
                    name="errorType"
                    onChange={(_, { value }) => {
                        onFilter(
                            _,
                            { name: "error_type", value },
                            "error_code"
                        );
                    }}
                    value={filters.error_type}
                    options={errorOptions}
                    disabled={isReadingPosts}
                    loading={isReadingPosts}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={2}>
                <ClearableCheckboxDropdown
                    text="Status"
                    name="status"
                    onChange={(_, { value }) => {
                        onFilter(_, { name: "status", value }, "status");
                    }}
                    value={filters.status}
                    options={statusOptions}
                    disabled={isReadingPosts}
                    loading={isReadingPosts}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={1}>
                Actions
            </SemanticTable.HeaderCell>
        </Table.Row>
    );
};
