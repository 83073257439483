const initialState = {
    users: [],
    searchUsers: {
        result: [],
        count: 0,
        queryParameters: {}
    },
    userOptions: [],
    bulkUsernames: [],
    bulkUsernamesQueryParameters: {},
    userOptionsQueryParameters: {
        page: 1,
        limit: 25,
    },
    userOptionsCount: 0,
    userProfile: {
        company: {
            industry: '',
            name: ''
        },
        location: {},
        schedule: [],
        social_media: [],
        teams: []
    },
    isReadingUsers: false,
    isSearchingUsers: false,
    isReadingUser: false,
    isReadingBulkUsernames: false,
    userBeingDeleted: null,
    isUpdatingUser: false,
    isCreatingUser: false,

    queryParameters: {
        page: 1,
        limit: 25
    },
    user_id: 0,
    count: 0
}

export default initialState;
