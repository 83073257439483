import { generateCreateActionCreator, generateReadActionCreator, generateUpdateActionCreator, generateDeleteActionCreator } from '../crudActionCreators';
import { Notification, STATUS_TYPES } from 'dyl-components';
import routes from '../routes';
import ACTION_NAMES from 'actions/ACTION_NAMES';

export default {
    createPipelineCategory: generateCreateActionCreator(routes.API_PIPELINE_CATEGORY, ACTION_NAMES.PIPELINE_CATEGORY),
    getPipelineCategories: generateReadActionCreator(routes.API_PIPELINE_CATEGORY, ACTION_NAMES.PIPELINE_CATEGORY),
    updatePipelineCategory: generateUpdateActionCreator(routes.API_PIPELINE_CATEGORY, ACTION_NAMES.PIPELINE_CATEGORY),
    primaryPipelineCategory: generateUpdateActionCreator(routes.API_PIPELINE_CATEGORY, ACTION_NAMES.PIPELINE_CATEGORY, () => {}, () => {}, 'primary'),
    deletePipelineCategory: generateDeleteActionCreator(routes.API_PIPELINE_CATEGORY, ACTION_NAMES.PIPELINE_CATEGORY),

    createStage: generateCreateActionCreator(routes.API_PIPELINE_CATEGORY_STAGE, ACTION_NAMES.PIPELINE_STAGE, () => {}, () => {}, 'stage'),
    updateStage: generateUpdateActionCreator(routes.API_PIPELINE_STAGE, ACTION_NAMES.PIPELINE_STAGE, () => {}, () => {}),
    deleteStage: generateDeleteActionCreator(routes.API_PIPELINE_STAGE, ACTION_NAMES.PIPELINE_STAGE, () => {}, () => {}),
    
    convertContact: generateCreateActionCreator(routes.API_PIPELINE_CONVERT_CONTACT, ACTION_NAMES.PIPELINE_CONVERT_CONTACT),
    transition: generateUpdateActionCreator(routes.API_ACCOUNT_TRANSITION, ACTION_NAMES.PIPELINE_TRANSITION),
    convertToCustomer: generateCreateActionCreator(routes.API_CONVERT_TO_CUSTOMER, ACTION_NAMES.CONVERT_TO_CUSTOMER, () => {
        Notification.alert('Successfully converted to customer!', STATUS_TYPES.SUCCESS, true);
    }, () => {
        Notification.alert('Failed to convert to customer', STATUS_TYPES.ERROR, true);
    }),
    createTrialAccount: generateCreateActionCreator(routes.API_PIPELINE_TRIAL, ACTION_NAMES.PIPELINE_TRIAL),
    resetPassword: generateUpdateActionCreator(routes.API_AUTH_PASSWORD, ACTION_NAMES.AUTH_PASSWORD),

    closeCustomerPipeline: generateUpdateActionCreator(routes.API_CLOSE_CUSTOMER_PIPELINE, ACTION_NAMES.CLOSE_CUSTOMER_PIPELINE, () => {}, () => {}, "pipeline"),

    endTrial: generateCreateActionCreator(routes.API_CUSTOMER_PIPELINE, ACTION_NAMES.PIPELINE_END_TRIAL, () => {}, () => {}, 'end_trial'),
    setModuleAsLost: generateUpdateActionCreator(routes.API_CUSTOMER_PIPELINE, ACTION_NAMES.PIPELINE_ACCOUNT_LOST, () => {}, () => {}, 'lost'),
    readCloseReasons: generateReadActionCreator(routes.API_CUSTOMER_CLOSE_REASONS, ACTION_NAMES.CLOSE_REASONS),
    patchCloseReasons: generateUpdateActionCreator(routes.API_CUSTOMER_CLOSE_REASONS, ACTION_NAMES.CLOSE_REASONS, () => {}, () => {}, null, true),
    reactivateTrial: generateCreateActionCreator(routes.API_PIPELINE_REACTIVATE_TRIAL, ACTION_NAMES.REACTIVATE_TRIAL),

    changeModulePipeline: generateUpdateActionCreator(routes.API_PIPELINE, ACTION_NAMES.CHANGE_MODULE_PIPELINE)
}
