import React from "react";
import { Table } from "dyl-components";
import { Table as SemanticTable } from "semantic-ui-react";
import SelectAllCheckbox from "shared/SelectAllCheckbox";

const Header = ({ count, callRoutingRules }) => {
    return (
        <Table.Row>
            <SemanticTable.HeaderCell
                width={1}
            >
                <SelectAllCheckbox 
                    count={count}
                    data={callRoutingRules || []}
                    type={"Call Routing Rules"}
                    idProperty={"call_routing_rule_id"}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={3}
            >
                Routing Rule Name
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={3}
            >
                Phone Number
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                Holiday Schedule
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={3}
            >
                After Hours
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell 
                width={1}
            >
                Actions
            </SemanticTable.HeaderCell>
        </Table.Row>
    );
};

export default Header;
