import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { ButtonLink, MiniCalendar } from 'dyl-components';
import { Header, Icon, Dropdown, Button, Popup } from 'semantic-ui-react';
import oauthActions from 'actions/oauth';
import OptionDrawer from './optionDrawer';

import './index.scss'

const LeftPanel = ({ miniDate, handleMiniChange, options, allOptions, openAddActivityModal, addActivityAction, onChangeAllOptions, onChangeOptions, user_id }) => {
    const navigate = useNavigate();
    const [hasIntegrations, setHasIntegrations] = useState(false);
    const [mainIntegration, setMainIntegration] = useState({name:'',email:''})
    const dispatch = useDispatch();
    
    const { integrations = [] } = useSelector(state => state.oauth_integrations);

    useEffect(() => {
        dispatch(oauthActions.getIntegrations({ scopes: 'calendar', include_disabled: false }));
    }, [dispatch]);

    useEffect(()=>{
        setHasIntegrations(integrations.length > 0);
        setMainIntegration({name:'',email:''});
        integrations.forEach(({scopes, name, email}) => {
            scopes.forEach(({main,scope_type}) => {
                if (main === true && scope_type === 'calendar') {
                    setMainIntegration({name, email});
                }
            })
        })
    }, [integrations]);

    const goToIntegrations = () => {
        const integrationURI = `/settings/users/${user_id}/integration`;
        navigate(integrationURI);
    }

    return (
        <>
            <div className="LeftPanel__Container">
                <div className="LeftPanel__MiniContainer">
                    <MiniCalendar value={miniDate} onChange={handleMiniChange} markToday>
                        <Dropdown
                            as={Button}
                            floating
                            options={[
                                { key: 0, value: 'task', text: 'Task', icon: 'fa-solid fa-clipboard-check' },
                                { key: 1, value: 'event', text: 'Event', icon: 'fa-solid fa-calendar-day' },
                            ]}
                            onChange={(_, { value }) => { openAddActivityModal(value) }}
                            color='primary'
                            text={<span><Icon name='plus' size='tiny' /> New </span>}
                            value={addActivityAction}
                            selectOnBlur={false}
                            className="AddActivityButton"
                            size="small"
                        />
                    </MiniCalendar>
                </div>
                <div className="LeftPanel__BottomContainer">
                    <div className="MyCalendar">
                        <div className="MyCalendar__Container">
                            <Icon name="calendar check" size="large" />
                            <Header as={'h4'} className="LeftPanel__Header">My Calendar</Header>
                        </div>
                        <div className="MyCalendar__Integration">
                            <Popup
                                trigger={
                                    <ButtonLink onClick={goToIntegrations}>
                                        {hasIntegrations? 'Update integration' : 'Set up integration'}
                                    </ButtonLink>
                                }
                                position='bottom center'
                                basic
                                flowing
                                hoverable
                            >
                            <div>
                                <Header as="h4">{mainIntegration.name}</Header>
                                {mainIntegration.email}
                            </div>
                            </Popup>
                        </div>
                    </div>
                    <div>
                        <OptionDrawer options={options} allOptions={allOptions} onChangeAllOptions={onChangeAllOptions} onChangeOptions={onChangeOptions} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default LeftPanel;
