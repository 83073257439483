import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function calendarReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CALENDAR, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCalendar: true };
        case getNamedAction(ACTION_NAMES.CALENDAR, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {
                ...state,
                isReadingCalendar: false,
                calendar: action.data,
                queryParameters: action.queryParameters
            };
        case getNamedAction(ACTION_NAMES.CALENDAR, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCalendar: false };

        default:
            return state;
    }
}


export default calendarReducer;
