import React, { useEffect, useState } from 'react';
import { ButtonLink, STATUS_TYPES, Notification } from 'dyl-components';
import { Popup, Segment, Header, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import moduleInformationActions from 'actions/module_information';
import productInterestActions from "actions/product_interest";
import ProductInterestsForm from './subcomponent/Form';

import './index.scss';
import onReadContactInformationDispatch from 'shared/onReadContactInfoDispatch';
import { useParams } from 'react-router-dom';

const ProductInterestsSection = () => {
    const { contact_id } = useParams();

    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');

    useEffect(() => {
        dispatch(productInterestActions.readProductInterestOptions());
    }, [dispatch]);

    const {
        current_stage,
        productInterestOptions,
        isUpdatingPipeline,
        isReadingProductInterestOptions
    } = useSelector((state) => {
        return {
            current_stage: state.contact.current_stage,
            productInterestOptions: state.product_interests.productInterestOptions,
            productInterestOptionCount: state.product_interests.productInterestOptionCount,
            isUpdatingPipeline: state.module_information.pipelineBeingUpdated,
            isReadingProductInterestOptions: state.product_interests.isReadingProductInterestOptions
        }
    });

    const { control, handleSubmit, formState: { isDirty, isValid }, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            product_interests: current_stage.product_interest,
        }
    });

    const onCancel = () => {
        reset();
        setSearch("");
        setIsOpen(false)
    }

    const onSearchChange = (e) => {
        setSearch(e.target.value);
    }

    const onSave = async (data) => {
        const { product_interests } = data;

        try {
            await dispatch(moduleInformationActions.update(current_stage.pipeline_id, {
                product_interest_ids: product_interests.map(product_interest => (product_interest.value || product_interest.id))
            }));

            setIsOpen(false);
            reset();
            setSearch("");
            onReadContactInformationDispatch(dispatch)(contact_id)
            Notification.alert('Successfully updated product interests', STATUS_TYPES.SUCCESS);
        } catch (e) {
            console.log(e);
            Notification.alert(`Failed to update lead`, STATUS_TYPES.ERROR);
        }
    }

    return (
        <Popup
            trigger={
                <Segment className='ProductInterestsSection__Trigger'>
                    <div className='FlexContainer'>
                        <Header className='FlexContainer__Header' as='h4'>Product Interests</Header>
                        <ButtonLink>{`${current_stage.product_interest.length} Interest${current_stage.product_interest.length !== 1 ? 's' : ''}`}</ButtonLink>
                    </div>
                </Segment>
            }
            className="ProductInterestsSection__Content"
            on='click'
            content={
                <Form loading={isUpdatingPipeline}>
                    <Header as='h4'>
                        Add/Edit Product Interests
                    </Header>
                    <ProductInterestsForm
                        control={control}
                        handleSubmit={handleSubmit}
                        isDirty={isDirty}
                        isValid={isValid}
                        onSave={onSave}
                        productInterestOptions={productInterestOptions}
                        onCancel={onCancel}
                        search={search}
                        onSearchChange={onSearchChange}
                        setSearch={setSearch}
                        isReadingProductInterestOptions={isReadingProductInterestOptions}
                    />
                </Form>
            }
            position="bottom center"
            flowing
            basic
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
        />
    )
};

export default ProductInterestsSection;
