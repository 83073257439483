import React from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { Modal, DateInput, TimeInput, DateTimeUtils } from 'dyl-components';

const ScheduleLaterModal = ({ date, time, open, onClose, onChange }) => (
    <Modal open={open} onClose={onClose} size='tiny' animated>
        <Modal.Header>
            <span>
                {`Pick date & time`}
            </span>
        </Modal.Header>
        <Modal.Content>
            <Form autoComplete='off'>

                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <DateInput
                                inline
                                value={date}
                                name='date'
                                onChange={onChange}
                                minDate={DateTimeUtils.getTime().moment}
                                marked={DateTimeUtils.getTime().moment}
                                dateFormat={DateTimeUtils.DATE_FORMAT}
                                markColor='blue'
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <TimeInput
                                popupPosition='right center'
                                value={time}
                                name='time'
                                placeholder="Time"
                                onChange={onChange}
                                timeFormat={'ampm'}
                            />
                        </Grid.Column>
                
                    </Grid.Row>
                </Grid>
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={onClose} basic>Cancel</Button>
            <Button primary>Schedule Send</Button>
        </Modal.Actions>
    </Modal>
);

class ScheduleLaterModalContainer extends React.Component {
    state = {
        date: '',
        time: ''
    }

    onChange = (_, { name, value }) => {
        this.setState({
            [name]: value
        })
    }

    render() {
        return (
            <ScheduleLaterModal {...this.props} date={this.state.date} time={this.state.time} onChange={this.onChange} />
        )
    }
}

export default ScheduleLaterModalContainer;
