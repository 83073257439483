import contactActions from 'actions/contact';
import oauthActions from 'actions/oauth';
import React from 'react';
import { connect } from 'react-redux';
import { Grid, Segment } from 'semantic-ui-react';
import { DateTimeUtils } from 'dyl-components';
import Latest from './Latest';
import Upcoming from './Upcoming';

const Overview = () => (
    <Grid stackable>
        <Grid.Row columns={2}>
            <Grid.Column>
                <Segment className='TabSegment'>
                    <Upcoming />
                </Segment>
            </Grid.Column>
            <Grid.Column>
                <Segment className='TabSegment'>
                    <Latest />
                </Segment>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

class OverviewContainer extends React.Component {
    componentDidMount() {

        return this.props.onReadIntegrations().then(() => {
            const { id } = this.props.activeIntegration;
            const { contactEmails } = this.props;
            this.props.onReadOverviewTabData(this.props.contact_id, this.props.account_id, id, contactEmails.length > 0 ? contactEmails.join() : '');
        })

    }
    render() {
        return (
            <Overview />
        )
    }
}
const mapStateToProps = state => {

    const contactEmails = state.contact.email ? state.contact.email.slice(0).map(({ email }) => email) : [];

    return ({
        contact_id: Number(state.contact.contact_id),
        account_id: Number(state.contact.account_id),
        activeIntegration: state.email.activeIntegration,
        contactEmails,
    })
}

const mapDispatchToProps = dispatch => ({
    onReadOverviewTabData: (contact_id, account_id, integration_id, from) => {
        const end = DateTimeUtils.getNext(6, 'month');
        dispatch(contactActions.readLatestHistory(contact_id, { account_id, start: DateTimeUtils.getLast(2, 'week'), end, integration_id, from }));
        dispatch(contactActions.readUpcomingHistory(contact_id, { account_id, start: DateTimeUtils.getCurrentDate(null, true, false), end }));
    },
    onReadIntegrations: () => {
        return dispatch(oauthActions.getIntegrations({scopes: 'email'}));
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(OverviewContainer);
