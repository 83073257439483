import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RecordsTable } from 'dyl-components';

import TableHeader from './TableHeader';
import TableRow from './TableRow';

const Table = ({ onPageChange }) => {
    const { count, addons, isReadingAddons } = useSelector(state => ({
        count: state.product_addons.count,
        addons: state.product_addons.addons,
        isReadingAddons: state.product_addons.isReadingAddons
    }))

    const [params] = useSearchParams();

    return (
        <RecordsTable
            tableBodyClassName='AddonsTable'
            isTableLoading={isReadingAddons}
            isSearchResultEmpty={false}
            isTableEmpty={count === 0}
            TableHeader={<TableHeader />}
            TableRows={addons.map(addon => (
                <TableRow key={addon.id} addon={addon} />
            ))}
            recordsCount={count}
            activePage={params.get('page')}
            onPageChange={onPageChange}
            recordsName='Add-On'
            emptySubtitle={(
                <span>
                    Add an Add-on by clicking on the &nbsp;<Link to='/settings/product-catalog-quotes-invoices/addons/create'>+ New Add-Ons</Link>&nbsp; button on the top-right corner.
                </span>
            )}
        />
    );
}

export default Table;
