import React from 'react';
import { Popup } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { ConfirmationPrompt, Notification, STATUS_TYPES } from 'dyl-components';

import campaignsActions from 'actions/campaigns';

const RemoveFromCampaignPopup = ({ isOpen, actionPopupRef, campaignID, onFormClose, refresh, contact_id }) => {
    const dispatch = useDispatch();

    return (
        <Popup
            open={isOpen}
            position='bottom center'
            context={actionPopupRef}
            onClose={() => onFormClose()}
            content={
                <ConfirmationPrompt
                    subheader='This will remove the contact from a campaign'
                    onConfirm={async () => {
                        try {
                            await dispatch(campaignsActions.campaignContactsBulkAction("", { payload: [contact_id] }, { action: 'remove_from_campaign', campaign_id: campaignID }));
                            Notification.alert('Successfully removed contact from campaign!', STATUS_TYPES.SUCCESS);
                            onFormClose();
                            refresh();
                        } catch (e) {
                            console.log(e);
                            Notification.alert('Failed to remove contact from campaign', STATUS_TYPES.ERROR)
                        }
                    }}
                    onCancel={() => { onFormClose() }}
                />
            }
            hoverable
        />
    );
}

export default RemoveFromCampaignPopup;
