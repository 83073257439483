import React from 'react';
import { TableWithHeader } from 'dyl-components';
import { List, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import './index.scss';
import { connect } from 'react-redux';

const Reports = ({ onAddRecents }) => (
    <React.Fragment>
        <TableWithHeader
            header='Phone System'
            table={
                <Segment>
                    <List className="ReportsList" size='large'>
                        <List.Item>
                            <List.Header as={Link} to={'/reports/pbx-log'} onClick={() => onAddRecents('/reports/pbx-log', 'Call History')}>Call History</List.Header>
                            <List.Content>View a history of individual calls</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Header as={Link} to={'/reports/pbx-vm'} onClick={() => onAddRecents('/reports/pbx-vm', 'Voicemail History')}>Voicemail History</List.Header>
                            <List.Content>View a history of voicemails received</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Header as={Link} to={'/reports/fax-log'} onClick={() => onAddRecents('/reports/fax-log', 'Fax History')}>Fax History</List.Header>
                            <List.Content>View a history of inbound and outbound fax</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Header as={Link} to={'/reports/pbx-log'} onClick={() => onAddRecents('/reports/pbx-log', 'Advanced IVR')}>Advanced IVR</List.Header>
                            <List.Content>View a history of IVR calls</List.Content>
                        </List.Item>
                    </List>
                </Segment>
            }
        />
        <TableWithHeader
            header='Activity Logs'
            table={
                <Segment>
                    <List className="ReportsList" size='large'>
                        <List.Item>
                            <List.Header as={Link} to={'/reports/sms-report'} onClick={() => onAddRecents('/reports/sms-report', 'Text Log')}>Text Log</List.Header>
                            <List.Content>View Most Recent Text conversations</List.Content>
                        </List.Item>
                    </List>
                </Segment>
            }
        />
    </React.Fragment>
);

class ReportsContainer extends React.Component {

    onAddRecents = (url, name) => {
        const recent = [{
            activity_type: 'visit',
            custom_data: {
                name: `Report: ${name}`,
                type: 'report'
            },
            url
        }]
        this.props.onAddRecents(recent);
    }


    render() {
        return (
            <Reports onAddRecents={this.onAddRecents} />
        )
    }
}


const mapDispatchToProps = dispatch => ({
    onAddRecents: (recent) => {

    }
})

export default connect(null, mapDispatchToProps)(ReportsContainer);
