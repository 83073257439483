import React from 'react';
import { Checkbox, Form, Icon, Radio } from 'semantic-ui-react';
import {  PhoneUtil, StringUtils } from 'utils';
import { DateInput, DatesRangeInput, TimeInput, DateTimeUtils } from 'dyl-components';
import UsersField from './UsersField';
import { STATES } from 'shared/constants/STATES';

const DynamicField = ({ onChange, name, label, value, loading, inline, styled, type = 'text', size = 'mini', options = [], minDate = DateTimeUtils.getCurrentDate() }) => {
    switch (type) {
        case 'radio':
            return <div>
                <label>{label}</label>
                {options.map(option => (
                    <Radio
                        name={name}
                        onChange={onChange}
                        value={option.value}
                        checked={value === option.value}
                        label={option.text}
                        size={size}
                        style={{ marginRight: '1em' }}
                    />
                ))}
                {loading && <Icon name='circle notched' loading />}
            </div>
        case 'currency':
            return <Form.Input
                label={label}
                name={name}
                type='number'
                step="0.01"
                error={value && !StringUtils.isValidCurrency(value) ? {
                    content: 'Please enter a number with 2 decimal places (max)',
                    size: 'tiny',
                    pointing: 'above'
                } : false}
                value={value}
                onChange={onChange}
                icon='dollar sign'
                min={0}
                iconPosition='left'
                size={size}
                {...(inline ? { style: { display: 'inline' } } : {})}
                loading={loading}
            />
        case 'date':
            return <DateInput
                minDate={minDate}
                name={name}
                onChange={onChange}
                label={label}
                dateFormat={DateTimeUtils.DATE_FORMAT}
                value={value}
                size={size}
                loading={loading}
                {...(inline ? { style: { display: 'inline' } } : {})}
            />
        case 'daterange':
            return <DatesRangeInput
                minDate={minDate}
                name={name}
                onChange={onChange}
                label={label}
                dateFormat={DateTimeUtils.DATE_FORMAT}
                value={value}
                size={size}
                loading={loading}
                {...(inline ? { style: { display: 'inline' } } : {})}
            />
        case 'time':
            return <TimeInput
                name={name}
                onChange={onChange}
                timeFormat='AMPM'
                value={value}
                label={label}
                size={size}
                loading={loading}
                {...(inline ? { style: { display: 'inline' } } : {})}
            />
        case 'timerange':
            return <React.Fragment>
                <TimeInput
                    name={name}
                    onChange={((e, data) => {
                        const [, to] = value ? value.split('-') : ' - '.split('-');
                        const from = data.value;
                        onChange(e, { name, value: `${from}-${to}` });
                    })}
                    timeFormat='AMPM'
                    value={value ? value.split('-')[0] : ''}
                    placeholder='From'
                    size={size}
                    loading={loading}
                    {...(inline ? { style: { display: 'inline' } } : {})}
                /> - <TimeInput
                    name={name}
                    onChange={((e, data) => {
                        const [from] = value ? value.split('-') : ' - '.split('-');
                        const to = data.value;
                        onChange(e, { name, value: `${from}-${to}` });
                    })}
                    timeFormat='AMPM'
                    value={value ? value.split('-')[1] || '' : ''}
                    placeholder='To'
                    size={size}
                    loading={loading}
                    {...(inline ? { style: { display: 'inline' } } : {})}
                />
            </React.Fragment>
        case 'checkbox':
            return <React.Fragment>
                <Form.Field>
                    <label>{label}</label>
                    <Checkbox
                        name={name}
                        onChange={(e, { name, checked }) => { onChange(e, { name, value: checked }) }}
                        checked={value}
                        size={size}
                        toggle
                        loading={loading}
                        {...(inline ? { style: { display: 'inline' } } : {})}
                    /> {loading && <Icon name='circle notched' loading />}
                </Form.Field>
            </React.Fragment>
        case 'number':
            return <Form.Input
                label={label}
                name={name}
                type='number'
                value={value}
                onChange={onChange}
                min={0}
                size={size}
                {...(inline ? { style: { display: 'inline' } } : {})}
                loading={loading}
            />
        case 'phone':
            return <Form.Input
                label={label}
                name={name}
                value={value}
                onChange={onChange}
                error={value && !PhoneUtil.isValid(value) ? {
                    content: 'Please enter valid phone number',
                    size: 'tiny',
                    pointing: 'above'
                } : false}
                size={size}
                {...(inline ? { style: { display: 'inline' } } : {})}
                loading={loading}
            />
        case 'email':
            return <Form.Input
                label={label}
                name={name}
                value={value}
                onChange={onChange}
                type='email'
                size={size}
                {...(inline ? { style: { display: 'inline' } } : {})}
                loading={loading}
            />
        case 'textarea':
            return <Form size={size}>
                <Form.TextArea
                    label={label}
                    name={name}
                    value={value}
                    onChange={onChange}
                    {...(inline ? { style: { display: 'inline' } } : {})}
                    loading={loading}
                />
            </Form>
        case 'text':
            return <Form.Input
                label={label}
                name={name}
                value={value}
                onChange={onChange}
                size={size}
                {...(inline ? { style: { display: 'inline' } } : {})}
                loading={loading}
            />
        case 'dropdown':
        case 'picklist':
        case 'tagsinput':
            const DropdownComponent = styled ? Form.Select : Form.Dropdown;
            return <DropdownComponent
                label={label}
                name={name}
                value={value}
                onChange={onChange}
                options={options}
                multiple={type === 'picklist' || type === 'tagsinput'}
                loading={loading}
                size={size}
                {...(inline ? { style: { display: 'inline' } } : {})}
                {...(type === 'tagsinput' ? { renderLabel: (label) => ({
                    icon: 'tag',
                    content: label.text
                }) } : {})}
            />
        case 'fixed':
            return <Form.Input
                disabled
                value={value}
                size={size}
                {...(inline ? { style: { display: 'inline' } } : {})}
                loading={loading}
            />;
        case 'user':
            return <UsersField
                name={name}
                value={value}
                onChange={onChange}
                loading={loading}
                inline={inline}
                label={label}
                styled={styled}
            />
        case 'address':
            return <React.Fragment>
                <Form.Input
                    size='tiny'
                    style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
                    placeholder='Street #'
                    onChange={((e, data) => {
                        const [, street, city, state, zip] = value ? value.split('*') : Array(5).fill('');
                        const additional_street = data.value.replaceAll('*', '');
                        onChange(e, { name, value: `${additional_street}*${street}*${city}*${state}*${zip}` });
                    })}
                    fluid
                    name={name}
                    value={value ? value.split('*')[0] : ''}
                />
                <Form.Input
                    size='tiny'
                    style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
                    placeholder='Street'
                    onChange={((e, data) => {
                        const [additional_street, , city, state, zip] = value ? value.split('*') : Array(5).fill('');
                        const street = data.value.replaceAll('*', '');
                        onChange(e, { name, value: `${additional_street}*${street}*${city}*${state}*${zip}` });
                    })}
                    fluid
                    name={name}
                    value={value ? value.split('*')[1] : ''}
                />
                <Form.Input
                    size='tiny'
                    style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
                    placeholder='City'
                    onChange={((e, data) => {
                        const [additional_street, street, , state, zip] = value ? value.split('*') : Array(5).fill('');
                        const city = data.value.replaceAll('*', '');
                        onChange(e, { name, value: `${additional_street}*${street}*${city}*${state}*${zip}` });
                    })}
                    fluid
                    name={name}
                    value={value ? value.split('*')[2] : ''}
                />
                <Form.Group widths='equal'>
                    <Form.Select
                        size='tiny'
                        placeholder={'State'}
                        header={'State'}
                        options={
                            STATES.map(({ key, text }) => ({
                                key, value: key, text
                            }))
                        }
                        fluid
                        style={{ zIndex: 11, fontSize: "0.9em", borderColor: "#777" }}
                        onChange={((e, data) => {
                            const [additional_street, street, city, , zip] = value ? value.split('*') : Array(5).fill('');
                            const state = data.value.replaceAll('*', '');
                            onChange(e, { name, value: `${additional_street}*${street}*${city}*${state}*${zip}` });
                        })}
                        name={name}
                        value={value ? value.split('*')[3] : ''}
                    />
                    <Form.Input
                        size='tiny'
                        name={name}
                        onChange={(e, data) => {
                            const [additional_street, street, city, state] = value ? value.split('*') : Array(5).fill('');
                            const zip = data.value.replaceAll('*', '');
                            onChange(e, { name, value: `${additional_street}*${street}*${city}*${state}*${zip}` });
                        }}
                        placeholder='Zip Code'
                        fluid
                        value={value ? value.split('*')[4] : ''}
                    />
                </Form.Group>
            </React.Fragment>
        default:
            return <Form.Input
                label={label}
                name={name}
                value={value}
                onChange={onChange}
                size={size}
                {...(inline ? { style: { display: 'inline' } } : {})}
                loading={loading}
            />;
    }
}

export default DynamicField;
