import React from 'react';
import { Table } from 'dyl-components';
import RingGroupRow from './RingGroupRow';
import { connect } from 'react-redux';
import { Loader, Segment } from 'semantic-ui-react';

const RingGroupsTable = ({ groups, isReadingRingGroups }) => (
    isReadingRingGroups ? <Segment><Loader active /></Segment> :
    <Table>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Ring Group</Table.HeaderCell>
                <Table.HeaderCell>Extension</Table.HeaderCell>
                <Table.HeaderCell>Receive Calls</Table.HeaderCell>
                <Table.HeaderCell>Options</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {groups.map(group => (
                <RingGroupRow 
                    key={group.id}
                    group={group}
                />
            ))}
        </Table.Body>
    </Table>
);

const mapStateToProps = (state) => ({
    groups: state.ring_groups.ring_groups,
    isReadingRingGroups: state.ring_groups.isReadingRingGroups || state.pbx_config.isReadingDomains,
    domain: state.pbx_config.domain
})

export default connect(mapStateToProps)(RingGroupsTable);
