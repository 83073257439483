import React from 'react';
import { Grid, Icon, Dropdown, List, Button } from 'semantic-ui-react';

import { ButtonLink, EllipsisDropdown, LinkedAccount, Table } from 'dyl-components';
import { Link } from 'react-router-dom';

const ACCOUNT_STATUS_COLORS = {
    active: 'teal',
    convert: 'blue',
    deactivated: 'red'
}

const ELLIPSIS_OPTIONS = [
    {
        id: 1,
        text: 'Change Master Account',
        icon: 'fa-solid fa-buildings'
    }
];

const DropdownItem = ({ text, icon, ...otherProps }) => {
    return (
        <Dropdown.Item text={<React.Fragment>
            <Icon name={icon} />
            {text}
        </React.Fragment>}
            style={{ cursor: 'pointer' }}
            {...otherProps}
        />
    );
}

export const Account = ({
    name,
    id,

    primary_contact,
    contact_count,
    lead_count,
    opportunity_count,

    status,
    pinned
}) => (
    <Table.Row>
        <Table.Cell>
            {pinned && <Icon color='primary' link className='fas thumbtack' />}
        </Table.Cell>
        <Table.Cell>
            <LinkedAccount
                account={name}
                maxWidth={'9em'}
                subtitle={id && `ID: ${id}`}
                popup
                linkToAccount={<Link target={'_blank'} to={`/account/${id}`}>{name}</Link>}
            />
        </Table.Cell>
        <Table.Cell>
            {primary_contact?.id && (
                <Grid columns='equal' verticalAlign='middle'>
                    <Grid.Column>
                        <LinkedAccount
                            maxWidth={'9em'}
                            account={`${primary_contact.first_name} ${primary_contact.last_name}`}
                            subtitle={primary_contact.primary_email?.email}
                            linkToAccount={<Link target={'_blank'} to={`/contact/${primary_contact.id}`}>{primary_contact.first_name} {primary_contact.last_name}</Link>}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Icon disabled={!primary_contact.primary_phone} link color='primary' className='fas fa-phone' />
                        <Icon disabled={!primary_contact.primary_phone} link color='primary' className='fas fa-comment' />
                        <Icon disabled={!primary_contact.primary_email} link color='primary' className='fas fa-envelope' />
                    </Grid.Column>
                </Grid>
            )}
        </Table.Cell>
        <Table.Cell>
            <List>
                <List.Item>
                    <ButtonLink>{contact_count || 0} contact{contact_count > 1 && 's'}</ButtonLink>
                </List.Item>
                <List.Item>
                    <ButtonLink>{lead_count || 0} lead{lead_count > 1 && 's'}</ButtonLink>
                </List.Item>
                <List.Item>
                    <ButtonLink>{opportunity_count || 0} {opportunity_count > 1 ? 'opportunities' : 'opportunity'}</ButtonLink>
                </List.Item>
            </List>
        </Table.Cell>
        <Table.Cell>
            <Dropdown
                options={[
                    {
                        key: 'active', value: 'active', text: 'Active'
                    },
                    {
                        key: 'convert', value: 'convert', text: 'Convert'
                    },
                    {
                        key: 'deactivated', value: 'deactivated', text: 'Deactivated'
                    }
                ]}
                as={Button}
                color={ACCOUNT_STATUS_COLORS[status]}
                value={status}
                fluid
                size='tiny'
            />
        </Table.Cell>
        <Table.Cell>
            <EllipsisDropdown>
                {ELLIPSIS_OPTIONS.map(({ text, icon, ...otherProps }, idx) => {
                    return (
                        <DropdownItem
                            key={idx}
                            text={text}
                            icon={icon}
                            {...otherProps}
                        />
                    );
                })}
            </EllipsisDropdown>
        </Table.Cell>
    </Table.Row>
)
