import React, { useState, useRef, useEffect } from 'react';
import { Form, Segment, Transition } from 'semantic-ui-react';
import { SearchBar } from 'dyl-components';

import './index.scss';

const useOutsideClick = (ref, callback) => {
    const handleClick = e => {
      if (callback && ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
  
    useEffect(() => {
      document.addEventListener("click", handleClick);
  
      return () => {
        document.removeEventListener("click", handleClick);
      };
    });
};

const TableDropdown = ({ 
    isSelectionVisible, 
    value, 
    searchPlaceholder = '', 
    onSearchAction, 
    onFocusAction, 
    onChange, 
    SelectionRender, 
    SearchResultsRender, 
    isSmall = false, 
    onChangeTeamsAndTerritories = null
}) => {
    const [state, setState] = useState({
        options: [],
        count: 0,
        search: '',
        open: false,
        isFocused: false
    });

    const ref = useRef();

    const [loading, setLoading] = useState(false);

    const searchFunction = async () => {
        try {
            setLoading(true);
            const { data, count } = await onSearchAction(state.search);
            setLoading(false);
            setState({ ...state, options: data, count, open: true, page: 1, search: state.search, isFocused: true });
        } catch (e) {
            setLoading(false);
        }
    }

    const onChangeSearch = (_, { value }) => {
        if(value.length > 1){
            setState({ ...state, search: value, isFocused: true });
        } else {
            setState({ ...state, isFocused: false });
        }
    }

    const cancelFunction = () => {
        setState({ ...state, options: [], count: 0, open: false, search: '', isFocused: false});
    }

    const onFocus = async () => {
        if(state.open){
            setState({ ...state, isFocused: true });
        } else {
            try {
                setLoading(true);
                const { data, count } = await onFocusAction();
                setLoading(false);
                setState({ ...state, options: data, count, open: true, page: 1, isFocused: true });
            } catch (e) {
                setLoading(false);
            }
        }
    }

    useOutsideClick(ref, cancelFunction);
    useOutsideClick(ref, onChangeTeamsAndTerritories);

    return (
        <div className='TableDropdownComponent' ref={ref}>
            <Segment.Group className='SegmentGroup'>
                <Segment onFocus={onFocus} onBlur={cancelFunction} className='SearchBarSegment'>
                    <Transition visible={isSelectionVisible}>
                        <SelectionRender  areSelectionsRemovable={state.isFocused} value={value} onChange={onChange} />
                    </Transition>
                    <Form.Field
                        control={SearchBar}
                        addClassNames='SearchBarSegment__SearchInput'
                        isSearching={loading}
                        search={state.search}
                        searchFcn={searchFunction}
                        cancelFcn={cancelFunction}
                        placeholder={searchPlaceholder}
                        onChange={onChangeSearch} 
                        dropdown
                    />
                </Segment>
                <Transition visible={state.open} unmountOnHide={true} duration={0}>
                    <SearchResultsRender results={state.options} count={state.count} value={value} onChange={onChange} isSmall={isSmall}/>
                </Transition>
            </Segment.Group>
        </div>
    );
}

export default TableDropdown;
