import React from "react";
import { ClippedContent } from "dyl-components";

import './index.scss';

const Location = ({
    location
}) => (
    <div className="Location">
        <div className="Location__line">
            <ClippedContent popup={false}>
                {location?.street ? `${location.street} ` : ""}
                {location?.additional_street
                    ? `${location.additional_street} `
                    : ""}
            </ClippedContent>
        </div>
        <div className="Location__line">
            <ClippedContent popup={false}>
                {location?.city ? `${location.city}, ` : ""}
                {location?.state ? `${location.state} ` : ""}
                {location?.zip || ""}
            </ClippedContent>
        </div>
    </div>
);

export default Location;
