import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import initialState from "./initialState";
import ACTION_NAMES from "actions/ACTION_NAMES";

function sequenceTasksReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.SEQUENCE_CONTACT_TASKS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, selectedSequenceTasks: [], selectedSequenceId: null, selectedSequenceName: '', isReadingSequenceContactTasks: true };
        case getNamedAction(ACTION_NAMES.SEQUENCE_CONTACT_TASKS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, selectedSequenceTasks: action.data.tasks || [], selectedSequenceId: action.data.workflow_id || null, selectedSequenceName: action.data.workflow_name || '', isReadingSequenceContactTasks: false };
        case getNamedAction(ACTION_NAMES.SEQUENCE_CONTACT_TASKS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, selectedSequenceTasks: [], selectedSequenceId: null, selectedSequenceName: '', isReadingSequenceContactTasks: false };

        case getNamedAction(ACTION_NAMES.SEQUENCE_TASK, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingSequenceTasks: true, sequenceTasks: [], sequence_task_count: 0 };
        case getNamedAction(ACTION_NAMES.SEQUENCE_TASK, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingSequenceTasks: false, sequenceTasks: action.data?.data || [], sequence_task_count: action.data.count };
        case getNamedAction(ACTION_NAMES.SEQUENCE_TASK, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingSequenceTasks: false, sequenceTasks: [], sequence_task_count: 0 };

        case getNamedAction(ACTION_NAMES.SEQUENCE_TASK, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, sequenceTask: null, isReadingSequenceTask: true, sequenceTaskError: false };
        case getNamedAction(ACTION_NAMES.SEQUENCE_TASK, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, sequenceTask: action.data, isReadingSequenceTask: false };
        case getNamedAction(ACTION_NAMES.SEQUENCE_TASK, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, sequenceTask: null, isReadingSequenceTask: false, sequenceTaskError: true };

        case getNamedAction(ACTION_NAMES.RESCHEDULE_SEQUENCE_TASK_STATS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, rescheduleSequenceTaskStats: { call: 0, email: 0, text: 0 }, isReadingRescheduleSequenceTaskStats: true, rescheduleSequenceTaskStatsError: false };
        case getNamedAction(ACTION_NAMES.RESCHEDULE_SEQUENCE_TASK_STATS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, rescheduleSequenceTaskStats: action.data, isReadingRescheduleSequenceTaskStats: false };
        case getNamedAction(ACTION_NAMES.RESCHEDULE_SEQUENCE_TASK_STATS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, rescheduleSequenceTaskStats: { call: 0, email: 0, text: 0 }, isReadingRescheduleSequenceTaskStats: false, rescheduleSequenceTaskStatsError: true };

        case getNamedAction(ACTION_NAMES.SEQUENCE_TASK, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, sequenceTaskBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.SEQUENCE_TASK, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, sequenceTaskBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.SEQUENCE_TASK, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, sequenceTaskBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.RESCHEDULE_SEQUENCE_TASK, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, sequenceTaskBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.RESCHEDULE_SEQUENCE_TASK, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, sequenceTaskBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.RESCHEDULE_SEQUENCE_TASK, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, sequenceTaskBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.COMPLETE_SEQUENCE_TASK, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, sequenceTaskBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.COMPLETE_SEQUENCE_TASK, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, sequenceTaskBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.COMPLETE_SEQUENCE_TASK, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, sequenceTaskBeingUpdated: null };

        default:
            return state;
    }
}

export default sequenceTasksReducer;
