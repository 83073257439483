import ACTION_NAMES from "actions/ACTION_NAMES";
import types from "actions/call/types";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import initialState from "./state";

function callReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CALL, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCalling: true, callDetails: { ...action.body, name: action.queryParameters.name } };
        case getNamedAction(ACTION_NAMES.CALL, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCalling: true, callIsActive: true };
        case getNamedAction(ACTION_NAMES.CALL, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCalling: false };

        case getNamedAction(ACTION_NAMES.HANGUP, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isHangingUp: true };
        case getNamedAction(ACTION_NAMES.HANGUP, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isHangingUp: false, callIsActive: false, isResolvingCallResult: true };
        case getNamedAction(ACTION_NAMES.HANGUP, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isHangingUp: false };

        case getNamedAction(ACTION_NAMES.TRANSFER, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isTransferringCall: true };
        case getNamedAction(ACTION_NAMES.TRANSFER, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isTransferringCall: false };
        case getNamedAction(ACTION_NAMES.TRANSFER, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isTransferringCall: false };

        case 'RECEIVE_CALL':
            return { ...state, isCalling: true, callIsActive: true, channel_uuid: action.channel_uuid };

        case 'ANSWER_CALL':
            return { ...state, isCalling: false, callIsActive: true };

        case types.RESOLVE_CALL_RESULT:
            return { ...state, isResolvingCallResult: false, callIsActive: false };

        default:
            return state;
    }
}


export default callReducer;
