import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import { MessagingIcon, ButtonLink } from 'dyl-components';
import { connect } from 'react-redux';
import Timer from 'shared/Timer';

import Controls from './Controls';

import './index.scss';

const ActionBar = ({ callDetails, isCalling }) => (
    <Segment className='ActionBar' loading={isCalling}>
        <Grid verticalAlign='middle' columns='equal'>
            <Grid.Column width={1}>
                <MessagingIcon icon={'phone volume'}  />
            </Grid.Column>
            <Grid.Column width={3}>
                <strong><ButtonLink disabled>Outbound</ButtonLink></strong><br />
                <strong><ButtonLink disabled>{callDetails.name}</ButtonLink></strong><br />
                <strong><ButtonLink disabled>{callDetails.destination}</ButtonLink> {!isCalling && <Timer />}</strong>
            </Grid.Column>
            <Grid.Column>
                {!isCalling && <Controls />}
            </Grid.Column>
        </Grid>
    </Segment>
);

const mapStateToProps = state => {
    return {
        callDetails: state.call.callDetails,
        isCalling: state.call.isCalling
    }
}

export default connect(mapStateToProps, null)(ActionBar);
