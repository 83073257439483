import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RecordsTable } from 'dyl-components';

import LeadsHeader from './Header';
import LeadsRow from './LeadsRow';

const LeadsTable = ({ onFilter, isColumnHidden, onPageChange, filterQueryParameters }) => {
    const [params] = useSearchParams();

    const { count, leads = [], isReadingLeads } = useSelector(state => state.leads);

    return (
        <RecordsTable
            tableBodyClassName='ModulesListTable'
            isTableLoading={isReadingLeads}
            isSearchResultEmpty={count === 0 && (params.get('search') || params.get('start') || params.get('end') || params.get('account_type') || params.get('owned_by'))}
            isTableEmpty={count === 0 && !(params.get('search') || params.get('start') || params.get('end') || params.get('account_type') || params.get('owned_by'))}
            TableHeader={<LeadsHeader isColumnHidden={isColumnHidden} onFilter={onFilter} />}
            TableRows={leads.map(lead => (
                <LeadsRow
                    key={lead.pipeline_id}
                    lead={lead}
                    isColumnHidden={isColumnHidden}
                    filterQueryParameters={filterQueryParameters}
                />
            ))}
            recordsCount={count}
            activePage={params.get('page')}
            onPageChange={onPageChange}
            recordsName='Lead'
        />
    );
}

export default LeadsTable;
