
import routes from "actions/routes";
import { generateReadActionCreator, generateCreateActionCreator, generateUpdateActionCreator, generateReadOneActionCreator, generateDeleteActionCreator } from "actions/crudActionCreators";
import ACTION_NAMES from "actions/ACTION_NAMES";

export default {
    readGroups: generateReadActionCreator(routes.API_GROUPS, ACTION_NAMES.GROUPS),
    readGroupOptions: generateReadActionCreator(routes.API_GROUPS, ACTION_NAMES.GROUP_OPTIONS),
    readSubgroups: generateReadActionCreator(routes.API_GROUPS, ACTION_NAMES.SUBGROUPS),
    createGroups: generateCreateActionCreator(routes.API_GROUPS, ACTION_NAMES.GROUPS, () => { }, () => { }),
    updateGroup: generateUpdateActionCreator(routes.API_GROUPS, ACTION_NAMES.GROUPS),
    moveSubgroup: generateUpdateActionCreator(routes.API_MOVE_GROUPS, ACTION_NAMES.MOVE_GROUPS),
    isGroupUnique: generateReadActionCreator(routes.API_UNIQUE_GROUP, ACTION_NAMES.UNIQUE_GROUP),

    readHotlist: generateReadActionCreator(routes.API_GROUPS_HOTLIST, ACTION_NAMES.GROUPS_HOTLIST),
    checkHotlist: generateReadActionCreator(routes.API_GROUPS_HOTLIST_CHECK, ACTION_NAMES.HOTLIST_CHECK),
    removeHotlist: generateDeleteActionCreator(routes.API_GROUPS_HOTLIST, ACTION_NAMES.GROUPS_HOTLIST),
    addToHotlist: generateCreateActionCreator(routes.API_GROUPS_HOTLIST, ACTION_NAMES.GROUPS_HOTLIST),
    bulkHotlistContacts: generateCreateActionCreator(routes.API_BULK_HOTLIST, ACTION_NAMES.BULK_HOTLIST),

    readGroupContacts: generateReadOneActionCreator(routes.API_GROUPS, ACTION_NAMES.GROUP_CONTACTS, "contacts"),
    addContactsToGroup: generateCreateActionCreator(routes.API_GROUP_CONTACTS, ACTION_NAMES.GROUP_CONTACTS,  () => { }, () => { }),
    readContactGroups: generateReadOneActionCreator(routes.API_GROUPS, ACTION_NAMES.CONTACT_GROUPS),
    deleteGroup: generateDeleteActionCreator(routes.API_GROUPS, ACTION_NAMES.GROUPS),
    deleteContactGroup: generateDeleteActionCreator(routes.API_GROUP_CONTACTS, ACTION_NAMES.CONTACT_GROUPS),
    deleteGroupsBulk: generateDeleteActionCreator(routes.API_BULK_GROUPS, ACTION_NAMES.GROUPS_BULK),
    bulkGroupContacts: generateCreateActionCreator(routes.API_BULK_GROUPS, ACTION_NAMES.GROUPS_BULK),
}
