import React from 'react';
import { Form, Header, Image, Segment } from 'semantic-ui-react';
import { ActiveFields } from 'dyl-components';
import { STATES } from 'shared/constants/STATES';
import { LogoTextBlue, LogoBlue } from 'dyl-components'; 


const WebformPreview = ({
    form_background_color,
    use_logo,
    logo,
    form_display_name,
    activeFields
}) => (
    <Segment style={{ backgroundColor: form_background_color }} padded='very'>
        <React.Fragment>
            {use_logo && (
                <Image
                    className={`WebformPreview__logo WebformPreview__logo${logo ? "--company" : "--default"}`}
                    src={logo ? `data:image/png;base64, ${logo}` : LogoTextBlue}
                    size='small'
                />
            )}
            <Header>{form_display_name || 'Contact Us'}</Header>
            <Form>
                <ActiveFields
                    activeFields={activeFields}
                    preview
                    states={STATES}
                />
                <Segment basic textAlign='center'>
                    <Form.Button primary>Submit</Form.Button>
                </Segment>
            </Form>
            <Segment textAlign='center' basic>
                <Header as='h6'><Image src={LogoBlue} style={{ width: '1.5em' }} /> This form is powered by <span style={{ color: '#2979ff' }}>DYL</span>.</Header>
            </Segment>
        </React.Fragment>
    </Segment>
);

export default WebformPreview;
