import React from 'react';
import { Grid, Pagination } from 'semantic-ui-react';
import { TableWithTools } from 'dyl-components';
import { MOCK_VOICEMAILS } from '__mocks__/mockData';
import VoicemailHistoryFilter from './subcomponents/VoicemailHistoryFilter';
import VoicemailHistoryToolbar from './subcomponents/VoicemailHistoryToolbar';
import VoicemailHistoryTable from './subcomponents/VoicemailHistoryTable';
import HeaderContainer from 'shared/HeaderContainer';

const VoicemailHistory = ({ voicemails }) => (
    <Grid>
        <HeaderContainer 
            details={{
                type: 'reports',
                path: '/reports/pbx-vm',
                title: 'Voicemail History'
            }}
        />
        <Grid.Row>
            <Grid.Column>
                <VoicemailHistoryFilter />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <TableWithTools
                    pagination={
                        <Pagination
                            boundaryRange={0}
                            defaultActivePage={1}
                            ellipsisItem={null}
                            firstItem={null}
                            lastItem={null}
                            siblingRange={2}
                            totalPages={Math.ceil(voicemails.length / 10)}
                        />
                    }
                    toolbar={<VoicemailHistoryToolbar numberOfReceived={voicemails.length} />}
                    table={<VoicemailHistoryTable voicemails={voicemails} />}
                />
            </Grid.Column>
        </Grid.Row>
    </Grid>
)

class VoicemailHistoryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            voicemails: []
        }
    }

    componentDidMount() {
        this.setState({
            voicemails: MOCK_VOICEMAILS
        })
    }

    render() {
        return (
            <VoicemailHistory
                {...this.state}
            />
        )
    }
}

export default VoicemailHistoryContainer;
