import React from 'react';
import DepartmentContainer from './Department';
import { TargetMenu } from 'dyl-components';
import { connect } from 'react-redux';

import './Departments.scss';

const VisibleDepartments = ({ teams, activeTeam }) => {
    if (activeTeam === '') {
        return (
            teams.map(team => (
                <DepartmentContainer 
                    team={team}
                    key={team.id}
                />
            ))
        )
    }
    let currentlyActiveTeam = teams.filter(team => activeTeam === team.id)[0];
    return (
        <DepartmentContainer 
            team={currentlyActiveTeam}
            key={`${activeTeam}--active`}
        />
    )
}

export const Teams = ({ teams, activeTeam, onChangeActiveTeam }) => (
    <div className='Departments'>
        <div className='Departments__navigation'>
        <TargetMenu>
            <TargetMenu.Item 
                name={'All'}
                active={activeTeam === ''}
                onClick={() => {onChangeActiveTeam('')}}
            />
            {teams.map(team => (
                <TargetMenu.Item 
                    key={team.id}
                    name={team.name}
                    active={activeTeam === team.id}
                    onClick={() => {onChangeActiveTeam(team.id)}}
                />
            ))}
        </TargetMenu>
        </div>
        <VisibleDepartments 
            teams={teams}
            activeTeam={activeTeam}
        />
    </div>
)

class TeamsContainer extends React.Component {
    state = {
        activeTeam: ''
    }

    onChangeActiveTeam(newTeam) {
        this.setState({ activeTeam: newTeam });
    }

    render() {
        return (
            <Teams 
                activeTeam={this.state.activeTeam}
                teams={this.props.teams}
                onChangeActiveTeam={this.onChangeActiveTeam.bind(this)}
            />
        )
    }
}

const mapStateToProps = state => {
    const extensions = state.pbx_config.extensions.slice(0);
    return {
        teams: state.teams.teams.filter(({ users, hide_view, deleted }) => !hide_view && !deleted && users.count > 0).map(team => ({
            ...team,
            users: {
                ...team.users,
                data: team.users.data.map(({ id, first_name, last_name }) => ({
                    user_id: id,
                    name: `${first_name} ${last_name}`,
                    extension: (extensions.find(extension => extension.user_id === id) || { extension_number: 0 }).extension_number
                })).filter(({ extension }) => extension !== 0)
            }
        }))
    }
}

export default connect(mapStateToProps, null)(TeamsContainer);
