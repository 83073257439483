import React from "react";
import { CollapsibleList, DateTimeUtils, PriorityIcon, Table, TemperatureIcon } from "dyl-components";

import {
    KEY_CREATED, KEY_LAST_MODIFIED,
    KEY_ACCOUNT, KEY_STAGE, KEY_CAMPAIGN_SOURCE,
    KEY_LAST_MODIFIED_BY, KEY_CREATED_BY,
    KEY_OUTCOME, KEY_LOST_REASON, KEY_LEAD_TYPE,
    KEY_PRODUCT_INTEREST, KEY_LEAD_PRIORITY,
    KEY_LEAD_RATING, KEY_LEAD_SCORE, KEY_MASTER_SECONDARY,
    KEY_CLOSE_PROBABILITY, KEY_TOTAL_COST, KEY_ACQUISITION_COST,
    KEY_ADDITIONAL_COST, KEY_EXPECTED_REVENUE
} from 'shared/constants/MODULE_COLUMNS';

import { Header } from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import Contact from "shared/Contact";
import ModuleLink from "shared/ModuleLink";

const TableCellComponent = (outcome) => ({ children, allowDisable = true }) => {
    const isClosed = outcome !== '';
    return (
        <Table.Cell  {...(allowDisable && outcome ? { disabled: isClosed } : {})} >
            {children}
        </Table.Cell>
    )
}

const LeadsRow = ({ lead, isColumnHidden }) => {
    const { contact, account, outcome, close_reason, pipeline_stage_summary, pipeline_custom_data,
        pipeline_master_source, pipeline_secondary_source } = lead;

    const { fields } = pipeline_custom_data || {};

    const { account_id } = useParams();

    const TableCell = TableCellComponent(outcome || '');

    return (
        <Table.Row>
            {!isColumnHidden(KEY_CREATED) && (
                <TableCell>
                    <div><b>{DateTimeUtils.formatEpoch(lead.pipeline_created, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{DateTimeUtils.formatEpoch(lead.pipeline_created, DateTimeUtils.TIME_FORMAT)}</div>
                </TableCell>
            )}
            {!isColumnHidden(KEY_CREATED_BY) && (
                <TableCell>

                </TableCell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED) && (
                <TableCell>
                    <div><b>{lead.pipeline_activity && DateTimeUtils.formatEpoch(lead.pipeline_activity, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{lead.pipeline_activity && DateTimeUtils.formatEpoch(lead.pipeline_activity, DateTimeUtils.TIME_FORMAT)}</div>
                </TableCell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED_BY) && (
                <TableCell>

                </TableCell>
            )}
            {account_id && (
                <TableCell>
                    <Contact
                        contact={{
                            ...contact,
                            email: contact?.primary_email || {},
                            phone: contact?.primary_phone || {}
                        }}
                        name={`${contact?.first_name} ${contact?.last_name}`}
                        jobTitle={contact?.job_title || ''}
                        linkToContact={<Link to={`/contact/${contact?.id}`}>{`${contact?.first_name} ${contact?.last_name}`}</Link>}
                    />
                </TableCell>
            )}
            <TableCell allowDisable={false}>
                {
                    lead.pipeline_id && (
                        <ModuleLink pipeline_id={lead.pipeline_id} />
                    )
                }
            </TableCell>
            {!isColumnHidden(KEY_ACCOUNT) && (
                <TableCell >
                    {account?.id &&
                        <span>
                            {account?.name}
                        </span>
                    }
                </TableCell>
            )
            }
            {
                !isColumnHidden(KEY_STAGE) && (
                    <TableCell>
                        <Header as='h5' disabled={outcome}>
                            {pipeline_stage_summary?.pipeline_category_name}
                            <Header.Subheader>
                                {pipeline_stage_summary?.pipeline_stage_name}
                            </Header.Subheader>
                        </Header>
                    </TableCell>
                )
            }
            {
                !isColumnHidden(KEY_OUTCOME) && (
                    <TableCell>
                        {outcome &&
                            <span className={`ModuleRow__outcome--${outcome === 'lost' ? 'lost' : 'won'}`}>
                                <b>{outcome.charAt(0).toUpperCase() + outcome.slice(1).replace('(', ' (')}</b>
                            </span>
                        }
                    </TableCell>
                )
            }
            {
                !isColumnHidden(KEY_LOST_REASON) && (
                    <TableCell>
                        {close_reason?.id &&
                            <span>
                                {close_reason?.name}
                            </span>
                        }
                    </TableCell>
                )
            }
            {
                !isColumnHidden(KEY_LEAD_TYPE) && (
                    <TableCell>
                        {fields?.type}
                    </TableCell>
                )
            }
            {
                !isColumnHidden(KEY_PRODUCT_INTEREST) && (
                    <TableCell>
                        {lead.product_interests?.length > 0 && (
                            <CollapsibleList
                                minimumNumberOfItemsToShow={3}
                                items={lead.product_interests?.map(product_interest => (product_interest.name))}
                            />
                        )}
                    </TableCell>
                )
            }
            {
                !isColumnHidden(KEY_LEAD_PRIORITY) && (
                    <TableCell>
                        {fields?.priority &&
                            <React.Fragment>
                                <PriorityIcon type={fields?.priority} /> {fields?.priority}
                            </React.Fragment>
                        }
                    </TableCell>
                )
            }
            {
                !isColumnHidden(KEY_LEAD_RATING) && (
                    <TableCell>
                        {fields?.rating &&
                            <React.Fragment>
                                <TemperatureIcon type={fields?.rating} /> {fields?.rating}
                            </React.Fragment>
                        }
                    </TableCell>
                )
            }
            {
                !isColumnHidden(KEY_LEAD_SCORE) && (
                    <TableCell>
                        {fields?.score}
                    </TableCell>
                )
            }
            {
                !isColumnHidden(KEY_MASTER_SECONDARY) && (
                    <TableCell>
                        {`${pipeline_master_source ? pipeline_master_source : ''}${pipeline_secondary_source ? `/${pipeline_secondary_source.name}` : ''}`}
                    </TableCell>
                )
            }
            {
                !isColumnHidden(KEY_CAMPAIGN_SOURCE) && (
                    <TableCell>

                    </TableCell>
                )
            }
            {
                !isColumnHidden(KEY_CLOSE_PROBABILITY) && (
                    <TableCell>
                        {fields?.close_probability}
                    </TableCell>
                )
            }
            {
                !isColumnHidden(KEY_TOTAL_COST) && (
                    <TableCell>

                    </TableCell>
                )
            }
            {
                !isColumnHidden(KEY_ACQUISITION_COST) && (
                    <TableCell>
                        {fields?.acquisition_cost}
                    </TableCell>
                )
            }
            {
                !isColumnHidden(KEY_ADDITIONAL_COST) && (
                    <TableCell>
                        {fields?.additional_cost}
                    </TableCell>
                )
            }
            {
                !isColumnHidden(KEY_EXPECTED_REVENUE) && (
                    <TableCell>
                        {fields?.expected_revenue}
                    </TableCell>
                )
            }
        </Table.Row >
    )
}

export default LeadsRow;
