import { DateTimeUtils, Notification, STATUS_TYPES, generateResolver } from "dyl-components";
import Sequence from "shared/Sequence";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import sequencesActions from 'actions/sequences';
import { StringUtils } from "utils";
import { useEffect } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { Error404, Error500 } from "pages/ErrorPages";
import { sequenceValidationSchema } from "shared/schemas/sequenceValidationSchema";

const SequenceEdit = ({ id }) => {
    const { sequence, sequenceBeingUpdated } = useSelector(state => state.sequences);

    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            name: sequence.name || '',
            description: sequence.description || '',
            actions: (sequence?.tasks || []).map(task => ({
                data: task.data,
                timeamount: task.interval.amount,
                deliver: task.interval.type,
                type: task.task_type?.toLowerCase(),
                time: task.time ? DateTimeUtils.convertSecondsToTime(task.time) : '' 
            })),
            settings_sequence_access: { 
                territories: sequence?.territories.map(territory => ({ ...territory, teams: territory?.teams || [] })), 
                teams: sequence?.teams || []
            },
            saturday: sequence.saturday || false,
            sunday: sequence.sunday || false,
            conversion_remove: sequence.conversion_remove || false,
            reassign_remove: sequence.assign_remove || false
        },
        resolver: generateResolver(sequenceValidationSchema)
    })

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const onUpdate = async (data) => {
        const { name, description, saturday, sunday, conversion_remove, reassign_remove, settings_sequence_access, actions } = data;
        const { teams, territories } = settings_sequence_access;
        try {
            await dispatch(sequencesActions.update(id, {
                name,
                description,
                saturday,
                sunday,
                conversion_remove,
                reassign_remove,
                tasks: actions.map(action => ({
                    data: action.data,
                    interval: {
                        amount: action.timeamount,
                        type: action.deliver
                    },
                    task_type: StringUtils.toSentenceCase(action.type),
                    time: action.time ? DateTimeUtils.convertTimeToSeconds(action.time, DateTimeUtils.TIME_FORMAT) : 0
                })),
                teams: teams.map(team => team.id),
                territories: territories.map(territory => territory.id)
            }))
            Notification.alert('Successfully updated sequence!', STATUS_TYPES.SUCCESS);
            methods.reset();
            navigate('/settings/sequences', { replace: true, state: { saved: true } });
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update sequence', STATUS_TYPES.ERROR);
        }
    }


    return (
        <FormProvider {...methods}>
            <Sequence id={id} loading={sequenceBeingUpdated} onSave={onUpdate} />
        </FormProvider>
    )
}

const SequenceEditContainer = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(sequencesActions.readOne(id))
    }, [id, dispatch]);

    const { isReadingSequence, sequenceError, sequence } = useSelector(state => state.sequences);

    if (isReadingSequence) {
        return <Dimmer active><Loader active /></Dimmer>
    }

    if (!isReadingSequence && sequenceError) {
        return <Error500 message={"Something went wrong"} />;
    }

    if (!isReadingSequence && !sequence) {
        return <Error404 message={"Sequence not found"} />;
    }

    return (
        <SequenceEdit id={id} />
    )
}

export default SequenceEditContainer;
