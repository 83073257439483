import { DateTimeUtils, VALIDATORS, yup } from "dyl-components";
import applyCurrencySchema from "../products/applyCurrencySchema";

import { getDefaultSupplyChainNetworkValuesFromPayload, formatSupplyChainNetworkValues } from "../supply_chain_network/helper";

const addonSchema = {
    name: VALIDATORS.SIMPLE_INPUT()
        .noemoji()
        .minlength(2)
        .maxlength(100)
        .no_whitespace_only()
        .required("This field is required"),
    description: VALIDATORS.DESCRIPTION(),
    active_date: yup
        .string()
        .nullable(true)
        .test("valid_date", "Invalid date", (value) => {
            return value
                ? DateTimeUtils.isValid(value, DateTimeUtils.DATE_FORMAT)
                : true;
        }),
    price_data: applyCurrencySchema(yup.number()).required("This field is required"),
};

export const getDefaultValuesFromReadingAnAddon = (addon) => {
    return {
        ...getDefaultSupplyChainNetworkValuesFromPayload(addon),
        name: addon?.name || "",
        description: addon?.description || "",
        image: null,
        status: addon?.status || null,
        active_date: addon?.active_date || null,
        price_data: addon.price_data,
        products: addon.products.map(product => ({ value: product.id + '/C', name: product.name }))
    };
};

export const formatAddonDetails = ({ price_data, ...data }) => {
    return {
        ...data,
        ...formatSupplyChainNetworkValues(data),
        price_data: price_data,
        products: data.products.map(product => Number(product.value.split('/')[0]))
    };
};

export default addonSchema;
