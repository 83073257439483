import React, { useEffect, useState } from 'react';
import { Table as SemanticTable, Grid, Segment, Pagination, Button, Header } from 'semantic-ui-react';

import { ButtonLink, EmptyListPlaceholder, SearchBar, Table } from 'dyl-components';
import { Account } from './Account';
import masterAccountActions from 'actions/master_account';
import { connect } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LinkAccountDropdown from 'shared/LinkAccountDropdown';

const mapStateToProps = state => ({
    accounts: state.master_account.accounts,
    accounts_count: state.master_account.accounts_count,
    isReading: state.master_account.isReadingLinkedAccounts
})

const mapDispatchToProps = dispatch => ({
    readLinkedAccounts: (id, queryParameters) => {
        dispatch(masterAccountActions.readAccounts(id, queryParameters));
    }
});

const LIMIT = 25;

const SubAccounts = ({
    accounts,

    accounts_count,
    isReading,
    readLinkedAccounts
}) => {
    const { master_account_id } = useParams();

    const route_root = `/master_account/${master_account_id}/accounts`;

    const [params] = useSearchParams();

    const [search, setSearch] = useState(params.get('search') || '');

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }

    const navigate = useNavigate();

    useEffect(() => {
        readLinkedAccounts(master_account_id, Object.fromEntries(params));
    }, [master_account_id, readLinkedAccounts, params]);

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`${route_root}${query_string ? `?${query_string}` : ''}`);
    }

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${route_root}${query_string ? `?${query_string}` : ''}`);
    }

    const onClearSearch = () => {
        navigate(`${route_root}`);
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={8}>
                    <SearchBar
                        isSearching={isReading}
                        search={search}
                        searchFcn={onSearchSubmit}
                        cancelFcn={onClearSearch}
                        placeholder={'Search by Name or ID'}
                        onChange={onChangeSearch}
                    />
                </Grid.Column>
                <Grid.Column width={4} floated='right'>
                    <LinkAccountDropdown
                        triggerProps={{
                            color: 'primary',
                            text: '+ New Account',
                            icon: '',
                            floated: 'right',
                            as: Button
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment.Group>
                        <Segment loading={isReading} basic style={{ padding: 0 }}>
                            <Table striped={false}>
                                <SemanticTable.Header className='Table__header--primary'>
                                    <Table.Row>
                                        <SemanticTable.HeaderCell width={1} />
                                        <SemanticTable.HeaderCell>Account</SemanticTable.HeaderCell>
                                        <SemanticTable.HeaderCell>Primary Contact</SemanticTable.HeaderCell>
                                        <SemanticTable.HeaderCell width={3}>Modules</SemanticTable.HeaderCell>
                                        <SemanticTable.HeaderCell width={3}>Account Status</SemanticTable.HeaderCell>
                                        <SemanticTable.HeaderCell>Actions</SemanticTable.HeaderCell>
                                    </Table.Row>
                                </SemanticTable.Header>
                                {accounts && (
                                    <Table.Body>
                                        {accounts.map(account => (
                                            <Account
                                                {...account}
                                            />
                                        ))}
                                    </Table.Body>
                                )}
                            </Table>
                        </Segment>
                        {!isReading && !accounts?.length && (
                            !accounts_count === 0 || params.get('search') ? (
                                <Segment>
                                    <Header as='h3'>No Results Found</Header>
                                </Segment>
                            ) : (
                                <EmptyListPlaceholder title={'No Accounts Created'}
                                    subtitle={
                                        <span>
                                            Add an Account by clicking on the
                                            <LinkAccountDropdown
                                                triggerProps={{
                                                    color: 'primary',
                                                    text: '+ New Account',
                                                    icon: '',
                                                    as: ButtonLink,
                                                    style: { marginRight: "-1.5em" }
                                                }}
                                            /> button on the top-right corner.
                                        </span>
                                    } wrapped={false}
                                />
                            )
                        )}
                    </Segment.Group>
                    {accounts_count > 0 && (
                        <Segment style={{ padding: 0 }} basic textAlign='right'>
                            <Pagination
                                boundaryRange={0}
                                activePage={params.get('page') || 1}
                                ellipsisItem={null}
                                siblingRange={2}
                                totalPages={Math.ceil(accounts_count / LIMIT)}
                                onPageChange={onPageChange}
                                disabled={isReading}
                            />
                        </Segment>
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubAccounts);
