import React from 'react';
import { Table, DateTimeUtils } from 'dyl-components';
import { Checkbox, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';

import TextListRowContainer from './TextListRow';
import smsListActions from 'actions/sms_list';
import { PhoneUtil } from 'utils';

import './TextListTable.scss';

const TextListTable = ({ leads, onMarkAllTextList, onCall, onConsent }) => (
    <Table striped stackable>
        <Table.Header>
            <Table.Row className='MainHeader'>
                <Table.HeaderCell width={5}>
                    <Checkbox onChange={onMarkAllTextList} />
                </Table.HeaderCell>
                <Table.HeaderCell resizable>
                    Received
                </Table.HeaderCell>
                <Table.HeaderCell resizable>
                    Source
                </Table.HeaderCell>
                <Table.HeaderCell resizable width={200}>
                    Contact
                </Table.HeaderCell>
                <Table.HeaderCell resizable>
                    Latest Note
                </Table.HeaderCell>
                <Table.HeaderCell resizable>
                    Consent
                </Table.HeaderCell>
                <Table.HeaderCell resizable>
                    Actions
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {leads.map(lead => (
                <TextListRowContainer 
                    lead={lead}
                    onCall={onCall}
                    onConsent={onConsent}
                />
            ))}
        </Table.Body>
    </Table>
)

class TextListTableContainer extends React.Component {
    componentDidMount() {
        this.props.onReadTextList();
    }

    render() {
        if (this.props.isGettingSmsList) {
            return (
                <Dimmer active>
                    <Loader active />
                </Dimmer>
            )
        }
        return (
            <TextListTable 
                {...this.props}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        leads: PhoneUtil.formatPhoneNumbersIn(state.smsList.smsList.map(lead => ({
            ...lead,
            ts: DateTimeUtils.formatEpoch(lead.ts, 'YYYY-MM-DDThh:mm A')
        }))),
        isGettingSmsList: state.smsList.isGettingSmsList
    }
}

const mapDispatchToProps = dispatch => ({
    onReadTextList: () => {
        dispatch(smsListActions.getSmsList());
    },
    onMarkAllTextList: () => {
        dispatch(smsListActions.markAllLeadsInTextList());
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(TextListTableContainer);
