import React, { useState } from 'react';
import { DividingHeader, DropdownTimeInput } from 'dyl-components';
import { Form } from 'semantic-ui-react';
import { DateTimeUtils } from 'dyl-components';


const TIMEZONE_OPTIONS = DateTimeUtils.generateTimezoneOptions();

const CalendarPreferences = () => {

    const [timezone, setTimezone] = useState('America/New_York');
    const [startDay, setStart] = useState('08:00 AM');
    const [endDay, setEnd] = useState('05:00 PM');

    return (
        <React.Fragment>
            <DividingHeader noline content='Calendar Preferences' compact />
            <Form size='small'>
                <Form.Group>
                    <Form.Select search width={5} options={TIMEZONE_OPTIONS} onChange={(_, { value }) => { setTimezone(value) }} value={timezone} name={'timezone'} label={<b>Time Zone</b>} />
                </Form.Group>
                <Form.Group>
                    <Form.Input control={DropdownTimeInput} width={5} onChange={(_, { value }) => { setStart(value) }} value={startDay} name={'startDay'} label={<b>Start of Day</b>} />
                    <Form.Input control={DropdownTimeInput} width={5} onChange={(_, { value }) => { setEnd(value) }} value={endDay} name={'endDay'} label={<b>End of Day</b>} />
                </Form.Group>
            </Form>
        </React.Fragment>

    );
};

export default CalendarPreferences;
