import React from "react";
import { Grid, Menu, Segment } from "semantic-ui-react";
import { Outlet, NavLink } from "react-router-dom";

const Library = ({ generateTabProperties }) => {
    return (
        <React.Fragment>
            <Menu borderless pointing secondary color={"primary"} stackable>
                <Menu.Item {...generateTabProperties("Text Templates")} />
                <Menu.Item {...generateTabProperties("Email Templates")} />
                <Menu.Item {...generateTabProperties("Dialer Scripts")} />
                <Menu.Item {...generateTabProperties("Task Types")} />
            </Menu>
            <Segment basic>
            <Outlet />
            </Segment>
        </React.Fragment>
    );
};

class LibraryContainer extends React.Component {
    generateTabProperties = item => {
        const formatted_item = item.split(" ").join("-").toLowerCase();
        return {
            name: item,
            as: NavLink,
            to: formatted_item
        };
    };

    render() {
        return (
            <Grid>
                <Grid.Row style={{ marginTop: 0, borderRadius: 0 }}>
                    <Grid.Column>
                        <Library
                            generateTabProperties={this.generateTabProperties}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default LibraryContainer;
