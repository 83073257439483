const initialState = {
    leads: [],
    count: 0,
    queryParameters: {
        page: 1,
        limit: 25
    }
}

export default initialState;
