import React, { useState } from 'react';
import { Table, ButtonLink } from 'dyl-components';
import { Icon, Popup } from 'semantic-ui-react';

import PipelineStageRow from './PipelineStageRow';
import StagePopup from '../StagePopup';

const PipelineStagesTable = ({
    pipelineStages = [],
    pipeline_id,
    onDeleteStage,

    refresh
}) => {
    const [isStagePopupOpen, setIsStagePopupOpen] = useState(false);
    const cannotAddStages = pipelineStages.length >= 10;
    const isDeleteDisabled = (index) => (pipelineStages.length === 1 || pipelineStages[index].has_records)

    const isStageDuplicate = (name) => {
        if (name) {
            const duplicatedStage = pipelineStages.find((stage) => {
                return name.toLowerCase() === stage.name.toLowerCase();
            })
            return duplicatedStage
        }
        return null;
    }

    return (
        <Table className={'LeadStageTable'} basic='very'>
            <Table.Body>
                {pipelineStages.slice(0, 10).map((stage, index) => {
                    return (
                        <PipelineStageRow
                            pipelineStage={stage}
                            key={stage.id}
                            disabledDeleteBtn={isDeleteDisabled(index)}
                            refresh={refresh}
                            onDeleteStage={onDeleteStage}
                            pipeline_id={pipeline_id}
                            isStageDuplicate={isStageDuplicate}
                        />
                    );
                })}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.Cell colspan={5} className={'LeadStageTable__Footer'}>
                        <Popup
                            trigger={
                                <ButtonLink disabled={cannotAddStages}><Icon name='plus circle' /> New Pipeline Stage</ButtonLink>
                            }
                            on='click'
                            content={
                                <StagePopup 
                                    refresh={refresh}
                                    onClose={() => setIsStagePopupOpen(false)}
                                    pipeline_id={pipeline_id}
                                    isStageDuplicate={isStageDuplicate}
                                />
                            }
                            open={isStagePopupOpen}
                            onOpen={() => setIsStagePopupOpen(true)}
                            onClose={() => setIsStagePopupOpen(false)}
                            wide='very'
                            position='bottom left'
                        />
                    </Table.Cell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
}

export default PipelineStagesTable;
