import initialState from "./initialState";
import CRUD_ACTION_TYPES, {getNamedAction} from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function customersReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CUSTOMERS, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return {...state, isRemovingCustomer: true};
        case getNamedAction(ACTION_NAMES.CUSTOMERS, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return {...state, isRemovingCustomer: false};
        case getNamedAction(ACTION_NAMES.CUSTOMERS, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return {...state, isRemovingCustomer: false};

        case getNamedAction(ACTION_NAMES.CUSTOMERS, CRUD_ACTION_TYPES.READ_REQUEST):
            return {...state, isReadingCustomers: true};
        case getNamedAction(ACTION_NAMES.CUSTOMERS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {
                ...state,
                isReadingCustomers: false,
                customers: (action.data.data || []).map(customer => ({...customer, isMarked: state.allPagesMarked})),
                queryParameters: {...state.queryParameters, ...action.queryParameters},
                count: action.data.count,
                allMarked: state.allPagesMarked
            };
        case getNamedAction(ACTION_NAMES.CUSTOMERS, CRUD_ACTION_TYPES.READ_FAILURE):
            return {...state, isReadingCustomers: false};
            
        case getNamedAction(ACTION_NAMES.CUSTOMERS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return {...state, isUpdatingCustomer: true};
        case getNamedAction(ACTION_NAMES.CUSTOMERS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return {...state, isUpdatingCustomer: false};
        case getNamedAction(ACTION_NAMES.CUSTOMERS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return {...state, isUpdatingCustomer: false};
            
        case getNamedAction(ACTION_NAMES.CUSTOMERS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return {...state, isCreatingCustomer: true};
        case getNamedAction(ACTION_NAMES.CUSTOMERS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return {...state, isCreatingCustomer: false};
        case getNamedAction(ACTION_NAMES.CUSTOMERS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return {...state, isCreatingCustomer: false};

        default:
            return state;
    }
}

export default customersReducer;
