import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function accountReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.ACCOUNT_OPTIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingOptions: true };
        case getNamedAction(ACTION_NAMES.ACCOUNT_OPTIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingOptions: false, options: action.data.data, options_count: action.data.count };
        case getNamedAction(ACTION_NAMES.ACCOUNT_OPTIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingOptions: false };

        default:
            return state;
    }
}


export default accountReducer;
