import React from 'react';
import { Breadcrumb, Select, Segment, Label } from 'semantic-ui-react';
import DAYS_OPTIONS from 'shared/constants/DAYS_OPTIONS';
import { MOCK_ROUTE_OPTIONS, MOCK_RING_GROUP_OPTIONS } from '__mocks__/mockData';
import { Table, MessagingIcon, ControlledPopup, CategorizedDropdown, TimeInput } from 'dyl-components';
import { EditableContent } from 'dyl-components';

const routeOptionCategories = [
    { name: 'IVR Menus', options: MOCK_ROUTE_OPTIONS },
    { name: 'Ring Groups', options: MOCK_RING_GROUP_OPTIONS }
];

const routeOptions = [{ key: 'Default', value: 'Default', text: 'Default' }, ...MOCK_ROUTE_OPTIONS, ...MOCK_RING_GROUP_OPTIONS];

export const TimeframeRow = ({ timeframe, onChange, onChangeDestination, isEditMode, onDisableEditMode, onEnableEditMode, onUpdate, onDelete, onAdd }) => (
    <Table.Row>
        <Table.Cell>
            <EditableContent
                control={Select}
                onChange={onChange}
                multiple
                options={Object.keys(DAYS_OPTIONS)
                    .map(day => ({ key: DAYS_OPTIONS[day], value: DAYS_OPTIONS[day], text: DAYS_OPTIONS[day] }))}
                value={isEditMode ? timeframe.days : timeframe.days.map((day, index) => (
                    <React.Fragment>
                        <b>{day}</b>{index < timeframe.days.length - 1 ? ', ' : ''}
                    </React.Fragment>
                ))}
                name='days'
                isEditMode={isEditMode}
            />
        </Table.Cell>
        <Table.Cell>
            <EditableContent
                control={TimeInput}
                timeFormat={'AMPM'}
                value={timeframe.start}
                onChange={onChange}
                name='start'
                isEditMode={isEditMode}
            />
        </Table.Cell>
        <Table.Cell>
            <EditableContent
                control={TimeInput}
                timeFormat={'AMPM'}
                value={timeframe.end}
                onChange={onChange}
                name='end'
                isEditMode={isEditMode}
            />
        </Table.Cell>
        <Table.Cell>
            <Breadcrumb size='large'>
                {timeframe.destinations.map((destination, index) => (
                    <React.Fragment>
                        <Breadcrumb.Section as={Segment}>
                            <Label horizontal color='blue'>
                                {index+1}
                            </Label>
                            <EditableContent
                                control={CategorizedDropdown}
                                categories={routeOptionCategories}
                                defaultOption={{ key: 'Default', value: 'Default', text: 'Default' }}
                                value={isEditMode ? destination : routeOptions.filter(option => option.key === destination)[0].text}
                                onChange={(_, { name, value }) => { onChangeDestination({ name, value, index }) }}
                                label={index + 1}
                                labeled
                                name={'destinations'}
                                isEditMode={isEditMode}
                            />
                        </Breadcrumb.Section>
                        {index < timeframe.destinations.length - 1 && <Breadcrumb.Divider icon='chevron right' />}
                    </React.Fragment>
                ))}
            </Breadcrumb>
        </Table.Cell>
        <Table.Cell>
            {!isEditMode && (
                <React.Fragment>
                    <MessagingIcon icon='plus circle'  onClick={onAdd} />
                    <ControlledPopup
                        trigger={<MessagingIcon icon='trash'  />}
                        onConfirm={onDelete}
                        content={'Delete'}
                        inverted
                    />
                </React.Fragment>
            )}
            {isEditMode && (
                <MessagingIcon icon='times'  onClick={onDisableEditMode} />
            )}
            <MessagingIcon
                icon={isEditMode ? 'check' : 'edit'}
                onClick={isEditMode ? onUpdate : onEnableEditMode}
                
            />
        </Table.Cell>
    </Table.Row>
);

class TimeframeRowContainer extends React.Component {
    state = {
        timeframe: this.props.timeframe,
        isEditMode: this.props.isEditMode
    }

    onEnableEditMode = () => {
        this.setState({ isEditMode: true });
    }

    onDisableEditMode = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        } else {
            this.setState({ isEditMode: false, timeframe: this.props.timeframe });
        }
    }

    onChange = (_, { name, value }) => {
        let timeframe = { ...this.state.timeframe, [name]: value };
        this.setState({
            timeframe
        });
    }

    onChangeDestination = ({ name, value, index }) => {
        let timeframe = { ...this.state.timeframe };
        let destinations = timeframe[name].slice(0);
        destinations[index] = value;
        timeframe = { ...timeframe, destinations };
        this.addDestinationToTimeframe(timeframe, value, index);
        this.setState({ timeframe });
    }

    addDestinationToTimeframe(timeframe, dest, index) {
        if (index === timeframe.destinations.length - 1) {
            if (MOCK_RING_GROUP_OPTIONS.findIndex(option => option.value === dest) !== -1) {
                timeframe.destinations.push('Default');
            }
        } else if (MOCK_RING_GROUP_OPTIONS.findIndex(option => option.value === dest) === -1) {
            timeframe.destinations = timeframe.destinations.slice(0, index + 1);
        }
    }

    onUpdate = () => {
        if (this.props.onConfirmAdd) {
            this.props.onConfirmAdd(this.state.timeframe);
        } else {
            this.setState({
                isEditMode: false
            }, () => {
                this.props.onUpdate(this.state.timeframe);
            })
        }
    }

    onDelete = () => {
        this.props.onDelete(this.props.timeframe.id);
    }

    onAdd = () => {
        this.props.onAdd(this.props.index + 1);
    }

    render() {
        return (
            <TimeframeRow
                {...this.state}
                onEnableEditMode={this.onEnableEditMode}
                onDisableEditMode={this.onDisableEditMode}
                onChange={this.onChange}
                onChangeDestination={this.onChangeDestination}
                onUpdate={this.onUpdate}
                onDelete={this.onDelete}
                onAdd={this.onAdd}
            />
        )
    }
}

export default TimeframeRowContainer;
