
import initialState from './state';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';

function templateReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.EMAIL_TEMPLATES, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingTemplate: true }
        case getNamedAction(ACTION_NAMES.EMAIL_TEMPLATES, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingTemplate: false }
        case getNamedAction(ACTION_NAMES.EMAIL_TEMPLATES, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingTemplate: false }

        case getNamedAction(ACTION_NAMES.EMAIL_TEMPLATES, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, templateBeingUpdated: action.id }
        case getNamedAction(ACTION_NAMES.EMAIL_TEMPLATES, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, templateBeingUpdated: null }
        case getNamedAction(ACTION_NAMES.EMAIL_TEMPLATES, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, templateBeingUpdated: null }

        case getNamedAction(ACTION_NAMES.EMAIL_TEMPLATES, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, templateBeingDeleted: action.id }
        case getNamedAction(ACTION_NAMES.EMAIL_TEMPLATES, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, templateBeingDeleted: null }
        case getNamedAction(ACTION_NAMES.EMAIL_TEMPLATES, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, templateBeingDeleted: null }

        default:
            return state;
    }
}

export default templateReducer;
