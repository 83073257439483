import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import { TableWithHeader } from 'dyl-components';
import ImportSteps from './subcomponents/ImportSteps';
import ImportForm from 'shared/forms/ImportForm';
import ImportActionsAndMapping from 'shared/forms/ImportActionsAndMapping';

import './index.scss';

const MOCK_CLASSIFICATIONS = [
    { key: 'contacts', value: 'contacts', text: 'Contacts' },
    { key: 'leads', value: 'leads', text: 'Leads' },
    { key: 'opportunities', value: 'opportunities', text: 'Opportunities' },
    { key: 'customers', value: 'Customers', text: 'Customers' }
];

const ImportContent = ({
    activeStep,
    filters,
    classification,
    classType,
    leadAssignment,
    addTo,
    addToSecond,
    importToFolder,
    importToSecondFolder,
    importToCampaign,
    importToSecondCampaign,
    onChange,
    onSubmit,
    onToggleSecondAdd
}) => {
    switch (activeStep) {
        case 1:
            return <ImportForm
                filters={filters}
                classification={classification}
                classType={classType}
                onChange={onChange}
                onSubmit={onSubmit}
                classifications={MOCK_CLASSIFICATIONS} />;
        default:
            return <ImportActionsAndMapping
                leadAssignment={leadAssignment}
                onChange={onChange}
                onToggleSecondAdd={onToggleSecondAdd}
                addTo={addTo}
                addToSecond={addToSecond}
                importToFolder={importToFolder}
                importToCampaign={importToCampaign}
                importToSecondFolder={importToSecondFolder}
                importToSecondCampaign={importToSecondCampaign}
                possibleFields={[]}
                users={[]} />;
    }
}

class Import extends React.Component {
    state = {
        classification: null,
        classType: null,
        addTo: 'None',
        addToSecond: 'None',
        importToFolder: null,
        importToCampaign: null,
        importToSecondFolder: null,
        importToSecondCampaign: null,
        leadAssignment: null,
        filters: []
    }

    onChange = (_, { name, value }) => {
        this.setState({
            [name]: value
        })
    }

    onSubmit = () => {

    }

    render() {
        const activeStep = this.props.activeStep ? this.props.activeStep : 1;
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <TableWithHeader
                            header='Import'
                            table={
                                <Segment className="ImportSegment">
                                    <div className="ImportDiv">
                                        <ImportSteps activeStep={activeStep} />
                                    </div>
                                    <ImportContent
                                        {...this.state}
                                        onSubmit={this.onSubmit}
                                        onChange={this.onChange}
                                        activeStep={activeStep} />
                                </Segment>
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default Import;
