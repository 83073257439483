import routes from "actions/routes";
import { generateReadActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

const getCalls = generateReadActionCreator(routes.API_CALL_HISTORY_CALLS, ACTION_NAMES.CALL_HISTORY_CALLS);
const getActiveCalls = generateReadActionCreator(routes.API_CALL_HISTORY_ACTIVE_CALLS, ACTION_NAMES.CALL_HISTORY_ACTIVE);

function markCall(id) {
    return {
        type: 'CALL_HISTORY_MARK_CALL',
        id
    }
}

function markAllCalls() {
    return {
        type: 'CALL_HISTORY_MARK_ALL'
    }
}

export default {
    getCalls,
    getActiveCalls,
    markCall,
    markAllCalls
}
