import React from 'react';
import { Funnel } from 'dyl-components';

const options = {
    chart: {
        bottomWidth: 3 / 8,
    },
    block: {
        minHeight: 50,
    },
    label: {
        format: '{l}\n{f}',
    }
};

const chartWidth = 320;
const chartHeight = 420;
const HEX_COLOR = {
    0: '#303f94',
    1: '#2979ff',
    2: '#00bfa5',
    3: '#ff9654',
    4: '#9467bd',
    5: '#4975aa',
}

const FunnelChart = ({ totals, isReading }) => (
    (isReading && " ") || <Funnel
        height={chartHeight}
        width={chartWidth}
        options={options}
        data={totals.length > 0 ? totals.map(({ count, pipeline_stage }, index) => ({
            label: pipeline_stage,
            value: count,
            backgroundColor: HEX_COLOR[index]
        })): [{
            label: 'No stages',
            value: "",
            backgroundColor: HEX_COLOR[0]
        }]}
        animationSpeed={100}
        dynamicHeight
        pinched
    />
);

export default React.memo(FunnelChart);
