import React from 'react';
import { Popup } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { ConfirmationPrompt, Notification, STATUS_TYPES } from 'dyl-components';

import productCategoryActions from 'actions/product_category';

const DeleteProductCategoryPopup = ({ isOpen, actionPopupRef, id, onFormClose, refresh }) => {
    const dispatch = useDispatch();

    return (
        <Popup
            open={isOpen}
            position='bottom center'
            context={actionPopupRef}
            onClose={() => onFormClose()}
            content={
                <ConfirmationPrompt
                    subheader='You cannot undo delete actions.'
                    onConfirm={async () => {
                        try {
                            onFormClose();
                            await dispatch(productCategoryActions.deleteProductCategory(id));
                            Notification.alert('Successfully deleted product category!', STATUS_TYPES.SUCCESS);
                            refresh();
                        } catch (e) {
                            console.log(e);
                            Notification.alert('Failed to delete product category', STATUS_TYPES.ERROR)
                        }
                    }}
                    onCancel={() => { onFormClose() }}
                />
            }
            hoverable
        />
    );
}

export default DeleteProductCategoryPopup;
