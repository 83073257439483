import React from 'react';
import { Table, TimestampFilters, ClearableCheckboxDropdown } from 'dyl-components';
import { Table as SemanticTable, Icon } from "semantic-ui-react";
import { useSearchParams, useNavigate } from "react-router-dom";

import {
    KEY_CREATED_ON, KEY_CREATED_BY, KEY_LAST_MODIFIED_ON, KEY_LAST_MODIFIED_BY, KEY_START_END_DATE,
    KEY_TYPE, KEY_STATUS, KEY_CATEGORY_SUBCATEGORY, KEY_SHARED, KEY_TOTAL_CONTACTS,
    KEY_REACHED, KEY_UNREACHED, KEY_OWNER, KEY_ACTIONS, KEY_CAMPAIGN_CONVERSION
} from './constants';

import {
    getData,
    onToggleTimeUnit,
    useRangeCalendar,
} from "shared/CustomDateRange/customDateRange";

const SortActions = ({ direction = false, onToggleSort = () => { } }) => {
    return (
        <div
            onClick={onToggleSort}
            style={{ display: "inline-flex", cursor: "pointer" }}
        >
            <Icon
                name="fa-solid fa-arrow-down-long"
                style={{
                    marginRight: -7,
                    opacity: !direction ? "100%" : "50%",
                }}
            />
            <Icon
                name="fa-solid fa-arrow-up-long"
                style={{ opacity: direction ? "100%" : "50%" }}
            />
        </div>
    );
};

const CampaignsTableHeader = ({ isColumnHidden, onFilter }) => {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        userAuthTimezone,
        dateRange,
        setDateRange,
    } = useRangeCalendar(params);

    const createdOrder = params.get("order_by") === "created" && params.get("order") === "asc";
    const lastModifiedOrder = params.get("order_by") === "activity" && params.get("order") === "asc";

    const onDateRangeChange = (_, { value }) => {
        setDateRange(value);
    };

    const createdOrderFilter = () => {
        const query = new URLSearchParams(params);
        const order = !createdOrder ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", "created");
        const query_string = query.toString();
        navigate(`/campaigns${query_string ? `?${query_string}` : ""}`);
    };

    const lastModifiedOrderFilter = () => {
        const query = new URLSearchParams(params);
        const order = !lastModifiedOrder ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", "activity");
        const query_string = query.toString();
        navigate(`/campaigns${query_string ? `?${query_string}` : ""}`);
    };

    return (
        <Table.Row isHeader>
            {!isColumnHidden(KEY_CREATED_ON) && (
                <SemanticTable.HeaderCell className="CampaignsTable__MediumFixedWidth" width={2}>
                    <span style={{ marginRight: 5 }}>Created</span>

                    <TimestampFilters
                        timestamp={timestamp}
                        onToggleTimeUnit={(timestamp) =>
                            onToggleTimeUnit(
                                timestamp,
                                setTimestamp,
                                params,
                                navigate,
                                "/campaigns"
                            )
                        }
                        isCustomDateRangeOpen={isCustomDateRangeOpen}
                        onOpenCustomDateRange={() => {
                            setCustomDateRangeOpen(true);
                        }}
                        onCloseCustomDateRange={() => {
                            setCustomDateRangeOpen(false);
                        }}
                        dateRange={dateRange}
                        onDateRangeChange={onDateRangeChange}
                        getData={() =>
                            getData({
                                setCustomDateRangeOpen,
                                dateRange,
                                userAuthTimezone,
                                params,
                                navigate,
                                url: "/campaigns",
                            })
                        }
                    />

                    <SortActions
                        direction={createdOrder}
                        onToggleSort={() => {
                            createdOrderFilter();
                        }}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CREATED_BY) && (
                <SemanticTable.HeaderCell className='CampaignsTable__MediumFixedWidth' width={2}>Created By</SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED_ON) && (
                <SemanticTable.HeaderCell className='CampaignsTable__MediumFixedWidth' width={2}>
                    Last Modified
                    <SortActions
                        direction={lastModifiedOrder}
                        onToggleSort={() => {
                            lastModifiedOrderFilter();
                        }}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED_BY) && (
                <SemanticTable.HeaderCell className='CampaignsTable__MediumFixedWidth' width={2}>
                    Last Modified By
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_START_END_DATE) && (
                <SemanticTable.HeaderCell className='CampaignsTable__MediumFixedWidth' width={2}>
                    Start/End Date
                </SemanticTable.HeaderCell>
            )}
            <SemanticTable.HeaderCell className="CampaignsTable__SmallFixedWidth" width={1}>
                ID
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className="CampaignsTable__MediumFixedWidth" width={2}>Name</SemanticTable.HeaderCell>
            {!isColumnHidden(KEY_TYPE) && (
                <SemanticTable.HeaderCell className="CampaignsTable__MediumFixedWidth" width={2}>
                    <ClearableCheckboxDropdown
                        text="Type"
                        name="type"
                        options={[
                            { key: "call", value: "call", text: "Call" },
                            { key: "text", value: "text", text: "Text" }
                        ]}
                        onChange={(_, { value }) => {
                            onFilter(_, { name: "type", value });
                        }}
                        value={params.get("type")?.split(",")}
                        closeOnLoad={true}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CAMPAIGN_CONVERSION) && (
                <SemanticTable.HeaderCell className="CampaignsTable__CampaignConversionColumn" width={2}>
                    <ClearableCheckboxDropdown
                        text="Campaign Conversion"
                        name="campaign_conversion"
                        options={[
                            { key: "lead", value: "lead", text: "Lead" },
                            { key: "opportunity", value: "opportunity", text: "Opportunity" },
                            { key: "customer", value: "customer", text: "Customer" },
                            { key: "custom", value: "custom", text: "Custom" }
                        ]}
                        onChange={(_, { value }) => {
                            onFilter(_, { name: "campaign_conversion", value });
                        }}
                        value={params.get("campaign_conversion")?.split(",")}
                        closeOnLoad={true}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CATEGORY_SUBCATEGORY) && (
                <SemanticTable.HeaderCell className="CampaignsTable__MediumFixedWidth" width={2}>Category/Subcategory</SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_STATUS) && (
                <SemanticTable.HeaderCell className="CampaignsTable__MediumFixedWidth" width={2}>
                    <ClearableCheckboxDropdown
                        text="Status"
                        name="status"
                        options={[
                            { key: "none", value: "none", text: "None" },
                            { key: "active", value: "active", text: "Active" },
                            { key: "expired", value: "expired", text: "Expired" },
                            { key: "deleted", value: "deleted", text: "Deleted" },
                        ]}
                        onChange={(_, { value }) => {
                            onFilter(_, { name: "status", value });
                        }}
                        value={params.get("status")?.split(",")}
                        closeOnLoad={true}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_SHARED) && (
                <SemanticTable.HeaderCell className="CampaignsTable__MediumFixedWidth" width={2}>
                    <ClearableCheckboxDropdown
                        text="Shared"
                        name="shared"
                        options={[
                            { key: "personal", value: "personal", text: "Personal" },
                            { key: "company", value: "company", text: "Company" }
                        ]}
                        onChange={(_, { value }) => {
                            onFilter(_, { name: "shared", value });
                        }}
                        value={params.get("shared")?.split(",")}
                        closeOnLoad={true}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_TOTAL_CONTACTS) && (
                <SemanticTable.HeaderCell className="CampaignsTable__MediumFixedWidth" width={2}>Total Contacts</SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_REACHED) && (
                <SemanticTable.HeaderCell className="CampaignsTable__SmallFixedWidth" width={1}>
                    Reached
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_UNREACHED) && (
                <SemanticTable.HeaderCell className="CampaignsTable__SmallFixedWidth" width={1}>
                    Unreached
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_OWNER) && (
                <SemanticTable.HeaderCell className="CampaignsTable__MediumFixedWidth" width={2}>Owner</SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_ACTIONS) && (
                <SemanticTable.HeaderCell className="CampaignsTable__SmallFixedWidth" width={1}>
                    Actions
                </SemanticTable.HeaderCell>
            )}
        </Table.Row>
    );
}

export default CampaignsTableHeader;
