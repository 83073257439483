import { Grid, Menu, Segment } from 'semantic-ui-react';
import { NavLink, Outlet } from 'react-router-dom';

const NotificationsTabs = () => {
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Menu borderless pointing secondary color='primary' stackable size='large'>
                        <Menu.Item name='All' as={NavLink} to='/notifications'/>
                        <Menu.Item name='Read' as={NavLink} to='/read/notifications'/>
                        <Menu.Item name='Unread' as={NavLink} to='/unread/notifications' />
                        <Menu.Item name='Snoozed' as={NavLink} to='/snoozed/notifications' />
                        <Menu.Item name='Archived' as={NavLink} to='/archived/notifications' />
                    </Menu>
                    <Segment basic style={{padding:0}}>
                        <Outlet />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
export default NotificationsTabs;