import React from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Grid, Tab } from 'semantic-ui-react';

import './index.scss';

const Integration = () => {
    const { id } = useParams();
    const { pathname } = useLocation();
    const end = pathname.split('/').pop();

    const root = `/settings/users/${id}/integration`;

    const navigate = useNavigate();

    const panes = [
        { menuItem: 'Apps', path: '' },
        { menuItem: 'Email', path: 'email' },
        { menuItem: 'Calendar', path: 'calendar' },
        { menuItem: 'Phone', path: 'phone' },
        { menuItem: 'Text', path: 'text' },
    ];

    const activeIndex = () => {
        return end === 'integration' ? 0 : panes.findIndex(({ path }) => path === end);
    }

    const onTabChange = (_, { activeIndex }) => {
        const path = panes[activeIndex].path;
        navigate(`${root}/${path}`);
    }


    return (
        <Grid className='IntegrationTab'>
            <Grid.Row>
                <Grid.Column>
                    <Tab activeIndex={activeIndex()} onTabChange={onTabChange} menu={{ color: 'blue', secondary: true, pointing: true }} panes={panes} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Outlet />
                </Grid.Column>
            </Grid.Row>

        </Grid>
    )
}

export default Integration;
