import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function quoteReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.INVOICE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreating: true };
        case getNamedAction(ACTION_NAMES.INVOICE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreating: false };
        case getNamedAction(ACTION_NAMES.INVOICE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreating: false };

        case getNamedAction(ACTION_NAMES.INVOICE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, invoiceBeingRead: action.id, invoice: null, invoiceError: false };
        case getNamedAction(ACTION_NAMES.INVOICE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, invoiceBeingRead: null, invoice: action.data };
        case getNamedAction(ACTION_NAMES.INVOICE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, invoiceBeingRead: null, invoice: null, invoiceError: true };

        case getNamedAction(ACTION_NAMES.INVOICE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, invoiceBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.INVOICE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, invoiceBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.INVOICE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, invoiceBeingUpdated: null };

        default:
            return state;
    }
}


export default quoteReducer;
