import productActions from "actions/product";
import productsActions from "actions/products";
import { Notification, STATUS_TYPES, generateResolver } from "dyl-components";
import { Error404, Error500 } from "pages/ErrorPages";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import ProductForm from "shared/forms/ProductForm";
import { MODEL_SCHEDULE } from "shared/schemas/products/MODEL_SCHEDULE";
import {
    formatProductDetails,
    getDefaultValuesFromReadingAProduct,
} from "shared/schemas/products/helper";
import productSchema from "shared/schemas/products/productSchema";

const DuplicateProduct = ({ id }) => {
    const { product, isCreating } = useSelector((state) => state.products);
    const { price_data } = product;
    const [model, schedule] = MODEL_SCHEDULE[price_data.model].split(":");

    const methods = useForm({
        mode: "onChange",
        defaultValues: getDefaultValuesFromReadingAProduct({
            ...product,
            variants: Object.keys(product.variants || {}).reduce(
                (data, name) => {
                    return {
                        ...data,
                        [name]: product.variants[name].map(
                            ({ value, additional_price }) => ({
                                value,
                                additional_price,
                                sku: "",
                            })
                        ),
                    };
                },
                {}
            ),
            model,
            schedule,
        }),
        resolver: generateResolver(productSchema),
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (product) {
            const value = `Duplicate of ${product.name}`;
            methods.setValue("name", value, {
                shouldDirty: true,
            });
            dispatch(productsActions.isDuplicate({ name: value, type: "product" })).then(isDuplicate => {
                if (isDuplicate) {
                    methods.setError("name", {
                        type: 'unique_product',
                        message: 'Product name already exists!'
                    })
                }
            })
        }
    }, [product, methods, dispatch]);

    const onSave = async (data) => {
        try {
            await dispatch(
                productsActions.addProduct(formatProductDetails(data))
            );
            Notification.alert(
                "Succesfully duplicated product",
                STATUS_TYPES.SUCCESS
            );
            methods.reset();
            navigate(
                "/settings/product-catalog-quotes-invoices/product-catalog",
                { replace: true, state: { saved: true } }
            );
        } catch (e) {
            console.log(e);
            Notification.alert(
                "Failed to duplicate product",
                STATUS_TYPES.ERROR
            );
        }
    };

    return (
        <FormProvider {...methods}>
            <ProductForm onSave={onSave} id={id} loading={isCreating} />
        </FormProvider>
    );
};

const DuplicateProductContainer = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(productActions.readProduct(id));
    }, [id, dispatch]);

    const { productBeingRead, productError, product } = useSelector(
        (state) => state.products
    );

    if (productBeingRead) {
        return (
            <Dimmer active>
                <Loader active />
            </Dimmer>
        );
    }

    if (!productBeingRead && productError) {
        return <Error500 message={"Something went wrong"} />;
    }

    if (!productBeingRead && !product) {
        return <Error404 message={"Product not found"} />;
    }

    return <DuplicateProduct id={id} />;
};

export default DuplicateProductContainer;
