export const MODEL_SCHEDULE = {
    fixed: "fixed:one-time",
    recurring: "fixed:recurring",
    volume: "volume:one-time",
    volume_recurring: "volume:recurring",
    usage: "usage",
};

export const getModelMapping = (model, schedule) => {
    if (model === "usage") {
        return "usage";
    }
    const value = [model, schedule].filter((part) => part).join(":");
    return Object.keys(MODEL_SCHEDULE).find(
        (key) => MODEL_SCHEDULE[key] === value
    );
};
