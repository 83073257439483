
import React, { useState } from "react";
import { Icon, Table as SemanticTable } from 'semantic-ui-react';
import { Table, TimestampFilters, ClearableCheckboxDropdown } from 'dyl-components';
import { useSelector } from 'react-redux';

import {
    KEY_CREATED, KEY_LAST_MODIFIED,
    KEY_ACCOUNT, KEY_STAGE, KEY_CAMPAIGN_SOURCE,
    KEY_LAST_MODIFIED_BY, KEY_CREATED_BY,
    KEY_OUTCOME, KEY_LOST_REASON, KEY_LEAD_TYPE,
    KEY_PRODUCT_INTEREST, KEY_LEAD_PRIORITY,
    KEY_LEAD_RATING, KEY_LEAD_SCORE, KEY_MASTER_SECONDARY,
    KEY_CLOSE_PROBABILITY, KEY_TOTAL_COST, KEY_ACQUISITION_COST,
    KEY_ADDITIONAL_COST, KEY_EXPECTED_REVENUE
} from 'shared/constants/MODULE_COLUMNS';

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getData, onToggleTimeUnit, useRangeCalendar } from "shared/CustomDateRange/customDateRange";

const SortActions = ({ direction = false, onToggleSort = () => { } }) => {
    return (
        <div
            onClick={onToggleSort}
            style={{ display: "inline-flex", cursor: "pointer" }}
        >
            <Icon
                name="fa-solid fa-arrow-down-long"
                style={{
                    marginRight: -7,
                    opacity: !direction ? "100%" : "50%",
                }}
            />
            <Icon
                name="fa-solid fa-arrow-up-long"
                style={{ opacity: direction ? "100%" : "50%" }}
            />
        </div>
    );
};

const LeadsHeader = ({ isColumnHidden, routeRoot }) => {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const {
        productInterestOptions,
    } = useSelector((state) => {
        return {
            productInterestOptions: state.account.account?.product_interests,           
        }
    });

    const { account_id } = useParams();

    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        userAuthTimezone,
        dateRange,
        setDateRange,
    } = useRangeCalendar(params);

    const [createdOrder, setCreatedOrder] = useState(
        params.get("order_by") === "created" && params.get("order") === "asc"
    );

    const onDateRangeChange = (_, { value }) => {
        setDateRange(value);
    };

    const createdOrderFilter = () => {
        setCreatedOrder(!createdOrder);
        const query = new URLSearchParams(params);
        const order = !createdOrder ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", "created");
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    };

    const onClickInterest = (updatedInterestsFilter) => {
        const query = new URLSearchParams(params);
        query.delete("product_interest_ids");
        const query_string = query.toString();
        const root = `/account/${account_id}/leads?${query_string ? `${query_string}` : ''}`;
        if (updatedInterestsFilter.length === 0) {
            navigate(root)
        } else {
            const interests_string = updatedInterestsFilter.toString();
            navigate(`${root}${interests_string ? `${query_string ? "&" : ""}product_interest_ids=${interests_string}` : ""}`)
        }
    }

    return (
        <Table.Row>
            {!isColumnHidden(KEY_CREATED) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    <span style={{ marginRight: 5 }}>Created</span>
                    <TimestampFilters
                        timestamp={timestamp}
                        onToggleTimeUnit={(timestamp) =>
                            onToggleTimeUnit(
                                timestamp,
                                setTimestamp,
                                params,
                                navigate,
                                routeRoot
                            )
                        }
                        isCustomDateRangeOpen={isCustomDateRangeOpen}
                        onOpenCustomDateRange={() => {
                            setCustomDateRangeOpen(true);
                        }}
                        onCloseCustomDateRange={() => {
                            setCustomDateRangeOpen(false);
                        }}
                        dateRange={dateRange}
                        onDateRangeChange={onDateRangeChange}
                        getData={() =>
                            getData({
                                setCustomDateRangeOpen,
                                dateRange,
                                userAuthTimezone,
                                params,
                                navigate,
                                url: routeRoot,
                            })
                        }
                    />

                    <SortActions
                        direction={createdOrder}
                        onToggleSort={() => {
                            createdOrderFilter();
                        }}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CREATED_BY) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Created By
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Last Modified
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED_BY) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Last Modified By
                </SemanticTable.HeaderCell>
            )}
            {account_id && (
                <SemanticTable.HeaderCell className='ModulesListTable__LargeFixedWidth' width={4}>
                    Contact
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_ACCOUNT) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Account
                </SemanticTable.HeaderCell>
            )}
            <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                ID
            </SemanticTable.HeaderCell>
            {!isColumnHidden(KEY_STAGE) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Pipeline/Stage
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_OUTCOME) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Outcome
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_LOST_REASON) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Lost Reason/Type
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_LEAD_TYPE) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Type
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_PRODUCT_INTEREST) && account_id ? (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    <ClearableCheckboxDropdown
                        text='Product Interests'
                        name='product_interests'
                        options={productInterestOptions.map(({ id, name }) => ({
                            key: id,
                            value: id?.toString(),
                            text: name
                        }))}
                        onChange={(_, { value }) => { onClickInterest(value) }}
                        value={params.get('product_interest_ids')?.split(',')}
                        closeOnLoad={true}
                    />
                </SemanticTable.HeaderCell> ) :
                ( <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Product Interests
                </SemanticTable.HeaderCell> )
            }
            {!isColumnHidden(KEY_LEAD_PRIORITY) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Priority
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_LEAD_RATING) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Rating
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_LEAD_SCORE) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Score
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_MASTER_SECONDARY) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Master/Secondary Source
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CAMPAIGN_SOURCE) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Campaign Converted
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CLOSE_PROBABILITY) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Close Probability
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_TOTAL_COST) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Total Cost
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_ACQUISITION_COST) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Acquisition Costs
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_ADDITIONAL_COST) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Additional Costs
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_EXPECTED_REVENUE) && (
                <SemanticTable.HeaderCell className='ModulesListTable__MediumFixedWidth' width={2}>
                    Expected Revenue
                </SemanticTable.HeaderCell>
            )}
        </Table.Row>
    )
}

export default LeadsHeader;
