import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import NotesModal from 'shared/modals/NotesModal';

import './index.scss';
import { ClippedContent } from 'dyl-components';

const Account = ({ name, id, linkToAccount, isActionsVisible, account }) => {
    const [modal, setModal] = useState("");

    const getModal = () => {
        switch (modal) {
            case "note":
                return <NotesModal
                    open
                    onClose={() => { setModal("") }}
                    account={account}
                />;
            default:
                <React.Fragment />
        }
    }

    return (
        <>
            <div className='AccountComponent'>
                {name && <Avatar name={name} maxInitials={2} round size='3em' />}
                <div className="AccountComponent__information">
                    <span className='AccountComponent__Link'>
                        <ClippedContent>{linkToAccount}</ClippedContent>
                        <span className="AccountComponent__Subtitle">{id && `ID: ${id}`}</span>
                    </span>
                    {isActionsVisible ? (
                        <div className='AccountComponent__Actions'>
                            <Icon className='fa-solid fa-phone' link />
                            <Icon className='fa-solid fa-messages' link />
                            <Icon className='fa-solid fa-envelope' link />
                            <Icon className='fa-sharp fa-solid fa-pen-clip' link onClick={() => { setModal("note") }} />
                        </div>
                    ) : (
                        <div className='AccountComponent__MasterAccountActions'>
                            <Icon className='fa-sharp fa-solid fa-pen-clip' link onClick={() => { setModal("note") }} />
                        </div>
                    )}
                </div>
            </div>
            {getModal()}
        </>
    );
}

export default Account;
