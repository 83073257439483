import { CardData, CardDataItem } from 'shared/CardData';
import { Header, Icon } from 'semantic-ui-react';
import { ButtonLink } from 'dyl-components';

export const ReportsAndLogsToolbar = ({ rejected = 0, partial = 0, onFilter }) => (
  <div className='ReportsAndLogsToolbar'>
    <CardData>
      <CardDataItem>
        <Icon className='fas fa-exclamation-triangle' /> <Header as='h4' style={{ margin: 0 }}>Rejected: <ButtonLink onClick={(_) => { onFilter(_,{ name: 'status', value: ['Rejected'] }, 'status') }}> {rejected}</ButtonLink></Header>
      </CardDataItem>
      <CardDataItem>
        <Icon className='fas fa-diagram-project' /> <Header as='h4' style={{ margin: 0 }}>Partially Accepted: <ButtonLink onClick={(_) => { onFilter(_,{ name: 'status', value: ['Partial'] }, 'status') }}> {partial}</ButtonLink></Header>
      </CardDataItem>
    </CardData>
  </div>
);
