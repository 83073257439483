import React from 'react';
import { GridTimeline, CollapsibleContent } from 'dyl-components';
import { Checkbox, Grid } from 'semantic-ui-react';
import { DateTimeUtils } from 'dyl-components';

const STATUS = {
    'Upcoming': 'upcoming',
    'Received': 'received',
    'Sent': 'sent',
}

const getStatus = (direction, occurred) => {
    if (!DateTimeUtils.isBeforeOrOnCurrentDate(DateTimeUtils.formatEpoch(occurred, DateTimeUtils.DATETIME_FORMAT), DateTimeUtils.DATETIME_FORMAT)) {
        return "Upcoming"
    }
    return direction === 'inbound' ? 'Received' : 'Sent';
}

const TextTimeline = ({ items, expanded, onToggleText }) => (
    <div className='TextsTab__Timeline'>
        <GridTimeline
            expanded={expanded}
            items={items.map(item => {
                const date = DateTimeUtils.formatEpoch(item.occurred, 'ddd MM/DD');
                const time = DateTimeUtils.formatEpoch(item.occurred);
                return ({
                    date,
                    time,
                    otherControls: <Checkbox checked={item.completed} onClick={(e, { checked }) => onToggleText(e, { checked, id: item.id })} className='TextsTab__otherControls' />,
                    itemComponent: <Grid className='TextsTab__Info'>
                        <Grid.Row columns='equal' style={{ padding: 0 }}>
                            <Grid.Column verticalAlign='middle'>
                                <span className={`Info__name Info__name--${item.completed ? 'selected' : ''}`}>
                                    Text {(item.direction)}
                                </span>
                                <div className={`Info__description Info__description--${item.completed ? 'selected' : ''}`}>
                                    <CollapsibleContent width={'80%'} content={item.messages} />
                                </div>

                            </Grid.Column>
                            <Grid.Column width={2} verticalAlign='middle'>
                                <span className={`Info__status Info__status--${STATUS[getStatus(item.direction, item.occurred)]}`}>
                                    {getStatus(item.direction, item.occurred)}
                                </span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>,
                    markerColor: '#af5fe6',
                    selected: item.completed
                });
            })} />
    </div>
);

export default TextTimeline;
