import React from 'react';
import { Icon, Button, Popup, Select, Grid } from 'semantic-ui-react';
import { TableWithHeader, ControlledPopup, Table } from 'dyl-components';
import { MOCK_LEAD_ROUTE } from '__mocks__/mockData';
import { STATUS_TYPES, Notification } from 'dyl-components';
import { Link } from 'react-router-dom';

import ModalRouteTemplates from './ModalRouteTemplates';

class LeadRouteRowForm extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            source: 'Any',
            type: 'Any',
            distributionType: 'Auto Assign',
            routeDestination: 'All Users',
            distributionMethod: 'Round Robin',
            workflow: 'Lead Connect'
        }
    }

    onChange = (_, {name, value}) => {
        this.setState({
            [name]: value
        })
    }

    render(){
        const { isCreateRouteOpen, onCancelClick, onConfirmClick } = this.props;
        const {
            source, 
            type,
            distributionType, 
            routeDestination,
            distributionMethod, 
            workflow         
        } = this.state;

        return(
            <Table.Row style={{display: isCreateRouteOpen ? 'table-row' : 'none'}}>
                <Table.Cell>
                    <Select options={[
                        { key: 'Any', value: 'Any', text: 'Any' }
                    ]} 
                    value={source}
                    onChange={this.onChange}
                    name='source'/>
                </Table.Cell>
                <Table.Cell>
                    <Select options={[
                        { key: 'Any', value: 'Any', text: 'Any' }
                    ]} 
                    value={type}
                    onChange={this.onChange}
                    name='type'/>
                </Table.Cell>
                <Table.Cell>
                    <Select options={[
                        { key: 'Auto Assign', value: 'Auto Assign', text: 'Auto Assign' }
                    ]} 
                    value={distributionType}
                    onChange={this.onChange}
                    name='distributionType'/>
                </Table.Cell>
                <Table.Cell>
                    <Select options={[
                        { key: 'All Users', value: 'All Users', text: 'All Users' }
                    ]} 
                    value={routeDestination}
                    onChange={this.onChange}
                    name='routeDestination'/>
                </Table.Cell>
                <Table.Cell>
                    <Select options={[
                        { key: 'Round Robin', value: 'Round Robin', text: 'Round Robin' }
                    ]} 
                    value={distributionMethod}
                    onChange={this.onChange}
                    name='distributionMethod'/>
                </Table.Cell>
                <Table.Cell>
                    <Select options={[
                        { key: 'Lead Connect', value: 'Lead Connect', text: 'Lead Connect' }
                    ]} 
                    value={workflow}
                    onChange={this.onChange}
                    name='workflow'/>
                </Table.Cell>
                <Table.Cell>
                    <Popup content='Cancel' inverted trigger={<Icon name='close' color='red' onClick={onCancelClick} link />} />
                    <Popup content='Confirm' inverted trigger={<Icon name='checkmark' color='blue' link 
                        onClick={() => onConfirmClick({...this.state})} />} />
                </Table.Cell>
          </Table.Row>
        )
    }
}

const LeadRouteRowViewInfo = ({
    id,
    source,
    type,
    distributionType,
    routeDestination,
    distributionMethod,
    workflow,

    isEditMode,
    onDisableEditMode,
    onDeleteClick,
    onEditClick,
    toggleModalRouteTemplates
}) => {
    return(
    <Table.Row>
        <Table.Cell>{source}</Table.Cell>
        <Table.Cell>{type}</Table.Cell>
        <Table.Cell>{distributionType}</Table.Cell>
        <Table.Cell>{routeDestination}</Table.Cell>
        <Table.Cell>{distributionMethod}</Table.Cell>
        <Table.Cell>{workflow}</Table.Cell>
        <Table.Cell>
            <Popup content='Edit Templates' inverted trigger={<Icon name='file alternate' color='grey' link
                onClick={toggleModalRouteTemplates} />} />
            <ControlledPopup 
                trigger={<Icon name='trash alternate' color='grey'  link />}
                onConfirm={() => onDeleteClick(id)}
                onCancel={onDisableEditMode}
            />
            <Popup content='Edit' inverted trigger={<Icon name='pencil' color='grey' link
                onClick={() => onEditClick(id)} />} />
        </Table.Cell>
    </Table.Row>
    )
}

class LeadRouteRowViewEdit extends React.Component{
    constructor(props){
        super(props);

        const {
            source,
            type,
            distributionType,
            routeDestination,
            distributionMethod,
            workflow,
        } = props;

        this.state = {
            source: source,
            type: type,
            distributionType: distributionType,
            routeDestination: routeDestination,
            distributionMethod: distributionMethod,
            workflow: workflow
        }
    }

    onChange = (_, {name, value}) => {
        this.setState({
            [name]: value
        })
    }

    render(){
        const {
            source,
            type,
            distributionType,
            routeDestination,
            distributionMethod,
            workflow,
        } = this.state;

        const { id, onEditCancelClick, onEditConfirmClick } = this.props;

        return(
            <Table.Row>
                <Table.Cell>
                    <Select options={[
                        { key: 'Any', value: 'Any', text: 'Any' },
                        { key: 'Any2', value: 'Any2', text: 'Any2' }
                    ]} 
                    value={source}
                    onChange={this.onChange}
                    name='source'/>
                </Table.Cell>
                <Table.Cell>
                    <Select options={[
                        { key: 'Any', value: 'Any', text: 'Any' }
                    ]} 
                    value={type}
                    onChange={this.onChange}
                    name='type'/>
                </Table.Cell>
                <Table.Cell>
                    <Select options={[
                        { key: 'Auto Assign', value: 'Auto Assign', text: 'Auto Assign' }
                    ]} 
                    value={distributionType}
                    onChange={this.onChange}
                    name='distributionType'/>
                </Table.Cell>
                <Table.Cell>
                    <Select options={[
                        { key: 'All Users', value: 'All Users', text: 'All Users' }
                    ]} 
                    value={routeDestination}
                    onChange={this.onChange}
                    name='routeDestination'/>
                </Table.Cell>
                <Table.Cell>
                    <Select options={[
                        { key: 'Round Robin', value: 'Round Robin', text: 'Round Robin' }
                    ]} 
                    value={distributionMethod}
                    onChange={this.onChange}
                    name='distributionMethod'/>
                </Table.Cell>
                <Table.Cell>
                    <Select options={[
                        { key: 'Lead Connect', value: 'Lead Connect', text: 'Lead Connect' }
                    ]} 
                    value={workflow}
                    onChange={this.onChange}
                    name='workflow'/>
                </Table.Cell>
                <Table.Cell>
                    <Popup content='Cancel' inverted trigger={<Icon name='close' color='red'  link
                        onClick={() => onEditCancelClick(id)} />} />
                    <Popup content='Confirm' inverted trigger={<Icon name='checkmark' color='blue' link 
                         onClick={() => onEditConfirmClick(id, {...this.state})} />} />
                </Table.Cell>
            </Table.Row>
        )
    }
}


class TableSingleLine extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            isEditMode: false
        }
    }

    onDisableEditMode = () => {
        this.setState({
            isEditMode: false,
        })
    }

    formLeadRouteItems = () => {
        const { leadRouteItems, onDeleteClick, onEditClick, onEditCancelClick, onEditConfirmClick, toggleModalRouteTemplates } = this.props;
        const { isEditMode } = this.state;

        return leadRouteItems.map(leadRouteItem => {
            if(leadRouteItem.edit){
                return(
                    <LeadRouteRowViewEdit 
                        key={leadRouteItem.id} 
                        id={leadRouteItem.id}
                        source={leadRouteItem.source}
                        type={leadRouteItem.type}
                        distributionType={leadRouteItem.distributionType}
                        routeDestination={leadRouteItem.routeDestination}
                        distributionMethod={leadRouteItem.distributionMethod}
                        workflow={leadRouteItem.workflow}

                        onEditCancelClick={onEditCancelClick}
                        onEditConfirmClick={onEditConfirmClick}
                    />
                )
            }else{
                return(
                    <LeadRouteRowViewInfo
                        key={leadRouteItem.id} 
                        id={leadRouteItem.id}
                        source={leadRouteItem.source}
                        type={leadRouteItem.type}
                        distributionType={leadRouteItem.distributionType}
                        routeDestination={leadRouteItem.routeDestination}
                        distributionMethod={leadRouteItem.distributionMethod}
                        workflow={leadRouteItem.workflow}

                        isEditMode={isEditMode} 
                        onDisableEditMode={this.onDisableEditMode}
                        onDeleteClick={onDeleteClick}       
                        onEditClick={onEditClick}        
                        toggleModalRouteTemplates={toggleModalRouteTemplates}
                    />
                )
            }
        })
    }

    render(){
        const {isCreateRouteOpen, onCancelClick, onConfirmClick} = this.props;
        const LeadRouteItems = this.formLeadRouteItems();

        return(
            <Table singleLine>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Source</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Distribution Type</Table.HeaderCell>
                        <Table.HeaderCell>Route Destination</Table.HeaderCell>
                        <Table.HeaderCell>Distribution Method</Table.HeaderCell>
                        <Table.HeaderCell>Workflow</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            
                <Table.Body>
                    <LeadRouteRowForm
                        isCreateRouteOpen={isCreateRouteOpen} 
                        onCancelClick={onCancelClick}
                        onConfirmClick={onConfirmClick} />
                    {LeadRouteItems}
                </Table.Body>
            </Table>
        )
    }
}

class LeadRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCreateRouteOpen: false,
            leadRouteItems: MOCK_LEAD_ROUTE,
            modalRouteTemplatesOpen: false,
        }
    }

    onCreateRouteClick = () => {
        this.setState({
            isCreateRouteOpen: true
        })
    }

    onCancelClick = () => {
        this.setState({
            isCreateRouteOpen: false
        })
    }

    onConfirmClick = (data) => {
        const { leadRouteItems } = this.state;

        data.id = leadRouteItems.length + 1;

        this.setState({
            leadRouteItems: [
                ...this.state.leadRouteItems,
                data
            ],
            isCreateRouteOpen: false
        });

        Notification.alert('Lead Routing Added', STATUS_TYPES.SUCCESS, true);
    }

    onDeleteClick = (id) => {
        let leadRouteItems = this.state.leadRouteItems.slice(0);
        let indexOfLeadRouteItemToDelete = leadRouteItems.findIndex(leadRouteItem => leadRouteItem.id === id);
        
        if (indexOfLeadRouteItemToDelete !== -1) {
            leadRouteItems.splice(indexOfLeadRouteItemToDelete, 1);
            Notification.alert('Removed Lead Routing', STATUS_TYPES.SUCCESS, true);
            this.setState({ leadRouteItems });
        }
    }

    onEditClick = (id) => {
        let leadRouteItems = this.state.leadRouteItems.slice(0);
        let indexOfLeadRouteItem = leadRouteItems.findIndex(leadRouteItem => leadRouteItem.id === id);
        
        if (indexOfLeadRouteItem !== -1) {
            leadRouteItems[indexOfLeadRouteItem].edit = true;
            this.setState({ leadRouteItems });
        }
    }

    onEditCancelClick = (id) => {
        let leadRouteItems = this.state.leadRouteItems.slice(0);
        let indexOfLeadRouteItem = leadRouteItems.findIndex(leadRouteItem => leadRouteItem.id === id);
        
        if (indexOfLeadRouteItem !== -1) {
            leadRouteItems[indexOfLeadRouteItem].edit = false;
            this.setState({ leadRouteItems });
        }
    }

    onEditConfirmClick = (id, data) => {
        let leadRouteItems = this.state.leadRouteItems.slice(0);
        let indexOfLeadRouteItem = leadRouteItems.findIndex(leadRouteItem => leadRouteItem.id === id);
        
        if (indexOfLeadRouteItem !== -1) {
            leadRouteItems[indexOfLeadRouteItem] = data;
            this.setState({ leadRouteItems });
        }
    }

    toggleModalRouteTemplates = () => this.setState({ modalRouteTemplatesOpen: !this.state.modalRouteTemplatesOpen })

    render() {
        const { isCreateRouteOpen, leadRouteItems, modalRouteTemplatesOpen } = this.state;

        return (
            <React.Fragment>
                <TableWithHeader 
                    header={
                        <Grid verticalAlign='middle'>
                            <Grid.Column width={6}>
                                <strong>Lead Route</strong>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign='right' floated='right'>
                                <Button basic inverted color='white' size='tiny' onClick={this.onCreateRouteClick}>Create Route</Button>
                                <Button as={Link} to="/settings/users" basic inverted color='white' size='tiny'>Back To Users</Button>
                            </Grid.Column>
                        </Grid>
                    }
                    table={
                        <TableSingleLine
                            isCreateRouteOpen={isCreateRouteOpen}
                            leadRouteItems={leadRouteItems}
                            onCancelClick={this.onCancelClick} 
                            onConfirmClick={this.onConfirmClick}
                            onDeleteClick={this.onDeleteClick}
                            onEditClick={this.onEditClick}
                            onEditCancelClick={this.onEditCancelClick}
                            onEditConfirmClick={this.onEditConfirmClick}
                            toggleModalRouteTemplates={this.toggleModalRouteTemplates}/>
                    }
                    className='TableWithHeader'/>
                    <ModalRouteTemplates 
                        modalOpen={modalRouteTemplatesOpen}
                        closeModal={this.toggleModalRouteTemplates}
                    />
            </React.Fragment>
        )
    }
}

export default LeadRoute;
