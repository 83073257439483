import React from 'react';
import { Grid, Pagination } from 'semantic-ui-react';
import { TableWithTools } from 'dyl-components';
import CRMActivityFilter from './subcomponents/CRMActivityFilter';
import CRMActivityToolbar from './subcomponents/CRMActivityToolbar';
import CRMActivityTable from './subcomponents/CRMActivityTable';

const MOCK_CRM_ACTIVITY = [
    {lead_id: 1, id: 1, ts: '2/21/19 7:57 PM', crm: 'Vertafore', event: 'Customer Lookup',
        first_name: 'Sample', last_name: 'Lead'
    },
    {lead_id: 2, id: 2, ts: '2/21/19 7:57 PM', crm: 'Vertafore', event: 'Customer Lookup',
        first_name: 'Test', last_name: 'Lead', phone: '(614) 333-2210' 
    },
    {lead_id: 3, id: 3, ts: '2/21/19 7:57 PM', crm: 'Vertafore', event: 'Customer Lookup',
        first_name: 'Chris', last_name: 'Jones', phone: '(614) 333-2210'
    },
    {lead_id: 4, id: 4, ts: '2/21/19 7:57 PM', crm: 'Vertafore', event: 'Customer Lookup',
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210'
    },
    {lead_id: 5, id: 5, ts: '2/21/19 7:57 PM', crm: 'Vertafore', event: 'Customer Lookup',
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210'
    },
    {lead_id: 6, id: 6, ts: '2/21/19 7:57 PM', crm: 'Vertafore', event: 'Customer Lookup',
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210'
    },
    {lead_id: 7, id: 7, ts: '2/21/19 7:57 PM', crm: 'Vertafore', event: 'Customer Lookup',
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210'
    },
    {lead_id: 8, id: 8, ts: '2/21/19 7:57 PM', crm: 'Vertafore', event: 'Customer Lookup',
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210'
    }
];

const CRMActivity = ({ activities }) => (
    <Grid>
        <Grid.Row>
            <Grid.Column>
                <CRMActivityFilter />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <TableWithTools 
                    pagination={
                        <Pagination
                            boundaryRange={0}
                            defaultActivePage={1}
                            ellipsisItem={null}
                            firstItem={null}
                            lastItem={null}
                            siblingRange={2}
                            totalPages={Math.ceil(activities.length/10)}
                        />
                    }
                    toolbar={<CRMActivityToolbar numberOfActivities={activities.length} />}
                    table={<CRMActivityTable activities={activities} />}
                />
            </Grid.Column>
        </Grid.Row>
    </Grid>
)

class CRMActivityContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activities: []
        }
    }

    componentDidMount() {
        this.setState({
            activities: MOCK_CRM_ACTIVITY
        })
    }

    render() {
        return (
            <CRMActivity 
                {...this.state}
            />
        )
    }
}

export default CRMActivityContainer;
