import { createContext, useState } from "react";

export const CampaignsContext = createContext();

const initialState = {
    selectedCampaign: null,
    action: null,
    ref: null
};

const CampaignsContextProvider = ({ children }) => {
    const [state, setState] = useState(initialState)

    const onSelectCampaign = (campaignID, action, ref) => {
        setState(state => ({
            ...state,
            selectedCampaign: campaignID,
            action,
            ref
        }))
    }

    const onClearSelected = () => {
        setState(state => ({
            ...state,
            ...initialState
        }))
    }

    return (
        <>
            <CampaignsContext.Provider value={{
                onSelectCampaign,
                state,
                onClearSelected
            }}>
                {children}
            </CampaignsContext.Provider>
        </>
    );
}

export default CampaignsContextProvider;
