import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function fieldAutocompleteReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.FIELD_AUTOCOMPLETE, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingAutocompleteResults: true };
        case getNamedAction(ACTION_NAMES.FIELD_AUTOCOMPLETE, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingAutocompleteResults: false, results: action.data['data-field-suggest'][0].options };
        case getNamedAction(ACTION_NAMES.FIELD_AUTOCOMPLETE, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingAutocompleteResults: false };

        default:
            return state;
    }
}


export default fieldAutocompleteReducer;
