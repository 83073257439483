import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function customFieldsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, groupWhereFieldIsBeingAdded: action.id };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, groupWhereFieldIsBeingAdded: null };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, groupWhereFieldIsBeingAdded: null };

        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS_MAPPING, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingMappingFields: true, mapping_fields: null };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS_MAPPING, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingMappingFields: false, mapping_fields: action.data };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS_MAPPING, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingMappingFields: false, mapping_fields: null };

        case getNamedAction(ACTION_NAMES.AUTOMATION_FIELDS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingMappingFields: true, automation_fields: null };
        case getNamedAction(ACTION_NAMES.AUTOMATION_FIELDS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingMappingFields: false, automation_fields: action.data };
        case getNamedAction(ACTION_NAMES.AUTOMATION_FIELDS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingMappingFields: false, automation_fields: null };

        default:
            return state;
    }
}


export default customFieldsReducer;
