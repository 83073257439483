import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { generateResolver, yup, DateTimeUtils, Notification, STATUS_TYPES, VALIDATORS } from "dyl-components";
import { useDispatch, useSelector } from "react-redux";
import taskActions from 'actions/task';
import { useParams } from "react-router-dom";
import tasksActions from 'actions/tasks';
import uploadActions from 'actions/upload';
import taskAttachmentActions from 'actions/task_attachment';
import { StringUtils } from 'utils';
import taskTypesActions from "actions/task_types";
import Utils from "shared/NewTasksTab/subcomponents/Utils";

const useTaskForm = ({isContact = true, isModal = false, open}) => {
    const [isAllowedToModify, setIsAllowedToModify] = useState(false);
    const [state, setState] = useState({
        isFormTabOpen: true,
        activeItem: 'all',
        formKey: isContact ? 'CREATE_FORM' : '',
        saving: false
    });
    let { contact_id } = useParams();
    contact_id = Number(contact_id);
    const dispatch = useDispatch();
    const account_id = useSelector(state => state.contact.account_id);
    const { organizer_id, taskTypes, myTimeZone, user_id } = useSelector(state => ({
        organizer_id: state.auth.user_id,
        taskTypes: state.task_types.taskTypes,
        myTimeZone: state.auth.timezone,
        user_id: state.auth.user_id,
    }));

    const getTextProperties = (values) => {
        if (values.translate_values) {
            const { content, direction } = values.task_meta;
            return {
                message: content,
                direction
            }
        }
        const { message, direction } = values;
        return {
            task_meta: {
                content: message,
                content_type: 'text/plain',
                direction
            }
        };
    }

    const getEmailProperties = (values) => {
        if (values.translate_values) {
            const { subject, content, direction, signature } = values.task_meta;
            return {
                subject,
                body: content,
                direction,
                signature
            }
        }
        const { subject, direction, body, signature } = values;
        return {
            task_meta: {
                direction,
                subject,
                content: body,
                signature,
                content_type: 'text/html'
            }
        };
    }

    const getCallProperties = (values) => {
        if (values.translate_values) {
            const task_meta = values.task_meta || { task_call_meta: {} };
            const task_call_meta = task_meta.task_call_meta || { call_duration: 0, call_result: 'reached' };
            const [hours, minutes] = DateTimeUtils.getNumberOfHoursAndMinutes(parseInt(task_call_meta.call_duration) * 60).split(":");
            return {
                direction: task_meta.direction,
                call_result: task_call_meta.call_result,
                hours: parseInt(hours),
                minutes: parseInt(minutes)
            }
        }
        const { direction } = values;
        return {
            task_meta: { direction }
        };
    }

    const getTaskTypeSpecificPayload = (task_type, values) => {
        switch (task_type) {
            case 'Text':
                return getTextProperties(values);
            case 'Email':
                return getEmailProperties(values);
            case 'Call':
                return getCallProperties(values);
            default:
                return {
                    task_meta: {}
                }
        }
    }

    const getTotalFileSize = files => files.reduce((a, b) => a + b.size || 0, 0);

    const TOTAL_FILE_SIZE_LIMIT = 10485760;

    const getCorrectedTaskTypeName = (task_type_name) => {
        if (task_type_name?.includes('Task')) {
            const processed_name = task_type_name.split(' ');
            processed_name.pop();
            task_type_name = processed_name.join(' ');
        }
        if (task_type_name === 'To Do') {
            task_type_name = 'To-Do'
        }
        return task_type_name;
    }

    const getTaskTypeId = useCallback((task_type_name) => {
        // TODO: remove this once all tasks are deleted and 
        // unneeded task types are deleted as well
        task_type_name = getCorrectedTaskTypeName(task_type_name);

        return taskTypes.find(({ name }) => name === task_type_name)?.id;
    }, [taskTypes]);

    const formatTaskBeingEdited = (id, task, accountContactIds) => {
        const { first_name = '', last_name = '', email: assigner_email, user_id: assigner_id } = task.assigned_by || { email: '' };
        const date = DateTimeUtils.formatEpoch(task.ts, DateTimeUtils.WORD_DATE_FORMAT);
        const time = DateTimeUtils.formatEpoch(task.ts, DateTimeUtils.TIME_FORMAT);
        const completion_time = task.complete ? DateTimeUtils.formatEpoch(task.activity, DateTimeUtils.WORD_DATETIME_FORMAT) : '';
        const {
            first_name: assignee_first = '',
            last_name: assignee_last = '',
            email: assignee_email,
            user_id: assignee_id
        } = task.assigned_to || { email: '' };
        const assignee = `${assignee_first} ${assignee_last}`;
        const task_type = getCorrectedTaskTypeName(task.task_type?.name);
        const relatedTo = task.relation?.related_id? `${task.relation.related_type}-${task.relation.related_id}` : null;
        const taskBeingEdited = {
            relatedTo,
            ...task,
            completion_time,
            attachments: (task.attachments?.data || []),
            id,
            label: task.task_label?.id || null,
            label_name: task.task_label?.name || '',
            date,
            time,
            task_type,

            assigner: `${first_name} ${last_name}`,
            assigner_id,
            assigner_email,

            conference_line: task.conference_url,
            pin: task.conference_pin,
            phone_number: task.conference_phone,

            assignee,
            assignee_email,
            assignee_id,
            searchValue: assignee,
            contact: task.contact_id,
            ...getTaskTypeSpecificPayload(task_type, { ...task, translate_values: true })
        }
        return taskBeingEdited;
    }

    const formatTask = (task) => {
        const {
            id,
            name,
            relatedTo,
            task_type,
            date,
            time,
            priority,
            note,
            label,
            assignee_id,
            shareable,
            direction,
            complete,
            attachments,
            message,
            minutes,
            call_result,
            description,
            content,
            subject,
            hours,
            contact
        } = task || {}

        return {
            relatedTo: id ? relatedTo : null,
            ...task,
            name: id ? name : '',
            task_type: id ? task_type : 'To-Do',
            ...((() => {
                if (id) {
                    return {
                        date,
                        time
                    }
                }
                if (date && time) {
                    return {
                        date,
                        time
                    }
                }
                const timeDropdownDefaults = DateTimeUtils.getTimeDropdownDefaults();
                return {
                    date: timeDropdownDefaults.startDate,
                    time: timeDropdownDefaults.startTime
                }
            })()),
            note: id ? note || '' : '',
            priority: id ? priority || 'High' : 'High',
            label: id ? label || '' : '',
            user: id ? assignee_id || organizer_id : organizer_id,
            shareable: id ? shareable : false,
            direction: id ? direction : 'outbound',
            complete: id ? complete || false : false,
    
            attachments: id && (task_type === 'Email' || task_type === 'Text') ? attachments.map(attachment => ({
                path: attachment.name,
                id: attachment.id,
                file_id: attachment.file_id,
                name: attachment.name
            })) : [],
    
            message: id && (task_type === 'Text') ? message : '',
    
            hours: id && (task_type === 'Call') ? hours : 0,
            minutes: id && (task_type === 'Call') ? minutes : 0,
            call_result: id && (task_type === 'Call') ? call_result : null,
            description: id ? (task_type === 'Email' ? description || '' : content || '') : '',
            subject: id ? task_type === 'Email' ? subject || '' : '' : '',
            contact: id ? contact : null,
        };
    }

    const [taskBeingEdited, setTaskBeingEdited] = useState(formatTask(null));

    const { control, watch, formState: { isValid, isDirty }, trigger, setValue, getValues, clearErrors, setError, reset, resetField, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {...formatTask(null), isModal},
        resolver: generateResolver({
            name: yup.string().maxlength(41).required('This field is required').no_excessive_whitespaces().noemoji().minlength(2).no_whitespace_only(),
            complete: yup.boolean(),
            user: yup.number().required('This field is required'),
            date: yup.string().when("complete", {
                is: true,
                then: schema => schema.required('This field is required').test("valid_date", "Invalid date", (value => {
                    return DateTimeUtils.isValid(value, DateTimeUtils.WORD_DATE_FORMAT)
                })),
                otherwise: schema => schema.required('This field is required').test("valid_date", "Invalid date", (value => {
                    return DateTimeUtils.isValid(value, DateTimeUtils.WORD_DATE_FORMAT)
                })).test("is_before_current_date", "Invalid date", (value => {
                    const current_day = DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATE_FORMAT, false, false);
                    return !DateTimeUtils.dateIsBeforeAnotherDate(value, current_day, 'day', DateTimeUtils.WORD_DATE_FORMAT)
                }))
            }),
            time: yup.string().when("complete", {
                is: true,
                then: schema => schema.required('This field is required'),
                otherwise: schema => schema.test(
                    "is_before_current_date_and_time",
                    "Future date/time only",
                    (time, context) => {
                        const date = context.parent.date;
                        const current_time = DateTimeUtils.getCurrentDate(DateTimeUtils.TIME_FORMAT, false, false) //Need to have a time in order to compare dates, its undefined during first renders
                        const date_time = `${date} ${!!time ? time : current_time}`;
                        const current_datetime = DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATETIME_FORMAT, false, false);
                        return !DateTimeUtils.dateIsBeforeAnotherDate(date_time, current_datetime, 'minutes', DateTimeUtils.WORD_DATETIME_FORMAT);
                    }
                )
            }),
            attachments: yup.array().test("max_file_size_limit", "Total size exceeds 10MB", (value) => {
                return getTotalFileSize(value) < TOTAL_FILE_SIZE_LIMIT
            }),
            subject: yup.string().when("task_type", {
                is: 'Email',
                then: schema => schema.no_whitespace_only().required(),
            }),
            note: VALIDATORS.NOTE()
        })
    });

    const onUpdateTask = (id, payload) => {
        return dispatch(taskActions.updateTask(id, payload));
    }

    const onUpload = (file, file_type) => {
        return dispatch(uploadActions.upload(file, file_type))
    }

    const onAddAttachments = (attachments, user_id, task_id) => {
        return dispatch(taskAttachmentActions.addAttachments(attachments, { user_id }, task_id))
    }

    const onRemoveAttachment = (attachment_id, task_id, user_id) => {
        return dispatch(taskAttachmentActions.removeAttachment(attachment_id, { user_id, task_id }));
    }

    const onAddTask = (tasks) => {
        return dispatch(tasksActions.createTask(tasks));
    }

    const onDeleteTask = (id) => {
        return dispatch(taskActions.deleteTask(id));
    }

    const onCompleteTask = (id, payload) => {
        return dispatch(taskActions.completeTask(id, payload));
    }

    useEffect(() => {
        const onReadTaskLabels = (task_type_id) => {
            return dispatch(taskActions.readTaskTypeLabels(task_type_id));
        }

        const onReadTaskTypes = () => {
            return dispatch(taskTypesActions.readTaskTypes());
        }

        const readLabels = async () => {
            if (!taskBeingEdited.id) {
                await onReadTaskTypes()
                onReadTaskLabels(getTaskTypeId('To-Do'));
            }
        }

        readLabels();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskBeingEdited, open, dispatch]);

    const getCallCompletionDetails = (values) => {
        const { hours, minutes, call_result } = values;
        const call_duration = parseInt((StringUtils.isEmpty(hours) ? 0 : hours) * 60) + parseInt(StringUtils.isEmpty(minutes) ? 0 : minutes);
        return { call_result, call_duration };
    }

    const addAttachments = async (files, task_id, file_type) => {
        if (files && files.length > 0) {
            const toUpload = files.filter(file => file.id === undefined);
            const toCopy = files.filter(file => file.id).map(file => ({
                file_id: file.file_id,
                name: file.name
            }));
            const uploadedFiles = await Promise.all(toUpload.map(file => onUpload(file, file_type)));
            return onAddAttachments([
                ...uploadedFiles.map((file, index) => ({
                    file_id: file.id,
                    name: files[index].name
                })),
                ...toCopy
            ], user_id, task_id);
        }
        return Promise.resolve();
    }

    const removeAttachments = (files, task_id) => {
        if (!files) {
            files = [];
        }
        const toRemove = taskBeingEdited.attachments.filter(attachment => files.findIndex(file => file.id === attachment.id) === -1);
        if (toRemove.length <= 0) {
            return Promise.resolve();
        }
        return Promise.all(toRemove.map(file => onRemoveAttachment(file.id, task_id, user_id)));
    }

    const addTask = async (values, onRefresh, contactName) => {
        const { date, name, note, priority, task_type, time, user, attachments, label, contact, relatedTo } = values;
        let related_type, related_id;
        if(relatedTo){
            [related_type, related_id] = relatedTo.split("-");
            related_id = Number(related_id)
        }
        DateTimeUtils.setTimezone(myTimeZone);
        const ts = DateTimeUtils.getUnixTime(`${date} ${time}`, DateTimeUtils.WORD_DATETIME_FORMAT, myTimeZone);
        const task_type_id = getTaskTypeId(task_type);
        const newTasks = [{
            assigned_by: user_id,
            assigned_to: user,
            contact_id: isModal ? contact : contact_id, 
            contactName: contactName,
            ...(account_id !== 0 ? {account_id} : {}),
            related_type,
            related_id,
            name: name,
            note: note,
            priority: priority,
            task_type_id,
            ts: ts,
            ...(label ? { task_label_id: label } : {}),
            ...getTaskTypeSpecificPayload(getCorrectedTaskTypeName(task_type), values)
        }];

        try {
            setState({ ...state, saving: true, formKey: '' })
            const [task_id] = await onAddTask(newTasks);
            await addAttachments(attachments, task_id, 'pdf');
            Notification.alert('Successfully created task!', STATUS_TYPES.SUCCESS);
            onRefresh();
            setState({ ...state, saving: false, formKey: 'CREATE_FORM' });
        } catch (e) {
            console.log(e);
            setState({ ...state, saving: false });
            Notification.alert('Failed to create task', STATUS_TYPES.ERROR);
        }
    }

    const onUpdate = async (values, onRefresh, contactName) => {
        const { date, name, note, priority, task_type, time, user, attachments, label, complete, contact } = values;
        const ts = DateTimeUtils.getUnixTime(`${date} ${time}`, DateTimeUtils.WORD_DATETIME_FORMAT, myTimeZone);
        const { id, assigner_id } = taskBeingEdited;

        const task_type_id = getTaskTypeId(task_type);

        const payload = {
            task_id: id,
            assigned_by: assigner_id,
            assigned_to: user,
            contact_id: contact,
            contactName,
            name,
            note,
            priority,
            task_type_id,
            ts,
            ...(label ? { task_label_id: label } : {}),
            ...getTaskTypeSpecificPayload(getCorrectedTaskTypeName(task_type), values)
        }
		if (values.relatedTo) {
			let related_id;
			[payload.related_type, related_id] = values.relatedTo.split("-")
			payload.related_id = Number(related_id)
		}

        try {
            setState({ ...state, saving: true });
            await onUpdateTask(id, payload);
            const files = attachments || [];
            const filesToAdd = files.filter(file => file.id === undefined);
            await Promise.all([
                addAttachments(filesToAdd, id, 'pdf'),
                removeAttachments(files.filter(file => file.id), id)
            ])
            if (complete) {
                const { direction } = getTaskTypeSpecificPayload(getCorrectedTaskTypeName(task_type), values);
                await onCompleteTask(id, {
                    direction, ...(
                        task_type === 'Call' ? { task_call_meta: getCallCompletionDetails(values) } : {}
                    )
                });
            }
            Notification.alert('Successfully updated the task!', STATUS_TYPES.SUCCESS);
            onRefresh();
            setState({ ...state, saving: false, formKey: 'CREATE_FORM' });
        } catch (e) {
            console.log(e);
            setState({ ...state, saving: false });
            Notification.alert('Failed to update the task', STATUS_TYPES.ERROR);
        }
    }

    const onDelete = async (id, onRefresh) => {
        try {
            await onDeleteTask(id);
            Notification.alert('Successfully deleted task!', STATUS_TYPES.SUCCESS);
            onRefresh()
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to delete task.', STATUS_TYPES.ERROR)
        }
    }

    const loadTask = (task) => {
        setTaskBeingEdited(formatTask(task))
    }

    useEffect(() => {
        reset(taskBeingEdited || formatTask(null));
        resetField();
        const allowedToModify = Utils.isAllowedToModify(taskBeingEdited, organizer_id);
        setIsAllowedToModify(allowedToModify);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskBeingEdited, reset, resetField])
    

    return {
        control,
        watch,
        isValid,
        isDirty,
        trigger,
        setValue,
        getValues,
        clearErrors,
        setError,
        reset,
        resetField,
        taskBeingEdited,
        loadTask,
        addTask,
        state,
        setState,
        onDelete,
        onUpdate,
        handleSubmit,
        formatTaskBeingEdited, 
        onCompleteTask,
        getTaskTypeSpecificPayload,
        getCorrectedTaskTypeName,
        getCallCompletionDetails,
        isAllowedToModify
    }
}
export default useTaskForm;