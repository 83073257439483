import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Table as SemanticTable, Popup } from 'semantic-ui-react';

import { ButtonLink, CloseableContent, EmptyListPlaceholder, Table } from 'dyl-components';

import customFieldActions from 'actions/custom_field';
import CustomFieldGroup from './CustomFieldGroup';
import CustomFieldGroupForm from 'shared/forms/CustomFieldGroupForm';
import CustomFieldModal from 'shared/modals/CustomFieldModal';
import customFieldsActions from 'actions/custom_fields';

const CustomFields = ({
    group,
    subgroups,
    toggleSubgroup,
    isOpenSubgroup,
    refresh,
    isReading,

    addCustomGroupButtonRef,
    closeAddGroupForm,
    isAddGroupFormOpen,
    openAddGroupForm
}) => {
    const [fieldBeingEdited, setFieldBeingEdited] = useState(null);

    const onEditField = (field) => {
        setFieldBeingEdited(field);
    }

    const onCancelEdit = () => {
        setFieldBeingEdited(null);
    }

    const dispatch = useDispatch();

    const onUpdate = async (data) => {
        const { options, field_type } = data;
        const hasOptions = field_type === 'tagsinput' || field_type === 'picklist' || field_type === 'radio' || field_type === 'dropdown';
        const { categories } = data;
        const originalOptions = fieldBeingEdited?.options?.data?.filter(option => !option.deleted);
        const originalOptionsValues = originalOptions?.map(option => option.value) || [];
        if (hasOptions) {
            const toAdd = options ? options.filter(option => !originalOptionsValues.includes(option.name)) : [];
            const toRemove = fieldBeingEdited?.options?.data?.length ? fieldBeingEdited?.options?.data?.filter(originalOption => options.findIndex(option => option.name === originalOption.value) === -1) : [];
            return Promise.all([
                (() => {
                    if (toAdd.length > 0) {
                        return dispatch(customFieldActions.createFieldOptions(toAdd.map(option => ({ value: option.name })), null, fieldBeingEdited.id));
                    }
                    return Promise.resolve();
                })(),
                ...toRemove.map(({ id }) => dispatch(customFieldActions.removeFieldOption(id))),
                dispatch(customFieldActions.updateField(fieldBeingEdited.parent_group, {
                    field_type,
                    custom_group_id: fieldBeingEdited.parent_group,
                    description: data.description,
                    label: data.label
                }, null, fieldBeingEdited?.id))
            ])
        }
        return dispatch(customFieldActions.updateField(fieldBeingEdited.parent_group, {
            field_type,
            custom_group_id: fieldBeingEdited.parent_group,
            description: data.description,
            label: data.label,

            ...(field_type === 'dependency' ? {
                data: {
                    dependency_description: data.dependency_description,
                    dependency_label: data.dependency_label
                },
                option: categories.map(category => ({
                    ...(!category.new ? { id: category.id } : {}),
                    value: category.name,
                    name: category.name,
                    sub_options: category.options.map(option => ({
                        ...(!option.new ? { id: option.id } : {}),
                        value: option.name,
                        name: option.name,
                    }))
                }))
            } : {})
        }, null, fieldBeingEdited?.id));
    }

    const [isAddGroupFormModified, setIsAddGroupFormModified] = useState(false);

    const [groupWhereASubgroupIsToBeCreated, setGroupWhereASubgroupIsToBeCreated] = useState(null);
    const [isAddSubgroupFormModified, setIsAddSubgroupFormModified] = useState(false);

    const [groupBeingEdited, setGroupBeingEdited] = useState(null);
    const [isEditGroupFormModified, setIsEditGroupFormModified] = useState(false);

    const onCloseEditGroupForm = () => {
        setGroupBeingEdited(null);
        setIsEditGroupFormModified(false);
    }

    const openEditGroupForm = (group_id) => {
        setGroupBeingEdited(group_id);
        setIsAddGroupFormModified(false);
    }

    const onCloseAddGroupForm = () => {
        closeAddGroupForm();
        setIsAddGroupFormModified(false);
    }

    const onCloseAddSubgroupForm = () => {
        setGroupWhereASubgroupIsToBeCreated(null);
        setIsAddSubgroupFormModified(false);
    }

    const onOpenAddSubgroupForm = (group_id) => {
        setGroupWhereASubgroupIsToBeCreated(group_id);
        setIsAddSubgroupFormModified(false);
        closeAddGroupForm();
        setIsEditGroupFormModified(false);
        setGroupBeingEdited(null);
    }

    const onOpenAddGroupForm = () => {
        setGroupWhereASubgroupIsToBeCreated(null);
        setIsAddSubgroupFormModified(false);
        setIsEditGroupFormModified(false);
        setGroupBeingEdited(null);
    };

    const [groupWhereFieldIsToBeAdded, setGroupWhereFieldIsToBeAdded] = useState(null);

    const onOpenAddFieldModal = (parent_group) => {
        setGroupWhereFieldIsToBeAdded(parent_group);
        setGroupWhereASubgroupIsToBeCreated(null);
        closeAddGroupForm();
        setIsEditGroupFormModified(false);
        setGroupBeingEdited(null);
    }

    const onCloseAddFieldModal = () => {
        setGroupWhereFieldIsToBeAdded(false);
    }

    const onCreateField = async (data) => {
        const { options, field_type } = data;
        const hasOptions = field_type === 'tagsinput' || field_type === 'picklist' || field_type === 'radio' || field_type === 'dropdown';
        const { categories } = data;
        return dispatch(customFieldsActions.createField([{
            field_type,
            custom_group_id: groupWhereFieldIsToBeAdded,
            description: data.description,
            label: data.label,

            ...(field_type === 'dependency' ? {
                data: {
                    dependency_description: data.dependency_description,
                    dependency_label: data.dependency_label
                },
                option: categories.map(category => ({
                    value: category.name,
                    name: category.name,
                    sub_options: category.options.map(option => ({
                        value: option.name,
                        name: option.name,
                    }))
                }))
            } : {}),
            ...(hasOptions ? {
                option: options.map(option => ({
                    value: option.name
                }))
            } : {})
        }], null, groupWhereFieldIsToBeAdded));
    }

    const [expandedFields, setExpandedFields] = useState([]);

    const onToggleField = (field_id) => {
        setExpandedFields(expandedFields.includes(field_id) ? expandedFields.filter(id => id !== field_id) : [...expandedFields, field_id])
    }

    const isExpanded = field_id => expandedFields.includes(field_id);

    return (
        <React.Fragment>
            {fieldBeingEdited && (
                <CustomFieldModal
                    onClose={onCancelEdit}
                    onSave={onUpdate}
                    open={fieldBeingEdited !== null}
                    refresh={refresh}

                    field={fieldBeingEdited}
                    isEditing
                />
            )}
            {groupWhereFieldIsToBeAdded && (
                <CustomFieldModal
                    onClose={onCloseAddFieldModal}
                    onSave={onCreateField}
                    open={groupWhereFieldIsToBeAdded}
                    refresh={refresh}
                />
            )}
            <Popup
                context={addCustomGroupButtonRef}
                content={(
                    <CloseableContent onClose={onCloseAddGroupForm}>
                        <CustomFieldGroupForm
                            parent_group={group?.id}
                            refresh={refresh}
                            onModify={(isModified) => { setIsAddGroupFormModified(isModified) }}
                            onClose={onCloseAddGroupForm}
                        />
                    </CloseableContent>
                )}
                open={isAddGroupFormOpen}
                closeOnDocumentClick={!isAddGroupFormModified}
                onClose={onCloseAddGroupForm}
                onOpen={onOpenAddGroupForm}
                on='click'
                position='bottom left'
            />
            <Table striped={false} className="CustomFieldsTable" fixed>
                <SemanticTable.Header className='Table__header--primary'>
                    <Table.Row>
                        <SemanticTable.HeaderCell width={1} />
                        <SemanticTable.HeaderCell>
                            Group/Field Name
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell>
                            Field Type
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell>
                            Values
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell>
                            Description
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell>
                            {/* Display */}
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell>
                            {/* Linked Display */}
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell>
                            Actions
                        </SemanticTable.HeaderCell>
                    </Table.Row>
                </SemanticTable.Header>
                <Table.Body>
                    {!isReading && !subgroups?.length ? (
                        <Table.Row>
                            <Table.Cell colspan={8}>
                                <EmptyListPlaceholder
                                    title={'No Custom Groups Created'}
                                    subtitle={
                                        <span>
                                            Add a Custom Group by clicking on the
                                            <ButtonLink onClick={openAddGroupForm} disabled={isAddGroupFormOpen}>+ New Custom Group</ButtonLink> button on the top-right corner.
                                        </span>
                                    } wrapped={false}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ) : subgroups.map(subgroup => {
                        return (
                            <CustomFieldGroup
                                toggleGroup={toggleSubgroup}
                                group={subgroup}
                                isOpenGroup={isOpenSubgroup}
                                onEditField={onEditField}
                                parent_group={group?.id}
                                refresh={refresh}
                                groupWhereASubgroupIsToBeCreated={groupWhereASubgroupIsToBeCreated}
                                isAddGroupFormModified={isAddSubgroupFormModified}
                                onCloseAddGroupForm={onCloseAddSubgroupForm}
                                onModify={(isModified) => { setIsAddSubgroupFormModified(isModified) }}
                                onOpenAddGroupForm={onOpenAddSubgroupForm}
                                onOpenAddFieldModal={onOpenAddFieldModal}

                                groupBeingEdited={groupBeingEdited}
                                isEditGroupFormModified={isEditGroupFormModified}
                                onModifyEditGroupForm={(isModified) => { setIsEditGroupFormModified(isModified) }}
                                onCloseEditGroupForm={onCloseEditGroupForm}
                                openEditGroupForm={openEditGroupForm}

                                isExpanded={isExpanded}
                                onToggleField={onToggleField}
                            />
                        );
                    })}
                </Table.Body>
            </Table>
        </React.Fragment>
    )
}

export default CustomFields;
