import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Form } from 'semantic-ui-react';
import { CustomGroupUtils } from 'utils';
import { Controller } from 'react-hook-form';
import { ClippedContent } from 'dyl-components';

const ContactBusinessData = ({
    isEditing,
    control,
    setValue,

    textAlign = 'right',
}) => {
    const { industryOptions, business_details } = useSelector(state => {
        const custom_data = CustomGroupUtils.groupAndFlatten(state.contact.custom_data);
        const business_details = (custom_data.children['business_details'] || [])[0];
        
        const standard_groups = (state.contact.custom_data?.children || []).filter(group => group.standard);

        return {
            industryOptions: CustomGroupUtils.getFieldOptions((standard_groups.find(group => group.group_name === 'business_details')?.fields || []), 'industry_sector'),
            business_details: business_details?.fields
        };
    });
    
    const [industryValue, subIndustryValue] = `${business_details?.industry_sector || ''}`?.split(',').map(value => Number(value)) || ['', ''];

    const industry = industryOptions.find(option => option.value === industryValue);

    return (
        <Grid className='RecordInfo'>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Company Name
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        business_details?.company_name || 'None'
                    ) : (
                        <Controller
                            name='business_name'
                            control={control}
                            render={({ field: { name, onChange, value } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Enter company name'
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Department
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        business_details?.department || 'None'
                    ) : (
                        <Controller
                            name='department'
                            control={control}
                            render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Enter department'
                                    error={error?.message}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Reports To
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {business_details?.supervisor || 'None'}
                </Grid.Column>
            </Grid.Row>
            {!isEditing ? [
                <Grid.Row columns='equal'>
                    <Grid.Column>
                        Industry/Sector
                    </Grid.Column>
                    <Grid.Column textAlign={textAlign}>
                        {industry?.text || 'None'}
                    </Grid.Column>
                </Grid.Row>,
                <Grid.Row columns='equal'>
                    <Grid.Column>
                        Sub Industry
                    </Grid.Column>
                    <Grid.Column textAlign={textAlign}>
                        {industry?.sub_options?.find(option => option.value === subIndustryValue)?.text || 'None'}
                    </Grid.Column>
                </Grid.Row>
            ] : (
                <Controller
                    name={`industry_sector`}
                    control={control}
                    render={({ field: { name: industryName, onChange, value: industryValue } }) => [
                        <Grid.Row columns='equal'>
                            <Grid.Column>
                                Industry/Sector
                            </Grid.Column>
                            <Grid.Column textAlign={textAlign}>
                                <Form.Select
                                    name={industryName}
                                    value={industryValue}
                                    onChange={(_, { value }) => { onChange({ target: { name: industryName, value } }); setValue('sub_industry', ''); }}
                                    placeholder='Select industry/Sector'
                                    selectOnBlur={false}
                                    options={industryOptions}
                                    clearable
                                />
                            </Grid.Column>
                        </Grid.Row>,
                        <Grid.Row columns='equal'>
                            <Grid.Column>
                                Sub Industry
                            </Grid.Column>
                            <Grid.Column textAlign={textAlign}>
                                <Controller
                                    name={`sub_industry`}
                                    control={control}
                                    render={({ field: { name, onChange, value } }) => (
                                        <Form.Select
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                            placeholder='Select sub-industry'
                                            selectOnBlur={false}
                                            options={industryOptions?.find(industry => (industry.value === industryValue))?.sub_options || []} 
                                            clearable
                                        />
                                    )} />
                            </Grid.Column>
                        </Grid.Row>
                    ]}
                />
            )}
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Employee Count
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        business_details?.employee_count || 0
                    ) : (
                        <Controller
                            name='employee_size'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    type='number'
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <ClippedContent>
                        Products/Services Sold
                    </ClippedContent>
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        business_details?.product_services_sold || 'None'
                    ) : (
                        <Controller
                            name='product_services_sold'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Annual Revenue
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        business_details?.annual_revenue || 'None'
                    ) : (
                        <Controller
                            name='annual_revenue'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default ContactBusinessData;

