import React from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, Header, List, ListItem } from 'semantic-ui-react';

import ShareOptionFields from './ShareOptionFields';

const FormSettings = ({ 
    control, 
    isFormActive,
    url,
    embed
}) => (<React.Fragment>
        <Header as='h3' style={{ paddingBottom: '1em' }}>
            Publish Options
            <Header.Subheader>
                Select whether you'd like to publish or unpublish your form.
            </Header.Subheader>
        </Header>
        <List>
            <Controller
                name='active'
                control={control}
                render={({ field: { onChange, value: active, name } }) => (
                    <ListItem>
                        <List.Content>
                            <List.Header>
                                <Checkbox
                                    toggle
                                    onChange={(_, { checked }) => { onChange({ target: { name: 'active', value: checked } }); }}
                                    checked={active}
                                    label={active ? 'Active' : 'Inactive'}
                                />
                            </List.Header>
                            <List.Description style={{ paddingLeft: '4.5em', paddingTop: '0.5em' }}>
                                Your form is currently {active ? 'active and can be shared with the public' : 'inactive'}.
                            </List.Description>
                        </List.Content>
                    </ListItem>
                )} />
        </List>
        <Header as='h3'>
            Share Options
        </Header>
        <Header as='h4' style={{ marginTop: '0.5em', paddingBottom: '0.5em' }}>
            Copy and paste the below to share the form.
            <Header.Subheader>
                Responses will be recorded as person-contacts.
            </Header.Subheader>
        </Header>
        <ShareOptionFields value={url} label='URL' icon='fa-solid fa-link-simple' isFormActive={isFormActive} />
        <ShareOptionFields value={embed} label='Embed' icon='fa-solid fa-code-simple' isFormActive={isFormActive} />
    </React.Fragment>
);

export default FormSettings;
