import React from "react"
import { useSelector } from "react-redux";
import { Header, Label } from "semantic-ui-react";

const Groups = () => {
    const { contactGroupsCount, contactGroups } = useSelector((state) => ({
        contactGroupsCount: state.groups.contactGroupsCount,
        contactGroups: state.groups.contactGroups
    }));

    return (
        <div>
            <Header size='small' color='primary'>{`Groups ${contactGroupsCount}`}</Header>
            <div>
                {contactGroups.map((contactGroup) => (
                    <Label className='DataTab__label'>{contactGroup.name}</Label>
                ))}
            </div>
        </div>
    )
}

export default Groups;