import React from 'react';
import { Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import tagsActions from 'actions/tags';
import { TopPaddedControls } from 'dyl-components';
import { Notification, STATUS_TYPES } from 'dyl-components';
import tagCategoriesActions from 'actions/tag_categories';

const typeOptions = [
    { key: 'call', value: 'call', text: 'call' }
]

const AddTagSection = ({ name, category, type, onChange, onAddTag, onClose, categoryOptions }) => (
    <Form>
        <Form.Group>
            <Form.Input
                label='Tag Name'
                name='name'
                value={name}
                onChange={onChange}
            />
            <Form.Select
                label='Category'
                name='category'
                options={categoryOptions}
                value={category}
                onChange={onChange}
            />
            <Form.Select
                label='Type'
                name='type'
                options={typeOptions}
                value={type}
                onChange={onChange}
            />
            <TopPaddedControls>
                <Form.Button
                    floated='right'
                    content={'Cancel'}
                    onClick={onClose}
                />
                <Form.Button
                    primary
                    floated='right'
                    content={'Add'}
                    onClick={onAddTag}
                />
            </TopPaddedControls>
        </Form.Group>
    </Form>
)

class AddTagSectionContainer extends React.Component {
    state = {
        name: '',
        category: this.props.categoryOptions[0].value,
        type: 'call'
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onAddTag = () => {
        if (this.state.name.trim() === "") {
            Notification.alert(`The name for the tag can't be empty`, STATUS_TYPES.ERROR, true);
        } else {
            this.props.onAddTag({ ...this.state });
            this.setState({
                name: '',
                category: this.props.categoryOptions[0].value,
                type: 'call'
            });
        }
    }

    render() {
        return (
            <AddTagSection
                {...this.props}
                {...this.state}
                onAddTag={this.onAddTag}
                onChange={this.onChange}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        categoryOptions: state.tag_categories.listOfTagCategories.map(category => ({
            key: category.id, value: category.id, text: category.category
        })),
        isAddingCategory: state.tag_categories.isAddingTagCategory
    }
}

const mapDispatchToProps = dispatch => ({
    onAddTag: (newTag) => {
        dispatch(tagsActions.addTag(newTag))
            .then(() => {
                dispatch(tagsActions.getListOfTags());
                dispatch(tagCategoriesActions.getListOfTagCategories());
            });
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(AddTagSectionContainer);
