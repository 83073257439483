//Contains the unifying Field Types for display & validation relating to custom-fields & lead-ingest for BE and FE described in notes below.
//BE Validation on Primative data types, ie. text, int
//FE rough validation on Primative data types, and fine validation with yup and its exacts for givin field 
export const FIELD = {
    //simple validation
    TEXT: "text", //str
    TEXTAREA: "textarea", //str
    NUMBER: "number", //int
    PERCENT: "percent", //int or float
    CURRENCY: "currency", //int
    URL: "url", //str
    EMAIL: "email", //str
    PHONE: "phone", //int
    //complex validation
    DATE:"date", //str, check format 01-01-2023, before we convert to unix?
    TIME:"time", //str, check format 01-01-2023, before we convert to unix?
    DATE_RANGE: "daterange", //str, check format 01-01-2023 - 01-02-2023 , before we convert to unix?
    TIME_RANGE: "timerange", //str, check format 23:15:01 - 23:18:01 , before we convert to unix?
    CHECKBOX: "checkbox", //array of Obj ie.[{"Need Lead": true}]
    RADIO: "radio", //array of Obj ie.[{"Liability Insurance": true}]
    STATE: "state", //array of str ie.[STATES] from import { STATES } from 'shared/constants/STATES';
    PICKLIST: "picklist", //array of str ie.["10 miles"]
    TAGS: "tagsinput", //array of str ie.["green"]
    RATING: "rating", //array of str ie.["red"]
    DROPDOWN: "dropdown", //array of str ie.["4 doors"]
    DEPENDENCY: "dependency", //array of obj ie.[{"car":{"type":"4 doors"}}]
    
    //Review or post beta 
    /////////////////////
    USER: "user", // str ie. "Jody Albertson", need validate name via FE yup rules, then use exisiting BE endpoint to check if user exisits, if so use userID to send as payload. BE will validate whether it is int.  //Need Reqs, how does this apply to incoming data?

    // FORMULA: "formula", need reqs 
    // RELATION: "relation", ??
    // MULTI_SELECT_PICKLIST: "picklist", ??


    // CAMPAIGN_LOOKUP: "campaign", ?? Similar to USER
    // CONTACT_LOOKUP: "contact", ?? Similar to USER
    // ACCOUNT_LOOKUP: "account", ?? Similar to USER
};

export default [
    { value: FIELD.TEXT, text: "Text", icon: "fa-solid fa-text" },
    { value: FIELD.NUMBER, text: "Number", icon: "fa-solid fa-hashtag" },
    { value: FIELD.EMAIL, text: "Email", icon: "fa-solid fa-envelope" },
    { value: FIELD.PHONE, text: "Phone", icon: "fa-solid fa-phone-volume" },
    { value: FIELD.PERCENT, text: "Percent", icon: "fa-solid fa-percent" },
    { value: FIELD.CURRENCY, text: "Currency", icon: "fa-solid fa-dollar-sign" },
    { value: FIELD.URL, text: "URL", icon: "fa-solid fa-link-horizontal" },
    { value: FIELD.CHECKBOX, text: "Checkbox", icon: "fa-regular fa-square-check" },
    { value: FIELD.RADIO, text: "Radio", icon: "fa-solid fa-list-radio" },
    { value: FIELD.DATE, text: "Date", icon: "fa-solid fa-calendar-days" },
    { value: FIELD.TIME, text: "Time", icon: "fa-solid fa-clock" },
    { value: FIELD.DATE_RANGE, text: "Daterange", icon: "fa-solid fa-calendar-range" },
    { value: FIELD.TIME_RANGE, text: "Timerange", icon: "fa-solid fa-timer" },
    { value: FIELD.PICKLIST, text: "Picklist", icon: "fa-solid fa-list" },
    { value: FIELD.RATING, text: "Rating", icon: "far fa-thermometer-empty" },
    { value: FIELD.DROPDOWN, text: "Dropdown", icon: "fa-solid fa-circle-caret-down" },
    { value: FIELD.DEPENDENCY, text: "Dropdown (dependency)", icon: "fa-solid fa-list-dropdown" },
    { value: FIELD.STATE, text: "State", icon: "fas fa-location-dot" },
    { value: FIELD.TAGS, text: "Tags", icon: "fa-solid fa-tag" }, 
    // { value: FIELD.USER, text: "User", icon: "fa-solid fa-circle-user" }, 
]