import routes from 'actions/routes';
import { generateCreateActionCreator, generateUpdateActionCreator, generateReadActionCreator, generateReadOneActionCreator, generateDeleteActionCreator } from 'actions/crudActionCreators';

import ACTION_NAMES from 'actions/ACTION_NAMES';

export default {
    createTerritory: generateCreateActionCreator(routes.API_TERRITORY, ACTION_NAMES.TERRITORY, () => { }, () => { }),
    updateTerritory: generateUpdateActionCreator(routes.API_TERRITORY, ACTION_NAMES.TERRITORY),
    readTerritories: generateReadActionCreator(routes.API_TERRITORY, ACTION_NAMES.TERRITORY),
    deleteTerritory: generateDeleteActionCreator(routes.API_TERRITORY, ACTION_NAMES.TERRITORY),
    getTerritory: generateReadOneActionCreator(routes.API_TERRITORY, ACTION_NAMES.TERRITORY),
    checkIfDuplicate: generateReadActionCreator(`${routes.API_TERRITORY}/check_duplicate`, ACTION_NAMES.TERRITORY_DUPLICATE_CHECK),
    getValidOptions: generateCreateActionCreator(`${routes.API_TERRITORY}/check_territories`, ACTION_NAMES.TERRITORY_VALIDITY_CHECK),
    readCounties: generateCreateActionCreator(routes.API_TERRITORY_COUNTIES, ACTION_NAMES.TERRITORY_COUNTIES)
};
