import React from 'react';
import { TabAccordion } from 'dyl-components';
import EventLabels from './EventLabels';

class Events extends React.Component {
    state = {
        active: -1
    }

    onChangeTab = (_, { index }) => {
        this.setState(prevState => ({
            active: prevState.active === index ? -1 : index
        }));
    }

    render() {
        const items = [
            {
                title: 'Event Labels',
                description: `Create custom labels as a sub-category for your events.`,
                content: <EventLabels />
            }
        ]

        return (
            <TabAccordion header={'Events'} items={items} active={this.state.active} onChangeTab={this.onChangeTab} />
        );
    }
}

export default Events;
