import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function noteReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingNote: true };
        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingNote: false, note: action.data };
        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingNote: false };

        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingNote: true, noteBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingNote: false, noteBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingNote: false, noteBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTE, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingNote: true, noteBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTE, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingNote: false, noteBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.ACCOUNT_NOTE, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingNote: false, noteBeingDeleted: null };

        default:
            return state;
    }
}

export default noteReducer;
