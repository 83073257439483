import React from "react";
import { Checkbox, Transition, Form } from "semantic-ui-react";

const ringModeOptions = [
    { key: 'ringall', value: 'ringall', text: 'Ring All' },
    { key: 'roundrobin', value: 'roundrobin', text: 'Round Robin' },
]

const introMessageOptions = [{ key: "None", value: "None", text: "None" }];

const announceMessageOptions = [
    { key: "None", value: "None", text: "None" },
    {
        key: "Announce Position",
        value: "Announce Position",
        text: "Announce Position"
    }
];

const holdMusicOptions = [
    { key: "Ringing", value: "Ringing", text: "Ringing" },
    { key: "DYL Hold Music", value: "DYL Hold Music", text: "DYL Hold Music" }
];

const queueStrategyOptions = [
    { key: "Ring All", value: "Ring All", text: "Ring All" },
    { key: "Round Robin", value: "Round Robin", text: "Round Robin" },
    { key: "Random", value: "Random", text: "Random" },
    { key: "Longest Idle", value: "Longest Idle", text: "Longest Idle" }
];

const queueExitKeyOptions = [
    "None",
    "#",
    "*",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9"
].map(option => ({ key: option, value: option, text: option }));

const RingOptionsForm = ({ group, isEditMode, onChange, isRingGroup }) => (
    <Form>
        <Form.Group widths={3}>
            <Form.Input
                label="Name"
                value={group.label}
                name="label"
                onChange={onChange}
            />
            <Form.Select
                label="Ring Strategy"
                value={group.ring_strategy}
                name="ring_strategy"
                onChange={onChange}
                options={ringModeOptions}
            />
            <Form.Input
                value={group.ring_time}
                name="ring_time"
                type={"number"}
                max={600}
                min={5}
                label="Ring Time"
                onChange={onChange}
            />
        </Form.Group>
        <Transition
            unmountOnHide
            visible={!isRingGroup}
            animation="slide down"
            duration="100"
        >
            <div>
                <Form.Select
                    label="Introduction Message"
                    value={group.intro_message}
                    name="intro_message"
                    onChange={onChange}
                    options={introMessageOptions}
                />
                <Form.Group widths={3}>
                    <Form.Select
                        label="Hold Music"
                        value={group.hold_music}
                        name="hold_music"
                        onChange={onChange}
                        search
                        options={holdMusicOptions}
                    />
                    <Form.Select
                        label="Queue Strategy"
                        value={group.strategy}
                        name="strategy"
                        onChange={onChange}
                        options={queueStrategyOptions}
                    />
                    <Form.Select
                        label="Queue Exit Key"
                        value={group.exit_key}
                        name="exit_key"
                        onChange={onChange}
                        options={queueExitKeyOptions}
                    />
                </Form.Group>
                <Form.Checkbox
                    value={group.direct_extensions}
                    control={Checkbox}
                    onChange={(_, { name, checked }) => {
                        onChange(null, { name, value: checked });
                    }}
                    checked={group.direct_extensions}
                    name="direct_extensions"
                    label="Direct Extensions"
                />
                <Form.Group widths={2}>
                    <Form.Select
                        label="Announce Message"
                        value={group.announce_label}
                        name="announce_label"
                        onChange={onChange}
                        options={announceMessageOptions}
                    />
                    <Form.Input
                        label="Announce Frequency"
                        value={group.announce_frequency}
                        name="announce_frequency"
                        isEditMode={isEditMode}
                        type={"number"}
                        onChange={onChange}
                        max={10}
                        min={0.5}
                    />
                </Form.Group>
            </div>
        </Transition>
        <Form.Input
            label="Internal Extension"
            value={group.internal_extension}
            name="internal_extension"
            onChange={onChange}
        />
    </Form>
);

export default RingOptionsForm;
