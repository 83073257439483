import React from 'react';
import { connect } from 'react-redux';

import PersonalInformationContainer from './PersonalInformation';
import E911Information from './E911Information';
import userActions from 'actions/user';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';

class PersonalInformationTable extends React.Component {
    componentDidMount() {
        this.props.onReadUser();
    }

    render() {
        if (this.props.isReadingUser) {
            return (
                <Segment>
                    <Dimmer active>
                        <Loader />
                    </Dimmer>
                </Segment>
            )
        }
        return (
            <React.Fragment>
                <PersonalInformationContainer 
                />
                <E911Information 
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        info: state.user.user,
        isReadingUser: state.user.isReadingUser
    }
}

const mapDispatchToProps = dispatch => ({
    onReadUser: () => {
        dispatch(userActions.readUser());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformationTable);
