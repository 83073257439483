import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import leadIngestionActions from 'actions/lead_ingestion';
import { NestedDropdown } from 'dyl-components';

const ProviderDataRecordOptions = ({
    value,
    parentValue,

    onChange,

    placeholder = 'Select Data Provider/Data Record'
}) => {
    const dispatch = useDispatch();

    const [providerOptions, setProviderOptions] = useState([]);
    const [isReading, setIsReading] = useState(false);

    useEffect(() => {
        const getProviderOptions = async () => {
            const { data } = await (async () => {
                try {
                    return await dispatch(leadIngestionActions.readAllProviderRecordNames({ limit: 100 }))
                } 
                catch (e) {
                    return { data: [] };
                }
            })();
            return data.map((provider => ({
                key: provider.id,
                value: provider.id,
                text: provider.name,
                options: (
                    provider?.mappings.map(data_record => ({
                        key: data_record.id,
                        value: data_record.id,
                        text: data_record.name
                    }))
                )
            })))
        }

        setIsReading(true);
        getProviderOptions().then(options => {
            setProviderOptions(options);
            setIsReading(false);
        }).catch(e => {
            console.log(e);
            setProviderOptions([]);
            setIsReading(false);
        })
    }, [dispatch])
    return (
        <NestedDropdown
            child_value={value}
            parent_value={parentValue}
            loading={isReading}
            nested_options={providerOptions}
            onChange={(e, { parent_value, child_value }) => {
                onChange(e, { parent_value, child_value });
            }}
            placeholder={placeholder}
            display_parent
            selection
            allowedToSelectParentOnly 
            pointing='bottom'
        />
    )
}

export default ProviderDataRecordOptions;
