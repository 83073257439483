import React from 'react';
import { TableWithHeader } from 'dyl-components';
import { Grid, Segment, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';

import PersonalInformationFormContainer from './PersonalInformationForm';
import PersonalInformationHeader from './PersonalInformationHeader';
import userActions from 'actions/user';

const PersonalInformation = ({ 
    info,
    isEditMode,
    onEnableEditMode,
    onCloseEditMode,
    onUpdate,
    onChange
}) => (
    <TableWithHeader 
        header={(
            <PersonalInformationHeader 
                onEnableEditMode={onEnableEditMode}
                isEditMode={isEditMode}
                onCloseEditMode={onCloseEditMode}
                onUpdate={onUpdate}
            />
        )}
        table={(
            <Grid celled style={{margin: 0}} verticalAlign='middle'>
                <PersonalInformationFormContainer 
                    info={info}
                    isEditMode={isEditMode}
                    onChange={onChange}
                />
            </Grid>
        )}
    />
);

class PersonalInformationContainer extends React.Component {
    state = {
        isEditMode: false,
        info: this.props.info
    }

    onEnableEditMode = () => {
        this.setState({
            isEditMode: true
        })
    }

    onCloseEditMode = () => {
        this.setState({
            isEditMode: false,
            info: this.props.info
        })
    }

    onUpdate = () => {
        let info = { ...this.state.info };
        let { phones } = info;
        phones.forEach(phone => {
            phone.phone_type = phone.phone_type || " ";
        })
        this.props.onUpdate("", info)
            .then(() => {
                this.setState({
                    isEditMode: false,
                });
            });
    }

    onChange = (_, {name, value}) => {
        let info = Object.assign({}, this.state.info, {[name]: value});
        this.setState({
            info
        });
    }

    render() {
        if (this.props.isReadingUser) {
            return (
                <Segment>
                    <Dimmer active>
                        <Loader />
                    </Dimmer>
                </Segment>
            )
        }
        return (
            <PersonalInformation 
                {...this.state}
                onEnableEditMode={this.onEnableEditMode}
                onCloseEditMode={this.onCloseEditMode}
                onUpdate={this.onUpdate}
                onChange={this.onChange}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        info: state.user.user,
        isReadingUser: state.user.isReadingUser
    }
}

const mapDispatchToProps = dispatch => ({
    onUpdate: (id, updatedPersonalInformation) => {
        return dispatch(userActions.updateUser(id, updatedPersonalInformation));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformationContainer);
