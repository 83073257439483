import { DateTimeUtils } from 'dyl-components';
import { ReportsAndLogs, SequenceReports, ParseTemplateContainer, QuotesAndOrders, InvoicesReport } from 'pages';

const getDayTimestampsParameter = (userAuthTimezone) => {
    const { start, end } = DateTimeUtils.getDateRange('today', DateTimeUtils.getTimezoneCurrentDay(userAuthTimezone), DateTimeUtils.DATE_FORMAT, 'day', true, userAuthTimezone);
    return `start=${start}&end=${end}`;
}

const PARENT_NAME = "reports_and_logs";

export const REPORTS_AND_LOGS_SETTINGS = [
    {
        path: `data-integration-logs`,
        icon: 'fas fa-table',
        header: 'Data Integration Log',
        description: 'View incoming posts and emails, and parse your data for mapping.',
        page: ReportsAndLogs,
        type: `${PARENT_NAME}.data_integration_log`,
        favorite_type: "reports"
    },
    {
        path: `sequence-reports`,
        setQueryParameters: getDayTimestampsParameter,
        icon: 'fa-solid fa-network-wired',
        header: 'Sequence Report',
        description: 'View all sequence task activities.',
        page: SequenceReports,
        type: `${PARENT_NAME}.sequence_report`,
        favorite_type: "reports"
    },
    {
        path: `email-parsing-template/:email_id`,
        icon: 'fas fa-table',
        header: 'Parse Email',
        description: 'Parse Emails',
        page: ParseTemplateContainer,
        exclude: true,
        parent: {
            path: 'data-integration-logs',
            name: 'Data Integration Log'
        }
    },
    {
        path: `post-parsing-template/:post_id`,
        icon: 'fas fa-table',
        header: 'Parse Post',
        description: 'Parse Post',
        page: ParseTemplateContainer,
        exclude: true,
        parent: {
            path: 'data-integration-logs',
            name: 'Data Integration Log'
        }
    },
    {
        path: `quotes-orders`,
        setQueryParameters: getDayTimestampsParameter,
        icon: 'fa-solid fa-box-dollar',
        header: 'Quotes & Orders',
        description: 'View all quotes and orders',
        page: QuotesAndOrders,
        type: `${PARENT_NAME}.quotes_orders`,
        favorite_type: "reports"
    },
    {
        path: `invoices`,
        setQueryParameters: getDayTimestampsParameter,
        icon: 'fa-solid fa-file-invoice-dollar',
        header: 'Invoices',
        description: 'View all invoices',
        page: InvoicesReport,
        type: `${PARENT_NAME}.invoices`,
        favorite_type: "reports"
    }
];
