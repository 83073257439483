import React from 'react';
import { Select } from 'semantic-ui-react';

export const CallTagsInput = ({ callTags, onAddItem, onChange, selectedCallTags }) => (
    <Select 
        placeholder='Type to Search'
        fluid 
        multiple 
        allowAdditions
        search
        onChange={onChange}
        options={callTags}
        onAddItem={onAddItem}
        value={selectedCallTags}
    />
)

class CallTagsInputContainer extends React.Component {
    state = {
        callTags: [
            { key: 'Answered - Callback', value: 'Answered - Callback', text: 'Answered - Callback' },
            { key: 'Answered - Cold Call', value: 'Answered - Cold Call', text: 'Answered - Cold Call' },
            { key: "Answered - Don't Ever Call Again", value: "Answered - Don't Ever Call Again", text: "Answered - Don't Ever Call Again" },
            { key: 'Answered - Hot Call', value: 'Answered - Hot Call', text: 'Answered - Hot Call' },
        ],
        selectedCallTags: []
    }

    onAddItem(_, { value }) {
        this.setState(prevState => ({
            callTags: [{ text: value, value, key: value }, ...prevState.callTags]
        }))
    }

    onChange(_, { value }) {
        this.setState({
            selectedCallTags: value
        })
    }

    render() {
        return (
            <CallTagsInput 
                onAddItem={this.onAddItem.bind(this)}
                onChange={this.onChange.bind(this)}
                selectedCallTags={this.state.selectedCallTags}
                callTags={this.state.callTags}
            />
        )
    }
}

export default CallTagsInputContainer;
