import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { NavCard } from 'dyl-components';
import { Link } from 'react-router-dom';

import SettingsHeader from 'shared/SettingsHeader';
import { SETTINGS_ROOT } from 'shared/constants/SETTINGS';

const SettingsCategory = ({
    title,
    description,
    settings
}) => {
    const userAuthTimezone = useSelector((state) => state.auth.timezone);
    return (
        <React.Fragment>
            <SettingsHeader
                title={title}
                description={description}
            />
            <Grid columns={3}>
                {settings.map(({ icon, header, description, path, exclude, setQueryParameters = () => { return ''; } }) => {
                    const queryParameters = setQueryParameters(userAuthTimezone);
                    return (
                        !exclude && <Grid.Column key={header}>
                            <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={`/${SETTINGS_ROOT}/${path}${queryParameters !== '' ? `?${queryParameters}` : ''}`}>
                                <NavCard icon={icon} header={header} description={description} />
                            </Link>
                        </Grid.Column>
                    )
                })}
            </Grid>
        </React.Fragment>
    )
};

export default SettingsCategory;
