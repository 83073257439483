import React, { useState } from 'react';
import { Form, Header, Popup } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { DateInput, DateTimeUtils, Notification, STATUS_TYPES, generateResolver, yup } from 'dyl-components';

import campaignsActions from 'actions/campaigns';
import { Controller, useForm } from 'react-hook-form';

const ReactivateCampaignPopup = ({ isOpen, actionPopupRef, campaignID, onFormClose, refresh }) => {
    const dispatch = useDispatch();
    const currentDate = DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATE_FORMAT);
    const campaignBeingReactivated = useSelector(state => state.campaigns.campaignBeingReactivated);
    const [isDateFocused, setIsDateFocused] = useState(false);

    const { formState: { isValid }, handleSubmit, control, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            end_date: currentDate
        },
        resolver: generateResolver({
            end_date: yup.string().required('This field is required').test("valid_date", "Invalid date", (value => {
                return value ? DateTimeUtils.isValid(value, DateTimeUtils.WORD_DATE_FORMAT) : true;
            })).test('cannot_select_past_dates', 'End date should not be a past date', function (value) {
                if (!!!value) {
                    return true;
                }
                return !DateTimeUtils.dateIsBeforeAnotherDate(value, currentDate, "days", DateTimeUtils.WORD_DATE_FORMAT)
            })
        })
    });

    const onSubmit = async (data) => {
        try {
            await dispatch(campaignsActions.reactivateCampaign(campaignID, { end_time: DateTimeUtils.getUnixTime(data.end_date, DateTimeUtils.WORD_DATE_FORMAT) }));
            Notification.alert('Successfully reactivated campaign!', STATUS_TYPES.SUCCESS);
            refresh();
            onFormClose();
            reset();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to reactivate campaign', STATUS_TYPES.ERROR);
        }
    };

    return (
        <Popup
            open={isOpen}
            position='bottom left'
            context={actionPopupRef}
            onClose={() => {
                if (!isDateFocused) {
                    onFormClose();
                    reset();
                }
            }}
            content={
                <div>
                    <Header>Reactivate</Header>
                    <Form loading={campaignBeingReactivated}>
                        <Controller
                            control={control}
                            name='end_date'
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Field
                                    control={DateInput}
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value } })
                                    }}
                                    minDate={currentDate}
                                    dateFormat={DateTimeUtils.WORD_DATE_FORMAT}
                                    onFocus={() => { setIsDateFocused(true) }}
                                    onBlur={() => { 
                                        setIsDateFocused(false)
                                        if (!DateTimeUtils.isValid(value, DateTimeUtils.WORD_DATE_FORMAT) || DateTimeUtils.dateIsBeforeAnotherDate(value, currentDate, 'day', DateTimeUtils.WORD_DATE_FORMAT)) {
                                            onChange({ target: { name, value: currentDate } })
                                        }
                                    }}
                                    error={error?.message}
                                    label='End Date'
                                    required
                                />
                            )}
                        />
                        <Form.Button floated='right' loading={campaignBeingReactivated} color='primary' onClick={handleSubmit(onSubmit)} disabled={!isValid || campaignBeingReactivated}>Save</Form.Button>
                    </Form>
                </div>
            }
        />
    );
}

export default ReactivateCampaignPopup;
