import { Modal, generateResolver, yup, Notification, STATUS_TYPES, ConfirmationPrompt } from 'dyl-components';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Form, Icon, Popup, Modal as SemanticModal } from 'semantic-ui-react';
import groupsActions from 'actions/groups'
import { BulkActionsContext } from "shared/context/BulkActionsProvider";
import { useSearchParams } from 'react-router-dom';

const GroupBulkActionsModal = ({ open, onClose, numberSelectedGroups = 0, reload, setIsHeaderChecked, getSubgroups }) => {
    const [selectedGroups, setSelectedGroups] = useContext(BulkActionsContext);
    const [isPromptOpen, setIsPromptOpen] = useState(false);
    const [query] = useSearchParams();
    const search = query.get("search") || "";

    const { control, reset, formState: { isDirty, isValid }, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            type: ''
        },
        resolver: generateResolver({
            type: yup.string().required('This field is required')
        })
    });

    const dispatch = useDispatch();

    const close = () => {
        reset();
        onClose();
    }

    const bulkActionOptions = [
        {
            key: 'delete_groups', value: 'delete_groups', text: 'Delete Groups', content: (
                <>
                    <Icon name='trash' />
                    Delete Groups
                </>
            )
        }
    ];

    const getSelectedIds = () => {
        const parentIds = selectedGroups.filter((group) => group.parent_label_id === undefined).map((group) => group.group_id);
        const includedIds = selectedGroups.filter((group) => !parentIds.includes(group.parent_label_id)).map((group) => group.group_id);
        return {
            parentIds,
            includedIds
        }
    }

    const onSave = async () => {
        try {
            if (search) {
                const { parentIds, includedIds } = getSelectedIds();
                const promises = []
                parentIds.forEach((group) => {
                    promises.push(getSubgroups({parent_label_id: group}));
                });
                Promise.all(promises).then(async (results) => {
                    let data = []
                    results.forEach((result) => {
                        const ids = result?.data?.map((subgroup) => subgroup.id) || [];
                        data = data.concat(ids)
                    });
                    data = data.concat(includedIds);
                    data = [...new Set(data)];
                    await dispatch(groupsActions.deleteGroupsBulk(data));
                    Notification.alert('Successfully deleted groups!', STATUS_TYPES.SUCCESS, true);
                    close();
                    setSelectedGroups([]);
                    reload();
                    setIsHeaderChecked(false);
                    setIsPromptOpen(false);
                })
            } else {
                const selectedIds = selectedGroups.map((group) => group.group_id);
                await dispatch(groupsActions.deleteGroupsBulk(selectedIds));
                Notification.alert('Successfully deleted groups!', STATUS_TYPES.SUCCESS, true);
                close();
                setSelectedGroups([]);
                reload();
                setIsHeaderChecked(false);
                setIsPromptOpen(false);
            }
        } catch (error) {
            console.log(error)
            Notification.alert('Failed to delete groups!', STATUS_TYPES.ERROR, true);
        }
    }

    const isParentSelected = () => {
        const { parentIds } = getSelectedIds();
        return search && parentIds.length > 0;
    }

    return (
        <>
            <Modal open={open} onClose={close}>
                <Modal.Header>
                    Bulk Action <div style={{ float: 'right', marginRight: '1em' }}>
                        <small>{numberSelectedGroups} selected</small>
                        <Popup
                            trigger={<Icon size='small' style={{ marginLeft: '1em' }} floated='right' className='fas fa-circle-info' color='primary' />}
                            content={`Duplicates will be ignored`}
                            inverted
                            position='left center'
                            wide
                        />
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <Form noValidate>
                        <Controller
                            name='type'
                            control={control}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Select
                                    {...value ? { text: bulkActionOptions.find(action => action.value === value)?.content } : {}}
                                    label='Bulk Action Type'
                                    placeholder='Select bulk action type'
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    value={value}
                                    options={bulkActionOptions}
                                    required
                                    error={error?.message}
                                    width={8}
                                    selectOnBlur={false}
                                />
                            )}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions
                    hasSaveButton
                    saveDisabled={!isDirty || !isValid}
                    onSave={() => setIsPromptOpen(true)}
                />
            </Modal>
            <SemanticModal open={isPromptOpen} style={{ padding: 30, width: 350 }}>
                <ConfirmationPrompt 
                    header={<div style={{ paddingTop: '1em' }}>{isParentSelected() ? "Deleting the Group will also delete sub-groups." : "Are you sure?"}</div>}
                    subheader={isParentSelected() ? <div>Are you sure?</div> : <div>Selected groups will be permanently deleted.</div>}
                    onConfirm={handleSubmit(onSave)}
                    onCancel={() => setIsPromptOpen(false)}
                />
            </SemanticModal>
        </>
    )
}

export default GroupBulkActionsModal;
