import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';

import { Modal } from 'dyl-components';
import masterAccountActions from 'actions/master_account';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import MasterAccountForm from 'shared/MasterAccountForm';
import AddAccountModal from "../modals/AddAccountModal";

const mapStateToProps = state => ({
    isReading: state.master_account.isReadingLinkedAccounts,

    master_account: state.master_account.master_account
})

const mapDispatchToProps = dispatch => ({
    readMasterAccount: (id) => {
        dispatch(masterAccountActions.readOne(id));
    }
});

const SubAccounts = ({
    isReading,

    master_account,
    readMasterAccount,

    triggerProps = {}
}) => {
    const { master_account_id } = useParams();

    const [linkAccountAction, setLinkAccountAction] = useState(null);

    const openLinkAccountModal = (action) => {
        setLinkAccountAction(action);
    }

    const closeLinkAccountModal = () => {
        setLinkAccountAction(null);
    }

    const isLinkAccountModalOpen = (action) => {
        return action === linkAccountAction;
    }

    return (
        <React.Fragment>
            <Dropdown
                options={[
                    {
                        key: 'new', value: 'new', text: 'Create new account'
                    },
                    {
                        key: 'nest', value: 'nest', text: 'Nest existing account(s)'
                    }
                ]}
                loading={isReading}
                {...triggerProps}
                onChange={(_, { value }) => { openLinkAccountModal(value) }}
                value={linkAccountAction}
                selectOnBlur={false}
            />
            <Modal open={isLinkAccountModalOpen('nest')} onClose={closeLinkAccountModal}>
                <MasterAccountForm
                    close={closeLinkAccountModal}
                    master_account={{
                        name: master_account?.name,
                        id: master_account_id
                    }}
                    refreshFromLinking={() => {
                        readMasterAccount(master_account_id)
                    }}
                />
            </Modal>
            <Modal size='big' open={isLinkAccountModalOpen('new')} onClose={closeLinkAccountModal}>
                <AddAccountModal
                    account_type={'business'}
                    master_account_id={master_account_id}
                    refreshFromLinking={() => {
                        readMasterAccount(master_account_id)
                    }}
                    onClose={closeLinkAccountModal}
                />
            </Modal>
        </React.Fragment>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubAccounts);
