import React from 'react';
import { ControlPanel, UsersTable } from 'pages/CRM';
import { Table, Notification, STATUS_TYPES } from 'dyl-components';
import OptionsArea from './OptionsArea';

const Vertafore = ({ users, features, onUpdate }) => (
    <React.Fragment>
        <ControlPanel
            name={'Vertafore - ASM360'}
            numberOfActiveUsers={users.filter(user => user.isActive).length}
            totalNumberOfUsers={users.length}
            optionsArea={<OptionsArea />}
        />
        <UsersTable
            header={
                <Table.Row>
                    <Table.HeaderCell>
                        Name
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Active
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Popup
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Recordings
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Inbound
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Notes
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Prospect
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Sale
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Voicemails
                    </Table.HeaderCell>
                </Table.Row>
            }
            users={users}
            onUpdate={onUpdate}
            features={features}
        />
    </React.Fragment>

);

class VertaforeContainer extends React.Component {
    state = {
        features: [
            'popup',
            'recordings',
            'inbound',
            'notes',
            'prospect',
            'sale',
            'voicemails'
        ],
        users: [
            {
                id: 'js', name: 'James Sales', isActive: false,
                isEnabledList: [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false
                ]
            },
            {
                id: 'sc', name: 'Sarah Closer', isActive: false,
                isEnabledList: [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false
                ]
            },
            {
                id: 'tl', name: 'Tom Luck', isActive: true,
                isEnabledList: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true
                ]
            }
        ]
    }

    onUpdate = (updatedUsers) => {
        this.setState({ users: updatedUsers }, () => {
            Notification.alert('Update successful!', STATUS_TYPES.SUCCESS, true);
        });
    }

    render() {
        return (
            <Vertafore
                {...this.state}
                onUpdate={this.onUpdate}
            />
        )
    }
}

export default VertaforeContainer;
