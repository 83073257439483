import React from 'react';
import { TableWithHeader, STATUS_TYPES, Notification } from 'dyl-components';
import RingGroupMembersTable from './RingGroupMembersTable';
import RingGroupMembersHeader from './RingGroupMembersHeader';
import { Grid, Transition, Segment } from 'semantic-ui-react';
import AddMemberSectionContainer from './AddMemberSection';
import LeftPaddedContent from 'shared/LeftPaddedContent';

const extOptions = [
    'Ext. 100: Tom Luck',
    'Ext. 101: James Sales',
    'Ext. 102',
    'Ext. 103',
    'Ext. 104',
    'Ext. 105',
    'Ext. 106',
    'Ext. 107',
    'Ext. 108',
    'Ext. 109',
    'Ext. 110',
    'Ext. 111'
].map(ext => ({
    key: ext, value: ext, text: ext
}));

const RingGroupMembers = ({ 
    members, 
    onToggleCalls, 
    onDelete, 
    isAddMemberSectionOpen,
    onOpenAddMemberSection, 
    onCloseAddMemberSection, 
    onAddMember,
    extOptions
}) => (
    <TableWithHeader 
        header={
            <RingGroupMembersHeader 
                onOpenAddMemberSection={onOpenAddMemberSection}
            />
        }
        table={
            <Grid style={{margin: 0}}>
                <Transition visible={isAddMemberSectionOpen} unmountOnHide duration='100' animation='slide down'>
                    <Grid.Row>
                        <Grid.Column>
                            <LeftPaddedContent>
                                <AddMemberSectionContainer 
                                    onAddMember={onAddMember} 
                                    onClose={onCloseAddMemberSection}
                                    extOptions={extOptions}
                                />
                            </LeftPaddedContent>
                        </Grid.Column>
                    </Grid.Row>
                </Transition>
                <Grid.Row style={{padding: 0}}>
                    <Grid.Column style={{padding: 0}}>
                        {members.length <= 0 && (
                            <Segment textAlign='center'>
                                <em>There are no members</em>
                            </Segment>
                        )}
                        {members.length > 0 && (
                            <RingGroupMembersTable 
                                members={members}
                                onToggleCalls={onToggleCalls}
                                onDelete={onDelete}
                            />
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        }
    />
);

class RingGroupMembersContainer extends React.Component {
    state = {
        members: [
            {id: 'ext104rc', ext: 'Ext. 104', areCallsStopped: false }
        ],
        isAddMemberSectionOpen: false
    }

    onToggleCalls = (id) => {
        let members = this.state.members.slice(0);
        let memberToUpdate = members.filter(member => member.id === id)[0];
        if (memberToUpdate) {
            memberToUpdate.areCallsStopped = !memberToUpdate.areCallsStopped;
            this.setState({ members }, () => {
                Notification.alert(`Member ${memberToUpdate.areCallsStopped ? 'stopped' : ''} receiving calls`, STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    onAddMember = (newMembers) => {
        let members = this.state.members.slice(0);
        members = members.concat(newMembers.map(newMember => ({ id: Math.random(), ext: newMember, areCallsStopped: false })));
        Notification.alert('Successfully added group member(s)!', STATUS_TYPES.SUCCESS, true);
        this.setState({ members, isAddMemberSectionOpen: false });
    }

    onOpenAddMemberSection = () => {
        this.setState({ isAddMemberSectionOpen: true });
    }

    onCloseAddMemberSection = () => {
        this.setState({ isAddMemberSectionOpen: false });
    }

    onDelete = (id) => {
        let members = this.state.members.slice(0);
        let indexOfLeadSourceToDelete = members.findIndex(member => member.id === id);
        if (indexOfLeadSourceToDelete !== -1) {
            members.splice(indexOfLeadSourceToDelete, 1);
            this.setState({ members }, () => {
                Notification.alert('Successfully removed group member!', STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    render() {
        return (
            <RingGroupMembers 
                members={this.state.members.sort((a, b) => a.ext > b.ext ? 1 : -1)}
                isAddMemberSectionOpen={this.state.isAddMemberSectionOpen}
                onOpenAddMemberSection={this.onOpenAddMemberSection}
                onCloseAddMemberSection={this.onCloseAddMemberSection}
                onAddMember={this.onAddMember}
                onToggleCalls={this.onToggleCalls}
                onDelete={this.onDelete}
                extOptions={extOptions.filter(ext => !this.state.members.map(member => member.ext).includes(ext.key))}
            />
        )
    }
}

export default RingGroupMembersContainer;
