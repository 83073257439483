import types from "actions/office_view/types";
import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function officeViewReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.OFFICE_VIEW, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingOfficeView: true };
        case getNamedAction(ACTION_NAMES.OFFICE_VIEW, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {
                ...state,
                isReadingOfficeView: false,
                numberOfConferenceRooms: action.data.conference_rooms,
                departments: action.data.departments,
                extensions: action.data.extensions,
                queues: action.data.queues
            };
        case getNamedAction(ACTION_NAMES.OFFICE_VIEW, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingOfficeView: false };

        case getNamedAction(ACTION_NAMES.CALLS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCalls: true };
        case getNamedAction(ACTION_NAMES.CALLS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCalls: false, calls: action.data };
        case getNamedAction(ACTION_NAMES.CALLS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCalls: false };

        case getNamedAction(ACTION_NAMES.RECENT_CALLS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingRecentCalls: true };
        case getNamedAction(ACTION_NAMES.RECENT_CALLS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingRecentCalls: false, recentCalls: action.data };
        case getNamedAction(ACTION_NAMES.RECENT_CALLS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingRecentCalls: false };

        case types.ASSIGN_INCOMING_CALL_TO_QUEUE:
            return {
                ...state,
                queues: action.queues,
                incomingCalls: action.incomingCalls
            }
        case types.ASSIGN_INCOMING_CALL_TO_DEPARTMENT_AGENT:
            return {
                ...state,
                extensions: action.extensions,
                incomingCalls: action.incomingCalls
            }
        case types.ASSIGN_QUEUED_CALL_TO_DEPARTMENT_AGENT:
            return {
                ...state,
                extensions: action.extensions,
                queues: action.queues
            }

        case types.CALL_REQUEST:
            return { ...state, isCalling: true }
        case types.CALL_SUCCESS:
            return {
                ...state,
                incomingCalls: action.incomingCalls,
                callerInActionsView: action.contact || {},
                isActiveCall: true,
                actionsViewVisible: true,
                isCalling: true
            };
        case types.CALL_FAILURE:
            return { ...state, isCalling: false }

        case types.CLICK_RECENT_CALL:
            return {
                ...state,
                callerInActionsView: action.contact,
                actionsViewVisible: true,
                isActiveCall: false
            }

        case types.CLOSE_ACTIONS_VIEW:
            return { ...state, actionsViewVisible: false }

        case types.CLICK_ACTIVE_CALLER:
            return {
                ...state,
                callerInActionsView: action.contact,
                isActiveCall: true,
                actionsViewVisible: true
            }

        case types.REORDER_CALL_QUEUES:
            return {
                ...state,
                queues: action.queues
            }

        case types.HANGUP:
            return {
                ...state,
                isActiveCall: false,
                actionsViewVisible: false,
                callerInActionsView: null
            }

        default:
            return state;
    }
}


export default officeViewReducer;
