import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function webformsReducer(state = initialState, { type, ...action }) {
    switch (type) {
        case getNamedAction(ACTION_NAMES.WEBFORMS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReading: true };
        case getNamedAction(ACTION_NAMES.WEBFORMS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReading: false, webforms: action.data.data, count: action.data.count };
        case getNamedAction(ACTION_NAMES.WEBFORMS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReading: false, webforms: [], count: 0 };

        case getNamedAction(ACTION_NAMES.WEBFORMS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreating: true };
        case getNamedAction(ACTION_NAMES.WEBFORMS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreating: false };
        case getNamedAction(ACTION_NAMES.WEBFORMS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreating: false };

        case getNamedAction(ACTION_NAMES.WEBFORMS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, webformBeingRead: action.id };
        case getNamedAction(ACTION_NAMES.WEBFORMS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, webformBeingRead: null, webform: action.data };
        case getNamedAction(ACTION_NAMES.WEBFORMS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, webformBeingRead: null, webforms: {} };

        case getNamedAction(ACTION_NAMES.WEBFORMS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, webformBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.WEBFORMS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, webformBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.WEBFORMS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, webformBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.WEBFORM_STATUS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, webformBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.WEBFORM_STATUS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, webformBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.WEBFORM_STATUS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, webformBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.WEBFORMS, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, webformBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.WEBFORMS, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, webformBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.WEBFORMS, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, webformBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.WEBFORM_PUBLISH, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, webformBeingPublished: action.id };
        case getNamedAction(ACTION_NAMES.WEBFORM_PUBLISH, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, webformBeingPublished: null };
        case getNamedAction(ACTION_NAMES.WEBFORM_PUBLISH, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, webformBeingPublished: null };

        default:
            return state;
    }
}

export default webformsReducer;
