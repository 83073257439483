import React, { useContext, useRef } from 'react';
import { Checkbox, Ref, DropdownItem } from 'semantic-ui-react';
import { EllipsisDropdownPortal, Table } from 'dyl-components'

import Fee from './Fee';

import { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import { StringUtils } from 'utils';
import { Link } from 'react-router-dom';

const TableRow = ({ fee, type }) => {
    const [selectedFees, setSelectedFees] = useContext(BulkActionsContext);

    const isSelected = (fee_id) => {
        return selectedFees.includes(fee_id);
    };

    const onToggleFee = (fee_id) => {
        const newSelectedFees = isSelected(fee_id)
            ? selectedFees.filter((id) => fee_id !== id)
            : [...selectedFees, fee_id];
        setSelectedFees(newSelectedFees);
    };

    const ref = useRef();

    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox
                    checked={isSelected(fee.id)}
                    onChange={() => {
                        onToggleFee(fee.id);
                    }}
                />
            </Table.Cell>
            <Table.Cell>
                <Fee type={type} fee={fee} />
            </Table.Cell>
            <Table.Cell>{fee.description}</Table.Cell>
            <Table.Cell>{`${!fee.percent ? '$' : ''}${fee.amount}${fee.percent ? '%' : ''}`}</Table.Cell>
            <Table.Cell>{StringUtils.capitalize(fee.status)}</Table.Cell>
            <Table.Cell>
                <Ref innerRef={ref}>
                    <EllipsisDropdownPortal reference={ref}>
                        <DropdownItem
                            text="Edit"
                            icon="fa-solid fa-pencil"
                            as={Link}
                            to={`/settings/product-catalog-quotes-invoices/${type === "tax" ? "taxes" : "fees"}/${fee.id}`}
                        />
                        <DropdownItem
                            text="Duplicate"
                            icon="fas fa-copy"
                            as={Link}
                            to={`/settings/product-catalog-quotes-invoices/duplicate-${type}/${fee.id}`}
                        />
                    </EllipsisDropdownPortal>
                </Ref>
            </Table.Cell>
        </Table.Row>
    );
}

export default TableRow;
