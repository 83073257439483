import React from 'react';

import { Icon, Grid, Loader, Popup } from 'semantic-ui-react';
import { FilterableList } from 'dyl-components';
import { connect } from 'react-redux';
import contactActions from 'actions/contact';

import EventsTimeline from 'shared/EventsTimeline';

import { DateTimeUtils } from 'dyl-components';
import { Link } from 'react-router-dom';

class Events extends React.Component {
    state = {
        isCollapsed: false,
        isAddModalOpen: false,
        isEditModalOpen: false,
        eventToBeEdited: null
    }

    onToggleCollapsed = () => {
        this.setState(prevState => ({
            isCollapsed: !prevState.isCollapsed
        }));
    }

    onOpenEditModal = (event) => {
        this.setState({
            isEditModalOpen: true,
            eventToBeEdited: { date: DateTimeUtils.formatEpoch(event.ts, DateTimeUtils.DATE_FORMAT), time: DateTimeUtils.formatEpoch(event.ts), ...event }
        })
    }

    onCloseEditModal = () => {
        this.setState({
            isEditModalOpen: false
        })
    }

    render() {
        const contact_id = this.props.contact_id;
        return (
            <React.Fragment>
                <FilterableList
                    header={<React.Fragment>
                        <div><Popup
                            trigger={<Icon as={Link} to={`/contact/${contact_id}/events`} className='fas fa-plus-circle' link />}
                            content='Add Event'
                            inverted
                        /> Events
                        </div>
                        <Link to={`/contact/${contact_id}/events`}>
                            <span className='ViewAll'>
                                <b>View All</b>
                            </span>
                        </Link>
                    </React.Fragment>}
                    onToggleCollapsed={this.onToggleCollapsed}
                    listComponent={(
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Loader active={this.props.isReadingEvents} />
                                    <EventsTimeline
                                        onOpenEditModal={this.onOpenEditModal}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    )}
                    isCollapsed={this.state.isCollapsed}
                />
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    const contactEmails = state.contact.email ? state.contact.email.slice(0).map(({ email }) => email) : [];
    return {
        isReadingEvents: state.account.isReadingProfile || state.events.isReadingEvents,

        activeIntegration: state.email.activeIntegration,
        contact_id: state.contact.contact_id,
        account_id: state.contact.account_id || state.account.account_id,
        from: contactEmails.length > 0 ? contactEmails.join() : null,
    }
}

const mapDispatchToProps = dispatch => ({
    onReadHistory: (contact_id, { account_id, start, end, integration_id, from }) => {
        dispatch(contactActions.readContactHistory(contact_id, { account_id, start, end, integration_id, from }));
    }
})



export default connect(mapStateToProps, mapDispatchToProps)(Events);
