import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function userReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.TEAM, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, teamBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.TEAM, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, teamBeingDeleted: false };
        case getNamedAction(ACTION_NAMES.TEAM, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, teamBeingDeleted: false };

        case getNamedAction(ACTION_NAMES.TEAM, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, teamBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.TEAM, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, teamBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.TEAM, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, teamBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.TEAM, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, teamWhereUsersAreBeingAdded: action.id };
        case getNamedAction(ACTION_NAMES.TEAM, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, teamWhereUsersAreBeingAdded: null };
        case getNamedAction(ACTION_NAMES.TEAM, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, teamWhereUsersAreBeingAdded: null };

        case getNamedAction(ACTION_NAMES.TEAM_USER, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, teamBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.TEAM_USER, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, teamBeingUpdated: false };
        case getNamedAction(ACTION_NAMES.TEAM_USER, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, teamBeingUpdated: false };
        default:
            return state;
    }
}


export default userReducer;
