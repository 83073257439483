import React from 'react';
import { Grid, Select, Input } from 'semantic-ui-react';
import { EditableContent } from 'dyl-components';
import { STATES } from 'shared/constants/STATES';
import { EditableField } from 'dyl-components';
import { PhoneUtil } from 'utils';
import { LeftPaddedColumn } from 'dyl-components';

const PersonalInformationForm = ({ 
    info,
    isEditMode, 
    onChange 
}) => (
    <React.Fragment>
        <EditableField 
            label='Customer ID'
            value={info.email_id}
            isEditMode={false}
        />
        <Grid.Row columns='equal'>
            <Grid.Column width={3} as={LeftPaddedColumn}>
                Name
            </Grid.Column>
            <Grid.Column>
                <EditableContent 
                    control={Input}
                    value={info.name}
                    name='name'
                    onChange={onChange}
                    isEditMode={isEditMode}
                />
                &nbsp;
                <EditableContent 
                    control={Input}
                    value={info.last_name}
                    name='last_name'
                    onChange={onChange}
                    isEditMode={isEditMode}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row columns='equal'>
            <EditableField 
                label='Email'
                value={info.email}
                name='email'
                isEditMode={isEditMode}
                control={Input}
                onChange={onChange}
            />
        </Grid.Row>
        <Grid.Row columns='equal'>
            <EditableField 
                label='Phone'
                value={PhoneUtil.formatPhoneNumber(info.phones[0].phone)}
                name='phone'
                isEditMode={isEditMode}
                control={Input}
                onChange={onChange}
            />
        </Grid.Row>
        <Grid.Row columns='equal'>
            <EditableField 
                label='Address'
                value={info.address}
                name='address'
                isEditMode={isEditMode}
                control={Input}
                onChange={onChange}
            />
        </Grid.Row>
        <Grid.Row columns='equal'>
            <EditableField 
                label='Address (2)'
                value={info.address2}
                name='address2'
                isEditMode={isEditMode}
                control={Input}
                onChange={onChange}
            />
        </Grid.Row>
        <Grid.Row columns='equal'>
            <EditableField 
                label='City'
                value={info.city}
                name='city'
                isEditMode={isEditMode}
                control={Input}
                onChange={onChange}
            />
        </Grid.Row>
        <Grid.Row columns='equal'>
            <EditableField 
                label='State'
                value={isEditMode ? info.state : STATES.filter(state => state.key === info.state)[0].text}
                name='state'
                isEditMode={isEditMode}
                control={Select}
                onChange={onChange}
                search
                options={STATES.map(state => ({ ...state, value: state.key }))}
            />
        </Grid.Row>
        <Grid.Row columns='equal'>
            <EditableField 
                label='Zip'
                value={info.zip}
                name='zip'
                isEditMode={isEditMode}
                control={Input}
                onChange={onChange}
            />
        </Grid.Row>
    </React.Fragment>
)

export default PersonalInformationForm;
