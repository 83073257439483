import routes from '../routes';

import { generateReadActionCreator, generateUpdateActionCreator } from "actions/crudActionCreators";
import ACTION_NAMES from "actions/ACTION_NAMES";

const readSchedule = generateReadActionCreator(routes.API_SCHEDULE, ACTION_NAMES.SCHEDULE);
const updateSchedule = generateUpdateActionCreator(routes.API_SCHEDULE, ACTION_NAMES.SCHEDULE);

export default {
    readSchedule,
    updateSchedule
}
