import React from 'react';
import { Popup, Form } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationPrompt, STATUS_TYPES, Notification } from 'dyl-components';

import masterAccountActions from "actions/master_account";
import accountActions from 'actions/account';
import tasksActions from 'actions/tasks';

const UnlinkPopupForm = ({
    isUnlinkAccountPopupOpen,
    unlinkAccountPopupRef,
    setUnlinkAccountPopupOpen,
    master_account,
    account_id,
    unlinkContact = false,
    contact_id,
    onFormClose = () => { },
    onFormSubmit = () => { },
    refresh = () => { }
}) => {
    const dispatch = useDispatch();

    const { accountBeingUnlinked } = useSelector(state => ({
        accountBeingUnlinked: state.master_account.accountBeingUnlinked
    }));

    const onPopupClose = () => {
        setUnlinkAccountPopupOpen(false);
        onFormClose();
    }

    return (
        <Popup
            open={isUnlinkAccountPopupOpen}
            position={`bottom ${unlinkContact ? 'right' : 'center'}`}
            context={unlinkAccountPopupRef}
            onClose={onPopupClose}
            content={(
                <Form loading={accountBeingUnlinked}>
                    <ConfirmationPrompt
                        header={<div style={{ paddingTop: '1em' }}>Are you sure?</div>}
                        subheader={
                            <div style={{ marginBottom: '-1.5em' }}>
                                {unlinkContact ?
                                    'Unlinking stops data from linking to the Account.'
                                    :
                                    'Unlinking removes data from the Master Account.'
                                }
                            </div>
                        }
                        onConfirm={
                            async () => {
                                if(unlinkContact){
                                    try {
                                        await dispatch(accountActions.unlinkContact(account_id, { contact_id }));
                                        await dispatch(tasksActions.updateTasks(-1, { contact_id }));
                                        Notification.alert('Successfully unlinked contact from account!', STATUS_TYPES.SUCCESS);
                                        refresh();
                                    } catch (e) {
                                        console.log(e);
                                        Notification.alert('Failed to unlink contact from account', STATUS_TYPES.ERROR)
                                    }
                                } else {
                                    try {
                                        onFormSubmit();
                                        await dispatch(masterAccountActions.unlinkAccount(master_account, null, null, account_id));
                                        Notification.alert('Successfully unlinked account from master account!', STATUS_TYPES.SUCCESS);
                                        refresh();
                                    } catch (e) {
                                        console.log(e);
                                        Notification.alert('Failed to unlink account from master account', STATUS_TYPES.ERROR)
                                    }
                                }
                            }
                        }
                        onCancel={() => { onPopupClose() }}
                    />
                </Form>
            )}
        />
    );
}

export default UnlinkPopupForm;
