import React from 'react';
import { Table } from 'dyl-components';
import { connect } from 'react-redux';

import ScheduleTableRow from './ScheduleTableRow';
import { Button, Loader, Portal } from 'semantic-ui-react';

import './index.scss';
import SettingsFooter from 'dyl-components/molecules/SettingsFooter';
import useWidthListener from 'shared/SettingsFooter/useWidthListener';
import useWindowWidth from 'shared/SettingsFooter/useWindowWidth';
import { MathUtils } from 'utils';

const ScheduleTable = ({ schedule, isReading, onChange, isUpdating, onUpdate, hasChanges, isValid, saved }) => {
    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();

    return <>
        <Table className="scheduleTable" basic='very' striped={false}>
            <Loader active={isUpdating || isReading} />
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Day of the Week</Table.HeaderCell>
                    <Table.HeaderCell>Open</Table.HeaderCell>
                    <Table.HeaderCell>Start Time</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>End Time</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {schedule.map(daySchedule => (
                    <ScheduleTableRow 
                        schedule={daySchedule}
                        onChange={onChange}
                        isLoading={isReading || isUpdating}
                    />
                ))}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.Cell colSpan={4}>
                        
                    </Table.Cell>
                </Table.Row>
            </Table.Footer>
        </Table>
        <Portal open>
            <SettingsFooter
                style={{ width: MathUtils.calculatePercentage(windowWidth, windowWidth - width) }}
                className={`Webform__menu`}
                rightOptions={[
                    <Button disabled={!hasChanges || !isValid || isUpdating || isReading} onClick={onUpdate} primary>
                        Save
                    </Button>
                ]}
            />
        </Portal>
    </>
}

const mapStateToProps = state => {
    return {
        isReading: state.schedule.isReadingSchedule || state.user.isReadingCompany
    }
}


export default connect(mapStateToProps, null)(ScheduleTable);
