import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import ProductCodes from '../ProductCodes';
import SupplyChainNetwork from '../SupplyChainNetwork';

const AddonDetails = () => {
    return (
        <Segment>
            <Header as="h3" color="primary">Add-On Details</Header>
            <ProductCodes />
            <SupplyChainNetwork />
        </Segment>
    );
}

export default AddonDetails;
