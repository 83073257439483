import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Table } from 'dyl-components';
import { Link } from 'react-router-dom';

const Subfooter = ({ product_category }) => {
    return (
        <Table.CollapsibleRowContent indented nested>
            <Table.Cell colspan={7}>
                <Link to={"/settings/product-catalog-quotes-invoices/create"} state={{ product_category }}>
                    <Icon name='plus circle' /> New Product
                </Link>
            </Table.Cell>
        </Table.CollapsibleRowContent>
    );
}

export default Subfooter;
