const initialState = {
    isReadingPhones: false,
    phone: [],
    count: 0,

    isCreatingPhone: false,
    isUpdatingPhone: false,
    phoneBeingDeleted: null,

    phoneOptions: {}
};

export default initialState;
