import { DateTimeUtils } from 'dyl-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Segment, Statistic } from 'semantic-ui-react';

const RescheduleSequenceStats = ({ watch }) => {
    const { rescheduleSequenceTaskStats, isReading, sequenceTask } = useSelector(state => ({
        sequenceTask: state.sequence_tasks.sequenceTask || {},
        rescheduleSequenceTaskStats: state.sequence_tasks.rescheduleSequenceTaskStats,
        isReading: state.sequence_tasks.isReadingRescheduleSequenceTaskStats
    }));

    const { call, text, email } = rescheduleSequenceTaskStats;

    const date = watch('date');
    const { saturday, sunday } = sequenceTask?.workflow || {};

    const difference = (() => {
        const isValidDate = DateTimeUtils.isValid(date, DateTimeUtils.WORD_DATE_FORMAT);
        if (!sequenceTask?.ts || !isValidDate) {
            return 0;
        }
        const originalSequenceTaskDate = DateTimeUtils.formatEpoch(sequenceTask?.ts, DateTimeUtils.WORD_DATE_FORMAT);
        const newDateIsBeforeOriginalDate = DateTimeUtils.dateIsBeforeAnotherDate(date, originalSequenceTaskDate, "days", DateTimeUtils.WORD_DATE_FORMAT);
        const dates = newDateIsBeforeOriginalDate ? [originalSequenceTaskDate, date] : [date, originalSequenceTaskDate];
        const numberOfDays = DateTimeUtils.getNumberOfBusinessDays(dates[0], dates[1], 'day', DateTimeUtils.WORD_DATE_FORMAT, Boolean(saturday), Boolean(sunday));
        return numberOfDays;
    })();

    const message = (() => {
        if (difference === 0) {
            return "This action modifies the time for this task."
        }
        const numberOfSubsequentTasksAffected = call + text + email - 1;
        return `This action modifies the date for this task ${numberOfSubsequentTasksAffected === 0 ? '' : `and ${numberOfSubsequentTasksAffected} subsequent task${numberOfSubsequentTasksAffected === 1 ? '' : 's'} `}by ${difference} ${!(Boolean(saturday) && Boolean(sunday)) ? 'business' : ''} day${difference === 1 ? '' : 's'}`
    })();

    return (
        <>
            {message}
            <Grid as={Segment} loading={isReading} verticalAlign='middle' textAlign='center'>
                <Grid.Row style={{ paddingBottom: 0, marginBottom: 0 }} columns='equal'>
                    <Grid.Column>
                        <Statistic size='tiny' color='primary' >
                            <Statistic.Value>{call}</Statistic.Value>
                        </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                        <Statistic size='tiny' color='primary'>
                            <Statistic.Value>{text}</Statistic.Value>
                        </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                        <Statistic size='tiny' color='primary'>
                            <Statistic.Value>{email}</Statistic.Value>
                        </Statistic>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ paddingTop: 0, marginTop: 0 }} columns='equal'>
                    <Grid.Column>
                        Calls
                    </Grid.Column>
                    <Grid.Column>
                        Texts
                    </Grid.Column>
                    <Grid.Column>
                        Emails
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
}

export default RescheduleSequenceStats;
