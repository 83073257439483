const MARK_HOTLIST_ITEM = 'MARK_HOTLIST_ITEM';
const MARK_ALL_HOTLIST_ITEMS = 'MARK_ALL_HOTLIST_ITEMS';

const SET_LIMIT = 'SET_LIMIT';
const CHANGE_PAGE = 'CHANGE_PAGE';

export default {
    MARK_HOTLIST_ITEM,
    MARK_ALL_HOTLIST_ITEMS,
    SET_LIMIT,
    CHANGE_PAGE,
    MARK_HOTLIST_ITEMS_IN_EVERY_PAGE: 'MARK_HOTLIST_ITEMS_IN_EVERY_PAGE'
};
