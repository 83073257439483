import { yup } from "dyl-components";
import { default as applyCurrencySchema } from "./applyCurrencySchema";

const usageBasedProductSchema = {
    usage_billing_method: yup.string().when("model", {
        is: (model) => model === "usage",
        then: (schema) => schema.required("This field is required"),
        otherwise: (schema) => schema.nullable(true),
    }),

    usage_date: yup.number().when(["model", "usage_billing_method"], {
        is: (model, usage_billing_method) =>
            model === "usage" && usage_billing_method === "custom-day",
        then: (schema) => schema.required("This field is required"),
        otherwise: (schema) => schema.nullable(true),
    }),

    usage_below_balance: yup.number().when(["model", "usage_billing_method"], {
        is: (model, usage_billing_method) =>
            model === "usage" && usage_billing_method === "prepaid",
        then: (schema) =>
            applyCurrencySchema(schema).required("This field is required"),
        otherwise: (schema) => schema.nullable(true),
    }),

    usage_reload_amount: yup.number().when(["model", "usage_billing_method"], {
        is: (model, usage_billing_method) =>
            model === "usage" && usage_billing_method === "prepaid",
        then: (schema) =>
            applyCurrencySchema(schema).required("This field is required"),
        otherwise: (schema) => schema.nullable(true),
    }),
};

export function transformUsageBasedPricingDetails(price_data) {
    const { date, prepaid, price } = price_data.price;
    const otherPayload = (() => {
        if (date) {
            return { usage_date: date };
        }
        if (prepaid) {
            return {
                usage_reload_amount: prepaid.amount,
                usage_below_balance: prepaid.balance,
            };
        }
        return {};
    })();
    return {
        ...otherPayload,
        usage_billing_method: (() => {
            if (!Boolean(date) && !Boolean(prepaid)) {
                return "billing-day";
            }
            if (date) {
                return "custom-day";
            }
            if (prepaid) {
                return "prepaid";
            }
            return null;
        })(),
        price,
    };
}

export function formatUsageBasedPriceData(price_data) {
    const { usage_billing_method } = price_data;
    const price_payload = {
        price: price_data.price,
    };
    if (usage_billing_method === "billing-day") {
        return { price: price_payload };
    }
    if (usage_billing_method === "custom-day") {
        return {
            price: { ...price_payload, date: price_data.usage_date },
        };
    }
    return {
        price: {
            ...price_payload,
            prepaid: {
                amount: price_data.usage_reload_amount,
                balance: price_data.usage_below_balance,
            },
        },
    };
}

export default usageBasedProductSchema;
