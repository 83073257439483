import React from 'react';
import { Feed, Icon } from 'semantic-ui-react';
import { ButtonLink } from 'dyl-components';

import './index.scss';

const FeedItem = ({ date, time, content, onClick, active, status, hasAttachment }) => (
    <Feed.Event className={`FeedItem ${active ? 'FeedItem--active' : ''}`} onClick={onClick} as={ButtonLink}>
        <Feed.Content>
            <Feed.Summary>
                <Feed.User className='FeedItem__date'>
                    {date}
                </Feed.User>
                <Feed.Date className='FeedItem__time'>
                    {time}
                </Feed.Date>
                <Feed.Date className='FeedItem__meta'>
                    <span className={`Status Status--${status === 'Received' ? 'received' : 'sent' }`}>{status}</span> {hasAttachment && <Icon name='attach' />}
                </Feed.Date>
            </Feed.Summary>
            <Feed.Extra text className='FeedItem__content'>
                {content}
            </Feed.Extra>
        </Feed.Content>
    </Feed.Event>
);

export default FeedItem;
