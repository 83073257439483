import React from 'react';
import {  Notification, STATUS_TYPES, DateTimeUtils } from 'dyl-components';
import { Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { ValidationUtils, StringUtils } from 'utils';

import userActions from 'actions/user';
import authActions from 'actions/auth/auth';

import ChangePasswordModal from 'shared/modals/ChangePasswordModal';

import { withRouter } from 'shared/withRouter';

import { ProfileForm } from './ProfileForm';
import usersActions from 'actions/users';
import { RecordUtils } from 'utils';

const TIMEZONE_OPTIONS = DateTimeUtils.generateTimezoneOptions();

class GeneralContainer extends React.Component {
    state = {
        first_name: "",
        last_name: "",
        email: "",
        profile_type: null,
        timezone: "America/New_York",

        street: "",
        additional_street: "",
        city: "",
        state: "",
        zip: "",

        work_number: "",
        ext: "",
        mobile_number: "",

        facebook: "",
        linkedin: "",
        twitter: "",

        job_title: "",
        reports_to: "",

        emailError: null,
        saved: false,
        isChangePasswordModalOpen: false,
        isSaving: false,
        reportToSearchValue: '',
        defaultValues: {},
   
        users:[],

        isUserDataLoaded: false,
        isUserListLoaded: false
    }

    loadData = () => {
        if(this.mounted) {
            this.setState({
                first_name: this.props.first_name,
                last_name: this.props.last_name,
                email: this.props.email,
                profile_type: this.props.profile_type,
                timezone: ValidationUtils.notEmpty(this.props.timezone) && ValidationUtils.isValidOption(this.props.timezone, TIMEZONE_OPTIONS) ? this.props.timezone : "America/New_York",

                street: this.props.street,
                additional_street: this.props.additional_street,
                city: this.props.city,
                state: this.props.state,
                zip: this.props.zip,

                work_number: this.props.work_number,
                ext: this.props.ext,
                mobile_number: this.props.mobile_number,

                facebook: this.props.facebook.url,
                linkedin: this.props.linkedin.url,
                twitter: this.props.twitter.url,

                job_title: this.props.job_title,
                reports_to: this.props.reports_to,

                emailError: null,
                isChangePasswordModalOpen: false,

                reportToSearchValue: this.props.reportToSearchValue,

                
            },()=>{
                this.setState(prevState => ({
                    ...prevState,
                    defaultValues: this.initialState(),
                    isUserDataLoaded: true
                }))
            });
            this.props.onReadUsers().then( users => {
                this.setState(prevState => ({
                    ...prevState,
                    users,
                    isUserListLoaded: true
                }))
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.params.id !== prevState.id) {
            return {
                id: nextProps.params.id,
            }
        }
        return null
    }

    readProfiles() {
        this.props.onReadProfile(this.props.user_id).then(() => {
            this.loadData();
        });
        
    }

    componentDidMount() {
        this.mounted = true;
        this.readProfiles();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps){
        if (this.props.profile_type!== prevProps.profile_type) {
            this.setState({defaultValues: this.initialState()})
        }
        if (prevProps.params.id !== this.state.id) {
            this.setState((prevState) => ({...prevState, isUserDataLoaded: false, isUserListLoaded: false}), () => {
                this.readProfiles();
            })
        }
    }

    initialState = () => {
        let userProfile = this.props?.userProfile?.report_to;
        let reportsTo = {
            email: userProfile?.email || '',
            username: `${userProfile?.first_name || ''} ${userProfile?.last_name || ''}`,
            value: userProfile?.id || ''
        };

        return {
            id: this.props.user_id || '',
            first_name: this.props.first_name || '',
            last_name: this.props.last_name || '',
            email: this.props.email || '',
            profile_type: this.props.profile_type ,
            timezone: ValidationUtils.notEmpty(this.props.timezone) && ValidationUtils.isValidOption(this.props.timezone, TIMEZONE_OPTIONS) ? this.props.timezone : "America/New_York",

            street: this.props.street || '',
            additional_street: this.props.additional_street || '',
            city: this.props.city || '',
            state: this.props.state || "CA",
            zip: this.props.zip || '',

            work_number: this.props.work_number || '',
            ext: this.props.ext || '',
            mobile_number: this.props.mobile_number || '',

            facebook: this.props.facebook.url || '',
            linkedin: this.props.linkedin.url || '',
            twitter: this.props.twitter.url || '',

            job_title: this.props.job_title || '',
            reports_to: reportsTo || '',

            company: this.props.userProfile?.company?.company_name || 'N/A',
            industry: this.props.userProfile?.company?.industry?.name || 'N/A',
            
        }
    }

    onOpenChangePasswordModal = () => {
        this.setState({ isChangePasswordModalOpen: true });
    }

    onCloseChangePasswordModal = () => {
        this.setState({ isChangePasswordModalOpen: false });
    }

    onCheckIfDuplicateExistsV2 = async (email) => {

        const { customer_id, user_id } = await this.props.onFindByEmail(email);
        const result = (customer_id || user_id);
        return result;  

    }

    onUpdate = (data={}) => {
        this.setState({ isSaving: true }, async () => {

            try {
                await Promise.all([
                    this.props.onUpdateUser(this.props.user_id, {
                        ...this.props.userProfile,
                        id: data.user_id,
                        first_name: data.first_name,
                        last_name: data.last_name,
                        cell_phone: data.mobile_number,
                        email: data.email,
                        job_title: data.job_title,
                        report_to: parseInt(data.reports_to),
                        timezone: data.timezone,
                        work_phone: data.work_number,
                        work_phone_ext: data.ext,
						role_id: data.profile_type
                    }),
                    // this.updateProfileType(data.profile_type),
					// TODO MOVE THE FOLLOWING INTO THE ABOVE REQUEST
                    this.addSocialMedia(data),
                    this.updateSocialMedia(data.facebook, this.props.facebook),
                    this.updateSocialMedia(data.linkedin, this.props.linkedin),
                    this.updateSocialMedia(data.twitter, this.props.twitter),
                    this.modifyLocation(data),
                ])
              
                Notification.alert("Successfully updated user information!", STATUS_TYPES.SUCCESS, true);
                this.props.onReadProfile(this.props.user_id).then(()=> {
                    this.setState((prevState) => ({
                        ...prevState,
                        ...data,
                        saved: true,
                        isSaving: false
                    }));
                });
            } catch (e) {
                console.log(`|+|+ There was an error:`, e);
                Notification.alert("There was a problem in updating user information. Try resubmitting later.", STATUS_TYPES.ERROR, true);
                this.setState((prevState) => ({
                    isSaving: false
                }));
                
            }
        })
    }


    addSocialMedia = (data={}) => {
        const { facebook, linkedin, twitter } = data;
        const social_media_to_add = [];

        if (this.props.facebook.id === null && facebook) {
            social_media_to_add.push({ type: 'facebook', url: StringUtils.formatLink(data.facebook) });
        }
        if (this.props.linkedin.id === null && linkedin) {
            social_media_to_add.push({ type: 'linkedin', url: StringUtils.formatLink(data.linkedin) });
        }
        if (this.props.twitter.id === null && twitter) {
            social_media_to_add.push({ type: 'twitter', url: StringUtils.formatLink(data.twitter) });
        }
        if (social_media_to_add.length > 0) {            
            return this.props.onAddSocialMedia(this.props.user_id, social_media_to_add);
        }
        
        return {};
    }

    updateSocialMedia = (new_url, social) => {
        if(new_url.length === 0 && social.id){
            return this.props.onDeleteSocialMedia(this.props.user_id, social.id)
        }
        if (social.id) {
            return this.props.onUpdateSocialMedia(this.props.user_id, social.id, { type: social.type, url: StringUtils.formatLink(new_url) })
        }
        return {}
    }

    modifyLocation = (data={}) => {
        const location = {
            additional_street: data.additional_street,
            city: data.city,
            label: this.props.location_label,
            main: true,
            state: data.state,
            street: data.street,
            zip: data.zip
        };
        
        if (this.props.location_id) {
            return this.props.onUpdateLocation(this.props.user_id, this.props.location_id, location);
        }
        return this.props.onAddLocations(this.props.user_id, [{
            ...location, label: "Office Location"
        }]);
    }

    render() {
        const defaultProps = this.props;
        return (
            <React.Fragment>

                {(this.state.isUserDataLoaded && this.state.isUserListLoaded) ? 
                    <ProfileForm {...defaultProps}
                        defaultValues={this.state.defaultValues}
                        onCheckIfDuplicateExistsV2={this.onCheckIfDuplicateExistsV2}
                        onOpenChangePasswordModal={this.onOpenChangePasswordModal}
                        onUpdate={this.onUpdate}
                        isChangePasswordModalOpen={this.isChangePasswordModalOpen}
                    />

                    : <Segment loading />
                }   
                <ChangePasswordModal
                    open={this.state.isChangePasswordModalOpen}
                    onClose={this.onCloseChangePasswordModal}
                    selected_user_id={this.props.user_id}
                    user_id={this.props.loggedInUser}
                />

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, { params, excluded_users = []  }) => {
    const { first_name, last_name, email, cell_phone, job_title, location, company, social_media, timezone, access_role, report_to, work_phone, work_phone_ext } = state.user.userProfile;
    const { street, additional_street, city, state: address_state, zip, id: location_id, label: location_label } = location;
    const { industry, company_name } = company;
    const { isUpdatingUser, isCreatingSocialMedia, isUpdatingSocialMedia, locationBeingUpdated, isCreatingLocations } = state.user;
	const userRole = state.roles.roles.find(f => f.id === access_role);
    const isOwner = userRole ? userRole.owner : true; // DISABLED_PERMISSION // adjust

    const { id: superior_id, first_name: superior_first_name, last_name: superior_last_name } = report_to || { id: 0 };
    const reportToSearchValue = superior_first_name || superior_last_name ? `${superior_first_name || ''} ${superior_last_name || ''}` : "Unknown Name";
	const profileName = userRole ? userRole.name : "";

    const user_id = params.id || state.auth.user_id;

    return {
        userProfile: state.user.userProfile,
        user_id,
        loggedInUser: state.auth.user_id,
        first_name,
        last_name,
        email,
        timezone,

        street,
        additional_street,
        city,
        state: address_state,
        zip,

        mobile_number: cell_phone,
        facebook: (social_media.find(site => site.type.toLowerCase() === 'facebook') || { id: null, url: '', type: 'facebook' }),
        linkedin: (social_media.find(site => site.type.toLowerCase() === 'linkedin') || { id: null, url: '', type: 'linkedin' }),
        twitter: (social_media.find(site => site.type.toLowerCase() === 'twitter') || { id: null, url: '', type: 'twitter' }),

        company: company_name ? company_name : 'Not Available',
        industry: industry ? industry.name : 'Not Available',
        job_title,
        profile_type: access_role,
        profileName: profileName,
        reports_to: superior_id,
        reportToSearchValue,

        work_number: work_phone,
        ext: work_phone_ext,

        profile_types: state.roles.roles.filter(({owner}) => { return ! owner }).map(({ id, name }) => ({
            key: id,
            value: id,
            text: StringUtils.capitalize(name)
        })),
        location_id,
        location_label,
        isFindingUserByEmail: state.user.isFindingUserByEmail,
        isAllowedToEdit: isOwner || Number(user_id) === state.auth.user_id,
        isOwner,
        isUpdating: isUpdatingUser || isCreatingSocialMedia || isUpdatingSocialMedia || locationBeingUpdated || isCreatingLocations || state.auth.isUpdatingRole,
        users: RecordUtils.generateUserOptions(state.users.userOptions).filter(option => !excluded_users.includes(option.value)),
    }
}

const mapDispatchToProps = dispatch => ({
    onUpdateUser: (user_id, payload) => {
        return dispatch(userActions.updateUser(user_id, payload));
    },
    onUpdateSocialMedia: (user_id, social_media_id, payload) => {
        return dispatch(userActions.updateSocialMedia(user_id, payload, null, social_media_id));
    },
    onAddSocialMedia: (user_id, payload) => {
        return dispatch(userActions.addSocialMedia(payload, null, user_id));
    },
    onDeleteSocialMedia: (user_id, social_media_id) => {
        return dispatch(userActions.deleteSocialMedia(user_id, null, social_media_id));
    },
    onReadProfile: (user_id) => {
        return dispatch(userActions.viewUserProfile(user_id))
    },
    onUpdateLocation: (user_id, location_id, payload) => {
        return dispatch(userActions.updateLocation(user_id, payload, null, location_id))
    },
    onAddLocations: (user_id, locations) => {
        return dispatch(userActions.createLocations(locations, null, user_id));
    },
    onUpdateProfileType: (payload) => {
        return dispatch(authActions.updateAccessRole(null, payload));
    },
    onFindByEmail: (email) => {
        return dispatch(userActions.findByEmail('', { email }));
    },
    onReadUsers: (user_id) => {
        return dispatch(usersActions.readUserOptions({limit:500, page:1, user_id }));
    }
 
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GeneralContainer));
