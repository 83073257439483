import AssignmentRulesTable from 'pages/AssignmentRules/SubComponents/AssignmentRulesTable';
import DistributionUsersTable from 'pages/AssignmentRules/SubComponents/DistributionUsersTable';

import React from 'react';
import { Route } from 'react-router-dom';

export const ASSIGNMENT_RULES_ROUTES = [
    <Route path='assignment-rules' element={<AssignmentRulesTable />} />,
    <Route path='distribution-users' element={<DistributionUsersTable />} />,
];
