import React from 'react';
import { UsersHeader } from './Header';
import { UsersRow } from './Row';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RecordsTable } from 'dyl-components';

const UsersTable = ({ onFilter, onPageChange }) => {
    const { count, users = [], isReadingUsers } = useSelector(state => state.campaign_users);

    const [params] = useSearchParams();

    return (
        <RecordsTable
            tableBodyClassName='CampaignUsersTable'
            isTableLoading={isReadingUsers}
            isSearchResultEmpty={count === 0 && (params.get('search'))}
            isTableEmpty={count === 0 && !(params.get('search'))}
            TableHeader={<UsersHeader onFilter={onFilter} />}
            TableRows={users.map(user => (
                <UsersRow key={user.user_id} user={user} />
            ))}
            recordsCount={count}
            activePage={params.get('page')}
            onPageChange={onPageChange}
            recordsName='User'
            emptySubtitle=' '
            emptyListTitle={'No Users Added'}
        />
    )
}

export default UsersTable;
