import React from 'react';
import { Grid, Button, Select } from 'semantic-ui-react';
import { Modal } from 'dyl-components';

export default class ModalRouteTemplates extends React.Component {
    state = {
        
    }

    render() {
        const { modalOpen, closeModal } = this.props;

        return (
            <Modal
                open={modalOpen}
                onClose={closeModal}
                animated
            >
                <Modal.Header>
                    Route Templates
                </Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column computer={8} tablet={8} mobile={16}>
                                <div><strong>Email Template</strong></div>
                                <Select options={[
                                    { key: 'None', value: 'None', text: 'None' }
                                ]}
                                    value={'None'} />
                            </Grid.Column>
                            <Grid.Column computer={8} tablet={8} mobile={16}>
                                <div><strong>Text Template:</strong></div>
                                <Select options={[
                                    { key: 'Following Up', value: 'Following Up', text: 'Following Up' }
                                ]}
                                    value={'Following Up'} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic onClick={closeModal}>Cancel</Button>
                    <Button primary>Update</Button>
                </Modal.Actions>
            </Modal>
        )
    }
}
