import React from 'react';
import Avatar from 'react-avatar';
import { Header } from 'semantic-ui-react';
import { Table } from 'dyl-components';

const HistoryColumns = ({ activityHeader, activitySubheader, messageNotes, user, contactName }) => (
    <React.Fragment>
        <Table.Cell>
            <Header as='h4'>
                {activityHeader}
                <Header.Subheader>
                    <span
                        className={`HistorySection__ActivitySubheader--${activitySubheader.toLowerCase()}`}
                    >
                        {activitySubheader}
                    </span>
                </Header.Subheader>
            </Header>
        </Table.Cell>
        <Table.Cell>
            {messageNotes}
        </Table.Cell>
        <Table.Cell>
            {user}
        </Table.Cell>
        <Table.Cell>
            <Avatar
                name={contactName}
                round
                size='3em'
                maxInitials={2} 
            />
        </Table.Cell>
    </React.Fragment>
);

export default HistoryColumns;
