import React, { useEffect, useState } from 'react';
import { Header, Segment, Form } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { MultipleToggableDropdown } from 'dyl-components';
import { StringUtils } from 'utils';

import productsActions from 'actions/products';

const FeeApplication = () => {
    const dispatch = useDispatch();
    const { control, type } = useFormContext();

    const [productOptions, setProductOptions] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        dispatch(productsActions.readProducts({ limit: 1000 }));
    }, [dispatch]);

    const { products, categories, category_products, isReadingProducts } = useSelector((state) => {
        return {
            categories: state.products.categories,
            category_products: state.products.category_products,
            products: state.products.products,
            isReadingProducts: state.products.isReadingProducts
        };
    });

    useEffect(() => {
        const categoryOptions = categories.filter(category => category.has_products).map(category => {
            const categoryProducts = category_products[category.id] || [];
            return {
                key: category.id + "P",
                text: category.name,
                value: category.id + '/P',
                hasOptions: category.has_products,
                options: categoryProducts.map(categoryProduct => ({
                    key: categoryProduct.id,
                    text: categoryProduct.name,
                    value: categoryProduct.id + '/C'
                }))
            };
        });

        const productOptions = products.map(product => ({
            key: product.id + "C",
            text: product.name,
            value: product.id + '/C'
        }))

        setProductOptions([...categoryOptions, ...productOptions]);
    }, [categories, products, category_products]);

    const getCategoryProducts = async (product_category_id) => {
        await dispatch(productsActions.readCategoryProducts({ product_category_id: Number(product_category_id.slice(0, -1)) }));
    }

    const onSearchChange = (e) => {
        setSearch(e.target.value);
    }

    return (
        <Segment className='AddFeeForm__ProductApplication'>
            <Header as="h3" color="primary">Apply {StringUtils.capitalize(type)}</Header>
            <Controller
                control={control}
                name='products'
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field
                        control={MultipleToggableDropdown}
                        values={value}
                        nested_options={productOptions}
                        onChange={(_, { value }) => {
                            if (value.length) {
                                const splitValue = value[value.length - 1].value.split('/');
                                setSearch('');
                                if (splitValue[1] !== 'P') onChange({ target: { name, value } });
                            } else {
                                onChange({ target: { name, value: [] } })
                            }
                        }}
                        placeholder='Search product name'
                        onHoverParent={getCategoryProducts}
                        searchValue={search}
                        onSearchChange={onSearchChange}
                        loading={isReadingProducts}
                        selection
                    />
                )}
            />
        </Segment>
    );
}

export default FeeApplication;
