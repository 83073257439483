import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';
import { Icon, Notification, STATUS_TYPES, StickyNote } from 'dyl-components';
import { DateTimeUtils } from 'dyl-components';

import notesActions from 'actions/notes';
import noteActions from 'actions/note';

const StickyNotes = ({ sticky_notes, isReadingNotes, onReadNotes, onAddNote, onUpdateNote, onDeleteNote, activeNote, record_id }) => {
    const [notes, setNotes] = useState([]);

    useEffect(() => {
        onReadNotes(Number(record_id))
    }, [onReadNotes, record_id]);

    const onAddNotes = () => {
        setNotes(oldNotes => [{ id: Math.random(), existing: false }, ...oldNotes])
    }

    const [noteBeingAdded, setNoteBeingAdded] = useState(null);
    const [addedNotes, setAddedNotes] = useState([]);
    const [updatedNotes, setUpdatedNotes] = useState([]);

    const onSaveNote = async (note) => {
        const { id, color, title, content, existing } = note;

        try {
            if (existing) {
                await onUpdateNote({
                    id,
                    color,
                    title,
                    note: content,
                    sticky: true
                }, Number(record_id));
                setUpdatedNotes(notes => [...notes, id]);
                await onReadNotes(Number(record_id));
                
                Notification.alert('Successfully updated a sticky note!', STATUS_TYPES.SUCCESS);
            } else {
                setNoteBeingAdded(id);
                const { id: noteId } = await onAddNote({
                    color,
                    title,
                    note: content,
                    sticky: true
                }, Number(record_id));
                setAddedNotes(notes => [...notes, noteId]);
                await onReadNotes(Number(record_id));
                setNoteBeingAdded(null);
                setNotes(oldNotes => oldNotes.filter(note => note.id !== id));

                Notification.alert('Successfully created a sticky note!', STATUS_TYPES.SUCCESS);
            }

        } catch (e) {
            Notification.alert(`Failed to ${note.existing ? 'update' : 'create'} a sticky note`, STATUS_TYPES.ERROR);
        }

    }

    const onDelete = async (id, existing) => {
        try {
            if (existing) {
                await onDeleteNote(id);
                await onReadNotes(Number(record_id));
                Notification.alert('Successfully deleted a sticky note!', STATUS_TYPES.SUCCESS);
            } else {
                setNotes(oldNotes => oldNotes.filter(note => note.id !== id));
            }
        } catch (e) {
            Notification.alert(`Failed to delete a sticky note`, STATUS_TYPES.ERROR);
        }
    }

    const onChangeNoteColor = async (note) => {
        try {
            const { id, color, title, note: content } = note;

            await onUpdateNote({
                id,
                color,
                title,
                note: content,
                sticky: true
            }, Number(record_id));
            setUpdatedNotes(notes => [...notes, id]);
            await onReadNotes(Number(record_id));
            Notification.alert('Successfully updated the color of a sticky note!', STATUS_TYPES.SUCCESS);
         
        } catch (e) {
            Notification.alert('Failed to update the color of a sticky note', STATUS_TYPES.ERROR);   
        }
    }

    const isNoteCollapsed = (note) => {
        return note.existing && (!addedNotes.includes(note.id) && !updatedNotes.includes(note.id));
    }

    return (
        <React.Fragment>
            <Header as='h4' color='blue' >
                {[...notes, ...sticky_notes].length < 2 && <Icon disabled={isReadingNotes || activeNote} loading={isReadingNotes} className={isReadingNotes ? 'fa-solid fa-circle-notch' : 'fas fa-plus-circle'} link onClick={onAddNotes} />} Sticky Notes
            </Header>
            {
                [...notes, ...sticky_notes.sort((a, b) => b.activity - a.activity)].map(note => (
                    <StickyNote
                        key={note.id}
                        id={note.id}
                        title={note.title}
                        content={note.note}
                        color={note.color}
                        timestamp={note.existing ? DateTimeUtils.formatEpoch(note.activity, 'M/D/yyyy h:mma') : ''}
                        existing={note.existing}
                        onSave={onSaveNote}
                        onDelete={onDelete}
                        onChangeColor={onChangeNoteColor}
                        isLoading={activeNote === note.id || noteBeingAdded === note.id}
                        collapsed={isNoteCollapsed(note)}
                    />
                ))
            }
        </React.Fragment>

    )
}

const mapStateToProps = state => ({
    sticky_notes: state.notes.sticky_notes.filter(note => note.sticky).map(note => ({ ...note, existing: true })),
    isReadingNotes: state.notes.isReadingStickyNotes,
    activeNote: state.note.noteBeingUpdated || state.note.noteBeingDeleted
});

const mapDispatchToProps = dispatch => ({
    onAddNote: (note, contact_id) => {
        return dispatch(notesActions.addNote({ ...note }, { contact_id }));
    },
    onUpdateNote: (note, contact_id) => {
        return dispatch(noteActions.updateNote(note.id, { ...note }, { contact_id }));
    },
    onDeleteNote: (id) => {
        return dispatch(noteActions.deleteNote(id));
    },
    onReadNotes: (account_id) => {
        return dispatch(notesActions.readStickyNotes({ account_id, sticky: true }))
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(StickyNotes);
