import React from 'react';
import { Grid, Radio, Button, Segment } from 'semantic-ui-react';
import { Notification, STATUS_TYPES } from 'dyl-components';

const Options = ({ onUpdate, isAutoProspectEnabled, onChange }) => (
    <Segment basic textAlign='center'>
        <Grid>
            <Grid.Row columns={2}>
                <Grid.Column>
                    Auto Prospect
            </Grid.Column>
                <Grid.Column>
                    <Radio
                        toggle
                        onChange={(_, { name, checked }) => {
                            onChange(null, { name, value: checked });
                        }}
                        checked={isAutoProspectEnabled}
                        name={'isAutoProspectEnabled'}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column textAlign='right'>
                    <Button
                        primary
                        onClick={onUpdate}
                    >
                        Update
                </Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>
);

class OptionsContainer extends React.Component {
    state = {
        isAutoProspectEnabled: false
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onUpdate = () => {
        Notification.alert('Update successful!', STATUS_TYPES.SUCCESS, true);
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        return (
            <Options
                onUpdate={this.onUpdate}
                onChange={this.onChange}
            />
        )
    }
}

export default OptionsContainer;
