import { FileInput } from 'dyl-components';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Form } from 'semantic-ui-react';

const ImportSetup = ({
    control,
    onUpload,
    importSetupFields,
    dropzoneRef = React.createRef()
}) => {
    return (
        <Form noValidate>
            <Form.Group>
                <Form.Field
                    control='div'
                    width={6}
                >
                    <Controller
                        control={control}
                        name='file'
                        render={({ field: { onChange, name, value } }) => (
                            <Form.Field
                                control={FileInput}
                                onChange={(_, { value }) => {
                                    onChange({
                                        target: {
                                            name, value
                                        }
                                    });
                                    onUpload(value);
                                }}
                                onRemove={(_, { value }) => { onChange({ target: { name, value } }) }}
                                files={value}
                                name="file"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                icon="file csv outline"
                                size="mini"
                                dropzoneRef={dropzoneRef}
                                showIcon
                                hasBrowse
                            />
                        )}
                    />
                    <em>*Note: File needs (1) first Name, (2) last name, and (3) email OR phone</em>
                </Form.Field>

                <Form.Field
                    control='div'
                    width={7}
                >
                    <Controller
                        control={control}
                        name='description'
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Input
                                label='File Description'
                                name={name}
                                value={value}
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } });
                                }}
                                error={error?.message}
                                required
                                placeholder='Enter file description'
                            />
                        )}
                    />
                    <Form.Group widths='equal'>
                        {importSetupFields}
                    </Form.Group>
                </Form.Field>
            </Form.Group>
        </Form>
    )
};

export default ImportSetup;