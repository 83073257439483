import React from 'react';

import leadsActions from 'actions/leads';
import LeadsToolbar from './subcomponents/LeadsToolbar';
import LeadsTable from './subcomponents/LeadsTable';

import {
    KEY_CREATED, KEY_TOTAL_COST, KEY_CREATED_BY, KEY_OWNED_BY,
    KEY_ACQUISITION_COST, KEY_LAST_MODIFIED, KEY_PRODUCT_INTEREST,
    KEY_ADDITIONAL_COST, KEY_LAST_MODIFIED_BY, KEY_LEAD_PRIORITY,
    KEY_EXPECTED_REVENUE, KEY_LEAD_ID, KEY_LEAD_SCORE, KEY_CONVERT,
    KEY_CONTACT_NAME, KEY_LEAD_RATING, KEY_ACTIONS, KEY_ACCOUNT,
    KEY_MASTER_SECONDARY, KEY_STAGE, KEY_CAMPAIGN_SOURCE, KEY_LOST_REASON,
    KEY_CLOSE_PROBABILITY,
    KEY_OUTCOME
} from 'shared/constants/MODULE_COLUMNS';

import ModulesListView from 'shared/ModulesListView';

const Leads = () => {
    return (
        <ModulesListView
            TableComponent={LeadsTable}
            ToolbarComponent={LeadsToolbar}
            allowedColumns={[KEY_CREATED, KEY_ACCOUNT, KEY_STAGE, KEY_LEAD_ID, KEY_CONTACT_NAME, KEY_OUTCOME, KEY_LAST_MODIFIED]}
            defaultColumnsDisplayed={[
                KEY_CREATED,
                KEY_LEAD_ID,
                KEY_OUTCOME,
                KEY_ACCOUNT,
                KEY_STAGE,
                KEY_CONVERT,
                KEY_ACTIONS
            ]}
            moduleName='Leads'
            readModules={leadsActions.readLeads}
            routeRoot={'leads'}
            columnDisplayOptions={[
                KEY_CREATED, KEY_TOTAL_COST, KEY_CREATED_BY, KEY_OWNED_BY,
                KEY_ACQUISITION_COST, KEY_LAST_MODIFIED, KEY_PRODUCT_INTEREST,
                KEY_ADDITIONAL_COST, KEY_LAST_MODIFIED_BY, KEY_LEAD_PRIORITY,
                KEY_EXPECTED_REVENUE, KEY_LEAD_ID, KEY_LEAD_SCORE, KEY_CONVERT,
                KEY_CONTACT_NAME, KEY_LEAD_RATING, KEY_ACTIONS, KEY_ACCOUNT,
                KEY_MASTER_SECONDARY, KEY_STAGE, KEY_CAMPAIGN_SOURCE, KEY_LOST_REASON,
                KEY_CLOSE_PROBABILITY,
                KEY_OUTCOME
            ]}
        />
    );
}

export default Leads;
