import types from "actions/lead_connect/types";
import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function leadConnectReducer(state = initialState, action){
    switch(action.type){
        case getNamedAction(ACTION_NAMES.LEAD_CONNECT, CRUD_ACTION_TYPES.READ_REQUEST):
            return {...state, isReadingLeads: true};
        case getNamedAction(ACTION_NAMES.LEAD_CONNECT, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {...state, isReadingLeads: false, leads: action.data.leads || [], count: action.data.count || 0};
        case getNamedAction(ACTION_NAMES.LEAD_CONNECT, CRUD_ACTION_TYPES.READ_FAILURE):
            return {...state, isReadingLeads: false};

        case types.MARK_LEAD_IN_LEAD_CONNECT_LIST:
            return {...state, leads: action.leads, allMarked: false};
        case types.MARK_ALL_LEADS_IN_LEAD_CONNECT_LIST:
            return {...state, leads: action.leads, allMarked: action.allMarked};

        case types.SET_LIMIT:
            return {...state, limit: action.limit, currentPage: 1}
        case types.CHANGE_PAGE:
            return {...state, currentPage: action.page}
            
        default:
            return state;
    }
}


export default leadConnectReducer;
