import React from "react";
import { Grid, Segment } from "semantic-ui-react";
import { Button, EmptyListPlaceholder } from "dyl-components";

const CustomOptions = ({ contactsList, addClick, isVisible, loading }) => {
    return (
        <>
            {isVisible ? (
                <Segment loading={loading} className="select-control-segment">
                    <Grid>
                        {contactsList.map((user) => {
                            return (
                                <Grid.Row
                                    columns={"equal"}
                                    verticalAlign="middle"
                                    key={user.user_id}
                                >
                                    <Grid.Column>{user.name}</Grid.Column>
                                    <Grid.Column>{user.userName}</Grid.Column>
                                    <Grid.Column textAlign="right">
                                        <Button
                                            onClick={() =>
                                                addClick({
                                                    value: user.id,
                                                    text: user.name,
                                                })
                                            }
                                            disabled={user.isSelected}
                                        >
                                            Add
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            );
                        })}
                    </Grid>
                </Segment>
            ) : (
                <React.Fragment>
                    {loading ? (
                        <Segment basic loading />
                    ) : (
                        <EmptyListPlaceholder
                            title={"No results found"}
                            subtitle={' '}
                        />
                    )}
                </React.Fragment>
            )}
        </>
    );
};

export default CustomOptions;
