import React from 'react';
import { Modal, Notification, STATUS_TYPES } from 'dyl-components';
import { Button, Input} from 'semantic-ui-react';
import { connect } from 'react-redux';
import authActions from 'actions/auth/auth'
import assignActions from 'actions/assign';

const DeactivateReactivateModal = ({ open, onClose, onChange, old, arePasswordsValid, isUpdating, your_password, owner_password, userAccount, onDeactivate, onReactivate, queryParameters }) => (
    <Modal open={open} onClose={onClose} size='tiny'>
        <Modal.Header>
            {userAccount.active ? 'Deactivate' : 'Reactivate'} {userAccount.first_name}
        </Modal.Header>
        <Modal.Content>
           <Input 
                fluid
                name="old"
                type="password"
                placeholder="Enter your password"
                onChange={onChange}
                onKeyDown={ 
                    (e) => {
                        if (e.keyCode === 13) {
                            userAccount.active ?
                                onDeactivate(userAccount.user_id, old, queryParameters)
                                :
                                onReactivate(userAccount.user_id, userAccount.email, old, queryParameters)
                        }
                    }
                }

                
            />
        </Modal.Content>
        <Modal.Actions>
            <Button basic onClick={onClose}>Cancel</Button>
            <Button 
                loading={isUpdating} 
                disabled={!arePasswordsValid} 
                onClick={ 
                    userAccount.active ?
                        () => {onDeactivate(userAccount.user_id, old, queryParameters)}
                        :
                        () => {onReactivate(userAccount.user_id, userAccount.email, old, queryParameters) }
                }
                primary>
            Confirm</Button>
        </Modal.Actions>
    </Modal>
)

class DeactivateReactivateModalContainer extends React.Component {
    state = {
        old: '',
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    arePasswordsValid = () => {
        const { old } = this.state;
        return old.trim() !== '' 
    }

    
    onClose = () => {
        this.setState({
            old: '',
        });
        this.props.onClose();
    }

    render() {
        return (
            <DeactivateReactivateModal
                {...this.props}
                arePasswordsValid={this.arePasswordsValid()}
                old={this.state.old}
                onChange={this.onChange}
                onClose={this.onClose}
                open={this.props.open}
                isUpdating={this.props.isUpdating}
                your_password={this.props.your_password}
                owner_password={this.props.owner_password}
            />
        )
    }
}

const mapStateToProps = (state, { user_id }) => ({
    isUpdating: state.user.userPasswordBeingUpdated === user_id,
    queryParameters: state.assign.queryParameters
});

const mapDispatchToProps = (dispatch) => ({
    onDeactivate: (user_id, password, queryParameters) => {
        dispatch(authActions.deactivateUser( user_id, {}, '', {}, { password })).then(() => {
            Notification.alert('Successfully deactivated user!', STATUS_TYPES.SUCCESS, true);
            dispatch(assignActions.readUserAccounts(queryParameters));
        }).catch(() => {
            Notification.alert('Incorrect Password.', STATUS_TYPES.ERROR, true);
        });
    },
    onReactivate: (user_id, email, password, queryParameters) => {
        dispatch(authActions.reactivateUser(user_id, { email, password })).then(() => {
            Notification.alert('Successfully reactivated user!', STATUS_TYPES.SUCCESS, true);
            dispatch(assignActions.readUserAccounts(queryParameters));
        }).catch(() => {
            Notification.alert('Incorrect Password.', STATUS_TYPES.ERROR, true);
        });
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateReactivateModalContainer);
