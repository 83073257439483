import { Dropdown, Grid, Icon, Popup } from 'semantic-ui-react';
import React, { useContext } from 'react';
import { LinkedAccount } from 'dyl-components';
import { StringUtils } from 'utils';
import { Link } from 'react-router-dom';

import InfluenceField from './InfluenceField';

import { ContactContext } from 'shared/context/ContactProvider';

export default function AccountContact({ contact, contactBeingEdited, account_id, onCancelEdit, contactColumnWidth, onUnpin, isSidePanelOpen, pinOptions, onReplacePinnedContact, isReadingContactsForPinning, onEdit }) {
    const { first_name, last_name, id, influence, module_summary, primary_phone, primary_email, job_title = '' } = contact;
    const name = `${first_name} ${last_name}`;

    const { onOpenModal } = useContext(ContactContext);

    return (
        <Grid.Row key={id} verticalAlign='middle' columns='equal'>
            <Grid.Column verticalAlign='middle' width={contactColumnWidth}>
                <Grid columns='equal' verticalAlign='middle'>
                    <Grid.Column width={1}>
                        <Popup
                            trigger={<Icon onClick={() => { onUnpin(id); }} color='primary' link className='fas thumbtack' />}
                            content='Unpin'
                            inverted />
                    </Grid.Column>
                    <Grid.Column {...(isSidePanelOpen ? { width: contactColumnWidth } : {})}>
                        <Dropdown
                            placeholder='Pin a Contact'
                            selectOnBlur={false}
                            selection
                            options={pinOptions}
                            value={id}
                            onChange={(_, { value: contact_id }) => {
                                if (contact_id) {
                                    if (contact_id !== id) {
                                        onReplacePinnedContact(id, contact_id);
                                    }
                                } else {
                                    onUnpin(id);
                                }
                            }}
                            compact
                            text={(
                                <LinkedAccount
                                    account={name}
                                    maxWidth={'8.25em'}
                                    subtitle={job_title}
                                    popup
                                    linkToAccount={<Link onClick={e => { e.stopPropagation(); }} target={'_blank'} to={`/contact/${id}`}>{name}</Link>} />
                            )}
                            clearable
                            style={{ width: '15em' }}
                            loading={isReadingContactsForPinning} />
                    </Grid.Column>
                    <Grid.Column>
                        <Icon disabled={!primary_phone?.phone} link color='primary' className='fas fa-phone' />
                        <Icon disabled={!primary_phone?.phone} link color='primary' className='fas fa-comment' />
                        <Icon disabled={!primary_email?.email} link color='primary' className='fas fa-envelope'
                            onClick={() => {
                                onOpenModal('email', id);
                            }}
                        />
                        <Icon link color='primary' className='fa-sharp fa-solid fa-pen-clip'
                            onClick={() => {
                                onOpenModal('note', contact);
                            }}
                        />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column>
                <InfluenceField
                    account_id={account_id}
                    contactBeingEdited={contactBeingEdited}
                    id={id}
                    influence={influence}
                    onCancelEdit={onCancelEdit}
                    onEdit={onEdit}
                />
            </Grid.Column>
            <Grid.Column>
                {module_summary ? `${StringUtils.capitalize(module_summary.account_stage)}-${module_summary.pipeline_stage_name}` : ''}
            </Grid.Column>
        </Grid.Row>
    );
}

