import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function agendaReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.ACCESS_ROLE_ASSIGNMENT, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingAccessRoleAssignments: true };
        case getNamedAction(ACTION_NAMES.ACCESS_ROLE_ASSIGNMENT, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingAccessRoleAssignments: false, access_role_assignments: action.data };
        case getNamedAction(ACTION_NAMES.ACCESS_ROLE_ASSIGNMENT, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingAccessRoleAssignments: false };

        case getNamedAction(ACTION_NAMES.ACCESS_ROLE_ASSIGNMENT, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingAccessRoleAssignments: true };
        case getNamedAction(ACTION_NAMES.ACCESS_ROLE_ASSIGNMENT, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingAccessRoleAssignments: false };
        case getNamedAction(ACTION_NAMES.ACCESS_ROLE_ASSIGNMENT, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingAccessRoleAssignments: false };

        case getNamedAction(ACTION_NAMES.USER_ACCOUNTS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingUserAccounts: true, user_accounts: [], count: 0 };
        case getNamedAction(ACTION_NAMES.USER_ACCOUNTS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingUserAccounts: false, user_accounts: action.data.data, count: action.data.count, queryParameters: action.queryParameters };
        case getNamedAction(ACTION_NAMES.USER_ACCOUNTS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingUserAccounts: false, user_accounts: [], count: 0 };

        default:
            return state;
    }
}


export default agendaReducer;
