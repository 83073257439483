import React from 'react';
import { Grid, Icon, Form, Label } from 'semantic-ui-react';
import { ButtonLink, STATUS_TYPES, VALIDATORS } from 'dyl-components';

import { Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';

const PHONE_TYPE_OPTIONS = ["Home", "Cell", "Work", "Fax", "Other"].map(value => ({ key: value, value, text: value }));

const RecordPhonesForm = ({
    phones,
    control,
    onRemove,

    updateMain,

    checkDuplicates,
    setError,
    trigger,
    contact_id,
    allowNoPhones,

    fieldNamePrefix = ''
}) => (
    <Grid className='RecordInfo' columns='equal'>
        {phones.map((phone, index) => (
            <Controller
                key={phone.id}
                name={`${fieldNamePrefix}phones[${index}].value`}
                control={control}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => [
                    <Grid.Row columns='equal' verticalAlign='middle'>
                        <Grid.Column>
                            <Controller
                                name={`${fieldNamePrefix}phones[${index}].type`}
                                control={control}
                                defaultValue={phone.type}
                                render={({ field: { name, value, onChange } }) => (
                                    <Form.Dropdown
                                        selection
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        placeholder='Type'
                                        options={PHONE_TYPE_OPTIONS}
                                        selectOnBlur={false}
                                    />
                                )}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Input
                                name={name}
                                value={value}
                                onChange={async (_, { value }) => {
                                    await onChange({ target: { name, value } });
                                    if (trigger) {
                                        trigger(`${fieldNamePrefix}emails`);
                                        trigger('phones');
                                    }
                                    if ((value !== '' && (await VALIDATORS.PHONE_NUMBER().isValid(value)))) {
                                        const duplicatePhones = await checkDuplicates({ phones: [value] });
                                        const possibleDuplicate = duplicatePhones[0]?.contact_id;
                                        const hasNoDuplicates = possibleDuplicate === undefined || Number(possibleDuplicate) === Number(contact_id);
                                        if (!hasNoDuplicates) {
                                            await setError(name, {
                                                type: 'unique_phone',
                                                message: <Label color='red'>Phone matches an <Link style={{ color: "#2B78FF" }} target={"_blank"} to={`/contact/${possibleDuplicate}`}>existing record</Link></Label>
                                            })
                                        }
                                    }
                                }}
                                placeholder='Enter phone'
                                error={Boolean(error?.message)}
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <div>
                                <Controller
                                    name={`${fieldNamePrefix}phones[${index}].main`}
                                    control={control}
                                    render={({ field: { name, value, onChange } }) => (
                                        <Form.Radio
                                            name={name}
                                            checked={value}
                                            onChange={(_, { checked: value }) => {
                                                updateMain(() => {
                                                    onChange({ target: { name, value } });
                                                });
                                            }}
                                            label='Main'
                                        />
                                    )}
                                />
                            </div>
                            {(allowNoPhones || phones.length > 1) && (
                                <Form.Field>
                                    <ButtonLink style={{ marginLeft: '-0.5em' }} size='small' status={STATUS_TYPES.ERROR} onClick={() => { onRemove(index); }}>
                                        <Icon className='fas fa-trash-can' /> Delete
                                    </ButtonLink>
                                </Form.Field>
                            )}
                        </Grid.Column>
                    </Grid.Row>,
                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <Form.Field
                                control={'div'}
                                error={error && error.message}
                            />
                        </Grid.Column>
                    </Grid.Row>
                ]}
            />
        ))}
    </Grid>
);

export default RecordPhonesForm;
