import React from 'react';
import { Tab } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

export const HTMLCodePane = () => {

    const { email } = useSelector(state => state.lead_ingestion);
    const { html } = email?.email;

    return (
        <Tab.Pane>
            <pre>{ html }</pre>
        </Tab.Pane>
    );
}
