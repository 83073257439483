import React from 'react';
import { Dimmer, Loader, Button, Segment, Grid } from 'semantic-ui-react';
import { STATUS_TYPES, Notification } from 'dyl-components';

import './index.scss';

class SelectPhoneNumber extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { 
            loading,
         } = this.state;

        return (
            <React.Fragment>
                <div className='SelectPhoneNumber'>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={3}>
                                <Button onClick={() => Notification.alert('No results found', STATUS_TYPES.SUCCESS, true)}>Search for toll free numbers</Button>
                            </Grid.Column>
                            <Grid.Column width={13}>
                                <Segment></Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
                <Dimmer active={loading} inverted page={true}>
                    <Loader inverted></Loader>
                </Dimmer>
            </React.Fragment>
        )
    }
}

export default SelectPhoneNumber;
