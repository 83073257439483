import initialState from './initialState';

export default (state = initialState, { type, ...action }) => {
    switch (type) {
        case 'SET_MENU_ACTIVE':
            return { ...state, ...action };
        case 'SET_SUB_MENU_ACTIVE':
            return { ...state, ...action }
        default:
            return state
    }
}
