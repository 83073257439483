import React from 'react';
import { Table } from 'dyl-components';
import { EditableContent } from 'dyl-components';
import { Popup, Icon, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import MessagingIconButtons from 'shared/MessagingIconButtons';

const AudioFileRow = ({ 
    isEditMode, 
    audioFile,
    onChange, 
    onDelete, 
    onUpdate, 
    onEnableEditMode, 
    onDisableEditMode 
}) => (
    <Table.Row active={isEditMode}>
        <Table.Cell>
            <EditableContent 
                control={Input}
                name='label'
                onChange={onChange}
                value={audioFile.label}
                isEditMode={isEditMode}
            />
        </Table.Cell>
        <Table.Cell>
            <Popup 
                trigger={(
                    <Link to={`/download?id=${audioFile.id}`}>
                        <Icon 
                            name='download'
                            size='large'
                        />
                    </Link>
                )}
                content={'Download'}
                inverted
            />
           <MessagingIconButtons
                isEditMode={isEditMode}
                onDisableEditMode={onDisableEditMode} 
                onUpdate={onUpdate}
                onEnableEditMode={onEnableEditMode}
                onDelete={onDelete}
                id={audioFile.id} 
            />
        </Table.Cell>
    </Table.Row>
)

class AudioFileRowContainer extends React.Component {
    state = {
        audioFile: this.props.audioFile,
        isEditMode: false
    }

    onEnableEditMode = () => {
        this.setState({ isEditMode: true });
    }

    onDisableEditMode = () => {
        this.setState({ isEditMode: false, audioFile: this.props.audioFile });
    }

    onChange = (_, { name, value }) => {
        let audioFile = { ...this.state.audioFile, [name]: value};
        this.setState({ audioFile });
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false
        }, () => {
            this.props.onUpdate(this.state.audioFile);
        })
    }

    render() {
        return (
            <AudioFileRow 
                {...this.props}
                {...this.state}
                onChange={this.onChange}
                onEnableEditMode={this.onEnableEditMode}
                onDisableEditMode={this.onDisableEditMode}
                onUpdate={this.onUpdate}
            />
        )
    }
}

export default AudioFileRowContainer;
