const initialSate = {
    call_queue: {},
    isReadingCallQueue: false,
    groupBeingUpdated: false,
    isDeletingCallQueue: false,
    
    isReadingCallQueueMembers: false,
    isAddingMembers: false,
    memberBeingUpdated: null,
    memberBeingDeleted: null,
    members: []
}

export default initialSate;
