import { Controller, useFormContext } from "react-hook-form";
import { Form, Icon } from "semantic-ui-react";

import "./PricingTier.scss";
import bulkPricingTierSchema from "shared/schemas/products/bulkPricingTierSchema";
import PriceField from "./PriceField";

const PricingTier = ({
    fieldName,
    index,
    addRange,
    updateRange,
    currentTiers,
    removeRange,
}) => {
    const { control, trigger } = useFormContext();
    return (
        <Form.Group>
            <Controller
                name={`${fieldName}[${index}].start`}
                control={control}
                render={({
                    field: { name, value: start, onChange },
                    fieldState: { error },
                }) => (
                    <>
                        <Form.Input
                            label="Quantity"
                            width={4}
                            required
                            value={start}
                            name={name}
                            onChange={(_, { name, value }) => {
                                onChange({ target: { name, value } });
                            }}
                            error={error?.message}
                            readOnly={index > 0}
                        />
                        <Form.Field
                            control="div"
                            className="PricingTier__field--hidden-label"
                            label="Quantity"
                            width={1}
                        >
                            <Icon
                                style={{ marginTop: "0.75em" }}
                                name="minus"
                            />
                        </Form.Field>
                        <Controller
                            name={`${fieldName}[${index}].end`}
                            control={control}
                            render={({
                                field: { name, value, onChange },
                                fieldState: { error },
                            }) => (
                                <Form.Input
                                    className="PricingTier__field--hidden-label"
                                    label="Quantity"
                                    width={4}
                                    value={value}
                                    name={name}
                                    onChange={async (_, { name, value }) => {
                                        onChange({ target: { name, value } });
                                        const isValid =
                                            await bulkPricingTierSchema.end.isValid(
                                                value
                                            );
                                        if (!Number.isNaN(value) && start < Number(value) && isValid) {
                                            if (!currentTiers[index + 1]) {
                                                addRange({
                                                    start: Number(value) + 1,
                                                    end: null,
                                                });
                                            } else {
                                                updateRange(index + 1, {
                                                    ...currentTiers[index + 1],
                                                    start: Number(value) + 1,
                                                });
                                                trigger(`${fieldName}[${index + 1}].end`);
                                            }
                                        }
                                    }}
                                    error={error?.message}
                                />
                            )}
                        />
                    </>
                )}
            />
            <PriceField 
                label={"Unit Price"}
                name={`${fieldName}[${index}].price`}
                width={6}
            />
            <Form.Field
                control="div"
                className="PricingTier__field--hidden-label"
                label="Remove"
                width={1}
            >
                {index > 0 && index === currentTiers.length - 1 && (
                    <Icon
                        style={{ marginTop: "0.75em" }}
                        className="fas fa-times"
                        link
                        onClick={() => {
                            updateRange(index - 1, {
                                ...currentTiers[index - 1],
                                end: null,
                            });
                            removeRange(index);
                        }}
                    />
                )}
            </Form.Field>
        </Form.Group>
    );
};

export default PricingTier;
