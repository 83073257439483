import callQueueActions from 'actions/call_queues';
import pbxConfigActions from 'actions/pbx_config';
import ringGroupActions from 'actions/ring_groups';
import { STATUS_TYPES, Notification } from 'dyl-components';
import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { TopPaddedControls } from 'dyl-components';
import { RecordUtils } from 'utils';

const AddMemberSection = ({ ext, extOptions, onChange, onAddMember, onClose, isReadingExtensions, isAddingMembers }) => (
    <Form>
        <Form.Group widths='equal'>
            <Form.Select 
                label='Member'
                name='ext'
                options={extOptions}
                value={ext}
                onChange={onChange}
                multiple
                search
                loading={isReadingExtensions}
            />
            <TopPaddedControls>
                {!isAddingMembers && <Form.Button
                    floated='right'
                    content={'Cancel'}
                    onClick={onClose}
                />}
                <Form.Button 
                    primary
                    floated='right'
                    content={'Add'}
                    onClick={onAddMember}
                    loading={isAddingMembers}
                />
            </TopPaddedControls>
        </Form.Group>
    </Form>
)

class AddMemberSectionContainer extends React.Component {
    state = {
        ext: []
    }

    componentDidMount() {
        this.props.onReadExtensions(this.props.domain);
    }


    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onAddMember = () => {
        this.props.onAddMembers(this.props.ring_group, this.state.ext.map(ext => ({
            active: true,
            extension_id: ext,
            timeout: 0
        }))).then(() => {
            Notification.alert('Successfully added member!', STATUS_TYPES.SUCCESS, true);
            this.setState({ ext: [] });
            this.props.onClose();
            this.props.onReadRingGroupMembers(this.props.ring_group);
            this.props.onReadGroups(this.props.domain);
        }).catch(() => {
            Notification.alert('Failed to add member', STATUS_TYPES.ERROR, true);
        });
    }

    render() {
        return (
            <AddMemberSection 
                ext={this.state.ext}
                extOptions={this.props.extensions.filter(({ value }) => 
                    this.props.members.findIndex(({ extension_id }) => extension_id === value) === -1)
                }
                onClose={this.props.onClose}
                onAddMember={this.onAddMember}
                onChange={this.onChange}
                isReadingExtensions={this.props.isReadingExtensions}
                isAddingMembers={this.props.isAddingMembers}
            />
        )
    }
}

const mapStateToProps = (state, { isRingGroup }) => ({
    extensions: state.pbx_config.extensions.map(({ extension_number, id, user_id }) => {
        const users = RecordUtils.generateUserOptions(state.users.users);
        const user = (users.find(({ value }) => value === user_id) || { text: '(No assigned user)' }).text;
        return {
            key: id,
            value: id,
            text: `Ext: ${extension_number}: ${user}`
        }
    }),
    domain: state.pbx_config.domain,
    isReadingExtensions: state.pbx_config.isReadingExtensions,
    isAddingMembers: state[isRingGroup ? 'ring_group' : 'call_queue'].isAddingMembers
})

const mapDispatchToProps = (dispatch, { isRingGroup }) => ({
    onReadExtensions: (domain) => {
        dispatch(pbxConfigActions.getExtensions(domain));
    },
    onAddMembers: (group_id, members) => {
        if (isRingGroup) {
            return dispatch(ringGroupActions.addRingGroupMembers(members, null, group_id));
        }
        return dispatch(callQueueActions.addCallQueueMembers(members, null, group_id));
    },
    onReadRingGroupMembers: (group_id) => {
        if (isRingGroup) {
            return dispatch(ringGroupActions.readRingGroupMembers(group_id));
        }
        return dispatch(callQueueActions.readCallQueueMembers(group_id));
    },
    onReadGroups: (domain) => {
        if (isRingGroup) {
            return dispatch(ringGroupActions.readRingGroups(domain));
        }
        return dispatch(callQueueActions.readCallQueues(domain));
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(AddMemberSectionContainer);
