import React, { useState, useEffect } from 'react';
import { Form, Header, Icon } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import StageFields from './StageFields';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ButtonLink } from 'dyl-components';

const Stage = ({ 
    dragHandleProps, 
    rubric, 
    control, 
    removable, 
    fields, 
    onUpdate }) => {

    const canAddMoreStages = rubric.source.index !== 9;
    const isDeleteDisabled = fields[rubric.source.index]?.has_records;

    const [isDeleted, setIsDeleted] = useState(false);


    const indexfields = fields[rubric.source.index]

    useEffect(() => {
        if (indexfields.toBeDeleted === true) {
            setIsDeleted(true)}
        else {
            setIsDeleted(false);
        }
    },[indexfields.toBeDeleted]);

    return (
        <Form.Group>
            <Icon
                {...dragHandleProps}
                className={`fas fa-arrows-alt Pipeline__stage-drag-handle${!canAddMoreStages ? ' Pipeline__stage-drag-handle--padded' : ''}`}
                color='grey'
                size='small'
                disabled={isDeleted} />
            <span className={`Pipeline__stage-index ${!canAddMoreStages ? ' Pipeline__stage-index--padded' : ''} ${isDeleted ? ' Pipeline__stage-index--disabled' : ''}`}>{rubric.source.index + 1}</span>
            <StageFields control={control} index={rubric.source.index} disabled={isDeleted} />
            {removable && (
                isDeleted ? 
                <Icon
                className='fa-sharp fa-solid fa-arrow-rotate-left Pipeline__stage-undo-icon'
                size='large'
                link
                onClick={() => { 
                    onUpdate(rubric.source.index, {...fields[rubric.source.index], toBeDeleted: false}); 
                }}
                disabled={isDeleteDisabled}
            /> : 
            <Icon
                className='fa-solid fa-trash Pipeline__stage-remove-icon'
                size='large'
                link
                onClick={() => { 
                    onUpdate(rubric.source.index, {...fields[rubric.source.index], toBeDeleted: true});
                }}
                disabled={isDeleteDisabled}
                />
            )}
        </Form.Group>
    );
}

const getRenderItem = ({ 
    control, 
    removable, 
    fields, 
    onUpdate }) => (provided, snapshot, rubric) => {

    const stage = <Stage
        dragHandleProps={provided.dragHandleProps || {}}
        rubric={rubric}
        control={control}
        removable={removable}
        fields={fields}
        onUpdate = {onUpdate}
    />;
    return (
        <div
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            {snapshot.isDragging ? (
                <div className='ui form Pipeline__stage--dragging'>
                    {stage}
                </div>
            ) : stage}
            {provided.placeholder}
        </div>
    )
}

const PipelineForm = ({ 
    control, 
    fields, 
    onDragEnd, 
    onAddStage, 
    isSaving, 
    onUpdate }) => {

    const renderStage = getRenderItem({ 
        control, 
        removable: fields.length > 1, 
        fields, 
        onUpdate });

    return (
        <Form loading={isSaving} noValidate>
            <Controller
                name={'name'}
                control={control}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <Form.Input
                        name={name}
                        value={value}
                        onChange={(_, { value }) => {
                            onChange({ target: { name, value } })
                        }}
                        required
                        label='Pipeline Name'
                        error={error?.message}
                        placeholder='Enter pipeline name'
                        width={8}
                    />
                )}
            />
            <Controller
                name={'description'}
                control={control}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <Form.Input
                        name={name}
                        value={value}
                        onChange={(_, { value }) => {
                            onChange({ target: { name, value } })
                        }}
                        label='Pipeline Description'
                        error={error?.message}
                        placeholder='Enter pipeline description'
                    />
                )}
            />
            <Header as='h3' className='Pipelines__stagesHeader'>Stages</Header>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable' renderClone={renderStage}>
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {fields.map((item, index) => (
                                <Draggable key={item.itemId} draggableId={item.itemId} index={index}>
                                    {renderStage}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <ButtonLink onClick={onAddStage} disabled={fields.length >= 10}>
                <Icon
                    className='fa-light fa-plus'
                    color='grey'
                /> Add New Stage
            </ButtonLink>
        </Form>
    )
}

export default PipelineForm;
