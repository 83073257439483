import { ButtonLink, NestedDropdown } from "dyl-components";
import React from "react";
import { Controller } from "react-hook-form";
import { Form, Icon, Popup } from "semantic-ui-react";

const PromptOption = ({
    control,
    isReadingDestinations,
    destinationOptions,
    index,
    onRemove,
    excludeDigit,
    excludedDigits,
    playAudioOptions,
    isReadingSoundOptions,
    excluded_parents
}) => {
    const PARENT_NAME = `options[${index}]`;

    return (
        <div className="IvrPrompts__ruleContainer">
            <Controller
                name={`${PARENT_NAME}.digit`}
                control={control}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <Form.Select
                        name={name}
                        value={value}
                        onChange={(_, { value: newValue }) => { 
                            onChange({ target: { name, value: newValue } })
                            excludeDigit(value, newValue);
                        }}
                        placeholder='Select digit'
                        options={[
                            {key: "#", value: "#", text: "#"},
                            {key: "*", value: "*", text: "*"},
                            ...Array.from({ length: 10 }, (_, idx) => ({key: `${idx}`, value: `${idx}`, text: `${idx}` }))
                        ].filter((digit) => {
                            return value === digit.value || !excludedDigits.includes(digit.value);
                        })}
                        label="Digit"
                        className="IvrPrompts__timeframeField"
                        required
                        error={error?.message}
                    />
                )}
            />
            <Controller
                name={`${PARENT_NAME}.sound_id`}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Select
                        label={{
                            children: (
                                <span>
                                    Play Audio
                                    <Popup
                                        trigger={<Icon style={{ float: 'right', marginTop: '0.25em' }} name='fas fa-info-circle' color='primary' />}
                                        inverted
                                        content="The selected audio will play before the call is routed to a destination"
                                    />
                                </span>
                            )
                        }}
                        placeholder="Select existing audio file"
                        options={[{key: "none", value: "none", text: "None"}, ...playAudioOptions]}
                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                        value={value}
                        className="IvrPrompts__timeframeField"
                        loading={isReadingSoundOptions}
                    />
                )}
            />
            <Controller
                name={`${PARENT_NAME}.destination_parent`}
                control={control}
                render={({ field: { name: parentName, value: parentValue, onChange: onParentChange } }) => (
                    <Controller 
                        name={`${PARENT_NAME}.destination_id`}
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                            const { name: childName, value: childValue, onChange: onChildChange } = field;
                            return (
                                <Form.Field
                                    control={NestedDropdown}
                                    child_value={childValue}
                                    parent_value={parentValue}
                                    loading={isReadingDestinations}
                                    nested_options={destinationOptions}
                                    onChange={(_, { parent_value, child_value }) => {
                                        onParentChange({ target: { name: parentName, value: parent_value } });
                                        onChildChange({ target: { name: childName, value: child_value } });
                                    }}
                                    placeholder="Select destination"
                                    display_parent
                                    selection
                                    label="Destination"
                                    pointing='top'
                                    required
                                    error={error?.message}
                                    className="IvrPrompts__timeframeField IvrPrompts__destinationField"
                                    excluded_parents={excluded_parents}
                                />
                            )
                        }}
                    />
                )}
            />
            <ButtonLink onClick={() => { onRemove(index) }}>
                <Icon className="fa-solid fa-trash IvrPromptsModal__redIcon" />
            </ButtonLink>
        </div>
    )
}

export default PromptOption;
