import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function automationReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.AUTOMATION_TRIGGER, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingTrigger: true };
        case getNamedAction(ACTION_NAMES.AUTOMATION_TRIGGER, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingTrigger: false };
        case getNamedAction(ACTION_NAMES.AUTOMATION_TRIGGER, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingTrigger: false };

        case getNamedAction(ACTION_NAMES.AUTOMATION_TRIGGER, CRUD_ACTION_TYPES.READ_REQUEST): 
            return { ...state, isReadingTriggers: true, triggers: [] };
        case getNamedAction(ACTION_NAMES.AUTOMATION_TRIGGER, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingTriggers: false, triggers: action.data };
        case getNamedAction(ACTION_NAMES.AUTOMATION_TRIGGER, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingTriggers: false, triggers: [] };

        case getNamedAction(ACTION_NAMES.AUTOMATION_TRIGGER, CRUD_ACTION_TYPES.READ_ONE_REQUEST): 
            return { ...state, isReadingTrigger: true, trigger: null };
        case getNamedAction(ACTION_NAMES.AUTOMATION_TRIGGER, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingTrigger: false, trigger: action.data };
        case getNamedAction(ACTION_NAMES.AUTOMATION_TRIGGER, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingTrigger: false, trigger: null };

        case getNamedAction(ACTION_NAMES.AUTOMATION_USERS, CRUD_ACTION_TYPES.READ_REQUEST): 
            return { ...state, isReadingDistributionUsers: true, distribution_users: [], distribution_users_count: 0 };
        case getNamedAction(ACTION_NAMES.AUTOMATION_USERS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingDistributionUsers: false, distribution_users: action.data.data, distribution_users_count: action.data.count };
        case getNamedAction(ACTION_NAMES.AUTOMATION_USERS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingDistributionUsers: false, distribution_users: [], distribution_users_count: 0 };

        case getNamedAction(ACTION_NAMES.AUTOMATION_USER_CAP, CRUD_ACTION_TYPES.UPDATE_REQUEST): 
            return { ...state, isUpdatingCap: true };
        case getNamedAction(ACTION_NAMES.AUTOMATION_USER_CAP, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingCap: false };
        case getNamedAction(ACTION_NAMES.AUTOMATION_USER_CAP, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingCap: false };

        case getNamedAction(ACTION_NAMES.AUTOMATION_TRIGGER_UNIQUE, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingUniqueRule: true };
        case getNamedAction(ACTION_NAMES.AUTOMATION_TRIGGER_UNIQUE, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingUniqueRule: false, isUniqueRule: action.data };
        case getNamedAction(ACTION_NAMES.AUTOMATION_TRIGGER_UNIQUE, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingUniqueRule: false };

        default:
            return state;
    }
}


export default automationReducer;
