import types from './types';

import routes from 'actions/routes';
import { generateReadActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

function markLead(id) {
    return {
        type: types.MARK_LEAD,
        id
    }
}

function markAllLeads() {
    return {
        type: types.MARK_ALL_LEADS
    }
}

function markAllPages() {
    return {
        type: types.MARK_LEADS_IN_EVERY_PAGE
    }
}

function updateLeadAssignment(lead, user) {
    return dispatch => {
        dispatch({
            type: types.UPDATE_LEAD_ASSIGNMENT,
            lead,
            user
        })
    }
}

export default {
    readLeads: generateReadActionCreator(routes.API_LEADS, ACTION_NAMES.LEADS),
    markLead,
    markAllLeads,
    updateLeadAssignment,
    markAllPages
};
