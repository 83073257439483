import React from 'react';
import { TableWithHeader } from 'dyl-components';
import CallQueuesTable from './CallQueuesTable';
import CallQueuesHeader from './CallQueuesHeader';
import { Grid, Transition } from 'semantic-ui-react';
import AddCallQueueSectionContainer from './AddCallQueueSection';
import LeftPaddedContent from 'shared/LeftPaddedContent';

const CallQueues = ({ 
    isAddCallQueueSectionOpen,
    onOpenAddCallQueueSection, 
    onCloseAddCallQueueSection
}) => (
    <TableWithHeader 
        header={
            <CallQueuesHeader 
                onOpenAddCallQueuesSection={onOpenAddCallQueueSection}
            />
        }
        table={
            <Grid style={{margin: 0}}>
                <Transition visible={isAddCallQueueSectionOpen} unmountOnHide duration='100' animation='slide down'>
                    <Grid.Row>
                        <Grid.Column>
                            <LeftPaddedContent>
                                <AddCallQueueSectionContainer 
                                    onClose={onCloseAddCallQueueSection}
                                />
                            </LeftPaddedContent>
                        </Grid.Column>
                    </Grid.Row>
                </Transition>
                <Grid.Row style={{padding: 0}}>
                    <Grid.Column style={{padding: 0}}>
                        <CallQueuesTable />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        }
    />
);

class CallQueuesContainer extends React.Component {
    state = {
        isAddCallQueueSectionOpen: false
    }

    onOpenAddCallQueueSection = () => {
        this.setState({ isAddCallQueueSectionOpen: true });
    }

    onCloseAddCallQueueSection = () => {
        this.setState({ isAddCallQueueSectionOpen: false });
    }

    render() {
        return (
            <CallQueues 
                isAddCallQueueSectionOpen={this.state.isAddCallQueueSectionOpen}
                onOpenAddCallQueueSection={this.onOpenAddCallQueueSection}
                onCloseAddCallQueueSection={this.onCloseAddCallQueueSection}
            />
        )
    }
}

export default CallQueuesContainer;
