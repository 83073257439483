import React from 'react';
import { Form } from 'semantic-ui-react';
import { STATES } from 'shared/constants/STATES';
import { TopPaddedControls } from 'dyl-components';

const AddCallOverrideSection = ({ state, callerId, onChange, onAddCallOverride, onClose }) => (
    <Form>
        <Form.Group>
            <Form.Select
                width={4}
                name='state'
                options={STATES.map(state => ({ ...state, value: state.key }))}
                onChange={onChange}
                search
                label='State'
                value={state}
            />
            <Form.Input
                width={4}
                label='Caller ID'
                name='callerId'
                value={callerId}
                onChange={onChange}
            />
            <TopPaddedControls>
                <Form.Button
                    floated='right'
                    content={'Cancel'}
                    onClick={onClose}
                />
                <Form.Button
                    primary
                    floated='right'
                    content={'Add'}
                    onClick={onAddCallOverride}
                />
            </TopPaddedControls>
        </Form.Group>
    </Form>
)

class AddCallOverrideSectionContainer extends React.Component {
    state = {
        state: 'AL',
        callerId: ''
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onAddCallOverride = () => {
        this.props.onAddCallOverride(this.state);
        this.setState({
            state: 'AL',
            callerId: ''
        })
    }

    render() {
        return (
            <AddCallOverrideSection
                {...this.props}
                {...this.state}
                onAddCallOverride={this.onAddCallOverride}
                onChange={this.onChange}
            />
        )
    }
}

export default AddCallOverrideSectionContainer;
