import { createContext, useState } from "react";

export const BulkActionsContext = createContext();

const BulkActionsProvider = ({ children }) => {
    const [areItemsInAllPagesSelected, setAreItemsInAllPagesSelected] = useState(false);

    const [selectedItems, setSelectedItems] = useState([]);

    return (
        <BulkActionsContext.Provider value={[selectedItems, setSelectedItems, areItemsInAllPagesSelected, setAreItemsInAllPagesSelected]}>
            {children}
        </BulkActionsContext.Provider>
    )
}

export default BulkActionsProvider;
