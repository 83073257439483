import types from './types';

import routes from 'actions/routes';
import { generateReadActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

const readLeads = generateReadActionCreator(routes.API_LEAD_CONNECT, ACTION_NAMES.LEAD_CONNECT);

function markLead(id) {
    return (dispatch, getState) => {
        let leads = getState().leadConnect.leads.slice(0);
        let leadToMark = leads.filter(lead => lead.lead_id === id)[0];
        leadToMark.isMarked = !leadToMark.isMarked; 
        dispatch({
            type: types.MARK_LEAD_IN_LEAD_CONNECT_LIST,
            leads
        })
    }
}

function markAllLeads() {
    return (dispatch, getState) => {
        let leads = getState().leadConnect.leads.slice(0);
        let allMarked = !getState().leadConnect.allMarked;
        leads.forEach(lead => {lead.isMarked = allMarked});
        dispatch({
            type: types.MARK_ALL_LEADS_IN_LEAD_CONNECT_LIST,
            leads,
            allMarked
        })
    }
}

function setLimit(limit) {
    return dispatch => {
        dispatch({
            type: types.SET_LIMIT,
            limit
        });
    }
}

function goTo(page) {
    return dispatch => {
        dispatch({
            type: types.CHANGE_PAGE,
            page
        })
    }
}

export default {
    readLeads,
    markLead,
    markAllLeads,
    setLimit,
    goTo
};
