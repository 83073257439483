import React, { useState } from 'react';
import { generateResolver, Notification, STATUS_TYPES, Table, TableLoader, yup } from 'dyl-components';
import { Table as SemanticTable } from 'semantic-ui-react';
import { DataProvidersTableHeader } from './Header';
import { DataProvidersRow } from './Row';
import { useDispatch, useSelector } from 'react-redux';

import leadProviderActions from 'actions/lead_provider';
import { useSearchParams } from 'react-router-dom';

import { useConfirm } from 'shared/confirmation/useConfirm';
import CustomPrompt from 'shared/confirmation/CustomPrompt';
import ConfirmModal from 'shared/confirmation/ConfirmModal';
import { useForm } from 'react-hook-form';

export const DataProvidersTable = ({
    isReadingDataProviders,
    search,
    onFilter,
    limit
}) => {
    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const {providerBeingUpdated, providerBeingDeleted} = useSelector(state => state.lead_provider);
    const dataProviders = useSelector(state => state.lead_provider.providers);

    const [providerBeingEdited, setProviderBeingEdited] = useState(null);

    const { control, formState: { isValid, isDirty }, getValues, setValue, reset, setError, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: ""
        },
        resolver: generateResolver(({
            name: yup.string().required("This is a required field").maxlength(64).simple_alphanumeric().no_excessive_whitespaces()
        }))
    });

    const current_master_source_value = watch('master_source');

    const onConfirmEditProvider = async () => {

        try {
            const { secondary_source_id, ...values } = getValues();
            await dispatch(leadProviderActions.updateProvider(providerBeingEdited, { ...values, secondary_source_id: secondary_source_id || 0 }));
            Notification.alert('Successfully updated data provider', STATUS_TYPES.SUCCESS, true);
            await setProviderBeingEdited(null);
            dispatch(leadProviderActions.readProviders({
                ...Object.fromEntries(params),
                limit
            }));
        } catch (e) {
            const nameAlreadyExists = e?.Code === 409;
            const message = nameAlreadyExists ? 'Data provider name already exists' : 'Failed to update data provider';
            Notification.alert(message, STATUS_TYPES.ERROR);
            if (nameAlreadyExists) {
                setError('name', { message });
            }
        }
    }

    const onEditProvider = (id) => {
        setProviderBeingEdited(id);
        const providerBeingEditedData = dataProviders.find(dataProvider => dataProvider.id === id);
        reset({
            name: providerBeingEditedData?.name || '',
            category: providerBeingEditedData?.category || null,
            master_source: providerBeingEditedData?.master_source || null,
            secondary_source_id: providerBeingEditedData?.secondary_source_id || null
        }, { keepDefaultValues: false })
    }

    const onCancelEditProvider = () => {
        setProviderBeingEdited(null);
        reset();
    }

    const { isConfirmed } = useConfirm();

    CustomPrompt(null, providerBeingEdited !== null && isDirty, isConfirmed, 'Changes not saved', 'Are you sure you want to exit?');

    return (
        <Table striped={false} unstackable>
            {providerBeingEdited && (<ConfirmModal />)}
            <SemanticTable.Header className='Table__header--primary'>
                <DataProvidersTableHeader onFilter={onFilter} search={search} />
            </SemanticTable.Header>
            <Table.Body className='DataProvidersTable'>
                {isReadingDataProviders ? <TableLoader isLoading colspan={7} /> : (
                    dataProviders.map(dataProvider =>
                        <DataProvidersRow
                            key={dataProvider.id}
                            dataProvider={dataProvider}
                            isEditing={providerBeingEdited === dataProvider.id}
                            onEdit={() => { onEditProvider(dataProvider.id) }}
                            onCancelEdit={() => { onCancelEditProvider() }}
                            onConfirmEdit={onConfirmEditProvider}
                            isUpdating={providerBeingUpdated === dataProvider.id}
                            isDeleting={providerBeingDeleted === dataProvider.id}
                            limit={limit}

                            control={control}
                            setValue={setValue}
                            canConfirmEdit={isValid && isDirty}
                            current_master_source_value={current_master_source_value}
                        />
                    )
                )}
            </Table.Body>
        </Table>
    );
}

