const initialState = {
    count: 0,
    categories: [],
    queryParameters: {
        page: 1,
        offset: 0
    },
    category_being_read: null,
    isReading: false,
    isCreating: false,
    categoryBeingUpdated: false,
    categoryBeingDeleted: false,
    isUpdatingProductCategories: false
}

export default initialState;
