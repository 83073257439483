import React from 'react';
import { Icon } from 'semantic-ui-react';
import FIELD_TYPE from './FIELD_TYPE';

const FIELD_TYPE_OPTIONS = FIELD_TYPE.map((type)=>{
    return({
        key: type.value, value: type.value, text: type.text, content: (
            <>
                <Icon className={`${type.icon} FieldTypeDropdown__icon`} />
                {type.text}
            </>
        )})
    
})

export default FIELD_TYPE_OPTIONS;