import React from 'react';

import { ButtonLink, CloseableContent, Table } from 'dyl-components';

import { Dropdown, Popup, Ref, Icon } from 'semantic-ui-react';
import CustomFieldGroupForm from 'shared/forms/CustomFieldGroupForm';

const AddGroupItemSection = ({
    group_id,
    refresh,
    groupWhereASubgroupIsToBeCreated,
    onOpenAddGroupForm,
    onCloseAddGroupForm,
    isAddGroupFormModified,
    onModify,

    onOpenAddFieldModal,
    disabled
}) => {
    const addGroupFormRef = React.createRef();
    return (
        <Table.CollapsibleRowContent>
            <Table.Cell colspan={8}>
                <Popup
                    content={(
                        <CloseableContent onClose={onCloseAddGroupForm}>
                            <CustomFieldGroupForm
                                parent_group={group_id}
                                refresh={refresh}
                                onModify={onModify}
                                onClose={onCloseAddGroupForm}
                                isSubgroup
                            />
                        </CloseableContent>
                    )}
                    open={groupWhereASubgroupIsToBeCreated === group_id}
                    closeOnDocumentClick={!isAddGroupFormModified}
                    onClose={onCloseAddGroupForm}
                    on='click'
                    position='bottom left'
                    context={addGroupFormRef}
                />
                <Ref innerRef={addGroupFormRef}>
                    <Dropdown disabled={disabled} icon='' as={ButtonLink} noPadding trigger={<span><Icon name='plus circle' /> New Custom Subgroup or Field</span>}>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => { onOpenAddGroupForm(group_id) }}>
                                <Icon className='fas fa-layer-plus' /> Add Custom Subgroup
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => { onOpenAddFieldModal(group_id) }}>
                                <Icon className='fas fa-pen-field' /> Add Custom Field
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Ref>
            </Table.Cell>
        </Table.CollapsibleRowContent>
    );
}

export default AddGroupItemSection;
