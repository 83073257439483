const initialState = {
    attachments: [],
    isReading: false,
    isAdding: false,
    attachmentBeingUpdated: null,
    attachmentBeingRemoved: null,
    count: 0
}

export default initialState;
