import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox, Icon } from "semantic-ui-react";
import {
    ContactCard,
    DateTimeUtils,
    DYLAvatar,
    OnHoverOnClick,
    Person,
    Table,
} from "dyl-components";

import { BulkActionsContext } from "shared/context/BulkActionsProvider";
import HoverableIconWithList from "shared/HoverableIconWithList";
import Contact from "shared/Contact";
import { PhoneUtil, StringUtils } from "utils";
import QuoteID from "shared/QuoteID";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import {
    KEY_ACCOUNT_OWNER,
    KEY_CREATED,
    KEY_CREATED_BY,
    KEY_DUE_ON,
    KEY_INVOICE_STATUS,
    KEY_NUMBER_PRODUCTS,
    KEY_PAYMENT_TYPE,
    KEY_PRODUCTS,
    KEY_SUBTOTAL,
    KEY_TERM,
} from "shared/constants/INVOICE_REPORT_COLUMNS";

const InvoicesRow = ({ entry, isColumnHidden }) => {
    const { account } = entry;

    const [selectedEntries, setSelectedEntries] =
        useContext(BulkActionsContext);

    const isSelected = (entry_id) => {
        return selectedEntries.includes(entry_id);
    };

    const onToggleContact = (entry_id) => {
        const newSelectedContacts = isSelected(entry_id)
            ? selectedEntries.filter((id) => entry_id !== id)
            : [...selectedEntries, entry_id];
        setSelectedEntries(newSelectedContacts);
    };

    const key = entry.invoice_id;

    const formatStatus = (status) => {
        if (!status) {
            return "";
        }
        const statusSplit = status.split("/");
        if (statusSplit[0] !== "rejected") {
            return StringUtils.capitalize(statusSplit[0]);
        }
        const rejectReason = statusSplit[1].split("-");
        return `${StringUtils.capitalize(statusSplit[0])}/${
            rejectReason[0] === "price"
                ? "Priced"
                : StringUtils.capitalize(rejectReason[0])
        } ${rejectReason.slice(1, rejectReason.length).join(" ")}`;
    };

    const navigate = useNavigate();

    const hoverContent = (user) => {
        if (!user?.id) {
            return <div> No User Selected </div>;
        }
        return (
            <ContactCard
                userInfo={{
                    ...user,
                    phone: PhoneUtil.formatPhoneNumber(user.phone),
                }}
                toNavigate={() =>
                    navigate(`/settings/users/${user.id}/general`, {
                        target: "_blank",
                    })
                }
            />
        );
    };

    const { onEditOrder, onViewInvoice } = useContext(QuoteBuilderContext);

    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox
                    checked={isSelected(key)}
                    onChange={() => {
                        onToggleContact(key);
                    }}
                />
            </Table.Cell>
            {!isColumnHidden(KEY_CREATED) && (
                <Table.Cell>
                    <div>
                        <b>
                            {DateTimeUtils.formatEpoch(
                                entry.created,
                                DateTimeUtils.WORD_DATE_FORMAT
                            )}
                        </b>
                    </div>
                    <div>
                        {DateTimeUtils.formatEpoch(
                            entry.created,
                            DateTimeUtils.TIME_FORMAT
                        )}
                    </div>
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_DUE_ON) && (
                <Table.Cell>
                    <div>
                        <b>
                            {entry.due_on &&
                                DateTimeUtils.formatEpoch(
                                    entry.due_on,
                                    DateTimeUtils.WORD_DATE_FORMAT
                                )}
                        </b>
                    </div>
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_TERM) && (
                <Table.Cell>{StringUtils.capitalize(entry.term)}</Table.Cell>
            )}
            <Table.Cell className="InvoicesTable__LargeFixedWidth" width={3}>
                <div>
                    <QuoteID
                        onEditQuote={() => {
                            onViewInvoice(entry.invoice_id, account?.id);
                        }}
                        id={entry.invoice_id}
                    />
                </div>
            </Table.Cell>
            <Table.Cell className="InvoicesTable__LargeFixedWidth" width={3}>
                <div>
                    <QuoteID
                        onEditQuote={() => {
                            onEditOrder(entry.order_id, account?.id);
                        }}
                        id={entry.order_id}
                    />
                </div>
            </Table.Cell>
            {!isColumnHidden(KEY_ACCOUNT_OWNER) && (
                <Table.Cell>
                    <Person
                        username={entry.account_owner}
                        email={entry.account_owner_email}
                    />
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_CREATED_BY) && (
                <Table.Cell>
                    <OnHoverOnClick
                        hoverContent={hoverContent(entry.created_by)}
                        clickContent={null}
                        trigger={
                            entry.created_by ? (
                                <DYLAvatar
                                    name={entry.created_by.username}
                                    disabled
                                    size="2.5em"
                                />
                            ) : undefined
                        }
                    />
                </Table.Cell>
            )}
            <Table.Cell>
                {Boolean(account?.id) && (
                    <Contact
                        contact={{
                            ...(account || {}),
                            email: account?.email || {},
                            phone: account?.phone || {},
                        }}
                        name={account.name}
                        linkToContact={
                            <Link to={`/account/${account.id}`} target="_blank">
                                {account?.name}
                            </Link>
                        }
                    />
                )}
            </Table.Cell>
            {!isColumnHidden(KEY_PRODUCTS) && (
                <Table.Cell>
                    <HoverableIconWithList
                        icon={
                            <Icon className="fa-solid fa-box" color="primary" />
                        }
                        iconContent={"Products"}
                        items={entry?.products}
                    />
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_NUMBER_PRODUCTS) && (
                <Table.Cell>{entry.number_products}</Table.Cell>
            )}
            {!isColumnHidden(KEY_SUBTOTAL) && (
                <Table.Cell>
                    {entry.subtotal &&
                        `$${StringUtils.formatDecimal(
                            String(entry.subtotal),
                            true
                        )}`}
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_PAYMENT_TYPE) && (
                <Table.Cell
                    className="InvoicesTable__MediumFixedWidth"
                    width={2}
                >
                    {entry.payment_type}
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_INVOICE_STATUS) && (
                <Table.Cell>{formatStatus(entry.invoice_status)}</Table.Cell>
            )}
        </Table.Row>
    );
};

export default InvoicesRow;
