import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Ref, Icon } from 'semantic-ui-react';
import { MessagingIcon } from 'dyl-components';

export const CALL_CONTROL_NAMES = {
    VOICEMAIL: 'VOICEMAIL',
    CELL: 'CELL'
}

export function getCallControlId(teamName, agentId, callControlName) {
    return `${teamName}-${agentId}-${callControlName}`;
}

export const DroppableCallButton = ({ teamName, agentId, name, popupContent, children }) => (
    <Droppable 
        droppableId={getCallControlId(teamName, agentId, name)}
    >
        {(provided) => (
            <Ref innerRef={provided.innerRef}>
                {children}
            </Ref>
        )}
    </Droppable>
)

export const CallControls = ({ teamName, agentId }) => (
    <React.Fragment>
        <DroppableCallButton 
            teamName={teamName} 
            name={CALL_CONTROL_NAMES.VOICEMAIL} 
            agentId={agentId} 
            popupContent={'Drop to Voicemail'}
        >
            <React.Fragment>
                <Icon.Group style={{margin: 0}}>
                    <MessagingIcon icon={'mobile'} />
                    <MessagingIcon icon={'volume up'} corner />
                </Icon.Group> Drop to Voicemail
            </React.Fragment>
        </DroppableCallButton>
        <DroppableCallButton 
            teamName={teamName} 
            name={CALL_CONTROL_NAMES.CELL} 
            agentId={agentId} 
            popupContent={'Drop to Cell'}
        >
            <React.Fragment>
                <Icon.Group>
                    <MessagingIcon icon={'mobile alternate'} />
                </Icon.Group> Drop to Cell
            </React.Fragment>
        </DroppableCallButton>
    </React.Fragment>
)
