import React, { useContext, useEffect, useRef, useState } from 'react';
import { Table, EllipsisDropdown, Person, VoicemailPlayback, Icon, generateResolver, yup, Notification, STATUS_TYPES } from 'dyl-components';
import { Checkbox, DropdownItem, Popup} from 'semantic-ui-react';

import '../index.scss';
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import { PhoneUtil } from 'utils';
import UserExtensionsSubrow from './UserExtensionsSubrow';
import { useDispatch, useSelector } from 'react-redux';
import pbxConfigActions from 'actions/pbx_config';
import provisionActions from 'actions/provision';
import ViewLoginInfoPopup from './ViewLoginInfoPopup';
import { useForm } from 'react-hook-form';

const UserExtensionsRow = ({ userExtension = {}, onSave, onOpenModal }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isPhoneTruncated, setIsPhoneTruncated] = useState(false);
    const [isTeamsTruncated, setIsTeamsTruncated] = useState(false);
    const [selectedUserExtensions, setSelectedUserExtensions] = useContext(BulkActionsContext);
    const [isVoicemailPlaybackOpen, setIsVoicemailPlaybackOpen] = useState(false);
    const [isLoginInfoOpen, setIsLoginInfoOpen] = useState(false);
    const phoneRef = useRef(null);
    const teamsRef = useRef(null);

    const { extension_number, user, id, phones, voicemail: { pin, box, greeting }, isExternalSoftphone, groups } = userExtension;
    const { label: sound_label, url, sound_id } = greeting || {}
    const { teams } = user || {}
    
    const dispatch = useDispatch();

    const { userGroups, isReadingUserGroups } = useSelector((state) => state.pbx_config);

    const { control, handleSubmit, formState: { isDirty, isValid }, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            password: ''
        },
        resolver: generateResolver({
            password: yup.string().required("This field is required")
        })
    });

    const isSelected = (user_extension_id) => {
        return selectedUserExtensions.includes(user_extension_id);
    };

    const onToggleExtension = (user_extension_id) => {
        const newSelecteduserExtensions = isSelected(user_extension_id)
            ? selectedUserExtensions.filter((id) => user_extension_id !== id)
            : [...selectedUserExtensions, user_extension_id];
            setSelectedUserExtensions(newSelecteduserExtensions);
    };

    const handleToggle = async () => {
        try {
            if (isCollapsed) {
                dispatch(pbxConfigActions.getUserGroups({extension: id}))
            }
            setIsCollapsed((prevValue) => {
                return !prevValue
            })
        } catch (error) {
            console.log(error)
        }
    };

    const onNext = async (data) => {
        try {
            const { password } = data;
            await dispatch(provisionActions.getSoftphoneCreds(extension_number, { password }));
        } catch (error) {
            console.log(error)
            Notification.alert('Invalid credentials!', STATUS_TYPES.ERROR);
        }
    }

    const onCloseViewLoginInfo = () => {
        dispatch(provisionActions.removeSoftphoneCreds());
        reset({password: ''});
        setIsLoginInfoOpen(false);
    }

    useEffect(() => {
        if (phoneRef?.current) {
            const { scrollWidth, clientWidth } = phoneRef.current;
            if (scrollWidth > clientWidth) {
                setIsPhoneTruncated(true);
            } else {
                setIsPhoneTruncated(false);
            }
        } else {
            setIsPhoneTruncated(false);
        }
    }, [phoneRef])

    useEffect(() => {
        if (teamsRef?.current) {
            const { scrollWidth, clientWidth } = teamsRef.current;
            if (scrollWidth > clientWidth) {
                setIsTeamsTruncated(true);
            } else {
                setIsTeamsTruncated(false);
            }
        } else {
            setIsTeamsTruncated(false);
        }
    }, [teamsRef])

    return (
        <Table.CollapsibleRow
            onToggle={handleToggle}
            collapsed={isCollapsed}
            togglePos={1}
            toggleHidden={false}
            isLoading={isReadingUserGroups}
            toggleDisabled={!groups}
            subrowContent={[
                <UserExtensionsSubrow 
                    extensions={userGroups[id] || []}
                    onSave={onSave}
                    parentId={id}
                    isLoading={isReadingUserGroups}
                />
            ]}
        >
            <Table.Cell>
                <Checkbox 
                    checked={() => isSelected(id)} 
                    onChange={() => onToggleExtension(id)} 
                />
            </Table.Cell>
            <Table.Cell>
                {`Ext. ${extension_number}` || "-"}
            </Table.Cell>
            <Table.Cell>
                {user && (
                    <Person username={`${user.first_name} ${user.last_name}`} email={user.email} />
                )}
            </Table.Cell>
            <Table.Cell>
                <Popup
                    basic
                    on={"click"}
                    position='bottom center'
                    disabled={!isPhoneTruncated}
                    trigger={
                        <div ref={phoneRef} className='UserExtensions__clippedTrigger'>
                            {phones?.map((number, idx) => <span>{PhoneUtil.formatPhoneNumber(number)}{phones.length - 1 === idx ? "" : ", "}</span>)}
                        </div>
                    }
                    content={
                        <div className='UserExtensions__clippedContent'>
                            {phones?.map((number, idx) => {
                                return <div style={{marginRight: 50, marginBottom: idx === phones.length - 1 ? 0 : 7}}>
                                    <span>{PhoneUtil.formatPhoneNumber(number)}</span>
                                </div>
                            })}
                        </div>
                    }
                />
            </Table.Cell>
            <Table.Cell>
                <Popup
                    basic
                    on={"click"}
                    position='bottom center'
                    disabled={!isTeamsTruncated}
                    trigger={
                        <div ref={teamsRef} className='UserExtensions__clippedTrigger'>
                            {teams?.map(({name}, idx) => <span>{name}{teams.length - 1 === idx ? "" : ", "}</span>)}
                        </div>
                    }
                    content={
                        <div className='UserExtensions__clippedContent'>
                            {teams?.map(({name}, idx) => {
                                return <div style={{marginRight: 50, marginBottom: idx === teams.length - 1 ? 0 : 7}}>
                                    <span>{name}</span>
                                </div>
                            })}
                        </div>
                    }
                />
            </Table.Cell>
            <Table.Cell>
                {`VM ${box}` || ''}
            </Table.Cell>
            <Table.Cell>
                <Popup
                    style={{minWidth:400}}
                    trigger={
                        <Icon className="fa-solid fa-music" size="large" disabled={!!!url} />
                    }
                    on="click"
                    content={
                        <VoicemailPlayback
                            onClose={() => setIsVoicemailPlaybackOpen(false)}
                            className={`audio-playback-${sound_id}`}
                            fileName={sound_label}
                            link={url}
                        />
                    }
                    position="bottom center"
                    open={isVoicemailPlaybackOpen}
                    onOpen={() => setIsVoicemailPlaybackOpen(true)}
                />
            </Table.Cell>
            <Table.Cell>
                {pin}
            </Table.Cell>
            <Table.Cell>
                <Popup
                    style={{minWidth:250}}
                    trigger={
                        <EllipsisDropdown {...isLoginInfoOpen ? { isDropdownOpen: false } : {}}>
                            <DropdownItem 
                                text={"View login info"}
                                icon={'fa-solid fa-eye'}
                                onClick={() => setIsLoginInfoOpen(true)}
                                disabled={!isExternalSoftphone}
                            />
                            <DropdownItem 
                                text={"Edit"}
                                icon={'fa-solid fa-pencil'}
                                onClick={() => onOpenModal(extension_number)}
                            />
                        </EllipsisDropdown>
                    }
                    on="click"
                    content={
                        <ViewLoginInfoPopup
                            control={control}
                            onNext={handleSubmit(onNext)}
                            isDisabled={!isDirty || !isValid}
                            extension={extension_number}
                            onClose={onCloseViewLoginInfo}
                        />
                    }
                    position="bottom center"
                    open={isLoginInfoOpen}
                    onOpen={() => setIsLoginInfoOpen(true)}
                    onClose={onCloseViewLoginInfo}
                    size='small'
                />
            </Table.Cell>
        </Table.CollapsibleRow>
    );
}

export default UserExtensionsRow;


