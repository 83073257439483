import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function smsHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.SMS_HISTORY, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isGettingSmsHistory: true };
        case getNamedAction(ACTION_NAMES.SMS_HISTORY, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return {
                ...state,
                isGettingSmsHistory: false,
                smsHistory: action.data,
                from: action.data.from,
                history: action.data.history
            };
        case getNamedAction(ACTION_NAMES.SMS_HISTORY, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isGettingSmsHistory: false };

        default:
            return state;
    }
}


export default smsHistoryReducer;
