import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';

const CategorizedTemplates = ({
    onSelectTemplate,
    categorized_templates,
    isReadingCategorizedTemplates
}) => {
    const [open, setOpen] = useState(false);
    const [template, setTemplate] = useState('');
    return (
        <Dropdown
            style={{
                float: 'left',
                marginTop: '0.2em'
            }}
            text={template ? <span className='Task__selected-template'>{template}</span> : 'Select Template'}
            icon={template ? 'times' : 'sort'}
            clearable
            value={template}
            onChange={() => {
                setTemplate('');
                onSelectTemplate(null);
                setOpen(false);
            }}
            onOpen={() => {setOpen(true)}}
            onClose={() => {setOpen(false)}}
            open={open}
            loading={isReadingCategorizedTemplates}
        >
            <Dropdown.Menu>
                {categorized_templates.filter(category => category.templates?.data?.length > 0).map(category => (
                    <Dropdown.Item>
                        <Dropdown simple className='Task__template-selector' fluid key={category.id} text={category.name}>
                            <Dropdown.Menu>
                                {(category.templates?.data || []).map(template => (
                                    <Dropdown.Item onClick={() => {
                                        onSelectTemplate(template.id);
                                        setOpen(false);
                                        setTemplate(template.name)
                                    }} key={template.id}>
                                        {template.name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

const mapStateToProps = state => ({
    categorized_templates: state.template_category.categorized_templates,
    isReadingCategorizedTemplates: state.template_category.isReadingCategorizedTemplates
})

export default connect(mapStateToProps)(CategorizedTemplates);
