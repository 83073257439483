import React from 'react';
import { generateResolver, Notification, STATUS_TYPES, yup } from 'dyl-components';
import { Form, Icon } from 'semantic-ui-react';

import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import automationActions from 'actions/automation';

const EditCapPopup = ({ 
    onClose, 
    refresh,
    user_id,
    user_cap
}) => {
    const dispatch = useDispatch();

    const { formState: { isValid, isDirty }, control, handleSubmit, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            cap: user_cap || 0
        },
        resolver: generateResolver({
            cap: yup.number().typeError('Must be a number').integer('Must be an integer').required('This field is required')
        })
    });

    const onSave = async ({ cap }) => {
        try {
            await dispatch(automationActions.setUserCap(user_id, { cap }));
            Notification.alert('Successfully updated cap!', STATUS_TYPES.SUCCESS);
            onClose();
            if (refresh) {
                refresh();
            }
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update cap', STATUS_TYPES.ERROR);
        }
    }

    const { isUpdatingCap } = useSelector(state => state.automation);

    return (
        <div className='EditCapPopup__container'>
            <Form loading={isUpdatingCap} noValidate className='EditCapPopup__form'>
                <Form.Group>
                    <Controller 
                        name={`cap`}
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Input
                                name={name}
                                value={value}
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } })
                                }}
                                required
                                label='Cap'
                                error={error?.message}
                                placeholder='Enter cap'
                            />
                        )}
                    />
                </Form.Group>
                <div className='EditCapPopup__btnContainer'>
                    <Form.Button loading={isUpdatingCap} disabled={!isValid || !isDirty || isUpdatingCap} onClick={handleSubmit(onSave)} primary>Save</Form.Button>
                </div>
            </Form>
            <Icon
                className='fa-solid fa-xmark EditCapPopup__closeIcon'
                color='grey'
                size='large'
                link
                onClick={() => {
                    onClose();
                    reset({
                        cap: user_cap || 0
                    })
                }}
            />
        </div>
    )
}

export default EditCapPopup;
