import React from 'react';
import { DropdownItem, Header, Icon } from 'semantic-ui-react';
import { ControlledPopup, DateTimeUtils, EllipsisDropdown, Notification, Person, STATUS_TYPES, Table } from 'dyl-components';
import HoverList from './HoverList';
import { useDispatch } from 'react-redux';
import sequencesActions from 'actions/sequences';
import { Link, useNavigate } from 'react-router-dom';

const SequenceRow = ({ sequence }) => {
    const territories = sequence.territories.map(territory => (<><Icon className='fa-solid fa-globe' /> {territory.name}</>));
    const teams = sequence.teams.map(team => (<><Icon className='fa-solid fa-users' /> {team.name}</>));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onDeleteSequence = async () => {
        try {
            await dispatch(sequencesActions.delete(sequence.id));
            Notification.alert(`Successfully deleted sequence!`, STATUS_TYPES.SUCCESS);
            navigate('/settings/sequences?page=1', { replace: true });
            navigate('/settings/sequences', { replace: true });
        } catch (error) {
            console.log(error);
            Notification.alert('Failed to delete sequence', STATUS_TYPES.ERROR);
        }
    }

    return (
        <Table.Row>
            <Table.Cell>
                <div><b>{DateTimeUtils.formatEpoch(sequence.created, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                <div>{DateTimeUtils.formatEpoch(sequence.created, DateTimeUtils.TIME_FORMAT)}</div>
            </Table.Cell>
            <Table.Cell><Link to={`/settings/sequences/${sequence.id}`}>{sequence.name}</Link></Table.Cell>
            <Table.Cell>{sequence.description}</Table.Cell>
            <Table.Cell>
                <HoverList list={[...territories, ...teams]} />
            </Table.Cell>
            <Table.Cell><Person username={sequence.created_by} isUsernameOnly={true} /></Table.Cell>
            <Table.Cell>
                <EllipsisDropdown>
                    <DropdownItem
                        text='Edit'
                        icon='fa-solid fa-pencil'
                        as={Link}
                        to={`/settings/sequences/${sequence.id}`}
                    />
                    <DropdownItem
                        text='Duplicate'
                        icon='fas fa-copy'
                        as={Link}
                        to={`/settings/sequences/duplicate/${sequence.id}`}
                    />
                    <ControlledPopup
                        header={
                            <Header as='h4' textAlign='center'>
                                Are you sure?
                                <Header.Subheader style={{ marginTop: 5 }}>
                                    Delete will also remove this sequence from contacts and cannot be undone.
                                </Header.Subheader>
                            </Header>
                        }
                        trigger={<DropdownItem
                            text='Delete'
                            icon='fa-solid fa-trash'
                        />}
                        onConfirm={onDeleteSequence}
                        inverted
                    />
                </EllipsisDropdown>
            </Table.Cell>
        </Table.Row>
    );
}

export default SequenceRow;
