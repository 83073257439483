import React from 'react';
import { Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import chatRoomsActions from 'actions/chat_rooms';
import { TopPaddedControls } from 'dyl-components';

const AddChatRoomSection = ({ name, onChange, onAddChatRoom, onCancel, isCreating }) => (
    <Form>
        <Form.Group>
            <Form.Input 
                label='Name'
                name='name'
                value={name}
                onChange={onChange}
            />
            <TopPaddedControls>
                {!isCreating && (
                    <Form.Button
                        floated='right'
                        content={'Cancel'}
                        onClick={onCancel}
                    />
                )}
                <Form.Button 
                    primary
                    floated='right'
                    content={'Add'}
                    onClick={onAddChatRoom}
                    loading={isCreating}
                />
            </TopPaddedControls>
        </Form.Group>
    </Form>
)

class AddChatRoomSectionContainer extends React.Component {
    state = {
        name: '',
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onAddChatRoom = () => {
        this.props.onAddChatRoom({ ...this.state });
        this.setState({ name: '' });
    }

    render() {
        return (
            <AddChatRoomSection 
                {...this.props}
                {...this.state}
                onAddChatRoom={this.onAddChatRoom}
                onChange={this.onChange}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        isCreating: state.chatRooms.isCreating
    }
}

const mapDispatchToProps = dispatch => ({
    onAddChatRoom: (newChatRoom) => {
        dispatch(chatRoomsActions.createChatRoom(newChatRoom))
            .then(() => {
                dispatch(chatRoomsActions.readChatRooms());
            });
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(AddChatRoomSectionContainer);
