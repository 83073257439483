import React from 'react';
import { TableWithHeader, Table } from 'dyl-components';
import DefaultMessagesTableHeader from './DefaultMessagesTableHeader';
import DefaultMessagesRow from './DefaultMessagesRow';
import { MOCK_USERS } from '__mocks__/mockData';

class DefaultMessagesTable extends React.Component {
    state = {
        isEditMode: false,
        userMessages: this.props.userMessages
    }

    onChange = (id, { name, value }) => {
        let userMessages = this.state.userMessages.slice(0);
        let indexOfUserMessageToChange = userMessages.findIndex(userMessage => userMessage.id === id);
        if (indexOfUserMessageToChange !== -1) {
            userMessages[indexOfUserMessageToChange] = {...userMessages[indexOfUserMessageToChange], [name]: value};
            this.setState({ userMessages });
        }
    }

    onEnableEditMode = () => {
        this.setState({
            isEditMode: true
        })
    }

    onCloseEditMode = () => {
        this.setState({
            isEditMode: false,
            userMessages: this.props.userMessages
        })
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false,
        }, () => {
            this.props.onUpdate(this.state.userMessages);
        });
    }

    render() {
        let { isEditMode, userMessages } = this.state;
        return (
            <TableWithHeader 
                header={
                    <DefaultMessagesTableHeader 
                        isEditMode={isEditMode}
                        onCloseEditMode={this.onCloseEditMode}
                        onEnableEditMode={this.onEnableEditMode}
                        onUpdate={this.onUpdate}
                    />
                }
                table={
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>User</Table.HeaderCell>
                                <Table.HeaderCell>Message</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {userMessages.map(userMessage => (
                                <DefaultMessagesRow 
                                    userMessage={userMessage}
                                    isEditMode={isEditMode}
                                    messageOptions={this.props.messageOptions}
                                    onChange={this.onChange}
                                />
                            ))}
                        </Table.Body>
                    </Table>
                }
            />
        )
    }
}

class DefaultMessagesTableContainer extends React.Component {
    state = {
        userMessages: MOCK_USERS.map(user => ({
            id: user.id, user: user.name, message: 'qfu'
        }))
    }

    onUpdate = (newUserMessages) => {
        this.setState({ userMessages: newUserMessages });
    }

    render() {
        return (
            <DefaultMessagesTable 
                {...this.state}
                {...this.props}
                onUpdate={this.onUpdate}
            />
        )
    }
}

export default DefaultMessagesTableContainer;
