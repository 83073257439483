import React from 'react';
import VoicePrompt from './subcomponents/VoicePrompt';
import PromptOptionsContainer from './subcomponents/PromptOptions';
import { STATUS_TYPES, Notification } from 'dyl-components';
import { Transition } from 'semantic-ui-react';

const IVRPrompt = ({ info, onUpdate }) => (
    <React.Fragment>
        <VoicePrompt info={info} onUpdate={onUpdate} />
        <Transition unmountOnHide visible={!info.isMessageOnly}>
            <div>
                <PromptOptionsContainer />
            </div>
        </Transition>
    </React.Fragment>
);

class IVRPromptContainer extends React.Component {
    state = {
        info: {
            number: 1,
            name: 'Vacation',
            messageId: 'vct1',
            isMessageOnly: false,
            areDirectExtensionsAllowed: true,
            fallbackExtension: 'Hangup',
            internalExtension: '',
            isSpeechDetectionEnabled: false
        }
    }

    onUpdate = (newInfo) => {
        Notification.alert('Succefully updated voice prompt!', STATUS_TYPES.SUCCESS, true);
        this.setState({
            info: newInfo
        });
    }

    render() {
        return (
            <IVRPrompt 
                {...this.state}
                onUpdate={this.onUpdate}
            />
        )
    }
}

export default IVRPromptContainer;
