import contactActions from 'actions/contact';
import { generateResolver, Notification, STATUS_TYPES, VALIDATORS } from 'dyl-components';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Icon, Form } from 'semantic-ui-react';

export const ProfileName = ({ isEditing, onEdit, onCancelEdit }) => {
    const { first_name, last_name, suffix } = useSelector(state => state.contact.contact);
    const name = `${first_name} ${last_name}` + (suffix ? ` ${suffix}` : ``);

    const contact_id = useSelector(state => Number(state.contact.contact_id));
    const isUpdating = useSelector(state => !!state.contact.contactBeingUpdated);
    const dispatch = useDispatch();

    const { control, formState: { isValid, isDirty }, reset, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            first_name: first_name || '',
            last_name: last_name || '',
            suffix: suffix || ''
        },
        resolver: generateResolver({
            first_name: VALIDATORS.FIRST_NAME().required('This field is required'),
            last_name: VALIDATORS.LAST_NAME().required('This field is required'),
            suffix: VALIDATORS.SUFFIX(),
        })
    })

    const onCancel = () => {
        onCancelEdit();
        reset({
            first_name: first_name || '',
            last_name: last_name || '',
            suffix: suffix || ''
        })
    }

    const onConfirmEdit = async (data) => {
        try {
            await dispatch(contactActions.updateContact(contact_id, data));
            Notification.alert("Successfully updated name!", STATUS_TYPES.SUCCESS);
            dispatch(contactActions.readContact(contact_id));
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to update name", STATUS_TYPES.ERROR);
        }
    }

    return (
        !isEditing ? (
            <Header as='h3' className='ProfileHeader__header'>
                <div className='ProfileHeader__container'>
                    <div onClick={onEdit} className='ProfileHeader__nameContainer ProfileHeader__editable'>
                        {name}
                    </div>
                </div>
            </Header>
        ) :
            <Form size='mini' loading={isUpdating}>
                <Form.Group>
                    <Controller
                        name='first_name'
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Input
                                width={4}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                error={error?.message}
                                placeholder='First'
                                fluid
                            />
                        )}
                    />
                    <Controller
                        name='last_name'
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Input
                                width={4}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                error={error?.message}
                                placeholder='Last'
                                fluid
                            />
                        )}
                    />
                    <Controller
                        name='suffix'
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Input
                                width={3}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                error={error?.message}
                                placeholder='Suff.'
                                fluid
                            />
                        )}
                    />
                    <Form.Field width={1}>
                        <Icon size='large' color='red' link onClick={onCancel} className='fa-solid fa-xmark' />
                    </Form.Field>
                    <Form.Field width={1}>
                        <Icon size='large' disabled={!isValid || !isDirty} color='primary' link onClick={handleSubmit(onConfirmEdit)} className='fa-solid fa-check' />
                    </Form.Field>
                </Form.Group>
            </Form>
    );
}
