import React from 'react';
import { Segment, Grid, Radio, Icon, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { CheckboxDropdown } from 'dyl-components';

export const CampaignWirelessFilter = ({
    onChangeWirelessSkip,
    lastWirelessCheckDate = new Date().toDateString(),
    voicemailMessage,
    numberOfUncalledLeads = 0,
    numberOfCalledLeads = 0,
    campaignName,
    assignedScripts,
    onChange,
    isCampaignNameBeingEditted,
    onEditCampaignName,
    onCancelCampaignNameEdit,
    onConfirmCampaignNameEdit,
    onCampaignNameChange
}) => (
        <Grid celled as={Segment}>
            <Grid.Row columns='equal' className='WirelessFilter__header'>
                <Grid.Column>
                    {isCampaignNameBeingEditted ? (
                        <React.Fragment>
                            <Input transparent onChange={onCampaignNameChange} defaultValue={campaignName} />
                            <Icon name='times' link onClick={onCancelCampaignNameEdit} />
                            <Icon name='check' link onClick={onConfirmCampaignNameEdit} />
                        </React.Fragment>
                    ) : (
                            <React.Fragment>
                                {campaignName} <Icon name='pencil' link onClick={onEditCampaignName} />
                            </React.Fragment>
                        )}

                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal' className='WirelessFilter__header'>
                <Grid.Column>
                    Skip Wireless
            </Grid.Column>
                <Grid.Column>
                    Wireless Check
            </Grid.Column>
                <Grid.Column>
                    Voicemail Message
            </Grid.Column>
                <Grid.Column>
                    Assign Script
            </Grid.Column>
                <Grid.Column>
                    Leads
            </Grid.Column>
                <Grid.Column>
                    Uncalled
            </Grid.Column>
                <Grid.Column>
                    Called
            </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <Radio label='Yes' onChange={onChangeWirelessSkip} name='isWirelessSkipped' className='WirelessFilter__radio-button' />
                    <Radio label='No' onChange={onChangeWirelessSkip} name='isWirelessSkipped' className='WirelessFilter__radio-button' />
                </Grid.Column>
                <Grid.Column>
                    <Link to='/blast_lists'>Check</Link>
                    <span className="lastWirelessCheckDate">{lastWirelessCheckDate}</span>
                </Grid.Column>
                <Grid.Column>
                    {voicemailMessage}
                </Grid.Column>
                <Grid.Column>
                    Assign Script
                    <CheckboxDropdown
                        options={[
                            { key: "s1", value: "s1", text: "Script 1" },
                            { key: "s2", value: "s2", text: "Script 2" },
                            { key: "s3", value: "s3", text: "Script 3" },
                            { key: "s4", value: "s4", text: "Script 4" }
                        ]}
                        onChange={onChange}
                        name={"assignedScripts"}
                        value={assignedScripts}
                    />
                </Grid.Column>
                <Grid.Column>
                    {numberOfUncalledLeads + numberOfCalledLeads}
                </Grid.Column>
                <Grid.Column>
                    {numberOfUncalledLeads}
                </Grid.Column>
                <Grid.Column>
                    {numberOfUncalledLeads}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )

class CampaignWirelessFilterContainer extends React.Component {
    state = {
        isCampaignNameBeingEditted: false,
        campaignName: '',
        assignedScripts: []
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onCampaignNameChange(_, { value }) {
        this.setState({ campaignName: value });
    }

    onEditCampaignName() {
        this.setState({ isCampaignNameBeingEditted: true })
    }

    onCancelCampaignNameEdit() {
        this.setState({ isCampaignNameBeingEditted: false })
    }

    onConfirmCampaignNameEdit() {
        this.setState({ isCampaignNameBeingEditted: false })
    }

    render() {
        return (
            <CampaignWirelessFilter
                {...this.state}
                {...this.props}
                onChange={this.onChange}
                onEditCampaignName={this.onEditCampaignName.bind(this)}
                onCancelCampaignNameEdit={this.onCancelCampaignNameEdit.bind(this)}
                onConfirmCampaignNameEdit={this.onConfirmCampaignNameEdit.bind(this)}
                onCampaignNameChange={this.onCampaignNameChange.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state, ownProps) => {

}

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(CampaignWirelessFilterContainer);
