import React from 'react';
import { Checkbox, Dimmer, Grid, Header, Loader } from 'semantic-ui-react';
import { StatisticCard, Button, Modal, DateInput, DateTimeUtils } from 'dyl-components';
import { connect } from 'react-redux';

const RescheduleModal = ({
    open,
    onClose,
    workflowName,
    workflowDate,
    rescheduledDate,
    isCascading,
    differenceOfRescheduledDate,
    totalEvents,
    numberOfCalls = 0,
    numberOfTexts = 0,
    numberOfEmails = 0,
    numberOfTasks = 0,
    onReschedule,
    isReadingContactWorkflows,
    onChange
}) => (
        <Modal open={open} onClose={onClose} animated>
            <Modal.Content>
                <Dimmer active={isReadingContactWorkflows}>
                    <Loader />
                </Dimmer>
                <Header size='large' textAlign='center' color='primary'>
                    {workflowName}
                </Header>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <DateInput
                                inline
                                minDate={DateTimeUtils.getTime().moment}
                                marked={DateTimeUtils.formatEpoch(workflowDate, DateTimeUtils.DATE_FORMAT)}
                                markColor='blue'
                                value={rescheduledDate}
                                name='rescheduledDate'
                                dateFormat={DateTimeUtils.DATE_FORMAT}
                                onChange={onChange} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns='equal'>
                        <Grid.Column width={3}>
                            <Checkbox
                                radio
                                label='Just this event'
                                name='isCascading'
                                value={false}
                                onChange={onChange}
                                checked={!isCascading}
                            />
                            <Checkbox
                                radio
                                label='All subsequence'
                                name='isCascading'
                                value={true}
                                onChange={onChange}
                                checked={isCascading}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <p>
                                {`This action modifies the date for this event 
                                    ${isCascading ? `and the subsequent ${totalEvents - 1} item${totalEvents - 1 === 1 ? '' : 's'} ` : ''} 
                                    by ${differenceOfRescheduledDate} Day${differenceOfRescheduledDate === 1 ? '' : 's'}.`}
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns='equal'>
                        <Grid.Column>
                            <StatisticCard
                                name={'Calls'}
                                count={numberOfCalls}
                                icon={'phone'}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <StatisticCard
                                name={'Emails'}
                                count={numberOfEmails}
                                icon={'mail'}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <StatisticCard
                                name={'Texts'}
                                count={numberOfTexts}
                                icon={'conversation'}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <StatisticCard
                                name={'Tasks'}
                                count={numberOfTasks}
                                icon={'tasks'}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose} basic>Cancel</Button>
                <Button onClick={onReschedule} primary>Reschedule</Button>
            </Modal.Actions>
        </Modal>
    );

class RescheduleModalContainer extends React.Component {

    state = {
        rescheduledDate: null,
        isCascading: true,
        differenceOfRescheduledDate: 0,
        numberOfCalls: 0,
        numberOfTexts: 0,
        numberOfEmails: 0,
        numberOfTasks: 0,
    }

    componentDidMount() {
        return this.props.onReadContactWorkflows(this.props.contact_id, {
            ...this.props.queryParameters,
            action: 'call,task,email,text', status: 'pending,upcoming', limit: 50
        }).then(() => {
            this.setState({
                numberOfCalls: this.props.workflows.filter(workflow => workflow.action === 'call').length,
                numberOfTexts: this.props.workflows.filter(workflow => workflow.action === 'text').length,
                numberOfEmails: this.props.workflows.filter(workflow => workflow.action === 'email').length,
                numberOfTasks: this.props.workflows.filter(workflow => workflow.action === 'task').length,
            })
        })
    }

    onChange = (_, { name, value }) => {
        this.setState({
            [name]: value
        })
    }

    onReschedule = () => {
        let { rescheduledDate, isCascading } = this.state;
        let date = rescheduledDate || DateTimeUtils.formatEpoch(this.props.workflowDate, DateTimeUtils.DATE_FORMAT);
        let time = DateTimeUtils.formatEpoch(this.props.workflowDate);
        return this.props.onUpdateEvent(DateTimeUtils.getUnixTime(`${date} ${time}`, DateTimeUtils.DATETIME_FORMAT), isCascading).then(() => {
            this.props.onClose();
            this.props.onRead();
        })
    }

    render() {
        const { numberOfCalls, numberOfTexts, numberOfEmails, numberOfTasks } = this.state;
        return (
            <RescheduleModal
                {...this.props}
                {...this.state}
                totalEvents={numberOfCalls + numberOfTexts + numberOfEmails + numberOfTasks}
                onChange={this.onChange}
                onReschedule={this.onReschedule}
                differenceOfRescheduledDate={DateTimeUtils.getDifference(this.state.rescheduledDate || DateTimeUtils.formatEpoch(this.props.workflowDate, DateTimeUtils.DATE_FORMAT), DateTimeUtils.formatEpoch(this.props.workflowDate, DateTimeUtils.DATE_FORMAT))} />
        )
    }
}

RescheduleModal.ID = 'RESCHEDULE_MODAL';

const mapStateToProps = state => {
    return ({
        workflows: state.workflows.contact_workflows,
        isReadingContactWorkflows: state.workflows.isReadingContactWorkflows,
        queryParameters: state.workflows.queryParameters,
    })
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onUpdateEvent: (ts, cascade) => {

    },
    onReadContactWorkflows: (contact_id, queryParameters) => {

    },
});

export default connect(mapStateToProps, mapDispatchToProps)(RescheduleModalContainer);
