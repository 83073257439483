import React from 'react';
import { Controller } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import { ToggableDropdown } from 'dyl-components';

const MoveContactForm = ({ control, groupOptions, getSubgroups, isReadingGroups, handleSearchChange, search, setSearch }) => {
    return (
        <Form>
            <Form.Group>
                <Controller
                    name={"group"}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Field
                            control={ToggableDropdown}
                            value={value}
                            nested_options={groupOptions}
                            onChange={( _, { value }) => {
                                onChange({ target: { name, value }});
                            }}
                            placeholder='Select Group/Subgroup'
                            label='Group/Subgroup'
                            onHoverParent={getSubgroups}
                            loading={isReadingGroups}
                            onSearchChange={handleSearchChange}
                            searchValue={search}
                            resetSearchValue={() => setSearch("")}
                            width={16}
                            selection
                            required
                        />
                    )}
                />
            </Form.Group>
        </Form>
    ); 
}

export default MoveContactForm;