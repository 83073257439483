const READ_ACCOUNT_NOTES_REQUEST = 'READ_ACCOUNT_NOTES_REQUEST';
const READ_ACCOUNT_NOTES_SUCCESS = 'READ_ACCOUNT_NOTES_SUCCESS';
const READ_ACCOUNT_NOTES_FAILURE = 'READ_ACCOUNT_NOTES_FAILURE';

const ADD_LEAD_NOTE_REQUEST = 'ADD_LEAD_NOTE_REQUEST';
const ADD_LEAD_NOTE_SUCCESS = 'ADD_LEAD_NOTE_SUCCESS';
const ADD_LEAD_NOTE_FAILURE = 'ADD_LEAD_NOTE_FAILURE';

const CLEAR_ADD_LEAD_NOTE_MESSAGE = 'CLEAR_ADD_LEAD_NOTE_MESSAGE';

export default {
    READ_ACCOUNT_NOTES_REQUEST,
    READ_ACCOUNT_NOTES_SUCCESS,
    READ_ACCOUNT_NOTES_FAILURE,

    ADD_LEAD_NOTE_REQUEST,
    ADD_LEAD_NOTE_SUCCESS,
    ADD_LEAD_NOTE_FAILURE,

    CLEAR_ADD_LEAD_NOTE_MESSAGE
};
