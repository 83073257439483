import React from 'react';

import { Form, ListItem, Icon, Transition } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';


export default function LocationOptions(props) {
    let { control, includeCallInfo, toggleCallInfo, isReadOnly } = props;

    return <>
        <ListItem
            className='Event__location'
            icon={
                <Icon name='map marker alternate' color='black' />
            }
            description={(
                <Form.Group>
                    <Controller
                        name='location'
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Form.Input
                                label='Event location'
                                onChange={onChange}
                                value={value}
                                placeholder="Type location"
                                width={8}
                                disabled={isReadOnly}
                                error={error && error.message && {
                                    content: error.message,
                                    pointing: 'above'
                                }}
                            />
                        )}
                    />
                    <Transition visible={!includeCallInfo} animation='fade' duration='100' unmountOnHide={false}>
                        <Form.Button type="button" onClick={() => { toggleCallInfo(true) }} size='small' className="Event__no-label" primary disabled={isReadOnly}>
                            + Add Call Info
                        </Form.Button>
                    </Transition>
                </Form.Group>
            )}
        />
        <Transition visible={includeCallInfo} animation='slide down' duration='100'>
            <div>
                <Form.Group className="Event__row">
                    <Controller
                        name='conference_line'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Form.Input
                                width={8}
                                label='Add conference line'
                                placeholder='URL'
                                onChange={onChange}
                                value={value}
                                readOnly={isReadOnly}
                            />
                        )}
                    />
                    <Controller
                        name='phone_number'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Form.Input
                                width={4}
                                className="Event__no-label"
                                placeholder='Phone number'
                                onChange={onChange}
                                value={value}
                                readOnly={isReadOnly}
                            />
                        )}
                    />
                    <Controller
                        name='pin'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Form.Input
                                width={3}
                                className="Event__no-label"
                                placeholder='PIN'
                                onChange={onChange}
                                value={value}
                                readOnly={isReadOnly}
                            />
                        )}
                    />
                    <Form.Field className="Event__no-label" width={1} disabled={isReadOnly}>
                        <Icon color='black' name='times' link onClick={() => { toggleCallInfo(false) }} />
                    </Form.Field>
                </Form.Group>
            </div>
        </Transition>
    </>
}
