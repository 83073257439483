import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    Header,
    Pagination,
    Segment,
    Table as SemanticTable,
} from "semantic-ui-react";
import { Table, TableLoader } from "dyl-components";

import {
    getDataNonQuery,
    onToggleTimeUnitNonQuery,
    useRangeCalendar,
} from "shared/CustomDateRange/customDateRange";

import quoteActions from "actions/quote";

import QuoteTableHeader from "./QuoteTableHeader";
import QuotesTableRow from "./QuotesTableRow";
import QuoteBuilderModal from "shared/modals/QuoteBuilderModal";

const QuoteSection = () => {
    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const limit = 25;
    const module_id = params.get("module_id");

    const [dateRange, setDateRange] = useState("-");
    const [activePage, setActivePage] = useState(1);

    const [sortType, setSortType] = useState("created_on_sort");
    const [sortDirection, setSortDirection] = useState(false);

    const [filters, setFilters] = useState({});

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value,
        });
        setActivePage(1);
    };

    useEffect(() => {
        const dateRangeArray = dateRange.split("-");

        dispatch(
            quoteActions.getQuotesByOpportunityTabView(module_id, {
                page: activePage,
                limit: limit,
                [sortType]: sortDirection ? "asc" : "desc",
                ...filters,
                ...(!(dateRangeArray[0] === "" && dateRangeArray[1] === "") && {
                    created_on_start: dateRangeArray[0],
                    cerated_on_end: dateRangeArray[1],
                }),
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dateRange,
        sortType,
        sortDirection,
        module_id,
        activePage,
        limit,
        filters,
    ]);

    const { isReading, quotes, count } = useSelector(
        (state) =>
            state.quote.opportunityQuotes[module_id] || {
                isReading: false,
                quotes: [],
            }
    );

    const {
        contact_id,
        account_id,
        account_is_a_customer,
        current_primary_contact_id
    } = useSelector((state) => {
        const {
            contact,
        } = state.module_information.pipeline;

        return {
            contact_id: contact?.id,
            account_id: contact?.account?.id,
            account_is_a_customer:
                contact?.account?.customer,
            current_primary_contact_id:
                contact?.account
                    ?.primary_contact_id
        };
    });

    const onToggleTimeUnit = (
        timestamp,
        setTimestamp,
        setTimeUnitOptionsOpen
    ) => {
        const timestampRange = onToggleTimeUnitNonQuery(
            timestamp,
            setTimestamp
        );

        if (timestamp !== "Custom Range") {
            setDateRange(
                `${
                    timestampRange?.start !== undefined
                        ? timestampRange.start
                        : ""
                }-${
                    timestampRange?.end !== undefined ? timestampRange.end : ""
                }`
            );
            setTimeUnitOptionsOpen(false);
        }
    };

    const getData = (setCustomDateRangeOpen, dateRange) => {
        const timestampRange = getDataNonQuery(
            setCustomDateRangeOpen,
            dateRange
        );
        setDateRange(
            `${
                timestampRange?.start !== undefined ? timestampRange.start : ""
            }-${timestampRange?.end !== undefined ? timestampRange.end : ""}`
        );
    };

    const onToggleSort = (newSortType) => {
        if (sortType === newSortType) {
            setSortDirection(!sortDirection);
        } else {
            setSortDirection(false);
        }
        setSortType(newSortType);
        setActivePage(1);
    };

    const onPageChange = (_, { activePage }) => {
        setActivePage(activePage);
    };

    return (
        <>
            <div className="RightSidebar__QuoteSection">
                <div className="OverflowContainer">
                    <Table className="QuotesTable" striped={false}>
                        <SemanticTable.Header className="Table__header--primary">
                            <QuoteTableHeader
                                useRangeCalendar={useRangeCalendar(null, false)}
                                onToggleTimeUnit={onToggleTimeUnit}
                                getTimeData={getData}
                                sortType={sortType}
                                sortDirection={sortDirection}
                                onToggleSort={onToggleSort}
                                onFilter={onFilter}
                                filters={filters}
                            />
                        </SemanticTable.Header>
                        <Table.Body>
                            {isReading ? (
                                <TableLoader
                                    isLoading={isReading}
                                    colspan={8}
                                />
                            ) : (
                                quotes.map((quote) => (
                                    <QuotesTableRow
                                        key={quote.id}
                                        quote={quote}
                                    />
                                ))
                            )}
                        </Table.Body>
                    </Table>
                    {!isReading && count === 0 && (
                        <Header as="h3">No Results Found</Header>
                    )}
                </div>
                <Segment className="PaginationSegment" textAlign="right">
                    <Pagination
                        boundaryRange={0}
                        activePage={activePage || 1}
                        ellipsisItem={null}
                        siblingRange={2}
                        totalPages={Math.ceil(count / limit)}
                        onPageChange={onPageChange}
                        disabled={isReading}
                    />
                </Segment>
                <QuoteBuilderModal
                    account_id={account_id}
                    contact_id={contact_id}
                    opportunity_id={module_id}
                    isLinkedToCustomer={account_is_a_customer}
                    account_primary_contact_id={current_primary_contact_id}
                />
            </div>
        </>
    );
};

export default QuoteSection;
