import React from 'react';

import './index.scss';

class PrintComponent extends React.Component {

    render() {
        const { content } = this.props;
        return (
            <div className='print-container' style={{ padding: 0, margin: 0 }}>
                <div className="page-break" />
                  {content}
            </div>
        )
    }
}

export default PrintComponent;
