import React from "react";
import { List, Form, Icon, ListItem, Grid, Input } from "semantic-ui-react";
import { Controller } from 'react-hook-form';
import DirectionField from '../../NewTasksTab/subcomponents/DirectionField';


const TaskCallConfirmationForm = ({ isAllowedToModify, complete, isReadingLabels, id, control, callResultOptions }) => {

    return (
        <Form as={List}>
            <React.Fragment>
                <DirectionField
                    control={control}
                    id={id}
                    isAllowedToModify={isAllowedToModify}
                    complete={complete}
                />

                <ListItem
                    icon='clock'
                    description={(
                        <Grid>
                            <Grid.Row columns='equal'>
                                <Grid.Column>
                                    <Controller
                                        name='hours'
                                        control={control}
                                        render={({ field: { onChange, value, name } }) => (
                                            <Form.Field>
                                                <label>Call Duration</label>
                                                <Input
                                                    label={{
                                                        basic: true,
                                                        children: 'Hr(s)'
                                                    }}
                                                    value={value}
                                                    defaultValue={0}
                                                    name={name}
                                                    type='number'
                                                    min='0'
                                                    step='1'
                                                    max='24'
                                                    onChange={(_, { name, value }) => {
                                                        onChange({ target: { name, value } });
                                                    }}
                                                    onKeyPress={(e) => {
                                                        if (!/[0-9]/.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    readOnly={!isAllowedToModify}
                                                    labelPosition='right'
                                                    disabled={complete}
                                                    size="small"
                                                />
                                            </Form.Field>
                                        )}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Controller
                                        name='minutes'
                                        control={control}
                                        render={({ field: { onChange, value, name } }) => (
                                            <Form.Field className='Task__no-label'>
                                                <Input
                                                    label={{
                                                        basic: true,
                                                        children: 'min(s)'
                                                    }}
                                                    style={{ marginTop: '-0.2em' }}
                                                    value={value}
                                                    defaultValue={0}
                                                    name={name}
                                                    type='number'
                                                    min='1'
                                                    step='1'
                                                    max='60'
                                                    onChange={(_, { name, value }) => {
                                                        onChange({ target: { name, value } });
                                                    }}
                                                    onKeyPress={(e) => {
                                                        if (!/[0-9]/.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    readOnly={!isAllowedToModify}
                                                    labelPosition='right'
                                                    disabled={complete}
                                                    size="small"
                                                />
                                            </Form.Field>
                                        )}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    )}
                />
                <Controller
                    name='call_result'
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                        <ListItem
                            icon={
                                <Icon name='call' color='black' />
                            }
                            description={(
                                <Form.Field>
                                    <label>Call Result</label>
                                    <Form.Select
                                        defaultValue="reached"
                                        value={value}
                                        name={name}
                                        onChange={(_, { name, value }) => {
                                            onChange({ target: { name, value } })
                                        }}
                                        options={callResultOptions}
                                        placeholder="Select Result"
                                        selectOnBlur={false}
                                        disabled={isReadingLabels || complete}
                                        {...(!isAllowedToModify ? { open: false, clearable: false } : {})}
                                    />
                                </Form.Field>
                            )}
                        />

                    )}
                />
            </React.Fragment>
        </Form>
    )

}

export default TaskCallConfirmationForm;