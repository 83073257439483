import types from "actions/call_recordings/types";
import axiosInstance from "actions/axiosInstance";
import routes from "actions/routes";

function getCallRecordingsSettingsOptions() {
    return dispatch => {
        dispatch({ type: types.READ_CALL_RECORDINGS_SETTINGS_OPTIONS_REQUEST });

        return axiosInstance.instance1.get(routes.API_CALL_RECORDINGS_OPTIONS)
            .then(function (response) {
                console.log(response);

                const { options, disabled } = response.data;
                dispatch({
                    type: types.READ_CALL_RECORDINGS_SETTINGS_OPTIONS_SUCCESS,
                    isUserAccessEnabled: options.filter(option => option.name === 'share')[0].value,
                    isDeleteRecordingsEnabled: options.filter(option => option.name === 'delete')[0].value,
                    isRecordingConsented: options.filter(option => option.name === 'consent')[0].value,
                    disabled
                });
            })
            .catch(function (error) {
                console.log(error);

                dispatch({
                    type: types.READ_CALL_RECORDINGS_SETTINGS_OPTIONS_FAILURE
                });
            })
    }
}

export default {
    getCallRecordingsSettingsOptions
}
