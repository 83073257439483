import React from 'react';
import { Form } from 'semantic-ui-react';
import { TopPaddedControls } from 'dyl-components';

const AddMemberSection = ({ ext, extOptions, onChange, onAddMember, onClose }) => (
    <Form>
        <Form.Group widths='equal'>
            <Form.Select 
                label='Member'
                name='ext'
                options={extOptions}
                value={ext}
                onChange={onChange}
                multiple
                search
            />
            <TopPaddedControls>
                <Form.Button
                    floated='right'
                    content={'Cancel'}
                    onClick={onClose}
                />
                <Form.Button 
                    primary
                    floated='right'
                    content={'Add'}
                    onClick={onAddMember}
                />
            </TopPaddedControls>
        </Form.Group>
    </Form>
)

class AddMemberSectionContainer extends React.Component {
    state = {
        ext: [this.props.extOptions[0].value]
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onAddMember = () => {
        this.props.onAddMember(this.state.ext);
    }

    render() {
        return (
            <AddMemberSection 
                {...this.props}
                {...this.state}
                onAddMember={this.onAddMember}
                onChange={this.onChange}
            />
        )
    }
}

export default AddMemberSectionContainer;
