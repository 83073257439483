import CustomerPipelines from 'pages/Pipeline/CustomerPipelines';
import LeadPipelines from 'pages/Pipeline/LeadPipelines' 
import OpportunityPipelines from 'pages/Pipeline/OpportunityPipelines';

import React from 'react';
import { Route } from 'react-router-dom';

export const PIPELINE_ROUTES = [
    <Route path='lead-pipelines' element={<LeadPipelines />} />,
    <Route path='customer-pipelines' element={<CustomerPipelines />} />,
    <Route path='opportunity-pipelines' element={<OpportunityPipelines />} />,
];
