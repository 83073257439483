import React, { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

import './SocialMedia.scss';
import SocialNetwork from '../pages/ContactProfile/SocialNetwork';
import { useLocation } from 'react-router-dom';

const SocialMedia = () => {
    const [activeSocial, setActiveSocial] = useState('');

    const onChangeSocialMediaType = (_, { value }) => {
        setActiveSocial(value);
    }

    const onOpen = (type) => {
        setActiveSocial(type);
    }

    const onClose = () => {
        setActiveSocial('');
    }

    const { pathname } = useLocation();
    const record_type = pathname.split('/')[1];

    const socials = useSelector(state => {
        const social_media = state[record_type].social_media;
        return [
            {
                links: social_media?.filter(({ type }) => type === 'facebook') || [],
                type: 'facebook'
            },
            {
                links: social_media?.filter(({ type }) => type === 'linkedin') || [],
                type: 'linkedin'
            },
            {
                links: social_media?.filter(({ type }) => type === 'twitter') || [],
                type: 'twitter'
            }
        ]
    })

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column style={{ marginTop: '0.5em' }}>
                    {socials.map(social => (
                        <SocialNetwork
                            icon={social.type}
                            links={social.links || []}
                            onChangeSocialMediaType={onChangeSocialMediaType}
                            onClose={onClose}
                            isActive={activeSocial === social.type}
                            onOpen={() => {onOpen(social.type)}}
                            activeSocial={activeSocial}
                        />
                    ))}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default SocialMedia;
