import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { Modal, ModulesFilter } from 'dyl-components';
import AddContactModal from 'shared/modals/AddContactModal';

import {
    KEY_CREATED_ON, KEY_OWNED_BY, KEY_LAST_MODIFIED,
    KEY_CONVERT, KEY_ACCOUNT,
    KEY_ACTIONS, KEY_CONTACT_NAME
} from './constants';
import ContactBulkActionsModal from 'shared/modals/ContactBulkActionsModal';
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';

const COLUMN_ROWS = [
    [
        { value: KEY_CREATED_ON, label: "Created" },
        { value: KEY_OWNED_BY, label: "Owned By" }
    ],
    [
        { value: KEY_LAST_MODIFIED, label: "Last Modified" },
        { value: KEY_CONTACT_NAME, label: "Contact Name", isForcedChecked: true }
    ],
    [
        { value: KEY_CONVERT, label: "Convert" },
        { value: KEY_ACCOUNT, label: "Account" }
    ],
    [
        { value: KEY_ACTIONS, label: "Actions" }
    ]
];

const ContactsToolbar = ({ search, onSearchSubmit, cancelFunction, onChangeSearch, isColumnHidden, onToggleColumn }) => {
    const [selectedContacts, , areContactsInAllPagesSelected] = useContext(BulkActionsContext);

    const { isReadingContacts, count } = useSelector(state => ({
        isReadingContacts: state.contacts.isReadingContacts,
        count: state.contacts.count
    }));

    const hasSelectedContacts = selectedContacts.length || areContactsInAllPagesSelected;
    const numberOfSelectedContacts = areContactsInAllPagesSelected ? count : selectedContacts.length;

    const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);

    const onOpenAddContactModal = () => {
        setIsAddContactModalOpen(true);
    }

    const onCloseAddContactModal = () => {
        setIsAddContactModalOpen(false);
    }

    const [isBulkActionsModalOpen, setIsBulkActionsModalOpen] = useState(false);

    const onOpenBulkActionsModal = () => {
        setIsBulkActionsModalOpen(true);
    }

    const onCloseBulkActionsModal = () => {
        setIsBulkActionsModalOpen(false);
    }

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row columns='equal' verticalAlign='middle'>
                    <Grid.Column width={5}>
                        <ModulesFilter
                            isLoading={isReadingContacts}
                            search={search}
                            onSearchSubmit={onSearchSubmit}
                            cancelFunction={cancelFunction}
                            onChangeSearch={onChangeSearch}
                            columnRows={COLUMN_ROWS}
                            columnCount={2}
                            isColumnHidden={isColumnHidden}
                            onToggleColumn={onToggleColumn}
                        />
                    </Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column width={3} floated='right'>
                        <Button floated='right' className='ContactsToolbar__NewContactsButton' disabled={!hasSelectedContacts} onClick={onOpenBulkActionsModal}>
                            Bulk Action
                        </Button>
                    </Grid.Column>
                    <Grid.Column width={2} floated='right'>

                        <Button floated='right' className='ContactsToolbar__NewContactsButton' onClick={onOpenAddContactModal}>
                            <Icon name='plus' /> New Contact
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <ContactBulkActionsModal
                open={isBulkActionsModalOpen}
                onClose={onCloseBulkActionsModal}
                numberSelectedContacts={numberOfSelectedContacts}
            />
            <Modal open={isAddContactModalOpen} onClose={onCloseAddContactModal}>
                <AddContactModal onOpen={() => { return Promise.resolve(); }} onClose={onCloseAddContactModal} />
            </Modal>
        </React.Fragment>

    )
}

export default ContactsToolbar;
