import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function tasksReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.TASKS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingTasks: true, tasks: [] };
        case getNamedAction(ACTION_NAMES.TASKS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {
                ...state,
                isReadingTasks: false,
                tasks: action.data.data,
                ...action.data.counts,
                queryParameters:
                    action.queryParameters
            };
        case getNamedAction(ACTION_NAMES.TASKS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingTasks: false };

        case getNamedAction(ACTION_NAMES.TASKS_TAB, CRUD_ACTION_TYPES.READ_REQUEST):
            return {
                ...state, isReadingTasksTab: true, tasks_tab_tasks: [], 
                all: 0,
                complete: 0,
                missed: 0,
                upcoming: 0,
            }
        case getNamedAction(ACTION_NAMES.TASKS_TAB, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {
                ...state,
                isReadingTasksTab: false,
                tasks_tab_tasks: action.data.data,
                ...action.data.counts
            }
        case getNamedAction(ACTION_NAMES.TASKS_TAB, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingTasksTab: false }

        case getNamedAction(ACTION_NAMES.TASKS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingTask: true };
        case getNamedAction(ACTION_NAMES.TASKS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingTask: false };
        case getNamedAction(ACTION_NAMES.TASKS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingTask: false };

        case getNamedAction(ACTION_NAMES.TASK_TYPES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingTaskTypes: true };
        case getNamedAction(ACTION_NAMES.TASK_TYPES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingTaskTypes: false, taskTypes: action.data }
        case getNamedAction(ACTION_NAMES.TASK_TYPES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingTaskTypes: false }

        case getNamedAction(ACTION_NAMES.TASK_LABELS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingTaskLabels: true, task_labels: [] }
        case getNamedAction(ACTION_NAMES.TASK_LABELS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingTaskLabels: false, task_labels: action.data.data, task_labels_count: action.data.count }
        case getNamedAction(ACTION_NAMES.TASK_LABELS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingTaskLabels: false }

        case getNamedAction(ACTION_NAMES.TASK_LABELS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingTaskLabels: true }
        case getNamedAction(ACTION_NAMES.TASK_LABELS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAddingTaskLabels: false }
        case getNamedAction(ACTION_NAMES.TASK_LABELS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingTaskLabels: false }

        default:
            return state;
    }
}

export default tasksReducer;
