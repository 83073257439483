import { generateReadOneActionCreator, generateReadActionCreator, generateUpdateActionCreator } from 'actions/crudActionCreators';
import routes from 'actions/routes';
import ACTION_NAMES from 'actions/ACTION_NAMES';

const getRelationships = generateReadOneActionCreator(routes.API_ACCOUNT, ACTION_NAMES.ACCOUNT_RELATIONSHIPS, 'relationships');
const updateRelationship = generateUpdateActionCreator(routes.API_ACCOUNT, ACTION_NAMES.ACCOUNT_RELATIONSHIPS, () => {
}, () => {
}, 'relationship');

const getContactRelations = generateReadActionCreator(routes.API_CONTACT_RELATIONS, ACTION_NAMES.CONTACT_RELATIONS, 'contact_relations')
const updateContactRelationship = generateUpdateActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_RELATIONSHIP, () => {}, () => {}, 'relationship');

export default {
    getRelationships,
    updateRelationship,
    getContactRelations,
    updateContactRelationship
}
