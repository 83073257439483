import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { ModulesFilter } from 'dyl-components';

import {
    KEY_CREATED, KEY_NEXT_STEP,
    KEY_CREATED_BY, KEY_OPPORTUNITY_AGE, KEY_LAST_MODIFIED,
    KEY_LAST_MODIFIED_BY, KEY_OPPORTUNITY_ID,
    KEY_OPPORTUNITY_NAME,
    KEY_CONFIDENCE_LEVEL, KEY_STAGE, KEY_CLOSE_PROBABILITY,
    KEY_OUTCOME, KEY_EXPECTED_CLOSE_DATE, KEY_LOST_REASON,
    KEY_NEXT_STEP_DATE, KEY_CONTACT_NAME
} from 'shared/constants/MODULE_COLUMNS';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

const OpportunityTabFilter = ({ isColumnHidden, onToggleColumn, routeRoot }) => {
    const { isReadingOpportunities } = useSelector(state => ({
        isReadingOpportunities: state.opportunity_tab.isReadingOpportunities
    }));

    const { account_id } = useParams();
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const COLUMN_ROWS = !account_id ? [
        [
            { value: KEY_CREATED, label: "Created" },
            { value: KEY_STAGE, label: "Pipeline/Stage" },
            { value: KEY_CLOSE_PROBABILITY, label: "Close Probability" }
        ],
        [
            { value: KEY_CREATED_BY, label: "Created by" },
            { value: KEY_OUTCOME, label: "Outcome" },
            
            { value: KEY_EXPECTED_CLOSE_DATE, label: "Expected Close Date" },
            
        ],
        [
            { value: KEY_LAST_MODIFIED, label: "Last Modified" },
            { value: KEY_LOST_REASON, label: "Lost Reason/Type" },
            { value: KEY_CONFIDENCE_LEVEL, label: "Confidence Level" },
        ],
        [
            { value: KEY_LAST_MODIFIED_BY, label: "Last Modified By" },
            { value: KEY_OPPORTUNITY_AGE, label: "Opportunity Age" },
            
        ],
        [
            { value: KEY_OPPORTUNITY_ID, label: "ID", isForcedChecked: true },
            { value: KEY_NEXT_STEP_DATE, label: "Next Step Date" }
            
        ],
        [
            { value: KEY_OPPORTUNITY_NAME, label: "Name", isForcedChecked: true },
            { value: KEY_NEXT_STEP, label: "Next Step" }
        ]
    ] : [
        [
            { value: KEY_CREATED, label: "Created" },
            { value: KEY_STAGE, label: "Pipeline/Stage" },
            { value: KEY_CLOSE_PROBABILITY, label: "Close Probability" }
        ],
        [
            { value: KEY_CREATED_BY, label: "Created by" },
            { value: KEY_OUTCOME, label: "Outcome" },
            { value: KEY_EXPECTED_CLOSE_DATE, label: "Expected Close Date" }
        ],
        [
            { value: KEY_LAST_MODIFIED, label: "Last Modified" },
            { value: KEY_LOST_REASON, label: "Lost Reason/Type" },
            
        ],
        [
            { value: KEY_LAST_MODIFIED_BY, label: "Last Modified By" },
            { value: KEY_CONFIDENCE_LEVEL, label: "Confidence Level" },
        ],
        [
            { value: KEY_CONTACT_NAME, label: "Contact", isForcedChecked: true },
            { value: KEY_OPPORTUNITY_AGE, label: "Opportunity Age" },
        ],
        [
            { value: KEY_OPPORTUNITY_ID, label: "ID", isForcedChecked: true },
            { value: KEY_NEXT_STEP_DATE, label: "Next Step Date" }
            
        ],
        [
            { value: KEY_OPPORTUNITY_NAME, label: "Name", isForcedChecked: true },
            { value: KEY_NEXT_STEP, label: "Next Step" }
        ]
    ];

    const opportunityNameQuery = params.get('opportunity_name') || '';
    const [opportunityName, setOpportunityName] = useState(opportunityNameQuery);

    const onChangeSearch = (_, { value }) => {
        setOpportunityName(value);
    }

    const onSearchSubmit = (value) => {
        setOpportunityName(value);
        const query = new URLSearchParams(params);
        query.set('opportunity_name', opportunityName.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    }

    const cancelFunction = () => {
        setOpportunityName("");
        const query = new URLSearchParams(params);
        query.delete('opportunity_name');
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`,);
    }

    return (
        <ModulesFilter
            isLoading={isReadingOpportunities}
            hasSearchBar={account_id ? true : false}
            columnRows={COLUMN_ROWS}
            columnCount={3}
            isColumnHidden={isColumnHidden}
            onToggleColumn={onToggleColumn}
            search={opportunityNameQuery}
            onSearchSubmit={onSearchSubmit}
            cancelFunction={cancelFunction}
            onChangeSearch={onChangeSearch}
            searchBarPlaceholder={'Search by Opportunity Name'}
        />
    )
}

export default OpportunityTabFilter;
