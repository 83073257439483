import supplyChainNetworkActions from "actions/supply_chain_network";
import { RuleContainer } from "dyl-components";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Form, Header, Icon, Segment, Transition } from "semantic-ui-react";
import { StringUtils } from "utils";

const SupplyChainNetworkField = ({
    name,
    loading,
    noResultsMessage,
    label,
    options,
    updateOptions,
}) => {
    const { control } = useFormContext();
    const [search, setSearch] = useState('');

    const onAddOption = (_, { value }) => {
        const trimmedValue = value?.trim();
        if (trimmedValue) {
            updateOptions([
                ...options.filter((option) => typeof option.value === "number"),
                {
                    key: trimmedValue,
                    value: trimmedValue,
                    text: trimmedValue,
                },
            ]);
        }
    };

    return (
        <Controller
            control={control}
            name={name}
            render={({
                field: { name, value: selected, onChange },
                fieldState: { error },
            }) => {
                const isNew = typeof selected === "string";
                return (
                    <>
                        <Form.Dropdown
                            error={error?.message}
                            label={label}
                            placeholder={
                                <div>
                                    <Icon
                                        style={{ marginRight: 10 }}
                                        name="search plus"
                                    />
                                </div>
                            }
                            name={name}
                            search
                            searchQuery={search}
                            onSearchChange={(e) => {setSearch(e.target.value)}}
                            allowAdditions={options.filter(option => option?.text?.toLowerCase() === search?.toLowerCase()?.trim()).length === 0}
                            selection
                            value={selected}
                            selectOnBlur={false}
                            onChange={(_, { value, text }) => {
                                onChange({ target: { name, value } });
                                setSearch(text || '');
                                if (
                                    isNew &&
                                    (typeof value !== "string" || !value)
                                ) {
                                    updateOptions(
                                        options.filter(
                                            (option) =>
                                                typeof option.value === "number"
                                        )
                                    );
                                }
                            }}
                            onAddItem={onAddOption}
                            options={options}
                            noResultsMessage={noResultsMessage}
                            loading={loading}
                            clearable
                        />
                    </>
                );
            }}
        />
    );
};

const SupplyChainNetwork = () => {
    const [supplyChainNetworkOptions, setSupplyChainNetworkOptions] = useState({
        provider: [],
        manufacturer: [],
        vendor: [],
        supplier: [],
        carrier: [],
    });

    const [expanded, setExpanded] = useState(false);

    const isReadingFields = useSelector(
        (state) => state.supply_chain_network.isReadingFields
    );

    const dispatch = useDispatch();

    const getSupplyChainNetworkFields = useCallback(() => {
        return dispatch(supplyChainNetworkActions.getFields());
    }, [dispatch]);

    useEffect(() => {
        getSupplyChainNetworkFields().then((response) => {
            response.map(field => setSupplyChainNetworkOptions(currentSupplyChainNetworkOptions => ({
                ...currentSupplyChainNetworkOptions,
                [field.name]: field.values.map(value => ({
                    key: value.id,
                    value: value.id,
                    text: value.name
                }))
            })))
        });
    }, [dispatch, getSupplyChainNetworkFields]);

    const generateOptionsUpdater = (type) => (updatedOptions) => {
        setSupplyChainNetworkOptions({
            ...supplyChainNetworkOptions,
            [type]: updatedOptions,
        });
    };

    return (
        <Segment>
            <Header as="h4">
                <span>
                    <Icon
                        color="primary"
                        size="small"
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                        link
                        className={`fas fa-circle-caret-${
                            expanded ? "up" : "down"
                        }`}
                    />{" "}
                    Supply Chain Network
                </span>
            </Header>
            <Transition
                visible={expanded}
                duration={100}
                animation="slide down"
                unmountOnHide
            >
                <RuleContainer 
                    isActive
                    content={[
                        ["provider", "manufacturer"],
                        ["vendor", "supplier"],
                        ["carrier"],
                    ].map((supplyChainNetworkGroup, index) => (
                        <Form.Group key={index} widths={2}>
                            {supplyChainNetworkGroup.map((type) => (
                                <SupplyChainNetworkField
                                    key={type}
                                    label={StringUtils.capitalize(type)}
                                    loading={isReadingFields}
                                    name={type}
                                    noResultsMessage={`No ${type}s ${
                                        supplyChainNetworkOptions[type]
                                            .length === 0
                                            ? "added"
                                            : "found"
                                    }`}
                                    options={supplyChainNetworkOptions[type]}
                                    updateOptions={generateOptionsUpdater(type)}
                                />
                            ))}
                        </Form.Group>
                    ))}
                />
            </Transition>
        </Segment>
    );
};

export default SupplyChainNetwork;
