import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { RefreshCampaignModal, FilteredRefreshCampaignModal } from 'shared/modals';

const CampaignDialer = ({ onShowRefreshCampaignModal, leadId = 'test' }) => (
    <Button.Group basic style={{ backgroundColor: 'white' }}>
        <Button onClick={onShowRefreshCampaignModal}><Icon name='list' /> Refresh Campaign</Button>
        <Button as={Link} to={`/dialer?id=${leadId}`}><Icon name='play' /> Go to Dialer</Button>
    </Button.Group>
)


class CampaignDialerContainer extends React.Component {
    state = {
        isRefreshCampaignModalOpen: false,
        isFilteredRefreshCampaignModalOpen: false,
        appointments: null,
        customers: null,
        remove_excluded: null,
        shuffle_records: null,
        reset_voicemail: null
    }

    onChange = (_, { name, value }) => {
        this.setState({
            [name]: value
        });
    }

    onShowRefreshCampaignModal = () => {
        this.setState({
            isRefreshCampaignModalOpen: true
        });
    }

    onHideRefreshCampaignModal = () => {
        this.setState({
            isRefreshCampaignModalOpen: false,
            appointments: null,
            customers: null,
            remove_excluded: null,
            shuffle_records: null,
            reset_voicemail: null
        });
    }

    onShowFilteredRefreshCampaignModal = () => {
        this.setState({
            isFilteredRefreshCampaignModalOpen: true
        });
    }

    onHideFilteredRefreshCampaignModal = () => {
        this.setState({
            isFilteredRefreshCampaignModalOpen: false
        });
    }

    render() {
        return (
            <React.Fragment>
                <CampaignDialer onShowRefreshCampaignModal={this.onShowRefreshCampaignModal} />
                <RefreshCampaignModal
                    {...this.state}
                    open={this.state.isRefreshCampaignModalOpen}
                    onShowFilteredRefreshCampaignModal={this.onShowFilteredRefreshCampaignModal}
                    onClose={this.onHideRefreshCampaignModal}
                    onChange={this.onChange} />
                <FilteredRefreshCampaignModal
                    open={this.state.isFilteredRefreshCampaignModalOpen}
                    onClose={this.onHideFilteredRefreshCampaignModal} />
            </React.Fragment>
        );
    }
}

export default CampaignDialerContainer;
