import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, Form, Header, Icon, Loader } from 'semantic-ui-react';

const ViewLoginInfoPopup = ({ control, onNext, isDisabled, extension, onClose }) => {
    const { isReadingSoftphoneCreds, softphoneCreds } = useSelector(({ provision }) => provision);

    const hasCreds = !!softphoneCreds && softphoneCreds.extension === extension

    return (
        <div style={{ padding: '14px 10px 14px 10px' }}>
            <div style={{display: 'flex'}}>
                <Header style={{flex: 1}} as={"h3"} color='primary'>View Login Info</Header>
                <Icon
                    className='fa-solid fa-xmark UserExtensions__closeIcon'
                    size='large'
                    link
                    onClick={onClose}
                />
            </div>
            {!isReadingSoftphoneCreds && !hasCreds ? (
                <Form>
                    <Controller
                        name={`password`}
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <Form.Input
                                name={name}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                placeholder='Password'
                                error={error?.message}
                                type='password'
                                label="Password"
                                required
                                style={{ width: '100%' }}
                            />
                        )}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <Button color='primary' style={{ margin: 0 }} onClick={onNext} disabled={isDisabled}>Next</Button>
                    </div>
                </Form>
            ) : (
                isReadingSoftphoneCreds ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Loader active inline />
                    </div>
                ) : (
                    <div>
                        <div style={{ display: 'flex' }}>
                            <strong style={{ flex: 2 }}>Sip Server</strong>
                            <span style={{ flex: 3 }}>{softphoneCreds.proxy_server}</span>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <strong style={{ flex: 2 }}>Port</strong>
                            <span style={{ flex: 3 }}>{softphoneCreds.port}</span>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <strong style={{ flex: 2 }}>Username</strong>
                            <span style={{ flex: 3 }}>{softphoneCreds.username}</span>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <strong style={{ flex: 2 }}>Password</strong>
                            <span style={{ flex: 3 }}>{softphoneCreds.password}</span>
                        </div>
                    </div>
                )
            )}
        </div>
    ); 
}

export default ViewLoginInfoPopup;