import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification, STATUS_TYPES } from 'dyl-components';

import { MODULE_OPTIONS } from 'shared/constants/MODULE_OPTIONS'; 
import { METHOD_OPTIONS } from 'shared/constants/METHOD_OPTIONS';

import leadProviderActions from 'actions/lead_provider';
import leadIngestionActions from 'actions/lead_ingestion';

export const AddDataRecordForm = ({
    provider_id,
    refresh,
    onClose
}) => {
    const { control, formState: { isValid, isDirty }, handleSubmit, setError } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            product_interest: [],
            module: null,
            method: null,
            placeholder: ''
        }
    });

    const dispatch = useDispatch();
    const { isCreatingMappings } = useSelector(state => state.lead_provider)
    const { isReadingProductInterests, product_interests } = useSelector(state => state.lead_ingestion);
    const productInterests = product_interests.map((item) => ({...item, value:item.name, text:item.name}));

    useEffect(() => {
        dispatch(leadIngestionActions.readProductInterests());
    }, [dispatch]);

    const onSubmit = async (data) => {
        try {
            await dispatch(leadProviderActions.createProviderMappings([data], null, provider_id));
            Notification.alert('Successfully created data record', STATUS_TYPES.SUCCESS);
            refresh();
        } catch (e) {
            const nameAlreadyExists = e?.Code === 409;
            const providerRequired = e?.Code === 400;
            const message = nameAlreadyExists ? 'Data record name already exists' : providerRequired ? 'Lead and opportunity only allowed when mastersource set' : 'Failed to create data record';
            Notification.alert(message, STATUS_TYPES.ERROR);
            if (nameAlreadyExists) {
                setError('name', { message });
            }
        } finally {
            onClose();
        }
    }

    return (
   
        <Form size='small'>
            <Form.Group widths='equal'>
                <Controller
                    name='name'
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'This field should not be empty'
                        },
                        maxLength: {
                            value: 64,
                            message: 'This field should contain at most 64 characters'
                        },
                        pattern: {
                            value: /^[A-Za-z0-9:\-\s]+$/,
                            message: 'This field should contain only alphanumeric characters'
                        },
                        validate: {
                            no_excessive_whitespaces: (value) => {
                                return ((value === undefined || value?.length === 0)) ||
                                    (value?.length !== 0 && !!value?.trim()) ||
                                    'This field cannot only contain white spaces'
                            }
                        }
                    }}
                    render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            label='Data Record Name'
                            placeholder='Enter Data Record Name'
                            required
                            simple
                            error={error?.message}
                        />
                    )}
                />
                <Controller
                    name='product_interest'
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                        <Form.Select
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            label='Product Interest(s)'
                            placeholder='Select Product Interest(s)'
                            options={productInterests}
                            selectOnBlur={false}
                            loading={isReadingProductInterests}
                            multiple
                        />
                    )}
                />
            </Form.Group>
            <Form.Group>
                <Controller
                    name='module'
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'This field should not be empty'
                        }
                    }}
                    render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                        <Form.Select
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            label='Module'
                            placeholder='Select Module'
                            required
                            options={MODULE_OPTIONS}
                            selectOnBlur={false}
                            width={4}
                            error={error?.message}
                        />
                    )}
                />
                <Controller
                    name='method'
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'This field should not be empty'
                        }
                    }}
                    render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                        <Form.Select
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            label='Method'
                            placeholder='Select Method'
                            required
                            options={METHOD_OPTIONS}
                            selectOnBlur={false}
                            width={4}
                            error={error?.message}

                        />
                    )}
                />
            </Form.Group>
            <Form.Button loading={isCreatingMappings} onClick={handleSubmit(onSubmit)} floated='right' primary disabled={!isDirty || !isValid || isCreatingMappings}>
                Save
            </Form.Button>
        </Form>
    )
}
