import React, { useEffect, useState } from 'react';
import { Segment, Pagination, Header } from 'semantic-ui-react';
import { GeneralNotes, TableWithHeader, generateResolver, VALIDATORS, Notification, STATUS_TYPES } from 'dyl-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { NotesTable } from './NotesTable';
import { useDispatch, useSelector } from 'react-redux'; 
import { useParams, useLocation } from 'react-router-dom';
import notesActions from 'actions/notes';
import assignActions from 'actions/assign';
import { useConfirm } from 'shared/confirmation/useConfirm';
import CustomPrompt from 'shared/confirmation/CustomPrompt';
import ConfirmModal from 'shared/confirmation/ConfirmModal';

import { StringUtils } from 'utils';
import './index.scss';

const LIMIT = 25;

const Notes = () => {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { contact_id, account_id, master_account_id } = useParams();
    const { pathname } = useLocation();

    const contactAccount = pathname.split("/")[1];
    const record_id = contact_id || account_id || master_account_id;

    const { notes, isReadingNotes, count, accountRelatedNotes, isReadingAccountRelatedNotes }  = useSelector(state => state.notes);
    const account = useSelector(state => state.account.account);
    const masterAccount = useSelector(state => state.master_account.master_account);
    const { user_accounts: userAccounts, isReadingUserAccounts } = useSelector(state => state.assign);
    const [noteBeingEdited, setNoteBeingEdited] = useState(true);

    let contactAccountOptions = [
        {
            name: contactAccount === "account" ? "Account" : "Master",
            options: []
        },
        {
            name: "Linked",
            options: []
        },
        {
            name: "Unlinked",
            options: []
        },
    ];

    accountRelatedNotes.forEach(contact => {
        if(contact.linked){
            if(contactAccountOptions[1].options.length === 0){
                contactAccountOptions[1].options.push(
                    { key: "All", value: "All", text: "All", all: true }
                )
            }
            contactAccountOptions[1].options.push({ key: contactAccount === "account" ? contact.contact_id : contact.account_id, value: contactAccount === "account" ? contact.contact_id : contact.account_id, text: contactAccount === "account" ? `${contact?.first_name} ${contact?.last_name}` : contact.account_name }) 
        }
        if(!contact.linked){
            if(contactAccountOptions[2].options.length === 0){
                contactAccountOptions[2].options.push({ key: "All", value: "All", text: "All", all: true })
            }
            contactAccountOptions[2].options.push({ key: contactAccount === "account" ? contact.contact_id : contact.account_id, value: contactAccount === "account" ? contact.contact_id : contact.account_id, text: contactAccount === "account" ? `${contact?.first_name} ${contact?.last_name}` : contact.account_name })
        }
            
    })

    const [filters, setFilters] = useState({
        type: params.get('type')?.split(','),
        user_id: params.get('user_id')?.split(','),
        contact_id: params.get('contact_id')?.split(','),
        order: params.get('order')?.split(','),
        page: params.get('page')?.split(','),
    })

    const { control, formState: { isValid, isDirty }, getValues, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            note: '',
        },
        resolver: generateResolver({
            note: VALIDATORS.NOTE(),
        })
    });

    useEffect(() => {
        const query = new URLSearchParams(params);
        query.set('page', 1);
        const { type, user_id, contact_id, order } = filters;
        if (type?.length > 0) {
            query.set('type', type.join(','));
        } else {
            query.delete('type');
        }
        if (user_id?.length > 0) {
            query.set('user_id', user_id.join(','));
        } else {
            query.delete('user_id');
        }
        if (contact_id?.length > 0) {
            query.set('contact_id', contact_id.join(','));
        } else {
            query.delete('contact_id');
        }
        if (order?.length > 0) {
            query.set('order', order.join(','));
        } else {
            query.delete('order');
        }
        const query_string = query.toString();
        navigate(`/${contactAccount}/${record_id}/notes${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    useEffect(() => {
        if(contactAccount === 'contact'){
            dispatch(notesActions.notesTab(record_id, Object.fromEntries(params)));
        }
        if(contactAccount === 'account'){
            dispatch(notesActions.notesAccountTab(record_id, Object.fromEntries(params)));
            dispatch(notesActions.getAccountNotesContacts(record_id, { limit: 200 }));
            
        }
        if(contactAccount === 'master_account'){
            dispatch(notesActions.notesMasterAccountTab(record_id, Object.fromEntries(params)));
            dispatch(notesActions.getMasterAccountNotesAccount(record_id, { limit: 200 }));
        }
    }, [dispatch, record_id, params, contactAccount]);

    useEffect(() => {
        dispatch(assignActions.readUserAccounts({ limit: 400 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onPageChange = (_, { activePage }) => {
        const queryParams = new URLSearchParams(params);
        queryParams.set('page', activePage);
        const queryString = queryParams.toString();
        navigate(`/${contactAccount}/${record_id}/notes${queryString ? `?${queryString}` : ''}`);
    }

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value
        });
    }

    const onSort = (order) => {
        const queryParams = new URLSearchParams(params);
        queryParams.set('order', order);
        const queryString = queryParams.toString();
        navigate(`/${contactAccount}/${record_id}/notes${queryString ? `?${queryString}` : ''}`);
    }

    const onAddNote = async () => {
        const { note } = getValues();
        try {
            await dispatch(notesActions.addNote({ note, sticky: false }, { contact_id: record_id }));
            Notification.alert('Successfully added a note!', STATUS_TYPES.SUCCESS, true);
            reset();
            setNoteBeingEdited(null);
            if(contactAccount === 'contact'){
                dispatch(notesActions.notesTab(record_id, Object.fromEntries(params)));
            }
            if(contactAccount === 'account'){
                dispatch(notesActions.notesAccountTab(record_id, Object.fromEntries(params)));
            }
            if(contactAccount === 'master_account'){
                dispatch(notesActions.notesMasterAccountTab(record_id, Object.fromEntries(params)));
            }
        } catch (e) {
            Notification.alert('Failed to add a note', STATUS_TYPES.ERROR, true);
            console.log(e);
        }
    }

    const accountType = `${StringUtils.capitalize(account?.account_type)} Account`;
    const { isConfirmed } = useConfirm();
    CustomPrompt(null, noteBeingEdited !== null && isDirty, isConfirmed, 'Changes not saved', 'Are you sure you want to exit?');
    return (
        <div className='NotesTab'>
            {noteBeingEdited && (<ConfirmModal />)}
            <Header as='h2'>Notes</Header>
            <TableWithHeader
                header={(
                    <Segment className='TabSegment'>
                        <GeneralNotes 
                            header="Add General Note"
                            control={control}
                            onAddNote={onAddNote}
                            disabled={!isValid || !isDirty}
                        />
                    </Segment>
                )}
                table={(
                    <Segment.Group>
                        <Segment style={{ padding: 0 }}>
                            <NotesTable
                                notes={notes}
                                isReadingNotes={isReadingNotes}
                                onFilter={onFilter}
                                filters={filters}
                                onSort={onSort}
                                record_id={record_id}
                                isReadingUserAccounts={isReadingUserAccounts}
                                userAccounts={userAccounts}
                                contactAccount={contactAccount}
                                account={account}                                
                                masterAccount={masterAccount}
                                accountRelatedNotes={accountRelatedNotes}
                                isReadingAccountRelatedNotes={isReadingAccountRelatedNotes}
                                contactAccountOptions={contactAccountOptions}
                            />
                        </Segment>
                        {!isReadingNotes && notes.length === 0 &&
                            <Header as='h3' textAlign='center' style={{ marginTop: 30 }}>
                                No Notes Created Under { contactAccount === 'contact' ? 'Contact' : contactAccount === 'account' ? accountType : 'Master Account'}
                            </Header>
                        }
                        {notes.length > 0 &&
                            <Segment textAlign='right'>
                                <Pagination
                                    boundaryRange={0}
                                    activePage={params.get('page')}
                                    ellipsisItem={null}
                                    siblingRange={2}
                                    totalPages={Math.ceil(count / LIMIT)}
                                    onPageChange={onPageChange}
                                    disabled={isReadingNotes}
                                />
                            </Segment>
                        }
                    </Segment.Group>
                )}
            />
        </div>
    )
};

export default Notes;
