import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Header from './Header';
import Row from './Row';
import { useSelector } from 'react-redux';
import { RecordsTable } from 'dyl-components';

const Table = ({ onPageChange, onRead, onDelete, listViewUrl }) => {
    const { ivrCount: count, isReadingIvrs, ivrs } = useSelector((state) => state.pbx_config);
    const [params] = useSearchParams();

    return <RecordsTable
        isTableLoading={isReadingIvrs}
        isSearchResultEmpty={(!count || count === 0) && (params.get('search'))}
        isTableEmpty={(!count || count === 0) && !(params.get('search'))}
        tableEmptyMessage='No IVR Prompts Created'
        TableHeader={<Header count={count} IVRs={ivrs} listViewUrl={listViewUrl} />}
        TableRows={ivrs?.map((IVR) => <Row IVR={IVR} onRead={onRead} onDelete={onDelete} />) || []}
        recordsCount={count}
        activePage={params.get('page')}
        onPageChange={onPageChange}
        recordsName='IVR Prompt'
    />
}

export default Table;