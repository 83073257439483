import React, { useContext } from 'react';
import { EllipsisDropdown, Person, Table } from 'dyl-components';
import { Checkbox } from 'semantic-ui-react';
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import { StringUtils } from 'utils';

export const UsersRow = ({ user }) => {
    const [selectedUsers, setSelectedUsers] = useContext(BulkActionsContext);

    const isSelected = (user_id) => {
        return selectedUsers.includes(user_id);
    }

    const onToggleUser = (user_id) => {
        const newSelectedUsers = isSelected(user_id) ? selectedUsers.filter(id => user_id !== id) : [...selectedUsers, user_id];
        setSelectedUsers(newSelectedUsers);
    }

    const countsToDisplay = Object.keys(user.campaign_converted_count).map(key => ({
        type: key,
        count: user.campaign_converted_count[key]
    })).filter(stat => stat.count);

    countsToDisplay.sort((a, b) => a.key > b.key);

    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox checked={isSelected(user.user_id)} onChange={() => { onToggleUser(user.user_id) }} />
            </Table.Cell>
            <Table.Cell>
                <Person
                    username={user.user.username}
                    email={user.user.email}
                />
            </Table.Cell>
            <Table.Cell>
                {user.conversion_rate}%
            </Table.Cell>

            <Table.Cell>
                {countsToDisplay.length ? countsToDisplay.map(stat => (
                    <div key={stat.type}>{stat.count} {stat.count === 1 ? stat.type : StringUtils.capitalize(stat.type === "opportunity" ? "opportunities" : `${stat.type}s`)}</div>
                )) : 0}
            </Table.Cell>
            <Table.Cell>
                {user.total_calls}
            </Table.Cell>
            <Table.Cell>
                {user.attributed_revenue}
            </Table.Cell>
            <Table.Cell>
                {!user.is_owner && (
                    <EllipsisDropdown>
                    </EllipsisDropdown>
                )}
            </Table.Cell>
        </Table.Row>
    );
}
