import { Button } from 'semantic-ui-react';
import React from 'react';
import { Form, Header } from 'semantic-ui-react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './index.scss';

export const ProductInterestsSection = ({ account_id }) => {
    const [params] = useSearchParams();

    const navigate = useNavigate();

    const {
        productInterestOptions,
    } = useSelector((state) => {
        return {
            productInterestOptions: state.account.account?.product_interests,
        }
    });

    const selectedInterests = params.get('product_interest_ids') ? params.get('product_interest_ids').split(',') : [];
    const location = useLocation();

    const onClickInterest = (id) => {
        const updatedInterestsFilter = selectedInterests.includes(id) ? selectedInterests.filter(item => item !== id) : [...selectedInterests, id];
        const destinationRoot = `/account/${account_id}/leads`;
        const query = new URLSearchParams(params);
        query.delete("product_interest_ids");
        const query_string = query.toString();
        const root = (() => {
            if (location.pathname === destinationRoot) {
                return `${destinationRoot}?${query_string ? `${query_string}` : ''}`
            }
            return `${destinationRoot}?`;
        })();
        if (updatedInterestsFilter.length === 0) {
            navigate(root)
        } else {
            const interests_string = updatedInterestsFilter.toString();
            navigate(`${root}${interests_string ? `${query_string ? "&" : ""}product_interest_ids=${interests_string}` : ""}`)
        }
    }

    return (
        <Form size='small'>
            <Header as='h5' color='primary'>Product-Interests</Header>
            {productInterestOptions.map(item => (
                <Button
                    style={{ marginBottom: "5px" }}
                    className={selectedInterests.includes(item.id.toString()) ? `Button--active` : 'Button--inactive'}
                    onClick={() => { onClickInterest(item.id.toString()) }}
                >
                    {item.name}
                </Button>
            ))}
        </Form>
    )
}
