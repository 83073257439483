import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function attendeesReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.EVENT_ATTENDEES, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReading: true }
        case getNamedAction(ACTION_NAMES.EVENT_ATTENDEES, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReading: false, attendees: action.data.data, count: action.data.count }
        case getNamedAction(ACTION_NAMES.EVENT_ATTENDEES, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReading: false }

        case getNamedAction(ACTION_NAMES.EVENT_ATTENDEES, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAdding: true }
        case getNamedAction(ACTION_NAMES.EVENT_ATTENDEES, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAdding: false }
        case getNamedAction(ACTION_NAMES.EVENT_ATTENDEES, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAdding: false }

        case getNamedAction(ACTION_NAMES.EVENT_ATTENDEES, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, attendeeBeingUpdated: action.id }
        case getNamedAction(ACTION_NAMES.EVENT_ATTENDEES, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, attendeeBeingUpdated: null }
        case getNamedAction(ACTION_NAMES.EVENT_ATTENDEES, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, attendeeBeingUpdated: null }

        case getNamedAction(ACTION_NAMES.EVENT_ATTENDEES, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, attendeeBeingRemoved: action.id }
        case getNamedAction(ACTION_NAMES.EVENT_ATTENDEES, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, attendeeBeingRemoved: null }
        case getNamedAction(ACTION_NAMES.EVENT_ATTENDEES, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, attendeeBeingRemoved: null }

        default:
            return state;
    }
}

export default attendeesReducer;
