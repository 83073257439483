import React from 'react';
import { TableWithHeader } from 'dyl-components';
import RingGroupsTable from './RingGroupsTable';
import RingGroupsHeader from './RingGroupsHeader';
import { Grid, Transition } from 'semantic-ui-react';
import AddRingGroupSectionContainer from './AddRingGroupSection';
import LeftPaddedContent from 'shared/LeftPaddedContent';

const RingGroups = ({ 
    isAddRingGroupSectionOpen,
    onOpenAddRingGroupSection, 
    onCloseAddRingGroupSection
}) => (
    <TableWithHeader 
        header={
            <RingGroupsHeader 
                onOpenAddRingGroupsSection={onOpenAddRingGroupSection}
            />
        }
        table={
            <Grid style={{margin: 0}}>
                <Transition visible={isAddRingGroupSectionOpen} unmountOnHide duration='100' animation='slide down'>
                    <Grid.Row>
                        <Grid.Column>
                            <LeftPaddedContent>
                                <AddRingGroupSectionContainer 
                                    onClose={onCloseAddRingGroupSection}
                                />
                            </LeftPaddedContent>
                        </Grid.Column>
                    </Grid.Row>
                </Transition>
                <Grid.Row style={{padding: 0}}>
                    <Grid.Column style={{padding: 0}}>
                        <RingGroupsTable />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        }
    />
);

class RingGroupsContainer extends React.Component {
    state = {
        isAddRingGroupSectionOpen: false
    }

    onOpenAddRingGroupSection = () => {
        this.setState({ isAddRingGroupSectionOpen: true });
    }

    onCloseAddRingGroupSection = () => {
        this.setState({ isAddRingGroupSectionOpen: false });
    }

    render() {
        return (
            <RingGroups 
                isAddRingGroupSectionOpen={this.state.isAddRingGroupSectionOpen}
                onOpenAddRingGroupSection={this.onOpenAddRingGroupSection}
                onCloseAddRingGroupSection={this.onCloseAddRingGroupSection}
            />
        )
    }
}

export default RingGroupsContainer;
