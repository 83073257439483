import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';

const StageFields = ({
    index = 0,
    control,
    disabled
}) => {
    const { sequences, isReadingSequencesShort } = useSelector((state) => ({
        sequences: state.sequences.sequencesShort || [],
        isReadingSequencesShort: state.sequences.isReadingSequencesShort
    }));

    return <>
        <Controller 
            name={`stages[${index}].name`}
            control={control}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <Form.Input
                    name={name}
                    value={value}
                    onChange={(_, { value }) => {
                        onChange({ target: { name, value } })
                    }}
                    disabled={disabled}
                    required
                    label='Stage Name'
                    error={error?.message}
                    placeholder='Enter stage name'
                    width={6}
                />
            )}
        />
        <Controller 
            name={`stages[${index}].description`}
            control={control}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <Form.Input
                    name={name}
                    value={value}
                    onChange={(_, { value }) => {
                        onChange({ target: { name, value } })
                    }}
                    disabled={disabled}
                    label='Stage Description'
                    error={error?.message}
                    placeholder='Enter stage description'
                    width={6}
                />
            )}
        />
        <Controller 
            name={`stages[${index}].days`}
            control={control}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <Form.Input
                    name={name}
                    value={value}
                    onChange={(_, { value }) => {
                        onChange({ target: { name, value } })
                    }}
                    disabled={disabled}
                    required
                    label='# of Days'
                    error={error?.message}
                    placeholder='Enter # of days'
                    width={3}
                />
            )}
        />
        <Controller 
            name={`stages[${index}].sequence_id`}
            control={control}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <Form.Select
                    name={name}
                    value={value}
                    onChange={(_, { value }) => {
                        onChange({ target: { name, value: value || null } })
                    }}
                    label='Sequence Name'
                    error={error?.message}
                    placeholder='Select sequence name'
                    width={6}
                    options={sequences.map((sequence) => ({ key: sequence.id, value: sequence.id, text: sequence.name }))}
                    loading={isReadingSequencesShort}
                    clearable
                    disabled={sequences.length <= 0 || disabled}
                />
            )}
        />
    </>
};


export default StageFields;
