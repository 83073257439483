import React from 'react';
import { connect } from 'react-redux';
import { Grid, Form, Checkbox, Icon, Dropdown } from 'semantic-ui-react';

const CallHistoryToolbar = ({ numberOfCalls = 0 }) => (
    <Form as={Grid} stackable textAlign='left' style={{ paddingLeft: '1em' }}>
        <Form.Group as={Grid.Row} style={{ paddingBottom: 0, marginBottom: 0 }}>
            <Form.Field as={Grid.Column} width={2}>
                <Checkbox label='Select all pages' className='Checkbox--white' />
            </Form.Field>
            <Form.Field as={Grid.Column} width={2}>
                <Dropdown icon={''} trigger={<span><Icon name='cog' />Bulk Actions</span>}>
                    <Dropdown.Menu>
                        <Dropdown.Header content='Options:' />
                    </Dropdown.Menu>
                </Dropdown>
            </Form.Field>
            <Form.Field as={Grid.Column} width={1}>
                <Dropdown icon={''} trigger={<span><Icon name='list' />Show</span>}>
                    <Dropdown.Menu>
                        <Dropdown.Header content='Show' />
                        <Dropdown.Item>10</Dropdown.Item>
                        <Dropdown.Item>25</Dropdown.Item>
                        <Dropdown.Item>50</Dropdown.Item>
                        <Dropdown.Item>100</Dropdown.Item>
                        <Dropdown.Item>200</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Form.Field>
            <Grid.Column floated='right' width={2} textAlign='right'>
                Calls: {numberOfCalls}
            </Grid.Column>
        </Form.Group>
    </Form>
)

const mapStateToProps = state => ({
    numberOfCalls: state.call_history.call_history.length
})

export default connect(mapStateToProps)(CallHistoryToolbar);
