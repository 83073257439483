import React, { useContext, useState } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { DateRangePowerSelector, ModulesFilter, NumberRangePowerSelector, PowerSelector, PowerSelectors } from 'dyl-components';

import { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import { KEY_ACCOUNT_CO_OWNER, KEY_ACCOUNT_NAME, KEY_OPPORTUNITY_OUTCOME, KEY_ACCOUNT_OWNER, KEY_CONTACT_CO_OWNER, KEY_CONTACT_NAME, KEY_CONTACT_OWNER, KEY_CREATED, KEY_CREATED_BY, KEY_EXPIRES_ON, KEY_LAST_MODIFIED, KEY_LAST_MODIFIED_BY, KEY_NUMBER_PRODUCTS, KEY_OPPORTUNITY_NAME, KEY_PRODUCTS, KEY_PROPOSAL_INVOICE_CREATED, KEY_STATUS, KEY_SUBTOTAL, KEY_ACCOUNT_STATUS } from 'shared/constants/QUOTES_ORDERS_COLUMNS';

const COLUMN_ROWS = [
    [
        { value: KEY_CREATED, label: "Created" },
        { value: KEY_NUMBER_PRODUCTS, label: "# Products" },
        { value: KEY_ACCOUNT_NAME, label: "Account Name" },
    ],
    [
        { value: KEY_CREATED_BY, label: "Created By" },
        { value: KEY_PRODUCTS, label: "Products" },
        { value: KEY_OPPORTUNITY_NAME, label: "Opportunity Name" },
    ],
    [
        { value: KEY_LAST_MODIFIED, label: "Last Modified" },
        { value: KEY_CONTACT_OWNER, label: "Contact Owner" },
        { value: KEY_OPPORTUNITY_OUTCOME, label: "Opportunity Outcome" },
    ],
    [
        { value: KEY_LAST_MODIFIED_BY, label: "Last Modified By" },
        { value: KEY_CONTACT_CO_OWNER, label: "Contact Co-Owner" },
        { value: KEY_ACCOUNT_STATUS, label: "Account Status"}
    ],
    [
        { value: KEY_PROPOSAL_INVOICE_CREATED, label: "Proposal/Invoice Created" },
        { value: KEY_ACCOUNT_OWNER, label: "Account Owner" },
        { value: KEY_SUBTOTAL, label: "Subtotal" },
    ],
    [
        { value: KEY_EXPIRES_ON, label: "Expires On" },
        { value: KEY_ACCOUNT_CO_OWNER, label: "Account Co-Owner" },
        { value: KEY_STATUS, label: "Status" },
    ],
    [
        { value: KEY_EXPIRES_ON, label: "ID", isForcedChecked: true },
        { value: KEY_CONTACT_NAME, label: "Contact Name" },
    ]
];

const QuotesAndOrdersToolbar = ({ isColumnHidden, onToggleColumn }) => {
    const [selectedQuotesAndOrders, , areQuotesAndOrdersInAllPagesSelected] = useContext(BulkActionsContext);

    const hasSelectedQuotesOrOrders = selectedQuotesAndOrders.length || areQuotesAndOrdersInAllPagesSelected;

    const [, setIsBulkActionsModalOpen] = useState(false);

    const onOpenBulkActionsModal = () => {
        setIsBulkActionsModalOpen(true);
    }

    return (
        <Grid className='QuotesAndOrdersToolbar'>
            <Grid.Row>
                <Grid.Column>
                    <PowerSelectors>
                        <DateRangePowerSelector 
                            label={"Created"}
                            name={"created"}
                        />
                        <PowerSelector
                            label={"Created By"}
                            multiple
                            name={"created_by"}
                            options={[]}
                        />
                        <DateRangePowerSelector 
                            label={"Last Modified"}
                            name={"last_modified"}
                        />
                        <PowerSelector 
                            label={"Last Modified By"}
                            multiple
                            name={"last_modified_by"}
                            options={[]}
                        />
                        <DateRangePowerSelector 
                            label={"Proposal/Invoice Created"}
                            name={"proposal_invoice_created"}
                        />
                        <DateRangePowerSelector 
                            label={"Expires On"}
                            name={"expires_on"}
                        />
                        <NumberRangePowerSelector 
                            label={"# Products"}
                            name={"number_products"}
                            min={1}
                        />
                        <PowerSelector 
                            label={"Products"}
                            multiple
                            name={"products"}
                            options={[]}
                        />
                        <PowerSelector 
                            label={"Contact Owner"}
                            multiple
                            name={"contact_owner"}
                            options={[]}
                        />
                        <PowerSelector 
                            label={"Contact Co-Owner"}
                            multiple
                            name={"contact_co_owner"}
                            options={[]}
                        />
                        <PowerSelector 
                            label={"Account Owner"}
                            multiple
                            name={"account_owner"}
                            options={[]}
                        />
                        <PowerSelector 
                            label={"Account Co-Owner"}
                            multiple
                            name={"account_co_owner"}
                            options={[]}
                        />
                        <PowerSelector 
                            label={"Contact Name"}
                            multiple
                            name={"contact_name"}
                            options={[]}
                        />
                        <PowerSelector 
                            label={"Account Name"}
                            multiple
                            name={"account_name"}
                            options={[]}
                        />
                        <PowerSelector 
                            label={"Account Status"}
                            multiple
                            name={"account_status"}
                            options={[]}
                        />
                        <PowerSelector 
                            label={"Opportunity Name"}
                            multiple
                            name={"opportunity_name"}
                            options={[]}
                        />
                        <PowerSelector 
                            label={"Opportunity Outcome"}
                            multiple
                            name={"opportunity_outcome"}
                            options={[]}
                        />
                        <NumberRangePowerSelector 
                            label={"Subtotal"}
                            name={"subtotal"}
                            min={1}
                            isCurrency
                        />
                        <PowerSelector 
                            label={"Status"}
                            multiple
                            name={"status"}
                            options={[]}
                        />
                    </PowerSelectors>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal' verticalAlign='middle'>
                <Grid.Column style={{ marginLeft: "-2.75em" }} width={1}>
                    <ModulesFilter
                        hasSearchBar={false}
                        columnRows={COLUMN_ROWS}
                        columnCount={3}
                        isColumnHidden={isColumnHidden}
                        onToggleColumn={onToggleColumn}
                    />
                </Grid.Column>
                <Grid.Column />
                <Grid.Column width={4} floated='right'>
                    <Button floated='right' className='QuotesAndOrdersToolbar__QuotesAndOrdersButton' disabled={!hasSelectedQuotesOrOrders} onClick={onOpenBulkActionsModal}>
                        Bulk Action
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default QuotesAndOrdersToolbar;
