import axiosInstance from 'actions/axiosInstance';
import routes from 'actions/routes';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import { FileUtils } from 'utils';
import axios from 'axios';

export default {
    uploadFiles: (files, category) => dispatch => {
        const body = files.map(file => {
            if (file.size > FileUtils.fileSize[category]) {
                return Promise.reject("Max File Size");
            }
            const filename = file.name.replace(" ", "_");
            return {
                filename,
                "content_length": file.size,
                "content_type": file.type,
            }
        })
        dispatch({type: getNamedAction(ACTION_NAMES.UPLOAD_FILE, CRUD_ACTION_TYPES.CREATE_REQUEST)});
        return axiosInstance.instance1.post(routes.UPLOAD_URLS, body, {params: { category }})
            .then((response) => {
                const ids = [];
                // Upload each file asyncronusly
                const proms = response.data.map(async (info, idx) => {
                    ids.push(info.id);
                    const fileData = new Uint8Array(await files[idx].arrayBuffer())
                    return axios.put(info.url,fileData, {headers: {
                        'Content-Type': files[idx].type,
                    }})
                });
                // After all files are uploaded then return callback and dispatch file to uploaded
                Promise.all(proms).then(() => {
                    dispatch({ type: getNamedAction(ACTION_NAMES.UPLOAD_FILE, CRUD_ACTION_TYPES.CREATE_SUCCESS)});
                }).catch((error) => {
                    console.log(error);
                    dispatch({
                        type: getNamedAction(ACTION_NAMES.UPLOAD_FILE, CRUD_ACTION_TYPES.CREATE_FAILURE)
                    });
                    return Promise.reject(error.response?.data);
                })
            // list of file ids to be returned
            return ids;
            }).catch((error) => {
                console.log(error);
                dispatch({
                    type: getNamedAction(ACTION_NAMES.UPLOAD_FILE, CRUD_ACTION_TYPES.CREATE_FAILURE)
                });
                return Promise.reject(error.response?.data);
            })
    }
}
