import React from 'react';
import { Grid, Icon, Form } from 'semantic-ui-react';
import { ButtonLink, STATUS_TYPES } from 'dyl-components';

import { Controller } from 'react-hook-form';

const SOCIAL_MEDIA_TYPES = [
    { text: <Icon className='fab fa-facebook' />, key: 'facebook', value: 'facebook' },
    { text: <Icon className='fab fa-linkedin' />, key: 'linkedin', value: 'linkedin' },
    { text: <Icon className='fab fa-twitter' />, key: 'twitter', value: 'twitter' }
];

const EditSocials = ({
    socials,
    control,
    onRemove,
    updateType,
    selectedSocials
}) => {
    return (
        socials.map((social, index) => (
            <Grid.Row key={social.id} columns='equal' verticalAlign='middle'>
                <Grid.Column width={4}>
                    <Controller
                        name={`socials[${index}].type`}
                        control={control}
                        render={({ field: { name, value, onChange } }) => {
                            return (
                                <Form.Dropdown
                                    selection
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => {
                                        updateType(index, value);
                                    }}
                                    placeholder='Type'
                                    options={SOCIAL_MEDIA_TYPES.filter(type => type.value === value || !selectedSocials.includes(type.value))}
                                    selectOnBlur={false} 
                                />
                            );
                        }} />
                </Grid.Column>
                <Grid.Column>
                    <Controller
                        name={`socials[${index}].value`}
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Input
                                name={name}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                placeholder='Enter url'
                                error={error && error.message} />
                        )} />
                </Grid.Column>
                <Grid.Column width={6}>
                    <Form.Field>
                        <ButtonLink style={{ marginLeft: '-0.5em' }} size='small' status={STATUS_TYPES.ERROR} onClick={() => {
                            onRemove(index);
                        }}>
                            <Icon className='fas fa-trash-can' /> Delete
                        </ButtonLink>
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>
        ))
    );
}

export default EditSocials;
