import React, { useState } from 'react';
import { EditableCategorizedOptions, EditableOptions, generateResolver, Modal, Notification, STATUS_TYPES, VALIDATORS, yup } from 'dyl-components';
import './CustomFieldModal.scss';

import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Form, Icon, Popup } from 'semantic-ui-react';

import FIELD_TYPE_OPTIONS from 'shared/constants/FIELD_TYPE_OPTIONS';

const DependencyFieldValues = ({ control, watch, areCategoriesEditable }) => {
    const { remove: removeCategory, append: addCategory } = useFieldArray({
        control,
        name: 'categories'
    });

    const watchedCategories = watch('categories');

    return (
        <Controller
            control={control}
            name='categories'
            render={({ fieldState: { error } }) => (
                <EditableCategorizedOptions
                    categoriesFieldName={'categories'}
                    control={control}
                    categoriesToEdit={watchedCategories}
                    removeCategory={removeCategory}
                    addCategory={addCategory}
                    areCategoriesEditable={areCategoriesEditable}
                    error={error?.message}
                />
            )}
        />
    )
}

const SingleFieldValues = ({ control, watch }) => {
    const { remove: removeOption, append: addOption, update: updateOption } = useFieldArray({
        control,
        name: 'options'
    });

    const watchedOptions = watch('options');

    return (
        <Controller
            control={control}
            name='options'
            render={({ fieldState: { error } }) => (
                <EditableOptions
                    optionsFieldName={'options'}
                    control={control}
                    optionsToEdit={watchedOptions}
                    removeOption={removeOption}
                    addOption={addOption}
                    updateOption={updateOption}
                    error={error?.message}
                />
            )}
        />
    )
}

const FieldValues = ({ field_type, control, watch, areCategoriesEditable }) => {
    switch (field_type) {
        case 'radio':
        case 'picklist':
        case 'dropdown':
        case 'tagsinput':
            return (
                <SingleFieldValues
                    control={control}
                    watch={watch}
                />
            )
        case 'dependency':
            return (
                <DependencyFieldValues
                    control={control}
                    watch={watch}
                    areCategoriesEditable={areCategoriesEditable}
                />
            )
        default:
            return null;
    }
}

const CustomFieldModal = ({
    open,
    onClose,

    field,
    refresh,

    onSave
}) => {
    const { formState: { isValid, isDirty }, control, handleSubmit, watch, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            field_type: field?.field_type || '',
            field_name: field?.field_name || '',
            label: field?.label || '',
            description: field?.description || '',
            categories: [],
            options: [],
            ...(field?.field_type === 'dependency' ? {
                categories: field?.options?.data?.filter(option => !option.deleted)?.map(option => ({
                    id: option.id,
                    name: option.value,
                    options: option.sub_options?.map(option => ({
                        id: option.id,
                        name: option.value
                    })) || []
                })) || []
            } : {
                options: field?.options?.data?.filter(option => !option.deleted)?.map(option => ({
                    id: option.id,
                    name: option.value
                })) || []
            }),
            dependency_description: field?.data?.dependency_description || '',
            dependency_label: field?.data?.dependency_label || ''
        },
        resolver: generateResolver({
            field_type: yup.string().oneOf(FIELD_TYPE_OPTIONS.map(({ key }) => key)).required('This field is required'),
            label: yup.string().simple_alphanumeric().no_excessive_whitespaces().no_whitespace_only().required('This field is required'),
            description: VALIDATORS.DESCRIPTION(),
            dependency_label: yup.string().when("field_type", {
                is: 'dependency',
                then: schema => schema.simple_alphanumeric().no_excessive_whitespaces().no_whitespace_only().required('This field is required'),
                otherwise: schema => schema.simple_alphanumeric().no_excessive_whitespaces().no_whitespace_only()
            }),
            dependency_description: yup.string().when("field_type", {
                is: 'dependency',
                then: () => VALIDATORS.DESCRIPTION()
            }),
            options: yup.array().when("field_type", {
                is: field_type => field_type === 'tagsinput' || field_type === 'picklist' || field_type === 'radio' || field_type === 'dropdown',
                then: schema => schema.min(1, "This should have at least one option")
            }),
            categories: yup.array().when("field_type", {
                is: 'dependency',
                then: schema => schema.min(1, "This should have at least one option")
            })
        })
    });

    const [isSaving, setIsSaving] = useState(false);

    const isEditing = field !== undefined;

    const onConfirmChanges = async (data) => {
        try {
            setIsSaving(true);
            await onSave(data);
            Notification.alert(`Successfully ${isEditing ? 'updated' : 'created'} custom field`, STATUS_TYPES.SUCCESS);
            setIsSaving(false);
            onClose();
            if (refresh) {
                refresh();
            }
        } catch (e) {
            setIsSaving(false);
            console.log(e);
            Notification.alert(`Failed to ${isEditing ? 'update' : 'create'} custom field`, STATUS_TYPES.ERROR);
        }
    }

    return (
        <Modal open={open} onClose={() => {
            reset({
                field_type: field?.field_type || '',
                field_name: field?.field_name || '',
                label: field?.label || '',
                description: field?.description || '',
                categories: [],
                options: [],
                ...(field?.field_type === 'dependency' ? {
                    categories: field?.options?.data?.filter(option => !option.deleted)?.map(option => ({
                        id: option.id,
                        name: option.value,
                        options: option.sub_options?.map(option => ({
                            id: option.id,
                            name: option.value
                        })) || []
                    })) || []
                } : {
                    options: field?.options?.data?.filter(option => !option.deleted)?.map(option => ({
                        id: option.id,
                        name: option.value
                    })) || []
                }),
                dependency_description: field?.data?.dependency_description || '',
                dependency_label: field?.data?.dependency_label || ''
            });
            onClose();
        }} className='CustomFieldModal'>
            <Controller
                name='field_type'
                control={control}
                render={({ field: { name: fieldTypeName, value: fieldTypeValue, onChange: fieldTypeOnChange }, fieldState: { error: fieldTypeError } }) => [
                    <Modal.Header>
                        {isEditing ? 'Edit' : 'Add'} Custom Field
                        {isEditing && (
                            <Popup
                                trigger={<Icon className='fas fa-circle-info CustomFieldModal__infoMessage' color='primary' />}
                                content={`Please note that saved changes ${field?.data?.dependency_label ? 'will affect all existing values' : 'will only affect new changes to records'}`}
                                inverted
                                position='left center'
                                wide
                            />
                        )}
                    </Modal.Header>,
                    <Modal.Content>
                        <Form loading={isSaving} noValidate>
                            <Form.Group widths={'equal'}>
                                <Form.Select
                                    className='FieldTypeDropdown'
                                    options={FIELD_TYPE_OPTIONS}
                                    {...(fieldTypeValue ? { text: FIELD_TYPE_OPTIONS.find(option => option.value === fieldTypeValue)?.content } : {})}
                                    value={fieldTypeValue}
                                    label={'Field Type'}
                                    disabled={isEditing}
                                    required
                                    name={fieldTypeName}
                                    onChange={(_, { name, value }) => { fieldTypeOnChange({ target: { name, value } }) }}
                                    selectOnBlur={false}
                                    error={fieldTypeError?.message}
                                />
                                {!field?.standard && fieldTypeValue !== 'dependency' && (
                                    <Controller
                                        name='label'
                                        control={control}
                                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                            <Form.Input
                                                value={value}
                                                label={'Field Name'}
                                                required
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                error={error?.message}
                                                placeholder='Enter field name'
                                                disabled={!fieldTypeValue}
                                            />
                                        )}
                                    />
                                )}
                            </Form.Group>
                            {fieldTypeValue !== 'dependency' ? (
                                <Controller
                                    name='description'
                                    control={control}
                                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                        <Form.Input
                                            label={'Description'}
                                            value={value}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                            placeholder={'Enter field description'}
                                            error={error?.message}
                                            disabled={!fieldTypeValue}
                                        />
                                    )}
                                />
                            ) : [
                                <Form.Group widths={'equal'}>
                                    <Controller
                                        name='label'
                                        control={control}
                                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                            <Form.Input
                                                value={value}
                                                label={'Parent Field Name'}
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                placeholder={'Enter parent field name'}
                                                error={error?.message}
                                                required
                                                disabled={!fieldTypeValue}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name='dependency_label'
                                        control={control}
                                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                            <Form.Input
                                                value={value}
                                                label={'Child Field Name'}
                                                required
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                placeholder={'Enter child field name'}
                                                error={error?.message}
                                            />
                                        )}
                                    />
                                </Form.Group>,
                                <Form.Group widths={'equal'} >
                                    <Controller
                                        name='description'
                                        control={control}
                                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                            <Form.Input
                                                label={'Parent Description'}
                                                value={value}
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                placeholder={'Enter parent field description'}
                                                error={error?.message}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name='dependency_description'
                                        control={control}
                                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                            <Form.Input
                                                label={'Child Description'}
                                                value={value}
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                placeholder={'Enter child field description'}
                                                error={error?.message}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            ]}
                            <FieldValues
                                control={control}
                                field_type={fieldTypeValue}
                                areCategoriesEditable={field?.data?.edit_main !== false}
                                watch={watch}
                            />
                        </Form>
                    </Modal.Content>
                ]}
            />
            <Modal.Actions hasSaveButton
                onSave={handleSubmit(onConfirmChanges)}
                saveDisabled={!isValid || !isDirty || isSaving}
                saveOptions={{ loading: isSaving }}
            />
        </Modal>
    );
};

export default CustomFieldModal;
