import { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import templateCategoryActions from 'actions/template_category';
import emailTemplatesActions from 'actions/email_templates';
import textTemplatesActions from 'actions/text_templates';

export const TemplatesContext = createContext();

const TemplatesContextProvider = ({ children }) => {
    const [textTemplateOptions, setTextTemplateOptions] = useState([]);
    const [emailTemplateOptions, setEmailTemplateOptions] = useState([]);

    const dispatch = useDispatch();

    const onSelectTextTemplate = templateId => {
        return dispatch(textTemplatesActions.getTemplate(templateId))
    }

    const onSelectEmailTemplate = templateId => {
        return dispatch(emailTemplatesActions.getTemplate(templateId))
    }

    useEffect(() => {
        dispatch(templateCategoryActions.readCategorizedTemplates({ category: 'email' })).then((response) => {
            setEmailTemplateOptions(response.data);
        });
        dispatch(templateCategoryActions.readCategorizedTemplates({ category: 'text' })).then(response => {
            setTextTemplateOptions(response.data)
        });
    }, [dispatch]);

    return (
        <TemplatesContext.Provider value={{ textTemplateOptions, emailTemplateOptions, onSelectEmailTemplate, onSelectTextTemplate }}>
            {children}
        </TemplatesContext.Provider>
    )
}

export default TemplatesContextProvider;
