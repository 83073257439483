import { LinkedAccount, Person } from 'dyl-components';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Dropdown, Grid, Icon } from 'semantic-ui-react';
import { ContactContext } from 'shared/context/ContactProvider';
import SendEmailModal from 'shared/modals/SendEmailModal';
import { NotesModal } from 'shared/modals';
import { StringUtils } from 'utils';
import AccountContact from './AccountContact';
import InfluenceField from './InfluenceField';

export const AccountContacts = ({
    onPin,
    onUnpin,
    onReplacePinnedContact,

    onSetPrimary,

    isSidePanelOpen
}) => {
    const { account, contactsForPinning, isReadingContactsForPinning } = useSelector(state => ({
        account: state.account.account,

        contactsForPinning: state.account.contactsForPinning.map(contact => {
            const name = `${contact.first_name} ${contact.last_name}`;

            return ({
                key: contact.id,
                value: contact.id,
                text: name,
                content: (
                    <Person username={name} subtitleLabel='Job Title' email={contact?.job_title || ''} />
                )
            });
        }),
        isReadingContactsForPinning: state.account.isReadingContactsForPinning
    }));

    const contactColumnWidth = 9;

    const primary_contact = account.primary_contact;
    const pinnedContacts = account.pinned;

    const { account_id } = useParams();

    const isPrimary = (contact_id) => {
        return primary_contact.id === contact_id
    };

    const isPinned = (contact_id) => {
        return (pinnedContacts.findIndex(contact => contact.id === contact_id) !== -1)
    }

    const pinOptions = contactsForPinning.filter(({ value }) => !isPrimary(value) && !isPinned(value));

    const [contactBeingEdited, setContactBeingEdited] = useState(null);

    const onEdit = (id) => {
        setContactBeingEdited(id);
    }

    const onCancelEdit = () => {
        setContactBeingEdited(null);
    }

    const rows = [
        ...pinnedContacts.map(contact => {
            return (
                <AccountContact
                    contact={contact}
                    contactBeingEdited={contactBeingEdited}
                    account_id={account_id}
                    onCancelEdit={onCancelEdit}
                    contactColumnWidth={contactColumnWidth}
                    onUnpin={onUnpin}
                    isSidePanelOpen={isSidePanelOpen}
                    pinOptions={pinOptions}
                    onReplacePinnedContact={onReplacePinnedContact}
                    isReadingContactsForPinning={isReadingContactsForPinning}
                    onEdit={onEdit}
                />
            );
        })
    ]

    while (rows.length < 3) {
        rows.push((
            <Grid.Row verticalAlign='middle'>
                <Grid.Column width={contactColumnWidth}>
                    <Grid verticalAlign='middle' columns='equal'>
                        <Grid.Column width={1}>
                            <Icon color='grey' className='fas thumbtack' />
                        </Grid.Column>
                        <Grid.Column>
                            <Dropdown
                                placeholder='Pin a Contact'
                                selectOnBlur={false}
                                selection
                                options={pinOptions}
                                onChange={(_, { value: contact_id }) => { onPin(contact_id) }}
                                compact
                                value={null}
                                text={'Pin a Contact'}
                                style={{ width: '15em' }}
                                loading={isReadingContactsForPinning}
                            />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        ))
    }

    const { config: { modalType, contact }, onOpenModal, onCloseModal } = useContext(ContactContext);
    
    return (
        <>
            <Grid>
                <Grid.Row columns='equal'>
                    <Grid.Column width={contactColumnWidth}>
                        <strong>Primary Contact</strong>
                    </Grid.Column>
                    <Grid.Column>
                        <strong>Role</strong>
                    </Grid.Column>
                    <Grid.Column>
                        <strong>Module/Pipeline</strong>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row verticalAlign='middle' columns='equal'>
                    <Grid.Column width={contactColumnWidth}>
                        <Grid columns='equal' verticalAlign='middle'>
                            <Grid.Column width={1}>
                                <Icon color={primary_contact?.id ? 'primary' : 'grey'} className='fas fa-address-book' />
                            </Grid.Column>
                            {primary_contact?.id ? ([
                                <Grid.Column {...(isSidePanelOpen ? { width: contactColumnWidth } : {})}>
                                    <Dropdown
                                        placeholder='Select Primary'
                                        selectOnBlur={false}
                                        selection
                                        options={contactsForPinning.filter(({ value }) => value !== primary_contact.id)}
                                        value={primary_contact.id}
                                        onChange={(_, { value: contact_id }) => {
                                            onSetPrimary(contact_id, true)
                                        }}
                                        compact
                                        text={(
                                            <LinkedAccount
                                                account={`${primary_contact.first_name} ${primary_contact.last_name}`}
                                                maxWidth={'8.25em'}
                                                subtitle={primary_contact?.job_title}
                                                popup
                                                linkToAccount={<Link onClick={e => { e.stopPropagation(); }} target={'_blank'} to={`/contact/${primary_contact.id}`}>{primary_contact.first_name} {primary_contact.last_name}</Link>}
                                            />
                                        )}
                                        style={{ width: '15em' }}
                                        loading={isReadingContactsForPinning}
                                    />
                                </Grid.Column>,
                                <Grid.Column>
                                    <Icon disabled={!primary_contact.primary_phone?.phone} link color='primary' className='fas fa-phone' />
                                    <Icon disabled={!primary_contact.primary_phone?.phone} link color='primary' className='fas fa-comment' />
                                    <Icon disabled={!primary_contact.primary_email?.email} link color='primary' className='fas fa-envelope'
                                        onClick={() => {
                                            onOpenModal('email', primary_contact.id);
                                        }}
                                    />
                                    <Icon link color='primary' className='fa-sharp fa-solid fa-pen-clip'
                                        onClick={() => {
                                            onOpenModal('note', primary_contact);
                                        }}
                                    />
                                </Grid.Column>
                            ]) : (
                                <Grid.Column>
                                    <Dropdown
                                        placeholder='Select Primary'
                                        selectOnBlur={false}
                                        selection
                                        options={contactsForPinning}
                                        value={null}
                                        onChange={(_, { value: contact_id }) => {
                                            onSetPrimary(contact_id);
                                        }}
                                        compact
                                        clearable
                                        style={{ width: '15em' }}
                                        text='Select Primary'
                                        loading={isReadingContactsForPinning}
                                    />
                                </Grid.Column>
                            )}
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        {primary_contact?.id && (
                            <InfluenceField
                                account_id={account_id}
                                contactBeingEdited={contactBeingEdited}
                                id={primary_contact?.id}
                                influence={primary_contact?.influence}
                                onCancelEdit={onCancelEdit}
                                onEdit={onEdit}
                            />
                        )}
                    </Grid.Column>
                    <Grid.Column>
                        {Object.keys(primary_contact).length > 1 ? primary_contact?.module_summary ?
                            `${StringUtils.capitalize(primary_contact.module_summary.account_stage)}-${primary_contact.module_summary.pipeline_stage_name}` : ''
                            : ''}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <strong>Other Contacts</strong>
                    </Grid.Column>
                </Grid.Row>
                {rows}
            </Grid>
            <SendEmailModal
                open={modalType === "email"}
                onClose={() => { onCloseModal() }}
                contact_id={contact}
            />
            <NotesModal
                open={modalType === "note"}
                onClose={() => { onCloseModal() }}
                contact={contact}
            />
        </>
    )
}
