import React from "react";
import { Table as SemanticTable } from "semantic-ui-react";
import { Table, ClearableCheckboxDropdown } from "dyl-components";
import SelectAllCheckbox from "shared/SelectAllCheckbox";
import { useSearchParams } from "react-router-dom";

const RingGroupCallQueuesTableHeader = ({ ringGroups, count, onFilter }) => {
    const [params] = useSearchParams();

    return (
        <Table.Row>
            <SemanticTable.HeaderCell width={1} className="">
                <SelectAllCheckbox
                    count={count}
                    data={ringGroups || []}
                    type={"ring_group/call_queue"}
                    idProperty={"id"}
                />
            </SemanticTable.HeaderCell>

            <SemanticTable.HeaderCell width={2}>
                Ring Group/Call Queue Name
            </SemanticTable.HeaderCell>

            <SemanticTable.HeaderCell width={1}>
                <ClearableCheckboxDropdown
                    text="Type"
                    name="type"
                    options={[
                        {
                            key: "ring_group",
                            value: "ring_group",
                            text: "Ring Group",
                        },
                        {
                            key: "call_queue",
                            value: "call_queue",
                            text: "Call Queue",
                        },
                    ]}
                    onChange={(_, { value }) => {
                        onFilter(_, { name: "type", value });
                    }}
                    value={params.get("type")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>

            <SemanticTable.HeaderCell width={1}>
                Internal Extension
            </SemanticTable.HeaderCell>

            <SemanticTable.HeaderCell width={1}>
                Office View Display
            </SemanticTable.HeaderCell>

            <SemanticTable.HeaderCell width={1}>
                Receive Calls
            </SemanticTable.HeaderCell>

            <SemanticTable.HeaderCell width={1}>
                Actions
            </SemanticTable.HeaderCell>
        </Table.Row>
    );
};

export default RingGroupCallQueuesTableHeader;
