import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function campaignsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CAMPAIGN_USERS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingUsers: true, users: [], count: 0 };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_USERS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingUsers: false, users: action.data?.data || [], count: action.data?.count || 0 };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_USERS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingUsers: false, users: [], count: 0 };

        default:
            return state;
    }
}


export default campaignsReducer;

