const initialState = {
    territories: [],
    counties: [],
    count: 0,
    isCreatingTerritory: false,
    isUpdatingTerritory: false,
    isReadingTerritories: false,
    isDeletingTerritory: false,
    territory: null,
    isReadingTerritory: false,
    isReadingCounties: false,
    isCheckingDuplicate: false,
    isDuplicate: false,
    validationResults: null,
    isCheckingValidity: false
}

export default initialState;
