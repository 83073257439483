import React from 'react';
import { Grid, Button, Popup } from 'semantic-ui-react';
import AddTaskTypeSectionContainer from './AddTaskTypeSection';

const TaskTypeHeader = ({ onOpenAddTaskTypeSection, onCloseAddTaskTypeSection, isAddTaskTypeSectionOpen }) => (
    <Grid verticalAlign='middle' columns='equal'>
        <Grid.Column width={2}>
            Task Types
        </Grid.Column>
        <Grid.Column width={4} textAlign='right' floated='right'>
            <Popup
                trigger={<Button onClick={onOpenAddTaskTypeSection} size='tiny' basic inverted color='white'>Add Task Type</Button>}
                onOpen={onOpenAddTaskTypeSection}
                onClose={onCloseAddTaskTypeSection}
                on='click'
                content={
                    <AddTaskTypeSectionContainer
                        onClose={onCloseAddTaskTypeSection}
                    />
                }
                position='bottom center'
                open={isAddTaskTypeSectionOpen}
            />
        </Grid.Column>
    </Grid>
)

export default TaskTypeHeader;
