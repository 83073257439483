import React from "react";
import { Popup } from "semantic-ui-react";

const UpsellCustomerPopup = ({
    open,
    trigger,
    position = "left center"
}) => {
    return (
        <Popup
            style={{ zIndex: 1901 }}
            open={open}
            position={position}
            content={(
                <div style={{ minWidth: "20em" }}>
                    Quote has already been added. It will use the latest quote within the opportunity.
                </div>
            )}
            inverted
            trigger={trigger}
            size="small"
        />
    );
};

export default UpsellCustomerPopup;
