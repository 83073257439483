import React from 'react';
import { Grid, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';

const PersonalInformationHeader = ({ isEditMode, onEnableEditMode, onCloseEditMode, onUpdate, isUpdating }) => (
    <Grid verticalAlign='middle'>
        <Grid.Column width={6}>
            Your Information
        </Grid.Column>
        <Grid.Column width={4} textAlign='right' floated='right'>
            {!isEditMode ? <Button onClick={onEnableEditMode} size='tiny' basic inverted color='white'>Edit</Button> :
                <React.Fragment>
                    { !isUpdating && <Button size='tiny' inverted color='white' basic onClick={onCloseEditMode}>Cancel</Button> }
                    <Button loading={isUpdating} size='tiny' inverted color='white' basic onClick={onUpdate}>Update</Button>
                </React.Fragment>
            }
        </Grid.Column>
    </Grid>
);

const mapStateToProps = state => ({
    isUpdating: state.user.isUpdating
});

export default connect(mapStateToProps, null)(PersonalInformationHeader);
