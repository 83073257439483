import { yup } from "dyl-components";
import applyCurrencySchema from "./applyCurrencySchema";

const bulkPricingTierSchema = {
    start: yup
        .number()
        .positive("Please enter a valid quantity")
        .integer("Please enter a valid quantity")
        .typeError("Please enter a valid quantity")
        .required("This field is required")
        .test(
            "should_be_less_than_end",
            "Start should be less than end",
            function (start, context) {
                if (!!!context.parent || isNaN(start)) {
                    return true;
                }
                if (
                    !!!context.parent.end ||
                    Number.isNaN(context.parent.end)
                ) {
                    return true;
                }
                return start < (context.parent.end || 0);
            }
        ),
    end: yup.lazy((value) => {
        if (value === null || value === undefined) {
            return yup.string().nullable(true);
        }
        if (value === "") {
            return yup.string().nullable(true);
        }
        return yup
            .number()
            .positive("Please enter a valid quantity")
            .integer("Please enter a valid quantity")
            .typeError("Please enter a valid quantity")
            .test(
                "should_be_greater_than_start",
                "End should be greater than start",
                function (end, context) {
                    if (!!!context.parent || isNaN(end)) {
                        return true;
                    }
                    return end > (context.parent?.start || 0);
                }
            );
    }),
    price: applyCurrencySchema(yup.number()).required("This field is required"),
};

export default bulkPricingTierSchema;
