import React from "react";
import { ClearableCheckboxDropdown, Table } from "dyl-components";
import { Table as SemanticTable } from "semantic-ui-react";

import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import SelectAllCheckbox from "shared/SelectAllCheckbox";

export const UsersHeader = ({ onFilter }) => {
    const { count, users = [] } = useSelector((state) => {
        return {
            count: state.campaign_users.count,
            users: state.campaign_users.users.map(
                (campaign_user) => campaign_user.user
            ),
        };
    });

    const [params] = useSearchParams();

    return (
        <Table.Row>
            <SemanticTable.HeaderCell
                width={1}
                className="CampaignUsersTable__SmallFixedWidth"
            >
                <SelectAllCheckbox
                    count={count}
                    data={users}
                    type={"users"}
                    idProperty={"id"}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                className="CampaignUsersTable__LargeFixedWidth"
                width={4}
            >
                <ClearableCheckboxDropdown
                    text="User"
                    name="user"
                    onChange={(_, { value }) => {
                        onFilter(_, { name: "user", value });
                    }}
                    value={params.get("user")?.split(",")}
                    options={[
                        { key: "removed", value: "removed", text: "Removed" },
                        {
                            key: "in_campaign",
                            value: "in_campaign",
                            text: "In Campaign",
                        },
                    ]}
                    closeOnLoad
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                className="CampaignUsersTable__MediumFixedWidth"
                width={4}
            >
                Conversion Rate
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                className="CampaignUsersTable__MediumFixedWidth"
                width={2}
            >
                Total Converted
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                className="CampaignUsersTable__MediumFixedWidth"
                width={2}
            >
                Total Calls
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                className="CampaignUsersTable__MediumFixedWidth"
                width={2}
            >
                Attributed Revenue
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                className="CampaignUsersTable__SmallFixedWidth"
                width={1}
            >
                Actions
            </SemanticTable.HeaderCell>
        </Table.Row>
    );
};
