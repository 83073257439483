import React, { useContext, useRef } from 'react';
import { Checkbox, Dropdown, Icon, Ref } from 'semantic-ui-react';
import { DateTimeUtils, EllipsisDropdown, Notification, STATUS_TYPES, Table, TableLoader } from 'dyl-components';

import { Link, useParams, useSearchParams } from 'react-router-dom';
import { CampaignsContext } from './CampaignsContextProvider';
import { StringUtils } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import campaignsActions from 'actions/campaigns';
import contactCampaignActions from 'actions/contact_campaigns';

const DropdownItem = ({ text, icon, ...otherProps }) => {
    return (
        <Dropdown.Item
            text={text}
            style={{ cursor: 'pointer' }}
            {...otherProps}
        />
    );
}

const CampaignConversion = ({ isConverted, type, campaign_id, status }) => {
    const { contact_id: person_id } = useParams();
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const onUpdate = async () => {
        try {
            await dispatch(campaignsActions.updatePersonCustomCampaignConverted(null, null, { campaign_id, person_id: Number(person_id), campaign_converted: !isConverted }));
            Notification.alert("Successfully updated custom campaign conversion!", STATUS_TYPES.SUCCESS);
            dispatch(contactCampaignActions.readCampaigns(person_id, {
                ...Object.fromEntries(params)
            }));
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to update custom campaign conversion", STATUS_TYPES.ERROR);
        }
    }

    const icon = (() => {
        if (type === "lead") {
            return `address-card`
        }
        if (type === "opportunity") {
            return `megaphone`
        }
        if (type === "customer") {
            return `user-crown`
        }
        return null
    })();
    return (
        <div>
            <Checkbox onChange={onUpdate} disabled={status === "expired" || type !== "custom"} checked={isConverted} /> {icon && <Icon className={`fas fa-${icon}`} />} {StringUtils.capitalize(type)}
        </div>
    )
}

const CampaignsRow = ({ campaign }) => {
    const { onSelectCampaign, state } = useContext(CampaignsContext);
    const { action } = state;
    const ref = useRef();

    const isCompanyCampaign = Object.keys(campaign.is_company_campaign).length > 1;
    const status = campaign.status.toLowerCase();
    const isDeleted = status === 'deleted';
    const contactIsRemovedFromCampaign = campaign.deleted;
    const canBeRemovedFromCampaign = status === 'active' && !contactIsRemovedFromCampaign;

    const isUpdating = useSelector(state => Number(state.campaigns.customCampaignConvertedBeingUpdated) === campaign.id);

    if (isUpdating) {
        return (
            <TableLoader isLoading colspan={7} />
        )
    }
    
    return (
        <Table.CollapsibleRow {...((isDeleted || contactIsRemovedFromCampaign) && { className: 'CampaignsTable__Row--disabled ' })}>
            <Table.Cell>
                <div><b>{DateTimeUtils.formatEpoch(campaign.created, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                <div>{DateTimeUtils.formatEpoch(campaign.created, DateTimeUtils.TIME_FORMAT)}</div>
            </Table.Cell>
            <Table.Cell>
                <div>{campaign.is_company_campaign.start_time && (`${DateTimeUtils.formatEpoch(campaign.is_company_campaign?.start_time, DateTimeUtils.WORD_DATE_FORMAT)} -`)}</div>
                <div>{campaign.is_company_campaign.end_time && DateTimeUtils.formatEpoch(campaign.is_company_campaign?.end_time, DateTimeUtils.WORD_DATE_FORMAT)}</div>
            </Table.Cell>
            <Table.Cell disabled={isDeleted || contactIsRemovedFromCampaign}>
                <Link to={`/campaigns/${campaign.id}/dashboard`}>{campaign.name}</Link>
            </Table.Cell>
            <Table.Cell disabled={isDeleted || contactIsRemovedFromCampaign}>
                <CampaignConversion status={campaign.status.toLowerCase()} campaign_id={campaign.id} isConverted={campaign.campaign_converted} type={campaign.campaign_conversion} />
            </Table.Cell>
            <Table.Cell className='StatusColumn'>
                {<span className={`StatusColumn--${campaign.status.toLowerCase()}`}>{campaign.status}</span>}
            </Table.Cell>
            <Table.Cell>{isCompanyCampaign ? 'Company' : 'Personal'}</Table.Cell>
            <Table.Cell>
                {canBeRemovedFromCampaign && (
                    <Ref innerRef={ref}>
                        <EllipsisDropdown {...(action ? { isDropdownOpen: false } : {})}>
                            <DropdownItem
                                text='Remove from campaign'
                                onClick={() => { onSelectCampaign(campaign.id, 'remove', ref) }}
                            />
                        </EllipsisDropdown>
                    </Ref>
                )}
            </Table.Cell>
        </Table.CollapsibleRow>
    );
}

export default CampaignsRow;
