import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function leadSourcesReducer(state = initialState, action){
    
    switch(action.type){
        case getNamedAction(ACTION_NAMES.LEAD_SOURCES, CRUD_ACTION_TYPES.READ_REQUEST):
            return {...state, isReadingLeadSources: true}
        case getNamedAction(ACTION_NAMES.LEAD_SOURCES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {...state, isReadingLeadSources: false, leadSources: action.data}
        case getNamedAction(ACTION_NAMES.LEAD_SOURCES, CRUD_ACTION_TYPES.READ_FAILURE):
            return {...state, isReadingLeadSources: false}
        default:
            return state
    }

}

export default leadSourcesReducer;
