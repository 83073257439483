import React, { useContext } from "react";
import {
    ConfirmationPrompt,
    EllipsisDropdownPortal,
    Notification,
    STATUS_TYPES,
    Table,
} from "dyl-components";
import Contact from "shared/Contact";
import { Link, useParams } from "react-router-dom";
import { Checkbox, Dimmer, DropdownItem, Icon, Loader, Popup, Ref } from "semantic-ui-react";
import { BulkActionsContext } from "shared/context/BulkActionsProvider";
import { StringUtils } from "utils";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import campaignContactsActions from "actions/campaign_contacts";
import campaignsActions from "actions/campaigns";
import campaignActions from 'actions/campaigns';
import { useState } from "react";
import ModuleLink from "shared/ModuleLink";

export const ContactsRow = ({ contact, isCampaignDeleted, readContacts }) => {
    const [selectedContacts, setSelectedContacts] =
        useContext(BulkActionsContext);

    const isSelected = (contact_id) => {
        return selectedContacts.includes(contact_id);
    };

    const onToggleContact = (contact_id) => {
        const newSelectedContacts = isSelected(contact_id)
            ? selectedContacts.filter((id) => contact_id !== id)
            : [...selectedContacts, contact_id];
        setSelectedContacts(newSelectedContacts);
    };

    const contact_status = contact.active ? "active" : "inactive";
    const ref = useRef();

    const dispatch = useDispatch();
    const { id } = useParams();

    const onUpdateContactStatus = async () => {
        const newStatus = contact.active ? "inactive" : "active";
        try {
            await dispatch(
                campaignContactsActions.updateContactStatus(
                    id,
                    null,
                    { active: !contact.active },
                    contact.id
                )
            );
            Notification.alert(
                `Successfully set contact to ${newStatus}!`,
                STATUS_TYPES.SUCCESS
            );
            readContacts();
        } catch (e) {
            Notification.alert(
                `Failed to set contact to ${newStatus}`,
                STATUS_TYPES.ERROR
            );
        }
    };

    const isUpdatingStatus = useSelector(
        (state) => state.campaign_contacts.contactBeingUpdated === contact.id
    );

    const { campaign } = useSelector(state => ({
        campaign: state.campaigns.campaign
    }));

    const onRemoveContact = async () => {
        try {
            await dispatch(
                campaignsActions.campaignContactsBulkAction(
                    "",
                    { payload: [contact.id] },
                    { action: "remove_from_campaign", campaign_id: Number(id) }
                )
            );
            setIsRemoving(false);
            Notification.alert(
                `Successfully removed contact from campaign!`,
                STATUS_TYPES.SUCCESS
            );
            dispatch(campaignActions.readCampaign(Number(id)));
            readContacts();
        } catch (e) {
            Notification.alert(
                `Failed to remove contact from campaign`,
                STATUS_TYPES.ERROR
            );
        }
    };

    const [isRemoving, setIsRemoving] = useState(false);

    const isApplyingBulkAction = useSelector(state => state.campaigns.isApplyingBulkAction);
    
    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox
                    checked={isSelected(contact.id)}
                    onChange={() => {
                        onToggleContact(contact.id);
                    }}
                />
            </Table.Cell>
            <Table.Cell>
                <Contact
                    contact={contact}
                    name={`${contact.first_name} ${contact.last_name}`}
                    linkToContact={
                        <Link
                            to={`/contact/${contact.id}`}
                        >{`${contact.first_name} ${contact.last_name}`}</Link>
                    }
                    hideId
                />
            </Table.Cell>

            <Table.Cell>
                {contact?.campaign_converted ? (
                    <>
                        <div>
                            Yes{campaign.campaign_conversion === "custom" ? "" : `, ${StringUtils.capitalize(campaign.campaign_conversion)}`}
                        </div>
                        {contact.pipeline_id && <div>
                            <ModuleLink pipeline_id={contact.pipeline_id} includeLabel unlinked={contact.unlinked} />
                        </div>}
                        {campaign.campaign_conversion === "customer" && (
                            <>ID: {contact.unlinked ? "Unlinked" : <Link to={`/account/${contact.account_id}`}>{contact.account_id}</Link>}</>
                        )}
                    </>
                ) : "No"}
            </Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>

            <Table.Cell></Table.Cell>
            <Table.Cell className={`ContactStatus__${contact_status}`}>
                <Icon
                    name="circle"
                    className={`ContactStatus__${contact_status}`}
                />
                {StringUtils.capitalize(contact_status)}
            </Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
                {!isCampaignDeleted && (
                    <Ref innerRef={ref}>
                        <EllipsisDropdownPortal reference={ref}>
                            <DropdownItem
                                text="Remove"
                                disabled={isUpdatingStatus}
                                onClick={() => {
                                    setIsRemoving(true);
                                }}
                            />
                            <DropdownItem
                                text={
                                    <span>
                                        Set to{" "}
                                        {contact.active ? "Inactive" : "Active"}{" "}
                                        {isUpdatingStatus && (
                                            <Icon
                                                className="fas fa-circle-notch"
                                                loading
                                            />
                                        )}
                                    </span>
                                }
                                onClick={onUpdateContactStatus}
                                disabled={isUpdatingStatus}
                            />
                        </EllipsisDropdownPortal>
                    </Ref>
                )}
                <Popup
                    open={isRemoving}
                    position="bottom center"
                    context={ref}
                    onClose={() => {
                        setIsRemoving(false);
                    }}
                    content={
                        <div>
                            <Dimmer active={isApplyingBulkAction} />
                            <Loader active={isApplyingBulkAction} />
                            <ConfirmationPrompt
                                onConfirm={onRemoveContact}
                                onCancel={() => {
                                    setIsRemoving(false);
                                }}
                            />
                        </div>
                    }
                    hoverable
                />
            </Table.Cell>
        </Table.Row>
    );
};
