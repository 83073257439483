const initialState = {
    opportunities: [],
    count: 0,
    isReadingOpportunities: false,
    allMarked: false,
    stages: [],
    queryParameters: {
        page: 1,
        limit: 25
    },
    allPagesMarked: false
}

export default initialState;
