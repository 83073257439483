const initialState = {
    teams: [],
    assigned: [],
    count: 0,
    isReadingTeams: false,
    isReadingTeamOptions: false,
    isAddingTeam: false,
    queryParameters: {
        page: 1,
        limit: 25,
        deleted: false,
        team_name: ''
    },
}

export default initialState;
