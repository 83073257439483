import React from 'react';
import { Button } from 'semantic-ui-react';

import './DialButton.scss'

const DialButton = ({ mainKey, subKeys = [], onPressNumberKey, disabled }) => (
    <Button onClick={() => {onPressNumberKey(mainKey)}} className={'DialButton'} disabled={disabled} >
        <div><b>{mainKey}</b></div>
        <div><small>{subKeys.join('')}</small></div>
    </Button>
);

export default DialButton;
