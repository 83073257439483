import {
    Modal,
    generateResolver,
    yup,
    Notification,
    STATUS_TYPES,
} from "dyl-components";
import React, { useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Form, Icon } from "semantic-ui-react";
import contactsActions from "actions/contacts";
import campaignsActions from "actions/campaigns";
import { StringUtils } from "utils";

const AddToCampaignModal = ({
    open,
    onClose,
    contactToAdd,
    refresh
}) => {
    const CAMPAIGN_LIMIT = 999;

    const {
        control,
        reset,
        formState: { isValid, isDirty },
        handleSubmit,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            campaign: null,
        },
        resolver: generateResolver({
            campaign: yup.number().required("This field is required"),
        }),
    });

    const {
        campaigns,
        isReadingCampaigns,
        bulkAction
    } = useSelector((state) => {
        return {
            campaigns: state.campaigns.campaigns,
            isReadingCampaigns: state.campaigns.isReadingCampaigns,
            bulkAction: state.contacts.bulkAction
        };
    });

    const dispatch = useDispatch();

    const close = () => {
        reset();
        onClose();
        refresh();
    };

    const onBulkAction = async ({ campaign }) => {
        try {
            await dispatch(
                contactsActions.contactBulkAction(
                    {
                        action: "add_to_campaign",
                        contact_id: [contactToAdd],
                        campaign_id: campaign,
                    }
                )
            );
            close();
            Notification.alert("Successfully added to campaign!", STATUS_TYPES.SUCCESS);
        } catch (error) {
            console.log(error);
            Notification.alert("Failed to add to campaign", STATUS_TYPES.ERROR);
        }
    };
    
    const getCampaigns = useCallback(async () => {
        dispatch(campaignsActions.readCampaigns({ limit: CAMPAIGN_LIMIT, contact_id: contactToAdd }));
    }, [dispatch, contactToAdd]);

    useEffect(() => {
        getCampaigns();
    }, [getCampaigns]);

    const isAddingToCampaign = bulkAction === "add_to_campaign";
    return (
        <Modal open={open} onClose={close}>
            <Modal.Header>
                Add to Campaign
            </Modal.Header>
            <Modal.Content>
                <Form noValidate loading={isAddingToCampaign}>
                    <Controller
                        name="campaign"
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                            <Form.Select
                                value={value}
                                options={campaigns
                                    .filter((campaign) => !campaign.deleted && campaign.status === 'Active')
                                    .map((campaign) => ({
                                        key: campaign.id,
                                        text: campaign.name,
                                        value: campaign.id,
                                        description: StringUtils.capitalize(campaign.campaign_conversion)
                                    }))}
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } });
                                }}
                                placeholder={
                                    <div>
                                        <Icon
                                            style={{ marginRight: 10 }}
                                            name="search"
                                        />
                                        Select an Existing Campaign
                                    </div>
                                }
                                label="Campaign Name"
                                loading={isReadingCampaigns}
                                search
                                width={15}
                                selection
                                required
                                selectOnBlur={false}
                                clearable
                            />
                        )}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions
                hasSaveButton
                saveDisabled={!isValid || !isDirty || isAddingToCampaign}
                onSave={handleSubmit(onBulkAction)}
                saveOptions={{ loading: isAddingToCampaign }}
            />
        </Modal>
    );
};

export default AddToCampaignModal;
