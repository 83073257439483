import sequenceTasksActions from "actions/sequence_tasks";
import { DateInput, DateTimeUtils, DropdownTimeInput, Modal, Notification, STATUS_TYPES, generateResolver, yup } from "dyl-components";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Form, Segment } from "semantic-ui-react";
import RescheduleSequenceStats from "./RescheduleSequenceStats";

const Content = ({ sequenceTaskId, onClose, refresh }) => {
    const { sequenceTask, sequenceTaskBeingUpdated, isReading } = useSelector(state => ({
        sequenceTask: state.sequence_tasks.sequenceTask || {},
        isReading: state.sequence_tasks.isReadingSequenceTask,
        sequenceTaskBeingUpdated: state.sequence_tasks.sequenceTaskBeingUpdated
    }));

    const { workflow: sequence, action, ts } = sequenceTask;
    const [date, time] = DateTimeUtils.formatEpoch(ts, `${DateTimeUtils.WORD_DATE_FORMAT}-${DateTimeUtils.TIME_FORMAT}`).split('-');

    const isRescheduling = sequenceTaskBeingUpdated === sequenceTaskId;
    
    const { control, formState: { isValid, isDirty }, handleSubmit, reset, trigger, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            date: date,
            time: time,
        },
        resolver: generateResolver({
            date: yup.string().test("valid_date", "Invalid date", (value => {
                return value ? DateTimeUtils.isValid(value, DateTimeUtils.WORD_DATE_FORMAT) : true;
            })).test("only_future", "Cannot select a past date", function (value) {
                const currentDate = DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATE_FORMAT);
                return !DateTimeUtils.isValid(value, DateTimeUtils.WORD_DATE_FORMAT) || !DateTimeUtils.dateIsBeforeAnotherDate(value, currentDate, "days", DateTimeUtils.WORD_DATE_FORMAT)
            }),
            time: yup.string().required('This field is required').test("only_future", "Please select a future time", function (value) {
                const currentDateTime = DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATETIME_FORMAT);
                const date = this.parent.date;
                if (!DateTimeUtils.isValid(date, DateTimeUtils.WORD_DATE_FORMAT)) {
                    return true;
                }
                const dateTime = `${date} ${value}`;
                return !DateTimeUtils.dateIsBeforeAnotherDate(dateTime, currentDateTime, "minutes", DateTimeUtils.WORD_DATETIME_FORMAT);
            })
        })
    });

    const dispatch = useDispatch();

    const onReschedule = async (data) => {
        try {
            const { date, time } = data;
            const newDateTime = DateTimeUtils.getUnixTime(`${date} ${time}`, DateTimeUtils.WORD_DATETIME_FORMAT);
            await dispatch(sequenceTasksActions.reschedule(sequenceTaskId, { ts: newDateTime }));
            Notification.alert('Sequence task successfully rescheduled', STATUS_TYPES.SUCCESS);
            reset();
            onClose();
            if (refresh) {
                refresh();
            }
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to reschedule sequence task', STATUS_TYPES.ERROR);
        }
    }

    return (
        <>
        <Modal.Header>
                Reschedule {isReading ? "" : `'${`${sequence?.name} - ${action}`}'`}
            </Modal.Header>
            <Modal.Content>
                <Form loading={isRescheduling || isReading} noValidate>
                    <Controller
                        control={control}
                        name={'date'}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => {
                            const commonDateInputProps = {
                                control: DateInput,
                                name,
                                value,
                                dateFormat: DateTimeUtils.WORD_DATE_FORMAT,
                                minDate: DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATE_FORMAT),
                                preserveViewMode: false
                            }
                            return (
                                <React.Fragment>
                                    {!isReading && (
                                        <Form.Field
                                            control={'div'}
                                        >
                                            <DateInput
                                                {...commonDateInputProps}
                                                inline
                                                {...ts ? { marked: DateTimeUtils.formatEpoch(ts, DateTimeUtils.WORD_DATE_FORMAT), markColor: 'blue' } : {}}
                                                onChange={(_, { value }) => {
                                                    onChange({ target: { name, value } });
                                                    trigger('time')
                                                }}
                                            />
                                        </Form.Field>
                                    )}
                                    <Form.Field control='div' label='Date & Time' required>
                                        <Form.Group widths={'equal'}>
                                            <Form.Input
                                                error={error?.message}
                                                fluid
                                                value={value}
                                                icon='fas fa-calendar'
                                                iconPosition="right"
                                                readOnly
                                            />
                                            <Controller
                                                control={control}
                                                name='time'
                                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                                    <Form.Field
                                                        control={DropdownTimeInput}
                                                        onChange={(_, { value }) => {
                                                            onChange({ target: { name, value } });
                                                        }}
                                                        error={error?.message}
                                                        value={value}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </Form.Field>
                                </React.Fragment>
                            );
                        }}
                    />
                    <RescheduleSequenceStats watch={watch} />
                </Form>
                
            </Modal.Content>
            <Modal.Actions hasSaveButton onSave={handleSubmit(onReschedule)} saveDisabled={!isValid || !isDirty || isRescheduling || isReading} saveOptions={{ loading: isRescheduling }} />
        </>
    )
}

const RescheduleSequenceModal = ({ sequenceTaskId, open, onClose, refresh }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (open) {
            dispatch(sequenceTasksActions.readOne(sequenceTaskId))
            dispatch(sequenceTasksActions.readRescheduleStats(sequenceTaskId));
        }
    }, [dispatch, sequenceTaskId, open]);

    const { isReading } = useSelector(state => ({
        isReading: state.sequence_tasks.isReadingSequenceTask,
    }));


    return (
        <Modal open={open} onClose={onClose} >
            {!isReading ? (
                <Content sequenceTaskId={sequenceTaskId} onClose={onClose} refresh={refresh} />
            ) : (
                <Segment basic loading />
            )}
        </Modal>
    )
}

export default RescheduleSequenceModal;
