import React from 'react';
import { Grid, Icon, Header, Transition } from 'semantic-ui-react';
import BusinessStandardRegistrationData from './BusinessStandardRegistrationData';
import BusinessStandardFinancialData from './BusinessStandardFinancialData';

export const BusinessInfo = ({
    businessRegistrationExpanded,
    onToggleBusinessRegistration,
    financialInfoExpanded,
    onToggleFinancialInfo,
}) => {
    return [
        <Grid.Column>
            <Grid className='ContactInfo'>
                <Grid.Row columns={'equal'}>
                    <Grid.Column>
                        <Header color='primary'>
                            <span>Business Registration <Icon
                                onClick={onToggleBusinessRegistration}
                                link
                                size='small'
                                className={`fas fa-circle-chevron-${businessRegistrationExpanded ? 'up' : 'down'}`}
                            />
                            </span>
                        </Header>
                        <Transition visible={businessRegistrationExpanded} animation={'slide down'} unmountOnHide
                            duration={'150'}>
                            <div>
                                <BusinessStandardRegistrationData />
                            </div>
                        </Transition>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Grid.Column>,
        <Grid.Column>
            <Grid className='ContactInfo'>
                <Grid.Row columns={'equal'}>
                    <Grid.Column>
                        <Header color='primary'>
                            <span>Financial Info <Icon
                                onClick={onToggleFinancialInfo}
                                link
                                size='small'
                                className={`fas fa-circle-chevron-${financialInfoExpanded ? 'up' : 'down'}`}
                            />
                            </span>
                        </Header>
                        <Transition visible={financialInfoExpanded} animation={'slide down'} unmountOnHide
                            duration={'150'}>
                            <div>
                                <BusinessStandardFinancialData />
                            </div>
                        </Transition>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Grid.Column>
    ]
}
