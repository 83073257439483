import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function customerTabReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CUSTOMER_PIPELINE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, pipelines: [], count: 0, isReadingCustomerPipelines: true };
        case getNamedAction(ACTION_NAMES.CUSTOMER_PIPELINE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, pipelines: action.data?.data || [], count: action.data?.count || 0, isReadingCustomerPipelines: false };
        case getNamedAction(ACTION_NAMES.CUSTOMER_PIPELINE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, pipelines: [], count: 0, isReadingCustomerPipelines: false };
        
        case getNamedAction(ACTION_NAMES.CUSTOMER_PIPELINE_TIMELINE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, pipelineTimelines: { ...state.pipelineTimelines, [action.id]: { isReading: true, logs: [], count: 0 } } };
        case getNamedAction(ACTION_NAMES.CUSTOMER_PIPELINE_TIMELINE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, pipelineTimelines: { ...state.pipelineTimelines, [action.id]: { isReading: false, logs: action.data?.data || [], count: action.data?.count || 0 } } };
        case getNamedAction(ACTION_NAMES.CUSTOMER_PIPELINE_TIMELINE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, pipelineTimelines: { ...state.pipelineTimelines, [action.id]: { isReading: false, logs: [], count: 0 } } };

        default:
            return state;
    }
}


export default customerTabReducer;
