import { Notification, STATUS_TYPES } from 'dyl-components';

import { generateDeleteActionCreator, generateUpdateActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

const onDeleteSuccess = () => {
  Notification.alert('Successfully deleted tag!', STATUS_TYPES.SUCCESS, true);
}

const onDeleteFailure = () => {
  Notification.alert('Failed to delete tag', STATUS_TYPES.ERROR, true);
}

const onUpdateSuccess = () => {
  Notification.alert('Successfully updated tag!', STATUS_TYPES.SUCCESS, true);
}

const onUpdateFailure = () => {
  Notification.alert('Failed to update tag', STATUS_TYPES.ERROR, true);
}

const deleteTag = generateDeleteActionCreator('/v1/tag', ACTION_NAMES.TAG, onDeleteSuccess, onDeleteFailure);
const updateTag = generateUpdateActionCreator('/v1/tag', ACTION_NAMES.TAG, onUpdateSuccess, onUpdateFailure);

export default {
  deleteTag,
  updateTag
};
