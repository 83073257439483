import { Notification, STATUS_TYPES, VALIDATORS, generateResolver, yup } from 'dyl-components';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import pipelineActions from "actions/pipeline";
import { useDispatch, useSelector } from 'react-redux';
import ChangePipelineForm from 'shared/forms/ChangePipelineForm';

import contactSequenceActions from 'actions/contact_sequence';
import sequencesActions from 'actions/sequences';
import sequenceTasksActions from 'actions/sequence_tasks';

const ChangeModulePipelineForm = ({
    account_stage,
    onClose,
    refresh,
    current_pipeline_category,

    account_id,
    contact_id
}) => {
    const { formState: { isDirty, isValid }, handleSubmit, control, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            lost_reason: null,
            lost_type: null,
            notes: ''
        },
        resolver: generateResolver({
            pipeline: yup.string().required('This field is required'),
            stage: yup.string().required('This field is required'),
            notes: VALIDATORS.DESCRIPTION()
        })
    });

    const dispatch = useDispatch();

    const { pipelineCategories, isReadingCategories, isChangingPipeline, isReadingSequenceContactTasks, contactSequenceTasks, selectedSequenceId } = useSelector(state => ({
        pipelineCategories: state.pipeline.categories.filter(category => category.id !== current_pipeline_category).map(category => ({
            key: category.id,
            value: category.id,
            text: category.name,
            stages: category.stages?.map(stage => ({
                key: stage.id,
                value: stage.id,
                text: stage.name,
                sequence_id: stage.sequence_id
            })) || []
        })),
        isReadingCategories: state.pipeline.isReadingCategories,
        isChangingPipeline: state.pipeline.isChangingModulePipeline || state.contact_sequence.isRemovingSequence || state.sequences.isAddingContactToSequence,

        contactSequenceTasks: state.sequence_tasks.selectedSequenceTasks,
        isReadingSequenceContactTasks: state.sequence_tasks.isReadingSequenceContactTasks,
        selectedSequenceId: state.sequence_tasks.selectedSequenceId
    }));

    useEffect(() => {
        dispatch(sequenceTasksActions.readContactTasks(contact_id));
    }, [dispatch, account_stage, contact_id]);

    const onChangePipeline = async (data) => {
        try {
            const { stage, pipeline, notes } = data;
            const areSequenceTasksCompleted = contactSequenceTasks.length === 0 || contactSequenceTasks.every(task => task.status === 'complete' || task.status === 'canceled');
            const category = pipelineCategories.find(category => category.value === Number(pipeline));
            const newStage = category?.stages.find(categoryStage => categoryStage.value === Number(stage));
            await dispatch(pipelineActions.changeModulePipeline(null, {
                account_id,
                person_id: Number(contact_id),

                pipeline_stage_id: Number(stage),

                previous_sequence_complete: areSequenceTasksCompleted,
                change_reason_notes: notes
            }));
            if (selectedSequenceId) {
                await dispatch(contactSequenceActions.removeFromContact(Number(contact_id)));
            }
            if (newStage.sequence_id) {
                await dispatch(sequencesActions.addToSequence({ contact_id: Number(contact_id) }, null, newStage.sequence_id));
            }
            Notification.alert('Successfully changed pipeline!', STATUS_TYPES.SUCCESS);
            if (onClose) {
                onClose();
            }
            if (refresh) {
                refresh();
            }
            reset();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to change pipeline', STATUS_TYPES.ERROR);
        }
    }

    return (
        <ChangePipelineForm
            control={control}
            isReadingCategories={isReadingCategories}
            isSaving={isChangingPipeline || isReadingSequenceContactTasks}
            isSubmitDisabled={!isDirty || !isValid || isChangingPipeline}
            onSubmit={handleSubmit(onChangePipeline)}
            pipelineCategories={pipelineCategories}
        />
    );
}

export default ChangeModulePipelineForm;
