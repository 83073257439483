
import initialState from './initialState';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';

function upload(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.UPLOAD_FILE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isUploadingFile: true };
        case getNamedAction(ACTION_NAMES.UPLOAD_FILE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
        case getNamedAction(ACTION_NAMES.UPLOAD_FILE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isUploadingFile: false };

        case getNamedAction(ACTION_NAMES.DOWNLOAD_FILE, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isDownloadingFile: true };
        case getNamedAction(ACTION_NAMES.DOWNLOAD_FILE, CRUD_ACTION_TYPES.READ_SUCCESS):
        case getNamedAction(ACTION_NAMES.DOWNLOAD_FILE, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isDownloadingFile: false };

        case getNamedAction(ACTION_NAMES.UPLOAD_BLAST_LIST, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isUploadingBlastList: true };
        case getNamedAction(ACTION_NAMES.UPLOAD_BLAST_LIST, CRUD_ACTION_TYPES.CREATE_SUCCESS):
        case getNamedAction(ACTION_NAMES.UPLOAD_BLAST_LIST, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isUploadingBlastList: false };

        default:
            return state;
    }
}


export default upload;
