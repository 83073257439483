import React from 'react';
import { Controller } from 'react-hook-form';
import { Form, Icon, ListItem } from 'semantic-ui-react';

const DirectionField = ({
    control,
    id,
    isAllowedToModify,
    complete
}) => (
    <ListItem
        icon={<Icon className='fas fa-directions' color='black' />}
        description={(
            <Controller
                name='direction'
                defaultValue={'outbound'}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                    <Form.Field>
                        <label>Direction</label>
                        <Form.Select
                            value={value}
                            name={name}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }); }}
                            options={[
                                { key: 'outbound', value: 'outbound', text: 'Outbound' },
                                { key: 'inbound', value: 'inbound', text: 'Inbound' }
                            ]}
                            selectOnBlur={false}
                            required
                            {...(!id || !isAllowedToModify ? ({ tabIndex: -1, open: false, icon: '' }) : {})} 
                            disabled={complete}    
                        />
                    </Form.Field>

                )} />
        )} />
)

export default DirectionField;
