import { Modal } from 'dyl-components';
import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Grid, Header, Icon, Segment } from 'semantic-ui-react';
import LinkContactsForm from 'shared/forms/LinkContactsForm';
import { StringUtils } from 'utils';
import { AccountContacts } from './AccountContacts';
import { AccountStandardData } from './AccountStandardData';
import ContactProvider from 'shared/context/ContactProvider';

export const AccountDetails = ({
    onPin,
    onReplacePinnedContact,
    onUnpin,

    onSetPrimary,
    isSidePanelOpen,
    account_type
}) => {
    const { account_id } = useParams();

    const [isLinkContactsModalOpen, setLinkContactsModalOpen] = useState(false);
    const openLinkContactsModal = () => {
        setLinkContactsModalOpen(true);
    }
    const closeLinkContactsModal = () => {
        setLinkContactsModalOpen(false);
    }

    return (
        <Segment basic>
            <Grid>
                <AccountStandardData />
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h4' color='primary' className='AccountDetails__header'>
                            <Header.Content>
                                <Icon onClick={openLinkContactsModal} className='fas fa-plus-circle' link />
                                <Modal open={isLinkContactsModalOpen} onClose={closeLinkContactsModal}>
                                    <LinkContactsForm
                                        account_id={account_id}
                                        onClose={closeLinkContactsModal}
                                    />
                                </Modal> {StringUtils.capitalize(account_type)} Contacts
                                <small>
                                    <NavLink to={`/account/${account_id}/contacts`}> View All</NavLink>
                                </small>
                            </Header.Content>
                        </Header>
                        <Segment color='grey-6' basic>
                            <ContactProvider>
                                <AccountContacts
                                    onPin={onPin}
                                    onReplacePinnedContact={onReplacePinnedContact}
                                    onUnpin={onUnpin}
                                    onSetPrimary={onSetPrimary}
                                    isSidePanelOpen={isSidePanelOpen}
                                />
                            </ContactProvider>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                    <Grid.Column>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}
