import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

export const MasterAccountTabs = ({ id }) => {
    return (
        <Menu borderless pointing secondary color={"primary"} stackable>
            <Menu.Item as={NavLink} name='Accounts' to={`/master_account/${id}/accounts`} />
            <Menu.Item as={NavLink} name='Notes' to={`/master_account/${id}/notes`} />
        </Menu>
    );
};
