import { RichTextEditor, TEXT_EDITOR_MODES } from 'dyl-components';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Form, Icon, ListItem } from 'semantic-ui-react';

import CategorizedTemplates from './CategorizedTemplates';

const TextTask = ({
    editorRef = React.createRef(),
    control,
    onSelectTemplate
}) => (
    <ListItem
        className='Task__description'
        icon={<Icon name='chat' color='black' />}
        description={(
            <Controller
                name='message'
                control={control}
                defaultValue=''
                render={({ field: { onChange, value, name } }) => (
                    <Form.Field
                        control={RichTextEditor}
                        label='Message'
                        onChange={(_, { value: new_value }) => { onChange({ target: { name, value: new_value } }); }}
                        value={value}
                        toolbarClassName={"message_toolbar"}
                        text_only
                        mode={TEXT_EDITOR_MODES.HTML}
                        editorRef={editorRef}
                        size='small'
                        otherControls={[
                            <CategorizedTemplates
                                onSelectTemplate={onSelectTemplate}
                                type='Text'
                            />
                        ]} />
                )} />
        )} />
)

export default TextTask;
