import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import MessageList from './subcomponents/MessageList';
import MessageContent from './subcomponents/MessageContent';

import './index.scss';
import HeaderContainer from 'shared/HeaderContainer';

class TextMessageHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {

        return (
            <Grid className='TextMessageHistory'>
                <HeaderContainer 
                    details={{
                        type: 'reports',
                        path: '/reports/sms-report',
                        title: 'Text Message History'
                    }
                    }
                />
                <Grid.Row className='TextMessageHistory__HeaderContainer'>
                    <Grid.Column>
                        <Header as='h4' className='TextMessageHistory__HeaderContainer-Header'>
                            Text Message History
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className='TextMessageHistory__BodyContainer'>
                    <Grid.Column width={5} className='TextMessageHistory__MessageListContainer'>
                        <MessageList />
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <MessageContent />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default TextMessageHistory;
