import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function pipelineReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PIPELINE_CATEGORY, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingCategory: true };
        case getNamedAction(ACTION_NAMES.PIPELINE_CATEGORY, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingCategory: false };
        case getNamedAction(ACTION_NAMES.PIPELINE_CATEGORY, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingCategory: false };

        case getNamedAction(ACTION_NAMES.PIPELINE_CATEGORY, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCategories: true, categories: [] };
        case getNamedAction(ACTION_NAMES.PIPELINE_CATEGORY, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCategories: false, categories: action.data };
        case getNamedAction(ACTION_NAMES.PIPELINE_CATEGORY, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCategories: false, categories: [] };

        case getNamedAction(ACTION_NAMES.PIPELINE_CATEGORY, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, categoryBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.PIPELINE_CATEGORY, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, categoryBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.PIPELINE_CATEGORY, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, categoryBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.PIPELINE_CATEGORY, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, categoryBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.PIPELINE_CATEGORY, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, categoryBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.PIPELINE_CATEGORY, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, categoryBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.PIPELINE_STAGE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingStage: true };
        case getNamedAction(ACTION_NAMES.PIPELINE_STAGE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingStage: false };
        case getNamedAction(ACTION_NAMES.PIPELINE_STAGE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingStage: false };

        case getNamedAction(ACTION_NAMES.PIPELINE_STAGE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, stageBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.PIPELINE_STAGE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, stageBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.PIPELINE_STAGE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, stageBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.PIPELINE_STAGE, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, stageBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.PIPELINE_STAGE, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, stageBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.PIPELINE_STAGE, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, stageBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.PIPELINE_CONVERT_CONTACT, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isConvertingContact: true };
        case getNamedAction(ACTION_NAMES.PIPELINE_CONVERT_CONTACT, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isConvertingContact: false };
        case getNamedAction(ACTION_NAMES.PIPELINE_CONVERT_CONTACT, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isConvertingContact: false };

        case getNamedAction(ACTION_NAMES.PIPELINE_TRANSITION, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isTransitioningPipeline: true };
        case getNamedAction(ACTION_NAMES.PIPELINE_TRANSITION, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isTransitioningPipeline: false };
        case getNamedAction(ACTION_NAMES.PIPELINE_TRANSITION, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isTransitioningPipeline: false };

        case getNamedAction(ACTION_NAMES.CONVERT_TO_CUSTOMER, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isConvertingToCustomer: true };
        case getNamedAction(ACTION_NAMES.CONVERT_TO_CUSTOMER, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isConvertingToCustomer: false };
        case getNamedAction(ACTION_NAMES.CONVERT_TO_CUSTOMER, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isConvertingToCustomer: false };

        case getNamedAction(ACTION_NAMES.PIPELINE_TRIAL, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingTrialAccount: true };
        case getNamedAction(ACTION_NAMES.PIPELINE_TRIAL, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingTrialAccount: false };
        case getNamedAction(ACTION_NAMES.PIPELINE_TRIAL, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingTrialAccount: false };

        case getNamedAction(ACTION_NAMES.AUTH_TRIAL, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingAuthTrialAccount: true };
        case getNamedAction(ACTION_NAMES.AUTH_TRIAL, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingAuthTrialAccount: false };
        case getNamedAction(ACTION_NAMES.AUTH_TRIAL, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingAuthTrialAccount: false };

        case getNamedAction(ACTION_NAMES.AUTH_PASSWORD, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingPassword: true };
        case getNamedAction(ACTION_NAMES.AUTH_PASSWORD, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingPassword: false };
        case getNamedAction(ACTION_NAMES.AUTH_PASSWORD, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingPassword: false };

        case getNamedAction(ACTION_NAMES.CLOSE_CUSTOMER_PIPELINE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isClosingCustomerPipeline: true };
        case getNamedAction(ACTION_NAMES.CLOSE_CUSTOMER_PIPELINE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isClosingCustomerPipeline: false };
        case getNamedAction(ACTION_NAMES.CLOSE_CUSTOMER_PIPELINE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isClosingCustomerPipeline: false };

        case getNamedAction(ACTION_NAMES.PIPELINE_END_TRIAL, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isEndingTrial: true };
        case getNamedAction(ACTION_NAMES.PIPELINE_END_TRIAL, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isEndingTrial: false };
        case getNamedAction(ACTION_NAMES.PIPELINE_END_TRIAL, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isEndingTrial: false };

        case getNamedAction(ACTION_NAMES.PIPELINE_ACCOUNT_LOST, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isSettingAccountAsLost: true };
        case getNamedAction(ACTION_NAMES.PIPELINE_ACCOUNT_LOST, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isSettingAccountAsLost: false };
        case getNamedAction(ACTION_NAMES.PIPELINE_ACCOUNT_LOST, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isSettingAccountAsLost: false };

        case getNamedAction(ACTION_NAMES.CLOSE_REASONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCloseReasons: true, close_reasons: [] };
        case getNamedAction(ACTION_NAMES.CLOSE_REASONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCloseReasons: false, close_reasons: action.data || [] };
        case getNamedAction(ACTION_NAMES.CLOSE_REASONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCloseReasons: false };

        case getNamedAction(ACTION_NAMES.CLOSE_REASONS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingCloseReasons: true };
        case getNamedAction(ACTION_NAMES.CLOSE_REASONS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingCloseReasons: false, close_reasons: action.data || [] };
        case getNamedAction(ACTION_NAMES.CLOSE_REASONS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingCloseReasons: false };

        case getNamedAction(ACTION_NAMES.REACTIVATE_TRIAL, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isReactivatingTrial: true };
        case getNamedAction(ACTION_NAMES.REACTIVATE_TRIAL, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isReactivatingTrial: false };
        case getNamedAction(ACTION_NAMES.REACTIVATE_TRIAL, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isReactivatingTrial: false };

        case getNamedAction(ACTION_NAMES.CHANGE_MODULE_PIPELINE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isChangingModulePipeline: true };
        case getNamedAction(ACTION_NAMES.CHANGE_MODULE_PIPELINE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isChangingModulePipeline: false };
        case getNamedAction(ACTION_NAMES.CHANGE_MODULE_PIPELINE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isChangingModulePipeline: false };

        default:
            return state;
    }
}

export default pipelineReducer;
