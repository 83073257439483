import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function favoritesReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.FAVORITES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingFavorites: true };
        case getNamedAction(ACTION_NAMES.FAVORITES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingFavorites: false, favorites: action.data || [] };
        case getNamedAction(ACTION_NAMES.FAVORITES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingFavorites: false };

        case getNamedAction(ACTION_NAMES.FAVORITES, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingFavorite: true }
        case getNamedAction(ACTION_NAMES.FAVORITES, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAddingFavorite: false }
        case getNamedAction(ACTION_NAMES.FAVORITES, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingFavorite: false }

        case getNamedAction(ACTION_NAMES.FAVORITES, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isRemovingFavorite: true }
        case getNamedAction(ACTION_NAMES.FAVORITES, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isRemovingFavorite: false }
        case getNamedAction(ACTION_NAMES.FAVORITES, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isRemovingFavorite: false }

        default:
            return state;
    }
}

export default favoritesReducer;
