import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

export function useBlocker(blocker, when = true) {
    const { navigator } = useContext(NavigationContext);
    useEffect(() => {
        if (!when) {
            return;
        }
        const unblock = navigator.block((tx) => {
            const saved = tx.location.state?.saved || false;
            const autoUnblockingTx = Object.assign(Object.assign({}, tx), {
                retry() {
                    unblock();
                    tx.retry();
                },
                saved
            });
            blocker(autoUnblockingTx);
        });
        return unblock;
    }, [navigator, blocker, when]);
}
