import React from 'react';
import { Form } from 'semantic-ui-react';
import { connect } from 'react-redux';

import tagCategoriesActions from 'actions/tag_categories';
import { TopPaddedControls } from 'dyl-components';
import { Notification, STATUS_TYPES } from 'dyl-components';

const AddCategorySection = ({ name, onChange, onAddCategory, onClose, isAddingCategory }) => (
    <Form loading={isAddingCategory}>
        <Form.Group>
            <Form.Input
                label='Category Name'
                name='name'
                value={name}
                onChange={onChange}
            />
            <TopPaddedControls>
                <Form.Button
                    floated='right'
                    content={'Cancel'}
                    onClick={onClose}
                />
                <Form.Button
                    primary
                    floated='right'
                    content={'Add'}
                    onClick={onAddCategory}
                />
            </TopPaddedControls>
        </Form.Group>
    </Form>
)

class AddCategorySectionContainer extends React.Component {
    state = {
        name: ''
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onAddCategory = () => {
        if (this.state.name.trim() === "") {
            Notification.alert(`The name for the tag category can't be empty`, STATUS_TYPES.ERROR, true);
        } else {
            this.props.onAddCategory({ ...this.state });
            this.setState({
                name: ''
            });
        }
    }

    render() {
        return (
            <AddCategorySection
                {...this.props}
                {...this.state}
                onAddCategory={this.onAddCategory}
                onChange={this.onChange}
            />
        )
    }
}

const mapStateProps = state => {
    return {
        isAddingCategory: state.tag_categories.isAddingTagCategory
    }
}

const mapDispatchToProps = dispatch => ({
    onAddCategory: ({ name }) => {
        dispatch(tagCategoriesActions.addTagCategory({ category: name }))
            .then(() => {
                dispatch(tagCategoriesActions.getListOfTagCategories());
            });
    }
})

export default connect(mapStateProps, mapDispatchToProps)(AddCategorySectionContainer);
