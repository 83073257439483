import React, { useContext } from 'react';
import { Table, EllipsisDropdown } from 'dyl-components';
import { Icon, Checkbox, DropdownItem } from 'semantic-ui-react';
import { PhoneUtil, StringUtils } from 'utils';
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';

import '../index.scss';
import Destinations from './Destinations';

const CAPABILTY_ICONS = {
    Voice: "fas fa-phone",
    SMS: "fas fa-phone",
    MMS: "fas fa-phone",
    Fax: "fa-solid fa-fax"
}

const CAPABILTY_TEXT = {
    SMS: "SMS",
    MMS: "MMS",
}

const Row = ({ number = {}, onOpenEditDylNumberModal }) => {
    const { number: phoneNumber, capability, type, destination, label, cid_name_replace, cnam } = number;
    const [selectedNumbers, setSelectedNumbers] = useContext(BulkActionsContext);

    const isSelected = (dyl_number_id) => {
        return selectedNumbers.includes(dyl_number_id);
    }

    const onToggleNumber = (dyl_number_id) => {
        const newSelectedNumbers = isSelected(dyl_number_id) ? selectedNumbers.filter(id => dyl_number_id !== id) : [...selectedNumbers, dyl_number_id];
        setSelectedNumbers(newSelectedNumbers);
    }

    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox checked={isSelected(phoneNumber)} onChange={() => { onToggleNumber(phoneNumber) }} />
            </Table.Cell>
            <Table.Cell>
                {PhoneUtil.formatPhoneNumber(phoneNumber)}
                <Icon className={`${CAPABILTY_ICONS["Voice"]}`} style={{ marginLeft: 50, marginRight: 10 }} />
                {CAPABILTY_TEXT[capability]}
            </Table.Cell>
            <Table.Cell>
                {StringUtils.capitalize(type)}
            </Table.Cell>
            <Table.Cell>
                <Destinations destination={destination} />
            </Table.Cell>
            <Table.Cell>
                {label}
            </Table.Cell>
            <Table.Cell>
                {cid_name_replace}
            </Table.Cell>
            <Table.Cell>
                {cnam}
            </Table.Cell>
            <Table.Cell>
                <EllipsisDropdown>
                    <DropdownItem
                        text={"Edit"}
                        icon={"fa-solid fa-pencil"}
                        onClick={() => onOpenEditDylNumberModal(phoneNumber)}
                    />
                </EllipsisDropdown>      
            </Table.Cell>
        </Table.Row>
    );
}

export default Row;


