import {
    Icon,
    Modal,
    NestedDropdown,
    Notification,
    STATUS_TYPES,
    VALIDATION_PATTERNS,
    VALIDATORS,
    generateResolver,
    yup,
} from "dyl-components";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Checkbox, Form, Popup } from "semantic-ui-react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import pbxConfigActions from "actions/pbx_config";
import { PhoneUtil } from "utils";

const Content = ({ onClose, onReload, isEditing }) => {
    const [extensionOptions, setExtensionOptions] = useState([]);

    const [destinationOptions, setDestinationOptions] = useState([]);
    const [emailBccOptions, setEmailBccOptions] = useState([]);

    const dispatch = useDispatch();

    const {
        companyVoicemail,
        conferenceRoom,
        parkingLot,
        destinations,
        unusedExtensions,
        isReadingUnusedExtensions,
        isReadingDestinations,
        soundOptions
    } = useSelector((state) => state.pbx_config);

    const defaultValues = () => ({
        type: "company_voicemail",
        label: "",
        greeting_sound_id: null,
        extension_number: null,
        vm_exit: null,
        vm_exit_parent: "",
        pin: "",
        email: [],
        display_view: false,
        sound_id: null,
    });

    const companyVoicemailDefaultValues = () => ({
        type: "company_voicemail",
        label: companyVoicemail.label || "",
        greeting_sound_id: companyVoicemail?.greeting_sound?.sound_id || null,
        extension_number: companyVoicemail.extension || null,
        vm_exit: companyVoicemail.vm_exit?.destination_id || null,
        vm_exit_parent: companyVoicemail.vm_exit?.type || "",
        pin: companyVoicemail.pin || "",
        email: companyVoicemail.email || [],
        display_view: companyVoicemail.display_view || false,
        sound_id: null,
    });

    const conferenceRoomDefaultValues = () => ({
        type: "conference_room",
        label: conferenceRoom.label || "",
        extension_number: conferenceRoom.extension || null,
        sound_id: conferenceRoom.sound?.sound_id || null,
        display_view: conferenceRoom.view_display || false,
        greeting_sound_id: null,
        vm_exit: null,
        vm_exit_parent: "",
        pin: "",
        email: [],
    });

    const parkingLotDefaultValues = () => ({
        type: "parking_lot",
        label: parkingLot.label || "",
        extension_number: parkingLot.alias || null,
        sound_id: parkingLot.moh_sound?.sound_id || null,
        display_view: parkingLot.view_display || false,
        greeting_sound_id: null,
        vm_exit: null,
        vm_exit_parent: "",
        pin: "",
        email: [],
    });

    const getDefaultValues = () => {
        switch (isEditing) {
            case "company_voicemail": {
                return companyVoicemailDefaultValues();
            }
            case "conference_room": {
                return conferenceRoomDefaultValues();
            }
            case "parking_lot": {
                return parkingLotDefaultValues();
            }
            default: {
                return defaultValues();
            }
        }
    };

    const {
        control,
        formState: { isValid, isDirty, errors },
        handleSubmit,
        watch,
        getValues,
        setError,
        setValue,
    } = useForm({
        mode: "onChange",
        defaultValues: getDefaultValues(),
        resolver: generateResolver({
            type: yup.string().required("This field is required"),
            label: VALIDATORS.TEAM_NAME().required("This field is required"),
            greeting_sound_id: yup.mixed().when("type", {
                is: (type) => type === "company_voicemail",
                then: schema => schema.required('This field is required'),
                otherwise: schema => schema.nullable(true),
            }),
            extension_number: yup.string().required("This field is required"),
            pin: VALIDATORS.PIN(),
            email: yup.array().of(VALIDATORS.EMAIL_ADDRESS()),
        }),
    });

    const watchedType = watch("type");

    const formatConferenceRoom = (data) => {
        const { label, display_view, sound_id, extension_number } = data;
        return {
            label,
            sound_id,
            view_display: display_view,
            extension: extension_number,
        };
    };

    const formatParkingLot = (data) => {
        const { label, display_view, sound_id, extension_number } = data;
        return {
            label,
            moh_sound_id: sound_id,
            view_display: display_view,
            alias: extension_number,
        };
    };

    const onAdd = async (data) => {
        const { type } = data;
        try {
            switch (type) {
                case "company_voicemail": {
                    await dispatch(
                        pbxConfigActions.createCompanyVoicemails([data])
                    );
                    break;
                }
                case "conference_room": {
                    const payload = formatConferenceRoom(data);
                    await dispatch(
                        pbxConfigActions.createConferenceRoom(payload)
                    );
                    break;
                }
                case "parking_lot": {
                    const payload = formatParkingLot(data);
                    await dispatch(pbxConfigActions.createParkingLot(payload));
                    break;
                }
                default:
                    break;
            }
            onReload();
            onClose();
            Notification.alert(
                "Company Extension added successfully!",
                STATUS_TYPES.SUCCESS
            );
        } catch (e) {
            console.log(e);
            Notification.alert(
                "Failed to add Company Extension",
                STATUS_TYPES.ERROR
            );
        }
    };

    const onEdit = async (data) => {
        try {
            const { type } = data;
            switch (type) {
                case "company_voicemail": {
                    const { id } = companyVoicemail;
                    await dispatch(
                        pbxConfigActions.updateCompanyVoicemail(id, data)
                    );
                    break;
                }
                case "conference_room": {
                    const { id } = conferenceRoom;
                    const payload = formatConferenceRoom(data);
                    await dispatch(
                        pbxConfigActions.updateConferenceRoom(id, payload)
                    );
                    break;
                }
                case "parking_lot": {
                    const { id } = parkingLot;
                    const payload = formatParkingLot(data);
                    await dispatch(
                        pbxConfigActions.updateParkingLot(id, payload)
                    );
                    break;
                }
                default:
                    break;
            }
            onReload();
            onClose();
            Notification.alert(
                "Company Extension updated successfully!",
                STATUS_TYPES.SUCCESS
            );
        } catch (e) {
            console.log(e);
            Notification.alert(
                "Failed to update Company Extension",
                STATUS_TYPES.ERROR
            );
        }
    };

    const onCheckDuplicatedName = async (originalLabel, isEditing) => {
        const label = getValues("label");
        try {
            if (!isEditing || (!!isEditing && originalLabel !== label)) {
                const isDuplicated = await dispatch(
                    pbxConfigActions.isDuplicatedName({
                        label,
                        type:
                            watchedType === "company_voicemail"
                                ? "vmbox"
                                : watchedType,
                    })
                );
                if (isDuplicated) {
                    setError("label", {
                        type: "unique",
                        message: "Name already exists!",
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onLabelBlur = () => {
        switch (isEditing) {
            case "company_voicemail": {
                onCheckDuplicatedName(
                    companyVoicemail?.label || "",
                    !!isEditing
                );
                break;
            }
            case "conference_room": {
                onCheckDuplicatedName(conferenceRoom?.label || "", !!isEditing);
                break;
            }
            case "parking_lot": {
                onCheckDuplicatedName(parkingLot?.label || "", !!isEditing);
                break;
            }
            default:
                onCheckDuplicatedName("", false);
                break;
        }
    };

    const handleAdditionBcc = (_, { value }) => {
        setEmailBccOptions((prevOptions) => [
            {
                text: value,
                value: value,
                key: value,
                invalid: !VALIDATION_PATTERNS.email.test(value),
            },
            ...prevOptions,
        ]);
    };

    const renderLabel = (label) => {
        return {
            ...(!label.invalid ? {} : { className: "CompanyExtension--error" }),
            content: label.text,
        };
    };

    const getEditedExtension = (type) => {
        switch (type) {
            case "company_voicemail": {
                return companyVoicemail.extension;
            }
            case "conference_room": {
                return conferenceRoom.extension;
            }
            case "parking_lot": {
                return parkingLot.alias;
            }
            default:
                return null;
        }
    };

    useEffect(() => {
        if (destinations) {
            const destinationsAux = [...PhoneUtil.DESTINATION_TYPES_OPTIONS];
            const destinationOptionsAux = destinationsAux.map((destination) => {
                const { key } = destination;
                const options =
                    destinations[key]?.map(({ destination_id, label }) => ({
                        key: destination_id,
                        value: destination_id,
                        text: label,
                    })) || [];
                return { ...destination, options };
            });
            setDestinationOptions(destinationOptionsAux);
        }
    }, [destinations]);

    useEffect(() => {
        let lowerLimit = 0;
        let higherLimit = 0;
        switch (watchedType) {
            case "conference_room": {
                lowerLimit = 700;
                higherLimit = 799;
                break;
            }
            case "parking_lot": {
                lowerLimit = 600;
                higherLimit = 699;
                break;
            }
            default: {
                lowerLimit = 300;
                higherLimit = 399;
                break;
            }
        }
        const extensions = unusedExtensions
            .filter(
                (extension) =>
                    Number(extension) >= lowerLimit &&
                    Number(extension) <= higherLimit
            )
            .map((extension) => ({
                key: extension,
                value: extension,
                text: extension,
            }));
        const defaultExtension = extensions?.[0]?.value || null;
        if (isEditing) {
            const extension = getEditedExtension(isEditing);
            const fullExtensions = PhoneUtil.insertExtension(
                extension,
                extensions
            );
            setExtensionOptions(fullExtensions);
        } else {
            setValue("extension_number", defaultExtension);
            setExtensionOptions(extensions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unusedExtensions]);

    useEffect(() => {
        dispatch(pbxConfigActions.getDestinations());
    }, [dispatch]);

    useEffect(() => {
        dispatch(
            pbxConfigActions.unusedExtensions({
                type: watchedType ? watchedType : "company_voicemail",
            })
        );
        dispatch(pbxConfigActions.getSoundOptions({
                category: watchedType ? watchedType === "conference_room" || watchedType === "parking_lot" ? "music" : watchedType : "company_voicemail"
            })
        );
    }, [watchedType, dispatch]);

    useEffect(() => {
        if (isEditing === "company_voicemail" && !!companyVoicemail) {
            const { email } = companyVoicemail;
            if (email && email.length > 0) {
                const emailOptions = email.map((mail) => ({
                    key: mail,
                    value: mail,
                    text: mail,
                    invalid: false,
                }));
                setEmailBccOptions(emailOptions);
            }
        }
    }, [isEditing, companyVoicemail]);

    return (
        <>
            <Modal.Header>
                {!!isEditing ? "Edit " : "Create "} Company Extension
            </Modal.Header>
            <Modal.Content>
                <Form noValidate loading={false}>
                    <div className="CompanyExtensionModal__form">
                        <div className="CompanyExtensionModal__controllerContainer">
                            <Controller
                                name="type"
                                control={control}
                                style={{flex: 1}}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => {
                                    return !!isEditing ? (
                                        <Form.Input
                                            name={name}
                                            value={PhoneUtil.COMPANY_EXTENSION_TYPES.find((extension_type) => value === extension_type.value).text}
                                            label="Type"
                                            required
                                            readOnly
                                        />
                                    ) : (
                                        <Form.Select
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                            placeholder='Select extension type'
                                            options={PhoneUtil.COMPANY_EXTENSION_TYPES}
                                            label="Type"
                                            required
                                            error={error?.message}
                                            readOnly={!!isEditing}
                                        />
                                    )
                                }}
                            />
                            <Controller
                                name="label"
                                control={control}
                                style={{ flex: 1 }}
                                render={({
                                    field: { name, value, onChange },
                                    fieldState: { error },
                                }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => {
                                            onChange({
                                                target: { name, value },
                                            });
                                        }}
                                        label="Name"
                                        placeholder="Type name"
                                        required
                                        error={error?.message}
                                        onBlur={onLabelBlur}
                                    />
                                )}
                            />
                        </div>
                        {watchedType === "company_voicemail" && (
                            <div
                                className="CompanyExtensionModal__controllerContainer"
                                style={{ marginBottom: 14 }}
                            >
                                <Controller
                                    name="greeting_sound_id"
                                    control={control}
                                    style={{ flex: 1 }}
                                    render={({
                                        field: { name, value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Select
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => {
                                                onChange({
                                                    target: { name, value },
                                                });
                                            }}
                                            placeholder="Select VM greeting"
                                            options={soundOptions?.company_voicemail?.map(option => ({ key: option.id, value: option.id, text: option.name}))}
                                            label="VM Greeting"
                                            required
                                            error={error?.message}
                                        />
                                    )}
                                />
                            </div>
                        )}
                        <div className="CompanyExtensionModal__controllerContainer">
                            <Controller
                                name="extension_number"
                                control={control}
                                style={{flex: 1}}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => {
                                    return !!isEditing ? (
                                        <Form.Input
                                            name={name}
                                            value={value}
                                            label="Extension"
                                            required
                                            readOnly
                                        />  
                                    ) : (
                                        <Form.Select
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                            placeholder='Select extension'
                                            options={extensionOptions}
                                            label="Extension"
                                            required
                                            error={error?.message}
                                            loading={isReadingUnusedExtensions}
                                        />
                                    )
                                    
                                }}
                            />
                            {watchedType !== "company_voicemail" && (
                                <Controller
                                    name="sound_id"
                                    control={control}
                                    style={{ flex: 1 }}
                                    render={({
                                        field: { name, value, onChange },
                                    }) => (
                                        <Form.Select
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => {
                                                onChange({
                                                    target: { name, value },
                                                });
                                            }}
                                            placeholder="Select music on hold"
                                            options={soundOptions?.music?.map(option => ({ key: option.id, value: option.id, text: option.name}))}
                                            label="Music On Hold"
                                        />
                                    )}
                                />
                            )}
                            {watchedType === "company_voicemail" && (
                                <Controller
                                    name="vm_exit_parent"
                                    control={control}
                                    render={({
                                        field: {
                                            name: parentName,
                                            value: parentValue,
                                            onChange: onParentChange,
                                        },
                                    }) => (
                                        <Controller
                                            name="vm_exit"
                                            control={control}
                                            render={({ field }) => {
                                                const {
                                                    name: childName,
                                                    value: childValue,
                                                    onChange: onChildChange,
                                                } = field;
                                                return (
                                                    <Form.Field
                                                        control={NestedDropdown}
                                                        child_value={childValue}
                                                        parent_value={
                                                            parentValue
                                                        }
                                                        loading={
                                                            isReadingDestinations
                                                        }
                                                        nested_options={
                                                            destinationOptions
                                                        }
                                                        onChange={(
                                                            _,
                                                            {
                                                                parent_value,
                                                                child_value,
                                                            }
                                                        ) => {
                                                            onParentChange({
                                                                target: {
                                                                    name: parentName,
                                                                    value: parent_value,
                                                                },
                                                            });
                                                            onChildChange({
                                                                target: {
                                                                    name: childName,
                                                                    value: child_value,
                                                                },
                                                            });
                                                        }}
                                                        placeholder="Select destination"
                                                        display_parent
                                                        selection
                                                        label={{
                                                            children: (
                                                                <span>
                                                                    VM Exit
                                                                    <Popup
                                                                        trigger={
                                                                            <Icon
                                                                                style={{
                                                                                    float: "right",
                                                                                    marginTop:
                                                                                        "0.25em",
                                                                                }}
                                                                                name="fas fa-info-circle"
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        inverted
                                                                        content="VM Exit."
                                                                    />
                                                                </span>
                                                            ),
                                                        }}
                                                        pointing="top"
                                                        className="CompanyExtensions__ruleField"
                                                    />
                                                );
                                            }}
                                        />
                                    )}
                                />
                            )}
                        </div>
                        {watchedType === "company_voicemail" && (
                            <div className="CompanyExtensionModal__controllerContainer">
                                <Controller
                                    name="pin"
                                    control={control}
                                    style={{ flex: 1 }}
                                    render={({
                                        field: { name, value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Input
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => {
                                                onChange({
                                                    target: { name, value },
                                                });
                                            }}
                                            label="PIN"
                                            placeholder="Type PIN"
                                            error={error?.message}
                                        />
                                    )}
                                />
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({
                                        field: { onChange, value, name },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Dropdown
                                            className="CompanyExtensionsModal__emailField"
                                            label="Email"
                                            options={emailBccOptions}
                                            onChange={(
                                                _,
                                                { value: selected }
                                            ) => {
                                                onChange({
                                                    target: {
                                                        name: name,
                                                        value: selected,
                                                    },
                                                });
                                            }}
                                            selection
                                            fluid
                                            multiple
                                            allowAdditions
                                            search
                                            onAddItem={handleAdditionBcc}
                                            placeholder="Type email"
                                            value={value}
                                            renderLabel={renderLabel}
                                            error={
                                                error?.length
                                                    ? "Please remove invalid emails"
                                                    : false
                                            }
                                        />
                                    )}
                                />
                            </div>
                        )}
                        <Controller
                            name="display_view"
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Checkbox
                                    checked={value}
                                    onChange={(_, { name, checked }) => {
                                        onChange({
                                            target: { name, value: checked },
                                        });
                                    }}
                                    name={name}
                                    label="Display on Office View"
                                    toggle
                                />
                            )}
                        />
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions
                hasSaveButton
                onSave={handleSubmit(!!isEditing ? onEdit : onAdd)}
                saveDisabled={
                    !isValid || !isDirty || errors?.label?.type === "unique"
                }
                saveOptions={{ loading: false }}
            />
        </>
    );
};

const CompanyExtensionModal = ({ open, onClose, onReload, isEditing }) => {
    return (
        <Modal open={open} onClose={onClose} size={"small"}>
            <Content
                onClose={onClose}
                onReload={onReload}
                isEditing={isEditing}
            />
        </Modal>
    );
};

export default CompanyExtensionModal;
