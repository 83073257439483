import React from 'react';
import { Button, Form, Grid, Header, Popup, Segment } from 'semantic-ui-react';
import { TimeUnitToggler, DateTimeUtils } from 'dyl-components';

import { connect } from 'react-redux';

import CustomRangeDateModal from 'shared/modals/CustomRangeDateModal';

import FunnelChart from './FunnelChart';
import ConversionChart from './ConversionChart';

import teamDashboardActions from 'actions/team_dashboard';
import leadsActions from 'actions/leads';
import opportunitiesActions from 'actions/opportunities';
import teamsActions from 'actions/teams';
import contactsActions from 'actions/contacts';

const SalesManagerDashboard = ({
    funnel_data,
    account_stage,
    onChangeFunnelFilter,
    onOpenCustomDateRange,
    onToggleTimeUnit,
    isReadingFunnelData,
    funnel_team_ids,
    onFilterFunnelData,

    conversion_data,
    time_interval,
    onChangeConversionFilter,
    isReadingConversionData,
    conversion_team_ids,
    onFilterConversionData,

    team_options,
    areTimeUnitOptionsOpen,
    onOpenTimeUnitOptions,
    onCloseTimeUnitOptions
}) => (
    <Grid>
        <Grid.Row columns='equal'>
            <Grid.Column width={6}>
                <Segment loading={isReadingFunnelData}>
                    <div style={{ display: 'inline-flex', paddingBottom: 25 }}>
                        <Header as='h2' style={{ marginRight: 40 }}>
                            Pipeline Stages
                        </Header>
                        <Button primary onClick={onFilterFunnelData}>
                            Filter
                        </Button>
                        <Popup
                            position='top right'
                            flowing
                            trigger={<Button primary icon='calendar outline plus' />}
                            content={(
                                <Grid onClick={onCloseTimeUnitOptions} columns={'equal'}>
                                    <Grid.Column>
                                        <TimeUnitToggler onClick={() => { onToggleTimeUnit('Last Two Weeks') }} content='Last Two Weeks' />
                                        <TimeUnitToggler onClick={() => { onToggleTimeUnit('This Month') }} content='This Month' />
                                        <TimeUnitToggler onClick={() => { onToggleTimeUnit('Year to Date') }} content='Year to Date' />
                                        <TimeUnitToggler content='Custom Range' onClick={onOpenCustomDateRange} />
                                    </Grid.Column>
                                </Grid>
                            )}
                            on='click'
                            onOpen={onOpenTimeUnitOptions}
                            open={areTimeUnitOptionsOpen}
                            onClose={onCloseTimeUnitOptions}
                        />
                    </div>

                    <Form size='tiny'>
                        <Form.Group widths='equal'>
                            <Form.Dropdown
                                label="Account Stage"
                                options={[
                                    {
                                        key: "lead", value: "lead", text: "Lead"
                                    },
                                    {
                                        key: "opportunity", value: "opportunity", text: "Opportunity"
                                    }
                                ]}
                                value={account_stage}
                                name="account_stage"
                                onChange={onChangeFunnelFilter}
                            />
                            <Form.Dropdown
                                label="Teams"
                                options={team_options}
                                value={funnel_team_ids}
                                name="team_ids"
                                onChange={onChangeFunnelFilter}
                                multiple
                            />

                        </Form.Group>
                    </Form>
                    <div style={{ marginLeft: isReadingFunnelData ? 0 : 46 }}>
                        <FunnelChart
                            totals={funnel_data}
                            isReading={isReadingFunnelData}
                        />
                    </div>
                </Segment>
            </Grid.Column>
            <Grid.Column stretched>
                <Segment loading={isReadingConversionData}>
                    <div style={{ display: 'inline-flex', paddingBottom: 25 }}>
                        <Header as='h2' style={{ marginRight: 40 }}>
                            Pipelines
                        </Header>
                        <Button primary size='small' onClick={onFilterConversionData}>
                            Filter
                        </Button>
                    </div>

                    <Form size='tiny'>
                        <Form.Group widths='equal'>
                            <Form.Dropdown
                                label="Time Interval"
                                options={[
                                    {
                                        key: "year", value: "year", text: "Year"
                                    },
                                    {
                                        key: "month", value: "month", text: "Month"
                                    },
                                    {
                                        key: "week", value: "week", text: "Week"
                                    },
                                    {
                                        key: "day", value: "day", text: "Day"
                                    }
                                ]}
                                value={time_interval}
                                name="time_interval"
                                onChange={onChangeConversionFilter}
                            />
                            <Form.Dropdown
                                label="Teams"
                                options={team_options}
                                value={conversion_team_ids}
                                name="team_ids"
                                onChange={onChangeConversionFilter}
                                multiple
                            />

                        </Form.Group>
                    </Form>
                    <div style={{ marginTop: isReadingConversionData ? 0 : 75 }}>
                        <ConversionChart
                            conversion_data={conversion_data}
                            isReading={isReadingConversionData}
                            isMonthInterval={time_interval === "month"}
                        />
                    </div>
                </Segment>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

class SalesRepresentativeDashboardContainer extends React.Component {
    state = {
        isCustomDateRangeOpen: false,
        dateRange: '',
        areTimeUnitOptionsOpen: false
    }

    componentDidMount() {
        this.props.onReadTeams();
        this.props.onReadFunnelData({ account_stage: "lead", team_ids: [], start: DateTimeUtils.getStartOfTheMonth(), end: DateTimeUtils.getCurrentDate(DateTimeUtils.DATE_FORMAT, true) })
        this.props.onReadConversionData({ team_ids: [], time_interval: 'month' });
        this.props.onReadRecentContacts({ limit: 5, page: 1, team_id: this.props.team_id, assigned_only: true });
        this.props.onReadLeads({ limit: 5, page: 1, team_id: this.props.team_id, assigned_only: true });
        this.props.onReadOpportunities({ limit: 5, page: 1, team_id: this.props.team_id, assigned_only: true });
    }

    onToggleTimeUnit = (unit) => {
        let start = DateTimeUtils.getLast(2, 'week');
        let end = DateTimeUtils.getCurrentDate(DateTimeUtils.DATE_FORMAT, true);
        switch (unit.toLowerCase()) {
            case 'last two weeks':
                start = DateTimeUtils.getLast(2, 'week');
                break;
            case 'this month':
                start = DateTimeUtils.getStartOfTheMonth()
                break;
            case 'year to date':
                start = DateTimeUtils.getStartOfYear();
                break;
            default:
                break;
        }
        this.props.onReadFunnelData({ ...this.props.funnelQueryParameters, start, end });
    }

    onOpenCustomDateRange = () => {
        this.setState({ isCustomDateRangeOpen: true });
    }

    onCloseCustomDateRange = () => {
        this.setState({ isCustomDateRangeOpen: false, dateRange: '' });
    }

    onOpenTimeUnitOptions = () => {
        this.setState({ areTimeUnitOptionsOpen: true });
    }

    onCloseTimeUnitOptions = () => {
        this.setState({ areTimeUnitOptionsOpen: false });
    }

    onDateRangeChange = (_, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    }

    onReadFunnelWithCustomRange = () => {
        const dateData = this.state.dateRange.split(' - ');

        const queryParameters = {
            ...this.props.funnelQueryParameters,
            start: '',
            end: ''
        }

        dateData[0] === "" ? delete queryParameters.start :
            queryParameters.start = DateTimeUtils.getUnixTime(`${dateData[0]} 0:00:00`);

        dateData[1] === "" ? delete queryParameters.end :
            queryParameters.end = DateTimeUtils.getUnixTime(`${dateData[1]} 23:59:59`);

        this.onCloseCustomDateRange();
        if (dateData.length > 1) {
            this.props.onReadFunnelData(queryParameters);
        }
    }

    onChangeConversionFilter = (_, { name, value }) => {
        this.setState(prevState => ({
            conversionQueryParameters: { ...this.props.conversionQueryParameters, ...prevState.conversionQueryParameters, [name]: value }
        }));
    }

    onFilterConversionData = () => {
        this.props.onReadConversionData({
            ...this.props.conversionQueryParameters,
            ...this.state.conversionQueryParameters
        });
    }

    onChangeFunnelFilter = (_, { name, value }) => {
        this.setState(prevState => ({
            funnelQueryParameters: { ...this.props.funnelQueryParameters, ...prevState.funnelQueryParameters, [name]: value }
        }));
    }

    onFilterFunnelData = () => {
        this.props.onReadFunnelData({
            ...this.props.funnelQueryParameters,
            ...this.state.funnelQueryParameters
        })
    }

    render() {
        let { conversionQueryParameters, funnelQueryParameters } = this.props;
        conversionQueryParameters = { ...conversionQueryParameters, ...this.state.conversionQueryParameters };
        funnelQueryParameters = { ...funnelQueryParameters, ...this.state.funnelQueryParameters };
        return (
            <React.Fragment>
                <SalesManagerDashboard
                    account_stage={funnelQueryParameters.account_stage}
                    funnel_data={this.props.funnel_data}
                    onChangeFunnelFilter={this.onChangeFunnelFilter}
                    onOpenCustomDateRange={this.onOpenCustomDateRange}
                    onToggleTimeUnit={this.onToggleTimeUnit}
                    isReadingFunnelData={this.props.isReadingFunnelData}
                    team_options={this.props.team_options.filter(({ users, hide_view, deleted }) => !hide_view && !deleted && users.count > 0).map(({ id, name }) => ({
                        key: id,
                        value: id,
                        text: name
                    }))}
                    funnel_team_ids={funnelQueryParameters.team_ids}
                    onFilterFunnelData={this.onFilterFunnelData}

                    conversion_data={this.props.conversion_data}
                    onChangeConversionFilter={this.onChangeConversionFilter}
                    time_interval={conversionQueryParameters.time_interval}
                    isReadingConversionData={this.props.isReadingConversionData}
                    conversion_team_ids={conversionQueryParameters.team_ids}
                    onFilterConversionData={this.onFilterConversionData}

                    areTimeUnitOptionsOpen={this.state.areTimeUnitOptionsOpen}
                    onCloseTimeUnitOptions={this.onCloseTimeUnitOptions}
                    onOpenTimeUnitOptions={this.onOpenTimeUnitOptions}
                />
                <CustomRangeDateModal
                    open={this.state.isCustomDateRangeOpen}
                    onClose={this.onCloseCustomDateRange}
                    onDateRangeChange={this.onDateRangeChange}
                    dateRange={this.state.dateRange}
                    getData={this.onReadFunnelWithCustomRange}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    funnelQueryParameters: state.team_dashboard.funnelQueryParameters,
    conversionQueryParameters: state.team_dashboard.conversionQueryParameters,

    funnel_data: state.team_dashboard.funnel_data,
    conversion_data: state.team_dashboard.conversion_data,

    isReadingFunnelData: state.team_dashboard.isReadingFunnelData,
    isReadingConversionData: state.team_dashboard.isReadingConversionData,
    team_options: state.teams.teams
});

const mapDispatchToProps = dispatch => ({
    onReadFunnelData: (queryParameters) => {
        return dispatch(teamDashboardActions.getFunnelData(queryParameters));
    },
    onReadConversionData: (queryParameters) => {
        return dispatch(teamDashboardActions.getConversionData(queryParameters));
    },
    onReadRecentContacts: (queryParameters) => {
        dispatch(contactsActions.readRecentContacts(queryParameters));
    },
    onReadLeads: (queryParameters) => {
        dispatch(leadsActions.readLeads(queryParameters));
    },
    onReadOpportunities: (queryParameters) => {
        dispatch(opportunitiesActions.readOpportunities(queryParameters));
    },
    onReadTeams: () => {
        return dispatch(teamsActions.getTeams());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesRepresentativeDashboardContainer);
