import React from 'react';
import { Dropdown, Form, Icon } from 'semantic-ui-react';
import { DividingHeader, RichTextEditor, STATUS_TYPES, Notification } from 'dyl-components';
import { connect } from 'react-redux';

import userActions from 'actions/user';

import './index.scss';


const MOCK_SIGNATURE_TEMPLATES = [
    {
        key: 'Default Template 1',
        value: `
            <div>
                <p><strong>[Type in your name]</strong></p>
                <p>[Type in your position] at [Type in your company]</p>
                <p>
                    <span style="color: blue;">e:</span> [Type in email] |
                    <span style="color: blue;">p:</span> [Type in phone number] |
                    <span style="color: blue;">f:</span> [Type in fax number]
                </p>
                <p>
                    <span style="color: blue;">a:</span> [Type in address]
                </p>
                <p>
                    <span style="color: blue;">w:</span> [Type in website]
                </p>
            </div>
        `,
        text: 'DYL Template 1'
    },
    {
        key: 'Default Template 2',
        value: `
            <div>
                <p><strong>[Type in your Company Name]</strong></p>
                <p>
                    <span style="color: blue;">e:</span> [Type in email] |
                    <span style="color: blue;">p:</span> [Type in phone number] |
                    <span style="color: blue;">f:</span> [Type in fax number]
                </p>
                <p>
                    <span style="color: blue;">a:</span> [Type in address]
                </p>
                <p>
                    <span style="color: blue;">w:</span> [Type in website]
                </p>
            </div>
        `,
        text: 'DYL Template 2'
    }
]

const SIGNATURE_PREFERENCES = [
    { key: 1, value: 1, text: 'Use my signature' },
    { key: 0, value: 0, text: 'Use no signature' },
];

const EmailPreferences = React.forwardRef((({
    onChange,
    signature_body,
    onSelectTemplate,

    useSignatureInNewEmails,
    useSignatureInReplies,
    onSaveChanges,
    isReadingSignatures,
    isUpdatingSignature,
    saved,
    hasChanges,
    selectedTemplate
}, editorRef) => (
    <React.Fragment>
        <DividingHeader noline content='Default Signature' compact />
        <Form loading={isReadingSignatures || isUpdatingSignature}>
            <Form.Field
                control={RichTextEditor}
                label="My Signature"
                editorRef={editorRef}
                name='signature_body'
                onChange={onChange}
                value={signature_body}
                toolbarClassName={"signature_toolbar"}
                otherControls={[
                    <Dropdown
                        name='template'
                        options={MOCK_SIGNATURE_TEMPLATES}
                        selectOnBlur={false}
                        clearable
                        onChange={onSelectTemplate}
                        placeholder="Signature Templates"
                        basic
                        className='Signature__template-selector ql-custom-dropdown'
                        value={selectedTemplate}
                        icon={<Icon name={selectedTemplate ? 'times' : 'sort'} />}
                    />
                ]}
            />
            <Form.Group>
                <Form.Select
                    label="For New Emails"
                    options={SIGNATURE_PREFERENCES}
                    width={5}
                    onChange={onChange}
                    value={useSignatureInNewEmails}
                    name="useSignatureInNewEmails"
                />
                <Form.Select
                    label="For Email Reply/Forward"
                    options={SIGNATURE_PREFERENCES}
                    width={5}
                    onChange={onChange}
                    value={useSignatureInReplies}
                    name="useSignatureInReplies"
                />
            </Form.Group>
            <Form.Button disabled={saved ? false : !hasChanges} onClick={onSaveChanges} primary>
                {!saved ? (
                    !isUpdatingSignature ? 'Save Changes' : 'Saving...'
                ) : <React.Fragment><Icon name='check circle' /> Saved</React.Fragment>}
            </Form.Button>
        </Form>
    </React.Fragment>
)));

class EmailPreferencesContainer extends React.Component {
    state = {
        signature_body: '',
        useSignatureInNewEmails: 0,
        useSignatureInReplies: 0,
        saved: false,
        selectedTemplate: null
    }

    componentDidMount() {
        const user_id = this.props.user_id;
        Promise.all([
            this.props.onReadSignatures(user_id),
            this.props.onReadSignaturePreferences(user_id)
        ]).then(() => {
            this.setState({ 
                signature_body: this.props.signature_body,
                useSignatureInNewEmails: this.props.useSignatureInNewEmails,
                useSignatureInReplies: this.props.useSignatureInReplies
            })
        });
    }

    editorRef = React.createRef();

    onChange = (_, { name, value }) => {
        this.setState({
            [name]: value,
            saved: false
        })
    }

    onSelectTemplate = (_, { value }) => {
        this.setState({
            signature_body: value || this.props.signature_body,
            selectedTemplate: value
        })
    }

    onSaveChanges = async () => {
        try {
            const payload = {
                content: this.state.signature_body || '',
                content_type: 'html',
                label: 'my_signature',
                main: true
            };

            const { useSignatureInNewEmails: new_email, useSignatureInReplies: reply_forward_email } = this.state;

            if (this.props.signature_id) {
                await Promise.all([
                    this.props.onUpdateSignature(this.props.user_id, this.props.signature_id, payload),
                    this.props.onUpdateSignaturePreferences(this.props.user_id, {
                        new_email: new_email ? this.props.signature_id : null,
                        reply_forward_email: reply_forward_email ? this.props.signature_id : null
                    })
                ])
            } else {
                const { id } = await this.props.onCreateSignature(this.props.user_id, payload);
                await this.props.onUpdateSignaturePreferences(this.props.user_id, {
                    new_email: new_email ? id : null,
                    reply_forward_email: reply_forward_email ? id : null
                })
            }
            Notification.alert('Successfully updated signature', STATUS_TYPES.SUCCESS, true);
            await Promise.all([
                this.props.onReadSignatures(this.props.user_id),
                this.props.onReadSignaturePreferences(this.props.user_id)
            ]);
            this.setState({ 
                signature_body: this.props.signature_body, 
                useSignatureInNewEmails: this.props.useSignatureInNewEmails,
                useSignatureInReplies: this.props.useSignatureInReplies,

                saved: true,
                selectedTemplate: null
            });
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update signature', STATUS_TYPES.ERROR, true);
        }

    }

    hasChanges = () => {
        return (
            this.props.signature_body !== this.state.signature_body ||
            this.props.useSignatureInNewEmails !== this.state.useSignatureInNewEmails ||
            this.props.useSignatureInReplies !== this.state.useSignatureInReplies
        );
    }

    render() {
        return (
            <EmailPreferences
                onChange={this.onChange}
                onSelectTemplate={this.onSelectTemplate}
                signature_body={this.state.signature_body}
                useSignatureInReplies={this.state.useSignatureInReplies}
                useSignatureInNewEmails={this.state.useSignatureInNewEmails}

                ref={this.editorRef}
                onSaveChanges={this.onSaveChanges}

                isUpdatingSignature={this.props.isUpdatingSignature}
                isReadingSignatures={this.props.isReadingSignatures}

                saved={this.state.saved}
                hasChanges={this.hasChanges()}
                selectedTemplate={this.state.selectedTemplate}
            />
        )
    }
}

const mapStateToProps = state => {
    const { signatures, signature_preferences } = state.user;
    const { user_id } = state.auth;
    const { isReadingSignatures, signatureBeingUpdated, isCreatingSignature, isReadingSignaturePreferences, isUpdatingSignaturePreferences } = state.user;
    const { id: signature_id, content, content_type, label, main } = signatures[0] || {
        id: 0,
        content: '',
        content_type: 'html',
        label: '',
        main: false
    }
    const { new_email, reply_forward_email } = signature_preferences

    return {
        isReadingSignatures: isReadingSignatures || isReadingSignaturePreferences,
        isUpdatingSignature: isCreatingSignature || signatureBeingUpdated || isUpdatingSignaturePreferences,
        signature_id,
        signature_body: content || '',
        content_type,
        label,
        main,
        user_id,

        useSignatureInNewEmails: new_email ? 1 : 0,
        useSignatureInReplies: reply_forward_email ? 1 : 0
    }
}

const mapDispatchToProps = dispatch => ({
    onReadSignatures: (user_id) => {
        return dispatch(userActions.readSignatures({ user_id }))
    },
    onCreateSignature: (user_id, payload) => {
        return dispatch(userActions.createSignature(payload, { user_id }));
    },
    onUpdateSignature: (user_id, signature_id, payload) => {
        return dispatch(userActions.updateSignature(signature_id, payload, { user_id }));
    },

    onReadSignaturePreferences: (user_id) => {
        return dispatch(userActions.readSignaturePreferences({ user_id }));
    },
    onUpdateSignaturePreferences: (user_id, payload) => {
        return dispatch(userActions.updateSignaturePreferences(null, payload, { user_id }));
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailPreferencesContainer);
