import React, { useState } from 'react';
import { Table as SemanticTable } from 'semantic-ui-react';
import { ClearableCheckboxDropdown, Icon, Table, TimestampFilters } from 'dyl-components';
import { StringUtils } from 'utils';

const SortActions = ({ isFilterSelected, direction = false, onToggleSort = () => { } }) => {
    return (
        <div
            onClick={onToggleSort}
            style={{ display: "inline-flex", cursor: "pointer" }}
        >
            <Icon
                name="fa-solid fa-arrow-down-long"
                style={{
                    marginRight: -7,
                    opacity: isFilterSelected && !direction ? "100%" : "50%",
                }}
            />
            <Icon
                name="fa-solid fa-arrow-up-long"
                style={{ opacity: isFilterSelected && direction ? "100%" : "50%" }}
            />
        </div>
    );
};

const QuoteTableHeader = ({ useRangeCalendar, onToggleTimeUnit, getTimeData, sortType, sortDirection, onToggleSort, onFilter, filters }) => {
    const [areTimeUnitOptionsOpen, setTimeUnitOptionsOpen] = useState(false);

    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        dateRange,
        setDateRange,
    } = useRangeCalendar;

    return (
        <Table.CollapsibleRow isHeader>
            <SemanticTable.HeaderCell className='QuotesTable__MediumFixedWidth' width={3}>
                <span style={{ marginRight: 5 }}>Created</span>
                <TimestampFilters
                    timestamp={timestamp}
                    onToggleTimeUnit={(timestamp) => {
                        onToggleTimeUnit(timestamp, setTimestamp, setTimeUnitOptionsOpen);
                    }}
                    dateRange={dateRange}
                    onDateRangeChange={(_, { value }) => {
                        setDateRange(value);
                    }}
                    getData={() => {
                        getTimeData(setCustomDateRangeOpen, dateRange);
                    }}
                    areTimeUnitOptionsOpen={areTimeUnitOptionsOpen}
                    onOpenTimeUnitOptions={() => {
                        setTimeUnitOptionsOpen(true);
                    }}
                    onCloseTimeUnitOptions={() => {
                        setTimeUnitOptionsOpen(false);
                    }}
                    isCustomDateRangeOpen={isCustomDateRangeOpen}
                    onOpenCustomDateRange={() => {
                        setCustomDateRangeOpen(true);
                    }}
                    onCloseCustomDateRange={() => {
                        setCustomDateRangeOpen(false);
                    }}
                />
                <SortActions
                    isFilterSelected={sortType === 'created_on_sort'}
                    direction={sortDirection}
                    onToggleSort={() => onToggleSort('created_on_sort')}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='QuotesTable__MediumFixedWidth' width={2}>
                <span style={{ marginRight: 5 }}>Last Modified</span>
                <SortActions
                    isFilterSelected={sortType === 'last_modified_sort'}
                    direction={sortDirection}
                    onToggleSort={() => onToggleSort('last_modified_sort')}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='QuotesTable__MediumFixedWidth' width={2}>
                <span style={{ marginRight: 5 }}>Expires On</span>
                <SortActions
                    isFilterSelected={sortType === 'expires_on_sort'}
                    direction={sortDirection}
                    onToggleSort={() => onToggleSort('expires_on_sort')}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='QuotesTable__MediumFixedWidth' width={2}>
                Created By
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='QuotesTable__MediumFixedWidth' width={2}>
                Last Modified By
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='QuotesTable__MediumFixedWidth' width={3}>
                ID
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='QuotesTable__StatusColumn' width={3}>
                <ClearableCheckboxDropdown
                    text="Status"
                    name="status"
                    onChange={(_, { value }) => {
                        onFilter(_, { name: "status", value });
                    }}
                    onRead={() => {}}
                    value={filters.status || []}
                    options={[
                        ...["draft", "sent", "approved"].map((status) => ({
                            key: status,
                            value: status,
                            text: StringUtils.capitalize(status),
                            options: [],
                        })),
                        {
                            key: "rejected",
                            value: "rejected",
                            text: "Rejected",
                            options: [
                                "quote-expired",
                                "product-changed",
                                "price-too-high",
                                "did-not-meet-needs",
                            ].map((status) => ({
                                key: status,
                                value: `rejected/${status}`,
                                text: StringUtils.capitalize(
                                    status.split("-").join(" ")
                                ),
                            })),
                        },
                    ]}
                    closeOnLoad
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='QuotesTable__MediumFixedWidth' width={2}>
                # Product/Bundles
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='QuotesTable__MediumFixedWidth' width={2}>
                Subtotal
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='QuotesTable__MediumFixedWidth' width={2}>
                One-Time Amount
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell className='QuotesTable__MediumFixedWidth' width={2}>
                Recurring Amount
            </SemanticTable.HeaderCell>
        </Table.CollapsibleRow>
    );
}

export default QuoteTableHeader;
