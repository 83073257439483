import React, { useState } from "react";
import { Checkbox, DropdownItem, Ref, Icon } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import {
    ClippedContent,
    EllipsisDropdownPortal,
    Table,
    TableLoader,
} from "dyl-components";

import CategorySubrow from "./CategorySubrow";
import Subfooter from "./Subfooter";
import { StringUtils } from "utils";
import { useRef } from "react";
import DeleteProductCategoryPopup from "./DeleteProductCategoryPopup";
import productsActions from "actions/products";
import EditProductCategoryPopup from "./EditProductCategoryPopup";
import HoverableIconWithList from "shared/HoverableIconWithList";

const ProductCatalogRow = ({ category = {}, product, getCategoryProducts }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isReadingCategoryProducts, setIsReadingCategoryProducts] =
        useState(false);

    const { category_products } = useSelector((state) => ({
        category_products: state.products.category_products[category.id] || [],
    }));

    const handleToggle = async () => {
        setIsCollapsed(!isCollapsed);
        if (isCollapsed) {
            await setIsReadingCategoryProducts(true);
            const product_category_id = category.id;
            await getCategoryProducts({ product_category_id });
            await setIsReadingCategoryProducts(false);
        }
    };

    const isCategory = Object.keys(category).length > 0;
    const entry = isCategory ? category : product;
    const RowComponent = isCategory ? Table.CollapsibleRow : Table.Row;

    const ref = useRef();

    const [action, setAction] = useState(null);

    const performAction = (action) => {
        setAction(action);
    };

    const cancelAction = () => {
        setAction(null);
    };

    const dispatch = useDispatch();

    const [params] = useSearchParams();

    const refresh = () => {
        dispatch(productsActions.readProducts(Object.fromEntries(params)));
    };

    const includesRemovedAddition = (items) => {
        return items.some(
            (item) =>
                item.status === "inactive" || item.status === "discontinued"
        );
    };

    const hasRemovedAdditions = (product) => {
        return (
            includesRemovedAddition(product.addons) ||
            includesRemovedAddition(product.fees) ||
            includesRemovedAddition(product.taxes)
        );
    };

    return (
        <RowComponent
            togglePos={1}
            onToggle={handleToggle}
            collapsed={isCollapsed}
            subrowContent={
                isReadingCategoryProducts ? (
                    <Table.CollapsibleRowContent indented nested secondCol>
                        <Table.Cell>
                            <TableLoader isLoading colSpan={8} />
                        </Table.Cell>
                    </Table.CollapsibleRowContent>
                ) : (
                    [
                        ...category_products.map((product) => {
                            return (
                                <CategorySubrow
                                    key={product.id}
                                    product={product}
                                    hasRemovedAdditions={hasRemovedAdditions(
                                        product
                                    )}
                                />
                            );
                        }),
                        <Subfooter product_category={entry.id} />,
                    ]
                )
            }
        >
            <Table.Cell>
                <Checkbox />
            </Table.Cell>
            {!isCategory && <Table.Cell />}
            <Table.Cell>
                <div style={{ width: "10em", display: "inline-flex" }}>
                    <ClippedContent popupContent={entry.name}>
                        {!isCategory ? (
                            <span>
                                {hasRemovedAdditions(entry) && (
                                    <Icon
                                        className="fas fa-info-circle"
                                        color="orange"
                                    />
                                )}
                                <Link
                                    to={`/settings/product-catalog-quotes-invoices/product/${product.id}`}
                                >
                                    {product.name}
                                </Link>
                            </span>
                        ) : (
                            category.name
                        )}
                    </ClippedContent>
                </div>
                <div style={{ display: "inline-flex", paddingLeft: "1em" }}>
                    <HoverableIconWithList
                        items={entry?.addons}
                        icon={
                            <Icon
                                color="primary"
                                className="fa-solid fa-boxes-stacked"
                            />
                        }
                        iconContent={"Add-ons"}
                    />
                    <HoverableIconWithList
                        items={entry?.fees}
                        icon={
                            <Icon
                                color="primary"
                                className="fa-solid fa-circle-dollar-to-slot"
                            />
                        }
                        iconContent={"Fees"}
                    />
                    <HoverableIconWithList
                        items={entry?.taxes}
                        icon={
                            <Icon
                                color="primary"
                                className="fa-solid fa-landmark"
                            />
                        }
                        iconContent={"Taxes"}
                    />
                </div>
            </Table.Cell>
            <Table.Cell {...(entry.status === 'inactive' || entry.status === 'discontinued') && { className: 'DisabledProduct' }}>
                {StringUtils.capitalize(entry.product_type)}
            </Table.Cell>
            <Table.Cell {...(entry.status === 'inactive' || entry.status === 'discontinued') && { className: 'DisabledProduct' }}>
                {entry.description}
            </Table.Cell>
            <Table.Cell {...(entry.status === 'inactive' || entry.status === 'discontinued') && { className: 'DisabledProduct' }}>
                {entry.min_price ? `$${entry.min_price}` : ""}
            </Table.Cell>
            <Table.Cell {...(entry.status === 'inactive' || entry.status === 'discontinued') && { className: 'DisabledProduct' }}>
                {StringUtils.capitalize(entry.status)}
            </Table.Cell>
            <Table.Cell>
                <Ref innerRef={ref}>
                    <EllipsisDropdownPortal reference={ref}>
                        {!isCategory ? (
                            <>
                                <DropdownItem
                                    text="Edit"
                                    icon="fa-solid fa-pencil"
                                    as={Link}
                                    to={`/settings/product-catalog-quotes-invoices/product/${entry.id}`}
                                />
                                <DropdownItem
                                    text="Duplicate"
                                    icon="fas fa-copy"
                                    as={Link}
                                    to={`/settings/product-catalog-quotes-invoices/duplicate-product/${entry.id}`}
                                />
                            </>
                        ) : null}
                        {isCategory && (
                            <>
                                <DropdownItem
                                    text="Edit"
                                    icon="fa-solid fa-pencil"
                                    onClick={() => {
                                        performAction("edit");
                                    }}
                                />
                                <DropdownItem
                                    text="Delete"
                                    icon="fas fa-trash"
                                    disabled={entry.has_products}
                                    onClick={() => {
                                        performAction("delete");
                                    }}
                                />
                            </>
                        )}
                    </EllipsisDropdownPortal>
                </Ref>
                {isCategory && (
                    <DeleteProductCategoryPopup
                        actionPopupRef={ref}
                        id={entry.id}
                        isOpen={action === "delete"}
                        onFormClose={cancelAction}
                        refresh={refresh}
                    />
                )}
                {isCategory && (
                    <EditProductCategoryPopup
                        actionPopupRef={ref}
                        id={entry.id}
                        description={entry.description}
                        name={entry.name}
                        isOpen={action === "edit"}
                        onFormClose={cancelAction}
                        refresh={refresh}
                    />
                )}
            </Table.Cell>
        </RowComponent>
    );
};

export default ProductCatalogRow;
