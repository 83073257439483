import React from 'react';
import { Form, Select } from 'semantic-ui-react';

const MOCK_CALL_RESULTS = [
    { key: 'answered', value: 'answered', text: 'Answered' },
    { key: 'busy', value: 'busy', text: 'Busy' },
    { key: 'inbound', value: 'inbound', text: 'Inbound' },
];

const RefreshCampaignForm = ({
    appointments,
    customers,
    remove_excluded,
    shuffle_records,
    reset_voicemail,
    onChange
}) => (
        <Form>
            <Form.Group>
                <Form.Field>
                    <label>Filters</label>
                </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Field>
                    <label>Appointments</label>
                    <Form.Group inline>
                        <Form.Radio checked={appointments === 'exclude'} label='Exclude' value='exclude' name={'appointments'} onChange={onChange} />
                        <Form.Radio checked={appointments === 'include'} label='Include' value='include' name={'appointments'} onChange={onChange} />
                    </Form.Group>
                </Form.Field>
                <Form.Field>
                    <label>Customers</label>
                    <Form.Group inline>
                        <Form.Radio checked={customers === 'exclude'} label='Exclude' value='exclude' name={'customers'} onChange={onChange} />
                        <Form.Radio checked={customers === 'include'} label='Include' value='include' name={'customers'} onChange={onChange} />
                    </Form.Group>
                </Form.Field>
                <Form.Field>
                    <label>Remove Excluded</label>
                    <Form.Group inline>
                        <Form.Radio checked={remove_excluded === 'remove'} label='Remove' value='remove' name={'remove_excluded'} onChange={onChange} />
                        <Form.Radio checked={remove_excluded === 'keep'} label='Keep' value='keep' name={'remove_excluded'} onChange={onChange} />
                    </Form.Group>
                </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Field>
                    <label>Shuffle Records</label>
                    <Form.Group inline>
                        <Form.Radio checked={shuffle_records === 'yes'} label='Yes' value='yes' name={'shuffle_records'} onChange={onChange} />
                        <Form.Radio checked={shuffle_records === 'no'} label='No' value='no' name={'shuffle_records'} onChange={onChange} />
                    </Form.Group>
                </Form.Field>
                <Form.Field>
                    <label>Reset Voicemail</label>
                    <Form.Group inline>
                        <Form.Radio checked={reset_voicemail === 'yes'} label='Yes' value='yes' name={'reset_voicemail'} onChange={onChange} />
                        <Form.Radio checked={reset_voicemail === 'no'} label='No' value='no' name={'reset_voicemail'} onChange={onChange} />
                    </Form.Group>
                </Form.Field>
                <Form.Field>
                    <label>Include Call Results</label>
                    <Form.Group>
                        <Form.Select as={Select} fluid multiple options={MOCK_CALL_RESULTS} />
                    </Form.Group>
                </Form.Field>
            </Form.Group>
        </Form>
    );

export default RefreshCampaignForm;
