import React from "react";
import {
    ClearableCheckboxDropdown,
    Table,
} from "dyl-components";
import { Table as SemanticTable } from "semantic-ui-react";
import { useSearchParams } from "react-router-dom";
import SelectAllCheckbox from "shared/SelectAllCheckbox";
import { PhoneUtil } from "utils";

const Header = ({ onFilter, count, devices }) => {
    const [params] = useSearchParams();

    return (
        <Table.Row>
            <SemanticTable.HeaderCell>
                <SelectAllCheckbox 
                    count={count}
                    data={devices}
                    type={"Devices"}
                    idProperty={"id"}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                <ClearableCheckboxDropdown
                    text="Type"
                    name="type"
                    options={PhoneUtil.DEVICE_TYPES_OPTIONS}
                    onChange={(_, { name, value }) => {
                        onFilter(_, { name, value });
                    }}
                    value={params.get("type")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                Model
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                Mac ID 
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                IP Address
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                User
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={1}
            >
                Extension
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                Label
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={1}
                className="Devices__statusHeader"
            >
                <ClearableCheckboxDropdown
                    text="Status"
                    name="status"
                    options={[
                        {key: "offline", text: "Offline", value: "offline"},
                        {key: "online", text: "Online", value: "online"},
                    ]}
                    onChange={(_, { name, value }) => {
                        onFilter(_, { name, value });
                    }}
                    value={params.get("status")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={3}
            >
                E911 Location
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell 
                width={1}
            >
                Actions
            </SemanticTable.HeaderCell>
        </Table.Row>
    );
};

export default Header;
