import React, { useState, useEffect, useCallback } from "react"
import { MultipleToggableDropdown } from "dyl-components";
import { Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Form, Header } from "semantic-ui-react";
import groupsActions from 'actions/groups';

const EditGroups = ({ control }) => {
    const [groupOptions, setGroupOptions] = useState([]);
    const [search, setSearch] = useState('');

    const dispatch = useDispatch();

    const { groups, subgroups, isReadingGroups } = useSelector((state) => ({
        groups: state.groups.groups,
        subgroups: state.groups.subgroups,
        isReadingGroups: state.groups.isReadingGroups,
    }));

    const getSubgroups = async (parent_label_id) => {
        await dispatch(groupsActions.readSubgroups({ parent_label_id: Number(parent_label_id.slice(0, -1)) }));
    }

    const onSearchChange = (e) => {
        setSearch(e.target.value);
    }

    const getGroups = useCallback(async (search) => {
        await dispatch(groupsActions.readGroups({ search, limit: 500 }));
    }, [dispatch])

    useEffect(() => {
        getGroups(search);
    }, [search, getGroups])

    useEffect(() => {
        const groupOptionsAux = groups.map((group) => {
            let subgroupsAux = [];
            if (subgroups[group.id] && subgroups[group.id].length > 0) {
                subgroupsAux = subgroups[group.id].map((subgroup) => ({ text: subgroup.name, key: subgroup.id + "C", value: subgroup.id }));
            }
            return { text: group.name, key: group.id + "P", value: group.id, hasOptions: group.sub_labels, options: subgroupsAux, parent: group.parent_label_name };
        })
        setGroupOptions(groupOptionsAux);
    }, [groups, subgroups])

    return (
        <div>
            <Header size='small' color='primary'>Groups</Header>
            <Controller
                name='groups'
                control={control}
                style={{ margin: 0 }}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field
                        control={MultipleToggableDropdown}
                        values={value}
                        nested_options={groupOptions}
                        onChange={(_, { value }) => {
                            setSearch("");
                            onChange({ target: { name, value } });
                        }}
                        placeholder='Search groups'
                        onHoverParent={getSubgroups}
                        searchValue={search}
                        onSearchChange={onSearchChange}
                        loading={isReadingGroups}
                        selection
                    />
                )}
            />
        </div>
    )
}

export default EditGroups;
