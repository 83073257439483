// Override this if testing locally
const FRONT_END_HOST = process.env.REACT_APP_FRONT_END_HOST || process.env.FRONT_END_HOST || 'http://app.getdyl.com';
const REMOTE_IP_HOST = process.env.REACT_APP_REMOTE_IP_HOST || process.env.REMOTE_IP_HOST || 'http://ip.getdyl.com';
const VIEW_HOST = process.env.REACT_APP_VIEW_HOST || process.env.VIEW_HOST;
const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST || process.env.IMAGE_HOST || 'https://image.dev.getdyl.com';
const UPLOAD_HOST = process.env.REACT_APP_UPLOAD_HOST || process.env.UPLOAD_HOST || 'https://upload.dev.getdyl.com';

console.log('FRONT_END_HOST', FRONT_END_HOST);
console.log('FRONT_END_HOST_ENV', process.env.REACT_APP_FRONT_END_HOST);
console.log('VIEW_HOST', VIEW_HOST);

export default {
    FRONT_END_HOST, 
    REMOTE_IP_HOST,
    VIEW_HOST,
    IMAGE_HOST,
    UPLOAD_HOST
};

