import React from 'react';

class Timer extends React.Component {
    state = {
        seconds: 0
    }

    tick() {
        this.setState(state => ({
            seconds: (state.seconds + 1)
        }));
    }

    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        let { seconds } = this.state;
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(seconds / 3600); 
        seconds = seconds % 60;
        return (
            <span>
                {hours !== 0 && `${(hours.toString().length === 1 ? `0${hours}` : hours)}:`}{minutes.toString().length === 1 ? `0${minutes}` : minutes}:{seconds.toString().length === 1 ? `0${seconds}` : seconds}
            </span>
        );
    }
}

export default Timer;
