import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'dyl-components';
import { Checkbox } from 'semantic-ui-react';
import { TwoLinedContent } from 'dyl-components';

const FaxHistoryRow = ({ fax, onMark }) => (
    <Table.Row>
        <Table.Cell>
            <TwoLinedContent firstLine={fax.ts.split()[0]}
            secondLine={fax.ts.split()[1]} />
        </Table.Cell>
        <Table.Cell>
            <Link to={`/lead?id=${fax.lead_id}`}>
                <TwoLinedContent className={`Contact__details`} 
                firstLine={`${fax.first_name} ${fax.last_name}`}
                secondLine={fax.phone} />
            </Link>

        </Table.Cell>
        <Table.Cell>
            <Link to={`/lead/pdf_review?id=${fax.id}`}>
                {fax.numberOfPages} page{fax.numberOfPages === 1 ? '' : 's'}
            </Link>
        </Table.Cell>
        <Table.Cell>
            <Checkbox onChange={onMark} />
        </Table.Cell>
    </Table.Row>
);

export default FaxHistoryRow;
