import { EmptyListPlaceholder, Table } from "dyl-components";
import {
    Table as SemanticTable,
} from "semantic-ui-react";
import React from "react";

const RecurringProducts = ({ products }) => {
    return (
        <Table striped={false}>
            <Table.Header>
                <Table.Row>
                    <SemanticTable.HeaderCell>
                        Product
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell width={3}>
                        Description
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Pricing Schedule
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Price
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell width={2}>
                        Quantity
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Sum
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Discount
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Subtotal
                    </SemanticTable.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {products?.length ? [] : (
                    <Table.Row>
                        <Table.Cell colspan={8}>
                            <EmptyListPlaceholder
                                title={'No Recurring Products Purchased'}
                                link=""
                                subtitle={" "} wrapped={false}
                            />
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    );
};

export default RecurringProducts;
