import React from 'react';
import AudioSelections from './subcomponents/AudioSelections';
import AudioFilesContainer from './subcomponents/AudioFiles';
import { STATUS_TYPES, Notification } from 'dyl-components';

const MusicOnHold = ({ info, onUpdate, onAddAudioFile, onUpdateAudioFile, onDeleteAudioFile, audioFiles }) => (
    <React.Fragment>
        <AudioSelections info={info} onUpdate={onUpdate} audioFileOptions={audioFiles.map(file => ({
            key: file.id, value: file.id, text: file.label
        }))} />
        <AudioFilesContainer
            onAddAudioFile={onAddAudioFile}
            onDelete={onDeleteAudioFile}
            onUpdate={onUpdateAudioFile}
            audioFiles={audioFiles}
        />
    </React.Fragment>
);

class MusicOnHoldContainer extends React.Component {
    state = {
        info: {
            ringing: 'hlda1',
            holdMusic: 'hlda1',
            transfer: 'hlda1'
        },
        audioFiles: [
            { id: 'hlda1', label: 'Hold Audio 1' },
            { id: 'hlda2', label: 'Hold Audio 2' },
            { id: 'hlda3', label: 'Hold Audio 3' },
            { id: 'hlda4', label: 'Hold Audio 4' },
            { id: 'hlda5', label: 'Hold Audio 5' }
        ]
    }

    onUpdate = (newInfo) => {
        Notification.alert('Succefully updated audio selections!', STATUS_TYPES.SUCCESS, true);
        this.setState({
            info: newInfo
        });
    }

    onUpdateAudioFile = (updatedAudioFile) => {
        let audioFiles = this.state.audioFiles.slice(0);
        let indexOfAudioFileToUpdate = audioFiles.findIndex(audioFile => audioFile.id === updatedAudioFile.id);
        if (indexOfAudioFileToUpdate !== -1) {
            audioFiles[indexOfAudioFileToUpdate] = { ...updatedAudioFile };
            this.setState({ audioFiles }, () => {
                Notification.alert('Successfully updated audio file!', STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    onAddAudioFile = (newAudioFile) => {
        let audioFiles = this.state.audioFiles.slice(0);
        audioFiles.push({ ...newAudioFile, id: Math.random() });
        Notification.alert('Successfully added audio file!', STATUS_TYPES.SUCCESS, true);
        this.setState({ audioFiles, isAddAudioFileSectionOpen: false });
    }

    onDeleteAudioFile = (id) => {
        let audioFiles = this.state.audioFiles.slice(0);
        let indexOfAudioFileToDelete = audioFiles.findIndex(audioFile => audioFile.id === id);
        if (indexOfAudioFileToDelete !== -1) {
            audioFiles.splice(indexOfAudioFileToDelete, 1);
            this.setState({ audioFiles }, () => {
                Notification.alert('Successfully deleted audio file!', STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    render() {
        return (
            <MusicOnHold
                {...this.state}
                onUpdate={this.onUpdate}
                onAddAudioFile={this.onAddAudioFile}
                onDeleteAudioFile={this.onDeleteAudioFile}
                onUpdateAudioFile={this.onUpdateAudioFile}
            />
        )
    }
}

export default MusicOnHoldContainer;
