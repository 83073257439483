const initialState = {
    access_role_assignments: [],
    isReadingAccessRoleAssignments: false,
    isUpdatingAccessRoleAssignments: false,

    user_accounts: [],
    isReadingUserAccounts: false,
    count: 0,
    queryParameters: {
        page: 1,
        limit: 25,
        search: ''
    }
}

export default initialState;
