import React from 'react';
import { Header } from 'semantic-ui-react'

const SettingsHeader = ({ title, description }) => (
    <Header className='Segment__Header'>
        {title}
        <Header.Subheader>
            {description}
        </Header.Subheader>
    </Header>
);

export default SettingsHeader;
