import React from "react";
import {
    Table,
    MessagingIcon,
    ControlledPopup,
    Notification,
    STATUS_TYPES,
    EditableContent
} from "dyl-components";
import { TextArea, Form, Radio, Header, Input } from "semantic-ui-react";

import './TemplateRow.scss';

const WrappedTextArea = props => (
    <Form>
        <TextArea {...props} />
    </Form>
);

const TemplateRow = ({
    template,
    isEditMode,
    onChange,
    onDelete,
    onEnableEditMode,
    onDisableEditMode,
    onUpdate
}) => (
    <Table.Row>
        <Table.Cell>
            <EditableContent
                control={Input}
                value={template.name}
                name="name"
                onChange={onChange}
                isEditMode={isEditMode}
            />
        </Table.Cell>
        <Table.Cell>
            <EditableContent
                control={Radio}
                toggle
                value={
                    <Header
                        as="h6"
                        color={template.isEnabled ? "green" : "red"}
                    >
                        {template.isEnabled ? "Yes" : "No"}
                    </Header>
                }
                checked={template.isEnabled}
                onChange={(e, { name, checked }) => {
                    onChange(e, { name, value: checked });
                }}
                name="isEnabled"
                isEditMode={isEditMode}
            />
            {template.status}
        </Table.Cell>
        <Table.Cell width={25}>
            <EditableContent
                control={WrappedTextArea}
                value={template.text}
                name="text"
                onChange={onChange}
                isEditMode={isEditMode}
            />
        </Table.Cell>
        <Table.Cell>
            <ControlledPopup
                trigger={
                    <MessagingIcon icon="trash" className="MessagingIcon"/>
                }
                onConfirm={() => {
                    onDelete(template.id);
                }}
                content={"Delete"}
                inverted
            />
            {isEditMode && (
                <MessagingIcon
                    icon="times"
                    onClick={onDisableEditMode}
                />
            )}
            <MessagingIcon
                className="MessagingIcon"
                icon={isEditMode ? "check" : "edit"}
                onClick={isEditMode ? onUpdate : onEnableEditMode}
            />
        </Table.Cell>
    </Table.Row>
);

class TemplateRowContainer extends React.Component {
    state = {
        template: this.props.template,
        isEditMode: false
    };

    onEnableEditMode = () => {
        this.setState({ isEditMode: true });
    };

    onDisableEditMode = () => {
        this.setState({ isEditMode: false, template: this.props.template });
    };

    onChange = (_, { name, value }) => {
        let template = { ...this.state.template, [name]: value };
        this.setState({ template });
    };

    onUpdate = () => {
        this.setState(
            {
                isEditMode: false
            },
            () => {
                this.props.onUpdate(this.state.template);
                Notification.alert(
                    "Successfully updated lead source!",
                    STATUS_TYPES.SUCCESS,
                    true
                );
            }
        );
    };

    render() {
        return (
            <TemplateRow
                {...this.props}
                {...this.state}
                onChange={this.onChange}
                onEnableEditMode={this.onEnableEditMode}
                onDisableEditMode={this.onDisableEditMode}
                onUpdate={this.onUpdate}
            />
        );
    }
}

export default TemplateRowContainer;
