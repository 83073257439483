import React from 'react';

import { Table } from 'dyl-components';

import FieldEntries from './FieldEntries';
import DependentFieldEntries from './DependentFieldEntries';

const GroupFields = ({
    fields,
    onEditField,
    parent_group,
    refresh,

    isExpanded,
    onToggleField,
    nested,
    isParentArchived
}) => {
    return (
        fields.map(field => {
            const hasDependency = field.field_type === 'dependency';
            const RowComponent = hasDependency ? Table.CollapsibleRow : Table.CollapsibleRowContent;
            return (
                <RowComponent
                    nested={nested}
                    key={field.id}
                    {...(hasDependency ? {
                        isSubrow: true,
                        onToggle: () => { onToggleField(field.id) },
                        collapsed: !isExpanded(field.id),
                        subrowContent: (
                            field.data && (
                                <Table.CollapsibleRowContent indented nested={nested}>
                                    <DependentFieldEntries
                                        field={field}
                                        isParentArchived={isParentArchived}
                                    />
                                </Table.CollapsibleRowContent>
                            )
                        )
                    } : { indented: true })}
                >
                    <FieldEntries
                        editable
                        field={field}
                        hasDependency={hasDependency}
                        onEditField={onEditField}
                        parent_group={parent_group}
                        refresh={refresh} 
                        isParentArchived={isParentArchived}
                    />
                </RowComponent>
            );
        })
    );
}

export default GroupFields;
