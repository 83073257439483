const TOGGLE_MAIN_MENU = 'TOGGLE_MAIN_MENU';
const OPEN_RIGHT_SIDEBAR = 'OPEN_RIGHT_SIDEBAR';
const CLOSE_RIGHT_SIDEBAR = 'CLOSE_RIGHT_SIDEBAR';
const SAVE_SETTINGS_ROUTE = 'SAVE_SETTINGS_ROUTE';


export default {
    TOGGLE_MAIN_MENU,
    OPEN_RIGHT_SIDEBAR,
    CLOSE_RIGHT_SIDEBAR,
    SAVE_SETTINGS_ROUTE
};
