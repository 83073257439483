import initialState from './initialState';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';

function oauthIntegrationsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.OAUTH_INTEGRATIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingIntegrations: true }
        case getNamedAction(ACTION_NAMES.OAUTH_INTEGRATIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            const { data } = action;
            const activeIntegrations = data.integrations?.filter(integration => integration.active) || [];
            const mainIntegration = activeIntegrations.find(e => e.scopes.find(scope => scope.main)) || {};
            return { ...state, isReadingIntegrations: false, providers: data.providers, integrations: data.integrations || [], activeIntegration: mainIntegration }
        case getNamedAction(ACTION_NAMES.OAUTH_INTEGRATIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingIntegrations: false, providers: [], integrations: [], activeIntegration: {} }

        case getNamedAction(ACTION_NAMES.UPDATE_PRIMARY_OAUTH_INTEGRATION, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingPrimaryIntegration: true }
        case getNamedAction(ACTION_NAMES.UPDATE_PRIMARY_OAUTH_INTEGRATION, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingPrimaryIntegration: false }
        case getNamedAction(ACTION_NAMES.UPDATE_PRIMARY_OAUTH_INTEGRATION, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingPrimaryIntegration: false }

        case getNamedAction(ACTION_NAMES.DELETE_OAUTH_INTEGRATION, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, integrationBeingDeleted: action.queryParameters.intergration_id }
        case getNamedAction(ACTION_NAMES.DELETE_OAUTH_INTEGRATION, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, integrationBeingDeleted: null }
        case getNamedAction(ACTION_NAMES.DELETE_OAUTH_INTEGRATION, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, integrationBeingDeleted: null }

        case getNamedAction(ACTION_NAMES.EMAIL_ENABLE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isEnablingIntegration: true }
        case getNamedAction(ACTION_NAMES.EMAIL_ENABLE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isEnablingIntegration: false }
        case getNamedAction(ACTION_NAMES.EMAIL_ENABLE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isEnablingIntegration: false }

        case getNamedAction(ACTION_NAMES.OAUTH_INTEGRATION_REACTIVATE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isReactivatingAnIntegration: true }
        case getNamedAction(ACTION_NAMES.OAUTH_INTEGRATION_REACTIVATE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isReactivatingAnIntegration: false }
        case getNamedAction(ACTION_NAMES.OAUTH_INTEGRATION_REACTIVATE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isReactivatingAnIntegration: false }


        default:
            return state;
    }
}

export default oauthIntegrationsReducer;
