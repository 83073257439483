import routes from 'actions/routes';
import { generateReadActionCreator, generateUpdateActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

const readAssignments = generateReadActionCreator(routes.API_ACCESS_ROLE_ASSIGNMENT, ACTION_NAMES.ACCESS_ROLE_ASSIGNMENT);
const updateAssignments = generateUpdateActionCreator(routes.API_ACCESS_ROLE_ASSIGNMENT, ACTION_NAMES.ACCESS_ROLE_ASSIGNMENT, () => {}, () => {}, '', true);
const readUserAccounts = generateReadActionCreator(routes.API_USER_ACCOUNTS, ACTION_NAMES.USER_ACCOUNTS);

export default {
    readAssignments,
    updateAssignments,
    readUserAccounts
}
