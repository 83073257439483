import basePath from './basePath';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { reqLog, resLog } from 'logger';
import queryString from 'query-string';

const instance1 = axios.create({
    baseURL: basePath.FRONT_END_HOST,
    withCredentials: true,
    crossDomain: true
});

const image_instance = axios.create({
    baseURL: basePath.IMAGE_HOST,
    crossDomain: true
});

const ip_instance = axios.create({
    baseURL: basePath.REMOTE_IP_HOST,
    crossDomain: true
});

const upload_instance = axios.create({
    baseURL: basePath.UPLOAD_HOST,
    crossDomain: true
});

const parsed = queryString.parse(window.location.search);
const update_host = (host, feature) => {
    switch (host) {
        case "app":
            instance1.defaults.baseURL = basePath.FRONT_END_HOST.substr(0, 8) + feature + '-' + basePath.FRONT_END_HOST.substr(8);
			break;	
        case "image":
            image_instance.defaults.baseURL = basePath.IMAGE_HOST.substr(0, 8) + feature + '-' + basePath.IMAGE_HOST.substr(8);
			break;	
        case "ip":
            ip_instance.defaults.baseURL = basePath.REMOTE_IP_HOST.substr(0, 8) + feature + '-' + basePath.REMOTE_IP_HOST.substr(8);
			break;	
        case "upload":
            upload_instance.defaults.baseURL = basePath.REMOTE_IP_HOST.substr(0, 8) + feature + '-' + basePath.REMOTE_IP_HOST.substr(8);
			break;	
		default:
			break;
	}
};
if (!parsed.feature) {
	const parts = window.location.hostname.split('.');
	if (parts.length > 3) {
		parsed.feature = parts[0];
	} /* else if (process.env.REACT_APP_ENVIRONMENT == 'prod' && parts.length > 2) {
		parsed.feature = parts[0];
	} */
}
if (parsed.feature) {
	console.log("FEATURE", parsed.feature);
	if (! parsed.hosts) {
		parsed.hosts = 'app';
	}
    parsed.hosts.split(",").forEach((host) => {
        update_host(host, parsed.feature);
    });
}

ip_instance.interceptors.request.use(async (config) => {
    return config;
}, (error) => {
    return Promise.reject(error);
});

instance1.interceptors.request.use(async (config) => {
    let requestID = uuid();
    config.headers['X-Request-ID'] = requestID;
    reqLog.append({
        request_id: requestID,
        url: config.url,
        method: config.method,
        headers: config.headers,
    })
    return config;
}, (error) => {
    return Promise.reject(error);
});

instance1.interceptors.response.use( async (response)=> {
    let requestID = response.hasOwnProperty("data")? response.data.requestID : null;
    resLog.append({
        request_id: requestID,
        status: response.status,
        body: response.data,
    })
    return response;
})

image_instance.interceptors.request.use(async (config) => {
    return config;
}, (error) => {
    return Promise.reject(error);
});

upload_instance.interceptors.request.use(async (config) => {
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default {
    update_host,
    instance1,
    ip_instance,
    image_instance,
    upload_instance
};
