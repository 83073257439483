import React from 'react';
import Idle from 'react-idle'
import { connect } from 'react-redux';
import authActions from 'actions/auth/auth';

const IdleCheck = ({ logoutOnIdle }) => (
    <Idle 
        timeout={3600000}
        onChange={logoutOnIdle}
    />
)

const mapDispatchToProps = dispatch => ({
    logoutOnIdle: ({ idle }) => {
        if (idle) {
            dispatch(authActions.logout());
        }   
    }
})

export default connect(null, mapDispatchToProps)(IdleCheck);
