import React from 'react';
import { connect } from 'react-redux';
import { Grid, Transition, Dropdown, Input, Button, Divider } from 'semantic-ui-react';

import { TextMessageModal, NotesModal } from 'shared/modals';
import ActionButton from './ActionButton';

import './ActionButtons.scss';

export const ActionButtons = (props) => {
    if (!props.isActiveCall) {
        return (
            <InactiveCallActions {...props}  />
        )
    }
    return (
        <ActiveCallActionsContainer {...props} />
    )
}

const ActiveCallActions = ({
    onTransfer,
    onText,
    onCheckNotes
}) => (
    <Grid columns={4}>
        <Grid.Column>
            <ActionButton color='hippie-blue' icon='exchange' onClick={onTransfer} label={'Transfer'} />
        </Grid.Column>
        <Grid.Column>
            <ActionButton color='glacier' icon='chat' onClick={onText} label={'Text'} />
        </Grid.Column>
        <Grid.Column>
            <ActionButton color='ship-cove' icon='sticky note outline' onClick={onCheckNotes} label={'Notes'} />
        </Grid.Column>
    </Grid>
)

class ActiveCallActionsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transferControlsVisible: false
        }
    }

    toggleTransfer() {
        this.setState({ transferControlsVisible: !this.state.transferControlsVisible })
    }

    render() {
        return (
            <React.Fragment>
                <ActiveCallActions 
                    {...this.props}
                    onTransfer={this.toggleTransfer.bind(this)}
                />
                <Transition visible={this.state.transferControlsVisible} animation='slide down'>
                    <Grid.Row columns='equal' className="ActiveCallActionsContainerGrid">
                        <Grid.Column textAlign='center'>
                            <Dropdown
                                placeholder='Select agent'
                                fluid
                                search
                                selection
                            />
                            <Divider horizontal>Or</Divider>
                            <Input type='text' name='phone_number' placeholder='Specify phone number' fluid />
                            <div className="ActiveCallActionsContainerDiv">
                            <Button primary className="ActiveCallActionsContainerButton">Transfer</Button>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Transition>
            </React.Fragment>
        )
    }
}



const InactiveCallActions = ({
    caller,
    onCall, 
    onText,
    onCheckNotes 
}) => (
    <Grid columns={4}>
        <Grid.Column>
            <ActionButton color='neptune' icon='phone' onClick={() => {onCall(caller)}} label={'Call'} />
        </Grid.Column>
        <Grid.Column>
            <ActionButton color='glacier' icon='chat' onClick={onText} label={'Text'} />
        </Grid.Column>
        <Grid.Column>
            <ActionButton color='ship-cove' icon='sticky note outline' onClick={onCheckNotes} label={'Notes'} />
        </Grid.Column>
    </Grid>
)

class ActionButtonsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeModal: ''
        }
        this.toggleModal = this.toggleModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    toggleModal(name) {
        this.setState({
            activeModal: name
        });
    }

    closeModal() {
        this.toggleModal('');
    }

    isActiveModal(modal) {
        return this.state.activeModal === modal;
    }

    render() {
        if (this.props.caller === null) {
            return '';
        } 
        return (
            <React.Fragment>
                <ActionButtons 
                    {...this.props}
                    onText={() => {this.toggleModal(TextMessageModal.ID)}}
                    onCheckNotes={() => {this.toggleModal(NotesModal.ID)}}
                />
                <NotesModal 
                    onClose={this.closeModal}
                    open={this.isActiveModal(NotesModal.ID)}
                    contact_id={this.props.caller.contact_id}
                    account_id={this.props.caller.account_id}
                />
                <TextMessageModal 
                    onClose={this.closeModal}
                    open={this.isActiveModal(TextMessageModal.ID)}
                    to_phone={this.props.caller.phone}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        caller: state.officeView.callerInActionsView,
        isActiveCall: state.officeView.isActiveCall
    }
}

export default connect(mapStateToProps)(ActionButtonsContainer);
