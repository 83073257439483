import React from 'react';
import { Grid, Button } from 'semantic-ui-react';

const TagsHeader = ({ onOpenAddTagSection }) => (
    <Grid verticalAlign='middle'>
        <Grid.Column width={6}>
            Tags
        </Grid.Column>
        <Grid.Column width={4} textAlign='right' floated='right'>
        <Button onClick={onOpenAddTagSection} size='tiny' basic inverted color='white'>Add Tag</Button>
        </Grid.Column>
    </Grid>
)

export default TagsHeader;
