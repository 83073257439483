import { Controller, useFormContext } from "react-hook-form";
import { Form, Icon, Popup } from "semantic-ui-react";
import PriceField from "./PriceField";
import './ProductForm.scss'

const UsageBasedFields = () => {
    const { control, productBeingEdited } = useFormContext();
    return (
        <Controller
            control={control}
            name={"price_data.usage_billing_method"}
            render={({ field: { name, value, onChange } }) => (
                <>
                    <Form.Group>
                        <Form.Select
                            name={name}
                            value={value}
                            width={8}
                            onChange={(_, { name, value }) => {
                                onChange({ target: { name, value } });
                            }}
                            selectOnBlur={false}
                            disabled={productBeingEdited}
                            options={[
                                {
                                    key: "billing-day",
                                    value: "billing-day",
                                    text: "Charge on the billing day",
                                },
                                {
                                    key: "custom-day",
                                    value: "custom-day",
                                    text: "Charge on a custom day",
                                },
                                {
                                    key: "prepaid",
                                    value: "prepaid",
                                    text: "Prepaid",
                                },
                            ]}
                            label={
                                <label>
                                    <div className="Label__container">
                                        <span>
                                            Billing Method 
                                            <span className="Label__mandatory"> *</span>
                                        </span>
                                        {value === "billing-day" ? <Popup
                                            position='top right'
                                            trigger={
                                                <Icon size='large' name='fas fa-circle-info' className='Label__icon' />
                                            }
                                            inverted
                                            content="The customer will automatically be charged on the first day of every month if they do not have monthly billing"
                                        /> : null } 
                                    </div>
                                </label>}
                        />
                        {value === "custom-day" && (
                            <Controller
                                control={control}
                                name="price_data.usage_date"
                                render={({
                                    field: { name, value, onChange },
                                }) => (
                                    <>
                                        <Form.Select
                                            name={name}
                                            width={4}
                                            value={value}
                                            onChange={(_, { name, value }) => {
                                                onChange({
                                                    target: { name, value },
                                                });
                                            }}
                                            selectOnBlur={false}
                                            options={Array.from(
                                                { length: 31 },
                                                (_, index) => index + 1
                                            ).map((day) => ({
                                                key: day,
                                                value: day,
                                                text: `Day ${day}`,
                                            }))}
                                            required
                                            label="Day of Month"
                                            disabled={productBeingEdited}
                                        />
                                        <Form.Field control={"div"} width={4}>
                                            <label
                                                style={{ visibility: "hidden" }}
                                            >
                                                Day of month
                                            </label>
                                            <div
                                                style={{ marginTop: "0.75em" }}
                                            >
                                                of every month
                                            </div>
                                        </Form.Field>
                                    </>
                                )}
                            />
                        )}
                    </Form.Group>
					{value === "prepaid" && (
						<Form.Group widths={2}>
							<PriceField name={"price_data.usage_below_balance"} label="When Below Balance" />
							<PriceField step="any" name={"price_data.usage_reload_amount"} label="Auto Reload Amount" />
						</Form.Group>
					)}
                </>
            )}
        />
    );
};

export default UsageBasedFields;
