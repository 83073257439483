import initialState from './state';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';

function attachmentsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.EMAIL_ATTACHMENTS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingAttachments: true };
        case getNamedAction(ACTION_NAMES.EMAIL_ATTACHMENTS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return {
                ...state, isReadingAttachments: false, attachments: action.data.data,
                count: action.data.count, queryParameters: action.queryParameters
            }
        case getNamedAction(ACTION_NAMES.EMAIL_ATTACHMENTS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingAttachments: false, attachments: [] };

        case getNamedAction(ACTION_NAMES.EMAIL_ATTACHMENTS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingAttachment: true };
        case getNamedAction(ACTION_NAMES.EMAIL_ATTACHMENTS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingAttachment: false }
        case getNamedAction(ACTION_NAMES.EMAIL_ATTACHMENTS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingAttachment: false };

        case getNamedAction(ACTION_NAMES.EMAIL_ATTACHMENTS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, attachmentBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.EMAIL_ATTACHMENTS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, attachmentBeingUpdated: null }
        case getNamedAction(ACTION_NAMES.EMAIL_ATTACHMENTS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, attachmentBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.EMAIL_ATTACHMENTS, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, attachmentBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.EMAIL_ATTACHMENTS, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, attachmentBeingDeleted: null }
        case getNamedAction(ACTION_NAMES.EMAIL_ATTACHMENTS, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, attachmentBeingDeleted: null };

        default:
            return state;
    }
}

export default attachmentsReducer;
