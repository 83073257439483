import routes from "actions/routes";
import { generateCreateActionCreator, generateDeleteActionCreator, generateReadOneActionCreator, generateUpdateActionCreator } from "actions/crudActionCreators";
import ACTION_NAMES from "actions/ACTION_NAMES";

export default {
    getLocations: generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_LOCATION, 'location'),
    addContactLocation: generateCreateActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_LOCATION, () => { }, () => { }, 'location'),
    updateContactLocation: generateUpdateActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_LOCATION, () => { }, () => { }, 'location'),
    deleteContactLocation: generateDeleteActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_LOCATION, () => { }, () => { }, 'location'),
    readOptions: generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_LOCATION_OPTIONS, 'location')
}
