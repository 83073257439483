import React from 'react';
import { Table } from 'dyl-components';
import { EditableContent } from 'dyl-components';
import { Select } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import MessagingIconButtons from 'shared/MessagingIconButtons';

const PromptOptionRow = ({ 
    isEditMode, 
    option,
    playMessageOptions, 
    onChange, 
    onDelete, 
    onUpdate, 
    onEnableEditMode, 
    onDisableEditMode 
}) => (
    <Table.Row active={isEditMode}>
        <Table.Cell>{option.digit}</Table.Cell>
        <Table.Cell>
            <EditableContent 
                control={Select}
                name='playMessage'
                value={isEditMode ? option.playMessage : [
                    { key: 'None', value: 'None', text: 'None' },
                    ...playMessageOptions
                ].filter(playMessageOption => playMessageOption.value === option.playMessage)[0].text}
                onChange={onChange}
                isEditMode={isEditMode}
                options={[
                    { key: 'None', value: 'None', text: 'None' },
                    ...playMessageOptions
                ]}
            />
        </Table.Cell>
        <Table.Cell>
            <EditableContent 
                control={Select}
                name='route'
                value={isEditMode ? option.route : (
                    <React.Fragment>
                        IVR Menu:&nbsp;
                        <Link to={option.route}>
                            {playMessageOptions.filter(playMessageOption => playMessageOption.value === option.route)[0].text}
                        </Link>
                    </React.Fragment>
                )}
                onChange={onChange}
                isEditMode={isEditMode}
                options={playMessageOptions}
            />
        </Table.Cell>
        <Table.Cell>
           <MessagingIconButtons
                isEditMode={isEditMode}
                onDisableEditMode={onDisableEditMode} 
                onUpdate={onUpdate}
                onEnableEditMode={onEnableEditMode}
                onDelete={onDelete}
                id={option.digit} 
            />
        </Table.Cell>
    </Table.Row>
)

class PromptOptionRowContainer extends React.Component {
    state = {
        option: this.props.option,
        isEditMode: false
    }

    onEnableEditMode = () => {
        this.setState({ isEditMode: true });
    }

    onDisableEditMode = () => {
        this.setState({ isEditMode: false, option: this.props.option });
    }

    onChange = (_, { name, value }) => {
        let option = { ...this.state.option, [name]: value};
        this.setState({ option });
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false
        }, () => {
            this.props.onUpdate(this.state.option);
        })
    }

    render() {
        return (
            <PromptOptionRow 
                {...this.props}
                {...this.state}
                onChange={this.onChange}
                onEnableEditMode={this.onEnableEditMode}
                onDisableEditMode={this.onDisableEditMode}
                onUpdate={this.onUpdate}
            />
        )
    }
}

export default PromptOptionRowContainer;
