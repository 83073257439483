import ACTION_NAMES from "actions/ACTION_NAMES";
import { generateDeleteActionCreator, generateReadOneActionCreator, generateUpdateActionCreator } from "actions/crudActionCreators";
import routes from "actions/routes";

export default {
    transfer: generateUpdateActionCreator(routes.API_PIPELINE, ACTION_NAMES.PIPELINE_TRANSFER, () => { }, () => { }, 'transfer'),
    getOne: generateReadOneActionCreator(routes.API_PIPELINE, ACTION_NAMES.PIPELINE),
    update: generateUpdateActionCreator(routes.API_PIPELINE, ACTION_NAMES.PIPELINE),
    delete: generateDeleteActionCreator(routes.API_PIPELINE, ACTION_NAMES.PIPELINE),
    readHistory: generateReadOneActionCreator(routes.API_PIPELINE, ACTION_NAMES.PIPELINE_TIMELINE, 'history')
}
