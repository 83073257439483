import React from 'react';
import { Grid } from 'semantic-ui-react';
import PendingCallsRow from './PendingCallsRow';
import { TableWithHeader } from 'dyl-components';

const PendingCallsArea = ({ pendingCallLists, onClear }) => (
    <TableWithHeader 
        header={'Clear Pending Calls'}
        table={
            <Grid style={{margin: 0}}>
                {pendingCallLists.map(list => (
                    <PendingCallsRow list={list} onClear={onClear} />
                ))}
                {pendingCallLists.length <= 0 && (
                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <em>There are no pending calls</em>
                        </Grid.Column>
                    </Grid.Row>
                )}
            </Grid>
        }
    />
);

export default class PendingCallsAreaContainer extends React.Component {
    state = {
        pendingCallLists: [
            { id: 'Appointments', name: 'Appointments', numberOfLeads: 2 },
            { id: 'Call Now List', name: 'Call Now List', numberOfLeads: 0 }
        ]
    }

    onClear = (id, clearLeadsDate) => {
        let pendingCallLists = this.state.pendingCallLists.slice(0);
        let pendingCallListToClear = pendingCallLists.filter(list => list.id === id)[0];
        if (pendingCallListToClear) {
            pendingCallListToClear.numberOfLeads = 0;
            this.setState({ pendingCallLists });
        }
    }

    render() {
        return (
            <PendingCallsArea 
                pendingCallLists={this.state.pendingCallLists.filter(list => list.numberOfLeads > 0)}
                onClear={this.onClear}
            />
        )
    }
}
