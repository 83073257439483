const initialState = {
    isReadingPipeline: false,
    isReadingPipelineHistory: false,
    isCreating: false,
    pipelineBeingMoved: null,
    pipelineBeingDelted: null,
    pipelineBeingUpdated: null,
    pipeline: null,
    pipelineHistory: []
};

export default initialState;
