import React from 'react';
import { Table, ControlledPopup, MessagingIcon } from 'dyl-components';
import { connect } from 'react-redux';

import tagActions from 'actions/tag';
import tagsActions from 'actions/tags';
import tagCategoriesActions from 'actions/tag_categories';

const TagRow = ({
    callTag,
    onDelete
}) => (
    <Table.Row>
        <Table.Cell>
            {callTag.tag}
        </Table.Cell>
        <Table.Cell>
            {callTag.category.name}
        </Table.Cell>
        <Table.Cell>
            {callTag.type}
        </Table.Cell>
        <Table.Cell>
            {callTag.items}
        </Table.Cell>
        <Table.Cell>
            <ControlledPopup
                trigger={<MessagingIcon icon='trash' />}
                onConfirm={onDelete}
                content={'Delete'}
                inverted
            />
        </Table.Cell>
    </Table.Row>
)

const mapDispatchToProps = (dispatch, { callTag }) => ({
    onDelete: () => {
        dispatch(tagActions.deleteTag(callTag.tag_id))
            .then(() => {
                dispatch(tagsActions.getListOfTags());
                dispatch(tagCategoriesActions.getListOfTagCategories());
            });
    }
})

export default connect(null, mapDispatchToProps)(TagRow);
