import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function customFieldsParentGroupsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS_PARENT_GROUPS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingParentGroups: true };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS_PARENT_GROUPS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingParentGroups: false, parentGroups: action.data.data, count: action.data.count };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS_PARENT_GROUPS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingParentGroups: false };

        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS_GROUPS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingCustomGroup: true };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS_GROUPS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAddingCustomGroup: false };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS_GROUPS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingCustomGroup: false };

        default:
            return state;
    }
}


export default customFieldsParentGroupsReducer;
