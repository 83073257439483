import { HTMLPreview } from 'dyl-components';
import React from 'react';
import { Image, Segment } from 'semantic-ui-react';
import { LogoTextBlue } from 'dyl-components'; 

const WebformSuccessMessage = ({
    form_background_color,
    use_logo,
    logo,
    success_message,

}) => (
    <Segment style={{ backgroundColor: form_background_color }} padded='very'>
        <div>
            {use_logo && (
                <Image
                    className={`WebformPreview__logo WebformPreview__logo${logo ? "--company" : "--default"}`}
                    src={logo ? `data:image/png;base64, ${logo}` : LogoTextBlue}
                    size='small'
                />
            )}
            <HTMLPreview content={success_message} full padded={false} />
        </div>
    </Segment>
);

export default WebformSuccessMessage;
