import initialState from './initialState';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import { DateTimeUtils } from 'dyl-components';

function usersReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.USERS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingUsers: true, queryParameters: action.queryParameters }
        case getNamedAction(ACTION_NAMES.USERS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingUsers: false, users: action.data.data, count: action.data.count, queryParameters: action.queryParameters }
        case getNamedAction(ACTION_NAMES.USERS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingUsers: false }

        case getNamedAction(ACTION_NAMES.SEARCH_USERS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isSearchingUsers: true, searchUsers: { ...state.searchUsers, queryParameters: action.queryParameters } }
        case getNamedAction(ACTION_NAMES.SEARCH_USERS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isSearchingUsers: false, searchUsers: { result: action.data.data, count: action.data.count, queryParameters: action.queryParameters } }
        case getNamedAction(ACTION_NAMES.SEARCH_USERS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isSearchingUsers: false }

        case getNamedAction(ACTION_NAMES.USER_OPTIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingUsers: true, userOptionsQueryParameters: action.queryParameters }
        case getNamedAction(ACTION_NAMES.USER_OPTIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingUsers: false, userOptions: action.data.data, userOptionsCount: action.data.count, userOptionsQueryParameters: action.queryParameters }
        case getNamedAction(ACTION_NAMES.USER_OPTIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingUsers: false }

        case getNamedAction(ACTION_NAMES.USER_PROFILE, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, ...initialState, isReadingUser: true, user_id: action.id }
        case getNamedAction(ACTION_NAMES.USER_PROFILE, CRUD_ACTION_TYPES.READ_SUCCESS):
            {
                const { company, social_media, schedule, user_id, timezone } = action.data;
                return {
                    ...state, isReadingUser: false, userProfile: {
                        ...action.data,
                        company: company || { industry: '', name: '' },
                        social_media: social_media || [],
                        schedule: schedule || [],
                        user_id: user_id || state.user_id,
                        timezone: timezone || "US Mountain Standard Time",
                    }
                }
            }
        case getNamedAction(ACTION_NAMES.USER_PROFILE, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingUser: false }

        case getNamedAction(ACTION_NAMES.USERS, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, UserBeingDeleted: action.id }
        case getNamedAction(ACTION_NAMES.USERS, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, UserBeingDeleted: null }
        case getNamedAction(ACTION_NAMES.USERS, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, UserBeingDeleted: null }

        case getNamedAction(ACTION_NAMES.USERS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingUser: true }
        case getNamedAction(ACTION_NAMES.USERS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            const timezone = action.body.timezone;
            DateTimeUtils.setTimezone(timezone);
            return { ...state, isUpdatingUser: false }
        case getNamedAction(ACTION_NAMES.USERS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingUser: false }

        case getNamedAction(ACTION_NAMES.USERS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingUser: true }
        case getNamedAction(ACTION_NAMES.USERS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingUser: false }
        case getNamedAction(ACTION_NAMES.USERS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingUser: false }

        case getNamedAction(ACTION_NAMES.BULK_USERNAMES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingBulkUsernames: true, bulkUsernamesQueryParameters: action.queryParameters }
        case getNamedAction(ACTION_NAMES.BULK_USERNAMES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingBulkUsernames: false, bulkUsernames: action.data, bulkUsernamesQueryParameters: action.queryParameters }
        case getNamedAction(ACTION_NAMES.BULK_USERNAMES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingBulkUsernames: false }

        default:
            return state;
    }
}

export default usersReducer;
