import React from 'react';
import { Table, DropdownTimeInput } from 'dyl-components';
import { EditableContent } from 'dyl-components';
import { Checkbox, Form } from 'semantic-ui-react';
import { DateTimeUtils } from 'dyl-components';

const ScheduleTableRow = ({ schedule, onChange, isLoading }) => (
    <Table.Row>
        <Table.Cell width={3}>
            {schedule.day}
        </Table.Cell>
        <Table.Cell width={2}>
            <EditableContent
                name={'open'}
                value={schedule.open ? 'Yes' : 'No'}
                onChange={(_, { name, checked }) => { onChange(schedule.day, { name, value: checked }) }}
                control={Checkbox}
                checked={schedule.open}
                isEditMode={true}
                disabled={isLoading}
                toggle
            />
        </Table.Cell>
        <Table.Cell width={3}>
            {schedule.open && (
                <Form>
                    <EditableContent
                        name={'start'}
                        onChange={(_, { name, value }) => { onChange(schedule.day, { name, value }) }}
                        control={DropdownTimeInput}
                        value={schedule.start}
                        isEditMode={true}
                        error={schedule.open && !DateTimeUtils.dateIsBeforeAnotherDate(schedule.start, schedule.end) && (
                            "Start time should be before end time"
                        )}
                    />
                </Form>
            )}
        </Table.Cell>
        <Table.Cell width={1}>
            {schedule.open && "to"}
        </Table.Cell>
        <Table.Cell width={3}>
            {schedule.open && (
                <Form>
                    <EditableContent
                        name={'end'}
                        onChange={(_, { name, value }) => { onChange(schedule.day, { name, value }) }}
                        control={DropdownTimeInput}
                        value={schedule.end}
                        isEditMode={true}
                        compact
                    />
                </Form>
            )}
        </Table.Cell>
    </Table.Row>
);

export default ScheduleTableRow;
