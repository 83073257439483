import { RuleContainer } from "dyl-components";
import { Controller, useFormContext } from "react-hook-form";
import { Form, Grid } from "semantic-ui-react";

const PaymentFrequency = ({ frequency, parentName, content }) => {
    const { control, trigger } = useFormContext();
    return (
        <Grid columns={"equal"} verticalAlign="middle">
            <Controller
                control={control}
                name={`price_data.${parentName}_frequencies`}
                render={({ field: { name, value: frequencies, onChange } }) => {
                    const active = frequencies?.includes(frequency);
                    return (
                        <>
                            <Grid.Column width={1}>
                                <Form.Checkbox
                                    name={name}
                                    checked={active}
                                    onChange={(_, { name }) => {
                                        onChange({
                                            target: {
                                                name,
                                                value: active
                                                    ? frequencies?.filter(
                                                          (activeFrequency) =>
                                                              activeFrequency !==
                                                              frequency
                                                      )
                                                    : [
                                                          ...(frequencies ||
                                                              []),
                                                          frequency,
                                                      ],
                                            },
                                        });
                                        trigger("price_data");
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field
                                    control={RuleContainer}
                                    isActive
                                    content={content}
                                />
                            </Grid.Column>
                        </>
                    );
                }}
            />
        </Grid>
    );
};

export default PaymentFrequency;
