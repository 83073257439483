import React from 'react';
import { Form } from 'semantic-ui-react';
import { FilterComponent, DatesRangeInput } from 'dyl-components';
import TopMarginedFilterButton from 'shared/TopMarginedFilterButton';

const timeframeOptions = [
    { key: 'any', value: 'any', text: 'Any' },
    { key: 'specify', value: 'specify', text: 'Specify' },
    { key: 'today', value: 'today', text: 'Today' },
    { key: 'yesterday', value: 'yesterday', text: 'Yesterday' },
    { key: 'this_week', value: 'this_week', text: 'This Week' },
    { key: 'last_week', value: 'last_week', text: 'Last Week' },
    { key: 'this_month', value: 'this_month', text: 'This Month' },
    { key: 'last_month', value: 'last_month', text: 'Last Month' },
    { key: 'year_to_date', value: 'year_to_date', text: 'Year to Date' },
    { key: 'last_year', value: 'last_year', text: 'Last Year' },
]

const CustomFilter = ({ name, filters, label, onChange, value }) => {
    let options = filters.map(filter => ({
        key: filter,
        value: filter,
        text: filter
    }))

    options.sort((a, b) => a.key > b.key ? 1 : -1);
    options.unshift({ key: 'any', value: 'any', text: 'Any' });

    return (
        <Form.Select name={name} options={options} fluid label={label} onChange={onChange} value={value} />
    )
}

export const CallHistoryFilter = ({
    users = [],
    dateRange,
    user,
    direction,
    timeframe,
    inbound_line,
    onInputChange,
    onSubmit
}) => (
        <FilterComponent>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Select name={'timeframe'} onChange={onInputChange} options={timeframeOptions} fluid label='Browse by Timeframe' value={timeframe || 'any'} />
                    <CustomFilter name={'user'} onChange={onInputChange} filters={users} label={'User'} value={user || 'any'} />
                    <CustomFilter name={'direction'} onChange={onInputChange} filters={[
                        'Both', 'Inbound', 'Outbound'
                    ]} label={'Direction'} value={direction || 'any'} />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field label='Browse by Date'
                        control={DatesRangeInput}
                        placeholder={"From - To"}
                        iconPosition="right"
                        value={dateRange}
                        popupPosition={'bottom center'}
                        onChange={onInputChange}
                        name="dateRange"
                    />
                    <Form.Field className='FilterComponent__radio-buttons'>
                        <label>Answered</label>
                        <Form.Group inline>
                            <Form.Radio label='Both' value='both' name='answered' onChange={onInputChange} />
                            <Form.Radio label='Yes' value='yes' name='answered' onChange={onInputChange} />
                            <Form.Radio label='No' value='no' name='answered' onChange={onInputChange} />
                        </Form.Group>
                    </Form.Field>
                    <Form.Field className='FilterComponent__radio-buttons'>
                        <label>Customer</label>
                        <Form.Group inline>
                            <Form.Radio label='Both' value='both' name='isCustomer' onChange={onInputChange} />
                            <Form.Radio label='Yes' value='yes' name='isCustomer' onChange={onInputChange} />
                            <Form.Radio label='No' value='no' name='isCustomer' onChange={onInputChange} />
                        </Form.Group>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal' className='FilterComponent__action-buttons'>
                    {direction === 'Inbound' && (
                        <CustomFilter name={'inbound_line'} onChange={onInputChange} filters={[
                        ]} label={'Inbound Line'} value={inbound_line || 'any'}
                        />
                    )}
                    <Form.Select
                        label={'Call Tags'}
                        multiple
                        options={[
                            { key: 'Answered', value: 'Answered', text: 'Answered' },
                            { key: 'Hung up', value: 'Hung up', text: 'Hung up' },
                            { key: 'Never Call Again', value: 'Never Call Again', text: 'Never Call Again' },
                            { key: 'Special', value: 'Special', text: 'Special' },
                        ]}
                    />
                    <TopMarginedFilterButton onSubmit={onSubmit} />
                </Form.Group>
            </Form>
        </FilterComponent>
    )

export default class CallHistoryFilterContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRange: '',
            user: null,
            direction: null,
            timeframe: null,
            inbound_line: null
        }
        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onInputChange(_, { name, value }) {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    }

    onSubmit() {
        if (this.props.onFilterLeads) {
            this.props.onFilterLeads({ ...this.state });
        }
    }

    render() {
        return (
            <CallHistoryFilter
                {...this.props}
                onInputChange={this.onInputChange}
                onSubmit={this.onSubmit}

                {...this.state}
            />
        )
    }
}
