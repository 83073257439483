import React from 'react';
import { Grid, Transition, Segment } from 'semantic-ui-react';
import { TableWithHeader } from 'dyl-components';

import ChatRoomsHeader from './ChatRoomsHeader';
import ChatRoomsTable from './ChatRoomsTable';
import AddChatRoomSection from './AddChatRoomSection';

class ChatRooms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAddChatRoomSectionOpen: false
        }
    }

    onCloseAddChatRoomSection = () => {
        this.setState({
            isAddChatRoomSectionOpen: false,
        })
    }

    onOpenAddChatRoomSection = () => {
        this.setState({
            isAddChatRoomSectionOpen: true,
        })
    }

    render() {
        return (
            <TableWithHeader
                header={
                    <ChatRoomsHeader onOpenAddChatRoomSection={this.onOpenAddChatRoomSection} />
                }
                table={
                    <Grid style={{margin: 0}}>
                        <Transition visible={this.state.isAddChatRoomSectionOpen} unmountOnHide duration='100' animation='slide down'>
                            <Grid.Row>
                                <Grid.Column>
                                    <Segment basic style={{paddingTop: 0, paddingBottom: 0}}>
                                        <AddChatRoomSection onCancel={this.onCloseAddChatRoomSection} />
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Transition>
                        <Grid.Row style={{padding: 0}}>
                            <Grid.Column style={{padding: 0}}>
                                <ChatRoomsTable />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                } />
        )
    }
}

export default ChatRooms;
