import initialState from './initialState';

export default (state = initialState, { type, ...action }) => {
    switch (type) {
        case 'TEXT_UI_SELECT_CONTACT_PHONE':
            return { ...state, to: action.contact_phone };
        case 'OPEN_SIDE_PANEL':
            return { ...state, ...action }
        case 'CLOSE_SIDE_PANEL':
            return { ...state, ...action }
        case 'CHANGE_ACTIVE_MESSAGE':
            return { ...state, ...action }
        case 'CLOSE_MESSAGE_IN_VIEW':
            return { ...state, ...action }
        case 'OPEN_MAIN_REPLY_FORM':
            return { ...state, ...action }
        case 'CLOSE_MAIN_REPLY_FORM':
            return { ...state, ...action }
        default:
            return state
    }
}
