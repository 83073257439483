import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function assignmentReducer(state = {}, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.ASSIGNMENT_TERRITORIES_TEAMS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingTerritoriesTeams: true };
        case getNamedAction(ACTION_NAMES.ASSIGNMENT_TERRITORIES_TEAMS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingTerritoriesTeams: false, territories_teams: { teams: action.data.teams, territories: action.data.territories } };
        case getNamedAction(ACTION_NAMES.ASSIGNMENT_TERRITORIES_TEAMS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingTerritoriesTeams: false };

        case getNamedAction(ACTION_NAMES.ASSIGNMENT_UNIQUE_NAME, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingUniqueName: true };
        case getNamedAction(ACTION_NAMES.ASSIGNMENT_UNIQUE_NAME, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingUniqueName: false, isUniqueAssignmentName: action.data };
        case getNamedAction(ACTION_NAMES.ASSIGNMENT_UNIQUE_NAME, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingUniqueName: false };

        default:
            return state;
    }
}

export default assignmentReducer;
