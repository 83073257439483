import ACTION_NAMES from "actions/ACTION_NAMES";
import {
    generateReadActionCreator,
    generateUpdateActionCreator,
} from "actions/crudActionCreators";
import routes from "actions/routes";

export default {
    getFields: generateReadActionCreator(
        routes.API_SUPPLY_CHAIN_NETWORK_FIELDS,
        ACTION_NAMES.SUPPLY_CHAIN_NETWORK
    ),
    patchField: generateUpdateActionCreator(
        routes.API_SUPPLY_CHAIN_NETWORK_FIELDS,
        ACTION_NAMES.SUPPLY_CHAIN_NETWORK,
        () => {},
        () => {},
        null,
        true
    ),
};
