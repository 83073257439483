import { AttachableItem } from 'dyl-components';
import React, { useState } from 'react';
import { Divider, Input, Segment, Tab } from 'semantic-ui-react';

import { StringUtils } from 'utils';

// TODO: use standard fields from the custom-fields-api

const standardFields = [
    {
        name: 'first_name', label: 'First Name', type: 'text'
    },
    {
        name: 'last_name', label: 'Last Name', type: 'text'
    },
    {
        name: 'phone', label: 'Phone', type: 'phone'
    },
    {
        name: 'email', label: 'Email', type: 'email'
    },
    {
        name: 'address', label: 'Address', type: 'address'
    },
    {
        name: 'date_of_birth', label: 'Date of Birth', type: 'date'
    }
];

const socialMediaFields = [
    {
        name: 'linkedin', label: 'LinkedIn', type: 'text', icon: 'linkedin'
    },
    {
        name: 'facecbook', label: 'Facebook', type: 'text', icon: 'facebook'
    },
    {
        name: 'twitter', label: 'Twitter', type: 'text', icon: 'twitter'
    }
]

const standardLeadData = {
    fields: {
        data: [
            {
              "data": {},
              "description": "Indicates value or prospect of the lead (cold, warm, hot)",
              "field_name": "lead_rating",
              "field_type": "dropdown",
              "important": true,
              "label": "Lead Rating",
              "options": {
                "data": [
                  {
                    "value": "cold"
                  },
                  {
                    "value": "hot"
                  },
                  {
                    "value": "warm"
                  }
                ]
              },
              "required": true
            },
            {
              "data": {},
              "description": "Indicates priority of the lead (high, medium, low)",
              "field_name": "lead_priority",
              "field_type": "dropdown",
              "important": true,
              "label": "Lead Priority",
              "options": {
                "data": [
                  {
                    "value": "high"
                  },
                  {
                    "value": "low"
                  },
                  {
                    "value": "medium"
                  }
                ]
              },
              "required": true
            },
            {
              "data": {},
              "description": "The Product Interest may be associated with a lead process.",
              "field_name": "product_interest",
              "field_type": "picklist",
              "id": 41,
              "important": true,
              "label": "Product Interest",
              "options": {
                "data": [
                  {
                    "value": "Auto"
                  },
                  {
                    "value": "Flooring"
                  },
                  {
                    "value": "Handyman"
                  },
                  {
                    "value": "Healthy"
                  },
                  {
                    "value": "Home"
                  },
                  {
                    "value": "HVAC"
                  },
                  {
                    "value": "Life"
                  },
                  {
                    "value": "Roofing"
                  },
                  {
                    "value": "Window"
                  }
                ]
              },
              "required": true
            }
          ]
    }
}

export const FieldToggles = ({ activeFields, onToggleField }) => {
    const [search, setSearch] = useState('');

    const filterFieldsByLabel = () => {
        return field => field.label.toLowerCase().includes(search.toLowerCase());
    }

    const contactStandardFields = standardFields.slice(0).filter(filterFieldsByLabel());
    const standardSocialMediaFields = socialMediaFields.slice(0).filter(filterFieldsByLabel());
    const leadStandardFields = standardLeadData.fields.data.slice(0).map(({ 
        field_name, field_type, options, ...field }) => ({...field, name: field_name, type: field_type, options: options.data.map(({ value }) => ({ key: value, value, text: StringUtils.capitalize(value)}))})).filter(filterFieldsByLabel());

    return (
        <Tab
            menu={{ color: 'primary', fluid: true, className: 'centered Webform__field-categories' }}
            panes={[
                {
                    menuItem: 'Standard',
                    render: () => (
                        <React.Fragment>
                            <Divider hidden />
                            <Input
                                fluid
                                size='small'
                                icon='search'
                                iconPosition='left'
                                type='search'
                                placeholder='Search for a field name'
                                className='Webform__field-search'
                                onChange={(_, { value }) => {setSearch(value)}}
                            />
                            <div className='Webform__field-options'>
                                {contactStandardFields.map(field => {
                                    return (
                                        <div className='Webform__field-option'>
                                            <AttachableItem
                                                key={field.name}
                                                active={!!activeFields.find(activeField => activeField.name === field.name)}
                                                label={field.label}
                                                name={field.name}
                                                size={'tiny'}
                                                onToggle={() => { onToggleField(field); }} 
                                            />
                                        </div>
                                    );
                                })}
                                {standardSocialMediaFields.length > 0 && (
                                    <Segment size='tiny' textAlign='left' className='Webform__field-category'>
                                        <div>
                                            <strong>Social Media</strong>
                                        </div>
                                        {standardSocialMediaFields.map(field => (
                                            <AttachableItem
                                                key={field.name}
                                                active={!!activeFields.find(activeField => activeField.name === field.name)}
                                                label={field.label}
                                                name={field.name}
                                                size={'tiny'}
                                                onToggle={() => { onToggleField(field); }} />
                                        ))}
                                    </Segment>
                                )}
                                {leadStandardFields.length > 0 && (
                                    <Segment size='tiny' textAlign='left' className='Webform__field-category'>
                                        <div>
                                            <strong>Lead</strong>
                                        </div>
                                        {leadStandardFields.map(field => (
                                            <AttachableItem
                                                key={field.name}
                                                active={!!activeFields.find(activeField => activeField.name === field.name)}
                                                label={field.label}
                                                name={field.name}
                                                size={'tiny'}
                                                onToggle={() => { onToggleField(field); }} />
                                        ))}
                                    </Segment>
                                )}
                            </div>
                        </React.Fragment>
                    )
                },
                {
                    menuItem: 'Custom'
                }
            ]} />
    );
}
