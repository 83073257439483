import React from 'react';
import { Form } from 'semantic-ui-react';

const AddTemplateSection = ({ name, text, isEnabled, onChange, onAddTemplate, onClose }) => (
    <Form>
        <Form.Input 
            label='Script Name'
            name='name'
            value={name}
            onChange={onChange}
        />
        <Form.Radio 
            label='Script Status'
            onChange={(e, { name, checked }) => {onChange(e, { name, value: checked })}}
            name='isEnabled'
            checked={isEnabled}
            toggle
        />
        <Form.TextArea 
            label='Script Text'
            name='text'
            value={text}
            onChange={onChange}
        />
        <Form.Group>
            <Form.Button
                floated='right'
                content={'Cancel'}
                onClick={onClose}
            />
            <Form.Button 
                primary
                floated='right'
                content={'Add'}
                onClick={onAddTemplate}
            />
        </Form.Group>
    </Form>
)

class AddTemplateSectionContainer extends React.Component {
    state = {
        name: '',
        isEnabled: false,
        text: ''
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onAddTemplate = () => {
        this.props.onAddTemplate({ ...this.state });
        this.setState({
            name: '',
            text: ''
        });
    }

    render() {
        return (
            <AddTemplateSection 
                {...this.props}
                {...this.state}
                onAddTemplate={this.onAddTemplate}
                onChange={this.onChange}
            />
        )
    }
}

export default AddTemplateSectionContainer;
