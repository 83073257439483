import types from './types';

import routes from 'actions/routes';
import { generateReadActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

const getSmsList = generateReadActionCreator(routes.API_SMS_LIST, ACTION_NAMES.SMS_LIST);

function markLeadInTextList(id) {
  return (dispatch, getState) => {
      let smsList = getState().smsList.smsList.slice(0);
      let leadToMark = smsList.filter(smsList => smsList.id === id)[0];
      if (leadToMark) {
          leadToMark.isMarked = !leadToMark.isMarked;
          dispatch({
              type: types.MARK_LEAD_IN_TEXT_LIST,
              smsList
          });
      }
  };
}

function markAllLeadsInTextList() {
  return (dispatch, getState) => {
      let smsList = getState().smsList.smsList.slice(0);
      let allMarked = !getState().smsList.allMarked;
      smsList.forEach(lead => lead.isMarked = allMarked);
      dispatch({
          type: types.MARK_ALL_LEADS_IN_TEXT_LIST,
          smsList,
          allMarked
      });
  };
}

function onConsent(id) {
  return (dispatch, getState) => {
    let smsList = getState().smsList.smsList.slice(0);
    let leadToConsent = smsList.filter(smsList => smsList.id === id)[0];
    if (leadToConsent) {
      leadToConsent.isConsented = !leadToConsent.isConsented;
      dispatch({
        type: types.TEXT_LIST_LEAD_CONSENT,
        smsList
      });
    }
  }
}


export default {
  getSmsList,
  markLeadInTextList,
  markAllLeadsInTextList,
  onConsent
};
