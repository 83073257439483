import React from 'react';
import { Table as SemanticTable } from 'semantic-ui-react';

const CustomerPipelineSubrowHeader = () => {
    return (
        < React.Fragment >
            <SemanticTable.HeaderCell width={2}>
                Activity Type
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={3}>
                Message/Notes
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={1}>
                User
            </SemanticTable.HeaderCell>
        </React.Fragment >
    );
}

export default CustomerPipelineSubrowHeader;
