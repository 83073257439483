import React from 'react';
import { ShortcodeButton } from 'dyl-components';

const generateShortcodeValue = (variable) => (
    `%%${variable}%%`
)

const SHORTCODES = [
    {name: 'Address', value: generateShortcodeValue('ADDRESS')},
    {name: 'My Full Name', value: generateShortcodeValue('AGENT')},
    {name: 'My Company', value: generateShortcodeValue('AGENT_COMPANY')},
    {name: 'My First Name', value: generateShortcodeValue('AGENT_PHONE')},
    {name: 'City', value: generateShortcodeValue('CITY')},
    {name: 'First Name', value: generateShortcodeValue('FIRST')},
    {name: 'Last Name', value: generateShortcodeValue('LAST')},
    {name: 'Full Name', value: generateShortcodeValue('NAME')},
    {name: 'Contact Phone', value: generateShortcodeValue('PHONE')},
    {name: 'State', value: generateShortcodeValue('STATE')},
    {name: 'Zip', value: generateShortcodeValue('ZIP')}
]

const ShortcodeButtons = ({ onAddShortcode = () => {}, currentlyFocusedElement }) => (
    SHORTCODES.map(shortcode => (
        <ShortcodeButton key={shortcode.name} onAddShortcode={() => {onAddShortcode(currentlyFocusedElement, shortcode.value);}}>
            {shortcode.name}
        </ShortcodeButton>
    ))
)

export default ShortcodeButtons;
