import { ButtonLink, Icon } from "dyl-components";
import React from "react";

const RemoveUndoToggle = ({onChange, value}) => {
    return (
        <ButtonLink onClick={(event) => onChange(event, !value)} className="CallRoutingRule___elementContainer">
            {value ? (
                <Icon className="fa-sharp fa-solid fa-arrow-rotate-left" color="primary" />
            ) : (
                <Icon className="fa-solid fa-trash CallRoutingRuleModal__redIcon" />
            )}
        </ButtonLink>
    )
}

export default RemoveUndoToggle;