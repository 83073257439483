import React, { useContext } from 'react';
import { QuotesAndOrdersHeader } from './Header';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RecordsTable } from 'dyl-components';
import QuotesAndOrdersRow from './Row';
import { CURRENT_STEPS, QuoteBuilderContext } from 'shared/context/QuoteBuilderProvider';
import QuoteBuilderModal from 'shared/modals/QuoteBuilderModal';

const QuotesAndOrdersTable = ({ isColumnHidden, onPageChange }) => {
    const { count, quotesAndOrders, isReadingQuotesAndOrders } = useSelector(state => ({
        count: state.quotes_and_orders.count,
        quotesAndOrders: state.quotes_and_orders.entries,
        isReadingQuotesAndOrders: state.quotes_and_orders.isReadingQuotesAndOrders
    }));

    const [params] = useSearchParams();

    const { quoteBuilderConfig: { id: entryBeingViewed, currentStep } } = useContext(QuoteBuilderContext);

    const toView = (() => {
        if (currentStep === CURRENT_STEPS.PROPOSAL_PREVIEW) {
            return "quote_pdf_id";
        }
        if (currentStep === CURRENT_STEPS.INVOICE) {
            return "invoice_id";
        }
        return "id";
    })();

    const { account, contact, opportunity } = quotesAndOrders.find(entry => entry[toView] === entryBeingViewed) || { account: {}, contact: {}, opportunity: {} };

    return (
        <>
            <RecordsTable
                tableBodyClassName='QuotesAndOrdersTable'
                isTableLoading={isReadingQuotesAndOrders}
                isSearchResultEmpty={count === 0 && (params.get('start') || params.get('end'))}
                isTableEmpty={count === 0 && !(params.get('start') || params.get('end'))}
                TableHeader={<QuotesAndOrdersHeader isColumnHidden={isColumnHidden} />}
                TableRows={quotesAndOrders.map(log => (
                    <QuotesAndOrdersRow key={log.id} entry={log} isColumnHidden={isColumnHidden} />
                ))}
                recordsCount={count}
                activePage={params.get('page')}
                onPageChange={onPageChange}
                recordsName='Quotes or Orders'
                emptySubtitle=' '
                emptyListTitle='No Quotes or Orders Created'
            />
            <QuoteBuilderModal
                account_id={account?.id}
                contact_id={contact?.id}
                opportunity_id={opportunity?.id}
                account_primary_contact_id={account?.primary_contact_id}
                isLinkedToCustomer={account?.customer}
            />
        </>
    )
}

export default QuotesAndOrdersTable;
