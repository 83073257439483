import { Sequences, SequenceAdd, SequenceEdit, SequenceDuplicate } from 'pages';
import CreateRuleContainer from 'pages/CreateRule';

const PARENT_NAME = "automation";

export const AUTOMATION_SETTINGS = [
    {
        path: 'sequences',
        icon: 'fas fa-sitemap',
        header: 'Sequences',
        description: 'Design inbound lead routing and assignment rules.',
        page: Sequences,
        type: `${PARENT_NAME}.sequences`
    },
    {
        path: 'sequences/create',
        icon: 'fas fa-sitemap',
        header: 'Create Sequence',
        description: 'Design inbound lead routing and assignment rules.',
        page: SequenceAdd,
        exclude: true,
        parent: {
            path: 'sequences',
            name: 'Sequences'
        }
    },
    {
        path: `sequences/:id`,
        icon: 'fas fa-sitemap',
        header: 'Edit Sequence',
        description: 'Design inbound lead routing and assignment rules.',
        page: SequenceEdit,
        exclude: true,
        parent: {
            path: 'sequences',
            name: 'Sequences'
        }
    },
    {
        path: `sequences/duplicate/:id`,
        icon: 'fas fa-sitemap',
        header: 'Duplicate Sequence',
        description: 'Design inbound lead routing and assignment rules.',
        page: SequenceDuplicate,
        exclude: true,
        parent: {
            path: 'sequences',
            name: 'Sequences'
        }
    },
    {
        path: 'assignment-rules/assignment-rules',
        icon: 'fas fa-screen-users',
        header: 'Assignment Rules',
        description: 'Set up assignment rules, including default routing.',
        page: null
    },
    {
        path: 'assignment-rules/create',
        icon: 'fas fa-screen-users',
        header: 'Add New Rule',
        description: 'Set up assignment rules, including default routing.',
        page: CreateRuleContainer,
        exclude: true,
        parent: {
            path: 'assignment-rules/assignment-rules',
            name: 'Assignment Rules'
        }
    },
    {
        path: 'assignment-rules/edit/:rule_id',
        icon: 'fas fa-screen-users',
        header: 'Edit Rule',
        description: 'Set up assignment rules, including default routing.',
        page: CreateRuleContainer,
        exclude: true,
        parent: {
            path: 'assignment-rules/assignment-rules',
            name: 'Assignment Rules'
        }
    },
];
