import routes from 'actions/routes';
import { generateReadActionCreator, generateCreateActionCreator, generateUpdateActionCreator, generateDeleteActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';

import axiosInstance from 'actions/axiosInstance';

const getTemplates = generateReadActionCreator(routes.API_EMAIL_TEMPLATES, ACTION_NAMES.EMAIL_TEMPLATES);
const createTemplates = generateCreateActionCreator(routes.API_EMAIL_TEMPLATES, ACTION_NAMES.EMAIL_TEMPLATES);
const updateTemplate = generateUpdateActionCreator(routes.API_EMAIL_TEMPLATES, ACTION_NAMES.EMAIL_TEMPLATES);
const deleteTemplate = generateDeleteActionCreator(routes.API_EMAIL_TEMPLATES, ACTION_NAMES.EMAIL_TEMPLATES);

export default {
    getTemplates,
    createTemplates,
    getTemplate: (id) => async (dispatch) => {
        dispatch({
            type: getNamedAction(ACTION_NAMES.EMAIL_TEMPLATES, CRUD_ACTION_TYPES.READ_ONE_REQUEST)
        });

        try {
            const { data } = await axiosInstance.instance1.get(`${routes.API_EMAIL_TEMPLATES}/${id}`);
            dispatch({
                type: getNamedAction(ACTION_NAMES.EMAIL_TEMPLATES, CRUD_ACTION_TYPES.READ_ONE_SUCCESS),
                data,
                id
            });
            return data;
        } catch (error) {
            dispatch({
                type: getNamedAction(ACTION_NAMES.EMAIL_TEMPLATES, CRUD_ACTION_TYPES.READ_ONE_FAILURE)
            });
            return Promise.reject(error.response?.data);
        }


    },
    updateTemplate,
    deleteTemplate
}
