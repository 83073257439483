import { Segment, Header, Form, Icon } from "semantic-ui-react";
import { Controller, useFormContext } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import productsActions from "actions/products";

const ProductCategoryDetails = () => {
    const dispatch = useDispatch();
    const { control, setValue } = useFormContext();

    const [options, setOptions] = useState([]);

    const { isReadingCategoryProducts } = useSelector((state) => {
        return {
            isReadingCategoryProducts: state.products.isReadingCategoryProducts,
        };
    });

    const getProductCatalogs = useCallback(() => {
        return dispatch(productsActions.readProducts());
    }, [dispatch]);

    useEffect(() => {
        getProductCatalogs().then((response) => {
            setOptions(
                response.categories.map((category) => ({
                    key: category.id,
                    value: category.id,
                    text: category.name,
                    category_description: category.description,
                }))
            );
        });
    }, [dispatch, getProductCatalogs]);

    const onAddOption = (_, { value }) => {
        const trimmedValue = value?.trim();
        if (trimmedValue) {
            setOptions([
                ...options.filter((option) => typeof option.value === "number"),
                {
                    key: trimmedValue,
                    value: trimmedValue,
                    text: trimmedValue,
                    description: "",
                },
            ]);
        }
    };

    return (
        <Segment>
            <Header color="primary" as="h3">
                Category Details
            </Header>
            <Controller
                control={control}
                name="product_category.name"
                render={({
                    field: { name, value: selectedCategory, onChange }, fieldState: { error }
                }) => {
                    const isNew = typeof selectedCategory === "string";
                    return (
                        <>
                            <Form.Dropdown
                                width={8}
                                error={error?.message}
                                label="Category"
                                placeholder={
                                    <div>
                                        <Icon
                                            style={{ marginRight: 10 }}
                                            name="search plus"
                                        />
                                    </div>
                                }
                                name={name}
                                search
                                allowAdditions
                                selection
                                value={selectedCategory}
                                selectOnBlur={false}
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } });
                                    setValue(
                                        "product_category.description",
                                        ""
                                    );
                                    if (
                                        isNew &&
                                        (typeof value !== "string" || !value)
                                    ) {
                                        setOptions(
                                            options.filter(
                                                (option) =>
                                                    typeof option.value ===
                                                    "number"
                                            )
                                        );
                                    }
                                }}
                                onAddItem={onAddOption}
                                options={options}
                                noResultsMessage="No Categories Found"
                                loading={isReadingCategoryProducts}
                                clearable
                            />
                            {selectedCategory && (
                                <Controller
                                    label="Product Description"
                                    control={control}
                                    name="product_category.description"
                                    render={({
                                        field: { name, value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <Form.TextArea
                                            label="Category Description"
                                            name={name}
                                            value={
                                                isNew
                                                    ? value
                                                    : options.find(
                                                          (option) =>
                                                              option.value ===
                                                              selectedCategory
                                                      )?.category_description
                                            }
                                            onChange={(_, { value }) => {
                                                onChange({
                                                    target: { name, value },
                                                });
                                            }}
                                            error={error?.message}
                                            readOnly={!isNew}
                                        />
                                    )}
                                />
                            )}
                        </>
                    );
                }}
            />
        </Segment>
    );
};

export default ProductCategoryDetails;
