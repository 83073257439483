import React from 'react';
import HeaderContainer from 'shared/HeaderContainer';

const withBreadcrumb = (WrappedComponent) => (props) => {
    return (
        <HeaderContainer
            {...props}
            details={props.details}
            WrappedComponent={WrappedComponent}
        />
    )
}

export default withBreadcrumb;
