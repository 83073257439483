import React, { useEffect, useState } from 'react'
import { Grid, Header, Form } from 'semantic-ui-react';
import { generateResolver, yup, ClippedContent } from 'dyl-components';
import { Controller, useForm } from 'react-hook-form';
import '../index.scss';

export const ParseTemplateHeader = ({data, match_subject, match_from}) => {
    const [isSubjectDisabled, setSubjectDisabled] = useState(false);
    const [firstSub, setFirstSub] = useState(true);
    const {email_id, from, subject, onChangeParsingFromSubject, isEmail, post_id, code} = data;
    const fromSubstring = from ? from.split('@') : [];
    const EMAIL_OPTIONS = [
        {
            key: 'full_email',
            value: from,
            text: 'Full email'
        },
        {
            key: 'email_domain_only',
            value: fromSubstring.length === 2 ? `@${fromSubstring[1]}` : '',
            text: 'Domain only'
        }
    ]
    const { control, setValue, getValues, setError, clearErrors, watch } = useForm({
        mode: 'onChange',
        resolver: generateResolver({
            subject: yup.string().required('This field is required'),
        })
    });

    const checkSubjectSubString = () => {
        const subjectValues = getValues('subject');
        const isSubjectSame = subject.includes(subjectValues);
        if(!isSubjectSame){
            setError('subject', { type:'custom', message:'Needs to match'})
        } 
        if(isSubjectSame){
            setValue('subject', subjectValues);
            clearErrors('subject');
            getFromSubjectValues();
        }

    }

    const getFromSubjectValues = () => {
        const { email, subject=null } = getValues();
        onChangeParsingFromSubject({ email, subject });
    }

    const emailWatch = watch('email', from); 
    useEffect(()=>{
        setValue('email', match_from || emailWatch || from);
        getFromSubjectValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValue, from, emailWatch])

    const subjectWatch = watch('subject', subject ); 
    useEffect(()=>{
		setSubjectDisabled(match_subject?.length > 0);
    }, [match_subject])
    useEffect(()=>{
        if(isSubjectDisabled){
			if (firstSub) { 
				setFirstSub(false);
				setValue('subject', match_subject);
			} else {
				setValue('subject', subjectWatch || subject);
			}
        }
        if(!isSubjectDisabled){
            setValue('subject', '');
            clearErrors('subject');
        }
        getFromSubjectValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubjectDisabled, setValue, subject, watch, match_subject])

  return (
      <Grid >
          <Grid.Row style={{paddingBottom: 0}}>
                <Grid.Column width={8} verticalAlign='middle'>
                    <Grid style={{paddingBottom: 64}}>
                        <Grid.Row>
                            <Grid.Column width={2}>
                                <Header as="h4" style={{display: 'inline'}}>ID:</Header>
                                <ClippedContent>{isEmail ? email_id : post_id}</ClippedContent>
                            </Grid.Column>
                            {isEmail &&
                                <Grid.Column width={7}>
                                    <Header as="h4" style={{display: 'inline'}}>From:</Header>
                                    <ClippedContent >
                                        <a href={`mailto:${from}`}>{from}</a>
                                    </ClippedContent>
                                </Grid.Column>
                            }
                            <Grid.Column width={7}>
                                <Header as="h4" style={{display: 'inline'}}>{isEmail ? 'Subject' : 'Post Code'}:</Header>
                                <ClippedContent>{isEmail ? subject : code}</ClippedContent>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
               {isEmail && 
                <Grid.Column width={8}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={3}>
                                <Header as="h4">Parsing fields:</Header>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Controller
                                    name='email'
                                    control={control}
                                    defaultValue={''}
                                    render={({ field: { onChange, value, name }, fieldState: { error } }) =>
                                        <Form.Input
                                            width={5}
                                            required
                                            label='Email'
                                            onChange={onChange}
                                            name={name}
                                            value={value}
                                            error={error && error.message && {
                                                content: error.message,
                                                pointing: 'below'
                                            }}
                                            placeholder='Email'
                                            readOnly
                                        />    
                                    }
                                />
                                <Controller
                                    name='email'
                                    defaultValue={ "" }
                                    control={control}
                                    render={({ field: { onChange, value, name }, fieldState: { error } }) =>
                                        <Form.Group inline>
                                            {EMAIL_OPTIONS.map((option) => (
                                                <Form.Radio
                                                    key={option.key}
                                                    label={<label style={{ paddingLeft: '1.4em' }}>{option.text}</label>}
                                                    value={option.value}
                                                    checked={value === option.value}
                                                    onChange={() => { onChange ({ target: { name: name, value: option.value } }) }}
                                                />
                                            ))}
                                        </Form.Group>
                                    }
                                />
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Controller
                                    name='subject'
                                    control={control}
                                    defaultValue={ '' }
                                    render={({ field: { onChange, value, name }, fieldState: { error } }) =>
                                        <Form.Input
                                            width={5}
                                            label='Subject'
                                            onChange={onChange}
                                            name={name}
                                            value={value}
                                            error={error && error.message && {
                                                content: error.message,
                                                pointing: 'below'
                                            }}
                                            disabled={!isSubjectDisabled}
                                            onBlur={()=>checkSubjectSubString()}
                                        />
                                    }
                                />
                                <Controller
                                    name='subject_checkbox'
                                    control={control}
                                    defaultValue={''}
                                    render={() =>
                                        <Form.Checkbox
                                            key='subject_checkbox'
                                            label={<label style={{ paddingLeft: '1.4em' }}>Use Subject</label>}
                                            value='subject_checkbox'
                                            checked={isSubjectDisabled}
                                            onChange={() => { setSubjectDisabled(!isSubjectDisabled) }}
                                        />
                                    }
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>     
                </Grid.Column>  
                }
          </Grid.Row>
      </Grid>
  );
}
