import React from 'react';
import { Table, TableLoader } from 'dyl-components';
import { Table as SemanticTable, } from 'semantic-ui-react';
import WebFormsTableHeader from './Header';
import WebFormRow from './Row';
import { DateTimeUtils } from 'dyl-components';
import { useSelector } from 'react-redux';

const WebFormsTable = ({ isReadingWebForms, search, limit }) => {
    const webforms = useSelector(state => state.webforms.webforms) || [];
    return (
        <Table unstackable>
            <SemanticTable.Header>
                <WebFormsTableHeader search={search} />
            </SemanticTable.Header>
            <Table.Body>
                {isReadingWebForms ? <TableLoader isLoading colspan={6} /> : (
                    webforms.map((webform, index) => (
                        <WebFormRow
                            key={webform.id}
                            webform={{
                                id: webform.id, 
                                formID: `${index+1}`.padStart(3, '0'), 
                                internalName: webform.internal_name, 
                                displayName: webform.display_name || 'None', 
                                created: {
                                    name: `${webform.created_by?.first_name} ${webform.created_by?.last_name}`,
                                    email: webform.created_by?.email,
                                    id: webform.created_by?.id
                                },
                                entries: webform.entries || 0, 
                                createdOn: DateTimeUtils.formatEpoch(webform.created_ts, DateTimeUtils.WORD_DATE_FORMAT), 
                                lastEdited: webform.updated_ts && DateTimeUtils.formatEpoch(webform.updated_ts, DateTimeUtils.WORD_DATE_FORMAT), 
                                opportunities: webform.opportunities || 0, 
                                customers: webform.customers || 0, 
                                status: webform.active ? 'Active' : 'Inactive',
                                activeFields: [],
                                form_background_color: '#f8f7ff',
                                page_background_color: '#a9a0ff',
                                use_logo: true
                            }}
                            limit={limit}
                        />
                    ))
                )}
            </Table.Body>
        </Table>
    );
}

export default WebFormsTable;
