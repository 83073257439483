import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { ClippedContent } from 'dyl-components';

import { StringUtils } from 'utils';

const SOCIAL_MEDIA_ICONS = {
    facebook: 'facebook',
    linkedin: 'linkedin',
    twitter: 'twitter'
}

const Socials = ({
    socials
}) => (
    socials.map((social) => (
        <Grid.Row columns='equal'>
            <Grid.Column width={4}>
                <b><Icon className={`fab fa-${SOCIAL_MEDIA_ICONS[social.type]}`} /></b>
            </Grid.Column>
            <Grid.Column>
                <ClippedContent>
                    <a target={'_blank'} href={StringUtils.formatLink(social.url)} rel="noreferrer">{social.url}</a>
                </ClippedContent>
            </Grid.Column>
        </Grid.Row>
    ))
);

export default Socials;
