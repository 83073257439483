import React from 'react';
import { PriorityIcon, ClippedContent } from 'dyl-components';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Form, Grid } from 'semantic-ui-react';
import { CustomGroupUtils, StringUtils } from 'utils';
import MasterSecondarySourceOptions from 'shared/forms/MasterSecondarySourceOptions';
import { Link } from 'react-router-dom';

const OpportunityInfo = ({
    isEditing,
    control,

    textAlign = 'right'
}) => {
    const current_stage = useSelector(state => state.contact.current_stage);
    const opportunity_name = current_stage?.pipeline_name;
    const opportunity_custom_data = current_stage?.custom_data || {};
    const fields = CustomGroupUtils.groupAndFlatten(opportunity_custom_data).fields;

    const type_options = opportunity_custom_data.fields.find(field => field.field_name === 'type')?.options?.data?.map(option => ({
        key: option.id,
        value: option.id,
        text: option.value
    })) || [];
    const confidence_level_options = opportunity_custom_data.fields.find(field => field.field_name === 'confidence_level')?.options?.data?.map(option => ({
        key: option.id,
        value: option.id,
        text: option.value,
        content: (
            <PriorityIcon type={option.value} />
        )
    })) || []

    const type = type_options.find(option => option.value === fields?.type)?.text || '';
    const confidence_level = confidence_level_options.find(option => option.value === fields?.confidence_level)?.text || '';
    const master_source = current_stage?.master_source;
    const secondary_source = current_stage?.secondary_source;

    const campaign_converted = Object.keys(current_stage.campaign_converted).length > 1 && (Boolean(current_stage.campaign_converted.id) && Boolean(current_stage.campaign_converted.name)) ?
        <Link to={`/campaigns/${current_stage.campaign_converted.id}/dashboard`}><ClippedContent>{current_stage.campaign_converted.name}</ClippedContent></Link> : 'None';

    return (
        <Grid className='RecordInfo'>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Opportunity Name
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    <ClippedContent popupContent={
                        <div style={{ maxWidth: '500px', wordWrap: 'break-word' }}>
                            {opportunity_name}
                        </div>
                    }>
                        {opportunity_name}
                    </ClippedContent>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Type
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        type || 'None'
                    ) : (
                        <Controller
                            name='type'
                            control={control}
                            render={({ field: { name, onChange, value } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Select Type'
                                    options={type_options}
                                    selectOnBlur={false}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <ClippedContent>
                        Master/Secondary Source
                    </ClippedContent>
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        master_source ? `${master_source}/${secondary_source?.name}` : 'None'
                    ) : (
                        <Controller
                            name='master_source'
                            control={control}
                            render={({ field: { name: masterSourceFieldName, value: masterSourceFieldValue, onChange: masterSourceOnChange } }) => (
                                <Controller
                                    name='secondary_source'
                                    control={control}
                                    render={({ field: { name: secondarySourceFieldName, value: secondarySourceValue, onChange: secondarySourceOnChange } }) => (
                                        <Form.Field
                                            control={MasterSecondarySourceOptions}
                                            onChange={(_, { parent_value: master_source, child_value: secondary_source }) => {
                                                masterSourceOnChange({ target: { name: masterSourceFieldName, value: master_source } });
                                                secondarySourceOnChange({ target: { name: secondarySourceFieldName, value: secondary_source } });
                                            }}
                                            value={secondarySourceValue}
                                            parentValue={masterSourceFieldValue}
                                            label='Master/Secondary Source'
                                            placeholder='Select Source'
                                        />
                                    )}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Campaign Converted
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {campaign_converted}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Description
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    <ClippedContent popupContent={
                        <div style={{ maxWidth: '500px', wordWrap: 'break-word' }}>
                            {fields?.description || 'None'}
                        </div>
                    }>
                        {fields?.description || 'None'}
                    </ClippedContent>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Pain/Need Notes
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    <ClippedContent popupContent={
                        <div style={{ maxWidth: '500px', wordWrap: 'break-word' }}>
                            {fields?.pain_need_notes || 'None'}
                        </div>
                    }>
                        {fields?.pain_need_notes || 'None'}
                    </ClippedContent>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Sales Strategy
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    <ClippedContent popupContent={
                        <div style={{ maxWidth: '500px', wordWrap: 'break-word' }}>
                            {fields?.sales_strategy_notes || 'None'}
                        </div>
                    }>
                        {fields?.sales_strategy_notes || 'None'}
                    </ClippedContent>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Confidence Level
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {!isEditing ? (
                        confidence_level ? <span><PriorityIcon type={confidence_level} /> {StringUtils.capitalize(confidence_level)}</span> : 'None'
                    ) : (
                        <Controller
                            name='confidence_level'
                            control={control}
                            render={({ field: { name, onChange, value } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Select Confidence Level'
                                    options={confidence_level_options}
                                    selectOnBlur={false}
                                />
                            )}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Forecast Amount
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {fields?.forecast_amount || 'None'}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Close Probability
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {fields?.close_probability}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Expected Close Date
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {fields?.expected_close_date}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Next Step Date
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {fields?.next_step_date}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Next Step
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {fields?.next_step}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default OpportunityInfo;
