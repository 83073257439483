import React from 'react';
import { connect } from 'react-redux';
import { Grid, List, Icon, ListItem, Input, Select, Dropdown, Checkbox } from 'semantic-ui-react';
import './index.scss';

const EditUserForm = ({ userAccount, onInputChange, isReadingTeams, teamOptions, roles }) => (
    <Grid columns={2}>
        <Grid.Column>
            <List relaxed size='small'>
                <ListItem
                    icon={
                        <Icon name='user outline' />
                    }
                    header='First Name'
                    description={
                        <Input value={userAccount.first_name} name='first_name' onChange={onInputChange} fluid />
                    }
                />
                <ListItem
                    icon={
                        <Icon name='user outline' />
                    }
                    header='Last Name'
                    description={
                        <Input value={userAccount.last_name} name='last_name' onChange={onInputChange} fluid />
                    }
                />
                <ListItem
                    icon={
                        <Icon name='mail' />
                    }
                    header='Email'
                    description={
                        <Input value={userAccount.email} name='email' onChange={onInputChange} fluid />
                    } />
                <ListItem
                    icon={
                        <Icon name='address book' />
                    }
                    header='Job Title'
                    description={
                        <Input onChange={onInputChange} name='job_title' value={userAccount.job_title} fluid />
                    } />
            </List>
        </Grid.Column>
        <Grid.Column>
            <List relaxed size='small'>
            <ListItem
                    icon={
                        <Icon name='users' />
                    }
                    header='Team(s)'
                    description={
                        <Dropdown multiple selection fluid
                            value={userAccount.teams}
                            onChange={onInputChange}
                            name='teams'
                            options={teamOptions}
                            loading={isReadingTeams}
                        />
                    } />
                <ListItem
                    icon={
                        <Icon name='lock' />
                    }
                    header='Permission Profile'
                    description={
                        <Select
                            value={userAccount.access_role}
                            name='access_role'
                            onChange={onInputChange}
                            options={roles}
                            fluid
                        />
                    } />
                <ListItem
                    icon={
                        <Icon name='bell' />
                    }
                    header='Allow Notifications'
                    description={
                        <Checkbox
                            toggle
                            onChange={(_, { name, checked }) => onInputChange(_, { name, value: checked })}
                            name='areNotificationsEnabled'
                        />
                    }
                />
            </List>
        </Grid.Column>
    </Grid>
)

const mapStateToProps = state => ({
    teamOptions: state.teams.teams.filter(({ deleted }) => !deleted).map(({ id, name }) => ({
        key: id,
        value: id,
        text: name
    })),
    isReadingTeams: state.teams.isReadingTeams,
    roles: state.roles.roles.map(({ id, name }) => ({
        key: id,
        value: id,
        text: name
    }))
})

export default connect(mapStateToProps)(EditUserForm);
