import { generateReadActionCreator, generateCreateActionCreator, generateUpdateActionCreator, generateReadOneActionCreator } from '../crudActionCreators';
import routes from 'actions/routes';
import ACTION_NAMES from 'actions/ACTION_NAMES';

import { Notification, STATUS_TYPES } from 'dyl-components';

const onCreateSuccess = () => {
  Notification.alert('Succesfully created permission profile!', STATUS_TYPES.SUCCESS, true);
}

const onUpdateSuccess = () => {
  Notification.alert('Succesfully updated permission profile!', STATUS_TYPES.SUCCESS, true);
}

export default {
    readRoles: generateReadActionCreator(routes.API_ACCESS_ROLES, ACTION_NAMES.ACCESS_ROLES),
    readRole:  generateReadOneActionCreator(routes.API_ACCESS_ROLE, ACTION_NAMES.ACCESS_ROLES),
    createRole: generateCreateActionCreator(routes.API_ACCESS_ROLES, ACTION_NAMES.ACCESS_ROLES, onCreateSuccess),
    updateRole: generateUpdateActionCreator(routes.API_ACCESS_ROLE, ACTION_NAMES.ACCESS_ROLES, onUpdateSuccess)
}
