import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function campaignsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CAMPAIGN_HISTORY, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingHistory: true, history: [] };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_HISTORY, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingHistory: false, history_count: action.data.count, history: action.data?.data || [] };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_HISTORY, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingHistory: false, history: [], history_count: 0 };

        default:
            return state;
    }
}


export default campaignsReducer;

