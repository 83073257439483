import React from "react";
import {
    CategorizedCheckboxDropdown,
    ClearableCheckboxDropdown,
    Icon,
    Table,
    TimestampFilters,
} from "dyl-components";
import { Table as SemanticTable } from "semantic-ui-react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    getData,
    onToggleTimeUnit,
    useRangeCalendar,
} from "shared/CustomDateRange/customDateRange";

import {
    KEY_CREATED_ON,
    KEY_OWNED_BY,
    KEY_LAST_MODIFIED,
    KEY_CONVERT,
    KEY_ACCOUNT,
    KEY_ACTIONS,
} from "./constants";
import { useSelector } from "react-redux";
import SelectAllCheckbox from "shared/SelectAllCheckbox";

const SortActions = ({ direction = false, onToggleSort = () => {} }) => {
    return (
        <div
            onClick={onToggleSort}
            style={{ display: "inline-flex", cursor: "pointer" }}
        >
            <Icon
                name="fa-solid fa-arrow-down-long"
                style={{
                    marginRight: -7,
                    opacity: !direction ? "100%" : "50%",
                }}
            />
            <Icon
                name="fa-solid fa-arrow-up-long"
                style={{ opacity: direction ? "100%" : "50%" }}
            />
        </div>
    );
};

const OWNED_BY_CATEGORIES = [
    {
        name: "Assigned Status",
        options: [
            { key: "All", value: "All", text: "All", all: true },
            { key: "Assigned", value: "Assigned", text: "Assigned" },
            { key: "Unassigned", value: "Unassigned", text: "Unassigned" },
        ],
    },
    {
        name: "Owned By",
        options: [
            { key: "Anyone", value: "Anyone", text: "Anyone", all: true },
            { key: "Myself", value: "Myself", text: "Myself" },
            { key: "My team", value: "My team", text: "My team" },
            {
                key: "My territory",
                value: "My territory",
                text: "My territory",
            },
        ],
    },
];

export const ContactsHeader = ({ onFilter, isColumnHidden, isInGroups }) => {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const { group_id } = useParams();

    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        userAuthTimezone,
        dateRange,
        setDateRange,
    } = useRangeCalendar(params);

    const createdOrder =
        (params.get("order_by") === "created" || group_id) &&
        params.get("order") === "asc";
    const lastModifiedOrder =
        params.get("order_by") === "activity" && params.get("order") === "asc";

    const onDateRangeChange = (_, { value }) => {
        setDateRange(value);
    };

    const createdOrderFilter = () => {
        const query = new URLSearchParams(params);
        if (!group_id) {
            const order = !createdOrder ? "asc" : "desc";
            query.set("order", order);
            query.set("order_by", "created");
        } else {
            const order = params.get("order") !== "asc" ? "asc" : "desc";
            query.set("order", order);
        }
        const query_string = query.toString();
        navigate(
            `${group_id ? `/group/${group_id}` : "/contacts"}${
                query_string ? `?${query_string}` : ""
            }`
        );
    };

    const lastModifiedOrderFilter = () => {
        const query = new URLSearchParams(params);
        const order = !lastModifiedOrder ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", "activity");
        const query_string = query.toString();
        navigate(`/contacts${query_string ? `?${query_string}` : ""}`);
    };

    const { count, contacts = [] } = useSelector((state) => {
        if (!isInGroups) {
            return state.contacts;
        }
        if (group_id !== "hotlist") {
            return {
                count: state.groups.contactCount,
                contacts: state.groups.contacts,
            };
        }
        return {
            count: state.groups.hotlistCount,
            contacts: state.groups.hotlistContacts,
        };
    });

    return (
        <Table.Row>
            <SemanticTable.HeaderCell
                width={1}
                className="AccountsTable__SmallFixedWidth"
            >
                <SelectAllCheckbox
                    count={count}
                    data={contacts}
                    type={"contacts"}
                    idProperty={"id"}
                />
            </SemanticTable.HeaderCell>
            {!isColumnHidden(KEY_CREATED_ON) && (
                <SemanticTable.HeaderCell
                    className="AccountsTable__MediumFixedWidth"
                    width={2}
                >
                    <span style={{ marginRight: 5 }}>Created</span>
                    <TimestampFilters
                        timestamp={timestamp}
                        onToggleTimeUnit={(timestamp) =>
                            onToggleTimeUnit(
                                timestamp,
                                setTimestamp,
                                params,
                                navigate,
                                `${
                                    group_id
                                        ? `/group/${group_id}`
                                        : "/contacts"
                                }`
                            )
                        }
                        isCustomDateRangeOpen={isCustomDateRangeOpen}
                        onOpenCustomDateRange={() => {
                            setCustomDateRangeOpen(true);
                        }}
                        onCloseCustomDateRange={() => {
                            setCustomDateRangeOpen(false);
                        }}
                        dateRange={dateRange}
                        onDateRangeChange={onDateRangeChange}
                        getData={() =>
                            getData({
                                setCustomDateRangeOpen,
                                dateRange,
                                userAuthTimezone,
                                params,
                                navigate,
                                url: `${
                                    group_id
                                        ? `/group/${group_id}`
                                        : "/contacts"
                                }`,
                            })
                        }
                    />

                    <SortActions
                        direction={createdOrder}
                        onToggleSort={() => {
                            createdOrderFilter();
                        }}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED) && (
                <SemanticTable.HeaderCell
                    className="AccountsTable__MediumFixedWidth"
                    width={2}
                >
                    Last Modified
                    <SortActions
                        direction={lastModifiedOrder}
                        onToggleSort={() => {
                            lastModifiedOrderFilter();
                        }}
                    />
                </SemanticTable.HeaderCell>
            )}
            <SemanticTable.HeaderCell
                className="AccountsTable__LargeFixedWidth"
                width={4}
            >
                Contact Name
            </SemanticTable.HeaderCell>
            {!isColumnHidden(KEY_ACCOUNT) && (
                <SemanticTable.HeaderCell
                    className="AccountsTable__MediumFixedWidth"
                    width={2}
                >
                    <ClearableCheckboxDropdown
                        text="Account"
                        name="account_type"
                        options={[
                            {
                                key: "household",
                                value: "household",
                                text: "Household",
                            },
                            {
                                key: "business",
                                value: "business",
                                text: "Business",
                            },
                        ]}
                        onChange={(_, { value }) => {
                            onFilter(_, { name: "account_type", value });
                        }}
                        value={params.get("account_type")?.split(",")}
                        closeOnLoad={true}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_OWNED_BY) && (
                <SemanticTable.HeaderCell
                    className="AccountsTable__MediumFixedWidth"
                    width={2}
                >
                    <CategorizedCheckboxDropdown
                        text="Owned By"
                        name="owned_by"
                        categories={OWNED_BY_CATEGORIES}
                        onChange={(_, { value }) => {
                            onFilter(_, { name: "owned_by", value });
                        }}
                        value={params.get("owned_by")?.split(",")}
                        closeOnLoad={true}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CONVERT) && (
                <SemanticTable.HeaderCell
                    className="AccountsTable__MediumFixedWidth"
                    width={2}
                >
                    Convert
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_ACTIONS) && (
                <SemanticTable.HeaderCell className="AccountsTable__SmallFixedWidth">
                    Actions
                </SemanticTable.HeaderCell>
            )}
        </Table.Row>
    );
};
