import { Controller, useFormContext } from "react-hook-form";
import { Form } from "semantic-ui-react";
import { StringUtils } from "utils";

const PriceField = ({ name, label, width, step }) => {
    const { control } = useFormContext();
    return (
        <Controller
            control={control}
            name={name}
            render={({
                field: { value, onChange },
                fieldState: { error },
            }) => (
                <Form.Input
                    {...width ? { width } : {}}
                    name={name}
                    value={value}
                    onChange={(_, { value }) => {
                        onChange({ target: { name, value: StringUtils.formatDecimal(value) } });
                    }}
                    error={error?.message}
                    label={label}
                    required
                    icon={"fas fa-dollar-sign"}
                    iconPosition="left"
                    {...(step ? { type: "number", step } : {})}
                />
            )}
        />
    );
};

export default PriceField;
