import React from 'react';
import { Grid } from 'semantic-ui-react';
import EmailSender from './EmailSender';
import EmailIntegrations from './EmailIntegrations';
import EmailPreferences from './EmailPreferences';

const Email = () => (
    <Grid>
        <Grid.Row>
            <Grid.Column>
                <EmailSender />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <EmailIntegrations />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <EmailPreferences />
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

export default Email;
