import React, { useState } from 'react';
import { Dropdown, Form, Header, Icon, Popup, Ref } from 'semantic-ui-react';

import { useDispatch, useSelector } from 'react-redux';
import accountActions from "actions/account";
import {
    ConfirmationPrompt,
    Modal,
    STATUS_TYPES,
    Notification,
    CloseableContent
} from 'dyl-components';
import { NavLink, useParams } from 'react-router-dom';

import 'shared/ProfileControls.scss';
import LinkToMasterAccountForm from "shared/forms/LinkToMasterAccountForm";
import masterAccountActions from "actions/master_account";
import CustomerPipelineForm from 'shared/forms/CustomerPipelineForm';
import LostForm from 'shared/forms/LostForm';
import ChangeCustomerPipelineForm from './ChangeCustomerPipelineForm';

const Controls = () => {
    const {
        phone,
        canCall,
        canText,
        master_account,
        account_type,
        accountBeingUnlinked,
        pipeline,
        customer_status,
        isUpdatingPipeline,
        primary_contact_id
    } = useSelector(state => {
        return {
            phone: state.contact_phone.phone[0] ? `${state.contact_phone.phone[0].phone}` : "",
            canCall: state.users.userProfile.cell_phone,
            canText: state.users.userProfile.cell_phone,

            master_account: state.account.account.master_account?.id,
            account_type: state.account.account?.account_type,
            accountBeingUnlinked: state.master_account.accountBeingUnlinked,
            pipeline: state.account.account?.customer?.customer_pipeline,
            customer_status: state.account.account?.customer?.customer_status,
            isUpdatingPipeline: state.customer_pipeline.accountBeingUpdated,
            primary_contact_id: state.account.account?.primary_contact?.id
        }
    });

    const dispatch = useDispatch();

    const [activeForm] = useState('');

    const { account_id } = useParams();

    const locationParam = () => `account/${account_id}`;

    const [openForm, setOpenForm] = useState(null);
    const onCloseForm = () => {
        setOpenForm(null);
    }

    const popupRef = React.createRef();

    const refresh = () => {
        dispatch(accountActions.readAccount(account_id));
    }

    return (
        <React.Fragment>
            {canCall ? (
                <Icon disabled={!phone} className='Profile__controls fas fa-phone' link rotated='counterclockwise' />
            ) : (
                <Icon as={NavLink} to={`/account/${account_id}`} className={`Profile__controls ${activeForm === 'calls' ? 'Profile__controls--active' : ''} fas fa-phone`} />
            )}
            {canText ? (
                <Icon
                    as={NavLink}
                    to={`/${locationParam()}/texts`}
                    link
                    className='Profile__controls fas fa-comment'
                />
            ) : (
                <Icon
                    as={NavLink}
                    to={`/account/${account_id}`}
                    link
                    className='Profile__controls fas fa-comment'
                />
            )}
            <Icon
                as={NavLink}
                to={`/${locationParam()}/emails`}
                link
                className='Profile__controls fas fa-envelope'
                color='grey'
            />
            <Icon
                as={NavLink}
                to={`/${locationParam()}/notes`}
                link
                className='Profile__controls fa-sharp fa-solid fa-pen-clip'
                color='grey'
            />
            {account_type === 'business' && (
                <Popup
                    open={openForm === 'unlink-master'}
                    position='bottom center'
                    context={popupRef}
                    onClose={onCloseForm}
                    content={(
                        <Form loading={accountBeingUnlinked}>
                            <ConfirmationPrompt
                                header={<div style={{ paddingTop: '1em' }}>Are you sure?</div>}
                                subheader={<div style={{ marginBottom: '-1.5em' }}>Unlinking removes data from the Master Account.</div>}
                                onConfirm={async () => {
                                    try {
                                        await dispatch(masterAccountActions.unlinkAccount(master_account, null, null, account_id));
                                        Notification.alert('Successfully unlinked account from master account!', STATUS_TYPES.SUCCESS);
                                        onCloseForm();
                                        refresh();
                                    } catch (e) {
                                        console.log(e);
                                        Notification.alert('Failed to unlink account from master account', STATUS_TYPES.ERROR)
                                    }
                                }}
                                onCancel={onCloseForm}
                            />
                        </Form>
                    )}
                />
            )}
            <Popup
                open={openForm === 'add-pipeline'}
                position='bottom center'
                context={popupRef}
                onClose={onCloseForm}
                content={(
                    <>
                        <Header color='primary'>Add Pipeline</Header>
                        <CloseableContent onClose={onCloseForm}>
                            <Form style={{ width: '30em' }} loading={isUpdatingPipeline}>
                                <CustomerPipelineForm primary_contact_id={primary_contact_id} refresh={refresh} account_id={account_id} onClose={onCloseForm} />
                            </Form>
                        </CloseableContent>
                    </>
                )}
            />
            <Popup
                open={openForm === 'close-pipeline' || openForm === 'change-pipeline'}
                position='bottom right'
                context={popupRef}
                onClose={() => {
                    if (openForm === "add-pipeline") {
                        onCloseForm();
                    }
                }}
                content={(
                    <>
                        <Header color='primary'>
                            {`${openForm === 'close-pipeline' ? 'Close' : 'Change'} Pipeline`}
                        </Header>
                        <CloseableContent onClose={onCloseForm}>
                            {openForm === 'close-pipeline' ? (
                                <LostForm
                                    account_id={account_id}
                                    onClose={onCloseForm}
                                    type={'pipeline'}
                                    account_stage={'customer'}
                                    refresh={refresh}
                                    pipeline_id={pipeline?.pipeline_id}
                                />
                            ) : (
                                <ChangeCustomerPipelineForm
                                    account_id={account_id}
                                    onClose={onCloseForm}
                                    refresh={refresh}
                                    current_pipeline_category={pipeline?.pipeline_category_id}
                                    pipeline_id={pipeline?.pipeline_id}
                                />
                            )}
                        </CloseableContent>
                    </>
                )}
            />
            <Ref innerRef={popupRef}>
                <Dropdown
                    icon={<Icon name='ellipsis vertical' className='Profile__controls' link />}
                    pointing={'top right'}
                >
                    <Dropdown.Menu>
                        {
                            [
                                ...(account_type === 'business' ? master_account ? [
                                    <Dropdown.Item onClick={() => { setOpenForm('link-master'); }} icon={<Icon className='fas fa-buildings' />} content={'Change Master Account'} />,
                                    <Dropdown.Item onClick={() => { setOpenForm('unlink-master'); }} icon={<Icon className='fas fa-diagram-nested' />} content={'Unlink Master Account'} />
                                ] : [
                                    <Dropdown.Item onClick={() => { setOpenForm('link-master'); }} icon={<Icon className='fas fa-diagram-nested' />} content={'Link Master Account'} />
                                ] : [])
                            ]
                        }
                        {customer_status === 'active' && (
                            pipeline?.stages?.length > 0 ? (
                                [
                                    <Dropdown.Item onClick={() => { setOpenForm('change-pipeline') }}>Change Pipeline</Dropdown.Item>,
                                    <Dropdown.Item onClick={() => { setOpenForm('close-pipeline') }}>Close Pipeline</Dropdown.Item>
                                ]
                            ) : (
                                <Dropdown.Item onClick={() => { setOpenForm('add-pipeline') }} icon={<Icon className='fas fa-filter' />} content='Add Pipeline' />
                            )
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </Ref>
            {account_type === 'business' && (
                <Modal open={openForm === 'link-master'} onClose={onCloseForm}>
                    <LinkToMasterAccountForm
                        account_id={account_id}
                        refreshFromLinking={() => {
                            dispatch(accountActions.readAccount(account_id));
                        }}
                        close={onCloseForm}
                        current_master_account={master_account}
                    />
                </Modal>
            )}
        </React.Fragment>
    );
};

export default Controls;

