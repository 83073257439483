import React from 'react';
import { TimelineTabDisplay, GridList, DateTimeUtils } from 'dyl-components';
import { Segment, Header, Icon } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import {  StringUtils } from 'utils';
import { attachmentsExist } from 'utils/EmailFormUtils';
import './index.scss';


const EmailTimeLine = ({ isGettingAllEmails, allEmails = [],onChangeActiveMessage, activeIntegration}) => {
const ICONS = {
    'email-gmail': 'mail',
    'task': 'fas fa-clipboard-check',
    'log': 'history'
};

const allRecentEmails = allEmails.map(email => { //if thread, display latest
    const emailThread = email?.threads;
    if(emailThread !== undefined && emailThread.length > 1){
        const latestEmailThread = emailThread[emailThread.length-1];
        return {
            ...email,
            from: latestEmailThread.from,
            body: latestEmailThread.body,
            subject: latestEmailThread.subject,
            direction: latestEmailThread.direction,
            occurred: latestEmailThread.occurred,
        }
    }
    return email;
})

    return (
        isGettingAllEmails ? 
        <Segment loading />
        :   
            <TimelineTabDisplay
                expanded
                selectedRow={onChangeActiveMessage}
                itemComponent={ allRecentEmails.map((item, idx) => {

                    const { name = '', email = '' } = item.from || '';
                    const avatarName = name || email || item.source;
                    const body = item.body !== undefined && item.body_type === "html" ? StringUtils.removeHTML(item.body) : ' ';
                    const counter = activeIntegration.email !== undefined && (item.type.includes('mail') && item?.threads?.length > 1) ? item.threads.length : false;
                    return ({ 
                        id: item.id,
                        type: item.type,
                        icon: ICONS[item.type],
                        counter: counter,
                        date: `${DateTimeUtils.formatEpoch(item.occurred, "ddd").toUpperCase()} ${DateTimeUtils.formatEpoch(item.occurred, "M[/]D")}`,
                        time: DateTimeUtils.formatEpoch(item.occurred),
                        itemComponent: 
                            <React.Fragment>
                                <GridList.Cell width={7} className="TimelineTabDisplay__Info" style={{marginLeft:6}}>
                                    <Header as='h5'>{item.subject }</Header>
                                    {body}
                                </GridList.Cell>
                                {attachmentsExist(item) ?
                                    <GridList.Cell width={1}>
                                        <Icon name='paperclip' />
                                    </GridList.Cell>
                                    :
                                    activeIntegration.email !== undefined && <GridList.Cell width={1}/>
                                }  
                                <GridList.Cell width={2} >
                                    <Avatar
                                        name={ avatarName }
                                        round
                                        size='2em'
                                        maxInitials={2}
                                    />
                                </GridList.Cell>
                                <GridList.Cell>
                                    <span className={`Info__status__TimelineDisplay Info__status__TimelineDisplay--${item.completed ? 'done' : item.direction === 'outbound' ? 'sent' : 'received'}` }>
                                        {item.completed ? 'Completed' : (item.direction === 'outbound' )? 'Sent' : 'Received' } 
                                    </span>
                                </GridList.Cell>
                             </React.Fragment>,
                        markerColor: '#e5e5e5',
                    })
                }) 
            } 
        />
    )
};

export default EmailTimeLine;