import React, { useState } from 'react';
import { Table, EllipsisDropdown, ButtonLink, ControlledPopup, Notification, STATUS_TYPES } from 'dyl-components';
import { Dropdown, Icon, Header } from 'semantic-ui-react';

import { Person } from 'dyl-components';
import EntriesModal from './EntriesModal';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import webformsActions from 'actions/webforms';

const WebFormRow = ({ webform, limit }) => {
    const [isEntriesModalOpen, setModal] = useState(false);
    const navigate = useNavigate();
    const webforms = useSelector(state => state.webforms.webforms) || [];

    const info = {
        name: webform.internalName,
        createdOn: webform.createdOn,
        lastEdited: webform.lastEdited,
        entries: webform.entries,
        opportunities: webform.opportunities,
        customers: webform.customers
    }

    const dispatch = useDispatch();

    const [params] = useSearchParams();
    const refresh = () => {
        dispatch(webformsActions.readForms({
            ...Object.fromEntries(params),
            limit
        }));
    }

    const ELLIPSIS_OPTIONS = [
        { text: 'Edit', icon: 'fas fa-pencil', as: Link, to: `/settings/web-forms/edit/${webform.id}` },
        {
            text: 'Duplicate', icon: 'fas fa-copy', as: Link, to: '/settings/web-forms/create', state: {
                form_id: webform.id,
                breadcrumb_header: `Duplicate '${webform.internalName}'`
            }
        },
        ...(webform.status === 'Active' ? [{
            text: 'Deactivate',
            icon: 'fas fa-eye-slash',
            displaysPopup: true,
            popupProps: {
                header: 'Are you sure?',
                subheader: 'Deactivating will disable the shared links and unpublish this form.',
                onConfirm: async () => {
                    try {
                        await dispatch(webformsActions.updateStatus(webform.id, { active: false }));
                        Notification.alert(`Successfully deactivated webform!`, STATUS_TYPES.SUCCESS);
                        refresh();
                    } catch (e) {
                        console.log(e);
                        Notification.alert(`Failed to deactivate webform`, STATUS_TYPES.ERROR);
                    }
                }
            }
        }] : [{
            text: 'Activate',
            icon: 'fas fa-eye',
            onClick: async () => {
                try {
                    await dispatch(webformsActions.updateStatus(webform.id, { active: true }));
                    Notification.alert(`Successfully activated webform!`, STATUS_TYPES.SUCCESS);
                    refresh();
                } catch (e) {
                    console.log(e);
                    Notification.alert(`Failed to activate webform`, STATUS_TYPES.ERROR);
                }
            }
        }]),
        {
            text: 'Delete',
            icon: 'fas fa-trash-alt',
            displaysPopup: true,
            popupProps: {
                header: 'Are you sure?',
                onConfirm: async () => {
                    try {
                        await dispatch(webformsActions.deleteForm(webform.id));
                        Notification.alert('Successfully deleted webform!', STATUS_TYPES.SUCCESS, true);
                        const isNowEmpty = webforms.length - 1 === 0;
                        if (!isNowEmpty) {
                            refresh();
                        } else {
                            const query = new URLSearchParams(params);
                            const page = query.get('page');
                            if (Number(page) !== 1) {
                                query.set('page', page - 1);
                                const query_string = query.toString();
                                navigate(`/settings/web-forms${query_string ? `?${query_string}` : ''}`);
                            } else {
                                refresh();
                            }
                        }
                    } catch (e) {
                        console.log(e);
                        Notification.alert('Failed to delete webform', STATUS_TYPES.ERROR, true);
                    }
                }
            }
        }
    ];

    const DropdownItem = ({ text, icon, displaysPopup, popupProps, ...otherProps }) => {
        const DropdownModel = () => {
            return (
                <Dropdown.Item text={<React.Fragment>
                    <Icon name={icon} />
                    {text}
                </React.Fragment>}
                    style={{ cursor: 'pointer' }}
                    {...otherProps}
                />
            );
        }

        if (displaysPopup === true) {
            return (
                <ControlledPopup
                    header={
                        <Header as='h4' textAlign='center'>
                            {popupProps.header}
                            <Header.Subheader style={{ marginTop: 5 }}>
                                {popupProps.subheader}
                            </Header.Subheader>
                        </Header>
                    }
                    trigger={
                        DropdownModel()
                    }
                    onConfirm={popupProps.onConfirm}
                />
            )
        } else {
            return (
                <DropdownModel />
            );
        }
    }

    return (
        <React.Fragment>
            <Table.Row>
                <Table.Cell width={3}>
                    <Link to={`/settings/web-forms/edit/${webform.id}`}>
                        {webform.internalName}
                    </Link>
                </Table.Cell>
                <Table.Cell width={3}>
                    {webform.displayName}
                </Table.Cell>
                <Table.Cell width={3}>
                    <Person username={webform.created.name} email={webform.created.email} />
                </Table.Cell>

                <Table.Cell width={1}>
                    <ButtonLink onClick={() => { setModal(true) }}>
                        {webform.entries}
                    </ButtonLink>
                </Table.Cell>
                <Table.Cell width={2}>
                    {webform.status}
                </Table.Cell>
                <Table.Cell width={1}>
                    <EllipsisDropdown>
                        {ELLIPSIS_OPTIONS.map(({ text, icon, displaysPopup, popupProps, ...otherProps }) => {
                            return (
                                <DropdownItem
                                    text={text}
                                    icon={icon}
                                    displaysPopup={displaysPopup}
                                    popupProps={popupProps}
                                    {...otherProps}
                                />
                            );
                        })}
                    </EllipsisDropdown>
                </Table.Cell>
            </Table.Row>
            {isEntriesModalOpen &&
                <EntriesModal info={info} open={isEntriesModalOpen} onClose={() => { setModal(false) }} />
            }
        </React.Fragment>
    )
}

export default WebFormRow;
