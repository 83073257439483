import initialState from "./initialState";

function message_editor_reducer(state = initialState, { type, ...action }) {
    switch (type) {
        case 'MESSAGE_EDITOR_TEMPLATE_CHANGE':
            return { ...state, ...action };
        case 'MESSAGE_EDITOR_SELECT_FILE':
            return { ...state, ...action };
        case 'MESSAGE_EDITOR_CHANGE_MESSAGE':
            const { message } = action;
            return { ...state, ...action, message: message.substring(0, 160) };
        case 'MESSAGE_EDITOR_CLEAR_MESSAGE':
            return {
                ...state,
                message: '',
                selectedTemplate: null,
                file: null
            }
        default:
            return state;
    }
}

export default message_editor_reducer;
