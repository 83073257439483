const initialState = {
    isReading: false,
    isCreating: false,

    webforms: [],
    webform: {},
    count: 0,
    
    webformBeingRead: null,
    webformBeingUpdated: null,
    webformBeingDeleted: null,
    webformBeingPublished: null
}

export default initialState;
