const initialState = {
    masterAccountBeingRead: null,
    master_account: null,
    isCreatingMasterAccount: false,
    masterAccountError: false,
    isUpdatingMasterAccount: false,
    isDeletingMasterAccount: false,

    accounts: [],
    accounts_count: 0,
    isReadingLinkedAccounts: false,

    accountsForPinning: [],
    isReadingAccountsForPinning: false,

    isLinkingAccounts: false,
    accountBeingUnlinked: null,
    
    masterAccounts: [],
    isReadingMasterAccounts: false
};

export default initialState;
