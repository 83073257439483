import React from 'react';
import { Menu, Icon, Dropdown, Popup } from 'semantic-ui-react';
import { NavLink as Link, useNavigate  } from "react-router-dom";

import authActions from "actions/auth/auth";
import imageActions from 'actions/image';
import './index.scss';
import { connect } from 'react-redux';
import { DYLAvatar } from 'dyl-components';

const TopNavigation = ({isSidebarOpen, setSidebarOpen, onLogOut, name, email, user_id, profilePicture, savedSettingsRoute }) => {
const navigate = useNavigate();
return (
    <Menu fixed='top' borderless className={`SettingsPage__TopNavigation SettingsPage__TopNavigation${isSidebarOpen ? '--squished' : '--full'}`}>
        <Menu.Item position='left'>
            <Icon name={`angle ${isSidebarOpen ? 'left' : 'right'}`} circular link onClick={setSidebarOpen} />
        </Menu.Item>
        <Menu.Item>
            <Dropdown
                inline
                style={{ color: "#303f9f" }}
                text={
                    <Popup
                        trigger={<Icon
                            className="fas fa-question-circle"
                            size="large"
                        />}
                        content='Help'
                        position='left center'
                        inverted
                    />
                }
                pointing='top right'
            >
                <Dropdown.Menu>
                    <Dropdown.Item
                        content='Help'
                        href='https://dyl-cloud.atlassian.net/servicedesk/customer/portals'
                        target='_blank'
                    />
                    <Dropdown.Item
                        content='Support'
                        href='https://dyl.com/support'
                        target='_blank'
                    />
                </Dropdown.Menu>
            </Dropdown>
        </Menu.Item>
        <Menu.Item>
            <Dropdown
                inline
                style={{ color: "#303f9f" }}
                text={
                    <DYLAvatar name={name} email={email} picture={profilePicture} position='left center'/>
                }
                pointing='top right'

            >
                <Dropdown.Menu>
                    <Dropdown.Item
                        content="Profile"
                        as={Link}
                        to={`/settings/users/${user_id}/general`}
                    />
                    <Dropdown.Item content="Billing" />
                    <Dropdown.Item
                        content="Logout"
                        onClick={onLogOut}
                    />
                </Dropdown.Menu>
            </Dropdown>
        </Menu.Item>
        <Menu.Item>
            <Popup
                trigger={<Icon
                    className="x"
                    size="large"
                    style={{ color: "#303f9f" }}
                    onClick={() => {navigate(savedSettingsRoute)}} 
                />}
                content='Close Settings'
                position='left center'
                inverted
            />
        </Menu.Item>
    </Menu>
)};

const mapStateToProps = state => ({
    name: state.auth.name,
    email: state.users.userProfile.email,
    user_id: state.auth.user_id,
    savedSettingsRoute : state.app.savedSettingsRoute,
    profilePicture: state.image.image ? `data:image/png;base64,${state.image.image}` : '',
})

const mapDispatchToProps = dispatch => {
    return {
        onLogOut: () => {
            dispatch(authActions.logout());
        },

        onReadUserProfileImage: (userId) => {
            return dispatch(imageActions.getUserProfileImage({ isThumbnail: true, user: userId }));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNavigation);
