import React from "react";
import { Table, EllipsisDropdown} from "dyl-components";
import { Icon, Popup, Dropdown } from "semantic-ui-react";
import { LocationUtils } from "utils";
import { Link } from "react-router-dom";

const E911Rows = ({
    location,
    setDeleteIndex,
    setIsDeletePromptOpen
}) => {
    const deviceCount = location.devices?.count;    
    const deviceDisabled = deviceCount > 0;
    return (
        <Table.Row>
            <Table.Cell width={4}>
                {location.label}
            </Table.Cell>
            <Table.Cell width={7}>
                {LocationUtils.formatAddress(location.address, location.address2, location.city, location.state, location.zip)}
            </Table.Cell>
            <Table.Cell width={3}>
                <Link to='/settings/phone-management/devices'>{deviceCount || 0}</Link>
            </Table.Cell>
            <Table.Cell width={2}>
                <EllipsisDropdown>
                    <Dropdown.Item
                        className={`e911-item--${deviceDisabled ? 'disabled' : ''}`} 
                        disabled={deviceDisabled} 
                        onClick={()=>{ 
                            setIsDeletePromptOpen(!deviceDisabled);
                            setDeleteIndex(location.caller_id_number)
                        }}
                        text={
                            deviceDisabled ?
                                <Popup
                                    trigger={
                                        <div>
                                            <Icon
                                                name='trash'
                                            />
                                            Delete
                                        </div>}
                                    content='E911 location in use'
                                    position='right center'
                                    inverted
                                />
                            :
                                <div>
                                    <Icon
                                        name='trash'
                                    />
                                    Delete
                                </div>
                        }
                    />   
                </EllipsisDropdown>
            </Table.Cell>
        </Table.Row>
    );
};

export default E911Rows;
