import React, { useState } from 'react';
import { Grid, Icon, Header, Form, Button, Loader } from 'semantic-ui-react';

import { connect } from 'react-redux';

import { STATUS_TYPES, Notification, generateResolver, yup, VALIDATORS } from 'dyl-components';

import Email from './Email';
import contactDuplicatesActions from 'actions/contact_duplicates';
import contactEmailActions from 'actions/contact_email';
import { useFieldArray, useForm } from 'react-hook-form';
import { RecordUtils, ValidationUtils } from 'utils';
import RecordEmailsForm from 'shared/forms/RecordEmailsForm';
import accountActions from 'actions/account';
import contactActions from 'actions/contact';
import EmailUtil from 'utils/EmailUtil';


const EmailsField = ({
    mainEmails,
    nonMainEmails,

    emails,
    phones,

    checkDuplicates,
    contact_id,
    onUpdateContactEmails,
    onReadContactEmails,
    isCheckingDuplicates,
    isReadingEmails,
    onReadAccount,
    onReadContact,

    shouldCheckDuplicates = true,
    noPadding,
    allowNoEmails,

    isEditing,
    onCancelEdit,
    onEdit
}) => {
    const [areOtherEmailsShown, setOtherEmailsShown] = useState(false);

    const onCancel = () => {
        onCancelEdit(() => {
            reset({
                emails: emails.map(email => ({
                    value: email.email,
                    main: email.main,
                    id: email.id,
                    type: email.email_type
                })),
                phones: phones.map(phone => ({
                    value: phone.phone,
                    main: phone.main,
                    id: phone.id,
                    type: phone.phone_type
                }))
            })
        });
    }

    const onToggleOtherEmails = () => {
        setOtherEmailsShown(!areOtherEmailsShown);
    }

    const { formState: { isDirty, isValid }, control, watch, setError, clearErrors, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            emails: emails.map(email => ({
                value: email.email,
                main: email.main,
                id: email.id,
                type: email.email_type
            })),
            phones: phones.map(phone => ({
                value: phone.phone,
                main: phone.main,
                id: phone.id,
                type: phone.phone_type
            }))
        },
        resolver: generateResolver({
            emails: yup.array().when("phones", {
                is: phones => {
                    return phones?.some((phone) => Boolean(phone.value)) === true;
                },
                then: schema => schema.of(yup.object().shape({
                    value: VALIDATORS.EMAIL_ADDRESS().maxlength(256).required('This field is required')
                })).test('no_repeating_email', "Email already in the list", ValidationUtils.checkForDuplicateEmailsInTheList),
                otherwise: schema => schema.of(yup.object().shape({
                    value: VALIDATORS.EMAIL_ADDRESS().maxlength(256).required('This field is required')
                })).test('no_repeating_email', "Email already in the list", ValidationUtils.checkForDuplicateEmailsInTheList).min(1, 'Should have at least one email')
            })
        })
    });

    const { fields: emailsToEdit, append: addEmail, remove: removeEmail, update: updateEmail } = useFieldArray({
        control,
        name: 'emails'
    });

    const [watchedEmails] = watch(['emails']);

    const [isUpdating, setIsUpdating] = useState(false);

    const onConfirmEdit = () => {
        setIsUpdating(true);
        onUpdateContactEmails(contact_id, emails, watchedEmails).then(() => {
            setIsUpdating(false);
            Notification.alert('Successfully updated emails!', STATUS_TYPES.SUCCESS, true);
            onReadContactEmails(contact_id).then(() => {
                onCancel();
                //update dispatch for contact or account email tab
                onReadAccount(contact_id);
                onReadContact(contact_id);
            });
            
        }).catch(() => {
            setIsUpdating(false);
            Notification.alert('Failed to update emails', STATUS_TYPES.ERROR, true);
        })
    }

    if (isReadingEmails) {
        return <Loader active />
    }

    return (
        !isEditing ? (
            <Grid.Row verticalAlign='top' className={`ContactInfo__value ${noPadding ? 'ContactInfo__row' : ''}`}>
                <Grid.Column width={9} style={{ paddingRight: 0, flex: 1 }}>
                    <div onClick={onEdit}>
                        {mainEmails.length <= 0 && (
                            <span>
                                <Icon name='envelope' size='small' color='grey' /> No main email
                            </span>
                        )}
                        {mainEmails.map(email => (
                            <div>
                                <Email email={email.email} email_type={email.email_type} />
                            </div>
                        ))}
                        {areOtherEmailsShown && (
                            nonMainEmails.map(email => (
                                <div className='ContactInfo__fieldContainer'>
                                    <Email email={email.email} email_type={email.email_type} />
                                </div>
                            ))
                        )}
                    </div>
                </Grid.Column>
                <Grid.Column width={1} style={{ left: -15 }}>
                    {nonMainEmails.length > 0 && <Icon disabled={nonMainEmails.length <= 0} onClick={onToggleOtherEmails} bordered size='tiny' name={`caret ${areOtherEmailsShown ? 'down' : 'right'}`} link style={{ marginLeft: 5, fontSize: 9, borderRadius: 5 }} />}
                </Grid.Column>
                {isReadingEmails && (
                    <Loader active={isReadingEmails} />
                )}
            </Grid.Row>
        ) : (
            <Grid.Row>
                <Grid.Column>
                    <Form size='mini' loading={isUpdating}>
                        <Header size='small' color='primary'>
                            <span>
                                <Icon link onClick={() => { EmailUtil.onAddEmail({ value: '', type: 'Work', new: true }, emailsToEdit, addEmail) }} className='fas fa-plus-circle' color='primary' /> Emails
                            </span>
                        </Header>
                        <RecordEmailsForm
                            control={control}
                            emails={emailsToEdit}
                            onRemove={(index) => {
                                RecordUtils.removeItem(watchedEmails, index, updateEmail, removeEmail);
                            }}
                            updateMain={(onChange) => {
                                RecordUtils.updateMain(watchedEmails, updateEmail, onChange);
                            }}
                            checkDuplicates={shouldCheckDuplicates ? checkDuplicates : () => { return [] }}
                            clearErrors={clearErrors}
                            contact_id={contact_id}
                            setError={setError}
                            allowNoEmails={allowNoEmails}
                        />
                        <Grid>
                            <Grid.Column textAlign='right'>
                                {!isUpdating && <Button size='mini' basic primary onClick={onCancel}>
                                    Cancel
                                </Button>}
                                <Button size='mini' loading={isUpdating} disabled={!isDirty || !isValid || isCheckingDuplicates} type='button' onClick={onConfirmEdit} primary>
                                    Save
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        )
    );
};

const mapStateToProps = state => {
    const emails = state.contact_email.email ? state.contact_email.email.slice(0) : [];
    const mainEmails = emails.filter(email => email.main);
    const nonMainEmails = emails.filter(email => !email.main);

    return {
        mainEmails,
        nonMainEmails,
        emails,
        isCheckingDuplicates: state.contact_duplicates.isCheckingDuplicates,
        isReadingEmails: state.contact_email.isReadingEmails,
        phones: state.contact_phone.phone ? state.contact_phone.phone.slice(0) : []
    }
};

const mapDispatchToProps = (dispatch) => ({
    onUpdateContactEmails: async (contact_id, emails, emailValues) => {
        const emailsToDelete = emails.filter(email => emailValues.findIndex(emailValue => emailValue.id === email.id) === -1);
        const emailsToAdd = emailValues.filter(email => email.new);
        const emailsToUpdate = emailValues.filter(emailValue => !emailValue.new && emails.findIndex(email => (
            email.id === emailValue.id &&
            email.main === emailValue.main &&
            email.email === emailValue.value &&
            email.email_type === emailValue.type
        )) === - 1);

        await Promise.all([
            ...emailsToUpdate.map(email => {
                return dispatch(contactEmailActions.updateContactEmail(contact_id, {
                    main: email.main,
                    email: email.value,
                    email_type: email.type
                }, null, email.id));
            }),
            (() => {
                if (emailsToDelete.length) {
                    return Promise.all(emailsToDelete.map(email => dispatch(contactEmailActions.deleteContactEmail(contact_id, null, email.id))));
                }
                return Promise.resolve();
            })()
        ])
        if (emailsToAdd.length > 0) {
            return dispatch(contactEmailActions.addContactEmail(emailsToAdd.map(email => ({
                main: email.main,
                email: email.value,
                email_type: email.type
            })), null, contact_id));
        }
        return Promise.resolve();
    },
    onReadContactEmails: (contact_id) => {
        return dispatch(contactEmailActions.getContactEmails(contact_id));
    },
    checkDuplicates: (queryParameters) => {
        return dispatch(contactDuplicatesActions.checkDuplicates(queryParameters));
    },
    onReadAccount: (contact_id) => {
        return dispatch(accountActions.readAccount(contact_id));
    },
    onReadContact: (contact_id) => {
        return dispatch(contactActions.readContact(contact_id));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailsField);
