import React, { useState } from 'react';
import { Header, Grid, Icon, Checkbox, Transition } from 'semantic-ui-react';



const CheckboxesGrid = ({ division, content, onChangeOptions, options }) => {
    return content.map((element) => {
        return (
            <React.Fragment>
                <Header as="h4" color="primary">
                    {element.label}
                </Header>
                <Grid columns="two">
                    {element.options.map(({ label, value }, _) => {
                        const lowerCaseLabel = element.label.toLowerCase();
                        const thisOne = options[division][lowerCaseLabel][value];
                        const isThisTheSelectAll = value === element['select_all'];
                        return (
                            <React.Fragment>
                                <Grid.Column>
                                    <Checkbox
                                        checked={thisOne}
                                        name={value}
                                        label={label}
                                        onChange={() => {
                                            onChangeOptions(division, value, isThisTheSelectAll, lowerCaseLabel, element['select_all']);
                                        }}
                                    />
                                </Grid.Column>
                            </React.Fragment>
                        );
                    })}
                </Grid>
            </React.Fragment>
        );
    });
}

export default function OptionDrawer({ onChangeAllOptions, allOptions, options, onChangeOptions }) {
    const [expanded, setExpanded] = useState({ Sequences: false, Task: false, Event: false });

    const _options = [
        {
            label: 'Sequences',
            value: 'sequences',
            content: [
                {
                    label: 'Type',
                    select_all: 'type_all',
                    options: [
                        { label: 'All', value: 'type_all' },
                        { label: 'Email', value: 'type_email' },
                        { label: 'Call', value: 'type_call' },
                        { label: 'Text', value: 'type_text' },
                    ]
                },
                {
                    label: 'Outcome',
                    select_all: 'outcome_all',
                    options: [
                        { label: 'All', value: 'outcome_all' },
                        {
                            label: 'Unconfirmed',
                            value: 'outcome_unconfirmed',
                        },
                    ],
                },
            ]
        },
        {
            label: 'Task',
            value: 'tasks',
            content: [
                {
                    label: 'Type',
                    select_all: 'type_all',
                    options: [
                        { label: 'All', value: 'type_all' },
                        { label: 'Email', value: 'type_email' },
                        { label: 'Call', value: 'type_call' },
                        { label: 'To Do', value: 'type_todo' },
                        { label: 'Text', value: 'type_text' },
                    ],
                },
                {
                    label: 'Outcome',
                    select_all: 'outcome_all',
                    options: [
                        { label: 'All', value: 'outcome_all' },
                        {
                            label: 'Unconfirmed',
                            value: 'outcome_unconfirmed',
                        },
                    ],
                },
            ],
        },
        {
            label: 'Event',
            value: 'events',
            content: [
                {
                    label: 'Outcome',
                    select_all: 'outcome_all',
                    options: [
                        { label: 'All', value: 'outcome_all' },
                        { label: 'Unconfirmed', value: 'outcome_unconfirmed' },
                    ],
                },
            ],
        },
    ];

    const onToggleExpand = (label) => {
        setExpanded((prevState) => ({
            ...prevState,
            [label]: !prevState[label],
        }));
    };

    return (
        <React.Fragment>
            {_options.map(({ label, value, content }) => {
                return (
                    <React.Fragment>
                        <Grid columns="two">
                            <Grid.Column>
                                <Header color="primary">
                                    <Icon
                                        onClick={() => {
                                            onToggleExpand(label);
                                        }}
                                        link
                                        size="small"
                                        className={`fas fa-circle-chevron-${expanded[label] ? 'up' : 'down'}`}
                                    />
                                    {` ${label}`}
                                </Header>
                            </Grid.Column>
                            <Grid.Column>
                                <Checkbox
                                    toggle
                                    onClick={() => {
                                        onChangeAllOptions(value);
                                    }}
                                    checked={allOptions[value]}
                                />{' '}
                                {allOptions[value] ? 'On' : 'Off'}
                            </Grid.Column>
                        </Grid>
                        <Transition visible={expanded[label]} animation={'slide down'} unmountOnHide duration={'150'}>
                            <div>
                                <CheckboxesGrid
                                    division={value}
                                    content={content}
                                    options={options}
                                    onChangeOptions={onChangeOptions}
                                />
                            </div>
                        </Transition>
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
}