import productActions from "actions/product";
import { Notification, STATUS_TYPES, generateResolver } from "dyl-components";
import { Error404, Error500 } from "pages/ErrorPages";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import FullScreenModalPopUp from "shared/FullScreenModalPopUp";
import ProductForm from "shared/forms/ProductForm";
import { MODEL_SCHEDULE } from "shared/schemas/products/MODEL_SCHEDULE";
import { formatProductDetails, getDefaultValuesFromReadingAProduct } from "shared/schemas/products/helper";
import productSchema from "shared/schemas/products/productSchema";

const EditProduct = ({ id }) => {
    const { product, productBeingUpdated } = useSelector(state => state.products);
    const { price_data } = product;
    const [model, schedule] = MODEL_SCHEDULE[price_data.model].split(":");

    const methods = useForm({
        mode: "onChange",
        defaultValues: getDefaultValuesFromReadingAProduct({
            ...product,
            model,
            schedule
        }),
        resolver: generateResolver(productSchema)
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSave = async (data) => {
        try {
            await dispatch(productActions.updateProduct(id, formatProductDetails(data)));
            Notification.alert("Succesfully updated product", STATUS_TYPES.SUCCESS);
            methods.reset();
            navigate("/settings/product-catalog-quotes-invoices/product-catalog", { replace: true, state: { saved: true } });
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to update product", STATUS_TYPES.ERROR);
        }
    }

    const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);

    const { order, quote } = product.in_use || { order: { draft_count: 0, open_count: 0 }, quote: { draft_count: 197, sent_count: 0 } };
    const { draft_count: order_draft_count, open_count } = order;
    const { draft_count: quote_draft_count, sent_count } = quote;
    const willAffectQuotesAndOrders = (order_draft_count || open_count || quote_draft_count || sent_count) && !Object.keys(methods.formState.dirtyFields).every(field => ["name", "image", "description", "product_category", "vendor", "carrier", "supplier", "provider", "manufacturer"].includes(field));

    const affectedChanges = [
        quote_draft_count ? `${quote_draft_count} draft quote${quote_draft_count > 1 ? 's' : ''}` : null,
        sent_count ? `${sent_count} sent quote${sent_count > 1 ? 's' : ''}` : null,
        order_draft_count ? `${order_draft_count} draft order${order_draft_count > 1 ? 's' : ''}` : null,
        open_count ? `${open_count} open order${open_count > 1 ? 's' : ''}` : null,
    ].filter(change => change);

    if (affectedChanges.length > 1) {
        affectedChanges[affectedChanges.length - 1] = `and ${affectedChanges[affectedChanges.length - 1]}`
    }

    return (
        <FormProvider {...({ ...methods, productBeingEdited: id })}>
            <ProductForm onSave={willAffectQuotesAndOrders ? () => {setIsSaveConfirmationOpen(true)} : onSave} id={id} loading={productBeingUpdated} />
            <FullScreenModalPopUp
                header={'Are you sure?'} 
                subheader={`Changes will affect ${affectedChanges.join(', ')}.`} 
                isOpen={isSaveConfirmationOpen}
                onConfirm={() => {
                    setIsSaveConfirmationOpen(false);
                    methods.handleSubmit(onSave)();
                }}
                onFormClose={() => setIsSaveConfirmationOpen(false)}
                closeIcon={false}
            />
        </FormProvider>
    )
}

const EditProductContainer = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(productActions.readProduct(id))
    }, [id, dispatch]);

    const { productBeingRead, productError, product } = useSelector(state => state.products);

    if (productBeingRead) {
        return <Dimmer active><Loader active /></Dimmer>
    }

    if (!productBeingRead && productError) {
        return <Error500 message={"Something went wrong"} />;
    }

    if (!productBeingRead && !product) {
        return <Error404 message={"Product not found"} />;
    }

    return (
        <EditProduct id={id} />
    )
}

export default EditProductContainer;
