import quoteActions from "actions/quote";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header, Icon, Transition } from "semantic-ui-react";

import OpportunityRecentQuote from "shared/OpportunityRecentQuote";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";

const QuotesSection = ({ toggler, isExpanded }) => {
    const current_stage = useSelector((state) => state.contact.current_stage);

    const pipeline_id = current_stage?.pipeline_id;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(quoteActions.getOpportunityRecentQuote(pipeline_id));
    }, [dispatch, pipeline_id]);

    const {
        onOpenAddProducts,
    } = useContext(QuoteBuilderContext);

    return (
        <>
            <Header color="primary">
                <span>
                    <Icon
                        className="fas fa-plus-circle"
                        onClick={() => {onOpenAddProducts()}}
                        link
                    />{" "}
                    Quote
                    {toggler}
                </span>
            </Header>
            <Transition
                visible={isExpanded}
                animation={"slide down"}
                unmountOnHide
                duration={"150"}
            >
                <div>
                    <OpportunityRecentQuote pipeline_id={pipeline_id} />
                </div>
            </Transition>
        </>
    );
};

export default QuotesSection;
