/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { SearchBar } from 'dyl-components';

import { CardData, CardDataItem } from 'shared/CardData';

export const DataProvidersToolbar = ({ isSearching, onSearch, search, setSearch, onChangeSearch }) => {
    const navigate = useNavigate();
    const [params] = useSearchParams();

    const cancelFunction = () => {
        const query = new URLSearchParams(params);
        query.delete('search');
        setSearch('');
        const query_string = query.toString();
        navigate(`/settings/data-providers${query_string ? `?${query_string}` : ''}`,);
    };

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <div className='DataProviders__info'>
                            <div>
                                <CardData>
                                    <CardDataItem>
                                        <strong>Post API Key</strong>&nbsp;[<a>dyl</a>] <Icon color='primary' link style={{ margin: '0 5px' }} className='fas fa-copy' />
                                    </CardDataItem>
                                    <CardDataItem>
                                        <strong>Email</strong>&nbsp;[<a>dyl@dyl.com</a>] <Icon color='primary' link style={{ margin: '0 5px' }} className='fas fa-copy' />
                                    </CardDataItem>
                                </CardData>
                            </div>
                            <div style={{ marginLeft: '1em', marginRight: '0.1em' }}>
                                <CardData>
                                    <CardDataItem>
                                        <Icon style={{ margin: '0 5px' }} className='fas fa-exclamation-triangle' /> <strong>Rejected: </strong>&nbsp;0
                                    </CardDataItem>
                                    <CardDataItem>
                                        <Icon style={{ margin: '0 5px' }} className='fas fa-diagram-project' /> <strong>Partially Accepted: </strong>&nbsp;0
                                    </CardDataItem>
                                </CardData>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div className='DataProvidersToolbar'>
                            <SearchBar
                                isSearching={isSearching}
                                search={search}
                                searchFcn={onSearch}
                                cancelFcn={cancelFunction}
                                placeholder={'Search by Provider Name'}
                                onChange={onChangeSearch}
                            />
                            <Button primary as={Link} to="/settings/data-providers/create" className='DataProvidersToolbar__NewDataProviderButton'>
                                <Icon name='plus' /> New Data Provider
                            </Button>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    )
}
