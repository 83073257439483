import React from 'react';
import { Table, MessagingIcon } from 'dyl-components';
import { Checkbox, Popup, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import {  RecordUtils, StringUtils } from 'utils';
import callHistoryActions from 'actions/call_history';
import { connect } from 'react-redux';
import EmailActivityForm from 'shared/forms/EmailActivityForm';
import TextActivityForm from 'shared/forms/TextActivityForm';
import CallActivityForm from 'shared/forms/CallActivityForm';
import callActions from 'actions/call';
import { TwoLinedContent, DateTimeUtils } from 'dyl-components';

const getPhone = (contact) => {
    const { phone = [] } = contact;

    let mainPhone = phone.data.find(item => item.main)

    if (!mainPhone) {
        mainPhone = phone.data[0] ? phone.data[0] : { phone: '' }
    }

    return mainPhone.phone;
}

const getEmail = (contact) => {
    const { email = [] } = contact;

    let mainEmail = email.data.find(item => item.main)

    if (!mainEmail) {
        mainEmail = email.data[0] ? email.data[0] : { email: '' }
    }

    return mainEmail.email;
}

const getStatus = ({ completed, system_result, direction, created_at }) => {

    if (completed) {
        return 'Completed';
    }

    if (system_result) {
        return StringUtils.capitalize(system_result);
    }

    if (!DateTimeUtils.isBeforeOrOnCurrentDate(DateTimeUtils.formatEpoch(created_at, DateTimeUtils.DATETIME_FORMAT), DateTimeUtils.DATETIME_FORMAT)) {
        return 'Upcoming';
    }

    return direction === 'inbound' ? 'Received' : 'Sent';
}

const CallHistoryRow = ({ call, onMarkChange, onReadCalls, onCall, onToggleModal, canCall, canText, canEmail }) => (
    <Table.Row active={call.isMarked}>
        <Table.Cell>
            <Checkbox checked={call.isMarked} onChange={onMarkChange} />
        </Table.Cell>
        <Table.Cell>
            {DateTimeUtils.formatEpoch(call.created_at, DateTimeUtils.DATETIME_FORMAT)}
        </Table.Cell>
        <Table.Cell>
            <Link to={RecordUtils.generateRecordPath(call.contact.account_stage, call.contact_id, call.contact.account_id)}>
                <Icon name='circle' className={`Contact__status-icon`} />
                <TwoLinedContent className={'Contact__details'} {...RecordUtils.getName(call.contact)} />
            </Link>
        </Table.Cell>
        <Table.Cell>
            {call.duration && DateTimeUtils.getNumberOfHoursAndMinutes(call.duration)}
        </Table.Cell>
        <Table.Cell>
            {getStatus(call)}
        </Table.Cell>
        <Table.Cell>
            {call.source}
        </Table.Cell>
        <Table.Cell>
            {call.latest_note}
        </Table.Cell>
        <Table.Cell>

            {canCall ? (
                <Popup inverted position='top center' trigger={<span className='LeadsTable__messagingIcon'><MessagingIcon size='' className={call.phone ? '' : 'disabled'} icon={'phone'} onClick={() => {
                    onCall(RecordUtils.generateCallPayload(
                        call.contact_id,
                        getPhone(call.contact)
                    ), `${RecordUtils.getName(call.contact).firstLine} ${RecordUtils.getName(call.contact).secondLine}`)
                }} /></span>} content='Call' />
            ) : (
                <CallActivityForm
                    trigger={<MessagingIcon size='' icon={'phone'} />}
                    contact_id={call.contact_id}
                    to={getPhone(call.contact) || 'No main phone'}
                    refresh={onReadCalls}
                />
            )}
            {canText ? (
                <Popup inverted position='top center' trigger={<span className='LeadsTable__messagingIcon'><MessagingIcon size='' icon={'conversation'} onClick={() => { onToggleModal('text', call) }} /></span>} content='Text' />
            ) : (
                <TextActivityForm
                    trigger={<MessagingIcon size='' icon={'conversation'} />}
                    contact_id={call.contact_id}
                    to={getPhone(call.contact) || 'No main phone'}
                />
            )}
            {canEmail ? (
                <Popup inverted position='top center' trigger={<span className='LeadsTable__messagingIcon'><MessagingIcon size='' icon={'mail'} onClick={() => { onToggleModal('email', call) }} /></span>} content='Email' />
            ) : (
                <EmailActivityForm
                    trigger={<MessagingIcon size='' icon={'mail'} />}
                    contact_id={call.contact_id}
                    to={getEmail(call.contact) || 'No main email'}
                />
            )}
            <Popup inverted position='top center' trigger={<span className='LeadsTable__messagingIcon'><MessagingIcon size='' icon={'list alternate outline'} onClick={() => { onToggleModal('add_note', call) }} /></span>} content='View Notes' />
        </Table.Cell>
    </Table.Row>
);

const mapStateToProps = state => {
    return ({
        canCall: state.users.userProfile.cell_phone,
        canText: state.users.userProfile.cell_phone,
        canEmail: state.oauth_integrations.integrations && state.oauth_integrations.integrations.length > 0,
        queryParameters: state.call_history.queryParameters
    })
}

const mapDispatchToProps = (dispatch, { call }) => ({
    onMarkChange: () => {
        dispatch(callHistoryActions.markCall(call.id));
    },
    onCall: (contact, name) => {
        dispatch(callActions.call(contact, { name }));
    },
    onReadCalls: () => {
        dispatch(callHistoryActions.getCalls({ end_at: DateTimeUtils.getLast(1, 'day') }));
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(CallHistoryRow);
