import React from 'react';
import RingGroupsContainer from './subcomponents/RingGroups';
import CallQueuesContainer from './subcomponents/CallQueues';
import ringGroupsActions from 'actions/ring_groups';
import callQueuesActions from 'actions/call_queues';
import { connect } from 'react-redux';
import pbxConfigActions from 'actions/pbx_config';
import usersActions from 'actions/users';

const RingGroupsManagement = () => (
    <React.Fragment>
        <RingGroupsContainer />
        <CallQueuesContainer />
    </React.Fragment>
);

class RingGroupsManagementContainer extends React.Component {
    componentDidMount() {
        this.props.onReadDomains().then(() => {
            this.props.onReadUsers();
            this.props.onReadRingGroups(this.props.domain);
            this.props.onReadCallQueues(this.props.domain);
        })
    }

    render() {
        return (
            <RingGroupsManagement />
        )
    }
}

const mapStateToProps = state => ({
    domain: state.pbx_config.domain
})

const mapDispatchToProps = dispatch => ({
    onReadRingGroups: (domain) => {
        dispatch(ringGroupsActions.readRingGroups(domain));
    },
    onReadDomains: () => {
        return dispatch(pbxConfigActions.getDomains());
    },
    onReadCallQueues: (domain) => {
        dispatch(callQueuesActions.readCallQueues(domain));
    },
    onReadUsers: () => {
        dispatch(usersActions.readUsers());
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(RingGroupsManagementContainer);
