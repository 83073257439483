import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function masterAccountReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, masterAccountBeingRead: action.id, masterAccountError: false };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, master_account: action.data, masterAccountBeingRead: null };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, master_account: null, masterAccountBeingRead: null, masterAccountError: true };

        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingMasterAccount: true };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingMasterAccount: false };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingMasterAccount: false };

        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingMasterAccount: true };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingMasterAccount: false };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingMasterAccount: false };

        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingMasterAccount: true };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingMasterAccount: false };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingMasterAccount: false };

        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT_ACCOUNTS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingLinkedAccounts: true, accounts: [], accounts_count: 0 };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT_ACCOUNTS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingLinkedAccounts: false, accounts: action.data.data, accounts_count: action.data.count };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT_ACCOUNTS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingLinkedAccounts: false, accounts: [], accounts_count: 0 };

        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT_ACCOUNT_PIN_OPTIONS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingAccountsForPinning: true, accountsForPinning: [] };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT_ACCOUNT_PIN_OPTIONS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingAccountsForPinning: false, accountsForPinning: action.data.data };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT_ACCOUNT_PIN_OPTIONS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingAccountsForPinning: false, accountsForPinning: [] };

        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNTS_SEARCH, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingMasterAccounts: true, masterAccounts: [] };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNTS_SEARCH, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingMasterAccounts: false, masterAccounts: action.data.data };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNTS_SEARCH, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingMasterAccounts: false, masterAccounts: [] };

        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT_ACCOUNTS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isLinkingAccounts: true };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT_ACCOUNTS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isLinkingAccounts: false };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT_ACCOUNTS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isLinkingAccounts: false };

        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT_ACCOUNT, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, accountBeingUnlinked: action.subId };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT_ACCOUNT, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, accountBeingUnlinked: null };
        case getNamedAction(ACTION_NAMES.MASTER_ACCOUNT_ACCOUNT, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, accountBeingUnlinked: null };

        default:
            return state;
    }
}

export default masterAccountReducer;
