export const KEY_CREATED = "created";
export const KEY_CREATED_BY = "created_by";
export const KEY_DUE_ON = "due_on";
export const KEY_INVOICE_ID = "invoice_id";
export const KEY_ORDER_ID = "order_id";
export const KEY_INVOICE_STATUS = "invoice_status";
export const KEY_ACCOUNT_OWNER = "Account Owner";
export const KEY_ACCOUNT_CO_OWNER = "Account Co-Owner";
export const KEY_SUBTOTAL = "subtotal";
export const KEY_PAYMENT_TYPE = "payment_type";
export const KEY_TERM = "Term";
export const KEY_NUMBER_PRODUCTS = "# Products";
export const KEY_PRODUCTS = "Products";
export const KEY_ACCOUNT_NAME = "account_id";

export const KEY_CONTACT_NAME = "Contact Name";
