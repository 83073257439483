import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    const unlisten = () => {
      window.scrollTo(0, 0);
    }
    return () => {
      unlisten();
    }
  }, [pathname]);

  return (null);
}

export default ScrollToTop;
