import React, { useCallback, useEffect, useState } from 'react';
import '../index.scss';
import { Table, Button, EllipsisDropdown, TableLoader, Modal, STATUS_TYPES, Notification } from 'dyl-components';
import { Table as SemanticTable, Icon, Dropdown, Header, Popup } from 'semantic-ui-react';
import { KEY_RULE_NAME, KEY_DESCRIPTION, KEY_DISTRIBUTION_TYPE, 
        KEY_ASSIGNED, KEY_CREATED_ON, KEY_CREATED_BY, 
        KEY_ACTIVE, KEY_ACTIONS } from './constants';
import { AssignmentRulesRow } from './AssignmentRulesRow';
import { NavLink, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import automationActions from 'actions/automation';
import { StringUtils } from 'utils';
import OrderAssignmentRulesModal from 'shared/modals/OrderAssignmentRulesModal/OrderAssignmentRulesModal';

const AssignmentRulesTable = () => {

    const ASSIGNMENT_LIMIT = 75;

    // TODO: Set empty once endpoint is reached and data loads
    const [expandedRows, setExpandedRows] = useState([]);
    const [rulesBeingEdited, setRulesBeingEdited] = useState([]);
    const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
    const [isLimitReached, setIsLimitReached] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const dispatch = useDispatch();

    const {assignmentRules, isReadingTriggers} = useSelector((state) => ({
        assignmentRules: state.automation.triggers,
        isReadingTriggers: state.automation.isReadingTriggers
    }));

    const onGetAssignmentRules = useCallback(async () => {
        await dispatch(automationActions.getTriggers({limit: ASSIGNMENT_LIMIT}));
    }, [dispatch]);

    const onDelete = async (id) => {
        try {
            await dispatch(automationActions.deleteTrigger(`${id}`));
            Notification.alert(`Successfully removed assignment rule!`, STATUS_TYPES.SUCCESS, true);
            onGetAssignmentRules();
        } catch (error) {
            Notification.alert(`Failed to remove assignment rule!`, STATUS_TYPES.ERROR, true);
        }
    };
    
    const onCloseOrderModal = () => {
        setIsOrderModalOpen(false);
    }

    const onOpenOrderModal = () => {
        setIsOrderModalOpen(true);
    }

    const onToggleField = (field_id) => {
		setExpandedRows(expandedRows.includes(field_id) ? expandedRows.filter(id => id !== field_id) : [...expandedRows, field_id])
	}

	const isExpanded = field_id => expandedRows.includes(field_id);

    const DropdownItem = ({ text, icon, ...otherProps }) => {
        return (
            <Dropdown.Item text={<React.Fragment>
                <Icon name={icon} />
                {text}
            </React.Fragment>}
                style={{ cursor: 'pointer' }}
                {...otherProps}
            />
        );
    }

    const onChangeOrderClick = (section) => {
        const rules = assignmentRules[section.toLowerCase()];
        setRulesBeingEdited({data: rules?.data || [], name: section});
        onOpenOrderModal();
    }
    
    const renderEmpty = (
        <Table.Row>
            <Table.Cell colspan={9}>
                <div className='AssignmentRules_emptyContainer'> 
                    <Header as="h4" style={{marginBottom: 0}}>No Assignment Rule Created</Header>
                    <div>
                        Add an Assignment Rule by clicking 
                        <Link className='ARTable__link ARTable__latoBlack' to='/settings/assignment-rules/create'> + New Rule</Link>
                    </div>
                </div>
            </Table.Cell>
        </Table.Row>
    )

    const getAssignmentCategories = () => {
        const names = Object.keys(assignmentRules);
        const values = Object.values(assignmentRules);
        names.pop();
        values.pop();
        const result = values.map((value, index) => {
            return {
                name: StringUtils.capitalize(names[index]),
                content: value?.data?.map((rule, index) => {
                    return (
                        <AssignmentRulesRow
                            id={rule.id}
                            index={index} 
                            name={rule.name} 
                            description={rule.description} 
                            distributionType={rule.distributionType} 
                            usersAssigned={rule.user_counts} 
                            dateCreated={rule.created}
                            createdBy={rule.createdBy} 
                            isActive={rule.active} 
                            onDelete={() => onDelete(rule.id)}
                        />
                    )
                })
            }
        })
        return result
    }

    const renderSection = (section, innerContent) => {
        return (
            <Table.CollapsibleRow 					
                onToggle={() => { onToggleField(section) }}
                collapsed={!isExpanded(section)}
                subrowContent={innerContent}
            >
            <Table.Cell colspan={7}>
                <div className='ARTable__latoBlack'>{section}</div>
            </Table.Cell>
            <Table.Cell colspan={1}>
                <EllipsisDropdown>
                    <DropdownItem
                        key={section}
                        text={"Change Order"}
                        icon={'resize vertical'}
                        onClick={() => onChangeOrderClick(section)}
                        style={{marginVertical: "10px"}}
                    />
                </EllipsisDropdown></Table.Cell>
            </Table.CollapsibleRow>
        )
    }

    useEffect(() => {
        onGetAssignmentRules();
    }, [dispatch, onGetAssignmentRules]);

    useEffect(() => {
        const areAssignmentRulesFull = () => {
            const { contact, lead, opportunity } = assignmentRules;
            return contact.count === 25 && lead.count === 25 && opportunity.count === 25;
        }

        const areAssignmentRulesEmpty = () => {
            if (assignmentRules.length === 0) {
                return true;
            }
            for (let key in assignmentRules) {
                if (assignmentRules[key]?.data?.length > 0) {
                    return false
                }
            }
            return true
        }

        const areRulesEmpty = areAssignmentRulesEmpty();
        if (!areRulesEmpty) {
            const areRulesFull = areAssignmentRulesFull();
            setIsEmpty(false);
            setIsLimitReached(areRulesFull);
        } else {
            setIsEmpty(true);
            setIsLimitReached(false);
        }
    }, [assignmentRules])

    return (
        <>
            <div> 
                <div style={{display:"flex", justifyContent: "flex-end"}}>
                    {isLimitReached ? (
                        <Popup
                            trigger={<div><Button disabled={true}><Icon name="plus" /> New Rule</Button></div>}
                            content='Assigment Rule limit has been reached.'
                            inverted
                            flowing
                        />
                    ) : (
                        <Button as={NavLink} disabled={isLimitReached} to={`/settings/assignment-rules/create`} ><Icon name="plus" /> New Rule</Button>
                    )}
                </div>
                <Table>
                    <SemanticTable.Header className="Table__header--primary">
                        <Table.Row>
                            <SemanticTable.HeaderCell width={1}></SemanticTable.HeaderCell>
                            <SemanticTable.HeaderCell width={3}>{KEY_RULE_NAME}</SemanticTable.HeaderCell>
                            <SemanticTable.HeaderCell width={3}>{KEY_DESCRIPTION}</SemanticTable.HeaderCell>
                            <SemanticTable.HeaderCell>{KEY_DISTRIBUTION_TYPE}</SemanticTable.HeaderCell>
                            <SemanticTable.HeaderCell>{KEY_ASSIGNED}</SemanticTable.HeaderCell>
                            <SemanticTable.HeaderCell>{KEY_CREATED_ON}</SemanticTable.HeaderCell>
                            <SemanticTable.HeaderCell>{KEY_CREATED_BY}</SemanticTable.HeaderCell>
                            <SemanticTable.HeaderCell>{KEY_ACTIVE}</SemanticTable.HeaderCell>
                            <SemanticTable.HeaderCell>{KEY_ACTIONS}</SemanticTable.HeaderCell>
                        </Table.Row>
                    </SemanticTable.Header>
                    <Table.Body>
                        {isReadingTriggers ? (
                            <TableLoader isLoading colSpan={8} />
                        ) : (isEmpty ? renderEmpty : (
                            getAssignmentCategories().map((category) => {
                                return !!category?.content ? renderSection(category.name, category.content) : null
                            })
                        ))}
                    </Table.Body>
                </Table>
            </div>
            <Modal open={isOrderModalOpen} onClose={onCloseOrderModal}>
                <OrderAssignmentRulesModal rules={rulesBeingEdited} onClose={onCloseOrderModal} onGetAssignmentRules={onGetAssignmentRules} />
            </Modal>
        </>
        
    )
};

export default AssignmentRulesTable;
