import React, { useContext, useState } from 'react'; 
import { useSelector } from 'react-redux';
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import { Button, Grid } from 'semantic-ui-react';
import { SearchBar } from 'dyl-components';
import NotificationBulkActionsModal from 'shared/modals/NotificationBulkActionsModal';
import '../index.scss';

const NotficationsToolbar = ({ user_id, search, onSearchSubmit, cancelFunction, onChangeSearch }) => {
    const { isReadingNotificationHub, count } = useSelector(state => state.notifications);

    const [selectedNotifications, , areNotificationsInAllPagesSelected] = useContext(BulkActionsContext);

    const [isBulkActionsModalOpen, setIsBulkActionsModalOpen] = useState(false);
    const hasSelectedNotifications = selectedNotifications.length || areNotificationsInAllPagesSelected;
    const numberSelectedNotifications = areNotificationsInAllPagesSelected ? count : selectedNotifications.length;

    const onOpenBulkActionsModal = () => {
        setIsBulkActionsModalOpen(true);
    }

    const onCloseBulkActionsModal = () => {
        setIsBulkActionsModalOpen(false);
    }

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row columns='equal' verticalAlign='middle'>
                    <Grid.Column width={5}>
                        <SearchBar
                            isSearching={isReadingNotificationHub}
                            search={search}
                            searchFcn={onSearchSubmit}
                            cancelFcn={cancelFunction}
                            placeholder='Search by contact name'
                            addClassNames='Notification__Search'
                            onChange={onChangeSearch}
                        />
                    </Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column width={3} floated='right'>
                        <Button floated='right' className='NotificationsToolbar__BulkActionButton' disabled={!hasSelectedNotifications} onClick={onOpenBulkActionsModal}>
                            Bulk Action
                        </Button>
                    </Grid.Column>
                    <Grid.Column width={2} floated='right'>
                        <Button basic as='a' href={`/settings/users/${user_id}/notification`} floated='right' className='NotificationsToolbar__UserSettingsButton'>
                            User Settings
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <NotificationBulkActionsModal
                open={isBulkActionsModalOpen}
                onClose={onCloseBulkActionsModal}
                numberSelectedNotifications={numberSelectedNotifications}
            />
        </React.Fragment>

    )
}

export default NotficationsToolbar;
