import React from 'react';
import { Grid, List } from 'semantic-ui-react';
import { ButtonLink, Modal } from 'dyl-components';
import { Link } from 'react-router-dom';
import OptionsAreaModalContent from './OptionsAreaModalContent';

import './OptionsArea.scss';

const OptionsArea = ({ onToggleModal }) => (
    <Grid>
        <Grid.Row columns={2}>
            <Grid.Column>
                <List className="OptionsAreaList" size='large'>
                    <List.Item>
                        <List.Header as={ButtonLink} onClick={() => { onToggleModal('exec') }}>Exec/Rep Options</List.Header>
                        <List.Content>Match DYL users to execs and reps in AMS360.</List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Header as={ButtonLink} onClick={() => { onToggleModal('login') }}>Login Info</List.Header>
                        <List.Content>Update your AMS360 login information.</List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Header as={ButtonLink} onClick={() => { onToggleModal('options') }}>Options</List.Header>
                        <List.Content>Configure integration options.</List.Content>
                    </List.Item>
                </List>
            </Grid.Column>
            <Grid.Column>
                <List className="OptionsAreaList" size='large'>
                    <List.Item>
                        <List.Header as={ButtonLink} onClick={() => { onToggleModal('test') }}>Test Features</List.Header>
                        <List.Content>Test integration features.</List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Header as={Link} to='/reports/crm_activity'>Activity Log</List.Header>
                        <List.Content>View report of all CRM activity.</List.Content>
                    </List.Item>
                </List>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

class OptionsAreaContainer extends React.Component {
    state = {
        activeModal: '',
        modalState: {
            unassignedUsers: []
        }
    }

    onToggleModal = (modalName) => {
        this.setState({ activeModal: modalName });
    }

    onClose = () => {
        this.setState({ activeModal: '' });
    }

    render() {
        return (
            <React.Fragment>
                <OptionsArea 
                    onToggleModal={this.onToggleModal}
                />
                <Modal open={this.state.activeModal} onClose={this.onClose} size='small'>
                    <OptionsAreaModalContent 
                        modalName={this.state.activeModal} 
                        onClose={this.onClose}
                        {...this.state.modalState}
                    />
                </Modal>
            </React.Fragment>
        )
    }
}

export default OptionsAreaContainer;
