import React from "react";
import { Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";

const ConvertNewCustomerPopup = ({
    account_id,
    isPrimaryContact,
    open,
    trigger,
    position = "bottom left"
}) => {
    return (
        <Popup
            open={open}
            disabled={
                !isPrimaryContact
            }
            position={position}
            content={
                open && (
                    <div style={{ minWidth: isPrimaryContact ? "20em" : "25em" }}>
                        {(isPrimaryContact && (
                            <>
                                Primary contact cannot be unlinked.
                                <div>
                                    <Link
                                        to={`/account/${account_id}`}
                                        style={{
                                            textDecoration: "underline",
                                            color: "unset",
                                        }}
                                    >
                                        Change primary contact for current
                                        customer
                                    </Link>
                                </div>
                            </>
                        ))}
                    </div>
                )
            }
            inverted
            trigger={trigger}
        />
    );
};

export default ConvertNewCustomerPopup;
