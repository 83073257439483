const initialState = {
    phone_numbers: [],
    isReadingPhoneNumbers: false,
    search_results: [],
    isSearchingPhoneNumbers: false,
    domain: '',
    phoneNumberBeingUpdated: null
}

export default initialState;
