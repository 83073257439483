import { ButtonLink, CheckboxDropdown } from 'dyl-components';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Dropdown, Form, Loader } from 'semantic-ui-react';
import { DUPLICATE_OPTIONS } from './DYLFieldsConstants';
import './ImportSetupFields.scss';

const ImportSetupFields = ({
    control,
    module,
    clearStage,
    duplicate_option,
    disable_module,
    pipelineCategories,
    isReadingPipelineCategories,
	stageName
}) => {
    const pipelineOptions = pipelineCategories ? pipelineCategories.map((pipeline)=>(({key: pipeline.id, value: pipeline.name, text: pipeline.name}))) : [{key: 'no_option', value: 'No Options', text: 'No Options'}];
    return [
        <Form.Field
            control='div'
            label='Module'
            required
        >
            <Controller
                control={control}
                name='module'
                render={({ field: { name, value, onChange } }) => (
                    <Form.Select
                        options={[
                            {
                                key: 'contacts', value: 'Contact', text: 'Contact'
                            },
                            {
                                key: 'leads', value: 'Lead', text: 'Lead'
                            },
                            {
                                key: 'opportunitiy', value: 'Opportunity', text: 'Opportunity'
                            },
                            {
                                key: 'customer', value: 'Customer', text: 'Customer'
                            },
                        ]}
                        name={name}
                        value={value}
                        onChange={(_, { value }) => {
                            onChange({ target: { name, value } });
                            clearStage();
                        }}
                        placeholder='Select module'
                        selectOnBlur={false}
                        disabled={disable_module}
                    />
                )}
            />
            <em>Import as <Controller
                control={control}
                name='stage'
                rules={{
                    required: {
                        value: module && module !== 'Contact',
                        message: 'This field is required'
                    }
                }}
                render={({ field: { name, value, onChange } }) => (
                    isReadingPipelineCategories ?
                        <Loader />
                        :
                        <Dropdown
                            placeholder='select stage'
                            disabled={!module || module === 'Contact'}
                            name={name}
                            value={value}
                            onChange={(_, { value }) => {
                                onChange({ target: { name, value } })
                            }}
                            trigger={<ButtonLink>{value || stageName || 'select stage'}</ButtonLink>}
                            icon=''
                            options={pipelineOptions}
                        />
                )}
            /></em>
        </Form.Field>,
        <Form.Field
            control='div'
            required
            label='Duplicate Option'
        >
            <Controller
                control={control}
                name='duplicate_option'
                render={({ field: { name, value, onChange } }) => (
                    <Form.Select
                        options={DUPLICATE_OPTIONS}
                        name={name}
                        value={value}
                        onChange={(_, { value }) => {
                            onChange({ target: { name, value } });
                        }}
                        placeholder='Select option'
                        selectOnBlur={false}
                    />
                )}
            />
            <small>Match duplicates by <Controller
                control={control}
                name='match_type'
                rules={{
                    required: {
                        value: duplicate_option && duplicate_option !== 'duplicate',
                        message: 'This field is required'
                    }
                }}
                render={({ field: { name, value, onChange } }) => (
                    <CheckboxDropdown
                        placeholder='match type'
                        disabled={!duplicate_option || duplicate_option === 'duplicate'}
                        name={name}
                        value={value}
                        onChange={(_, { value }) => {
                            onChange({ target: { name, value } })
                        }}
                        options={[
                            {
                                key: 'email', value: 'email', text: 'Email'
                            },
                            {
                                key: 'full_name', value: 'full_name', text: 'Full Name'
                            },
                            {
                                key: 'phone', value: 'phone', text: 'Phone Number'
                            }
                        ]}
                        multiple
                        text={(
                            <div style={{ marginBottom: '-0.4em', width: '5.5em', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                {value?.length ? value?.map((type, index) => (
                                    <React.Fragment>
                                        <small className='DuplicateType'>{type}</small>{index < value.length ? ', ' : ''}
                                    </React.Fragment>
                                )) : <ButtonLink>match type</ButtonLink>}
                            </div>
                        )}
                    />
                )}
            /></small>
        </Form.Field>
    ]
};

export default ImportSetupFields;
