const initialState = {
    count: 0,
    addons: [],
    isReadingAddons: false,
    isCreating: false,

    addon: null,

    addonBeingRead: null,
    addonBeingUpdated: false,
    addonBeingDeleted: false,
    addonError: false,

    isCheckingForDuplicates: false
}

export default initialState;
