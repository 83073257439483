import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function productsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PRODUCT, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingProducts: true };
        case getNamedAction(ACTION_NAMES.PRODUCT, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingProducts: false, categories: action.data.categories || [], products: action.data.products || [], count: action.data.count || 0, queryParameters: action.queryParameters };
        case getNamedAction(ACTION_NAMES.PRODUCT, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingProducts: false };

        case getNamedAction(ACTION_NAMES.CATEGORY_PRODUCT, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCategoryProducts: true };
        case getNamedAction(ACTION_NAMES.CATEGORY_PRODUCT, CRUD_ACTION_TYPES.READ_SUCCESS):
            const { product_category_id } = action.queryParameters;
            return {
                ...state,
                isReadingCategoryProducts: false,
                category_products: { ...state.category_products, [product_category_id]: action.data.products }
            };
        case getNamedAction(ACTION_NAMES.CATEGORY_PRODUCT, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCategoryProducts: false };

        case getNamedAction(ACTION_NAMES.PRODUCT, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreating: true };
        case getNamedAction(ACTION_NAMES.PRODUCT, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreating: false };
        case getNamedAction(ACTION_NAMES.PRODUCT, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreating: false };

        case getNamedAction(ACTION_NAMES.PRODUCT, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, productBeingRead: action.id, product: null, productError: false };
        case getNamedAction(ACTION_NAMES.PRODUCT, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, productBeingRead: null, product: action.data, productError: false };
        case getNamedAction(ACTION_NAMES.PRODUCT, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, productBeingRead: null, product: null, productError: true };

        case getNamedAction(ACTION_NAMES.PRODUCT, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, productBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.PRODUCT, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, productBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.PRODUCT, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, productBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.PRODUCT, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, productBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.PRODUCT, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, productBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.PRODUCT, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, productBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.CHECK_PRODUCT_DUPLICATE, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isCheckingForDuplicates: true };
        case getNamedAction(ACTION_NAMES.CHECK_PRODUCT_DUPLICATE, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isCheckingForDuplicates: false };
        case getNamedAction(ACTION_NAMES.CHECK_PRODUCT_DUPLICATE, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isCheckingForDuplicates: false };

        default:
            return state;
    }
}


export default productsReducer;
