import React, { useState } from 'react';
import { Table, ButtonLink, TableLoader } from 'dyl-components';
import { Icon, Table as SemanticTable, Popup } from 'semantic-ui-react';

import { DataRecordRow } from './DataRecordRow';

import { AddDataRecordForm } from './AddDataRecordForm';

export const DataRecordsTable = ({
    data_records = [],
    isReadingDataRecords,
    refresh,
    provider_id
}) => {
    const [dataRecordBeingEdited, setDataRecordBeingEdited] = useState(null);
    const [isAddRecordModalOpen, setIsAddRecordModalOpen] = useState(null);

    const onEdit = (id) => {
        setDataRecordBeingEdited(id);
    }

    const onCancelEdit = () => {
        setDataRecordBeingEdited(null);
    }

    const handleRecordsModalOpen = () => {
        setIsAddRecordModalOpen(true);
    }

    const handleRecordsModalClose = () => {
        setIsAddRecordModalOpen(false);
    }

    return (
        <Table>
            <SemanticTable.Header>
                <SemanticTable.Row>
                    <SemanticTable.HeaderCell>
                        Data Record Name
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Module
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Method
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Product Interests
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Status
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell />
                </SemanticTable.Row>
            </SemanticTable.Header>
            <Table.Body>
                {!isReadingDataRecords ? data_records.map(data_record => {
                    const isEditing = data_record.id === dataRecordBeingEdited;
                    return (
                        <DataRecordRow
                            data_record={data_record}
                            isEditing={isEditing}
                            onCancelEdit={onCancelEdit}
                            onEdit={() => { onEdit(data_record.id) }}
                            key={data_record.id}
                            refresh={refresh}
                        />
                    );
                }) : (
                    <TableLoader colspan={6} isLoading />
                )}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.Cell colSpan={6}>
                        <Popup
                            trigger={<ButtonLink><Icon name='plus circle' /> New Data Record</ButtonLink>}
                            on='click'
                            content={<AddDataRecordForm refresh={refresh} provider_id={provider_id} onClose={handleRecordsModalClose} />}
                            wide='very'
                            style={{ width: '600px' }}
                            position='bottom left'
                            open={isAddRecordModalOpen}
                            onOpen={handleRecordsModalOpen}
                            onClose={handleRecordsModalClose}
                        />
                    </Table.Cell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
}
