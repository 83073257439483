import React from 'react';
import { Popup, Checkbox, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { Table, MessagingIcon } from 'dyl-components';
import { TextMessageModal, EmailModal, NotesModal } from 'shared/modals';
import { connect } from 'react-redux';

import smsListActions from 'actions/sms_list';
import { TwoLinedContent } from 'dyl-components';
import LatestNoteContent from 'shared/LatestNoteContent';

const TextListRow = ({ lead, onMark, onCall, onToggleModal, onShowNote, onConsent, noteVisible }) => (
    <Table.Row active={lead.isMarked}>
        <Table.Cell>
            <Checkbox checked={lead.isMarked} onChange={() => { onMark(lead.id) }} />
        </Table.Cell>
        <Table.Cell>
            <TwoLinedContent firstLine={lead.ts.split('T')[0]} secondLine={lead.ts.split('T')[1]} />
        </Table.Cell>
        <Table.Cell>
            <TwoLinedContent firstLine={lead.source} secondLine={lead.type} />
        </Table.Cell>
        <Table.Cell>
            <Link to={`/lead?id=${lead.id}`}>
                <Icon name='circle' className={`Contact__status-icon`} />
                <TwoLinedContent className={'Contact__details'} firstLine={lead.name} secondLine={lead.phone} />
            </Link>
        </Table.Cell>
        <Table.Cell>
            {lead.latest_note && (
                <LatestNoteContent
                    noteVisible={noteVisible}
                    latestNote={lead.latest_note}
                    onShowNote={onShowNote}
                />
            )}
        </Table.Cell>
        <Table.Cell>
            <Checkbox onChange={() => { onConsent(lead.id) }} checked={lead.isConsented} />
        </Table.Cell>
        <Table.Cell>
            <Popup inverted position='top center' trigger={<span className='LeadsTable__messagingIcon'><MessagingIcon size='' icon={'phone'} onClick={() => { onCall(lead) }} /></span>} content='Call' />
            <Popup inverted position='top center' trigger={<span className='LeadsTable__messagingIcon'><MessagingIcon size='' icon={'conversation'} onClick={() => { onToggleModal('text') }} /></span>} content='Text' />
            <Popup inverted position='top center' trigger={<span className='LeadsTable__messagingIcon'><MessagingIcon size='' icon={'mail'} onClick={() => { onToggleModal('email') }} /></span>} content='Email' />
            <Popup inverted position='top center' trigger={<span className='LeadsTable__messagingIcon'><MessagingIcon size='' icon={'list alternate outline'} onClick={() => { onToggleModal('add_note') }} /></span>} content='View Notes' />
        </Table.Cell>
    </Table.Row>
)

class TextListRowContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeModal: null,
            actionBarVisible: false,
            noteVisible: false
        }
        this.onToggleModal = this.onToggleModal.bind(this);
        this.onShowNote = this.onShowNote.bind(this);
    }

    onToggleModal(name) {
        this.setState({
            activeModal: name
        })
    }

    isOpenModal(name) {
        return this.state.activeModal === name;
    }

    onShowNote() {
        this.setState({
            noteVisible: !this.state.noteVisible
        })
    }

    render() {
        const { lead } = this.props;
        return (
            <React.Fragment>
                <TextListRow
                    lead={lead}
                    onToggleModal={this.onToggleModal}
                    noteVisible={this.state.noteVisible}
                    onShowNote={this.onShowNote}
                    {...this.props}
                />
                <TextMessageModal
                    open={this.isOpenModal('text')}
                    onClose={this.onToggleModal}
                    to_phone={lead.phone}
                />
                <EmailModal
                    open={this.isOpenModal('email')}
                    onClose={this.onToggleModal}
                    lead={lead}
                />
                <NotesModal
                    open={this.isOpenModal('add_note')}
                    onClose={this.onToggleModal}
                    lead={lead}
                />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    onMark: (id) => {
        dispatch(smsListActions.markLeadInTextList(id));
    },
    onConsent: (id) => {
        dispatch(smsListActions.onConsent(id));
    }
})

export default connect(null, mapDispatchToProps)(TextListRowContainer);
