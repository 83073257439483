import { Header, Form } from 'semantic-ui-react'
import { RichTextEditor } from 'dyl-components';
import { Controller } from 'react-hook-form';
import React from 'react';

const SuccessMessageForm = ({ control }) => {
    return (
        <Form className='form-options--increase-left-margin'>
            <Header as='h3'>
                Success Message
                <Header.Subheader>
                    Add a custom message for your users. This message will appear once they successfully fillup the form.
                </Header.Subheader>
            </Header>
            <br />
            <Controller
                name='success_message'
                control={control}
                render={({ field: { name, onChange, value } }) => (
                    <Form.Field
                        control={RichTextEditor}
                        toolbarClassName={"success_message_toolbar"}
                        value={value}
                        controls={(
                            <React.Fragment>
                                <span className="ql-formats">
                                    <select className="ql-font"></select>
                                </span>
                                <select className="ql-header">
                                    <option value="1"></option>
                                    <option value="2"></option>
                                    <option selected></option>
                                </select>
                                <span className="ql-formats">
                                    <button className="ql-bold"></button>
                                    <button className="ql-italic"></button>
                                    <button className="ql-underline"></button>
                                </span>
                                <span className="ql-formats">
                                    <select className="ql-color"></select>
                                </span>
                            </React.Fragment>
                        )}
                        onChange={(_, { value: new_value }) => { onChange({ target: { name, value: new_value } }) }}
                    />
                )}
            />
        </Form>
    );
}

export default SuccessMessageForm;
