import React, { useState } from 'react';
import { Modal, Notification, STATUS_TYPES, VALIDATION_PATTERNS } from 'dyl-components';
import { connect } from 'react-redux';
import { VALIDATORS } from 'dyl-components';
import AddUserForm from './AddUserForm';
import authActions from 'actions/auth/auth';
import assignActions from 'actions/assign';
import teamActions from 'actions/team';
import { QAOptionsUtils } from 'utils';
import userActions from 'actions/user';

const AddUserModal = ({
    open,
    onAddLogin,
    onClose,
    onInputChange,
    isCreating,
    isValid,
    isEmailValid,
    emailErrorMessage,
    isNameValid,
    onCheckEmailValidity,
    isPhoneNumberValid,
    isWorkNumberValid,
    isExtensionValid,
    errorMessages,
}) => (
    <Modal open={open} onClose={onClose} size='small'>
        <Modal.Header>
            Add New User
        </Modal.Header>
        <Modal.Content>
            <AddUserForm
                onInputChange={onInputChange}
                emailErrorMessage={emailErrorMessage}
                isNameValid={isNameValid}
                isEmailValid={isEmailValid}
                onCheckEmailValidity={onCheckEmailValidity}
                isPhoneNumberValid={isPhoneNumberValid}
                isWorkNumberValid={isWorkNumberValid}
                isExtensionValid={isExtensionValid}
                errorMessages={errorMessages}
            />
        </Modal.Content>
        <Modal.Actions hasSaveButton onSave={onAddLogin} saveDisabled={!isValid} saveOptions={{ loading: isCreating }} />
    </Modal>
)

const AddUserModalContainer = (props) => {

    const [userAccount, setUserAccount] = useState({
        first_name: '',
        last_name: '',
        email: '',
        street: '',
        suite: '',
        city: '',
        state: '',
        zipcode: '',
        timezone: 'America/New_York',
        work_number: '',
        extension: '',
        cell_number: '',
        report_to: 0,
        teams: [],
        profile_type: (props.roles.find((role) => role.text === "User")?.value),
        job_title: ''
    });
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
    const [isWorkNumberValid, setIsWorkNumberValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isExtensionValid, setIsExtensionValid] = useState(true);
    const [errorMessages, setErrorMessages] = useState({});
    const [lineAssignments, setLineAssignements] = useState({});

    const onInputChange = async (_, { name, value }) => {
        let isExtensionValid = await VALIDATORS.PHONE_NUMBER_EXTENSION().isValid(value);
        let isvalidNum = await VALIDATORS.PHONE_NUMBER().isValid(value);

        if(name === 'job_title'){
            let isvalidJobTitle = await VALIDATORS.JOB_TITLE().isValid(value);
            let errorMsg = isvalidJobTitle ? false : 'This field only allows alphanumeric characters and - _ / & . ';
            setErrorMessages({ ...errorMessages, [name]: errorMsg });
        }

        if (name === 'cell_number') setIsPhoneNumberValid(isvalidNum);
        if (name === 'work_number') setIsWorkNumberValid(isvalidNum);
        if (name === 'extension') setIsExtensionValid(isExtensionValid);
        setUserAccount({ ...userAccount, [name]: value });
    }

    const isNameValid = async (name, required = true, min = 2, max = 64) => {
        const checkedName = userAccount[name];
        let errorMsg = false;

        if (required && checkedName.trim().length < 1) {
            errorMsg = "This field is required";
        }

        if (checkedName) {

            const nameMinErrorMessage = `The min length for this field is ${min} characters`;
            const nameMaxErrorMessage = `The max length for this field is ${max} characters`;

            if (checkedName.length < min) {
                errorMsg = nameMinErrorMessage;
            }
            if (checkedName.length > max) {
                errorMsg = nameMaxErrorMessage;
            }

            if (name === 'zipcode') {
                let digitCheck = /^[0-9]{5}?$/;
                errorMsg = (checkedName.match(digitCheck)) ? errorMsg : 'This field should contain only US postal codes';
            }

            if (name === 'first_name' || 'last_name') {
                let nameCheck = VALIDATION_PATTERNS.alphanumeric;
                errorMsg = (checkedName.match(nameCheck)) ? errorMsg : 'This field only allows alphanumeric characters and : or - ';
            }
          
        }
        setErrorMessages({ ...errorMessages, [name]: errorMsg });
    }

    const isValid = () => {
        const { first_name, last_name, timezone, cell_number, work_number, email, state, profile_type, extension } = userAccount;

        const optionalFieldsAreValid = Object.values(errorMessages).every((item => item === false)) //confirm no errors (all are false)

        return optionalFieldsAreValid && first_name && last_name && email && state && email.trim() && isEmailValid && profile_type && timezone && (cell_number ? isPhoneNumberValid : true) && (work_number ? isWorkNumberValid : true) && (extension ? isExtensionValid : true);
    }

    const onCheckEmailValidity = async () => {
        const { email } = userAccount;
        let isEmailValid = false;
        let emailErrorMessage = 'Email already exists. Please try another one.';

        if (email.length <= 256) {
            if (email.includes('@')) {
                const { customer_id, user_id } = await props.onFindByEmail(email);
                isEmailValid = !(customer_id && user_id);
            } else {
                emailErrorMessage = 'Please enter a valid email address';
            }
        } else {
            emailErrorMessage = 'The max length for this field is 256 characters';
        }

        setIsEmailValid(isEmailValid);
        setEmailErrorMessage(emailErrorMessage);
    }

    const onToggleLineAssignmentButton = (button1, button2) => {
        setLineAssignements({
            [`lineAssignmentButton${button1}`]: true,
            [`lineAssignmentButton${button2}`]: false,
        });
    }

    const onAddLogin = () => {
        const {
            first_name,
            last_name,
            email,
            street,
            suite,
            city,
            state,
            zipcode,
            timezone,
            work_number,
            extension,
            cell_number,
            report_to,
            teams,
            profile_type,
            callerId,
            job_title
        } = userAccount;

        const qaExpirationTime = QAOptionsUtils.getQueryOption('qattl') || null;  //allows QA to change the expiration of a new user link

        props.onAddLogin({
            first_name,
            last_name,
            email,
            street,
            suite,
            city,
            state,
            zipcode,
            timezone,
            work_number,
            extension,
            cell_number,
            report_to,
            caller_id: callerId,
            label: 'Office',
            main: true,
            profile_type,
            job_title,
            teams,
            ttl: qaExpirationTime
        }).then(() => {
            Notification.alert('Successfully added user!', STATUS_TYPES.SUCCESS, true);
            setUserAccount({
                first_name: '',
                last_name: '',
                email: '',
                street: '',
                suite: '',
                city: '',
                state: '',
                zipcode: '',
                timezone: 'America/New_York',
                work_number: '',
                extension: '',
                cell_number: '',
                report_to: 0,
                teams: [],
                profile_type: 1,
                job_title: ''
            })
            props.onClose();
            props.onReadUsers({ limit: 25, page: 1 });
        }).catch(() => {
            Notification.alert('Failed to add user', STATUS_TYPES.ERROR, true);
        });
    }

    return (
        <AddUserModal
            {...props}
            onAddLogin={onAddLogin}
            onToggleLineAssignmentButton={onToggleLineAssignmentButton}
            lineAssignmentButton1={lineAssignments.lineAssignmentButton1}
            lineAssignmentButton2={lineAssignments.lineAssignmentButton2}
            onInputChange={onInputChange}
            isValid={isValid()}
            emailErrorMessage={emailErrorMessage}
            isNameValid={isNameValid}
            onCheckEmailValidity={onCheckEmailValidity}
            isEmailValid={isEmailValid}
            isPhoneNumberValid={isPhoneNumberValid}
            isWorkNumberValid={isWorkNumberValid}
            isExtensionValid={isExtensionValid}
            errorMessages={errorMessages}
        />
    )
}

const mapStateToProps = state => {
    return ({
        isCreating: state.users.isCreatingUser || state.auth.isCreatingUser || state.team.teamWhereUsersAreBeingAdded,
        queryParameters: state.assign.queryParameters,
        roles: state.roles.roles.map(({ id, name }) => ({
            key: id,
            value: id,
            text: name
        }))
    });
}

const mapDispatchToProps = dispatch => ({
    onAddLogin: (userDetails) => {
        return dispatch(authActions.createUser(userDetails));
    },
    onReadUsers: (queryParameters) => {
        dispatch(assignActions.readUserAccounts(queryParameters));
    },
    onAddUserToTeam: (user, team_id) => {
        return dispatch(teamActions.addUsers([user], null, team_id));
    },
    onFindByEmail: (email) => {
        return dispatch(userActions.findByEmail('', { email }));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModalContainer);
