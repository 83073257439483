const initialState = {
    isReadingActivityLog: false,
    activityLog: [],
    
    isLoggingCall: false,
    userCallLogs: [],
    customerCallLogs: [],
    callLogBeingUpdated: null,
    callLogBeingDeleted: null,
    isReadingUserCallLogs: false,
    isReadingCustomerCallLogs: false,

    isLoggingText: false,
    userTextLogs: [],
    customerTextLogs: [],
    textLogBeingUpdated: null,
    textLogBeingDeleted: null,
    isReadingUserTextLogs: false,
    isReadingCustomerTextLogs: false,

    isLoggingEmail: false,
    userEmailLogs: [],
    customerEmailLogs: [],
    emailLogBeingUpdated: null,
    emailLogBeingDeleted: null,
    isReadingUserEmailLogs: false,
    isReadingCustomerEmailLogs: false,
}

export default initialState;
