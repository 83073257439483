import React from 'react';
import { ImportList, WebformCreate, DataProviderCreate, WebForms, DataProviders, AddImport, DataMapping } from 'pages';

const PARENT_NAME = "integrations_data_import";

export const INTEGRATIONS_SETTINGS = [
    {
        path: `web-forms`,
        icon: 'fas fa-file-alt',
        header: 'Web Forms',
        description: 'Add, preview, and customize your Contact Forms.',
        page: WebForms,
        type: `${PARENT_NAME}.web_forms`
    },
    {
        path: `web-forms/create`,
        icon: 'fas fa-file-alt',
        header: 'Create Web Form',
        description: 'Add, and customize your Contact Form.',
        page: WebformCreate,
        exclude: true,
        parent: {
            path: 'web-forms',
            name: 'Web Forms'
        }
    },
    {
        path: `web-forms/edit/:form_id`,
        icon: 'fas fa-file-alt',
        header: 'Edit Web Form',
        description: 'Edit your Contact Form.',
        page: WebformCreate,
        exclude: true,
        parent: {
            path: 'web-forms',
            name: 'Web Forms'
        }
    },
    {
        path: `import-data`,
        icon: 'fas fa-file-import',
        header: 'Import data',
        description: 'Import Contacts or Leads in bulk as .CSV.',
        page: ImportList,
        type: `${PARENT_NAME}.import_data`
    },
    {
        path: `import-history`,
        icon: 'fas fa-history',
        header: 'Import History',
        description: 'View import history, delete records, remap fields, or add to campaigns.',
        page: React.Fragment,
        type: `${PARENT_NAME}.import_history`
    },
    {
        path: `data-providers`,
        icon: 'fab fa-sourcetree',
        header: 'Data Providers',
        description: 'Add providers and organize by Source Type and Secondary Source.',
        page: DataProviders,
        type: `${PARENT_NAME}.data_providers`
    },
    {
        path: `data-providers/create`,
        icon: 'fab fa-sourcetree',
        header: 'Create Data Providers',
        description: 'Update the description when you get here :).',
        page: DataProviderCreate,
        exclude: true,
        parent: {
            path: 'data-providers',
            name: 'Data Providers'
        }
    },
    {
        path: 'import-data/setup',
        page: AddImport,
        exclude: true,
        parent: {
            path: 'import-data',
            name: 'Import Data'
        },
        header: 'Add New Import'
    },
    //post
    {
        path: 'data-mapping/post/:id',
        page: DataMapping,
        exclude: true,
        parent: {
            path: 'post-parsing-template',
            name: 'Parse Post',
            pathId: true, //Add if, breadcrumb path needs to pass :id to return to page
        },
        header: 'Data Mapping'
    },
    {
        path: 'data-mapping/post/edit/:id',
        page: DataMapping,
        exclude: true,
        parent: {
            path: 'post-parsing-template',
            name: 'Parse Post',
            pathId: true, //Add if, breadcrumb path needs to pass :id to return to page
        },
        header: 'Edit Data Mapping'
    },
    //email
    {
        path: 'data-mapping/email/:id',
        page: DataMapping,
        exclude: true,
        parent: {
            path: 'email-parsing-template',
            name: 'Parse Email',
            pathId: true, 
        },
        header: 'Data Mapping'
    },
    {
        path: 'data-mapping/email/edit/:id',
        page: DataMapping,
        exclude: true,
        parent: {
            path: 'email-parsing-template',
            name: 'Parse Email',
            pathId: true, 
        },
        header: 'Edit Data Mapping'
    },
    {
        path: 'assignments/:id',
        page: AddImport,
        exclude: true,
        parent: {
            path: 'data-mapping',
            name: 'Data Mapping',
            pathId: true,
        },
        header: 'Assignments'
    },
    {
        path: 'confirm/:id',
        page: AddImport,
        exclude: true,
        parent: {
            path: 'assignments',
            name: 'Assignments',
            pathId: true,
        },
        header: 'Confirm'
    }
];
