import { Notification, STATUS_TYPES } from 'dyl-components';

import routes from 'actions/routes';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import { generateReadActionCreator, generateCreateActionCreator, generateDeleteActionCreator } from 'actions/crudActionCreators';

const onDeleteSuccess = () => {
  Notification.alert('Succesfully deleted task type!', STATUS_TYPES.SUCCESS, true);
}

const onDeleteFailure = () => {
  Notification.alert('Failed to delete task type', STATUS_TYPES.ERROR, true);
}

const readTaskTypes = generateReadActionCreator(routes.API_TASK_TYPES, ACTION_NAMES.TASK_TYPES);
const addTaskType = generateCreateActionCreator(routes.API_TASK_TYPES, ACTION_NAMES.TASK_TYPES);
const deleteTaskType = generateDeleteActionCreator(routes.API_TASK_TYPES, ACTION_NAMES.TASK_TYPES, onDeleteSuccess, onDeleteFailure);

export default {
  readTaskTypes,
  addTaskType,
  deleteTaskType
};
