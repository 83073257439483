import ACTION_NAMES from 'actions/ACTION_NAMES';
import { STATUS_TYPES, Notification } from "dyl-components";
import { generateCreateActionCreator, generateReadOneActionCreator, generateDeleteActionCreator, generateReadActionCreator, generateUpdateActionCreator } from 'actions/crudActionCreators';
import routes from 'actions/routes';

const readLeadProviders = generateReadActionCreator(routes.API_LEAD_INGEST_LEAD_PROVIDER, ACTION_NAMES.LEAD_INGEST_LEAD_PROVIDER);
const addLeadProviders = generateCreateActionCreator(routes.API_LEAD_INGEST_LEAD_PROVIDER, ACTION_NAMES.LEAD_INGEST_LEAD_PROVIDER, () => {
    Notification.alert('Successfully added a lead provider!', STATUS_TYPES.SUCCESS, true);
}, () => {
    Notification.alert('Failed to add a lead provider', STATUS_TYPES.ERROR, true);
});
const updateLeadProvider = generateUpdateActionCreator(routes.API_LEAD_INGEST_LEAD_PROVIDER, ACTION_NAMES.LEAD_INGEST_LEAD_PROVIDER, () => {
    Notification.alert('Successfully updated a lead provider!', STATUS_TYPES.SUCCESS, true);
}, () => {
    Notification.alert('Failed to update a lead provider', STATUS_TYPES.ERROR, true);
});
const deleteLeadProvider = generateDeleteActionCreator(routes.API_LEAD_INGEST_LEAD_PROVIDER, ACTION_NAMES.LEAD_INGEST_LEAD_PROVIDER, () => {
    Notification.alert('Successfully deleted a lead provider!', STATUS_TYPES.SUCCESS, true);
}, () => {
    Notification.alert('Failed to delete a lead provider', STATUS_TYPES.ERROR, true);
});

const readLeadProviderMeta = generateReadActionCreator(routes.API_LEAD_INGEST_PROVIDER_META, ACTION_NAMES.LEAD_INGEST_PROVIDER_META);
const addLeadProviderMeta = generateCreateActionCreator(routes.API_LEAD_INGEST_PROVIDER_META, ACTION_NAMES.LEAD_INGEST_PROVIDER_META, () => {
    Notification.alert('Successfully added a lead provider meta!', STATUS_TYPES.SUCCESS, true);
}, () => {
    Notification.alert('Failed to add a lead provider meta', STATUS_TYPES.ERROR, true);
});
const updateLeadProviderMeta = generateUpdateActionCreator(routes.API_LEAD_INGEST_PROVIDER_META, ACTION_NAMES.LEAD_INGEST_PROVIDER_META, () => {
    Notification.alert('Successfully updated a lead provider meta!', STATUS_TYPES.SUCCESS, true);
}, () => {
    Notification.alert('Failed to update a lead provider meta', STATUS_TYPES.ERROR, true);
});
const deleteLeadProviderMeta = generateDeleteActionCreator(routes.API_LEAD_INGEST_PROVIDER_META, ACTION_NAMES.LEAD_INGEST_PROVIDER_META, () => {
    Notification.alert('Successfully deleted a lead provider meta!', STATUS_TYPES.SUCCESS, true);
}, () => {
    Notification.alert('Failed to delete a lead provider meta', STATUS_TYPES.ERROR, true);
});

const readMappings = generateReadActionCreator(routes.API_LEAD_INGEST_MAPPINGS, ACTION_NAMES.LEAD_INGEST_MAPPING);
const createMapping = generateCreateActionCreator(routes.API_LEAD_INGEST_MAPPINGS, ACTION_NAMES.LEAD_INGEST_MAPPING, () => {
    Notification.alert('Successfully added a mapping!', STATUS_TYPES.SUCCESS, true);
}, () => {
    Notification.alert('Failed to add a mapping', STATUS_TYPES.ERROR, true);
});
const deleteMapping = generateDeleteActionCreator(routes.API_LEAD_INGEST_MAPPING, ACTION_NAMES.LEAD_INGEST_MAPPING, () => {
    Notification.alert('Successfully deleted a mapping!', STATUS_TYPES.SUCCESS, true);
}, () => {
    Notification.alert('Failed to delete a mapping', STATUS_TYPES.ERROR, true);
});
const updateMapping = generateUpdateActionCreator(routes.API_LEAD_INGEST_MAPPING, ACTION_NAMES.LEAD_INGEST_MAPPING, () => {
    Notification.alert('Successfully updated a mapping!', STATUS_TYPES.SUCCESS, true);
}, () => {
    Notification.alert('Failed to update a mapping', STATUS_TYPES.ERROR, true);
});

const readMainLeadSources = generateReadActionCreator(routes.API_LEAD_INGESTION_MAIN_SOURCE_PROVIDER, ACTION_NAMES.LEAD_INGESTION_MAIN_SOURCE_PROVIDER);
const readProductInterests = generateReadActionCreator(routes.API_LEAD_INGESTION_PRODUCT_INTERESTS, ACTION_NAMES.LEAD_INGESTION_PRODUCT_INTERESTS);

const readAllLeadIngestion = generateReadActionCreator(routes.API_LEAD_INGESTION_ALL, ACTION_NAMES.LEAD_INGESTION_ALL);

const readLeadIngestion = generateReadOneActionCreator(routes.API_LEAD_INGESTION, ACTION_NAMES.LEAD_INGESTION);
const readLeadIngestionEmailParse = generateCreateActionCreator(routes.API_LEAD_INGESTION_EMAIL_PARSE, ACTION_NAMES.LEAD_INGESTION_EMAIL_PARSE);
const saveLeadIngestionParser = generateCreateActionCreator(routes.API_LEAD_INGESTION_EMAIL_SAVE_PARSER, ACTION_NAMES.LEAD_INGESTION_PARSER);
const updateLeadIngestionParser = generateUpdateActionCreator(routes.API_LEAD_INGESTION_PARSER, ACTION_NAMES.LEAD_INGESTION_EMAIL_SAVE_PARSER);
const readAllProviderRecordNames = generateReadActionCreator(routes.API_LEAD_INGESTION_PROVIDER_RECORD, ACTION_NAMES.LEAD_INGESTION_PROVIDER_RECORD);
const updateMappingFields = generateUpdateActionCreator(routes.API_LEAD_INGESTION_MAPPING_FIELDS, ACTION_NAMES.LEAD_INGESTION_MAPPING_FIELDS, () => {
    Notification.alert('Successfully updated a mapping!', STATUS_TYPES.SUCCESS, true);
}, () => {
    Notification.alert('Failed to update a mapping', STATUS_TYPES.ERROR, true);
});

const readCSVFile = generateReadOneActionCreator(routes.API_LEAD_INGESTION_CSV, ACTION_NAMES.IMPORT_CSV);

export default {
    readLeadProviders,
    addLeadProviders,
    deleteLeadProvider,
    updateLeadProvider,
    readLeadProviderMeta,
    addLeadProviderMeta,
    updateLeadProviderMeta,
    deleteLeadProviderMeta,
    readMappings,
    createMapping,
    deleteMapping,
    updateMapping,
    readMainLeadSources,
    readProductInterests,
    readLeadIngestion,
    readAllLeadIngestion,
    readLeadIngestionEmailParse,
    saveLeadIngestionParser,
    readAllProviderRecordNames,
    readCSVFile,
    updateMappingFields,
	updateLeadIngestionParser
}
