export const MONTHS = [
    {text: 'January', key: 'January'},
    {text: 'February', key: 'February'},
    {text: 'March', key: 'March'},
    {text: 'April', key: 'April'},
    {text: 'May', key: 'May'},
    {text: 'June', key: 'June'},
    {text: 'July', key: 'July'},
    {text: 'August', key: 'August'},
    {text: 'September', key: 'September'},
    {text: 'October', key: 'October'},
    {text: 'November', key: 'November'},
    {text: 'December', key: 'December'}
];
