import React from 'react';
import CredentialsAreaContainer from './CredentialsArea';
import { Loader, Grid, Transition } from 'semantic-ui-react';
import SearchArea from './PhoneNumberSearchArea';
import AddCustomer from './AddCustomer';

const TestFeatures = ({ onLogin, isLoggedIn }) => (
    <Grid>
        <Grid.Row>
            <Grid.Column>
            <CredentialsAreaContainer onLogin={onLogin} />
            </Grid.Column>
        </Grid.Row>
        <Transition visible={isLoggedIn} animation='slide down' duration='100' unmountOnHide>
            <Grid.Row>
                <Grid.Column>
                    <SearchArea />
                </Grid.Column>
            </Grid.Row>
        </Transition>
        <Transition visible={isLoggedIn} animation='slide down' duration='100' unmountOnHide>
            <Grid.Row>
                <Grid.Column>
                    <AddCustomer />
                </Grid.Column>
            </Grid.Row>
        </Transition>
    </Grid>
);

class TestFeaturesContainer extends React.Component {
    state = {
        isLoggedIn: false,
        isLoggingIn: false
    }

    onLogin = () => {
        this.setState({ isLogginIn: true }, () => {
            setTimeout(() => {
                this.setState({ isLoggedIn: true })
            }, 2);
        })
    }

    render() {
        if (this.state.isLoggingIn) {
            return (
                <Loader inverted active/>
            )
        }
        return (
            <TestFeatures 
                {...this.state}
                onLogin={this.onLogin}
            />
        )
    }
}

export default TestFeaturesContainer;
