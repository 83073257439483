import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function chatRoomsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CHAT_ROOMS, CRUD_ACTION_TYPES.READ_REQUEST):
            return {...state, isReadingChatRooms: true};
        case getNamedAction(ACTION_NAMES.CHAT_ROOMS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {...state, enabled: action.data.enabled, rooms: action.data.rooms, users: action.data.users, isReadingChatRooms: false};
        case getNamedAction(ACTION_NAMES.CHAT_ROOMS, CRUD_ACTION_TYPES.READ_FAILURE):
            return {...state, isReadingChatRooms: false};

        case getNamedAction(ACTION_NAMES.CHAT_ROOMS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreating: true };
        case getNamedAction(ACTION_NAMES.CHAT_ROOMS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
        case getNamedAction(ACTION_NAMES.CHAT_ROOMS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreating: false };

        default:
            return state;
    }
}

export default chatRoomsReducer;
