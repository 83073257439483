import React from 'react';
import { Form } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification, Person, STATUS_TYPES } from 'dyl-components';
import campaignsActions from 'actions/campaigns';
import campaignUsersActions from 'actions/campaign_users';
import { useParams } from 'react-router-dom';

const CampaignOwnerDropdown = () => {
    const { campaignUserIds, isReadingCampaignUserIds, owner, campaignBeingUpdated } = useSelector(state => ({
        campaignUserIds: state.campaigns.campaignUserIds,
        isReadingCampaignUserIds: state.campaigns.isReadingCampaignUserIds,
        owner: state.campaigns.campaign?.user_owner_id,
        campaignBeingUpdated: state.campaigns.campaignBeingUpdated
    }));

    const dispatch = useDispatch();
    const { id } = useParams();

    const onChangeOwner = async (e, { value }) => {
        try {
            await dispatch(campaignsActions.updateCampaign(id, { owner_id: value }));
            Notification.alert("Successfully changed owner of the campaign", STATUS_TYPES.SUCCESS);
            dispatch(campaignsActions.readCampaignUserIds(id));
            dispatch(campaignUsersActions.readCampaignUsers(id));
            dispatch(campaignsActions.readCampaign(id));
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to change owner of the campaign", STATUS_TYPES.ERROR);
        }
    }

    return (
        <Form>
            <Form.Dropdown
                selection
                options={campaignUserIds.filter(user => !user.deleted).map(user => ({
                    key: user.user_id,
                    value: user.user_id,
                    text: user.username,
                    content: (
                        <Person
                            username={user.username}
                            email={user.email}
                        />
                    )
                }))}
                onChange={onChangeOwner}
                loading={isReadingCampaignUserIds || campaignBeingUpdated}
                value={owner}
                label="Campaign Owner"
                selectOnBlur={false}
            />
        </Form>
    );
}

export default CampaignOwnerDropdown;
