import React from 'react';
import { TableWithHeader } from 'dyl-components';
import TagsTable from './TagsTable';
import TagsHeader from './TagsHeader';
import { Grid, Transition } from 'semantic-ui-react';
import AddTagSectionContainer from './AddTagSection';
import LeftPaddedContent from 'shared/LeftPaddedContent';

const Tags = ({ 
    callTags,  
    onDelete, 
    isAddTagSectionOpen,
    onOpenAddTagSection, 
    onCloseAddTagSection, 
    categoryOptions
}) => (
    <TableWithHeader 
        header={
            <TagsHeader 
                onOpenAddTagSection={onOpenAddTagSection}
            />
        }
        table={
            <Grid style={{margin: 0}}>
                <Transition visible={isAddTagSectionOpen} unmountOnHide duration='100' animation='slide down'>
                    <Grid.Row>
                        <Grid.Column>
                            <LeftPaddedContent>
                                <AddTagSectionContainer 
                                    onClose={onCloseAddTagSection}
                                    categoryOptions={categoryOptions}
                                />
                            </LeftPaddedContent>
                        </Grid.Column>
                    </Grid.Row>
                </Transition>
                <Grid.Row style={{padding: 0}}>
                    <Grid.Column style={{padding: 0}}>
                        <TagsTable 
                            callTags={callTags}
                            onDelete={onDelete}
                            categoryOptions={categoryOptions}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        }
    />
);

class TagsContainer extends React.Component {
    state = {
        isAddTagSectionOpen: false
    }

    onOpenAddTagSection = () => {
        this.setState({ isAddTagSectionOpen: true });
    }

    onCloseAddTagSection = () => {
        this.setState({ isAddTagSectionOpen: false });
    }

    render() {
        return (
            <Tags 
                {...this.state}
                {...this.props}
                isAddTagSectionOpen={this.state.isAddTagSectionOpen}
                onOpenAddTagSection={this.onOpenAddTagSection}
                onCloseAddTagSection={this.onCloseAddTagSection}
            />
        )
    }
}

export default TagsContainer;
