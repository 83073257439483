import routes from "actions/routes";
import ACTION_NAMES from "actions/ACTION_NAMES";
import {
    generateReadActionCreator,
    generateCreateActionCreator,
    generateReadOneActionCreator,
    generateUpdateActionCreator,
    generateDeleteActionCreator
} from "actions/crudActionCreators";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import axiosInstance from "actions/axiosInstance";

export default {
    readForms: generateReadActionCreator(routes.API_WEBFORMS, ACTION_NAMES.WEBFORMS),
    createForms: generateCreateActionCreator(routes.API_WEBFORMS, ACTION_NAMES.WEBFORMS),
    readForm: generateReadOneActionCreator(routes.API_WEBFORMS, ACTION_NAMES.WEBFORMS),
    updateForm: generateUpdateActionCreator(routes.API_WEBFORMS, ACTION_NAMES.WEBFORMS),
    deleteForm: generateDeleteActionCreator(routes.API_WEBFORMS, ACTION_NAMES.WEBFORMS),
    updateStatus: generateUpdateActionCreator(routes.API_WEBFORMS, ACTION_NAMES.WEBFORM_STATUS, () => {}, () => {}, 'active'),
    
    publishForm: (id, { name, form_html }) => async dispatch => {
        try {
            dispatch({
                type: getNamedAction(ACTION_NAMES.WEBFORM_PUBLISH, CRUD_ACTION_TYPES.CREATE_REQUEST),
                id
            });
            let headers = {
                "X-TOKEN": (await axiosInstance.instance1.get(`/v1/jwt`)).data.token,
                "Content-Type": "multipart/form-data"
            };
            const data = new FormData();
            data.append(
                "file",
                new Blob([form_html], { type: "text/html" }),
                `${name}.html`
            );
            const response = await axiosInstance.upload_instance.post('/v1/upload/html', data, {
                headers,
                params: {
                    custom_id: id,
                },
            });
            dispatch({
                type: getNamedAction(ACTION_NAMES.WEBFORM_PUBLISH, CRUD_ACTION_TYPES.CREATE_SUCCESS)
            });
            return Promise.resolve(response.data);
        } catch (error) {
            console.log(error);

            dispatch({
                type: getNamedAction(ACTION_NAMES.WEBFORM_PUBLISH, CRUD_ACTION_TYPES.CREATE_FAILURE)
            });

            return Promise.reject(error);
        }
    }
};
