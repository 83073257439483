import React from 'react';
import { Header } from 'semantic-ui-react';
import { DataLogUtils } from 'utils';

const ErrorPopupContainer = ({error_code}) => {
    const errorType = DataLogUtils.getErrorType(error_code);
    
    return (
        <div className='ErrorPopup'>
            <div className='Title'>
                <Header className='Title__Header' as='h5'>Error:</Header>
                <span className='Title__Content'>{errorType.name}</span>
            </div>
            <div className='Body'>
                <Header className='Body__Header' as='h5'>Description:</Header>
                <span className='Body__Content'>{errorType.description}</span>
            </div>
        </div>
    )
}

export default ErrorPopupContainer;