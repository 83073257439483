import React from 'react';
import { Table } from 'dyl-components';
import { EditableContent } from 'dyl-components';
import { Input, Select } from 'semantic-ui-react';
import { MOCK_USERS } from '__mocks__/mockData';
import { Link } from 'react-router-dom';
import MessagingIconButtons from 'shared/MessagingIconButtons';

const options = [
    { key: 'All Users', value: 'All Users', text: 'All Users'},
    ...MOCK_USERS.map(user => ({
        key: user.id,
        value: user.id,
        text: user.name
    }))
]

const VoicemailDropMessageRow = ({ isEditMode, message, index, onChange, onDelete, onUpdate, onEnableEditMode, onDisableEditMode }) => (
    <Table.Row active={isEditMode}>
        <Table.Cell>{index}</Table.Cell>
        <Table.Cell>
            <EditableContent 
                control={Input}
                name='name'
                value={message.name}
                onChange={onChange}
                isEditMode={isEditMode}
            />
        </Table.Cell>
        <Table.Cell>
            <Link to={`/settings/vm_drop?id=${message.id}&command=download`}>Download</Link>
        </Table.Cell>
        <Table.Cell>
            <EditableContent 
                control={Select}
                name='assigned'
                options={options}
                value={isEditMode ? message.assigned : options.filter(option => option.value === message.assigned)[0].text}
                onChange={onChange}
                isEditMode={isEditMode}
            />
        </Table.Cell>
        <Table.Cell>{message.lastUpdated}</Table.Cell>
        <Table.Cell>
           <MessagingIconButtons
                isEditMode={isEditMode}
                onDisableEditMode={onDisableEditMode} 
                onUpdate={onUpdate}
                onEnableEditMode={onEnableEditMode}
                onDelete={onDelete}
                id={message.id} 
            />
        </Table.Cell>
    </Table.Row>
)

class VoicemailDropMessageRowContainer extends React.Component {
    state = {
        message: this.props.message,
        isEditMode: false
    }

    onEnableEditMode = () => {
        this.setState({ isEditMode: true });
    }

    onDisableEditMode = () => {
        this.setState({ isEditMode: false, message: this.props.message });
    }

    onChange = (_, { name, value }) => {
        let message = { ...this.state.message, [name]: value};
        this.setState({ message });
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false
        }, () => {
            this.props.onUpdate(this.state.message);
        })
    }

    render() {
        return (
            <VoicemailDropMessageRow 
                {...this.props}
                {...this.state}
                onChange={this.onChange}
                onEnableEditMode={this.onEnableEditMode}
                onDisableEditMode={this.onDisableEditMode}
                onUpdate={this.onUpdate}
            />
        )
    }
}

export default VoicemailDropMessageRowContainer;
