import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function scheduleReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.SCHEDULE, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingSchedule: true };
        case getNamedAction(ACTION_NAMES.SCHEDULE, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingSchedule: false, days: action.data };
        case getNamedAction(ACTION_NAMES.SCHEDULE, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingSchedule: false };
            
        case getNamedAction(ACTION_NAMES.SCHEDULE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingSchedule: true };
        case getNamedAction(ACTION_NAMES.SCHEDULE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingSchedule: false };
        case getNamedAction(ACTION_NAMES.SCHEDULE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingSchedule: false };
        default:
            return state;
    }
}

export default scheduleReducer;
