import types from "actions/sms_list/types";
import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function smsListReducer(state = initialState, action){
    switch(action.type){
        case getNamedAction(ACTION_NAMES.SMS_LIST, CRUD_ACTION_TYPES.READ_REQUEST):
            return {...state, isGettingSmsList: true};
        case getNamedAction(ACTION_NAMES.SMS_LIST, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {...state, isGettingSmsList: false, smsList: action.data.sms_list, requiresConsent: action.data.requires_consent};
        case getNamedAction(ACTION_NAMES.SMS_LIST, CRUD_ACTION_TYPES.READ_FAILURE):
            return {...state, isGettingSmsList: false};

        case types.MARK_LEAD_IN_TEXT_LIST:
            return {...state, smsList: action.smsList, allMarked: action.allMarked}
        case types.MARK_ALL_LEADS_IN_TEXT_LIST:
            return {...state, smsList: action.smsList, allMarked: action.allMarked}

        case types.TEXT_LIST_LEAD_CONSENT:
            return {...state, smsList: action.smsList}
            
        default:
            return state;
    }
}


export default smsListReducer;
