import initialState from './initialState';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';

function teamDashboardReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.TEAM_CONVERSION_DATA, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingConversionData: true };
        case getNamedAction(ACTION_NAMES.TEAM_CONVERSION_DATA, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingConversionData: false, conversion_data: action.data || [], conversionQueryParameters: action.queryParameters };
        case getNamedAction(ACTION_NAMES.TEAM_CONVERSION_DATA, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingConversionData: false, conversion_data: [] };

        case getNamedAction(ACTION_NAMES.TEAM_FUNNEL_DATA, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingFunnelData: true };
        case getNamedAction(ACTION_NAMES.TEAM_FUNNEL_DATA, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingFunnelData: false, funnel_data: action.data || [], funnelQueryParameters: action.queryParameters };
        case getNamedAction(ACTION_NAMES.TEAM_FUNNEL_DATA, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingFunnelData: false, funnel_data: [] };

        default:
            return state;
    }
}

export default teamDashboardReducer;
