import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Notification, STATUS_TYPES } from 'dyl-components';
import { Grid, Loader, Segment, Icon } from 'semantic-ui-react';
import { ParseTemplateHeader } from './subcomponents/ParseTemplateHeader';
import { ParseTemplateStaticFooter } from './subcomponents/ParseTemplateStaticFooter';
import { ParseTemplateRightSide } from './subcomponents/ParseTemplateRightSide';
import { ParseTemplateLeftSide } from './subcomponents/ParseTemplateLeftSide';
import leadIngestionActions from 'actions/lead_ingestion';

import './index.scss';


export const ParseTemplateContainer = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inputtedValues, setinputtedValues] = useState({});
    const [parsePayload, setparsePayload] = useState({});
    const [readyForMapping, setreadyForMapping] = useState(false);
    const [readyToParse, setreadyToParse] = useState(false);
    const [fromSubjectValues, setfromSubjectValues] = useState({});
    const [emailPostInfo, setEmailPostInfo] = useState({});
    const [returnedValues, setreturnedValues] = useState([]);
    const [reset, setReset] = useState(false);
    const [anyReset, setAnyReset] = useState(false);

    const { email_id, post_id } = useParams();
    const { email = [], isReadingEmail, emailParseData } = useSelector(state => state.lead_ingestion);

    const isEmail = ((email_id && email?.length !== 0 && email.creation_type) === 'email');
    const emailParse = email?.email;
    const from = emailParse?.from;
    const subject = emailParse?.subject;
    const html = emailParse?.html;
    const parser = emailParse?.parser;
    const postParse = email?.post;
    const code = postParse?.code;
    const postPairs = emailPostInfo?.data || {};
    const { pairs, missing } = emailParseData;
	let parse_logic = !reset ? emailParseData.parse_logic : undefined; 
	if (!anyReset) {
		if (parse_logic && parser.parse_logic?.html_parser) {
			if (parse_logic.html_parser) {
				parse_logic.html_parser.pair_parsers.concat(parser.parse_logic.html_parser.pair_parsers)
			} else {
				parse_logic.html_parser = parser.parse_logic.html_parser
			}
		} else if (parser?.parse_logic) {
			parse_logic = parser?.parse_logic
		}
	}
    useEffect(() => {
        if(email_id){
            dispatch(leadIngestionActions.readLeadIngestion(email_id))
                .then((emailPost)=>{
					let mapped = (Object.keys(emailPost?.data || {})?.map(k => { return { "status": "good", key: k, value: emailPost.data[k] } } )) || [];
					setreturnedValues(mapped)
					setreadyForMapping(mapped.length >= 2 )
                    setEmailPostInfo(emailPost)
                });
		}
        if(post_id){
            dispatch(leadIngestionActions.readLeadIngestion(post_id))
                .then((emailPost)=>{
                    setEmailPostInfo(emailPost)
                });
        }
    }, [dispatch, email_id, post_id]);

    useEffect(() => {
        const isReadyToParse =  (inputtedValues.length > 0 ) ? inputtedValues.every(item => (!Object.values(item).includes('') && (!Object.keys(item).includes('')))) : false;
        setreadyToParse(isReadyToParse);
       
    }, [inputtedValues]);

    useEffect(() => {
        let results;
        const badValues = (missing) ? missing.map(miss => { miss.status = 'bad'; return miss }) : [];
        const goodValues = (pairs) ? pairs.map(pair => { pair.status = 'good'; return pair }) : [];
        if(isEmail){
            results = [...goodValues, ...badValues];
        }
        if(!isEmail){
            const postValues = Object.entries(postPairs).map(([k,v]) => ({status: 'good', key: k, value: v})) || [];
            results = [...postValues];
        }
        if(results.length > 0 && returnedValues.length === 0){ //will create infinite loop w/o check
            setreturnedValues(results);
        }
        setreadyForMapping(((goodValues.length > 2 ) && (badValues.length === 0)) || Object.keys(emailPostInfo?.data || {}).length > 2)
            
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pairs, missing, postPairs])

    /** Page functions */
    const isObjectEmpty = (obj) => {
        if (obj) {return Object.keys(obj).length === 0} ;
        return false;
    };


    const parseButtonCopy = (isObjectEmpty(emailParseData) || reset) ? 'Parse' : 'Parse Again';

    const onAddInputValues = (data) => {
        const { newInput } = data;

        // eslint-disable-next-line no-regex-spaces
        const cleanString = (str) => str.trim().replace((/  |\r\n|\n|\r/gm), "");

        const cleanInputs = newInput.map(pair => {
            const key = cleanString(pair.key)
            const value = cleanString(pair.value);
            return { key, value };
        })
        setinputtedValues(cleanInputs);
    }

    //Reset Button had to use refForward since useForm and it's method reset() was in a grandchild
    const dynamicFieldsRef = useRef(null);
    const resetDynForm = () => {
		setReset(true);
		setAnyReset(true);
        if (dynamicFieldsRef.current)
        {
            dynamicFieldsRef.current.resetForm();
        }

        setreturnedValues([]);
    };

    const onParseAttempt = async () => {
        const parsePayload = {
            "parse_pairs": inputtedValues,
			parse_logic,
            "use_html": !!html
        };
        const thePayload = {
            email_id,
            parsePayload
        }
		setparsePayload(thePayload);

        try
        {
            await dispatch(leadIngestionActions.readLeadIngestionEmailParse(thePayload));
			setReset(false);
            Notification.alert('Succesful Parse Attempt', STATUS_TYPES.SUCCESS, true);
        } catch {
            Notification.alert('There was a issue parsing this email', STATUS_TYPES.ERROR, true);
        }
    }

    const onChangeParsingFromSubject = (parsingfromSubject) => {
        setfromSubjectValues({...parsingfromSubject})
    }
    const saveParseGoToMapping = async () => {
        // const goodValues = returnedValues.filter((item)=> item.status === 'good'); This willl be used when the endpoint is updated
        const ignores = returnedValues.filter((item) => item.status !== 'good');
        const name = `${email_id}-${from}`;
        const subject = fromSubjectValues.subject === '' ? null : fromSubjectValues.subject;
        const payload = {
            email_id,
            match_from: fromSubjectValues.email,
            match_subject: subject,
            ignores,
            name,
            'parse_logic': {
                ...parse_logic,
            }
        }

        try
        {
			if (parser.id) {
				if (pairs?.length) {
					await dispatch(leadIngestionActions.updateLeadIngestionParser(parser.id, payload));
				}
			} else {
				await dispatch(leadIngestionActions.saveLeadIngestionParser(payload));
			}
            Notification.alert('Succesful Saved Parse', STATUS_TYPES.SUCCESS, true);
            navigate(`/settings/data-mapping/email/${email_id}`);
        } catch (e) {
			console.log(e);
            Notification.alert('There was a issue saving the parser', STATUS_TYPES.ERROR, true);
        }
    }

    const postGoToMapping = () => {
        navigate(`/settings/data-mapping/post/${post_id}`);
    }

    const toggleIcon = (id) => {
        const tempArray = returnedValues;
        const oldValue = tempArray[id].status;

        tempArray[id].status = (oldValue === 'delete') ? 'good' : 'delete';
        setreturnedValues([...tempArray])
    }

    const iconChoice = (id, value = 'unknown') => {
        const iconSettings = {
            'good': { 'name': 'fa-circle-check ValidBlue' },
            'bad': { 'name': 'fa-solid fa-triangle-exclamation WarningRed' },
            'unknown': { 'name': 'fa-solid fa-circle-question NeutralGray' },
            'delete': { 'name': 'fa-solid fa-circle-x NeutralGray' }
        }

        return <Icon className={ iconSettings[value].name } size='large' onClick={ () => { toggleIcon(id) } } />;
    };


    if (isReadingEmail)
    {
        return <Loader active content="Loading" />
    }
    return (
        <>
            { ((email.creation_type === 'email' || email.creation_type === 'post'))
                ? <div className='ParseTemplatePanel'>
                    <ParseTemplateHeader
                        data={ { email_id, from, subject, dynamicFieldsRef, onChangeParsingFromSubject, isEmail, post_id, code } }
						match_from={ parser?.match_from || "" }
						match_subject={ parser?.match_subject || "" }
                    />
                    <Grid>
                        <Grid.Row columns={ 2 } style={{paddingTop: 0}}>
                            <ParseTemplateLeftSide email={ isEmail ? emailParse : postParse } isEmail={isEmail} />
                            <ParseTemplateRightSide data={ { onAddInputValues, dynamicFieldsRef, parsePayload, iconChoice, returnedValues, isEmail } } />
                        </Grid.Row>
                    </Grid>
                    <ParseTemplateStaticFooter data={ { readyToParse, onParseAttempt, readyForMapping, parseButtonCopy, resetDynForm, saveParseGoToMapping, isEmail, postGoToMapping, returnedValues } } />
                </div>
                :
                <Segment>
                    <h3>Can't retrieve the content for {isEmail ? 'email' : 'post'} id {isEmail ? email_id : post_id}.</h3>
                </Segment> }
        </>
    )
}

