import React from 'react';
import { TableWithHeader, Notification, STATUS_TYPES } from 'dyl-components';
import LeadSourceHeader from './subcomponents/LeadSourceHeader';
import { Grid, Transition, Segment } from 'semantic-ui-react';
import AddLeadSourceSectionContainer from './subcomponents/AddLeadSourceSection';
import LeadSourcesTable from './subcomponents/LeadSourcesTable';
import { LeftPaddedColumn } from 'dyl-components';

const LeadSources = ({ 
    leadSources,
    isAddLeadSourceSectionOpen, 
    onOpenAddLeadSourceSection,
    onCloseAddLeadSourceSection,
    onAddLeadSource,
    onUpdateLeadSource,
    onDelete
}) => (
    <TableWithHeader 
        header={
            <LeadSourceHeader 
                onOpenAddLeadSourceSection={onOpenAddLeadSourceSection} 
            />
        }
        table={
            <React.Fragment>
                <Transition unmountOnHide visible={isAddLeadSourceSectionOpen} animation='slide down' duration='100'>
                    <Grid>
                        <Grid.Row style={{paddingBottom: 0}}>
                            <Grid.Column as={LeftPaddedColumn}>
                                <Segment basic>
                                    <AddLeadSourceSectionContainer 
                                        onAddLeadSource={onAddLeadSource}
                                        onClose={onCloseAddLeadSourceSection}
                                    />
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Transition>
                {leadSources.length <= 0 && (
                    <Segment textAlign='center'>
                        <em>There are no lead sources</em>
                    </Segment>
                )}
                {leadSources.length > 0 && (
                    <LeadSourcesTable 
                        leadSources={leadSources} 
                        onUpdate={onUpdateLeadSource}
                        onDelete={onDelete}
                    />
                )}
            </React.Fragment>
        }
    />
)

class LeadSourceContainer extends React.Component {
    state = {
        leadSources: [
            { id: 'sd0', name: 'Source', leadsDestination: 'doejxx05@dialyourleads.com', cost: 0 },
            { id: 'pzl4', name: 'ProspectZone', leadsDestination: 'lucktx17@dialyourleads.com', cost: 4 },
            { id: 'dl260', name: 'Direct', leadsDestination: 'lucktx26@dialyourleads.com', cost: 0 },
            { id: 'dl290', name: 'Direct', leadsDestination: 'lucktx29@dialyourleads.com', cost: 0 },
            { id: 'fsl30', name: 'Formstack', leadsDestination: 'lucktx30@dialyourleads.com', cost: 0 },
            { id: 'uia0', name: 'User Input', leadsDestination: 'Automatic', cost: 0 }
        ],
        isAddLeadSourceSectionOpen: false
    }

    onOpenAddLeadSourceSection = () => {
        this.setState({ isAddLeadSourceSectionOpen: true });
    }

    onCloseAddLeadSourceSection = () => {
        this.setState({ isAddLeadSourceSectionOpen: false });
    }

    onUpdateLeadSource = (updatedLeadSource) => {
        let leadSources = this.state.leadSources.slice(0);
        let indexOfLeadSourceToUpdate = leadSources.findIndex(leadSource => leadSource.id === updatedLeadSource.id);
        if (indexOfLeadSourceToUpdate !== -1) {
            leadSources[indexOfLeadSourceToUpdate] = {...updatedLeadSource};
            this.setState({ leadSources });
        }
    }

    onDelete = (id) => {
        let leadSources = this.state.leadSources.slice(0);
        let indexOfLeadSourceToDelete = leadSources.findIndex(leadSource => leadSource.id === id);
        if (indexOfLeadSourceToDelete !== -1) {
            leadSources.splice(indexOfLeadSourceToDelete, 1);
            this.setState({ leadSources }, () => {
                Notification.alert('Successfully deleted lead source!', STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    onAddLeadSource = (newLeadSource) => {
        let leadSources = this.state.leadSources.slice(0);
        leadSources.push(newLeadSource);
        this.setState({ leadSources }, () => {
            Notification.alert('Successfully added lead source!', STATUS_TYPES.SUCCESS, true);
        });
    }

    render() {
        return (
            <LeadSources 
                {...this.state}
                onUpdateLeadSource={this.onUpdateLeadSource}
                onOpenAddLeadSourceSection={this.onOpenAddLeadSourceSection}
                onCloseAddLeadSourceSection={this.onCloseAddLeadSourceSection}
                onAddLeadSource={this.onAddLeadSource}
                onDelete={this.onDelete}
            />
        )
    }
}

export default LeadSourceContainer;
