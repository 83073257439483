import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, STATUS_TYPES, SearchBar } from 'dyl-components';

import './PermissionsHeader.scss'

export const PermissionsHeader = ({ isReadingRoles }) => {
    const navigate = useNavigate();
    const [ query ] = useSearchParams();
    const search = query.get('search') || '';

    const searchFunction = (value)=> navigate(`/settings/permission-profile?search=${value}`);
    const cancelFunction = ()=> navigate(`/settings/permission-profile`);

    return (
        <Grid className='Permissions__New'>
            <Grid.Row verticalAlign='middle'>
                <Grid.Column width='6' floated='left'>
                    <SearchBar
                        isSearching={ isReadingRoles }
                        search={ search }
                        searchFcn={ searchFunction }
                        cancelFcn={ cancelFunction }
                        placeholder={ 'Search for a Permission Role'}
                    />
                </Grid.Column>
                <Grid.Column width={6} floated='right' textAlign='right'>
                    <Link to={'/settings/add-permission-profile'}>
                        <Button status={STATUS_TYPES.PRIMARY}>
                            + New Permission Profile
                        </Button>
                    </Link>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}