import React from 'react';

import { ItemCard, Timeline, CollapsibleContent, DateTimeUtils } from 'dyl-components';

import { connect } from 'react-redux';
import {  StringUtils } from 'utils';

import './index.scss';

const TasksTimeline = ({ tasks, isReadingTasks, groupedTasks }) => (
    tasks.length <= 0 && !isReadingTasks ? (
        <i>There are no tasks</i>
    ) : groupedTasks.map(({ name, dateTimes }) => (
        dateTimes.length > 0 && (
            <React.Fragment>
                <div className='Timeline__group'>
                    <strong>{name}</strong>
                </div>
                <div className='Timeline__items'>
                    <Timeline
                        items={dateTimes.map((task) => ({
                            date: <div className='TimelineItem__date'>
                                <span className='TimelineItem__day'>
                                    {DateTimeUtils.formatEpoch(task.ts, "ddd").toUpperCase()}
                                </span> <span className='TimelineItem__day-of-month'>
                                    {DateTimeUtils.formatEpoch(task.ts, "M[/]D")}
                                </span>
                            </div>,
                            time: <span className='TimelineItem__time'>
                                {DateTimeUtils.formatEpoch(task.ts)}
                            </span>,
                            itemComponent: <ItemCard
                                title={<div><span className='TaskItem__name'>{StringUtils.capitalize(task.name)}</span></div>}
                                description={<div className='TaskItem__note'>
                                    <CollapsibleContent
                                        content={task.note || ''}
                                    />
                                </div>}
                                size='mini'
                            />,
                            markerColor: '#e5e5e5'
                        }))}
                    />
                </div>
            </React.Fragment>
        )
    ))
)

const mapStateToProps = state => {
 
    const currentTimeStamp = (new Date().getTime()/1000);
    const tasks = state.tasks.tasks
                    .filter(task => (task.ts > currentTimeStamp && !task.complete ))
                    .sort((a,b) => a.ts - b.ts)
                    .slice(0,5);
    return ({
        tasks,
        groupedTasks: DateTimeUtils.groupDates(tasks),
        isReadingTasks: state.tasks.isReadingTasks
    });
};

export default connect(mapStateToProps)(TasksTimeline)
