import React from 'react';
import { Modal, Notification, STATUS_TYPES } from 'dyl-components';
import { Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import userActions from 'actions/user';

import ChangePasswordForm from 'shared/forms/ChangePasswordForm';


const ChangePasswordModal = ({ open, onUpdate, onClose, onValueChange, old, new_password, confirm_password, arePasswordsValid, isUpdating, your_password, isOldPasswordValid }) => (
    <Modal open={open} onClose={onClose} size='tiny'>
        <Modal.Header>
            { your_password ? 'Reset Password' : 'Change Password' }
        </Modal.Header>
        <Modal.Content>
            <ChangePasswordForm
                onValueChange={onValueChange}
                old={old}
                new_password={new_password}
                confirm_password={confirm_password}
                your_password={your_password}
                isOldPasswordValid={isOldPasswordValid}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button basic onClick={onClose}>Cancel</Button>
            <Button loading={isUpdating} disabled={!arePasswordsValid} primary onClick={onUpdate}>Update</Button>
        </Modal.Actions>
    </Modal>
)

class ChangePasswordModalContainer extends React.Component {
    state = {
        old: '',
        new_password: '',
        confirm_password: '',
        isOldPasswordValid: {error: false, message:null}
    }

    onValueChange = (_, { name, value }) => {
        this.setState({ [name]: value });
        this.arePasswordsValid();
 
        if(name === 'old'){ // reset check of the network response to a valid password for the user making the change when the user retypes their password
            this.setState({isOldPasswordValid: {error: false, message:null}})
        }
    }

    arePasswordsValid = () => {
        const { old, new_password, confirm_password, isOldPasswordValid} = this.state;
        const allFieldsFilled = old.trim() !== '' 
            && new_password.trim() !== ''
            && confirm_password.trim() !== ''
            && new_password === confirm_password;

        //Adding regex for password here ... can remove it and use the dyl-component Validators when the form is refactored to NOT use this modal and the button is included in the form;
        const isPasswordValid = /^(?=.{6,})(?=.*[A-Za-z])(?=.*[0-9])(?=.*\W).+/.test(new_password.trim());
        const validAdminpassword = (!isOldPasswordValid.error);  // disables Update button is the network response returns an incorrect password msg

        return (allFieldsFilled && validAdminpassword && isPasswordValid);
    }

    onResetPassword = async () => {

        let payload = {
            new_password: this.state.new_password,
            target_user_id: this.props.selected_user_id,
            old: this.state.old,
        }
 
        try {
 
            await this.props.onUpdateUserPassword(payload);
            Notification.alert("Successfully reset password!", STATUS_TYPES.SUCCESS, true);
            this.onClose();
        } catch (e) {

            const message = (e.message.includes('401')) ? `Your password is incorrect` : `There was an issue authenticating your password`;

            this.setState({
                isOldPasswordValid: {error: true, message}
            })

            Notification.alert("Failed to reset password.", STATUS_TYPES.ERROR, true);
        }
    }
    
    onClose = () => {
        this.setState({
            old: '',
            new_password: '',
            confirm_password: '',
            isOldPasswordValid: {error: false, message:''}
        });
        this.props.onClose();
    }

    render() {

        return (
            <ChangePasswordModal
                arePasswordsValid={this.arePasswordsValid()}
                confirm_password={this.state.confirm_password}
                old={this.state.old}
                isOldPasswordValid={this.state.isOldPasswordValid}
                new_password={this.state.new_password}
                onValueChange={this.onValueChange}
                onClose={this.onClose}
                onUpdate={this.onResetPassword}
                open={this.props.open}
                isUpdating={this.props.isUpdating}
                your_password={(this.props.selected_user_id) && parseInt(this.props.selected_user_id) !== parseInt(this.props.user_id)}
            />
        )
    }
}

const mapStateToProps = (state, { user_id }) => ({
    isUpdating: state.user.userPasswordBeingUpdated === user_id,
});

const mapDispatchToProps = (dispatch, { user_id }) => ({
    onUpdateUserPassword: (payload) => {
        return dispatch(userActions.updatePassword(user_id, payload));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModalContainer);
