import StringUtils from "./StringUtils";
import React from 'react';
import { Grid } from "semantic-ui-react";
import DynamicField from "shared/forms/DynamicField";
import { STATUS_TYPES, Notification, yup, VALIDATORS } from "dyl-components";
import { FIELD_TYPE } from "shared/CustomData/DynamicField";

export const DYNAMIC_FIELD_VALUE_VALIDATION_SCHEMA = {
    field_type: yup.mixed(),
    value: yup.mixed().when("field_type", {
        is: FIELD_TYPE.PHONE,
        then: () => VALIDATORS.PHONE_NUMBER()
    }).when("field_type", {
        is: FIELD_TYPE.EMAIL,
        then: () => VALIDATORS.EMAIL_ADDRESS()
    }).when("field_type", {
        is: FIELD_TYPE.URL,
        then: () => VALIDATORS.WEBSITE()
    }).when("field_type", {
        is: FIELD_TYPE.TEXT,
        then: () => VALIDATORS.SIMPLE_INPUT()
    }).when("field_type", {
        is: FIELD_TYPE.TEXTAREA,
        then: () => VALIDATORS.DESCRIPTION()
    }).when("field_type", {
        is: FIELD_TYPE.NUMBER,
        then: () => yup.lazy(value => value === '' ? yup.string() : yup.number())
    }).when("field_type", {
        is: FIELD_TYPE.CURRENCY,
        then: () => yup.lazy(value => value === '' ? yup.string() : yup.number())
    }).when("field_type", {
        is: FIELD_TYPE.ADDRESS,
        then: schema => schema.object().shape({
            street: yup.string().maxlength(100),
            additional_street: yup.string().maxlength(12),
            city: yup.string().maxlength(60),
            state: yup.string(),
            zip: VALIDATORS.US_POSTAL_CODE()
        })
    })
}

export default class CustomGroupUtils {
    static groupAndFlatten = (custom_data) => {
        const children = custom_data.children;
        const fields = custom_data.fields;
        const fields_to_display = {}
        if (fields?.length) {
            fields.forEach(field => {
                fields_to_display[field.field_name] = field.value || field.default_value || "";
            })
        }
        const grouped_children = children ? children.reduce((result, group) => {
            const array = (result[group.group_name] = result[group.group_name] || []);
            const fields = {}
            if (group.fields) {
                group.fields.forEach(field => {
                    fields[field.field_name] = field.value || field.default_value || ""
                })
            }
            array.push({
                ...CustomGroupUtils.groupAndFlatten(group),
                fields
            })
            return result;
        }, {}) : null;
        return {
            children: grouped_children,
            fields: fields_to_display
        }
    }

    static onChangeCustomData = (groupToModify, name, value) => {
        const group = groupToModify.slice(0);
        const [root, ...parentHeirarchy] = name.split('|');
        let toModify = group;
        let nameOfFieldToModify = "";
        if (root === 'fields') {
            nameOfFieldToModify = parentHeirarchy[0];
        } else {
            for (let i = 0; i < parentHeirarchy.length; i++) {
                const parentName = parentHeirarchy[i];
                if (parentName === "children") {
                    toModify = toModify.children;
                } else if (parentName === "fields") {
                    toModify = toModify.fields.data ? toModify.fields.data : toModify.fields;
                    nameOfFieldToModify = parentHeirarchy[i + 1];
                    break;
                } else {
                    toModify = toModify.find(({ id }) => `${id}` === `${parentName}`);
                }
            }
        }
        const fieldToModify = toModify.find(({ field_name }) => field_name === nameOfFieldToModify);
        if (fieldToModify) {
            fieldToModify.value = value;
        }
        return group;
    }

    static onAddInstance = (group_name, groupToModify, originalGroup, groupIndex, parent) => {
        const [, ...parentHeirarchy] = parent.split('|');
        const custom_groups = groupToModify.slice(0);
        let toAddTo = custom_groups;
        let groupToAdd = JSON.parse(JSON.stringify(originalGroup.slice(0)));
        for (let i = 0; i < parentHeirarchy.length; i++) {
            const parentName = parentHeirarchy[i];
            if (parentName === 'children') {
                toAddTo = toAddTo.children;
                groupToAdd = groupToAdd.children;
            } else {
                toAddTo = toAddTo.find(({ id }) => `${id}` === parentName);
                // eslint-disable-next-line no-loop-func
                groupToAdd = groupToAdd.find(({ group_name }) => group_name === toAddTo.group_name);
            }
        }
        groupToAdd = groupToAdd.find(inGroup => inGroup.group_name === group_name);
        const fields = groupToAdd.fields ?
            Array.isArray(groupToAdd.fields) ?
                groupToAdd.fields.map(field => ({ ...field, value: '' })) :
                { data: groupToAdd.fields.data.map(field => ({ ...field, value: '' })) }
            : Array.isArray(groupToAdd.fields) ? [] : { data: [] }
        toAddTo.splice(groupIndex + 1, 0, {
            ...groupToAdd,
            id: StringUtils.generateRandomId(4),
            children: groupToAdd.children ? [...groupToAdd.children] : null,
            fields,
            removable: true
        })
        return custom_groups;
    }

    static onRemoveInstance = (group_id, groupToModify, groupIndex, parent) => {
        const [, ...parentHeirarchy] = parent.split('|');
        const custom_groups = groupToModify.slice(0);
        let toRemoveFrom = custom_groups;
        for (let i = 0; i < parentHeirarchy.length; i++) {
            const parentName = parentHeirarchy[i];
            if (parentName === 'children') {
                toRemoveFrom = toRemoveFrom.children;
            } else {
                toRemoveFrom = toRemoveFrom.find(({ id }) => `${id}` === parentName);
            }
        }
        const indexOfItemToRemove = toRemoveFrom.findIndex(({ id }) => `${id}` === `${group_id}`);
        if (indexOfItemToRemove !== -1) {
            toRemoveFrom.splice(indexOfItemToRemove, 1);
        }
        return custom_groups;
    }

    static renderField = (parent, group, onChange) => (field) => {
        const field_name = `${parent}|${group.id}|fields|${field.field_name}`;
        const field_value = field.value;
        return (
            <Grid.Column style={{ paddingBottom: "1em", paddingLeft: "2.25em", paddingRight: "2.25em" }} key={field_name}>
                <DynamicField
                    name={field_name}
                    onChange={onChange}
                    value={field_value}
                    options={field.options.data.map(({ value }) => ({ key: value, value: value, text: value }))}
                    type={field.field_type}
                    label={field.label}
                    styled
                    required={field.required}
                    size=''
                />
            </Grid.Column>
        );
    }

    static inlineUpdateContactCustomData = (custom_data, onUpdateContact, contact_id, contact_data, account_id, onUpdateContactCustomGroup) => ({ name, value }) => {
        const children = CustomGroupUtils.onChangeCustomData(custom_data.children, name, value);
        return onUpdateContact(contact_id, {
            ...contact_data,
            custom_data: CustomGroupUtils.groupAndFlatten({ ...custom_data, children })
        }, account_id).then(() => {

            Notification.alert('Successfully updated contact', STATUS_TYPES.SUCCESS, true);
            return onUpdateContactCustomGroup(name, value);
        });
    }

    static extractPersonFields = data => {
        const {
            school,
            degree,
            description
        } = data;
        return {
            school,
            degree,
            description
        }
    }

    static extractBusinessAndHouseholdDetails = data => {
        const {
            business_name,
            industry_sector,
            sub_industry,
            employee_size,
            annual_revenue,
            product_services_sold,
            department
        } = data

        const {
            household_name,
            household_type,
            household_members,
            number_of_children,
            annual_household_income,
        } = data;

        const payload = {
            business_details: [
                {
                    fields: {
                        company_name: business_name,
                        industry_sector: [industry_sector, sub_industry].filter(part => part).join(','),
                        employee_count: employee_size,
                        annual_revenue,
                        product_services_sold,
                        department
                    },
                    children: {}
                }
            ],
            household_details: [
                {
                    fields: {
                        household_name,
                        household_type,
                        household_members_count: household_members,
                        number_of_children,
                        annual_income: annual_household_income
                    },
                    children: {}
                }
            ]
        };

        return payload
    }

    static extractLeadDetails = data => {
        const {
            type,
            expected_revenue,
            close_probability,
            priority,
            rating,
            score,
            acquisition_cost,
            additional_cost
        } = data;

        return {
            type,
            expected_revenue,
            close_probability,
            priority,
            rating,
            score,
            acquisition_cost,
            additional_cost
        }
    }

    static extractOpportunityDetails = data => {
        const {
            type,
            description,
            pain_need_notes,
            sales_strategy_notes,
            confidence_level,
            close_probability,
            forecast_amount,
            expected_close_date,
            next_step_date,
            next_step
        } = data;

        return {
            type,
            description,
            pain_need_notes,
            sales_strategy_notes,
            confidence_level,
            close_probability,
            forecast_amount,
            expected_close_date,
            next_step_date,
            next_step
        }
    }

    static getFieldOptions = (fields, field_name) => {
        const options = fields.find(field => field.field_name === field_name)?.options;
        return (options?.data || options)?.map(option => ({
            key: option.id,
            value: option.id,
            text: option.value,
            sub_options: (option?.sub_options || []).map(sub_option => ({
                key: sub_option.id,
                value: sub_option.id,
                text: sub_option.value
            }))
        })) || []
    }

    static extractHouseholdDetails = data => {
        const {
            account_household_type,
            members,
            do_not_call,
            text_opt_out,
            email_opt_out,
            number_of_children,
            annual_income,
            monthly_income
        } = data;
        return {
            account_household_type,
            members,
            do_not_call,
            text_opt_out,
            email_opt_out,
            children: number_of_children,
            annual_income,
            monthly_income
        }
    }

    static extractBusinessDetails = data => {
        const {
            industry_sector,
            sub_industry,
            business_structure,
            business_method,
            legal_business_name,
            dba_business_name,
            business_nickname,
            do_not_call,
            fax_opt_out,
            email_opt_out,
            website,
            state_of_organization,
            business_in_other_states,
            date_founded,
            state_employer_account_number,
            federal_employer_identification_number,
            sic,
            primary_sic,
            naics_code,
            stock_ticker,
            duns,
            employee_size,
            out_of_state_employees,
            professional_corporation,
            accounting_fiscal_year,
            annual_revenue,
            sale_volume,
            product_services_sold
        } = data;
        return {
            industry_sector: [industry_sector, sub_industry].filter(part => part).join(','),
            business_structure,
            business_method,
            legal_business_name,
            dba_business_name,
            business_nickname,
            do_not_call,
            fax_opt_out,
            email_opt_out,
            website,
            state_of_organization,
            business_in_other_states,
            date_founded,
            state_employer_account_number,
            federal_employer_identification_number,
            sic,
            primary_sic,
            naics_code,
            stock_ticker,
            duns,
            employee_size,
            out_of_state_employees,
            professional_corporation,
            accounting_fiscal_year,
            annual_revenue,
            sale_volume,
            product_services_sold
        }
    }

    static extractCustomerDetails = (data) => {
        const { customer_type } = data;
        return {
            customer_type
        }
    }

    static generateValidationSchema = (custom_groups) => {
        if (custom_groups === null || custom_groups === undefined || custom_groups.length <= 0) {
            return yup.array().of(yup.object().shape({}))
        }
        const fieldsSchema = yup.array().of(yup.object().shape(DYNAMIC_FIELD_VALUE_VALIDATION_SCHEMA));
        return yup.array().of(yup.object().shape({
            fields: fieldsSchema,
            children: yup.array().of(yup.object().shape({
                fields: fieldsSchema,
                children: yup.array()
            }))
        }));
    }
    
    static formatFields = (groups) => {
        if (!groups?.length) {
            return [];
        }
        return groups.map(group => ({
            ...group,
            fields: group.fields?.data?.map(field => ({
                ...field,
                value: ''
            })) || [],
            children: CustomGroupUtils.formatFields(group.children)
        }))
    }
}
