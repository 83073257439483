import React, { useState } from 'react';
import { Form, Transition, Segment, Grid, Button, Icon } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import { useDispatch } from 'react-redux';
import { SearchBar, Person, ClippedContent } from 'dyl-components';

import usersActions from 'actions/users';

const SelectedUserRender = ({ user_id, username, email, onRemoveUser }) => {
    return (
        <Segment className='SelectedUserSegment'>
            <Grid container>
                <Grid.Column width={4}>
                    <Avatar name={username} maxInitials={2} round size='3em' />
                </Grid.Column>
                <Grid.Column width={8}>
                    <div><span className="SelectedUserSegment__name"><b>{username}</b></span></div>
                    <div><span className="SelectedUserSegment__email"><b>{email}</b></span></div>
                </Grid.Column>
                <Grid.Column width={2} floated='right' verticalAlign='middle'>
                    <Icon
                        name='delete'
                        link
                        onClick={(e) => { onRemoveUser(e, user_id) }}
                    />
                </Grid.Column>
            </Grid>
        </Segment>
    );
}

const UserTableDropdown = ({ value, excludedUserIds, onChange, errorMessage }) => {
    const dispatch = useDispatch();

    const [state, setState] = useState({
        user_options: [],
        count: 0,
        search: '',
        open: false,
    });
    const [loading, setLoading] = useState(false);

    const onChangeSearch = (_, { value }) => {
        setState({ ...state, search: value });
    }

    const cancelFunction = () => {
        setState({ ...state, user_options: [], count: 0, open: false, search: '' });
    }

    const searchFunction = async () => {
        try {
            setLoading(true);
            const { data, count } = await dispatch(usersActions.searchUsers({ search: state.search }));
            setLoading(false);
            setState({ ...state, user_options: data, count, open: true, page: 1, search: state.search });
        } catch (e) {
            setLoading(false);
        }
    }

    const onSelectUser = (_, user) => {
        const { user_id, first_name, last_name, email } = user;
        onChange(_, [...value, { user_id, first_name, last_name, email }]);
    }

    const onRemoveUser = (_, user_id) => {
        onChange(_, value.filter(selectedUser => selectedUser.user_id !== user_id));
    }

    return (
        <div className='UserTableDropdown'>
            <Segment.Group className='SearchBarSegmentGroup'>
                <Segment className='SearchBarSegment'>
                    <Transition visible={value.length > 0}>
                        <Grid className='SearchBarSegment__SelectedUsersGrid' container stackable>
                            {value.map(selectedUser => {
                                const username = selectedUser?.first_name || selectedUser?.last_name ? `${selectedUser.first_name || ''} ${selectedUser.last_name || ''}` : "Unknown Name";
                                return (
                                    <Grid.Column width={5}>
                                        <SelectedUserRender
                                            user_id={selectedUser.user_id}
                                            username={username}
                                            email={selectedUser.email}
                                            onRemoveUser={onRemoveUser}
                                        />
                                    </Grid.Column>
                                )
                            })}
                        </Grid>
                    </Transition>
                    <Form.Field
                        control={SearchBar}
                        addClassNames='SearchBarSegment__SearchInput'
                        isSearching={loading}
                        search={state.search}
                        searchFcn={searchFunction}
                        cancelFcn={cancelFunction}
                        placeholder='Search and add users by user name or team name'
                        onChange={onChangeSearch}
                    />
                </Segment>
                <Transition visible={state.open}>
                    <Segment className='ResultsSegment'>
                        <Grid className='ResultsGrid' container>
                            <Grid.Row className='ResultsGrid__HeaderRow'>
                                <Grid.Column width={7}>
                                    User Name
                                </Grid.Column>
                                <Grid.Column width={7}>
                                    Team Name
                                </Grid.Column>
                                <Grid.Column width={2}>
                                </Grid.Column>
                            </Grid.Row>
                            {(state.user_options.map(user_option => {
                                const username = user_option?.first_name || user_option?.last_name ? `${user_option.first_name || ''} ${user_option.last_name || ''}` : "Unknown Name";
                                return (
                                    <Grid.Row>
                                        <Grid.Column width={7}>
                                            <Person
                                                username={username}
                                                email={user_option.email}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={7} verticalAlign='middle'>
                                            <ClippedContent>
                                                {(user_option?.teams || []).map(team => team.name).join(', ')}
                                            </ClippedContent>
                                        </Grid.Column>
                                        <Grid.Column width={2} verticalAlign='middle'>
                                            <Button primary
                                                disabled={[...new Set([...value, ...excludedUserIds])].find(selectedUser => selectedUser.user_id === user_option.user_id)}
                                                onClick={_ => { onSelectUser(_, user_option) }}
                                            >
                                                Add
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            }))}
                        </Grid>
                    </Segment>
                </Transition>
            </Segment.Group>
            {errorMessage && <span className='UserTableDropdown__ErrorMessage'>{errorMessage}</span>}
        </div>
    );
}

export default UserTableDropdown;
