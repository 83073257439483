import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function notificationsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.NOTIFICATION_CENTER, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingNotificationCenter: true };
        case getNamedAction(ACTION_NAMES.NOTIFICATION_CENTER, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingNotificationCenter: false, centerNotifications: action.data.notifications || [], enableBell: action.data.enable_bell || [] };
        case getNamedAction(ACTION_NAMES.NOTIFICATION_CENTER, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingNotificationCenter: false };

        case getNamedAction(ACTION_NAMES.NOTIFICATION_HUB, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingNotificationHub: true };
        case getNamedAction(ACTION_NAMES.NOTIFICATION_HUB, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingNotificationHub: false, hubNotifications: action.data.notifications, count: action.data.count };
        case getNamedAction(ACTION_NAMES.NOTIFICATION_HUB, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingNotificationHub: false };

        case getNamedAction(ACTION_NAMES.NOTIFICATION_ARCHIVE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingNotificationHub: true };
        case getNamedAction(ACTION_NAMES.NOTIFICATION_ARCHIVE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingNotificationHub: false};
        case getNamedAction(ACTION_NAMES.NOTIFICATION_ARCHIVE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingNotificationHub: false };

        case getNamedAction(ACTION_NAMES.NOTIFICATION_MARK_READ, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingNotification: true };
        case getNamedAction(ACTION_NAMES.NOTIFICATION_MARK_READ, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingNotification: false};
        case getNamedAction(ACTION_NAMES.NOTIFICATION_MARK_READ, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingNotification: false };

        case getNamedAction(ACTION_NAMES.NOTIFICATION_SNOOZED, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingNotification: true };
        case getNamedAction(ACTION_NAMES.NOTIFICATION_SNOOZED, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingNotification: false};
        case getNamedAction(ACTION_NAMES.NOTIFICATION_SNOOZED, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingNotification: false };

        case getNamedAction(ACTION_NAMES.NOTIFICATION_CLEAR, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isClearingNotification: true };
        case getNamedAction(ACTION_NAMES.NOTIFICATION_CLEAR, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isClearingNotification: false};
        case getNamedAction(ACTION_NAMES.NOTIFICATION_CLEAR, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isClearingNotification: false };
        
        case getNamedAction(ACTION_NAMES.NOTIFICATION_DELETE, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingNotification: true };
        case getNamedAction(ACTION_NAMES.NOTIFICATION_DELETE, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingNotification: false};
        case getNamedAction(ACTION_NAMES.NOTIFICATION_DELETE, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingNotification: false };

        case getNamedAction(ACTION_NAMES.NOTIFICATION_SETTINGS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingNotificationSettings: true };
        case getNamedAction(ACTION_NAMES.NOTIFICATION_SETTINGS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingNotificationSettings: false, notificationSettings: action.data };
        case getNamedAction(ACTION_NAMES.NOTIFICATION_SETTINGS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingNotificationSettings: false };

        case getNamedAction(ACTION_NAMES.UPDATE_NOTIFICATION_SETTINGS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingNotificationSettings: true };
        case getNamedAction(ACTION_NAMES.UPDATE_NOTIFICATION_SETTINGS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingNotificationSettings: false, notificationSettings: action.data };
        case getNamedAction(ACTION_NAMES.UPDATE_NOTIFICATION_SETTINGS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingNotificationSettings: false };

        case getNamedAction(ACTION_NAMES.NOTIFICATION_BULK_ACTION, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isReadingNotificationBulkAction: true };
        case getNamedAction(ACTION_NAMES.NOTIFICATION_BULK_ACTION, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isReadingNotificationBulkAction: false, hubNotifications: action.data.notifications, count: action.data.count };
        case getNamedAction(ACTION_NAMES.NOTIFICATION_BULK_ACTION, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isReadingNotificationBulkAction: false };

        case 'HAS_READ_NOTIFICATIONS':
            return { ...state, hasNotifications: false };

        case 'HAS_UNREAD_NOTIFICATIONS':
            return { ...state, hasNotifications: true };

        default:
            return state;
    }
}

export default notificationsReducer;
