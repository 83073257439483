import React, { useEffect, useState } from 'react';
import { WentWrong } from 'pages';
import { useLocation } from 'react-router-dom';

const withErrorBoundary = (Component) => {
    const Wrapper = (props) => {
        const [error, setError] = useState(null);
        const { pathname } = useLocation();

        useEffect(() => {
            if (error) {
                setError(null);
            }
        }, [pathname, error]);

        try {
            return <Component {...props} />
        } catch (e) {
            setError(e);
            return <WentWrong />;
        }
    }

    return Wrapper;
}

export default withErrorBoundary;
