import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { ClippedContent, DateTimeUtils, Person, Table } from "dyl-components";
import { StringUtils } from "utils";

const Row = ({ sequenceTask }) => {
    const {
        ts: created,
        user,
        workflow,
        status,
        action,
        contact,
    } = sequenceTask;
    return (
        <Table.Row>
            <Table.Cell>
                <div>
                    <b>
                        {DateTimeUtils.formatEpoch(
                            created,
                            DateTimeUtils.WORD_DATE_FORMAT
                        )}
                    </b>
                </div>
                <div>
                    {DateTimeUtils.formatEpoch(
                        created,
                        DateTimeUtils.TIME_FORMAT
                    )}
                </div>
            </Table.Cell>
            <Table.Cell>
                <Person
                    username={`${user.first_name} ${user.last_name}`}
                    isUsernameOnly
                />
            </Table.Cell>
            <Table.Cell className={`SequenceReports__StatusColumn--${status}`}>
                <b>{StringUtils.capitalize(status)}</b>
            </Table.Cell>
            <Table.Cell>
                <div className="SequenceReports__NameColumn">
                    {!workflow.deleted ? (
                        <Link to={`/settings/sequences/${workflow.id}`}>
                            <ClippedContent>{workflow.name}</ClippedContent>
                        </Link>
                    ) : (
                        workflow.name
                    )}
                </div>
            </Table.Cell>
            <Table.Cell>{action}</Table.Cell>
            <Table.Cell>
                <Person
                    username={`${contact.first_name} ${contact.last_name}`}
                    isUsernameOnly
                />
            </Table.Cell>
            <Table.Cell>
                {contact.account_type &&
                    (contact.account_type === "business" ? (
                        <Icon className="fa-solid fa-building" />
                    ) : (
                        <Icon className="fa-solid fa-house" />
                    ))}
                <Link to={`/account/${contact.account_id}`}>
                    {contact.account_name}
                </Link>
            </Table.Cell>
        </Table.Row>
    );
};

export default Row;
