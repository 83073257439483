import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function accountReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.TERRITORY, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingTerritory: true };
        case getNamedAction(ACTION_NAMES.TERRITORY, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingTerritory: false };
        case getNamedAction(ACTION_NAMES.TERRITORY, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingTerritory: false };

        case getNamedAction(ACTION_NAMES.TERRITORY, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingTerritory: true };
        case getNamedAction(ACTION_NAMES.TERRITORY, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingTerritory: false };
        case getNamedAction(ACTION_NAMES.TERRITORY, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingTerritory: false };

        case getNamedAction(ACTION_NAMES.TERRITORY, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingTerritory: true };
        case getNamedAction(ACTION_NAMES.TERRITORY, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingTerritory: false };
        case getNamedAction(ACTION_NAMES.TERRITORY, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingTerritory: false };

        case getNamedAction(ACTION_NAMES.TERRITORY, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingTerritories: true, territories: [], count: 0 };
        case getNamedAction(ACTION_NAMES.TERRITORY, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingTerritories: false, territories: action.data.data, count: action.data.count };
        case getNamedAction(ACTION_NAMES.TERRITORY, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingTerritories: false, territories: [], count: 0 };

        case getNamedAction(ACTION_NAMES.TERRITORY_DUPLICATE_CHECK, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isCheckingDuplicate: true, isDuplicate: false };
        case getNamedAction(ACTION_NAMES.TERRITORY_DUPLICATE_CHECK, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isCheckingDuplicate: false, isDuplicate: action.data };
        case getNamedAction(ACTION_NAMES.TERRITORY_DUPLICATE_CHECK, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isCheckingDuplicate: false, isDuplicate: false };

        case getNamedAction(ACTION_NAMES.TERRITORY_VALIDITY_CHECK, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCheckingValidity: true, validationResults: null };
        case getNamedAction(ACTION_NAMES.TERRITORY_VALIDITY_CHECK, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCheckingValidity: false, validationResults: action.data };
        case getNamedAction(ACTION_NAMES.TERRITORY_VALIDITY_CHECK, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCheckingValidity: false, validationResults: null };

        case getNamedAction(ACTION_NAMES.TERRITORY, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingTerritory: true, territory: null };
        case getNamedAction(ACTION_NAMES.TERRITORY, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingTerritory: false, territory: action.data };
        case getNamedAction(ACTION_NAMES.TERRITORY, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingTerritory: false, territory: null };

        case getNamedAction(ACTION_NAMES.TERRITORY_COUNTIES, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isReadingCounties: true };
        case getNamedAction(ACTION_NAMES.TERRITORY_COUNTIES, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isReadingCounties: false, counties: action.data };
        case getNamedAction(ACTION_NAMES.TERRITORY_COUNTIES, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isReadingCounties: false };

        default:
            return state;
    }
}


export default accountReducer;
