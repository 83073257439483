import customFieldsGroupActions from 'actions/custom_fields_group';
import customFieldsGroupsActions from 'actions/custom_fields_groups';
import { generateResolver, Notification, STATUS_TYPES, VALIDATORS } from 'dyl-components';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form } from 'semantic-ui-react';

const CustomFieldGroupForm = ({ group, parent_group, refresh, onModify, onClose, isSubgroup }) => {
    const { formState: { isValid, isDirty }, control, handleSubmit } = useForm({
        defaultValues: {
            name: group?.label || '',
            description: group?.description || '',
            allow_multiples: group?.multiple || false
        },
        mode: 'onChange',
        resolver: generateResolver({
            name: VALIDATORS.SIMPLE_INPUT().no_whitespace_only().required('This field is required'),
            description: VALIDATORS.DESCRIPTION()
        })
    });

    useEffect(() => {
        if (onModify) {
            onModify(isDirty);
        }
    }, [isDirty, onModify]);

    const dispatch = useDispatch();

    const onCreate = async (data) => {
        const { name, description, allow_multiples } = data;
        try {
            await dispatch(customFieldsGroupsActions.addCustomFieldGroup({
                label: name,
                multiple: allow_multiples,
                parent_group_id: parent_group,
                description
            }));
            Notification.alert(`Successfully created custom ${isSubgroup ? 'sub' : ''}group!`, STATUS_TYPES.SUCCESS);
            onClose();
            if (refresh) {
                refresh();
            }
        } catch (e) {
            console.log(e);
            Notification.alert(`Failed to create custom ${isSubgroup ? 'sub' : ''}group`, STATUS_TYPES.ERROR);
        }
    }

    const onUpdate = async (data) => {
        const { name, description, allow_multiples } = data;
        try {
            await dispatch(customFieldsGroupActions.updateCustomFieldGroup(group.id, {
                name,
                multiple: allow_multiples,
                description
            }));
            Notification.alert(`Successfully updated custom ${isSubgroup ? 'sub' : ''}group!`, STATUS_TYPES.SUCCESS);
            onClose();
            if (refresh) {
                refresh();
            }
        } catch (e) {
            console.log(e);
            Notification.alert(`Failed to update custom ${isSubgroup ? 'sub' : ''}group`, STATUS_TYPES.ERROR);
        }
    }

    const isSaving = useSelector(state => {
        if (group) {
            return state.custom_fields_group.groupBeingUpdated;
        }
        return state.custom_fields_groups.isAddingCustomGroup;
    });

    return (
        <Form noValidate loading={isSaving} style={{ width: "30em" }}>
            <Form.Group widths='equal'>
                <Controller
                    name='name'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            error={error?.message}
                            label={`Custom ${isSubgroup ? 'Subgroup' : 'Group'} Name`}
                            placeholder={`Enter ${isSubgroup ? 'subgroup' : 'group'} name`}
                            required
                        />
                    )}
                />
                {!isSubgroup && (
                    <Controller
                        name='allow_multiples'
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                            <Form.Checkbox
                                name={name}
                                checked={value}
                                onChange={(_, { checked: value }) => { onChange({ target: { name, value } }) }}
                                label='Allow multiples'
                                style={{ position: 'absolute', top: '35px' }}
                            />
                        )}
                    />
                )}
            </Form.Group>
            <Controller
                control={control}
                name='description'
                render={({ field: { name, value, onChange } }) => (
                    <Form.Input
                        name={name}
                        value={value}
                        onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                        label='Description'
                        placeholder={`Enter ${isSubgroup ? 'subgroup' : 'group'} name description`}
                    />
                )}
            />
            <Button floated='right' disabled={!isDirty || !isValid || isSaving} primary onClick={handleSubmit(group ? onUpdate : onCreate)} loading={isSaving}>Save</Button>
        </Form>
    )
}

export default CustomFieldGroupForm;
