import { DateTimeUtils } from "dyl-components";
import { StringUtils } from 'utils';

const { CALENDAR_API_DATE_FORMAT, WEEKDAY_DATE_FORMAT, TIME_FORMAT } = DateTimeUtils;

export const formatEvents = events => {
    // add hour and date
    const items = events.map(function (item) {
        const allDay = item.all_day === true;
        const date = allDay? DateTimeUtils.changeFormat(item.start?.date, CALENDAR_API_DATE_FORMAT, WEEKDAY_DATE_FORMAT) : DateTimeUtils.formatEpoch(item.start?.date_time, WEEKDAY_DATE_FORMAT);
        const hour = allDay? "12:00 am" : DateTimeUtils.formatEpoch(item?.start?.date_time, TIME_FORMAT);
        return {
            id: item.id,
            activity_type: "Event",
            action: "Event",
            allDay,
            status: "",
            name: item.name,
            label: item.event_type?.name || "unlabeled",
            hour,
            date,
            source: item.source?.charAt(0).toUpperCase() + item.source?.slice(1).toLowerCase() || "System",
            contacts: item.attendees?.filter(attendee => attendee.contact_id) || []  // get contacts
        };
    });
    return items;
};

export const formatEventsCalendar = events => {
    return events.map((item) => {
        const { id, name, start, end, all_day } = item
        if (all_day) {
            return {
                id,
                allDay: all_day,
                title: name,
                date: start.date,
                extendedProps: {
                    activity_type: "event"
                }
            };
        }
        const startDate = DateTimeUtils.convertUnixTimeToDate(start.date_time).toDate();
        const endDate = DateTimeUtils.convertUnixTimeToDate(end.date_time).toDate();
        return {
            id,
            title: name,
            start: startDate,
            end: endDate,
            extendedProps: {
                activity_type: "event"
            }
        };
    });
};

export const formatTasksCalendar = tasks => {
    return tasks.map((item) => {
        const { id, ts, name, task_type: { name: task_type } } = item
        const startDate = DateTimeUtils.convertUnixTimeToDate(ts).toDate();
        return {
            id,
            task_type,
            title: name,
            start: startDate,
            extendedProps: {
                activity_type: "task"
            }
        };
    });
};

export const formatSequenceTasks = (sqcs) => {
    return sqcs.map(function (item) {
        return {
            id: item.id,
            activity_type: "SequenceTask",
            action: item.action,
            source: 'System',
            name: item.workflow.name,
            status: StringUtils.capitalize(item.status),
            allDay: false,
            title: item.workflow.name,
            date: DateTimeUtils.formatEpoch(item.ts, WEEKDAY_DATE_FORMAT),
            hour: DateTimeUtils.formatEpoch(item.ts, TIME_FORMAT),
            start: DateTimeUtils.convertUnixTimeToDate(item.ts).toDate(),
            contacts: item.contact? [item.contact] : [],
            label: `${item.action} ${item.index}`,
            extendedProps: {
                activity_type: 'sequence'
            }
        }
    });
}

export const formatTasks = (tasks) => {
    const items = tasks.map(function (item) {
        const date = DateTimeUtils.formatEpoch(item.ts, WEEKDAY_DATE_FORMAT);
        const hour = DateTimeUtils.formatEpoch(item.ts, TIME_FORMAT);
        return {
            id: item.id,
            activity_type: "Task",
            action: item.task_type?.name,
            extendedProps: {
                activity_type: "task"
            },
            contacts: [item.contact],
            status: StringUtils.capitalize(item.status),
            title: item.name,
            name: item.name,
            notes: item.note,
            label: item.task_label?.name || "unlabeled",
            hour,
            date,
            source: "System"
        }
    });
    return items
}
