import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Grid, Header, Segment } from 'semantic-ui-react';

import recentsActions from 'actions/recents';
import { DateTimeUtils } from 'dyl-components';
import { Link } from 'react-router-dom';
import { FAVORITE_TYPE } from 'shared/constants/FAVORITE_TYPE';
import { StringUtils } from 'utils';

const getDayTimestampsParameter = (userAuthTimezone) => {
    const { start, end } = DateTimeUtils.getDateRange('today', DateTimeUtils.getTimezoneCurrentDay(userAuthTimezone), DateTimeUtils.DATE_FORMAT, 'day', true, userAuthTimezone);
    return `start=${start}&end=${end}`;
}

const getDisplayName = (recent) => {
    if (recent.page_type !== FAVORITE_TYPE.REPORT && recent.page_type !== FAVORITE_TYPE.SETTING) {
        return recent.display_name;
    }
    if (recent.display_name === "product catalog quotes invoices") {
        return "Product Catalog, Quotes & Invoices";
    }
    return StringUtils.toSentenceCase(recent.display_name)
}

const getUrl = ({ external_id, page_type, setting_report_type }, timezone) => {
    switch (page_type) {
        case FAVORITE_TYPE.CONTACT:
            return `/contact/${external_id}`;
        case FAVORITE_TYPE.MASTER_ACCOUNT:
            return `/master_account/${external_id}`;
        case FAVORITE_TYPE.ACCOUNT:
            return `/account/${external_id}`;
        case FAVORITE_TYPE.CAMPAIGN:
            return `/campaigns/${external_id}/dashboard`;
        case FAVORITE_TYPE.GROUP:
            return `/group/${external_id !== 0 ? external_id : "hotlist"}`;
        case FAVORITE_TYPE.SETTING:
        case FAVORITE_TYPE.REPORT:
            {
                const root = setting_report_type.split(".")[1].split("_").join("-");
                const subRoute = (() => {
                    if (root === "data-customization") {
                        return "/contact";
                    }
                    if (root === "library") {
                        return "/text-templates"
                    }
                    if (root === "pipeline") {
                        return "/lead-pipelines"
                    }
                    if (root === "sequence-report") {
                        const queryParameters = getDayTimestampsParameter(timezone);
                        return `${queryParameters ? `s?${queryParameters}` : "s"}`;
                    }
                    return "";
                })();
                const correctedRoot = (() => {
                    switch (root) {
                        case "data-integration-log": return "data-integration-logs";
                        case "chat-settings": return "chat";
                        case "pipeline": return "pipelines";
                        case "permissions": return "permission-profile";
                        case "ring-groups-and-queues": return "ring-groups-management";
                        default: return root;
                    }
                })();
                return `/settings/${correctedRoot}${subRoute}`;
            }
        default:
            return '/';
    }
}

const Recents = () => {
    const { recents, loading } = useSelector(state => ({
        recents: state.recents.recents.filter(recent => recent.page_type && (
            (recent.page_type !== FAVORITE_TYPE.SETTING && recent.page_type !== FAVORITE_TYPE.REPORT && !isNaN(recent.external_id)) || recent.setting_report_type
        )).slice(0),
        loading: state.recents.isReadingRecents
    }));

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(recentsActions.readRecents())
    }, [dispatch]);

    const userAuthTimezone = useSelector((state) => state.auth.timezone);

    return (
        <Segment loading={loading} basic style={{ padding: 0 }}>
            <Header color="primary">Recently Viewed</Header>
            <Divider />
            <Grid>
                {recents.map(recent => (
                    <Grid.Row columns={"equal"}>
                        <Grid.Column width={6} textAlign='left'>
                            <div><b>{DateTimeUtils.formatEpoch(recent.ts, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                            <div>{DateTimeUtils.formatEpoch(recent.ts, DateTimeUtils.TIME_FORMAT)}</div>
                        </Grid.Column>
                        <Grid.Column>
                            <div>
                                <Link target='_blank' to={getUrl(recent, userAuthTimezone)}>
                                    {getDisplayName(recent)}
                                </Link>
                            </div>
                            <div>
                                {StringUtils.toSentenceCase(recent.page_type !== "master account" ? recent.page_type : "master accounts")}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                ))}
            </Grid>
        </Segment>
    )
}

export default Recents;
