const initialState = {
    count: 0,
    quotes: [],
    queryParameters: {
        page: 1,
        offset: 0
    },

    isReading: false,
    isCreating: false,
    quoteBeingUpdated: false,
    quoteBeingDeleted: false,
    productQuote: {
        "account_id": 0,
        "activity": 0,
        "created": 0,
        "created_user_id": 0,
        "delivered": true,
        "discount": {
            "discount": 0,
            "discount_type": "percent",
            "id": 0
        },
        "expires": 0,
        "items": [],
        "name": "string",
        "note": "string",
        "opportunity_id": 0,
        "shipping": {
            "address": {
                "additional_street": "string",
                "city": "string",
                "id": 0,
                "state": "string",
                "street": "string",
                "zip": "string"
            },
            "cost": 0,
            "discount": {
                "discount": 0,
                "discount_type": "percent",
                "id": 0
            },
            "id": 0,
            "to": "string"
        },
        "status": "string",
        "tax": 0
    },
    isOrdering: false,
    quoteShippingDetailsBeingUpdated: null
}

export default initialState;
