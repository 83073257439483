import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function eventReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.EVENT, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingEvent: true }
        case getNamedAction(ACTION_NAMES.EVENT, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingEvent: false }
        case getNamedAction(ACTION_NAMES.EVENT, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingEvent: false }
        case getNamedAction(ACTION_NAMES.EVENT, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingEvent: true }
        case getNamedAction(ACTION_NAMES.EVENT, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingEvent: false }
        case getNamedAction(ACTION_NAMES.EVENT, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingEvent: false }

        case getNamedAction(ACTION_NAMES.EVENT, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingEvent: true }
        case getNamedAction(ACTION_NAMES.EVENT, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingEvent: false }
        case getNamedAction(ACTION_NAMES.EVENT, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingEvent: false }

        case getNamedAction(ACTION_NAMES.EVENT_LABEL, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingEventLabel: true }
        case getNamedAction(ACTION_NAMES.EVENT_LABEL, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingEventLabel: false }
        case getNamedAction(ACTION_NAMES.EVENT_LABEL, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingEventLabel: false }

        case getNamedAction(ACTION_NAMES.EVENT_LABEL, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingEventLabel: true }
        case getNamedAction(ACTION_NAMES.EVENT_LABEL, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingEventLabel: false }
        case getNamedAction(ACTION_NAMES.EVENT_LABEL, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingEventLabel: false }

        case getNamedAction(ACTION_NAMES.CHECK_EVENT_LABEL_DUPLICATE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isCheckingEventLabelDuplicate: true }
        case getNamedAction(ACTION_NAMES.CHECK_EVENT_LABEL_DUPLICATE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isCheckingEventLabelDuplicate: false }
        case getNamedAction(ACTION_NAMES.CHECK_EVENT_LABEL_DUPLICATE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isCheckingEventLabelDuplicate: false }

        default:
            return state;
    }
}

export default eventReducer;
