import React from 'react';

import { Modal, STATUS_TYPES, Notification } from 'dyl-components';
import { Grid } from 'semantic-ui-react';
import RingGroupEditForm from 'shared/forms/RingGroupEditForm';
import RingGroupMembers from './RingGroupMembers';
import ringGroupActions from 'actions/ring_groups';
import { connect } from 'react-redux';
import callQueueActions from 'actions/call_queues';

const RingGroupEditModalContainer = ({ open, onClose, onUpdate, onChange, group, isUpdating, isRingGroup }) => (
    <Modal animated={false} open={open} onClose={onClose} size='large'>
        <Modal.Header>
            Edit Prompt Options
        </Modal.Header>
        <Modal.Content scrolling>
            <Grid columns='equal'>
                <Grid.Column>
                    <RingGroupEditForm 
                        onChange={onChange}
                        group={group}
                        isRingGroup={isRingGroup}
                    />
                    <RingGroupMembers isRingGroup={isRingGroup} ring_group={group.id} />
                </Grid.Column>
            </Grid>
        </Modal.Content>
        <Modal.Actions
            hasSaveButton
            onSave={onUpdate}
            saveOptions={{loading: isUpdating}}
        />
    </Modal>
)

class RingGroupEditModal extends React.Component {
    state = {
        group: this.props.group
    }

    componentDidMount() {
        this.setState({ group: this.props.group });
    }

    onChange = (_, { name, value }) => {
        const group = { ...this.state.group, [name]: value };
        this.setState({ group });
    }

    onUpdate = () => {
        const { label, ring_strategy, ring_time, group_number, ...otherProps } = this.state.group;
        this.props.onUpdateGroup(this.props.domain, this.props.group.id, { ...otherProps, announce_mode: "none", group_number: Number(group_number), label, ring_strategy, ring_time: Number(ring_time) }).then(() => {
            Notification.alert(`Successfully updated ${this.props.isRingGroup ? 'ring group' : 'call queue'}!`, STATUS_TYPES.SUCCESS, true);
            this.props.onClose();
            this.props.onReadGroups(this.props.domain);
        }).catch(() => {
            Notification.alert(`Failed to update ${this.props.isRingGroup ? 'ring group' : 'call queue'}`, STATUS_TYPES.ERROR, true);
        });
    }

    render() {
        return (
            <RingGroupEditModalContainer
                onClose={this.props.onClose}
                onUpdate={this.onUpdate}
                open={this.props.open}              
                onChange={this.onChange}
                group={this.state.group}
                isUpdating={this.props.groupBeingUpdated === this.props.group.id}
                isRingGroup={this.props.isRingGroup}
            />
        )
    }
}

const mapStateToProps = (state, { isRingGroup }) => ({
    groupBeingUpdated: state[isRingGroup ? 'ring_group' : 'call_queue'].groupBeingUpdated,
    domain: state.pbx_config.domain
})

const mapDispatchToProps = (dispatch, { isRingGroup }) => ({
    onUpdateGroup: (domain, group_id, payload) => {
        if (isRingGroup) {
            return dispatch(ringGroupActions.updateRingGroup(domain, payload, null, group_id));
        }
        return dispatch(callQueueActions.updateCallQueue(domain, payload, null, group_id))
    },
    onReadGroups: (domain) => {
        if (isRingGroup) {
            return dispatch(ringGroupActions.readRingGroups(domain));
        }
        return dispatch(callQueueActions.readCallQueues(domain));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(RingGroupEditModal);
