import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Timeline, ItemCard, DateTimeUtils } from 'dyl-components';
import { connect } from 'react-redux';
import {  StringUtils } from 'utils';

const ICONS = {
    text: 'conversation',
    task: 'clipboard',
    note: 'sticky note',
    email: 'mail',
    call: 'phone',
    pipeline: 'code branch'
}

const HistoryTimeline = ({ history, isReadingHistory }) => (
    history.length <= 0 && !isReadingHistory ? (
        <i>There are no entries</i>
    ) : <Timeline
            items={history.map((item) => ({
                date: DateTimeUtils.formatEpoch(item.ts, "ddd M[/]D").toUpperCase(),
                time: DateTimeUtils.formatEpoch(item.ts),
                itemComponent: <ItemCard
                    meta={(
                        <span>
                            <Icon circular name={ICONS[item.type]} /> {StringUtils.capitalize(item.type)}
                            {item.name && `: ${StringUtils.toSentenceCase(item.name)}`}
                            {item.type === 'pipeline' && ` (${item.user})`}
                        </span>
                    )}
                    title={item.type === 'email' ? item.subject || '[No subject]' : (item.direction || '')}
                    description={
                        item.body || item.message || item.note
                    }
                    size='mini'
                />,
                markerColor: '#ffca83'
            }))}
        />
);

const mapStateToProps = (state, { type }) => ({
    history: state.contact[`${type}_history`].slice(0, 5),
    isReadingHistory: state.contact[`isReading${StringUtils.capitalize(type)}History`],
    contact_id: state.contact.contact_id,
    account_id: state.contact.account_id || state.account.account_id
});

export default connect(mapStateToProps)(HistoryTimeline);
