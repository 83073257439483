import React from 'react';
import { FilterComponent, FileInput, Button, STATUS_TYPES } from 'dyl-components';
import { Form, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';

import './TextMessageBox.scss';

const TextMessageBox = ({ onSend, templates, onChange, selectedTemplate, body, attachedFile, numberOfLeads, isGettingSmsList }) => {
    if (isGettingSmsList) {
        return (
            <FilterComponent>
                <Dimmer active>
                    <Loader active />
                </Dimmer>
            </FilterComponent>
        )
    }
    if (numberOfLeads < 0) {
        return null;
    }
    return (
        <FilterComponent>
            <Form onSubmit={onSend}>
                <Form.Select 
                    placeholder='Select Template'
                    options={templates}
                    onChange={onChange}
                    name='template'
                    value={selectedTemplate}
                    width={8}
                />
                <Form.TextArea 
                    value={body}
                    onChange={onChange}
                    name='body'
                />
                <FileInput
                    onChange={onChange} 
                    name='attachment'
                    value={attachedFile}
                />
                <div className="TextMessageBox">
                <Form.Button 
                    as={Button}
                    status={STATUS_TYPES.PRIMARY}
                    type='submit'
                    primary
                    content='Send'
                    floated='right'
                />
                </div>
            </Form>
        </FilterComponent>
    )
}

const mapStateToProps = state => {
    return {
        numberOfLeads: state.smsList.smsList.length,
        isGettingSmsList: state.smsList.isGettingSmsList
    }
}

export default connect(mapStateToProps, null)(TextMessageBox);
