export const MOCK_RING_GROUP_OPTIONS = [
    { id: 'rfi', name: 'Ring First-IVR' },
    { id: 'sc1', name: 'Sales-Call Queue' },
    { id: 'csr', name: 'Customer Service' },
    { id: 'cqra', name: 'Call Queue Ring All' },
    { id: 'csls', name: 'Commercial Sales' }
].map(route => ({ key: route.id, value: route.id, text: route.name }));

export const MOCK_ROUTE_OPTIONS = [
    { id: 'vct', name: 'Vacation' },
    { id: 'nh', name: 'Normal Hours' },
    { id: 'cd', name: 'Company Directory' },
    { id: 'clms', name: 'Claims' },
    { id: 'sc', name: 'Service Center' }
].map(route => ({ key: route.id, value: route.id, text: route.name }));

export const MOCK_USERS = [
    { id: 'tl', name: 'Tom Luck', assignedCampaign: '', isLeadsRealTime: true },
    { id: 'js', name: 'James Sales', assignedCampaign: 'march 29 test', isLeadsRealTime: false },
    { id: 'hb', name: 'Howard Bling', assignedCampaign: 'march 28 test', isLeadsRealTime: true },
    { id: 'jse', name: 'James Service', assignedCampaign: 'march 27 test', isLeadsRealTime: true },
    { id: 'sc', name: 'Sarah Closer', assignedCampaign: 'march 26 test', isLeadsRealTime: true },
    { id: 'pt', name: 'Preston Truck', assignedCampaign: 'march 25 test', isLeadsRealTime: true },
    { id: 'tt', name: 'Tes Tes', assignedCampaign: 'march 24 test', isLeadsRealTime: false }
]

export const MOCK_LEAD_ROUTE = [
    {
        id: 1,
        source: 'Agent Form',
        type: 'Any',
        distributionType: 'Lead Connect',
        routeDestination: 'All Users',
        distributionMethod: 'Round Robin',
        workflow: 'N/A'
    },
    {
        id: 2,
        source: 'Call',
        type: 'Any',
        distributionType: 'Lead Connect',
        routeDestination: 'All Users',
        distributionMethod: 'Round Robin',
        workflow: 'N/A'
    },
    {
        id: 3,
        source: 'Default Routing',
        type: '',
        distributionType: 'Auto Assign',
        routeDestination: 'All Users',
        distributionMethod: 'Round Robin',
        workflow: 'Lead Connect'
    }
];

export const MOCK_LOCATION_DESTINATION = [
    {
        id: 1,
        name: 'ZIP',
        location: 'Default Zip',
        userDestination: 'All Users',
    },
    {
        id: 2,
        name: '',
        location: '90503',
        userDestination: 'Service',
    },
];

export const MOCK_LEAD_CONNECT_CALLBACKS = [
    {
        id: 1,
        callIn: 'Minute',
        howMany: '1',
        atTime: 'N/A',
    },
    {
        id: 2,
        callIn: 'Day',
        howMany: '1',
        atTime: '8:00 am',
    },
    {
        id: 3,
        callIn: 'Minutes',
        howMany: '5',
        atTime: 'N/A',
    },
    {
        id: 4,
        callIn: 'Day',
        howMany: '1',
        atTime: '10:00 am',
    },
];

export const MOCK_VOICEMAILS = [
    {
        lead_id: 1, id: 1, ts: '2/21/19 7:57 PM', mailbox: 101, listened: false,
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210'
    },
    {
        lead_id: 2, id: 2, ts: '2/21/19 7:57 PM', mailbox: 102, listened: false,
        first_name: 'Test', last_name: 'Lead', phone: '(614) 333-2210'
    },
    {
        lead_id: 3, id: 3, ts: '2/21/19 7:57 PM', mailbox: 103, listened: false,
        first_name: 'Chris', last_name: 'Jones', phone: '(614) 333-2210'
    },
    {
        lead_id: 4, id: 4, ts: '2/21/19 7:57 PM', mailbox: 104, listened: false,
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210'
    },
    {
        lead_id: 5, id: 5, ts: '2/21/19 7:57 PM', mailbox: 105, listened: false,
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210'
    },
    {
        lead_id: 6, id: 6, ts: '2/21/19 7:57 PM', mailbox: 106, listened: false,
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210'
    },
    {
        lead_id: 7, id: 7, ts: '2/21/19 7:57 PM', mailbox: 107, listened: false,
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210'
    },
    {
        lead_id: 8, id: 8, ts: '2/21/19 7:57 PM', mailbox: 108, listened: false,
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210'
    }
]

export const MOCK_FAXES = [
    {
        lead_id: 1, id: 1, ts: '2/21/19 7:57 PM', numberOfPages: 2,
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210'
    },
    {
        lead_id: 2, id: 2, ts: '2/21/19 7:57 PM', numberOfPages: 2,
        first_name: 'Test', last_name: 'Lead', phone: '(614) 333-2210'
    },
    {
        lead_id: 3, id: 3, ts: '2/21/19 7:57 PM', numberOfPages: 3,
        first_name: 'Chris', last_name: 'Jones', phone: '(614) 333-2210'
    },
    {
        lead_id: 4, id: 4, ts: '2/21/19 7:57 PM', numberOfPages: 2,
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210'
    },
    {
        lead_id: 5, id: 5, ts: '2/21/19 7:57 PM', numberOfPages: 4,
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210'
    },
    {
        lead_id: 6, id: 6, ts: '2/21/19 7:57 PM', numberOfPages: 2,
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210'
    },
    {
        lead_id: 7, id: 7, ts: '2/21/19 7:57 PM', numberOfPages: 3,
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210'
    },
    {
        lead_id: 8, id: 8, ts: '2/21/19 7:57 PM', numberOfPages: 2,
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210'
    }
]

export const MOCK_CALLS = [
    {
        lead_id: 1, ts: '2/21/19 7:57 PM', result: 'Reached', user: '',
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210', duration: '0:00',
        latest_note: ''
    },
    {
        lead_id: 2, ts: '2/21/19 7:57 PM', result: 'Reached', user: '',
        first_name: 'Test', last_name: 'Lead', phone: '(614) 333-2210', duration: '0:02',
        latestNote: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation'
    },
    {
        lead_id: 3, ts: '2/21/19 7:57 PM', result: 'Reached', user: '',
        first_name: 'Chris', last_name: 'Jones', phone: '(614) 333-2210', duration: '1:35',
        latest_note: ''
    },
    {
        lead_id: 4, ts: '2/21/19 7:57 PM', result: 'Reached', user: 'Michelle Orihuela',
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210', duration: '0:00',
        latest_note: ''
    },
    {
        lead_id: 5, ts: '2/21/19 7:57 PM', result: 'Reached', user: 'Nazly Villatoro',
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210', duration: '3:30',
        latest_note: ''
    },
    {
        lead_id: 6, ts: '2/21/19 7:57 PM', result: 'Reached', user: 'Nazly Villatoro',
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210', duration: '3:00',
        latest_note: ''
    },
    {
        lead_id: 7, ts: '2/21/19 7:57 PM', result: 'Reached', user: 'Michelle Orihuela',
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210', duration: '4:00',
        latestNote: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation'
    },
    {
        lead_id: 8, ts: '2/21/19 7:57 PM', result: 'Reached', user: 'Michelle Orihuela',
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210', duration: '1:00',
        latest_note: ''
    }
]

export const MOCK_LEADS = [
    {
        lead_id: 1, received: '2/21/19T7:57 PM', source: 'Test', type: 'Auto',
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210', disposition: 'None',
        latest_note: ''
    },
    {
        lead_id: 2, received: '2/21/19T7:57 PM', source: 'Source', type: 'Contact',
        first_name: 'Test', last_name: 'Lead', phone: '(614) 333-2210', disposition: 'Terminated',
        latest_note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation'
    },
    {
        lead_id: 3, received: '2/21/19T7:57 PM', source: 'Wufoo', type: 'Form 3',
        first_name: 'Chris', last_name: 'Jones', phone: '(614) 333-2210', disposition: 'Terminated',
        latest_note: ''
    },
    {
        lead_id: 4, received: '2/21/19T7:57 PM', source: 'Agent Form', type: 'Auto',
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210', disposition: 'None',
        latest_note: ''
    },
    {
        lead_id: 5, received: '2/21/19T7:57 PM', source: 'Test', type: 'Auto',
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210', disposition: 'None',
        latest_note: ''
    },
    {
        lead_id: 6, received: '2/21/19T7:57 PM', source: 'Source', type: 'Contact',
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210', disposition: 'None',
        latest_note: ''
    },
    {
        lead_id: 7, received: '2/21/19T7:57 PM', source: 'Wufoo', type: 'Form 3',
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210', disposition: 'Terminated',
        latest_note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation'
    },
    {
        lead_id: 8, received: '2/21/19T7:57 PM', source: 'Agent Form', type: 'Auto',
        first_name: 'Sample', last_name: 'Lead', phone: '(614) 333-2210', disposition: 'Terminated',
        latest_note: ''
    }
]
