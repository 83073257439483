import React, { useEffect, useState, useCallback } from "react"; 
import { useDispatch, useSelector } from "react-redux";
import Table from './subcomponents/E911Table';
import { Portal } from 'semantic-ui-react';
import { generateResolver, yup, Button, SettingsFooter, VALIDATORS, Notification, STATUS_TYPES } from "dyl-components"; 
import { useForm } from 'react-hook-form';
import { MathUtils } from 'utils';
import useWidthListener from 'shared/SettingsFooter/useWidthListener';
import useWindowWidth from 'shared/SettingsFooter/useWindowWidth'
import pbx_config from "actions/pbx_config";
import provision from "actions/provision";
import { ObjectUtils, StringUtils } from 'utils';
import './index.scss';

const E911Location = () => {
    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();
    const dispatch = useDispatch();

    const [isValidating, setValidating] = useState(false);
    const [revalidating, setRevalidating] = useState(false);
    const [isPopupOpen, setPopupOpen ] = useState(false);
    const [locations, setLocations] = useState([]);
    const [excludedPhones, setExcludedPhones] = useState([]);
    const [isDeletePromptOpen, setIsDeletePromptOpen] = useState(false);

    const  { isValidLocation, locationSuggestion, e911Locations, e911LocationsCount, isReadingLocations } = useSelector(state => {
        return {
            isValidLocation: state.provision.isValidLocation,
            locationSuggestion: state.provision.locationSuggestion,
            e911Locations: state.provision.locations,
            e911LocationsCount: state.provision.locationsCount,
            isReadingLocations: state.provision.isReadingLocations,
        }
    });

    const { formState: { isDirty, isValid }, control, handleSubmit, setError, clearErrors, trigger, setValue, reset, getValues } = useForm({
        mode: 'onChange',
        defaultValues: {
            label:'',
            caller_id_number: null,
            address: null,
            address2: null,
            city: null,
            state: null,
            zip: null,

        },
        resolver: generateResolver({
            label: VALIDATORS.TEAM_NAME(),
            caller_id_number: VALIDATORS.PHONE_NUMBER(),
            address: VALIDATORS.STREET_ADDRESS(),
            address2: yup.lazy(value => value === null || value === '' ? yup.string().nullable(true) : yup.string().minlength(2).maxlength(12)), 
            city: VALIDATORS.TOWN(),
            state: VALIDATORS.STATE(),
            zip: VALIDATORS.US_POSTAL_CODE()
        })
    });

    const onClosePopUp = () => {
        setPopupOpen(false);
        reset();
    }

    const onSaveLocations = async () => {
        try {
            await dispatch(provision.createLocations(locations));
            Notification.alert('E911 address saved!', STATUS_TYPES.SUCCESS);
            dispatch(provision.getLocations());
        } catch (e) {
            console.log(e);
            setRevalidating(false);
            Notification.alert('E911 address failed to save', STATUS_TYPES.ERROR);
        }
    }

    const isDuplicateLocation = (duplicateLocation) => {
        if((duplicateLocation.address2 === null || duplicateLocation.address2 === '')){
            delete duplicateLocation.address2
        }
        delete duplicateLocation.caller_id_number
        delete duplicateLocation.label
        const duplicateLocations = locations?.filter(location => {
            let locationCheck = { 
                address: location.address, 
                ...(duplicateLocation.address2 ? { address2: location.address2} : {}), 
                city: location.city, 
                state: location.state, 
                zip: location.zip
            }
            return ObjectUtils.deepEqual(duplicateLocation, locationCheck)
        });
        return duplicateLocations.length > 0
    }

    const onValidate = async (data) => {
        const location = { 
            address: StringUtils.toUpperCase(data.address), 
            ...(data.address2 !== null ? { address2: StringUtils.toUpperCase(data.address2) } : {}),
            caller_id_number: data.caller_id_number,
            city: StringUtils.toUpperCase(data.city), 
            label: data.label,
            state: data.state,
            zip: data.zip
        };
        if(isDuplicateLocation(location)){
            Notification.alert('Duplicate E911 address', STATUS_TYPES.ERROR);
            setRevalidating(false);
        } 
        if(!isDuplicateLocation(location)){
            try {
                await setValidating(true);
                await dispatch(provision.validateLocation(location));
                await setValidating(false);
                
            } catch (e) {
                console.log(e);
                setRevalidating(false);
                Notification.alert('Invalid E911 address', STATUS_TYPES.ERROR);
            }
        }
    }

    const onDeleteLocation = (id) => {
        const remainingLocations = locations.filter(location => location.caller_id_number !== id);
        setLocations(remainingLocations);
        setExcludedPhones(remainingLocations.map(location => location.caller_id_number));
        setIsDeletePromptOpen(false);
    }

    const isSavedDisabled = ObjectUtils.deepEqual(e911Locations, locations);

    useEffect(()=>{
        if(isValidLocation && isValidating){
            let { label, caller_id_number, address2 } = getValues();
            const location = {
                label,
                caller_id_number,
                ...locationSuggestion,
                ...(address2 !== null ? { address2: StringUtils.toUpperCase(address2) } : {})
            };
            Notification.alert('Valid E911 address!', STATUS_TYPES.SUCCESS);
            setLocations(prevState => [
                ...prevState,
                location
            ]);
            setExcludedPhones(prevState => [
                ...prevState,
                location.caller_id_number
            ]);
            setRevalidating(false);
            setPopupOpen(false);
            reset();
        }
        if(!isValidLocation && isValidating){
            Notification.alert('Revalidate E911 address', STATUS_TYPES.ERROR);
            setRevalidating(true);
            const { address, address2, city, state, zip } = locationSuggestion;
            setValue("address", address);
            setValue("address2", address2 || null);
            setValue("city", city);
            setValue("state", state);
            setValue("zip", zip);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationSuggestion, isValidLocation]);

    useEffect(()=>{
        dispatch(provision.getLocations());
    }, [dispatch]);

    const getPhones = useCallback(async()=>{
        await dispatch(pbx_config.listPhones(e911LocationsCount > 0 ? {excludes:excludedPhones} : {}));
    }, [dispatch, e911LocationsCount, excludedPhones]);

    useEffect(()=>{
        setLocations(e911Locations);
        setExcludedPhones(e911Locations.map(location => location.caller_id_number));
    }, [e911Locations]);

    return (
        <>
            <div className="e991-location">
                <Table
                    control={control}
                    clearErrors={clearErrors}
                    setError={setError}
                    trigger={trigger}
                    onValidate={handleSubmit(onValidate)}
                    isValid={isValid}
                    isDirty={isDirty}
                    revalidating={revalidating}
                    isPopupOpen={isPopupOpen}
                    setPopupOpen={setPopupOpen}
                    locations={locations}
                    onClosePopUp={onClosePopUp}
                    isReadingLocations={isReadingLocations}
                    onDeleteLocation={onDeleteLocation}
                    getPhones={getPhones}
                    setIsDeletePromptOpen={setIsDeletePromptOpen}
                    isDeletePromptOpen={isDeletePromptOpen}
                />
            </div>
            <Portal open>
                <SettingsFooter
                    style={{ width: MathUtils.calculatePercentage(windowWidth, windowWidth - width) }}
                    className={`Webform__menu`}
                    rightOptions={[
                        <Button
                            onClick={onSaveLocations}
                            disabled={ isSavedDisabled }
                        >
                            Save
                        </Button>
                    ]}
                />
            </Portal>
        </>
    );
}

export default E911Location;