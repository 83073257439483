import React, { useCallback, useEffect, useState } from 'react';
import Table from './subcomponents/Table';
import { Notification, STATUS_TYPES, TableWithHeader } from 'dyl-components';
import Toolbar from './subcomponents/Toolbar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BulkActionsProvider from 'shared/context/BulkActionsProvider';
import { useDispatch } from 'react-redux';
import pbxConfigActions from 'actions/pbx_config';
import CallRoutingRuleModal from 'shared/modals/CallRoutingRuleModal';

const CALL_ROUTING_RULES_URL = "/settings/phone-management/routing/call-routing-rules"

const CallRoutingRules = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const dispatch = useDispatch();
    const searchQuery = params.get('search') || '';
    const [search, setSearch] = useState(searchQuery);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }
    
    const cancelFunction = () => {
        setSearch("");
        const query = new URLSearchParams(params);
        query.delete('search');
        const query_string = query.toString();
        navigate(`${CALL_ROUTING_RULES_URL}${query_string ? `?${query_string}` : ''}`,);
    }

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`${CALL_ROUTING_RULES_URL}${query_string ? `?${query_string}` : ''}`);
    }

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${CALL_ROUTING_RULES_URL}${query_string ? `?${query_string}` : ''}`);
    }

    const onReload = useCallback(() => {
        dispatch(pbxConfigActions.getRoutingRules(params));
    }, [dispatch, params])

    const onReadCallRoutingRule = async (id) => {
        await dispatch(pbxConfigActions.getRoutingRule(id));
        setIsEditing(true);
        setIsModalOpen(true);
    }
    
    const onDelete = async (id) => {
        try {
            await dispatch(pbxConfigActions.deleteRoutingRule(id))
            onReload();
            Notification.alert('Call Routing Rule deleted successfully!', STATUS_TYPES.SUCCESS);
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to delete Call Routing Rule', STATUS_TYPES.ERROR);
        }
    }

    useEffect(() => {
        const query = new URLSearchParams(params);
        query.set('page', 1);
        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }
        const query_string = query.toString();
        navigate(`${CALL_ROUTING_RULES_URL}${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        onReload();
    }, [onReload])

    return <>
        <div className='CallRoutingRules'>
            <TableWithHeader
                header={(
                    <Toolbar
                        search={searchQuery}
                        onChangeSearch={onChangeSearch}
                        onSearchSubmit={onSearchSubmit}
                        cancelFunction={cancelFunction}
                        onOpenModal={() => setIsModalOpen(true)}
                    />
                )}
                table={(
                    <Table onPageChange={onPageChange} onReadCallRoutingRule={onReadCallRoutingRule} onDelete={onDelete} />
                )}
            />
            <CallRoutingRuleModal 
                open={isModalOpen} 
                onClose={() => {
                    setIsModalOpen(false)
                    setIsEditing(false);
                }} 
                onReload={onReload} 
                isEditing={isEditing}
            />
        </div>
    </>
}

export default () => (
    <BulkActionsProvider>
        <CallRoutingRules />
    </BulkActionsProvider>
);