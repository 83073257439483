import React, { useState } from 'react';
import { Segment, Grid, Header, Transition, Icon, Popup, Form } from 'semantic-ui-react';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';

import { NavLink, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import CustomData from "../CustomData";
import contactActions from 'actions/contact';
import { CustomGroupUtils } from 'utils';
import { Notification, STATUS_TYPES } from 'dyl-components';

const CustomDataSection = ({
    custom_data,
    contact_id
}) => {
    const {
        school,
        degree,
        description,
        department,

        business_name,
        industry_sector,
        sub_industry,
        employee_size,
        annual_revenue,
        product_services_sold,

        household_name,
        household_type,
        household_members,
        number_of_children,
        annual_household_income,

        standard_groups
    } = useSelector(state => {
        const { fields, children } = CustomGroupUtils.groupAndFlatten(state.contact.custom_data);
        const household_details = (children?.household_details || [])[0]?.fields;
        const business_details = (children?.business_details || [])[0]?.fields;

        const [industryValue, subIndustryValue] = `${business_details?.industry_sector} || ''`?.split(',').map(value => Number(value)) || ['', ''];

        return {
            school: fields?.school || '',
            degree: fields?.degree || '',
            description: fields?.description || '',
            department: business_details?.department || '',

            business_name: business_details?.company_name || '',
            industry_sector: industryValue,
            sub_industry: subIndustryValue,
            employee_size: business_details?.employee_count,
            annual_revenue: business_details?.annual_revenue,
            product_services_sold: business_details?.product_services_sold || '',

            household_name: household_details?.household_name || '',
            household_type: household_details?.household_type || '',
            household_members: household_details?.household_members_count || 0,
            number_of_children: household_details?.number_of_children || 0,
            annual_household_income: household_details?.annual_income || '',

            standard_groups: state.contact.custom_data?.children?.filter(child => child.standard)
        }
    });

    const dispatch = useDispatch();

    const { control, handleSubmit } = useForm({
        defaultValues: {
            school,
            degree,
            description,
            department,

            business_name,
            industry_sector,
            sub_industry,
            employee_size,
            annual_revenue,
            product_services_sold,

            household_name,
            household_type,
            household_members,
            number_of_children,
            annual_household_income,

            children: custom_data
        }
    })

    const confirmInlineEdit = async (data) => {
        try {
            await dispatch(contactActions.updateContact(contact_id, { custom_data: {
                fields: CustomGroupUtils.extractPersonFields(data),
                children: {
                    ...CustomGroupUtils.extractBusinessAndHouseholdDetails(data),
                    ...CustomGroupUtils.groupAndFlatten({ children: data.children }).children
                }
            } }))
            dispatch(contactActions.inlineUpdateCustomData([
                ...standard_groups,
                ...data.children
            ]))
            Notification.alert('Successfully updated contact!', STATUS_TYPES.SUCCESS);
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update contact', STATUS_TYPES.ERROR);
        }
    }

    return (
        <Form size='mini'>
            <CustomData
                control={control}
                numberOfColumns={4}
                onConfirmInlineEdit={handleSubmit(confirmInlineEdit)}
            />
        </Form>
    )
}

const CustomDataOverview = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const toggleCollapsed = () => {
        setIsCollapsed(!isCollapsed);
    }

    const { contact_id } = useParams();

    const { isReadingContact, isUpdatingContact, custom_data } = useSelector(state => {
        return {
            custom_data: state.contact.custom_data?.children?.filter(child => !child.standard),
            isReadingContact: state.contact.isReadingContact,
            isUpdatingContact: state.contact.contactBeingUpdated
        }
    });

    return (
        <Segment loading={isReadingContact || isUpdatingContact} color='blue' className='CustomData'>
            <Grid stackable>
                <Grid.Row textAlign='center' style={{ paddingBottom: `${isCollapsed ? '1' : '0'}em` }}>
                    <Grid.Column>
                        <Header as='h5' color='blue'>CUSTOM DATA <span style={{ float: 'right' }}>
                            <Icon
                                className='CustomData__toggler'
                                size='small'
                                link
                                color='blue'
                                inverted
                                onClick={toggleCollapsed}
                                circular
                                name={`chevron ${isCollapsed ? 'right' : 'down'}`}
                            />
                            <Popup
                                trigger={<Icon className={`fas fa-external-link`}
                                    size='small'
                                    circular
                                    link
                                    color='primary'
                                    as={NavLink}
                                    to={`/contact/${contact_id}/data`}
                                />
                                }
                                content={'View/Edit Full Contact Info'}
                                inverted
                                position='right center'
                            />
                        </span> </Header>
                    </Grid.Column>
                </Grid.Row>
                <Transition visible={!isCollapsed} unmountOnHide animation='slide down' duration='150' style={{ paddingTop: 0 }}>
                    <Grid.Row>
                        <Grid.Column>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <CustomDataSection
                                            custom_data={custom_data}
                                            contact_id={contact_id}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Transition>
            </Grid>
        </Segment>
    )
}

export default CustomDataOverview;



