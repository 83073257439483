import React from 'react';
import { TableWithHeader } from 'dyl-components';
import CategoriesTable from './CategoriesTable';
import CategoriesHeader from './CategoriesHeader';
import { Grid, Transition} from 'semantic-ui-react';
import AddCategorySectionContainer from './AddCategorySection';
import LeftPaddedContent from 'shared/LeftPaddedContent';

const Categories = ({ 
    categories,  
    onDelete, 
    isAddCategorySectionOpen,
    onOpenAddCategorySection, 
    onCloseAddCategorySection, 
    onAddCategory,
    onUpdate
}) => (
    <TableWithHeader 
        header={
            <CategoriesHeader 
                onOpenAddCategorySection={onOpenAddCategorySection}
            />
        }
        table={
            <Grid style={{margin: 0}}>
                <Transition visible={isAddCategorySectionOpen} unmountOnHide duration='100' animation='slide down'>
                    <Grid.Row>
                        <Grid.Column>
                            <LeftPaddedContent>
                                <AddCategorySectionContainer 
                                    onAddCategory={onAddCategory} 
                                    onClose={onCloseAddCategorySection}
                                />
                            </LeftPaddedContent>
                        </Grid.Column>
                    </Grid.Row>
                </Transition>
                <Grid.Row style={{padding: 0}}>
                    <Grid.Column style={{padding: 0}}>
                        <CategoriesTable 
                            categories={categories}
                            onDelete={onDelete}
                            onUpdate={onUpdate}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        }
    />
);

class CategoriesContainer extends React.Component {
    state = {
        isAddCategorySectionOpen: false
    }

    onOpenAddCategorySection = () => {
        this.setState({ isAddCategorySectionOpen: true });
    }

    onCloseAddCategorySection = () => {
        this.setState({ isAddCategorySectionOpen: false });
    }

    render() {
        return (
            <Categories 
                {...this.state}
                {...this.props}
                isAddCategorySectionOpen={this.state.isAddCategorySectionOpen}
                onOpenAddCategorySection={this.onOpenAddCategorySection}
                onCloseAddCategorySection={this.onCloseAddCategorySection}
            />
        )
    }
}

export default CategoriesContainer;
