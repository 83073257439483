import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StringUtils } from 'utils';
import Avatar from 'react-avatar';
import { Grid, Header, Icon, List, Popup, Divider, Segment } from 'semantic-ui-react';
import { ButtonLink } from 'dyl-components';

import './index.scss';

const NoUsersMsg = () => (
    <div className="NoAssignedUserTeam">
        <Header as="h2" icon>
            <Icon name="users" size="huge" />
            No Teams
            <Header.Subheader>
                You have not been assigned to any teams.
            </Header.Subheader>
        </Header>
    </div>
);

const Teams = ({ teams, toggleTeamMembers, user_id, isReadingUserProfile }) => {

    const defaultNumberOfMembersDisplayed = 10;

    return (!teams.length && !isReadingUserProfile) ?
        <NoUsersMsg />
        :
        <Segment basic loading={ isReadingUserProfile } >

            { teams.map(({ name, members, id, areAllTeamMembersShown }, index) => (
                <div className="UserTeamList">
                    <Header color='blue' as='h3'>{ StringUtils.capitalize(name) }</Header>
                    <Grid key={ id } columns={ 5 } >
                        { members.slice(0, areAllTeamMembersShown ? members.length : defaultNumberOfMembersDisplayed).map(({ user_id: member_user_id, first_name, last_name, email }) => {
                            return (
                                <Grid.Column className="TeamMember" key={ member_user_id } style={ { paddingRight: 0, paddingBottom: "0.5em", paddingTop: "0.5em" } }>
                                    <List relaxed>
                                        <Popup
                                            trigger={ (
                                                <List.Item key={ id }>
                                                    <List.Icon verticalAlign='middle'>
                                                        <Avatar name={ `${first_name} ${last_name}` } maxInitials={ 2 } round size='3em' />
                                                    </List.Icon>
                                                    <List.Content>
                                                        <List.Header><span className="TeamMember__name">{ first_name } { last_name }{ member_user_id === user_id && " (me)" }</span></List.Header>
                                                        <List.Description><Icon name="mail outline" /><a href={ `mailto:${email}` }><span className="TeamMember__email">{ email }</span></a></List.Description>
                                                    </List.Content>
                                                </List.Item>
                                            ) }
                                            content={ (
                                                <div>
                                                    <p>Name: { first_name } { last_name }{ member_user_id === user_id && " (me)" }</p>
                                                    <p>Email: { email }</p>
                                                </div>
                                            ) }
                                            inverted
                                        />
                                    </List>
                                </Grid.Column>
                            );
                        }) }
                    </Grid>
                    <div className="UserTeamList__LoadTeamMembers">
                        { members.length > defaultNumberOfMembersDisplayed && (
                            <ButtonLink onClick={ () => { toggleTeamMembers(index) } }>
                                <span style={ { fontWeight: "bold" } }>{ `Load ${areAllTeamMembersShown ? "less" : "more"}` }</span>
                            </ButtonLink>
                        ) }
                    </div>
                    <Divider />
                </div>
            )) }
        </Segment>
}

const TeamsContainer = () => {
    const { userProfile, isReadingUserProfile } = useSelector(state => state.user);
    const { teams: myTeams } = userProfile;
    const { user_id } = useSelector(state => state.auth);

    const [teams, setTeams] = useState([]);

    const toggleTeamMembers = (index) => {

        const theTeam = teams.slice(0);
        theTeam[index] = { ...theTeam[index], areAllTeamMembersShown: !theTeam[index].areAllTeamMembersShown }
        setTeams(theTeam);
    }

    useEffect(() => {
        const results = myTeams.map((team) => {
            const userFilteredMembers = team.members;
            return {
                id: team.id,
                members: userFilteredMembers,
                name: team.name,
                areAllTeamMembersShown: false
            };
        });
        setTeams(results);
        return () => {
            setTeams([])
        }

    }, [myTeams]);

    return <Teams user_id={ user_id } teams={ teams } toggleTeamMembers={ toggleTeamMembers } isReadingUserProfile={ isReadingUserProfile } />
}

export default TeamsContainer;
