import React from 'react';
import { Grid, Checkbox, Button } from 'semantic-ui-react';
import { TextAreaCharCount, IconFileAttach, ButtonLink } from 'dyl-components';
import { MessageList } from 'react-chat-elements';

import './MessageContent.scss';

export default class MessageContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    render() {
        return (
            <Grid className='MessageContent'>
                <Grid.Row columns={2} className='MessageContent__header'>
                    <Grid.Column textAlign='left'>
                        To: <span>march 27 test</span>
                    </Grid.Column>
                    <Grid.Column textAlign='right'>
                        <ButtonLink>View Details</ButtonLink>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className='MessageContent__body'>
                    <Grid.Column>
                        <MessageList
                            className='message-list'
                            lockable={true}
                            toBottomHeight={'100%'}
                            dataSource={[
                                {
                                    position: 'right',
                                    type: 'text',
                                    text: 'Sample Right',
                                    date: new Date(),
                                },
                                {
                                    position: 'left',
                                    type: 'text',
                                    text: 'Sample Left',
                                    date: new Date(),
                                },
                                {
                                    position: 'right',
                                    type: 'text',
                                    text: 'Sample Right',
                                    date: new Date(),
                                },
                                {
                                    position: 'left',
                                    type: 'text',
                                    text: 'Sample Left',
                                    date: new Date(),
                                },
                            ]} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className='MessageContent__consent-text-messaging'>
                    <Grid.Column>
                        <p>
                            <strong>
                                <Checkbox label='Consent to Text Messaging' />
                            </strong>
                            &nbsp;
                            I agree that I have consent allowing me to send this message
                            and that the intended recipient has not previously opted out.
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className='MessageContent__send-message'> 
                    <Grid.Column computer={12} tablet={12} mobile={16}>
                        <TextAreaCharCount 
                            textAreaPlaceholder='Message'
                            maxChar={160}
                            rows={1} 
                            value={''}/>
                    </Grid.Column>
                    <Grid.Column computer={1} tablet={1} mobile={2}>
                        <IconFileAttach 
                            icon='paperclip'/>
                    </Grid.Column>
                    <Grid.Column computer={1} tablet={1} mobile={1}>
                        <Button basic>SEND</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}
