/**
 * Determines whether a task is editable.
 *
 * @param {Object|null} taskBeingEdited - The task to check, or null if creating a new task.
 * @param {string} user_id - The ID of the system user.
 * @returns {boolean} - True if the task is editable; false otherwise.
 */
const isAllowedToModify = (taskBeingEdited, organizer_id) => {
    if (taskBeingEdited?.id) {
        const { assigner_id, assignee_id } = taskBeingEdited
        const isEditor = organizer_id === assigner_id || organizer_id === assignee_id;
        const isTaskIncomplete = !taskBeingEdited?.complete;
        return (isTaskIncomplete && isEditor);
    }
    return true;
}


export default {
    isAllowedToModify,
}
