import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function quoteReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.QUOTES_AND_ORDERS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, entries: [], count: 0, isReadingQuotesAndOrders: true };
        case getNamedAction(ACTION_NAMES.QUOTES_AND_ORDERS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, entries: action.data?.data || [], count: action.data?.count || 0, isReadingQuotesAndOrders: false };
        case getNamedAction(ACTION_NAMES.QUOTES_AND_ORDERS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, entries: [], count: 0, isReadingQuotesAndOrders: false };

        default:
            return state;
    }
}


export default quoteReducer;
