const initialState = {
    calendar: [],
    isReadingCalendar: false,
    activeFilter: 'All',
    userFilter: 'All',
    queryParameters: {
        type: null,
        user: null,
        status: 'all',
        limit: 150
    }
}

export default initialState;
