import React from 'react';
import opportunitiesActions from 'actions/opportunities';
import OpportunitiesToolbar from './subcomponents/OpportunitiesToolbar';
import OpportunitiesTable from './subcomponents/OpportunitiesTable';
import {
    KEY_CREATED, KEY_NEXT_STEP,
    KEY_CREATED_BY, KEY_OWNED_BY, KEY_OPPORTUNITY_AGE, KEY_LAST_MODIFIED, KEY_SOURCE, KEY_SYNCED_QUOTE,
    KEY_LAST_MODIFIED_BY, KEY_CAMPAIGN_SOURCE, KEY_ONE_TIME_AMOUNT, KEY_OPPORTUNITY_ID,
    KEY_DESCRIPTION, KEY_REOCCURRING_AMOUNT, KEY_OPPORTUNITY_NAME,
    KEY_SALES_STRATEGY_NOTES, KEY_CONVERT, KEY_CONTACT_NAME,
    KEY_PAIN_NEED_NOTES, KEY_TOTAL_AMOUNT, KEY_ACCOUNT,
    KEY_CONFIDENCE_LEVEL, KEY_ACTIONS, KEY_STAGE, KEY_CLOSE_PROBABILITY,
    KEY_OUTCOME, KEY_EXPECTED_CLOSE_DATE, KEY_LOST_REASON,
    KEY_NEXT_STEP_DATE
} from 'shared/constants/MODULE_COLUMNS';

import ModulesListView from 'shared/ModulesListView';

const Opportunities = () => {
    return (
        <ModulesListView
            TableComponent={OpportunitiesTable}
            ToolbarComponent={OpportunitiesToolbar}
            allowedColumns={[KEY_CREATED, KEY_ACCOUNT, KEY_STAGE, KEY_OPPORTUNITY_ID, KEY_OPPORTUNITY_NAME, KEY_CONTACT_NAME, KEY_OUTCOME, KEY_LAST_MODIFIED]}
            defaultColumnsDisplayed={[
                KEY_CREATED,
                KEY_OPPORTUNITY_ID,
                KEY_ACCOUNT,
                KEY_OUTCOME,
                KEY_CONVERT,
                KEY_ACTIONS
            ]}
            moduleName='Opportunities'
            readModules={opportunitiesActions.readOpportunities}
            routeRoot={'opportunities'}
            columnDisplayOptions={[
                KEY_CREATED, KEY_NEXT_STEP,
                KEY_CREATED_BY, KEY_OWNED_BY, KEY_OPPORTUNITY_AGE, KEY_LAST_MODIFIED, KEY_SOURCE, KEY_SYNCED_QUOTE,
                KEY_LAST_MODIFIED_BY, KEY_CAMPAIGN_SOURCE, KEY_ONE_TIME_AMOUNT, KEY_OPPORTUNITY_ID,
                KEY_DESCRIPTION, KEY_REOCCURRING_AMOUNT, KEY_OPPORTUNITY_NAME,
                KEY_SALES_STRATEGY_NOTES, KEY_CONVERT, KEY_CONTACT_NAME,
                KEY_PAIN_NEED_NOTES, KEY_TOTAL_AMOUNT, KEY_ACCOUNT,
                KEY_CONFIDENCE_LEVEL, KEY_ACTIONS, KEY_STAGE, KEY_CLOSE_PROBABILITY,
                KEY_OUTCOME, KEY_EXPECTED_CLOSE_DATE, KEY_LOST_REASON,
                KEY_NEXT_STEP_DATE
            ]}
        />
    );
}

export default Opportunities;
