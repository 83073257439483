import React, { useState } from 'react';
import { Table } from 'dyl-components';
import FieldEntries from './FieldEntries';
import DependentFieldEntries from './DependentFieldEntries';

const FieldGroup = ({ fields, onEditField, standard, isReading, parent_group, group_name }) => {
    const [expandedFields, setExpandedFields] = useState([]);

    const onToggleField = (field_id) => {
        setExpandedFields(expandedFields.includes(field_id) ? expandedFields.filter(id => id !== field_id) : [...expandedFields, field_id])
    }

    const isExpanded = field_id => expandedFields.includes(field_id);
    const isEmptyFieldGroup = fields.every(item => Object.keys(item).length === 0);

    if (isReading) {
        return null;
    }

    if (!fields || !fields.length || isEmptyFieldGroup) {
        return (
            <Table.Row>
                <Table.Cell colSpan={8}>
                    There are no fields associated with this Item
                </Table.Cell>
            </Table.Row>
        )
    }

    return (
        fields.map((field) => {
            const hasDependency = field.field_type === 'dependency';
            const RowComponent = hasDependency ? Table.CollapsibleRow : Table.Row;
            const editable = (hasDependency || field.field_type === 'tagsinput' || (field.field_name === "product_interest" && field.field_type === "picklist")) && !(field.field_name === 'master_source' && group_name === 'opportunity')
            return (
                (field?.field_type) && <RowComponent
                    key={field.field_name}
                    {...(hasDependency ? {
                        onToggle: () => { onToggleField(field.field_name) },
                        collapsed: !isExpanded(field.field_name),
                        subrowContent: (
                            field.data && (
                                <Table.CollapsibleRowContent indented>
                                    <DependentFieldEntries
                                        field={field}
                                    />
                                </Table.CollapsibleRowContent>
                            )
                        )
                    } : {})}
                >
                    {!hasDependency && <Table.Cell width={1} />}
                    <FieldEntries
                        editable={editable}
                        field={field}
                        hasDependency={hasDependency}
                        onEditField={onEditField}
                        parent_group={parent_group}
                        standard={standard}
                    />
                </RowComponent>
            );
        })
    )
};

export default FieldGroup;
