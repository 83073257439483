import React from 'react';
import { TableWithHeader } from 'dyl-components';
import { Grid, Segment, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { EditableField } from 'dyl-components';

const E911Information = ({ info, isReadingUser }) => (
    <TableWithHeader 
        header='Your address has been updated to work with e911'
        table={(
            isReadingUser ? (
                <Segment>
                    <Dimmer active>
                        <Loader />
                    </Dimmer>
                </Segment>
            ) :
            <Grid celled style={{marginTop: 0, marginBottom: 0}}>
                <Grid.Row columns='equal'>
                    <EditableField 
                        label='Address'
                        value={info.address.toUpperCase()}
                        isEditMode={false}
                    />
                </Grid.Row>
                <Grid.Row columns='equal'>
                    <EditableField 
                        label='Address (2)'
                        value={info.address2.toUpperCase()}
                        isEditMode={false}
                    />
                </Grid.Row>
                <Grid.Row columns='equal'>
                    <EditableField 
                        label='City'
                        value={info.city.toUpperCase()}
                        isEditMode={false}
                    />
                </Grid.Row>
                <Grid.Row columns='equal'>
                    <EditableField 
                        label='State'
                        value={info.state.toUpperCase()}
                        isEditMode={false}
                    />
                </Grid.Row>
                <Grid.Row columns='equal'>
                    <EditableField 
                        label='Zip'
                        value={info.zip}
                        isEditMode={false}
                    />
                </Grid.Row>
            </Grid>
        )}
    />
);

const mapStateToProps = state => {
    return {
        info: state.user.user,
        isReadingUser: state.user.isReadingUser
    }
}

export default connect(mapStateToProps, null)(E911Information);
