import { RuleContainer } from "dyl-components";
import { useState } from "react";
import { Header, Icon, Segment, Transition } from "semantic-ui-react";

const ProductCodes = () => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Segment>
            <Header as="h4">
                <span>
                    <Icon
                        color="primary"
                        size="small"
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                        link
                        className={`fas fa-circle-caret-${
                            expanded ? "up" : "down"
                        }`}
                    />{" "}
                    Product Codes
                </span>
            </Header>
            <Transition
                visible={expanded}
                duration={100}
                animation="slide down"
                unmountOnHide
            >
                <RuleContainer 
                    isActive
                    content={<></>}
                />
            </Transition>
        </Segment>
    );
};

export default ProductCodes;
