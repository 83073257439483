const initialState = {
    callIsActive: false,
    callDetails: {},
    isHangingUp: false,
    isCalling: false,
    isTransferringCall: false,
    isResolvingCallResult: false
}

export default initialState;
