import { DateTimeUtils } from 'dyl-components';
import { StringUtils } from 'utils';

const { CALENDAR_API_DATE_FORMAT } = DateTimeUtils

const DAYS_OPTIONS = [
    { key: 'Su', value: 'Su', text: 'S' },
    { key: 'M', value: 'M', text: 'M' },
    { key: 'T', value: 'T', text: 'T' },
    { key: 'W', value: 'W', text: 'W' },
    { key: 'Th', value: 'Th', text: 'T' },
    { key: 'F', value: 'F', text: 'F' },
    { key: 'Sa', value: 'Sa', text: 'S' }
]

const FREQUENCY_OPTIONS = [
    {
        key: 'daily', value: 'daily', text: 'Daily',
    },
    {
        key: 'monthly', value: 'monthly', text: 'Monthly'
    },
    {
        key: 'yearly', value: 'yearly', text: 'Yearly'
    }
]


/**
 * Converts a date pair value between eventForm (date,date_time) & calendar-api date formats.
 * We use the calendar-api format on payloads and eventForm for display
 * example:
 *     form     = {all_day:false, date: "May 04, 2023", date_time: "03:45 PM" }
 *     payload  = {all_day:false, date: null date_time: 1683305267 }
 *
 *
 * @param {Object} options - The options object.
 * @param {boolean} options.all_day - Whether the event is an all-day event.
 * @param {string} options.date - The date of the event ("May 04, 2023")
 * @param {string} options.date_time - The time of the event ("03:45 PM")
 * @param {string} options.format - ["form","payload"] whether the output is calendar-api or eventForm formats
 * @returns {Object} - The calendar-api date payload.
 */
const formatDate = ({ all_day, date, date_time, format="payload" , timezone }) => {
    let newDate = {}
    switch(format) {
        case "payload":
            newDate = {
                date: all_day? DateTimeUtils.changeFormat(date, DateTimeUtils.WORD_DATE_FORMAT, CALENDAR_API_DATE_FORMAT) : null,
                date_time: all_day? null : DateTimeUtils.getUnixTime(`${date} ${date_time}`, DateTimeUtils.WORD_DATETIME_FORMAT, timezone), 
            };
            break;
        case "form":
            newDate = {
                date: all_day? DateTimeUtils.changeFormat(date, CALENDAR_API_DATE_FORMAT, DateTimeUtils.WORD_DATE_FORMAT) : null,
                date_time: all_day? null : DateTimeUtils.getUnixTime(`${date} ${date_time}`, DateTimeUtils.WORD_DATETIME_FORMAT, timezone)
            };
            break;
        default:
            break;
    }
    return newDate;
};

/**
 * Converts an event between eventForm & calendar-api event payload.
 *
 * @param {Object} event - The event form object.
 * @returns {Object} The calendar event payload.
 */
const toPayload = (event) => {
    const [ related_to_type, related_to_id ] = event.related_to?.split('-') || [ null, null ]
    const { all_day, start_date, start_time, end_date, end_time, organizer_id, users, timezone } = event;
    const user_ids = [organizer_id, ...users];
    const all_day_date_time =  DateTimeUtils.getUnixTime(`${start_date} 12:00 am`, DateTimeUtils.DATETIME_FORMAT);
    const payload = {
        // ...(account_id !== 0 ? {account_id} : {}), //TODO: possibly add account_id to payload
        "complete": false,
        "conference_phone": event.phone_number,
        "conference_pin": event.pin,
        "conference_url": event.conference_line,
        "content": `
                <html>
                <body>
                ${event.content}
                </body>
                </html>
            `,
        "content_type": "text/html",
        "event_label_id": event.label || 0,
        "location": event.location,
        "name": event.name,
        "all_day": all_day,
        "related_to_type": related_to_type,
        "related_to_id": parseInt(related_to_id) || 0,
        "start": formatDate({all_day, date: start_date, date_time: start_time, timezone}),
        "end": formatDate({all_day, date: end_date, date_time: end_time, timezone}),
        "description": event.content ? StringUtils.CleanHTML({children: event.content, collapsed: true}) : "",
        "contact_id": event?.contacts || event?.contact_id,
        "contact_name": event.contact_name,
        "user_ids": user_ids,
        "all_day_date_time": all_day_date_time,
        "event_id": event.event_id,
    };
    return payload;
}

/**
 * Determines whether an event is editable.
 *
 * @param {Object|null} eventBeingEdited - The event to check, or null if creating a new event.
 * @param {string} user_id - The ID of the system user.
 * @returns {boolean} - True if the event is editable; false otherwise.
 */
const isAllowedToModify = (eventBeingEdited, user_id) => {
    if (eventBeingEdited?.id) {
        const { organizer_id } = eventBeingEdited; // TODO: organizer is not set correctly
        return user_id === organizer_id;
    }
    return true;
}


export default {
    toPayload,
    isAllowedToModify,
    DAYS_OPTIONS,
    FREQUENCY_OPTIONS
}
