import React from 'react';
import { Table } from 'dyl-components';
import { connect } from 'react-redux';
import { Loader, Segment } from 'semantic-ui-react';

import ChatRoomRow from './ChatRoomRow';

const ChatRoomsTable = ({ chatRooms }) => (
    <Table celled>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Command</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {chatRooms.map(chatRoom => (
                <ChatRoomRow chatRoom={chatRoom} key={chatRoom.id} />
            ))}
        </Table.Body>
    </Table>
);

class ChatRoomsTableContainer extends React.Component {
    render() {
        if (this.props.isReading) {
            return (
                <Segment>
                    <Loader inverted active />
                </Segment>
            )
        }
        return (
            <ChatRoomsTable 
                {...this.props}
            />
        )
    }
}

const mapStateToProps = state => ({
    chatRooms: state.chatRooms.rooms,
    isReading: state.chatRooms.isReadingChatRooms
});

export default connect(mapStateToProps)(ChatRoomsTableContainer);
