import React from 'react';
import { STATUS_TYPES, TimelineTabForm, Notification } from 'dyl-components';
import { Input, Header, Icon } from 'semantic-ui-react';

import ActivityForm from './subcomponents/ActivityForm';
import Timeline from './subcomponents/Timeline';

import './index.scss';
import { connect } from 'react-redux';

import selfReportActions from 'actions/self_report';
import { DateTimeUtils } from 'dyl-components';

class ActivitiesTab extends React.Component {
    state = {
        isFormTabOpen: true,
        activeItem: 'all'
    }

    componentDidMount() {
        this.props.readActivityLog(this.props.contact_id);
    }

    handleItemClick = (_, { name }) => {
        this.setState({ activeItem: name })
    }

    onChange = (_, { name, value }) => {
        this.setState({
            [name]: value
        })
    }

    onToggleFormTab = () => {
        this.setState(prevState => ({
            isFormTabOpen: !prevState.isFormTabOpen
        }))
    }

    onToggleActivity = async (_, { type, checked, id }) => {
        const onToggleActivityCompletion = async () => {
            if (type === 'call') {
                return this.props.onToggleCallCompletion(id, checked);
            }
            if (type === 'text') {
                return this.props.onToggleTextCompletion(id, checked);
            }
            if (type === 'email') {
                return this.props.onToggleEmailCompletion(id, checked);
            }
            return Promise.reject({
                message: `Completion is not possible for this type: ${type}`
            });
        }
        try {
            await onToggleActivityCompletion(id, checked);
            Notification.alert('Successfully updated completion!', STATUS_TYPES.SUCCESS, true);
            this.props.readActivityLog(this.props.contact_id);
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update completion', STATUS_TYPES.ERROR, true);
        }
    }

    render() {
        return (
            <TimelineTabForm
                timelineHeader={
                    <Header className='ActivitiesTab__Header' as='h2'>
                        <Icon className='TimelineTab__add' name='plus' color='blue' link circular inverted size='tiny' onClick={this.onToggleFormTab} /> 
                        Activity
                    </Header>
                }
                timelineSubHeader={
                    <div className='ActivitiesTab__NewActivity'>
                        <Input fluid placeholder='Filter by activity name/date' />
                    </div>
                }
                timelineBody={
                    <Timeline
                        expanded={this.state.isFormTabOpen}
                        items={this.props.activities}
                        onToggleActivity={this.onToggleActivity}
                    />
                }
                formHeader={
                    <Header className='ActivityForm__Header'>
                        New Activity
                    </Header>
                }
                formBody={
                    <ActivityForm
                        logActivity={this.props.logActivity}
                        onClose={this.onToggleFormTab}
                        refresh={() => {
                            this.props.onReadSelfReportedItems({
                                contact_id: this.props.contact_id,
                                user_id: this.props.user_id
                            });
                            this.props.readActivityLog(this.props.contact_id);
                        }}
                    />
                }
                //primary-table-6
                color='#214BD6'
                expanded={this.state.isFormTabOpen}
                loading={this.props.isReadingActivities || this.props.isUpdating}
            />
        )
    }
}

const mapStateToProps = state => ({
    activities: state.self_report.activityLog,
    contact_id: Number(state.contact.contact_id),
    isUpdating: state.self_report.callLogBeingUpdated || state.self_report.textLogBeingUpdated || state.self_report.emailLogBeingUpdated,
    isReadingActivities: state.self_report.isReadingActivityLog
});

const mapDispatchToProps = dispatch => ({
    onToggleCallCompletion: (call_id, completed) => {
        return dispatch(selfReportActions.toggleCallCompletion(call_id, { completed }));
    },
    onToggleTextCompletion: (text_id, completed) => {
        return dispatch(selfReportActions.toggleTextCompletion(text_id, { completed }));
    },
    onToggleEmailCompletion: (email_id, completed) => {
        return dispatch(selfReportActions.toggleEmailCompletion(email_id, { completed }));
    },
    readActivityLog: (contact_id) => {
        dispatch(selfReportActions.readActivity({ contact_id, occurred: DateTimeUtils.getCurrentDate(null, true, false) }))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesTab);
