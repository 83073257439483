import React from 'react';
import { Dimmer, Loader, Input, Checkbox, Select } from 'semantic-ui-react';
import { TableWithHeader, ControlledPopup, Table, ButtonLink } from 'dyl-components';

import './index.scss';

class EditForwardingNumber extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { 
            loading
         } = this.state;

        const { isEdit, forwardingNumber } = this.props;

        return (
            <React.Fragment>
                <div className='EditForwardingNumber'>
                    <TableWithHeader
                    header={
                        <React.Fragment>
                            <span>
                                Forwarding Number
                            </span>
                        </React.Fragment>
                    }
                    table={
                        <React.Fragment>
                            <Table celled>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell width={1}>
                                            Forwarding Number
                                        </Table.Cell>
                                        <Table.Cell width={4}>
                                            <div><Input value={isEdit && forwardingNumber} /></div>
                                            <div>Calls will be forwarded to this number.</div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={1}>
                                            Label
                                        </Table.Cell>
                                        <Table.Cell width={4}>
                                            <Input />
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={1}>
                                            Show In Office View
                                        </Table.Cell>
                                        <Table.Cell width={4}>
                                            <Checkbox checked />
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={1}>
                                            Play Message
                                        </Table.Cell>
                                        <Table.Cell width={4}>
                                            <div>
                                                <Select 
                                                    options={[
                                                        { key: 'No Message', value: 'No Message', text: 'No Message' }
                                                    ]}
                                                    value='No Message'
                                                    />
                                            </div>
                                            <div>Play this message before forwarding a call.</div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={1}>
                                            Change CallerID
                                        </Table.Cell>
                                        <Table.Cell width={4}>
                                            <div><Input /></div>
                                            <p>
                                                Change the CallerID number for the forwarded call.
                                                <br/><br/>
                                                Leave blank to keep the CallerID the same.
                                                <br/><br/>
                                                Check This Box to use the number dialed. <Checkbox />
                                            </p>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={1}>
                                            Ring For
                                        </Table.Cell>
                                        <Table.Cell width={4}>
                                            <Select 
                                                options={[
                                                    { key: '30', value: '30', text: '30' }
                                                ]}
                                                value='30'
                                                /> Seconds
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={1}>
                                            
                                        </Table.Cell>
                                        <Table.Cell width={4}>
                                            {
                                                isEdit ? (
                                                    <React.Fragment>
                                                        <ButtonLink>Update</ButtonLink>
                                                        &nbsp;|&nbsp;
                                                        <ButtonLink>Cancel</ButtonLink>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <ButtonLink>Add Forwarding Number</ButtonLink>
                                                        &nbsp;|&nbsp;
                                                        <ButtonLink>Cancel</ButtonLink>
                                                    </React.Fragment>
                                                )
                                            }
                                        </Table.Cell>
                                    </Table.Row>
                                        {
                                            isEdit && (
                                                <Table.Row>
                                                    <Table.Cell width={1}>
                                                        <ControlledPopup 
                                                            trigger={<ButtonLink>Delete This Forwarding Number</ButtonLink>}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell width={4}>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        }
                                </Table.Body>
                            </Table>
                        </React.Fragment>
                    } />
                </div>
                <Dimmer active={loading} inverted page={true}>
                    <Loader inverted></Loader>
                </Dimmer>
            </React.Fragment>
        )
    }
}

export default EditForwardingNumber;
