import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function contactEmailsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CONTACT_EMAIL, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingEmails: true, email: [], count: 0 };
        case getNamedAction(ACTION_NAMES.CONTACT_EMAIL, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, email: action.data.data, count: action.data.count, isReadingEmails: false };
        case getNamedAction(ACTION_NAMES.CONTACT_EMAIL, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingEmails: false };

        case getNamedAction(ACTION_NAMES.CONTACT_EMAIL, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingEmail: true };
        case getNamedAction(ACTION_NAMES.CONTACT_EMAIL, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingEmail: false };
        case getNamedAction(ACTION_NAMES.CONTACT_EMAIL, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingEmail: false };

        case getNamedAction(ACTION_NAMES.CONTACT_EMAIL, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingEmail: true };
        case getNamedAction(ACTION_NAMES.CONTACT_EMAIL, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingEmail: false };
        case getNamedAction(ACTION_NAMES.CONTACT_EMAIL, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpadtingEmail: false };

        case getNamedAction(ACTION_NAMES.CONTACT_EMAIL, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, emailBeingDeleted: action.subId };
        case getNamedAction(ACTION_NAMES.CONTACT_EMAIL, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, emailBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.CONTACT_EMAIL, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, emailBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.CONTACT_EMAIL_OPTIONS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, emailOptions: { ...state.emailOptions, [action.id]: { isReading: true, options: [] } } };
        case getNamedAction(ACTION_NAMES.CONTACT_EMAIL_OPTIONS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, emailOptions: { ...state.emailOptions, [action.id]: { isReading: false, options: action.data.data || [] } } };
        case getNamedAction(ACTION_NAMES.CONTACT_EMAIL_OPTIONS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, emailOptions: { ...state.emailOptions, [action.id]: { isReading: false, options: [] } } };

        default:
            return state;
    }
}

export default contactEmailsReducer;
