import { VALIDATORS, yup } from "dyl-components";

const NAME_VALIDATOR = yup.string().no_whitespace_only().no_excessive_whitespaces().minlength(2).maxlength(64).simple_alphanumeric();

export const sequenceValidationSchema = {
    name: NAME_VALIDATOR.required('This field is required'),
    description: VALIDATORS.DESCRIPTION(),
    actions: yup.array().of(yup.object({
        type: yup.string().oneOf(['call', 'text', 'email']).required('This field is required'),
        deliver: yup.string().oneOf(['instant', 'minutes', 'hours', 'days', 'weeks', 'months']).required('This field is required'),
        timeamount: yup.number().nullable(true).when("deliver", {
            is: deliver => deliver !== 'instant',
            then: schema => schema.required('This field is required')
        }),
        time: yup.string().nullable(true).when("deliver", {
            is: deliver => deliver !== 'instant' && deliver !== 'minutes' && deliver !== 'hours',
            then: schema => schema.required('This field is required')
        })
    }))
}
