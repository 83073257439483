import ACTION_NAMES from 'actions/ACTION_NAMES';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import initialState from './initialState';

function ringGroupsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.RING_GROUPS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingRingGroups: true };
        case getNamedAction(ACTION_NAMES.RING_GROUPS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingRingGroups: false, ring_groups: action.data.data || [], count: action.data.count || 0 };
        case getNamedAction(ACTION_NAMES.RING_GROUPS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingRingGroups: false };

        case getNamedAction(ACTION_NAMES.RING_GROUPS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingRingGroups: true };
        case getNamedAction(ACTION_NAMES.RING_GROUPS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingRingGroups: false };
        case getNamedAction(ACTION_NAMES.RING_GROUPS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingRingGroups: false };
        default:
            return state;
    }
}

export default ringGroupsReducer;
