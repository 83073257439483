import React, { useEffect } from 'react';
import { Table, EllipsisDropdown, Notification, STATUS_TYPES, ControlledPopup, Editable, TableLoader, generateResolver, yup, ButtonLink } from 'dyl-components';
import { Icon, Dropdown, Header, Form, Popup } from 'semantic-ui-react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { StringUtils } from 'utils';

import dataRecordActions from 'actions/data_record';

export const DataRecordRow = ({
    data_record,
    isEditing,
    onCancelEdit,
    onEdit,
    refresh
}) => {
    const PENDING_ELLIPSIS_OPTIONS = [
        {
            id: 3,
            text: 'Delete',
            icon: 'fas fa-trash-alt',
            displaysPopup: true,
            popupProps: {
                header: 'Are you sure?',
                onConfirm: async () => {
                    try {

                    } catch (e) {
                        console.log(e);
                        Notification.alert('Failed to delete data record', STATUS_TYPES.ERROR, true);
                    }
                }
            }
        }
    ];
    const POST_ELLIPSIS_OPTIONS = [
        {
            id: 1,
            text: 'View Post',
            icon: 'fas fa-table-columns',
            as: Link,
            to: '/settings/post-parsing-template',
        },
        {
            id: 2,
            text: 'Edit Mapping',
            icon: 'fas fa-sitemap',
            as: Link,
            to: '/settings/data-mapping/email',

        },
    ];
    const EMAIL_ELLIPSIS_OPTIONS = [
        {
            id: 1,
            text: 'Edit parse rule',
            icon: 'fas fa-table-columns',
            as: Link,
            to: '/settings/email-parsing-template',
        },
        {
            id: 2,
            text: 'Edit Mapping',
            icon: 'fas fa-sitemap',
            as: Link,
            to: '/settings/data-mapping/email',

        },
    ];

    const {dataRecordBeingUpdated} = useSelector(state => state.data_record)

    const DropdownItem = ({ text, icon, displaysPopup, popupProps, ...otherProps }) => {
        const DropdownModel = () => {
            return (
                <Dropdown.Item text={<React.Fragment>
                    <Icon name={icon} />
                    {text}
                </React.Fragment>}
                    style={{ cursor: 'pointer' }}
                    {...otherProps}
                />
            );
        }

        if (displaysPopup === true) {
            return (
                <ControlledPopup
                    header={
                        <Header as='h4' textAlign='center'>
                            {popupProps.header}
                            <Header.Subheader style={{ marginTop: 5 }}>
                                {popupProps.subheader}
                            </Header.Subheader>
                        </Header>
                    }
                    trigger={
                        DropdownModel()
                    }
                    onConfirm={popupProps.onConfirm}
                />
            )
        } else {
            return (
                <DropdownModel />
            );
        }
    }

    const { control, formState: { isValid, isDirty }, handleSubmit, reset, setError } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: data_record.name || ""
        },
        resolver: generateResolver({
            name: yup.string().required("This is a required field").maxlength(64).simple_alphanumeric().no_excessive_whitespaces()
        })
    });

    useEffect(() => {
        if (!isEditing) {
            reset();
        }
    }, [isEditing, reset]);

    const dispatch = useDispatch();

    const onConfirmEdit = async ({ name }) => {
        try {
            await dispatch(dataRecordActions.update(data_record.id, { name: name?.trim() }));
            Notification.alert('Successfully updated data record', STATUS_TYPES.SUCCESS);
            refresh();
            onCancelEdit();
        } catch (e) {
            const nameAlreadyExists = e?.code === 409;
            const message = nameAlreadyExists ? 'Data record name already exists' : 'Failed to update data record';
            Notification.alert(message, STATUS_TYPES.ERROR);
            if (nameAlreadyExists) {
                setError('name', { message });
            }
        }
    }

    const isUpdating = dataRecordBeingUpdated === data_record.id;
    const accepted = data_record.results?.accepted;
    const rejected = data_record.results?.rejected;
    const ELLIPSIS_OPTIONS = !(accepted || rejected) ? PENDING_ELLIPSIS_OPTIONS : data_record.method === "Email" ? EMAIL_ELLIPSIS_OPTIONS : POST_ELLIPSIS_OPTIONS; 
    return (
        !isUpdating ? (
            <Table.Row>
                <Table.Cell width={5}>
                    <Editable
                        value={data_record.name}
                        isEditing={isEditing}
                        onCancelEdit={() => {
                            reset();
                            onCancelEdit();
                        }}
                        onConfirmEdit={handleSubmit(onConfirmEdit, (errors, e) => {
                            console.log(errors, e);
                        })}
                        submitDisabled={!isDirty || !isValid}
                        onEdit={onEdit}
                    >
                        <Form size='tiny' className='DataProviders__editable'>
                            <Controller
                                name='name'
                                control={control}
                                render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                                        <Form.Input
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                            error={error && error.message}
                                            placeholder='Enter new name'
                                        />
                                )}
                            />
                        </Form>
                    </Editable>
                </Table.Cell>
                <Table.Cell>
                    {data_record.module}
                </Table.Cell>
                <Table.Cell>
                    <div>{data_record.method} {data_record.method === "Post" && 
                        <Popup
                            trigger={<ButtonLink role="button" tabIndex="0" onClick={StringUtils.copy(data_record.code)}>{`[ ${data_record.code} ]`} <Icon className='fas fa-copy' /></ButtonLink>}
                            content='Copied!'
                            on='click'
                            closeOnTriggerMouseLeave
                            position='bottom center'
                            inverted
                        />
                    }
                    </div>
                    {data_record.method === "Email" && ([
                        <div>
                            <small>From 
                                {` [ ${data_record.parser.from || ''} ]`}
                            </small>
                        </div>,
                        <div>
                            <small>Subject line [ {data_record.parser.subject} ]</small>
                        </div>
                    ])}
                </Table.Cell>
                <Table.Cell>
                    {data_record.product_interest}
                </Table.Cell>
                <Table.Cell>
                    {(accepted || rejected) ? 
                        <>
                            <div>
                                <Icon name='circle' className={`RowStatus__Accepted`}/>{accepted || 0} Accepted
                            <div>
                            </div>
                                <Icon name='circle' className={`RowStatus__Rejected`}/>{rejected || 0} Rejected
                            </div>
                        </>
                        :
                        <div><Icon className='fas fa-circle' color='grey'/> Pending {data_record.method}s</div>
                    }
                </Table.Cell>
                <Table.Cell>
                    <EllipsisDropdown>
                        {ELLIPSIS_OPTIONS.map(({ text, icon, displaysPopup, popupProps, to, ...otherProps }, idx) => {
                            return (
                                <DropdownItem
                                    key={idx}
                                    text={text}
                                    icon={icon}
                                    displaysPopup={displaysPopup}
                                    popupProps={popupProps}
                                    style={{ cursor: 'pointer' }}
                                    to={`${to}/${data_record.id}`}
                                    {...otherProps} 
                                />     
                            );
                        })}
                    </EllipsisDropdown>
                </Table.Cell>
            </Table.Row>
        ) : (
            <TableLoader isLoading colspan={6} />
        )
    );
}