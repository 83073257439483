import React from 'react';
import { Icon, Button, Popup, Select, Grid } from 'semantic-ui-react';
import { TableWithHeader, ControlledPopup, Table } from 'dyl-components';
import { MOCK_LEAD_CONNECT_CALLBACKS } from '__mocks__/mockData';
import { STATUS_TYPES, Notification } from 'dyl-components';

class LeadConnectCallbacksRowForm extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            callIn: 'Minute',
            howMany: '1',
            atTime: 'N/A',
        }
    }

    onChange = (_, {name, value}) => {
        this.setState({
            [name]: value
        })
    }

    render(){
        const { isAddCallOpen, onCancelClick, onConfirmClick } = this.props;
        const { callIn, howMany, atTime } = this.state;

        return(
            <Table.Row style={{display: isAddCallOpen ? 'table-row' : 'none'}}>
                <Table.Cell>
                    <Select options={[
                            { key: 'Days', value: 'Days', text: 'Days' }
                        ]} 
                        value={callIn}
                        onChange={this.onChange}
                        name='callIn'/>
                </Table.Cell>
                <Table.Cell>
                    <Select options={[
                            { key: '1', value: '1', text: '1' }
                        ]} 
                        value={howMany}
                        onChange={this.onChange}
                        name='howMany'/>
                </Table.Cell>
                <Table.Cell>
                    <Select options={[
                            { key: '8:00 am', value: '8:00 am', text: '8:00 am' }
                        ]} 
                        value={atTime}
                        onChange={this.onChange}
                        name='atTime'/>
                </Table.Cell>
                <Table.Cell>
                    <Popup content='Cancel' inverted trigger={<Icon name='close' color='red' link onClick={onCancelClick} />} />
                    <Popup content='Confirm' inverted trigger={<Icon name='checkmark' color='blue' link 
                        onClick={() => onConfirmClick(this.state)} />} />
                </Table.Cell>
            </Table.Row>
        )
    }
}

const LeadConnectCallbacksRowViewInfo = ({
    id,
    callIn,
    howMany,
    atTime,

    isEditMode,
    onDisableEditMode,
    onDeleteClick,
    onEditClick
}) => (
    <Table.Row>
        <Table.Cell>{callIn}</Table.Cell>
        <Table.Cell>{howMany}</Table.Cell>
        <Table.Cell>{atTime}</Table.Cell>
        <Table.Cell>
            <ControlledPopup 
                trigger={<Icon name='trash alternate' color='grey'  link/>}
                onConfirm={() => onDeleteClick(id)}
                onCancel={onDisableEditMode}
            />
            <Popup content='Edit' inverted trigger={<Icon name='pencil' color='grey' link
                onClick={() => onEditClick(id)} />} />
            {/* <Popup content='Add Next Call' inverted trigger={<Icon name='add circle' color='grey' link />} /> */}
        </Table.Cell>
    </Table.Row>
)

class LeadConnectCallbacksRowViewEdit extends React.Component{
    constructor(props){
        super(props);

        const {
            callIn,
            howMany,
            atTime,
        } = props;

        this.state = {
            callIn,
            howMany,
            atTime,
        }
    }

    onChange = (_, {name, value}) => {
        this.setState({
            [name]: value
        })
    }

    render(){
        const {
            callIn,
            howMany,
            atTime,
        } = this.state;

        const { id, onEditCancelClick, onEditConfirmClick } = this.props;

        return(
            <Table.Row>
                <Table.Cell>
                    <Select options={[
                            { key: 'Days', value: 'Days', text: 'Days' },
                            { key: 'Weeks', value: 'Weeks', text: 'Weeks' }
                        ]} 
                        value={callIn}
                        onChange={this.onChange}
                        name='callIn'/>
                </Table.Cell>
                <Table.Cell>
                    <Select options={[
                            { key: '1', value: '1', text: '1' }
                        ]} 
                        value={howMany}
                        onChange={this.onChange}
                        name='howMany'/>
                </Table.Cell>
                <Table.Cell>
                    <Select options={[
                            { key: '8:00 am', value: '8:00 am', text: '8:00 am' }
                        ]} 
                        value={atTime}
                        onChange={this.onChange}
                        name='atTime'/>
                </Table.Cell>
                <Table.Cell>
                    <Popup content='Cancel' inverted trigger={<Icon name='close' color='red' link 
                        onClick={() => onEditCancelClick(id)} />} />
                    <Popup content='Confirm' inverted trigger={<Icon name='checkmark' color='blue' link 
                        onClick={() => onEditConfirmClick(id, {...this.state})} />} />
                </Table.Cell>
            </Table.Row>
        )
    }
}

class TableSingleLine extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            isEditMode: false
        }
    }

    onDisableEditMode = () => {
        this.setState({
            isEditMode: false,
        })
    }

    formLeadConnectCallbacksItems = () => {
        const { leadConnectCallbacksItems, onDeleteClick, onEditClick, onEditCancelClick, onEditConfirmClick } = this.props;
        const { isEditMode } = this.state;

        return leadConnectCallbacksItems.map(leadConnectCallbacksItem => {
            if(leadConnectCallbacksItem.edit){
                return(
                    <LeadConnectCallbacksRowViewEdit
                        id={leadConnectCallbacksItem.id}
                        key={leadConnectCallbacksItem.id} 
                        callIn={leadConnectCallbacksItem.callIn}
                        howMany={leadConnectCallbacksItem.howMany}
                        atTime={leadConnectCallbacksItem.atTime}
        
                        onEditCancelClick={onEditCancelClick}
                        onEditConfirmClick={onEditConfirmClick}
                    />
                )
            }else{
                return(
                    <LeadConnectCallbacksRowViewInfo
                        id={leadConnectCallbacksItem.id}
                        key={leadConnectCallbacksItem.id} 
                        callIn={leadConnectCallbacksItem.callIn}
                        howMany={leadConnectCallbacksItem.howMany}
                        atTime={leadConnectCallbacksItem.atTime}
        
                        isEditMode={isEditMode} 
                        onDisableEditMode={this.onDisableEditMode}
                        onDeleteClick={onDeleteClick}       
                        onEditClick={onEditClick}
                    />
                )
            }
        })
    }

    render(){
        const {isAddCallOpen, onCancelClick, onConfirmClick} = this.props;
        const LeadConnectCallbacksItems = this.formLeadConnectCallbacksItems();

        return(
            <Table singleLine>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Call In</Table.HeaderCell>
                        <Table.HeaderCell>How Many</Table.HeaderCell>
                        <Table.HeaderCell>At Time</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            
                <Table.Body>
                    <LeadConnectCallbacksRowForm
                        isAddCallOpen={isAddCallOpen} 
                        onCancelClick={onCancelClick}
                        onConfirmClick={onConfirmClick} />
                    {LeadConnectCallbacksItems}
                </Table.Body>
            </Table>
        )
    }
}

class LeadConnectCallbacks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAddCallOpen: false,
            leadConnectCallbacksItems: MOCK_LEAD_CONNECT_CALLBACKS
        }
    }

    onAddCallClick = () => {
        this.setState({
            isAddCallOpen: true
        })
    }

    onCancelClick = () => {
        this.setState({
            isAddCallOpen: false
        })
    }

    onConfirmClick = (data) => {
        const { leadConnectCallbacksItems } = this.state;

        data.id = leadConnectCallbacksItems.length + 1;

        this.setState({
            leadConnectCallbacksItems: [
                ...this.state.leadConnectCallbacksItems,
                data
            ],
            isAddCallOpen: false
        });

        Notification.alert('Lead Connect Callback Added', STATUS_TYPES.SUCCESS, true);
    }

    onDeleteClick = (id) => {
        let leadConnectCallbacksItems = this.state.leadConnectCallbacksItems.slice(0);
        let indexOfLeadConnectCallbacksItemToDelete = leadConnectCallbacksItems.findIndex(leadConnectCallbacksItem => leadConnectCallbacksItem.id === id);
        
        if (indexOfLeadConnectCallbacksItemToDelete !== -1) {
            leadConnectCallbacksItems.splice(indexOfLeadConnectCallbacksItemToDelete, 1);
            Notification.alert('Call Removed', STATUS_TYPES.SUCCESS, true);
            this.setState({ leadConnectCallbacksItems });
        }
    }

    onEditClick = (id) => {
        let leadConnectCallbacksItems = this.state.leadConnectCallbacksItems.slice(0);
        let indexOfleadConnectCallbacksItem = leadConnectCallbacksItems.findIndex(leadConnectCallbacksItem => leadConnectCallbacksItem.id === id);
        
        if (indexOfleadConnectCallbacksItem !== -1) {
            leadConnectCallbacksItems[indexOfleadConnectCallbacksItem].edit = true;
            this.setState({ leadConnectCallbacksItems });
        }
    }

    onEditCancelClick = (id) => {
        let leadConnectCallbacksItems = this.state.leadConnectCallbacksItems.slice(0);
        let indexOfleadConnectCallbacksItem = leadConnectCallbacksItems.findIndex(leadConnectCallbacksItem => leadConnectCallbacksItem.id === id);
        
        if (indexOfleadConnectCallbacksItem !== -1) {
            leadConnectCallbacksItems[indexOfleadConnectCallbacksItem].edit = false;
            this.setState({ leadConnectCallbacksItems });
        }
    }

    onEditConfirmClick = (id, data) => {
        let leadConnectCallbacksItems = this.state.leadConnectCallbacksItems.slice(0);
        let indexOfleadConnectCallbacksItem = leadConnectCallbacksItems.findIndex(leadConnectCallbacksItem => leadConnectCallbacksItem.id === id);
        
        if (indexOfleadConnectCallbacksItem !== -1) {
            leadConnectCallbacksItems[indexOfleadConnectCallbacksItem] = data;
            this.setState({ leadConnectCallbacksItems });
        }
    }

    render() {
        const { isAddCallOpen, leadConnectCallbacksItems } = this.state;

        return (
            <React.Fragment>
                <TableWithHeader 
                    header={
                        <Grid verticalAlign='middle'>
                            <Grid.Column width={6}>
                            <strong>Lead Connect Callbacks</strong>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign='right' floated='right'>
                                <Button size='tiny' basic inverted color='white' onClick={this.onAddCallClick}>Add Call</Button>
                            </Grid.Column>
                        </Grid>
                    }
                    table={
                        <TableSingleLine 
                            isAddCallOpen={isAddCallOpen}
                            onCancelClick={this.onCancelClick}
                            onConfirmClick={this.onConfirmClick}
                            leadConnectCallbacksItems={leadConnectCallbacksItems}
                            onDeleteClick={this.onDeleteClick}
                            onEditClick={this.onEditClick}
                            onEditCancelClick={this.onEditCancelClick}
                            onEditConfirmClick={this.onEditConfirmClick}/>
                    }
                    className='TableWithHeader' />
            </React.Fragment>
        )
    }
}

export default LeadConnectCallbacks;
