import { generateResolver, Notification, SettingsFooter, STATUS_TYPES, yup } from 'dyl-components';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, Portal } from 'semantic-ui-react';
import { STATES } from 'shared/constants/STATES';
import TerritoryForm from 'shared/forms/TerritoryForm';
import useWidthListener from 'shared/SettingsFooter/useWidthListener';
import useWindowWidth from 'shared/SettingsFooter/useWindowWidth';
import { useDispatch, useSelector } from 'react-redux';
import { MathUtils } from 'utils';
import territoryActions from 'actions/territory';
import { useNavigate } from 'react-router-dom';

const CreateTerritory = () => {
    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();

    const dispatch = useDispatch();

    const { control, formState: { isValid, isDirty }, handleSubmit, clearErrors, setError, setValue, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            states: [],
            counties: [],
            zips: [],
            teams: []
        },
        resolver: generateResolver({
            name: yup.string().no_whitespace_only().no_excessive_whitespaces().maxlength(64).simple_alphanumeric().required('This field is required'),
            states: yup.array().of(yup.string().oneOf(STATES.map(({ key }) => key))).test('at_least_one_criterion_required', 'There should be at least one criteria for a territory', function (value) {
                return value.length || this.parent.zips?.length || this.parent.counties?.length;
            }),
            counties: yup.array().of(yup.string()).test('at_least_one_criterion_required', 'There should be at least one criteria for a territory', function (value) {
                return value.length || this.parent.zips?.length || this.parent.states?.length;
            }),
            zips: yup.array().of(yup.string()).test("valid_zip", "Please remove all previously used or invalid zip codes", zips => {
                if (zips.length) {
                    return zips.every(zip => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip));
                }
                return true;
            }).test('at_least_one_criterion_required', 'There should be at least one criteria for a territory', function (value) {
                return value.length || this.parent.states?.length || this.parent.counties?.length;
            })
        })
    });

    const navigate = useNavigate();

    const onCreate = async (data) => {
        const {
            name,
            states: state,
            teams,
            zips: zip,
            counties
        } = data;

        try {
            await dispatch(territoryActions.createTerritory({
                name,
                state,
                teams,
                zip,
                county: counties.map(county => {
                    const [countyName, state] = county.split('(');
                    return {
                        county: countyName.trim(),
                        state: state.trim().replace(')', '')
                    }
                })
            }));
            Notification.alert('Successfully created territory!', STATUS_TYPES.SUCCESS);
            navigate('/settings/territories');
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to create territory', STATUS_TYPES.ERROR);
        }
    }

    const isCreating = useSelector(state => state.territory.isCreatingTerritory);
    const isCheckingDuplicate = useSelector(state => state.territory.isCheckingDuplicate);
    const isCheckingValidity = useSelector((state) => state.territory.isCheckingValidity);

    return (
        <>
            <TerritoryForm
                control={control}
                loading={isCreating}
                clearErrors={clearErrors}
                setError={setError}
                setValue={setValue}
                watch={watch}
            />
            <Portal open>
                <SettingsFooter
                    style={{ width: MathUtils.calculatePercentage(windowWidth, windowWidth - width) }}
                    className={`Webform__menu`}
                    rightOptions={(
                        <Form.Group>
                            <Form.Button
                                disabled={(!isValid || !isDirty || isCreating || isCheckingDuplicate || isCheckingValidity)}
                                onClick={handleSubmit(onCreate)}
                                primary
                                loading={isCreating || isCheckingDuplicate || isCheckingValidity}
                            >
                                Save
                            </Form.Button>
                        </Form.Group>
                    )}
                />
            </Portal>
        </>
    )
}

export default CreateTerritory;
