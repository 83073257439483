import { NestedDropdown, Notification, Person, STATUS_TYPES, VALIDATORS, generateResolver, yup } from 'dyl-components';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import customerPipelineActions from 'actions/customer_pipeline';
import pipelineActions from "actions/pipeline";
import sequenceTasksActions from 'actions/sequence_tasks';
import contactSequenceActions from 'actions/contact_sequence';
import sequencesActions from 'actions/sequences';
import { useDispatch, useSelector } from 'react-redux';
import ChangePipelineForm from 'shared/forms/ChangePipelineForm';
import { Form } from 'semantic-ui-react';

const ChangeCustomerPipelineForm = ({ account_id, onClose, refresh, current_pipeline_category, pipeline_id }) => {
    const pipelineOwnerId = useSelector(state => state.account.account.customer.customer_pipeline.assocciated_contact.id);
    const { formState: { isDirty, isValid }, handleSubmit, control, reset, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            lost_reason: null,
            lost_type: null,
            notes: '',
            person_id: pipelineOwnerId
        },
        resolver: generateResolver({
            pipeline: yup.string().required('This field is required'),
            stage: yup.string().required('This field is required'),
            notes: VALIDATORS.DESCRIPTION(),
            person_id: yup.number().typeError("This field is required").required('This field is required')
        })
    });

    const dispatch = useDispatch();

    const watchedPersonId = watch("person_id");

    useEffect(() => {
        dispatch(pipelineActions.getPipelineCategories({ account_stage: 'customer' }));
        dispatch(pipelineActions.readCloseReasons({ account_stage: 'customer', close_reason_type: 'pipeline' }))
    }, [dispatch]);

    useEffect(() => {
        if (watchedPersonId) {
            dispatch(sequenceTasksActions.readContactTasks(watchedPersonId));
        }
    }, [watchedPersonId, dispatch]);

    const { pipelineCategories, isReadingCategories, isChangingPipeline, isReadingCloseReasons, associateContactOptions, isReadingAssociateContactOptions, isReadingSequenceContactTasks, selectedSequenceId, contactSequenceTasks } = useSelector(state => ({
        pipelineCategories: state.pipeline.categories.filter(category => category.id !== current_pipeline_category).map(category => ({
            key: category.id,
            value: category.id,
            text: category.name,
            stages: category.stages?.map(stage => ({
                key: stage.id,
                value: stage.id,
                text: stage.name,
                sequence_id: stage.sequence_id
            })) || []
        })),
        isReadingCategories: state.pipeline.isReadingCategories,
        isChangingPipeline: state.customer_pipeline.accountBeingUpdated || state.contact_sequence.isRemovingSequence || state.sequences.isAddingContactToSequence,
        isReadingCloseReasons: state.pipeline.isReadingCloseReasons,
        associateContactOptions: state.account.contactsForPinning.map(contact => {
            const name = `${contact.first_name} ${contact.last_name}`;
            return ({
                key: contact.id,
                value: contact.id,
                text: name,
                content: (
                    <Person username={name} email={contact.email.email || ''} />
                ),
                pinned: contact.pinned
            });
        }),
        isReadingAssociateContactOptions: state.account.isReadingContactsForPinning,

        contactSequenceTasks: state.sequence_tasks.selectedSequenceTasks,
        isReadingSequenceContactTasks: state.sequence_tasks.isReadingSequenceContactTasks,
        selectedSequenceId: state.sequence_tasks.selectedSequenceId
    }));

    const lostReasons = useSelector(state => state.pipeline.close_reasons.map(reason => ({
        key: reason.id,
        value: reason.id,
        text: reason.name,
        options: (
            reason.close_types.map(type => ({
                key: type.id,
                value: type.id,
                text: type.name
            })) || []
        )
    })) || [])


    const onChangePipeline = async (data) => {
        try {
            const { stage, pipeline, notes, person_id } = data;
            const areSequenceTasksCompleted = contactSequenceTasks.length === 0 || contactSequenceTasks.every(task => task.status === 'complete' || task.status === 'canceled');
            const category = pipelineCategories.find(category => category.value === Number(pipeline));
            const newStage = category?.stages.find(categoryStage => categoryStage.value === Number(stage));
            await dispatch(customerPipelineActions.update(account_id, {
                pipeline_stage_id: Number(stage),
                person_id: person_id,
                pipeline_id,
                close_reason_notes: notes,
                previous_sequence_complete: areSequenceTasksCompleted
            }, { action: 'move' }));
            if (selectedSequenceId) {
                await dispatch(contactSequenceActions.removeFromContact(Number(person_id)));
            }
            if (newStage.sequence_id) {
                await dispatch(sequencesActions.addToSequence({ contact_id: Number(person_id) }, null, newStage.sequence_id));
            }
            Notification.alert('Successfully changed pipeline!', STATUS_TYPES.SUCCESS);
            if (onClose) {
                onClose();
            }
            if (refresh) {
                refresh();
            }
            reset();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to change pipeline', STATUS_TYPES.ERROR);
        }
    }

    return (
        <ChangePipelineForm
            className='AccountPage__ChangePipelineForm'
            control={control}
            isReadingCategories={isReadingCategories || isReadingSequenceContactTasks}
            isSaving={isChangingPipeline}
            isSubmitDisabled={!isDirty || !isValid || isChangingPipeline}
            onSubmit={handleSubmit(onChangePipeline)}
            pipelineCategories={pipelineCategories}
            changeReasonsField={(
                <>
                    <Controller
                        name={'person_id'}
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Field
                                control='div'
                                required
                                label={'Associate Contact'}
                            >
                                <Form.Dropdown
                                    className='AssociateContactDropdown'
                                    placeholder='Select Associate Contact'
                                    selectOnBlur={false}
                                    selection
                                    options={[]}
                                    value={value}
                                    onChange={(_, { value: contact_id }) => {
                                        onChange({ target: { name, value: contact_id } });
                                    }}
                                    compact
                                    loading={isReadingAssociateContactOptions}
                                    error={error?.message}
                                    disabled={associateContactOptions?.length <= 1}
                                    {...value ? { text: associateContactOptions.find(option => option.value === value)?.content, icon: null } : {}}
                                />
                            </Form.Field>
                        )}
                    />
                    <Controller
                        name='lost_reason'
                        control={control}
                        render={({ field: { name: parentName, value: parentValue, onChange: parentOnChange } }) => (
                            <Controller
                                name='lost_type'
                                control={control}
                                render={({ field: { name, value, onChange } }) => (
                                    <Form.Field
                                        control={NestedDropdown}
                                        allowedToSelectParentOnly
                                        child_value={value}
                                        parent_value={parentValue}
                                        loading={isReadingCloseReasons}
                                        nested_options={lostReasons}
                                        onChange={(_, { parent_value, child_value }) => {
                                            parentOnChange({ target: { name: parentName, value: parent_value } });
                                            onChange({ target: { name, value: child_value } });
                                        }}
                                        placeholder={`Select Change Reason/Type`}
                                        display_parent
                                        selection
                                        label={`Change Reason/Type`}
                                        pointing='top'
                                    />
                                )}
                            />
                        )}
                    />
                </>
            )}
        />
    );
}

export default ChangeCustomerPipelineForm;
