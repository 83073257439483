import React from 'react';
import { useLocation, useParams } from "react-router";

// USE ONLY FOR CLASS BASED COMPONENTS
// THAT DIFFICULT (IF NOT IMPOSSIBLE) TO CONVERT
// TO FUNCTIONAL COMPONENTS
export const withRouter = WrappedComponent => props => {
    const location = useLocation();
    const params = useParams();

    return (
        <WrappedComponent
            {...props}
            location={location}
            params={params}
        />
    );
};
