import { generateCreateActionCreator, generateDeleteActionCreator, generateReadActionCreator, generateUpdateActionCreator } from '../crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import routes from 'actions/routes';

export default {
    readProductCategories: generateReadActionCreator(routes.API_PRODUCT_CATEGORY, ACTION_NAMES.PRODUCT_CATEGORY),
    addProductCategory: generateCreateActionCreator(routes.API_PRODUCT_CATEGORY, ACTION_NAMES.PRODUCT_CATEGORY),
    updateProductCategory: generateUpdateActionCreator(routes.API_PRODUCT_CATEGORY, ACTION_NAMES.PRODUCT_CATEGORY),
    deleteProductCategory: generateDeleteActionCreator(routes.API_PRODUCT_CATEGORY, ACTION_NAMES.PRODUCT_CATEGORY),
    updateProductCatgories: generateUpdateActionCreator(routes.API_PRODUCT_CATEGORY, ACTION_NAMES.PRODUCT_CATEGORIES, () => {}, () => {}, null, true)
}
