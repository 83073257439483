import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import PHONE_ICON from 'shared/constants/PHONE_ICON';
import { PhoneUtil } from 'utils';

const Phone = ({ phone, phone_type }) => (
    <React.Fragment>
        <Popup
            trigger={<Icon name={PHONE_ICON[phone_type] || 'phone'} color='grey' />}
            content={phone_type}
            position='top center'
            inverted
        />
        <p className='ContactInfo__contact'>{PhoneUtil.formatPhoneNumber(phone)}</p>
    </React.Fragment>
);

export default Phone;
