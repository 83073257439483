import React, { useContext, useRef, useState } from 'react';
import { Grid, Icon, Dropdown, Ref } from 'semantic-ui-react';
import { DateTimeUtils, EllipsisDropdownPortal, Table, ClippedContent, ButtonLink, Person } from 'dyl-components';

import {
    KEY_CREATED_ON, KEY_CREATED_BY, KEY_LAST_MODIFIED_ON, KEY_LAST_MODIFIED_BY, KEY_START_END_DATE,
    KEY_TYPE, KEY_STATUS, KEY_CATEGORY_SUBCATEGORY, KEY_SHARED, KEY_TOTAL_CONTACTS,
    KEY_REACHED, KEY_UNREACHED, KEY_OWNER, KEY_ACTIONS, KEY_CAMPAIGN_CONVERSION
} from './constants';
import { Link, useNavigate } from 'react-router-dom';
import { CampaignsContext } from './CampaignsContextProvider';
import { StringUtils } from 'utils';

const DropdownItem = ({ text, icon, ...otherProps }) => {
    return (
        <Dropdown.Item
            text={
                <React.Fragment>
                    <Icon name={icon} />
                    {text}
                </React.Fragment>
            }
            style={{ cursor: 'pointer' }}
            {...otherProps}
        />
    );
}

const CampaignsRow = ({ user_id, campaign, isColumnHidden }) => {
    const navigate = useNavigate();
    const [isCopied, setIsCopied] = useState(false);
    const { onSelectCampaign, state } = useContext(CampaignsContext);
    const { action } = state;
    const ref = useRef();

    const isCompanyCampaign = Object.keys(campaign.is_company_campaign).length > 1;
    const isExpired = campaign.status.toLowerCase() === 'expired';
    const isDeleted = campaign.status.toLowerCase() === 'deleted';
    const isEditable = (!isDeleted && !isExpired);

    return (
        <Table.Row {...(isDeleted && { className: 'CampaignsTable__Row--disabled' })}>
            {
                !isColumnHidden(KEY_CREATED_ON) &&
                <Table.Cell>
                    <div><b>{DateTimeUtils.formatEpoch(campaign.created, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{DateTimeUtils.formatEpoch(campaign.created, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
            }
            {
                !isColumnHidden(KEY_CREATED_BY) &&
                <Table.Cell>{campaign.created_by}</Table.Cell>
            }
            {
                !isColumnHidden(KEY_LAST_MODIFIED_ON) &&
                <Table.Cell>
                    <div><b>{campaign.last_modified_on && DateTimeUtils.formatEpoch(campaign.last_modified_on, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{campaign.last_modified_on && DateTimeUtils.formatEpoch(campaign.last_modified_on, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
            }
            {
                !isColumnHidden(KEY_LAST_MODIFIED_BY) &&
                <Table.Cell>{campaign.last_modified_by}</Table.Cell>
            }
            {
                !isColumnHidden(KEY_START_END_DATE) &&
                <Table.Cell>
                    <div>{campaign.is_company_campaign.start_time && (`${
                        DateTimeUtils.changeFormat(DateTimeUtils.convertUnixTimeToDate(
                            campaign.is_company_campaign?.start_time
                        ), "", DateTimeUtils.WORD_DATE_FORMAT, 'UTC')} -`)
                    }</div>
                    <div>{campaign.is_company_campaign.end_time && 
                    DateTimeUtils.changeFormat(DateTimeUtils.convertUnixTimeToDate(
                        campaign.is_company_campaign?.end_time
                    ), "", DateTimeUtils.WORD_DATE_FORMAT, 'UTC')}</div>
                </Table.Cell>
            }
            <Table.Cell>
                <ClippedContent popupContent={isCopied ? 'Copied!' : (campaign.id || '')} onClose={() => { setIsCopied(false) }} >
                    <ButtonLink
                        role="button"
                        tabIndex="0"
                        onClick={(e) => {
                            e.preventDefault();
                            setIsCopied(true);
                            navigator.clipboard.writeText(campaign.id);
                        }}
                    >
                        <Grid>
                            <Grid.Column width={7}>
                                {campaign.id}
                            </Grid.Column>
                            <Grid.Column width={1}>
                                <Icon link className='fas fa-copy' color="blue" />
                            </Grid.Column>
                        </Grid>
                    </ButtonLink>
                </ClippedContent>
            </Table.Cell>
            <Table.Cell disabled={isDeleted} className="CampaignsTable__campaign-name">
                <Link to={`/campaigns/${campaign.id}/dashboard`}>{campaign.name}</Link>
            </Table.Cell>
            {
                !isColumnHidden(KEY_TYPE) &&
                <Table.Cell>{campaign.type}</Table.Cell>
            }
            {
                !isColumnHidden(KEY_CAMPAIGN_CONVERSION) &&
                <Table.Cell>{StringUtils.capitalize(campaign.campaign_conversion)}</Table.Cell>
            }
            {
                !isColumnHidden(KEY_CATEGORY_SUBCATEGORY) &&
                <Table.Cell>
                    <div><b>{campaign.is_company_campaign?.campaign_category?.campaign_category_name}</b></div>
                    <div>{campaign.is_company_campaign?.campaign_category?.campaign_subcategory_name}</div>
                </Table.Cell>
            }
            {
                !isColumnHidden(KEY_STATUS) &&
                <Table.Cell className='StatusColumn'>
                    {<span className={`StatusColumn--${campaign.status.toLowerCase()}`}>{campaign.status}</span>}
                </Table.Cell>
            }
            {
                !isColumnHidden(KEY_SHARED) &&
                <Table.Cell>{isCompanyCampaign ? 'Company' : 'Personal'}</Table.Cell>
            }
            {
                !isColumnHidden(KEY_TOTAL_CONTACTS) &&
                <Table.Cell>{campaign.total_contacts || 0}</Table.Cell>
            }
            {
                !isColumnHidden(KEY_REACHED) &&
                <Table.Cell>{campaign.reached}</Table.Cell>
            }
            {
                !isColumnHidden(KEY_UNREACHED) &&
                <Table.Cell>{campaign.unreached}</Table.Cell>
            }
            {
                !isColumnHidden(KEY_OWNER) &&
                <Table.Cell><Person username={campaign.owner} isUsernameOnly /></Table.Cell>
            }
            {
                !isColumnHidden(KEY_ACTIONS) &&
                <Table.Cell>
                    <Ref innerRef={ref}>
                        <EllipsisDropdownPortal reference={ref} action={action}>
                            {isDeleted && (
                                <DropdownItem
                                    text='View'
                                    icon='fa-solid fa-eye'
                                    onClick={() => { navigate(`/campaigns/${campaign.id}/dashboard`) }}
                                />
                            )}
                            {isEditable && (
                                <DropdownItem
                                    text='Edit'
                                    icon='fa-solid fa-pencil'
                                    onClick={() => { navigate(`/campaign/${campaign.id}`) }}
                                />
                            )}
                            {(!isDeleted && isCompanyCampaign && isExpired) && (
                                <DropdownItem
                                    text='Reactivate'
                                    icon='fa-solid fa-door-open'
                                    disabled={user_id !== campaign.user_owner_id}
                                    onClick={() => { onSelectCampaign(campaign.id, 'reactivate', ref) }}
                                />
                            )}
                            {(!isDeleted) && (
                                <DropdownItem
                                    text='Delete'
                                    icon='fa-solid fa-trash'
                                    onClick={() => { onSelectCampaign(campaign.id, 'delete', ref) }}
                                />
                            )}
                        </EllipsisDropdownPortal>
                    </Ref>
                </Table.Cell>
            }
        </Table.Row>
    );
}

export default CampaignsRow;
