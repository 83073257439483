import { createContext, useState } from "react";

export const ContactContext = createContext();

const ContactProvider = ({ children }) => {
    const [config, setConfig] = useState({
        modalType: null,
        contact: null
    });

    const onOpenModal = (modalType, contact) => {
        setConfig({
            modalType,
            contact
        })
    }

    const onCloseModal = () => {
        setConfig({
            modalType: null,
            contact: null
        })
    }


    return (
        <ContactContext.Provider
            value={{
                config,
                onOpenModal,
                onCloseModal
            }}
        >
            {children}
        </ContactContext.Provider>
    );
}

export default ContactProvider;
