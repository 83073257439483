import React, { useEffect, useState } from 'react';
import { Segment, Divider, Breadcrumb, Dimmer, Loader } from 'semantic-ui-react';
import { ClippedContent, Notification, RecordProfile, STATUS_TYPES } from 'dyl-components';

import { Link, Outlet, useParams } from "react-router-dom";

import { PinnedAccounts } from './PinnedAccounts';
import { ProfileHeader } from './ProfileHeader';
import { RecordInfo } from './RecordInfo';
import { MasterAccountStatistics } from './MasterAccountStatistics';
import { MasterAccountTabs } from './MasterAccountTabs';
import { useDispatch, useSelector } from 'react-redux';

import { Error404, Error500 } from 'pages';

import masterAccountActions from 'actions/master_account';

import './index.scss';
import { DateTimeUtils } from 'dyl-components';
import StickyNotes from 'shared/StickyNotes';
import recentsActions from 'actions/recents';
import { FAVORITE_TYPE } from 'shared/constants/FAVORITE_TYPE';

const MasterAccount = () => {
    const [isSidePanelOpen, setSidePanelOpen] = useState(true);
    const [isDetailsSectionOpen] = useState(true);

    const { master_account, masterAccountError, masterAccountBeingRead } = useSelector(state => ({
        master_account: state.master_account.master_account,
        masterAccountBeingRead: state.master_account.masterAccountBeingRead,
        masterAccountError: state.master_account.masterAccountError
    }));

    const master_account_id = Number(useParams().master_account_id);
    const dispatch = useDispatch();

    const refresh = () => {
        dispatch(masterAccountActions.readOne(master_account_id));
    }

    useEffect(() => {
        dispatch(masterAccountActions.readOne(master_account_id));
        dispatch(recentsActions.addRecent({ external_id: master_account_id, page_type: FAVORITE_TYPE.MASTER_ACCOUNT }));
    }, [dispatch, master_account_id]);

    const reading = masterAccountBeingRead;

    const pinnedAccounts = master_account?.pins || [];

    const onPin = async (id) => {
        const updatedPins = pinnedAccounts.map(account => account.id).slice(0);
        updatedPins.push(id);
        try {
            await dispatch(masterAccountActions.update(master_account_id, { name: master_account.name, pins: updatedPins }));
            Notification.alert('Successfully pinned account!', STATUS_TYPES.SUCCESS);
            refresh();
        } catch (e) {
            Notification.alert('Failed to pin account', STATUS_TYPES.ERROR);
        }
    }

    const onUnpin = async (id) => {
        const updatedPins = pinnedAccounts.filter(account => account.id !== id).map(account => account.id).slice(0);
        try {
            await dispatch(masterAccountActions.update(master_account_id, { name: master_account.name, pins: updatedPins }));
            Notification.alert('Successfully unpinned account!', STATUS_TYPES.SUCCESS);
            refresh();
        } catch (e) {
            Notification.alert('Failed to unpin account', STATUS_TYPES.ERROR);
        }
    }

    const onReplacePinnedAccount = async (previouslyPinnedAccount, accountToPin) => {
        const pins = pinnedAccounts.map(account => account.id).slice(0);
        const indexOfAccountToUnpin = pins.findIndex(id => id === previouslyPinnedAccount);

        if (indexOfAccountToUnpin !== -1) {
            try {
                const updatedPins = [
                    ...pins.slice(0, indexOfAccountToUnpin),
                    accountToPin,
                    ...pins.slice(indexOfAccountToUnpin + 1)
                ];
                await dispatch(masterAccountActions.update(master_account_id, { name: master_account.name, pins: updatedPins }));
                Notification.alert('Successfully replaced pinned account!', STATUS_TYPES.SUCCESS);
                refresh();
            } catch (e) {
                Notification.alert('Failed to replace pinned account', STATUS_TYPES.ERROR);
            }
        };
    }

    if (reading) {
        return <Dimmer active><Loader active /></Dimmer>
    }

    if (!reading && masterAccountError) {
        return <Error500 message={'Something went wrong'} />
    }

    if (!reading && !master_account) {
        return <Error404 message={'Master account not found'} />
    }

    const created = master_account?.created ? DateTimeUtils.formatEpoch(master_account.created, DateTimeUtils.WORD_DATETIME_FORMAT) : '';
    const last_modified = master_account?.modified ? DateTimeUtils.formatEpoch(master_account.modified, DateTimeUtils.WORD_DATETIME_FORMAT) : '';

    return (
        <div className='MasterAccountPage'>
            <RecordProfile
                isDetailsSectionOpen={isDetailsSectionOpen}
                header={(
                    <ProfileHeader
                        name={master_account.name}
                        id={master_account.id}
                        type='Master Account'
                        isFavorite
                        isInHotlist
                        isSidePanelOpen={isSidePanelOpen}
                        breadcrumbs={(
                            <Breadcrumb>
                                <Breadcrumb.Section as={Link} to='/accounts' link>Accounts</Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section as={Link} to={`/accounts?${(new URLSearchParams({ page: 1, filter: 'master_account' })).toString()}`} link>Master Accounts</Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section active>
                                    <div className='ClippedContentBreadCrumb__text'>
                                        <ClippedContent>{master_account?.name}</ClippedContent>
                                    </div>
                                </Breadcrumb.Section>
                            </Breadcrumb>
                        )}
                        pinnedAccounts={pinnedAccounts}
                    />
                )}
                details={(
                    <PinnedAccounts
                        accounts={pinnedAccounts}
                        onPin={onPin}
                        onUnpin={onUnpin}
                        onReplacePinnedAccount={onReplacePinnedAccount}
                    />
                )}
                tabs={(
                    <MasterAccountTabs id={master_account_id} />
                )}
                tabContent={(
                    <Outlet />
                )}
                rightHandContent={(
                    <React.Fragment>
                        <StickyNotes record_id={master_account_id} />
                        <Divider />
                        <Segment>
                            <MasterAccountStatistics />
                        </Segment>
                        <Segment>
                            <RecordInfo
                                created={created}
                                last_modified={last_modified}
                                source=''
                            />
                        </Segment>
                    </React.Fragment>
                )}

                onToggleSidePanel={isOpen => {
                    setSidePanelOpen(isOpen);
                }}
                isSidePanelOpen={isSidePanelOpen}
            />
        </div>
    )
}

export default MasterAccount;
