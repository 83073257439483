import types from './appTypes';

function toggleMainMenu() {
    return {
        type: types.TOGGLE_MAIN_MENU,
    }
  }

 function onOpenRightSidebar() {
     return {
         type: types.OPEN_RIGHT_SIDEBAR
     }
 } 

 function onCloseRightSidebar() {
    return {
        type: types.CLOSE_RIGHT_SIDEBAR
    }
} 

function onSaveSettingsRoute(route) {
    return {
        type: types.SAVE_SETTINGS_ROUTE,
        route: route
    }
} 

export default {
    toggleMainMenu,
    onOpenRightSidebar,
    onCloseRightSidebar,
    onSaveSettingsRoute
};
