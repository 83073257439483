const initialState = {
    count: 0,
    manufacturers: [],
    queryParameters: {
        page: 1,
        offset: 0
    },

    isReading: false,
    isCreating: false,
    manufacturerBeingUpdated: false,
    manufacturerBeingDeleted: false
}

export default initialState;
