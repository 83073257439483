import React, { useCallback, useEffect } from 'react';
import '../index.scss';
import { Table, TableLoader, SearchBar } from 'dyl-components';
import { Header, Table as SemanticTable, Segment, Pagination } from 'semantic-ui-react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DistributionUsersRow } from './DistributionUsersRow';
import automationActions from 'actions/automation';

const LIMIT = 25;

const DistributionUsersTable = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [params] = useSearchParams();
    const searchQuery = params.get('search') || '';

    const { count, distribution_users, isReadingDistributionUsers } = useSelector((state) => ({
        distribution_users: state.automation.distribution_users,
        count: state.automation.distribution_users_count,
        isReadingDistributionUsers: state.automation.isReadingDistributionUsers
    }));

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        const query_string = query.toString();
        navigate(`/settings/assignment-rules/distribution-users${query_string ? `?${query_string}` : ''}`);
    }

    const cancelFunction = () => {
        const query = new URLSearchParams(params);
        query.delete('search');
        const query_string = query.toString();
        navigate(`/settings/assignment-rules/distribution-users${query_string ? `?${query_string}` : ''}`);
    }

    const onPageChange = (_, { activePage }) => {
        const queryParams = new URLSearchParams(params);
        queryParams.set('page', activePage);
        const queryString = queryParams.toString();
        navigate(`/settings/assignment-rules/distribution-users${queryString ? `?${queryString}` : ''}`);
    }

    const refresh = useCallback(async (params) => {
        await dispatch(automationActions.getDistributionUsers(params));
    }, [dispatch])

    useEffect(() => {
        refresh(Object.fromEntries(params));
    }, [refresh, params])

    const renderEmpty = (
        <Table.Row>
            <Table.Cell colspan={6}>
                <div className='AssignmentRules_emptyContainer'>
                    {!!searchQuery ? (
                        <Header as="h3" style={{marginBottom: 0}}>No results found.</Header>
                    ) : (
                        <>
                            <Header as="h4" style={{marginBottom: 0}}>No Distribution Users Added</Header>
                            <div>
                                You must first create an assignment rule with routing
                            </div>
                        </>
                    )}
                </div>
            </Table.Cell>
        </Table.Row>
    )

    return (
        <>
            <div> 
                <div className='DistributionUsers__searchContainer'>
                    <SearchBar
                        search={searchQuery}
                        searchFcn={onSearchSubmit}
                        cancelFcn={cancelFunction}
                        placeholder={'Search by distribution team name or assignment rule name'}
                    />
                </div>
                <Table>
                    <SemanticTable.Header className="Table__header--primary">
                        <Table.Row>
                            <SemanticTable.HeaderCell width={4}>User</SemanticTable.HeaderCell>
                            <SemanticTable.HeaderCell width={2} textAlign='center'>Cap</SemanticTable.HeaderCell>
                            <SemanticTable.HeaderCell width={8}>Distribution team(s)/Assignment Rule(s)</SemanticTable.HeaderCell>
                            <SemanticTable.HeaderCell width={2}>Total Active Assigned</SemanticTable.HeaderCell>
                            <SemanticTable.HeaderCell width={1}>Actions</SemanticTable.HeaderCell>
                        </Table.Row>
                    </SemanticTable.Header>
                    <Table.Body>
                        {isReadingDistributionUsers ? (
                            <TableLoader isLoading colSpan={5} />
                        ) : (distribution_users?.length === 0 ? renderEmpty : (
                            distribution_users?.map((user) => {
                                return <DistributionUsersRow user={user} refresh={refresh} />
                            })
                        ))}
                    </Table.Body>
                </Table>
                <Segment textAlign='right' basic style={{paddingRight: 0}}>
                    <Pagination
                        boundaryRange={0}
                        activePage={params.get('page') || 1}
                        ellipsisItem={null}
                        siblingRange={2}
                        totalPages={Math.ceil(count / LIMIT)}
                        onPageChange={onPageChange}
                        disabled={isReadingDistributionUsers}
                    />
                </Segment>
            </div>
        </>
        
    )
};

export default DistributionUsersTable;
