import routes from '../routes';
import { generateReadActionCreator, generateCreateActionCreator, generateUpdateActionCreator, generateDeleteActionCreator } from "actions/crudActionCreators";
import ACTION_NAMES from "actions/ACTION_NAMES";

export default {
    readUserProfile: generateReadActionCreator(`${routes.API_USERS}/profile`, ACTION_NAMES.USER_PROFILE),
    readUsers: generateReadActionCreator(routes.API_USERS, ACTION_NAMES.USERS),
    searchUsers: generateReadActionCreator(`${routes.API_USERS}/search_users`, ACTION_NAMES.SEARCH_USERS),
    addUser: generateCreateActionCreator(routes.API_USERS, ACTION_NAMES.USERS),
    updateUser: generateUpdateActionCreator(routes.API_USERS, ACTION_NAMES.USERS),
    deleteUser: generateDeleteActionCreator(routes.API_USERS, ACTION_NAMES.USERS),
    reactivateUser: generateUpdateActionCreator(routes.API_USERS, ACTION_NAMES.REACTIVATE_USER, () => { }, () => { }, 'reactivate'),
    resetUserPassword: generateUpdateActionCreator(routes.API_USERS, ACTION_NAMES.RESET_USER_PASSWORD, () => { }, () => { }, 'reset_password'),
    readUserOptions: generateReadActionCreator(routes.API_USERS, ACTION_NAMES.USER_OPTIONS),
    readBulkUsernames: generateReadActionCreator(`${routes.API_USERS}/bulk_users`, ACTION_NAMES.BULK_USERNAMES)
}
