import React from 'react';
import { Table, DateTimeUtils } from 'dyl-components';
import { Icon } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';

const HotlistRow = ({ hotlist }) => {
    const navigate = useNavigate();

    return (
        <Table.Row>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell>
                <Icon link onClick={() => { navigate("/group/hotlist") }} className="fab fa-hotjar GroupsPage__hotlistRow" />
                <Link to="/group/hotlist">Hotlist</Link>
            </Table.Cell>
            <Table.Cell>
                {hotlist.contact_count || "0"}
            </Table.Cell>
            <Table.Cell>
                Personal
            </Table.Cell>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell>
                {hotlist.last_modified && (
                    <>
                        <div><b>{DateTimeUtils.formatEpoch(hotlist.last_modified, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                        <div>{DateTimeUtils.formatEpoch(hotlist.last_modified, DateTimeUtils.TIME_FORMAT)}</div>
                    </>
                )}
            </Table.Cell>
            <Table.Cell />
        </Table.Row>
    )
}

export default HotlistRow;