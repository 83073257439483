export const DIGITS = [
    {
        "text": "*1",
        "key": "1"
    },
    {
        "text": "*2",
        "key": "2"
    },
    {
        "text": "*3",
        "key": "3"
    },
    {
        "text": "*4",
        "key": "4"
    },
    {
        "text": "*5",
        "key": "5"
    },
    {
        "text": "*6",
        "key": "6"
    },
    {
        "text": "*7",
        "key": "7"
    },
    {
        "text": "*8",
        "key": "8"
    },
    {
        "text": "*9",
        "key": "9"
    }
]