import routes from "actions/routes";
import ACTION_NAMES from "actions/ACTION_NAMES";
import { generateReadActionCreator, generateCreateActionCreator } from "actions/crudActionCreators";

const getTeams = generateReadActionCreator(routes.API_TEAMS, ACTION_NAMES.TEAMS);
const getTeamOptions= generateReadActionCreator(routes.API_TEAMS, ACTION_NAMES.TEAM_OPTIONS);
const addTeam = generateCreateActionCreator(routes.API_TEAMS, ACTION_NAMES.TEAMS);

export default {
    getTeams,
    getTeamOptions,
    addTeam
}
