import React from 'react';
import { MessageList } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import { Segment } from 'semantic-ui-react';
import './index.scss';
import { connect } from 'react-redux';

const ChatThread = ({ messages, isReadingMessages }) => (
    <Segment loading={isReadingMessages} className='ChatThread'>
        <MessageList
            className="message-list"
            lockable={true}
            toBottomHeight={"100%"}
            dataSource={messages}
        />
    </Segment>
);


const mapStateToProps = state => {
    return {
        isReadingMessages: state.text.isReadingMessages
    }
}

export default connect(mapStateToProps)(ChatThread);
