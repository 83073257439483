import { generateCreateActionCreator, generateDeleteActionCreator, generateReadOneActionCreator, generateReadActionCreator, generateUpdateActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import routes from 'actions/routes';

export default {
    readRingGroups: generateReadOneActionCreator(routes.API_PBX_CONFIG_DOMAIN, ACTION_NAMES.RING_GROUPS, 'ring_groups'),
    createRingGroups: generateCreateActionCreator(routes.API_PBX_CONFIG_DOMAIN, ACTION_NAMES.RING_GROUPS, () => {}, () => {}, 'ring_groups'),
    readRingGroup: generateReadOneActionCreator(routes.API_PBX_CONFIG_DOMAIN, ACTION_NAMES.RING_GROUP, 'ring_groups'),
    updateRingGroup: generateUpdateActionCreator(routes.API_PBX_CONFIG_DOMAIN, ACTION_NAMES.RING_GROUP, () => {}, () => {}, 'ring_groups'),
    deleteRingGroup: generateDeleteActionCreator(routes.API_PBX_CONFIG_DOMAIN, ACTION_NAMES.RING_GROUP, () => {}, () => {}, 'ring_groups'),

    readRingGroupMembers: generateReadOneActionCreator(routes.API_PBX_CONFIG_RING_GROUP, ACTION_NAMES.RING_GROUP_MEMBERS, 'members'),
    addRingGroupMembers: generateCreateActionCreator(routes.API_PBX_CONFIG_RING_GROUP, ACTION_NAMES.RING_GROUP_MEMBERS, () => {}, () => {}, 'members'),
    updateRingGroupMember: generateUpdateActionCreator(routes.API_PBX_CONFIG_RING_GROUP, ACTION_NAMES.RING_GROUP_MEMBERS, () => {}, () => {}, 'members'),
    deleteRingGroupMember: generateDeleteActionCreator(routes.API_PBX_CONFIG_RING_GROUP, ACTION_NAMES.RING_GROUP_MEMBERS, () => {}, () => {}, 'members'),

    getRingGroups: generateReadActionCreator(routes.API_PBX_CONFIG_RING_GROUPS, ACTION_NAMES.RING_GROUPS),
    getRingGroup: generateReadOneActionCreator(routes.API_PBX_CONFIG_RING_GROUP, ACTION_NAMES.RING_GROUP),
    updateRingGrp: generateUpdateActionCreator(routes.API_PBX_CONFIG_RING_GROUP, ACTION_NAMES.RING_GROUP, () => {}, () => {}),
    deleteRingGrp: generateDeleteActionCreator(routes.API_PBX_CONFIG_RING_GROUP, ACTION_NAMES.RING_GROUP, () => {}, () => {}),


}
