import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { RecordsTable } from "dyl-components";

import {
    getDataNonQuery,
    onToggleTimeUnitNonQuery,
    useRangeCalendar,
} from "shared/CustomDateRange/customDateRange";

import orderActions from 'actions/order';

import Header from './Header';
import Row from './Row';

import './index.scss';
import QuoteBuilderProvider from "shared/context/QuoteBuilderProvider";
import QuoteBuilderModal from "shared/modals/QuoteBuilderModal";

const CustomerOrderInvoices = () => {
    const limit = 25;
    const { account_id } = useParams();
    const dispatch = useDispatch();

    const [dateRange, setDateRange] = useState("-");

    const [sortType, setSortType] = useState("created_on");
    const [sortDirection, setSortDirection] = useState(false);

    const [activePage, setActivePage] = useState(1);

    useEffect(() => {
        const dateRangeArray = dateRange.split("-");
        dispatch(orderActions.read(account_id, {
            page: activePage,
            limit: limit,
            sort_by: sortType,
            order:  sortDirection ? 'asc' : 'desc',
            ...(!(dateRangeArray[0] === "" && dateRangeArray[1] === "") && {
                start: dateRangeArray[0],
                end: dateRangeArray[1],
            })
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dateRange,
        sortType,
        sortDirection,
        account_id,
        activePage,
        limit
    ]);

    const { isReadingOrders, orders, count } = useSelector((state) => ({
        isReadingOrders: state.order.isReadingOrders,
        orders: state.order.orders,
        count: state.order.orders.length
    }));

    const onToggleTimeUnit = (
        timestamp,
        setTimestamp,
        setTimeUnitOptionsOpen
    ) => {
        const timestampRange = onToggleTimeUnitNonQuery(
            timestamp,
            setTimestamp
        );

        if (timestamp !== "Custom Range") {
            setDateRange(
                `${timestampRange?.start !== undefined
                    ? timestampRange.start
                    : ""
                }-${timestampRange?.end !== undefined ? timestampRange.end : ""
                }`
            );
            setTimeUnitOptionsOpen(false);
        }
    };

    const getData = (setCustomDateRangeOpen, dateRange) => {
        const timestampRange = getDataNonQuery(
            setCustomDateRangeOpen,
            dateRange
        );
        setDateRange(
            `${timestampRange?.start !== undefined ? timestampRange.start : ""
            }-${timestampRange?.end !== undefined ? timestampRange.end : ""}`
        );
    };

    const onToggleSort = (newSortType) => {
        if (sortType === newSortType) {
            setSortDirection(!sortDirection);
        } else {
            setSortDirection(false);
        }
        setSortType(newSortType);
        setActivePage(1);
    };

    const onPageChange = (_, { activePage }) => {
        setActivePage(activePage);
    };

    return (
        <QuoteBuilderProvider refreshAccountInformation={() => {
            const dateRangeArray = dateRange.split("-");
            dispatch(orderActions.read(account_id, {
                page: activePage,
                limit: limit,
                sort_by: sortType,
                order:  sortDirection ? 'asc' : 'desc',
                ...(!(dateRangeArray[0] === "" && dateRangeArray[1] === "") && {
                    start: dateRangeArray[0],
                    end: dateRangeArray[1],
                })
            }));
        }}>
            <RecordsTable
                tableBodyClassName='OrdersTable'
                isTableLoading={isReadingOrders}
                isSearchResultEmpty={false}
                isTableEmpty={count === 0}
                TableHeader={<Header
                    useRangeCalendar={useRangeCalendar(null, false)}
                    onToggleTimeUnit={onToggleTimeUnit}
                    getTimeData={getData}
                    sortType={sortType}
                    sortDirection={sortDirection}
                    onToggleSort={onToggleSort}
                />}
                TableRows={orders.map(order => (
                    <Row
                        key={order.order_id}
                        order={order}
                    />
                ))}
                recordsCount={count}
                activePage={activePage}
                onPageChange={onPageChange}
                emptyListTitle={'No Orders Added'}
                emptySubtitle=' '
            />
            <QuoteBuilderModal account_id={account_id} />
        </QuoteBuilderProvider>
    );
}

export default CustomerOrderInvoices;
