import React from 'react';

import AfterHoursRoutingSettingsContainer from './subcomponents/AfterHoursRoutingSettings';
import { Notification, STATUS_TYPES } from 'dyl-components';

class AfterHoursRouting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: {
                phone: '(310) 510-6959',
                routeWhenOpen: 'sc1',
                routeWhenClosed: 'vct',
                schedule: 'Use Main Schedule'
            }
        }
    }

    onUpdate = (updatedSettings) => {
        let settings = { ...this.state.settings, ...updatedSettings };
        this.setState({ settings }, () => {
            Notification.alert('Successfully updated routing!', STATUS_TYPES.SUCCESS, true);
        });
    }

    render() {
        return (
            <AfterHoursRoutingSettingsContainer 
                settings={this.state.settings}
                onUpdate={this.onUpdate}
            />
        )
    }
}

export default AfterHoursRouting;
