import ACTION_NAMES from 'actions/ACTION_NAMES';
import { generateReadOneActionCreator, generateCreateActionCreator, generateUpdateActionCreator, generateDeleteActionCreator } from 'actions/crudActionCreators';
import routes from 'actions/routes';

export default {
    addAttachments: generateCreateActionCreator(routes.API_TASK, ACTION_NAMES.TASK_ATTACHMENTS, () => {}, () => {}, 'task_attachment'),
    getAttachments: generateReadOneActionCreator(routes.API_TASK, ACTION_NAMES.TASK_ATTACHMENTS, 'task_attachment'),
    updateAttachment: generateUpdateActionCreator(routes.API_TASK_ATTACHMENT, ACTION_NAMES.TASK_ATTACHMENTS),
    removeAttachment: generateDeleteActionCreator(routes.API_TASK_ATTACHMENT, ACTION_NAMES.TASK_ATTACHMENTS)
}
