import React from 'react';
import { Table as SemanticTable } from 'semantic-ui-react';

const HistoryTableHeader = () => {
    return (
        <React.Fragment>
            <SemanticTable.HeaderCell width={2}>
                Activity Type
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={3}>
                Activity Description
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={1}>
                Contact
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={1}>
                User
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={1}>
                Actions
            </SemanticTable.HeaderCell>
        </React.Fragment>
    );
}

export default HistoryTableHeader;
