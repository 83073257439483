import React from 'react';
import { Form } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import { FileAttach, Button, STATUS_TYPES, CheckboxDropdown } from 'dyl-components';
import './ImportForm.scss';

const ImportForm = ({ filters, classification, classType, classifications, onSubmit, selectedFilters, onChange }) => (
    <Form>
        <FileAttach />
        <Form.Group className='ImportForm'>
            <Form.Field className='ImportForm__FilterLabel'>
                <label>Select Filters</label>
            </Form.Field>
        </Form.Group>
        <Form.Group widths='equal' className='ImportForm'>
            <Form.Field
                className='ImportForm__CheckBoxDropDown'
                control={CheckboxDropdown}
                options={[
                    { key: "filterdisconnected", value: "filterdisconnected", text: "Filter Disconnected" },
                    { key: "checkwireless", value: "checkwireless", text: "Check Wireless" },
                    { key: "scandnc", value: "scandnc", text: "Scan DNC" },
                ]}
                text="Choose your Filters"
                onChange={onChange}
                name={"filters"}
                value={filters}
                selection
            />
            <Form.Select
                label={'Select Classification'}
                defaultValue={classification}
                search
                allowAdditions
                options={classifications}
                name={'classification'}
                onChange={onChange}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Field>

            </Form.Field>
            <Form.Field>
                <label>Select Record Type</label>
                <Form.Group inline>
                    <Form.Radio checked={classType === 'business'} label='Business' value='business' name={'classType'} onChange={onChange} />
                    <Form.Radio checked={classType === 'person'} label='Person' value='person' name={'classType'} onChange={onChange} />
                    <Form.Radio checked={classType === 'household'} label='Household' value='household' name={'classType'} onChange={onChange} />
                </Form.Group>
            </Form.Field>
        </Form.Group>
        <div className="ImportFormDiv">
            <Link to='/import-step-2' >
                <Form.Button onClick={onSubmit} as={Button} status={STATUS_TYPES.PRIMARY} floated='right'>
                    Next
                </Form.Button>
            </Link>
        </div>
    </Form>
);

export default ImportForm;
