import React from "react";
import { Modal, Notification, STATUS_TYPES } from "dyl-components";
import { Button } from "semantic-ui-react";
import { useForm } from 'react-hook-form';
import { callResultOptions } from '../forms/TaskForm/optionsData';
import TaskCallConfirmationForm from "../forms/TaskForm/TaskCallConfirmationForm";

const TaskCallConfirmationModal = ({ open, onClose, isAllowedToModify, complete, isReadingLabels, taskCompleteInfo, onRefreshTask, onCompleteTask, id }) => {

    const { handleSubmit, control, reset } = useForm(
        {
            defaultValues: {
                call_result: "reached",
                direction: "outbound",
                minutes: 0
            }
        });

    const completeTaskClick = async (data) => {
        try {
            let minutes = +data.minutes;
            if (data.hours) minutes = minutes + (data?.hours * 60);
            let payload = {
                task_call_meta: {
                    call_duration: minutes,
                    call_result: data.call_result
                },
                direction: data.direction
            }
            let resp = await onCompleteTask(taskCompleteInfo?.id, payload);
            if (resp?.message === 'OK') Notification.alert("Sucessfully completed the task!", STATUS_TYPES.SUCCESS);
            reset();
            onRefreshTask();
            onClose();
        } catch (e) {
            Notification.alert(e?.Message || "Failed to complete the task!", STATUS_TYPES.ERROR);
        }
    }

    return <Modal open={open} size={"tiny"} animated onClose={onClose}>
        <Modal.Header>Complete</Modal.Header>
        <Modal.Content scrolling>
            <TaskCallConfirmationForm
                isAllowedToModify={isAllowedToModify}
                complete={complete}
                isReadingLabels={isReadingLabels}
                control={control}
                callResultOptions={callResultOptions}
                id={id}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button
                floated={'left'}
                negative
                onClick={onClose}
            >
                Cancel
            </Button>
            <Button
                primary
                basic
                onClick={handleSubmit((data) => completeTaskClick(data))}
                type="submit"
            >
                Complete task
            </Button>

        </Modal.Actions>
    </Modal>
}

export default TaskCallConfirmationModal;