import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { RecordsTable } from 'dyl-components';

import CampaignsTableHeader from './Header';
import CampaignsRow from './Row';
import RemoveFromCampaignPopup from "./RemoveFromCampaignPopup";
import { CampaignsContext } from './CampaignsContextProvider';

const CampaignsTable = ({ onFilter, onPageChange, readCampaigns }) => {
    const [params] = useSearchParams();
    const { contact_id } = useParams();

    const {
        state,
        onClearSelected,
    } = useContext(CampaignsContext);
    const { campaigns = [], isReadingCampaigns, campaigns_count } = useSelector(state => (state.contact_campaigns));
    const isApplyingBulkAction = useSelector(state => state.campaigns.isApplyingBulkAction);

    const isTableLoading = isReadingCampaigns;

    const { ref, selectedCampaign, action } = state;

    return (
        <>
            <RecordsTable
                tableBodyClassName='CampaignsTable'
                isTableLoading={isTableLoading || isApplyingBulkAction}
                isTableEmpty={campaigns_count === 0}
                TableHeader={<CampaignsTableHeader onFilter={onFilter} />}
                TableRows={campaigns.map(campaign => (
                    <CampaignsRow
                        key={campaign.id}
                        campaign={campaign}
                    />
                ))}
                recordsCount={campaigns_count}
                activePage={params.get('page')}
                onPageChange={onPageChange}
                recordsName='Campaign'
                emptySubtitle=' '
                emptyListTitle={'No Activities Created Under Campaign'}
            />
            <RemoveFromCampaignPopup
                isOpen={action === 'remove' && selectedCampaign}
                actionPopupRef={ref}
                campaignID={selectedCampaign}
                onFormClose={onClearSelected}
                refresh={readCampaigns}
                contact_id={Number(contact_id)}
            />
        </>
    );
}

export default CampaignsTable;
