import { Controller, useFormContext } from "react-hook-form";
import { Segment, Header, Form } from "semantic-ui-react";
import ModelField from "./ModelField";
import ScheduleField from "./ScheduleField";
import PriceField from "./PriceField";
import PricingFields from "./PricingFields";

const PricingDetails = () => {
    const { control } = useFormContext();
    return (
        <Segment>
            <Header color="primary" as="h3">
                Pricing Details
            </Header>
            <Controller
                control={control}
                name="price_data.model"
                render={({ field: { value: model } }) => {
                    return (
                        <Controller
                            control={control}
                            name="price_data.schedule"
                            render={({ field: { value: schedule } }) => (
                                <>
                                    <Form.Group widths={2}>
                                        <ModelField schedule={schedule} />
                                        <ScheduleField model={model} />
                                        {model === "usage" && <PriceField name={"price_data.price"} label="Unit Price" />}
                                    </Form.Group>
                                    <PricingFields
                                        model={model}
                                        schedule={schedule}
                                    />
                                </>
                            )}
                        />
                    );
                }}
            />
        </Segment>
    );
};

export default PricingDetails;
