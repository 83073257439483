import { ClippedContent, CloseableContent } from 'dyl-components';
import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, Grid, Header, Icon, Label, Popup } from 'semantic-ui-react';
import { StringUtils } from 'utils';
import ConversionControls from './ConversionControls';
import MoveModuleForm from './MoveModuleForm';
import StageControls from './StageControls';
import sequenceTasksActions from 'actions/sequence_tasks';

import './ModuleHeader.scss';
import AddQuote from './AddQuote';
import QuoteBuilderModal from 'shared/modals/QuoteBuilderModal';

const ModuleHeader = ({ onCloseModulePanel }) => {
    const { module_info, account_stage, active, outcome, isMovingModule, contact_id, account_id, account_is_a_customer, current_primary_contact_id, contactIsAssociatedWithCustomerPipeline } = useSelector(state => {
        const pipeline = state.module_information.pipeline;
        const contact_info = pipeline?.contact;
        return ({
            module_info: {
                ...pipeline?.contact,
                name: `${contact_info?.first_name} ${contact_info.last_name}`
            },
            account_id: contact_info?.account?.id,
            account_stage: state.module_information.pipeline?.module,
            active: pipeline?.active,
            outcome: (pipeline?.outcome || '').split('(')[0],
            isMovingModule: state.module_information.pipelineBeingMoved || state.contacts.isCreating || state.account.isLinkingContactsToAccount,
            contact_id: pipeline?.contact?.id,
            account_is_a_customer: Boolean(contact_info?.account?.customer),
            current_primary_contact_id: contact_info?.account?.primary_contact_id,
            contactIsAssociatedWithCustomerPipeline: contact_info?.id === contact_info?.account?.pipeline_person_id
        });
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (contact_id && active) {
            dispatch(sequenceTasksActions.readContactTasks(contact_id));
        }
    }, [contact_id, dispatch, active])

    const [params] = useSearchParams();

    const pipeline_id = params.get('module_id');
    const isRightSidebarOpen = pipeline_id !== null;

    const renderedPipelineId = `${pipeline_id || ''}`?.length > 5 ? (
        <Popup
            content={pipeline_id}
            inverted
            trigger={<span style={{ maxWidth: "4em", textOverflow: "ellipsis", overflow: "hidden", display: "inline-block", marginBottom: "-0.3em" }}>{pipeline_id}</span>}
        />
    ) : pipeline_id;

    const [isMoveModuleOpen, setIsMoveModuleOpen] = useState(false);

    const onOpenMoveModule = () => {
        setIsMoveModuleOpen(true);
    }

    const onCloseMoveModule = () => {
        setIsMoveModuleOpen(false);
    }

    const isOpportunity = account_stage === "opportunity";
    
    return [
        <div>
            <Grid>
                <Grid.Row columns='equal' verticalAlign='top' className='ModuleHeader__headerRow'>
                    <Grid.Column width={1} textAlign='center'>
                        <Avatar
                                size='3.75em'
                                round
                                maxInitials={2}
                                name={module_info.name}
                            />
                    </Grid.Column>
                    <Grid.Column width={7} className='ModuleHeader__headerColumn'>
                        <Header className='ModuleHeader__nameHeader' as='h2' color='primary' >
                            {module_info.name}
                        </Header>
                        <Grid columns={'equal'} verticalAlign='middle'>
                            <Grid.Column width={4}>
                                <ClippedContent popupContent={module_info.job_title}>
                                    <em>{module_info.job_title || 'No job title'}</em>
                                </ClippedContent>
                            </Grid.Column>
                            {
                                module_info.account?.id && (
                                    <Grid.Column>
                                        <ClippedContent popupContent={module_info.account.name}>
                                            <span>
                                                <Icon className={`fas ${module_info.account.type === 'business' ? 'fa-building' : 'fa-solid fa-house-chimney-blank'}`} />
                                                <Link to={`/account/${module_info.account?.id}`} target="_blank">
                                                    {module_info.account.name}
                                                </Link>
                                            </span>
                                        </ClippedContent>
                                    </Grid.Column>
                                )
                            }
                        </Grid>
                    </Grid.Column>
                    <Grid.Column verticalAlign='top' textAlign='right'>
                        <Header className='ModuleHeader__leadIdHeader' color='primary'>
                            <span>
                                {StringUtils.capitalize(account_stage)}  <span className='ModuleHeader__IdSpan'>ID:</span> <Popup
                                    trigger={
                                        <a href='#?' role="button" tabIndex="0" onClick={StringUtils.copy(pipeline_id)}>
                                            {renderedPipelineId} <Icon className='fas fa-copy ModuleHeader__copyIcon' />
                                        </a>
                                    }
                                    content='Copied!'
                                    on='click'
                                    closeOnTriggerMouseLeave
                                    position='bottom center'
                                    inverted
                                />
                                {active && (
                                    <Popup
                                        style={{ maxWidth: '1024px', maxHeight: '500px', overflowY: 'scroll' }}
                                        trigger={<Button className='ModuleHeader__rightBtn' icon={<Icon className='fas fa-people-arrows' />} color='primary' />}
                                        content={(
                                            <div className='ModuleHeader__move-module-form'>
                                                <CloseableContent onClose={onCloseMoveModule}>
                                                    <MoveModuleForm onCloseModulePanel={onCloseModulePanel} />
                                                </CloseableContent>
                                            </div>
                                        )}
                                        on='click'
                                        open={isRightSidebarOpen && isMoveModuleOpen}
                                        onOpen={onOpenMoveModule}
                                        disabled={!active || isMovingModule}
                                    />
                                )}
                            </span>
                        </Header>
                        {!active && (
                            <React.Fragment>
                                <Label className='ModuleHeader__closed-label'>Closed</Label> {StringUtils.capitalize(account_stage)} {outcome}
                            </React.Fragment>
                        )}
                    </Grid.Column>
                    <Grid.Column width={1} />
                </Grid.Row>
            </Grid>
            <Grid>
                <Grid.Row columns={'equal'} verticalAlign='top'>
                    <Grid.Column width={12}>
                        {active && (
                            <div style={{ paddingLeft: '1em', paddingRight: '1em' }}>
                                <StageControls />
                            </div>
                        )}
                    </Grid.Column>
                    <Grid.Column textAlign='right'>
                        {active && (
                            <>
                                {!contactIsAssociatedWithCustomerPipeline && <ConversionControls />}
                                <div style={{ paddingTop: "1em", paddingLeft: "0.75em" }}>
                                    <AddQuote isOpportunity={isOpportunity} />
                                </div>
                            </>
                        )}
                        <QuoteBuilderModal
                            account_id={account_id}
                            contact_id={contact_id}
                            {...(isOpportunity
                                ? {
                                    opportunity_id: pipeline_id,
                                    isLinkedToCustomer: account_is_a_customer,
                                    account_primary_contact_id:
                                        current_primary_contact_id,
                                }
                                : {})}
                        />
                    </Grid.Column>
                    <Grid.Column width={1} />
                </Grid.Row>
            </Grid>
        </div>
    ]
};

export default ModuleHeader;
