const onSetMenuActive = (activeMenuItem) => {
    return { activeMenuItem, activeSubMenuItem: '', type: 'SET_MENU_ACTIVE' }
}

const onSetSubMenuActive = (activeSubMenuItem) => {
    return { activeSubMenuItem, activeMenuItem: '', type: 'SET_SUB_MENU_ACTIVE' }
}

export default {
    onSetMenuActive,
    onSetSubMenuActive
}
