import { SettingsFooter, Button } from "dyl-components";
import { Link } from 'react-router-dom';
import React from "react";

export const Footer = ({
    isSaveDisabled,
    onSave,
    width = "100%"
}) => {
    return (
        <SettingsFooter
            style={{ width: width }}
            className={`Webform__menu`}
            rightOptions={[
                <Button
                    onClick={onSave}
                    disabled={isSaveDisabled}
                >
                    Save
                </Button>,
            ]}
            leftOptions={[
                <Button as={Link} to='/settings/assignment-rules/assignment-rules' status={'cancel'} basic>Cancel</Button>,
            ]}
        />
    );
};
