import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Ref } from 'semantic-ui-react';

import './Contact.scss';
import { ButtonLink } from 'dyl-components';

export function getTeamAgentId(teamName, agentId) {
    return `${teamName}-${agentId}`;
}

export const Contact = ({ teamName, agent }) => {
    let contactPerson = agent.contact;
    if (!contactPerson) {
        return (
            <Droppable
                droppableId={`EXTENSION-${agent.extension}-${agent.name}-${teamName}`}
            >
                {(provided, snapshot) => (
                    <Ref innerRef={provided.innerRef}>
                        <div 
                            className={`ContactContainer ${snapshot.isDraggingOver ? 'ContactContainer--dragged-over' : ''}`}
                        >
                        </div>
                    </Ref>
                )}
            </Droppable>
        )
    }
    return (
        <React.Fragment>
            <span className='OfficeViewTable__label'>
                {contactPerson.cid_name}
            </span>
            <br/>
            <ButtonLink>
                {contactPerson.cid_number}
            </ButtonLink>
        </React.Fragment>
    )
}
