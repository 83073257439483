import React from 'react';
import { Table, TableLoader } from 'dyl-components';
import { Table as SemanticTable } from 'semantic-ui-react';
import { NotesHeader } from './Header';
import { NotesRow } from './Row';
import './index.scss';

export const NotesTable = ({ 
    isReadingNotes, 
    notes, 
    dateSelected, 
    onFilter, 
    onSort, 
    onTimeFilter, 
    filters, 
    record_id, 
    userAccounts, 
    isReadingUserAccounts, 
    contactAccount, 
    account,
    masterAccount,
    accountRelatedNotes,
    isReadingAccountRelatedNotes,
    contactAccountOptions
}) => (
    <Table>
        <SemanticTable.Header>
            <NotesHeader
                dateSelected={dateSelected}
                onFilter={onFilter}
                isReadingNotes={isReadingNotes}
                filters={filters}
                onSort={onSort}
                onTimeFilter={onTimeFilter}
                record_id={record_id}
                isReadingUserAccounts={isReadingUserAccounts}
                userAccounts={userAccounts}
                contactAccount={contactAccount}
                account={account}
                masterAccount={masterAccount}
                accountRelatedNotes={accountRelatedNotes}
                isReadingAccountRelatedNotes={isReadingAccountRelatedNotes}  
                contactAccountOptions={contactAccountOptions}              
            />
        </SemanticTable.Header>
        <Table.Body>
            {
                isReadingNotes
                    ? <TableLoader isLoading colspan={6} />
                    : notes.map(note => <NotesRow key={note.id} note={note} contactAccount={contactAccount} masterAccount={masterAccount} account={account}/>)
            }
        </Table.Body>
    </Table>
)
