import axiosInstance from 'actions/axiosInstance';
import routes from 'actions/routes';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';

import { Notification, STATUS_TYPES } from 'dyl-components';

export default {
    getMetaData: async (type, file_ids) => {
        const jwtResponse = await axiosInstance.instance1.get(routes.API_JWT);
        const { data: metaData } = await axiosInstance.image_instance.post(`/v1/file/metadata/${type}`, file_ids, {
            headers: {
                "X-Token": jwtResponse.data.token
            }
        });
        return metaData;
    },
    getUserProfileImage: ({ isThumbnail = false, user }) => async dispatch => {
        try {
            dispatch({
                type: getNamedAction(ACTION_NAMES.USER_PROFILE_IMAGE, CRUD_ACTION_TYPES.READ_REQUEST)
            })
            let headers = { "X-TOKEN": (await axiosInstance.instance1.get(`/v1/jwt`)).data.token };
            let response = await axiosInstance.image_instance.get('/v1/profile', { params: { thumbnail: isThumbnail, user }, headers });
            dispatch({
                type: getNamedAction(ACTION_NAMES.USER_PROFILE_IMAGE, CRUD_ACTION_TYPES.READ_SUCCESS),
                data: { image: isThumbnail ? response.data.thumbnail : response.data.image }
            });
        }
        catch (err) {
            console.log(err);
            dispatch({
                type: getNamedAction(ACTION_NAMES.USER_PROFILE_IMAGE, CRUD_ACTION_TYPES.READ_FAILURE),
            });
        }
    },

    deleteUserProfileImage: ({ user }) => async dispatch => {
        try {
            dispatch({
                type: getNamedAction(ACTION_NAMES.USER_PROFILE_IMAGE, CRUD_ACTION_TYPES.DELETE_REQUEST)
            })
            let headers = { "X-TOKEN": (await axiosInstance.instance1.get(`/v1/jwt`)).data.token };
            let response = await axiosInstance.image_instance.delete(`/v1/profile`, { params: { user }, headers });
            if (response.status !== 200) {
                Notification.alert('Failed to delete user profile picture!', STATUS_TYPES.ERROR, true);
                dispatch({
                    type: getNamedAction(ACTION_NAMES.USER_PROFILE_IMAGE, CRUD_ACTION_TYPES.DELETE_FAILURE)
                })
            }
            else {
                Notification.alert('Successfully deleted user profile picture!', STATUS_TYPES.SUCCESS, true);
                dispatch({
                    type: getNamedAction(ACTION_NAMES.USER_PROFILE_IMAGE, CRUD_ACTION_TYPES.DELETE_SUCCESS, true)
                })
            }
        }
        catch (err) {
            console.log(err);
            Notification.alert('Failed to delete user profile picture!', STATUS_TYPES.ERROR, true);
            dispatch({
                type: getNamedAction(ACTION_NAMES.USER_PROFILE_IMAGE, CRUD_ACTION_TYPES.DELETE_FAILURE),
            });
        }
    },



    getUrls: (ids, type) => dispatch => {
        dispatch({
            type: getNamedAction(ACTION_NAMES.IMAGE_URLS, CRUD_ACTION_TYPES.READ_REQUEST)
        })

        return axiosInstance.instance1.get(routes.API_JWT)
            .then((response) => {
                return axiosInstance.image_instance.get(routes.API_IMAGE_URLS, {
                    headers: {
                        "X-Token": response.data.token
                    },
                    params: {
                        id: ids.join(','),
                        type
                    }
                })
            })
            .then((response) => {
                console.log(response);

                dispatch({
                    type: getNamedAction(ACTION_NAMES.IMAGE_URLS, CRUD_ACTION_TYPES.READ_SUCCESS),
                });

                return Promise.resolve(response.data);
            })
            .catch((error) => {
                console.log(error);

                dispatch({
                    type: getNamedAction(ACTION_NAMES.IMAGE_URLS, CRUD_ACTION_TYPES.READ_FAILURE),
                });

                return Promise.reject(error);
            })
    },

    uploadMMSPayload: (file) => dispatch => {
        dispatch({
            type: getNamedAction(ACTION_NAMES.MMS_PAYLOAD, CRUD_ACTION_TYPES.CREATE_REQUEST)
        });

        return axiosInstance.instance1.get(routes.API_JWT)
            .then((response) => {
                const data = new FormData();
                data.append('file', file);
                return axiosInstance.image_instance.post(routes.API_MMS_PAYLOAD, data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "X-Token": response.data.token
                    }
                })
            })
            .then((response) => {
                console.log(response);

                dispatch({
                    type: getNamedAction(ACTION_NAMES.MMS_PAYLOAD, CRUD_ACTION_TYPES.CREATE_SUCCESS),
                });

                return Promise.resolve(response.data);
            })
            .catch((error) => {
                console.log(error);

                dispatch({
                    type: getNamedAction(ACTION_NAMES.MMS_PAYLOAD, CRUD_ACTION_TYPES.CREATE_FAILURE),
                });

                return Promise.reject(error);
            })
    },

    uploadUserProfileImage: (file) => async dispatch => {
        // you just can upload your own profile picture
        // to be able to upload other user's pictures a way to enforce this permission needs to be implemented on IMAGE-API
        dispatch({
            type: getNamedAction(ACTION_NAMES.USER_PROFILE_IMAGE, CRUD_ACTION_TYPES.CREATE_REQUEST)
        });

        try {
            let formData = new FormData();
            formData.append("file", file, file.name);
            await axiosInstance.image_instance.post(
                `/v1/profile`, formData,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                        "x-token": (await axiosInstance.instance1.get(`/v1/jwt`)).data.token
                    },
                }
            );
            dispatch({
                type: getNamedAction(ACTION_NAMES.USER_PROFILE_IMAGE, CRUD_ACTION_TYPES.CREATE_SUCCESS),
            });
            Notification.alert('Successfully uploaded new user profile image!', STATUS_TYPES.SUCCESS, true);
        } catch (err) {
            console.log(err)
            dispatch({
                type: getNamedAction(ACTION_NAMES.USER_PROFILE_IMAGE, CRUD_ACTION_TYPES.CREATE_FAILURE),
            });
            Notification.alert('Failed to upload user profile image', STATUS_TYPES.ERROR, true);
        }

    }
}
