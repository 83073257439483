import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function customFieldReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELD, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, fieldBeingUpdated: action.subId };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELD, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, fieldBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELD, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, fieldBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.CUSTOM_FIELD, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, fieldBeingDeleted: action.subId };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELD, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, fieldBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELD, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, fieldBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.CUSTOM_FIELD, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingCustomFieldOption: true };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELD, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAddingCustomFieldOption: false };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELD, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingCustomFieldOption: false };

        case getNamedAction(ACTION_NAMES.CUSTOM_FIELD_OPTION, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingCustomFieldOption: true };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELD_OPTION, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingCustomFieldOption: false };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELD_OPTION, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingCustomFieldOption: false };

        case getNamedAction(ACTION_NAMES.CUSTOM_FIELD_REACTIVATE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, fieldBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELD_REACTIVATE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, fieldBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELD_REACTIVATE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, fieldBeingUpdated: null };

        default:
            return state;
    }
}


export default customFieldReducer;
