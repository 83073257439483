import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function contactDuplicatesReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CONTACT_DUPLICATES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isCheckingDuplicates: true };
        case getNamedAction(ACTION_NAMES.CONTACT_DUPLICATES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isCheckingDuplicates: false };
        case getNamedAction(ACTION_NAMES.CONTACT_DUPLICATES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isCheckingDuplicates: false };

        default:
            return state;
    }
}

export default contactDuplicatesReducer;
