
import types from 'actions/app/appTypes';
import initialState from 'reducers/app/appState';


function appReducer(state, action) {
    if (typeof state === 'undefined') {
        return Object.assign({}, initialState);
    }

    switch (action.type) {
        case types.TOGGLE_MAIN_MENU:
            return Object.assign({}, state, {
                isMainMenuOpen: !state.isMainMenuOpen
            })
        case types.OPEN_RIGHT_SIDEBAR:
            return Object.assign({}, state, {
                isRightSidebarOpen: true
            })
        case types.CLOSE_RIGHT_SIDEBAR:
            return Object.assign({}, state, {
                isRightSidebarOpen: false
            })
        case types.SAVE_SETTINGS_ROUTE:
            return Object.assign({}, state, {
                savedSettingsRoute: action.route
            })

        default:
            return state;
    }
}


export default appReducer;
