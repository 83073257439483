import types from './types';

import routes from 'actions/routes';
import { generateReadActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

function markOpportunity(id) {
    return dispatch => {
        dispatch({
            type: types.MARK_OPPORTUNITY,
            id
        })
    }
}

function markAllOpportunities() {
    return dispatch => {
        dispatch({
            type: types.MARK_ALL_OPPORTUNITIES
        })
    }
}

function markAllPages() {
    return {
        type: types.MARK_OPPORTUNITIES_IN_EVERY_PAGE
    }
}

function updateOpportunityAssignment(opportunity, user) {
    return dispatch => {
        dispatch({
            type: types.UPDATE_OPPORTUNITY_ASSIGNMENT,
            opportunity,
            user
        })
    }
}

export default {
    readOpportunities: generateReadActionCreator(routes.API_OPPORTUNITIES, ACTION_NAMES.OPPORTUNITIES),
    markOpportunity,
    markAllOpportunities,
    updateOpportunityAssignment,
    markAllPages
};
