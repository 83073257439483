import React, { useEffect } from 'react';
import { TableWithHeader } from 'dyl-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Pagination, Segment } from 'semantic-ui-react';
import { ImportTable } from './subcomponents/ImportTable';
import ImportToolbar from './subcomponents/ImportToolbar';
import { useDispatch, useSelector } from 'react-redux';
import importDataActions from 'actions/import_data';


import './index.scss';

const LIMIT = 25;

const ImportList = () => {

    const [params] = useSearchParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(importDataActions.readImports(Object.fromEntries(params)))
    }, [dispatch, params])

    const importDataReducer = useSelector(state => state.import_data);

    const { import_count, isReadingImports } = importDataReducer;

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`/settings/import-data${query_string ? `?${query_string}` : ''}`);
    }

    return (
        <div className='ImportListPanel'>
            <TableWithHeader
                header={(
                    <ImportToolbar />
                )}
                table={(
                    <Segment.Group>
                        <Segment style={{ padding: 0 }}>
                            <ImportTable />
                        </Segment>
                        {import_count !== 0 &&
                            <Segment textAlign='right'>
                                <Pagination
                                    boundaryRange={0}
                                    activePage={params.get('page') || 1}
                                    ellipsisItem={null}
                                    siblingRange={2}
                                    totalPages={Math.ceil(import_count / LIMIT)}
                                    onPageChange={onPageChange}
                                    disabled={isReadingImports}
                                />
                            </Segment>
                        }
                    </Segment.Group>
                )}
            />
        </div>
    )
}

export default ImportList
