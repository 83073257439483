import initialState from "./initialState";

function productsUiReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_SELECTED_PRODUCTS':
            return { ...state, selected_products: action.products };
        case 'SELECT_PRODUCT':
            return { ...state, selected_products: [...state.selected_products, action.product] };
        case 'REMOVE_PRODUCT':
            {
                const selected_products = state.selected_products.slice(0);
                const product = action.product;
                const indexOfProductToRemove = selected_products.findIndex(({ id, package_id }) =>
                    (id === product.id) ||
                    (package_id && product.package_id === package_id)
                );
                if (indexOfProductToRemove !== -1) {
                    selected_products.splice(indexOfProductToRemove, 1);
                    return { ...state, selected_products };
                }
                return state;
            }
        case 'EDIT_SELECTED_PRODUCT':
            {
                const { id, name, value, package_id } = action;
                const selected_products = state.selected_products.slice(0);
                const indexOfProductToBeEdited = selected_products.findIndex(product =>
                    (product.id === id) ||
                    (package_id && package_id === product.package_id));
                if (indexOfProductToBeEdited !== -1) {
                    const editedProduct = { ...selected_products[indexOfProductToBeEdited], [name]: value };
                    selected_products[indexOfProductToBeEdited] = editedProduct;
                    return { ...state, selected_products };
                }
                return state;
            }
        
        case 'OPEN_QUOTES_MODAL':
            return { ...state, isAddQuotesModalOpen: true };
        case 'CLOSE_QUOTES_MODAL':
            return { ...state, isAddQuotesModalOpen: false };
        case 'OPEN_PRODUCTS_MODAL':
            return { ...state, isAddProductsModalOpen: true };
        case 'CLOSE_PRODUCTS_MODAL':
            return { ...state, isAddProductsModalOpen: false };

        default:
            return state;
    }
}

export default productsUiReducer;
