import React from "react";
import { Modal, FileInput, ControlledPopup } from "dyl-components";
import { Grid, Checkbox, Button } from "semantic-ui-react";
import { connect } from "react-redux";

import { TEMPLATE_TYPES } from "shared/forms/TemplateSelector";
import BulkTextForm from "shared/forms/BulkTextForm";
import templateActions from "actions/template";
import textActions from "actions/text";

import { PhoneUtil } from "utils";

import "./TextMessageModal.scss";

export const TEXT_MESSAGE_MODAL_ID = "TEXT_MESSAGE";

const BulkTextMessageModal = ({
    open,
    onClose,
    contacts,
    onChange,
    onSelectTemplate,
    body,
    selectedTemplate,
    sendBulkSMSText,
    onAddShortcode,
    isReadingUser,
    isSendingSMSText,
    isSendingMMSText
}) => (
        <Modal
            open={open}
            className="TextMessageModal"
            onClose={onClose}
            animated={false}
        >
            <Modal.Header>Text Message</Modal.Header>
            <Modal.Content>
                <BulkTextForm
                    contacts={contacts}
                    message={body}
                    onChange={onChange}
                    onSelectTemplate={onSelectTemplate}
                    selectedTemplate={selectedTemplate}
                    name="body"
                    onAddShortcode={onAddShortcode}
                />
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <div>
                                <Checkbox label="Consent to Text Messaging" />
                            </div>
                            <div className="TextMessageModal__consent">
                                <p>
                                    I agree that I have consent allowing me to send
                                    this message and that the intended recipient has
                                    not previously opted out.
                            </p>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <div>
                                <FileInput />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button basic onClick={onClose}>
                    Cancel
            </Button>
                <ControlledPopup
                    trigger={<Button color='blue'>Blast</Button>}
                    onConfirm={sendBulkSMSText}
                    header={`Send this message to all ${contacts} contacts?`}
                    inverted
                />
            </Modal.Actions>
        </Modal>
    );

class BulkTextMessageModalContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            body: "",
            isConfirmSendMessagesModalOpen: false,
        };
        this.onChange = this.onChange.bind(this);
        this.onSelectTemplate = this.onSelectTemplate.bind(this);
        this.onAddShortcode = this.onAddShortcode.bind(this);
    }

    onChange(_, { name, value }) {
        this.setState({ [name]: value });
    }

    onSelectTemplate(_, { value }) {
        this.props.onReadTemplate(value).then(({ message }) => {
            this.setState({ body: message, selectedTemplate: value });
        });
    }

    onAddShortcode(currentlyFocusedElement, value) {
        currentlyFocusedElement.focus();
        let fieldWithShortCode = `${this.state[
            currentlyFocusedElement.name
        ].substr(
            0,
            currentlyFocusedElement.selectionStart
        )}${value}${this.state[currentlyFocusedElement.name].substr(
            currentlyFocusedElement.selectionStart
        )}`;
        this.setState({
            [currentlyFocusedElement.name]: fieldWithShortCode
        });
    }

    sendBulkSMSText = () => {
        console.log('Sent Bulk Messages');
    };

    onShowConfirmSendMessagesModal = () => {
        this.setState({
            isConfirmSendMessagesModalOpen: true
        });
    }

    onHideConfirmSendMessagesModal = () => {
        this.setState({
            isConfirmSendMessagesModalOpen: false
        });
    }

    render() {
        return (
            <BulkTextMessageModal
                onChange={this.onChange}
                onSelectTemplate={this.onSelectTemplate}
                onAddShortcode={this.onAddShortcode}
                onShowConfirmSendMessagesModal={this.onShowConfirmSendMessagesModal}
                sendBulkSMSText={this.sendBulkSMSText}
                {...this.props}
                {...this.state}
            />
        );
    }
}

const mapDispatchToProps = dispatch => ({
    onReadTemplate: id => {
        return dispatch(
            templateActions.getTemplate(id, { type: TEMPLATE_TYPES.SMS })
        );
    },
    onSendSMSText: smspayload => {
        return dispatch(textActions.sendSMSText(smspayload));
    }
});

const mapStateToProps = state => {
    return {
        isSendingSMSText: state.text.isSendingSMSText,
        isSendingMMSText: state.text.isSendingMMSText,
        user_phones: state.user.phones.map(({ phone }) => ({
            key: phone,
            value: phone,
            text: PhoneUtil.formatPhoneNumber(phone)
        })),
        isReadingUser: state.user.isReadingUser
    };
};

const ConnectedTextMessageModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(BulkTextMessageModalContainer);
ConnectedTextMessageModal.ID = "TEXT_MESSAGE";

export default ConnectedTextMessageModal;
