import React from 'react';
import { Tab } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { StringUtils } from 'utils';

export const ViewportModePane = () => {
    const CleanHTML = StringUtils.CleanHTML;
    const {email} = useSelector(state => state.lead_ingestion);
    const { html, text } = email.email;   


  return (
    <Tab.Pane>
     {html ? 
         /** This is check for html and display it formatted OR if it's just atext email ... diplay the email */
        <CleanHTML>{html}</CleanHTML>
        :
        <pre>{text}</pre>
     }
      
    </Tab.Pane>
  );
}
