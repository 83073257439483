import React from "react";
import {
    ClearableCheckboxDropdown,
    Icon,
    Table,
} from "dyl-components";
import { Popup, Table as SemanticTable } from "semantic-ui-react";
import { useSearchParams } from "react-router-dom";
import SelectAllCheckbox from "shared/SelectAllCheckbox";
import { PhoneUtil } from "utils";

const TYPE_FILTER = [
    { key: "tollfree", value: "tollfree", text: "Toll Free" },
    { key: "local", value: "local", text: "Local" },
];

const Header = ({ onFilter, count, numbers }) => {
    const [params] = useSearchParams();

    return (
        <Table.Row>
            <SemanticTable.HeaderCell
                width={1}
            >
                <SelectAllCheckbox 
                    count={count}
                    data={numbers}
                    type={"DYL numbers"}
                    idProperty={"number"}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={3}
            >
                Number
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={1}
            >
                <ClearableCheckboxDropdown
                    text="Type"
                    name="type"
                    options={TYPE_FILTER}
                    onChange={(_, { name, value }) => {
                        onFilter(_, { name, value });
                    }}
                    value={params.get("type")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                <ClearableCheckboxDropdown
                    text="Destination"
                    name="destination_type"
                    options={PhoneUtil.DESTINATION_TYPES}
                    onChange={(_, { name, value }) => {
                        onFilter(_, { name, value });
                    }}
                    value={params.get("destination_type")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                Label
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                Caller ID Display 
                <Popup
                    trigger={<Icon className='fas fa-circle-info' style={{marginLeft: 10}}/>}
                    content={
                        <span>
                            Inbound display when the number is called. If no caller display
                            is added, then it will populate with the caller ID.
                        </span>
                    }
                    inverted
                    hoverable
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                CNAM
                <Popup
                    trigger={<Icon className='fas fa-circle-info' style={{marginLeft: 10}}/>}
                    content={<span>Outbound display when the number is calling out</span>}
                    inverted
                    hoverable
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell 
                width={1}
            >
                Actions
            </SemanticTable.HeaderCell>
        </Table.Row>
    );
};

export default Header;
