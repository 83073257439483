const initialState = {
    isReadingCallRecordingsSettingsOptions: false,
    options: [],
    isUserAccessEnabled: false,
    isDeleteRecordingsEnabled: false,
    isRecordingConsented: false,
    disabled: false
};

export default initialState;
