import React from 'react';
import { Controller } from 'react-hook-form';
import { Form } from 'semantic-ui-react';

const ContactGroupForm = ({control, group, isSubgroup}) => {
    return (
        <Form>
            <Form.Group>
                <Controller 
                    name={"name"}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Form.Input
                            onChange={onChange}
                            value={value}
                            label={isSubgroup ? 'Subgroup Name' : 'Group Name'}
                            error={error && error.message}
                            placeholder="Enter Name"
                            required
                        />
                    )}
                />
                {!isSubgroup && (
                    <Controller
                        name={"shared_type"}
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <Form.Select
                                className='GroupForm__sharedSelect'
                                disabled={group}
                                name={name}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                label='Shared Type'
                                placeholder='Select Type'
                                options={[
                                    {text: "Personal", value: "personal"},
                                    {text: "Company", value: "company"}
                                ]}
                                required
                            />
                        )}
                    />
                )}
            </Form.Group>
        </Form>
    ); 
}

export default ContactGroupForm;