import React, { useState } from 'react';
import { Table, EmptyListPlaceholder, CSVViewer, Notification, STATUS_TYPES } from 'dyl-components';
import { Table as SemanticTable } from 'semantic-ui-react';
import { ImportTableHeader } from './Header';
import { ImportTableRow } from './Row';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';

import leadIngestionActions from 'actions/lead_ingestion';

export const ImportTable = () => {
    const dispatch = useDispatch();
    const [idOfFileBeingPreviewed, setIdOfFileBeingPreviewed] = useState(null);

    const importDataReducer = useSelector(state => state.import_data);
    const isReadingImportCSV = useSelector(state => state.import_data.isReadingImportCSV);

    const { import_data, import_count, isReadingImports, csv } = importDataReducer;

    const onPreview = (file_id) => {
        setIdOfFileBeingPreviewed(file_id);
        dispatch(leadIngestionActions.readCSVFile(encodeURIComponent(file_id))).catch(error => {
            console.log(error)
            Notification.alert('Failed to retrieve CSV file', STATUS_TYPES.ERROR, true);
        });
    }

    const onClosePreview = () => {
        setIdOfFileBeingPreviewed(null);
    }

    const fileBeingPreviewed = import_data.find(item => item.file_id === idOfFileBeingPreviewed)

    return (
        <React.Fragment>
            <Table striped={false} unstackable>
                <SemanticTable.Header className='Table__header--primary'>
                    <ImportTableHeader />
                </SemanticTable.Header>
                <Table.Body className='ImportTable'>
                    {import_data.map((data, index) => (
                        <ImportTableRow key={index} importItem={data} onPreview={onPreview} />
                    ))}
                </Table.Body>
            </Table>
            {!isReadingImports && import_count === 0 &&
                <EmptyListPlaceholder name='Import' subtitle={
                    <span>
                        Add an Import by clicking on the <Link to='/settings/import-data/setup'>+ New Import </Link> button on the top-right corner.
                    </span>
                } />
            }
            <CSVViewer
                title={fileBeingPreviewed?.file_name}
                csv={csv ? XLSX.read(csv, { type: 'string' }) : null}
                isPreviewing={idOfFileBeingPreviewed !== null}
                isReadingCSV={isReadingImportCSV}
                onClosePreview={onClosePreview}
            />
        </React.Fragment>

    );
}




