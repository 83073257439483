import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import EMAIL_ICON from 'shared/constants/EMAIL_ICON';

const Email = ({ email, email_type }) => (
    <React.Fragment>
        <Popup
            trigger={<Icon name={EMAIL_ICON[email_type] || 'envelope'} color='grey' />}
            content={email_type}
            position='top center'
            inverted
        />
        <p className='ContactInfo__contact'>{email}</p>
    </React.Fragment>
);

export default Email;
