import React from 'react';
import { Icon, Pagination, Input, Dimmer, Loader } from 'semantic-ui-react';
import { Table, CheckboxDropdown, Notification, STATUS_TYPES } from 'dyl-components';
import { connect } from 'react-redux';

import './LeadConnectTable.scss';
import LeadConnectTableRow from './LeadConnectTableRow';
import leadConnectActions from 'actions/lead_connect';

export const LeadConnectTable = ({ 
    leads, 
    onCancel,
    activeSortDirectionForDateReceived,
    onSort,
    count,
    limit
}) => (
    <Table striped stackable>
        <Table.Header>
            <Table.Row>
            <Table.HeaderCell resizable>
                Received
                <Icon className={`TableHeader__sort ${activeSortDirectionForDateReceived === 'asc' ? '' : 'WorkflowTableHeader__sort--inactive'}`} name='arlead up' link onClick={() => {onSort('asc', 'date_received')}} />
                <Icon className={`TableHeader__sort ${activeSortDirectionForDateReceived === 'desc' ? '' : 'WorkflowTableHeader__sort--inactive'}`} name='arlead down' link onClick={() => {onSort('desc', 'date_received')}} />
            </Table.HeaderCell>
            <Table.HeaderCell resizable>
                Contact <Input className='TableHeader__search' transparent type='text' icon='search' iconPosition='left' />
            </Table.HeaderCell>
            <Table.HeaderCell resizable>
                <CheckboxDropdown text='Type'>
                    <CheckboxDropdown.Item>All</CheckboxDropdown.Item>
                </CheckboxDropdown>
            </Table.HeaderCell>
            <Table.HeaderCell resizable>
                Missed Calls
                <Icon className={`TableHeader__sort ${activeSortDirectionForDateReceived === 'asc' ? '' : 'WorkflowTableHeader__sort--inactive'}`} name='arlead up' link onClick={() => {onSort('asc', 'missed_calls')}} />
                <Icon className={`TableHeader__sort ${activeSortDirectionForDateReceived === 'desc' ? '' : 'WorkflowTableHeader__sort--inactive'}`} name='arlead down' link onClick={() => {onSort('desc', 'missed_calls')}} />
            </Table.HeaderCell>
            <Table.HeaderCell resizable>
                Last Call
                <Icon className={`TableHeader__sort ${activeSortDirectionForDateReceived === 'asc' ? '' : 'WorkflowTableHeader__sort--inactive'}`} name='arlead up' link onClick={() => {onSort('asc', 'last_call')}} />
                <Icon className={`TableHeader__sort ${activeSortDirectionForDateReceived === 'desc' ? '' : 'WorkflowTableHeader__sort--inactive'}`} name='arlead down' link onClick={() => {onSort('desc', 'last_call')}} />
            </Table.HeaderCell>
            <Table.HeaderCell resizable>
                Next Call
                <Icon className={`TableHeader__sort ${activeSortDirectionForDateReceived === 'asc' ? '' : 'WorkflowTableHeader__sort--inactive'}`} name='arlead up' link onClick={() => {onSort('asc', 'next_call')}} />
                <Icon className={`TableHeader__sort ${activeSortDirectionForDateReceived === 'desc' ? '' : 'WorkflowTableHeader__sort--inactive'}`} name='arlead down' link onClick={() => {onSort('desc', 'next_call')}} />
            </Table.HeaderCell>
            <Table.HeaderCell resizable>Actions</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {(!leads || leads.length <= 0) && (
                <Table.Row>
                    <Table.Cell textAlign='center' colSpan={7}>
                        <em>No leads found</em>
                    </Table.Cell>
                </Table.Row>
            )}
            {leads && leads.map(lead => (
                <LeadConnectTableRow 
                    lead={lead}
                    onCancel={onCancel}
                />
            ))}
        </Table.Body>
        <Table.Footer>
            <Table.Row>
                <Table.HeaderCell colSpan={7} textAlign='right'>
                    <Pagination
                        boundaryRange={0}
                        defaultActivePage={1}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={2}
                        totalPages={Math.ceil(count/limit)}
                    />
                </Table.HeaderCell>
            </Table.Row>
        </Table.Footer>
    </Table>
)

class LeadConnectTableContainer extends React.Component {
    componentDidMount() {
        this.props.onReadLeads(this.props.currentPage, this.props.limit);
    }

    onCancel(leadId) {
        let leads = this.state.leads.slice(0);
        let indexOfRowToRemove = leads.findIndex(lead => lead.id === leadId);
        leads.splice(indexOfRowToRemove, 1);
        Notification.alert(`Lead removed from workflow`, STATUS_TYPES.SUCCESS, true);
        this.setState({ leads });
    }

    render() {
        if (this.props.isReadingLeads) {
            return (
                <Dimmer active>
                    <Loader active />
                </Dimmer>
            )
        }
        return (
            <LeadConnectTable 
                {...this.props}
                onCancel={this.onCancel.bind(this)}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        leads: state.leadConnect.leads,
        count: state.leadConnect.count,
        isReadingLeads: state.leadConnect.isReadingLeads,
        currentPage: state.leadConnect.currentPage,
        limit: state.leadConnect.limit,
        allMarked: state.leadConnect.allMarked
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onReadLeads: (page, limit) => {
            dispatch(leadConnectActions.readLeads({ page, limit }));
        },
        onMarkAll: () => {
            dispatch(leadConnectActions.markAllLeads());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadConnectTableContainer);
