import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Button, Grid, Icon, Dropdown } from 'semantic-ui-react';
import { Modal, ModulesFilter, SelectionsModal, VALIDATION_PATTERNS } from 'dyl-components';

import { CardData, CardDataItem } from 'dyl-components';
import { useSelector } from 'react-redux';
import MasterAccountForm from 'shared/MasterAccountForm';
import AddAccountModal from "../../../shared/modals/AddAccountModal";

import {
    KEY_CREATED_ON, KEY_ACCOUNT_TYPE, KEY_REACTIVATED_ON,
    KEY_CREATED_BY, KEY_OWNER, KEY_REACTIVATED_BY,
    KEY_LAST_MODIFIED, KEY_STAGE, KEY_ACCOUNT_STATUS,
    KEY_LAST_MODIFIED_BY, KEY_CUSTOMER_SINCE, KEY_ACTIONS, 
    KEY_CANCELED_ON, KEY_NAME, KEY_CANCELED_BY
} from './constants';

const TYPES = [
    { id: 'master_account', name: 'Master Accounts', icon: 'fa-solid fa-buildings', width: '16%' },
    { id: 'business', name: 'Businesses', icon: 'fa-solid fa-building' },
    { id: 'household', name: 'Households', icon: 'fa-solid fa-house-chimney-blank' },
    { id: 'customer', name: 'Customers', icon: 'fa-solid fa-users' },
];

const COLUMN_ROWS = [
    [
        { value: KEY_CREATED_ON, label: "Created" },
        { value: KEY_ACCOUNT_TYPE, label: "Type" },
        { value: KEY_CANCELED_BY, label: "Canceled By" }
    ],
    [
        { value: KEY_CREATED_BY, label: "Created By" },
        { value: KEY_OWNER, label: "Owner" },
        { value: KEY_REACTIVATED_ON, label: "Reactivated On" }
    ],
    [
        { value: KEY_LAST_MODIFIED, label: "Last Modified" },
        { value: KEY_STAGE, label: "Stage" },
        { value: KEY_REACTIVATED_BY, label: "Reactivated By" }
    ],
    [
        { value: KEY_LAST_MODIFIED_BY, label: "Last Modified By" },
        { value: KEY_CUSTOMER_SINCE, label: "Customer Since" },
        { value: KEY_ACCOUNT_STATUS, label: "Account Status" }
    ],
    [
        { value: KEY_NAME, label: "Account Name", isForcedChecked: true },
        { value: KEY_CANCELED_ON, label: "Canceled On" },
        { value: KEY_ACTIONS, label: "Actions" }
    ]
];

const AddModalContent = ({ activeContent, onClose }) => {
    const navigate = useNavigate();
    switch (activeContent) {
        case 'BusinessAccount':
            return <AddAccountModal
                account_type={'business'}
                onClose={onClose}
                refreshFromLinking={() => {
                    navigate(`/accounts?page=1`);
                }}
            />;
        case 'HouseholdAccount':
            return <AddAccountModal
                account_type={'household'}
                onClose={onClose}
                refreshFromLinking={() => {
                    navigate(`/accounts?page=1`);
                }}
            />;
        default:
            return null;
    }
}

export const AccountsToolbar = (({ search, onSearchSubmit, cancelFunction, onChangeSearch, isColumnHidden, onToggleColumn, onFilter }) => {
    const { isReadingAccounts, counts } = useSelector(state => ({
        isReadingAccounts: state.account.isReadingAccounts,
        counts: state.account.counts
    }));

    const modalCategories = [
        {
            selections: [
                { key: 'BusinessAccount', text: 'Business Account', icon: 'fa-solid fa-building' },
                { key: 'HouseholdAccount', text: 'Household Account', icon: 'fa-solid fa-house-chimney-blank' }
            ]
        }
    ];

    const [params] = useSearchParams();

    const navigate = useNavigate();

    const [filteredType, setFilteredType] = useState(params.get('filter'));

    const isFilterActive = (type) => {
        return filteredType === type;
    }

    const onFilterByType = (type) => {
        if (filteredType !== type) {
            setFilteredType(type);
            onFilter(null, { name: 'filter', value: type });
        } else {
            setFilteredType('');
            onFilter(null, { name: 'filter', value: '' });
        }
    }

    const getFilterValue = (type) => {
        switch (type) {
            case 'master_account':
                return counts.master_account_count;
            case 'business':
                return counts.business_count;
            case 'household':
                return counts.household_count;
            case 'customer':
                return counts.customer_count;
            default:
                return 0;
        }
    }

    const [linkAccountAction, setLinkAccountAction] = useState(null);

    const openLinkAccountModal = (action) => {
        setLinkAccountAction(action);
    }

    const closeAccountCreationModal = () => {
        setLinkAccountAction(null);
    }

    const isAccountCreationModalOpen = (action) => {
        return action === linkAccountAction;
    }

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <div className='Accounts__info'>
                            {TYPES.map(type => (
                                <div style={{ marginRight: '1em', width: type.width || '11%' }}>
                                    <CardData active={isFilterActive(type.id)} onClick={() => { onFilterByType(type.id); }}>
                                        <CardDataItem>
                                            <Icon style={{ margin: '0 5px' }} className={type.icon} /> <strong>{type.name}: </strong>&nbsp;<span className={`FilterValue${getFilterValue(type.id) === 0 ? '' : '--not-zero'}`}> {getFilterValue(type.id)} </span>
                                            {isFilterActive(type.id) &&
                                                <Icon style={{ marginLeft: 10 }} className='fa-solid fa-circle-x' link onClick={() => onFilterByType(type.id)} />
                                            }
                                        </CardDataItem>
                                    </CardData>
                                </div>
                            ))}
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <ModulesFilter
                            isLoading={isReadingAccounts}
                            search={search}
                            onSearchSubmit={onSearchSubmit}
                            cancelFunction={cancelFunction}
                            onChangeSearch={onChangeSearch}
                            columnRows={COLUMN_ROWS}
                            columnCount={3}
                            isColumnHidden={isColumnHidden}
                            onToggleColumn={onToggleColumn}
                            validationPattern={VALIDATION_PATTERNS.alphanumeric_w_hyphen_and_or_ampersand_search}
                        />
                    </Grid.Column>
                    <Grid.Column width={3} floated='right'>
                        <Dropdown
                            as={Button}
                            floated='right'
                            floating
                            pointing='top left'
                            options={[
                                { key: 0, value: 'master', text: 'New Master Account', icon: 'fa-solid fa-buildings' },
                                { key: 1, value: 'account', text: 'New Account', icon: 'fa-solid fa-screen-users' },
                            ]}
                            onChange={(_, { value }) => { openLinkAccountModal(value) }}
                            color='primary'
                            text={<span><Icon name='plus' /> New Account</span>}
                            className='AddAccountButton'
                            value={linkAccountAction}
                            selectOnBlur={false}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Modal open={isAccountCreationModalOpen('master')} onClose={closeAccountCreationModal}>
                <MasterAccountForm
                    close={closeAccountCreationModal}
                    refreshFromLinking={() => {
                        navigate(`/accounts?page=1`);
                    }}
                />
            </Modal>
            <SelectionsModal
                contentModalSize={activeContent => {
                    if (activeContent === 'selection') {
                        return 'tiny';
                    }
                    return 'big';
                }}
                open={isAccountCreationModalOpen('account')}
                selectionHeader='Create Account'
                categories={modalCategories}
                ToRender={AddModalContent}
                close={closeAccountCreationModal}
                hasCancel={false}
                hasFirstSelection={false}
            />
        </React.Fragment>
    )
})
