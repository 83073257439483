import React from 'react';
import { Table } from 'dyl-components';
import { connect } from 'react-redux';

import CategoryRow from './CategoryRow';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';

const CategoriesTable = ({ categories, onDelete, onUpdate, isReadingCategories }) => {
    if (isReadingCategories) {
        return (
            <Segment>
                <Dimmer active>
                    <Loader active />
                </Dimmer>
            </Segment>
        )
    }
    if (categories == null || categories.length === 0) {
        return (
            <Segment textAlign='center'>
                <em>There are no categories</em>
            </Segment>
        )
    }
    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Tags</Table.HeaderCell>
                    <Table.HeaderCell>Options</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {categories.map(category => (
                    <CategoryRow
                        key={category.id}
                        category={category}
                        onDelete={onDelete}
                        onUpdate={onUpdate}
                    />
                ))}
            </Table.Body>
        </Table>
    )
};

const mapStateToProps = state => {
    return {
        categories: state.tag_categories.listOfTagCategories,
        isReadingCategories: state.tag_categories.isGettingListOfTagCategories
    }
}

export default connect(mapStateToProps, null)(CategoriesTable);
