
import routes from "actions/routes";
import { generateReadOneActionCreator, generateCreateActionCreator, generateUpdateActionCreator, generateDeleteActionCreator, generateReadActionCreator } from "actions/crudActionCreators";
import ACTION_NAMES from "actions/ACTION_NAMES";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import axiosInstance from "actions/axiosInstance";

export default {
    readCompany: generateReadActionCreator(routes.API_COMPANY, ACTION_NAMES.COMPANY),
    createCompany: generateCreateActionCreator(routes.API_COMPANY, ACTION_NAMES.COMPANY, () => { }, () => { }),
    updateCompany: generateUpdateActionCreator(routes.API_COMPANY, ACTION_NAMES.COMPANY, () => { }, () => { }),

    readCompanyLocations: generateReadOneActionCreator(routes.API_COMPANY_LOCATIONS, ACTION_NAMES.COMPANY_LOCATIONS),
    createCompanyLocations: generateCreateActionCreator(routes.API_COMPANY_LOCATIONS, ACTION_NAMES.COMPANY_LOCATIONS, () => { }, () => { }),
    updateCompanyLocation: generateUpdateActionCreator(routes.API_COMPANY_LOCATIONS, ACTION_NAMES.COMPANY_LOCATIONS, () => { }, () => { }),
    deleteCompanyLocation: generateDeleteActionCreator(routes.API_COMPANY_LOCATIONS, ACTION_NAMES.COMPANY_LOCATIONS, () => { }, () => { }),

    readCompanyPhones: generateReadOneActionCreator(routes.API_COMPANY_PHONES, ACTION_NAMES.COMPANY_PHONES),
    createCompanyPhones: generateCreateActionCreator(routes.API_COMPANY_PHONES, ACTION_NAMES.COMPANY_PHONES, () => { }, () => { }),
    updateCompanyPhone: generateUpdateActionCreator(routes.API_COMPANY_PHONES, ACTION_NAMES.COMPANY_PHONES, () => { }, () => { }),
    deleteCompanyPhone: generateDeleteActionCreator(routes.API_COMPANY_PHONES, ACTION_NAMES.COMPANY_PHONES, () => { }, () => { }),

    readCompanySocialMedias: generateReadOneActionCreator(routes.API_COMPANY_SOCIAL_MEDIAS, ACTION_NAMES.COMPANY_SOCIAL_MEDIAS),
    createCompanySocialMedias: generateCreateActionCreator(routes.API_COMPANY_SOCIAL_MEDIAS, ACTION_NAMES.COMPANY_SOCIAL_MEDIAS, () => { }, () => { }),
    updateCompanySocialMedia: generateUpdateActionCreator(routes.API_COMPANY_SOCIAL_MEDIAS, ACTION_NAMES.COMPANY_SOCIAL_MEDIAS, () => { }, () => { }),
    deleteCompanySocialMedia: generateDeleteActionCreator(routes.API_COMPANY_SOCIAL_MEDIAS, ACTION_NAMES.COMPANY_SOCIAL_MEDIAS, () => { }, () => { }),

    readIndustry: generateReadOneActionCreator(routes.API_COMPANY_INDUSTRY, ACTION_NAMES.COMPANY_INDUSTRY),

    readSignatures: generateReadActionCreator(routes.API_SIGNATURE, ACTION_NAMES.COMPANY_SIGNATURE),
    createSignature: generateCreateActionCreator(routes.API_SIGNATURE, ACTION_NAMES.COMPANY_SIGNATURE),
    updateSignature: generateUpdateActionCreator(routes.API_SIGNATURE, ACTION_NAMES.COMPANY_SIGNATURE),
    deleteSignature: generateDeleteActionCreator(routes.API_SIGNATURE, ACTION_NAMES.COMPANY_SIGNATURE),

    readSignaturePreferences: generateReadActionCreator(routes.API_SIGNATURE_PREFERENCES, ACTION_NAMES.COMPANY_SIGNATURE_PREFERENCES, () => { }, () => { }),
    updateSignaturePreferences: generateUpdateActionCreator(routes.API_SIGNATURE_PREFERENCES, ACTION_NAMES.COMPANY_SIGNATURE_PREFERENCES, () => { }, () => { }),

    readCompanyLogo: ({ isThumbnail = true }) => async dispatch => {
        try {
            dispatch({
                type: getNamedAction(ACTION_NAMES.COMPANY_LOGO, CRUD_ACTION_TYPES.READ_REQUEST)
            })
            let headers = { "X-TOKEN": (await axiosInstance.instance1.get(`/v1/jwt`)).data.token };
            let r = await axiosInstance.image_instance.get('/v1/logo', { params: { thumbnail: isThumbnail }, headers });
            dispatch({
                type: getNamedAction(ACTION_NAMES.COMPANY_LOGO, CRUD_ACTION_TYPES.READ_SUCCESS),
                data: { image: isThumbnail ? r.data.thumbnail : r.data.image }
            });
        }
        catch (err) {
            console.log(err);
            dispatch({
                type: getNamedAction(ACTION_NAMES.COMPANY_LOGO, CRUD_ACTION_TYPES.READ_FAILURE),
            });
        }
    },
    uploadCompanyLogo: (file) => async dispatch => {

        dispatch({
            type: getNamedAction(ACTION_NAMES.COMPANY_LOGO, CRUD_ACTION_TYPES.CREATE_REQUEST)
        });

        try {
            let formData = new FormData();
            formData.append("file", file, file.name);
            await axiosInstance.image_instance.post(
                `/v1/logo`, formData,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                        "x-token": (await axiosInstance.instance1.get(`/v1/jwt`)).data.token
                    },
                }
            );
            dispatch({
                type: getNamedAction(ACTION_NAMES.COMPANY_LOGO, CRUD_ACTION_TYPES.CREATE_SUCCESS),
            });

        } catch (err) {
            console.log(err)
            dispatch({
                type: getNamedAction(ACTION_NAMES.COMPANY_LOGO, CRUD_ACTION_TYPES.CREATE_FAILURE),
            });
        }

    },
    deleteCompanyLogo: () => async dispatch => {
        try {
            dispatch({
                type: getNamedAction(ACTION_NAMES.COMPANY_LOGO, CRUD_ACTION_TYPES.DELETE_REQUEST)
            })
            let headers = { "X-TOKEN": (await axiosInstance.instance1.get(`/v1/jwt`)).data.token };
            await axiosInstance.image_instance.delete('/v1/logo', { headers });
            dispatch({
                type: getNamedAction(ACTION_NAMES.COMPANY_LOGO, CRUD_ACTION_TYPES.DELETE_SUCCESS)
            });
        }
        catch (err) {
            console.log(err);
            dispatch({
                type: getNamedAction(ACTION_NAMES.COMPANY_LOGO, CRUD_ACTION_TYPES.DELETE_FAILURE),
            });
        }
    }
}
