import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Icon } from 'semantic-ui-react';
import { Button } from 'dyl-components';

import { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import { StringUtils } from 'utils';

const Toolbar = ({ type }) => {
    const [selectedFees, , areFeesInAllPagesSelected] = useContext(BulkActionsContext);

    const hasSelectedFees = selectedFees.length || areFeesInAllPagesSelected;

    const [, setIsBulkActionsModalOpen] = useState(false);

    const onOpenBulkActionsModal = () => {
        setIsBulkActionsModalOpen(true);
    }

    return (
        <Grid>
            <Grid.Row columns='equal' verticalAlign='middle'>
                <Grid.Column></Grid.Column>
                <Grid.Column width={4} floated='right'>
                    <Button as={Link} to={`/settings/product-catalog-quotes-invoices/${type === "tax" ? "taxes" : "fees"}/create`} floated='right' className='FeesToolbar__NewFeesButton'>
                        <Icon name='plus' /> New {StringUtils.capitalize(type)}
                    </Button>
                    <Button floated='right' className='FeesToolbar__NewFeesButton' disabled={!hasSelectedFees} onClick={onOpenBulkActionsModal}>
                        Bulk Action
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default Toolbar;
