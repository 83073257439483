import React from 'react';
import { Table } from 'dyl-components';
import VoicemailHistoryRow from './VoicemailHistoryRow';

const VoicemailHistoryTable = ({ voicemails, otherProps }) => (
    <Table>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    TimeStamp
                </Table.HeaderCell>
                <Table.HeaderCell>
                    Contact
                </Table.HeaderCell>
                <Table.HeaderCell>
                    Mailbox
                </Table.HeaderCell>
                <Table.HeaderCell>
                    Listened
                </Table.HeaderCell>
                <Table.HeaderCell>
                    Actions
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {voicemails.map(voicemail => (
                <VoicemailHistoryRow voicemail={voicemail} {...otherProps} />
            ))}
        </Table.Body>
    </Table>
);

export default VoicemailHistoryTable;
