import favoritesActions from "actions/favorites";
import { Notification, STATUS_TYPES } from "dyl-components";
import { createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FAVORITE_TYPE } from "shared/constants/FAVORITE_TYPE";
import { StringUtils } from "utils";

export const FavoritesContext = createContext();

const FavoritesProvider = ({ children }) => {
    const { favorites, isAddingFavorite, isRemovingFavorite } = useSelector(state => ({
        favorites: state.favorites.favorites,
        isAddingFavorite: state.favorites.isAddingFavorite,
        isRemovingFavorite: state.favorites.isRemovingFavorite
    }));

    const isInFavorites = ({ favorite_type, external_id, setting_report_type }) => {
        const isFavorite = favorites.findIndex(favorite => (
            favorite.favorite_type === favorite_type &&
            (favorite.setting_report_type ? favorite.setting_report_type === setting_report_type :
                favorite.external_id === (!isNaN(external_id) ? Number(external_id) : undefined))
        )) !== -1;
        if (favorite_type !== FAVORITE_TYPE.GROUP) {
            return isFavorite;
        }
        if (!isFavorite) {
            const subgroups = favorites.filter(favorite => favorite.favorite_type === FAVORITE_TYPE.GROUP && favorite.children?.length).map(favoriteGroup => favoriteGroup.children).flat();
            const subgroup = subgroups.find(subgroup => subgroup.id === external_id);
            return Boolean(subgroup);
        }
        return isFavorite;
    }
    
    const dispatch = useDispatch();

    const onToggleFavorite = async (details) => {
        const isFavorite = isInFavorites(details);
        try {
            await (isFavorite ? dispatch(favoritesActions.deleteFavorite(null, null, null, null, [details])) : dispatch(favoritesActions.addFavorites(details)));
            Notification.alert(`Successfully ${isFavorite ? "removed from" : "added to"} favorites`, STATUS_TYPES.SUCCESS);
            dispatch(favoritesActions.readFavorites());
        } catch (e) {
            if (e.Message === "you have reach limit of 50") {
                Notification.alert(`Maximum favorites ${StringUtils.capitalize(details.favorite_type)} reached`, STATUS_TYPES.ERROR);
            } else {
                Notification.alert(`Failed to ${isFavorite ? "remove from" : "add to"} favorites`, STATUS_TYPES.ERROR);
            }
        }
    }

    return (
        <FavoritesContext.Provider value={{ onToggleFavorite, isInFavorites, isAddingFavorite, isRemovingFavorite }}>
            {children}
        </FavoritesContext.Provider>
    )
}

export default FavoritesProvider;
