import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Dropdown, Icon, Loader, Popup, Search, Segment } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import usersActions from 'actions/users';

import './UserSearchField.scss';

const resultRenderer = ({ first_name, last_name, email }) => {
    const name = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : "Unknown Name";
    return (
        <div style={{ verticalAlign: 'middle' }}>
            <Avatar name={name} maxInitials={2} round size='3em' /> <div style={{ display: "inline-block", paddingLeft: "1em" }}>
                <div><b>{name}</b></div>
                <div>{email}</div>
            </div>
        </div>
    )
}

const UserSearchField = ({
    searchValue,
    isSearchingUsers,
    onUserSearchChange,
    onFocusSearch,
    onBlur,
    open,
    users,
    onSelectUser,
    displayed_selected_user,
    clearSelectedUser,

    upward,
    placeholder,

    multiple,
    onRemove,
    onRemoveOneUser,
    ownedOrCoOwned,
    name,
    size = 'small',
    selected,

    selected_outside,
    one_user_display
}) => {
    const [focused, setFocused] = useState(false);

    const selectedUsersRender = multiple ? selected.map(({ user_id, name: username, email }) => (
        <Popup
            inverted
            content={(
                <div>
                    <p>Name: {username}</p>
                    <p>Email: {email}</p>
                </div>
            )}
            trigger={(
                <Segment size={size} className="UserSearchField__user">
                    <Avatar name={username} maxInitials={2} round size='3em' className='UserSearchField__noFontSize' />
                    <div className="UserSearchField__information">
                        <div><span className="SelectedUser__name"><b>{username}</b></span></div>
                        <div><span className="SelectedUser__email"><b>{email}</b></span></div>
                    </div>
                    <div className={`UserSearchField__remove-user`}>
                        <Icon
                            name='delete'
                            link
                            onClick={() => { onRemove(null, { name, value: selected.filter(selected_user => selected_user.user_id !== user_id) }); }}
                        />
                    </div>
                </Segment>
            )} />
    )) : [];

    let selectedUserRender = null;

    if (displayed_selected_user && displayed_selected_user.email) {
        const { username, email } = displayed_selected_user;
        selectedUserRender = (
            <Popup
                inverted
                content={(
                    <div>
                        <p>Name: {username}</p>
                        <p>Email: {email}</p>
                    </div>
                )}
                trigger={(
                    <div>
                        <Segment basic style={{ padding: 0, margin: 0 }} onClick={() => {
                        }} size='tiny' className="UserSearchField__user UserSearchField_ReportsTo_user">
                            <Avatar name={username} maxInitials={2} round size='3em' className='UserSearchField__noFontSize' />
                            <div className="UserSearchField__information">
                                <div><span className="SelectedUser__name"><b>{username}</b></span></div>
                                <div><span className="SelectedUser__email"><b>{email}</b></span></div>
                            </div>
                        </Segment>
                        <div className={`UserSearchField__ReportsTo_remove-user`}>
                            <Icon
                                name='close'
                                link
                                onClick={() => {
                                    onRemove(null, { name, value: selected.filter(selected_user => selected_user.user_id !== displayed_selected_user.value) });
                                    selectedUserRender = null;
                                    clearSelectedUser();
                                }}
                            />
                        </div>
                    </div>
                )}
            />
        )
    }

    return (
        !multiple ? (
            <React.Fragment>
                <div className='UserSearchField__dropdown__close-flex'>
                    <Dropdown
                        upward={upward}
                        loading={isSearchingUsers}
                        onSearchChange={onUserSearchChange}
                        options={!isSearchingUsers && users.map(user => ({
                            key: user.user_id,
                            value: user.user_id,
                            text: `${user.first_name} ${user.last_name} ${user.email}`,
                            content: (resultRenderer({ ...user }))
                        }))}
                        onOpen={() => {
                            if (!displayed_selected_user) {
                                setFocused(true);
                                onUserSearchChange(null, { searchQuery: '' });
                            }
                        }}
                        search={focused}
                        searchQuery={focused ? searchValue : ''}
                        onChange={(e, { value }) => {
                            if (value === '' && onRemoveOneUser && ownedOrCoOwned) {
                                onRemoveOneUser(null, { name: ownedOrCoOwned });
                            }
                            onSelectUser(null, { result: users.find(({ user_id }) => user_id === value) }); setFocused(false)
                        }}
                        placeholder={placeholder}
                        fluid
                        onClose={() => { setFocused(false) }}
                        selection
                        selectOnBlur={false}
                        trigger={focused ? <div></div> : selectedUserRender}
                        focus={focused}
                        defaultValue={one_user_display && selected.length > 0 ? selected[0] : undefined}
                        onFocus={onFocusSearch}
                        value={displayed_selected_user}
                    />
                </div>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <Segment className={`UserSearchField${upward ? " UserSearchField--upward" : ""}`} size={size}>
                    {!selected_outside && selectedUsersRender}
                    <Search
                        loading={isSearchingUsers}
                        onSearchChange={onUserSearchChange}
                        onFocus={onFocusSearch}
                        onBlur={onBlur}
                        results={users.filter(({ user_id }) => !selected.map(({ user_id }) => user_id).includes(user_id))}
                        resultRenderer={resultRenderer}
                        value={searchValue}
                        onResultSelect={onSelectUser}
                        placeholder={placeholder}
                        fluid
                        icon="caret down"
                        open={open} />
                </Segment>
                {selected_outside && selectedUsersRender}
            </React.Fragment>
        )
    );
};

const UserSearchFieldContainer = (props) => {
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(props.selected || []);

    const [typingTimeout, setTypingTimeout] = useState(0);
    const [isSearchingUsers, setIsSearchingUsers] = useState(false);
    const [displayedSelectedUser, setDisplayedSelectedUser] = useState(props.displayed_selected_user || null);
    const [allUsers, setAllUsers] = useState([]);
    useEffect(() => {
        props.onSearchUsers({ limit: 500 }).then(res => {
            const users = props?.excluded_users ? res.data.filter(user => !props.excluded_users.includes(user.user_id)) : res.data;
            setAllUsers(users)
            setIsSearchingUsers(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.one_user_display && selected.length > 0 && props.ownedOrCoOwned) {
            const user_id = selected[0]
            let selectedUser = allUsers.find(item => { return item.user_id === user_id })
            if (selectedUser) {
                const username = selectedUser.first_name || selectedUser.last_name ? `${selectedUser.first_name || ''} ${selectedUser.last_name || ''}` : "Unknown Name";
                setDisplayedSelectedUser({ username, email: selectedUser.email });
                props.onChange(null, { name: props.ownedOrCoOwned, value: user_id })
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allUsers])

    const onUserSearchChange = (e, data) => {
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const search = data.value || data.searchQuery;
        const filterAllUsers = (search = '') => {
            const filteredData = allUsers.filter(({ first_name = '', last_name = '', email = '' }) => {
                const searchStr = search.toLowerCase();
                const searchObject = `${first_name} ${last_name} ${email}`.toLowerCase();

                return searchObject.includes(searchStr);
            });
            return filteredData;
        }

        setTypingTimeout(setTimeout(() => {
            const filteredResults = filterAllUsers(search);
            setResults(filteredResults)
            setIsSearchingUsers(false);
            setOpen(true);
        }, 100));
        setSearchValue(search);
        setIsSearchingUsers(true)
    }

    const onFocusSearch = () => {
        if (!displayedSelectedUser || !displayedSelectedUser.value) {
            props.onSearchUsers({ limit: 300, ...(searchValue ? { search: searchValue } : {}) }).then((res) => {
                const filteredResult = res.data?.filter(user => !props.excluded_users.includes(user.user_id));
                setResults(filteredResult);
                setOpen(true)
            });
        }
    }

    const onBlur = () => {
        setOpen(false);
    }

    const clearSelectedUser = () => {
        setDisplayedSelectedUser(null);
        setSearchValue('');
        setOpen(false);
    }

    const onSelectUser = (e, { result }) => {
        if (!props.multiple) {
            if (!result) {
                setSearchValue("");
                setDisplayedSelectedUser(null);
                props.onChange(e, { name: props.name, value: '', result: '' })
            } else {
                const { user_id, first_name, last_name, email } = result;
                const username = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : "Unknown Name";
                setSearchValue(username)
                if (props.display_selected_user) {
                    setDisplayedSelectedUser({ username, email });
                    props.onChange(e, { name: props.name, value: user_id, result })
                }
            }
        } else {
            const { user_id, first_name, last_name, email } = result;
            const username = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : "Unknown Name";
            const valueAux = props.value.slice(0);
            valueAux.push(user_id);
            const selectedAux = selected.slice(0);
            selectedAux.push({ user_id, name: username, email });
            setSelected(selectedAux)
            setSearchValue('');
            props.onChange(e, { name: props.name, value: valueAux })
        }
        setOpen(false);
    }

    const onRemove = (_, { value: selectedParam }) => {
        setSearchValue('')
        setSelected(selectedParam);
        props.onChange(null, { name: props.name, value: selectedParam.map(({ user_id }) => user_id) })
    }

    return (
        <UserSearchField
            upward={props.upward}
            placeholder={props.placeholder}
            users={results}
            isSearchingUsers={props.isSearchingUsers || isSearchingUsers}
            onSelectUser={onSelectUser}
            onUserSearchChange={onUserSearchChange}
            onFocusSearch={onFocusSearch}
            onBlur={onBlur}
            open={open}
            searchValue={searchValue === '' ? searchValue : searchValue || props.searchValue}
            selected={selected}

            multiple={props.multiple}
            name={props.name}
            onChange={props.onChange}
            value={props.value}
            onRemove={onRemove}
            ownedOrCoOwned={props.ownedOrCoOwned}
            onRemoveOneUser={props.onRemoveOneUser}
            selected_outside={props.selected_outside}
            displayed_selected_user={displayedSelectedUser}
            one_user_display={props.one_user_display}
            clearSelectedUser={clearSelectedUser}
        />
    )
}

const UserSearchFieldLoader = (props) => {
    const isReadingUsers = useSelector(state => state.users.isReadingUser);
    if (props.display_selected_user && isReadingUsers) {
        return <Loader />
    }
    return <UserSearchFieldContainer {...props} />
}

const mapStateToProps = (state, { excluded_users }) => {
    return {
        users: state.users.userOptions.filter(user => !excluded_users.includes(user.user_id)),
        isSearchingUsers: state.users.isReadingUsers,
    }
}

const mapDispatchToProps = dispatch => ({
    onSearchUsers: (queryParameters) => {
        return dispatch(usersActions.readUserOptions(queryParameters));
    },
    onGetAllUsers: () => {
        return dispatch(usersActions.readUsers())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchFieldLoader);
