import React from 'react';
import { Table } from 'dyl-components';
import { Select, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { EditableContent } from 'dyl-components';
import MessagingIconButtons from 'shared/MessagingIconButtons';

function getPlayMessageValue(isEditMode, rule, playMessageOptions) {
    if (isEditMode) {
        return rule.playMessage;
    }
    let playMessage = [
        { key: 'None', value: 'None', text: 'None' },
        ...playMessageOptions
    ].filter(playMessageOption => playMessageOption.value === rule.playMessage)[0]
    if (playMessage && playMessage !== 'None') {
        return <Link to={`/settings/ivr_menu?id=${playMessage.value}`}>{playMessage.text}</Link>
    }
    return 'None';
}

const CallRoutingRuleRow = ({ 
    isEditMode, 
    rule,
    playMessageOptions, 
    onChange, 
    onDelete, 
    onUpdate, 
    onEnableEditMode, 
    onDisableEditMode 
}) => (
    <Table.Row active={isEditMode}>
        <Table.Cell>
            <EditableContent
                isEditMode={isEditMode} 
                name='name'
                value={isEditMode ? rule.name : <Link to={`/settings/custom_route?id=${rule.id}`}>{rule.name}</Link>}
                control={Input}
                onChange={onChange}
            />
        </Table.Cell>
        <Table.Cell>
            {isEditMode ? '' : 'IVR Menu: '}
            <EditableContent 
                control={Select}
                name='playMessage'
                value={getPlayMessageValue(isEditMode, rule, playMessageOptions)}
                onChange={onChange}
                isEditMode={isEditMode}
                options={[
                    { key: 'None', value: 'None', text: 'None' },
                    ...playMessageOptions
                ]}
            />
        </Table.Cell>
        <Table.Cell>
           <MessagingIconButtons
                isEditMode={isEditMode}
                onDisableEditMode={onDisableEditMode} 
                onUpdate={onUpdate}
                onEnableEditMode={onEnableEditMode}
                onDelete={onDelete}
                id={rule.id} 
            />
        </Table.Cell>
    </Table.Row>
)

class CallRoutingRuleRowContainer extends React.Component {
    state = {
        rule: this.props.rule,
        isEditMode: false
    }

    onEnableEditMode = () => {
        this.setState({ isEditMode: true });
    }

    onDisableEditMode = () => {
        this.setState({ isEditMode: false, rule: this.props.rule });
    }

    onChange = (_, { name, value }) => {
        let rule = { ...this.state.rule, [name]: value};
        this.setState({ rule });
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false
        }, () => {
            this.props.onUpdate(this.state.rule);
        })
    }

    render() {
        return (
            <CallRoutingRuleRow 
                {...this.props}
                {...this.state}
                onChange={this.onChange}
                onEnableEditMode={this.onEnableEditMode}
                onDisableEditMode={this.onDisableEditMode}
                onUpdate={this.onUpdate}
            />
        )
    }
}

export default CallRoutingRuleRowContainer;
