import React from 'react';
import { NavCard } from 'dyl-components';

const formOptions = [
    {
        name: 'form_settings', header: 'Form Settings', description: 'Manage form settings.', icon: 'file alternate outline'
    },
    {
        name: 'success_message', header: 'Success Message', description: 'Display a success message when users submit your form.', icon: 'fas fa-comment-alt-check'
    },
    {
        name: 'form_theme', header: 'Form Theme', description: 'Stylize your form by adding custom logo, form & page background color.', icon: 'fas fa-paint-roller'
    },
];

export const WebformOptions = ({ activeFormOption, setActiveFormOption }) => (
    <div className='Webform__form-options'>
        {formOptions.map(({ name, header, description, icon }) => (
            <NavCard header={header} description={description} icon={icon} hsize='h5' width='auto' highlight={activeFormOption === name} onClick={() => { setActiveFormOption(name) }} />
        ))}
    </div>
);
