import React from 'react';

import './TemplateSelector.scss';
import { NestedDropdown } from 'dyl-components';

const TemplateSelector = ({
    value,
    parentValue,
    categorized_templates,
    isReading,
    onChange
}) => {
    return (
        <div className='Action__template-selector'>
            <NestedDropdown
                child_value={value}
                parent_value={parentValue}
                loading={isReading}
                nested_options={(categorized_templates || []).filter(category => category.templates?.data?.length > 0).map(category => ({
                    key: category.id,
                    value: category.id,
                    text: category.name,
                    options: (
                        (category.templates?.data || []).map(type => ({
                            key: type.id,
                            value: type.id,
                            text: type.name
                        })) || []
                    )
                }))}
                onChange={(_, { parent_value, child_value }) => {
                    onChange(null, { parent_value, child_value });
                }}
                placeholder={`Select Template`}
                selection
                pointing='top'
            />
        </div>
    );
}

export default TemplateSelector;
