import React from 'react';
import { Table, ButtonLink, Notification, STATUS_TYPES } from 'dyl-components';
import { EditableContent } from 'dyl-components';
import { Input, Popup, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import IVRPromptEditModal from '../IVRPromptEditModal';
import MessagingIconButtons from 'shared/MessagingIconButtons';

const IVRPromptMessageRow = ({ 
    isEditMode, 
    message, 
    index, 
    onChange, 
    onDelete, 
    onUpdate, 
    onEnableEditMode, 
    onDisableEditMode,
    onOpenIVRPromptEditModal 
}) => (
    <Table.Row active={isEditMode}>
        <Table.Cell>{index}</Table.Cell>
        <Table.Cell>
            <EditableContent 
                control={Input}
                name='name'
                value={message.name}
                onChange={onChange}
                isEditMode={isEditMode}
            />
        </Table.Cell>
        <Table.Cell>
            {message.ext}
        </Table.Cell>
        <Table.Cell>
            <Link to={`/settings/ivr?id=${message.id}&command=download`}>Download</Link>
        </Table.Cell>
        <Table.Cell>{message.lastUpdated}</Table.Cell>
        <Table.Cell>
            <Popup 
                trigger={(
                    <ButtonLink onClick={onOpenIVRPromptEditModal}>
                        <Icon 
                            name='cog'
                            size='large'
                        />
                    </ButtonLink>
                )}
                content={'Edit Prompt Options'}
                inverted
            />  
           <MessagingIconButtons
                isEditMode={isEditMode}
                onDisableEditMode={onDisableEditMode} 
                onUpdate={onUpdate}
                onEnableEditMode={onEnableEditMode}
                onDelete={onDelete}
                id={message.id} 
            />
        </Table.Cell>
    </Table.Row>
)

class IVRPromptMessageRowContainer extends React.Component {
    state = {
        message: this.props.message,
        isEditMode: false,
        isIVRPromptEditModalOpen: false,
        info: {
            number: 1,
            name: 'Vacation',
            messageId: null,
            isMessageOnly: false,
            areDirectExtensionsAllowed: true,
            fallbackExtension: 'Hangup',
            internalExtension: '',
            isSpeechDetectionEnabled: false
        }
    }

    onOpenIVRPromptEditModal = () => {
        this.setState({ isIVRPromptEditModalOpen: true });
    }

    onCloseIVRPromptEditModal = () => {
        this.setState({ isIVRPromptEditModalOpen: false });
    }

    onEnableEditMode = () => {
        this.setState({ isEditMode: true });
    }

    onDisableEditMode = () => {
        this.setState({ isEditMode: false, message: this.props.message });
    }

    onChange = (_, { name, value }) => {
        let message = { ...this.state.message, [name]: value};
        this.setState({ message });
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false
        }, () => {
            this.props.onUpdate(this.state.message);
        })
    }

    onUpdatePrompt = (info) => {
        this.setState({ info, isIVRPromptEditModalOpen: false }, () => {
            Notification.alert('Successfully updated IVR prompt!', STATUS_TYPES.SUCCESS, true);
        });
    }

    render() {
        return (
            <React.Fragment>
                <IVRPromptMessageRow 
                    {...this.props}
                    {...this.state}
                    onChange={this.onChange}
                    onEnableEditMode={this.onEnableEditMode}
                    onDisableEditMode={this.onDisableEditMode}
                    onUpdate={this.onUpdate}
                    onOpenIVRPromptEditModal={this.onOpenIVRPromptEditModal}
                />
                {this.state.isIVRPromptEditModalOpen && (
                    <IVRPromptEditModal 
                        open={this.state.isIVRPromptEditModalOpen}
                        onClose={this.onCloseIVRPromptEditModal}
                        info={this.state.info}
                        onUpdate={this.onUpdatePrompt}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default IVRPromptMessageRowContainer;
