import React from 'react';
import { Table, ControlledPopup, MessagingIcon, StatusLabel, STATUS_TYPES, Notification } from 'dyl-components';
import ringGroupActions from 'actions/ring_groups';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import callQueueActions from 'actions/call_queues';

const RingGroupMemberRow = ({ member, onDelete, onToggleActivity, isModifying }) => (
    <Table.Row>
        <Table.Cell>
            Ext. {member.member}{`${member.user ? `: ${member.user}` : <em> (Deleted)</em>}`}
        </Table.Cell>
        <Table.Cell>
            <StatusLabel 
                text={member.active ? 'Receive Calls' : 'Do not Ring'}
                type={member.active ? 'success' : 'error'}
            />
        </Table.Cell>
        <Table.Cell>
            <MessagingIcon 
                icon={member.active ? 'stop circle' : 'play circle'}
                onClick={onToggleActivity}
            />
            <ControlledPopup 
                trigger={<MessagingIcon icon='trash'  />}
                onConfirm={onDelete}
                content={'Delete'}
                inverted
            />
            {isModifying && <Icon name='circle notched' loading />}
        </Table.Cell>
    </Table.Row>
);

class RingGroupMemberRowContainer extends React.Component {
    onToggleActivity = () => {
        const { active, id, extension_id } = this.props.member;
        this.props.onUpdate(this.props.ring_group, id, { active: !active, extension_id, timeout: 0, phone_number: '' }).then(() => {
            Notification.alert(`Member set to ${active ? 'inactive' : 'active'}!`, STATUS_TYPES.SUCCESS, true);
            this.props.onReadRingGroupMembers(this.props.ring_group);
            this.props.onReadGroups(this.props.domain);
        }).catch(() => {
            Notification.alert('Failed to update member status', STATUS_TYPES.ERROR, true);
        })
    }

    onDelete = () => {
        this.props.onDelete(this.props.ring_group, this.props.member.id).then(() => {
            Notification.alert('Successfully removed member!', STATUS_TYPES.SUCCESS, true);
            this.props.onReadRingGroupMembers(this.props.ring_group);
            this.props.onReadGroups(this.props.domain);
        }).catch(() => {
            Notification.alert('Failed to remove member', STATUS_TYPES.ERROR, true);
        })
    }

    render() {
        return (
            <RingGroupMemberRow 
                member={this.props.member}
                onDelete={this.onDelete}
                onToggleActivity={this.onToggleActivity}
                isModifying={
                    this.props.memberBeingDeleted === this.props.member.id || 
                    this.props.memberBeingUpdated === this.props.member.id
                }
            />
        )
    }
}

const mapStateToProps = (state, { isRingGroup }) => ({
    memberBeingUpdated: state[isRingGroup ? 'ring_group' : 'call_queue'].memberBeingUpdated,
    memberBeingDeleted: state[isRingGroup ? 'ring_group' : 'call_queue'].memberBeingDeleted,
    domain: state.pbx_config.domain
})

const mapDispatchToProps = (dispatch, { isRingGroup }) => ({
    onUpdate: (group_id, member, payload) => {
        if (isRingGroup) {
            return dispatch(ringGroupActions.updateRingGroupMember(group_id, payload, null, member));
        }
        return dispatch(callQueueActions.updateCallQueueMember(group_id, payload, null, member));
    },
    onReadRingGroupMembers: (group_id) => {
        if (isRingGroup) {
            return dispatch(ringGroupActions.readRingGroupMembers(group_id));
        }
        return dispatch(callQueueActions.readCallQueueMembers(group_id));
    },
    onDelete: (group_id, member) => {
        if (isRingGroup) {
            return dispatch(ringGroupActions.deleteRingGroupMember(group_id, null, member));
        }
        return dispatch(callQueueActions.deleteCallQueueMember(group_id, null, member));
    },
    onReadGroups: (domain) => {
        if (isRingGroup) {
            return dispatch(ringGroupActions.readRingGroups(domain));
        }
        return dispatch(callQueueActions.readCallQueues(domain));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(RingGroupMemberRowContainer);
