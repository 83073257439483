import types from "actions/hotlist/types";
import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function hotlistReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.HOTLIST, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingHotlist: true }
        case getNamedAction(ACTION_NAMES.HOTLIST, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingHotlist: false, hotlist: (action.data.data || []).map(hotlistItem => ({ ...hotlistItem, isMarked: state.allPagesMarked })), queryParameters: { ...state.queryParameters, ...action.queryParameters }, count: action.data.count, limit: action.data.limit, allMarked: state.allPagesMarked }
        case getNamedAction(ACTION_NAMES.HOTLIST, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingHotlist: false }

        case getNamedAction(ACTION_NAMES.HOTLIST, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingToHotlist: true }
        case getNamedAction(ACTION_NAMES.HOTLIST, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAddingToHotlist: false }
        case getNamedAction(ACTION_NAMES.HOTLIST, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingToHotlist: false }

        case getNamedAction(ACTION_NAMES.HOTLIST, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isRemovingFromHotlist: true, contactBeingRemoved: action.queryParameters.contact_id }
        case getNamedAction(ACTION_NAMES.HOTLIST, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isRemovingFromHotlist: false, contactBeingRemoved: null }
        case getNamedAction(ACTION_NAMES.HOTLIST, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isRemovingFromHotlist: false, contactBeingRemoved: null }

        case types.MARK_HOTLIST_ITEM:
            {
                const hotlist = state.hotlist.slice(0);
                let hotlistItemToMark = hotlist.filter(lead => lead.id === action.id)[0];
                hotlistItemToMark.isMarked = !hotlistItemToMark.isMarked;
                return { ...state, hotlist, allPagesMarked: false, 
                    ...(hotlist.every(({ isMarked }) => !isMarked) ? { allMarked: false } : {}),
                    ...(hotlist.every(({ isMarked }) => isMarked) ? { allMarked: true } : {})
                }
            }
        case types.MARK_ALL_HOTLIST_ITEMS:
            {
                const hotlist = state.hotlist.slice(0);
                let allMarked = !state.allMarked;
                hotlist.forEach(hotlistItem => { hotlistItem.isMarked = allMarked });
                return { ...state, hotlist, allMarked, allPagesMarked: false }
            }
        case types.MARK_HOTLIST_ITEMS_IN_EVERY_PAGE:
            {
                const hotlist = state.hotlist.slice(0);
                let allPagesMarked = !state.allPagesMarked;
                hotlist.forEach(hotlistItem => { hotlistItem.isMarked = allPagesMarked });
                return { ...state, hotlist, allMarked: allPagesMarked, allPagesMarked }
            }
        case types.SET_LIMIT:
            return { ...state, limit: action.limit, currentPage: 1 }
        case types.CHANGE_PAGE:
            return { ...state, currentPage: action.page }

        case getNamedAction(ACTION_NAMES.HOTLIST_CHECK_CONTACTS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isCheckingContactExistence: true, existing_contacts: [] }
        case getNamedAction(ACTION_NAMES.HOTLIST_CHECK_CONTACTS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isCheckingContactExistence: false, existing_contacts: action.data || [] }
        case getNamedAction(ACTION_NAMES.HOTLIST_CHECK_CONTACTS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isCheckingContactExistence: false, existing_contacts: [] }

        default:
            return state;
    }
}

export default hotlistReducer;
