import feeActions from "actions/product_fee";
import { Notification, STATUS_TYPES, generateResolver } from "dyl-components";
import { Error404, Error500 } from "pages/ErrorPages";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import feeSchema, { formatFeeDetails, getDefaultValuesFromReadingAFee } from "shared/schemas/fees/feeSchema";
import FeeForm from "shared/forms/FeeForm";

const EditFee = ({ type, id }) => {
    const { fee, feeBeingUpdated } = useSelector((state) => state.product_fees);

    const methods = useForm({
        mode: "onChange",
        defaultValues: getDefaultValuesFromReadingAFee(fee),
        resolver: generateResolver(feeSchema),
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSave = async (data) => {
        try {
            await dispatch(
                feeActions.updateFee(id, formatFeeDetails(data))
            );
            Notification.alert(
                `Succesfully updated ${type}!`,
                STATUS_TYPES.SUCCESS
            );
            methods.reset();
            navigate(
                `/settings/product-catalog-quotes-invoices/${type === "tax" ? "taxes" : "fees"}`,
                { replace: true, state: { saved: true } }
            );
        } catch (e) {
            console.log(e);
            Notification.alert(
                `Failed to update ${type}`,
                STATUS_TYPES.ERROR
            );
        }
    };

    return (
        <FormProvider {...({ ...methods, type, feeBeingEdited: id })}>
            <FeeForm onSave={onSave} loading={feeBeingUpdated} />
        </FormProvider>
    );
};

const EditFeeContainer = ({ type }) => {
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(feeActions.readFee(id));
    }, [id, dispatch]);

    const { feeBeingRead, feeError, fee } = useSelector(
        (state) => state.product_fees
    );

    if (feeBeingRead) {
        return (
            <Dimmer active>
                <Loader active />
            </Dimmer>
        );
    }

    if (!feeBeingRead && feeError) {
        return <Error500 message={"Something went wrong"} />;
    }

    if (!feeBeingRead && !fee) {
        return <Error404 message={"Fee not found"} />;
    }

    return <EditFee type={type} id={id} />;
};

export default EditFeeContainer;
