import routes from 'actions/routes';

import { generateCreateActionCreator, generateDeleteActionCreator, generateReadActionCreator, generateReadOneActionCreator, generateUpdateActionCreator } from "actions/crudActionCreators";
import ACTION_NAMES from "actions/ACTION_NAMES";

export default {
    readActivity: generateReadActionCreator(routes.API_SELF_REPORT, ACTION_NAMES.SELF_REPORT),

    addCallActivity: generateCreateActionCreator(`${routes.API_SELF_REPORT}/calls`, ACTION_NAMES.SELF_REPORT_CALL),
    updateCallActivity: generateUpdateActionCreator(`${routes.API_SELF_REPORT}/calls`, ACTION_NAMES.SELF_REPORT_CALL),
    toggleCallCompletion: generateReadOneActionCreator(`${routes.API_SELF_REPORT}/calls`, ACTION_NAMES.SELF_REPORT_CALL),
    deleteCallActivity: generateDeleteActionCreator(`${routes.API_SELF_REPORT}/calls`, ACTION_NAMES.SELF_REPORT_CALL),
    readUserCallActivity: generateReadActionCreator(`${routes.API_SELF_REPORT}/user/calls`, ACTION_NAMES.USER_CALL_ACTIVITY_LOG),
    readCustomerCallActivity: generateReadActionCreator(`${routes.API_SELF_REPORT}/customer/calls`, ACTION_NAMES.CUSTOMER_CALL_ACTIVITY_LOG),

    addTextActivity: generateCreateActionCreator(`${routes.API_SELF_REPORT}/texts`, ACTION_NAMES.SELF_REPORT_TEXT),
    updateTextActivity: generateUpdateActionCreator(`${routes.API_SELF_REPORT}/texts`, ACTION_NAMES.SELF_REPORT_TEXT),
    toggleTextCompletion: generateReadOneActionCreator(`${routes.API_SELF_REPORT}/texts`, ACTION_NAMES.SELF_REPORT_TEXT),
    deleteTextActivity: generateDeleteActionCreator(`${routes.API_SELF_REPORT}/texts`, ACTION_NAMES.SELF_REPORT_TEXT),
    readUserTextActivity: generateReadActionCreator(`${routes.API_SELF_REPORT}/user/texts`, ACTION_NAMES.USER_TEXT_ACTIVITY_LOG),
    readCustomerTextActivity: generateReadActionCreator(`${routes.API_SELF_REPORT}/customer/texts`, ACTION_NAMES.CUSTOMER_TEXT_ACTIVITY_LOG), 

    addEmailActivity: generateCreateActionCreator(`${routes.API_SELF_REPORT}/email`, ACTION_NAMES.SELF_REPORT_EMAIL),
    updateEmailActivity: generateUpdateActionCreator(`${routes.API_SELF_REPORT}/email`, ACTION_NAMES.SELF_REPORT_EMAIL),
    toggleEmailCompletion: generateReadOneActionCreator(`${routes.API_SELF_REPORT}/email`, ACTION_NAMES.SELF_REPORT_EMAIL),
    deleteEmailActivity: generateDeleteActionCreator(`${routes.API_SELF_REPORT}/email`, ACTION_NAMES.SELF_REPORT_EMAIL),
    readUserEmailActivity: generateReadActionCreator(`${routes.API_SELF_REPORT}/user/email`, ACTION_NAMES.USER_EMAIL_ACTIVITY_LOG),
    readCustomerEmailActivity: generateReadActionCreator(`${routes.API_SELF_REPORT}/customer/email`, ACTION_NAMES.CUSTOMER_EMAIL_ACTIVITY_LOG), 
}
