import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function lead_ingestionReducer(state = initialState, action) {
    
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.LEAD_INGEST_LEAD_PROVIDER, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingLeadProviders: true };
        case getNamedAction(ACTION_NAMES.LEAD_INGEST_LEAD_PROVIDER, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingLeadProviders: false, queryParameters: { ...state.queryParameters, ...action.queryParameters }, lead_providers: action.data.data, lead_providers_count: action.data.count };
        case getNamedAction(ACTION_NAMES.LEAD_INGEST_LEAD_PROVIDER, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingLeadProviders: false };

        case getNamedAction(ACTION_NAMES.LEAD_INGEST_LEAD_PROVIDER, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingLeadProvider: true };
        case getNamedAction(ACTION_NAMES.LEAD_INGEST_LEAD_PROVIDER, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAddingLeadProvider: false };
        case getNamedAction(ACTION_NAMES.LEAD_INGEST_LEAD_PROVIDER, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingLeadProvider: false }

        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_LEAD_PROVIDER, CRUD_ACTION_TYPES.DELETE_REQUEST):
        //     return { ...state, isDeletingLeadProvider: true };
        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_LEAD_PROVIDER, CRUD_ACTION_TYPES.DELETE_SUCCESS):
        //     return { ...state, isDeletingLeadProvider: false };
        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_LEAD_PROVIDER, CRUD_ACTION_TYPES.DELETE_FAILURE):
        //     return { ...state, isDeletingLeadProvider: false }

        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_LEAD_PROVIDER, CRUD_ACTION_TYPES.UPDATE_REQUEST):
        //     return { ...state, isUpdatingLeadProvider: true };
        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_LEAD_PROVIDER, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
        //     return { ...state, isUpdatingLeadProvider: false };
        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_LEAD_PROVIDER, CRUD_ACTION_TYPES.UPDATE_FAILURE):
        //     return { ...state, isUpdatingLeadProvider: false }

        case getNamedAction(ACTION_NAMES.LEAD_INGEST_PROVIDER_META, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingProviderMeta: true };
        case getNamedAction(ACTION_NAMES.LEAD_INGEST_PROVIDER_META, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingProviderMeta: false, queryParameters: { ...state.queryParameters, ...action.queryParameters }, provider_meta: action.data.data, provider_meta_count: action.data.count };
        case getNamedAction(ACTION_NAMES.LEAD_INGEST_PROVIDER_META, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingProviderMeta: false };

        case getNamedAction(ACTION_NAMES.LEAD_INGEST_PROVIDER_META, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingProviderMeta: true };
        case getNamedAction(ACTION_NAMES.LEAD_INGEST_PROVIDER_META, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAddingProviderMeta: false };
        case getNamedAction(ACTION_NAMES.LEAD_INGEST_PROVIDER_META, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingProviderMeta: false }

        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_PROVIDER_META, CRUD_ACTION_TYPES.DELETE_REQUEST):
        //     return { ...state, isDeletingProviderMeta: true };
        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_PROVIDER_META, CRUD_ACTION_TYPES.DELETE_SUCCESS):
        //     return { ...state, isDeletingProviderMeta: false };
        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_PROVIDER_META, CRUD_ACTION_TYPES.DELETE_FAILURE):
        //     return { ...state, isDeletingProviderMeta: false }

        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_PROVIDER_META, CRUD_ACTION_TYPES.UPDATE_REQUEST):
        //     return { ...state, isUpdatingProviderMeta: true };
        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_PROVIDER_META, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
        //     return { ...state, isUpdatingProviderMeta: false };
        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_PROVIDER_META, CRUD_ACTION_TYPES.UPDATE_FAILURE):
        //     return { ...state, isUpdatingProviderMeta: false }

        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_MAPPING, CRUD_ACTION_TYPES.READ_REQUEST):
        //     return { ...state, isReadingMappings: true };
        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_MAPPING, CRUD_ACTION_TYPES.READ_SUCCESS):
        //     return { ...state, isReadingMappings: false, queryParameters: { ...state.queryParameters, ...action.queryParameters }, mappings: action.data.data, mappings_count: action.data.count };
        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_MAPPING, CRUD_ACTION_TYPES.READ_FAILURE):
        //     return { ...state, isReadingMappings: false };

        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_MAPPING, CRUD_ACTION_TYPES.CREATE_REQUEST):
        //     return { ...state, isAddingMapping: true };
        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_MAPPING, CRUD_ACTION_TYPES.CREATE_SUCCESS):
        //     return { ...state, isAddingMapping: false };
        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_MAPPING, CRUD_ACTION_TYPES.CREATE_FAILURE):
        //     return { ...state, isAddingMapping: false }

        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_MAPPING, CRUD_ACTION_TYPES.DELETE_REQUEST):
        //     return { ...state, isDeletingMapping: true };
        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_MAPPING, CRUD_ACTION_TYPES.DELETE_SUCCESS):
        //     return { ...state, isDeletingMapping: false };
        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_MAPPING, CRUD_ACTION_TYPES.DELETE_FAILURE):
        //     return { ...state, isDeletingMapping: false }

        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_MAPPING, CRUD_ACTION_TYPES.UPDATE_REQUEST):
        //     return { ...state, isUpdatingMapping: true };
        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_MAPPING, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
        //     return { ...state, isUpdatingMapping: false };
        // case getNamedAction(ACTION_NAMES.LEAD_INGEST_MAPPING, CRUD_ACTION_TYPES.UPDATE_FAILURE):
        //     return { ...state, isUpdatingMapping: false };

        case getNamedAction(ACTION_NAMES.LEAD_INGESTION_MAIN_SOURCE_PROVIDER, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingMainSources: true };
        case getNamedAction(ACTION_NAMES.LEAD_INGESTION_MAIN_SOURCE_PROVIDER, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingMainSources: false, queryParameters: { ...state.queryParameters, ...action.queryParameters }, main_sources: action.data };
        case getNamedAction(ACTION_NAMES.LEAD_INGESTION_MAIN_SOURCE_PROVIDER, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingMainSources: false };

        case getNamedAction(ACTION_NAMES.LEAD_INGESTION_PRODUCT_INTERESTS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingMProductInterests: true };
        case getNamedAction(ACTION_NAMES.LEAD_INGESTION_PRODUCT_INTERESTS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingProductInterests: false, product_interests: action.data.data || [] };
        case getNamedAction(ACTION_NAMES.LEAD_INGESTION_PRODUCT_INTERESTS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingProductInterests: false };

        case getNamedAction(ACTION_NAMES.LEAD_INGESTION_ALL, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingPosts: true };
        case getNamedAction(ACTION_NAMES.LEAD_INGESTION_ALL, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingPosts: false };
        case getNamedAction(ACTION_NAMES.LEAD_INGESTION_ALL, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingPosts: false, posts: action.data };


        case getNamedAction(ACTION_NAMES.LEAD_INGESTION, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingEmail: true };
        case getNamedAction(ACTION_NAMES.LEAD_INGESTION, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingEmail: false };
        case getNamedAction(ACTION_NAMES.LEAD_INGESTION, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            if(action.data?.creation_type === 'email'){
                //reset emailParseData 
                return { ...state, isReadingEmail: false , email: action.data, emailParseData: {} };
            }else if(action.data?.creation_type === 'email'){
                //email init
                return { ...state, isReadingEmail: false , email: action.data };
            }else if(action.data?.creation_type === 'post'){
                //update post
                return { ...state, isReadingEmail: false, email: action.data };
            }else{
                //fail over to account of actions not yet forseen
                return { ...state, isReadingEmail: false, email: action.data };
            }

        case getNamedAction(ACTION_NAMES.LEAD_INGESTION_EMAIL_PARSE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAttemptingEmailParse: true };
        case getNamedAction(ACTION_NAMES.LEAD_INGESTION_EMAIL_PARSE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAttemptingEmailParse: false };
        case getNamedAction(ACTION_NAMES.LEAD_INGESTION_EMAIL_PARSE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAttemptingEmailParse: false , emailParseData: action.data};
        
        case getNamedAction(ACTION_NAMES.LEAD_INGESTION_EMAIL_SAVE_PARSER, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isSavingParser: true };
        case getNamedAction(ACTION_NAMES.LEAD_INGESTION_EMAIL_SAVE_PARSER, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isSavingParser: false };
        case getNamedAction(ACTION_NAMES.LEAD_INGESTION_EMAIL_SAVE_PARSER, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isSavingParser: false , emailParserID: action.data};

        case getNamedAction(ACTION_NAMES.LEAD_INGESTION_PROVIDER_RECORD, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingProviderRecordNames: true };
        case getNamedAction(ACTION_NAMES.LEAD_INGESTION_PROVIDER_RECORD, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingProviderRecordNames: false, providerRecordNames: action.data.data };
        case getNamedAction(ACTION_NAMES.LEAD_INGESTION_PROVIDER_RECORD, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingProviderRecordNames: false };
        
        
        default:
            
            return state;
       
    }
}

export default lead_ingestionReducer;
