import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function customFieldsGroupReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS_GROUP, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCustomFieldGroup: true, count: 0, group: [] };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS_GROUP, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingCustomFieldGroup: false, count: action.data.count, group: action.data.data, queryParameters: { ...state.queryParameters, ...action.queryParameters } };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS_GROUP, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCustomFieldGroup: false };

        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS_GROUP, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, groupBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS_GROUP, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, groupBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS_GROUP, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, groupBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS_GROUP, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, groupBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS_GROUP, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, groupBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELDS_GROUP, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, groupBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.MODAL_CUSTOM_FIELDS_STANDARD_GROUP, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingModalStandardFieldGroup: true };
        case getNamedAction(ACTION_NAMES.MODAL_CUSTOM_FIELDS_STANDARD_GROUP, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingModalStandardFieldGroup: false, modal_standard_group: action.data?.data[0] || initialState.modal_standard_group };
        case getNamedAction(ACTION_NAMES.MODAL_CUSTOM_FIELDS_STANDARD_GROUP, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingModalStandardFieldGroup: false };
            
        case getNamedAction(ACTION_NAMES.CONTACT_STANDARD_GROUP, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingContactStandardFieldGroup: true };
        case getNamedAction(ACTION_NAMES.CONTACT_STANDARD_GROUP, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingContactStandardFieldGroup: false, contact_standard_group: action.data?.data[0] || initialState.contact_standard_group };
        case getNamedAction(ACTION_NAMES.CONTACT_STANDARD_GROUP, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingContactStandardFieldGroup: false };

        case getNamedAction(ACTION_NAMES.CUSTOMER_STANDARD_GROUP, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCustomerStandardFieldGroup: true };
        case getNamedAction(ACTION_NAMES.CUSTOMER_STANDARD_GROUP, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCustomerStandardFieldGroup: false, customer_standard_group: action.data?.data[0] || initialState.customer_standard_group };
        case getNamedAction(ACTION_NAMES.CUSTOMER_STANDARD_GROUP, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCustomerStandardFieldGroup: false };

        case getNamedAction(ACTION_NAMES.CUSTOM_FIELD_GROUP_REACTIVATE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, groupBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELD_GROUP_REACTIVATE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, groupBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.CUSTOM_FIELD_GROUP_REACTIVATE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, groupBeingUpdated: null };

        default:
            return state;
    }
}


export default customFieldsGroupReducer;
