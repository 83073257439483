import React from 'react';
import { Grid } from 'semantic-ui-react';

import DialButton from './DialButton';

import './index.scss';

const Keypad = ({ onPressNumberKey, disabled }) => (
    <Grid textAlign='center' verticalAlign='middle'>
        <Grid.Row stretched columns={3} centered>
            <Grid.Column>
                <DialButton
                    disabled={disabled}
                    mainKey={1}
                    onPressNumberKey={onPressNumberKey}
                />
            </Grid.Column>
            <Grid.Column>
                <DialButton
                    mainKey={2}
                    disabled={disabled}
                    subKeys={['A', 'B', 'C']}
                    onPressNumberKey={onPressNumberKey}
                />
            </Grid.Column>
            <Grid.Column>
                <DialButton
                    mainKey={3}
                    disabled={disabled}
                    subKeys={['D', 'E', 'F']}
                    onPressNumberKey={onPressNumberKey}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched columns={3} centered>
            <Grid.Column>
                <DialButton
                    mainKey={4}
                    disabled={disabled}
                    subKeys={['G', 'H', 'I']}
                    onPressNumberKey={onPressNumberKey}
                />
            </Grid.Column>
            <Grid.Column>
                <DialButton
                    mainKey={5}
                    disabled={disabled}
                    subKeys={['J', 'K', 'L']}
                    onPressNumberKey={onPressNumberKey}
                />
            </Grid.Column>
            <Grid.Column>
                <DialButton
                    mainKey={6}
                    disabled={disabled}
                    subKeys={['M', 'N', 'O']}  
                    onPressNumberKey={onPressNumberKey}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched columns={3} centered>
            <Grid.Column>
                <DialButton
                    mainKey={7}
                    disabled={disabled}
                    subKeys={['P', 'Q', 'R', 'S']}
                    onPressNumberKey={onPressNumberKey}
                />
            </Grid.Column>
            <Grid.Column>
                <DialButton
                    mainKey={8}
                    disabled={disabled}
                    subKeys={['T', 'U', 'V']}
                    onPressNumberKey={onPressNumberKey}
                />
            </Grid.Column>
            <Grid.Column>
                <DialButton
                    mainKey={9}
                    disabled={disabled}
                    subKeys={['W', 'X', 'Y', 'Z']}
                    onPressNumberKey={onPressNumberKey}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched columns={3} centered>
            <Grid.Column>
                <DialButton
                    disabled={disabled}
                    mainKey={'*'}
                />
            </Grid.Column>
            <Grid.Column>
                <DialButton
                    disabled={disabled}
                    mainKey={'0'}
                    subKeys={['-']}
                    onPressNumberKey={onPressNumberKey}
                />
            </Grid.Column>
            <Grid.Column>
                <DialButton
                    disabled={disabled}
                    mainKey={'#'}
                />
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

export default Keypad;
