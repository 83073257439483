import React from 'react';
import { Table, ControlledPopup, MessagingIcon } from 'dyl-components';
import { connect } from 'react-redux';
import { EditableContent } from 'dyl-components';
import { Input } from 'semantic-ui-react';
import tagCategoryActions from 'actions/tag_category';
import tagCategoriesActions from 'actions/tag_categories';

const CategoryRow = ({ 
    category,
    onDelete,
    isEditMode,
    onUpdate,
    onChange,
    onEnableEditMode, 
    onDisableEditMode,
    isUpdatingCategory,
    isDeletingCategory 
}) => (
    <Table.Row>
        <Table.Cell>
            <EditableContent
                isEditMode={isEditMode || isUpdatingCategory} 
                name='category'
                value={category.category}
                control={Input}
                onChange={onChange}
                loading={isUpdatingCategory}
            /> &nbsp;
            { isEditMode && (
                <MessagingIcon icon='times'  onClick={onDisableEditMode} />
            ) }
            <MessagingIcon 
                icon={isEditMode ? 'check' : 'pencil'} 
                onClick={isEditMode ? onUpdate : onEnableEditMode} 
                
                size={isEditMode ? 'large' : 'small'}
            />
        </Table.Cell>
        <Table.Cell>
            {category.tag_count || 0}
        </Table.Cell>
        <Table.Cell>
            <ControlledPopup 
                trigger={<MessagingIcon icon='trash'  />}
                onConfirm={onDelete}
                content={'Delete'}
                inverted
            />
        </Table.Cell>
    </Table.Row>
)

class CategoryRowContainer extends React.Component {
    state = {
        category: this.props.category,
        isEditMode: false
    }

    onEnableEditMode = () => {
        this.setState({ isEditMode: true });
    }

    onDisableEditMode = () => {
        this.setState({ isEditMode: false, category: this.props.category });
    }

    onChange = (_, { name, value }) => {
        let category = { ...this.state.category, [name]: value};
        this.setState({ category });
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false,
        }, () => {
            this.props.onUpdate(this.state.category)
                .then(() => {
                    this.setState({category: this.props.category});
                })
        })
    }

    render() {
        return (
            <CategoryRow 
                {...this.props}
                {...this.state}
                onChange={this.onChange}
                onEnableEditMode={this.onEnableEditMode}
                onDisableEditMode={this.onDisableEditMode}
                onUpdate={this.onUpdate}
            />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        isUpdatingCategory: state.tag_category.updatingCategory === ownProps.category.id
    }
} 

const mapDispatchToProps = (dispatch, ownProps) => ({
    onUpdate: ({ category }) => {
        return dispatch(tagCategoryActions.updateTagCategory(ownProps.category.id, { category }))
            .then(() => {
                dispatch(tagCategoriesActions.getListOfTagCategories());
            });
    },
    onDelete: () => {
        return dispatch(tagCategoryActions.deleteTagCategory(ownProps.category.id))
            .then(() => {
                dispatch(tagCategoriesActions.getListOfTagCategories());
            });
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoryRowContainer);
