import { generateUpdateActionCreator, generateReadOneActionCreator, generateDeleteActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import routes from 'actions/routes';

const updateNote = generateUpdateActionCreator(routes.API_ACCOUNT_NOTE, ACTION_NAMES.ACCOUNT_NOTE);

const readNote = generateReadOneActionCreator(routes.API_ACCOUNT_NOTE, ACTION_NAMES.ACCOUNT_NOTE);
const deleteNote = generateDeleteActionCreator(routes.API_ACCOUNT_NOTE, ACTION_NAMES.ACCOUNT_NOTE);

export default {
  updateNote,
  readNote,
  deleteNote
};
