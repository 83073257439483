import { generateDeleteActionCreator, generateCreateActionCreator, generateUpdateActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import routes from 'actions/routes';

import { Notification, STATUS_TYPES } from 'dyl-components';

const onDeleteChatRoomSuccess = () => {
    Notification.alert('Successfully deleted chat room!', STATUS_TYPES.SUCCESS, true);
}

const onDeleteChatRoomFailure = () => {
    Notification.alert('Failed to delete chat room', STATUS_TYPES.ERROR, true);
}


const onUpdateChatRoomSuccess = () => {
    Notification.alert('Successfully updated chat room!', STATUS_TYPES.SUCCESS, true);
}

const onUpdateChatRoomFailure = () => {
    Notification.alert('Failed to update chat room', STATUS_TYPES.ERROR, true);
}

const onAddUserSuccess = () => {
    Notification.alert('Successfully added user to chat room!', STATUS_TYPES.SUCCESS, true);
}

const onAddUserFailure = () => {
    Notification.alert('Failed to add user to chat room', STATUS_TYPES.ERROR, true);
}

const deleteChatRoom = generateDeleteActionCreator(routes.API_CHAT_ROOM, ACTION_NAMES.CHAT_ROOM, onDeleteChatRoomSuccess, onDeleteChatRoomFailure);
const addUserToChatRoom = generateCreateActionCreator(routes.API_CHAT_ROOM, ACTION_NAMES.CHAT_ROOM, onAddUserSuccess, onAddUserFailure);
const updateChatRoom = generateUpdateActionCreator(routes.API_CHAT_ROOM, ACTION_NAMES.CHAT_ROOM, onUpdateChatRoomSuccess, onUpdateChatRoomFailure);

export default {
    deleteChatRoom,
    addUserToChatRoom,
    updateChatRoom
}
