import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Breadcrumb } from 'semantic-ui-react';
import withBreadcrumb from 'shared/withBreadcrumb';

const CampaignBreadcrumbs = ({ campaignName }) => {
    return (
        <Breadcrumb className="HeaderBreadcrumb">
            <Breadcrumb.Section as={Link} to={'/blast_lists'}>Campaigns</Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section>{campaignName}</Breadcrumb.Section>
        </Breadcrumb>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        campaignName: ownProps.campaignName,
        title: ownProps.campaignName,
        path: `/email_campaign?id=${ownProps.id}`,
        type: 'campaigns'
    }
}

export default connect(mapStateToProps, null)(withBreadcrumb(CampaignBreadcrumbs));
