import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    CollapsibleList,
    DateTimeUtils,
    GroupedTimeLine,
    LinkedAccount,
    Notification,
    Person,
    STATUS_TYPES,
    Table,
    TableLoader,
} from "dyl-components";

import Avatar from "react-avatar";
import { Dropdown, Grid, Header, Icon } from "semantic-ui-react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Contact from "shared/Contact";
import { StringUtils } from "utils";
import customerTabActions from "actions/customer_tab";

import CustomerPipelineSubrowHeader from "./CustomerPipelinesSubrowHeader";
import CustomerPipelinesSubrowColumns from "./CustomerPipelineSubrowColumns";

import { ContactContext } from "shared/context/ContactProvider";
import customerPipelineActions from "actions/customer_pipeline";
import sequencesActions from "actions/sequences";
import contactSequenceActions from "actions/contact_sequence";
import sequenceTasksActions from "actions/sequence_tasks";
import accountActions from "actions/account";

const TableCellComponent =
    (outcome) =>
    ({ children, allowDisable = true }) => {
        const isClosed = outcome === "closed";
        return (
            <Table.Cell {...(allowDisable ? { disabled: isClosed } : {})}>
                {children}
            </Table.Cell>
        );
    };

const SubrowContent = ({ logs }) => {
    const getIcon = (entry) => {
        if (entry.action_type === "pipeline") {
            switch (entry.action) {
                case "created":
                    return "fas fa-square-user";
                case "stage_changed":
                case "changed":
                case "closed":
                case "assigned":
                    return "fas fa-funnel-dollar";
                default:
                    return "fas fa-clipboard";
            }
        }
        if (entry.action_type === "sequence") {
            switch (entry.action) {
                case "applied":
                case "changed":
                case "removed":
                    return "fas fa-sitemap";
                default:
                    return "fas fa-clipboard";
            }
        }
        if (entry.action_type === "contact_association") {
            switch (entry.action) {
                case "changed":
                    return "fas fa-image-user";
                default:
                    return "fas fa-clipboard";
            }
        }
        return "fas fa-clipboard";
    };

    return (
        <Table.CollapsibleRowContent>
            <Table.Cell width={1} />
            <Table.Cell colSpan={12}>
                <div className="HistorySection">
                    <GroupedTimeLine
                        headers={<CustomerPipelineSubrowHeader />}
                        isLoading={false}
                        additionalColumnNumber={3}
                        useDateFunctions={false}
                        groups={DateTimeUtils.groupDates(
                            (logs || []).map((log) => {
                                const [messageType,] = log?.message.split(" ");
                                const isSystemGenerated = messageType?.includes("System");
                                return {
                                    ts: log.activity,
                                    icon: <Icon className={getIcon(log)} />,
                                    columns: (
                                        <CustomerPipelinesSubrowColumns
                                            activityHeader={StringUtils.toSentenceCase(
                                                (log.action_type || "").split("_").join(" ")
                                            )}
                                            activitySubheader={log.action}
                                            messageNotes={
                                                <div>
                                                    {log.message}
                                                    {((log.action === "changed" &&
                                                        log.action_type ===
                                                            "pipeline") ||
                                                        log.action ===
                                                            "closed") && (
                                                        <CollapsibleList
                                                            minimumNumberOfItemsToShow={
                                                                0
                                                            }
                                                            items={[
                                                                "",
                                                                "",
                                                                `${
                                                                    log.close_reason
                                                                        ? `${
                                                                            log.close_reason
                                                                        }${
                                                                            log.close_type
                                                                                ? `/${log.close_type}`
                                                                                : ""
                                                                        }`
                                                                        : ""
                                                                }`,
                                                                "",
                                                                log.note || "",
                                                            ]}
                                                            action="View"
                                                        />
                                                    )}
                                                </div>
                                            }
                                            user={!isSystemGenerated ? (
                                                log.user && <Avatar
                                                    name={log.user}
                                                    round
                                                    size='3em'
                                                    maxInitials={2} />
                                            ) : (
                                                <Icon
                                                    className='fas fa-robot AutomationIcon'
                                                    circular
                                                    size='large' />
                                            )}
                                        />
                                    ),
                                };
                            })
                        )}
                    />
                </div>
            </Table.Cell>
        </Table.CollapsibleRowContent>
    );
};

const CustomerPipelinesRow = ({ pipeline }) => {
    const {
        contact_information: contact,
        status,
        pipeline_category_name,
        pipeline_stage_name,
        pipeline_stage_id,
        pipeline_stage_sequence_id,
        id,
    } = pipeline;
    const { isReading: isReadingTimeline, logs } = useSelector(
        (state) =>
            state.customer_tab.pipelineTimelines[id] || {
                isReading: false,
                logs: [],
            }
    );

    const { onOpenModal } = useContext(ContactContext);

    const [collapsed, setCollapsed] = useState(true);

    const dispatch = useDispatch();

    const refreshTimeline = async () => {
        dispatch(customerTabActions.readPipelineTimeline(pipeline.id));
    };

    const onToggle = () => {
        setCollapsed(!collapsed);
        if (collapsed) {
            refreshTimeline();
        }
    };

    const TableCell = TableCellComponent(status || "");

    const isClosed = status === "closed";

    const { contactsForPinning, isReadingContactsForPinning, contactSequenceTasks, selectedSequenceId, isReadingSequenceContactTasks } = useSelector(state => ({
        account: state.account.account,

        contactsForPinning: state.account.contactsForPinning.map(contact => {
            const name = `${contact.first_name} ${contact.last_name}`;
            return ({
                key: contact.id,
                value: contact.id,
                text: name,
                disabled: Boolean(contact.module_summary?.account_stage),
                content: (
                    <Person username={name} email={contact?.email?.email || ''} />
                )
            });
        }),
        isReadingContactsForPinning: state.account.isReadingContactsForPinning,

        contactSequenceTasks: state.sequence_tasks.selectedSequenceTasks,
        isReadingSequenceContactTasks: state.sequence_tasks.isReadingSequenceContactTasks,
        selectedSequenceId: state.sequence_tasks.selectedSequenceId
    }));

    const contact_id = contact?.id;

    useEffect(() => {
        if (!isClosed) {
            dispatch(sequenceTasksActions.readContactTasks(contact_id));
        }
    }, [contact_id, dispatch, isClosed]);

    const { account_id } = useParams();

    const [params] = useSearchParams();

    const onTransferPipeline = async (person_id) => {
        try {
            const areSequenceTasksCompleted = contactSequenceTasks.length === 0 || contactSequenceTasks.every(task => task.status === 'complete' || task.status === 'canceled');
            await dispatch(customerPipelineActions.update(Number(account_id), {
                pipeline_stage_id,
                person_id,
                pipeline_id: id,
                previous_sequence_complete: areSequenceTasksCompleted
            }, { action: 'transfer' }));
            if (selectedSequenceId) {
                await dispatch(contactSequenceActions.removeFromContact(Number(contact?.id)));
            }
            if (pipeline_stage_sequence_id) {
                await dispatch(sequencesActions.addToSequence({ contact_id: Number(person_id) }, null, pipeline_stage_sequence_id));
            }
            Notification.alert('Successfully transferred pipeline!', STATUS_TYPES.SUCCESS);
            dispatch(customerTabActions.readPipelines(Number(account_id), {
                page: 1,
                limit: 10,
                ...Object.fromEntries(params)
            }));
            dispatch(accountActions.readContactsForPinning(account_id, { page: 1, limit: 100 }));
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to transfer pipeline', STATUS_TYPES.ERROR);
        }
    }

    return (
        <>
            <Table.CollapsibleRow
                onToggle={onToggle}
                collapsed={collapsed}
                toggleDisabled={!Boolean(pipeline.id)}
                subrowContent={
                    isReadingTimeline ? (
                        <TableLoader colspan={10} isLoading />
                    ) : (
                        <SubrowContent logs={logs} />
                    )
                }
            >
                <TableCell>
                    <div>
                        <b>
                            {DateTimeUtils.formatEpoch(
                                pipeline.created,
                                DateTimeUtils.WORD_DATE_FORMAT
                            )}
                        </b>
                    </div>
                    <div>
                        {DateTimeUtils.formatEpoch(
                            pipeline.created,
                            DateTimeUtils.TIME_FORMAT
                        )}
                    </div>
                </TableCell>
                <TableCell allowDisable={false}>
                    <Grid verticalAlign="middle" className="PipelineRow__contact" columns={"equal"}>
                        <Grid.Column>
                            <Dropdown
                                placeholder='Select Primary'
                                selectOnBlur={false}
                                selection
                                options={!isClosed ? contactsForPinning.filter(({ value }) => value !== contact.id) : []}
                                {...(isClosed ? { icon: null } : {})}
                                value={contact.id}
                                onChange={(_, { value: contact_id }) => {onTransferPipeline(contact_id, contact.id)}}
                                compact
                                text={(
                                    <LinkedAccount
                                        account={`${contact.first_name} ${contact.last_name}`}
                                        maxWidth={'8.25em'}
                                        subtitle={contact?.email}
                                        popup
                                        linkToAccount={<Link onClick={e => { e.stopPropagation(); }} target={'_blank'} to={`/contact/${contact.id}`}>{contact.first_name} {contact.last_name}</Link>}
                                    />
                                )}
                                loading={isReadingContactsForPinning || isReadingSequenceContactTasks}
                                className="PipelineRow__contact-dropdown"
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Contact
                                contact={{
                                    ...contact,
                                    email: {
                                        id: contact?.email,
                                        email: contact?.email,
                                    },
                                    phone: {
                                        id: contact?.phone,
                                        phone: contact?.phone,
                                    },
                                }}
                                name={`${contact.first_name} ${contact.last_name}`}
                                jobTitle={contact.email}
                                linkToContact={
                                    <Link
                                        to={`/contact/${contact.id}`}
                                    >{`${contact.first_name} ${contact.last_name}`}</Link>
                                }
                                isModalOutside={true}
                                onExteriorModalOpen={(modalType, contact) => {
                                    onOpenModal(modalType, contact);
                                }}
                                hideContact
                            />
                        </Grid.Column>
                    </Grid>
                </TableCell>
                <TableCell>
                    {status && (
                        <span
                            className={`ModuleRow__outcome--${
                                isClosed ? "closed" : "open"
                            }`}
                        >
                            <b>{StringUtils.capitalize(status)}</b>
                        </span>
                    )}
                </TableCell>
                <TableCell>
                    <Header as="h5" disabled={isClosed}>
                        {pipeline_category_name}
                        <Header.Subheader>
                            {pipeline_stage_name}
                        </Header.Subheader>
                    </Header>
                </TableCell>
            </Table.CollapsibleRow>
        </>
    );
};

export default CustomerPipelinesRow;
