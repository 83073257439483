import initialState from './initialState';

export default (state = initialState, { type, ...action }) => {
    switch (type) {
        case 'TEXT_UI_SELECT_CONTACT_PHONE':
            return { ...state, to: action.contact_phone };
        default:
            return state
    }
}
