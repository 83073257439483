import React, { useCallback, useEffect, useState } from "react";
import { Table, TableWithHeader, TableLoader, STATUS_TYPES, Notification } from "dyl-components";
import PipelinesTableHeader from "./Header";
import PipelinesTableRow from "./Row";
import {
    Segment,
    Table as SemanticTable,
    Header,
} from "semantic-ui-react";
import PipelinesToolbar from "./Toolbar";
import pipelineActions from 'actions/pipeline';

import "./index.scss";
import { useSelector, useDispatch } from "react-redux";
import PipelineModal from "../PipelineModal";

const PipelinesTable = ({
    pipelines = [],
    isReading,
    isDeletingCategories,
    onDeletePipeline,
    onMakeDefault,
    onDeleteStage,
    stage,
    refresh,
    isDuplicated,
}) => {

    const disabled = (index) => {
        if (pipelines.length === 1) {
            return true
        }
        const stages = pipelines[index].stages
        if (stages) {
            const usedStage = stages.find((stage) => {
                return stage.has_records === true
            });
            return usedStage !== undefined
        }
        return false;
    }

    if (isReading || isDeletingCategories) {
        return (
            <Table>
                <SemanticTable.Header className="Table__header--primary">
                    <PipelinesTableHeader />
                </SemanticTable.Header>
                <TableLoader isLoading colspan={7} />
            </Table>
        )
    }

    if (pipelines.size < 1) {
        return (
            <Table>
                <SemanticTable.Header className="Table__header--primary">
                    <PipelinesTableHeader />
                </SemanticTable.Header>
            </Table>
        )
    }

    return pipelines.map((pipeline, index) => {
        if (index === 0) {
            pipeline.default = true;
        }

        return (
            <Table>
                {index === 0 && (
                    <SemanticTable.Header className="Table__header--primary">
                        <PipelinesTableHeader />
                    </SemanticTable.Header>
                )}
                <Table.Body>
                    <PipelinesTableRow
                        key={pipeline.id}
                        pipeline={pipeline}
                        disabledDeleteBtn={disabled(index)}
                        disabledDefaultBtn={pipeline.default}
                        stage={stage}
                        refresh={refresh}
                        onDelete={onDeletePipeline}
                        onMakeDefault={onMakeDefault}
                        onDeleteStage={onDeleteStage}
                        isDuplicated={isDuplicated}
                    />
                </Table.Body>
            </Table>
        )
    })
};

const PipelinesTableContainer = ({ stage }) => {
    const dispatch = useDispatch();
    const [isAddPipelineModalOpen, setIsAddPipelineModalOpen] = useState(false);

    const { categories, isReading, categoryBeingDeleted } = useSelector((state) => {
        return {
            categoryBeingDeleted: state.pipeline.categoryBeingDeleted,
            categories: state.pipeline.categories.filter(({ name }) => name !== 'CUSTOMER'),
            isReading: state.pipeline.isReadingCategories || state.sequences.isReadingSequencesShort,
            categoryBeingUpdated: state.pipeline.categoryBeingUpdated,
            categoryBeingReordered: state.pipeline.categoryBeingReordered
        }
    });

    const getMaxPipelines = useCallback(() => {
        switch (stage) {
            case "lead":
                return 2;
            case "opportunity":
                return 1;
            case "customer":
                return 3;
            default:
                return 3;
        }
    }, [stage]);

    const onOpenAddPipeline = () => {
        setIsAddPipelineModalOpen(true);
    }

    const onCloseAddPipeline = () => {
        setIsAddPipelineModalOpen(false);
    }

    const onMakeDefault = async (pipeline) => {
        try {
            await dispatch(pipelineActions.primaryPipelineCategory(pipeline.id));
            Notification.alert('Pipeline successfully made default!', STATUS_TYPES.SUCCESS);
            refresh();
        } catch (error) {
            console.log(error);
            Notification.alert('Failed to make pipeline default', STATUS_TYPES.ERROR);
        }
        
    }

    const onDeleteStage = async (pipeline_category_id, stage_id) => {
        try {
            await dispatch(pipelineActions.deleteStage(stage_id, {pipeline_category_id}))
            Notification.alert('Successfully deleted stage!', STATUS_TYPES.SUCCESS);
            refresh();
        } catch (error) {
            console.log(error);
            Notification.alert('Failed to delete a stage', STATUS_TYPES.ERROR);
        }
    }

    const isDuplicated = (name) => {
        if (name) {
            const duplicated = categories.find((category) => {
                return name.toLowerCase() === category.name.toLowerCase();
            })
            return duplicated
        }
        return null;
    }

    useEffect(() => {
        dispatch(pipelineActions.getPipelineCategories({ account_stage: stage }));
    }, [dispatch, stage, getMaxPipelines])

    const refresh = () => { dispatch(pipelineActions.getPipelineCategories({ account_stage: stage })); };

    const onDeletePipeline = async (id) => {
        try {
            await dispatch(pipelineActions.deletePipelineCategory(id));
            Notification.alert('Successfully deleted pipeline!', STATUS_TYPES.SUCCESS);
            refresh();
        } catch (error) {
            console.log(error);
            Notification.alert('Failed to delete pipeline', STATUS_TYPES.ERROR);
        }
    }

    return (
        <div className="LeadPipelinesPanel">
            <TableWithHeader
                header={<PipelinesToolbar onOpenAddPipeline={onOpenAddPipeline} disableAddBtn={categories.length >= getMaxPipelines() || isReading || categoryBeingDeleted} />}
                table={
                    <Segment.Group vertical>
                        <Segment style={{ padding: 0 }}>
                            <PipelinesTable
                                refresh={refresh}
                                stage={stage}
                                pipelines={categories}
                                isReading={isReading}
                                isDeletingCategories={categoryBeingDeleted}
                                onDeletePipeline={onDeletePipeline} 
                                onMakeDefault={onMakeDefault}
                                onDeleteStage={onDeleteStage}
                                isDuplicated={isDuplicated}
                            />
                        </Segment>
                        {!isReading && categories.count === 0 && (
                            <Segment>
                                <Header as="h3">No Results Found</Header>
                            </Segment>
                        )}
                    </Segment.Group>
                }
            />
            <PipelineModal open={isAddPipelineModalOpen} onClose={onCloseAddPipeline} stage={stage} refresh={refresh} isDuplicated={isDuplicated} />
        </div>
    );
};

export default PipelinesTableContainer;
