import { ClippedContent, Table } from "dyl-components";

const CategoryProductAddition = ({ label, value, action, labelColspan = 3, popupProps }) => {
    return (
        <Table.CollapsibleRowContent>
            <Table.Cell width={1} />
            <Table.Cell colSpan={labelColspan}>
                <div style={{ maxWidth: "14em" }}>
                    <ClippedContent {...(popupProps ? { ...popupProps } : {})}>
                        {label}
                    </ClippedContent>
                </div>
            </Table.Cell>
            <Table.Cell colSpan={(action ? 2 : 3) + 3 - labelColspan}>{value}</Table.Cell>
            {action && (
                <Table.Cell style={{ textAlign: "center" }}>
                    {action}
                </Table.Cell>
            )}
        </Table.CollapsibleRowContent>
    );
};

export default CategoryProductAddition;
