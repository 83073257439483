import textActions from 'actions/text';
import React from 'react';
import { connect } from 'react-redux';
import { Input } from 'semantic-ui-react';

const MessageSearchBar = ({ onSearchMessages, contact_id, queryParameters }) => (
    <Input onChange={(e, { value }) => { onSearchMessages(e, { value, contact_id, queryParameters }) }} transparent size='tiny' placeholder='Search message or date' icon='search' iconPosition='left' />
);

const mapStateToProps = state => ({
    contact_id: Number(state.contact.contact_id),
    queryParameters: state.text.queryParameters
})

const mapDispatchToProps = dispatch => ({
    onSearchMessages: (_, { value, contact_id, queryParameters }) => {
        if (value.trim() !== '') {
            return dispatch(textActions.searchMessages({ search: value, contact_id }));
        }
        return dispatch(textActions.getMessages(contact_id, { ...queryParameters }));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(MessageSearchBar);
