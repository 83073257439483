import React from 'react';
import { TableWithHeader } from 'dyl-components';
import TextEmailAlertHeader from './TextEmailAlertHeader';
import TextEmailAlertSetting from './TextEmailAlertSetting';
import { Grid } from 'semantic-ui-react';

class TextEmailAlertTable extends React.Component {
    state = {
        isEditMode: false,
        isEmailNotificationsEnabled: this.props.isEmailNotificationsEnabled,
        email_notifications_frequency: this.props.email_notifications_frequency,
        email_notifications_condition: this.props.email_notifications_condition
    }

    onChange = (_, { name, value }) => {
        this.setState({
            [name]: value
        });
    }

    onEnableEditMode = () => {
        this.setState({
            isEditMode: true
        })
    }

    onCloseEditMode = () => {
        this.setState({
            isEditMode: false,
            isEmailNotificationsEnabled: this.props.isEmailNotificationsEnabled,
            email_notifications_frequency: this.props.email_notifications_frequency,
            email_notifications_condition: this.props.email_notifications_condition
        })
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false,
        }, () => {
            let { isEmailNotificationsEnabled, email_notifications_frequency, email_notifications_condition } = this.state;
            this.props.onUpdate({
                isEmailNotificationsEnabled,
                email_notifications_frequency,
                email_notifications_condition
            })
        });
    }

    render() {
        return (
            <TableWithHeader 
                header={<TextEmailAlertHeader 
                    isEditMode={this.state.isEditMode}
                    onEnableEditMode={this.onEnableEditMode}
                    onCloseEditMode={this.onCloseEditMode}
                    onUpdate={this.onUpdate}
                />}

                table={
                    <Grid celled style={{margin: 0}} verticalAlign='middle'>
                        <TextEmailAlertSetting 
                            {...this.state}
                            onChange={this.onChange}
                        />
                    </Grid>
                }
            />
        )
    }
}

export default class TextEmailAlertTableContainer extends React.Component {
    state = {
        isEmailNotificationsEnabled: true,
        email_notifications_frequency: 'In 15 Minutes',
        email_notifications_condition: 'when not acknowledged'
    }

    onUpdate = (textEmailAlertSetting) => {
        this.setState({
            ...textEmailAlertSetting
        })
    }

    render() {
        return (
            <TextEmailAlertTable 
                {...this.state}
                onUpdate={this.onUpdate} 
            />
        )
    }
}
