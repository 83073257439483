import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function productPlanReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PRODUCT_PLAN, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReading: true };
        case getNamedAction(ACTION_NAMES.PRODUCT_PLAN, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReading: false, plans: (action.data.data || []).filter(plan => plan), count: action.data.count };
        case getNamedAction(ACTION_NAMES.PRODUCT_PLAN, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReading: false, plans: [], count: 0 };

        case getNamedAction(ACTION_NAMES.PRODUCT_PLAN, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreating: true };
        case getNamedAction(ACTION_NAMES.PRODUCT_PLAN, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreating: false };
        case getNamedAction(ACTION_NAMES.PRODUCT_PLAN, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreating: false };

        case getNamedAction(ACTION_NAMES.PRODUCT_PLAN, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, planBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.PRODUCT_PLAN, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, planBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.PRODUCT_PLAN, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, planBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.PRODUCT_PLAN, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, planBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.PRODUCT_PLAN, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, planBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.PRODUCT_PLAN, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, planBeingDeleted: null };

        default:
            return state;
    }
}


export default productPlanReducer;
