import SettingsCategory from 'shared/SettingsCategory';

import { GENERAL_SETTINGS } from './GENERAL_SETTINGS';
import { ACCOUNT_MANAGEMENT_SETTINGS } from './ACCOUNT_MANAGEMENT_SETTINGS';
import { AUTOMATION_SETTINGS } from './AUTOMATION_SETTINGS';
import { INTEGRATIONS_SETTINGS } from './INTEGRATIONS_SETTINGS';
import { CRM_CUSTOMIZATIONS_SETTINGS } from './CRM_CUSTOMIZATIONS_SETTINGS';
import { REPORTS_AND_LOGS_SETTINGS } from './REPORTS_AND_LOGS_SETTINGS';
import { PhoneManagement } from 'pages';

export const SETTINGS_ROOT = 'settings';

export const SETTINGS = [
    {
        path: 'general',
        name: 'General Settings',
        description: 'Organize and manage all aspects of your Accounts, including permissions, and more.',
        icon: 'fas fa-cog',
        pages: GENERAL_SETTINGS
    },
    {
        path: 'account-management',
        name: 'Account Management',
        description: 'View your company profile and DYL billing information.',
        icon: 'fas fa-user',
        pages: ACCOUNT_MANAGEMENT_SETTINGS
    },
    {
        path: 'phone-management',
        name: 'Phone Management',
        description: 'Manage your numbers, extensions, routing, IVR, prompts, and more.',
        icon: 'fas fa-phone-alt',
        child: 'general/call-recording',
        component: () => <PhoneManagement />
    },
    {
        path: 'integrations',
        name: 'Integrations/Data Import',
        description: 'Import, filter and classify your data.',
        icon: 'fas fa-download',
        pages: INTEGRATIONS_SETTINGS
    },
    {
        path: 'crm-customizations',
        name: 'CRM Customizations',
        description: 'Manage your customer relationships.',
        icon: 'fas fa-users',
        pages: CRM_CUSTOMIZATIONS_SETTINGS
    },
    {
        path: 'automation',
        name: 'Automation',
        description: 'Automate your lead communications.',
        icon: 'fad fa-robot',
        pages: AUTOMATION_SETTINGS
    },
    {
        path: 'reports-and-logs',
        name: 'Reports and Logs',
        description: 'View and download your logs.',
        icon: 'fas fa-file-chart-line',
        pages: REPORTS_AND_LOGS_SETTINGS
    }
].map(category => ({
    component: () => (
        <SettingsCategory
            title={category.name}
            description={category.description}
            settings={category.pages}
        />
    ),
    ...category,
}));
