import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Button } from 'dyl-components';

const PipelinesToolbar = ({ disableAddBtn, onOpenAddPipeline }) => {
    return (
        <div className='LeadPipelineOptions'>
            <Button className='LeadPipelineOptions__NewPipelineButton' onClick={onOpenAddPipeline} disabled={disableAddBtn}>
                <Icon name='plus' /> New Pipeline
            </Button>
        </div>
    );
}

export default PipelinesToolbar;
