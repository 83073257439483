import React from 'react';
import { Transition } from 'semantic-ui-react';
import CallerDetailsContainer from '../CallerDetails';
import ActionButtonsContainer from '../ActionButtons';
import ActiveCallControls from '../ActiveCallControls';

import { CloseableContent } from 'dyl-components';
import { connect } from 'react-redux';
import officeViewActions from 'actions/office_view';

export const ActionsView = ({
    actionsViewVisible,
    onCloseActionsView,
    isActiveCall,
    onCall,
    callerInActionsView
}) => (
    <Transition visible={callerInActionsView && actionsViewVisible} unmountOnHide>
        <CloseableContent onClose={onCloseActionsView} uncloseable={isActiveCall}>
            <div>
                <CallerDetailsContainer />
                <ActionButtonsContainer
                    onCall={onCall}
                    isActiveCall={isActiveCall}
                />
                <Transition visible={isActiveCall} unmountOnHide>
                    <div className='OfficeView__active-call-controls'>
                        <ActiveCallControls />
                    </div>
                </Transition>
            </div>
        </CloseableContent>
    </Transition>
)

const mapStateToProps = state => {
    return {
        callerInActionsView: state.officeView.callerInActionsView,
        actionsViewVisible: state.officeView.actionsViewVisible,
        isActiveCall: state.officeView.isActiveCall
    }
}

const mapDispatchToProps = dispatch => ({
    onCloseActionsView: () => {
        dispatch(officeViewActions.closeActionsView());
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ActionsView);
