import React, { useState, useContext } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { SearchBar, Button } from 'dyl-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ContactGroupPopup from './ContactGroupPopup';
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import GroupBulkActionsModal from 'shared/modals/GroupBulkActionsModal';

const GroupsToolbar = ({ onSave, isPopupOpen, setIsPopupOpen, isDuplicated, reload, setIsHeaderChecked, getSubgroups }) => {
    const navigate = useNavigate();
    const [ query ] = useSearchParams();
    const search = query.get("search") || '';
    const [isBulkActionsModalOpen, setIsBulkActionsModalOpen] = useState(false);
    const [selectedGroups] = useContext(BulkActionsContext);

    const { isReadingGroups } = useSelector(state => ({
        isReadingGroups: state.groups.isReadingGroups,
    }));

    const searchFunction = (value) => navigate(`/groups?search=${value}`);
    const cancelFunction = () => navigate(`/groups`);

    const hasSelectedGroups = selectedGroups.length > 0;
    const numberOfSelectedGroups = selectedGroups.length;

    const onOpenBulkActionsModal = () => {
        setIsBulkActionsModalOpen(true);
    }

    const onCloseBulkActionsModal = () => {
        setIsBulkActionsModalOpen(false);
    }

    return (
        <div className='GroupsToolbar'>
            <div className='GroupsToolbar__searchContainer'>
                <SearchBar
                    isSearching={isReadingGroups}
                    searchFcn={searchFunction}
                    cancelFcn={cancelFunction}
                    search={search}
                    placeholder="Search by Group or Subgroup Name"
                />
            </div>
            <div>
                <Button disabled={!hasSelectedGroups} onClick={onOpenBulkActionsModal}>
                    Bulk Action
                </Button>
                <Popup
                    trigger={
                        <Button>
                            <Icon name='plus' /> New Group
                        </Button>
                    }
                    content={<ContactGroupPopup handlePopupClose={() => setIsPopupOpen(false)} onSave={onSave} isDuplicated={isDuplicated} />}
                    on="click"
                    position='bottom left'
                    open={isPopupOpen}
                    onClose={() => setIsPopupOpen(false)}
                    onOpen={() => setIsPopupOpen(true)}
                />
            </div>
            <GroupBulkActionsModal
                open={isBulkActionsModalOpen}
                onClose={onCloseBulkActionsModal}
                numberSelectedGroups={numberOfSelectedGroups}
                reload={reload}
                setIsHeaderChecked={setIsHeaderChecked}
                getSubgroups={getSubgroups}
            />
        </div>
    );
}

export default GroupsToolbar;
