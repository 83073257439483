const initialState = {
    notes: [],
    count: 0,
    isReadingNotes: false,

    sticky_notes: [],
    sticky_notes_count: 0,
    isReadingStickyNotes: false,

    isReadingAccountRelatedNotes: false,
    accountRelatedNotes: [],
    
    isAddingLeadNote: false,
    addLeadNoteMessage: null,
    isCreatingNote: false,
    queryParameters: {
        page: 1,
        limit: 7
    }
}

export default initialState;
