import React from 'react';
import { Grid, Dropdown, Divider, Input } from 'semantic-ui-react';
import { Button, STATUS_TYPES } from 'dyl-components';

import './TransferForm.scss';

const TransferForm = ({ onConfirmTransfer, onChange }) => (
    <Grid>
        <Grid.Row columns='equal' className="TransferFormRow">
            <Grid.Column textAlign='center'>
                <Dropdown
                    placeholder='Select agent'
                    fluid
                    search
                    selection
                    onChange={onChange}
                />
                <Divider horizontal>Or</Divider>
                <Input onChange={onChange} type='text' name='phone_number' placeholder='Specify phone number' fluid />
                <div className="TransferFormDiv">
                <Button status={STATUS_TYPES.PRIMARY} className="TransferFormButton" onClick={onConfirmTransfer}>Transfer</Button>
                </div>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

export default TransferForm;
