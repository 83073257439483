import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Form, Header, Segment } from 'semantic-ui-react';
import { StringUtils } from 'utils';

import './index.scss';

const PricingDetails = () => {
    const { control, trigger, type } = useFormContext();

    return (
        <Segment>
            <Header as="h3" color="primary">Pricing Details</Header>
            <Controller
                name='percent'
                control={control}
                render={({ field: { name: percentName, value: percentValue, onChange: onPercentChange } }) => (
                    <Form.Group>
                        <Controller
                            name='amount'
                            control={control}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Input
                                    width='7'
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value: percentValue === false ? StringUtils.formatDecimal(value) : value } });
                                    }}
                                    error={error?.message}
                                    label={`${StringUtils.capitalize(type)} Amount`}
                                    required
                                    {...(percentValue === false ? { icon: "fas fa-dollar-sign", iconPosition: "left" } : {})}
                                />
                            )
                            }
                        />
                        <Form.Field className='AddFeeForm__FeeAmountType'>
                            <label>hidden</label>
                            <Button.Group>
                                <Button
                                    {...(percentValue === true ? { primary: true } : { basic: true })}
                                    onClick={() => { onPercentChange({ target: { name: percentName, value: true } }); trigger('amount'); }}
                                >%</Button>
                                <Button
                                    {...(percentValue === false ? { primary: true } : { basic: true })}
                                    onClick={() => { onPercentChange({ target: { name: percentName, value: false } }); trigger('amount'); }}
                                >$</Button>
                            </Button.Group>
                        </Form.Field>
                    </Form.Group>
                )}
            />
        </Segment>
    );
}

export default PricingDetails;
