import initialState from './initialState';

export default (state = initialState, { type, ...action }) => {
    switch (type) {
        case 'EMAIL_FORM_EDITOR_ON_CHANGE':
            return { ...state, ...action };
        case 'EMAIL_FORM_ON_CHANGE':
            return { ...state, ...action }
        case 'EMAIL_FORM_DELETE':
            return { ...state, ...action }
        case 'EMAIL_FORM_CLEAR':
            return { ...state, ...action }
        case 'EMAIL_FORM_CHANGE_SUBJECT_BODY':
            return { ...state, ...action }
        default:
            return state
    }
}
