import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Grid, Header, Icon, Segment } from 'semantic-ui-react';

import favoritesActions from 'actions/favorites';
import FavoriteCategory from './subcomponents/FavoriteCategory';
import { ButtonLink, Notification, STATUS_TYPES } from 'dyl-components';
import { Controller, useForm } from 'react-hook-form';
import { FAVORITE_TYPE } from 'shared/constants/FAVORITE_TYPE';

const types = Object.keys(FAVORITE_TYPE).map(key => FAVORITE_TYPE[key]);

const Favorites = () => {
    const { favorites, loading, isRemovingFavorite } = useSelector(state => ({
        favorites: state.favorites.favorites.filter(favorite => favorite.favorite_type && (
            (favorite.favorite_type !== FAVORITE_TYPE.SETTING && favorite.favorite_type !== FAVORITE_TYPE.REPORT && !isNaN(favorite.external_id)) || favorite.setting_report_type
        )).slice(0),
        loading: state.favorites.isReadingFavorites,
        isRemovingFavorite: state.favorites.isRemovingFavorite
    }));

    const [expandedCategories, setExpandedCategories] = useState([]);

    const isExpanded = (category) => {
        return expandedCategories.includes(category);
    }

    const onToggle = (category) => {
        setExpandedCategories(isExpanded(category) ? expandedCategories.filter(expandedCategory => expandedCategory !== category) : [...expandedCategories, category]);
    }

    const atLeastOneExpanded = expandedCategories.length > 0;

    const collapseAll = () => {
        setExpandedCategories([]);
    }

    const expandAll = () => {
        setExpandedCategories([...new Set(favorites.map(favorite => favorite.favorite_type))]);
    }

    const { control, setValue, handleSubmit, watch } = useForm({
        mode: "onChange",
        defaultValues: {
            favoritesToRemove: []
        }
    })

    const [isEditing, setIsEditing] = useState(false);

    const onEdit = () => {
        setIsEditing(true);
    }

    const onCancelEdit = () => {
        setIsEditing(false);
        setValue("favoritesToRemove", []);
    }

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(favoritesActions.readFavorites({ display_name: true })).then(favorites => {
            setExpandedCategories([...new Set(favorites.map(favorite => favorite.favorite_type))])
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const toRemove = watch("favoritesToRemove");

    const onUpdateFavorites = async () => {
        if (toRemove.length) {
            try {
                await dispatch(favoritesActions.deleteFavorite(null, null, null, null, toRemove));
                Notification.alert("Successfully removed favorite(s)", STATUS_TYPES.SUCCESS);
                setIsEditing(false);
                dispatch(favoritesActions.readFavorites({ display_name: true })).then(() => {
                    setExpandedCategories([...new Set(favorites.map(favorite => favorite.favorite_type))])
                });
            } catch (e) {
                console.log(e);
                Notification.alert("Failed to remove favorite(s)", STATUS_TYPES.ERROR);
            }
        }
    }

    return (
        <Segment loading={isRemovingFavorite || loading} basic style={{ padding: 0 }}>
            <Header color="primary">Favorites</Header>
            <Divider />
            <Grid columns={"equal"}>
                <Grid.Column>
                    <Header as="h4" color="primary">
                        <span>
                            <Icon link color="primary" onClick={atLeastOneExpanded ? collapseAll : expandAll} className={`fas fa-circle-chevron-${atLeastOneExpanded ? "up" : "down"}`} /> All
                        </span>
                    </Header>
                </Grid.Column>
                <Grid.Column textAlign='right'>
                    {!isEditing ? <ButtonLink onClick={onEdit} noPadding><Icon className='fas fa-pencil' /> Edit All</ButtonLink> : (
                        <>
                            <ButtonLink onClick={onCancelEdit} noPadding status={STATUS_TYPES.ERROR}><Icon className='fas fa-times' /> Cancel</ButtonLink> | <ButtonLink onClick={handleSubmit(onUpdateFavorites)} disabled={toRemove.length === 0} noPadding><Icon className='fas fa-check' /> Save</ButtonLink>
                        </>
                    )}
                </Grid.Column>
            </Grid>
            {loading ? <></> : (
                <Controller
                    control={control}
                    name="favoritesToRemove"
                    render={({ field: { name, value, onChange } }) => (
                        types.map(category => {
                            const isFavorite = (favorite) => {
                                const { external_id, favorite_type, setting_report_type } = favorite;
                                return value.findIndex(favorite => (
                                    favorite.favorite_type === favorite_type && (
                                        favorite.setting_report_type ? favorite.setting_report_type === setting_report_type :
                                        favorite.external_id === (!isNaN(external_id) ? Number(external_id) : undefined)
                                    ))) === -1;
                            }

                            return (
                                <FavoriteCategory
                                    key={category}
                                    category={{ group: category, data: favorites.filter(favorite => favorite.favorite_type === category) }}
                                    onToggle={() => { onToggle(category); }}
                                    isExpanded={isExpanded(category)}
                                    isEditing={isEditing}
                                    onToggleFavorite={(favorite) => {
                                        onChange({
                                            target: {
                                                name, value: isFavorite(favorite) ?
                                                    [...value, favorite] :
                                                    value.filter(favoriteToRemove => {
                                                        const { external_id, favorite_type, setting_report_type } = favorite;
                                                        return favoriteToRemove.favorite_type !== favorite_type || (
                                                            favoriteToRemove.setting_report_type ? favoriteToRemove.setting_report_type !== setting_report_type :
                                                                favoriteToRemove.external_id !== external_id
                                                        )
                                                    })
                                            }
                                        });
                                    }}
                                    isFavorite={isFavorite}
                                />
                            );
                        })
                    )}
                />
            )}
        </Segment>
    )
}

export default Favorites;
