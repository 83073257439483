const initialState = {
    triggers: [],
    trigger: null,
    isCreatingTrigger: false,
    isReadingTriggers: false,
    distribution_users: [],
    isReadingDistributionUsers: false,
    isUpdatingCap: false,
    isReadingUniqueRule: false,
    isUniqueRule: null,
    isReadingTrigger: false,
}

export default initialState;
