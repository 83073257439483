import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Icon, Header } from 'semantic-ui-react';
import MoveContactForm from './MoveContactForm';
import { useDispatch, useSelector } from 'react-redux';
import { yup, generateResolver, Notification, STATUS_TYPES } from 'dyl-components';
import groupsActions from 'actions/groups';
import { useSearchParams } from 'react-router-dom';
import oauthActions from 'actions/oauth';
import { BulkActionsContext } from "shared/context/BulkActionsProvider";

const MoveContactGroup = ({ handlePopupClose, contact_id, group_id }) => {
    const [search, setSearch] = useState('');
    const [groupOptions, setGroupOptions] = useState([]);

    const [params] = useSearchParams();
    const page = params.get("page") || ""
    const searchParam = params.get("search") || "";
    const order = params.get("order") || ""
    const start = params.get("start") || "";
    const end = params.get("end") || "";
    const account_type = params.get("account_type") || "";

    const [, setSelectedContacts, , setAreContactsInAllPagesSelected] = useContext(BulkActionsContext);

    const { control, formState: { isDirty, isValid }, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            group: null
        },
        resolver: generateResolver({
            group: yup.mixed().required('This field is required'),
        })
    });

    const dispatch = useDispatch();

    const { groups, subgroups, isReadingGroups } = useSelector((state) => {
        return {
            groups: state.groups.groups,
            subgroups: state.groups.subgroups,
            isReadingGroups: state.groups.isReadingGroups
        }
    });
    
    const handleSearchChange = (e, { searchQuery }) => {
        setSearch(searchQuery);
    }

    const getSubgroups = async (parent_label_id) => {
        await dispatch(groupsActions.readSubgroups({parent_label_id}));
    }

    const reloadContacts = () => {
        const query = {
            search: searchParam,
            order,
            page,
            account_type,
            start_time: start,
            end_time: end,
            limit: 25
        }
        if (group_id !== "hotlist") {
            dispatch(groupsActions.readGroupContacts(group_id, query));
        } else {
            dispatch(groupsActions.readHotlist(query));
        }
        dispatch(oauthActions.getIntegrations({ scopes: 'email', include_disabled: false }));
        setSelectedContacts([]);
        setAreContactsInAllPagesSelected(false);
    }
    
    const onMove = async (data) => {
        const { group: { value } } = data;
        try {
            await dispatch(groupsActions.bulkGroupContacts({ group_id, action: "move_contacts", contact_id: [contact_id], new_group_id: value }));
            handlePopupClose();
            reloadContacts();
        Notification.alert(`Successfully moved the contact!`, STATUS_TYPES.SUCCESS);
        } catch (error) {
            console.log(error);
            Notification.alert(`Failed to move the contact`, STATUS_TYPES.ERROR);
        }
    }

    const getGroups = useCallback(async (search) => {
        await dispatch(groupsActions.readGroups({search, limit: 500}));
    }, [dispatch])

    useEffect(() => {
        getGroups(search);
    }, [search, getGroups])

    useEffect(() => {
        const groupOptionsAux = groups.map((group) => {
            let subgroupsAux = [];
            if (subgroups[group.id] && subgroups[group.id].length > 0) {
                subgroupsAux = subgroups[group.id].map((subgroup) => ({text: subgroup.name, key: subgroup.id, value: subgroup.id}));
            }
            return {text: group.name, key: group.id, value: group.id, hasOptions: group.sub_labels, options: subgroupsAux};
        })
        setGroupOptions(groupOptionsAux);
    }, [groups, subgroups])

    return (
        <div className='GroupForm__moveContainer'>
            <Icon
                className='fa-solid fa-xmark GroupForm__xIcon'
                size='large'
                onClick={handlePopupClose}
                link
            />
            <div className='GroupForm__formContainer'>
                <Header as={"h3"}>Move Contact</Header>
                <MoveContactForm 
                    control={control} 
                    groupOptions={groupOptions} 
                    getSubgroups={getSubgroups} 
                    isReadingGroups={isReadingGroups}
                    search={search}
                    setSearch={setSearch}
                    handleSearchChange={handleSearchChange}
                />
            </div>
            <Button
                className='GroupFrom__saveBtn'
                disabled={!isDirty || !isValid}
                onClick={handleSubmit(onMove)}
                primary
            >
                Save
            </Button>
        </div>
    ); 
}

export default MoveContactGroup;