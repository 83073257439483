import { Table } from "dyl-components";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header, Icon, Transition } from "semantic-ui-react";
import ProductField from "./ProductField";
import EditFieldModal from "shared/modals/EditFieldModal";
import { StringUtils } from "utils";
import supplyChainNetworkService from "actions/supply_chain_network";

const Fields = () => {
    const fields = useSelector((state) => state.supply_chain_network.fields);
    const [fieldBeingEdited, setFieldBeingEdited] = useState(null);

    const getFieldBeingEdited = () => {
        const field =
            fields.find((field) => field.name === fieldBeingEdited) || {};
        return {
            field_type: field.type,
            field_name: field.name,
            label: StringUtils.capitalize(field.name),
            description: field.description,
            options: {
                data: field.values.map((value) => ({
                    id: value.id,
                    value: value.name
                }))
            },
            standard: true
        };
    };

    const onEdit = (field_name) => {
        setFieldBeingEdited(field_name);
    };

    const onCancelEdit = () => {
        setFieldBeingEdited(null);
    };

    const dispatch = useDispatch();

    const onUpdate = async (data) => {
        const { field_name, options } = data;
        return dispatch(
            supplyChainNetworkService.patchField(
                null,
                options.map(({ id, name }) => {
                    return {
                        ...(Number.isInteger(id) ? { id } : {}),
                        name,
                    };
                }),
                { type: field_name },
                null
            )
        );
    };

    const refresh = () => {
        return dispatch(supplyChainNetworkService.getFields());
    };

    return (
        <>
            {fields.map((field) => (
                <ProductField
                    key={field.name}
                    field={field}
                    onEdit={() => {
                        onEdit(field.name);
                    }}
                    isSubrow
                />
            ))}
            {fieldBeingEdited && (
                <EditFieldModal
                    field={getFieldBeingEdited()}
                    onClose={onCancelEdit}
                    onUpdate={onUpdate}
                    open={fieldBeingEdited}
                    refresh={refresh}
                    willAffectExistingValues
                />
            )}
        </>
    );
};

const SupplyChainNetwork = () => {
    const [isOpen, setIsOpen] = useState(true);

    const onToggle = () => {
        setIsOpen(!isOpen);
    };

    const isReadingFields = useSelector(
        (state) => state.supply_chain_network.isReadingFields
    );

    return (
        <>
            <Table.Row>
                <Table.Cell colSpan={6}>
                    <Header color="primary">
                        <span>
                            <Icon
                                onClick={onToggle}
                                link
                                size="small"
                                className={`fas fa-circle-chevron-${
                                    isOpen ? "up" : "down"
                                }`}
                                disabled={isReadingFields}
                            />
                            Supply Chain Network
                        </span>
                    </Header>
                </Table.Cell>
            </Table.Row>
            <Transition
                visible={isOpen}
                animation={"slide down"}
                unmountOnHide
                duration={"150"}
            >
                <Fields />
            </Transition>
        </>
    );
};

export default SupplyChainNetwork;
