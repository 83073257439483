import React from 'react';
import { Grid, Select, Input, Checkbox, Transition } from 'semantic-ui-react';

import { EditableField } from 'dyl-components';
import { EditableContent } from 'dyl-components';

import './RingOptionsForm.scss';

const ringModeOptions = [
    { key: 'Ring All', value: 'Ring All', text: 'Ring All' },
    { key: 'Round Robin', value: 'Round Robin', text: 'Round Robin' },
    { key: 'Call Queue', value: 'Call Queue', text: 'Call Queue' }
];

const introMessageOptions = [
    { key: 'None', value: 'None', text: 'None' }
];

const announceMessageOptions = [
    { key: 'None', value: 'None', text: 'None' },
    { key: 'Announce Position', value: 'Announce Position', text: 'Announce Position' }
];

const holdMusicOptions = [
    { key: 'Ringing', value: 'Ringing', text: 'Ringing' },
    { key: 'DYL Hold Music', value: 'DYL Hold Music', text: 'DYL Hold Music' }
];

const queueStrategyOptions = [
    { key: 'Ring All', value: 'Ring All', text: 'Ring All' },
    { key: 'Round Robin', value: 'Round Robin', text: 'Round Robin' },
    { key: 'Random', value: 'Random', text: 'Random' },
    { key: 'Longest Idle', value: 'Longest Idle', text: 'Longest Idle' }
];

const queueExitKeyOptions = [
    'None', '#', '*', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
].map(option => ({ key: option, value: option, text: option }));

const RingOptionsForm = ({
    info,
    isEditMode,
    onChange
}) => (
    <React.Fragment>
        <Grid.Row columns='equal'>
            <EditableField
                label='Name'
                value={info.name}
                name='name'
                isEditMode={isEditMode}
                control={Input}
                onChange={onChange}
            />
        </Grid.Row>
        <Grid.Row columns='equal'>
            <EditableField
                label='Ring Mode'
                value={isEditMode ? info.ringMode : ringModeOptions.filter(ringMode => ringMode.key === info.ringMode)[0].text}
                name='ringMode'
                isEditMode={isEditMode}
                control={Select}
                onChange={onChange}
                options={ringModeOptions}
            />
        </Grid.Row>
        <Grid.Row columns='equal'>
            <Grid.Column width={3} className="RingOptionsFormColumn">
                Ring Time
            </Grid.Column>
            <Grid.Column>
                <EditableContent
                    value={info.ringTime}
                    name='ringTime'
                    isEditMode={isEditMode}
                    control={Input}
                    type={'number'}
                    onChange={onChange}
                    max={600}
                    min={5}
                /> seconds
            </Grid.Column>
        </Grid.Row>
        <Transition unmountOnHide visible={info.ringMode === 'Call Queue'} animation='slide down' duration='100'>
            <Grid.Row style={{ margin: 0 }}>
                <Grid.Column style={{ margin: 0, padding: 0 }}>
                    <Grid celled style={{ margin: 0, padding: 0 }}>
                        <Grid.Row columns='equal'>
                            <EditableField
                                label='Hold Music'
                                value={isEditMode ? info.holdMusic : info.holdMusic && holdMusicOptions.filter(music => music.key === info.holdMusic)[0].text}
                                name='holdMusic'
                                isEditMode={isEditMode}
                                control={Select}
                                onChange={onChange}
                                search
                                options={holdMusicOptions}
                            />
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <EditableField
                                label='Queue Strategy'
                                value={isEditMode ? info.strategy : info.queueStrategy && queueStrategyOptions.filter(strategy => strategy.key === info.strategy)[0].text}
                                name='strategy'
                                isEditMode={isEditMode}
                                control={Select}
                                onChange={onChange}
                                options={queueStrategyOptions}
                            />
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <EditableField
                                label='Queue Exit Key'
                                value={isEditMode ? info.exitKey : info.exitKey && queueExitKeyOptions.filter(exitKey => exitKey.key === info.exitKey)[0].text}
                                name='exitKey'
                                isEditMode={isEditMode}
                                control={Select}
                                onChange={onChange}
                                options={queueExitKeyOptions}
                            />
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <EditableField
                                label='Introduction Message'
                                value={isEditMode ? info.introMessage : info.introMessage && introMessageOptions.filter(introMessage => introMessage.key === info.introMessage)[0].text}
                                name='introMessage'
                                isEditMode={isEditMode}
                                control={Select}
                                onChange={onChange}
                                options={introMessageOptions}
                            />
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <Grid.Column width={3} className="RingOptionsFormColumn">
                                Direct Extensions
                            </Grid.Column>
                            <Grid.Column>
                                <EditableContent
                                    value={info.directExtensions ? 'Yes' : 'No'}
                                    control={Checkbox}
                                    onChange={(_, { name, checked }) => { onChange(null, { name, value: checked }) }}
                                    checked={info.directExtensions}
                                    name='directExtensions'
                                    isEditMode={isEditMode}
                                /> &nbsp; (During Introduction)
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <EditableField
                                label='Announce Message'
                                value={isEditMode ? info.announceMessage : info.announceMessage && announceMessageOptions.filter(announceMessage => announceMessage.key === info.announceMessage)[0].text}
                                name='announceMessage'
                                isEditMode={isEditMode}
                                control={Select}
                                onChange={onChange}
                                options={announceMessageOptions}
                            />
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <Grid.Column width={3} className="RingOptionsFormColumn">
                                Announce Frequency
                            </Grid.Column>
                            <Grid.Column>
                                <EditableContent
                                    value={info.announceFrequency}
                                    name='announceFrequency'
                                    isEditMode={isEditMode}
                                    control={Input}
                                    type={'number'}
                                    onChange={onChange}
                                    max={10}
                                    min={0.5}
                                /> minute{info.announceFrequency === 1 ? '' : 's'}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        </Transition>
        <Grid.Row columns='equal'>
            <EditableField
                label='Internal Extension'
                value={info.internalExtension}
                name='internalExtension'
                isEditMode={isEditMode}
                control={Input}
                onChange={onChange}
            />
        </Grid.Row>
    </React.Fragment>
)

export default RingOptionsForm;
