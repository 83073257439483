import React, { useEffect } from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { Outlet } from 'react-router';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CampaignTabsHeader from './subcomponents/Header';
import campaignActions from 'actions/campaigns';
import { Error404, Error500 } from 'pages/ErrorPages';
import BulkActionsProvider from 'shared/context/BulkActionsProvider';
import recentsActions from 'actions/recents';
import { FAVORITE_TYPE } from 'shared/constants/FAVORITE_TYPE';

const CampaignTabs = () => {
    const id = Number(useParams().id);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(campaignActions.readCampaign(id));
        dispatch(recentsActions.addRecent({ external_id: id, page_type: FAVORITE_TYPE.CAMPAIGN }))
    }, [id, dispatch]);

    const { isReadingCampaign, campaign, campaignError } = useSelector(state => ({
        isReadingCampaign: state.campaigns.isReadingCampaign,
        campaign: state.campaigns.campaign,
        campaignError: state.campaigns.campaignError
    }));

    if (isReadingCampaign) {
        return (
            <Dimmer active>
                <Loader active />
            </Dimmer>
        );
    }

    if (!isReadingCampaign && campaignError) {
        return <Error500 message={"Something went wrong"} />;
    }

    if (!isReadingCampaign && !campaign) {
        return <Error404 message={"Campaign not found"} />;
    }

    return (
        <Segment className='CampaignTabs' basic>
            <CampaignTabsHeader id={id} campaign={campaign} />
            <Outlet />
        </Segment>
    );
}

export default function CampaignTabsContainer() {
    return (
        <BulkActionsProvider>
            <CampaignTabs />
        </BulkActionsProvider>
    )
};
