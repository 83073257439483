import React from 'react';
import { TableWithHeader } from 'dyl-components';
import ScheduleHeader from './ScheduleHeader';
import ScheduleTable from './ScheduleTable';

class CustomSchedule extends React.Component {
    state = {
        isEditMode: false,
        schedule: this.props.schedule
    }

    onChange = (dayOfWeek, { name, value }) => {
        let schedule = this.state.schedule.slice(0);
        let indexOfDayScheduleToChange = schedule.findIndex(daySchedule => daySchedule.dayOfWeek === dayOfWeek);
        if (indexOfDayScheduleToChange !== -1) {
            schedule[indexOfDayScheduleToChange] = {...schedule[indexOfDayScheduleToChange], [name]: value};
            this.setState({ schedule });
        }
    }

    onEnableEditMode = () => {
        this.setState({
            isEditMode: true
        })
    }

    onCloseEditMode = () => {
        this.setState({
            isEditMode: false,
            schedule: this.props.schedule
        })
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false,
        }, () => {
            this.props.onUpdate(this.state.schedule);
        });
    }

    render() {
        return (
            <TableWithHeader 
                header={
                    <ScheduleHeader 
                        isEditMode={this.state.isEditMode}
                        onEnableEditMode={this.onEnableEditMode}
                        onCloseEditMode={this.onCloseEditMode}
                        onUpdate={this.onUpdate}
                    />
                }
                table={
                    <ScheduleTable 
                        schedule={this.state.schedule}
                        isEditMode={this.state.isEditMode}
                        onChange={this.onChange}
                    />
                }
            />
        )
    }
}

export default class CustomScheduleContainer extends React.Component {
    state = {
        schedule: [
            {
                dayOfWeek: 'Monday', open: true, start: '5:00 AM', end: '10:45 PM'
            },
            {
                dayOfWeek: 'Tuesday', open: true, start: '5:00 AM', end: '10:45 PM'
            },
            {
                dayOfWeek: 'Wednesday', open: true, start: '5:00 AM', end: '10:45 PM'
            },
            {
                dayOfWeek: 'Thursday', open: true, start: '5:00 AM', end: '10:45 PM'
            },
            {
                dayOfWeek: 'Friday', open: true, start: '5:00 AM', end: '10:45 PM'
            },
            {
                dayOfWeek: 'Saturday', open: true, start: '5:00 AM', end: '10:45 PM'
            },
            {
                dayOfWeek: 'Sunday', open: true, start: '5:00 AM', end: '10:45 PM'
            }
        ]
    }

    onUpdate = (newSchedule) => {
        this.setState({ schedule: newSchedule });
    }

    render() {
        return (
            <CustomSchedule 
                {...this.state}
                onUpdate={this.onUpdate}
            />
        )
    }
}
