import React from 'react';
import { TableWithHeader, Notification, STATUS_TYPES } from 'dyl-components';
import { Grid, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';

import CallRecordingSettingsFormContainer from './CallRecordingsSettingsForm';

const CallRecordingSettings = ({ 
    callRecordingSettings, 
    isUserAccessEnabled, 
    isDeleteRecordingsEnabled,
    isRecordingConsented, 
    isEditMode,
    onEnableEditMode,
    onDisableEditMode,
    onConfirmEdit,
    onChange
}) => (
    <TableWithHeader 
        header={(
            <Grid verticalAlign='middle'>
                <Grid.Column width={6}>
                    Call Recording Settings
                </Grid.Column>
                <Grid.Column width={4} textAlign='right' floated='right'>
                    {!isEditMode ? <Button onClick={onEnableEditMode} size='tiny' basic inverted color='white'>Edit</Button> :
                    <React.Fragment>
                        <Button size='tiny' inverted color='white' basic onClick={onDisableEditMode}>Cancel</Button>
                        <Button size='tiny' inverted color='white' basic onClick={onConfirmEdit}>Update</Button>
                    </React.Fragment>
                }
                </Grid.Column>
            </Grid>
        )}
        table={(
            <Grid celled style={{margin: 0}} verticalAlign='middle'>
                <CallRecordingSettingsFormContainer
                    isEditMode={isEditMode}
                    onChange={onChange}
                    callRecordingSettings={callRecordingSettings}
                    isUserAccessEnabled={isUserAccessEnabled}
                    isDeleteRecordingsEnabled={isDeleteRecordingsEnabled}
                    isRecordingConsented={isRecordingConsented}
                />
            </Grid>
        )}
    />
);

class CallRecordingSettingsContainer extends React.Component {
    state = {
        isEditMode: false,
        callRecordingSettings: this.props.callRecordingSettings, 
        isUserAccessEnabled: this.props.isUserAccessEnabled, 
        isDeleteRecordingsEnabled: this.props.isDeleteRecordingsEnabled,
        isRecordingConsented: this.props.isRecordingConsented
    }

    onEnableEditMode = () => {
        this.setState({
            isEditMode: true
        })
    }

    onDisableEditMode = () => {
        this.setState({
            ...this.state,
            isEditMode: false
        })
    }

    onChange = (_, {name, value}) => {
        this.setState({
            [name]: value
        })
    }

    onConfirmEdit = (updatedCallRecordingSettings) => {
        this.setState({
            ...updatedCallRecordingSettings,
            isEditMode: false
        }, () => {
            Notification.alert('Successfully updated call recording settings!', STATUS_TYPES.SUCCESS, true);
        })
    }

    render() {
        return (
            <CallRecordingSettings 
                {...this.state}
                onChange={this.onChange}
                onEnableEditMode={this.onEnableEditMode}
                onDisableEditMode={this.onDisableEditMode}
                onConfirmEdit={this.onConfirmEdit}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        callRecordingSettings: !state.callRecordings.disabled,
        isUserAccessEnabled: state.callRecordings.isUserAccessEnabled,
        isDeleteRecordingsEnabled: state.callRecordings.isDeleteRecordingsEnabled,
        isRecordingConsented: state.callRecordings.options.isRecordingConsented
    }
}

export default connect(mapStateToProps, null)(CallRecordingSettingsContainer);
