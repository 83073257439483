import React from 'react';
import { Popup, Form } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationPrompt, STATUS_TYPES, Notification } from 'dyl-components';

import masterAccountActions from "actions/master_account";

const DeleteMasterAccountPopup = ({ isDeleteMasterAccountPopupOpen, accountPopupRef, deleteMasterAccount, onFormClose, onFormSubmit, refresh }) => {
    const dispatch = useDispatch();

    const { isDeletingMasterAccount } = useSelector(state => ({
        isDeletingMasterAccount: state.master_account.isDeletingMasterAccount
    }));

    return (
        <Popup
            open={isDeleteMasterAccountPopupOpen}
            position='bottom center'
            context={accountPopupRef}
            onClose={() => onFormClose()}
            content={(
                <Form loading={isDeletingMasterAccount}>
                    <ConfirmationPrompt
                        header={<div style={{ paddingTop: '1em' }}>Are you sure?</div>}
                        onConfirm={async () => {
                            try {
                                onFormSubmit();
                                await dispatch(masterAccountActions.delete(deleteMasterAccount));
                                Notification.alert('Successfully deleted master account!', STATUS_TYPES.SUCCESS);
                                refresh();
                            } catch (e) {
                                console.log(e);
                                Notification.alert('Failed to delete master account', STATUS_TYPES.ERROR)
                            }
                        }}
                        onCancel={() => { onFormClose() }}
                    />
                </Form>
            )}
        />
    );
}

export default DeleteMasterAccountPopup;
