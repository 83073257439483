import React from 'react';
import { Checkbox, Popup, Table as SemanticTable } from 'semantic-ui-react';
import { Table } from 'dyl-components';

const Header = () => {
    return (
        <SemanticTable.Header className='Table__header--primary Table__header--checkbox'>
            <Table.Row>
                <SemanticTable.HeaderCell width={1}>
                    <Popup
                        position="bottom right"
                        open={false}
                        trigger={<Checkbox checked={false} onClick={() => {}} indeterminate={false} />}
                        content={<div> All {`${0} groups on this page`} selected. </div>}
                    />
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell width={1} />
                <SemanticTable.HeaderCell width={4}>
                    Category/Product Name
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell>
                    Type
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell width={4}>
                    Description
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell>
                    Price
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell>
                    Status
                </SemanticTable.HeaderCell>
                <SemanticTable.HeaderCell>
                    Actions
                </SemanticTable.HeaderCell>
            </Table.Row>
        </SemanticTable.Header>
    );
}

export default Header;
