import React, { useContext } from 'react';
import { Modal } from 'dyl-components';

import { PopupForm } from './ConversionControls';
import DeleteMasterAccountPopup from './DeleteMasterAccountPopup';
import UnlinkPopupForm from 'shared/UnlinkPopupForm';
import LinkToMasterAccountForm from "shared/forms/LinkToMasterAccountForm";
import { FormsContext } from './FormsProvider';

const Forms = ({ readAccounts }) => {
    const {
        statusRef,
        accountConversion,
        conversionModalOpen,
        isConversionFormModified,
        setIsConversionFormModified,
        onCloseConversion,

        actionPopupRef,
        deleteMasterAccount,
        isDeleteMasterAccountPopupOpen,
        unlinkAccount,
        isUnlinkAccountPopupOpen,
        setUnlinkAccountPopupOpen,
        linkAccount,
        isLinkMasterAccountModalOpen,
        onLinkFormClose,
        closeLinkMasterAccountModal,
        onDeleteMasterAccountFormSubmit,
        onDeleteMasterAccountFormClose,
        onUnlinkFormSubmit,
        onUnlinkFormClose
    } = useContext(FormsContext);

    return (
        <>
            <PopupForm
                account={accountConversion}
                statusRef={statusRef}
                conversionModalOpen={conversionModalOpen}
                isConversionFormModified={isConversionFormModified}
                setIsConversionFormModified={setIsConversionFormModified}
                onClose={onCloseConversion}
                refresh={() => {
                    readAccounts();
                    onCloseConversion();
                }}
            />
            <DeleteMasterAccountPopup
                isDeleteMasterAccountPopupOpen={isDeleteMasterAccountPopupOpen}
                accountPopupRef={actionPopupRef}
                deleteMasterAccount={deleteMasterAccount}
                onFormClose={onDeleteMasterAccountFormClose}
                onFormSubmit={onDeleteMasterAccountFormSubmit}
                refresh={() => {
                    readAccounts();
                    onDeleteMasterAccountFormClose();
                }}
            />
            <UnlinkPopupForm
                isUnlinkAccountPopupOpen={isUnlinkAccountPopupOpen}
                unlinkAccountPopupRef={actionPopupRef}
                setUnlinkAccountPopupOpen={setUnlinkAccountPopupOpen}
                master_account={unlinkAccount?.master_account}
                account_id={unlinkAccount?.account_id}
                onFormClose={onUnlinkFormClose}
                onFormSubmit={onUnlinkFormSubmit}
                refresh={() => {
                    readAccounts();
                    onUnlinkFormClose();
                }}
            />
            <Modal open={isLinkMasterAccountModalOpen} onClose={closeLinkMasterAccountModal}>
                <LinkToMasterAccountForm
                    account_id={linkAccount?.account_id}
                    refreshFromLinking={() => {
                        readAccounts();
                        onLinkFormClose();
                    }}
                    close={onLinkFormClose}
                    current_master_account={linkAccount?.master_account}
                />
            </Modal>
        </>
    );
}

export default Forms;
