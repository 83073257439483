import React, { useContext } from 'react'; 
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import { Button } from 'semantic-ui-react';
import { SearchBar } from 'dyl-components';
import '../index.scss';

const Toolbar = ({ search, onSearchSubmit, cancelFunction, onChangeSearch }) => {
    const [selectedNumbers, , areNumbersInAllPagesSelected] = useContext(BulkActionsContext);
    const hasSelectedNumbers = selectedNumbers.length || areNumbersInAllPagesSelected;

    return (
        <div style={{display: 'flex'}}>
            <div style={{flex: 1}}>
                <SearchBar
                    isSearching={false}
                    search={search}
                    searchFcn={onSearchSubmit}
                    cancelFcn={cancelFunction}
                    placeholder='Search by number'
                    addClassNames='DylNumbers__search'
                    onChange={onChangeSearch}
                />
            </div>
            <div>
                <Button style={{marginRight: 10}} className='DylNumbersToolbar__button' disabled={!hasSelectedNumbers} onClick={() => {}}>
                    Bulk Actions
                </Button>
                <Button className='DylNumbersToolbar__button'>
                    Port Numbers
                </Button>
            </div>
        </div>

    )
}

export default Toolbar;
