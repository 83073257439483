import React from 'react';
import { Grid, Button } from 'semantic-ui-react';

const RingGroupMembersHeader = ({ onOpenAddMemberSection }) => (
    <Grid verticalAlign='middle'>
        <Grid.Column width={6}>
            Members
        </Grid.Column>
        <Grid.Column width={4} textAlign='right' floated='right'>
        <Button onClick={onOpenAddMemberSection} size='tiny' basic inverted color='white'>Add Member</Button>
        </Grid.Column>
    </Grid>
)

export default RingGroupMembersHeader;
