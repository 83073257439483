import React from 'react';
import { STATUS_TYPES, TimelineTabForm, Notification } from 'dyl-components';
import { Input, Menu, Header, Label, Icon } from 'semantic-ui-react';
import { DateTimeUtils } from 'dyl-components';

import TextTabActivityForm from './subcomponents/TextTabActivityForm';
import TextTimeline from './subcomponents/TextTimeline';

import './index.scss';
import selfReportActions from 'actions/self_report';
import { connect } from 'react-redux';

class SelfReportedTextsTab extends React.Component {
    state = {
        isFormTabOpen: true,
        activeItem: 'all'
    }

    componentDidMount() {
        this.props.onReadSelfReportedItems({
            contact_id: this.props.contact_id,
            user_id: this.props.user_id
        })
    }

    handleItemClick = (_, { name }) => {
        this.setState({ activeItem: name })
    }

    onChange = (_, { name, value }) => {
        this.setState({
            [name]: value
        })
    }

    onToggleFormTab = () => {
        this.setState(prevState => ({
            isFormTabOpen: !prevState.isFormTabOpen
        }))
    }

    onToggleText = async (_, { checked, id }) => {
        try {
            await this.props.onToggleTextCompletion(id, checked);
            Notification.alert('Successfully updated completion!', STATUS_TYPES.SUCCESS, true);
            this.props.onReadSelfReportedItems({
                contact_id: this.props.contact_id,
                user_id: this.props.user_id
            });
            this.props.readActivityLog(this.props.contact_id);
        } catch {
            Notification.alert('Failed to update completion', STATUS_TYPES.ERROR, true);
        }
    }

    render() {
        return (
            <TimelineTabForm
                timelineHeader={
                    <Header className='TextsTab__Header' as='h2'>
                        <Icon className='TimelineTab__add' name='plus' color='blue' link circular inverted size='tiny' onClick={this.onToggleFormTab} />
                        Text
                    </Header>
                }
                timelineSubHeader={
                    <React.Fragment>
                        <div className='TextsTab__Menu'>
                            <Menu pointing secondary >
                                <Menu.Item
                                    name='all'
                                    active={this.state.activeItem === 'all'}
                                    onClick={this.handleItemClick}
                                >
                                    All
                        <Label color='teal'>{this.props.items.length}</Label>
                                </Menu.Item>
                                <Menu.Item
                                    name='Sent'
                                    active={this.state.activeItem === 'Sent'}
                                    onClick={this.handleItemClick}
                                >
                                    Sent
                        <Label color='teal'>{this.props.items.filter(({ status }) => status === 'Sent').length}</Label>
                                </Menu.Item>
                                <Menu.Item
                                    name='Received'
                                    active={this.state.activeItem === 'Received'}
                                    onClick={this.handleItemClick}
                                >
                                    Received
                        <Label color='teal'>{this.props.items.filter(({ status }) => status === 'Sent').length}</Label>
                                </Menu.Item>
                            </Menu>
                        </div>
                        <div className='TextsTab__NewText'>
                            <Input fluid placeholder='Filter by text name/date' />
                        </div>
                    </React.Fragment>
                }
                timelineBody={
                    <TextTimeline 
                        expanded={this.state.isFormTabOpen}
                        items={this.props.items}
                        onToggleText={this.onToggleText}
                    />
                }
                formHeader={
                    <Header className='TextsForm__Header'>
                        New Text
                    </Header>
                }
                formBody={
                    <TextTabActivityForm 
                        logText={this.props.logText}
                        onClose={this.onToggleFormTab}
                        refresh={() => {
                            this.props.onReadSelfReportedItems({
                                contact_id: this.props.contact_id,
                                user_id: this.props.user_id
                            });
                            this.props.readActivityLog(this.props.contact_id);
                        }}
                    />
                }
                color='#a368de'
                expanded={this.state.isFormTabOpen}
                loading={this.props.isReadingLogs || this.props.textLogBeingUpdated}
            />
        )
    }
}

const mapStateToProps = state => ({
    contact_id: Number(state.contact.contact_id),
    user_id: state.auth.user_id,
    isReadingLogs: state.self_report.isReadingCustomerTextLogs,
    items: state.self_report.customerTextLogs.sort((a, b) => b.occurred - a.occurred),
    textLogBeingUpdated: state.self_report.textLogBeingUpdated
})

const mapDispatchToProps = dispatch => ({
    onReadSelfReportedItems: (queryParameters) => {
        dispatch(selfReportActions.readCustomerTextActivity(queryParameters));
    },
    onToggleTextCompletion: (text_id, completed) => {
        return dispatch(selfReportActions.toggleTextCompletion(text_id, { completed }));
    },
    readActivityLog: (contact_id) => {
        dispatch(selfReportActions.readActivity({ contact_id, occurred: DateTimeUtils.getCurrentDate(null, true, false) }))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(SelfReportedTextsTab);
