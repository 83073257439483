import React from 'react';
import { MessagingIcon, ControlledPopup } from 'dyl-components';

const MessagingIconButtons = ({ isTeamEmpty, isEditMode, onDisableEditMode, onUpdate, onEnableEditMode, onDelete, id, disabled }) => (
    <React.Fragment>
        {isEditMode && (
            <MessagingIcon disabled={disabled} icon='times' onClick={onDisableEditMode} />
        )}
        <MessagingIcon
            icon={isEditMode ? 'check' : 'edit'}
            onClick={isEditMode ? onUpdate : onEnableEditMode}
            disabled={disabled}
        />
        {isTeamEmpty && !isEditMode && <ControlledPopup
            trigger={<MessagingIcon icon='trash' disabled={disabled} />}
            onConfirm={() => { onDelete(id) }}
            content={'Delete'}
            inverted
        />}
    </React.Fragment>
);

export default MessagingIconButtons;
