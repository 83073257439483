import React, { useEffect, useState } from 'react';
import { Segment, Form, Checkbox, Input } from 'semantic-ui-react';
import { Button } from 'dyl-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import authActions from 'actions/auth/auth';

import './index.scss';
import usersActions from 'actions/users';

const Login = ({ onLogin, onChange, isAuthenticatingUser }) => {
    return (
        <div className="LoginWrapper">
            <Segment className='Login' loading={ isAuthenticatingUser }>
                <h2 className="Login__header">Sign In to DYL</h2>
                <div className="Login__content">
                    <Form onSubmit={ onLogin }>
                        <div className="Login__fields">
                            <Form.Field>
                                <Input icon='user' iconPosition='left' placeholder='Email' onChange={ onChange } name='username' fluid />
                            </Form.Field>
                            <Form.Field>
                                <Input icon='lock' iconPosition='left' placeholder='Password' onChange={ onChange } name='password' type="password" fluid />
                            </Form.Field>
                            <Form.Field>
                                <Checkbox label='Remember me' onChange={ onChange } name='remember_me' />
                            </Form.Field>
                        </div>
                        <Button type="submit" color='blue' fluid>LOG IN</Button>
                    </Form>
                    <div className="Login__footer">
                        <a href='/forgotten_password'>Forgot your password?</a>
                    </div>
                </div>
            </Segment>
        </div>
    );
}


const LoginContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [form, setForm] = useState({ form: { username: '', password: '', remember_me: false } });
    const [firstLoad, setFirstLoad] = useState(true);
    const [isCheckingLogin, setIsCheckingLogin] = useState(false);
    const {isAuthenticatingUser,  authenticateUserMessage, temporary_password } = useSelector(state => state.auth);


    const runLogin = async (form) => {
        const { username, password } = form;
        setFirstLoad(false);
        const ip_addr = await authActions.getIP();
        dispatch(authActions.login({ username, password, ip_addr }));
       
    };

    const onChange = (e) => {
        const inputValues = form;
        inputValues[e.target.name] = e.target.value;
        setForm(inputValues);
    };

    const onLogin = (e) => {
        e.preventDefault();
        runLogin(form);
    };

    if (authenticateUserMessage === 'success') {
        dispatch(usersActions.readUserProfile());
        let link = (location?.state?.from) ? location?.state?.from : '/';
        if(temporary_password){
            link = '/password_reset';
        }
        
        navigate(link);
    }

    useEffect(() => {
        if(!firstLoad){
            setIsCheckingLogin(isAuthenticatingUser)
        }
        return () => {
            setIsCheckingLogin(false)
        }
    }, [firstLoad, isAuthenticatingUser])
    
    return (
        <Login isAuthenticatingUser={ isCheckingLogin } onChange={ onChange } onLogin={ onLogin } />
    );


}

export default LoginContainer
