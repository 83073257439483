import { DateTimeUtils, VALIDATORS, yup } from "dyl-components";
import fixedRecurringProductSchema from "./fixedRecurringProductSchema";
import usageBasedProductSchema from "./usageBasedProductSchema";
import { applyVolumeBasedBulkPricingTierSchema } from "./volumeBasedSchema";
import volumeBasedRecurringProductSchema from "./volumeBasedRecurringProductSchema";
import applyCurrencySchema from "./applyCurrencySchema";
import { variantsSchema } from "./variantsSchema";

const supplyChainNetworkSchema = [
    "manufacturer",
    "vendor",
    "provider",
    "supplier",
    "carrier",
].reduce((schema, type) => {
    return {
        ...schema,
        [type]: yup.lazy((value) => {
            if (typeof value === "string") {
                return VALIDATORS.SIMPLE_INPUT()
                    .noemoji()
                    .minlength(2)
                    .maxlength(200)
                    .no_whitespace_only();
            }
            if (typeof value === "number") {
                return yup.number();
            }
            return yup.mixed();
        }),
    };
}, {});

const productSchema = {
    name: VALIDATORS.SIMPLE_INPUT()
        .noemoji()
        .minlength(2)
        .maxlength(200)
        .no_whitespace_only()
        .required("This field is required"),
    product_type: yup.string().required("This field is required"),
    description: VALIDATORS.DESCRIPTION(),
    status: yup.string().required("This field is required"),

    active_date: yup
        .string()
        .nullable(true)
        .test("valid_date", "Invalid date", (value) => {
            return value
                ? DateTimeUtils.isValid(value, DateTimeUtils.DATE_FORMAT)
                : true;
        }),

    price_data: yup.object().shape({
        model: yup.string().required("This field is required"),
        schedule: yup.string().when("model", {
            is: (value) => value !== "usage",
            then: (schema) => schema.required("This field is required"),
            otherwise: (schema) => schema.nullable(true),
        }),
        price: yup.number().when(["model", "schedule"], {
            is: (model, schedule) =>
                (model === "fixed" && schedule === "one-time") ||
                model === "usage",
            then: (schema) =>
                applyCurrencySchema(schema).required("This field is required"),
            otherwise: (schema) => schema.nullable(true),
        }),

        ...fixedRecurringProductSchema,
        volume_prices: yup
            .array()
            .optional()
            .when(["model", "schedule"], {
                is: (model, schedule) =>
                    model === "volume" && schedule === "one-time",
                then: (schema) => applyVolumeBasedBulkPricingTierSchema(schema),
                otherwise: (schema) => schema.nullable(true),
            }),
        ...volumeBasedRecurringProductSchema,
        ...usageBasedProductSchema,
    }),

    product_category: yup.object().shape({
        name: yup.lazy((value) => {
            if (typeof value === "string") {
                return VALIDATORS.SIMPLE_INPUT()
                    .noemoji()
                    .minlength(2)
                    .maxlength(100)
                    .no_whitespace_only();
            }
            if (typeof value === "number") {
                return yup.number();
            }
            return yup.mixed();
        }),
        description: VALIDATORS.DESCRIPTION(),
    }),

    ...supplyChainNetworkSchema,

    variants: yup.array().optional().of(yup.object(variantsSchema)),
    max_discount: yup
        .number()
        .typeError("Please enter a valid rate")
        .min(0, "Please enter a valid rate")
        .max(100, "Please enter a valid rate")
        .test("maxDigitsAfterDecimal", "Please enter a valid rate", (number) =>
            /^\d+(\.\d{1,2})?$/.test(number)
        ),
};

export default productSchema;
