import React from 'react';
import { Modal, STATUS_TYPES, Notification } from 'dyl-components';
import { Button } from 'semantic-ui-react';
import { connect } from 'react-redux';

import EditUserForm from './EditUserForm';
import ChangePasswordModal from '../../../../shared/modals/ChangePasswordModal';
import usersActions from 'actions/users';
import teamActions from 'actions/team';
import assignActions from 'actions/assign';

const EditUserModal = ({ open, isValid, onUpdateDetails, userAccount, onClose, onChangePassword, onInputChange, isUpdatingUser }) => (
    <Modal open={open} onClose={onClose} size='small'>
        <Modal.Header>
            Edit User Settings
        </Modal.Header>
        <Modal.Content>
            <EditUserForm
                userAccount={userAccount}
                onInputChange={onInputChange}
            />
        </Modal.Content>
        <Modal.Actions>
            {!isUpdatingUser && (
                <React.Fragment>
                    <Button onClick={onChangePassword} primary floated='left' >Change Password</Button>
                    <Button basic onClick={onClose}>Cancel</Button>
                </React.Fragment>
            )}
            <Button disabled={!isValid} loading={isUpdatingUser} primary onClick={onUpdateDetails}>Update</Button>
        </Modal.Actions>
    </Modal>
)

class EditUserModalContainer extends React.Component {
    state = {
        isChangePasswordModalOpen: false,
        userAccount: {
            first_name: '',
            last_name: '',
            email: '',
            teams: [],
            access_role: '',
            areUserNotificationsEnabled: false
        },
    }

    componentDidMount() {
        const userAccount = { ...this.props.userAccount };
        this.setState({
            userAccount: {
                ...userAccount,
                teams: (userAccount.teams || []).map(({ id }) => id),
                access_role: userAccount.access_role.id
            }
        })
    }

    onChangePassword = () => {
        this.setState({
            isChangePasswordModalOpen: true
        })
    }

    hideChangePassword = () => {
        this.setState({
            isChangePasswordModalOpen: false
        })
    }

    onInputChange = (_, { name, value }) => {
        this.setState(prevState => ({
            userAccount: {
                ...prevState.userAccount,
                [name]: value
            }
        }))
    }

    onUpdateDetails = () => {
        const { teams, access_role, user_id, ...userValues } = this.state.userAccount;
        const currentUserAccountTeams = this.props.userAccount.teams || [];
        const teamsWhereToAddUser = teams.filter(teamWhereToAddUser => currentUserAccountTeams.findIndex(({ id }) => id === teamWhereToAddUser) === -1);
        const teamsWhereToRemoveUser = currentUserAccountTeams.filter(({ id }) => teams.findIndex(selectedTeam => selectedTeam === id) === -1).map(({ id }) => id);
        Promise.all([
            this.props.onUpdateUser(user_id, userValues),
            this.props.onUpdateAssignment(user_id, access_role),
            ...teamsWhereToAddUser.map(id => this.props.onAddUsersToTeam(id, [{ user_id }])),
            ...teamsWhereToRemoveUser.map(id => this.props.onRemoveUserFromTeam(id, user_id))
        ]).then(() => {
            this.props.onClose();
            this.props.onReadUsers(this.props.queryParameters);
            this.props.onReadAccessRoleAssignments();
            Notification.alert('Successfully updated user', STATUS_TYPES.SUCCESS, true);
        }).catch(error => {
            console.log(error);
            Notification.alert('Failed to update user', STATUS_TYPES.ERROR, true);
        });
    }

    isValid = () => {
        const { first_name, last_name } = this.state.userAccount;
        return first_name && last_name && first_name.trim() && last_name.trim();
    }

    render() {
        return (
            <React.Fragment>
                <EditUserModal
                    {...this.props}
                    onUpdateDetails={this.onUpdateDetails}
                    userAccount={this.state.userAccount}
                    isChangePasswordModalOpen={this.state.isChangePasswordModalOpen}
                    onChangePassword={this.onChangePassword}
                    onInputChange={this.onInputChange}
                    isValid={this.isValid()}
                />

                <ChangePasswordModal
                    open={this.state.isChangePasswordModalOpen}
                    onClose={this.hideChangePassword}
                    user_id={this.props.userAccount.user_id}
                />
            </React.Fragment>

        )
    }
}

const mapStateToProps = state => ({
    isUpdatingUser: state.users.isUpdatingUser || state.team.teamBeingUpdated ||
        state.team.teamBeingDeleted || state.assign.isUpdatingAccessRoleAssignments,
    queryParameters: state.assign.queryParameters
})

const mapDispatchToProps = dispatch => ({
    onUpdateUser: (user_id, updatedUser) => {
        return dispatch(usersActions.updateUser(user_id, { ...updatedUser }))
    },
    onReadUsers: (queryParameters) => {
        dispatch(assignActions.readUserAccounts(queryParameters));
    },
    onRemoveUserFromTeam: (team_id, user_id) => {
        return dispatch(teamActions.deleteUser(team_id, { user_id }));
    },
    onAddUsersToTeam: (team_id, users) => {
        return dispatch(teamActions.addUsers(users, null, team_id));
    },
    onUpdateAssignment: (user_id, new_access_role) => {
        return dispatch(assignActions.updateAssignments(null, {
            assign: [
                {
                    user: user_id,
                    access_role: new_access_role
                }
            ]
        }))
    },
    onReadAccessRoleAssignments: () => {
        dispatch(assignActions.readAssignments());
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(EditUserModalContainer);
