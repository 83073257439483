import React from 'react';
import { ContactsHeader } from './Header';
import { ContactsRow } from './Row';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RecordsTable } from 'dyl-components';

const ContactsTable = ({ onFilter, isColumnHidden, onPageChange }) => {
    const { count, contacts = [], isReadingContacts } = useSelector(state => state.contacts);
    
    const contactsData = contacts.map(contact => {
        const mainLocation = contact.location?.data.filter(location => location.main).length > 0 ? contact.location.data.filter(location => location.main)[0] : {}
        const { city = "", street = "", additional_street = "", state = "", zip = "", label = "" } = mainLocation;
        const first_name = contact.first_name || '';
        const last_name = contact.last_name || '';
        const suffix = contact.suffix || '';

        return {
            ...contact,
            account_id: contact.account ? contact.account.id : 0,
            first_name,
            last_name,
            suffix,
            phone: [contact.phone],
            location: Object.keys(mainLocation).length === 0 ? [] : mainLocation,
            address: `${label ? `${label}:` : ''} ${street ? `${street},` : ''} ${additional_street ? `${additional_street},` : ''} ${city ? `${city},` : ''} ${state || ''} ${zip || ''} `,
        }
    });

    const [params] = useSearchParams();

    return (
        <RecordsTable
            tableBodyClassName='ContactsTable'
            isTableLoading={isReadingContacts}
            isSearchResultEmpty={count === 0 && (params.get('search') || params.get('start') || params.get('end') || params.get('account_type') || params.get('owned_by'))}
            isTableEmpty={count === 0 && !(params.get('search') || params.get('start') || params.get('end') || params.get('account_type') || params.get('owned_by'))}
            TableHeader={<ContactsHeader isColumnHidden={isColumnHidden} onFilter={onFilter} />}
            TableRows={contactsData.map(contact => (
                <ContactsRow key={contact.id} contact={contact} isColumnHidden={isColumnHidden} />
            ))}
            recordsCount={count}
            activePage={params.get('page')}
            onPageChange={onPageChange}
            recordsName='Contact'
        />
    )
}

export default ContactsTable;
