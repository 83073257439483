import React from 'react';
import { Grid, Transition, Segment, Icon, Image, Popup } from 'semantic-ui-react';
import MessageFeed from './MessageFeed';
import MessageSearchBar from './MessageSearchBar';
import * as _ from 'underscore';

import { DateTimeUtils } from 'dyl-components';
import { connect } from 'react-redux';
import MessageEditor from 'shared/MessageEditor';
import ChatThread from 'shared/ChatThread';
import textActions from 'actions/text';
import MessageRecipient from './MessageRecipient';

import './index.scss';
import textUiActions from 'actions/text_ui';

import SelfReportedTextsTab from 'shared/SelfReportedTextsTab';

const Text = ({
    isSidePanelOpen,
    isChatBoxOpen,
    chatMessages,
    messages,
    activeMessage,
    onChangeActiveMessage,
    onCloseSidePanel,
    onCloseChatBox,
    onOpenSidePanel,
    isReadingMessages,
    isSearchingMessages
}) => (
    <Grid columns='equal'>
        <Transition visible={isSidePanelOpen} animation='slide right' duration='150' unmountOnHide>
            <Grid.Column width={4} className='Messages'>
                <Segment.Group>
                    <Segment>
                        <div>
                            <Icon size='large' style={{ float: 'right' }} name='times' link onClick={onCloseSidePanel} />
                        </div>
                        <MessageSearchBar />
                    </Segment>
                    <Segment style={{ padding: 0 }} loading={isReadingMessages || isSearchingMessages}>
                        <MessageFeed
                            activeMessage={activeMessage}
                            messages={messages}
                            onChangeActiveMessage={onChangeActiveMessage}
                        />
                    </Segment>
                </Segment.Group>
            </Grid.Column>
        </Transition>
        <Transition visible={isChatBoxOpen} animation='slide down' duration='150' unmountOnHide>
            <Grid.Column className='ChatBox'>
                <Segment.Group className='ChatBox__content'>
                    <MessageRecipient
                        closeButton={<Icon size='large' name='times' link onClick={onCloseChatBox} />}
                        additionalControls={!isSidePanelOpen && (
                            <Popup trigger={
                                <Icon
                                    link
                                    circular
                                    inverted
                                    name={`chevron ${isSidePanelOpen ? 'left' : 'right'}`}
                                    color='blue'
                                    size='tiny'
                                    onClick={isSidePanelOpen ? onCloseSidePanel : onOpenSidePanel}
                                />
                            }
                                content='Open your text history'
                                position='right center'
                                inverted />


                        )}
                    />
                    <ChatThread messages={chatMessages} />
                </Segment.Group>
                <MessageEditor />
            </Grid.Column>
        </Transition>
    </Grid>
);

class TextContainer extends React.Component {
    state = {
        activeMessage: null,
        isSidePanelOpen: true,
        isChatBoxOpen: true,
        chatMessage: ''
    }

    componentDidMount() {
        this.props.onReadTextPhones();
        this.props.onSelectContactPhone(this.props.contact_phone);
        this.readMessages();
        const intervalId = setInterval(this.readMessages, 30000);
        this.setState({ intervalId });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    readMessages = () => {
        this.props.onReadTextMessages(this.props.contact_id, {
            contact_phone: this.props.contact_phone,
            start: DateTimeUtils.getLast(3, 'years'),
            end: DateTimeUtils.getCurrentDate(DateTimeUtils.DATE_FORMAT, true, false)
        });
        this.props.onReadFilteredMessages(this.props.contact_id, {
            start: DateTimeUtils.getLast(3, 'years'),
            end: DateTimeUtils.getCurrentDate(DateTimeUtils.DATE_FORMAT, true, false),
            contact_phone: this.props.contact_phone,
            ...this.props.queryParameters,
        })
    }

    onChangeActiveMessage = (ts) => {
        const activeMessage = this.state.activeMessage;
        if (activeMessage === ts) {
            this.setState({ activeMessage: null }, () => {
                this.props.onReadFilteredMessages(this.props.contact_id, {
                    start: DateTimeUtils.getLast(3, 'years'),
                    end: DateTimeUtils.getCurrentDate(DateTimeUtils.DATE_FORMAT, true),
                    contact_phone: this.props.contact_phone
                })
            })
        } else {
            this.setState({ activeMessage: ts }, () => {
                ts = Number(ts);
                this.props.onReadFilteredMessages(this.props.contact_id, { contact_phone: this.props.contact_phone, start: ts, end: DateTimeUtils.getEndOfDay(ts) });
            });
        }

    }

    onCloseSidePanel = () => {
        this.setState({ isSidePanelOpen: false });
    }

    onOpenSidePanel = () => {
        this.setState({ isSidePanelOpen: true });
    }

    onCloseChatBox = () => {
        this.setState({ isChatBoxOpen: false });
    }

    onChange = (_, { name, value }) => {
        this.setState({
            [name]: value
        })
    }

    render() {
        return (
            this.props.canText ? (
                <Text
                    chatMessage={this.state.chatMessage}
                    chatMessages={this.props.messages}
                    messages={this.props.groups.map(({ day, latestMessage }) => ({
                        id: day,
                        content: latestMessage.message,
                        date: DateTimeUtils.formatEpoch(day, 'ddd MM/DD'),
                        time: DateTimeUtils.formatEpoch(latestMessage.ts),
                        hasAttachment: latestMessage.attachment !== null,
                        status: latestMessage.status
                    }))}
                    isChatBoxOpen={this.state.isChatBoxOpen}
                    activeMessage={this.state.activeMessage}
                    onChangeActiveMessage={this.onChangeActiveMessage}
                    onCloseSidePanel={this.onCloseSidePanel}
                    isSidePanelOpen={this.state.isSidePanelOpen}
                    onChange={this.onChange}
                    onCloseChatBox={this.onCloseChatBox}
                    onOpenSidePanel={this.onOpenSidePanel}
                    isReadingMessages={this.props.isReadingMessages}
                    isSearchingMessages={this.props.isSearchingMessages}
                />
            ) : (
                <SelfReportedTextsTab />
            )
        )
    }
}

const mapStateToProps = state => {
    const messages = state.text.messages.slice(0)
    messages.sort((a, b) => a.ts - b.ts);

    const filteredMessages = state.text.filteredMessages.slice(0);
    filteredMessages.sort((a, b) => a.ts - b.ts);
    const phones = state.contact_phone.phone.slice(0);
    let contact_phone = phones.find(phone => phone.main) || phones[0];
    if (contact_phone) {
        contact_phone = contact_phone.phone;
    }
    const groups = _.map(_.groupBy(messages, DateTimeUtils.groupItemsByDate), function (group, day) {
        return {
            day,
            latestMessage: group[group.length - 1]
        }
    });
    groups.sort((a, b) => Number(b.day) - Number(a.day));

    return {
        messages: filteredMessages.map(message => ({
            position: message.direction === 'outbound' ? 'right' : 'left',
            type: 'text',
            text: (
                <div>
                    {message.message}
                    {message.attachment && message.attachment.map(attachment => (
                        <Image
                            src={attachment}
                        />
                    ))}
                </div>
            ),
            dateString: DateTimeUtils.changeFormat(DateTimeUtils.convertUnixTimeToDate(message.ts), "", "MM/DD/YYYY, hh:mm a")
        })),
        groups,
        phones,
        contact_id: Number(state.contact.contact_id),
        contact_phone,
        queryParameters: state.text.queryParameters,
        isReadingMessages: state.text.isReadingMessages,
        isSearchingMessages: state.text.isSearchingMessages,
        canText: state.users.userProfile.cell_phone
    }
}

const mapDispatchToProps = (dispatch) => ({
    onReadTextMessages: (contact_id, { contact_phone, start, end }) => {
        dispatch(textActions.getMessages(contact_id, { contact_phone, start, end }));
    },
    onReadFilteredMessages: (contact_id, { contact_phone, start, end }) => {
        dispatch(textActions.getFilteredMessages(contact_id, { contact_phone, start, end }));
    },
    onReadTextPhones: () => {
        dispatch(textActions.getPhones());
    },
    onSelectContactPhone: (contact_phone) => {
        dispatch(textUiActions.selectContactPhone(contact_phone));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(TextContainer);
