import { PriorityIcon, TemperatureIcon } from 'dyl-components';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import MasterSecondarySourceOptions from 'shared/forms/MasterSecondarySourceOptions';
import { CustomGroupUtils } from 'utils';

const LeadFields = ({
    control,
    disabled,
    ownerField,
    lost_reason_fields
}) => {
    const { isReadingCustomFields, group, campaignConvertedOptions, isReadingCampaignConverted, productInterestOptions, isReadingProductInterestOptions } = useSelector(state => ({
        isReadingCustomFields: state.custom_fields_groups.isReadingParentGroups || state.custom_fields_group.isReadingModalStandardFieldGroup,
        group: state.custom_fields_group.modal_standard_group,

        campaignConvertedOptions: state.campaigns.campaignConvertedOptions.map((campaign) => ({ key: campaign.id, value: campaign.id, text: campaign.name })),
        isReadingCampaignConverted: state.campaigns.isReadingCampaignConverted,

        productInterestOptions: state.product_interests.productInterestOptions,
        isReadingProductInterestOptions: state.product_interests.isReadingProductInterestOptions
    }));

    const fields = group?.fields?.data || [];
    const priority_options = CustomGroupUtils.getFieldOptions(fields, 'priority');
    const rating_options = CustomGroupUtils.getFieldOptions(fields, 'rating');

    return [
        <Form.Group widths='equal'>
            <Controller
                name='type'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Lead Type</label>
                        <Form.Select
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            options={CustomGroupUtils.getFieldOptions(fields, name)}
                            search
                            selectOnBlur={false}
                            placeholder='Select Type'
                            clearable
                            loading={isReadingCustomFields}
                            disabled={disabled}
                        />
                    </Form.Field>
                )}
            />
            <Controller
                name='expected_revenue'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Expected Revenue</label>
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Expected Revenue'
                            disabled={disabled}
                        />
                    </Form.Field>
                )}
            />
            <Controller
                name='close_probability'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Close Probability</label>
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Close Probability'
                            disabled={disabled}
                        />
                    </Form.Field>
                )}
            />
        </Form.Group>,
        <Form.Group widths={'equal'}>
            <Controller
                name='total_cost'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Total Cost</label>
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder=''
                            disabled
                        />
                    </Form.Field>
                )}
            />
            <Controller
                name='acquisition_cost'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Acquisition Cost</label>
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Acquisition Cost'
                            disabled={disabled}
                        />
                    </Form.Field>
                )}
            />
            <Controller
                name='additional_cost'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Additional Costs</label>
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Additional Costs'
                            disabled={disabled}
                        />
                    </Form.Field>
                )}
            />
        </Form.Group>,
        <Form.Group widths={'equal'}>
            <Controller
                name='priority'
                control={control}
                render={({ field: { name, value, onChange } }) => (

                    <Form.Field>
                        <label>Priority</label>
                        <Form.Select
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            options={priority_options.map(option => ({
                                ...option,
                                content: (
                                    <span><PriorityIcon type={option.text?.toLowerCase()} /> {option.text}</span>
                                )
                            }))}
                            search
                            selectOnBlur={false}
                            placeholder='Select Lead Priority'
                            clearable
                            loading={isReadingCustomFields}
                            disabled={disabled}
                            {...value ? { text: <span><PriorityIcon type={priority_options.find(option => option.value === value)?.text?.toLowerCase()} /> {priority_options.find(option => option.value === value)?.text}</span> } : {}}
                        />
                    </Form.Field>
                )}
            />
            <Controller
                name='rating'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Rating</label>
                        <Form.Select
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            options={rating_options.map(option => ({
                                ...option,
                                content: (
                                    <span><TemperatureIcon type={option.text?.toLowerCase()} /> {option.text}</span>
                                )
                            }))}
                            search
                            selectOnBlur={false}
                            placeholder='Select Lead Rating'
                            clearable
                            loading={isReadingCustomFields}
                            disabled={disabled}
                            {...value ? { text: <span><TemperatureIcon type={rating_options.find(option => option.value === value)?.text?.toLowerCase()} /> {rating_options.find(option => option.value === value)?.text}</span> } : {}}
                        />
                    </Form.Field>
                )}
            />
            <Controller
                name='score'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Score</label>
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Lead Score'
                            disabled={disabled}
                        />
                    </Form.Field>

                )}
            />
        </Form.Group>,
        <Form.Group widths={'equal'}>
            <Controller
                name='product_interests'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Product Interests</label>
                        <Form.Select
                            multiple
                            search
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            options={productInterestOptions.map(productInterestOption => (
                                { key: productInterestOption.id, value: productInterestOption.id, text: productInterestOption.value }
                            ))}
                            selectOnBlur={false}
                            placeholder='Select Product Interest(s)'
                            disabled={disabled || isReadingProductInterestOptions}
                            loading={isReadingProductInterestOptions}
                        />
                    </Form.Field>
                )}
            />
            <Controller
                name='master_source'
                control={control}
                render={({ field: { name: masterSourceFieldName, value: masterSourceFieldValue, onChange: masterSourceOnChange } }) => (
                    <Controller
                        name='secondary_source'
                        control={control}
                        render={({ field: { name: secondarySourceFieldName, value: secondarySourceValue, onChange: secondarySourceOnChange } }) => (
                            <Form.Field
                                control={MasterSecondarySourceOptions}
                                onChange={(_, { parent_value: master_source, child_value: secondary_source }) => {
                                    masterSourceOnChange({ target: { name: masterSourceFieldName, value: master_source } });
                                    secondarySourceOnChange({ target: { name: secondarySourceFieldName, value: secondary_source } });
                                }}
                                value={secondarySourceValue}
                                parentValue={masterSourceFieldValue}
                                disabled={disabled}
                                label="Master/Secondary Source"
                            />
                        )}
                    />
                )}
            />
            <Controller
                name='campaign_converted'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Field>
                        <label>Campaign Converted</label>
                        <Form.Select
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            options={campaignConvertedOptions}
                            search
                            selectOnBlur={false}
                            placeholder='Select Campaign Converted'
                            clearable
                            disabled={disabled}
                            loading={isReadingCampaignConverted}
                        />
                    </Form.Field>
                )}
            />
        </Form.Group>,
        <Form.Group widths={3}>
            {lost_reason_fields}
            {ownerField}
        </Form.Group>
    ]
}

export default LeadFields;
