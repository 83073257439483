import React from 'react';

import { ItemCard, Timeline, CollapsibleContent, DateTimeUtils } from 'dyl-components';
import { Icon } from 'semantic-ui-react';

import { connect } from 'react-redux';
import {  StringUtils } from 'utils';

import './index.scss';

const EventsTimeline = ({ events, isReadingEvents, onOpenEditModal, editable = true, groupedEvents }) => (
    events.length <= 0 && !isReadingEvents ? (
        <i>No events to display</i>
    ) : groupedEvents.map(({ name, dateTimes }) => (
        dateTimes.length > 0 && (
            <React.Fragment>
                <div className='Timeline__group'>
                    <strong>{name}</strong>
                </div>
                <div className='Timeline__items'>
                    <Timeline
                        items={dateTimes.map((event) => ({
                            date: <div className='TimelineItem__date'>
                                <span className='TimelineItem__day'>
                                    {DateTimeUtils.formatEpoch(event.ts, "ddd").toUpperCase()}
                                </span> <span className='TimelineItem__day-of-month'>
                                    {DateTimeUtils.formatEpoch(event.ts, "M[/]D")}
                                </span>
                            </div>,
                            time: <span className='TimelineItem__time'>
                                {DateTimeUtils.formatEpoch(event.ts)}
                            </span>,
                            itemComponent: <ItemCard
                                title={<div><span className='EventItem__name'>{StringUtils.capitalize(event.name)}</span>{editable && <Icon className='EventItem__edit' name='pencil' link onClick={() => onOpenEditModal(event)} />}</div>}
                                description={<div className='EventItem__note'>
                                    <CollapsibleContent
                                        content={event.note || ''}
                                    />
                                </div>}
                                size='mini'
                            />,
                            markerColor: '#e5e5e5'
                        }))}
                    />
                </div>
            </React.Fragment>
        )
    ))
)

const mapStateToProps = state => {
    const currenTimeStamp = DateTimeUtils.getCurrentDate(DateTimeUtils.DATE_FORMAT, true, false);
    const rawGroupedEvents = state.events.events
                                .filter(event => event?.start?.date_time > currenTimeStamp)
                                .sort((a,b) => a.start?.date_time  - b.start?.date_time)
                                .slice(0,5);

    return ({
        events: state.events.events.map(event => ({
            ...event,
            ts: event.start?.date_time ,
            type: event.event_type?.name || ''
        })),
        groupedEvents: DateTimeUtils.groupDates(rawGroupedEvents.map(event => ({
            ...event,
            ts: event.start?.date_time 
        })), true),
        isReadingEvents: state.events.isReadingEvents,
    })
};

export default connect(mapStateToProps)(EventsTimeline)
