import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Notification, STATUS_TYPES } from 'dyl-components';

import contactActions from 'actions/contact';
import pipelineActions from 'actions/pipeline';
import ProfileStages from 'shared/ProfileStages';
import contactSequenceActions from 'actions/contact_sequence';
import sequencesActions from 'actions/sequences';
import sequenceTasksActions from 'actions/sequence_tasks';
import ConversionControls from './ConversionControls';
import customerPipelineActions from 'actions/customer_pipeline';

const StagesSectionContainer = (({
    id
}) => {
    const { parent_account, current_stage, isTransitioningPipeline, isReadingSequenceContactTasks, contactSequenceTasks, selectedSequenceId } = useSelector(state => {
        return {
            isTransitioningPipeline: state.pipeline.isTransitioningPipeline || state.contact_sequence.isRemovingSequence || state.sequences.isAddingContactToSequence || state.customer_pipeline.accountBeingUpdated,

            parent_account: state.contact.account,
            current_stage: state.contact.current_stage,

            contactSequenceTasks: state.sequence_tasks.selectedSequenceTasks,
            isReadingSequenceContactTasks: state.sequence_tasks.isReadingSequenceContactTasks,
            selectedSequenceId: state.sequence_tasks.selectedSequenceId
        }
    });

    const { id: account_id } = parent_account || {};

    const dispatch = useDispatch();

    if (isReadingSequenceContactTasks) {
        return (
            <ProfileStages
                current_stage={current_stage}
                onTransitionPipeline={() => { }}
                isTransitioningPipeline={isTransitioningPipeline}
            />
        )
    }

    const stages = current_stage.stages?.map(({ id, name, sequence_id }) => {
        return {
            id,
            content: name,
            sequence_id
        }
    }) || [];

    const pipeline_id = current_stage?.pipeline_id;

    const onTransitionPipeline = async (stage_name) => {
        const newStage = stages.find(stage => stage.content === stage_name);
        if (Number(current_stage.pipeline_stage_id) === Number(newStage.id)) {
            return;
        }
        const areSequenceTasksCompleted = contactSequenceTasks.length === 0 || contactSequenceTasks.every(task => task.status === 'complete' || task.status === 'canceled');
        try {
            if (current_stage?.account_stage !== "customer") {
                await dispatch(pipelineActions.transition(pipeline_id, {
                    account_id: account_id,
                    pipeline_stage_id: newStage.id,
                    person_id: id
                }, { sequence_done: areSequenceTasksCompleted }));
            } else {
                await dispatch(customerPipelineActions.update(account_id, {
                    pipeline_stage_id: Number(newStage.id),
                    person_id: id,
                    pipeline_id: pipeline_id,
                    previous_sequence_complete: areSequenceTasksCompleted
                }, { action: 'transition' }));
            }
            if (selectedSequenceId) {
                await dispatch(contactSequenceActions.removeFromContact(id));
            }
            if (newStage.sequence_id) {
                await dispatch(sequencesActions.addToSequence({ contact_id: id }, null, newStage.sequence_id));
            }
            Notification.alert(`Successfully updated stage!`, STATUS_TYPES.SUCCESS);
            dispatch(contactActions.readContact(id));
            dispatch(sequenceTasksActions.readContactTasks(id));
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update stage', STATUS_TYPES.ERROR);
        }
    }

    return (
        <div>
            <ProfileStages
                current_stage={current_stage}
                onTransitionPipeline={onTransitionPipeline}
                isTransitioningPipeline={isTransitioningPipeline}
            />
        </div>
    );
});

const StagesSection = ({ id }) => {
    const contactIsAssociatedWithCustomerPipeline = useSelector(state => state.contact.account?.pipeline_person_id === Number(state.contact.contact_id),)
    return (
        <>
            {!contactIsAssociatedWithCustomerPipeline && (
                <div style={{ position: 'absolute', top: 0, right: 0 }}>
                    <ConversionControls />
                </div>
            )}
            <StagesSectionContainer id={id} />
        </>
    )
}

export default StagesSection;
