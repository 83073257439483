import React from 'react';
import { Route } from 'react-router-dom';

import { ProductCatalog, Addons, ProductFields } from "pages";
import Fees from 'shared/Fees';

export const PRODUCT_MANAGEMENT_ROUTES = [
    <Route index path='product-catalog' element={<ProductCatalog />} />,
    <Route index path='addons' element={<Addons />} />,
    <Route index path='bundle-catalog' element={<></>} />,
    <Route index path='fees' element={<Fees type="fee" />} />,
    <Route index path='taxes' element={<Fees type="tax" />} />,
    <Route index path='quote-invoice-settings' element={<></>} />,
    <Route index path='product-fields' element={<ProductFields />} />
];
