import React from "react";
import { Controller } from "react-hook-form";
import { Table } from "dyl-components";
import { Form } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";
import { PhoneUtil } from 'utils';

const DataInputRow = ({
    appendData,
    index,
    getValues,
    remove,
    type,
    control,
    placeHolderText,
    phoneNumbers,
    statesOrDigitsOptions,
}) => {
    const closeClick = () => {
        let updatedStateCid = getValues(type)[index];
        if (updatedStateCid.callerId.name || updatedStateCid.state.name) {
            updatedStateCid.callerId.value = PhoneUtil.sanitizePhoneNumber( updatedStateCid.callerId.name );
            updatedStateCid.state.value = updatedStateCid.state.name;

            appendData(updatedStateCid, index);
        } else {
            remove(index);
        }
    };
    const checkClick = () => {
        appendData(getValues(type)[index], index);
    };

    const filterOptions = (options, selectedOptions, param) => {
        const filteredOptions = options?.filter(
            (option) =>
                !selectedOptions.some(
                    (selected) => selected[param] === option.key
                ) && option.key !== ""
        );
        let formatedOptions = filteredOptions.map((state) => ({
            ...state,
            value: state.key,
        }));
        return formatedOptions;
    };

    return (
        <>
            <Table.Row>
                <Table.Cell width={4}>
                    <Controller
                        name={`${type}[${index}].state.value`}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                            <Form.Select
                                name={name}
                                value={value}
                                search
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } });
                                }}
                                placeholder={`Select ${placeHolderText}`}
                                selectOnBlur={false}
                                options={filterOptions(
                                    statesOrDigitsOptions,
                                    getValues()[type],
                                    placeHolderText
                                )}
                            />
                        )}
                    />
                </Table.Cell>

                <Table.Cell width={4}>
                    <Controller
                        name={`${type}[${index}].callerId.value`}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                            <Form.Select
                                name={name}
                                value={value}
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } });
                                }}
                                placeholder="Select Caller ID"
                                selectOnBlur={false}
                                options={
                                    type === "state_cids"
                                        ? phoneNumbers.map((state) => ({
                                              ...state,
                                              value: state.key,
                                          }))
                                        : filterOptions(
                                              phoneNumbers,
                                              getValues()[type],
                                              "caller_id_replace"
                                          )
                                }
                            />
                        )}
                    />
                </Table.Cell>

                <Table.Cell width={4}>
                    <Icon
                        name="close"
                        color="red"
                        className="callerID-action-icon"
                        size="large"
                        onClick={closeClick}
                    />
                    <Icon
                        name="check"
                        color="blue"
                        className="callerID-action-icon"
                        size="large"
                        onClick={checkClick}
                        disabled={!getValues(type)[index].state.value || !getValues(type)[index].callerId.value }
                    />
                </Table.Cell>
            </Table.Row>
        </>
    );
};

export default DataInputRow;
