import customFieldsGroupActions from 'actions/custom_fields_group';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Menu, Ref } from 'semantic-ui-react';
import { useLocation } from "react-router-dom";

import { Button } from 'dyl-components';

import './index.scss';

import StandardFields from './StandardFields';
import CustomFields from './CustomFields';

const DataCustomizationParentGroup = ({ group_name, additionalControls }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { isReadingParentGroup } = useSelector(state => state.custom_fields_group);

    const parentGroups = useSelector(state => state.custom_fields_groups.parentGroups.filter(group => group.group_name))

    const group_id = parentGroups.find(group => group.group_name.toLowerCase().includes(group_name.toLowerCase()))?.id || 0;

    const [group, setGroup] = useState(null);
    const [openSubgroups, setOpenSubgroups] = useState([]);
    const [isReadingAll, setIsReadingAll] = useState(true);

    const isOpenSubgroup = (id) => {
        return openSubgroups.includes(id);
    }

    const toggleSubgroup = (id) => {
        setOpenSubgroups(isOpenSubgroup(id) ? openSubgroups.filter(subgroup_id => subgroup_id !== id) : [...openSubgroups, id]);
    }

    const [activeGroup, setActiveGroup] = useState('Standard');

    useEffect(()=>{
        if (location.state?.activeTab === 'Custom'){
            setActiveGroup('Custom')
        }
    }, [location])

    const onReadParentGroup = (group_id) => {
        return dispatch(customFieldsGroupActions.getCustomFieldGroup(group_id, { limit: 1000, standard: activeGroup === 'Standard',  deleted_options: false}));
    };

    const refresh = () => {
        setIsReadingAll(true);
        setGroup(null);
        onReadParentGroup(group_id).then(async response => {
            const groupToDisplay = response.data[0];
            setGroup(groupToDisplay);
            if (groupToDisplay?.subgroup_count) {
                const subgroupsToDisplay = groupToDisplay.children;
                setOpenSubgroups(subgroupsToDisplay?.map(({ id }) => id) || []);
                setIsReadingAll(false);
            } else {
                setOpenSubgroups([]);
                setIsReadingAll(false);
            }
        }).catch(e => {
            console.log(e);
            setGroup(null);
            setOpenSubgroups([]);
            setIsReadingAll(false);
        });
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(refresh, [group_id, activeGroup]);

    const groupBeingDeleted = useSelector(state => (state.custom_fields_group.groupBeingDeleted));
    const groupBeingUpdated = useSelector(state => state.custom_fields_group.groupBeingUpdated);
    const fieldBeingDeleted = useSelector(state => (state.custom_field.fieldBeingDeleted));
    const fieldBeingUpdated = useSelector(state => (state.custom_field.fieldBeingUpdated));

    const isCreatingCustomGroup = useSelector(state => state.custom_fields_groups.isAddingCustomGroup);

    const addCustomGroupButtonRef = React.createRef();

    const FieldsComponent = activeGroup === 'Standard' ? StandardFields : CustomFields;

    const [isAddGroupFormOpen, setIsAddGroupFormOpen] = useState(false);

    const openAddGroupForm = () => {
        setIsAddGroupFormOpen(true);
    }

    const closeAddGroupForm = () => {
        setIsAddGroupFormOpen(false);
    }

    return (
        <Segment loading={isReadingParentGroup || isReadingAll || groupBeingDeleted || fieldBeingDeleted || fieldBeingUpdated || groupBeingUpdated} basic className='ParentGroup'>
            <Menu secondary className='DataCustomization__groupTypeOptions'>
                {additionalControls && <Menu.Item>{additionalControls}</Menu.Item>}
                <Menu.Item active={activeGroup === 'Standard'} onClick={() => { setActiveGroup('Standard') }}>Standard</Menu.Item>
                <Menu.Item active={activeGroup === 'Custom'} onClick={() => { setActiveGroup('Custom') }}>Custom</Menu.Item>
                {activeGroup === 'Custom' && (
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Ref innerRef={addCustomGroupButtonRef}>
                                <Button onClick={openAddGroupForm} disabled={isCreatingCustomGroup}>+ New Custom Group</Button>
                            </Ref>
                        </Menu.Item>
                    </Menu.Menu>
                )}
            </Menu>
            <FieldsComponent
                {...(activeGroup !== 'Standard' ? { addCustomGroupButtonRef, isAddGroupFormOpen, closeAddGroupForm, openAddGroupForm } : {})}

                group={{ ...group, fields: { data: [...(group?.fields?.data || [])] } }}
                isOpenSubgroup={isOpenSubgroup}
                isReading={isReadingParentGroup || isReadingAll}
                refresh={refresh}
                subgroups={group?.children || []}
                toggleSubgroup={toggleSubgroup}
            />
        </Segment>
    )
};

export default DataCustomizationParentGroup;
