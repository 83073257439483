import React from 'react';
import { TableWithHeader, STATUS_TYPES, Notification } from 'dyl-components';
import IVRPromptMessagesTable from './IVRPromptMessagesTable';
import IVRPromptMessagesHeader from './IVRPromptMessagesHeader';
import { Grid, Transition } from 'semantic-ui-react';
import AddAudioFileSectionContainer from './AddAudioMessgeSection';
import LeftPaddedContent from 'shared/LeftPaddedContent';

const IVRPromptMessages = ({ 
    messages, 
    onUpdate, 
    onDelete, 
    isAddAudioFileSectionOpen,
    onOpenAddAudioFileSection, 
    onCloseAddAudioFileSection, 
    onAddAudioFile 
}) => (
    <TableWithHeader 
        header={
            <IVRPromptMessagesHeader 
                onOpenAddIVRPromptMessagesSection={onOpenAddAudioFileSection}
            />
        }
        table={
            <Grid style={{margin: 0}}>
                <Transition visible={isAddAudioFileSectionOpen} unmountOnHide duration='100' animation='slide down'>
                    <Grid.Row>
                        <Grid.Column>
                            <LeftPaddedContent>
                                <AddAudioFileSectionContainer 
                                    onAddAudioFile={onAddAudioFile} 
                                    onClose={onCloseAddAudioFileSection}
                                />
                            </LeftPaddedContent>
                        </Grid.Column>
                    </Grid.Row>
                </Transition>
                <Grid.Row style={{padding: 0}}>
                    <Grid.Column style={{padding: 0}}>
                        <IVRPromptMessagesTable 
                            messages={messages}
                            onUpdate={onUpdate}
                            onDelete={onDelete}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        }
    />
);

class IVRPromptMessagesContainer extends React.Component {
    state = {
        messages: [
            { id: '8745620', name: 'Vacation', lastUpdated: 'Thu Oct 30, 2014' },
            { id: '8745621', name: 'Normal Hours', lastUpdated: 'Thu Oct 30, 2014' },
            { id: '8745622', name: 'Company Directory', lastUpdated: 'Thu, Mar 21, 2019' },
            { id: '8745623', name: 'Claims', lastUpdated: 'Fri, Mar 22, 2019', assigned: 'All Users' },
            { id: '8745624', name: 'Service Center', lastUpdated: 'Tue, Apr 23, 2019' }
        ],
        isAddAudioFileSectionOpen: false
    }

    onUpdate = (updatedMessage) => {
        let messages = this.state.messages.slice(0);
        let indexOfMessageToUpdate = messages.findIndex(message => message.id === updatedMessage.id);
        if (indexOfMessageToUpdate !== -1) {
            messages[indexOfMessageToUpdate] = {...updatedMessage};
            this.setState({ messages }, () => {
                Notification.alert('Successfully updated IVR prompt message!', STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    onAddAudioFile = (newAudioFile) => {
        let messages = this.state.messages.slice(0);
        messages.push({ ...newAudioFile, id: Math.random(), lastUpdated: new Date().toLocaleDateString("en-US", { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) });
        this.setState({ messages });
    }

    onOpenAddAudioFileSection = () => {
        this.setState({ isAddAudioFileSectionOpen: true });
    }

    onCloseAddAudioFileSection = () => {
        this.setState({ isAddAudioFileSectionOpen: false });
    }

    onDelete = (id) => {
        let messages = this.state.messages.slice(0);
        let indexOfLeadSourceToDelete = messages.findIndex(message => message.id === id);
        if (indexOfLeadSourceToDelete !== -1) {
            messages.splice(indexOfLeadSourceToDelete, 1);
            this.setState({ messages }, () => {
                Notification.alert('Successfully deleted IVR prompt message!', STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    render() {
        return (
            <IVRPromptMessages 
                {...this.state}
                onOpenAddAudioFileSection={this.onOpenAddAudioFileSection}
                onCloseAddAudioFileSection={this.onCloseAddAudioFileSection}
                onAddAudioFile={this.onAddAudioFile}
                onUpdate={this.onUpdate}
                onDelete={this.onDelete}
            />
        )
    }
}

export default IVRPromptMessagesContainer;
