import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function productQuoteReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PRODUCT_QUOTE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReading: true };
        case getNamedAction(ACTION_NAMES.PRODUCT_QUOTE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReading: false, quotes: action.data.data || [], count: action.data.count };
        case getNamedAction(ACTION_NAMES.PRODUCT_QUOTE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReading: false, quotes: [], count: 0 };

        case getNamedAction(ACTION_NAMES.PRODUCT_QUOTE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreating: true };
        case getNamedAction(ACTION_NAMES.PRODUCT_QUOTE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreating: false };
        case getNamedAction(ACTION_NAMES.PRODUCT_QUOTE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreating: false };

        case getNamedAction(ACTION_NAMES.PRODUCT_QUOTE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, quoteBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.PRODUCT_QUOTE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, quoteBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.PRODUCT_QUOTE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, quoteBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.PRODUCT_QUOTE, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, quoteBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.PRODUCT_QUOTE, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, quoteBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.PRODUCT_QUOTE, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, quoteBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.SINGLE_PRODUCT_QUOTE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, productQuoteBeingRead: action.id };
        case getNamedAction(ACTION_NAMES.SINGLE_PRODUCT_QUOTE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, productQuoteBeingRead: null, productQuote: action.data };
        case getNamedAction(ACTION_NAMES.SINGLE_PRODUCT_QUOTE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, productQuoteBeingRead: null };

        case getNamedAction(ACTION_NAMES.QUOTE_ITEMS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, quoteBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.QUOTE_ITEMS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, quoteBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.QUOTE_ITEMS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, quoteBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.ORDER_QUOTE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isOrdering: true };
        case getNamedAction(ACTION_NAMES.ORDER_QUOTE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isOrdering: false };
        case getNamedAction(ACTION_NAMES.ORDER_QUOTE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isOrdering: false };

        case getNamedAction(ACTION_NAMES.PRODUCT_QUOTE_SHIPPING, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, quoteShippingDetailsBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.PRODUCT_QUOTE_SHIPPING, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, quoteShippingDetailsBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.PRODUCT_QUOTE_SHIPPING, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, quoteShippingDetailsBeingUpdated: null };

        default:
            return state;
    }
}


export default productQuoteReducer;
