import React from 'react';
import { TableWithHeader } from 'dyl-components';
import { Grid } from 'semantic-ui-react';

import AudioSelectionsHeader from './AudioSelectionsHeader';
import AudioSelectionsForm from './AudioSelectionsForm';

export default class AudioSelections extends React.Component {
    state = {
        isEditMode: false,
        info: this.props.info
    }

    onEnableEditMode = () => {
        this.setState({
            isEditMode: true
        })
    }

    onCloseEditMode = () => {
        this.setState({
            isEditMode: false,
            info: this.props.info
        })
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false,
        }, () => {
            this.props.onUpdate({
                ...this.state.info
            })
        });
    }

    onChange = (_, {name, value}) => {
        let info = {...this.state.info, [name]: value};
        this.setState({
            info
        });
    }

    render() {
        return (
            <TableWithHeader 
                header={(
                    <AudioSelectionsHeader 
                        isEditMode={this.state.isEditMode}
                        onCloseEditMode={this.onCloseEditMode}
                        onEnableEditMode={this.onEnableEditMode}
                        onUpdate={this.onUpdate}
                    />
                )}
                table={(
                    <Grid celled style={{margin: 0}} verticalAlign='middle'>
                        <AudioSelectionsForm
                            audioFileOptions={this.props.audioFileOptions}
                            info={this.state.info}
                            isEditMode={this.state.isEditMode}
                            onChange={this.onChange}
                            onToggleMessageOnly={this.props.onToggleMessageOnly}
                        />
                    </Grid>
                )}
            />
        )
    }
}
