import React from 'react';
import { STATUS_TYPES, Notification } from 'dyl-components';

import RingGroupMembersContainer from 'shared/RingGroupMembers';
import RingOptions from 'shared/RingOptions';

class RingOptionsContainer extends React.Component {
    state = {
        info: {
            name: 'Sales-Call Queue',
            ringMode: 'Call Queue',
            ringTime: 20,
            holdMusic: 'DYL Hold Music',
            strategy: 'Ring All',
            exitKey: 'None',
            introMessage: 'None',
            directExtensions: false,
            announceMessage: 'None',
            announceFrequency: 1,
            fallbackExtension: 'Hangup',
            internalExtension: 405
        }
    }

    onUpdate = (newInfo) => {
        this.setState({
            info: newInfo
        }, () => {
            Notification.alert('Succefully updated ring group!', STATUS_TYPES.SUCCESS, true);
        });
    }

    render() {
        return (
            <RingOptions 
                {...this.state}
                onUpdate={this.onUpdate}
            />
        )
    }
}

const CallQueue = () => (
    <React.Fragment>
        <RingOptionsContainer />
        <RingGroupMembersContainer />
    </React.Fragment>
);

export default CallQueue;
