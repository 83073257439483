import React  from "react";
import { useSelector } from 'react-redux';
import { Grid, Segment, Header, Form } from "semantic-ui-react";

const ReturnedKeyValuePairsTable = ({returnedValues, iconChoice, isEmail}) => {
    const { isAttemptingEmailParse} = useSelector(state => state.lead_ingestion);
    return (
        <Segment placeholder className="ReturnedKeyValuePairsTable" loading={isAttemptingEmailParse}>
            <Form >
                <Grid verticalAlign='middle' textAlign='center' className="parse-header">
                    {returnedValues.length !== 0 ?
                        <Grid.Row >
                            <Grid.Column width={ 7 }>
                                <Header as="h4">Field name</Header>
                            </Grid.Column>
                            <Grid.Column width={ 7 }>
                                <Header as="h4">Field value</Header>
                            </Grid.Column>
                            {isEmail &&
                                <Grid.Column width={ 2 }>
                                    <Header as="h4">Keep</Header>
                                </Grid.Column>
                            } 
                        </Grid.Row>
                        :
                        <Grid.Column width={ 7 }>
                            <Header as="h5">Parse failed and unable to map.</Header>
                        </Grid.Column>
                    }
                    {
                        returnedValues.map((pair, idx) => (
                            <Grid.Row key={ idx } columns={ 3 } className="parse-row">
                                <Grid.Column width={ 7 }>
                                    <Form.Input  readOnly value={pair.key}  />
                                </Grid.Column>
                                <Grid.Column width={ 7 }>
                                <Form.Input  readOnly value={pair.value} className={(pair.status === 'bad') ? 'bad' : ''} />
                                </Grid.Column>
                                {isEmail &&
                                    <Grid.Column width={ 2 }>
                                        { iconChoice(idx, pair.status) }
                                    </Grid.Column>
                                }
                                {(pair.status === 'bad') &&<p class="error">Entered key value pair not found. This pair will not be entered.</p>}
                            </Grid.Row>
                        ))
                    }

                </Grid>
            </Form>
        </Segment>
    );
};

export default ReturnedKeyValuePairsTable;
