import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { RecordsTable } from 'dyl-components';

import SequenceTableHeader from './Header';
import SequenceRow from './Row';

const SequenceTable = ({ onPageChange }) => {
    const [params] = useSearchParams();

    const { sequenceReducer } = useSelector(state => ({
        sequenceReducer: state.sequences
    }));
    const { sequences, isReadingSequences, sequence_count } = sequenceReducer;
    
    return (
        <RecordsTable
            className='SequencesTable'
            isTableLoading={isReadingSequences}
            isSearchResultEmpty={false}
            isTableEmpty={sequence_count === 0}
            TableHeader={<SequenceTableHeader />}
            TableRows={sequences.map(sequence => (
                <SequenceRow
                    key={sequence.id}
                    sequence={sequence}
                />
            ))}
            recordsCount={sequence_count}
            activePage={params.get('page')}
            onPageChange={onPageChange}
            recordsName='Sequence'
            emptySubtitle={(
                <span>
                    Add a Sequence by clicking on the &nbsp;<Link to='/settings/sequences/create'>+ Sequence</Link>&nbsp; button on the top-right corner.
                </span>
            )}
        />
    );
}

export default SequenceTable;
