import React, { useState, useEffect } from 'react';
import { Icon, Header, Dropdown } from 'semantic-ui-react';
import { Button, DateTimeUtils } from 'dyl-components';
import './index.scss';

const dateFormats = {
    day: DateTimeUtils.WORD_DATE_LONG_FORMAT,
    week: `${DateTimeUtils.WORD_MONTH_LONG_FORMAT} ${DateTimeUtils.WORD_YEAR_FORMAT}`,
    month: `${DateTimeUtils.WORD_MONTH_LONG_FORMAT} ${DateTimeUtils.WORD_YEAR_FORMAT}`,
    year: DateTimeUtils.WORD_YEAR_FORMAT,
};

export default function CalendarToolBar({
    viewType, // grid, list
    setViewType,
    timeUnits, // day, week, month, year
    setTimeUnits,
    refreshCalendarDate,
    fullCalendarApiFunction,
    calendarDate,
    setCalendarDate
}) {
    const [toolbarHeader, setToolbarHeader] = useState('');
    const [isCurrent, setIsCurrent] = useState(false);
    const viewOptions = [
        {
            text: 'Day',
            value: 'day',
        },
        {
            text: 'Week',
            value: 'week',
        },
        {
            text: 'Month',
            value: 'month',
        },
        {
            text: 'Year',
            value: 'year',
        },
    ];

    const nowButtonLabel = {
        day: 'Today',
        week: 'This week',
        month: 'This month',
        year: 'This year',
    };

    const onChangeViewMode = (_, { value }) => {
            setTimeUnits(value);
    };

    const changeDate = (name) => {
        fullCalendarApiFunction(name);
        refreshCalendarDate();
    }

    const onPrev = () => {
        if (viewType === "grid") {
            changeDate("prev")
        } else {
            setCalendarDate(DateTimeUtils.getTimeUnits(calendarDate,1,timeUnits,'subtraction'))
        }
    }

    const onNext = () => {
        if (viewType === "grid") {
            changeDate("next")
        } else {
            setCalendarDate(DateTimeUtils.getTimeUnits(calendarDate,1,timeUnits,'addition'))
        }
    }

    const resetDay = () => {
        if (viewType === "grid") {
            changeDate("today");
        } else {
            setCalendarDate(DateTimeUtils.getCurrentDate('',true))
        }
    }

    useEffect(()=> {
        const start = DateTimeUtils.getStartOf(calendarDate, timeUnits);

        // check if we are on current period
        setIsCurrent(DateTimeUtils.getStartOf(DateTimeUtils.getCurrentDate('',true),timeUnits) === start)

        let header = DateTimeUtils.formatEpoch(start, dateFormats[timeUnits]);
        
        if (timeUnits === 'week') {
            // just in case we have two months on the same week
            const end = DateTimeUtils.getEndOf(calendarDate, timeUnits);
            const start_month = DateTimeUtils.formatEpoch(start, DateTimeUtils.WORD_MONTH_FORMAT);
            const end_month = DateTimeUtils.formatEpoch(end - 60 * 60, DateTimeUtils.WORD_MONTH_FORMAT);
            if (start_month !== end_month) {
                header = (`${start_month} - ${end_month} ${DateTimeUtils.formatEpoch(end - 60 * 60, DateTimeUtils.WORD_YEAR_FORMAT)}`);
            }
        }
        setToolbarHeader(header);
    }, [timeUnits, calendarDate])

    return (
        <div className="CalendarToolbar">
            <div className="CalendarToolbar__LeftContainer">
                <div className="CalendarToolbar__MonthSelector">
                    <Header as={'h3'} className="CalendarToolbar__Header">
                        {toolbarHeader}
                    </Header>
                    <Icon link name="chevron left" value="previous" onClick={onPrev} />
                    <Icon link name="chevron right" value="next" onClick={onNext} />
                </div>
                <Dropdown search selection options={viewOptions} value={timeUnits} onChange={onChangeViewMode} />
                <Button basic disabled={ isCurrent } onClick={resetDay}>
                    {nowButtonLabel[timeUnits]}
                </Button>
            </div>
            <div className="CalendarToolbar__RightContainer">
                <Icon
                    link
                    size={'big'}
                    className={`calendar alternate ${viewType === 'grid' ? 'ActiveViewType' : ''}`}
                    onClick={() => {
                        setViewType('grid');
                    }}
                />
                <Icon
                    link
                    size={'big'}
                    className={`list ${viewType === 'list' ? 'ActiveViewType' : ''}`}
                    onClick={() => {
                        setViewType('list');
                    }}
                />
            </div>
        </div>
    );
}
