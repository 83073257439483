
import routes from '../routes';

import { generateReadOneActionCreator } from "actions/crudActionCreators";
import ACTION_NAMES from "actions/ACTION_NAMES";

export default {
    readPipelines: generateReadOneActionCreator(routes.API_ACCOUNT, ACTION_NAMES.CUSTOMER_PIPELINE, 'pipeline'),
    readPipelineTimeline: generateReadOneActionCreator(routes.API_CUSTOMER_PIPELINE, ACTION_NAMES.CUSTOMER_PIPELINE_TIMELINE, 'timeline')
};
