import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function contactPhonesReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CONTACT_PHONE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingPhones: true, phone: [], count: 0 };
        case getNamedAction(ACTION_NAMES.CONTACT_PHONE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, phone: action.data.data, count: action.data.count, isReadingPhones: false };
        case getNamedAction(ACTION_NAMES.CONTACT_PHONE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingPhones: false };

        case getNamedAction(ACTION_NAMES.CONTACT_PHONE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingPhone: true };
        case getNamedAction(ACTION_NAMES.CONTACT_PHONE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingPhone: false };
        case getNamedAction(ACTION_NAMES.CONTACT_PHONE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingPhone: false };

        case getNamedAction(ACTION_NAMES.CONTACT_PHONE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingPhone: true };
        case getNamedAction(ACTION_NAMES.CONTACT_PHONE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingPhone: false };
        case getNamedAction(ACTION_NAMES.CONTACT_PHONE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpadtingPhone: false };

        case getNamedAction(ACTION_NAMES.CONTACT_PHONE, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, phoneBeingDeleted: action.subId };
        case getNamedAction(ACTION_NAMES.CONTACT_PHONE, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, phoneBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.CONTACT_PHONE, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, phoneBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.CONTACT_PHONE_OPTIONS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, phoneOptions: { ...state.phoneOptions, [action.id]: { isReading: true, options: [] } } };
        case getNamedAction(ACTION_NAMES.CONTACT_PHONE_OPTIONS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, phoneOptions: { ...state.phoneOptions, [action.id]: { isReading: false, options: action.data.data || [] } } };
        case getNamedAction(ACTION_NAMES.CONTACT_PHONE_OPTIONS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, phoneOptions: { ...state.phoneOptions, [action.id]: { isReading: false, options: [] } } };

        default:
            return state;
    }
}

export default contactPhonesReducer;
