import React, { useState, useContext, useEffect } from 'react';
import { Checkbox, Dropdown, Header, Popup } from 'semantic-ui-react';
import { Table, Person, DateTimeUtils, EllipsisDropdown, Notification, STATUS_TYPES, TableLoader, ControlledPopup } from 'dyl-components';
import { Link, useSearchParams } from 'react-router-dom';
import DropdownItem from './DropdownItem';
import GroupsSubrow from './GroupsSubrow';
import Subfooter from './Subfooter';
import { useDispatch, useSelector } from 'react-redux';
import ContactGroupPopup from './ContactGroupPopup';
import groupsActions from 'actions/groups';
import { BulkActionsContext } from "shared/context/BulkActionsProvider";

const GroupsRow = ({ isHeaderChecked, group, getGroups, isDuplicated, isReadingSubgroups, onDeleteGroup, getSubgroups }) => {
    const [query] = useSearchParams();
    const search = query.get("search") || "";
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const dispatch = useDispatch()

    const { subgroups } = useSelector((state) => ({
        subgroups: state.groups.subgroups[group.id] || []
    }));

    const ELLIPSIS_OPTIONS = [
        {
            id: 1,
            text: 'Edit',
            icon: 'fa-solid fa-pencil',
            onClick: () => setIsPopupOpen(true)
        },
        {
            id: 2,
            text: 'Delete',
            icon: 'fa-solid fa-trash',
            disabled: group.sub_labels,
            onClick: () => onDeleteGroup(group.id),
        }
    ];

    const [selectedGroups, setSelectedGroups] = useContext(BulkActionsContext);

    const handleToggle = async () => {
        setIsCollapsed(!isCollapsed)
        if (isCollapsed) {
            const parent_label_id = group.id;
            await getSubgroups({parent_label_id});
        }
    };

    const onSaveSubgroup = async (data, setIsPopupOpen) => {
        const {name} = data;
        const parent_label_id = group.id;
        try {
            await dispatch(groupsActions.createGroups([{name, parent_label_id}]))
            Notification.alert(`Successfully added subgroup!`, STATUS_TYPES.SUCCESS);
            getSubgroups({parent_label_id});
            setIsPopupOpen(false);
            group.sub_labels = true;
            const selectedGroupsAux = selectedGroups.filter((selectedGroup) => selectedGroup !== parent_label_id);
            setSelectedGroups(selectedGroupsAux);
        } catch (error) {
            console.log(error)
            Notification.alert('Failed to add subgroup', STATUS_TYPES.ERROR);
        }
    }

    const onDeleteSubgroup = async (group_id) => {
        const parent_label_id = group.id;
        try {
            await dispatch(groupsActions.deleteGroup(group_id));
            Notification.alert(`Successfully deleted subgroup!`, STATUS_TYPES.SUCCESS);
            getSubgroups({parent_label_id});
            getGroups();
        } catch (error) {
            console.log(error)
            Notification.alert('Failed to delete subgroup', STATUS_TYPES.ERROR);
        }
    }

    const onUpdateSubgroup = async (data, id, setIsPopupOpen) => {
        const {name} = data;
        const parent_label_id = group.id;
        try {
            await dispatch(groupsActions.updateGroup(id, {name}));
            Notification.alert(`Successfully updated subgroup!`, STATUS_TYPES.SUCCESS);
            getSubgroups({parent_label_id});
            setIsPopupOpen(false);
        } catch (error) {
            console.log(error)
            Notification.alert('Failed to update subgroup', STATUS_TYPES.ERROR);
        }
    }

    const isSubgroupDuplicated = (name, parent_label_id) => {
        const result = dispatch(groupsActions.isGroupUnique({name, parent_label_id}));
        return result;
    }

    const onUpdate = async (data) => {
        const {name} = data;
        const group_id = group.id
        try {
            await dispatch(groupsActions.updateGroup(group_id, {name}));
            Notification.alert(`Successfully updated group!`, STATUS_TYPES.SUCCESS);
            getGroups();
            setIsPopupOpen(false);
        } catch (error) {
            console.log(error)
            Notification.alert('Failed to update group', STATUS_TYPES.ERROR);
        }
    }

    const isSelected = (group_id) => {
        return selectedGroups.some((group) => group.group_id === group_id);
    }

    const onToggleGroup = async (group) => {
        const {id: group_id, parent_label_id, sub_labels} = group;
        if (isSelected(group_id)) {
            const newSelectedGroups = selectedGroups.filter((group) => group.group_id !== group_id);
            setSelectedGroups(newSelectedGroups);
        } else {
            if (!parent_label_id && sub_labels && !search) {
                getSubgroups({parent_label_id: group_id}).then((res) => {
                    const subgroupIds = res.data.map((subgroup) => ({group_id: subgroup.id, parent_label_id: group_id}));
                    const newSelectedGroups = [...selectedGroups, {group_id, parent_label_id}, ...subgroupIds];
                    setSelectedGroups(newSelectedGroups);
                    if (isCollapsed) {
                        setIsCollapsed(false);
                    }
                })
            } else {
                const newSelectedGroups = [...selectedGroups, {group_id, parent_label_id}];
                setSelectedGroups(newSelectedGroups);
            }
        }
    }

    useEffect(() => {
        if (group.sub_labels && isHeaderChecked) {
            setIsCollapsed(false);
        }
    }, [isHeaderChecked, group.sub_labels])

    return (
        <Table.CollapsibleRow
            onToggle={handleToggle}
            collapsed={isCollapsed || search !== ""}
            togglePos={1}
            toggleHidden={search !== ""}
            subrowContent={!isReadingSubgroups ? [
            ...subgroups.map((subgroup, index) => <GroupsSubrow 
                key={index} 
                onDeleteGroup={onDeleteSubgroup} 
                subgroup={subgroup} 
                parent={group} 
                onUpdateSubgroup={onUpdateSubgroup} 
                reloadSubgroups={async (targetId) => {
                    await getSubgroups({parent_label_id: group.id});
                    await getSubgroups({parent_label_id: targetId});
                }} 
                isSubgroupDuplicated={(name) => isSubgroupDuplicated(name, group.id)} />
            ),
                <Subfooter onSaveSubgroup={onSaveSubgroup} isSubgroupDuplicated={(name) => isSubgroupDuplicated(name, group.id)} />
            ] : [
                <Table.CollapsibleRowContent indented nested secondCol>
                    <Table.Cell>
                        <TableLoader isLoading colSpan={9} />
                    </Table.Cell>
                </Table.CollapsibleRowContent>
            ]}
        >
            <Table.Cell>
                <Checkbox 
                    checked={isSelected(group.id)} 
                    onChange={() => { onToggleGroup(group) }} 
                />
            </Table.Cell>
            <Table.Cell>
                <Link to={`/group/${group.id}`}>{group.name}</Link>
            </Table.Cell>
            <Table.Cell>
                {group.contact_count}
            </Table.Cell>
            <Table.Cell>
                {group.user_id ? "Personal" : "Company"}
            </Table.Cell>
            <Table.Cell>
                <Person username={`${group.created_by.first_name} ${group.created_by.last_name}`} email={group.created_by.email} />
            </Table.Cell>
            <Table.Cell>
                <div><b>{DateTimeUtils.formatEpoch(group.created, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                <div>{DateTimeUtils.formatEpoch(group.created, DateTimeUtils.TIME_FORMAT)}</div>
            </Table.Cell>
            <Table.Cell>
                <div><b>{DateTimeUtils.formatEpoch(group.activity, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                <div>{DateTimeUtils.formatEpoch(group.activity, DateTimeUtils.TIME_FORMAT)}</div>
            </Table.Cell>
            <Table.Cell>
                <Popup 
                    trigger={
                        <EllipsisDropdown {...isPopupOpen ? { isDropdownOpen: false } : {}}>
                            {ELLIPSIS_OPTIONS.map(({ text, icon, disabled, onClick, ...otherProps }, idx) => {
                                return idx === 1 ? (
                                    <ControlledPopup
                                        header={
                                            <Header as='h4' textAlign='center'>
                                                Are you sure?
                                                <Header.Subheader style={{ marginTop: 5 }}>
                                                    You cannot undo delete actions.
                                                </Header.Subheader>
                                            </Header>
                                        }
                                        trigger={<Dropdown.Item icon={icon} text={text} disabled={disabled} />}
                                        onConfirm={onClick}
                                        inverted
                                    />
                                ) : (
                                    <DropdownItem
                                        key={idx}
                                        text={text}
                                        icon={icon}
                                        disabled={disabled}
                                        onClick={onClick}
                                        {...otherProps}
                                    />
                                );
                            })}
                        </EllipsisDropdown>
                    }
                    content={
                        <ContactGroupPopup
                            handlePopupClose={() => setIsPopupOpen(false)}
                            group={{...group, shared_type: group.user_id ? "personal" : "company"}}
                            onSave={onUpdate}
                            isDuplicated={isDuplicated}
                        />
                    }
                    on='click'
                    position='bottom left'
                    open={isPopupOpen}
                    onClose={() => setIsPopupOpen(false)}
                />
            </Table.Cell>
        </Table.CollapsibleRow>
    )
}

export default GroupsRow;
