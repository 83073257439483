import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Chart, Transformation, XAxis, Pie, Histogram, Legend } from 'dyl-components';
import * as d3 from 'd3';

import { ArrayUtils } from "utils";

let chartWidth = 500;
let chartHeight = 300;

let margin = { top: 60, right: 0, bottom: 30, left: 0 };

let histogramWidth = chartWidth - (margin.left + margin.right);
let histogramHeight = chartHeight - (margin.top + margin.bottom);

let adjustedChartXloc = margin.left;
let adjustedChartYloc = margin.top;

const getColor = (name) => {
    switch (name) {
        case 'all':
            return '#00bfa5';
        case 'Lead':
            return '#303f94';
        case 'Opportunity':
            return '#2979ff';
        case 'Contacts':
            return '#6d8eb0';
        case 'Customer':
            return '#126496';
        default:
            return '#00bfa5';
    }
}

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

class ConversionChartContainer extends React.Component {
    state = {
        pieChartData: [],
        histogramData: [],
        barColor: getColor('all'),
        isPiechartUpdateDisabled: false
    }

    getHistogramData = () => {
        const conversion_data = this.props.conversion_data.length > 0 ? this.props.conversion_data : [
            {
                lead_count: 0,
                opportunity_count: 0,
                time_interval: "",
                total_count: 0
            }
        ]
        const histogramData = ArrayUtils.groupBy(conversion_data, 'time_interval').map(({ group, data }) => {
            let lead_count = 0;
            let opportunity_count = 0;
            data.forEach((time_interval) => {
                lead_count += time_interval.lead_count;
                opportunity_count += time_interval.opportunity_count;
            })
            return {
                time_interval: group,
                counts: [
                    {
                        type: 'Lead',
                        count: lead_count
                    },
                    {
                        type: 'Opportunity',
                        count: opportunity_count
                    }
                ]
            }
        });
        if (this.props.isMonthInterval) {
            histogramData.sort(function (a, b) {
                return months.indexOf(a.time_interval)
                    - months.indexOf(b.time_interval);
            });
        }
        return histogramData;
    }

    getPieChartData = () => {
        const conversion_data = this.props.conversion_data.length > 0 ? this.props.conversion_data : [
            {
                lead_count: 0,
                opportunity_count: 0,
                time_interval: "N/A",
                total_count: 0
            }
        ]
        const lead_count = conversion_data.reduce((a, b) => a + b.lead_count || 0, 0);
        const opportunity_count = conversion_data.reduce((a, b) => a + b.opportunity_count || 0, 0);
        return [
            {
                name: 'Lead',
                value: lead_count
            },
            {
                name: 'Opportunity',
                value: opportunity_count
            }
        ];
    }

    onBarMouseOver = (_, data) => {
        const timeIntervalData = this.getHistogramData().filter((s) => s.time_interval === data[0])[0];
        const countsGroupedByType = ArrayUtils.groupBy(timeIntervalData.counts, 'type', true);
        const newPieChartData = Object.keys(countsGroupedByType)
            .map((type) => ({ name: type, value: countsGroupedByType[type][0].count }));
        this.setState({ pieChartData: newPieChartData, isPiechartUpdateDisabled: false });
    }

    onBarMouseOut = () => {
        this.setState({ pieChartData: [] });
    }

    onSliceMouseOver = (_, d) => {
        if (d === undefined) {
            return;
        }
        const { name } = d.data;
        const histogramData = this.getHistogramData().map(({ time_interval, counts }) => {
            return [time_interval, counts.filter(({ type }) => type === name).reduce((a, b) => a + (b.count || 0), 0)];
        });
        this.setState({ histogramData, isPiechartUpdateDisabled: true, barColor: getColor(name) });
    }

    onSliceMouseOut = () => {
        this.setState({ histogramData: [], barColor: getColor('all') });
    }

    render() {
        if (this.props.isReading) {
            return "";
        }
        const histogramData = this.state.histogramData.length > 0 ? this.state.histogramData :
            this.getHistogramData().map(({ time_interval, counts }) => [time_interval, counts.reduce((a, b) => a + (b.count || 0), 0)]);
        const pieChartData = this.state.pieChartData.length > 0 ? this.state.pieChartData : this.getPieChartData();
        const xScale = d3.scaleBand().rangeRound([0, histogramWidth], 0.1).domain(histogramData.map(function (d) { return d[0]; }));
        const yScale = d3.scaleLinear().range([histogramHeight, 0]).domain([0, d3.max(histogramData, function (d) { return d[1]; })]);
        return (
            <Grid>
                <Grid.Row verticalAlign='middle'>
                    <Grid.Column width={8}>
                        <Chart viewBoxWidth={chartWidth} viewBoxHeight={chartHeight}>
                            <Transformation transform={`translate(${adjustedChartXloc}, ${adjustedChartYloc})`}>
                                <XAxis xScale={xScale} height={histogramHeight} />
                                <Histogram
                                    data={histogramData}
                                    xScale={xScale}
                                    yScale={yScale}
                                    height={histogramHeight}
                                    barColor={this.state.barColor}
                                    onMouseOver={this.onBarMouseOver}
                                    onMouseOut={this.onBarMouseOut}
                                />
                            </Transformation>
                        </Chart>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Chart viewBoxWidth={20} viewBoxHeight={20}>
                            <Pie
                                data={pieChartData}
                                innerRadius={0}
                                outerRadius={10}
                                animation={{ type: 'clockwise', duration: 1000 }}
                                expanding={false}
                                onMouseOver={this.onSliceMouseOver}
                                isUpdateDisabled={this.state.isPiechartUpdateDisabled}
                                colorFunction={(type) => {
                                    return getColor(type);
                                }}
                                onMouseOut={this.onSliceMouseOut}
                            />
                        </Chart>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Legend
                            data={pieChartData}
                            colorFunction={d => {
                                return getColor(d.name);
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default React.memo(ConversionChartContainer);
