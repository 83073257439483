import initialState from "./initialState";
import types from '../../actions/call_recordings/types';

function callRecordings(state = initialState, action) {
    switch (action.type) {
        case types.READ_CALL_RECORDINGS_SETTINGS_OPTIONS_REQUEST:
            return { ...state, isReadingCallRecordingsSettingsOptions: true };
        case types.READ_CALL_RECORDINGS_SETTINGS_OPTIONS_SUCCESS:
            return { ...state, isReadingCallRecordingsSettingsOptions: false, 
                isUserAccessEnabled: action.isUserAccessEnabled,
                isDeleteRecordingsEnabled: action.isDeleteRecordingsEnabled,
                isRecordingConsented: action.isRecordingConsented,
                disabled: action.disabled 
            };
        case types.READ_CALL_RECORDINGS_SETTINGS_OPTIONS_FAILURE:
            return { ...state, isReadingCallRecordingsSettingsOptions: false };

        default:
            return state;
    }
}

export default callRecordings;
