import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import initialState from "./initialState";
import ACTION_NAMES from "actions/ACTION_NAMES";

function contactSequenceReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CONTACT_SEQUENCE_CONVERT, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isHandlingConvert: true };
        case getNamedAction(ACTION_NAMES.CONTACT_SEQUENCE_CONVERT, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isHandlingConvert: false };
        case getNamedAction(ACTION_NAMES.CONTACT_SEQUENCE_CONVERT, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isHandlingConvert: false };

        case getNamedAction(ACTION_NAMES.CONTACT_SEQUENCE_REASSIGN, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isHandlingReassign: true };
        case getNamedAction(ACTION_NAMES.CONTACT_SEQUENCE_REASSIGN, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isHandlingReassign: false };
        case getNamedAction(ACTION_NAMES.CONTACT_SEQUENCE_REASSIGN, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isHandlingReassign: false };

        case getNamedAction(ACTION_NAMES.CONTACT_SEQUENCE, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isRemovingSequence: true };
        case getNamedAction(ACTION_NAMES.CONTACT_SEQUENCE, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isRemovingSequence: false };
        case getNamedAction(ACTION_NAMES.CONTACT_SEQUENCE, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isRemovingSequence: false };

        case getNamedAction(ACTION_NAMES.CONTACT_SEQUENCE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingTasks: true, tasksInfo: null };
        case getNamedAction(ACTION_NAMES.CONTACT_SEQUENCE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingTasks: false, tasksInfo: action.data };
        case getNamedAction(ACTION_NAMES.CONTACT_SEQUENCE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingTasks: false, tasksInfo: null };


        default:
            return state;
    }
}

export default contactSequenceReducer;
