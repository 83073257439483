import React from 'react';
import { Form } from 'semantic-ui-react';
import { TopPaddedControls } from 'dyl-components';

const AddLeadSourceSection = ({ name, cost, onChange, onAddLeadSource, onClose }) => (
    <Form>
        <Form.Group>
            <Form.Input 
                width={4}
                label='Source Name'
                name='name'
                value={name}
                onChange={onChange}
            />
            <Form.Input 
                width={4}
                label='Lead Cost'
                name='cost'
                value={cost}
                onChange={onChange}
                type='number'
            />
            <TopPaddedControls>
                <Form.Button
                    floated='right'
                    content={'Cancel'}
                    onClick={onClose}
                />
                <Form.Button 
                    primary
                    floated='right'
                    content={'Add'}
                    onClick={onAddLeadSource}
                />
            </TopPaddedControls>
        </Form.Group>
    </Form>
)

class AddLeadSourceSectionContainer extends React.Component {
    state = {
        name: '',
        cost: 0
    }

    onChange = (_, { name, value }) => {
        this.setState({ [name]: value });
    }

    onAddLeadSource = () => {
        this.props.onAddLeadSource({ ...this.state, leadsDestination: 'Automatic' });
        this.setState({
            name: '',
            cost: 0
        });
    }

    render() {
        return (
            <AddLeadSourceSection 
                {...this.props}
                {...this.state}
                onAddLeadSource={this.onAddLeadSource}
                onChange={this.onChange}
            />
        )
    }
}

export default AddLeadSourceSectionContainer;
