import React from 'react';
import { Table } from 'dyl-components';
import { Select } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { EditableContent } from 'dyl-components';

function getValue(isEditMode, field, options) {
    return isEditMode ? field :
        options.filter(function (option) {
            return this.indexOf(option.key) >= 0;
        }, field)
            .map(option => option.text)
            .join(', ');
}

const UserChatRoomsRow = ({ isEditMode, userChatRooms, chatRooms, onModifyChatRooms }) => (
    <Table.Row active={isEditMode}>
        <Table.Cell>
            {userChatRooms.user.name}
        </Table.Cell>
        <Table.Cell>
            <EditableContent
                control={Select}
                onChange={onModifyChatRooms}
                multiple
                options={chatRooms}
                name={userChatRooms.user.id}
                isEditMode={isEditMode}
                value={getValue(isEditMode, userChatRooms.rooms, chatRooms)}
            />
        </Table.Cell>
    </Table.Row>
);

const mapStateToProps = state => ({
    chatRooms: state.chatRooms.rooms.map(({ id, name }) => ({
        key: id, value: id, text: name
    }))
})

export default connect(mapStateToProps, null)(UserChatRoomsRow);
