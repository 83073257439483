import productsActions from "actions/products";
import { CloseableContent } from "dyl-components";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Dropdown, Header, Icon, Popup, Ref } from "semantic-ui-react";
import AddProductCategory from "shared/AddProductCategory";

const Toolbar = () => {
    const hasSelectedProducts = false;

    const onOpenBulkActionsModal = () => {};

    const addCategoryFormRef = useRef();

    const [isAddCategoryFormOpen, setIsAddCategoryFormOpen] = useState(false);

    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const refresh = () => {
        dispatch(productsActions.readProducts(Object.fromEntries(params)));
    };

    const onCloseAddCategoryForm = () => {
        setIsAddCategoryFormOpen(false);
    };
    return (
        <>
            <Ref innerRef={addCategoryFormRef}>
                <Dropdown
                    as={Button}
                    floated="right"
                    floating
                    pointing="top left"
                    options={[
                        {
                            key: "New Category",
                            value: "New Category",
                            text: "New Category",
                            selected: false,
                            content: (
                                <span>
                                    <Icon className="fas fa-folder-plus" /> New
                                    Category
                                </span>
                            ),
                            onClick: () => {
                                setIsAddCategoryFormOpen(true);
                            },
                        },
                        {
                            as: Link,
                            to: `/settings/product-catalog-quotes-invoices/create`,
                            text: "New Product",
                            key: "New Product",
                            value: "New Product",
                            content: (
                                <span>
                                    <Icon className="fas fa-box" /> New Product
                                </span>
                            ),
                        },
                    ]}
                    color="primary"
                    text={
                        <span>
                            <Icon className="fas fa-plus" /> New
                            Category/Product
                        </span>
                    }
                    className="AddCategoryProductButton"
                    selectOnBlur={false}
                    value={null}
                />
            </Ref>
            <Popup
                context={addCategoryFormRef}
                open={isAddCategoryFormOpen}
                onClose={onCloseAddCategoryForm}
                content={
                    <CloseableContent onClose={onCloseAddCategoryForm}>
                        <div style={{ padding: "1em" }}>
                            <Header color="primary">Create Category</Header>
                            <AddProductCategory refresh={refresh} onClose={onCloseAddCategoryForm} />
                        </div>
                    </CloseableContent>
                }
                position="bottom left"
            />
            <Button
                floated="right"
                primary
                disabled={!hasSelectedProducts}
                onClick={onOpenBulkActionsModal}
            >
                Bulk Action
            </Button>
        </>
    );
};

export default Toolbar;
