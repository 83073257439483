import { createStore,applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers';

//DISABLING LOG ROCKET UNTIL USED
// import LogRocket from 'logrocket';

// const LRMiddleWare = LogRocket.reduxMiddleware({
//     actionSanitizer: function (action) {
//         if (action.type === 'LOGIN|CREATE_REQUEST') {
//           return null;
//         }
//         return action;
//       },
// });

const applyEnhancer = (process.env.NODE_ENV === 'development'&& window.__REDUX_DEVTOOLS_EXTENSION__) ? 
          compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
          :
          applyMiddleware(thunk);
          

const store = createStore(
    reducers,
    applyEnhancer
    
);

export default store; 
