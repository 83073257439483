import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function eventsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.EVENTS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingEvents: true, events: [], count: 0 }
        case getNamedAction(ACTION_NAMES.EVENTS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingEvents: false, events: action.data.data || [], count: action.data.count || 0, source: action.data.source || "system" }
        case getNamedAction(ACTION_NAMES.EVENTS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingEvents: false, events: [], count: 0 }

        case getNamedAction(ACTION_NAMES.EVENTS_TAB, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingEventsTab: true, events_tab_events: [], events_tab_count: 0 }
        case getNamedAction(ACTION_NAMES.EVENTS_TAB, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingEventsTab: false, events_tab_events: action.data.data || [], events_tab_count: action.data.count || 0 }
        case getNamedAction(ACTION_NAMES.EVENTS_TAB, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingEventsTab: false, events_tab_events: [], events_tab_count: 0 }

        case getNamedAction(ACTION_NAMES.EVENTS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingEvents: true }
        case getNamedAction(ACTION_NAMES.EVENTS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAddingEvents: false }
        case getNamedAction(ACTION_NAMES.EVENTS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingEvents: false }

        case getNamedAction(ACTION_NAMES.EVENT_LABELS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingEventLabels: true }
        case getNamedAction(ACTION_NAMES.EVENT_LABELS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingEventLabels: false, event_labels: action.data.data, event_labels_count: action.data.count }
        case getNamedAction(ACTION_NAMES.EVENT_LABELS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingEventLabels: false }

        case getNamedAction(ACTION_NAMES.EVENT_LABELS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingEventLabels: true }
        case getNamedAction(ACTION_NAMES.EVENT_LABELS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAddingEventLabels: false }
        case getNamedAction(ACTION_NAMES.EVENT_LABELS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingEventLabels: false }


        default:
            return state;
    }
}

export default eventsReducer;
