import React from 'react';
import { STATUS_TYPES, Notification } from 'dyl-components';
import { connect } from 'react-redux';

import TagCategories from './subcomponents/TagCategories';
import TagsContainer from './subcomponents/Tags';
import tagsActions from 'actions/tags';
import tagCategoriesActions from 'actions/tag_categories';

const CallTags = ({ onUpdateCategory, onAddCategory, onDeleteCategory, onAddTag, onDeleteTag }) => (
    <React.Fragment>
        <TagCategories 
            onUpdate={onUpdateCategory} 
            onAddCategory={onAddCategory}
            onDelete={onDeleteCategory}
        />
        <TagsContainer
            onAddTag={onAddTag}
            onDelete={onDeleteTag}
        />
    </React.Fragment>
);

class CallTagsContainer extends React.Component {
    componentDidMount() {
        this.props.onReadTags();
        this.props.onReadCategories();
    }

    onUpdateCategory = (updatedCategory) => {
        let categories = this.state.categories.slice(0);
        let indexOfCategoryToUpdate = categories.findIndex(category => category.id === updatedCategory.id);
        if (indexOfCategoryToUpdate !== -1) {
            categories[indexOfCategoryToUpdate] = { ...updatedCategory };
            this.setState({ categories }, () => {
                Notification.alert('Succefully updated category!', STATUS_TYPES.SUCCESS, true);
            })
        }
    }

    onAddCategory = (newCategory) => {
        let categories = this.state.categories.slice(0);
        categories.push({ ...newCategory, id: Math.random() });
        this.setState({ categories }, () => {
            Notification.alert('Successfully added category!', STATUS_TYPES.SUCCESS, true);
        });
    }

    onDeleteCategory = (id) => {
        let categories = this.state.categories.slice(0);
        let indexOfCategoryToDelete = categories.findIndex(category => category.id === id);
        if (indexOfCategoryToDelete !== -1) {
            categories.splice(indexOfCategoryToDelete, 1);
            this.setState({ categories }, () => {
                Notification.alert('Successfully deleted category!', STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    onAddTag = (newTag) => {
        let callTags = this.state.callTags.slice(0);
        callTags.push({ ...newTag, id: Math.random(), items: 0 });
        Notification.alert('Successfully added call tag!', STATUS_TYPES.SUCCESS, true);
        this.setState({ callTags, isAddTagSectionOpen: false });
    }

    onDeleteTag = (id) => {
        let callTags = this.state.callTags.slice(0);
        let indexOfCallTagToDelete = callTags.findIndex(callTag => callTag.id === id);
        if (indexOfCallTagToDelete !== -1) {
            callTags.splice(indexOfCallTagToDelete, 1);
            this.setState({ callTags }, () => {
                Notification.alert('Successfully deleted call tag!', STATUS_TYPES.SUCCESS, true);
            });
        }
    }

    render() {
        return (
            <CallTags
                {...this.state}
                onUpdateCategory={this.onUpdateCategory}
                onAddCategory={this.onAddCategory}
                onDeleteCategory={this.onDeleteCategory}
                onAddTag={this.onAddTag}
                onDeleteTag={this.onDeleteTag}
            />
        )
    }
}

const mapDispatchToProps = dispatch => ({
    onReadTags: (type = 'call') => {
        dispatch(tagsActions.getListOfTags({ type }));
    },
    onReadCategories: () => {
        dispatch(tagCategoriesActions.getListOfTagCategories());
    }
})

export default connect(null, mapDispatchToProps)(CallTagsContainer);
