const selectContactPhone = (contact_phone) => {
    return {
        type: 'TEXT_UI_SELECT_CONTACT_PHONE',
        contact_phone
    }
};

export default {
    selectContactPhone
};
