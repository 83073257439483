import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function importReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.IMPORT, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingImports: true };
        case getNamedAction(ACTION_NAMES.IMPORT, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingImports: false, import_data: action.data.data, import_count: action.data.count };
        case getNamedAction(ACTION_NAMES.IMPORT, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingImports: false, import_data: [], import_count: 0 };

        case getNamedAction(ACTION_NAMES.IMPORT, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingImport: true }
        case getNamedAction(ACTION_NAMES.IMPORT, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAddingImport: false }
        case getNamedAction(ACTION_NAMES.IMPORT, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingImport: false }

        case getNamedAction(ACTION_NAMES.IMPORT, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, importBeingUpdated: action.id }
        case getNamedAction(ACTION_NAMES.IMPORT, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, importBeingUpdated: null }
        case getNamedAction(ACTION_NAMES.IMPORT, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, importBeingUpdated: null }

        case getNamedAction(ACTION_NAMES.IMPORT, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, importBeingDeleted: action.id }
        case getNamedAction(ACTION_NAMES.IMPORT, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, importBeingDeleted: null }
        case getNamedAction(ACTION_NAMES.IMPORT, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, importBeingDeleted: null }

        case getNamedAction(ACTION_NAMES.IMPORT, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingImport: true }
        case getNamedAction(ACTION_NAMES.IMPORT, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingImport: false, import_info: action.data.data }
        case getNamedAction(ACTION_NAMES.IMPORT, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingImport: false, import_info: {} }

        case getNamedAction(ACTION_NAMES.IMPORT_CSV, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingImportCSV: true, csv: null }
        case getNamedAction(ACTION_NAMES.IMPORT_CSV, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingImportCSV: false, csv: action.data }
        case getNamedAction(ACTION_NAMES.IMPORT_CSV, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingImportCSV: false, csv: null }

        default:
            return state;
    }
}

export default importReducer;
