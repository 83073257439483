import ACTION_NAMES from 'actions/ACTION_NAMES';
import { generateCreateActionCreator, generateReadOneActionCreator, generateReadActionCreator, generateUpdateActionCreator, generateDeleteActionCreator } from 'actions/crudActionCreators';
import routes from 'actions/routes';

export default {
    readProviders: generateReadActionCreator(routes.API_LEAD_PROVIDERS, ACTION_NAMES.LEAD_PROVIDERS),
    createProvider: generateCreateActionCreator(routes.API_LEAD_PROVIDERS, ACTION_NAMES.LEAD_PROVIDERS),
    readProviderNames: generateReadActionCreator(routes.API_LEAD_PROVIDER_NAMES, ACTION_NAMES.LEAD_PROVIDER_NAMES),
    updateProvider: generateUpdateActionCreator(routes.API_LEAD_PROVIDERS, ACTION_NAMES.LEAD_PROVIDERS),
    deleteProvider: generateDeleteActionCreator(routes.API_LEAD_INGEST_LEAD_PROVIDER, ACTION_NAMES.LEAD_PROVIDERS),

    readUnmappedProviders: generateReadActionCreator(routes.API_UNMAPPED_LEAD_PROVIDERS, ACTION_NAMES.UNMAPPED_LEAD_PROVIDERS),
    readProviderMappings: generateReadOneActionCreator(routes.API_LEAD_PROVIDERS, ACTION_NAMES.LEAD_PROVIDER_MAPPINGS, 'mappings'),
    createProviderMappings: generateCreateActionCreator(routes.API_LEAD_PROVIDERS, ACTION_NAMES.LEAD_PROVIDER_MAPPINGS, () => {}, () => {}, 'mappings'),
    createProviderMapping: generateCreateActionCreator(routes.API_LEAD_PROVIDERS, ACTION_NAMES.LEAD_PROVIDER_MAPPING, () => {}, () => {}, 'mapping'),
    isExistingMapping: generateReadOneActionCreator(routes.API_LEAD_PROVIDERS, ACTION_NAMES.IS_EXISTING_LEAD_PROVIDER_MAPPING, 'unique_mapping'),
    updateMappingFields: generateUpdateActionCreator(routes.API_LEAD_INGESTION_MAPPING_FIELDS, ACTION_NAMES.LEAD_PROVIDER_MAPPINGS, () => {}, () => {}, null, false),

    readSecondarySources: generateReadActionCreator(routes.API_LEAD_PROVIDER_SECONDARY_SOURCES, ACTION_NAMES.LEAD_PROVIDER_SECONDARY_SOURCES),
    readSecondarySourceOptions: generateReadActionCreator(routes.API_LEAD_PROVIDER_SECONDARY_SOURCES, ACTION_NAMES.LEAD_PROVIDER_SECONDARY_SOURCE_OPTIONS),
    patchSecondarySources: generateUpdateActionCreator(routes.API_LEAD_PROVIDER_SECONDARY_SOURCES, ACTION_NAMES.LEAD_PROVIDER_SECONDARY_SOURCES, () => {}, () => {}, null, true)
}
