import React from 'react';
import Tasks from './Tasks';
import { connect } from 'react-redux';

import './index.scss';
import Events from './Events';
import Sequences from './Sequences';

const Activity = ({ contact_id }) => (
    <div className='Activity'>
        <Sequences contact_id={contact_id} />
        <Events contact_id={contact_id} />
        <Tasks contact_id={contact_id} />
    </div>
);

const mapStateToProps = state => ({
    contact_id: Number(state.contact.contact_id)
})

export default connect(mapStateToProps)(Activity);
