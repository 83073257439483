import React from 'react';
import '../index.scss';

export const ReportsAndLogsDataDetails = ({...report}) => {
    const isPost = report.method === 'post' ? true : false;
    const from = report?.email.from;
    const subject = isPost ? report.provider?.post_code : report.email.subject;
  return (
    <>
        { 
            !isPost  && 
                <div className='DataDetails'>
                    <strong><a href={`mailto:${from}`}>{ from }</a></strong>
                </div>
        }
        { 
            subject && isPost ?
                <div className='DataDetails'>Post code: '{ subject }'</div>
            : 
                <div className='DataDetails'>'{ subject }'</div>
        }
    </>
  )
}
