import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import FavoriteIcon from 'shared/FavoriteIcon';

const SettingsPageContainer = ({ breadcrumbs, details, title, ...props }) => (
    <Grid>
        <Grid.Row>
            <Grid.Column>
                <Header as="h2">
                    {details && <FavoriteIcon details={details} />}
                    <Header.Content>{title}</Header.Content>
                </Header>
                {breadcrumbs}
            </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Grid.Column style={{ paddingTop: '1em', paddingRight: 0, paddingLeft: '0.5em' }}>
                {props.children}
            </Grid.Column>
        </Grid.Row>
    </Grid>
)

export default SettingsPageContainer;
