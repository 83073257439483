import React, { useState } from 'react';
import { ButtonLink } from 'dyl-components';

import './index.scss';
import { Header } from 'semantic-ui-react';
import { ClippedContent } from 'dyl-components';


const HorizontalFlexList = ({ limit, entries = [] }) => {
    const [showAllEntries, setShowAllEntries] = useState(false);

    const maxRenderedEntries = entries.length === 0 ? 0 : (
        showAllEntries ? entries.length : (
            limit > entries.length ? entries.length : limit
        )
    );
    let renderedEntries = [];
    
    for (let x = 0; x < maxRenderedEntries; x++) {
        renderedEntries.push((
            <div className='List__Entry'>
                <Header as='h4' className='TeamContainer__header'>
                    <ClippedContent>{entries[x].name}</ClippedContent>
                </Header>
                <span className='TeamContainer__footer'>{`${entries[x].user_count ? entries[x].user_count : '0'} ${entries[x].user_count === 1 ? 'user' : 'users'}`}</span>
            </div>
        ));
    }

    return (
        <div className='HorizontalFlexList'>
            <div className='List'>
                {renderedEntries}
            </div>
            <div className='EntryToggleButton'>
                {(entries.length > limit && <ButtonLink onClick={() => setShowAllEntries(!showAllEntries)}>
                    <span style={{ fontWeight: "bold" }}>{`Show ${showAllEntries ? "less" : "more"}`}</span>
                </ButtonLink>)}
            </div>
        </div>
    );
}

export default HorizontalFlexList;
