import initialState from './state';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';

function templateCategoryReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CATEGORIZED_TEMPLATES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCategorizedTemplates: true };
        case getNamedAction(ACTION_NAMES.CATEGORIZED_TEMPLATES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCategorizedTemplates: false, categorized_templates: action.data.data }
        case getNamedAction(ACTION_NAMES.CATEGORIZED_TEMPLATES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCategorizedTemplates: false };

        case getNamedAction(ACTION_NAMES.TEMPLATE_CATEGORY, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCategories: true };
        case getNamedAction(ACTION_NAMES.TEMPLATE_CATEGORY, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCategories: false, categories: action.data.data, count: action.data.count }
        case getNamedAction(ACTION_NAMES.TEMPLATE_CATEGORY, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCategories: false };

        case getNamedAction(ACTION_NAMES.TEMPLATE_CATEGORY, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingCategory: true };
        case getNamedAction(ACTION_NAMES.TEMPLATE_CATEGORY, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingCategory: false }
        case getNamedAction(ACTION_NAMES.TEMPLATE_CATEGORY, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingCategory: false };

        case getNamedAction(ACTION_NAMES.TEMPLATE_CATEGORY, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, categoryBeingUpdated: action.id }
        case getNamedAction(ACTION_NAMES.TEMPLATE_CATEGORY, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, categoryBeingUpdated: null }
        case getNamedAction(ACTION_NAMES.TEMPLATE_CATEGORY, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, categoryBeingUpdated: null }

        case getNamedAction(ACTION_NAMES.TEMPLATE_CATEGORY, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, categoryBeingDeleted: action.id }
        case getNamedAction(ACTION_NAMES.TEMPLATE_CATEGORY, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, categoryBeingDeleted: null }
        case getNamedAction(ACTION_NAMES.TEMPLATE_CATEGORY, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, categoryBeingDeleted: null }

        default:
            return state;
    }
}

export default templateCategoryReducer;
