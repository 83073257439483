import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function textReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.SMS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isSendingSMSText: true };
        case getNamedAction(ACTION_NAMES.SMS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isSendingSMSText: false };
        case getNamedAction(ACTION_NAMES.SMS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isSendingSMSText: false };

        case getNamedAction(ACTION_NAMES.MMS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isSendingMMSText: true };
        case getNamedAction(ACTION_NAMES.MMS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isSendingMMSText: false };
        case getNamedAction(ACTION_NAMES.MMS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isSendingMMSText: false };

        case getNamedAction(ACTION_NAMES.PHONE_TEXT, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingPhones: true };
        case getNamedAction(ACTION_NAMES.PHONE_TEXT, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingPhones: false, phones: action.data.data, phones_count: action.data.count };
        case getNamedAction(ACTION_NAMES.PHONE_TEXT, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingPhones: false };

        case getNamedAction(ACTION_NAMES.PHONE_TEXT, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingPhone: true };
        case getNamedAction(ACTION_NAMES.PHONE_TEXT, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingPhone: false };
        case getNamedAction(ACTION_NAMES.PHONE_TEXT, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingPhone: false };

        case getNamedAction(ACTION_NAMES.PHONE_TEXT, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingPhone: true };
        case getNamedAction(ACTION_NAMES.PHONE_TEXT, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingPhone: false };
        case getNamedAction(ACTION_NAMES.PHONE_TEXT, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingPhone: false };

        case getNamedAction(ACTION_NAMES.PHONE_TEXT, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingPhone: true };
        case getNamedAction(ACTION_NAMES.PHONE_TEXT, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingPhone: false };
        case getNamedAction(ACTION_NAMES.PHONE_TEXT, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingPhone: false };

        case getNamedAction(ACTION_NAMES.LIMIT_TEXT, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingLimits: true };
        case getNamedAction(ACTION_NAMES.LIMIT_TEXT, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingLimits: false, limits: action.data.data, limits_count: action.data.count };
        case getNamedAction(ACTION_NAMES.LIMIT_TEXT, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingLimits: false };

        case getNamedAction(ACTION_NAMES.LIMIT_TEXT, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingLimit: true };
        case getNamedAction(ACTION_NAMES.LIMIT_TEXT, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingLimit: false };
        case getNamedAction(ACTION_NAMES.LIMIT_TEXT, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingLimit: false };

        case getNamedAction(ACTION_NAMES.LIMIT_TEXT, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingLimit: true };
        case getNamedAction(ACTION_NAMES.LIMIT_TEXT, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingLimit: false };
        case getNamedAction(ACTION_NAMES.LIMIT_TEXT, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingLimit: false };

        case getNamedAction(ACTION_NAMES.LIMIT_TEXT, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingLimit: true };
        case getNamedAction(ACTION_NAMES.LIMIT_TEXT, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingLimit: false };
        case getNamedAction(ACTION_NAMES.LIMIT_TEXT, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingLimit: false };


        case getNamedAction(ACTION_NAMES.CONVERSATION_TEXT, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingConversations: true };
        case getNamedAction(ACTION_NAMES.CONVERSATION_TEXT, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingConversations: false, conversations: action.data.data, conversations_count: action.data.count };
        case getNamedAction(ACTION_NAMES.CONVERSATION_TEXT, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingConversations: false };

        case getNamedAction(ACTION_NAMES.CONVERSATION_TEXT, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingConversation: true };
        case getNamedAction(ACTION_NAMES.CONVERSATION_TEXT, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingConversation: false };
        case getNamedAction(ACTION_NAMES.CONVERSATION_TEXT, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingConversation: false };

        case getNamedAction(ACTION_NAMES.MESSAGES_TEXT, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingMessages: true };
        case getNamedAction(ACTION_NAMES.MESSAGES_TEXT, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            {
                const { data, count, customer_phone, contact_phone } = action.data;
                return { ...state, isReadingMessages: false, messages: data || [], messages_count: count, to: contact_phone, from: customer_phone };
            }
        case getNamedAction(ACTION_NAMES.MESSAGES_TEXT, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingMessages: false, messages: [], message_count: 0 };

        case getNamedAction(ACTION_NAMES.FILTER_MESSAGES_TEXT, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingMessages: true };
        case getNamedAction(ACTION_NAMES.FILTER_MESSAGES_TEXT, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            {
                const { data, count, customer_phone, contact_phone } = action.data;
                return { ...state, isReadingMessages: false, filteredMessages: data || [], filtered_messages_count: count, to: contact_phone, from: customer_phone, queryParameters: action.queryParameters };
            }
        case getNamedAction(ACTION_NAMES.FILTER_MESSAGES_TEXT, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingMessages: false, messages: [], message_count: 0 };

        case getNamedAction(ACTION_NAMES.STATUS_TEXT, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isReadingStatus: true };
        case getNamedAction(ACTION_NAMES.STATUS_TEXT, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isReadingStatus: false, status: action.data.status };
        case getNamedAction(ACTION_NAMES.STATUS_TEXT, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isReadingStatus: false };

        case getNamedAction(ACTION_NAMES.MESSAGES_SEARCH, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isSearchingMessages: true };
        case getNamedAction(ACTION_NAMES.MESSAGES_SEARCH, CRUD_ACTION_TYPES.READ_SUCCESS):
            {
                const { data, count } = action.data;
                return { ...state, isSearchingMessages: false, messages: data || [], messages_count: count }
            }
        default:
            return state;
    }
}

export default textReducer;
