import React from 'react';
import { Dimmer, Loader, Button, Grid, Transition, Segment, Form } from 'semantic-ui-react';
import { TableWithHeader, ControlledPopup, STATUS_TYPES, Notification, Table, MessagingIcon } from 'dyl-components';

import './index.scss';
import { TopPaddedControls } from 'dyl-components';

class EmailForwarding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            addEmailForward: false,
            destinationEmails: [
                {
                    id: 1,
                    email: 'james@dyl.com'
                },
                {
                    id: 2,
                    email: 'sample@dyl.com'
                },
            ],
            email: ''
        }
    }

    formForwardLeadsSection = () => {
        return (
            <Button color="white" size='tiny' basic inverted
                onClick={this.onAddEmailForwardClick}>
                Add Email Forward
            </Button>
        )
    }

    formAddEmailForwardSection = () => {
        const { email } = this.state;
        
        return (
            <Form>
                <Form.Group>
                    <Form.Input width={4} 
                        label='Email'
                        value={email}
                        onChange={this.onChange}
                        name='email'
                    />
                    <TopPaddedControls>
                        <Form.Button
                            floated='right'
                            content={'Cancel'}
                            onClick={this.onCancelClick}
                        />
                        <Form.Button 
                            primary
                            floated='right'
                            content={'Add'}
                            onClick={() => {this.onAddEmailClick(email)}}
                        />
                    </TopPaddedControls>
                </Form.Group>
            </Form>
        )
            
    }

    onAddEmailForwardClick = () => {
        this.setState({
            addEmailForward: true
        })
    }

    onCancelClick = () => {
        this.setState({
            addEmailForward: false
        })
    }

    onAddEmailClick = (email) => {
        const { destinationEmails } = this.state;

        this.setState({
            destinationEmails:[
                ...destinationEmails,
                {
                   id: destinationEmails.length + 1,
                   email: email 
                }
            ],
            email: ''
        }, () => {
            Notification.alert('Email forwarding updated', STATUS_TYPES.SUCCESS, true);
        });
    }

    formDestinationEmailSection = () => {
        const { destinationEmails } = this.state;
        
        return destinationEmails.map(destinationEmail => (
                <Table.Row>
                    <Table.Cell>{destinationEmail.email}</Table.Cell>
                    <Table.Cell>
                        <ControlledPopup 
                            trigger={<MessagingIcon icon='trash'  />}
                            onConfirm={() => this.onRemoveClick(destinationEmail.id)} />
                    </Table.Cell>
                </Table.Row>
            )
        )
    }

    onRemoveClick = (id) => {
        let destinationEmails = this.state.destinationEmails.slice(0);
        let indexOfDestinationEmailsItem = destinationEmails.findIndex(destinationEmailsItem => destinationEmailsItem.id === id);
        
        if (indexOfDestinationEmailsItem !== -1) {
            destinationEmails.splice(indexOfDestinationEmailsItem, 1);

            Notification.alert('Email forwarding removed', STATUS_TYPES.SUCCESS, true);

            this.setState({ destinationEmails });
        }
    }

    onChange = (_, {name, value}) => {
        this.setState({
            [name]: value
        })
    }

    render() {
        const ForwardLeadsSection = this.formForwardLeadsSection();
        const AddEmailForwardSection = this.formAddEmailForwardSection();
        const DestinationEmailSection = this.formDestinationEmailSection();
        
        const { loading } = this.state;

        return (
            <React.Fragment>
                <div className='EmailForwarding'>
                    <TableWithHeader
                        header={
                            <Grid verticalAlign='middle'>
                                <Grid.Column width={6}>
                                    Forward Leads
                                </Grid.Column>
                                <Grid.Column width={4} textAlign='right' floated='right'>
                                    {ForwardLeadsSection}
                                </Grid.Column>
                            </Grid>
                        } 
                        table={
                            <Grid style={{margin: 0}}>
                                <Transition visible={this.state.addEmailForward} unmountOnHide duration='100' animation='slide down'>
                                    <Grid.Row style={{padding: 0}}>
                                        <Grid.Column>
                                            <Segment basic>
                                                {AddEmailForwardSection}
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Transition>
                                <Grid.Row style={{padding: 0}}>
                                    <Grid.Column style={{padding: 0}}>
                                        <Table singleLine>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell>
                                                        All leads received by your account will be forwarded to these email addresses
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        }/>
                        
                    <TableWithHeader 
                        header={
                            'Destination Email'
                        }
                        table={
                            <Table singleLine>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            Email
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Options
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {DestinationEmailSection}
                                </Table.Body>
                            </Table>
                        } />
                </div>
                <Dimmer active={loading} inverted page={true}>
                    <Loader inverted></Loader>
                </Dimmer>
            </React.Fragment>
        )
    }
}

export default EmailForwarding;
