import React, { useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { Table, ButtonLink } from 'dyl-components';
import ContactGroupPopup from './ContactGroupPopup';

const Subfooter = ({onSaveSubgroup, isSubgroupDuplicated}) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    return (
        <Table.CollapsibleRowContent indented nested secondCol>
            <Table.Cell colspan={7}>
                <Popup 
                    trigger={
                        <ButtonLink disabled={false} noPadding>
                            <Icon name='plus circle' /> New Contact Subgroup
                        </ButtonLink>
                    }
                    content={
                        <ContactGroupPopup
                            handlePopupClose={() => setIsPopupOpen(false)}
                            onSave={(data) => onSaveSubgroup(data, setIsPopupOpen)}
                            isDuplicated={isSubgroupDuplicated}
                            isSubgroup
                        />
                    }
                    open={isPopupOpen}
                    on="click"
                    onOpen={() => setIsPopupOpen(true)}
                    onClose={() => setIsPopupOpen(false)}
                    position="bottom left"
                />
            </Table.Cell>
        </Table.CollapsibleRowContent>
    )
}

export default Subfooter;