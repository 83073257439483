import React, { useState } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import { StringUtils } from 'utils';
import oauthActions from 'actions/oauth';
import { useSelector, useDispatch } from 'react-redux';
import { Notification, STATUS_TYPES } from 'dyl-components';
import './IntegrationForm.scss';

const IntegrationForm = ({ onClose }) => {

    const dispatch = useDispatch();

    const { providers = [], integrations = [], isReadingProviders, isEnablingIntegration } = useSelector(state => state.oauth_integrations);

    const providersOptions = providers.map((name, id) => ({
        key: id,
        value: name,
        text: StringUtils.capitalize(name)
    }));

    const activeIntegrations = integrations.filter(integration => integration.active) || [];
    const hasMainEmailIntegration = activeIntegrations.find(e => e.scopes.find(scope => scope.main && scope.scope_type === 'email')) || false;
    const hasMainCalendarIntegration = activeIntegrations.find(e => e.scopes.find(scope => scope.main && scope.scope_type === 'calendar')) || false;

    const [provider, setProvider] = useState(null);
    const [name, setName] = useState('');
    const [isEmailIntegration, setIsEmailIntegration] = useState(true);
    const [isCalendarIntegration, setIsCalendarIntegration] = useState(false);
    const [makeEmailPrimary, setEmailPrimary] = useState(!hasMainEmailIntegration);
    const [makeCalendarPrimary, setCalendarPrimary] = useState(!hasMainCalendarIntegration);


    const onAuthorize = async () => {
        let scopes = [];

        if (isEmailIntegration && isCalendarIntegration) {
            scopes = [
                { main: makeEmailPrimary, scope_type: 'email' },
                { main: makeCalendarPrimary, scope_type: 'calendar' }
            ];
        } else if (isEmailIntegration) {
            scopes = [
                { main: makeEmailPrimary, scope_type: 'email' }
            ];
        } else if (isCalendarIntegration) {
            scopes = [
                { main: makeCalendarPrimary, scope_type: 'calendar' }
            ];
        } else {
            Notification.alert('Integration requires either an email or calendar', STATUS_TYPES.ERROR, true);
            return;
        }

        const integration = await dispatch(oauthActions.enable({
            scopes,
            provider,
            name
        }));

        if (integration) {
            window.location.replace(integration);
        } else {
            Notification.alert('Integration Failed.', STATUS_TYPES.ERROR, true);
        }
    }

    return (
        <div className="IntegrationForm">
            <div className="IntegrationForm__header">
                <Icon link name='close' inverted onClick={onClose} />
            </div>
            <div className="IntegrationForm__body">
                <Form size='small' loading={isEnablingIntegration}>
                    <Form.Group widths='equal'>
                        <Form.Select
                            fluid
                            required
                            label='Providers'
                            placeholder='Select a Provider'
                            name='provider'
                            value={provider}
                            onChange={(_, { value }) => { setProvider(value) }}
                            options={providersOptions}
                            loading={isReadingProviders}
                            disabled={isReadingProviders}
                        />
                        <Form.Input fluid required label='Name' name='name' value={name} onChange={(_, { value }) => { setName(value) }} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Checkbox label='Email Integration' name='isEmailIntegration' checked={isEmailIntegration} onChange={(_, { checked }) => { setIsEmailIntegration(checked) }} />
                        <Form.Radio
                            label='Primary'
                            name='makeEmailPrimary'
                            onChange={(_, { checked }) => { setEmailPrimary(checked) }}
                            checked={makeEmailPrimary}
                            disabled={!hasMainEmailIntegration}

                        />
                        <Form.Checkbox label='Calendar Integration' name='isCalendarIntegration' checked={isCalendarIntegration} onChange={(_, { checked }) => { setIsCalendarIntegration(checked) }} />
                        <Form.Radio
                            label='Primary'
                            name='makeCalendarPrimary'
                            onChange={(_, { checked }) => { setCalendarPrimary(checked) }}
                            checked={makeCalendarPrimary}
                            disabled={!hasMainCalendarIntegration}
                        />
                    </Form.Group>

                    <Form.Group style={{ marginBottom: 0 }}>
                        <Form.Button content='Add and Authorize' primary onClick={onAuthorize} disabled={isReadingProviders || (provider === null || name === '')} />
                    </Form.Group>
                </Form>
            </div>
        </div>
    )
}

export default IntegrationForm;
