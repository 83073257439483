import React from 'react'
import { Header, Segment } from 'semantic-ui-react';
import PriceField from '../ProductForm/PriceField';

const PricingDetails = () => {    
    return (
        <Segment>
            <Header as="h3" color="primary">Pricing Details</Header>
            <PriceField 
                label={"Unit Price"}
                name={"price_data"}
                width={7}
            />
        </Segment>
    );
}

export default PricingDetails;
