import { useState } from "react";
import { DateTimeUtils } from "dyl-components";
import { useSelector } from "react-redux";

export const useRangeCalendar = (params, useQueryParameters = true) => {
    const [timestamp, setTimestamp] = useState(
        useQueryParameters ? getTimestampFilter(params.get("start"), params.get("end")) : "All Time"
    );
    const [isCustomDateRangeOpen, setCustomDateRangeOpen] = useState(false);
    const [isTimeRangeOpen, setTimeRangeOpen] = useState(false);
    const userAuthTimezone = useSelector((state) => state.auth.timezone);
    const [dateRange, setDateRange] = useState(useQueryParameters ? getDateRange(params) : " - ");

    return {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        isTimeRangeOpen,
        setTimeRangeOpen,
        userAuthTimezone,
        dateRange,
        setDateRange,
    };
};

const getDateRange = (params) => {
    return `${params.get("start")
        ? DateTimeUtils.formatEpoch(
            params.get("start"),
            DateTimeUtils.DATE_FORMAT
        )
        : ""
        } - ${params.get("end")
            ? DateTimeUtils.formatEpoch(
                params.get("end"),
                DateTimeUtils.DATE_FORMAT
            )
            : ""
        }`;
};

const dateFilter = ({ start, end, params, navigate, url }) => {
    const query = new URLSearchParams(params);
    query.set("page", 1);
    if (start) {
        query.set("start", start);
    } else {
        query.delete("start");
    }

    if (end) {
        query.set("end", end);
    } else {
        query.delete("end");
    }

    const query_string = query.toString();
    navigate(`${url}${query_string ? `?${query_string}` : ""}`);
};

const baseGetData = (
    setCustomDateRangeOpen,
    dateRange
) => {
    setCustomDateRangeOpen(false);
    const startString = dateRange.split(" - ")[0].trim();
    const endString = dateRange.split(" - ")[1].trim();
    return {
        start: DateTimeUtils.getUnixTime(startString, DateTimeUtils.DATE_FORMAT),
        end: DateTimeUtils.getUnixTime(`${endString} 11:59 pm`, DateTimeUtils.DATETIME_FORMAT),
    };
}

export const getData = ({
    setCustomDateRangeOpen,
    dateRange,
    params,
    navigate,
    url
}) => {
    dateFilter({
        ...baseGetData(setCustomDateRangeOpen, dateRange),
        params,
        navigate,
        url,
    });
};

export const getDataNonQuery = (
    setCustomDateRangeOpen,
    dateRange
) => {
    return baseGetData(setCustomDateRangeOpen, dateRange);
};

const baseToggleTimeUnit = (
    timestamp,
    setTimestamp,
    returnEmptyObject = false
) => {
    setTimestamp(timestamp);

    const TIMESTAMP = {
        Today: "today",
        "This Week": "this_week",
        "This Month": "this_month",
        "All Time": "any",
    };

    if (timestamp === "All Time") {
        return !returnEmptyObject ? { start: false, end: false } : {};
    }
    if (timestamp === 'Today') {
        return getCurrentDayTimestamps();
    }
    if (timestamp !== "Custom Range" && timestamp !== "All Time" && timestamp !== "Today") {
        const { start, end } = DateTimeUtils.getDateRange(TIMESTAMP[timestamp]);
        const format = DateTimeUtils.DATE_FORMAT;
        return {
            start: DateTimeUtils.getUnixTime(start, format),
            end: DateTimeUtils.getUnixTime(end, format)
        };
    }
}

export const onToggleTimeUnit = (
    timestamp,
    setTimestamp,
    params,
    navigate,
    url
) => {
    dateFilter({
        ...baseToggleTimeUnit(timestamp, setTimestamp),
        params,
        navigate,
        url,
    });
};

export const onToggleTimeUnitNonQuery = (timestamp, setTimestamp) => {
    return baseToggleTimeUnit(timestamp, setTimestamp, true);
};

const getCurrentDayTimestamps = () => {
    const { start, end } = DateTimeUtils.getDateRange('today');
    return {
        start: DateTimeUtils.getUnixTime(`${start} 12:00 am`, DateTimeUtils.DATETIME_FORMAT),
        end: DateTimeUtils.getUnixTime(`${end} 11:59 pm`, DateTimeUtils.DATETIME_FORMAT)
    };
}

const getTimestampFilter = (start, end) => {
    const { start: currentStart, end: currentEnd } = getCurrentDayTimestamps();
    return start || end ? Number(start) === currentStart && Number(end) === currentEnd ? "Today" : "Custom Range" : "All Time";
}
