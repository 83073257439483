import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import HistoryTimeline from 'shared/HistoryTimeline';

const Latest = () => (
    <React.Fragment>
        <Grid>
            <Grid.Row columns={2} verticalAlign='middle'>
                <Grid.Column>
                    <Header as='h3'>Latest</Header>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        <HistoryTimeline
            type='latest'
        />
    </React.Fragment>
);

export default Latest;
