import { DateInput, DateTimeUtils, DropdownTimeInput, Modal, Notification, STATUS_TYPES, generateResolver, yup } from "dyl-components";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { Form } from "semantic-ui-react";
import notificationActions from 'actions/notifications';


const Content = ({ notification_id, readNotifications, onClose }) => {
    const [params] = useSearchParams();
    const dispatch = useDispatch();
    const ts = DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATE_FORMAT);
    const { isUpdatingNotification } = useSelector(state => ({
        isUpdatingNotification: state.notifications.isUpdatingNotification,
    }));

    const { control, formState: { isValid, isDirty }, handleSubmit, trigger } = useForm({
        mode: 'onChange',
        defaultValues: {
            date: ts,
            time: DateTimeUtils.getCurrentDate(DateTimeUtils.TIME_FORMAT),
        },
        resolver: generateResolver({
            date: yup.string().test("valid_date", "Invalid date", (value => {
                return value ? DateTimeUtils.isValid(value, DateTimeUtils.WORD_DATE_FORMAT) : true;
            })).test("only_future", "Cannot select a past date", function (value) {
                const currentDate = DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATE_FORMAT);
                return !DateTimeUtils.isValid(value, DateTimeUtils.WORD_DATE_FORMAT) || !DateTimeUtils.dateIsBeforeAnotherDate(value, currentDate, "days", DateTimeUtils.WORD_DATE_FORMAT)
            }),
            time: yup.string().required('This field is required').test("only_future", "Please select a future time", function (value) {
                const currentDateTime = DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATETIME_FORMAT);
                const date = this.parent.date;
                if (!DateTimeUtils.isValid(date, DateTimeUtils.WORD_DATE_FORMAT)) {
                    return true;
                }
                const dateTime = `${date} ${value}`;
                return !DateTimeUtils.dateIsBeforeAnotherDate(dateTime, currentDateTime, "minutes", DateTimeUtils.WORD_DATETIME_FORMAT);
            })
        })
    });


    const onRescheduleNotification = async (data) => {
        try {
            const { date, time } = data;
            const ts = DateTimeUtils.getUnixTime(`${date} ${time}`, DateTimeUtils.WORD_DATETIME_FORMAT);
            await dispatch(notificationActions.snoozeNotification(notification_id, { snooze_ts: ts }));
            Notification.alert('Snoozed successfully', STATUS_TYPES.SUCCESS);
            readNotifications(params); 
            onClose();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to snooze notification', STATUS_TYPES.ERROR);
        }
    }

    return (
        <>
        <Modal.Header>
            Pick Date and Time
            </Modal.Header>
            <Modal.Content>
                <Form noValidate>
                    <Controller
                        control={control}
                        name={'date'}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => {
                            const commonDateInputProps = {
                                control: DateInput,
                                name,
                                value,
                                dateFormat: DateTimeUtils.WORD_DATE_FORMAT,
                                minDate: DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATE_FORMAT),
                                preserveViewMode: false
                            }
                            return (
                                <React.Fragment>
                                    <Form.Field
                                        control={'div'}
                                    >
                                        <DateInput
                                            {...commonDateInputProps}
                                            inline
                                            {...ts ? { marked: DateTimeUtils.formatEpoch(ts, DateTimeUtils.WORD_DATE_FORMAT), markColor: 'blue' } : {}}
                                            onChange={(_, { value }) => {
                                                onChange({ target: { name, value } });
                                                trigger('time')
                                            }}
                                        />
                                    </Form.Field>
                                    <Form.Field control='div' label='Date & Time' required>
                                        <Form.Group widths={'equal'}>
                                            <Form.Input
                                                error={error?.message}
                                                fluid
                                                value={value}
                                                icon='fas fa-calendar'
                                                iconPosition="right"
                                                readOnly
                                            />
                                            <Controller
                                                control={control}
                                                name='time'
                                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                                    <Form.Field
                                                        control={DropdownTimeInput}
                                                        onChange={(_, { value }) => {
                                                            onChange({ target: { name, value } });
                                                        }}
                                                        error={error?.message}
                                                        value={value}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </Form.Field>
                                </React.Fragment>
                            );
                        }}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions hasSaveButton onSave={handleSubmit(onRescheduleNotification)} saveDisabled={!isValid || !isDirty } saveOptions={{ loading: isUpdatingNotification}}/>  
        </>
    )
}

const NotificationRescheduleSnoozeModal = ({ notification_id, readNotifications, open, onClose }) => {
    return (
        <Modal open={open} onClose={onClose} >
            <Content notification_id={notification_id} readNotifications={readNotifications} onClose={onClose} />
        </Modal>
    )
}

export default NotificationRescheduleSnoozeModal;
