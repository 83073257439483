import routes from "actions/routes";
import { generateReadOneActionCreator, generateUpdateActionCreator, generateCreateActionCreator, generateDeleteActionCreator } from "actions/crudActionCreators";
import ACTION_NAMES from "actions/ACTION_NAMES";

export default {
    getContactPhones: generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_PHONE, 'phone'),
    addContactPhone: generateCreateActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_PHONE, () => { }, () => { }, 'phone'),
    updateContactPhone: generateUpdateActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_PHONE, () => { }, () => { }, 'phone'),
    deleteContactPhone: generateDeleteActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_PHONE, () => { }, () => { }, 'phone'),
    readOptions: generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.CONTACT_PHONE_OPTIONS, 'phone')
}
