import React from 'react';
import { Grid, Select, Transition } from 'semantic-ui-react';
import { EditableField } from 'dyl-components';
import { MOCK_ROUTE_OPTIONS, MOCK_RING_GROUP_OPTIONS } from '__mocks__/mockData';
import { CategorizedDropdown, TableWithHeader } from 'dyl-components';
import AfterHoursRoutingHeader from './AfterHoursRoutingHeader';
import CustomScheduleContainer from './CustomSchedule';
import './AfterHoursRoutingSettings.scss';

const routeOptionCategories = [
    { name: 'IVR Menus', options: MOCK_ROUTE_OPTIONS },
    { name: 'Ring Groups', options: MOCK_RING_GROUP_OPTIONS }
];

const scheduleOptions = [{ key: 'Use Main Schedule', value: 'Use Main Schedule', text: 'Use Main Schedule' },
{ key: 'Custom Schedule', value: 'Custom Schedule', text: 'Custom Schedule' }];

const routeOptions = [{ key: 'Default', value: 'Default', text: 'Default' }, ...MOCK_ROUTE_OPTIONS, ...MOCK_RING_GROUP_OPTIONS];

const AfterHoursRoutingSettings = ({ settings, isEditMode, onChange }) => (
    <Grid celled className='AfterHoursRoutingSettings'>
        <Grid.Row columns='equal'>
            <EditableField
                isEditMode={false}
                value={settings.phone}
                label='Phone Number'
            />
        </Grid.Row>
        <Grid.Row columns='equal'>
            <EditableField
                isEditMode={false}
                value={routeOptions.filter(option => option.key === settings.routeWhenOpen)[0].text}
                label='Route to When Open'
            />
        </Grid.Row>
        <Grid.Row columns='equal' verticalAlign='middle'>
            <EditableField
                isEditMode={isEditMode}
                label='Route to When Closed'
                control={CategorizedDropdown}
                categories={routeOptionCategories}
                defaultOption={{ key: 'Same as Open Hours', value: 'Same as Open Hours', text: 'Same as Open Hours' }}
                value={isEditMode ? settings.routeWhenClosed : routeOptions.filter(option => option.key === settings.routeWhenClosed)[0].text}
                onChange={onChange}
                name={'routeWhenClosed'}
            />
        </Grid.Row>
        <Grid.Row columns='equal' verticalAlign='middle'>
            <EditableField
                isEditMode={isEditMode}
                value={isEditMode ? settings.schedule : scheduleOptions.filter(option => option.key === settings.schedule)[0].text}
                label='Schedule'
                name={'schedule'}
                onChange={onChange}
                options={scheduleOptions}
                control={Select}
            />
        </Grid.Row>
    </Grid>
);

class AfterHoursRoutingSettingsContainer extends React.Component {
    state = {
        settings: this.props.settings,
        isEditMode: false
    }

    onChange = (_, { name, value }) => {
        let settings = { ...this.state.settings, [name]: value };
        this.setState({
            settings
        });
    }

    onUpdate = () => {
        this.setState({ isEditMode: false }, () => {
            this.props.onUpdate(this.state.settings);
        })
    }

    onEdit = () => {
        this.setState({
            isEditMode: true
        })
    }

    onCancelEdit = () => {
        this.setState({
            isEditMode: false,
            settings: this.props.settings
        })
    }

    render() {
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <TableWithHeader
                            header={
                                <AfterHoursRoutingHeader
                                    isEdit={this.state.isEditMode}
                                    onUpdate={this.onUpdate}
                                    onEdit={this.onEdit}
                                    onCancelEdit={this.onCancelEdit}
                                />
                            }
                            table={
                                <AfterHoursRoutingSettings
                                    isEditMode={this.state.isEditMode}
                                    settings={this.state.settings}
                                    onChange={this.onChange}
                                />
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
                <Transition visible={this.state.settings.schedule === 'Custom Schedule'} animation='slide down' unmountOnHide duration='150'>
                    <Grid.Row>
                        <Grid.Column>
                            <CustomScheduleContainer />
                        </Grid.Column>
                    </Grid.Row>
                </Transition>
            </Grid>

        )
    }
}

export default AfterHoursRoutingSettingsContainer;
