import React from 'react';

import { ClippedContent, CollapsibleList, Table } from 'dyl-components';
import { Icon } from 'semantic-ui-react';

const DependentFieldEntries = ({
    field,
    isParentArchived
}) => {
    const isArchived = isParentArchived || field.deleted;
    return [
        <Table.Cell disabled={isArchived}>
            <ClippedContent>
                {field.data.dependency_label} {field.data.required && <Icon className='fas fa-asterisk' color='red' size='small' />}
            </ClippedContent>
        </Table.Cell>,
        <Table.Cell>
            <ClippedContent>

            </ClippedContent>
        </Table.Cell>,
        <Table.Cell disabled={isArchived}>
            <CollapsibleList
                disabled={isArchived}
                minimumNumberOfItemsToShow={3}
                items={field.options?.data?.map(({ sub_options }) => sub_options?.map(option => option.value))?.flat() || []}
            />
        </Table.Cell>,
        <Table.Cell disabled={isArchived}>
            <ClippedContent>
                {field.data.dependency_description}
            </ClippedContent>
        </Table.Cell>,
        <Table.Cell>

        </Table.Cell>,
        <Table.Cell>

        </Table.Cell>,
        <Table.Cell>
        </Table.Cell>
    ];
}

export default DependentFieldEntries;
