import React from 'react';
import { Grid, Radio, Transition, Select } from 'semantic-ui-react';

import { EditableField } from 'dyl-components';

const CallRecordingSettingsForm = ({ callRecordingSettings, isDeleteRecordingsEnabled, isEditMode, onChange, isUserAccessEnabled, isRecordingConsented }) => (
    <React.Fragment>
        <Grid.Row columns='equal'>
            <EditableField 
                label='Call Recording Settings'
                value={callRecordingSettings}
                name='callRecordingSettings'
                isEditMode={isEditMode}
                control={Select}
                options={[
                    {key: '1-Party States', value: '1-Party States', text: '1-Party States'},
                    {key: 'Enabled', value: 'Enabled', text: 'Enabled'},
                    {key: 'Disabled', value: 'Disabled', text: 'Disabled'},
                    {key: 'Inbound Only', value: 'Inbound Only', text: 'Inbound Only'}
                ]}
                onChange={onChange}
            />
        </Grid.Row>
        <Grid.Row columns='equal'>
            <EditableField 
                label='User Access'
                value={isEditMode ? isUserAccessEnabled : isUserAccessEnabled ? 'Yes' : 'No'}
                checked={isUserAccessEnabled}
                name='isUserAccessEnabled'
                isEditMode={isEditMode}
                control={Radio}
                toggle
                onChange={(_, { name, checked }) => {onChange(null, {name, value: checked})}}
            />
        </Grid.Row>
        <Grid.Row columns='equal'>
            <EditableField 
                label='Delete Recordings'
                value={isEditMode ? isDeleteRecordingsEnabled : isDeleteRecordingsEnabled ? 'Yes' : 'No'}
                checked={isDeleteRecordingsEnabled}
                name='isDeleteRecordingsEnabled'
                isEditMode={isEditMode}
                control={Radio}
                toggle
                onChange={(_, { name, checked }) => {onChange(null, {name, value: checked})}}
            />
        </Grid.Row>
        <Transition visible={callRecordingSettings === 'Disabled' || callRecordingSettings === 'Inbound Only'} animation='slide down' duration='100'>
            <Grid.Row columns='equal'>
                <EditableField 
                    label='Recording Consent'
                    value={isEditMode ? isRecordingConsented : isRecordingConsented ? 'Yes' : 'No'}
                    checked={isRecordingConsented}
                    name='isRecordingConsented'
                    isEditMode={isEditMode}
                    control={Radio}
                    toggle
                    onChange={(_, { name, checked }) => {onChange(null, {name, value: checked})}}
                />
            </Grid.Row>
        </Transition>
    </React.Fragment>
)

export default CallRecordingSettingsForm;
