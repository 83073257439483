import React, { useState } from 'react';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Header, Icon } from 'semantic-ui-react';

import { UploadPhotoModal } from 'shared/modals';
import { useParams } from 'react-router';

import imageActions from 'actions/image';

import './index.scss';

const ProfileHeader = ({
    name,
    profilePicture,
    allowEdit,
    showUploadModal,
    onRemoveUserProfileImage
}) => {
    const { id } = useParams();
    return (
        <Grid stackable className="ProfileHeader">
            <Grid.Row columns='equal' verticalAlign='middle'>
                <Grid.Column width={1}>
                    <div className={`ProfileHeader__avatar`}>
                        <Avatar name={name} maxInitials={2} src={profilePicture} round size='5.625em' />
                        <div className={`${allowEdit ? 'ProfileHeader__avatar-overlay' : ''}`}>
                            {allowEdit &&
                                <Icon
                                    className={`ProfileHeader__avatar-action-icon ${profilePicture !== '' ? "ProfileHeader__avatar-action-icon-left" : "ProfileHeader__avatar-action-icon-center"}`}
                                    name="camera"
                                    size="big"
                                    inverted
                                    onClick={showUploadModal} />}
                            {allowEdit && profilePicture !== '' &&
                                <Icon
                                    className={"ProfileHeader__avatar-action-icon ProfileHeader__avatar-action-icon-right"}
                                    name="trash"
                                    size="big"
                                    inverted
                                    onClick={onRemoveUserProfileImage} />}
                        </div>
                    </div>
                </Grid.Column>
                <Grid.Column {...(id ? { style: { paddingLeft: '2em' } } : {})}>
                    <Header className="ProfileHeader__name">{name}</Header>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

const ProfileHeaderContainer = ({user_id}) => {
    const dispatch = useDispatch();
    const {
        profilePicture,
        name,
        isUploading,
        isCurrentUserProfile,
        isAllowedToEditOtherUsersProfilePictures
    } = useSelector((state) => {
        const { first_name = '', last_name = '' } = state.user.userProfile;
        return ({
            profilePicture: state.image.image ? `data:image/png;base64,${state.image.image}` : '',
            name: `${first_name} ${last_name}`,
            isUploading: state.image.isUploadingUserProfileImage,
            isCurrentUserProfile: user_id === state.auth.user_id,
            isAllowedToEditOtherUsersProfilePictures: false // should be replaced by a real permission if we want to use this feature
        });
    })
    const [state, setState] = useState({
        newProfilePicture: '',
        isUploadPhotoModalOpen: false
    });

    const onUpload = (file, user) => {
        return dispatch(imageActions.uploadUserProfileImage(file, user));
    };

    const onReadUserProfileImage = ({ user, isThumbnail }) => {
        return dispatch(imageActions.getUserProfileImage({ isThumbnail, user }));
    };

    const onRemoveUserProfileImage = ({ user }) => {
        return dispatch(imageActions.deleteUserProfileImage({ user }))
    }

    const onFileChange = (_, data) => {
        setState({
            newProfilePicture: data.value.length > 0 ? data.value[0] : null
        });
    };

    const showUploadModal = () => {
        setState({
            isUploadPhotoModalOpen: true
        });
    };

    const hideUploadModal = () => {
        setState({
            newProfilePicture: '',
            isUploadPhotoModalOpen: false
        });
    };

    const localOnUpload = async () => {
        onUpload(state.newProfilePicture, user_id)
            .then(() => {
                hideUploadModal();
                onReadUserProfileImage({ user: user_id, isThumbnail: true });
            })
    };

    const localOnRemoveUserProfileImage = async () => {
        let user = user_id;
        onRemoveUserProfileImage({ user })
            .then(() => {
                onReadUserProfileImage({ user, isThumbnail: true })
            })
    };

    return (
        <React.Fragment>
            <ProfileHeader
                name={name}
                profilePicture={profilePicture}
                showUploadModal={showUploadModal}
                allowEdit={isCurrentUserProfile || isAllowedToEditOtherUsersProfilePictures}
                onRemoveUserProfileImage={localOnRemoveUserProfileImage}
            />
            <UploadPhotoModal
                onClose={hideUploadModal}
                open={state.isUploadPhotoModalOpen}
                newImage={state.newProfilePicture && URL.createObjectURL(state.newProfilePicture)}
                fileChange={onFileChange}
                onUpload={localOnUpload}

                altImage={<div className="ProfileHeader__alt-image">
                    <Avatar name={name} maxInitials={2} round size="160px" textSizeRatio={0.25} />
                </div>}
                isUploading={isUploading}
                oldImage={profilePicture}
            />
        </React.Fragment>
    )
}

export default ProfileHeaderContainer;
