import React from 'react';
import { TableWithTools } from 'dyl-components';
import { Grid, Pagination } from 'semantic-ui-react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import CampaignBreadcrumbs from './subcomponents/CampaignBreadcrumbs';
import CampaignWirelessFilterContainer from './subcomponents/CampaignWirelessFilter';
import CampaignTable from './subcomponents/CampaignTable';
import CampaignTableToolbar from './subcomponents/CampaignTableToolbar';
import CampaignTextContainer from './subcomponents/CampaignText';

const Campaign = ({ queryParameters, campaignName, onChangePage, count, ...props }) => (
    <React.Fragment>
        <CampaignBreadcrumbs id={queryString.parse(window.location.search).id} campaignName={campaignName} />
        <Grid>
            <Grid.Row>
               <Grid.Column>
                    <CampaignTextContainer />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <CampaignWirelessFilterContainer id={queryString.parse(window.location.search).id} campaignName={campaignName} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <TableWithTools
                        pagination={(
                            <Pagination
                                boundaryRange={0}
                                activePage={1}
                                ellipsisItem={null}
                                siblingRange={2}
                                totalPages={1}
                                onPageChange={(_, { activePage }) => { }}
                            />
                        )}
                        table={(
                            <CampaignTable id={queryString.parse(window.location.search).id} />
                        )}
                        toolbar={(
                            <CampaignTableToolbar id={queryString.parse(window.location.search).id} />
                        )}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </React.Fragment>
)

const MOCK_CAMPAIGNS = [
    {
        id: "m34",
        name: "Cross Sell 2",
    },
    {
        id: "m35",
        name: "NOLA Conference",
    },
    {
        id: "m36",
        name: "Data",
    },
    {
        id: "m37",
        name: "Requotes 2",
    },
    {
        id: "m33",
        name: "Requotes 3"

    },
    {
        id: "m32",
        name: "Manual Data"

    },
    {
        id: "m31",
        name: "SAS Conference"

    },
    {
        id: "m30",
        name: "Cross Sell 3"

    },
    {
        id: "m29",
        name: "Cross Sell"

    },
    {
        id: "m28",
        name: "LA Conference"

    },
    {
        id: "m27",
        name: "Auto Data"

    },
    {
        id: "m26",
        name: "Requotes"

    }
];

const mapStateToProps = (state, ownProps) => {
    let id = queryString.parse(window.location.search).id
    return {
        campaignName: MOCK_CAMPAIGNS.filter(campaign => campaign.id === id)[0].name
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({

})


export default connect(mapStateToProps, mapDispatchToProps)(Campaign);
