import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function tagsReducer(state = initialState, action){
    switch(action.type){
        case getNamedAction(ACTION_NAMES.TAG_CATEGORIES, CRUD_ACTION_TYPES.READ_REQUEST):
            return {...state, isGettingListOfTagCategories: true};
        case getNamedAction(ACTION_NAMES.TAG_CATEGORIES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {...state, isGettingListOfTagCategories: false, listOfTagCategories: action.data};
        case getNamedAction(ACTION_NAMES.TAG_CATEGORIES, CRUD_ACTION_TYPES.READ_FAILURE):
            return {...state, isGettingListOfTagCategories: false};

        case getNamedAction(ACTION_NAMES.TAG_CATEGORIES, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return {...state, isAddingTagCategory: true};
        case getNamedAction(ACTION_NAMES.TAG_CATEGORIES, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return {...state, isAddingTagCategory: false};
        case getNamedAction(ACTION_NAMES.TAG_CATEGORIES, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return {...state, isAddingTagCategory: false};

        default:
            return state;
    }
}


export default tagsReducer;
