import React from 'react';
import { Modal, FileInput } from 'dyl-components';
import { Grid, Input, Checkbox, Button, Divider, Dropdown, Segment, Transition, Form } from 'semantic-ui-react';

import './SendFaxModal.scss';

class CoverLetterContainer extends React.Component {
    state = {
        coverLetterIsIncluded: false
    }

    toggleInclusionOfCoverLetter() {
        this.setState({ coverLetterIsIncluded: !this.state.coverLetterIsIncluded });
    }

    render() {
        const { coverLetterIsIncluded } = this.state;
        return (
            <React.Fragment>
                <Checkbox 
                    label='Include Cover Letter' 
                    checked={coverLetterIsIncluded} 
                    onChange={this.toggleInclusionOfCoverLetter.bind(this)} 
                />
                <Transition visible={coverLetterIsIncluded} animation='slide down' duration={150}>
                    <Form className="CoverLetterContainerForm">
                        <Form.Group widths='equal'>
                            <Form.Input 
                                label='Attention'
                                fluid
                            />
                            <Form.Input 
                                label='Company'
                                fluid
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input 
                                label='From'
                            />
                        </Form.Group>
                        <Form.TextArea 
                            label='Message'
                            placeholder='Write your message here...'
                        />
                    </Form>
                </Transition>
            </React.Fragment>
        )
    }
}

const SendFaxModal = ({ 
    open, 
    onClose, 
    onSendFax,
}) => (
    <Modal open={open} onClose={onClose}>
        <Modal.Header>
            Send Fax
        </Modal.Header>
        <Modal.Content>
            <Grid>
                <Grid.Row columns={'equal'}>
                    <Grid.Column>
                        <Segment basic>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column floated='left' textAlign='left'>
                                    <Input
                                        label={{ basic: true, content: 'Fax To:' }}
                                        placeholder=''
                                        defaultValue='(310) 623-7462'
                                    />
                                </Grid.Column>
                                <Grid.Column floated='right' textAlign='right'>
                                    <Dropdown 
                                        text={'Select Number'}
                                        floating
                                        selection
                                    />
                                </Grid.Column>
                            </Grid>
                            <Divider vertical>
                                Or
                            </Divider>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <CoverLetterContainer />
                        <div className="CoverLetterContainerDiv">
                        </div>
                        <div className="CoverLetterContainerDiv">
                        <FileInput />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Modal.Content>
        <Modal.Actions>
            <Button basic onClick={onClose}>Cancel</Button>
            <Button primary onClick={onSendFax}>Send</Button>
        </Modal.Actions>
    </Modal>
)

export default SendFaxModal;
