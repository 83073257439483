const initialState = {
    count: 0,

    categories: [],
    products: [],
    category_products: [],

    isReading: false,
    isCreating: false,
    isReadingProducts: false,
    isReadingCategoryProducts: false,

    product: null,

    productBeingRead: null,
    productBeingUpdated: false,
    productBeingDeleted: false,
    productError: false,

    isCheckingForDuplicates: false
}

export default initialState;
