import { NestedDropdown } from "dyl-components";
import React from "react";
import { StringUtils } from "utils";

const TermField = ({ name, value, onChange }) => {
    const [parent_value, child_value] = (value || "").split("/");
    return (
        <NestedDropdown
            parent_value={parent_value}
            child_value={child_value}
            label="Status"
            allowedToSelectParent
            allowedToSelectParentOnly
            onChange={(e, { parent_value, child_value }) => {
                onChange(e, {
                    name,
                    value: child_value
                        ? `${parent_value}/${child_value}`
                        : parent_value,
                });
            }}
            display_parent
            selection
            nested_options={[
                ...["default", "trial", "PIA"].map((term) => ({
                    key: term,
                    value: term,
                    text: StringUtils.capitalize(term),
                    options: [],
                })),
                {
                    key: "net",
                    value: "net",
                    text: "Net",
                    options: [
                        "15",
                        "30",
                        "60"
                    ].map((term) => ({
                        key: term,
                        value: term,
                        text: term,
                    })),
                },
            ]}
            selectOnBlur={false}
            clearable={false}
            placeholder="Select term"
        />
    );
};

export default TermField;
