import React from 'react';
import { Checkbox, Button, Grid } from 'semantic-ui-react';
import { TableWithHeader } from 'dyl-components';
import { EditableField } from 'dyl-components';

const HeaderRightViewInfo = ({onEnableEditMode}) => {
    return(
        <Button size='tiny' basic inverted color='white'
            onClick={onEnableEditMode}>Update Chat</Button>
    )
}

const HeaderRightViewEdit = ({onDisableEditMode, onUpdate}) => {
    return(
        <React.Fragment>
            <Button size='tiny' basic inverted color='white'
                onClick={onDisableEditMode}>Cancel</Button>
            &nbsp;&nbsp;&nbsp;
            <Button size='tiny' basic inverted color='white'
                onClick={onUpdate}>Update</Button>
        </React.Fragment>
    )
}

class ChatFeature extends React.Component {
    state = {
        isEditMode: false,
        isChatFeatureEnabled: this.props.isChatFeatureEnabled
    }

    onDisableEditMode = () => {
        this.setState({
            isEditMode: false,
            isChatFeatureEnabled: this.props.isChatFeatureEnabled
        })
    }

    onEnableEditMode = () => {
        this.setState({
            isEditMode: true,
        })
    }

    onChange = (_, { name, value }) => {
        this.setState({
            [name]: value
        })
    }

    onUpdate = () => {
        this.setState({
            isEditMode: false
        }, () => {
            this.props.onUpdate(this.state.isChatFeatureEnabled);
        })
    }

    formHeaderRightSection = () => {
        return this.state.isEditMode ? 
            <HeaderRightViewEdit
                onDisableEditMode={this.onDisableEditMode}
                onUpdate={this.onUpdate}
                /> : 
            <HeaderRightViewInfo 
                onEnableEditMode={this.onEnableEditMode}
                />;
    }

    formChatFeatureSection = () => {
        return (
            <EditableField 
                label='Chat Feature'
                value={this.state.isChatFeatureEnabled ? 'Yes' : 'No' }
                checked={this.state.isChatFeatureEnabled}
                control={Checkbox}
                isEditMode={this.state.isEditMode}
                onChange={(_, { name, checked }) => {this.onChange(null, {name, value: checked})}}
                name='isChatFeatureEnabled'
            />
        );
    }

    render() {
        const HeaderRightSection = this.formHeaderRightSection();
        const ChatFeatureSection = this.formChatFeatureSection();
        
        return (
            <TableWithHeader
                header={
                    <Grid verticalAlign='middle'>
                        <Grid.Column width={6}>
                            Chat Feature
                        </Grid.Column>
                        <Grid.Column width={8} textAlign='right' floated='right'>
                            {HeaderRightSection}
                        </Grid.Column>
                    </Grid>
                }
                table={
                    <Grid style={{margin: 0}} celled>
                        <Grid.Row>
                            {ChatFeatureSection}
                        </Grid.Row>
                    </Grid>
                } />
        )
    }
}

export default ChatFeature;
