import React from "react";
import { Modal } from "dyl-components";

const VoicemailBoxPinInfoModal = ({isVoicemailInfoModalOpen, setIsVoicemailInfoModalOpen}) => {
    return (
        <Modal
            open={isVoicemailInfoModalOpen}
            onClose={() => setIsVoicemailInfoModalOpen(false)}
            size="small"
        >
            <Modal.Header>Voice Mailbox Instructions</Modal.Header>
            <Modal.Content>
                <div>
                    <p>
                        This is for the main company Mailbox 300. If you create
                        a new company mailbox, please follow instruction with
                        the new mailbox extension/pin.
                    </p>
                    <h3>Company VM Set-Up</h3>
                    <ol>
                        <li>Dial extension 501.</li>
                        <li>Enter Mailbox Number: 300.</li>
                        <li>Enter Voicemail Box Pin: ****</li>
                        <li>Press 5 for Advanced Options</li>
                        <li>Follow prompts to record</li>
                    </ol>
                </div>
            </Modal.Content>
        </Modal>
    );
};

export default VoicemailBoxPinInfoModal;
