import addonActions from "actions/product_addon";
import { Notification, STATUS_TYPES, generateResolver } from "dyl-components";
import { Error404, Error500 } from "pages/ErrorPages";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import addonSchema, { formatAddonDetails, getDefaultValuesFromReadingAnAddon } from "shared/schemas/addons/addonSchema";
import AddonForm from "shared/forms/AddonForm";

const EditAddOn = ({ id }) => {
    const { addon, addonBeingUpdated } = useSelector((state) => state.product_addons);

    const methods = useForm({
        mode: "onChange",
        defaultValues: getDefaultValuesFromReadingAnAddon(addon),
        resolver: generateResolver(addonSchema),
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSave = async (data) => {
        try {
            await dispatch(
                addonActions.updateAddon(id, formatAddonDetails(data))
            );
            Notification.alert(
                "Succesfully updated add-on!",
                STATUS_TYPES.SUCCESS
            );
            methods.reset();
            navigate(
                "/settings/product-catalog-quotes-invoices/addons",
                { replace: true, state: { saved: true } }
            );
        } catch (e) {
            console.log(e);
            Notification.alert(
                "Failed to update add-on",
                STATUS_TYPES.ERROR
            );
        }
    };

    return (
        <FormProvider {...({ ...methods, addonBeingEdited: id })}>
            <AddonForm onSave={onSave} loading={addonBeingUpdated} />
        </FormProvider>
    );
};

const EditAddOnContainer = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(addonActions.readAddon(id));
    }, [id, dispatch]);

    const { addonBeingRead, addonError, addon } = useSelector(
        (state) => state.product_addons
    );

    if (addonBeingRead) {
        return (
            <Dimmer active>
                <Loader active />
            </Dimmer>
        );
    }

    if (!addonBeingRead && addonError) {
        return <Error500 message={"Something went wrong"} />;
    }

    if (!addonBeingRead && !addon) {
        return <Error404 message={"AddOn not found"} />;
    }

    return <EditAddOn id={id} />;
};

export default EditAddOnContainer;
