import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Grid, Icon } from 'semantic-ui-react';

const SequencesToolbar = () => {
    return (
        <Grid>
            <Grid.Column width={3} floated='right'>
                <Button as={NavLink} primary floated='right' to={`/settings/sequences/create`} className='AddImportButton'>
                    <Icon name='plus' /> Sequence
                </Button>
            </Grid.Column>
        </Grid>
    );
}

export default SequencesToolbar;
