import { SettingsFooter, Button } from "dyl-components";
import {Icon, Popup, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import React from "react";
import { useSelector } from "react-redux";

export const ImportFooter = ({
    isMinimumFields,
    currentStep,
    goToNextStep,
    isNextStepEnabled,
    STEPS,
    goToPreviousStep,
    width = "100%",

    customStep
}) => {
    const loading = useSelector(state => state.import_data.isAddingImport)
    const error = <Popup
                        content={
                            <div>
                                <div className="MinimumErrors"><u>Errors</u></div>
                                    <List as="ol" className="MinimumErrors">
                                        {isMinimumFields.validFields.map(field=>{
                                            if(!field.isValid){
                                                return (
                                                    <List.Item as='li'>{field.text}</List.Item>
                                                )
                                            }
                                            return ''
                                        })}
                                    </List>
                            </div>
                        }
                        wide='very'
                        position='top center'
                        trigger={
                            <Icon size='large' className='fa-solid fa-triangle-exclamation WarningRed' />
                        }
                        open
                    />

    const BackButton = (currentStep > 0) ? <Button primary basic onClick={goToPreviousStep}>Back</Button> : null;
    return (
        <SettingsFooter
            style={{ width: width }}
            className={`Webform__menu`}
            rightOptions={[
                    ((!isMinimumFields.isValid && currentStep > 0) && error),
                    <Button as={Link} to='/settings/import-data' basic>Cancel</Button>,
                    <Button loading={loading} onClick={goToNextStep} disabled={(!isNextStepEnabled())} color={(!isNextStepEnabled()) ? 'black' : 'primary'}>
                                {customStep || (currentStep < STEPS.length - 1 ? 'Next' : 'Save')}
                            </Button>,
            ]}
            leftOptions={[
                BackButton,
            ]}
        />
    );
};
