import React, { useState } from "react";
import {
    Icon,
    Header,
    Popup,
} from "semantic-ui-react";

import NewActivityForm from "./NewActivityForm";

import "./index.scss";
import { useLocation } from "react-router-dom";


const TimelineContainer = () => {
    const { state, pathname } = useLocation();
    const [isAddModalOpen, setIsAddModalOpen] = useState(state?.fromEmail || false);
    const record_type = pathname.split('/')[1];
    const isContact = record_type === 'contact';


    const onToggleAddForm = () => {
        setIsAddModalOpen(true);
    };

    return (
        <Header className="ActivitiesTab__Header" as="h2">
            <Popup
                className="AddForm"
                trigger={
                    <Icon
                        className="TimelineTab__add"
                        name="plus"
                        color="blue"
                        link
                        circular
                        inverted
                        size="tiny"
                        onClick={onToggleAddForm}
                    />
                }
                content={
                    <NewActivityForm
                        onClose={() => {
                            setIsAddModalOpen(false);
                        }}
                        readTimeline={() => { }}
                        fromEmail={state?.fromEmail}
                        isContact={isContact}
                    />
                }
                open={isAddModalOpen}
                on="click"
                position="bottom left"
                pinned
                basic
                wide
            />
            Timeline
        </Header>
    );

};

export default TimelineContainer;
