import React from 'react';
import { Form } from 'semantic-ui-react';
import { FilterComponent, DatesRangeInput } from 'dyl-components';
import TopMarginedFilterButton from 'shared/TopMarginedFilterButton';

const timeframeOptions = [
    { key: 'any', value: 'any', text: 'Any' },
    { key: 'specify', value: 'specify', text: 'Specify' },
    { key: 'today', value: 'today', text: 'Today' },
    { key: 'yesterday', value: 'yesterday', text: 'Yesterday' },
    { key: 'this_week', value: 'this_week', text: 'This Week' },
    { key: 'last_week', value: 'last_week', text: 'Last Week' },
    { key: 'this_month', value: 'this_month', text: 'This Month' },
    { key: 'last_month', value: 'last_month', text: 'Last Month' },
    { key: 'year_to_date', value: 'year_to_date', text: 'Year to Date' },
    { key: 'last_year', value: 'last_year', text: 'Last Year' },
]

export const VoicemailHistoryFilter = ({
    dateRange,
    mailbox,
    timeframe,
    onInputChange,
    onSubmit,
    listened
}) => (
        <FilterComponent>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Select name={'timeframe'} onChange={onInputChange} options={timeframeOptions} fluid label='Browse by Timeframe' value={timeframe || 'any'} />
                    <Form.Select name={'mailbox'} onChange={onInputChange} options={[
                        { key: 'all', value: 'all', text: '(All)' }
                    ]} label='Mailbox' value={mailbox}
                    />
                    <Form.Field className='FilterComponent__radio-buttons'>
                        <label>Listened</label>
                        <Form.Group inline>
                            <Form.Radio label='Both' value='both' checked={listened === 'both'} name='listened' onChange={onInputChange} />
                            <Form.Radio label='Yes' value='yes' checked={listened === 'yes'} name='listened' onChange={onInputChange} />
                            <Form.Radio label='No' value='no' checked={listened === 'no'} name='listened' onChange={onInputChange} />
                        </Form.Group>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal' className='FilterComponent__action-buttons'>
                    <Form.Field label='Browse by Date'
                        control={DatesRangeInput}
                        placeholder={"From - To"}
                        iconPosition="right"
                        value={dateRange}
                        popupPosition={'bottom center'}
                        onChange={onInputChange}
                        name="dateRange"
                    />
                    <Form.Input
                        label='Browse Transcriptions'
                        placeholder='Keywords'
                    />
                    <TopMarginedFilterButton onSubmit={onSubmit} />
                </Form.Group>
            </Form>
        </FilterComponent>
    )

export default class VoicemailHistoryFilterContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRange: '',
            listened: null,
            mailbox: null,
            timeframe: null
        }
        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onInputChange(_, { name, value }) {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    }

    onSubmit() {
        if (this.props.onFilterLeads) {
            this.props.onFilterLeads({ ...this.state });
        }
    }

    render() {
        return (
            <VoicemailHistoryFilter
                {...this.props}
                onInputChange={this.onInputChange}
                onSubmit={this.onSubmit}

                {...this.state}
            />
        )
    }
}
